import { compact } from 'lodash';

import { BlocRhpCreateRequestDTO, BlocRhpDetailsResponseDTO, BlocRhpUpdateRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface BlockRhpDetailsData {
  uuidEntity: string;
  function: EntityLightData;
  count: number;
  observation: string;
  skills: EntityLightData[];
}

export namespace BlockRhpDetailsData {
  export function mapFromApiValue(blockTeam: BlocRhpDetailsResponseDTO): BlockRhpDetailsData {
    return {
      uuidEntity: blockTeam.uuidEntity,
      function: blockTeam.familyFunction && LabelFamilyLightData.mapToEntityLightData(blockTeam.familyFunction),
      count: blockTeam.number,
      observation: blockTeam.observation,
      skills: blockTeam.skills?.length ? blockTeam.skills.map(skill => LabelFamilyLightData.mapToEntityLightData(skill)) : [{} as EntityLightData],
    };
  }

  export function mapToCreateRequestApiValue(blockTeam: BlockRhpDetailsData, blockUuidEntity: string): BlocRhpCreateRequestDTO {
    return {
      uuideBloc: blockUuidEntity,
      uuideFunction: blockTeam.function && blockTeam.function.uuidEntity,
      // eslint-disable-next-line id-blacklist
      number: +blockTeam.count,
      observation: blockTeam.observation,
      uuideSkills: compact((blockTeam.skills || []).map(skill => skill?.uuidEntity)),
    };
  }

  export function mapToUpdateRequestApiValue(blockTeam: BlockRhpDetailsData): BlocRhpUpdateRequestDTO {
    return {
      uuideFunction: blockTeam.function && blockTeam.function.uuidEntity,
      // eslint-disable-next-line id-blacklist
      number: blockTeam.count,
      observation: blockTeam.observation,
      uuideSkills: compact((blockTeam.skills || []).map(skill => skill?.uuidEntity)),
      uuidEntity: blockTeam.uuidEntity,
    };
  }
}
