import { ListCreateRequestDTO, ListDetailsResponseDTO, ListUpdateRequestDTO } from '../../generated/models/models';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { ListsTypeEnum } from '../../enums';

export interface ListDetailsData {
  uuidEntity: string;
  flowType: ListsTypeEnum;
  name: string;
  description: string;
  reference: string;
  category: LabelFamilyLightData;
}
export namespace ListDetailsData {
  export function mapFromApiValue(data: ListDetailsResponseDTO): ListDetailsData {
    return {
      uuidEntity: data.uuidEntity,
      flowType: data.typeFlow && ListsTypeEnum.convertFromApiValue.getValue(data.typeFlow),
      name: data.name,
      description: data.description,
      reference: data.refInt,
      category: data.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(data.labelFamilyProject),
    };
  }

  export function mapToCreateRequestApiValue(listDetail: ListDetailsData, projectUuidEntity: string): ListCreateRequestDTO {
    return {
      projectUuidEntity,
      name: listDetail.name,
      description: listDetail.description,
      refInt: listDetail.reference,
      labelFamilyProjectUuidEntity: listDetail.category && listDetail.category.uuidEntity,
      typeFlow: ListsTypeEnum.convertToApiValue.getValue(listDetail.flowType),
    };
  }

  export function mapToUpdateRequestApiValue(listDetail: ListDetailsData): ListUpdateRequestDTO {
    return {
      listUuidEntity: listDetail.uuidEntity,
      name: listDetail.name,
      description: listDetail.description,
      refInt: listDetail.reference,
      labelFamilyProjectUuidEntity: listDetail.category && listDetail.category.uuidEntity,
      typeFlow: ListsTypeEnum.convertToApiValue.getValue(listDetail.flowType),
    };
  }
}
