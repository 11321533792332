import { CustomFieldCreateRequestDTO, CustomFieldDetailsResponseDTO, CustomFieldUpdateRequestDTO, LabelFamilyLightDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';

import { BusinessClassNameEnum, FieldTypeEnum, LinkTypeEnum } from '../../enums';
import { TypeUuidEntityEnum } from '../../enums/utils/type-uuid-entity-enum';
import { DateUtils } from '../../utils/date.utils';

export interface CustomFieldDetailsData {
  application: EntityLightData;
  businessClass: BusinessClassNameEnum;
  createDate: Date;
  defaultValue: string | boolean;
  deleted: boolean;
  description: string;
  enums: string;
  file: ApplicationFileLightData;
  labelFamily: LabelFamilyLightData;
  modificationDate: Date;
  name: string;
  orderField: string;
  pdfFieldName: string;
  placeholder: string;
  readOnly: boolean;
  required: boolean;
  tooltip: string;
  typeField: FieldTypeEnum;
  linkType: LinkTypeEnum;
  uuidEntity: string;
  uuidType: string;
  value: string;
  variableName: string;
  displayInFlowCard: boolean;
  displayInFlowBanner: boolean;
  displayOnFlowOpenning: boolean;
  textDisplay: string;
}

export namespace CustomFieldDetailsData {
  export const defaultUuidEntity = TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT);

  export function mapFromApiValue(customField: CustomFieldDetailsResponseDTO): CustomFieldDetailsData {
    return {
      application: EntityLightData.mapFromApiValue(customField.application),
      businessClass: (customField.businessClass && BusinessClassNameEnum.convertFromApiValue.getValue(customField.businessClass)) || BusinessClassNameEnum.Flow,
      createDate: customField.createDate,
      defaultValue: mapDefaultValueFromApiValue(customField.defaultValue, customField.typeField),
      deleted: customField.deleted,
      description: customField.description,
      enums: customField.value,
      file: customField.file && ApplicationFileLightData.mapFromApiValue(customField.file),
      labelFamily: customField.labelFamily && LabelFamilyLightData.mapFromApiValue(customField.labelFamily),
      modificationDate: customField.modificationDate,
      name: customField.name,
      orderField: customField.orderField,
      pdfFieldName: customField.pdfFieldName,
      placeholder: customField.placeholder,
      readOnly: customField.readOnly,
      required: customField.required,
      tooltip: customField.tooltip,
      typeField: FieldTypeEnum.convertFromApiValue(customField.longText)?.getValue(customField.typeField),
      uuidEntity: customField.uuidEntity,
      uuidType: customField.uuidType,
      value: customField.enums,
      variableName: customField.variableName,
      linkType: getLinkType(customField),
      displayInFlowCard: customField.displayInFlowCard,
      displayInFlowBanner: customField.displayInFlowBanner,
      displayOnFlowOpenning: customField.displayOnFlowOpenning,
      textDisplay: customField.textDisplay,
    };
  }

  export function mapToCreateRequestApiValue(customField: CustomFieldDetailsData): CustomFieldCreateRequestDTO {
    return {
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(customField.businessClass),
      defaultValue: mapDefaultValueToApiValue(customField.defaultValue as string, customField.typeField),
      description: customField.description,
      labelFamilyUuidEntity: customField.labelFamily && customField.labelFamily.uuidEntity,
      name: customField.name,
      pdfFieldName: customField.pdfFieldName,
      placeholder: customField.placeholder,
      readOnly: customField.readOnly,
      required: customField.required,
      tooltip: customField.tooltip,
      typeField: FieldTypeEnum.convertToApiValue.getValue(customField.typeField),
      longText: customField.typeField === FieldTypeEnum.TextArea,
      variableName: customField.variableName,
      selectTypeValue: customField.value,
      choixLinkType: LinkTypeEnum.convertToApiValue.getValue(customField.linkType),
      displayInFlowCard: customField.displayInFlowCard,
      displayInFlowBanner: customField.displayInFlowBanner,
      displayOnFlowOpenning: customField.displayOnFlowOpenning,
      uuidEntity: customField.uuidEntity,
      uuidType: customField.uuidType,
      textDisplay: customField.textDisplay,
    };
  }

  export function mapToUpdateRequestApiValue(customField: CustomFieldDetailsData): CustomFieldUpdateRequestDTO {
    return {
      uuidEntity: customField.uuidEntity,
      defaultValue: mapDefaultValueToApiValue(customField.defaultValue as string, customField.typeField),
      description: customField.description,
      name: customField.name,
      pdfFieldName: customField.pdfFieldName,
      placeholder: customField.placeholder,
      readOnly: customField.readOnly,
      required: customField.required,
      tooltip: customField.tooltip,
      variableName: customField.variableName,
      selectTypeValue: customField.value,
      displayInFlowCard: customField.displayInFlowCard,
      displayInFlowBanner: customField.displayInFlowBanner,
      displayOnFlowOpenning: customField.displayOnFlowOpenning,
      uuidType: customField.uuidType,
      textDisplay: customField.textDisplay,
    };
  }

  function getLinkType(customField: CustomFieldDetailsResponseDTO): LinkTypeEnum {
    if (customField.choixLinkType) {
      return LinkTypeEnum.convertFromApiValue.getValue(customField.choixLinkType);
    }

    if (!customField.labelFamily) {
      return LinkTypeEnum.Default;
    }

    if (customField.labelFamily.relativeNature === LabelFamilyLightDTO.RelativeNatureEnum.PARENT) {
      return LinkTypeEnum.EntityParentLabelFamilyParent;
    }

    return LinkTypeEnum.EntityParentLabelFamily;
  }

  function mapDefaultValueFromApiValue(defaultValue: string, typeField: CustomFieldDetailsResponseDTO.TypeFieldEnum): string | boolean {
    switch (typeField) {
      case CustomFieldDetailsResponseDTO.TypeFieldEnum.BOOLEAN:
        return defaultValue?.toLowerCase() === 'true';
      case CustomFieldDetailsResponseDTO.TypeFieldEnum.DATETIME:
        return DateUtils.toCalendarFormat(defaultValue);
      default:
        return defaultValue;
    }
  }

  function mapDefaultValueToApiValue(defaultValue: string, typeField: FieldTypeEnum): string {
    switch (typeField) {
      case FieldTypeEnum.DateTime:
        return DateUtils.toZonedDateTime(defaultValue);
      default:
        return defaultValue;
    }
  }
}
