import { TechnicalObjectPropsResponseDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserLightData } from '../user/user-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface ObjectPropertiesData {
  name: string;
  family: EntityLightData;
  applicabilityName: string;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
}

export namespace ObjectPropertiesData {
  export function mapFromApiValue(objectProperties: TechnicalObjectPropsResponseDTO): ObjectPropertiesData {
    return {
      name: objectProperties.name,
      family: objectProperties.family && LabelFamilyLightData.mapToEntityLightData(objectProperties.family),
      applicabilityName: objectProperties.applicabilityName,
      creationDate: DateUtils.toDateTimeFormat(objectProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(objectProperties.modifcationDate),
      createdBy: objectProperties.createdBy && UserLightData.mapFromApiValue(objectProperties.createdBy),
      lastModifiedBy: objectProperties.lastModifiedBy && UserLightData.mapFromApiValue(objectProperties.lastModifiedBy),
    };
  }
}
