import { compact } from 'lodash';

import { FolderDetailsResponseDTO } from '../../generated/models/folderDetailsResponseDTO';
import { ProjectDetailsResponseDTO } from '../../generated/models/projectDetailsResponseDTO';

import { ClientLightData } from '../client/client-light';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';

import { LanguageEnum, ProjectStatusEnum, ProjectTypeEnum, UserRoleEnum } from '../../enums';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

export interface ProjectDetailsData {
  name: string;
  index: string;
  designation: string;
  projectType: ProjectTypeEnum;
  uuidEntity: string;
  client: EntityLightData;
  currentUserRoles: UserRoleEnum[];
  location: string;
  description: string;
  labelFamilyApplication: EntityLightData;
  firstLanguage: LanguageEnum;
  secondLanguage: LanguageEnum;
  codeProject: string;
  pgacActivities: string[];
  isContractual: boolean;
  isSupervised: boolean;
  isInternSupervised: boolean;
  contractRef: string;
  internalCommand: string;
  externalCommand: string;
  marketNumber: string;
  technicalDeal: string;
  keywords: string;
  businessDomain: string;
  nationalProgram: string;
  businessRef: string;
  startDate: string;
  endDate: string;
  complementRefOne: string;
  complementRefTwo: string;
  firstSupervision: string;
  secondSupervision: string;
  thirdSupervision: string;
  firstInternSupervision: string;
  secondInternSupervision: string;
  thirdInternSupervision: string;
  pgacBlockActive?: boolean;
  status: ProjectStatusEnum;
  localFormAllowed: boolean;
  folder?: ProjectDetailsData;
  atLeastOneFlow?: boolean;
  displayContractInformation?: boolean;
  customStatus?: CustomStatusLightData;
  customStatusComment?: string;
  diagramUuidEntity?: string;
}

export namespace ProjectDetailsData {
  export function mapFromApiValue(projectDetails: ProjectDetailsResponseDTO | FolderDetailsResponseDTO): ProjectDetailsData {
    return {
      name: projectDetails.name,
      index: projectDetails.index,
      designation: projectDetails.designation,
      uuidEntity: projectDetails.uuidEntity,
      client: projectDetails.client && ClientLightData.mapToEntityLightData(projectDetails.client),
      projectType: ProjectTypeEnum.convertFromApiValue.getValue(projectDetails.typeProject),
      firstLanguage: LanguageEnum.convertFromApiValue.getValue(projectDetails.exportLanguageOne),
      secondLanguage: LanguageEnum.convertFromApiValue.getValue(projectDetails.exportLanguageTwo),
      // Note: Change userRoles from here to test permissions...
      currentUserRoles: projectDetails.currentUserRoles?.map(role => UserRoleEnum.convertFromApiValue.getValue(role)),
      location: projectDetails.locationLibelle,
      description: projectDetails.description,
      labelFamilyApplication: projectDetails.labelFamilyApplication && LabelFamilyLightData.mapToEntityLightData(projectDetails.labelFamilyApplication),
      codeProject: projectDetails.codeProject,
      pgacActivities: compact((projectDetails.pgacActivities || '').split(';')) || [],
      isContractual: projectDetails.isContractual,
      isSupervised: projectDetails.isSupervised,
      isInternSupervised: projectDetails.isInternSupervised,
      contractRef: projectDetails.contractRef,
      internalCommand: projectDetails.internalCommand,
      externalCommand: projectDetails.externalCommand,
      marketNumber: projectDetails.marketNumber,
      technicalDeal: projectDetails.technicalDeal,
      keywords: projectDetails.keywords,
      businessDomain: projectDetails.businessDomain,
      nationalProgram: projectDetails.nationalProgram,
      businessRef: projectDetails.businessRef,
      startDate: projectDetails.startDate,
      endDate: projectDetails.endDate,
      complementRefOne: projectDetails.complementRefOne,
      complementRefTwo: projectDetails.complementRefTwo,
      firstSupervision: projectDetails.supervision1,
      secondSupervision: projectDetails.supervision2,
      thirdSupervision: projectDetails.supervision3,
      firstInternSupervision: projectDetails.supervisionIntern1,
      secondInternSupervision: projectDetails.supervisionIntern2,
      thirdInternSupervision: projectDetails.supervisionIntern3,
      pgacBlockActive:
        !!projectDetails.codeProject || !!projectDetails.startDate || !!projectDetails.endDate || (projectDetails.pgacActivities && !!projectDetails.pgacActivities.length),
      status: projectDetails.status && ProjectStatusEnum.convertFromApiValue.getValue(projectDetails.status),
      folder: (projectDetails as ProjectDetailsResponseDTO).folder && ProjectDetailsData.mapFromApiValue((projectDetails as ProjectDetailsResponseDTO).folder),
      localFormAllowed: projectDetails.localFormAllowed,
      diagramUuidEntity: (projectDetails as ProjectDetailsResponseDTO).diagramUuidEntity,
      atLeastOneFlow: (projectDetails as ProjectDetailsResponseDTO).atLeastOneFlow,
      displayContractInformation:
        projectDetails?.isContractual &&
        (!!projectDetails?.contractRef ||
          !!projectDetails?.internalCommand ||
          !!projectDetails?.externalCommand ||
          !!projectDetails?.marketNumber ||
          !!projectDetails?.technicalDeal ||
          !!projectDetails?.keywords ||
          !!projectDetails?.keywords ||
          !!projectDetails?.businessDomain ||
          !!projectDetails?.complementRefOne ||
          !!projectDetails?.complementRefTwo),
      customStatus: projectDetails?.customStatus && CustomStatusLightData.mapFromApiValue(projectDetails?.customStatus),
    };
  }

  export function mapToEntityLightData(projectDetails: ProjectDetailsData): EntityLightData {
    return {
      name: projectDetails.designation,
      uuidEntity: projectDetails.uuidEntity,
    };
  }

  export function mapApiValueToEntityLightData(projectDetails: ProjectDetailsResponseDTO): EntityLightData {
    return {
      name: projectDetails.designation,
      uuidEntity: projectDetails.uuidEntity,
      labelFamilyUuidEntity: projectDetails.labelFamilyApplication && projectDetails.labelFamilyApplication.uuidEntity,
    };
  }
}
