import { ApplicationDetailsResponseDTO } from '../../generated/models/models';
import { ApplicationUpdateRequestDTO } from '../../generated/models/applicationUpdateRequestDTO';

export interface AccountDetailsData {
  uuidEntity: string;
  uuidOrganization: string;
  companyName: string;
  companyNameComplement: string;
  ligneOne: string;
  ligneTwo: string;
  codePostal: string;
  ville: string;
  pays: string;
  telephone: string;
  fax: string;
  email: string;
  site: string;
  logo: string;
  subdomain: string;
  applicability: string;
}

export namespace AccountDetailsData {
  export function mapFromApiValue(account: ApplicationDetailsResponseDTO): AccountDetailsData {
    return {
      uuidEntity: account.uuidEntity,
      uuidOrganization: account.uuidOrganization,
      companyName: account.companyName,
      companyNameComplement: account.companyNameComp,
      ligneOne: account.ligneOne,
      ligneTwo: account.ligneTwo,
      codePostal: account.codePostal,
      ville: account.ville,
      pays: account.pays,
      telephone: account.telephone,
      fax: account.fax,
      email: account.email,
      site: account.site,
      logo: account.logo,
      subdomain: account.subdomain,
      applicability: account.applicability && account.applicability.name,
    };
  }

  export function mapToUpdateRequestApiValue(account: AccountDetailsData): ApplicationUpdateRequestDTO {
    return {
      uuidEntity: account.uuidEntity,
      companyName: account.companyName,
      companyNameComp: account.companyNameComplement,
      ligneOne: account.ligneOne,
      ligneTwo: account.ligneTwo,
      codePostal: account.codePostal,
      ville: account.ville,
      pays: account.pays,
      telephone: account.telephone,
      fax: account.fax,
      email: account.email,
      site: account.site,
      logo: account.logo,
      subdomain: account.subdomain,
      applicabilityName: account.applicability,
    };
  }
}
