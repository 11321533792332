import { FlowConflictSummaryResponseDTO, PageFlowRequest } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

export interface PgacConflictsData {
  uuidEntity: string;
  refFolder: string;
  projectNames: string;
}

export namespace PgacConflictsData {
  export type PgacConflictQueryRequest = BaseQuery<PgacConflictsData>;

  export function mapFromApiValue(conflict: FlowConflictSummaryResponseDTO): PgacConflictsData {
    return {
      uuidEntity: undefined,
      refFolder: conflict.refFolder,
      projectNames: conflict.projectNames,
    };
  }

  export function mapToPageRequestApiValue(queries: PgacConflictQueryRequest): PageFlowRequest {
    return {
      page: queries.page,
      size: queries.size,
    };
  }

  export function initializeQueryRequest(sort: SortFilter<PgacConflictsData>): PgacConflictQueryRequest {
    return { ...initializeQueries<PgacConflictsData>(sort) };
  }
}
