import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FormLocalAlreadyUseResponseDTO } from '../../generated/models/models';
import { FlowLightData } from '../flows/flow-light-data';

export interface FormAlreadyUsedResponseData {
  formUuidEntity: string;
  name: string;
  linkedFlows: FlowLightData[];
}

export namespace FormAlreadyUsedResponseData {
  export function mapFromApiValue(form: FormLocalAlreadyUseResponseDTO): FormAlreadyUsedResponseData {
    return {
      formUuidEntity: form.uuidEntity,
      name: (form.reference ? form.reference.concat(' - ') : '').concat(form.name),
      linkedFlows: (form.linkedFlows || []).map(flow => FlowLightData.mapFromApiResponse(flow)),
    };
  }

  export function mapToConflictData(form: FormAlreadyUsedResponseData): ConflictData {
    return {
      title: form.name,
      links: (form.linkedFlows || []).map(flow => (flow.refInt ? flow.refInt.concat(' - ') : '').concat(flow.name)),
    };
  }
}
