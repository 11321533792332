import { ClientCreateRequestDTO, ClientDetailsResponseDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';

export interface ClientDetailsData {
  uuidEntity: string;
  name: string;
  nameComp: string;
  nameComp2: string;
  lineOne: string;
  lineTwo: string;
  codePostal: string;
  city: string;
  pays: string;
  reference: string;
  observation: string;
  logo?: string;
  coverPage?: boolean;
  xNumPage?: number;
  yNumPage?: number;
  file?: ApplicationFileLightData;
  templateUuidEntity?: string;
  indiceMax: number;
}

export namespace ClientDetailsData {
  export function mapFromApiValue(client: ClientDetailsResponseDTO): ClientDetailsData {
    return {
      uuidEntity: client.uuidEntity,
      name: client.name,
      nameComp: client.nameComp,
      nameComp2: client.nameComp2,
      lineOne: client.ligneOne,
      lineTwo: client.ligneTwo,
      codePostal: client.codePostal,
      city: client.ville,
      pays: client.pays,
      reference: client.reference,
      observation: client.observation,
      logo: client.logo,
      coverPage: client.coverPage,
      xNumPage: client.xNumPage,
      yNumPage: client.yNumPage,
      file: client.file && ApplicationFileLightData.mapFromApiValue(client.file),
      templateUuidEntity: client.file && client.file.uuidEntity,
      indiceMax: client.indiceMax,
    };
  }

  export function mapToCreateRequestApiValue(projectForInsert: ClientDetailsData): ClientCreateRequestDTO {
    return {
      codePostal: projectForInsert.codePostal,
      ligneOne: projectForInsert.lineOne,
      ligneTwo: projectForInsert.lineTwo,
      logo: projectForInsert.logo,
      name: projectForInsert.name,
      nameComp: projectForInsert.nameComp,
      nameComp2: projectForInsert.nameComp2,
      pays: projectForInsert.pays,
      ville: projectForInsert.city,
      observation: projectForInsert.observation,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToUpdateRequestApiValue(clientForUpdate: ClientDetailsData): any {
    return {
      codePostal: clientForUpdate.codePostal,
      ligneOne: clientForUpdate.lineOne,
      ligneTwo: clientForUpdate.lineTwo,
      logo: clientForUpdate.logo,
      name: clientForUpdate.name,
      nameComp: clientForUpdate.nameComp,
      nameComp2: clientForUpdate.nameComp2,
      pays: clientForUpdate.pays,
      uuidEntity: clientForUpdate.uuidEntity,
      ville: clientForUpdate.city,
      observation: clientForUpdate.observation,
      coverPage: clientForUpdate.coverPage,
      xNumPage: (clientForUpdate.coverPage && clientForUpdate.xNumPage) || undefined,
      yNumPage: (clientForUpdate.coverPage && clientForUpdate.yNumPage) || undefined,
      templateUuide: (clientForUpdate.coverPage && clientForUpdate.templateUuidEntity) || undefined,
      indiceMax: clientForUpdate.indiceMax,
    };
  }
}
