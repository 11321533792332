import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';

export interface ContributorDeleteResponseData {
  success: boolean;
  links: string[];
}

export namespace ContributorDeleteResponseData {
  export function mapFromApiValue(deleteResponse): ContributorDeleteResponseData {
    return {
      success: deleteResponse?.success,
      links: (deleteResponse?.links || []).map(teamRole => teamRole.project.designation),
    };
  }

  export function mapToConflictData(deleteResponse: ContributorDeleteResponseData, isOperator?: boolean, isTablet?: boolean): ConflictData {
    let title = 'global.entityConflict.hint.contributor.projectLink';
    if (isOperator) {
      title = 'global.entityConflict.hint.contributor.opProjectLink';
    }
    if (isTablet) {
      title = 'global.entityConflict.hint.contributor.tabletLink';
    }

    return {
      title,
      links: deleteResponse.links || [],
    };
  }
}
