import { FamilyCountResponseDTO } from '../../generated/models/models';

export interface FamiliesCount {
  filteredFamiliesCount: number;
  totalFamiliesCount: number;
}
export namespace FamiliesCount {
  export function mapFromApiValue(countData: FamilyCountResponseDTO): FamiliesCount {
    return {
      filteredFamiliesCount: countData.filteredCount,
      totalFamiliesCount: countData.totalCount,
    };
  }
}
