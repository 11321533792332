import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './library-documents-dialog.actions';

import { LazyLoadResponse } from '../../../../../core/models';

import { DocumentSummaryData } from '../../../../../core/models/document/document-summary';

export interface LibraryDocumentsDialogState {
  dataLoading: boolean;
  saveLoading: boolean;
  documents: LazyLoadResponse<DocumentSummaryData[]>;
  importLoading: boolean;
  reset: boolean;
}

export const initialLibraryDocumentsDialogState = () => ({
  dataLoading: false,
  saveLoading: false,
  documents: {} as LazyLoadResponse<DocumentSummaryData[]>,
  importLoading: false,
  reset: false,
});

const reducer = createReducer<LibraryDocumentsDialogState>(
  initialLibraryDocumentsDialogState(),
  on(
    fromActions.loadReorganizeDocumentsSuccess,
    (state, { data, reset }): LibraryDocumentsDialogState => ({
      ...state,
      reset,
      documents: reset ? data : { ...state.documents, payload: [...state.documents.payload, ...data.payload] },
    }),
  ),
);

export function libraryDocumentsDialogReducer(state: LibraryDocumentsDialogState | undefined, action: Action): LibraryDocumentsDialogState {
  return reducer(state, action);
}
