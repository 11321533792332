import { createAction, props } from '@ngrx/store';

import { DefaultCategoryDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { LabelFamilyNatureEnum } from '../../../../../core/enums';

export const checkForFirstDefaultParent = createAction('[sCategory] Check for first parent', props<{ nature: LabelFamilyNatureEnum }>());
export const checkForFirstDefaultParentSuccess = createAction('[sCategory] Check for first parent success', props<{ exist: boolean; keepCurrentType: boolean }>());
export const checkForFirstDefaultParentFail = createAction('[sCategory] Check for first parent fail', props<DialogToasterData>());

export const addDefaultCategory = createAction('[sCategory] add new category', props<{ category: DefaultCategoryDetailsData; addNewOne?: boolean }>());
export const addDefaultCategorySuccess = createAction('[sCategory] add new category success', props<{ category: DefaultCategoryDetailsData; addNewOne?: boolean }>());
export const addDefaultCategoryFail = createAction('[sCategory] add new category fail', props<DialogToasterData>());

export const updateDefaultCategory = createAction('[sCategory] update category', props<{ category: DefaultCategoryDetailsData; closeModal?: boolean }>());
export const updateDefaultCategorySuccess = createAction('[sCategory] update category success', props<{ category: DefaultCategoryDetailsData; closeModal?: boolean }>());
export const updateDefaultCategoryFail = createAction('[sCategory] update category fail', props<DialogToasterData>());

export const getDefaultCategoryDetailsSuccess = createAction('[sCategory] get category details success', props<{ category: DefaultCategoryDetailsData }>());
export const getDefaultCategoryDetailsFail = createAction('[sCategory] get category details fail', props<DialogToasterData>());

export const navigateThroughDefaultCategories = createAction('[sCategory] Navigate through categories', props<{ category: DefaultCategoryDetailsData; step: number }>());
