import { HistorySharedLinkResponseContentDto } from '../../generated/models/historySharedLinkResponseContentDto';
import { SfxMap } from '../../utils/enum-utils';

export enum ShareHistoryStatusEnum {
  Pending = 1,
  Done = 2,
  Blocked = 3,
}

export namespace ShareHistoryStatusEnum {
  export const convertFromApiValue = new SfxMap<HistorySharedLinkResponseContentDto.StatusEnum, ShareHistoryStatusEnum>([
    [HistorySharedLinkResponseContentDto.StatusEnum.PENDING, ShareHistoryStatusEnum.Pending],
    [HistorySharedLinkResponseContentDto.StatusEnum.DONE, ShareHistoryStatusEnum.Done],
    [HistorySharedLinkResponseContentDto.StatusEnum.BLOCKED, ShareHistoryStatusEnum.Blocked],
  ]);
}
