import { PageSkillRequest, SkillSummaryResponseDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';

import { SkillsStateEnum, InnerSortDirectionEnum } from '../../enums';

export interface SkillSummaryData {
  applicationFiles: ApplicationFileLightData[];
  endDate: string;
  expertise: number;
  modificationDate: string;
  name: string;
  fullName: string;
  skillOrder: number;
  startDate: string;
  updateDate: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}

export namespace SkillSummaryData {
  const sortFields = ['name', 'modificationDate', 'endDate', 'state'];
  export interface QueryRequest extends BaseQuery<SkillSummaryData> {
    status: SkillsStateEnum;
    uuidEntityUser?: string;
    labelFamilySkill: string[];
    activityDomainsUuides: string[];
  }

  export function mapFromApiValue(skill: SkillSummaryResponseDTO): SkillSummaryData {
    return {
      applicationFiles: skill.applicationFiles.map(file => ApplicationFileLightData.mapFromApiValue(file)),
      endDate: DateUtils.toDateFormat(skill.endDate),
      updateDate: DateUtils.toDateFormat(skill.updateDate),
      expertise: +skill.expertise,
      modificationDate: DateUtils.toDateFormat(skill.modificationDate),
      name: skill.name,
      fullName: skill['tooltip'],
      skillOrder: skill.skillOrder,
      startDate: DateUtils.toDateFormat(skill.startDate),
      uuid: skill.uuid,
      uuidEntity: skill.uuidEntity,
      uuidOrganization: skill.uuidOrganization,
      uuidType: skill.uuidType,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageSkillRequest {
    return {
      textSearch: queries.textSearch || undefined,
      status: SkillsStateEnum.convertToApiValue.getValue(queries.status),
      uuidEntityUser: queries.uuidEntityUser,
      labelFamilyChildUuides: queries.labelFamilySkill,
      activityDomainsUuides: queries.activityDomainsUuides,
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, skills: string[], domaine: string[], status: string, sort: SortFilter<SkillSummaryData>): QueryRequest {
    return {
      ...initializeQueries<SkillSummaryData>(sort),
      textSearch: searchText || undefined,
      labelFamilySkill: (skills || []).length ? skills : undefined,
      activityDomainsUuides: (domaine || []).length ? domaine : undefined,
      status: (status && SkillsStateEnum.convertFromParamsValue.getValue(status)) || undefined,
    };
  }

  export function toString(skill: SkillSummaryData): string {
    return skill.name;
  }
}
