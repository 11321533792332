import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep, Dictionary } from 'lodash';

import { dummyAction } from '../../../../main/state/main.actions';

import {
  ApplicationEventLightData,
  BlockDocumentData,
  ContributorSummaryData,
  CustomColumnData,
  CustomFieldData,
  EpsilonOutSummaryData,
  ErrorResponseData,
  FlowAffectationData,
  FlowDetailsData,
  FlowDiagramData,
  FlowForDuplicate,
  FlowForMove,
  FlowPgacDetails,
  FlowSummaryData,
  GeoJsonSummaryData,
  LazyLoadResponse,
  PlanTeamRoleResponseData,
} from '../../../../../core/models';
import * as fromFlowActions from './flow-dialog.actions';

const adapter: EntityAdapter<EpsilonOutSummaryData> = createEntityAdapter<EpsilonOutSummaryData>({
  selectId: pgac => pgac.uuidEntity,
});
const flowAdapter: EntityAdapter<Partial<FlowSummaryData>> = createEntityAdapter<Partial<FlowSummaryData>>({
  selectId: flow => flow.uuidEntity,
});
const flowDiagramAdapter: EntityAdapter<FlowDiagramData> = createEntityAdapter<FlowDiagramData>({
  selectId: flow => flow.uuidEntity,
});
const customFieldsAdapter: EntityAdapter<CustomColumnData> = createEntityAdapter<CustomColumnData>({
  selectId: customField => customField.uuidEntity,
});

const flowContributorsAdapter: EntityAdapter<ContributorSummaryData> = createEntityAdapter<ContributorSummaryData>({
  selectId: flowContributor => flowContributor.uuidEntity,
});

export interface FlowDialogState {
  flowDetails: FlowDetailsData;
  customFields: CustomFieldData[];
  initialFlowDetails: FlowDetailsData;
  initialCustomFields: CustomFieldData[];
  hasReferenceGenerated: boolean;
  flowDocumentBlocks: BlockDocumentData[];
  saveLoading: boolean;
  viewLoading: boolean;
}

const initialFlowDialogState = (): FlowDialogState => ({
  flowDetails: null,
  customFields: null,
  initialFlowDetails: null,
  initialCustomFields: null,
  hasReferenceGenerated: false,
  flowDocumentBlocks: null,
  saveLoading: false,
  viewLoading: false,
});

export interface DuplicateDialogState {
  duplicateFlow: FlowForDuplicate;
  loading: boolean;
}

const initialDuplicateState = (): DuplicateDialogState => ({
  duplicateFlow: null,
  loading: false,
});

export interface FlowContributorsDialogState extends EntityState<ContributorSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  preselectedContributors: ContributorSummaryData[];
  initialContributors: ContributorSummaryData[];
  reset: boolean;
}

export const flowContributorsInitialState: FlowContributorsDialogState = flowContributorsAdapter.getInitialState<FlowContributorsDialogState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  preselectedContributors: [],
  initialContributors: [],
  reset: false,
});

export interface ImportFlowDiagramDialogState extends EntityState<FlowDiagramData> {
  ids: string[];
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export const initialImportFlowDiagramDialogState = (): ImportFlowDiagramDialogState =>
  flowDiagramAdapter.getInitialState<ImportFlowDiagramDialogState>({
    ids: [],
    entities: {},
    loading: false,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export interface MoveDialogState {
  moveFlow: FlowForMove;
  loading: boolean;
}

const initialMoveState = (): MoveDialogState => ({
  moveFlow: null,
  loading: false,
});

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

export interface GeoJsonDialogState {
  geoJsonFileElements: GeoJsonSummaryData[];
  uploading: boolean;
}

const initialGeoJsonDialogState = (): GeoJsonDialogState => ({
  geoJsonFileElements: [],
  uploading: false,
});

export interface DuplicatePgacState extends EntityState<EpsilonOutSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

const initialDuplicatePgacState = (): DuplicatePgacState =>
  adapter.getInitialState<DuplicatePgacState>({
    ids: [],
    loading: false,
    entities: {},
    error: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export interface FlowReorganizeState extends EntityState<FlowSummaryData> {
  ids: string[];
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export const initialFlowReorganizeState = (): FlowReorganizeState =>
  flowAdapter.getInitialState<FlowReorganizeState>({
    ids: [],
    entities: {},
    loading: false,
    error: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export interface CustomFieldsState extends EntityState<CustomColumnData> {
  loading: boolean;
  familyTotalCount: number;
  filteredTotalCount: number;
  error: ErrorResponseData;
  reset: boolean;
  parents: Dictionary<string>;
  subRows: Dictionary<{ children: CustomColumnData[]; childrenCount: number; page: number }>;
}

export const initialCustomFieldsState = (): CustomFieldsState =>
  customFieldsAdapter.getInitialState<CustomFieldsState>({
    ids: [],
    entities: {},
    loading: false,
    error: null,
    familyTotalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
    parents: {},
    subRows: {},
  });

const flowTeamRoleAdapter: EntityAdapter<PlanTeamRoleResponseData> = createEntityAdapter<PlanTeamRoleResponseData>({
  selectId: teamRole => teamRole.uuidEntity,
});
interface FlowTeamRoleDialogState extends EntityState<PlanTeamRoleResponseData> {
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
  flowDetails: FlowDetailsData;
}

const flowTeamRoleDialogInitialState = (): FlowTeamRoleDialogState =>
  flowTeamRoleAdapter.getInitialState<FlowTeamRoleDialogState>({
    ids: [],
    loading: false,
    entities: {},
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
    flowDetails: undefined,
  });

interface FlowAffectationsDialogState extends FlowAffectationData {
  loading: boolean;
}

const flowAffectationsDialogInitialState = (): FlowAffectationsDialogState => ({
  loading: false,
  reporter: null,
  assignee: null,
  tablet1: null,
  tablet2: null,
  tablet3: null,
  collaborators: [],
});

export interface DashboardFlowDialogState {
  flowDialogState: FlowDialogState;
  duplicatePgacState: DuplicatePgacState;
  duplicateFlowState: DuplicateDialogState;
  moveFlowState: MoveDialogState;
  historyState: HistoryDialogState;
  pgacDetails: FlowPgacDetails;
  reorganizeFlowsState: FlowReorganizeState;
  geoJsonState: GeoJsonDialogState;
  customFields: CustomFieldsState;
  affectationsEditState: FlowTeamRoleDialogState;
  affectationsListState: FlowAffectationsDialogState;
  flowContributorsState: FlowContributorsDialogState;
  importFlowDiagramState: ImportFlowDiagramDialogState;
}

export const initialDashboardFlowDialogState = (): DashboardFlowDialogState => ({
  flowDialogState: initialFlowDialogState(),
  duplicatePgacState: initialDuplicatePgacState(),
  historyState: initialHistoryDialogState(),
  duplicateFlowState: initialDuplicateState(),
  moveFlowState: initialMoveState(),
  pgacDetails: {} as FlowPgacDetails,
  reorganizeFlowsState: initialFlowReorganizeState(),
  geoJsonState: initialGeoJsonDialogState(),
  customFields: initialCustomFieldsState(),
  affectationsEditState: flowTeamRoleDialogInitialState(),
  affectationsListState: flowAffectationsDialogInitialState(),
  flowContributorsState: flowContributorsInitialState,
  importFlowDiagramState: initialImportFlowDiagramDialogState(),
});

const reducer = createReducer<DashboardFlowDialogState>(
  initialDashboardFlowDialogState(),
  on(
    fromFlowActions.loadFlowDetailsSuccess,
    (state, { flowDetails }): DashboardFlowDialogState => ({ ...state, affectationsEditState: { ...state.affectationsEditState, flowDetails } }),
  ),
  on(fromFlowActions.addFlow, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowActions.addFlowFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowActions.addFlowSuccess,
    (state, { flowDetails }): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDetails, saveLoading: false } }),
  ),
  on(fromFlowActions.updateFlow, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowActions.updateFlowFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowActions.updateFlowSuccess, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowActions.getFlowDetailsSuccess, (state, { flowDetails }): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDetails } })),
  on(fromFlowActions.saveFlowCustomFields, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowActions.saveFlowCustomFieldsFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowActions.saveFlowCustomFieldsSuccess, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowActions.loadFlowCustomFields, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowActions.loadFlowCustomFieldsFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowActions.loadFlowCustomFieldsSuccess,
    (state, { customFields }): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, customFields, saveLoading: false } }),
  ),
  on(
    fromFlowActions.initializeFlowDetails,
    (state, { flowDetails }): DashboardFlowDialogState => ({
      ...state,
      flowDialogState: { ...state.flowDialogState, initialFlowDetails: cloneDeep(flowDetails) },
    }),
  ),
  on(
    fromFlowActions.initializeFlowCustomFields,
    (state, { customFields }): DashboardFlowDialogState => ({
      ...state,
      flowDialogState: { ...state.flowDialogState, initialCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromFlowActions.checkReferenceGeneratedSuccess,
    (state, { hasReferenceGenerated, reset }): DashboardFlowDialogState => ({
      ...state,
      flowDialogState: {
        ...state.flowDialogState,
        hasReferenceGenerated,
        flowDetails: {
          ...state.flowDialogState.flowDetails,
          refInt: hasReferenceGenerated && reset ? null : state.flowDialogState?.flowDetails?.refInt,
        },
      },
    }),
  ),
  on(
    fromFlowActions.loadFlowDocumentsSuccess,
    (state, { flowDocumentBlocks }): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDocumentBlocks } }),
  ),
  on(fromFlowActions.generateFlowDocuments, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(
    fromFlowActions.generateFlowDocumentsSuccess,
    (state, { flowDocuments }): DashboardFlowDialogState => {
      const newFlowDocumentBlocks = state.flowDialogState.flowDocumentBlocks;
      newFlowDocumentBlocks.map(block => {
        const newDocument = flowDocuments.find(document => document.nature === block.documentNature);
        if (newDocument) {
          block.generated = true;
          block.uuidEntity = newDocument.uuidEntity;
          block.labelFamily = newDocument.labelFamilyApplication;
          block.versionUuidEntity = newDocument.versionUuidEntity;
        }

        return block;
      });

      return { ...state, flowDialogState: { ...state.flowDialogState, flowDocumentBlocks: newFlowDocumentBlocks, saveLoading: false } };
    },
  ),
  on(fromFlowActions.generateFlowDocumentsFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowActions.viewFlowDocument, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: true } })),
  on(fromFlowActions.viewFlowDocumentFail, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: false } })),
  on(fromFlowActions.viewFlowDocumentSuccess, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: false } })),
  on(dummyAction, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowActions.loadPgacDuplicateListSuccess,
    (state, { rows, totalCount, filteredTotalCount, reset }): DashboardFlowDialogState => {
      const newDuplicatePgacState: DuplicatePgacState = { ...state.duplicatePgacState, loading: false, totalCount, filteredTotalCount, reset };
      const newState = reset ? adapter.setAll(rows, newDuplicatePgacState) : adapter.addMany(rows, newDuplicatePgacState);

      return { ...state, duplicatePgacState: newState };
    },
  ),
  on(
    fromFlowActions.resetEpsilonStateSuccess,
    (state, data): DashboardFlowDialogState => {
      const updateEpsilons = adapter.updateOne({ id: data.uuidEntity, changes: data }, state.duplicatePgacState);

      return { ...state, duplicatePgacState: updateEpsilons };
    },
  ),
  on(
    fromFlowActions.activateEpsilonOutSuccess,
    fromFlowActions.rejectEpsilonOutSuccess,
    (state, { epsilon }): DashboardFlowDialogState => {
      const activeEpsilons = adapter.removeOne(epsilon.uuidEntity, state.duplicatePgacState);

      return { ...state, duplicatePgacState: { ...activeEpsilons, totalCount: state.duplicatePgacState.totalCount - 1 } };
    },
  ),
  on(
    fromFlowActions.initializeDuplicateFlow,
    (state, { duplicateFlow }): DashboardFlowDialogState => ({ ...state, duplicateFlowState: { ...state.duplicateFlowState, duplicateFlow } }),
  ),
  on(fromFlowActions.duplicateFlow, (state): DashboardFlowDialogState => ({ ...state, duplicateFlowState: { ...state.duplicateFlowState, loading: true } })),
  on(
    fromFlowActions.duplicateFlowFail,
    fromFlowActions.duplicateFlowSuccess,
    fromFlowActions.duplicateFlowTimeout,
    (state): DashboardFlowDialogState => ({ ...state, duplicateFlowState: { ...state.duplicateFlowState, loading: false } }),
  ),
  on(fromFlowActions.initializeMoveFlow, (state, { moveFlow }): DashboardFlowDialogState => ({ ...state, moveFlowState: { ...state.moveFlowState, moveFlow } })),
  on(fromFlowActions.moveFlow, (state): DashboardFlowDialogState => ({ ...state, moveFlowState: { ...state.moveFlowState, loading: true } })),
  on(fromFlowActions.moveFlowFail, (state): DashboardFlowDialogState => ({ ...state, moveFlowState: { ...state.moveFlowState, loading: false } })),
  on(fromFlowActions.moveFlowSuccess, (state): DashboardFlowDialogState => ({ ...state, moveFlowState: { ...state.moveFlowState, loading: false } })),
  on(
    fromFlowActions.loadHistoryListSuccess,
    (state, { data, reset }): DashboardFlowDialogState => ({
      ...state,
      historyState: {
        ...state.historyState,
        reset,
        historyList: reset ? data : { ...state.historyState.historyList, payload: [...state.historyState.historyList.payload, ...data.payload] },
      },
    }),
  ),
  on(fromFlowActions.loadPgacDetailsSuccess, fromFlowActions.updatePgacDetailsSuccess, (state, { pgacDetails }): DashboardFlowDialogState => ({ ...state, pgacDetails })),

  on(fromFlowActions.resetFlowDialogState, (state): DashboardFlowDialogState => ({ ...state, flowDialogState: initialFlowDialogState() })),
  on(fromFlowActions.addGeoJson, (state): DashboardFlowDialogState => ({ ...state, geoJsonState: { ...state.geoJsonState, uploading: true } })),
  on(fromFlowActions.addGeoJsonSuccess, (state): DashboardFlowDialogState => ({ ...state, geoJsonState: { ...state.geoJsonState, uploading: false } })),
  on(fromFlowActions.addGeoJsonFail, (state): DashboardFlowDialogState => ({ ...state, geoJsonState: { ...state.geoJsonState, uploading: false } })),
  on(
    fromFlowActions.loadGeoJsonSummaryDataElementsSuccess,
    (state, { geoJsonSummaryDataElements }): DashboardFlowDialogState => ({ ...state, geoJsonState: { ...state.geoJsonState, geoJsonFileElements: geoJsonSummaryDataElements } }),
  ),
  on(
    fromFlowActions.deleteGeoJsonSuccess,
    (state, { geoJsonSummaryData: { uuidEntity: deletedUuidEntity } }): DashboardFlowDialogState => ({
      ...state,
      geoJsonState: {
        ...state.geoJsonState,
        geoJsonFileElements: state.geoJsonState.geoJsonFileElements.filter(({ uuidEntity }) => uuidEntity !== deletedUuidEntity),
      },
    }),
  ),
  on(fromFlowActions.loadCustomFieldsList, (state): DashboardFlowDialogState => ({ ...state, customFields: { ...state.customFields, loading: true } })),
  on(
    fromFlowActions.loadCustomFieldsListSuccess,
    (state, { response, reset }): DashboardFlowDialogState => {
      const newState: CustomFieldsState = {
        ...state.customFields,
        reset,
        loading: false,
        familyTotalCount: response.totalCount,
      };

      const newCustomState = reset ? customFieldsAdapter.setAll(response.payload, newState) : customFieldsAdapter.addMany(response.payload, newState);
      const parents: Dictionary<string> = {};
      const subRows: Dictionary<{ children: CustomColumnData[]; childrenCount: number; page: number }> = {};

      response.payload.forEach((cf, index) => {
        parents[cf.uuidEntity] = cf.uuidEntity;
        subRows[cf.uuidEntity] = {
          children: cf.subItems?.length ? [{ ...cf, uuidEntity: `${cf.uuidEntity}__${index}`, isParent: false, subItems: cf.subItems }] : [],
          childrenCount: cf.subItems?.length ? 1 : 0,
          page: 0,
        };
      });

      return {
        ...state,
        customFields: { ...newCustomState, parents, subRows },
      };
    },
  ),
  on(fromFlowActions.loadTeamRoleList, (state): DashboardFlowDialogState => ({ ...state, affectationsEditState: { ...state.affectationsEditState, loading: true } })),
  on(
    fromFlowActions.loadTeamRoleListSuccess,
    (state, { planTeamRoles, totalCount, filteredTotalCount, reset }): DashboardFlowDialogState => {
      const newState: FlowTeamRoleDialogState = { ...state.affectationsEditState, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, affectationsEditState: reset ? flowTeamRoleAdapter.setAll(planTeamRoles, newState) : flowTeamRoleAdapter.addMany(planTeamRoles, newState) };
    },
  ),
  on(fromFlowActions.resetFlowTeamRoleDialogState, (state): DashboardFlowDialogState => ({ ...state, affectationsEditState: flowTeamRoleDialogInitialState() })),
  on(
    fromFlowActions.loadFlowAffectationsSuccess,
    (state, { flowAffectations }): DashboardFlowDialogState => {
      const newState: FlowAffectationsDialogState = { ...state.affectationsListState, loading: false, ...flowAffectations };

      return { ...state, affectationsListState: newState };
    },
  ),
  on(fromFlowActions.loadFlowAffectationsFail, (state): DashboardFlowDialogState => ({ ...state, affectationsListState: { ...state.affectationsListState, loading: false } })),
  on(fromFlowActions.loadFlowContributors, state => ({ ...state, collaboratorsState: { ...state.flowContributorsState, loading: true } })),
  on(
    fromFlowActions.loadFlowContributorsSuccess,
    (state, { payload, totalCount, filteredTotalCount, reset, preselectedContributors }): DashboardFlowDialogState => {
      const initialContributors = payload.map(c => cloneDeep(c));
      const flowContributorsState: FlowContributorsDialogState = {
        ...state.flowContributorsState,
        loading: false,
        reset,
        totalCount,
        filteredTotalCount,
        preselectedContributors: reset ? preselectedContributors : state.flowContributorsState.preselectedContributors,
        initialContributors: reset ? initialContributors : [...state.flowContributorsState.initialContributors, ...initialContributors],
      };
      const newState = reset ? flowContributorsAdapter.setAll(payload, flowContributorsState) : flowContributorsAdapter.addMany(payload, flowContributorsState);

      return { ...state, flowContributorsState: newState };
    },
  ),
  on(
    fromFlowActions.loadImportFlowsDiagramList,
    fromFlowActions.loadImportFlowsDiagramNextPage,
    (state): DashboardFlowDialogState => ({ ...state, importFlowDiagramState: { ...state.importFlowDiagramState, loading: true } }),
  ),
  on(fromFlowActions.loadImportFlowsDiagramFail, (state): DashboardFlowDialogState => ({ ...state, importFlowDiagramState: { ...state.importFlowDiagramState, loading: true } })),
  on(
    fromFlowActions.loadImportFlowsDiagramSuccess,
    (state, { flows, totalCount, filteredTotalCount, reset }): DashboardFlowDialogState => {
      const importFlowsDiagramDialogState: ImportFlowDiagramDialogState = {
        ...state.importFlowDiagramState,
        loading: false,
        reset,
        totalCount,
        filteredTotalCount,
      };
      const newState = reset ? flowDiagramAdapter.setAll(flows, importFlowsDiagramDialogState) : flowDiagramAdapter.addMany(flows, importFlowsDiagramDialogState);

      return { ...state, importFlowDiagramState: newState };
    },
  ),
);

export function dashboardFlowDialogReducer(state: DashboardFlowDialogState | undefined, action: Action): DashboardFlowDialogState {
  return reducer(state, action);
}

export const selectAllRefs = adapter.getSelectors().selectAll;
export const selectAllFlows = flowAdapter.getSelectors().selectAll;
export const selectAllDiagramFlows = flowDiagramAdapter.getSelectors().selectAll;
export const selectAllCustomFields = customFieldsAdapter.getSelectors().selectAll;
export const selectAllFlowTeamRolesItems = flowTeamRoleAdapter.getSelectors().selectAll;
export const selectAllFlowContributors = flowContributorsAdapter.getSelectors().selectAll;
