import { compact } from 'lodash';

import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';
import { ProjectStatusEnum } from '../project/project-status.enum';
import { ProjectTypeEnum } from '../project/project-type.enum';

export enum ProjectActionEnum {
  Edit = 1,
  Duplicate,
  Delete,
  Property,
  Publish,
  Private,
  Prepare,
  History,
}

export namespace ProjectActionEnum {
  const key = 'projects.table.actions.';

  export const toString: SfxMap<ProjectActionEnum, string> = new SfxMap<ProjectActionEnum, string>([
    [ProjectActionEnum.Edit, key + 'edit'],
    [ProjectActionEnum.Duplicate, key + 'duplicate'],
    [ProjectActionEnum.Property, key + 'properties'],
    [ProjectActionEnum.Delete, key + 'delete'],
    [ProjectActionEnum.Publish, key + 'publish'],
    [ProjectActionEnum.Private, key + 'private'],
    [ProjectActionEnum.Prepare, key + 'prepare'],
    [ProjectActionEnum.History, key + 'history'],
  ]);

  export const iconData: SfxMap<ProjectActionEnum, IconData> = new SfxMap<ProjectActionEnum, IconData>([
    [ProjectActionEnum.Edit, { name: 'edit', prefix: 'fas' }],
    [ProjectActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [ProjectActionEnum.Property, { name: 'info', prefix: 'fas' }],
    [ProjectActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [ProjectActionEnum.Publish, { name: 'store', prefix: 'fas' }],
    [ProjectActionEnum.Private, { name: 'store', prefix: 'fas', iconClassName: 'users-custom-icon' }],
    [ProjectActionEnum.Prepare, { name: 'blender', prefix: 'fas' }],
    [ProjectActionEnum.History, { name: 'flag', prefix: 'fas' }],
  ]);

  export const getActionMenu = (
    projectStatus: ProjectStatusEnum,
    projectType: ProjectTypeEnum,
    publish: boolean,
    existManyAccount: boolean,
  ): ActionSelectItemData<ProjectActionEnum>[] =>
    compact([
      mapToActionMenuItem(ProjectActionEnum.Edit, PermissionActionEnum.Edit),
      mapToActionMenuItem(ProjectActionEnum.History),
      projectType !== ProjectTypeEnum.Pgac && projectType !== ProjectTypeEnum.Folder && mapToActionMenuItem(ProjectActionEnum.Duplicate),
      projectType !== ProjectTypeEnum.Pgac &&
        existManyAccount &&
        mapToActionMenuItem(!publish ? ProjectActionEnum.Publish : ProjectActionEnum.Private, PermissionActionEnum.Publish),
      mapToActionMenuItem(ProjectActionEnum.Property),
      mapToActionMenuItem(ProjectActionEnum.Delete, PermissionActionEnum.Delete),
    ]);

  export function mapToActionMenuItem(projectActionMenu: ProjectActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<ProjectActionEnum> {
    return {
      name: ProjectActionEnum.toString.getValue(projectActionMenu),
      permissionAction,
      value: projectActionMenu,
      icon: ProjectActionEnum.iconData.getValue(projectActionMenu),
    };
  }
}
