import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowColumnEnum } from '../entity-columns/flow-column.enum';

export enum CustomColumnsTypeEnum {
  Mandatory = 'obligatory',
  Optional = 'optional',
  Custom = 'custom-status',
}

export namespace CustomColumnsTypeEnum {
  export const predefinedCategories = [CustomColumnsTypeEnum.Mandatory, CustomColumnsTypeEnum.Optional];

  export const toString: SfxMap<CustomColumnsTypeEnum, string> = new SfxMap<CustomColumnsTypeEnum, string>([
    [CustomColumnsTypeEnum.Mandatory, 'customColumns.type.mandatory'],
    [CustomColumnsTypeEnum.Optional, 'customColumns.type.optional'],
  ]);

  export const color: SfxMap<CustomColumnsTypeEnum, string> = new SfxMap<CustomColumnsTypeEnum, string>([
    [CustomColumnsTypeEnum.Mandatory, '#0d4e8a'],
    [CustomColumnsTypeEnum.Optional, '#0d4e8a'],
  ]);

  export const icon: SfxMap<CustomColumnsTypeEnum, IconData> = new SfxMap<CustomColumnsTypeEnum, IconData>([[CustomColumnsTypeEnum.Mandatory, { name: 'lock', prefix: 'fas' }]]);

  export const toFlowColumn = (forPgac: boolean): SfxMap<CustomColumnsTypeEnum, FlowColumnEnum[]> =>
    new SfxMap<CustomColumnsTypeEnum, FlowColumnEnum[]>(
      [
        [CustomColumnsTypeEnum.Mandatory, FlowColumnEnum.mandatoryColumns],
        [CustomColumnsTypeEnum.Optional, forPgac ? FlowColumnEnum.pgacAllOptionalColumns : FlowColumnEnum.allOptionalColumns],
      ],
      [],
    );
}
