import { MoveEnum, RubricNatureEnum } from '../../enums';
import { RubricQuestionDuplicateRequestDTO } from '../../generated/models/rubricQuestionDuplicateRequestDTO';

export interface QuestionToDuplicateData {
  questionUuidEntity: string;
  rubricType?: RubricNatureEnum;
}

export namespace QuestionToDuplicateData {
  export function mapToRequestApiValue(request: QuestionToDuplicateData): RubricQuestionDuplicateRequestDTO {
    return {
      siblingUuidEntity: request.questionUuidEntity,
      position: MoveEnum.convertToApiValue.getValue(MoveEnum.After),
    };
  }
}
