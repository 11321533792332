import { RubricOptionDetailsResponseDTO, RubricOptionRecap } from '../../generated/models/models';

export interface QuestionLocationData {
  latitude: number;
  longitude: number;
  altitude: number;
}

export namespace QuestionLocationData {
  export function mapFromApiValue(option: RubricOptionDetailsResponseDTO | RubricOptionRecap): QuestionLocationData {
    return { longitude: option.longitude, altitude: option.altitude, latitude: option.latitude };
  }
}
