import { FamilyDetailsResponseDTO, FamilyUpdateRequestDTO, FamilyCreateRequestDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { WordingConfigLightData } from '../wording-config-setting/wording-config-light-data';

import { LabelFamilyProvenanceEnum, LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum, ColorPickerPaletteEnum } from '../../enums';
export interface FamilyDetailsData {
  uuidEntity: string;
  nature: LabelFamilyNatureEnum;
  shortName: string;
  longName: string;
  code: string;
  color: string;
  relativeNature: LabelFamilyRelativeNatureEnum;
  provenance: LabelFamilyProvenanceEnum;
  parentFamily: LabelFamilyLightData;
  qualityReference: string;
  applicabilityName: string;
  clientSuperVision: boolean;
  clientSuperVisionSubIndex: boolean;
  wordingConfiguration: WordingConfigLightData;
}
export namespace FamilyDetailsData {
  export function mapFromApiValue(family: FamilyDetailsResponseDTO): FamilyDetailsData {
    return {
      uuidEntity: family.uuidEntity,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(family.nature),
      shortName: family.name,
      longName: family.fullName,
      code: family.code,
      color: family.color,
      provenance: LabelFamilyProvenanceEnum.convertFromApiValue.getValue(family.labelFamilyProvenance),
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(family.relativeNature),
      parentFamily: family.familyParent && LabelFamilyLightData.mapFromApiValue(family.familyParent),
      qualityReference: family.qualityReference,
      applicabilityName: (family.application && family.application.applicability && family.application.applicability.name) || '',
      clientSuperVision: family?.clientSuperVision,
      clientSuperVisionSubIndex: family?.clientSuperVisionSubIndex,
      wordingConfiguration: family?.wordingConfiguration && WordingConfigLightData.mapFromApiValue(family?.wordingConfiguration),
    };
  }

  export function mapToCreateRequestApiValue(value: FamilyDetailsData): FamilyCreateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      code: value.code || null,
      color: value.color || ColorPickerPaletteEnum.defaultColor,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(value.nature),
      relativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(value.relativeNature),
      qualityReference: value.qualityReference,
      categoryParentUuidEntity: (value.parentFamily && value.parentFamily.uuidEntity) || undefined,
      clientSuperVision: value.clientSuperVision,
      clientSuperVisionSubIndex: value.clientSuperVisionSubIndex,
      customWordingUuidEntity: value?.wordingConfiguration?.uuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(value: FamilyDetailsData): FamilyUpdateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      code: value.code || null,
      color: value.color,
      uuidEntity: value.uuidEntity,
      qualityReference: value.qualityReference,
      clientSuperVision: value.clientSuperVision,
      clientSuperVisionSubIndex: value.clientSuperVisionSubIndex,
      customWordingUuidEntity: value?.wordingConfiguration?.uuidEntity,
    };
  }
}
