import { createAction, props } from '@ngrx/store';

import { BusinessClassNameEnum } from '../../../../../../../core/enums';
import { MilestoneSummaryData } from '../../../../../../../core/models/plan/milestone-summary-data';

import { ElementPlanToImportData, FlowDetailsData, PlanSummaryData, SelectAllData } from '../../../../../../../core/models';
import { DialogToasterData } from '../../../../../../sfx-dialog/state/dialog-toaster-data';

export const addPlan = createAction('[PlanDialog] add new plan', props<{ plan: PlanSummaryData }>());
export const addPlanFail = createAction('[PlanDialog] add new plan fail', props<DialogToasterData>());

export const editPlan = createAction('[PlanDialog] edit plan', props<{ plan: PlanSummaryData }>());
export const editPlanFail = createAction('[PlanDialog] edit plan fail', props<DialogToasterData>());

export const addMilestone = createAction('[PlanDialog] add new milestone', props<{ milestone: MilestoneSummaryData }>());

export const editMilestone = createAction('[PlanDialog] edit milestone', props<{ milestone: MilestoneSummaryData }>());

export const deleteMileStone = createAction('[PlanDialog] delete milestone', props<{ milestoneUuidEntity: string }>());

export const loadPlanDetails = createAction('[PlanDialog] load plan details', props<{ plan: PlanSummaryData }>());
export const loadPlanDetailsFail = createAction('[PlanDialog] load plan details fail', props<DialogToasterData>());

export const loadMileStoneDetails = createAction('[PlanDialog] load milestone details ', props<{ milestoneUuidEntity: string }>());
export const loadMileStoneSuccess = createAction('[PlanDialog] load milestone details success ', props<{ milestone: MilestoneSummaryData }>());

export const loadImportItems = createAction('[PlanDialog] Load items for plan list', props<{ businessClass: BusinessClassNameEnum }>());
export const loadImportItemsSuccess = createAction(
  '[PlanDialog] Load items for plan list success',
  props<{ rows: Partial<ElementPlanToImportData>[]; totalCount: number; filteredTotalCount: number; disabledFilteredTotalCount?: number; reset?: boolean }>(),
);
export const loadImportItemsNextPage = createAction('[PlanDialog] Load items for plan list next page', props<{ businessClass: BusinessClassNameEnum }>());
export const loadImportItemsFails = createAction('[PlanDialog] Load items for plan list fails', props<DialogToasterData>());

export const importPlanItems = createAction('[PlanDialog] import items for plan', props<{ selectAllData: SelectAllData; businessClass: BusinessClassNameEnum }>());

export const createElementPlan = createAction('[PlanDialog] create new element plan', props<{ flowUuidEntity: string }>());

export const loadFlowDetails = createAction('[PlanDialog] load Flow Details', props<{ flowUuidEntity: string }>());
export const loadFlowDetailsSuccess = createAction('[PlanDialog] load Flow Details success', props<{ flowDetails: FlowDetailsData }>());
export const loadFlowDetailsFail = createAction('[PlanDialog] load Flow Details failed');
