import { ImportDocumentRequest } from '../../generated/models/importDocumentRequest';
import { SelectAllData } from 'app/v2.0/core/models';
import { DocumentFlowFindLazySharedRequestDTO } from '../../generated/models/documentFlowFindLazySharedRequestDTO';

export interface DashboardDocumentImportQueryRequest {
  projectUuidEntity: string;
  selectAllData: SelectAllData;
}

export namespace DashboardDocumentImportQueryRequest {
  export function mapToApiValue(data: DashboardDocumentImportQueryRequest, pageRequest: DocumentFlowFindLazySharedRequestDTO): ImportDocumentRequest {
    return {
      projectUuide: data.projectUuidEntity,
      request: {
        excludedUuids: data.selectAllData.allSelected ? data.selectAllData.excludedUuids || [] : undefined,
        selectedUuids: !data.selectAllData.allSelected ? data.selectAllData.selectedUuids || [] : undefined,
        pageRequest: data.selectAllData.allSelected ? pageRequest : undefined,
      },
    };
  }
}
