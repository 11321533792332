import { CustomStatusCountResponseDTO } from '../../generated/models/customStatusCountResponseDTO';

export interface CustomStatusCount {
  filteredCount: number;
  totalCount: number;
}

export namespace CustomStatusCount {
  export function mapFromApiValue(countData: CustomStatusCountResponseDTO): CustomStatusCount {
    return {
      filteredCount: countData.filteredCount,
      totalCount: countData.totalCount,
    };
  }
}
