import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

import { PermissionActionEnum } from '../permission-action.enum';

export enum ListActionEnum {
  Refresh = 1,
  Delete = 2,
}

export namespace ListActionEnum {
  const key = 'lists.dialog.actions.';

  export const toString: SfxMap<ListActionEnum, string> = new SfxMap<ListActionEnum, string>([
    [ListActionEnum.Refresh, key + 'refresh'],
    [ListActionEnum.Delete, key + 'delete'],
  ]);

  export const iconData: SfxMap<ListActionEnum, IconData> = new SfxMap<ListActionEnum, IconData>([
    [ListActionEnum.Refresh, { name: 'sync-alt', prefix: 'fas' }],
    [ListActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
  ]);

  export const getActionMenu: ActionSelectItemData<ListActionEnum>[] = [
    mapToActionMenuItem(ListActionEnum.Refresh, PermissionActionEnum.Edit),
    mapToActionMenuItem(ListActionEnum.Delete, PermissionActionEnum.Delete),
  ];

  export function mapToActionMenuItem(listActionMenu: ListActionEnum, permissionAction: PermissionActionEnum): ActionSelectItemData<ListActionEnum> {
    return {
      name: ListActionEnum.toString.getValue(listActionMenu),
      permissionAction,
      value: listActionMenu,
      icon: ListActionEnum.iconData.getValue(listActionMenu),
    };
  }
}
