import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FileProcessingSeparatorEnum {
  comma = ',',
  semicolon = ';',
}

export namespace FileProcessingSeparatorEnum {
  const values: FileProcessingSeparatorEnum[] = [FileProcessingSeparatorEnum.comma, FileProcessingSeparatorEnum.semicolon];

  const key = 'fileProcessing.modals.project.table.actions.selectSeparator.';
  export const toString: SfxMap<FileProcessingSeparatorEnum, string> = new SfxMap<FileProcessingSeparatorEnum, string>([
    [FileProcessingSeparatorEnum.comma, key + 'comma'],
    [FileProcessingSeparatorEnum.semicolon, key + 'semicolon'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<FileProcessingSeparatorEnum>[]> =>
    of(
      values.map(separator => ({
        value: separator,
        title: toString.getValue(separator),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FileProcessingSeparatorEnum>, item: FileProcessingSeparatorEnum) => node.value === item;
}
