import { FlowSourcePropsResponseDTO } from '../../generated/models/models';

import { FlowTypeEnum } from '../../enums';

export interface FlowSourcePropertiesData {
  name: string;
  type: FlowTypeEnum;
  projectSourceName: string;
  projectIndex: string;
}

export namespace FlowSourcePropertiesData {
  export function mapFromApiValue(flowProperties: FlowSourcePropsResponseDTO): FlowSourcePropertiesData {
    return {
      name: flowProperties.flowName,
      type: FlowTypeEnum.convertFromApiValue.getValue(flowProperties.typeFlow),
      projectSourceName: flowProperties.projectName,
      projectIndex: flowProperties.projectIndex,
    };
  }
}
