import { BlocAdrImportRequestDTO, ElementAdrToImportSummaryResponseDTO, PageImportElementAdrRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum } from '../../enums';

export interface AdrElementToImportSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  name: string;
  source: string;
  frozen: boolean;
}

export namespace AdrElementToImportSummaryData {
  const sortFields = ['family', 'risk', 'source'];

  export interface QueryRequest extends BaseQuery<AdrElementToImportSummaryData> {
    blockUuidEntity: string;
    applicabilities?: string[];
    families?: string[];
  }

  export function mapFromApiValue(adr: ElementAdrToImportSummaryResponseDTO): AdrElementToImportSummaryData {
    return {
      uuidEntity: adr.uuidEntity,
      name: adr.risk,
      family: adr.labelFamily && LabelFamilyLightData.mapFromApiValue(adr.labelFamily),
      source: adr.riskSource,
      frozen: adr.alreadyImported,
    };
  }

  export function mapToRequestApiValue(blockUuidEntity: string, elementAdrUuidEntity: string): BlocAdrImportRequestDTO {
    return {
      blocUuidEntity: blockUuidEntity,
      elementAdrUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageImportElementAdrRequestDTO {
    return {
      applicabilities: queries.applicabilities,
      families: queries.families,
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
      blocUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(
    blockUuidEntity: string,
    applicabilities: string[],
    families: string[],
    searchText: string,
    sort: SortFilter<AdrElementToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<AdrElementToImportSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      blockUuidEntity,
    };
  }
}
