import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { cloneDeep } from 'lodash';

import * as fromActions from './add-lib-doc.actions';
import * as fromVersionActions from '../../../../../shared/documents-tabs/document-tracking-tab/shared-actions/doc-version.actions';

import { DocumentVersionDetailsData } from '../../../../../../core/models/document/document-version-details';
import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { ApplicationFileLightData, CustomFieldData } from '../../../../../../core/models';
import { DocumentVersionSummaryData } from '../../../../../../core/models/document/document-version-summary';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentManageDiffusionEnum } from '../../../../../../core/enums/document/document-manage-diffusion.enum';
import { InnerSortDirectionEnum } from '../../../../../../core/enums';

// Note: SubState DocFiles
const docFilesAdapter: EntityAdapter<ApplicationFileLightData> = createEntityAdapter<ApplicationFileLightData>({
  selectId: file => file.uuidEntity,
});

export interface DocFilesState extends EntityState<ApplicationFileLightData> {
  loading: boolean;
  viewFileLoading: string;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

const docFilesInitialState = (): DocFilesState =>
  docFilesAdapter.getInitialState<DocFilesState>({
    ids: [],
    entities: {},
    loading: false,
    viewFileLoading: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

// Note: Library Doc main state
const adapter: EntityAdapter<DocumentVersionDetailsData> = createEntityAdapter<DocumentVersionDetailsData>({
  selectId: documentVersion => documentVersion.uuidEntity,
});

export interface AddLibDocState extends EntityState<DocumentVersionDetailsData> {
  dataLoading: boolean;
  saveLoading: boolean;
  diffuseLoading: boolean;
  manageDiffusionLoading: DocumentManageDiffusionEnum;
  menuLoading: string;
  fileLoading: string;
  responseFileLoading: string;
  followTabChanged: boolean;
  documentDetails: DocumentDetailsData;
  initialDocumentDetails: DocumentDetailsData;
  documentCustomFields: CustomFieldData[];
  initialCustomFields: CustomFieldData[];
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
  documentFiles: DocFilesState;
  documentCustomWording: CustomWordingsDocumentData;
}

export const initialAddLibDocState = (): AddLibDocState =>
  adapter.getInitialState<AddLibDocState>({
    ids: [],
    entities: {},
    dataLoading: false,
    saveLoading: false,
    menuLoading: null,
    fileLoading: null,
    responseFileLoading: null,
    diffuseLoading: false,
    manageDiffusionLoading: null,
    followTabChanged: false,
    documentDetails: {} as DocumentDetailsData,
    initialDocumentDetails: {} as DocumentDetailsData,
    documentCustomFields: [],
    initialCustomFields: [],
    reset: false,
    totalCount: null,
    filteredTotalCount: null,
    documentFiles: docFilesInitialState(),
    documentCustomWording: {} as CustomWordingsDocumentData,
  });

const reducer = createReducer<AddLibDocState>(
  initialAddLibDocState(),
  // Info: Loading actions
  on(fromActions.loadDocumentDetails, fromActions.loadDocumentCustomFields, fromVersionActions.loadDocumentVersions, (state): AddLibDocState => ({ ...state, dataLoading: true })),
  on(fromActions.loadDocumentDetails, (state, { reset }): AddLibDocState => (reset ? { ...state, followTabChanged: false } : state)),
  on(
    fromActions.loadDocumentDetailsFail,
    fromActions.loadDocumentCustomFieldsFail,
    fromVersionActions.loadDocumentVersionsFail,
    (state): AddLibDocState => ({ ...state, dataLoading: false }),
  ),

  on(
    fromActions.loadDocumentDetailsSuccess,
    (state, { documentDetails }): AddLibDocState => ({ ...state, documentDetails, initialDocumentDetails: cloneDeep(documentDetails), dataLoading: false }),
  ),
  on(
    fromActions.loadDocumentCustomFieldsSuccess,
    (state, { documentCustomFields }): AddLibDocState => ({ ...state, documentCustomFields, initialCustomFields: cloneDeep(documentCustomFields), dataLoading: false }),
  ),
  on(
    fromVersionActions.loadDocumentVersionsSuccess,
    (state, { documentsVersion, reset }): AddLibDocState => {
      const newState: AddLibDocState = { ...state, dataLoading: false, reset, totalCount: documentsVersion.totalCount, filteredTotalCount: documentsVersion.filteredTotalCount };

      return reset ? adapter.setAll(documentsVersion.payload, newState) : adapter.addMany(documentsVersion.payload, newState);
    },
  ),
  on(
    fromActions.checkGeneratedRefsSuccess,
    (state, { familyUuidEntity, genRefInt, genRefExt, genCompRefInt, genCompRefExt, reset }): AddLibDocState => {
      const partialDocChanges = { generatedRefInt: genRefInt, generatedRefExt: genRefExt, generatedCompRefInt: genCompRefInt, generatedCompRefExt: genCompRefExt };
      const newRefInt = reset && genRefInt ? null : state.documentDetails?.refInt;
      const newRefExt = reset && genRefExt ? null : state.documentDetails?.refExt;

      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          ...partialDocChanges,
          refInt: state.initialDocumentDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialDocumentDetails.refInt : newRefInt,
          refExt: state.initialDocumentDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialDocumentDetails.refExt : newRefExt,
        },
        initialDocumentDetails: { ...state.initialDocumentDetails, ...partialDocChanges },
      };
    },
  ),

  // Info: Saving actions
  on(fromActions.saveDocumentChanges, (state): AddLibDocState => ({ ...state, saveLoading: true })),
  on(fromActions.createDocumentFail, fromActions.updateDocumentFail, fromActions.stopSaveLoading, (state): AddLibDocState => ({ ...state, saveLoading: false })),
  on(
    fromActions.createDocumentSuccess,
    (state, { documentDetails }): AddLibDocState => ({ ...state, documentDetails, initialDocumentDetails: cloneDeep(documentDetails), saveLoading: false }),
  ),
  on(
    fromActions.updateDocumentSuccess,
    (state, { documentDetails, customFields }): AddLibDocState => ({
      ...state,
      documentDetails,
      initialDocumentDetails: cloneDeep(documentDetails),
      initialCustomFields: cloneDeep(customFields),
      saveLoading: false,
    }),
  ),

  // Info: Diffusion Actions:
  on(
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    (state): AddLibDocState => ({ ...state, diffuseLoading: true }),
  ),
  on(fromVersionActions.addSubIndex, (state): AddLibDocState => ({ ...state, manageDiffusionLoading: DocumentManageDiffusionEnum.AddSubIndex })),
  on(
    fromVersionActions.unlockDocumentVersion,
    (state, { sameIndex }): AddLibDocState => ({ ...state, manageDiffusionLoading: sameIndex ? DocumentManageDiffusionEnum.SameIndex : DocumentManageDiffusionEnum.AddIndex }),
  ),
  on(
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    fromVersionActions.downloadOrGenerateLibraryDocumentVersionPdf,
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.unlockDocumentVersion,
    fromVersionActions.cancelDiffusion,
    fromVersionActions.particularization,
    (state): AddLibDocState => ({ ...state, followTabChanged: true }),
  ),
  on(fromVersionActions.uploadDocVersionFile, (state, { docVersionUuidEntity }): AddLibDocState => ({ ...state, fileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.viewLibraryDocumentVersionPdf,
    fromVersionActions.downloadOrGenerateLibraryDocumentVersionPdf,
    (state, { docVersionUuidEntity, fileLoading }): AddLibDocState => ({
      ...state,
      fileLoading: fileLoading && docVersionUuidEntity,
      menuLoading: !fileLoading && docVersionUuidEntity,
    }),
  ),
  on(fromVersionActions.downloadDocumentVersionResponse, (state, { docVersionUuidEntity }): AddLibDocState => ({ ...state, responseFileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.particularization,
    fromVersionActions.updateDocVersionCollaborators,
    fromVersionActions.downloadToDiskLibraryDocumentVersionPdf,
    fromVersionActions.refreshFromConfig,
    fromVersionActions.refreshFromLastIndex,
    (state, { docVersionUuidEntity }): AddLibDocState => ({ ...state, menuLoading: docVersionUuidEntity }),
  ),
  on(
    fromVersionActions.updateDocumentVersionFail,
    fromVersionActions.updateDocumentVersionSuccess,
    fromActions.stopSaveLoading,
    fromVersionActions.clientResponseSuccess,
    fromVersionActions.unlockVdfSuccess,
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state): AddLibDocState => ({ ...state, diffuseLoading: false, menuLoading: null, fileLoading: null, responseFileLoading: null, manageDiffusionLoading: null }),
  ),

  on(
    fromVersionActions.updateDocumentVersionSuccess,
    (state, { docVersion }): AddLibDocState =>
      docVersion
        ? adapter.updateOne(
            { id: docVersion.uuidEntity, changes: docVersion },
            { ...state, documentDetails: { ...state.documentDetails, status: docVersion.lastDocumentVersionStatus } },
          )
        : state,
  ),

  on(
    fromVersionActions.clientResponseSuccess,
    (state, { docVersions, sortDirection }): AddLibDocState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, state)
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),

  on(
    fromVersionActions.unlockVdfSuccess,
    (state, { docVersions, sortDirection }): AddLibDocState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, {
              ...state,
              documentDetails: { ...state.documentDetails, lastDocumentVersion: DocumentVersionSummaryData.mapFromDocVersionDetails(docVersions.currentVersion) },
            })
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),

  on(
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state, { docVersions }): AddLibDocState =>
      adapter.updateOne(
        { id: docVersions.currentVersion.uuidEntity, changes: docVersions.currentVersion },
        adapter.removeOne(docVersions.previousVersion.uuidEntity, { ...state, filteredTotalCount: state.filteredTotalCount - 1 }),
      ),
  ),

  // Document Files ons
  on(
    fromActions.loadDocumentFilesSuccess,
    (state, { files, totalCount, filteredTotalCount, reset }): AddLibDocState => {
      const newApplicationFilesState: DocFilesState = { ...state.documentFiles, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? docFilesAdapter.setAll(files, newApplicationFilesState) : docFilesAdapter.addMany(files, newApplicationFilesState);

      return { ...state, documentFiles: newState };
    },
  ),
  on(fromActions.resetFilesState, (state): AddLibDocState => ({ ...state, documentFiles: docFilesInitialState() })),
  on(
    fromActions.deleteDocumentFileSuccess,
    (state, { fileUuidEntity }): AddLibDocState => {
      const newState = docFilesAdapter.removeOne(fileUuidEntity, { ...state.documentFiles, filteredTotalCount: state.documentFiles.filteredTotalCount - 1 });

      return { ...state, documentFiles: newState };
    },
  ),
  on(
    fromActions.viewDocumentFileSuccess,
    fromActions.viewDocumentFileFail,
    (state): AddLibDocState => {
      const newState: DocFilesState = { ...state.documentFiles, viewFileLoading: null };

      return { ...state, documentFiles: newState };
    },
  ),
  on(fromActions.viewDocumentFile, (state, { fileUuidEntity }): AddLibDocState => ({ ...state, documentFiles: { ...state.documentFiles, viewFileLoading: fileUuidEntity } })),

  on(fromActions.loadCustomWordingDetailsSuccess, (state, { documentCustomWording }): AddLibDocState => ({ ...state, documentCustomWording })),
);

export function addLibDocReducer(state: AddLibDocState | undefined, action: Action): AddLibDocState {
  return reducer(state, action);
}

export const selectAllDocumentVersions = adapter.getSelectors().selectAll;
export const selectAllDocFiles = docFilesAdapter.getSelectors().selectAll;
