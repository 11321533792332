import { RubricOptionDetailsResponseDTO } from '../../generated/models/models';
import { durationHandler } from '../../utils/time.utils';

export interface QuestionDurationData {
  hours?: string;
  minutes?: string;
}

export namespace QuestionDurationData {
  export function mapFromApiValue(option: RubricOptionDetailsResponseDTO): QuestionDurationData {
    const duration = option.timeValue?.toString();

    return { hours: durationHandler(duration?.substring(0, 2)), minutes: durationHandler(duration?.substring(3, 5), true) };
  }
}
