import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserData } from '../models';
import { ApiUrlsEnum } from './helpers/api-url';
import { apiUrlMatcher, externApiUrlMatcher } from './helpers/api-url-matcher';
import { BaseHttpService } from './base-http.service';
import { ZendeskTokenResponseDTO } from '../generated/models/zendeskTokenResponseDTO';
import { CreateZendeskOrganization, CreateZendeskUser, ZendeskData } from '../models/help-center/zendesk-data';
import { SfsZendeskWidget } from '../../../../assets/config/zendesk-config';

@Injectable({ providedIn: 'root' })
export class ZendeskService {
  public widget: SfsZendeskWidget;
  private readonly headers = new HttpHeaders({
    Authorization: 'Bearer ' + environment.helpCenter.zendesk.accessToken,
    'Content-Type': 'application/json',
  });

  constructor(private httpClient: HttpClient, private baseHttp: BaseHttpService) {}

  public initZendeskWidget(jwtToken: string) {
    this.widget = new SfsZendeskWidget(jwtToken);
  }

  public getSingleSignOnToken(userData: UserData): Observable<ZendeskTokenResponseDTO> {
    return this.baseHttp.post<ZendeskTokenResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZendeskSingleSignOnToken), {
      body: ZendeskData.mapToSingleSignOnAuthDTO(userData),
    });
  }

  public getEndUserAuthToken(userData: UserData): Observable<ZendeskTokenResponseDTO> {
    return this.baseHttp.post<ZendeskTokenResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZendeskEndUserAuthToken), {
      body: ZendeskData.mapToEndUserAuthDTO(userData),
    });
  }

  public authenticateToHelpCenter(token: string, link: string): void {
    window.open(ZendeskData.buildHelpCenterLink(token, link), '_blank');
  }

  public createOrUpdateOrganization(userData: UserData): Observable<CreateZendeskOrganization> {
    return this.httpClient.post<CreateZendeskOrganization>(
      externApiUrlMatcher(ApiUrlsEnum.ZendeskCreateOrUpdateOrganization, ApiUrlsEnum.ZendeskApiUrl),
      ZendeskData.mapUserDataToCreateOrUpdateOrganization(userData),
      {
        headers: this.headers,
      },
    );
  }

  public createOrUpdateUser(userData: UserData, organizationZendeskId: number): Observable<CreateZendeskUser> {
    return this.httpClient.post<CreateZendeskUser>(
      externApiUrlMatcher(ApiUrlsEnum.ZendeskCreateOrUpdateUser, ApiUrlsEnum.ZendeskApiUrl),
      ZendeskData.mapUserDataToCreateOrUpdateRequest(userData, organizationZendeskId),
      {
        headers: this.headers,
      },
    );
  }
}
