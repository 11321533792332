import { FormLibraryCreateRequestDTO, FormLibraryDetailsResponseDTO, FormLibraryUpdateRequestDTO } from '../../generated/models/models';
import { FormLocalUpdateRequestDTO } from '../../generated/models/formLocalUpdateRequestDTO';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

import { FormTemplateParamsData } from './form-template-params';
import { DateUtils } from '../../utils/date.utils';
import { FormNatureEnum } from '../../enums';

export interface FormLibraryDetailsData {
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  name: string;
  nature: FormNatureEnum;
  indice: string;
  reference: string;
  labelFamily: EntityLightData;
  applicability: EntityLightData;
  useTemplate: boolean;
  description: string;
  customStatus: CustomStatusLightData;
  createDate: string;
  modificationDate: string;
  templateParams: FormTemplateParamsData;
  additionalComment?: string;
  additionalObservation?: string;
  customStatusChangeComment?: string;
  lockForm: boolean;
  companies?: string[];
}

export namespace FormLibraryDetailsData {
  export function mapFromApiValue(libraryForm: FormLibraryDetailsResponseDTO): FormLibraryDetailsData {
    const templateParams = (libraryForm.templateParams &&
      FormTemplateParamsData.mapFromApiValue(
        libraryForm.templateParams,
        libraryForm.useTemplate,
        libraryForm.hideInExportDsi,
        libraryForm.hideInExportForm,
        libraryForm.hideInExportMultiPr,
        libraryForm.hideInExportPr,
      )) || {
      useTemplate: libraryForm.useTemplate,
      template: null,
      xPagePosition: null,
      yPagePosition: null,
      uuidEntity: null,
    };

    return {
      uuid: libraryForm.uuid,
      uuidType: libraryForm.uuidType,
      uuidEntity: libraryForm.uuidEntity,
      name: libraryForm.name,
      nature: FormNatureEnum.convertFromApiValue.getValue(libraryForm.nature),
      indice: libraryForm.indice,
      reference: libraryForm.reference,
      labelFamily: libraryForm.labelFamily && LabelFamilyLightData.mapToEntityLightData(libraryForm.labelFamily),
      applicability: libraryForm.applicability && LabelApplicabilityLightData.mapToEntityLightData(libraryForm.applicability),
      useTemplate: libraryForm.useTemplate,
      description: libraryForm.description,
      customStatus: libraryForm.customStatus && CustomStatusLightData.mapFromApiValue(libraryForm.customStatus),
      createDate: DateUtils.toDateFormat(libraryForm.createDate),
      modificationDate: DateUtils.toDateFormat(libraryForm.modificationDate),
      templateParams: templateParams,
      additionalComment: libraryForm.additionalComment,
      additionalObservation: libraryForm.additionalObservation,
      lockForm: libraryForm.lockForm,
      companies: (libraryForm.companies || []).map(company => company.identifier),
    };
  }

  export function mapToCreateRequestApiValue(libraryForm: FormLibraryDetailsData): FormLibraryCreateRequestDTO {
    return {
      applicabilityUuidEntity: libraryForm.applicability && libraryForm.applicability.uuidEntity,
      description: libraryForm.description,
      indice: libraryForm.indice,
      labelFamilyUuidEntity: libraryForm.labelFamily && libraryForm.labelFamily.uuidEntity,
      name: libraryForm.name,
      nature: FormNatureEnum.convertToApiValue.getValue(libraryForm.nature),
      reference: libraryForm.reference || undefined,
      customStatusUuidEntity: libraryForm.customStatus?.uuidEntity,
      additionalComment: libraryForm.additionalComment,
      additionalObservation: libraryForm.additionalObservation,
      submitterComment: libraryForm.customStatusChangeComment,
      companyUuidEntities: libraryForm.companies,
    };
  }

  export function mapToUpdateRequestApiValue(libraryForm: FormLibraryDetailsData): FormLibraryUpdateRequestDTO {
    return {
      name: libraryForm.name,
      nature: FormNatureEnum.convertToApiValue.getValue(libraryForm.nature),
      description: libraryForm.description,
      indice: libraryForm.indice,
      reference: libraryForm.reference,
      customStatusUuidEntity: libraryForm.customStatus?.uuidEntity,
      labelFamilyUuidEntity: libraryForm.labelFamily && libraryForm.labelFamily.uuidEntity,
      applicabilityUuidEntity: libraryForm.applicability && libraryForm.applicability.uuidEntity,
      useTemplate: libraryForm.useTemplate,
      uuidEntity: libraryForm.uuidEntity,
      additionalComment: libraryForm.additionalComment,
      additionalObservation: libraryForm.additionalObservation,
      submitterComment: libraryForm.customStatusChangeComment,
      lockForm: libraryForm.lockForm,
      companyUuidEntities: libraryForm.companies,
    };
  }

  export function mapToUpdateLocalRequestApiValue(libraryForm: FormLibraryDetailsData): FormLocalUpdateRequestDTO {
    return {
      name: libraryForm.name,
      nature: FormNatureEnum.convertToApiValue.getValue(libraryForm.nature),
      description: libraryForm.description,
      indice: libraryForm.indice,
      reference: libraryForm.reference,
      customStatusUuidEntity: libraryForm.customStatus?.uuidEntity,
      labelFamilyUuidEntity: libraryForm.labelFamily && libraryForm.labelFamily.uuidEntity,
      applicabilityUuidEntity: libraryForm.applicability && libraryForm.applicability.uuidEntity,
      useTemplate: libraryForm.useTemplate,
      uuidEntity: libraryForm.uuidEntity,
      additionalComment: libraryForm.additionalComment,
      additionalObservation: libraryForm.additionalObservation,
      submitterComment: libraryForm.customStatusChangeComment,
      lockForm: libraryForm.lockForm,
    };
  }
}
