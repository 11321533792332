import { ElementAdrListDetailsResponseDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { PictogramEnum } from '../../enums';

export interface AdrSummaryData {
  uuidEntity: string;
  labelFamilyTeam: LabelFamilyLightData;
  name: string;
  applicability: EntityLightData;
  refInt: string;
  source: string;
  pictogram: PictogramEnum;
  landmark: string;
}

export namespace AdrSummaryData {
  export function mapFromApiValue(list: ElementAdrListDetailsResponseDTO): AdrSummaryData {
    return {
      uuidEntity: list.uuidEntity,
      labelFamilyTeam: list.labelFamily ? LabelFamilyLightData.mapFromApiValue(list.labelFamily) : LabelFamilyLightData.defaultData,
      name: list.risk,
      source: list.riskSource,
      applicability: list.applicability ? LabelApplicabilityLightData.mapToEntityLightData(list.applicability) : LabelApplicabilityLightData.defaultData,
      refInt: list.reference,
      pictogram: PictogramEnum.convertFromApiValue.getValue(list.pictogram),
      landmark: list['landmark'],
    };
  }
}
