import { SfxMap } from '../../utils/enum-utils';
import { DocumentFlowReorganizeCategoryRequestDTO } from '../../generated/models/models';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum DocumentCategoryTypeEnum {
  Principal = 'PRINCIPAL',
  Secondary = 'SECONDARY',
}

export namespace DocumentCategoryTypeEnum {
  const values = [DocumentCategoryTypeEnum.Principal, DocumentCategoryTypeEnum.Secondary];

  export const convertToApiValue: SfxMap<DocumentCategoryTypeEnum, DocumentFlowReorganizeCategoryRequestDTO.CategoryTypeEnum> = new SfxMap<
    DocumentCategoryTypeEnum,
    DocumentFlowReorganizeCategoryRequestDTO.CategoryTypeEnum
  >([
    [DocumentCategoryTypeEnum.Principal, DocumentFlowReorganizeCategoryRequestDTO.CategoryTypeEnum.PRINCIPAL],
    [DocumentCategoryTypeEnum.Secondary, DocumentFlowReorganizeCategoryRequestDTO.CategoryTypeEnum.SECONDARY],
  ]);

  const key = 'document.category.';
  export const toString: SfxMap<DocumentCategoryTypeEnum, string> = new SfxMap<DocumentCategoryTypeEnum, string>([
    [DocumentCategoryTypeEnum.Principal, key + 'principal'],
    [DocumentCategoryTypeEnum.Secondary, key + 'secondary'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<DocumentCategoryTypeEnum>[]> =>
    of(
      values.map(categoryType => ({
        value: categoryType,
        title: toString.getValue(categoryType),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DocumentCategoryTypeEnum>, item: DocumentCategoryTypeEnum) => node.value === item;
}
