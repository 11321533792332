import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';

export enum ClosingFolderActionEnum {
  Add = 1,
  Verify = 2,
  Properties = 3,
  Delete = 4,
}

export namespace ClosingFolderActionEnum {
  const allActions = [ClosingFolderActionEnum.Add, ClosingFolderActionEnum.Verify, ClosingFolderActionEnum.Properties, ClosingFolderActionEnum.Delete];

  const otherActions = [ClosingFolderActionEnum.Properties, ClosingFolderActionEnum.Delete];

  export const toString = new SfxMap<ClosingFolderActionEnum, string>([
    [ClosingFolderActionEnum.Add, 'closingFolder.table.actions.add'],
    [ClosingFolderActionEnum.Verify, 'closingFolder.table.actions.verify'],
    [ClosingFolderActionEnum.Properties, 'closingFolder.table.actions.properties'],
    [ClosingFolderActionEnum.Delete, 'closingFolder.table.actions.delete'],
  ]);

  export const getIcon = new SfxMap<ClosingFolderActionEnum, IconData>([
    [ClosingFolderActionEnum.Add, { name: 'bookmark', prefix: 'fas' }],
    [ClosingFolderActionEnum.Verify, { name: 'archive', prefix: 'fas' }],
    [ClosingFolderActionEnum.Properties, { name: 'info', prefix: 'fas' }],
    [ClosingFolderActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
  ]);

  export function getAllActionMenu(): ActionSelectItemData<ClosingFolderActionEnum>[] {
    return allActions.map(action => mapToActionMenuItem(action, PermissionActionEnum.Edit));
  }

  export function getOtherActions(): ActionSelectItemData<ClosingFolderActionEnum>[] {
    return otherActions.map(action => mapToActionMenuItem(action, PermissionActionEnum.Edit));
  }

  export function mapToActionMenuItem(responseActionMenu: ClosingFolderActionEnum, permissionAction: PermissionActionEnum): ActionSelectItemData<ClosingFolderActionEnum> {
    return {
      name: ClosingFolderActionEnum.toString.getValue(responseActionMenu),
      value: responseActionMenu,
      permissionAction,
      icon: ClosingFolderActionEnum.getIcon.getValue(responseActionMenu),
    };
  }
}
