import { FormStepLinkDTO } from '../../generated/models/models';

export interface FormStepLinkDeleteData {
  stepName: string;
}

export namespace FormStepLinkDeleteData {
  export function mapFromApiValue(formStepLinkDelete: FormStepLinkDTO): FormStepLinkDeleteData {
    return {
      stepName: formStepLinkDelete.stepName,
    };
  }

  export function toString(formStepLink: FormStepLinkDeleteData): string {
    return formStepLink.stepName;
  }
}
