import { ClosingFolderSummaryData } from '../../models';
import { SfxMap } from '../../utils/enum-utils';

export enum ClosingFolderColumnEnum {
  Name = 'name',
  RefIntern = 'refIntern',
  Indice = 'indice',
  Status = 'status',
  DiffusionDate = 'diffusionDate',
  Actions = 'actions',
}

export namespace ClosingFolderColumnEnum {
  export type closingFolderSortField = keyof ClosingFolderSummaryData;

  export const allColumns = [
    ClosingFolderColumnEnum.Name,
    ClosingFolderColumnEnum.RefIntern,
    ClosingFolderColumnEnum.Indice,
    ClosingFolderColumnEnum.Status,
    ClosingFolderColumnEnum.DiffusionDate,
    ClosingFolderColumnEnum.Actions,
  ];

  const key = 'closingFolder.table.header.';
  export const toString: SfxMap<ClosingFolderColumnEnum, string> = new SfxMap<ClosingFolderColumnEnum, string>([
    [ClosingFolderColumnEnum.Name, key + 'name'],
    [ClosingFolderColumnEnum.RefIntern, key + 'refIntern'],
    [ClosingFolderColumnEnum.Indice, key + 'indice'],
    [ClosingFolderColumnEnum.Status, key + 'status'],
    [ClosingFolderColumnEnum.DiffusionDate, key + 'diffusionDate'],
    [ClosingFolderColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ClosingFolderColumnEnum, closingFolderSortField> = new SfxMap<ClosingFolderColumnEnum, closingFolderSortField>([
    [ClosingFolderColumnEnum.Name, 'name'],
  ]);
}
