import { compact } from 'lodash';

import { SharedLinkExternalUserResponseDto, PageExternalApiUserAccessRequestDTO } from '../../generated/models/models';

import { InnerSortDirectionEnum, UserStatusEnum } from '../../enums';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
export interface LibraryExternalUsersSummaryData {
  profession: string;
  companyName: string;
  status: UserStatusEnum;
  name: string;
  uuidEntity: string;
  email: string;
}

export namespace LibraryExternalUsersSummaryData {
  export type QueryRequest = BaseQuery<LibraryExternalUsersSummaryData>;
  const sortFields = ['email', 'name', 'companyName', 'profession'];

  export function mapFromApiValue(data: SharedLinkExternalUserResponseDto): LibraryExternalUsersSummaryData {
    return {
      profession: data._function,
      status: UserStatusEnum.convertFromApiValue.getValue(data.status),
      name: compact([data.firstName, data.lastName]).join(' '),
      email: data.email,
      companyName: data.companyName,
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageExternalApiUserAccessRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, sort: SortFilter<LibraryExternalUsersSummaryData>): QueryRequest {
    return { ...initializeQueries<LibraryExternalUsersSummaryData>(sort), textSearch: searchText || undefined };
  }
}
