/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchedulingEndUpdateRequestDTO {
  condition: SchedulingEndUpdateRequestDTO.ConditionEnum;
  endDate?: Date;
  maxAmount?: number;
}
export namespace SchedulingEndUpdateRequestDTO {
  export type ConditionEnum = 'STATUS' | 'TIME' | 'AMOUNT' | 'NEVER';
  export const ConditionEnum = {
    STATUS: 'STATUS' as ConditionEnum,
    TIME: 'TIME' as ConditionEnum,
    AMOUNT: 'AMOUNT' as ConditionEnum,
    NEVER: 'NEVER' as ConditionEnum,
  };
}
