import { Observable, of } from 'rxjs';
import { SfxMap } from '../../utils/enum-utils';

import { FlowEventDTO } from '../../generated/models/flowEventDTO';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum FlowEventTypeEnum {
  Create = 1,
  ImportContentReferential,
  ImportContentTemplate,
  DetachFbl,
  UpdateFlowLibrary,
  ResetFlow,
  DeleteFlowContentAndImportContent,
  ActivatePhase,
  ActivateStep,
  ActivateForm,
  DeactivatePhase,
  DeactivateStep,
  DeactivateForm,
  DeactivateFormAndImportNewOne,
  DeleteFormAndImportNewOne,
  DeactivateSignature,
}

export namespace FlowEventTypeEnum {
  export const getValues = [
    FlowEventTypeEnum.Create,
    FlowEventTypeEnum.ImportContentReferential,
    FlowEventTypeEnum.ImportContentTemplate,
    FlowEventTypeEnum.DetachFbl,
    FlowEventTypeEnum.UpdateFlowLibrary,
    FlowEventTypeEnum.ResetFlow,
    FlowEventTypeEnum.DeleteFlowContentAndImportContent,
    FlowEventTypeEnum.ActivatePhase,
    FlowEventTypeEnum.ActivateStep,
    FlowEventTypeEnum.ActivateForm,
    FlowEventTypeEnum.DeactivatePhase,
    FlowEventTypeEnum.DeactivateStep,
    FlowEventTypeEnum.DeactivateForm,
    FlowEventTypeEnum.DeactivateFormAndImportNewOne,
    FlowEventTypeEnum.DeleteFormAndImportNewOne,
    FlowEventTypeEnum.DeactivateSignature,
  ];

  export const convertFromApiValue = new SfxMap<FlowEventDTO.FlowEventTypeEnumEnum, FlowEventTypeEnum>([
    [FlowEventDTO.FlowEventTypeEnumEnum.CREATE, FlowEventTypeEnum.Create],
    [FlowEventDTO.FlowEventTypeEnumEnum.IMPORTCONTENTREFERENTIAL, FlowEventTypeEnum.ImportContentReferential],
    [FlowEventDTO.FlowEventTypeEnumEnum.IMPORTCONTENTTEMPLATE, FlowEventTypeEnum.ImportContentTemplate],
    [FlowEventDTO.FlowEventTypeEnumEnum.UPDATELIBRARY, FlowEventTypeEnum.UpdateFlowLibrary],
    [FlowEventDTO.FlowEventTypeEnumEnum.DETACHFBL, FlowEventTypeEnum.DetachFbl],
    [FlowEventDTO.FlowEventTypeEnumEnum.RESETFLOW, FlowEventTypeEnum.ResetFlow],
    [FlowEventDTO.FlowEventTypeEnumEnum.DELETEANDREIMPORTCONTENT, FlowEventTypeEnum.DeleteFlowContentAndImportContent],
    [FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATEPHASE, FlowEventTypeEnum.ActivatePhase],
    [FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATESTEP, FlowEventTypeEnum.ActivateStep],
    [FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATEFORM, FlowEventTypeEnum.ActivateForm],
    [FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEPHASE, FlowEventTypeEnum.DeactivatePhase],
    [FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATESTEP, FlowEventTypeEnum.DeactivateStep],
    [FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEFORM, FlowEventTypeEnum.DeactivateForm],
    [FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEANDLINKNEWFORM, FlowEventTypeEnum.DeactivateFormAndImportNewOne],
    [FlowEventDTO.FlowEventTypeEnumEnum.DETACHANDLINKNEWFORM, FlowEventTypeEnum.DeleteFormAndImportNewOne],
    [FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATESIGNATURE, FlowEventTypeEnum.DeactivateSignature],
  ]);

  export const convertToApiValue = new SfxMap<FlowEventTypeEnum, FlowEventDTO.FlowEventTypeEnumEnum>([
    [FlowEventTypeEnum.Create, FlowEventDTO.FlowEventTypeEnumEnum.CREATE],
    [FlowEventTypeEnum.ImportContentReferential, FlowEventDTO.FlowEventTypeEnumEnum.IMPORTCONTENTREFERENTIAL],
    [FlowEventTypeEnum.ImportContentTemplate, FlowEventDTO.FlowEventTypeEnumEnum.IMPORTCONTENTTEMPLATE],
    [FlowEventTypeEnum.UpdateFlowLibrary, FlowEventDTO.FlowEventTypeEnumEnum.UPDATELIBRARY],
    [FlowEventTypeEnum.DetachFbl, FlowEventDTO.FlowEventTypeEnumEnum.DETACHFBL],
    [FlowEventTypeEnum.ResetFlow, FlowEventDTO.FlowEventTypeEnumEnum.RESETFLOW],
    [FlowEventTypeEnum.DeleteFlowContentAndImportContent, FlowEventDTO.FlowEventTypeEnumEnum.DELETEANDREIMPORTCONTENT],
    [FlowEventTypeEnum.ActivatePhase, FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATEPHASE],
    [FlowEventTypeEnum.ActivateStep, FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATESTEP],
    [FlowEventTypeEnum.ActivateForm, FlowEventDTO.FlowEventTypeEnumEnum.ACTIVATEFORM],
    [FlowEventTypeEnum.DeactivatePhase, FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEPHASE],
    [FlowEventTypeEnum.DeactivateStep, FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATESTEP],
    [FlowEventTypeEnum.DeactivateForm, FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEFORM],
    [FlowEventTypeEnum.DeactivateFormAndImportNewOne, FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATEANDLINKNEWFORM],
    [FlowEventTypeEnum.DeleteFormAndImportNewOne, FlowEventDTO.FlowEventTypeEnumEnum.DETACHANDLINKNEWFORM],
    [FlowEventTypeEnum.DeactivateSignature, FlowEventDTO.FlowEventTypeEnumEnum.DEACTIVATESIGNATURE],
  ]);

  const sentencePrefixKey = 'flowContentNavigation.dialog.history.content.actions.';
  export const getSentence = new SfxMap<FlowEventTypeEnum, string>([
    [FlowEventTypeEnum.Create, sentencePrefixKey + 'create'],
    [FlowEventTypeEnum.ImportContentReferential, sentencePrefixKey + 'importContentReferential'],
    [FlowEventTypeEnum.ImportContentTemplate, sentencePrefixKey + 'importContentTemplate'],
    [FlowEventTypeEnum.UpdateFlowLibrary, sentencePrefixKey + 'updateLibrary'],
    [FlowEventTypeEnum.DetachFbl, sentencePrefixKey + 'detachFbl'],
    [FlowEventTypeEnum.ResetFlow, sentencePrefixKey + 'resetFlow'],
    [FlowEventTypeEnum.DeleteFlowContentAndImportContent, sentencePrefixKey + 'deleteFlowContentAndImportContent'],
    [FlowEventTypeEnum.ActivatePhase, sentencePrefixKey + 'activatePhase'],
    [FlowEventTypeEnum.ActivateStep, sentencePrefixKey + 'activateStep'],
    [FlowEventTypeEnum.ActivateForm, sentencePrefixKey + 'activateForm'],
    [FlowEventTypeEnum.DeactivatePhase, sentencePrefixKey + 'deactivatePhase'],
    [FlowEventTypeEnum.DeactivateStep, sentencePrefixKey + 'deactivateStep'],
    [FlowEventTypeEnum.DeactivateForm, sentencePrefixKey + 'deactivateForm'],
    [FlowEventTypeEnum.DeactivateFormAndImportNewOne, sentencePrefixKey + 'deactivateFormAndImportNewOne'],
    [FlowEventTypeEnum.DeleteFormAndImportNewOne, sentencePrefixKey + 'deleteFormAndImportNewOne'],
    [FlowEventTypeEnum.DeactivateSignature, sentencePrefixKey + 'deactivateSignature'],
  ]);

  const namePrefixKey = 'flowContentNavigation.dialog.history.content.actions-name.';
  export const getName = new SfxMap<FlowEventTypeEnum, string>([
    [FlowEventTypeEnum.Create, namePrefixKey + 'create'],
    [FlowEventTypeEnum.ImportContentReferential, namePrefixKey + 'importContentReferential'],
    [FlowEventTypeEnum.ImportContentTemplate, namePrefixKey + 'importContentTemplate'],
    [FlowEventTypeEnum.UpdateFlowLibrary, namePrefixKey + 'updateLibrary'],
    [FlowEventTypeEnum.DetachFbl, namePrefixKey + 'detachFbl'],
    [FlowEventTypeEnum.ResetFlow, namePrefixKey + 'resetFlow'],
    [FlowEventTypeEnum.DeleteFlowContentAndImportContent, namePrefixKey + 'deleteFlowContentAndImportContent'],
    [FlowEventTypeEnum.ActivatePhase, namePrefixKey + 'activatePhase'],
    [FlowEventTypeEnum.ActivateStep, namePrefixKey + 'activateStep'],
    [FlowEventTypeEnum.ActivateForm, namePrefixKey + 'activateForm'],
    [FlowEventTypeEnum.DeactivatePhase, namePrefixKey + 'deactivatePhase'],
    [FlowEventTypeEnum.DeactivateStep, namePrefixKey + 'deactivateStep'],
    [FlowEventTypeEnum.DeactivateForm, namePrefixKey + 'deactivateForm'],
    [FlowEventTypeEnum.DeactivateFormAndImportNewOne, namePrefixKey + 'deactivateFormAndImportNewOne'],
    [FlowEventTypeEnum.DeleteFormAndImportNewOne, namePrefixKey + 'deleteFormAndImportNewOne'],
    [FlowEventTypeEnum.DeactivateSignature, namePrefixKey + 'deactivateSignature'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<FlowEventTypeEnum>[]> =>
    of(
      getValues.map(eventType => ({
        value: eventType,
        title: getName.getValue(eventType),
      })),
    );

  export const convertToParamsValue = new SfxMap<FlowEventTypeEnum, string>([
    [FlowEventTypeEnum.Create, 'create'],
    [FlowEventTypeEnum.ImportContentReferential, 'importContentReferential'],
    [FlowEventTypeEnum.ImportContentTemplate, 'importContentTemplate'],
    [FlowEventTypeEnum.UpdateFlowLibrary, 'updateLibrary'],
    [FlowEventTypeEnum.DetachFbl, 'detachFbl'],
    [FlowEventTypeEnum.ResetFlow, 'resetFlow'],
    [FlowEventTypeEnum.DeleteFlowContentAndImportContent, 'deleteFlowContentAndImportContent'],
    [FlowEventTypeEnum.ActivatePhase, 'activatePhase'],
    [FlowEventTypeEnum.ActivateStep, 'activateStep'],
    [FlowEventTypeEnum.ActivateForm, 'activateForm'],
    [FlowEventTypeEnum.DeactivatePhase, 'deactivatePhase'],
    [FlowEventTypeEnum.DeactivateStep, 'deactivateStep'],
    [FlowEventTypeEnum.DeactivateForm, 'deactivateForm'],
    [FlowEventTypeEnum.DeactivateFormAndImportNewOne, 'deactivateFormAndImportNewOne'],
    [FlowEventTypeEnum.DeleteFormAndImportNewOne, 'deleteFormAndImportNewOne'],
    [FlowEventTypeEnum.DeactivateSignature, 'deactivateSignature'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, FlowEventTypeEnum>([
    ['create', FlowEventTypeEnum.Create],
    ['importContentReferential', FlowEventTypeEnum.ImportContentReferential],
    ['importContentTemplate', FlowEventTypeEnum.ImportContentTemplate],
    ['updateLibrary', FlowEventTypeEnum.UpdateFlowLibrary],
    ['detachFbl', FlowEventTypeEnum.DetachFbl],
    ['resetFlow', FlowEventTypeEnum.ResetFlow],
    ['deleteFlowContentAndImportContent', FlowEventTypeEnum.DeleteFlowContentAndImportContent],
    ['activatePhase', FlowEventTypeEnum.ActivatePhase],
    ['activateStep', FlowEventTypeEnum.ActivateStep],
    ['activateForm', FlowEventTypeEnum.ActivateForm],
    ['deactivatePhase', FlowEventTypeEnum.DeactivatePhase],
    ['deactivateStep', FlowEventTypeEnum.DeactivateStep],
    ['deactivateForm', FlowEventTypeEnum.DeactivateForm],
    ['deactivateFormAndImportNewOne', FlowEventTypeEnum.DeactivateFormAndImportNewOne],
    ['deleteFormAndImportNewOne', FlowEventTypeEnum.DeleteFormAndImportNewOne],
    ['deactivateSignature', FlowEventTypeEnum.DeactivateSignature],
  ]);
}
