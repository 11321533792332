import { ListsSummaryData } from '../../models/lists/lists-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ListColumnEnum {
  Type = 'type',
  Name = 'name',
  Actions = 'actions',
}

export namespace ListColumnEnum {
  export type listsSortField = keyof ListsSummaryData;

  export const allColumns = [ListColumnEnum.Type, ListColumnEnum.Name, ListColumnEnum.Actions];

  const key = 'lists.table.header.';
  export const toString: SfxMap<ListColumnEnum, string> = new SfxMap<ListColumnEnum, string>([
    [ListColumnEnum.Type, key + 'type'],
    [ListColumnEnum.Name, key + 'name'],
    [ListColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ListColumnEnum, listsSortField> = new SfxMap<ListColumnEnum, listsSortField>([
    [ListColumnEnum.Type, 'typeFlow'],
    [ListColumnEnum.Name, 'name'],
  ]);
}
