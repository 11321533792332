import { OverlayRef } from '@angular/cdk/overlay';
import { takeUntil, skip, filter, tap, first } from 'rxjs/operators';
import { Subject, fromEvent, Observable, Subscription } from 'rxjs';

import { PopoverEventTypeEnum, KeyboardKeyEnum } from '../../../../core/enums';
import { PopoverCloseEvent, PopoverData } from './popover-config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PopoverOverlayRef<T = any, R = any> {
  public afterClosed$ = new Subject<PopoverCloseEvent<R>>();

  private subscriptions: Subscription[] = [];

  constructor(public overlay: OverlayRef, public popoverInitData: PopoverData<T>) {
    if (popoverInitData.hasBackdrop) {
      this.subscriptions.push(
        overlay
          .backdropClick()
          .pipe(takeUntil(this.overlay.detachments()))
          .subscribe(() => {
            this._close(PopoverEventTypeEnum.BackdropClick, null);
          }),
      );
    } else {
      this.subscriptions.push(this.overlayClickOutside(overlay, popoverInitData.origin).subscribe(() => this._close(PopoverEventTypeEnum.BackdropClick, null)));
    }

    this.subscriptions.push(
      overlay
        .keydownEvents()
        .pipe(
          filter(event => event.code === KeyboardKeyEnum.Escape),
          takeUntil(overlay.detachments()),
        )
        .subscribe(() => this._close(PopoverEventTypeEnum.Escape, null)),
    );
  }

  close(popoverCloseData?: R): void {
    if (popoverCloseData && this.popoverInitData.beforeClose$) {
      this.subscriptions.push(
        this.popoverInitData
          .beforeClose$(popoverCloseData)
          .pipe(first())
          .subscribe(success => success && this._close(PopoverEventTypeEnum.Close, popoverCloseData)),
      );
    } else {
      this._close(PopoverEventTypeEnum.Close, popoverCloseData);
    }
  }

  private overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(document, 'click').pipe(
      skip(1),
      filter(event => {
        // INFO: check if popover has a dropdown element, in this case the click is inside
        const target = event.target as HTMLElement;
        let parentElement = target.parentElement;

        while (parentElement) {
          if (parentElement.classList.contains('select-content')) {
            return false;
          }

          parentElement = parentElement.parentElement;
        }

        return target !== origin && !overlayRef.overlayElement.contains(target);
      }),
      tap(event => event.preventDefault()),
      takeUntil(overlayRef.detachments()),
    );
  }

  private _close(type: PopoverCloseEvent['type'], data?: R): void {
    this.overlay.dispose();
    this.afterClosed$.next({ type, data });
    this.afterClosed$.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
