/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateDocumentFlowLibraryRequestDTO {
  name: string;
  labelFamilyUuidEntity: string;
  categoryUuidEntity?: string;
  categorySecondaryUuidEntity?: string;
  refInt?: string;
  refExt?: string;
  complementRefInt?: string;
  complementRefExt?: string;
  natureFile: CreateDocumentFlowLibraryRequestDTO.NatureFileEnum;
  applicabilityUuidEntity: string;
}
export namespace CreateDocumentFlowLibraryRequestDTO {
  export type NatureFileEnum = 'PROJECT' | 'AUTRE';
  export const NatureFileEnum = {
    PROJECT: 'PROJECT' as NatureFileEnum,
    AUTRE: 'AUTRE' as NatureFileEnum,
  };
}
