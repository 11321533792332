import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { DynamicVariableData } from '../../models';
import { SfxMap } from '../../utils/enum-utils';

export enum DynamicVariableEnum {
  ProjectDesignation = 1,
  ProjectCaseNumber,
  ProjectContractNumber,
  ProjectClientName1,
  ProjectClientName2,
  ProjectClientName3,
  ProjectLocation,
  ProjectDescription,
  ProjectSupervision1,
  ProjectSupervision2,
  ProjectSupervision3,
  ProjectInternalOrder,
  ProjectExternalOrder,
  ProjectMarketNumber,
  ProjectTechnicalCase,
  ProjectKeywords,
  ProjectBusinessField,
  ProjectNationalProgram,
  ProjectFamily,
  FlowName,
  FlowDescription,
  FlowFamily,
  FlowCategory,
  FlowStatus,
  FlowSite,
  FlowUnit,
  FlowLocal,
  FlowElementarySystem,
  FlowFunctionalLandmark,
  FlowTechnicalObject,
  FlowChrono,
  FlowEpsilonFolderRef,
  FlowReporter,
  FlowResponsible,
  FlowTablet1,
  FlowTablet2,
  FlowTablet3,
  FlowStartDate,
  FlowEndDate,
  FlowGeometryCoordinateAlt,
  FlowGeometryCoordinateLat,
  FlowGeometryCoordinateLng,
  CurrentPhaseNumber,
  CurrentPhaseName,
  CurrentPhaseDescription,
  CurrentStepNumber,
  CurrentStepName,
  CurrentStepDescription,
  ResponseReference,
  CurrentCollaborator,
  ProjectInternalSupervision1,
  ProjectInternalSupervision2,
  ProjectInternalSupervision3,
  ExternalCollaboratorLastName,
  ExternalCollaboratorFirstName,
  ExternalCollaboratorCompany,
  ExternalCollaboratorJob,
  FlowInternalSupervision1,
  FlowInternalSupervision2,
  FlowInternalSupervision3,
  FlowExternalSupervision1,
  FlowExternalSupervision2,
  FlowExternalSupervision3,
  AdditionalStatementPhase,
  AdditionalStatementStep,
  AdditionalStatementForm,
}

export namespace DynamicVariableEnum {
  export const projectVariables: DynamicVariableEnum[] = [
    DynamicVariableEnum.ProjectDesignation,
    DynamicVariableEnum.ProjectCaseNumber,
    DynamicVariableEnum.ProjectContractNumber,
    DynamicVariableEnum.ProjectClientName1,
    DynamicVariableEnum.ProjectClientName2,
    DynamicVariableEnum.ProjectClientName3,
    DynamicVariableEnum.ProjectLocation,
    DynamicVariableEnum.ProjectDescription,
    DynamicVariableEnum.ProjectInternalSupervision1,
    DynamicVariableEnum.ProjectInternalSupervision2,
    DynamicVariableEnum.ProjectInternalSupervision3,
    DynamicVariableEnum.ProjectSupervision1,
    DynamicVariableEnum.ProjectSupervision2,
    DynamicVariableEnum.ProjectSupervision3,
    DynamicVariableEnum.ProjectInternalOrder,
    DynamicVariableEnum.ProjectExternalOrder,
    DynamicVariableEnum.ProjectMarketNumber,
    DynamicVariableEnum.ProjectTechnicalCase,
    DynamicVariableEnum.ProjectKeywords,
    DynamicVariableEnum.ProjectBusinessField,
    DynamicVariableEnum.ProjectNationalProgram,
    DynamicVariableEnum.ProjectFamily,
  ];

  export const flowVariables: DynamicVariableEnum[] = [
    DynamicVariableEnum.FlowName,
    DynamicVariableEnum.FlowDescription,
    DynamicVariableEnum.FlowFamily,
    DynamicVariableEnum.FlowCategory,
    DynamicVariableEnum.FlowStatus,
    DynamicVariableEnum.FlowSite,
    DynamicVariableEnum.FlowUnit,
    DynamicVariableEnum.FlowLocal,
    DynamicVariableEnum.FlowElementarySystem,
    DynamicVariableEnum.FlowFunctionalLandmark,
    DynamicVariableEnum.FlowTechnicalObject,
    DynamicVariableEnum.FlowChrono,
    DynamicVariableEnum.FlowEpsilonFolderRef,
    DynamicVariableEnum.FlowReporter,
    DynamicVariableEnum.FlowResponsible,
    DynamicVariableEnum.FlowTablet1,
    DynamicVariableEnum.FlowTablet2,
    DynamicVariableEnum.FlowTablet3,
    DynamicVariableEnum.FlowStartDate,
    DynamicVariableEnum.FlowEndDate,
    DynamicVariableEnum.FlowGeometryCoordinateAlt,
    DynamicVariableEnum.FlowGeometryCoordinateLat,
    DynamicVariableEnum.FlowGeometryCoordinateLng,
    DynamicVariableEnum.CurrentPhaseNumber,
    DynamicVariableEnum.CurrentPhaseName,
    DynamicVariableEnum.CurrentPhaseDescription,
    DynamicVariableEnum.CurrentStepNumber,
    DynamicVariableEnum.CurrentStepName,
    DynamicVariableEnum.CurrentStepDescription,
    DynamicVariableEnum.FlowInternalSupervision1,
    DynamicVariableEnum.FlowInternalSupervision2,
    DynamicVariableEnum.FlowInternalSupervision3,
    DynamicVariableEnum.FlowExternalSupervision1,
    DynamicVariableEnum.FlowExternalSupervision2,
    DynamicVariableEnum.FlowExternalSupervision3,
    DynamicVariableEnum.AdditionalStatementPhase,
    DynamicVariableEnum.AdditionalStatementStep,
  ];

  export const formVariables: DynamicVariableEnum[] = [
    DynamicVariableEnum.ResponseReference,
    DynamicVariableEnum.CurrentCollaborator,
    DynamicVariableEnum.ExternalCollaboratorLastName,
    DynamicVariableEnum.ExternalCollaboratorFirstName,
    DynamicVariableEnum.ExternalCollaboratorCompany,
    DynamicVariableEnum.ExternalCollaboratorJob,
    DynamicVariableEnum.AdditionalStatementForm,
  ];

  const values: DynamicVariableEnum[] = [...projectVariables, ...flowVariables, ...formVariables];

  const key = 'questions.dynamicVariable.variables.';
  export const toString: SfxMap<DynamicVariableEnum, string> = new SfxMap<DynamicVariableEnum, string>([
    [DynamicVariableEnum.ProjectDesignation, key + 'projectDesignation'],
    [DynamicVariableEnum.ProjectCaseNumber, key + 'projectCaseNumber'],
    [DynamicVariableEnum.ProjectContractNumber, key + 'projectContractNumber'],
    [DynamicVariableEnum.ProjectClientName1, key + 'projectClientName1'],
    [DynamicVariableEnum.ProjectClientName2, key + 'projectClientName2'],
    [DynamicVariableEnum.ProjectClientName3, key + 'projectClientName3'],
    [DynamicVariableEnum.ProjectLocation, key + 'projectLocation'],
    [DynamicVariableEnum.ProjectDescription, key + 'projectDescription'],
    [DynamicVariableEnum.ProjectSupervision1, key + 'projectSupervision1'],
    [DynamicVariableEnum.ProjectSupervision2, key + 'projectSupervision2'],
    [DynamicVariableEnum.ProjectSupervision3, key + 'projectSupervision3'],
    [DynamicVariableEnum.ProjectInternalSupervision1, key + 'projectInternalSupervision1'],
    [DynamicVariableEnum.ProjectInternalSupervision2, key + 'projectInternalSupervision2'],
    [DynamicVariableEnum.ProjectInternalSupervision3, key + 'projectInternalSupervision3'],
    [DynamicVariableEnum.ProjectInternalOrder, key + 'projectInternalOrder'],
    [DynamicVariableEnum.ProjectExternalOrder, key + 'projectExternalOrder'],
    [DynamicVariableEnum.ProjectMarketNumber, key + 'projectMarketNumber'],
    [DynamicVariableEnum.ProjectTechnicalCase, key + 'projectTechnicalCase'],
    [DynamicVariableEnum.ProjectKeywords, key + 'projectKeywords'],
    [DynamicVariableEnum.ProjectBusinessField, key + 'projectBusinessField'],
    [DynamicVariableEnum.ProjectNationalProgram, key + 'projectNationalProgram'],
    [DynamicVariableEnum.ProjectFamily, key + 'projectFamily'],
    [DynamicVariableEnum.FlowName, key + 'flowName'],
    [DynamicVariableEnum.FlowDescription, key + 'flowDescription'],
    [DynamicVariableEnum.FlowFamily, key + 'flowFamily'],
    [DynamicVariableEnum.FlowCategory, key + 'flowCategory'],
    [DynamicVariableEnum.FlowStatus, key + 'flowStatus'],
    [DynamicVariableEnum.FlowSite, key + 'flowSite'],
    [DynamicVariableEnum.FlowUnit, key + 'flowUnit'],
    [DynamicVariableEnum.FlowLocal, key + 'flowLocal'],
    [DynamicVariableEnum.FlowElementarySystem, key + 'flowElementarySystem'],
    [DynamicVariableEnum.FlowFunctionalLandmark, key + 'flowFunctionalLandmark'],
    [DynamicVariableEnum.FlowTechnicalObject, key + 'flowTechnicalObject'],
    [DynamicVariableEnum.FlowChrono, key + 'flowChrono'],
    [DynamicVariableEnum.FlowEpsilonFolderRef, key + 'flowEpsilonFolderRef'],
    [DynamicVariableEnum.FlowReporter, key + 'flowReporter'],
    [DynamicVariableEnum.FlowResponsible, key + 'flowResponsible'],
    [DynamicVariableEnum.FlowTablet1, key + 'flowTablet1'],
    [DynamicVariableEnum.FlowTablet2, key + 'flowTablet2'],
    [DynamicVariableEnum.FlowTablet3, key + 'flowTablet3'],
    [DynamicVariableEnum.FlowStartDate, key + 'flowStartDate'],
    [DynamicVariableEnum.FlowEndDate, key + 'flowEndDate'],
    [DynamicVariableEnum.FlowGeometryCoordinateAlt, key + 'flowGeometryCoordinateAlt'],
    [DynamicVariableEnum.FlowGeometryCoordinateLat, key + 'flowGeometryCoordinateLat'],
    [DynamicVariableEnum.FlowGeometryCoordinateLng, key + 'flowGeometryCoordinateLng'],
    [DynamicVariableEnum.CurrentPhaseNumber, key + 'currentPhaseNumber'],
    [DynamicVariableEnum.CurrentPhaseName, key + 'currentPhaseName'],
    [DynamicVariableEnum.CurrentPhaseDescription, key + 'currentPhaseDescription'],
    [DynamicVariableEnum.CurrentStepNumber, key + 'currentStepNumber'],
    [DynamicVariableEnum.CurrentStepName, key + 'currentStepName'],
    [DynamicVariableEnum.CurrentStepDescription, key + 'currentStepDescription'],
    [DynamicVariableEnum.ResponseReference, key + 'responseReference'],
    [DynamicVariableEnum.CurrentCollaborator, key + 'currentCollaborator'],
    [DynamicVariableEnum.ExternalCollaboratorLastName, key + 'externalCollaboratorLastName'],
    [DynamicVariableEnum.ExternalCollaboratorFirstName, key + 'externalCollaboratorFirstName'],
    [DynamicVariableEnum.ExternalCollaboratorCompany, key + 'externalCollaboratorCompany'],
    [DynamicVariableEnum.ExternalCollaboratorJob, key + 'externalCollaboratorJob'],
    [DynamicVariableEnum.FlowInternalSupervision1, key + 'flowInternalSupervision1'],
    [DynamicVariableEnum.FlowInternalSupervision2, key + 'flowInternalSupervision2'],
    [DynamicVariableEnum.FlowInternalSupervision3, key + 'flowInternalSupervision3'],
    [DynamicVariableEnum.FlowExternalSupervision1, key + 'flowExternalSupervision1'],
    [DynamicVariableEnum.FlowExternalSupervision2, key + 'flowExternalSupervision2'],
    [DynamicVariableEnum.FlowExternalSupervision3, key + 'flowExternalSupervision3'],
    [DynamicVariableEnum.AdditionalStatementPhase, key + 'additionalStatementPhase'],
    [DynamicVariableEnum.AdditionalStatementStep, key + 'additionalStatementStep'],
    [DynamicVariableEnum.AdditionalStatementForm, key + 'additionalStatementForm'],
  ]);

  const shortLabelKey = 'questions.dynamicVariable.variables.shortLabels.';
  export const toShortString: SfxMap<DynamicVariableEnum, string> = new SfxMap<DynamicVariableEnum, string>([
    [DynamicVariableEnum.ProjectDesignation, shortLabelKey + 'name'],
    [DynamicVariableEnum.ProjectCaseNumber, shortLabelKey + 'projectCaseNumber'],
    [DynamicVariableEnum.ProjectContractNumber, shortLabelKey + 'projectContractNumber'],
    [DynamicVariableEnum.ProjectClientName1, shortLabelKey + 'projectClientName1'],
    [DynamicVariableEnum.ProjectClientName2, shortLabelKey + 'projectClientName2'],
    [DynamicVariableEnum.ProjectClientName3, shortLabelKey + 'projectClientName3'],
    [DynamicVariableEnum.ProjectLocation, shortLabelKey + 'projectLocation'],
    [DynamicVariableEnum.ProjectDescription, shortLabelKey + 'description'],
    [DynamicVariableEnum.ProjectSupervision1, shortLabelKey + 'projectSupervision1'],
    [DynamicVariableEnum.ProjectSupervision2, shortLabelKey + 'projectSupervision2'],
    [DynamicVariableEnum.ProjectSupervision3, shortLabelKey + 'projectSupervision3'],
    [DynamicVariableEnum.ProjectInternalSupervision1, shortLabelKey + 'projectInternalSupervision1'],
    [DynamicVariableEnum.ProjectInternalSupervision2, shortLabelKey + 'projectInternalSupervision2'],
    [DynamicVariableEnum.ProjectInternalSupervision3, shortLabelKey + 'projectInternalSupervision3'],
    [DynamicVariableEnum.ProjectInternalOrder, shortLabelKey + 'projectInternalOrder'],
    [DynamicVariableEnum.ProjectExternalOrder, shortLabelKey + 'projectExternalOrder'],
    [DynamicVariableEnum.ProjectMarketNumber, shortLabelKey + 'projectMarketNumber'],
    [DynamicVariableEnum.ProjectTechnicalCase, shortLabelKey + 'projectTechnicalCase'],
    [DynamicVariableEnum.ProjectKeywords, shortLabelKey + 'projectKeywords'],
    [DynamicVariableEnum.ProjectBusinessField, shortLabelKey + 'projectBusinessField'],
    [DynamicVariableEnum.ProjectNationalProgram, shortLabelKey + 'projectNationalProgram'],
    [DynamicVariableEnum.ProjectFamily, shortLabelKey + 'family'],
    [DynamicVariableEnum.FlowName, shortLabelKey + 'name'],
    [DynamicVariableEnum.FlowDescription, shortLabelKey + 'description'],
    [DynamicVariableEnum.FlowFamily, shortLabelKey + 'family'],
    [DynamicVariableEnum.FlowCategory, shortLabelKey + 'category'],
    [DynamicVariableEnum.FlowStatus, shortLabelKey + 'status'],
    [DynamicVariableEnum.FlowSite, shortLabelKey + 'flowSite'],
    [DynamicVariableEnum.FlowUnit, shortLabelKey + 'flowUnit'],
    [DynamicVariableEnum.FlowLocal, shortLabelKey + 'flowLocal'],
    [DynamicVariableEnum.FlowElementarySystem, shortLabelKey + 'flowElementarySystem'],
    [DynamicVariableEnum.FlowFunctionalLandmark, shortLabelKey + 'flowFunctionalLandmark'],
    [DynamicVariableEnum.FlowTechnicalObject, shortLabelKey + 'flowTechnicalObject'],
    [DynamicVariableEnum.FlowChrono, shortLabelKey + 'flowChrono'],
    [DynamicVariableEnum.FlowEpsilonFolderRef, shortLabelKey + 'flowEpsilonFolderRef'],
    [DynamicVariableEnum.FlowReporter, shortLabelKey + 'flowReporter'],
    [DynamicVariableEnum.FlowResponsible, shortLabelKey + 'flowResponsible'],
    [DynamicVariableEnum.FlowTablet1, shortLabelKey + 'flowTablet1'],
    [DynamicVariableEnum.FlowTablet2, shortLabelKey + 'flowTablet2'],
    [DynamicVariableEnum.FlowTablet3, shortLabelKey + 'flowTablet3'],
    [DynamicVariableEnum.FlowStartDate, shortLabelKey + 'flowStartDate'],
    [DynamicVariableEnum.FlowEndDate, shortLabelKey + 'flowEndDate'],
    [DynamicVariableEnum.FlowGeometryCoordinateAlt, shortLabelKey + 'flowGeometryCoordinateAlt'],
    [DynamicVariableEnum.FlowGeometryCoordinateLat, shortLabelKey + 'flowGeometryCoordinateLat'],
    [DynamicVariableEnum.FlowGeometryCoordinateLng, shortLabelKey + 'flowGeometryCoordinateLng'],
    [DynamicVariableEnum.CurrentPhaseNumber, shortLabelKey + 'currentPhaseNumber'],
    [DynamicVariableEnum.CurrentPhaseName, shortLabelKey + 'currentPhaseName'],
    [DynamicVariableEnum.CurrentPhaseDescription, shortLabelKey + 'currentPhaseDescription'],
    [DynamicVariableEnum.CurrentStepNumber, shortLabelKey + 'currentStepNumber'],
    [DynamicVariableEnum.CurrentStepName, shortLabelKey + 'currentStepName'],
    [DynamicVariableEnum.CurrentStepDescription, shortLabelKey + 'currentStepDescription'],
    [DynamicVariableEnum.ResponseReference, shortLabelKey + 'responseReference'],
    [DynamicVariableEnum.CurrentCollaborator, shortLabelKey + 'currentCollaborator'],
    [DynamicVariableEnum.ExternalCollaboratorLastName, shortLabelKey + 'externalCollaboratorLastName'],
    [DynamicVariableEnum.ExternalCollaboratorFirstName, shortLabelKey + 'externalCollaboratorFirstName'],
    [DynamicVariableEnum.ExternalCollaboratorCompany, shortLabelKey + 'externalCollaboratorCompany'],
    [DynamicVariableEnum.ExternalCollaboratorJob, shortLabelKey + 'externalCollaboratorJob'],
    [DynamicVariableEnum.FlowInternalSupervision1, shortLabelKey + 'flowInternalSupervision1'],
    [DynamicVariableEnum.FlowInternalSupervision2, shortLabelKey + 'flowInternalSupervision2'],
    [DynamicVariableEnum.FlowInternalSupervision3, shortLabelKey + 'flowInternalSupervision3'],
    [DynamicVariableEnum.FlowExternalSupervision1, shortLabelKey + 'flowExternalSupervision1'],
    [DynamicVariableEnum.FlowExternalSupervision2, shortLabelKey + 'flowExternalSupervision2'],
    [DynamicVariableEnum.FlowExternalSupervision3, shortLabelKey + 'flowExternalSupervision3'],
    [DynamicVariableEnum.AdditionalStatementPhase, shortLabelKey + 'additionalStatementPhase'],
    [DynamicVariableEnum.AdditionalStatementStep, shortLabelKey + 'additionalStatementStep'],
    [DynamicVariableEnum.AdditionalStatementForm, shortLabelKey + 'additionalStatementForm'],
  ]);

  export const convertToApiValue: SfxMap<DynamicVariableEnum, string> = new SfxMap<DynamicVariableEnum, string>([
    [DynamicVariableEnum.ProjectDesignation, '@@ProjectDesignation'],
    [DynamicVariableEnum.ProjectCaseNumber, '@@ProjectNoCase'],
    [DynamicVariableEnum.ProjectContractNumber, '@@ProjectNoContract'],
    [DynamicVariableEnum.ProjectClientName1, '@@ProjectClientName1'],
    [DynamicVariableEnum.ProjectClientName2, '@@ProjectClientName2'],
    [DynamicVariableEnum.ProjectClientName3, '@@ProjectClientName3'],
    [DynamicVariableEnum.ProjectLocation, '@@ProjectLocation'],
    [DynamicVariableEnum.ProjectDescription, '@@ProjectDescription'],
    [DynamicVariableEnum.ProjectSupervision1, '@@ProjectSupervision1'],
    [DynamicVariableEnum.ProjectSupervision2, '@@ProjectSupervision2'],
    [DynamicVariableEnum.ProjectSupervision3, '@@ProjectSupervision3'],
    [DynamicVariableEnum.ProjectInternalSupervision1, '@@ProjectInternalSupervision1'],
    [DynamicVariableEnum.ProjectInternalSupervision2, '@@ProjectInternalSupervision2'],
    [DynamicVariableEnum.ProjectInternalSupervision3, '@@ProjectInternalSupervision3'],
    [DynamicVariableEnum.ProjectInternalOrder, '@@ProjectInternalOrder'],
    [DynamicVariableEnum.ProjectExternalOrder, '@@ProjectExternalOrder'],
    [DynamicVariableEnum.ProjectMarketNumber, '@@ProjectNoMarket'],
    [DynamicVariableEnum.ProjectTechnicalCase, '@@ProjectTechnicalCase'],
    [DynamicVariableEnum.ProjectKeywords, '@@ProjectKeywords'],
    [DynamicVariableEnum.ProjectBusinessField, '@@ProjectBusinessField'],
    [DynamicVariableEnum.ProjectNationalProgram, '@@ProjectNationalProgram'],
    [DynamicVariableEnum.ProjectFamily, '@@ProjectFamily'],
    [DynamicVariableEnum.FlowName, '@@FlowName'],
    [DynamicVariableEnum.FlowDescription, '@@FlowDescription'],
    [DynamicVariableEnum.FlowFamily, '@@FlowFamily'],
    [DynamicVariableEnum.FlowCategory, '@@FlowCategory'],
    [DynamicVariableEnum.FlowStatus, '@@FlowStatus'],
    [DynamicVariableEnum.FlowSite, '@@FlowSite'],
    [DynamicVariableEnum.FlowUnit, '@@FlowUnit'],
    [DynamicVariableEnum.FlowLocal, '@@FlowLocal'],
    [DynamicVariableEnum.FlowElementarySystem, '@@FlowElementarySystem'],
    [DynamicVariableEnum.FlowFunctionalLandmark, '@@FlowFunctionalLandmark'],
    [DynamicVariableEnum.FlowTechnicalObject, '@@FlowTechnicalObject'],
    [DynamicVariableEnum.FlowChrono, '@@FlowChrono'],
    [DynamicVariableEnum.FlowEpsilonFolderRef, '@@FlowEpsilonFolderRef'],
    [DynamicVariableEnum.FlowReporter, '@@FlowRapporteur'],
    [DynamicVariableEnum.FlowResponsible, '@@FlowResponsable'],
    [DynamicVariableEnum.FlowTablet1, '@@FlowTablette1'],
    [DynamicVariableEnum.FlowTablet2, '@@FlowTablette2'],
    [DynamicVariableEnum.FlowTablet3, '@@FlowTablette3'],
    [DynamicVariableEnum.FlowStartDate, '@@FlowDateDebut'],
    [DynamicVariableEnum.FlowEndDate, '@@FlowDateFin'],
    [DynamicVariableEnum.FlowGeometryCoordinateAlt, '@@FlowGeometryCoordinateAlt'],
    [DynamicVariableEnum.FlowGeometryCoordinateLat, '@@FlowGeometryCoordinateLat'],
    [DynamicVariableEnum.FlowGeometryCoordinateLng, '@@FlowGeometryCoordinateLng'],
    [DynamicVariableEnum.CurrentPhaseNumber, '@@CurrPhaseNumber'],
    [DynamicVariableEnum.CurrentPhaseName, '@@CurrPhaseName'],
    [DynamicVariableEnum.CurrentPhaseDescription, '@@CurrPhaseDescription'],
    [DynamicVariableEnum.CurrentStepNumber, '@@CurrStepNumber'],
    [DynamicVariableEnum.CurrentStepName, '@@CurrStepName'],
    [DynamicVariableEnum.CurrentStepDescription, '@@CurrStepDescription'],
    [DynamicVariableEnum.ResponseReference, '@@refResponse'],
    [DynamicVariableEnum.CurrentCollaborator, '@@currentCollaborator'],
    [DynamicVariableEnum.ExternalCollaboratorLastName, '@@externalCollaboratorLastName'],
    [DynamicVariableEnum.ExternalCollaboratorFirstName, '@@externalCollaboratorFirstName'],
    [DynamicVariableEnum.ExternalCollaboratorCompany, '@@externalCollaboratorCompany'],
    [DynamicVariableEnum.ExternalCollaboratorJob, '@@externalCollaboratorJob'],
    [DynamicVariableEnum.FlowInternalSupervision1, '@@FlowInternalSupervision1'],
    [DynamicVariableEnum.FlowInternalSupervision2, '@@FlowInternalSupervision2'],
    [DynamicVariableEnum.FlowInternalSupervision3, '@@FlowInternalSupervision3'],
    [DynamicVariableEnum.FlowExternalSupervision1, '@@FlowExternalSupervision1'],
    [DynamicVariableEnum.FlowExternalSupervision2, '@@FlowExternalSupervision2'],
    [DynamicVariableEnum.FlowExternalSupervision3, '@@FlowExternalSupervision3'],
    [DynamicVariableEnum.AdditionalStatementPhase, '@@AdditionalStatementPhase'],
    [DynamicVariableEnum.AdditionalStatementStep, '@@AdditionalStatementStep'],
    [DynamicVariableEnum.AdditionalStatementForm, '@@AdditionalStatementForm'],
  ]);

  export const selectItems: MultiSelectItemData[] = values.map(variable => ({ value: convertToApiValue.getValue(variable), title: toString.getValue(variable) }));

  export function mapToDynamicVariableData(variables: DynamicVariableEnum[]): DynamicVariableData[] {
    return variables.map((variable, index) => ({
      uuidEntity: index + '',
      name: DynamicVariableEnum.toShortString.getValue(variable),
      variableName: DynamicVariableEnum.convertToApiValue.getValue(variable),
    }));
  }
}
