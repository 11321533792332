import { CategorySummaryData } from '../../models/category/category-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum DefaultCategoriesColumnEnum {
  Name = 'name',
  Actions = 'action',
}

export namespace DefaultCategoriesColumnEnum {
  export type categoriesSortField = keyof CategorySummaryData;

  export const allColumns = [DefaultCategoriesColumnEnum.Name, DefaultCategoriesColumnEnum.Actions];

  const key = 'categories.table.header.';
  export const toString: SfxMap<DefaultCategoriesColumnEnum, string> = new SfxMap<DefaultCategoriesColumnEnum, string>([
    [DefaultCategoriesColumnEnum.Name, key + 'name'],
    [DefaultCategoriesColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<DefaultCategoriesColumnEnum, categoriesSortField> = new SfxMap<DefaultCategoriesColumnEnum, categoriesSortField>([
    [DefaultCategoriesColumnEnum.Name, 'name'],
  ]);
}
