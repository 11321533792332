import { EntityLightData } from '../utils/entity-light-data';
import { RiskReorganizeTypeEnum } from '../../enums';
import { RiskReorganizeRequestDTO } from '../../generated/models/models';
import { RiskSummaryData } from './risk-summary-data';

export interface RiskReorganizeData {
  allSelected: boolean;
  risksUuidEntity: string[];
  reorganize: EntityLightData;
  type: RiskReorganizeTypeEnum;
  queries: RiskSummaryData.QueryRequest;
}

export namespace RiskReorganizeData {
  export function mapToRequestApiValue(riskReorganize: RiskReorganizeData): RiskReorganizeRequestDTO {
    return {
      risksUuidEntity: !riskReorganize.allSelected ? riskReorganize.risksUuidEntity || [] : undefined,
      familyUuidEntity: RiskReorganizeTypeEnum.Family === riskReorganize.type && riskReorganize.reorganize && riskReorganize.reorganize.uuidEntity,
      pageRiskRequest: riskReorganize.allSelected && riskReorganize.queries ? RiskSummaryData.mapToPageRequestApiValue(riskReorganize.queries) : undefined,
    };
  }
}
