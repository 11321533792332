/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrganizationConfigResponseDTO {
  allowedDownloadResponse: number;
  blockFormResponseEdition: boolean;
  diagramEnabled: boolean;
  linkExpirationDelayInDays: number;
  lmsTrainingUrl: string;
  localFormAllowed: boolean;
  mapViewDisabled: boolean;
  numberFormatEnum: OrganizationConfigResponseDTO.NumberFormatEnumEnum;
  rubricPermissionsEnabled: boolean;
  tabletPositionDisabled: boolean;
  uuidOrganization: string;
  zendeskEnabled: boolean;
}
export namespace OrganizationConfigResponseDTO {
  export type NumberFormatEnumEnum = 'FR' | 'EN';
  export const NumberFormatEnumEnum = {
    FR: 'FR' as NumberFormatEnumEnum,
    EN: 'EN' as NumberFormatEnumEnum,
  };
}
