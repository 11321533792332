import { UpdateProjectTeamRoleRequest, PageUserRequest } from '../../generated/models/models';
import { UserNatureEnum, UserRoleEnum, InnerSortDirectionEnum } from '../../enums';
import { ContributorImportData } from './contributor-import-data';

export interface ContributorForImport {
  teamRole: UserRoleEnum;
  allSelected?: boolean;
  queries?: ContributorImportData.QueryRequest;
  uuides?: string[];
}

export namespace ContributorForImport {
  export function mapToRequestApiValue(request: ContributorForImport): UpdateProjectTeamRoleRequest {
    return {
      uuides: !request.allSelected ? request.uuides || [] : undefined,
      defaultTeamRole: UserRoleEnum.convertToApiValue.getValue(request.teamRole),
      pageUserRequest: request.allSelected ? request.queries && mapToPageRequestApiValue(request.queries) : undefined,
    };
  }

  function mapToPageRequestApiValue(queries: ContributorImportData.QueryRequest): PageUserRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      userNatures: (queries.userNatures && queries.userNatures.map(nature => UserNatureEnum.convertToApiValue.getValue(nature))) || [],
    };
  }
}
