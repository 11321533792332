import { EpsilonOutCreateRequestDTO, EpsilonOutDetailsResponseDTO, EpsilonOutUpdateRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { PgacEpsilonAfEnum } from '../../enums/project/pgac-epsilonaf.enum';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface FlowPgacDetails {
  caloAlu?: boolean;
  caloArmaflex?: boolean;
  caloArretClipses?: string;
  caloArretEncapsules?: string;
  caloArretVisses?: string;
  caloBridesClipsees?: string;
  caloBridesEncapsulees?: string;
  caloBridesVissees?: string;
  caloCiment?: boolean;
  caloClassique?: boolean;
  caloCoquille?: boolean;
  caloCoudeClipses?: string;
  caloCoudeEncapsulees?: string;
  caloCoudeVisses?: string;
  caloDateSignatureForm?: string;
  caloDddPrevisionnel?: string;
  caloDddReel?: string;
  caloDemontable?: boolean;
  caloDeposeRepose?: boolean;
  caloDeposeUniquement?: boolean;
  caloDle?: boolean;
  caloDoseCollectivePrevisionnel?: string;
  caloDoseCollectiveReel?: string;
  caloDoseIndividuelPrevisionnel?: string;
  caloDoseIndividuelReelle?: string;
  caloEh?: boolean;
  caloEhCsp?: boolean;
  caloGalva?: boolean;
  caloHzc?: boolean;
  caloInox?: boolean;
  caloNappe?: boolean;
  caloPiquagesClipses?: string;
  caloPiquagesEncapsulees?: string;
  caloPiquagesVisses?: string;
  caloReductionsClipsees?: string;
  caloReductionsEncapsulees?: string;
  caloReductionsVissees?: string;
  caloReleveAppareilDiametreAppareil?: string;
  caloReleveAppareilEpaisseurCouche1?: string;
  caloReleveAppareilEpaisseurCouche2?: string;
  caloReleveAppareilNombreFondBallon?: string;
  caloReleveAppareilSurAppareil?: string;
  caloReleveAppareilVirole?: string;
  caloReleveDiametre?: string;
  caloReleveEpaisseurCouche1?: string;
  caloReleveEpaisseurCouche2?: string;
  caloRelevePartieDroite?: string;
  caloReposeUniquement?: boolean;
  caloStyrodur?: boolean;
  caloSupportsClipses?: string;
  caloSupportsEncapsules?: string;
  caloSupportsVisses?: string;
  caloTelisol?: boolean;
  caloVannesClipsees?: string;
  caloVannesEncapsulees?: string;
  caloVannesVissees?: string;
  caloZc?: boolean;
  deleted?: boolean;
  description?: string;
  doublon?: boolean;
  doublonComment?: string;
  echafAccesDifficile?: boolean;
  echafDateReception?: string;
  echafDddPReel?: string;
  echafDddPrevisionnel?: string;
  echafDle?: boolean;
  echafDoseCollectivePrevisionnel?: string;
  echafDoseCollectiveReel?: string;
  echafDoseIndividuelPrevisionnel?: string;
  echafDoseIndividuelReelle?: string;
  echafHauteur?: string;
  echafHzc?: boolean;
  echafInterventionEnCapacite?: boolean;
  echafLargeur?: string;
  echafLongueur?: string;
  echafPlancherAcces?: string;
  echafPlancherAjuste?: boolean;
  echafPlancherAlternateur?: boolean;
  echafPlancherCondenseur?: boolean;
  echafPlancherDeTravail?: string;
  echafSansObjet?: boolean;
  echafSuspendu?: boolean;
  echafSuspenduAvecPlancherAjuste?: boolean;
  echafBacheGta?: boolean;
  echafCordiste?: boolean;
  echafZc?: boolean;
  echafInterventionFondPiscine?: boolean;
  echafSapineLongeur1?: string;
  echafSapineLongeur2?: string;
  echafSapineLongeur3?: string;
  echafSapineLongeur4?: string;
  echafSapineLargeur1?: string;
  echafSapineLargeur2?: string;
  echafSapineLargeur3?: string;
  echafSapineLargeur4?: string;
  echafSapineHauteur1?: string;
  echafSapineHauteur2?: string;
  echafSapineHauteur3?: string;
  echafSapineHauteur4?: string;
  echafSapineNombre1?: string;
  echafSapineNombre2?: string;
  echafSapineNombre3?: string;
  echafSapineNombre4?: string;
  echafSapineVolume1?: string;
  echafSapineVolume2?: string;
  echafSapineVolume3?: string;
  echafSapineVolume4?: string;
  epsilonA?: string;
  epsilonAa?: string;
  epsilonAb?: string;
  epsilonAc?: string;
  epsilonAd?: string;
  epsilonAe?: string;
  epsilonAf?: PgacEpsilonAfEnum;
  epsilonAg?: string;
  epsilonAh?: string;
  epsilonAi?: string;
  epsilonAj?: string;
  epsilonAk?: string;
  epsilonAl?: string;
  epsilonAm?: string;
  epsilonAn?: string;
  epsilonAo?: string;
  epsilonAp?: string;
  epsilonAq?: number;
  epsilonAr?: string;
  epsilonAs?: string;
  epsilonAt?: number;
  epsilonAu?: string;
  epsilonAv?: string;
  epsilonAw?: string;
  epsilonAx?: number;
  epsilonAy?: string;
  epsilonAz?: number;
  epsilonB?: string;
  epsilonBa?: string;
  epsilonBb?: boolean;
  epsilonBc?: boolean;
  epsilonBd?: boolean;
  epsilonBe?: boolean;
  epsilonBf?: string;
  epsilonC?: string;
  epsilonD?: string;
  epsilonE?: string;
  epsilonF?: string;
  epsilonG?: number;
  epsilonH?: number;
  epsilonI?: number;
  epsilonJ?: number;
  epsilonK?: string;
  epsilonL?: string;
  epsilonM?: string;
  epsilonN?: string;
  epsilonO?: string;
  epsilonP?: string;
  epsilonQ?: string;
  epsilonR?: string;
  epsilonS?: string;
  epsilonT?: string;
  epsilonU?: number;
  epsilonV?: string;
  epsilonW?: string;
  epsilonX?: string;
  epsilonY?: number;
  epsilonZ?: string;
  epsilonFd?: string;
  epsilonFf?: string;
  epsilonEf?: string;
  inEpsilonAa?: string;
  inEpsilonAe?: string;
  inEpsilonAf?: string;
  inEpsilonAg?: string;
  inEpsilonAh?: string;
  inEpsilonAi?: boolean;
  inEpsilonAo?: string;
  inEpsilonAp?: string;
  inEpsilonAs?: string;
  inEpsilonAv?: string;
  inEpsilonBb?: string;
  inEpsilonBc?: string;
  inEpsilonBd?: string;
  inEpsilonBe?: string;
  inEpsilonBf?: string;
  inEpsilonBg?: string;
  inEpsilonBh?: string;
  inEpsilonD?: string;
  inEpsilonDq?: boolean;
  inEpsilonEh?: string;
  inEpsilonFc?: string;
  inEpsilonFe?: string;
  inEpsilonFh?: string;
  inEpsilonH?: string;
  inEpsilonR?: boolean;
  mdsAccesDifficile?: boolean;
  mdsCapacite?: boolean;
  mdsDle?: boolean;
  mdsHauteur?: string;
  mdsLargeur?: string;
  mdsLongueur?: string;
  mdsSansObjet?: boolean;
  mdsZc?: boolean;
  mdsNacelle?: boolean;
  mdsPir?: boolean;
  mdsEchelle?: boolean;
  mdsEdf?: boolean;
  name?: string;
  ouvrageAccesDifficile?: boolean;
  ouvrageCapacite?: boolean;
  ouvrageDateReception?: string;
  ouvrageDle?: boolean;
  ouvrageHauteur?: string;
  ouvrageHzc?: boolean;
  ouvrageLargeur?: string;
  ouvrageLongueur?: string;
  ouvrageSansObjet?: boolean;
  ouvrageZc?: boolean;
  ouvrageEtabli?: boolean;
  ouvrageGardeCorps?: boolean;
  ouvragePassageCables?: boolean;
  ouvragePotence?: boolean;
  ouvrageStructureSAS?: boolean;
  ouvrageSupportProbio?: boolean;
  ouvrageRambardeCaillebotisCharpenteTole?: boolean;
  rambardeDddPrevisionnel?: string;
  rambardeDddReel?: string;
  rambardeDoseCollectivePrevisionnel?: string;
  rambardeDoseCollectiveReel?: string;
  rambardeDoseIndividuelPrevisionnel?: string;
  rambardeDoseIndividuelReelle?: string;
  refFlow?: string;
  statusEpsilon?: EpsilonOutDetailsResponseDTO.StatusEpsilonEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  version?: string;
  // deport largeur (1 - > 4)
  deport1Largeur: string;
  deport2Largeur: string;
  deport3Largeur: string;
  deport4Largeur: string;

  // deport longeur (1 - > 4)
  deport1Longueur: string;
  deport2Longueur: string;
  deport3Longueur: string;
  deport4Longueur: string;
  // deport Nombre (1 - > 4)
  deport1Nombre: string;
  deport2Nombre: string;
  deport3Nombre: string;
  deport4Nombre: string;

  // deport m3 (1 - > 4)
  deport1M3: string;
  deport2M3: string;
  deport3M3: string;
  deport4M3: string;
  deportsM3Total: string;
  echafSansDeportM3Total: string;
  echafAvecDeportM3Total: string;
  marchePied: boolean;
}

export namespace FlowPgacDetails {
  export function mapFromApiValue(data: EpsilonOutDetailsResponseDTO): FlowPgacDetails {
    return {
      caloAlu: data.caloAlu,
      caloArmaflex: data.caloArmaflex,
      caloArretClipses: data.caloArretClipses,
      caloArretEncapsules: data.caloArretEncapsules,
      caloArretVisses: data.caloArretVisses,
      caloBridesClipsees: data.caloBridesClipsees,
      caloBridesEncapsulees: data.caloBridesEncapsulees,
      caloBridesVissees: data.caloBridesVissees,
      caloCiment: data.caloCiment,
      caloClassique: data.caloClassique,
      caloCoquille: data.caloCoquille,
      caloCoudeClipses: data.caloCoudeClipses,
      caloCoudeEncapsulees: data.caloCoudeEncapsulees,
      caloCoudeVisses: data.caloCoudeVisses,
      caloDateSignatureForm: data.caloDateSignatureForm,
      caloDddPrevisionnel: data.caloDddPrevisionnel,
      caloDddReel: data.caloDddReel,
      caloDemontable: data.caloDemontable,
      caloDeposeRepose: data.caloDeposeRepose,
      caloDeposeUniquement: data.caloDeposeUniquement,
      caloDle: data.caloDle,
      caloDoseCollectivePrevisionnel: data.caloDoseCollectivePrevisionnel,
      caloDoseCollectiveReel: data.caloDoseCollectiveReel,
      caloDoseIndividuelPrevisionnel: data.caloDoseIndividuelPrevisionnel,
      caloDoseIndividuelReelle: data.caloDoseIndividuelReelle,
      caloEh: data.caloEh,
      caloEhCsp: data.caloEhCsp,
      caloGalva: data.caloGalva,
      caloHzc: data.caloHzc,
      caloInox: data.caloInox,
      caloNappe: data.caloNappe,
      caloPiquagesClipses: data.caloPiquagesClipses,
      caloPiquagesEncapsulees: data.caloPiquagesEncapsulees,
      caloPiquagesVisses: data.caloPiquagesVisses,
      caloReductionsClipsees: data.caloReductionsClipsees,
      caloReductionsEncapsulees: data.caloReductionsEncapsulees,
      caloReductionsVissees: data.caloReductionsVissees,
      caloReleveAppareilDiametreAppareil: data.caloReleveAppareilDiametreAppareil,
      caloReleveAppareilEpaisseurCouche1: data.caloReleveAppareilEpaisseurCouche1,
      caloReleveAppareilEpaisseurCouche2: data.caloReleveAppareilEpaisseurCouche2,
      caloReleveAppareilNombreFondBallon: data.caloReleveAppareilNombreFondBallon,
      caloReleveAppareilSurAppareil: data.caloReleveAppareilSurAppareil,
      caloReleveAppareilVirole: data.caloReleveAppareilVirole,
      caloReleveDiametre: data.caloReleveDiametre,
      caloReleveEpaisseurCouche1: data.caloReleveEpaisseurCouche1,
      caloReleveEpaisseurCouche2: data.caloReleveEpaisseurCouche2,
      caloRelevePartieDroite: data.caloRelevePartieDroite,
      caloReposeUniquement: data.caloReposeUniquement,
      caloStyrodur: data.caloStyrodur,
      caloSupportsClipses: data.caloSupportsClipses,
      caloSupportsEncapsules: data.caloSupportsEncapsules,
      caloSupportsVisses: data.caloSupportsVisses,
      caloTelisol: data.caloTelisol,
      caloVannesClipsees: data.caloVannesClipsees,
      caloVannesEncapsulees: data.caloVannesEncapsulees,
      caloVannesVissees: data.caloVannesVissees,
      caloZc: data.caloZc,
      deleted: data.deleted,
      description: data.description,
      doublon: data.doublon,
      doublonComment: data.doublonComment,
      echafAccesDifficile: data.echafAccesDifficile,
      echafDateReception: data.echafDateReception,
      echafDddPReel: data.echafDddPReel,
      echafDddPrevisionnel: data.echafDddPrevisionnel,
      echafDle: data.echafDle,
      echafDoseCollectivePrevisionnel: data.echafDoseCollectivePrevisionnel,
      echafDoseCollectiveReel: data.echafDoseCollectiveReel,
      echafDoseIndividuelPrevisionnel: data.echafDoseIndividuelPrevisionnel,
      echafDoseIndividuelReelle: data.echafDoseIndividuelReelle,
      echafHauteur: data.echafHauteur,
      echafHzc: data.echafHzc,
      echafInterventionEnCapacite: data.echafInterventionEnCapacite,
      echafLargeur: data.echafLargeur,
      echafLongueur: data.echafLongueur,
      echafPlancherAcces: data.echafPlancherAcces,
      echafPlancherAjuste: data.echafPlancherAjuste,
      echafPlancherAlternateur: data.echafPlancherAlternateur,
      echafPlancherCondenseur: data.echafPlancherCondenseur,
      echafPlancherDeTravail: data.echafPlancherDeTravail,
      echafSansObjet: data.echafSansObjet,
      echafSuspendu: data.echafSuspendu,
      echafSuspenduAvecPlancherAjuste: data.echafSuspenduAvecPlancherAjuste,
      echafZc: data.echafZc,
      echafBacheGta: data.echafBacheGta,
      echafCordiste: data.echafCordiste,
      echafInterventionFondPiscine: data.echafInterventionFondPiscine,
      echafSapineLongeur1: data.echafSapineLongeur1,
      echafSapineLongeur2: data.echafSapineLongeur2,
      echafSapineLongeur3: data.echafSapineLongeur3,
      echafSapineLongeur4: data.echafSapineLongeur4,
      echafSapineLargeur1: data.echafSapineLargeur1,
      echafSapineLargeur2: data.echafSapineLargeur2,
      echafSapineLargeur3: data.echafSapineLargeur3,
      echafSapineLargeur4: data.echafSapineLargeur4,
      echafSapineHauteur1: data.echafSapineHauteur1,
      echafSapineHauteur2: data.echafSapineHauteur2,
      echafSapineHauteur3: data.echafSapineHauteur3,
      echafSapineHauteur4: data.echafSapineHauteur4,
      echafSapineNombre1: data.echafSapineNombre1,
      echafSapineNombre2: data.echafSapineNombre2,
      echafSapineNombre3: data.echafSapineNombre3,
      echafSapineNombre4: data.echafSapineNombre4,
      echafSapineVolume1: data.echafSapineVolume1,
      echafSapineVolume2: data.echafSapineVolume2,
      echafSapineVolume3: data.echafSapineVolume3,
      echafSapineVolume4: data.echafSapineVolume4,
      epsilonA: data.epsilonA,
      epsilonAa: data.epsilonAa,
      epsilonAb: data.epsilonAb,
      epsilonAc: data.epsilonAc,
      epsilonAd: data.epsilonAd,
      epsilonAe: data.epsilonAe,
      epsilonAf: PgacEpsilonAfEnum.convertFromApiValue.getValue(data.epsilonAf),
      epsilonAg: data.epsilonAg,
      epsilonAh: data.epsilonAh,
      epsilonAi: data.epsilonAi,
      epsilonAj: data.epsilonAj,
      epsilonAk: data.epsilonAk,
      epsilonAl: data.epsilonAl,
      epsilonAm: data.epsilonAm,
      epsilonAn: data.epsilonAn,
      epsilonAo: data.epsilonAo,
      epsilonAp: data.epsilonAp,
      epsilonAq: data.epsilonAq,
      epsilonAr: data.epsilonAr,
      epsilonAs: data.epsilonAs,
      epsilonAt: data.epsilonAt,
      epsilonAu: data.epsilonAu,
      epsilonAv: data.epsilonAv,
      epsilonAw: data.epsilonAw,
      epsilonAx: data.epsilonAx,
      epsilonAy: data.epsilonAy,
      epsilonAz: data.epsilonAz,
      epsilonB: DateUtils.toCalendarFormat(data.epsilonB, true),
      epsilonBa: data.epsilonBa,
      epsilonBb: data.epsilonBb,
      epsilonBc: data.epsilonBc,
      epsilonBd: data.epsilonBd,
      epsilonBe: data.epsilonBe,
      epsilonBf: DateUtils.toCalendarFormat(data.epsilonBf, true),
      epsilonC: DateUtils.toCalendarFormat(data.epsilonC, true),
      epsilonD: DateUtils.toCalendarFormat(data.epsilonD, true),
      epsilonE: DateUtils.toCalendarFormat(data.epsilonE, true),
      epsilonF: data.epsilonF,
      epsilonG: data.epsilonG,
      epsilonH: data.epsilonH,
      epsilonI: data.epsilonI,
      epsilonJ: data.epsilonJ,
      epsilonK: data.epsilonK,
      epsilonL: data.epsilonL,
      epsilonM: data.epsilonM,
      epsilonN: data.epsilonN,
      epsilonO: data.epsilonO,
      epsilonP: data.epsilonP,
      epsilonQ: data.epsilonQ,
      epsilonR: data.epsilonR,
      epsilonS: (data.epsilonS || '').toLowerCase(),
      epsilonT: data.epsilonT,
      epsilonU: data.epsilonU,
      epsilonV: data.epsilonV,
      epsilonW: data.epsilonW,
      epsilonX: data.epsilonX,
      epsilonY: data.epsilonY,
      epsilonZ: data.epsilonZ,
      epsilonEf: DateUtils.toCalendarFormat(data.epsilonEf, true),
      epsilonFd: data.epsilonFd,
      epsilonFf: data.epsilonFf,
      inEpsilonAa: data.inEpsilonAa,
      inEpsilonAe: data.inEpsilonAe,
      inEpsilonAf: data.inEpsilonAf,
      inEpsilonAg: data.inEpsilonAg,
      inEpsilonAh: data.inEpsilonAh,
      inEpsilonAi: data.inEpsilonAi,
      inEpsilonAo: data.inEpsilonAo,
      inEpsilonAp: data.inEpsilonAp,
      inEpsilonAs: data.inEpsilonAs,
      inEpsilonAv: data.inEpsilonAv,
      inEpsilonBb: data.inEpsilonBb,
      inEpsilonBc: data.inEpsilonBc,
      inEpsilonBd: data.inEpsilonBd,
      inEpsilonBe: data.inEpsilonBe,
      inEpsilonBf: data.inEpsilonBf,
      inEpsilonBg: data.inEpsilonBg,
      inEpsilonBh: data.inEpsilonBh,
      inEpsilonD: DateUtils.toCalendarFormat(data.inEpsilonD, true),
      inEpsilonDq: data.inEpsilonDq,
      inEpsilonEh: data.inEpsilonEh,
      inEpsilonFc: data.inEpsilonFc,
      inEpsilonFe: data.inEpsilonFe,
      inEpsilonFh: data.inEpsilonFh,
      inEpsilonH: DateUtils.toCalendarFormat(data.inEpsilonH, true),
      inEpsilonR: data.inEpsilonR,
      mdsAccesDifficile: data.mdsAccesDifficile,
      mdsCapacite: data.mdsCapacite,
      mdsDle: data.mdsDle,
      mdsHauteur: data.mdsHauteur,
      mdsLargeur: data.mdsLargeur,
      mdsLongueur: data.mdsLongueur,
      mdsSansObjet: data.mdsSansObjet,
      mdsZc: data.mdsZc,
      mdsPir: data.mdsPir,
      mdsEchelle: data.mdsEchelle,
      mdsEdf: data.mdsEdf,
      mdsNacelle: data.mdsNacelle,
      name: data.name,
      ouvrageAccesDifficile: data.ouvrageAccesDifficile,
      ouvrageCapacite: data.ouvrageCapacite,
      ouvrageDateReception: data.ouvrageDateReception,
      ouvrageDle: data.ouvrageDle,
      ouvrageHauteur: data.ouvrageHauteur,
      ouvrageHzc: data.ouvrageHzc,
      ouvrageLargeur: data.ouvrageLargeur,
      ouvrageLongueur: data.ouvrageLongueur,
      ouvrageSansObjet: data.ouvrageSansObjet,
      ouvrageZc: data.ouvrageZc,
      ouvragePassageCables: data.ouvragePassageCables,
      ouvrageEtabli: data.ouvrageEtabli,
      ouvrageGardeCorps: data.ouvrageGardeCorps,
      ouvrageSupportProbio: data.ouvrageSupportProbio,
      ouvrageStructureSAS: data.ouvrageStructureSAS,
      ouvragePotence: data.ouvragePotence,
      ouvrageRambardeCaillebotisCharpenteTole: data.ouvrageRambardeCaillebotisCharpenteTole,
      rambardeDddPrevisionnel: data.rambardeDddPrevisionnel,
      rambardeDddReel: data.rambardeDddReel,
      rambardeDoseCollectivePrevisionnel: data.rambardeDoseCollectivePrevisionnel,
      rambardeDoseCollectiveReel: data.rambardeDoseCollectiveReel,
      rambardeDoseIndividuelPrevisionnel: data.rambardeDoseIndividuelPrevisionnel,
      rambardeDoseIndividuelReelle: data.rambardeDoseIndividuelReelle,
      refFlow: data.refFlow,
      statusEpsilon: data.statusEpsilon,
      uuid: data.uuid,
      uuidEntity: data.uuidEntity,
      uuidOrganization: data.uuidOrganization,
      uuidType: data.uuidType,
      version: data.version,
      // deport largeur (1 - > 4)
      deport1Largeur: data.deport1Largeur,
      deport2Largeur: data.deport2Largeur,
      deport3Largeur: data.deport3Largeur,
      deport4Largeur: data.deport4Largeur,

      // deport longeur (1 - > 4)
      deport1Longueur: data.deport1Longueur,
      deport2Longueur: data.deport2Longueur,
      deport3Longueur: data.deport3Longueur,
      deport4Longueur: data.deport4Longueur,
      // deport Nombre (1 - > 4)
      deport1Nombre: data.deport1Nombre,
      deport2Nombre: data.deport2Nombre,
      deport3Nombre: data.deport3Nombre,
      deport4Nombre: data.deport4Nombre,

      // deport m3 (1 - > 4)
      deport1M3: data.deport1M3,
      deport2M3: data.deport2M3,
      deport3M3: data.deport3M3,
      deport4M3: data.deport4M3,
      deportsM3Total: data.deportsM3Total,
      echafSansDeportM3Total: data.echafSansDeportM3Total,
      echafAvecDeportM3Total: data.echafAvecDeportM3Total,
      marchePied: data.marchePied,
    };
  }

  export function mapToCreateRequestApiValue(data: FlowPgacDetails, uuidEntity: string, uuideProject: string): EpsilonOutCreateRequestDTO {
    return {
      caloAlu: data.caloAlu,
      caloArmaflex: data.caloArmaflex,
      caloArretClipses: data.caloArretClipses,
      caloArretEncapsules: data.caloArretEncapsules,
      caloArretVisses: data.caloArretVisses,
      caloBridesClipsees: data.caloBridesClipsees,
      caloBridesEncapsulees: data.caloBridesEncapsulees,
      caloBridesVissees: data.caloBridesVissees,
      caloCiment: data.caloCiment,
      caloClassique: data.caloClassique,
      caloCoquille: data.caloCoquille,
      caloCoudeClipses: data.caloCoudeClipses,
      caloCoudeEncapsulees: data.caloCoudeEncapsulees,
      caloCoudeVisses: data.caloCoudeVisses,
      caloDateSignatureForm: data.caloDateSignatureForm,
      caloDddPrevisionnel: data.caloDddPrevisionnel,
      caloDddReel: data.caloDddReel,
      caloDemontable: data.caloDemontable,
      caloDeposeRepose: data.caloDeposeRepose,
      caloDeposeUniquement: data.caloDeposeUniquement,
      caloDle: data.caloDle,
      caloDoseCollectivePrevisionnel: data.caloDoseCollectivePrevisionnel,
      caloDoseCollectiveReel: data.caloDoseCollectiveReel,
      caloDoseIndividuelPrevisionnel: data.caloDoseIndividuelPrevisionnel,
      caloDoseIndividuelReelle: data.caloDoseIndividuelReelle,
      caloEh: data.caloEh,
      caloEhCsp: data.caloEhCsp,
      caloGalva: data.caloGalva,
      caloHzc: data.caloHzc,
      caloInox: data.caloInox,
      caloNappe: data.caloNappe,
      caloPiquagesClipses: data.caloPiquagesClipses,
      caloPiquagesEncapsulees: data.caloPiquagesEncapsulees,
      caloPiquagesVisses: data.caloPiquagesVisses,
      caloReductionsClipsees: data.caloReductionsClipsees,
      caloReductionsEncapsulees: data.caloReductionsEncapsulees,
      caloReductionsVissees: data.caloReductionsVissees,
      caloReleveAppareilDiametreAppareil: data.caloReleveAppareilDiametreAppareil,
      caloReleveAppareilEpaisseurCouche1: data.caloReleveAppareilEpaisseurCouche1,
      caloReleveAppareilEpaisseurCouche2: data.caloReleveAppareilEpaisseurCouche2,
      caloReleveAppareilNombreFondBallon: data.caloReleveAppareilNombreFondBallon,
      caloReleveAppareilSurAppareil: data.caloReleveAppareilSurAppareil,
      caloReleveAppareilVirole: data.caloReleveAppareilVirole,
      caloReleveDiametre: data.caloReleveDiametre,
      caloReleveEpaisseurCouche1: data.caloReleveEpaisseurCouche1,
      caloReleveEpaisseurCouche2: data.caloReleveEpaisseurCouche2,
      caloRelevePartieDroite: data.caloRelevePartieDroite,
      caloReposeUniquement: data.caloReposeUniquement,
      caloStyrodur: data.caloStyrodur,
      caloSupportsClipses: data.caloSupportsClipses,
      caloSupportsEncapsules: data.caloSupportsEncapsules,
      caloSupportsVisses: data.caloSupportsVisses,
      caloTelisol: data.caloTelisol,
      caloVannesClipsees: data.caloVannesClipsees,
      caloVannesEncapsulees: data.caloVannesEncapsulees,
      caloVannesVissees: data.caloVannesVissees,
      caloZc: data.caloZc,
      deleted: data.deleted,
      description: data.description,
      doublon: data.doublon,
      doublonComment: data.doublonComment,
      echafAccesDifficile: data.echafAccesDifficile,
      echafDateReception: data.echafDateReception,
      echafDddPReel: data.echafDddPReel,
      echafDddPrevisionnel: data.echafDddPrevisionnel,
      echafDle: data.echafDle,
      echafDoseCollectivePrevisionnel: data.echafDoseCollectivePrevisionnel,
      echafDoseCollectiveReel: data.echafDoseCollectiveReel,
      echafDoseIndividuelPrevisionnel: data.echafDoseIndividuelPrevisionnel,
      echafDoseIndividuelReelle: data.echafDoseIndividuelReelle,
      echafHauteur: data.echafHauteur,
      echafHzc: data.echafHzc,
      echafInterventionEnCapacite: data.echafInterventionEnCapacite,
      echafLargeur: data.echafLargeur,
      echafLongueur: data.echafLongueur,
      echafPlancherAcces: data.echafPlancherAcces,
      echafPlancherAjuste: data.echafPlancherAjuste,
      echafPlancherAlternateur: data.echafPlancherAlternateur,
      echafPlancherCondenseur: data.echafPlancherCondenseur,
      echafPlancherDeTravail: data.echafPlancherDeTravail,
      echafSansObjet: data.echafSansObjet,
      echafSuspendu: data.echafSuspendu,
      echafSuspenduAvecPlancherAjuste: data.echafSuspenduAvecPlancherAjuste,
      echafBacheGta: data.echafBacheGta,
      echafCordiste: data.echafCordiste,
      echafZc: data.echafZc,
      echafInterventionFondPiscine: data.echafInterventionFondPiscine,
      echafSapineLongeur1: data.echafSapineLongeur1,
      echafSapineLongeur2: data.echafSapineLongeur2,
      echafSapineLongeur3: data.echafSapineLongeur3,
      echafSapineLongeur4: data.echafSapineLongeur4,
      echafSapineLargeur1: data.echafSapineLargeur1,
      echafSapineLargeur2: data.echafSapineLargeur2,
      echafSapineLargeur3: data.echafSapineLargeur3,
      echafSapineLargeur4: data.echafSapineLargeur4,
      echafSapineHauteur1: data.echafSapineHauteur1,
      echafSapineHauteur2: data.echafSapineHauteur2,
      echafSapineHauteur3: data.echafSapineHauteur3,
      echafSapineHauteur4: data.echafSapineHauteur4,
      echafSapineNombre1: data.echafSapineNombre1,
      echafSapineNombre2: data.echafSapineNombre2,
      echafSapineNombre3: data.echafSapineNombre3,
      echafSapineNombre4: data.echafSapineNombre4,
      echafSapineVolume1: data.echafSapineVolume1,
      echafSapineVolume2: data.echafSapineVolume2,
      echafSapineVolume3: data.echafSapineVolume3,
      echafSapineVolume4: data.echafSapineVolume4,
      epsilonA: data.epsilonA,
      epsilonAa: data.epsilonAa,
      epsilonAb: data.epsilonAb,
      epsilonAc: data.epsilonAc,
      epsilonAd: data.epsilonAd,
      epsilonAe: data.epsilonAe,
      epsilonAf: PgacEpsilonAfEnum.convertToApiValue.getValue(data.epsilonAf),
      epsilonAg: data.epsilonAg,
      epsilonAh: data.epsilonAh,
      epsilonAi: data.epsilonAi,
      epsilonAj: data.epsilonAj,
      epsilonAk: data.epsilonAk,
      epsilonAl: data.epsilonAl,
      epsilonAm: data.epsilonAm,
      epsilonAn: data.epsilonAn,
      epsilonAo: data.epsilonAo,
      epsilonAp: data.epsilonAp,
      epsilonAq: data.epsilonAq,
      epsilonAr: data.epsilonAr,
      epsilonAs: data.epsilonAs,
      epsilonAt: data.epsilonAt,
      epsilonAu: data.epsilonAu,
      epsilonAv: data.epsilonAv,
      epsilonAw: data.epsilonAw,
      epsilonAx: data.epsilonAx,
      epsilonAy: data.epsilonAy,
      epsilonAz: data.epsilonAz,
      epsilonB: (data.epsilonB && DateUtils.toLocalDateTime(data.epsilonB)) || undefined,
      epsilonBa: data.epsilonBa,
      epsilonBb: data.epsilonBb,
      epsilonBc: data.epsilonBc,
      epsilonBd: data.epsilonBd,
      epsilonBe: data.epsilonBe,
      epsilonBf: (data.epsilonBf && DateUtils.toLocalDateTime(data.epsilonBf)) || undefined,
      epsilonC: (data.epsilonC && DateUtils.toLocalDateTime(data.epsilonC)) || undefined,
      epsilonD: (data.epsilonD && DateUtils.toLocalDateTime(data.epsilonD)) || undefined,
      epsilonE: (data.epsilonE && DateUtils.toLocalDateTime(data.epsilonE)) || undefined,
      epsilonF: data.epsilonF,
      epsilonG: data.epsilonG,
      epsilonH: data.epsilonH,
      epsilonI: data.epsilonI,
      epsilonJ: data.epsilonJ,
      epsilonK: data.epsilonK,
      epsilonL: data.epsilonL,
      epsilonM: data.epsilonM,
      epsilonN: data.epsilonN,
      epsilonO: data.epsilonO,
      epsilonP: data.epsilonP,
      epsilonQ: data.epsilonQ,
      epsilonR: data.epsilonR,
      epsilonS: data.epsilonS,
      epsilonT: data.epsilonT,
      epsilonU: data.epsilonU,
      epsilonV: data.epsilonV,
      epsilonW: data.epsilonW,
      epsilonX: data.epsilonX,
      epsilonY: data.epsilonY,
      epsilonZ: data.epsilonZ,
      epsilonEf: (data.epsilonEf && DateUtils.toLocalDateTime(data.epsilonEf)) || undefined,
      epsilonFd: data.epsilonFd,
      epsilonFf: data.epsilonFf,
      inEpsilonAa: data.inEpsilonAa,
      inEpsilonAe: data.inEpsilonAe,
      inEpsilonAf: data.inEpsilonAf,
      inEpsilonAg: data.inEpsilonAg,
      inEpsilonAh: data.inEpsilonAh,
      inEpsilonAi: data.inEpsilonAi,
      inEpsilonAo: data.inEpsilonAo,
      inEpsilonAp: data.inEpsilonAp,
      inEpsilonAs: data.inEpsilonAs,
      inEpsilonAv: data.inEpsilonAv,
      inEpsilonBb: data.inEpsilonBb,
      inEpsilonBc: data.inEpsilonBc,
      inEpsilonBd: data.inEpsilonBd,
      inEpsilonBe: data.inEpsilonBe,
      inEpsilonBf: data.inEpsilonBf,
      inEpsilonBg: data.inEpsilonBg,
      inEpsilonBh: data.inEpsilonBh,
      inEpsilonD: (data.inEpsilonD && DateUtils.toLocalDateTime(data.inEpsilonD)) || undefined,
      inEpsilonDq: data.inEpsilonDq,
      inEpsilonEh: data.inEpsilonEh,
      inEpsilonFc: data.inEpsilonFc,
      inEpsilonFe: data.inEpsilonFe,
      inEpsilonFh: data.inEpsilonFh,
      inEpsilonH: (data.inEpsilonH && DateUtils.toLocalDateTime(data.inEpsilonH)) || undefined,
      inEpsilonR: data.inEpsilonR,
      mdsAccesDifficile: data.mdsAccesDifficile,
      mdsCapacite: data.mdsCapacite,
      mdsDle: data.mdsDle,
      mdsHauteur: data.mdsHauteur,
      mdsLargeur: data.mdsLargeur,
      mdsLongueur: data.mdsLongueur,
      mdsSansObjet: data.mdsSansObjet,
      mdsZc: data.mdsZc,
      mdsPir: data.mdsPir,
      mdsEchelle: data.mdsEchelle,
      mdsNacelle: data.mdsNacelle,
      mdsEdf: data.mdsEdf,
      name: data.name,
      ouvrageAccesDifficile: data.ouvrageAccesDifficile,
      ouvrageCapacite: data.ouvrageCapacite,
      ouvrageDateReception: data.ouvrageDateReception,
      ouvrageDle: data.ouvrageDle,
      ouvrageHauteur: data.ouvrageHauteur,
      ouvrageHzc: data.ouvrageHzc,
      ouvrageLargeur: data.ouvrageLargeur,
      ouvrageLongueur: data.ouvrageLongueur,
      ouvrageSansObjet: data.ouvrageSansObjet,
      ouvrageZc: data.ouvrageZc,
      ouvragePassageCables: data.ouvragePassageCables,
      ouvrageEtabli: data.ouvrageEtabli,
      ouvrageGardeCorps: data.ouvrageGardeCorps,
      ouvragePotence: data.ouvragePotence,
      ouvrageStructureSAS: data.ouvrageStructureSAS,
      ouvrageSupportProbio: data.ouvrageSupportProbio,
      ouvrageRambardeCaillebotisCharpenteTole: data.ouvrageRambardeCaillebotisCharpenteTole,
      rambardeDddPrevisionnel: data.rambardeDddPrevisionnel,
      rambardeDddReel: data.rambardeDddReel,
      rambardeDoseCollectivePrevisionnel: data.rambardeDoseCollectivePrevisionnel,
      rambardeDoseCollectiveReel: data.rambardeDoseCollectiveReel,
      rambardeDoseIndividuelPrevisionnel: data.rambardeDoseIndividuelPrevisionnel,
      rambardeDoseIndividuelReelle: data.rambardeDoseIndividuelReelle,
      refFlow: uuidEntity,
      statusEpsilon: data.statusEpsilon,
      uuideProject,
      version: data.version,
      // deport largeur (1 - > 4)
      deport1Largeur: data.deport1Largeur,
      deport2Largeur: data.deport2Largeur,
      deport3Largeur: data.deport3Largeur,
      deport4Largeur: data.deport4Largeur,

      // deport longeur (1 - > 4)
      deport1Longueur: data.deport1Longueur,
      deport2Longueur: data.deport2Longueur,
      deport3Longueur: data.deport3Longueur,
      deport4Longueur: data.deport4Longueur,
      // deport Nombre (1 - > 4)
      deport1Nombre: data.deport1Nombre,
      deport2Nombre: data.deport2Nombre,
      deport3Nombre: data.deport3Nombre,
      deport4Nombre: data.deport4Nombre,

      // deport m3 (1 - > 4)
      deport1M3: data.deport1M3,
      deport2M3: data.deport2M3,
      deport3M3: data.deport3M3,
      deport4M3: data.deport4M3,
      deportsM3Total: data.deportsM3Total,
      echafSansDeportM3Total: data.echafSansDeportM3Total,
      echafAvecDeportM3Total: data.echafAvecDeportM3Total,
      marchePied: data.marchePied,
    };
  }

  export function mapToUpdateRequestApiValue(data: FlowPgacDetails, uuideProject: string): EpsilonOutUpdateRequestDTO {
    return {
      caloAlu: data.caloAlu,
      caloArmaflex: data.caloArmaflex,
      caloArretClipses: data.caloArretClipses,
      caloArretEncapsules: data.caloArretEncapsules,
      caloArretVisses: data.caloArretVisses,
      caloBridesClipsees: data.caloBridesClipsees,
      caloBridesEncapsulees: data.caloBridesEncapsulees,
      caloBridesVissees: data.caloBridesVissees,
      caloCiment: data.caloCiment,
      caloClassique: data.caloClassique,
      caloCoquille: data.caloCoquille,
      caloCoudeClipses: data.caloCoudeClipses,
      caloCoudeEncapsulees: data.caloCoudeEncapsulees,
      caloCoudeVisses: data.caloCoudeVisses,
      caloDateSignatureForm: data.caloDateSignatureForm,
      caloDddPrevisionnel: data.caloDddPrevisionnel,
      caloDddReel: data.caloDddReel,
      caloDemontable: data.caloDemontable,
      caloDeposeRepose: data.caloDeposeRepose,
      caloDeposeUniquement: data.caloDeposeUniquement,
      caloDle: data.caloDle,
      caloDoseCollectivePrevisionnel: data.caloDoseCollectivePrevisionnel,
      caloDoseCollectiveReel: data.caloDoseCollectiveReel,
      caloDoseIndividuelPrevisionnel: data.caloDoseIndividuelPrevisionnel,
      caloDoseIndividuelReelle: data.caloDoseIndividuelReelle,
      caloEh: data.caloEh,
      caloEhCsp: data.caloEhCsp,
      caloGalva: data.caloGalva,
      caloHzc: data.caloHzc,
      caloInox: data.caloInox,
      caloNappe: data.caloNappe,
      caloPiquagesClipses: data.caloPiquagesClipses,
      caloPiquagesEncapsulees: data.caloPiquagesEncapsulees,
      caloPiquagesVisses: data.caloPiquagesVisses,
      caloReductionsClipsees: data.caloReductionsClipsees,
      caloReductionsEncapsulees: data.caloReductionsEncapsulees,
      caloReductionsVissees: data.caloReductionsVissees,
      caloReleveAppareilDiametreAppareil: data.caloReleveAppareilDiametreAppareil,
      caloReleveAppareilEpaisseurCouche1: data.caloReleveAppareilEpaisseurCouche1,
      caloReleveAppareilEpaisseurCouche2: data.caloReleveAppareilEpaisseurCouche2,
      caloReleveAppareilNombreFondBallon: data.caloReleveAppareilNombreFondBallon,
      caloReleveAppareilSurAppareil: data.caloReleveAppareilSurAppareil,
      caloReleveAppareilVirole: data.caloReleveAppareilVirole,
      caloReleveDiametre: data.caloReleveDiametre,
      caloReleveEpaisseurCouche1: data.caloReleveEpaisseurCouche1,
      caloReleveEpaisseurCouche2: data.caloReleveEpaisseurCouche2,
      caloRelevePartieDroite: data.caloRelevePartieDroite,
      caloReposeUniquement: data.caloReposeUniquement,
      caloStyrodur: data.caloStyrodur,
      caloSupportsClipses: data.caloSupportsClipses,
      caloSupportsEncapsules: data.caloSupportsEncapsules,
      caloSupportsVisses: data.caloSupportsVisses,
      caloTelisol: data.caloTelisol,
      caloVannesClipsees: data.caloVannesClipsees,
      caloVannesEncapsulees: data.caloVannesEncapsulees,
      caloVannesVissees: data.caloVannesVissees,
      caloZc: data.caloZc,
      description: data.description,
      doublon: data.doublon,
      doublonComment: data.doublonComment,
      echafAccesDifficile: data.echafAccesDifficile,
      echafDateReception: data.echafDateReception,
      echafDddPReel: data.echafDddPReel,
      echafDddPrevisionnel: data.echafDddPrevisionnel,
      echafDle: data.echafDle,
      echafDoseCollectivePrevisionnel: data.echafDoseCollectivePrevisionnel,
      echafDoseCollectiveReel: data.echafDoseCollectiveReel,
      echafDoseIndividuelPrevisionnel: data.echafDoseIndividuelPrevisionnel,
      echafDoseIndividuelReelle: data.echafDoseIndividuelReelle,
      echafHauteur: data.echafHauteur,
      echafHzc: data.echafHzc,
      echafInterventionEnCapacite: data.echafInterventionEnCapacite,
      echafLargeur: data.echafLargeur,
      echafLongueur: data.echafLongueur,
      echafPlancherAcces: data.echafPlancherAcces,
      echafPlancherAjuste: data.echafPlancherAjuste,
      echafPlancherAlternateur: data.echafPlancherAlternateur,
      echafPlancherCondenseur: data.echafPlancherCondenseur,
      echafPlancherDeTravail: data.echafPlancherDeTravail,
      echafSansObjet: data.echafSansObjet,
      echafSuspendu: data.echafSuspendu,
      echafSuspenduAvecPlancherAjuste: data.echafSuspenduAvecPlancherAjuste,
      echafZc: data.echafZc,
      echafBacheGta: data.echafBacheGta,
      echafCordiste: data.echafCordiste,
      echafInterventionFondPiscine: data.echafInterventionFondPiscine,
      echafSapineLongeur1: data.echafSapineLongeur1,
      echafSapineLongeur2: data.echafSapineLongeur2,
      echafSapineLongeur3: data.echafSapineLongeur3,
      echafSapineLongeur4: data.echafSapineLongeur4,
      echafSapineLargeur1: data.echafSapineLargeur1,
      echafSapineLargeur2: data.echafSapineLargeur2,
      echafSapineLargeur3: data.echafSapineLargeur3,
      echafSapineLargeur4: data.echafSapineLargeur4,
      echafSapineHauteur1: data.echafSapineHauteur1,
      echafSapineHauteur2: data.echafSapineHauteur2,
      echafSapineHauteur3: data.echafSapineHauteur3,
      echafSapineHauteur4: data.echafSapineHauteur4,
      echafSapineNombre1: data.echafSapineNombre1,
      echafSapineNombre2: data.echafSapineNombre2,
      echafSapineNombre3: data.echafSapineNombre3,
      echafSapineNombre4: data.echafSapineNombre4,
      echafSapineVolume1: data.echafSapineVolume1,
      echafSapineVolume2: data.echafSapineVolume2,
      echafSapineVolume3: data.echafSapineVolume3,
      echafSapineVolume4: data.echafSapineVolume4,
      epsilonAa: data.epsilonAa,
      epsilonAb: data.epsilonAb,
      epsilonAc: data.epsilonAc,
      epsilonAd: data.epsilonAd,
      epsilonAe: data.epsilonAe,
      epsilonAf: PgacEpsilonAfEnum.convertToApiValue.getValue(data.epsilonAf),
      epsilonAg: data.epsilonAg,
      epsilonAh: data.epsilonAh,
      epsilonAi: data.epsilonAi,
      epsilonAj: data.epsilonAj,
      epsilonAk: data.epsilonAk,
      epsilonAl: data.epsilonAl,
      epsilonAm: data.epsilonAm,
      epsilonAn: data.epsilonAn,
      epsilonAo: data.epsilonAo,
      epsilonAp: data.epsilonAp,
      epsilonAq: data.epsilonAq,
      epsilonAr: data.epsilonAr,
      epsilonAs: data.epsilonAs,
      epsilonAt: data.epsilonAt,
      epsilonAu: data.epsilonAu,
      epsilonAv: data.epsilonAv,
      epsilonAw: data.epsilonAw,
      epsilonAx: data.epsilonAx,
      epsilonAy: data.epsilonAy,
      epsilonAz: data.epsilonAz,
      epsilonB: data.epsilonB && new Date(data.epsilonB),
      epsilonBa: data.epsilonBa,
      epsilonBb: data.epsilonBb,
      epsilonBc: data.epsilonBc,
      epsilonBd: data.epsilonBd,
      epsilonBe: data.epsilonBe,
      epsilonBf: data.epsilonBf && new Date(data.epsilonBf),
      epsilonC: data.epsilonC && new Date(data.epsilonC),
      epsilonD: data.epsilonD && new Date(data.epsilonD),
      epsilonE: data.epsilonE && new Date(data.epsilonE),
      epsilonF: data.epsilonF,
      epsilonG: data.epsilonG,
      epsilonH: data.epsilonH,
      epsilonI: data.epsilonI,
      epsilonJ: data.epsilonJ,
      epsilonK: data.epsilonK,
      epsilonL: data.epsilonL,
      epsilonM: data.epsilonM,
      epsilonN: data.epsilonN,
      epsilonO: data.epsilonO,
      epsilonP: data.epsilonP,
      epsilonQ: data.epsilonQ,
      epsilonR: data.epsilonR,
      epsilonS: (data.epsilonS || '').toLowerCase(),
      epsilonT: data.epsilonT,
      epsilonU: data.epsilonU,
      epsilonV: data.epsilonV,
      epsilonW: data.epsilonW,
      epsilonX: data.epsilonX,
      epsilonY: data.epsilonY,
      epsilonZ: data.epsilonZ,
      epsilonEf: (data.epsilonEf && DateUtils.toLocalDateTime(data.epsilonEf)) || undefined,
      epsilonFd: data.epsilonFd,
      epsilonFf: data.epsilonFf,
      inEpsilonAa: data.inEpsilonAa,
      inEpsilonAe: data.inEpsilonAe,
      inEpsilonAf: data.inEpsilonAf,
      inEpsilonAg: data.inEpsilonAg,
      inEpsilonAh: data.inEpsilonAh,
      inEpsilonAi: data.inEpsilonAi,
      inEpsilonAo: data.inEpsilonAo,
      inEpsilonAp: data.inEpsilonAp,
      inEpsilonAs: data.inEpsilonAs,
      inEpsilonAv: data.inEpsilonAv,
      inEpsilonBb: data.inEpsilonBb,
      inEpsilonBc: data.inEpsilonBc,
      inEpsilonBd: data.inEpsilonBd,
      inEpsilonBe: data.inEpsilonBe,
      inEpsilonBf: data.inEpsilonBf,
      inEpsilonBg: data.inEpsilonBg,
      inEpsilonBh: data.inEpsilonBh,
      inEpsilonD: data.inEpsilonD && new Date(data.inEpsilonD),
      inEpsilonDq: data.inEpsilonDq,
      inEpsilonEh: data.inEpsilonEh,
      inEpsilonFc: data.inEpsilonFc,
      inEpsilonFe: data.inEpsilonFe,
      inEpsilonFh: data.inEpsilonFh,
      inEpsilonH: data.inEpsilonH && new Date(data.inEpsilonH),
      inEpsilonR: data.inEpsilonR,
      mdsAccesDifficile: data.mdsAccesDifficile,
      mdsCapacite: data.mdsCapacite,
      mdsDle: data.mdsDle,
      mdsHauteur: data.mdsHauteur,
      mdsLargeur: data.mdsLargeur,
      mdsLongueur: data.mdsLongueur,
      mdsSansObjet: data.mdsSansObjet,
      mdsZc: data.mdsZc,
      mdsPir: data.mdsPir,
      mdsEchelle: data.mdsEchelle,
      mdsEdf: data.mdsEdf,
      mdsNacelle: data.mdsNacelle,
      name: data.name,
      ouvrageAccesDifficile: data.ouvrageAccesDifficile,
      ouvrageCapacite: data.ouvrageCapacite,
      ouvrageDateReception: data.ouvrageDateReception,
      ouvrageDle: data.ouvrageDle,
      ouvrageHauteur: data.ouvrageHauteur,
      ouvrageHzc: data.ouvrageHzc,
      ouvrageLargeur: data.ouvrageLargeur,
      ouvrageLongueur: data.ouvrageLongueur,
      ouvrageSansObjet: data.ouvrageSansObjet,
      ouvrageZc: data.ouvrageZc,
      ouvragePassageCables: data.ouvragePassageCables,
      ouvrageGardeCorps: data.ouvrageGardeCorps,
      ouvrageEtabli: data.ouvrageEtabli,
      ouvrageStructureSAS: data.ouvrageStructureSAS,
      ouvrageSupportProbio: data.ouvrageSupportProbio,
      ouvragePotence: data.ouvrageSupportProbio,
      ouvrageRambardeCaillebotisCharpenteTole: data.ouvrageRambardeCaillebotisCharpenteTole,
      rambardeDddPrevisionnel: data.rambardeDddPrevisionnel,
      rambardeDddReel: data.rambardeDddReel,
      rambardeDoseCollectivePrevisionnel: data.rambardeDoseCollectivePrevisionnel,
      rambardeDoseCollectiveReel: data.rambardeDoseCollectiveReel,
      rambardeDoseIndividuelPrevisionnel: data.rambardeDoseIndividuelPrevisionnel,
      rambardeDoseIndividuelReelle: data.rambardeDoseIndividuelReelle,
      statusEpsilon: data.statusEpsilon,
      uuidEntity: data.uuidEntity,
      uuideProject,
      version: data.version,
      // deport largeur (1 - > 4)
      deport1Largeur: data.deport1Largeur,
      deport2Largeur: data.deport2Largeur,
      deport3Largeur: data.deport3Largeur,
      deport4Largeur: data.deport4Largeur,

      // deport longeur (1 - > 4)
      deport1Longueur: data.deport1Longueur,
      deport2Longueur: data.deport2Longueur,
      deport3Longueur: data.deport3Longueur,
      deport4Longueur: data.deport4Longueur,
      // deport Nombre (1 - > 4)
      deport1Nombre: data.deport1Nombre,
      deport2Nombre: data.deport2Nombre,
      deport3Nombre: data.deport3Nombre,
      deport4Nombre: data.deport4Nombre,

      // deport m3 (1 - > 4)
      deport1M3: data.deport1M3,
      deport2M3: data.deport2M3,
      deport3M3: data.deport3M3,
      deport4M3: data.deport4M3,
      deportsM3Total: data.deportsM3Total,
      echafSansDeportM3Total: data.echafSansDeportM3Total,
      echafAvecDeportM3Total: data.echafAvecDeportM3Total,
      marchePied: data.marchePied,
    };
  }

  export const mapToSingleSelectItem = (value: string): SingleSelectItemData<string> => ({
    value,
    title: value,
  });

  export const comparePredicate = (node: SingleSelectItemData<FlowPgacDetails>, item: FlowPgacDetails) => node.value === item;
}
