import { PageResponseHistoryRequestDTO, ResponseHistoryDetailsResponseDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { ApplicationEventActionEnum, ApplicationEventSourceEnum, InnerSortDirectionEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface BlockFormHistoryData {
  uuidEntity: string;
  action: ApplicationEventActionEnum;
  responseName: string;
  userName: string;
  actionSource: ApplicationEventSourceEnum;
  actionDate: string;
  actionTime: string;
}

export namespace BlockFormHistoryData {
  export interface QueryRequest extends BaseQuery<BlockFormHistoryData> {
    formUuidEntity: string;
    projectUuidEntity: string;
  }

  export function mapFromApiValue(blockFormsHistory: ResponseHistoryDetailsResponseDTO): BlockFormHistoryData {
    return {
      uuidEntity: blockFormsHistory.uuidEntity,
      action: ApplicationEventActionEnum.convertFromApiValue.getValue(blockFormsHistory.action),
      userName: blockFormsHistory.user && `${blockFormsHistory.user.firstName} ${blockFormsHistory.user.lastName}`,
      responseName: blockFormsHistory.newRefResponse || blockFormsHistory.currentRefResponse,
      actionDate: blockFormsHistory.eventDate && DateUtils.toDateFormat(blockFormsHistory.eventDate),
      actionTime: blockFormsHistory.eventDate && DateUtils.toTimeFormat(blockFormsHistory.eventDate),
      actionSource: ApplicationEventSourceEnum.convertFromApiValue.getValue(blockFormsHistory.actionSource),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageResponseHistoryRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
      formBusinessLinkUuidEntity: queries.formUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, formUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockFormHistoryData>(),
      formUuidEntity,
      projectUuidEntity,
    };
  }
}
