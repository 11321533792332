import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { RouterState, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxStomp } from '@stomp/rx-stomp';

import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { HttpRequestInterceptor, MockHttpInterceptor } from './v2.0/core/services/___mock___/mock-http-interceptor.service';
import { SfxInterceptorService } from './v2.0/modules/shared/services/interceptor.service';

import { SfxAppRoutingModule } from './app-routing.module';

import { MainEffects } from './v2.0/modules/main/state/main.effects';
import { RouterEffects } from './v2.0/modules/router/router.effects';

import { AppComponent } from './app.component';
import { LoginService } from './v2.0/core/services/login.service';
import { actionsBlockList, appReducer, initialAppState, metaReducers } from './v2.0/modules/app.state';
import { CustomRouteSerializer } from './v2.0/modules/router/helpers/custom-serializer';
import { ToasterService } from './v2.0/modules/shared/components/toaster/toaster.service';

import { environment } from '../environments/environment';
import { TranslationFilesPathEnum } from './v2.0/core/enums';
import { DiagramService } from './v2.0/core/services/diagram.service';
import { FroalaService } from './v2.0/core/services/froala.service';
import { TRANSLATION_FILE_PATH_TOKEN, appLangInitializer, sfxTranslateConfig } from './v2.0/modules/translate-module/shared-translate-config';
import { LabelComponent } from 'app/v2.0/modules/shared/components/label/label.component';
import { IconComponent } from 'app/v2.0/modules/shared/components/icon/icon.component';
import { PlatformModule } from '@angular/cdk/platform';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SfxAppRoutingModule,
    PlatformModule,
    EffectsModule.forRoot([RouterEffects, MainEffects]),
    StoreModule.forRoot(appReducer, {
      initialState: initialAppState,
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateImmutability: false,
        strictActionImmutability: false,
        // Default value is false
        strictActionSerializability: false,
        strictStateSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    StoreDevtoolsModule.instrument({ maxAge: 25, serialize: false, actionsBlocklist: actionsBlockList, logOnly: environment.production }),
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot(sfxTranslateConfig),
    LabelComponent,
    IconComponent,
  ],
  declarations: [AppComponent],
  providers: [
    ToasterService,
    LoginService,
    FroalaService,
    DiagramService,
    { provide: RouterStateSerializer, useClass: CustomRouteSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: SfxInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: environment.mock ? MockHttpInterceptor : HttpRequestInterceptor, multi: true },
    { provide: 'Rx_STOMP_SERVICE_TOKEN', useFactory: () => new RxStomp() },
    { provide: TRANSLATION_FILE_PATH_TOKEN, useValue: [TranslationFilesPathEnum.Globals, TranslationFilesPathEnum.Login] },
    {
      provide: APP_INITIALIZER,
      useFactory: appLangInitializer,
      deps: [TranslateService, Injector, LocalStorageService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
