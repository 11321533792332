import { SkillSummaryData } from '../../models/contributors/skill-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ContributorSkillColumnEnum {
  Name = 'competence',
  UpdateDate = 'updateDate',
  EndDate = 'endDate',
  Status = 'status',
  Pdf = 'actions',
  Action = 'action',
}

export namespace ContributorSkillColumnEnum {
  export type contributorSkillsSortField = keyof SkillSummaryData;

  export const allColumns = [
    ContributorSkillColumnEnum.Name,
    ContributorSkillColumnEnum.UpdateDate,
    ContributorSkillColumnEnum.EndDate,
    ContributorSkillColumnEnum.Status,
    ContributorSkillColumnEnum.Pdf,
  ];

  export const allUserSkillColumns = [
    ContributorSkillColumnEnum.Name,
    ContributorSkillColumnEnum.UpdateDate,
    ContributorSkillColumnEnum.EndDate,
    ContributorSkillColumnEnum.Status,
    ContributorSkillColumnEnum.Pdf,
    ContributorSkillColumnEnum.Action,
  ];

  const key = 'contributors.modals.skillTable.header.';
  export const toString: SfxMap<ContributorSkillColumnEnum, string> = new SfxMap<ContributorSkillColumnEnum, string>([
    [ContributorSkillColumnEnum.Name, key + 'competence'],
    [ContributorSkillColumnEnum.UpdateDate, key + 'updateDate'],
    [ContributorSkillColumnEnum.EndDate, key + 'endDate'],
    [ContributorSkillColumnEnum.Status, key + 'status'],
    [ContributorSkillColumnEnum.Pdf, key + 'actions'],
    [ContributorSkillColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<ContributorSkillColumnEnum, contributorSkillsSortField> = new SfxMap<ContributorSkillColumnEnum, contributorSkillsSortField>([
    [ContributorSkillColumnEnum.Name, 'name'],
    [ContributorSkillColumnEnum.UpdateDate, 'modificationDate'],
    [ContributorSkillColumnEnum.EndDate, 'endDate'],
    [ContributorSkillColumnEnum.Status, 'state' as contributorSkillsSortField], // HACK: @wissem
  ]);
}
