import { CreateMultipleDocumentFlowResponseDTO, CreateMultipleDocumentsFromFlowRequest } from '../../generated/models/models';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { EntityLightData } from '../utils/entity-light-data';
import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';

import { DocumentNatureEnum, FlowTypeEnum, DocumentNatureIndexEnum, DocumentProvenanceEnum } from '../../enums';

// Review: @Bechir: check if we  should remove it later => replace it by DocumentDetailsData Or DocumentSummaryData
export interface BlockDocumentData {
  documentNature: DocumentNatureEnum;
  icon: IconData;
  labelFamily: EntityLightData;
  generated: boolean;
  uuidEntity: string;
  versionUuidEntity?: string;
  documentProvenance: DocumentProvenanceEnum;
}

export namespace BlockDocumentData {
  export interface MultipleFlowDocumentData {
    successList: DocumentFlowSummaryData[];
    errorList: DocumentFlowSummaryData[];
    createError: boolean;
  }

  export function prepareData(documents: DocumentFlowSummaryData[], flowType: FlowTypeEnum): BlockDocumentData[] {
    return DocumentNatureEnum.displayedDocument.getValue(flowType).map(documentType => {
      const flowDocument = (documents || []).find(doc => doc.nature === documentType);

      return {
        icon: DocumentNatureEnum.iconData.getValue(documentType),
        documentNature: documentType,
        labelFamily: flowDocument?.labelFamilyApplication,
        generated: !!flowDocument,
        uuidEntity: flowDocument?.uuidEntity,
        versionUuidEntity: flowDocument?.versionUuidEntity,
        documentProvenance: flowDocument?.provenance,
      };
    });
  }

  export function mapFromApiValue(createMultipleDocumentFLowResponse: CreateMultipleDocumentFlowResponseDTO): MultipleFlowDocumentData {
    return {
      successList: (createMultipleDocumentFLowResponse.successList || []).length
        ? createMultipleDocumentFLowResponse.successList.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow))
        : undefined,
      errorList: (createMultipleDocumentFLowResponse.errorList || []).length
        ? createMultipleDocumentFLowResponse.errorList.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow))
        : undefined,
      createError: createMultipleDocumentFLowResponse.createError,
    };
  }

  export function mapToCreateFromFlowRequestApiValue(
    flowUuidEntity: string,
    natureIndex: DocumentNatureIndexEnum,
    blocks: BlockDocumentData[],
  ): CreateMultipleDocumentsFromFlowRequest {
    return {
      documentList: blocks.map(blockData => ({
        flowUuidEntity,
        labelFamilyUuidEntity: blockData?.labelFamily?.uuidEntity,
        natureFile: DocumentNatureEnum.convertToApiValue.getValue(blockData.documentNature),
        natureIndice: DocumentNatureIndexEnum.convertToApiValue.getValue(natureIndex),
      })),
    };
  }
}
