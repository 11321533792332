import { createReducer, Action, on } from '@ngrx/store';

import { AccessApiDetailsData } from '../../../../../../core/models';
import * as fromAccessApiActions from './access-api-dialog.actions';

export interface AccessApiDialogState {
  saveLoading: boolean;
  isFirstParentToCreate: boolean;
  AccessApiDetailsData: AccessApiDetailsData;
}

export const initialAccessApiDialogState = () => ({
  saveLoading: false,
  isFirstParentToCreate: false,
  AccessApiDetailsData: {} as AccessApiDetailsData,
});

const reducer = createReducer<AccessApiDialogState>(
  initialAccessApiDialogState(),
  on(fromAccessApiActions.updateAccessApi, (state): AccessApiDialogState => ({ ...state, saveLoading: true })),
  on(fromAccessApiActions.updateAccessApiFail, (state): AccessApiDialogState => ({ ...state, saveLoading: false })),
  on(fromAccessApiActions.updateAccessApiSuccess, (state): AccessApiDialogState => ({ ...state, saveLoading: false })),
  on(fromAccessApiActions.addAccessApi, (state): AccessApiDialogState => ({ ...state, saveLoading: true })),
  on(fromAccessApiActions.addAccessApiFail, (state): AccessApiDialogState => ({ ...state, saveLoading: false })),
  on(
    fromAccessApiActions.addAccessApiSuccess,
    (state, _): AccessApiDialogState => ({
      ...state,
      saveLoading: false,
      isFirstParentToCreate: false,
    }),
  ),
  on(fromAccessApiActions.getAccessApiDetailsSuccess, (state, { accessApi }): AccessApiDialogState => ({ ...state, AccessApiDetailsData: accessApi })),
  on(fromAccessApiActions.setLoadingData, (state, { saveLoading }): AccessApiDialogState => ({ ...state, saveLoading })),
);

export function AccessApiDialogReducer(state: AccessApiDialogState | undefined, action: Action): AccessApiDialogState {
  return reducer(state, action);
}
