import dayjs from 'dayjs';
import { ElementPlanSchedulingUpdateRequestDTO } from '../../generated/models/elementPlanSchedulingUpdateRequestDTO';
import { ElementPlanTemporalUpdateRequestDTO } from '../../generated/models/elementPlanTemporalUpdateRequestDTO';

export interface ElementPlanUpdateData {
  elementPlanUuidEntity: string;
  flowUuidEntity?: string;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
}

export namespace ElementPlanUpdateData {
  export function mapToUpdateTemporalRequest(projectUuidEntity: string, elementPlan: ElementPlanUpdateData): ElementPlanTemporalUpdateRequestDTO {
    return {
      uuidEntity: elementPlan.elementPlanUuidEntity,
      projectUuidEntity,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      startDate: (elementPlan.startDate && elementPlan.startDate?.isValid() && (elementPlan.startDate.toISOString() as any)) || null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      finishDate: (elementPlan.endDate && elementPlan.endDate?.isValid() && (elementPlan.endDate.toISOString() as any)) || null,
    };
  }

  export function mapToUpdateSchedulerRequest(projectUuidEntity: string, elementPlan: ElementPlanUpdateData): ElementPlanSchedulingUpdateRequestDTO {
    return {
      elementPlanUuidEntity: elementPlan.elementPlanUuidEntity,
      flowUuidEntity: elementPlan.flowUuidEntity,
      projectUuidEntity,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      finishDate: (elementPlan.endDate && (elementPlan.endDate.toISOString() as any)) || undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      startDate: (elementPlan.startDate && (elementPlan.startDate.toISOString() as any)) || undefined,
    };
  }
}
