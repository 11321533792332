import { SfxMap } from '../../utils/enum-utils';

export enum BlockAdrParadeColumnEnum {
  Parade = 'parade',
  Picto = 'picto',
  Pdf = 'pdf',
  Actions = 'actions',
}

export namespace BlockAdrParadeColumnEnum {
  export const allColumns = [BlockAdrParadeColumnEnum.Parade, BlockAdrParadeColumnEnum.Picto, BlockAdrParadeColumnEnum.Pdf, BlockAdrParadeColumnEnum.Actions];
  export const viewModeColumns = [BlockAdrParadeColumnEnum.Parade, BlockAdrParadeColumnEnum.Picto, BlockAdrParadeColumnEnum.Pdf];

  const key = 'blocks.adr.dialogs.view.table.';
  export const toString: SfxMap<BlockAdrParadeColumnEnum, string> = new SfxMap<BlockAdrParadeColumnEnum, string>([
    [BlockAdrParadeColumnEnum.Parade, key + 'parade'],
    [BlockAdrParadeColumnEnum.Picto, key + 'picto'],
    [BlockAdrParadeColumnEnum.Pdf, key + 'pdf'],
    [BlockAdrParadeColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<BlockAdrParadeColumnEnum, string> = new SfxMap<BlockAdrParadeColumnEnum, string>([[BlockAdrParadeColumnEnum.Parade, 'name']]);
}
