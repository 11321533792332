import { ElementAdrSummaryResponseDTO, PageElementAdrRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { ParadeLightData } from './parade-light-data';

import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface RiskSummaryData {
  labelFamily: LabelFamilyLightData;
  listParades: ParadeLightData[];
  risk: string;
  riskSource: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  applicability: EntityLightData;
  archived: boolean;
}

export namespace RiskSummaryData {
  const sortFields = ['family', 'risk', 'riskSource'];

  export interface QueryRequest extends BaseQuery<RiskSummaryData> {
    applicabilities?: string[];
    families?: string[];
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(risk: ElementAdrSummaryResponseDTO): RiskSummaryData {
    return {
      labelFamily: LabelFamilyLightData.mapFromApiValue(risk.labelFamily),
      listParades: risk.listParades.map(parade => ParadeLightData.mapFromApiValue(parade)),
      riskSource: risk.riskSource,
      risk: risk.risk,
      uuid: risk.uuid,
      uuidEntity: risk.uuidEntity,
      uuidOrganization: risk.uuidOrganization,
      uuidType: risk.uuidType,
      applicability: risk.applicability && LabelApplicabilityLightData.mapToEntityLightData(risk.applicability),
      archived: risk.archived,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageElementAdrRequestDTO {
    return {
      applicabilities: queries.applicabilities,
      families: queries.families,
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
    };
  }

  export function initializeQueryRequest(applicabilities: string[], families: string[], searchText: string, sort: SortFilter<RiskSummaryData>, state?: string): QueryRequest {
    return {
      ...initializeQueries<RiskSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state || FilterArchivedEnum.Activated),
    };
  }

  export function toString(risk: RiskSummaryData): string {
    return (risk.riskSource ? risk.riskSource.concat(StringUtilsEnum.DashSeparator) : '').concat(risk.risk);
  }
}
