import { RiskSummaryData } from '../../models/library-risk/risk-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum LibraryRiskColumnEnum {
  Family = 'family',
  Risk = 'risk',
  Source = 'source',
  Actions = 'actions',
}

export namespace LibraryRiskColumnEnum {
  export type libraryRiskSortField = keyof RiskSummaryData;

  export const allColumns = [LibraryRiskColumnEnum.Family, LibraryRiskColumnEnum.Risk, LibraryRiskColumnEnum.Source, LibraryRiskColumnEnum.Actions];
  export const reorganizeColumns = [LibraryRiskColumnEnum.Family, LibraryRiskColumnEnum.Risk, LibraryRiskColumnEnum.Source];

  const key = 'riskLibrary.table.';
  export const toString: SfxMap<LibraryRiskColumnEnum, string> = new SfxMap<LibraryRiskColumnEnum, string>([
    [LibraryRiskColumnEnum.Family, key + 'family'],
    [LibraryRiskColumnEnum.Risk, key + 'risk'],
    [LibraryRiskColumnEnum.Source, key + 'source'],
    [LibraryRiskColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<LibraryRiskColumnEnum, libraryRiskSortField> = new SfxMap<LibraryRiskColumnEnum, libraryRiskSortField>([
    [LibraryRiskColumnEnum.Family, 'family' as libraryRiskSortField],
    [LibraryRiskColumnEnum.Risk, 'risk'],
    [LibraryRiskColumnEnum.Source, 'riskSource'],
  ]);
}
