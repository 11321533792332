import { SfxMap } from '../../utils/enum-utils';

export enum BlockRhpColumnEnum {
  Function = 'function',
  NumberCol = 'number',
  Skill = 'skill',
  MobileName = 'mobileName',
  TabletName = 'tabletName',
  Action = 'action',
  RhpAction = 'rhpAction',
}

export namespace BlockRhpColumnEnum {
  export const allColumns = [BlockRhpColumnEnum.Function, BlockRhpColumnEnum.NumberCol, BlockRhpColumnEnum.Skill, BlockRhpColumnEnum.Action];

  export const blockMobileAppFormColumns = [BlockRhpColumnEnum.MobileName, BlockRhpColumnEnum.RhpAction];
  export const blockTabletAppFormColumns = [BlockRhpColumnEnum.TabletName, BlockRhpColumnEnum.Skill, BlockRhpColumnEnum.RhpAction];

  const key = 'blocks.rhp.table.header.';
  export const toString: SfxMap<BlockRhpColumnEnum, string> = new SfxMap<BlockRhpColumnEnum, string>([
    [BlockRhpColumnEnum.Function, key + 'function'],
    [BlockRhpColumnEnum.NumberCol, key + 'number'],
    [BlockRhpColumnEnum.Skill, key + 'skill'],
    [BlockRhpColumnEnum.Action, key + 'action'],
    [BlockRhpColumnEnum.MobileName, key + 'mobile'],
    [BlockRhpColumnEnum.TabletName, key + 'tablet'],
    [BlockRhpColumnEnum.RhpAction, key + 'action'],
  ]);

  export const sortField: SfxMap<BlockRhpColumnEnum, string> = new SfxMap<BlockRhpColumnEnum, string>([
    [BlockRhpColumnEnum.Function, 'function'],
    [BlockRhpColumnEnum.NumberCol, 'number'],
    [BlockRhpColumnEnum.Skill, 'skills'],
  ]);
}
