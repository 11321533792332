import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './schedule.actions';

import { FlowDetailsData } from '../../../../core/models';
import { ScheduleDetailsData } from '../../../../core/models/schedule/schedule-details-data';

const adapter: EntityAdapter<ScheduleDetailsData> = createEntityAdapter<ScheduleDetailsData>({
  selectId: schedule => schedule.uuidEntity,
});

export interface ScheduleState extends EntityState<ScheduleDetailsData> {
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
  flowLight: FlowDetailsData;
  rowLoading: string;
}

export const scheduleInitialState: ScheduleState = adapter.getInitialState<ScheduleState>({
  ids: [],
  entities: {},
  loading: false,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
  flowLight: undefined,
  rowLoading: undefined,
});

const _scheduleReducer = createReducer<ScheduleState>(
  scheduleInitialState,
  on(fromActions.loadFlowLightSuccess, (state, { flowLight }): ScheduleState => ({ ...state, flowLight })),
  on(fromActions.loadScheduleList, (state): ScheduleState => ({ ...state, loading: true })),
  on(
    fromActions.loadScheduleListSuccess,
    (state, { schedules, totalCount, filteredTotalCount, reset }): ScheduleState => {
      const newState: ScheduleState = { ...state, loading: false, reset, totalCount, filteredTotalCount };

      return reset ? adapter.setAll(schedules, newState) : adapter.addMany(schedules, newState);
    },
  ),
  on(fromActions.loadScheduleListFails, (state): ScheduleState => ({ ...state, loading: false })),
  on(fromActions.resetState, (): ScheduleState => ({ ...scheduleInitialState })),

  on(
    fromActions.changeScheduleStateSuccess,
    (state, { status, scheduleUuidEntity }): ScheduleState => (scheduleUuidEntity ? adapter.updateOne({ id: scheduleUuidEntity, changes: { status } }, state) : state),
  ),
  on(fromActions.changeScheduleState, (state, { scheduleUuidEntity }): ScheduleState => ({ ...state, rowLoading: scheduleUuidEntity })),
  on(fromActions.changeScheduleStateFail, fromActions.changeScheduleStateSuccess, (state): ScheduleState => ({ ...state, rowLoading: null })),

  on(
    fromActions.startScheduleSuccess,
    (state, { schedule }): ScheduleState => (schedule?.uuidEntity ? adapter.updateOne({ id: schedule?.uuidEntity, changes: { startDate: schedule.startDate } }, state) : state),
  ),

  on(
    fromActions.endScheduleSuccess,
    (state, { schedule }): ScheduleState => (schedule?.uuidEntity ? adapter.updateOne({ id: schedule?.uuidEntity, changes: { endDate: schedule.endDate } }, state) : state),
  ),
);

export function scheduleManagementReducer(state: ScheduleState | undefined, action: Action): ScheduleState {
  return _scheduleReducer(state, action);
}

export const selectAllElements = adapter.getSelectors().selectAll;
