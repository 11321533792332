/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PlanSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  description: string;
  scale: PlanSummaryResponseDTO.ScaleEnum;
  unitNumber: number;
  planOrder: number;
  modificationDate: Date;
  createDate: Date;
}
export namespace PlanSummaryResponseDTO {
  export type ScaleEnum = 'TEMPORAL' | 'SCHEDULING' | 'DEFAULT';
  export const ScaleEnum = {
    TEMPORAL: 'TEMPORAL' as ScaleEnum,
    SCHEDULING: 'SCHEDULING' as ScaleEnum,
    DEFAULT: 'DEFAULT' as ScaleEnum,
  };
}
