/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LabelFamilyLightResponseDTO } from './labelFamilyLightResponseDTO';

export interface CustomFieldMiniDTO {
  typeField?: CustomFieldMiniDTO.TypeFieldEnum;
  labelFamily?: LabelFamilyLightResponseDTO;
  name?: string;
  uuidEntity?: string;
  enums?: string;
  longText?: boolean;
}
export namespace CustomFieldMiniDTO {
  export type TypeFieldEnum =
    | 'STRING'
    | 'LABEL'
    | 'INTEGER'
    | 'DOUBLE'
    | 'BOOLEAN'
    | 'DATE'
    | 'DATE_TIME'
    | 'ENUM_SINGLE_SELECTION'
    | 'ENUM_MULTIPLE_SELECTIONS'
    | 'FILE'
    | 'IMAGE'
    | 'METADATA'
    | 'LINK';
  export const TypeFieldEnum = {
    STRING: 'STRING' as TypeFieldEnum,
    LABEL: 'LABEL' as TypeFieldEnum,
    INTEGER: 'INTEGER' as TypeFieldEnum,
    DOUBLE: 'DOUBLE' as TypeFieldEnum,
    BOOLEAN: 'BOOLEAN' as TypeFieldEnum,
    DATE: 'DATE' as TypeFieldEnum,
    DATETIME: 'DATE_TIME' as TypeFieldEnum,
    ENUMSINGLESELECTION: 'ENUM_SINGLE_SELECTION' as TypeFieldEnum,
    ENUMMULTIPLESELECTIONS: 'ENUM_MULTIPLE_SELECTIONS' as TypeFieldEnum,
    FILE: 'FILE' as TypeFieldEnum,
    IMAGE: 'IMAGE' as TypeFieldEnum,
    METADATA: 'METADATA' as TypeFieldEnum,
    LINK: 'LINK' as TypeFieldEnum,
  };
}
