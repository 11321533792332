import { Observable, of } from 'rxjs';
import { LabelApplicabilityAffectationRequestDTO } from '../../generated/models/labelApplicabilityAffectationRequestDTO';
import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum LabelApplicabilityEnum {
  Normal = 1,
  Organization = 2,
}

export namespace LabelApplicabilityEnum {
  const values = [LabelApplicabilityEnum.Normal, LabelApplicabilityEnum.Organization];

  export const convertFromApiValue: SfxMap<LabelApplicabilityAffectationRequestDTO.TypeEnum, LabelApplicabilityEnum> = new SfxMap<
    LabelApplicabilityAffectationRequestDTO.TypeEnum,
    LabelApplicabilityEnum
  >([
    [LabelApplicabilityAffectationRequestDTO.TypeEnum.NORMAL, LabelApplicabilityEnum.Normal],
    [LabelApplicabilityAffectationRequestDTO.TypeEnum.ORGANIZATION, LabelApplicabilityEnum.Organization],
  ]);

  export const convertToApiValue: SfxMap<LabelApplicabilityEnum, LabelApplicabilityAffectationRequestDTO.TypeEnum> = new SfxMap<
    LabelApplicabilityEnum,
    LabelApplicabilityAffectationRequestDTO.TypeEnum
  >([
    [LabelApplicabilityEnum.Normal, LabelApplicabilityAffectationRequestDTO.TypeEnum.NORMAL],
    [LabelApplicabilityEnum.Organization, LabelApplicabilityAffectationRequestDTO.TypeEnum.ORGANIZATION],
  ]);

  export const convertToParamsValue = new SfxMap<LabelApplicabilityEnum, string>([
    [LabelApplicabilityEnum.Normal, 'normal'],
    [LabelApplicabilityEnum.Organization, 'organization'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, LabelApplicabilityEnum>([
    ['normal', LabelApplicabilityEnum.Normal],
    ['organization', LabelApplicabilityEnum.Organization],
  ]);

  const key = 'accessApi.v2.filters.';
  export const toString = new SfxMap<LabelApplicabilityEnum, string>([
    [LabelApplicabilityEnum.Normal, key + 'normal'],
    [LabelApplicabilityEnum.Organization, key + 'organization'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<LabelApplicabilityEnum>[]> => of(values.map(status => ({ value: status, title: toString.getValue(status) })));

  export const comparePredicate = (node: SingleSelectItemData<LabelApplicabilityEnum>, item: LabelApplicabilityEnum) => node.value === item;
}
