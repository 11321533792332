import { union, compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { ApiUrlsEnum } from '../../services/helpers/api-url';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';
import { CustomFileNatureEnum } from '../custom-file/custom-file-nature.enum';
import { CustomFileTypeEnum } from '../custom-file/custom-file-type.enum';

export enum CustomCellNatureEnum {
  Project = 1,
  Flow,
  CustomFieldProject,
  CustomFieldFlow,
  Geometry,
  Phase,
  Step,
  TechnicalObject,
  CustomFieldTechnicalObject,
  FlowReferential,
  CustomFieldFlowReferential,
}

export namespace CustomCellNatureEnum {
  const projectContentMiningValues: CustomCellNatureEnum[] = [
    CustomCellNatureEnum.Project,
    CustomCellNatureEnum.CustomFieldProject,
    CustomCellNatureEnum.Flow,
    CustomCellNatureEnum.CustomFieldFlow,
    CustomCellNatureEnum.Geometry,
    CustomCellNatureEnum.Phase,
    CustomCellNatureEnum.Step,
  ];
  const projectFlowContentValues = (nature?: CustomFileNatureEnum): CustomCellNatureEnum[] =>
    compact([
      CustomCellNatureEnum.Project,
      CustomCellNatureEnum.CustomFieldProject,
      CustomCellNatureEnum.Flow,
      CustomCellNatureEnum.CustomFieldFlow,
      CustomCellNatureEnum.Phase,
      CustomCellNatureEnum.Step,
      nature === CustomFileNatureEnum.Importation && CustomCellNatureEnum.FlowReferential,
    ]);

  const projectFlowFormValues: CustomCellNatureEnum[] = [
    CustomCellNatureEnum.Project,
    CustomCellNatureEnum.CustomFieldProject,
    CustomCellNatureEnum.Flow,
    CustomCellNatureEnum.CustomFieldFlow,
  ];
  const technicalObjectValues: CustomCellNatureEnum[] = [CustomCellNatureEnum.TechnicalObject, CustomCellNatureEnum.CustomFieldTechnicalObject];

  export const mapNatureByType = (nature?: CustomFileNatureEnum): SfxMap<CustomFileTypeEnum, CustomCellNatureEnum[]> =>
    new SfxMap<CustomFileTypeEnum, CustomCellNatureEnum[]>(
      [
        [CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING, projectContentMiningValues],
        [CustomFileTypeEnum.PROJECT_FLOW_CONTENT, projectFlowContentValues(nature)],
        [CustomFileTypeEnum.PROJECT_FLOW_FORM, projectFlowFormValues],
        [CustomFileTypeEnum.TECHNICAL_OBJECT, technicalObjectValues],
      ],
      union(projectContentMiningValues, projectFlowContentValues(nature), technicalObjectValues),
    );

  export const naturesWhenCanSelectCustomField = [
    CustomCellNatureEnum.CustomFieldProject,
    CustomCellNatureEnum.CustomFieldFlow,
    CustomCellNatureEnum.CustomFieldTechnicalObject,
    CustomCellNatureEnum.CustomFieldFlowReferential,
  ];

  export const convertFromApiValue: SfxMap<string, CustomCellNatureEnum> = new SfxMap<string, CustomCellNatureEnum>([
    ['PROJECT', CustomCellNatureEnum.Project],
    ['FLOW', CustomCellNatureEnum.Flow],
    ['CUSTOM_FIELD_PROJECT', CustomCellNatureEnum.CustomFieldProject],
    ['CUSTOM_FIELD_FLOW', CustomCellNatureEnum.CustomFieldFlow],
    ['GEOMETRY', CustomCellNatureEnum.Geometry],
    ['PHASE', CustomCellNatureEnum.Phase],
    ['STEP', CustomCellNatureEnum.Step],
    ['TECHNICAL_OBJECT', CustomCellNatureEnum.TechnicalObject],
    ['CUSTOM_FIELD_TECHNICAL_OBJECT', CustomCellNatureEnum.CustomFieldTechnicalObject],
    ['FLOW_REFERENTIAL', CustomCellNatureEnum.FlowReferential],
    ['CUSTOM_FIELD_FLOW_REFERENTIAL', CustomCellNatureEnum.CustomFieldFlowReferential],
  ]);

  export const convertToApiValue: SfxMap<CustomCellNatureEnum, string> = new SfxMap<CustomCellNatureEnum, string>([
    [CustomCellNatureEnum.Project, 'PROJECT'],
    [CustomCellNatureEnum.Flow, 'FLOW'],
    [CustomCellNatureEnum.CustomFieldProject, 'CUSTOM_FIELD_PROJECT'],
    [CustomCellNatureEnum.CustomFieldFlow, 'CUSTOM_FIELD_FLOW'],
    [CustomCellNatureEnum.Geometry, 'GEOMETRY'],
    [CustomCellNatureEnum.Phase, 'PHASE'],
    [CustomCellNatureEnum.Step, 'STEP'],
    [CustomCellNatureEnum.TechnicalObject, 'TECHNICAL_OBJECT'],
    [CustomCellNatureEnum.CustomFieldTechnicalObject, 'CUSTOM_FIELD_TECHNICAL_OBJECT'],
    [CustomCellNatureEnum.FlowReferential, 'FLOW_REFERENTIAL'],
    [CustomCellNatureEnum.CustomFieldFlowReferential, 'CUSTOM_FIELD_FLOW_REFERENTIAL'],
  ]);

  export const convertFromParamsValue: SfxMap<string, CustomCellNatureEnum> = new SfxMap<string, CustomCellNatureEnum>([
    ['project', CustomCellNatureEnum.Project],
    ['flow', CustomCellNatureEnum.Flow],
    ['cfProject', CustomCellNatureEnum.CustomFieldProject],
    ['cfFlow', CustomCellNatureEnum.CustomFieldFlow],
    ['geometry', CustomCellNatureEnum.Geometry],
    ['phase', CustomCellNatureEnum.Phase],
    ['step', CustomCellNatureEnum.Step],
    ['technicalObject', CustomCellNatureEnum.TechnicalObject],
    ['cfTechnicalObject', CustomCellNatureEnum.CustomFieldTechnicalObject],
    ['flowReferential', CustomCellNatureEnum.FlowReferential],
    ['cfFlowReferential', CustomCellNatureEnum.CustomFieldFlowReferential],
  ]);

  export const convertToParamsValue: SfxMap<CustomCellNatureEnum, string> = new SfxMap<CustomCellNatureEnum, string>([
    [CustomCellNatureEnum.Project, 'project'],
    [CustomCellNatureEnum.Flow, 'flow'],
    [CustomCellNatureEnum.CustomFieldProject, 'cfProject'],
    [CustomCellNatureEnum.CustomFieldFlow, 'cfFlow'],
    [CustomCellNatureEnum.Geometry, 'geometry'],
    [CustomCellNatureEnum.Phase, 'phase'],
    [CustomCellNatureEnum.Step, 'step'],
    [CustomCellNatureEnum.TechnicalObject, 'technicalObject'],
    [CustomCellNatureEnum.CustomFieldTechnicalObject, 'cfTechnicalObject'],
    [CustomCellNatureEnum.FlowReferential, 'flowReferential'],
    [CustomCellNatureEnum.CustomFieldFlowReferential, 'cfFlowReferential'],
  ]);

  const key = 'customCells.natures.';
  export const toString: SfxMap<CustomCellNatureEnum, string> = new SfxMap<CustomCellNatureEnum, string>([
    [CustomCellNatureEnum.Project, key + 'project'],
    [CustomCellNatureEnum.Flow, key + 'flow'],
    [CustomCellNatureEnum.CustomFieldProject, key + 'customFieldProject'],
    [CustomCellNatureEnum.CustomFieldFlow, key + 'customFieldFlow'],
    [CustomCellNatureEnum.Geometry, key + 'geometry'],
    [CustomCellNatureEnum.Phase, key + 'phase'],
    [CustomCellNatureEnum.Step, key + 'step'],
    [CustomCellNatureEnum.TechnicalObject, key + 'technicalObject'],
    [CustomCellNatureEnum.CustomFieldTechnicalObject, key + 'customFieldTechnicalObject'],
    [CustomCellNatureEnum.FlowReferential, key + 'flowRef'],
    [CustomCellNatureEnum.CustomFieldFlowReferential, key + 'cfFlowRef'],
  ]);

  export const convertToCustomFieldUrl: SfxMap<CustomCellNatureEnum, string> = new SfxMap<CustomCellNatureEnum, string>([
    [CustomCellNatureEnum.CustomFieldProject, ApiUrlsEnum.ProjectLabelFamilyAffectation],
    [CustomCellNatureEnum.CustomFieldFlow, ApiUrlsEnum.FlowLabelFamilyAffectation],
    [CustomCellNatureEnum.CustomFieldTechnicalObject, ApiUrlsEnum.TechnicalObjectLibraryLabelFamilyAffectation],
  ]);

  export const convertToBusinessClass: SfxMap<CustomCellNatureEnum, BusinessClassNameEnum> = new SfxMap<CustomCellNatureEnum, BusinessClassNameEnum>([
    [CustomCellNatureEnum.CustomFieldProject, BusinessClassNameEnum.Project],
    [CustomCellNatureEnum.CustomFieldFlow, BusinessClassNameEnum.Flow],
    [CustomCellNatureEnum.CustomFieldTechnicalObject, BusinessClassNameEnum.TechnicalObject],
  ]);

  export const itemsResolver = (type?: CustomFileTypeEnum, nature?: CustomFileNatureEnum): Observable<SingleSelectItemData<CustomCellNatureEnum>[]> =>
    of(
      mapNatureByType(nature)
        .getValue(type)
        .map(value => ({
          value: value,
          title: toString.getValue(value),
        })),
    );

  export const comparePredicate = (node: SingleSelectItemData<CustomCellNatureEnum>, item: CustomCellNatureEnum) => node.value === item;
}
