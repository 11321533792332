import { MarginDTO } from '../../generated/models/models';

export interface MarginData {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
}

export namespace MarginData {
  export function mapFromApiValue(margin: MarginDTO): MarginData {
    return {
      bottom: margin.bottom,
      left: margin.left,
      right: margin.right,
      top: margin.top,
    };
  }

  export function mapToRequestApiValue(margin: MarginData): MarginDTO {
    return {
      bottom: margin.bottom,
      left: margin.left,
      right: margin.right,
      top: margin.top,
    };
  }
}
