import { TranslateService } from '@ngx-translate/core';
import { compact } from 'lodash';

import { BusinessClassNameEnum, FormTypeEnum } from '../../enums';
import { FormInfoSharedLinkResponseDto } from '../../generated/models/formInfoSharedLinkResponseDto';
import { DateUtils } from '../../utils/date.utils';
import { SfxMap } from '../../utils/enum-utils';

export interface FormShareData {
  reference: string;
  name: string;
  index: string;
  type: FormTypeEnum;
  projectUuidEntity: string;
  uuidEntity: string;
  responseUuidEntity?: string;
}

export namespace FormShareData {
  export function mapFromApiValue(data: FormInfoSharedLinkResponseDto, translateService: TranslateService): FormShareData {
    let formReference: string;

    if (data.reference?.formReference) {
      formReference = data.reference?.formReference;
    } else if (data.reference && data.reference.contributorFirstName && data.reference.contributorLastName) {
      formReference = `${data.reference.contributorFirstName.charAt(0)}.${data.reference.contributorLastName}`.toLocaleUpperCase();
    }

    const phaseKey = translateService.instant('blocks.form.dialogs.reply.phasePrefix');
    const stepKey = translateService.instant('blocks.form.dialogs.reply.stepPrefix');
    const sharedKey = translateService.instant('blocks.form.dialogs.reply.sharedPrefix');

    return {
      reference: compact([
        formReference,
        data.reference.indice,
        data.reference.phaseOrder && `${phaseKey}${data.reference.phaseOrder}`,
        data.reference.stepOrder && `${stepKey}${data.reference.stepOrder}`,
        DateUtils.toDateTimeFormat(DateUtils.today()),
        `${sharedKey}${data.reference.responseCount}`,
      ]).join(' - '),
      name: data.name,
      index: data.index,
      type: FormTypeEnum.convertFromApiValue.getValue(data.type),
      uuidEntity: data.uuidEntity,
      projectUuidEntity: data.projectUuide,
      responseUuidEntity: data.responseUuide,
    };
  }

  const resendKey = 'share.toaster.';

  export const shareSuccessKeys = new SfxMap<BusinessClassNameEnum, string>(
    [
      [BusinessClassNameEnum.Form, resendKey + 'sharedFormSuccess'],
      [BusinessClassNameEnum.FormResponse, resendKey + 'sharedFormResponseSuccess'],
      [BusinessClassNameEnum.Document, resendKey + 'sharedDocumentSuccess'],
    ],
    resendKey + 'resendSuccess',
  );

  export const shareFailureKeys = new SfxMap<BusinessClassNameEnum, string>(
    [
      [BusinessClassNameEnum.Form, resendKey + 'sharedFormFailure'],
      [BusinessClassNameEnum.FormResponse, resendKey + 'sharedFormResponseFailure'],
      [BusinessClassNameEnum.Document, resendKey + 'sharedDocumentFailure'],
    ],
    resendKey + 'resendFailure',
  );
}
