/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { ProjectDTO } from './projectDTO';
import { UserLightDTO } from './userLightDTO';

export interface ProjectSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  modificationDate: Date;
  typeProject: ProjectSummaryResponseDTO.TypeProjectEnum;
  designation: string;
  description: string;
  labelFamilyApplication: LabelFamilyLightDTO;
  businessRef: string;
  clientName: string;
  clientName1: string;
  clientName2: string;
  locationLibelle: string;
  currentUserRoles: Array<ProjectSummaryResponseDTO.CurrentUserRolesEnum>;
  status: ProjectSummaryResponseDTO.StatusEnum;
  applicablityName: string;
  sharedProject: boolean;
  managers: Array<UserLightDTO>;
  published: boolean;
  folder: ProjectDTO;
  index: string;
  atLeastOneFlow: boolean;
  customStatus: CustomStatusLightDTO;
}
export namespace ProjectSummaryResponseDTO {
  export type TypeProjectEnum = 'PROJECT' | 'REFERENTIAL' | 'TEMPLATE' | 'PGAC' | 'FOLDER';
  export const TypeProjectEnum = {
    PROJECT: 'PROJECT' as TypeProjectEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeProjectEnum,
    TEMPLATE: 'TEMPLATE' as TypeProjectEnum,
    PGAC: 'PGAC' as TypeProjectEnum,
    FOLDER: 'FOLDER' as TypeProjectEnum,
  };
  export type CurrentUserRolesEnum = 'ROLE_NORMAL' | 'ROLE_ADVANCED' | 'ROLE_READONLY' | 'ROLE_ADMIN_PROJECT' | 'ROLE_OPERATOR';
  export const CurrentUserRolesEnum = {
    NORMAL: 'ROLE_NORMAL' as CurrentUserRolesEnum,
    ADVANCED: 'ROLE_ADVANCED' as CurrentUserRolesEnum,
    READONLY: 'ROLE_READONLY' as CurrentUserRolesEnum,
    ADMINPROJECT: 'ROLE_ADMIN_PROJECT' as CurrentUserRolesEnum,
    OPERATOR: 'ROLE_OPERATOR' as CurrentUserRolesEnum,
  };
  export type StatusEnum = 'ACTIF' | 'ARCHIVE';
  export const StatusEnum = {
    ACTIF: 'ACTIF' as StatusEnum,
    ARCHIVE: 'ARCHIVE' as StatusEnum,
  };
}
