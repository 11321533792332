import { AnimationTriggerMetadata, trigger, state, transition, style, animate } from '@angular/animations';
import { ToastAnimationStateEnum } from '../../../../core/enums/utils/toast-animation.enum';

export const toastAnimations: {
  readonly toastAnimation: AnimationTriggerMetadata;
} = {
  toastAnimation: trigger('fadeAnimation', [
    state(ToastAnimationStateEnum.Default, style({ opacity: 1 })),
    transition('void => *', [style({ opacity: 0 }), animate('{{ fadeIn }}ms')]),
    transition(`${ToastAnimationStateEnum.Default} => ${ToastAnimationStateEnum.Closing}`, animate('{{ fadeOut }}ms', style({ opacity: 0 }))),
  ]),
};
