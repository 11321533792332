import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SessionGuard } from './v2.0/modules/shared/guards/session.guard';
import { ResetStateGuard } from './v2.0/modules/shared/guards/reset-state.guard';

import { RouterPaths, sfxModalOutlet } from './v2.0/core/constant/route.constant';
import { RouteIdEnum } from './v2.0/core/enums/router-enums/route.enum';
import { StateName } from './v2.0/core/enums/state-name.enum';

export const appRoutes: Routes = [
  {
    path: RouterPaths.RootPaths.rootPath,
    canActivate: [SessionGuard],
    children: [
      {
        path: RouterPaths.RootPaths.mainPath,
        data: { routeId: RouteIdEnum.Main },
        loadChildren: () => import('./v2.0/modules/main/main.module').then(m => m.MainModule),
      },
      {
        path: RouterPaths.FormPaths.basePath,
        canDeactivate: [ResetStateGuard],
        data: { routeId: RouteIdEnum.Form, stateToReset: [StateName.Form] },
        loadChildren: () => import('./v2.0/modules/form/form.module').then(m => m.FormModule),
      },
    ],
  },
  {
    path: RouterPaths.RootPaths.rootPath,
    data: { routeId: RouteIdEnum.Share, stateToReset: [StateName.Share] },
    loadChildren: () => import('./v2.0/modules/entry/entry.module').then(m => m.EntryModule),
  },
  { path: RouterPaths.RootPaths.callbackPath, loadComponent: () => import('./v2.0/modules/callback/callback.component').then(comp => comp.CallbackComponent) },
  { path: RouterPaths.RootPaths.notFoundPath, loadComponent: () => import('./v2.0/modules/not-found/not-found.component').then(comp => comp.NotFoundComponent) },
  { path: RouterPaths.RootPaths.unregisteredPath, redirectTo: RouterPaths.RootPaths.notFoundPath, pathMatch: 'full' },
  {
    path: RouterPaths.DialogPaths.dialogPath,
    loadChildren: () => import('./v2.0/modules/sfx-dialog/sfx-dialog.module').then(m => m.SfxDialogModule),
    outlet: sfxModalOutlet,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class SfxAppRoutingModule {}
