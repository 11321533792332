import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { EntityLightData, BlockRhpDetailsData } from '../../../../../../../../core/models';
import * as fromActions from './block-team-dialog.actions';

export interface BlockRhpDialogState {
  teamDetails: BlockRhpDetailsData;
  initialTeamDetails: BlockRhpDetailsData;
  saveLoading: boolean;
}

export const initialBlockRhpDialogState = () => ({
  teamDetails: { skills: [{}] } as BlockRhpDetailsData,
  initialTeamDetails: { skills: [{}] } as BlockRhpDetailsData,
  saveLoading: false,
});

const reducer = createReducer<BlockRhpDialogState>(
  initialBlockRhpDialogState(),
  on(fromActions.getElementRhpDetailsSuccess, (state, { teamDetails }): BlockRhpDialogState => ({ ...state, teamDetails, initialTeamDetails: cloneDeep(teamDetails) })),
  on(fromActions.addElementRhp, (state): BlockRhpDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.addElementRhpFail, (state): BlockRhpDialogState => ({ ...state, saveLoading: false })),
  on(
    fromActions.addElementRhpSuccess,
    (state, { createAnother }): BlockRhpDialogState => ({
      ...state,
      saveLoading: false,
      teamDetails: createAnother ? ({ skills: [{}] } as BlockRhpDetailsData) : state.teamDetails,
    }),
  ),
  on(fromActions.updateElementRhp, (state): BlockRhpDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateElementRhpFail, (state): BlockRhpDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.getElementRhpDetailsSuccess, (state, { teamDetails }): BlockRhpDialogState => ({ ...state, teamDetails })),
  on(
    fromActions.addElementRhpSkill,
    (state): BlockRhpDialogState => ({
      ...state,
      teamDetails: {
        ...state.teamDetails,
        skills: [...(state.teamDetails.skills || []), {} as EntityLightData],
      },
    }),
  ),
);

export function blockRhpDialogReducer(state: BlockRhpDialogState | undefined, action: Action): BlockRhpDialogState {
  return reducer(state, action);
}
