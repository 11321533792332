import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum PgacActivityEnum {
  C = 'C',
  K = 'K',
  M = 'M',
  P = 'P',
  S = 'S',
  D = 'D',
  E = 'E',
  R = 'R',
  O = 'O',
  N = 'N',
  T = 'T',
  V = 'V',
  W = 'W',
}

export namespace PgacActivityEnum {
  const values: PgacActivityEnum[] = [
    PgacActivityEnum.C,
    PgacActivityEnum.D,
    PgacActivityEnum.E,
    PgacActivityEnum.K,
    PgacActivityEnum.M,
    PgacActivityEnum.N,
    PgacActivityEnum.O,
    PgacActivityEnum.P,
    PgacActivityEnum.R,
    PgacActivityEnum.S,
    PgacActivityEnum.T,
    PgacActivityEnum.V,
    PgacActivityEnum.W,
  ];

  export function toString(value: PgacActivityEnum): string {
    return value ? 'projects.pgacActivity.' + value : '';
  }

  export const multiSelectItemsResolver = (): Observable<MultiSelectItemData<PgacActivityEnum>[]> =>
    of(
      values.map(activity => ({
        value: activity,
        title: toString(activity),
      })),
    );
}
