import { UserNatureEnum } from '../../enums';
import { EntityLightData, UserLightData } from '../../models';

export interface AffectationInfoData {
  trigram: string;
  fullName: string;
  uuidEntity: string;
  tooltip?: string;
}

export namespace AffectationInfoData {
  export function mapFromEntityLightData(entity: EntityLightData): AffectationInfoData {
    return {
      uuidEntity: entity?.uuidEntity,
      trigram: entity?.name,
      fullName: entity?.tooltip,
      tooltip: entity?.tooltip,
    };
  }

  export function mapFromUserLightData(entity: UserLightData): AffectationInfoData {
    const nature = entity.natureUser === UserNatureEnum.DeviceExtern ? 'EXT' : 'INT';
    let fullName = entity?.trigram + ' - ' + entity?.firstName + ' ' + entity?.lastName;
    let trigram = entity.trigram?.toLocaleUpperCase();
    if ([UserNatureEnum.Device, UserNatureEnum.DeviceExtern].includes(entity.natureUser)) {
      fullName = `${nature} - ${entity.refExtern || entity.refIntern}`;
      trigram = entity.refExtern?.substring(0, 3)?.toLocaleUpperCase();
    }

    return {
      uuidEntity: entity?.uuidEntity,
      trigram: trigram,
      fullName,
      tooltip: fullName,
    };
  }
}
