import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FrequencyQuarterEnum {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  last = 5,
}

export namespace FrequencyQuarterEnum {
  const values = [FrequencyQuarterEnum.First, FrequencyQuarterEnum.Second, FrequencyQuarterEnum.Third, FrequencyQuarterEnum.Fourth, FrequencyQuarterEnum.last];

  const key = 'schedule.quarter.';
  export const toString = new SfxMap<FrequencyQuarterEnum, string>([
    [FrequencyQuarterEnum.First, key + 'first'],
    [FrequencyQuarterEnum.Second, key + 'second'],
    [FrequencyQuarterEnum.Third, key + 'third'],
    [FrequencyQuarterEnum.Fourth, key + 'fourth'],
    [FrequencyQuarterEnum.last, key + 'last'],
  ]);

  const expressionKey = 'schedule.quarter.expression.';
  export const toExpressionString = new SfxMap<FrequencyQuarterEnum, string>([
    [FrequencyQuarterEnum.First, expressionKey + 'first'],
    [FrequencyQuarterEnum.Second, expressionKey + 'second'],
    [FrequencyQuarterEnum.Third, expressionKey + 'third'],
    [FrequencyQuarterEnum.Fourth, expressionKey + 'fourth'],
    [FrequencyQuarterEnum.last, expressionKey + 'last'],
  ]);

  export const convertToApiValue = new SfxMap<FrequencyQuarterEnum, number>([
    [FrequencyQuarterEnum.First, 1],
    [FrequencyQuarterEnum.Second, 2],
    [FrequencyQuarterEnum.Third, 3],
    [FrequencyQuarterEnum.Fourth, 4],
    [FrequencyQuarterEnum.last, -1],
  ]);

  export const convertFromApiValue = new SfxMap<number | number[], FrequencyQuarterEnum>([
    [1, FrequencyQuarterEnum.First],
    [2, FrequencyQuarterEnum.Second],
    [3, FrequencyQuarterEnum.Third],
    [4, FrequencyQuarterEnum.Fourth],
    [-1, FrequencyQuarterEnum.last],
  ]);

  export const comparePredicate = (node: SingleSelectItemData<FrequencyQuarterEnum>, item: FrequencyQuarterEnum) => node.value === item;
  export const selectedItems = (): Observable<SingleSelectItemData<FrequencyQuarterEnum>[]> => of(values.map(quarter => ({ value: quarter, title: toString.getValue(quarter) })));
}
