import { compact } from 'lodash';

import { FormProjectSummaryResponseDTO } from '../../generated/models/models';

import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum FormNatureEnum {
  Model = 1,
  Generic = 2,
  Other = 3,
  Local = 4,
  Archived = 5,
}

export namespace FormNatureEnum {
  const getValues = (forLocal?: boolean): FormNatureEnum[] => compact([FormNatureEnum.Model, FormNatureEnum.Generic, forLocal && FormNatureEnum.Local]);

  export const convertFromApiValue: SfxMap<FormProjectSummaryResponseDTO.NatureEnum, FormNatureEnum> = new SfxMap<FormProjectSummaryResponseDTO.NatureEnum, FormNatureEnum>([
    [FormProjectSummaryResponseDTO.NatureEnum.Model, FormNatureEnum.Model],
    [FormProjectSummaryResponseDTO.NatureEnum.Generic, FormNatureEnum.Generic],
    [FormProjectSummaryResponseDTO.NatureEnum.Autre, FormNatureEnum.Other],
    [FormProjectSummaryResponseDTO.NatureEnum.LOCAL, FormNatureEnum.Local],
  ]);

  export const convertToApiValue: SfxMap<FormNatureEnum, FormProjectSummaryResponseDTO.NatureEnum> = new SfxMap<FormNatureEnum, FormProjectSummaryResponseDTO.NatureEnum>([
    [FormNatureEnum.Model, FormProjectSummaryResponseDTO.NatureEnum.Model],
    [FormNatureEnum.Generic, FormProjectSummaryResponseDTO.NatureEnum.Generic],
    [FormNatureEnum.Other, FormProjectSummaryResponseDTO.NatureEnum.Autre],
    [FormNatureEnum.Local, FormProjectSummaryResponseDTO.NatureEnum.LOCAL],
  ]);

  export const convertFromParamsValue: SfxMap<string, FormNatureEnum> = new SfxMap<string, FormNatureEnum>([
    ['model', FormNatureEnum.Model],
    ['generic', FormNatureEnum.Generic],
    ['other', FormNatureEnum.Other],
    ['local', FormNatureEnum.Local],
  ]);

  export const convertToParamsValue: SfxMap<FormNatureEnum, string> = new SfxMap<FormNatureEnum, string>([
    [FormNatureEnum.Model, 'model'],
    [FormNatureEnum.Generic, 'generic'],
    [FormNatureEnum.Other, 'other'],
    [FormNatureEnum.Local, 'local'],
  ]);

  const key = 'forms.formNature.';
  export const toString: SfxMap<FormNatureEnum, string> = new SfxMap<FormNatureEnum, string>(
    [
      [FormNatureEnum.Model, key + 'model'],
      [FormNatureEnum.Generic, key + 'generic'],
      [FormNatureEnum.Other, key + 'other'],
      [FormNatureEnum.Local, key + 'local'],
    ],
    'forms.filters.type',
  );

  const abbreviationkey = 'forms.formNature.abbreviation.';
  export const toLetter: SfxMap<FormNatureEnum, string> = new SfxMap<FormNatureEnum, string>([
    [FormNatureEnum.Model, abbreviationkey + 'model'],
    [FormNatureEnum.Generic, abbreviationkey + 'generic'],
    [FormNatureEnum.Other, abbreviationkey + 'other'],
    [FormNatureEnum.Local, abbreviationkey + 'local'],
  ]);

  export const color = (archived?: boolean) =>
    new SfxMap<FormNatureEnum, string>(
      [
        [FormNatureEnum.Model, archived ? 'light-grey' : 'form-model'],
        [FormNatureEnum.Generic, archived ? 'light-grey' : 'form-generic'],
        [FormNatureEnum.Other, archived ? 'light-grey' : 'melange-green'],
        [FormNatureEnum.Local, archived ? 'light-grey' : 'form-local'],
      ],
      'light-blue',
    );

  const infoKey = 'flows.modals.duplicateFlow.info.';
  export const displayedInfo: SfxMap<FormNatureEnum, string> = new SfxMap<FormNatureEnum, string>([
    [FormNatureEnum.Model, infoKey + 'content'],
    [FormNatureEnum.Generic, infoKey + 'content'],
    [FormNatureEnum.Other, infoKey + 'content'],
  ]);

  export const itemsResolver = (forLocal?: boolean): Observable<SingleSelectItemData<FormNatureEnum>[]> =>
    of(getValues(forLocal).map(status => ({ value: status, title: toString.getValue(status) })));

  export const selectSingleItemsForCreation = (genericFormIsAllowed: boolean, modelFormIsAllowed: boolean) => (): Observable<SingleSelectItemData<FormNatureEnum>[]> =>
    of(
      compact([modelFormIsAllowed && FormNatureEnum.Model, genericFormIsAllowed && FormNatureEnum.Generic]).map(status => ({
        value: status,
        title: toString.getValue(status),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FormNatureEnum>, item: FormNatureEnum) => node.value === item;
}
