import { compact } from 'lodash';
import { DateUtils } from '../../utils/date.utils';

import { SectionDocumentSummaryResponseDTO } from '../../generated/models/models';

import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { ClosingFolderLevelsEnum, DocumentProvenanceEnum, DocumentStatusEnum } from '../../enums';

export interface DocumentSummaryData {
  uuidEntity: string;
  name: string;
  subtitle?: string;
  refIntern: string;
  complementRefInt: string;
  order: number;
  docIndex: string;
  diffusionDate: string;
  status: DocumentStatusEnum;
  family: LabelFamilyLightData;
  level: ClosingFolderLevelsEnum;
  versionUuidEntity: string;
  refDocumentFlowUuidEntity: string;
  principalCategory?: LabelFamilyLightData;
  secondaryCategory?: LabelFamilyLightData;
  lastVdf: DocumentVersionLightData;
  provenance: DocumentProvenanceEnum;
  largeFile?: boolean;
  rfi?: boolean;
  sectionRfi?: boolean;
  customWording: CustomWordingsData;
}

export namespace DocumentSummaryData {
  const emptyDocumentIndex = '-';

  export function mapFromApiValue(document: SectionDocumentSummaryResponseDTO): DocumentSummaryData {
    return {
      uuidEntity: document.uuidEntity,
      name: document.name,
      subtitle: compact([document.flow?.refIntern, document.flow?.name]).join(' - '),
      refIntern: document.refInt,
      complementRefInt: document.complementRefInt,
      order: document.order,
      docIndex: document.indice || emptyDocumentIndex,
      diffusionDate: document.diffusionDate && DateUtils.toDateFormat(document.diffusionDate),
      status: DocumentStatusEnum.convertFromApiValue.getValue(document.etat),
      family: document.labelFamily && LabelFamilyLightData.mapFromApiValue(document.labelFamily),
      level: ClosingFolderLevelsEnum.Document,
      versionUuidEntity: document.versionUuidEntity,
      refDocumentFlowUuidEntity: document.refDocumentFlowUuidEntity,
      principalCategory: document.categoryPrincipal && LabelFamilyLightData.mapFromApiValue(document.categoryPrincipal),
      secondaryCategory: document.categorySecondary && LabelFamilyLightData.mapFromApiValue(document.categorySecondary),
      lastVdf: document?.lastVdf && DocumentVersionLightData.mapFromApiValue(document?.lastVdf),
      provenance: DocumentProvenanceEnum.convertFromApiValue.getValue(document.provenance),
      largeFile: document.largeFile,
      rfi: document.rfi,
      sectionRfi: document.sectionRfi,
      customWording: document.customWording && CustomWordingsData.mapFromApiValue(document.customWording),
    };
  }
}
