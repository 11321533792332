import { SfxMap } from '../../utils/enum-utils';

export enum BlockResponseColumnEnum {
  Provenance = 'provenance',
  Reference = 'reference',
  Status = 'status',
  Index = 'index',
  ResponseDate = 'responseDate',
  Actions = 'actions',
}

export namespace BlockResponseColumnEnum {
  export const allColumns = [
    BlockResponseColumnEnum.Provenance,
    BlockResponseColumnEnum.Reference,
    BlockResponseColumnEnum.Status,
    BlockResponseColumnEnum.Index,
    BlockResponseColumnEnum.ResponseDate,
    BlockResponseColumnEnum.Actions,
  ];

  const key = 'flowContentNavigation.form.dialogs.response.table.header.';
  export const toString: SfxMap<BlockResponseColumnEnum, string> = new SfxMap<BlockResponseColumnEnum, string>([
    [BlockResponseColumnEnum.Provenance, key + 'provenance'],
    [BlockResponseColumnEnum.Reference, key + 'reference'],
    [BlockResponseColumnEnum.Status, key + 'status'],
    [BlockResponseColumnEnum.Index, key + 'index'],
    [BlockResponseColumnEnum.ResponseDate, key + 'responseDate'],
    [BlockResponseColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<BlockResponseColumnEnum, string> = new SfxMap<BlockResponseColumnEnum, string>([
    [BlockResponseColumnEnum.Provenance, 'provenance'],
    [BlockResponseColumnEnum.Reference, 'reference'],
    [BlockResponseColumnEnum.Status, 'customStatus'],
    [BlockResponseColumnEnum.Index, 'index'],
    [BlockResponseColumnEnum.ResponseDate, 'dateResponse'],
  ]);
}
