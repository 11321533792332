import { compact } from 'lodash';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BlocViewDetailsResponseDTO, PagePhaseRequestDTO } from '../../generated/models/models';
import { BlockStatusCount } from '../../models';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockStatusEnum {
  Redaction = 1,
  Verification = 2,
  Approbation = 3,
  Validation = 4,
  Active = 5,
}

export namespace BlockStatusEnum {
  export const getValues = [BlockStatusEnum.Redaction, BlockStatusEnum.Verification, BlockStatusEnum.Approbation, BlockStatusEnum.Validation];
  export const chartValues = (active?: boolean) =>
    compact([
      !active && BlockStatusEnum.Redaction,
      !active && BlockStatusEnum.Verification,
      !active && BlockStatusEnum.Approbation,
      !active && BlockStatusEnum.Validation,
      active && BlockStatusEnum.Active,
    ]);

  export const whereBlockCanHaveInternHistory = [BlockStatusEnum.Verification, BlockStatusEnum.Approbation];

  export const convertFromApiValue: SfxMap<BlocViewDetailsResponseDTO.StatusBlocEnum, BlockStatusEnum> = new SfxMap([
    [BlocViewDetailsResponseDTO.StatusBlocEnum.REDACTION, BlockStatusEnum.Redaction],
    [BlocViewDetailsResponseDTO.StatusBlocEnum.VERIFICATION, BlockStatusEnum.Verification],
    [BlocViewDetailsResponseDTO.StatusBlocEnum.APROBATION, BlockStatusEnum.Approbation],
    [BlocViewDetailsResponseDTO.StatusBlocEnum.VALIDE, BlockStatusEnum.Validation],
  ]);

  type apiType = PagePhaseRequestDTO.StatusBlocEnum & BlocViewDetailsResponseDTO.StatusBlocEnum;
  export const convertToApiValue: SfxMap<BlockStatusEnum, apiType> = new SfxMap([
    [BlockStatusEnum.Redaction, BlocViewDetailsResponseDTO.StatusBlocEnum.REDACTION],
    [BlockStatusEnum.Verification, BlocViewDetailsResponseDTO.StatusBlocEnum.VERIFICATION],
    [BlockStatusEnum.Approbation, BlocViewDetailsResponseDTO.StatusBlocEnum.APROBATION],
    [BlockStatusEnum.Validation, BlocViewDetailsResponseDTO.StatusBlocEnum.VALIDE],
  ]);

  export const convertFromParamsValue: SfxMap<string, BlockStatusEnum> = new SfxMap([
    ['redaction', BlockStatusEnum.Redaction],
    ['verification', BlockStatusEnum.Verification],
    ['approbation', BlockStatusEnum.Approbation],
    ['validation', BlockStatusEnum.Validation],
  ]);

  export const convertToParamValue: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'redaction'],
    [BlockStatusEnum.Verification, 'verification'],
    [BlockStatusEnum.Approbation, 'approbation'],
    [BlockStatusEnum.Validation, 'validation'],
  ]);

  export const color: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, '#00B8D9'],
    [BlockStatusEnum.Verification, '#E46C0B'],
    [BlockStatusEnum.Approbation, '#B171C9'],
    [BlockStatusEnum.Validation, '#36B37E'],
    [BlockStatusEnum.Active, '#1F5086'],
  ]);

  export const styleClass: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'sfx-blue-inverted'],
    [BlockStatusEnum.Verification, 'sfx-dark-orange-inverted'],
    [BlockStatusEnum.Approbation, 'sfx-purple-inverted'],
    [BlockStatusEnum.Validation, 'sfx-light-green-inverted'],
  ]);

  export const toString: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'blocks.blockValidation.status.redaction'],
    [BlockStatusEnum.Verification, 'blocks.blockValidation.status.verification'],
    [BlockStatusEnum.Approbation, 'blocks.blockValidation.status.approval'],
    [BlockStatusEnum.Validation, 'blocks.blockValidation.status.validate'],
  ]);

  export const toChartString: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'dashboardMetrics.blocks.count.redaction'],
    [BlockStatusEnum.Verification, 'dashboardMetrics.blocks.count.verification'],
    [BlockStatusEnum.Approbation, 'dashboardMetrics.blocks.count.approbation'],
    [BlockStatusEnum.Validation, 'dashboardMetrics.blocks.count.validation'],
    [BlockStatusEnum.Active, 'dashboardMetrics.blocks.count.active'],
  ]);

  export const validationTooltip: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'flowContentNavigation.stepNavbar.tooltip.redaction'],
    [BlockStatusEnum.Verification, 'flowContentNavigation.stepNavbar.tooltip.verification'],
    [BlockStatusEnum.Approbation, 'flowContentNavigation.stepNavbar.tooltip.approbation'],
  ]);

  export const nextBlockStatus: SfxMap<BlockStatusEnum, BlockStatusEnum> = new SfxMap([
    [BlockStatusEnum.Redaction, BlockStatusEnum.Verification],
    [BlockStatusEnum.Verification, BlockStatusEnum.Approbation],
    [BlockStatusEnum.Approbation, BlockStatusEnum.Validation],
  ]);

  export const nextBlockValidationString: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'blocks.blockValidation.nextBlock.redaction'],
    [BlockStatusEnum.Verification, 'blocks.blockValidation.nextBlock.verification'],
    [BlockStatusEnum.Approbation, 'blocks.blockValidation.nextBlock.approval'],
  ]);

  export const retryBlockValidationString: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Redaction, 'blocks.blockValidation.retryBlock.redaction'],
    [BlockStatusEnum.Verification, 'blocks.blockValidation.retryBlock.verification'],
    [BlockStatusEnum.Approbation, 'blocks.blockValidation.retryBlock.approval'],
  ]);

  export const validationBlockConflictMessage: SfxMap<BlockStatusEnum, string> = new SfxMap([
    [BlockStatusEnum.Verification, 'blocks.blockValidation.ConflictMessage.verification'],
    [BlockStatusEnum.Approbation, 'blocks.blockValidation.ConflictMessage.approval'],
    [BlockStatusEnum.Validation, 'blocks.blockValidation.ConflictMessage.validation'],
  ]);

  export const selectChartItems = (counts: BlockStatusCount, active: boolean): SingleSelectItemData<BlockStatusEnum>[] =>
    chartValues(active).map(value => ({
      translateParams: { count: counts[value]?.toString() },
      title: toChartString.getValue(value),
      color: color.getValue(value),
      value: value,
    }));
}
