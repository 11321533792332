import { createAction, props } from '@ngrx/store';

import { CategoryDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { LabelFamilyNatureEnum } from '../../../../../core/enums';

export const checkForFirstParent = createAction('[dCategory] Check for first parent', props<{ nature: LabelFamilyNatureEnum }>());
export const checkForFirstParentSuccess = createAction('[dCategory] Check for first parent success', props<{ exist: boolean; keepCurrentType: boolean }>());
export const checkForFirstParentFail = createAction('[dCategory] Check for first parent fail', props<DialogToasterData>());

export const addCategory = createAction('[dCategory] add new category', props<{ category: CategoryDetailsData; addNewOne?: boolean }>());
export const addCategorySuccess = createAction('[dCategory] add new category success', props<{ category: CategoryDetailsData; addNewOne?: boolean }>());
export const addCategoryFail = createAction('[dCategory] add new category fail', props<DialogToasterData>());

export const updateCategory = createAction('[dCategory] update category', props<{ category: CategoryDetailsData; closeModal?: boolean }>());
export const updateCategorySuccess = createAction('[dCategory] update category success', props<{ category: CategoryDetailsData; closeModal?: boolean }>());
export const updateCategoryFail = createAction('[dCategory] update category fail', props<DialogToasterData>());

export const getCategoryDetailsSuccess = createAction('[dCategory] get category details success', props<{ category: CategoryDetailsData }>());
export const getCategoryDetailsFail = createAction('[dCategory] get category details fail', props<DialogToasterData>());

export const navigateThroughCategories = createAction('[dCategory] Navigate through categories', props<{ category: CategoryDetailsData; step: number }>());
