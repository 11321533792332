/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LabelApplicabilityResponseDTO {
  color?: string;
  fullName?: string;
  name?: string;
  priority?: number;
  type?: LabelApplicabilityResponseDTO.TypeEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}
export namespace LabelApplicabilityResponseDTO {
  export type TypeEnum = 'NORMAL' | 'ORGANIZATION';
  export const TypeEnum = {
    NORMAL: 'NORMAL' as TypeEnum,
    ORGANIZATION: 'ORGANIZATION' as TypeEnum,
  };
}
