import { ApplicationFileTmpSummaryResponseDTO, PageApplicationFileTmpRequestDTO } from '../../generated/models/models';

import { ApplicationFileTmpStatusEnum, FileTypeEnum, InnerSortDirectionEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface ApplicationFileTmpData {
  description: string;
  name: string;
  entityName: string;
  status: ApplicationFileTmpStatusEnum;
  documentType: FileTypeEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  createDate: string;
}

export namespace ApplicationFileTmpData {
  export type QueryRequest = BaseQuery<ApplicationFileTmpData>;

  export function mapFromApiValue(applicationFileTmp: ApplicationFileTmpSummaryResponseDTO): ApplicationFileTmpData {
    return {
      description: applicationFileTmp.description,
      name: applicationFileTmp.name,
      entityName: applicationFileTmp.entityName,
      status: ApplicationFileTmpStatusEnum.convertFromApiValue.getValue(applicationFileTmp.status),
      uuid: applicationFileTmp.uuid,
      uuidEntity: applicationFileTmp.uuidEntity,
      uuidOrganization: applicationFileTmp.uuidOrganization,
      uuidType: applicationFileTmp.uuidType,
      createDate: DateUtils.toEventDateTimeFormat(applicationFileTmp.createDate),
      documentType: FileTypeEnum.convertFromApiValue.getValue(applicationFileTmp.fileType),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageApplicationFileTmpRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
    };
  }

  export function initializeQueryRequest(): QueryRequest {
    return initializeQueries<ApplicationFileTmpData>({ key: 'createDate', direction: InnerSortDirectionEnum.Desc });
  }
}
