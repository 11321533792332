import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SchedulingResponseDTO } from '../../generated/models/schedulingResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum ScheduleStatusEnum {
  Active = 1,
  Inactive = 2,
  Paused = 3,
  Finished = 4,
}

export namespace ScheduleStatusEnum {
  export const values = [ScheduleStatusEnum.Active, ScheduleStatusEnum.Inactive];

  export const convertFromApiValue: SfxMap<SchedulingResponseDTO.StatusEnum, ScheduleStatusEnum> = new SfxMap<SchedulingResponseDTO.StatusEnum, ScheduleStatusEnum>([
    [SchedulingResponseDTO.StatusEnum.ACTIVE, ScheduleStatusEnum.Active],
    [SchedulingResponseDTO.StatusEnum.INACTIVE, ScheduleStatusEnum.Inactive],
    [SchedulingResponseDTO.StatusEnum.PAUSED, ScheduleStatusEnum.Paused],
    [SchedulingResponseDTO.StatusEnum.FINISHED, ScheduleStatusEnum.Finished],
  ]);

  export const convertToApiValue: SfxMap<ScheduleStatusEnum, SchedulingResponseDTO.StatusEnum> = new SfxMap<ScheduleStatusEnum, SchedulingResponseDTO.StatusEnum>([
    [ScheduleStatusEnum.Active, SchedulingResponseDTO.StatusEnum.ACTIVE],
    [ScheduleStatusEnum.Inactive, SchedulingResponseDTO.StatusEnum.INACTIVE],
    [ScheduleStatusEnum.Paused, SchedulingResponseDTO.StatusEnum.PAUSED],
    [ScheduleStatusEnum.Finished, SchedulingResponseDTO.StatusEnum.FINISHED],
  ]);

  export const convertToParamsValue: SfxMap<ScheduleStatusEnum, string> = new SfxMap<ScheduleStatusEnum, string>([
    [ScheduleStatusEnum.Active, 'active'],
    [ScheduleStatusEnum.Inactive, 'inactive'],
    [ScheduleStatusEnum.Paused, 'paused'],
    [ScheduleStatusEnum.Finished, 'finished'],
  ]);

  export const convertFromParamsValue: SfxMap<string, ScheduleStatusEnum> = new SfxMap<string, ScheduleStatusEnum>([
    ['active', ScheduleStatusEnum.Active],
    ['inactive', ScheduleStatusEnum.Inactive],
    ['paused', ScheduleStatusEnum.Paused],
    ['finished', ScheduleStatusEnum.Finished],
  ]);

  const filterKey = 'schedule.table.filters.state.';
  export const toString: SfxMap<ScheduleStatusEnum, string> = new SfxMap<ScheduleStatusEnum, string>([
    [ScheduleStatusEnum.Active, filterKey + 'active'],
    [ScheduleStatusEnum.Inactive, filterKey + 'inactive'],
    [ScheduleStatusEnum.Paused, filterKey + 'paused'],
    [ScheduleStatusEnum.Finished, filterKey + 'finished'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<ScheduleStatusEnum>[]> =>
    of(values.map(state => ({ value: state, title: ScheduleStatusEnum.toString.getValue(state) })));

  export const comparePredicate = (node: SingleSelectItemData<ScheduleStatusEnum>, item: ScheduleStatusEnum) => node.value === item;
}
