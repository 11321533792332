/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EntityChronoCreateRequestDTO {
  nature: EntityChronoCreateRequestDTO.NatureEnum;
  fieldName: EntityChronoCreateRequestDTO.FieldNameEnum;
  paternChrono: string;
  counterFormat: number;
  counterType?: EntityChronoCreateRequestDTO.CounterTypeEnum;
  startValue?: number;
  linkType: EntityChronoCreateRequestDTO.LinkTypeEnum;
  labelFamilyUuide?: string;
  applicabilities?: Array<string>;
}
export namespace EntityChronoCreateRequestDTO {
  export type NatureEnum =
    | 'PROJECT'
    | 'DOCUMENT_FLOW'
    | 'FLOW'
    | 'PHASE'
    | 'STEP'
    | 'BLOC'
    | 'PGAC'
    | 'CUSTOM_FIELD'
    | 'ELEMENT_RHP'
    | 'ELEMENT_ADR'
    | 'ELEMENT_EMM'
    | 'ELEMENT_DSI'
    | 'SIGNATORY'
    | 'TEAM_ROLE'
    | 'PARADE'
    | 'RUBRIC'
    | 'FORM'
    | 'QUESTION'
    | 'RUBRIC_OPTION'
    | 'OPTION_SIGNATORY'
    | 'APPLICATION_FILE'
    | 'FBL'
    | 'BLOC_DOCUMENT_FLOW'
    | 'APPLICATION'
    | 'USER'
    | 'RFI_TEMPLATE'
    | 'SKILL'
    | 'LABEL_FAMILY_SKILLS'
    | 'NAVIGATION_FLOW'
    | 'CUSTOM_STATUS'
    | 'GEOMETRY'
    | 'CUSTOM_FILE'
    | 'CUSTOM_FIELD_PROJECT'
    | 'CUSTOM_FIELD_FLOW'
    | 'TECHNICAL_OBJECT'
    | 'REVISION'
    | 'BLOC_PR'
    | 'BLOC_TXT'
    | 'ELEMENT_PR'
    | 'TEMPLATE_PARAMS'
    | 'CLIENT'
    | 'BLOC_MULTI_PR'
    | 'FORM_RESPONSE'
    | 'CUSTOM_FIELD_TECHNICAL_OBJECT'
    | 'FLOW_REFERENTIAL'
    | 'SCHEDULING_ITEM';
  export const NatureEnum = {
    PROJECT: 'PROJECT' as NatureEnum,
    DOCUMENTFLOW: 'DOCUMENT_FLOW' as NatureEnum,
    FLOW: 'FLOW' as NatureEnum,
    PHASE: 'PHASE' as NatureEnum,
    STEP: 'STEP' as NatureEnum,
    BLOC: 'BLOC' as NatureEnum,
    PGAC: 'PGAC' as NatureEnum,
    CUSTOMFIELD: 'CUSTOM_FIELD' as NatureEnum,
    ELEMENTRHP: 'ELEMENT_RHP' as NatureEnum,
    ELEMENTADR: 'ELEMENT_ADR' as NatureEnum,
    ELEMENTEMM: 'ELEMENT_EMM' as NatureEnum,
    ELEMENTDSI: 'ELEMENT_DSI' as NatureEnum,
    SIGNATORY: 'SIGNATORY' as NatureEnum,
    TEAMROLE: 'TEAM_ROLE' as NatureEnum,
    PARADE: 'PARADE' as NatureEnum,
    RUBRIC: 'RUBRIC' as NatureEnum,
    FORM: 'FORM' as NatureEnum,
    QUESTION: 'QUESTION' as NatureEnum,
    RUBRICOPTION: 'RUBRIC_OPTION' as NatureEnum,
    OPTIONSIGNATORY: 'OPTION_SIGNATORY' as NatureEnum,
    APPLICATIONFILE: 'APPLICATION_FILE' as NatureEnum,
    FBL: 'FBL' as NatureEnum,
    BLOCDOCUMENTFLOW: 'BLOC_DOCUMENT_FLOW' as NatureEnum,
    APPLICATION: 'APPLICATION' as NatureEnum,
    USER: 'USER' as NatureEnum,
    RFITEMPLATE: 'RFI_TEMPLATE' as NatureEnum,
    SKILL: 'SKILL' as NatureEnum,
    LABELFAMILYSKILLS: 'LABEL_FAMILY_SKILLS' as NatureEnum,
    NAVIGATIONFLOW: 'NAVIGATION_FLOW' as NatureEnum,
    CUSTOMSTATUS: 'CUSTOM_STATUS' as NatureEnum,
    GEOMETRY: 'GEOMETRY' as NatureEnum,
    CUSTOMFILE: 'CUSTOM_FILE' as NatureEnum,
    CUSTOMFIELDPROJECT: 'CUSTOM_FIELD_PROJECT' as NatureEnum,
    CUSTOMFIELDFLOW: 'CUSTOM_FIELD_FLOW' as NatureEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as NatureEnum,
    REVISION: 'REVISION' as NatureEnum,
    BLOCPR: 'BLOC_PR' as NatureEnum,
    BLOCTXT: 'BLOC_TXT' as NatureEnum,
    ELEMENTPR: 'ELEMENT_PR' as NatureEnum,
    TEMPLATEPARAMS: 'TEMPLATE_PARAMS' as NatureEnum,
    CLIENT: 'CLIENT' as NatureEnum,
    BLOCMULTIPR: 'BLOC_MULTI_PR' as NatureEnum,
    FORMRESPONSE: 'FORM_RESPONSE' as NatureEnum,
    CUSTOMFIELDTECHNICALOBJECT: 'CUSTOM_FIELD_TECHNICAL_OBJECT' as NatureEnum,
    FLOWREFERENTIAL: 'FLOW_REFERENTIAL' as NatureEnum,
    SCHEDULINGITEM: 'SCHEDULING_ITEM' as NatureEnum,
  };
  export type FieldNameEnum = 'REF_INT' | 'REF_EXT' | 'COMPLEMENT_REF_INT' | 'COMPLEMENT_REF_EXT';
  export const FieldNameEnum = {
    REFINT: 'REF_INT' as FieldNameEnum,
    REFEXT: 'REF_EXT' as FieldNameEnum,
    COMPLEMENTREFINT: 'COMPLEMENT_REF_INT' as FieldNameEnum,
    COMPLEMENTREFEXT: 'COMPLEMENT_REF_EXT' as FieldNameEnum,
  };
  export type CounterTypeEnum = 'GLOBAL' | 'LOCAL';
  export const CounterTypeEnum = {
    GLOBAL: 'GLOBAL' as CounterTypeEnum,
    LOCAL: 'LOCAL' as CounterTypeEnum,
  };
  export type LinkTypeEnum = 'DEFAULT' | 'ENTITY_LABEL_FAMILY' | 'ENTITY_LABEL_FAMILY_PARENT' | 'ENTITY_PARENT_LABEL_FAMILY' | 'ENTITY_PARENT_LABEL_FAMILY_PARENT';
  export const LinkTypeEnum = {
    DEFAULT: 'DEFAULT' as LinkTypeEnum,
    ENTITYLABELFAMILY: 'ENTITY_LABEL_FAMILY' as LinkTypeEnum,
    ENTITYLABELFAMILYPARENT: 'ENTITY_LABEL_FAMILY_PARENT' as LinkTypeEnum,
    ENTITYPARENTLABELFAMILY: 'ENTITY_PARENT_LABEL_FAMILY' as LinkTypeEnum,
    ENTITYPARENTLABELFAMILYPARENT: 'ENTITY_PARENT_LABEL_FAMILY_PARENT' as LinkTypeEnum,
  };
}
