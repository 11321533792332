import { ColorPickerPaletteEnum, LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum } from '../../enums';
import { DefaultCategoryDetailsResponseDTO, DefaultCategoryCreateRequestDTO, DefaultCategoryUpdateRequestDTO } from '../../generated/models/models';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface DefaultCategoryDetailsData {
  uuidEntity: string;
  nature: LabelFamilyNatureEnum;
  shortName: string;
  longName: string;
  color: string;
  relativeNature: LabelFamilyRelativeNatureEnum;
  parentCategory: LabelFamilyLightData;
}

export namespace DefaultCategoryDetailsData {
  export function mapFromApiValue(value: DefaultCategoryDetailsResponseDTO): DefaultCategoryDetailsData {
    return {
      uuidEntity: value.uuidEntity,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(value.nature),
      shortName: value.name,
      longName: value.fullName,
      color: value.color,
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(value.relativeNature),
      parentCategory: value.familyParent && LabelFamilyLightData.mapFromApiValue(value.familyParent),
    };
  }

  export function mapToCreateRequestApiValue(value: DefaultCategoryDetailsData): DefaultCategoryCreateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      color: value.color || ColorPickerPaletteEnum.defaultColor,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(value.nature),
      relativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(value.relativeNature),
      categoryParentUuidEntity: (value.parentCategory && value.parentCategory.uuidEntity) || undefined,
    };
  }

  export function mapToUpdateRequestApiValue(value: DefaultCategoryDetailsData): DefaultCategoryUpdateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      color: value.color,
      uuidEntity: value.uuidEntity,
    };
  }
}
