import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SignatureMethodEnum {
  Strong = 1,
  WithoutStrong = 2,
  Proxied = 3,
  NotListed = 4,
}

export namespace SignatureMethodEnum {
  const values = [SignatureMethodEnum.Strong, SignatureMethodEnum.WithoutStrong, SignatureMethodEnum.Proxied, SignatureMethodEnum.NotListed];
  export const authMethod = [SignatureMethodEnum.Strong, SignatureMethodEnum.WithoutStrong];

  const key = 'dashboardMetrics.signature.methods.';
  export const toString = new SfxMap<SignatureMethodEnum, string>([
    [SignatureMethodEnum.Strong, key + 'strong'],
    [SignatureMethodEnum.WithoutStrong, key + 'withoutStrong'],
    [SignatureMethodEnum.Proxied, key + 'proxied'],
    [SignatureMethodEnum.NotListed, key + 'notListed'],
  ]);

  export const convertToParamsValue = new SfxMap<SignatureMethodEnum, string>([
    [SignatureMethodEnum.Strong, 'strong'],
    [SignatureMethodEnum.WithoutStrong, 'any'],
    [SignatureMethodEnum.Proxied, 'proxied'],
    [SignatureMethodEnum.NotListed, 'notListed'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, SignatureMethodEnum>([
    ['strong', SignatureMethodEnum.Strong],
    ['any', SignatureMethodEnum.WithoutStrong],
    ['proxied', SignatureMethodEnum.Proxied],
    ['notListed', SignatureMethodEnum.NotListed],
  ]);

  export const multiSelectItemResolver = (): Observable<MultiSelectItemData<SignatureMethodEnum>[]> =>
    of(
      values.map(value => ({
        title: toString.getValue(value),
        value,
      })),
    );
}
