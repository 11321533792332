import { SfxMap } from '../../utils/enum-utils';

export enum FormColumnEnum {
  Family = 'family',
  Name = 'name',
  Reference = 'reference',
  Index = 'index',
  LastUpdate = 'lastUpdate',
  Status = 'status',
  Company = 'company',
  Actions = 'actions',
}

export namespace FormColumnEnum {
  export const allColumns = [
    FormColumnEnum.Family,
    FormColumnEnum.Name,
    FormColumnEnum.Reference,
    FormColumnEnum.Index,
    FormColumnEnum.LastUpdate,
    FormColumnEnum.Status,
    FormColumnEnum.Company,
    FormColumnEnum.Actions,
  ];
  export const formTableColumns = [FormColumnEnum.Family, FormColumnEnum.Name, FormColumnEnum.Reference, FormColumnEnum.Index, FormColumnEnum.LastUpdate, FormColumnEnum.Actions];

  export const importFormTableColumns = [
    FormColumnEnum.Family,
    FormColumnEnum.Name,
    FormColumnEnum.Reference,
    FormColumnEnum.Index,
    FormColumnEnum.Company,
    FormColumnEnum.LastUpdate,
    FormColumnEnum.Status,
  ];

  export const formLibraryTableColumns = [
    FormColumnEnum.Family,
    FormColumnEnum.Name,
    FormColumnEnum.Status,
    FormColumnEnum.Reference,
    FormColumnEnum.Index,
    FormColumnEnum.Company,
    FormColumnEnum.LastUpdate,
    FormColumnEnum.Actions,
  ];

  export const reorganizeColumns = [
    FormColumnEnum.Family,
    FormColumnEnum.Name,
    FormColumnEnum.Status,
    FormColumnEnum.Reference,
    FormColumnEnum.Index,
    FormColumnEnum.Company,
    FormColumnEnum.LastUpdate,
  ];

  const key = 'forms.table.header.';
  export const toString = new SfxMap<FormColumnEnum, string>([
    [FormColumnEnum.Family, key + 'family'],
    [FormColumnEnum.Name, key + 'name'],
    [FormColumnEnum.Reference, key + 'reference'],
    [FormColumnEnum.Index, key + 'index'],
    [FormColumnEnum.LastUpdate, key + 'lastUpdate'],
    [FormColumnEnum.Status, key + 'status'],
    [FormColumnEnum.Company, key + 'company'],
    [FormColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField = new SfxMap<FormColumnEnum, string>([
    [FormColumnEnum.Name, 'name'],
    [FormColumnEnum.Family, 'family'],
    [FormColumnEnum.Reference, 'reference'],
    [FormColumnEnum.Index, 'index'],
    [FormColumnEnum.LastUpdate, 'modificationDate'],
    [FormColumnEnum.Status, 'customStatus'],
  ]);
}
