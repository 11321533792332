import { createAction, props } from '@ngrx/store';

import {
  BlockDocumentData,
  CheckExistedReferenceRequest,
  CustomFieldData,
  DocumentFlowSummaryData,
  ErrorResponseData,
  FlowContentToImport,
  FlowDetailsData,
  FlowEventData,
  FlowSummaryData,
  FlowSummaryForStepData,
  LazyLoadResponse,
  PhaseDetailsData,
  PhaseSummaryData,
  PhaseToDuplicateData,
  RevisionEventSummaryData,
  RevisionSummaryData,
  SequencePhaseSummaryData,
  SequenceSummaryData,
  StepDetailsData,
  StepNavigationData,
  StepToDuplicateData,
  StepToMoveData,
  ValidateAllBlocksData,
} from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { ConflictData } from '../../../../shared/interfaces/conflict-data';

import { DocumentProvenanceEnum, ModalIdEnum, PopoverPositionEnum } from '../../../../../core/enums';

// Flow content actions
export const validateBlocks = createAction('[FlowContent/dialog] validate blocks', props<{ validationData: ValidateAllBlocksData; comment?: string }>());
export const allBlocksToRedaction = createAction('[FlowContent/dialog] back to redaction blocks', props<{ validationData: ValidateAllBlocksData; comment?: string }>());
export const validateBlocksSuccess = createAction('[FlowContent/dialog] validate blocks success');
export const validateBlocksFail = createAction('[FlowContent/dialog] validate blocks fail', props<DialogToasterData>());

// Phase actions
export const loadPhaseDetails = createAction('[Step/dialog] load phase details details', props<{ fromStepModal?: boolean }>());
export const loadPhaseDetailsSuccess = createAction('[Phase/dialog] load phase details success', props<{ phase: PhaseDetailsData }>());
export const loadPhaseDetailsFail = createAction('[Phase/dialog] load phase details fail', props<DialogToasterData>());

export const updatePhase = createAction('[Phase/dialog] update phase', props<{ phase: PhaseDetailsData; close?: boolean }>());
export const updatePhaseSuccess = createAction('[Phase/dialog] update phase success', props<{ phase: PhaseDetailsData }>());
export const updatePhaseFail = createAction('[Phase/dialog] update phase fail', props<DialogToasterData>());

export const createPhase = createAction('[Phase/dialog] create phase', props<{ phase: PhaseDetailsData; addNewOne?: boolean }>());
export const createPhaseSuccess = createAction('[Phase/dialog] create phase success');
export const createPhaseFail = createAction('[Phase/dialog] create phase fail', props<DialogToasterData>());

export const navigateThroughPhases = createAction('[Phase/dialog] Navigate through phases', props<{ step: number }>());
export const checkBeforeNavigateThroughPhases = createAction(
  '[Phase/dialog] Check before navigate through phases',
  props<{ phase: PhaseDetailsData; step: number; updatePhase?: boolean; updateCustomFields?: boolean; customFields?: CustomFieldData[] }>(),
);

export const duplicatePhase = createAction('[Phase/dialog] duplicate phase', props<{ data: PhaseToDuplicateData }>());
export const duplicatePhaseSuccess = createAction('[Phase/dialog] duplicate phase success', props<{ phaseUuidEntity: string; stepUuidEntity: string }>());
export const duplicatePhaseFail = createAction('[Phase/dialog] duplicate phase fail', props<DialogToasterData>());

export const movePhase = createAction('[Phase/dialog] move phase', props<{ siblingPhaseUuidEntity: string }>());
export const movePhaseSuccess = createAction('[Phase/dialog] move phase success');
export const movePhaseFail = createAction('[Phase/dialog] move phase fail', props<DialogToasterData>());

export const deletePhase = createAction('[Phase/dialog] delete phase');
export const deletePhaseFail = createAction('[Phase/dialog] delete phase fail', props<DialogToasterData>());

export const loadPhaseCustomFields = createAction('[Phase/dialog] load phase custom fields', props<{ phaseUuidEntity: string }>());
export const loadPhaseCustomFieldsSuccess = createAction('[Phase/dialog] load phase custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadPhaseCustomFieldsFail = createAction('[Phase/dialog] load phase custom fields fail', props<DialogToasterData>());
export const savePhaseCustomFields = createAction(
  '[Phase/dialog] save phase custom fields',
  props<{ phaseUuidEntity: string; customFields: CustomFieldData[]; closeModal: boolean }>(),
);
export const savePhaseCustomFieldsFail = createAction('[Phase/dialog] save phase custom fields fail', props<DialogToasterData>());

// Step actions
export const loadStepDetails = createAction('[Step/dialog] load step details');
export const loadStepDetailsSuccess = createAction('[Step/dialog] load step details success', props<{ step: StepDetailsData }>());
export const loadStepDetailsFail = createAction('[Step/dialog] load step details fail', props<DialogToasterData>());

export const updateStep = createAction('[Step/dialog] update step', props<{ step: StepDetailsData; close?: boolean }>());
export const updateStepSuccess = createAction('[Step/dialog] update step success', props<{ step: StepDetailsData }>());
export const updateStepFail = createAction('[Step/dialog] update step fail', props<DialogToasterData>());

export const createStep = createAction('[Step/dialog] create step', props<{ step: StepDetailsData; addNewOne?: boolean }>());
export const createStepSuccess = createAction('[Step/dialog] create step success', props<{ addNewOne: boolean }>());
export const createStepFail = createAction('[Step/dialog] create step fail', props<DialogToasterData>());

export const navigateThroughSteps = createAction('[Step/dialog] Navigate through steps', props<{ step: number }>());
export const checkBeforeNavigateThroughSteps = createAction(
  '[Step/dialog] Check before navigate through steps',
  props<{ stepDetails: StepDetailsData; step: number; updateStep?: boolean; updateCustomFields?: boolean; customFields?: CustomFieldData[] }>(),
);

export const duplicateStep = createAction('[Step/dialog] duplicate step', props<{ data: StepToDuplicateData }>());
export const duplicateStepSuccess = createAction('[Step/dialog] duplicate step success', props<{ phaseUuidEntity: string; stepUuidEntity: string }>());
export const duplicateStepFail = createAction('[Step/dialog] duplicate step fail', props<DialogToasterData>());

export const moveStep = createAction('[Step/dialog] move step', props<{ moveStepData: StepToMoveData }>());
export const moveStepSuccess = createAction('[Step/dialog] move step success');
export const moveStepFail = createAction('[Step/dialog] move step fail', props<DialogToasterData>());

export const deleteStep = createAction('[Step/dialog] delete step', props<{ uuidEntity: string }>());
export const deleteStepSuccess = createAction('[Step/dialog] delete step success');
export const deleteStepFail = createAction('[Step/dialog] delete step fail', props<ConflictData>());

export const loadStepNavigation = createAction('[Step/dialog] load step navigation');
export const loadMoreStepNavigation = createAction('[Step/dialog] load more step navigation');
export const loadStepNavigationSuccess = createAction(
  '[Step/dialog] load step navigation success',
  props<{ navigation: LazyLoadResponse<StepNavigationData[]>; reset?: boolean }>(),
);
export const loadStepNavigationFail = createAction('[Step/dialog] load step navigation fail', props<DialogToasterData>());

export const deleteStepNavigation = createAction('[Step/dialog] delete step navigation', props<{ uuidEntity: string }>());
export const deleteStepNavigationSuccess = createAction('[Step/dialog] delete step navigation success');
export const deleteStepNavigationFail = createAction('[Step/dialog] delete step navigation fail', props<DialogToasterData>());

export const createStepNavigation = createAction('[Step/dialog] create step navigation', props<{ navigation: StepNavigationData }>());
export const createStepNavigationSuccess = createAction('[Step/dialog] create step navigation success');
export const createStepNavigationFail = createAction('[Step/dialog] create step navigation fail', props<DialogToasterData>());

export const updateStepNavigation = createAction('[Step/dialog] update step navigation', props<{ navigation: StepNavigationData }>());
export const updateStepNavigationSuccess = createAction('[Step/dialog] update step navigation success', props<{ navigation: StepNavigationData }>());
export const updateStepNavigationFail = createAction('[Step/dialog] update step navigation fail', props<DialogToasterData>());

export const addNewStepNavigation = createAction('[Step/dialog] add new step navigation', props<{ navigation: StepNavigationData }>());

export const moveStepNavigation = createAction('[Step/dialog] move step navigation', props<{ uuidEntity: string; siblingUuidEntity: string }>());
export const moveStepNavigationFail = createAction('[Step/dialog] move step navigation fail', props<DialogToasterData>());

export const loadStepCustomFields = createAction('[Step/dialog] load step custom fields', props<{ stepUuidEntity: string }>());
export const loadStepCustomFieldsSuccess = createAction('[Step/dialog] load step custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadStepCustomFieldsFail = createAction('[Step/dialog] load step custom fields fail', props<DialogToasterData>());
export const saveStepCustomFields = createAction(
  '[Step/dialog] save step custom fields',
  props<{ stepUuidEntity: string; customFields: CustomFieldData[]; closeModal: boolean }>(),
);
export const saveStepCustomFieldsFail = createAction('[Step/dialog] save step custom fields fail', props<DialogToasterData>());

export const loadLinkFlowsList = createAction('[Step/dialog] Load link flows list');
export const loadNextLinkFlowsListPage = createAction('[Step/dialog] Load link flows list page');
export const loadLinkFlowsListSuccess = createAction(
  '[Step/dialog] Load link flows success',
  props<{ linkFlows: FlowSummaryForStepData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadLinkFlowsListFail = createAction('[Step/dialog] Load link flows list fail', props<DialogToasterData>());

export const linkFlow = createAction('[Step/dialog] link flow', props<{ flowUuidEntity: string }>());
export const linkFlowSuccess = createAction('[Step/dialog] link flow success');
export const linkFlowFail = createAction('[Step/dialog] link flow fail', props<DialogToasterData>());

export const resetImportFlowList = createAction('[Step/dialog] reset import flow list');
export const loadImportFlowsList = createAction('[Step/dialog] Load import flows list');
export const loadNextImportFlowsListPage = createAction('[Step/dialog] Load import flows list page');
export const loadImportFlowsListSuccess = createAction(
  '[Step/dialog] Load imports flows success',
  props<{ flows: FlowSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadImportFlowsListFail = createAction('[Step/dialog] Load imports flows list fail', props<DialogToasterData>());

export const importFlow = createAction('[Flow/dialog] import flow', props<{ flowSourceUuidEntity: string; phase: PhaseSummaryData }>());
export const importFlowForm = createAction(
  '[Flow/dialog] import flow form',
  props<{ projectUuidEntity: string; flowSourceUuidEntity: string; flowUuidEntity: string; importRequest: FlowContentToImport }>(),
);
export const importFlowSuccess = createAction('[Flow/dialog] import flow success', props<{ importedFlowUuidEntity: string }>());
export const importFlowFail = createAction('[Flow/dialog] import flow fail', props<ErrorResponseData>());

// Flow dialog actions
export const addFlow = createAction('[Flow/dialog] add new flow', props<{ flow: FlowDetailsData; closeModal?: boolean; nextTab?: string }>());
export const addFlowSuccess = createAction('[Flow/dialog] add new flow success', props<{ flowDetails: FlowDetailsData; closeModal?: boolean; nextTab?: string }>());
export const addFlowFail = createAction('[Flow/dialog] add new flow fail', props<DialogToasterData>());

export const updateFlow = createAction('[Flow/dialog] update flow', props<{ flow: FlowDetailsData; closeModal?: boolean; createModal?: boolean }>());
export const updateFlowSuccess = createAction('[Flow/dialog] update flow success', props<{ flowDetails: FlowDetailsData; closeModal?: boolean; createModal?: boolean }>());
export const updateFlowFail = createAction('[Flow/dialog] update flow fail', props<DialogToasterData>());

export const saveFlowCustomFields = createAction('[Flow/dialog] save flow custom fields', props<{ customFields: CustomFieldData[]; closeModal?: boolean }>());
export const saveFlowCustomFieldsSuccess = createAction('[Flow/dialog] save flow custom fields success', props<{ closeModal?: boolean }>());
export const saveFlowCustomFieldsFail = createAction('[Flow/dialog] save flow custom fields fail', props<DialogToasterData>());

export const getFlowDetailsSuccess = createAction('[Flow/dialog] get flow details success', props<{ flowDetails: FlowDetailsData }>());
export const getFlowDetailsFail = createAction('[Flow/dialog] get flow details fail', props<DialogToasterData>());

export const loadFlowCustomFields = createAction('[Flow/dialog] load flow custom fields', props<{ flow: FlowDetailsData }>());
export const loadFlowCustomFieldsSuccess = createAction('[Flow/dialog] load flow custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadFlowCustomFieldsFail = createAction('[Flow/dialog] load flow custom fields fail', props<DialogToasterData>());

export const initializeFlowDetails = createAction('[Flow/dialog] initialize flow details', props<{ flowDetails: FlowDetailsData }>());
export const initializeFlowCustomFields = createAction('[Flow/dialog] initialize flow custom fields', props<{ customFields: CustomFieldData[] }>());

export const saveChangesFlowDialog = createAction(
  '[Flow/dialog] save changes flow dialog',
  props<{ flow: FlowDetailsData; customFields: CustomFieldData[]; nextTab: string; editMode: boolean }>(),
);

export const saveAll = createAction(
  '[Flow/dialog] save all (flow and custom fields)',
  props<{
    flow: FlowDetailsData;
    initialFlow: FlowDetailsData;
    customFields: CustomFieldData[];
    initialCustomFields: CustomFieldData[];
    close?: boolean;
    nextTab?: string;
    createModal?: boolean;
  }>(),
);

export const checkReferenceGenerated = createAction('[Flow/dialog] check reference generated', props<CheckExistedReferenceRequest>());
export const checkReferenceGeneratedFail = createAction('[Flow/dialog] check reference generated fail', props<DialogToasterData>());
export const checkReferenceGeneratedSuccess = createAction('[Flow/dialog] check reference generated success', props<{ hasReferenceGenerated: boolean }>());

export const loadFlowDocuments = createAction('[Flow/dialog] load flow documents', props<{ flowDetails: FlowDetailsData }>());
export const loadFlowDocumentsFail = createAction('[Flow/dialog] load flow documents fail', props<DialogToasterData>());
export const loadFlowDocumentsSuccess = createAction('[Flow/dialog] load flow documents success', props<{ flowDocumentBlocks: BlockDocumentData[] }>());

export const generateFlowDocuments = createAction('[Flow/dialog] generate flow documents', props<{ flowDocumentBlocks: BlockDocumentData[]; flowDetails: FlowDetailsData }>());
export const generateFlowDocumentsFail = createAction('[Flow/dialog] generate flow documents fail', props<DialogToasterData>());
export const generateFlowDocumentsSuccess = createAction('[Flow/dialog] generate flow documents success', props<{ flowDocuments: DocumentFlowSummaryData[] }>());

export const viewFlowContentDocument = createAction(
  '[Flow/dialog] view flow document',
  props<{ docUuidEntity: string; versionUuidEntity: string; documentProvenance?: DocumentProvenanceEnum }>(),
);
export const viewFlowContentDocumentFail = createAction('[Flow/dialog] view flow document fail', props<DialogToasterData>());
export const viewFlowContentDocumentSuccess = createAction('[Flow/dialog] view flow document success');

// Revision
export const loadRevisionEvents = createAction('[Flow/dialog] load revision event');
export const loadMoreRevisionEvents = createAction('[Flow/dialog] load more revision event');
export const openRevisionRevision = createAction(
  '[Flow/dialog] open edit revision',
  props<{ origin: HTMLElement; data: RevisionSummaryData; editMode?: boolean; position?: PopoverPositionEnum }>(),
);
export const loadRevisionEventsFail = createAction('[Flow/dialog] load revision event fail', props<DialogToasterData>());
export const loadRevisionEventsSuccess = createAction(
  '[Flow/dialog] load revision event success',
  props<{ revisionEvents: LazyLoadResponse<RevisionEventSummaryData[]>; reset?: boolean }>(),
);

export const closeRevision = createAction('[Flow/dialog] close revision', props<{ uuidEntity: string }>());

export const openRevision = createAction('[Flow/dialog] open revision', props<{ uuidEntity: string }>());

export const findRevision = createAction('[Flow/dialog] find revision');
export const findRevisionSuccess = createAction('[Flow/dialog] find revision success', props<{ revision: RevisionSummaryData }>());
export const findRevisionFail = createAction('[Flow/dialog] find revision fail', props<{ error: ErrorResponseData }>());

// disable-enable and web-only phase-step
export const toggleStepMobileAppReadOnly = createAction('[Flow/dialog] toggle step web only', props<{ mobileAppReadOnly: boolean }>());
export const toggleStepState = createAction('[Flow/dialog] toggle step state', props<{ disable: boolean; comment: string }>());
export const toggleStepSuccess = createAction('[Flow/dialog] toggle step success', props<{ disable?: boolean; mobileAppReadOnly?: boolean; phaseUuidEntity?: string }>());
export const toggleStepFail = createAction('[Flow/dialog] toggle step fail', props<{ error: ErrorResponseData }>());

export const togglePhaseMobileAppReadOnly = createAction('[Flow/dialog] toggle phase web only', props<{ mobileAppReadOnly: boolean }>());
export const togglePhaseState = createAction('[Flow/dialog] toggle phase state', props<{ disable: boolean; comment: string }>());
export const togglePhaseSuccess = createAction('[Flow/dialog] toggle phase success', props<{ disable?: boolean; mobileAppReadOnly?: boolean }>());
export const togglePhaseFail = createAction('[Flow/dialog] toggle phase fail', props<{ error: ErrorResponseData }>());

/* HACK: to resolve close second modal from effects, issue, will be fixed when migrating to angular 11, to be removed later */
export const closeFlowContentModal = createAction('[Flow/dialog] close modal', props<{ modalId: ModalIdEnum }>());

export const openSequence = createAction(
  '[Flow/dialog] open edit sequence',
  props<{ origin: HTMLElement; data: SequenceSummaryData; editMode?: boolean; position?: PopoverPositionEnum }>(),
);
export const deleteSequence = createAction('[Sequence/dialog] delete sequence', props<{ uuidEntity: string }>());
export const deleteSequenceSuccess = createAction('[Sequence/dialog] delete sequence success');

export const loadSequencePhases = createAction('[Sequence/dialog] load sequence phases');
export const loadMoreSequencePhases = createAction('[Sequence/dialog] load more sequence phases');
export const loadSequencePhasesSuccess = createAction(
  '[Sequence/dialog] load sequence phases success',
  props<{ sequencePhases: LazyLoadResponse<SequencePhaseSummaryData[]>; reset?: boolean }>(),
);
export const changeSequence = createAction('[Sequence/dialog] change sequence', props<{ sequence: SequenceSummaryData }>());

export const updateSequencePhases = createAction('[Sequence/dialog] update sequence phases', props<{ uuidEntities: string[] }>());
export const linkSequence = createAction('[Sequence/dialog] link sequence', props<{ sequenceUuidEntity: string; stepUuidEntity: string; unlink?: boolean }>());

export const setNodeOrder = createAction('[Step/dialog] set node order', props<{ nodeOrder: number }>());

export const getViewHistory = createAction('[Flow/dialog] view history', props<{ flowUuidEntity }>());
export const getViewHistorySuccess = createAction('[Flow/dialog] view history success', props<{ events: LazyLoadResponse<FlowEventData[]>; reset?: boolean }>());
export const getViewHistoryFail = createAction('[Flow/dialog] view history fail', props<DialogToasterData>());

export const getFlowDetailsForHistory = createAction('[Flow/dialog] get flow details for history', props<{ flowUuidEntity }>());
export const getFlowDetailsForHistorySuccess = createAction('[Flow/dialog] get flow details for history success', props<{ flowDetails: FlowDetailsData }>());
export const getFlowDetailsForHistoryFail = createAction('[Flow/dialog] get flow details for history fail', props<DialogToasterData>());

export const getFlowDetailsForHistoryLoadMore = createAction('[Flow/dialog] get flow details for history load more');

export const openFilterDatePopover = createAction('[Flow/dialog] open field flow history filter date popover', props<{ origin: HTMLElement }>());

export const openOrphansResponsesFilterDatePopover = createAction('[Flow/dialog] open orphans responses date filter popover', props<{ origin: HTMLElement }>());
