import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

import { TooltipPositionType } from '../../interfaces/tooltip-data';

const bannedHtmlTagsFromHighlight = ['</strong>', '<br>', '</br>', '<br/>', '</a>'];
@Directive({
  selector: '[sfxTooltip]',
  standalone: true,
})
export class SfxTooltipDirective {
  @Input() position: TooltipPositionType = 'bottom';
  @Input() offset = 5;
  @Input() onTruncated = false;
  @Input() sfxTooltipCss: string;
  @Input() isTextContent: boolean;
  @Input() enableTag: boolean;
  @Input() set sfxTooltip(tooltip: string) {
    this._sfxTooltip = tooltip ? tooltip + '' : '';
    if (this.tooltipNode) {
      if (bannedHtmlTagsFromHighlight.some(tag => this._sfxTooltip.includes(tag))) {
        this.tooltipNode.innerHTML = this._sfxTooltip;
      } else {
        this.tooltipNode.innerText = this._sfxTooltip;
      }
    }
  }
  @Input() set delay(delay: number) {
    this._delay = delay;
    if (this.elementRef.nativeElement) {
      this.elementRef.nativeElement.style.setProperty('--delay', this._delay + 'ms');
    }
  }

  private _sfxTooltip: string;
  private _delay = 300;
  private timer: NodeJS.Timer;
  private tooltipNode: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    this.cleanTooltip();
    clearTimeout(this.timer);

    if (this._sfxTooltip) {
      const truncatedElement = this.elementRef.nativeElement;
      try {
        this.tooltipNode = this.renderer.createElement('span');

        if (this.isTextContent) {
          this.tooltipNode.textContent = this._sfxTooltip;
        } else if (this.enableTag) {
          this.tooltipNode.innerHTML = this._sfxTooltip;
        } else {
          if (bannedHtmlTagsFromHighlight.some(tag => this._sfxTooltip.includes(tag))) {
            this.tooltipNode.textContent = this._sfxTooltip;
          } else {
            this.tooltipNode.innerText = this._sfxTooltip;
          }
        }

        this.renderer.addClass(this.tooltipNode, 'sfx-tooltip');
        this.renderer.addClass(this.tooltipNode, this.position);

        // The tooltipNode should has the same font size of ElementRef in order to compare the fit content
        if (this.onTruncated) {
          this.renderer.setStyle(this.tooltipNode, 'fontSize', window.getComputedStyle(truncatedElement, null).getPropertyValue('font-size'));
        }

        this.renderer.setStyle(this.tooltipNode, 'opacity', '0');
        this.elementRef.nativeElement.style.setProperty('--delay', this._delay + 'ms');
        this.renderer.appendChild(document.body, this.tooltipNode);
      } catch {
        console.info('%c*** Tooltip crushed! ***', 'color: red');
      }

      if (!this.onTruncated || (truncatedElement && this.tooltipNode.clientWidth > truncatedElement.clientWidth)) {
        this.timer = setTimeout(() => {
          this.renderer.addClass(this.tooltipNode, 'default-tooltip-styles');
          if (this.sfxTooltipCss) {
            this.sfxTooltipCss.split(' ').forEach(cssClass => this.renderer.addClass(this.tooltipNode, cssClass));
          }
          this.setPosition();
          this.renderer.setStyle(this.tooltipNode, 'opacity', '1');
        }, this._delay);
      }
    }
  }

  @HostListener('click')
  @HostListener('mouseup')
  @HostListener('mouseleave')
  onMouseLeave(): void {
    clearTimeout(this.timer);
    if (this.tooltipNode) {
      this.renderer.removeChild(document.body, this.tooltipNode);
    }
  }

  private setPosition(): void {
    const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltipNode.getBoundingClientRect();
    const scrollPos = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top: number, left: number;
    if (this.position === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    } else if (this.position === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    } else if (this.position === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    } else if (this.position === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    } else if (this.position === 'start') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left;
    }

    this.renderer.setStyle(this.tooltipNode, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltipNode, 'left', `${left}px`);
  }

  private cleanTooltip(): void {
    const lastTooltip = document.body.querySelectorAll('.sfx-tooltip').length;
    if (lastTooltip > 1) {
      document.body.querySelectorAll('.sfx-tooltip').forEach(element => {
        this.renderer.removeChild(document.body, element);
      });
    }
  }
}
