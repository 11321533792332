import { compact } from 'lodash';

import {
  LabelFamilyTreeAffectationRequestDTO,
  LabelFamilyLightResponseDTO,
  LabelFamilyTreeFilterRequestDTO,
  LabelFamilyTechnicalObjectFlowFilterRequestDTO,
  LabelFamilyProjectDocumentFilterRequestDTO,
  LabelFamilyParentsAffectationRequestDTO,
  LabelFamilyLibraryCategoryFilterRequestDTO,
  LabelFamilyLibraryUserWithUuidEntityFilterRequestDTO,
  LabelFamilyProjectWithUserUuidEntityFilterRequestDTO,
  LabelFamilyLinkFlowFilterRequestDTO,
} from '../../generated/models/models';

import { SearchStrategyEnum } from '../../../modules/shared/components/select-v2/utils/search-strategy.enum';

import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyNatureEnum, UserTypeEnum } from '../../enums';
import { LabelFamilyFlowFilterRequestDTO } from '../../generated/models/labelFamilyFlowFilterRequestDTO';
import { LabelFamilyLibraryUserFilterRequestDTO } from '../../generated/models/labelFamilyLibraryUserFilterRequestDTO';
import { LabelFamilyPlanTreeFilterRequestDTO } from '../../generated/models/labelFamilyPlanTreeFilterRequestDTO';
import { CategoryDocumentRfiRequestDTO } from '../../generated/models/categoryDocumentRfiRequestDTO';

export namespace DropdownLabelFamilyData {
  export function mapToSelectFilterItemData(labelFamily: LabelFamilyLightResponseDTO, parentUuidEntity?: string): MultiSelectItemData {
    return {
      title: compact([labelFamily.name, labelFamily.fullName]).join(' - '),
      value: labelFamily.uuidEntity,
      children: (labelFamily.children || []).map(child => mapToSelectFilterItemData(child, labelFamily.uuidEntity)),
      parent: parentUuidEntity ? ({ value: parentUuidEntity } as MultiSelectItemData) : undefined,
    };
  }

  export function mapToSelectAffectationItemData(labelFamily: LabelFamilyLightResponseDTO, parent?: LabelFamilyLightResponseDTO): SingleSelectItemData {
    return {
      title: compact([labelFamily.name, labelFamily.fullName]).join(' - '),
      subtitle: getSubTitle(labelFamily.clientSuperVision, labelFamily.clientSuperVisionSubIndex),
      value: { name: compact([labelFamily.name, labelFamily.fullName]).join(' - '), uuidEntity: labelFamily.uuidEntity, parentName: parent && parent.name },
      children: (labelFamily.children || []).map(child => mapToSelectAffectationItemData(child, labelFamily)),
      parent: parent ? { uuidEntity: parent.uuidEntity } : undefined,
    };
  }

  export function mapToProjectApiFilterRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, projectUuidEntity: string): LabelFamilyTreeFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, projectUuidEntity };
  }

  export function mapToPlanApiFilterRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, planUuidEntity: string): LabelFamilyPlanTreeFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, planUuidEntity };
  }

  export function mapToFlowLinkedApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, flowUuidEntity: string): LabelFamilyLinkFlowFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, flowUuidEntity };
  }

  export function mapToDocumentApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    projectUuidEntity: string,
    extern?: boolean,
  ): LabelFamilyProjectDocumentFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, projectUuidEntity, extern };
  }

  export function mapToRfiApiFilterRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, sectionUuidEntity: string): CategoryDocumentRfiRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, sectionUuidEntity };
  }

  export function mapToListApiFilterRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, listUuidEntity: string): LabelFamilyFlowFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, listUuidEntity };
  }

  export function mapToUserApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    userType: UserTypeEnum,
    filterOnActivated: boolean,
  ): LabelFamilyLibraryUserFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, choixTypeUser: UserTypeEnum.convertToApiValue.getValue(userType), filterOnActivated };
  }

  export function mapToLibraryUserFilterApiRequest(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    userType: UserTypeEnum,
    userUuidEntity: string,
    filterOnActivated: boolean,
  ): LabelFamilyLibraryUserWithUuidEntityFilterRequestDTO {
    return {
      textSearch,
      searchOnChildren: searchStrategy === SearchStrategyEnum.Child,
      choixTypeUser: UserTypeEnum.convertToApiValue.getValue(userType),
      filterOnActivated,
      userUuidEntity,
    };
  }

  export function mapToProjectContributorFilterApiRequest(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    userUuidEntity: string,
    projectUuidEntity: string,
  ): LabelFamilyProjectWithUserUuidEntityFilterRequestDTO {
    return {
      textSearch,
      searchOnChildren: searchStrategy === SearchStrategyEnum.Child,
      userUuidEntity,
      projectUuidEntity,
    };
  }

  export function mapToLibraryApiFilterRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum): LabelFamilyLibraryCategoryFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child };
  }

  export function mapToFlowTechnicalObjectApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    technicalObjectUuidEntity: string,
  ): LabelFamilyTechnicalObjectFlowFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, technicalObjectUuidEntity };
  }

  export function mapToApiAffectationRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum): LabelFamilyTreeAffectationRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child };
  }

  export function mapToApiParentAffectationRequestValue(textSearch: string, nature: LabelFamilyNatureEnum): LabelFamilyParentsAffectationRequestDTO {
    return { textSearch, choixNatureLabel: LabelFamilyNatureEnum.convertToApiValue.getValue(nature) };
  }

  export function getSubTitle(clientSuperVision: boolean, clientSuperVisionSubIndex: boolean): string {
    if (!clientSuperVision && !clientSuperVisionSubIndex) {
      return 'families.dialog.withoutClientReturnIndexAndSubIndex';
    }
    if (!clientSuperVision && clientSuperVisionSubIndex) {
      return 'families.dialog.withoutClientReturnIndex';
    }
    if (clientSuperVision && !clientSuperVisionSubIndex) {
      return 'families.dialog.withoutClientReturnSubIndex';
    }

    return '';
  }

  export function mapToLinkFlowFilterApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, flowUuidEntity: string): LabelFamilyLinkFlowFilterRequestDTO {
    return {
      textSearch,
      searchOnChildren: searchStrategy === SearchStrategyEnum.Child,
      flowUuidEntity,
    };
  }
}
