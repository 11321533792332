/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlowEventDTO {
  uuidEntity: string;
  userFirstName: string;
  userLastName: string;
  userUuidEntity: string;
  flowName: string;
  flowUuidEntity: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  option5: string;
  option6: string;
  option7: string;
  option8: string;
  createDate: Date;
  flowEventTypeEnum: FlowEventDTO.FlowEventTypeEnumEnum;
  comment: string;
  signature: FlowEventDTO.SignatureEnum;
  supervisorName: string;
}
export namespace FlowEventDTO {
  export type FlowEventTypeEnumEnum =
    | 'CREATE'
    | 'DELETE'
    | 'IMPORT_CONTENT_REFERENTIAL'
    | 'IMPORT_CONTENT_TEMPLATE'
    | 'RESET_FLOW'
    | 'DELETE_AND_RE_IMPORT_CONTENT'
    | 'UPDATE_LIBRARY'
    | 'DETACH_FBL'
    | 'ACTIVATE_PHASE'
    | 'ACTIVATE_STEP'
    | 'ACTIVATE_FORM'
    | 'DEACTIVATE_PHASE'
    | 'DEACTIVATE_STEP'
    | 'DEACTIVATE_FORM'
    | 'DEACTIVATE_AND_LINK_NEW_FORM'
    | 'DETACH_AND_LINK_NEW_FORM'
    | 'ADD_USER_HIERARCHY_IN_FLOW'
    | 'DELETE_USER_HIERARCHY_FROM_FLOW'
    | 'DEACTIVATE_SIGNATURE';
  export const FlowEventTypeEnumEnum = {
    CREATE: 'CREATE' as FlowEventTypeEnumEnum,
    DELETE: 'DELETE' as FlowEventTypeEnumEnum,
    IMPORTCONTENTREFERENTIAL: 'IMPORT_CONTENT_REFERENTIAL' as FlowEventTypeEnumEnum,
    IMPORTCONTENTTEMPLATE: 'IMPORT_CONTENT_TEMPLATE' as FlowEventTypeEnumEnum,
    RESETFLOW: 'RESET_FLOW' as FlowEventTypeEnumEnum,
    DELETEANDREIMPORTCONTENT: 'DELETE_AND_RE_IMPORT_CONTENT' as FlowEventTypeEnumEnum,
    UPDATELIBRARY: 'UPDATE_LIBRARY' as FlowEventTypeEnumEnum,
    DETACHFBL: 'DETACH_FBL' as FlowEventTypeEnumEnum,
    ACTIVATEPHASE: 'ACTIVATE_PHASE' as FlowEventTypeEnumEnum,
    ACTIVATESTEP: 'ACTIVATE_STEP' as FlowEventTypeEnumEnum,
    ACTIVATEFORM: 'ACTIVATE_FORM' as FlowEventTypeEnumEnum,
    DEACTIVATEPHASE: 'DEACTIVATE_PHASE' as FlowEventTypeEnumEnum,
    DEACTIVATESTEP: 'DEACTIVATE_STEP' as FlowEventTypeEnumEnum,
    DEACTIVATEFORM: 'DEACTIVATE_FORM' as FlowEventTypeEnumEnum,
    DEACTIVATEANDLINKNEWFORM: 'DEACTIVATE_AND_LINK_NEW_FORM' as FlowEventTypeEnumEnum,
    DETACHANDLINKNEWFORM: 'DETACH_AND_LINK_NEW_FORM' as FlowEventTypeEnumEnum,
    ADDUSERHIERARCHYINFLOW: 'ADD_USER_HIERARCHY_IN_FLOW' as FlowEventTypeEnumEnum,
    DELETEUSERHIERARCHYFROMFLOW: 'DELETE_USER_HIERARCHY_FROM_FLOW' as FlowEventTypeEnumEnum,
    DEACTIVATESIGNATURE: 'DEACTIVATE_SIGNATURE' as FlowEventTypeEnumEnum,
  };
  export type SignatureEnum =
    | 'SIGNATURE_REALISATION'
    | 'SIGNATURE_SUPRV_INT_CT'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3'
    | 'SIGNATURE_SUPRV_INT_PI1'
    | 'SIGNATURE_SUPRV_INT_PI2'
    | 'SIGNATURE_SUPRV_INT_PI3'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT1'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT2'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT3'
    | 'SIGNATURE_SUPRV_EXT1'
    | 'SIGNATURE_SUPRV_EXT2'
    | 'SIGNATURE_SUPRV_EXT3';
  export const SignatureEnum = {
    REALISATION: 'SIGNATURE_REALISATION' as SignatureEnum,
    SUPRVINTCT: 'SIGNATURE_SUPRV_INT_CT' as SignatureEnum,
    CONVOCATIONSUPRVINTPI1: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1' as SignatureEnum,
    CONVOCATIONSUPRVINTPI2: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2' as SignatureEnum,
    CONVOCATIONSUPRVINTPI3: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3' as SignatureEnum,
    SUPRVINTPI1: 'SIGNATURE_SUPRV_INT_PI1' as SignatureEnum,
    SUPRVINTPI2: 'SIGNATURE_SUPRV_INT_PI2' as SignatureEnum,
    SUPRVINTPI3: 'SIGNATURE_SUPRV_INT_PI3' as SignatureEnum,
    CONVOCATIONSUPRVEXT1: 'SIGNATURE_CONVOCATION_SUPRV_EXT1' as SignatureEnum,
    CONVOCATIONSUPRVEXT2: 'SIGNATURE_CONVOCATION_SUPRV_EXT2' as SignatureEnum,
    CONVOCATIONSUPRVEXT3: 'SIGNATURE_CONVOCATION_SUPRV_EXT3' as SignatureEnum,
    SUPRVEXT1: 'SIGNATURE_SUPRV_EXT1' as SignatureEnum,
    SUPRVEXT2: 'SIGNATURE_SUPRV_EXT2' as SignatureEnum,
    SUPRVEXT3: 'SIGNATURE_SUPRV_EXT3' as SignatureEnum,
  };
}
