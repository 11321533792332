/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicationFileLightDTO } from './applicationFileLightDTO';
import { OptionSignatoryDetailsResponseDTO } from './optionSignatoryDetailsResponseDTO';
import { RubricOptionDetailsResponseDTO } from './rubricOptionDetailsResponseDTO';
import { RubricSummaryResponseDTO } from './rubricSummaryResponseDTO';

export interface RubricQuestionDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  description: string;
  type: RubricQuestionDetailsResponseDTO.TypeEnum;
  questionOrder: number;
  mandatory: boolean;
  lockable: boolean;
  allowBackDating: boolean;
  withObservation: boolean;
  backgroundColor: string;
  iconStyle: string;
  createDate: Date;
  modificationDate: Date;
  observation: string;
  attachementType: RubricQuestionDetailsResponseDTO.AttachementTypeEnum;
  attachementWidth: number;
  attachementHeight: number;
  hideInExportPDF: boolean;
  applicationFiles?: Array<ApplicationFileLightDTO>;
  rubricOptions?: Array<RubricOptionDetailsResponseDTO>;
  optionSignatories?: Array<OptionSignatoryDetailsResponseDTO>;
  rubric?: RubricSummaryResponseDTO;
  additionalComment?: string;
  additionalObservation?: string;
  unifyCells?: boolean;
  /**
   * Indicates if this question can be answered through the share link API
   */
  shared: boolean;
  /**
   * Indicates if question images will be included on pdf export
   */
  hideImagesOnBottomTable: boolean;
  versionHistoryCount: number;
  lastSignatoryDate: Date;
}
export namespace RubricQuestionDetailsResponseDTO {
  export type TypeEnum =
    | 'Text'
    | 'TextLong'
    | 'TextMultiple'
    | 'ValueUnit'
    | 'RadioSelect'
    | 'Contributor'
    | 'CheckBox'
    | 'File'
    | 'FileDocument'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'BooleanValue'
    | 'Localisation'
    | 'Formulas'
    | 'EnumSelection'
    | 'EnumMultipleSelections'
    | 'TextEditor';
  export const TypeEnum = {
    Text: 'Text' as TypeEnum,
    TextLong: 'TextLong' as TypeEnum,
    TextMultiple: 'TextMultiple' as TypeEnum,
    ValueUnit: 'ValueUnit' as TypeEnum,
    RadioSelect: 'RadioSelect' as TypeEnum,
    Contributor: 'Contributor' as TypeEnum,
    CheckBox: 'CheckBox' as TypeEnum,
    File: 'File' as TypeEnum,
    FileDocument: 'FileDocument' as TypeEnum,
    Date: 'Date' as TypeEnum,
    Time: 'Time' as TypeEnum,
    DateTime: 'DateTime' as TypeEnum,
    BooleanValue: 'BooleanValue' as TypeEnum,
    Localisation: 'Localisation' as TypeEnum,
    Formulas: 'Formulas' as TypeEnum,
    EnumSelection: 'EnumSelection' as TypeEnum,
    EnumMultipleSelections: 'EnumMultipleSelections' as TypeEnum,
    TextEditor: 'TextEditor' as TypeEnum,
  };
  export type AttachementTypeEnum = 'IMG' | 'PDF';
  export const AttachementTypeEnum = {
    IMG: 'IMG' as AttachementTypeEnum,
    PDF: 'PDF' as AttachementTypeEnum,
  };
}
