import RRule, { ByWeekday } from 'rrule';
import { Observable, of } from 'rxjs';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FrequencyWeekDayEnum {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
  Day,
  Weekday,
  Weekend,
}

export namespace FrequencyWeekDayEnum {
  const days = [
    FrequencyWeekDayEnum.Monday,
    FrequencyWeekDayEnum.Tuesday,
    FrequencyWeekDayEnum.Wednesday,
    FrequencyWeekDayEnum.Thursday,
    FrequencyWeekDayEnum.Friday,
    FrequencyWeekDayEnum.Saturday,
    FrequencyWeekDayEnum.Sunday,
  ];
  const values = [...days, FrequencyWeekDayEnum.Day, FrequencyWeekDayEnum.Weekday, FrequencyWeekDayEnum.Weekend];

  const weekdayKey = 'schedule.weekday.';
  export const toString = new SfxMap<FrequencyWeekDayEnum, string>([
    [FrequencyWeekDayEnum.Monday, weekdayKey + 'monday'],
    [FrequencyWeekDayEnum.Tuesday, weekdayKey + 'tuesday'],
    [FrequencyWeekDayEnum.Wednesday, weekdayKey + 'wednesday'],
    [FrequencyWeekDayEnum.Thursday, weekdayKey + 'thursday'],
    [FrequencyWeekDayEnum.Friday, weekdayKey + 'friday'],
    [FrequencyWeekDayEnum.Saturday, weekdayKey + 'saturday'],
    [FrequencyWeekDayEnum.Sunday, weekdayKey + 'sunday'],
    [FrequencyWeekDayEnum.Day, weekdayKey + 'day'],
    [FrequencyWeekDayEnum.Weekday, weekdayKey + 'weekday'],
    [FrequencyWeekDayEnum.Weekend, weekdayKey + 'weekend'],
  ]);

  const weekdayExpressionKey = 'schedule.weekday.expression.';
  export const toExpressionString = new SfxMap<FrequencyWeekDayEnum, string>([
    [FrequencyWeekDayEnum.Monday, weekdayExpressionKey + 'monday'],
    [FrequencyWeekDayEnum.Tuesday, weekdayExpressionKey + 'tuesday'],
    [FrequencyWeekDayEnum.Wednesday, weekdayExpressionKey + 'wednesday'],
    [FrequencyWeekDayEnum.Thursday, weekdayExpressionKey + 'thursday'],
    [FrequencyWeekDayEnum.Friday, weekdayExpressionKey + 'friday'],
    [FrequencyWeekDayEnum.Saturday, weekdayExpressionKey + 'saturday'],
    [FrequencyWeekDayEnum.Sunday, weekdayExpressionKey + 'sunday'],
    [FrequencyWeekDayEnum.Day, weekdayExpressionKey + 'day'],
    [FrequencyWeekDayEnum.Weekday, weekdayExpressionKey + 'weekday'],
    [FrequencyWeekDayEnum.Weekend, weekdayExpressionKey + 'weekend'],
  ]);

  export const convertToApiValue = new SfxMap<FrequencyWeekDayEnum, ByWeekday[]>([
    [FrequencyWeekDayEnum.Monday, [RRule.MO]],
    [FrequencyWeekDayEnum.Tuesday, [RRule.TU]],
    [FrequencyWeekDayEnum.Wednesday, [RRule.WE]],
    [FrequencyWeekDayEnum.Thursday, [RRule.TH]],
    [FrequencyWeekDayEnum.Friday, [RRule.FR]],
    [FrequencyWeekDayEnum.Saturday, [RRule.SA]],
    [FrequencyWeekDayEnum.Sunday, [RRule.SU]],
    [FrequencyWeekDayEnum.Day, [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA]],
    [FrequencyWeekDayEnum.Weekday, [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]],
    [FrequencyWeekDayEnum.Weekend, [RRule.SU, RRule.SA]],
  ]);

  export const convertFromApiValue = new SfxMap<ByWeekday, FrequencyWeekDayEnum>([
    [RRule.MO, FrequencyWeekDayEnum.Monday],
    [RRule.TU, FrequencyWeekDayEnum.Tuesday],
    [RRule.WE, FrequencyWeekDayEnum.Wednesday],
    [RRule.TH, FrequencyWeekDayEnum.Thursday],
    [RRule.FR, FrequencyWeekDayEnum.Friday],
    [RRule.SA, FrequencyWeekDayEnum.Saturday],
    [RRule.SU, FrequencyWeekDayEnum.Sunday],
  ]);

  export const comparePredicate = (node: SingleSelectItemData<FrequencyWeekDayEnum>, item: FrequencyWeekDayEnum) => node.value === item;

  const weekdaySelectedItemList: SingleSelectItemData<FrequencyWeekDayEnum>[] = values.map(weekday => ({ value: weekday, title: toString.getValue(weekday) }));

  export const daysSelectedItemList: SingleSelectItemData<FrequencyWeekDayEnum>[] = days.map(day => ({ value: day, title: toString.getValue(day) }));
  export const weekdaySelectedItemValues = (): Observable<SingleSelectItemData<FrequencyWeekDayEnum>[]> => of(weekdaySelectedItemList);
}
