/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';

export interface SignatoryFilterDTO {
  signWithQrCode?: boolean;
  typeSignatory?: Array<SignatoryFilterDTO.TypeSignatoryEnum>;
  signatureState?: Array<SignatoryFilterDTO.SignatureStateEnum>;
  proxied?: boolean;
  signatoriesStatus?: Array<SignatoryFilterDTO.SignatoriesStatusEnum>;
  signatureNatures?: Array<SignatoryFilterDTO.SignatureNaturesEnum>;
  dateFilterRequest?: DateFilterRequest;
}
export namespace SignatoryFilterDTO {
  export type TypeSignatoryEnum = 'INTERN' | 'EXTERN' | 'NO_LISTED_INTERN';
  export const TypeSignatoryEnum = {
    INTERN: 'INTERN' as TypeSignatoryEnum,
    EXTERN: 'EXTERN' as TypeSignatoryEnum,
    NOLISTEDINTERN: 'NO_LISTED_INTERN' as TypeSignatoryEnum,
  };
  export type SignatureStateEnum = 'SIGNATURE_NOT_POSSIBLE' | 'WAITING' | 'FREE' | 'SIGNED';
  export const SignatureStateEnum = {
    SIGNATURENOTPOSSIBLE: 'SIGNATURE_NOT_POSSIBLE' as SignatureStateEnum,
    WAITING: 'WAITING' as SignatureStateEnum,
    FREE: 'FREE' as SignatureStateEnum,
    SIGNED: 'SIGNED' as SignatureStateEnum,
  };
  export type SignatoriesStatusEnum = 'ENABLED' | 'DISABLED' | 'REQUIRED' | 'OPTIONAL';
  export const SignatoriesStatusEnum = {
    ENABLED: 'ENABLED' as SignatoriesStatusEnum,
    DISABLED: 'DISABLED' as SignatoriesStatusEnum,
    REQUIRED: 'REQUIRED' as SignatoriesStatusEnum,
    OPTIONAL: 'OPTIONAL' as SignatoriesStatusEnum,
  };
  export type SignatureNaturesEnum =
    | 'SIGNATURE_REALISATION'
    | 'SIGNATURE_SUPRV_INT_CT'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3'
    | 'SIGNATURE_SUPRV_INT_PI1'
    | 'SIGNATURE_SUPRV_INT_PI2'
    | 'SIGNATURE_SUPRV_INT_PI3'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT1'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT2'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT3'
    | 'SIGNATURE_SUPRV_EXT1'
    | 'SIGNATURE_SUPRV_EXT2'
    | 'SIGNATURE_SUPRV_EXT3';
  export const SignatureNaturesEnum = {
    REALISATION: 'SIGNATURE_REALISATION' as SignatureNaturesEnum,
    SUPRVINTCT: 'SIGNATURE_SUPRV_INT_CT' as SignatureNaturesEnum,
    CONVOCATIONSUPRVINTPI1: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1' as SignatureNaturesEnum,
    CONVOCATIONSUPRVINTPI2: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2' as SignatureNaturesEnum,
    CONVOCATIONSUPRVINTPI3: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3' as SignatureNaturesEnum,
    SUPRVINTPI1: 'SIGNATURE_SUPRV_INT_PI1' as SignatureNaturesEnum,
    SUPRVINTPI2: 'SIGNATURE_SUPRV_INT_PI2' as SignatureNaturesEnum,
    SUPRVINTPI3: 'SIGNATURE_SUPRV_INT_PI3' as SignatureNaturesEnum,
    CONVOCATIONSUPRVEXT1: 'SIGNATURE_CONVOCATION_SUPRV_EXT1' as SignatureNaturesEnum,
    CONVOCATIONSUPRVEXT2: 'SIGNATURE_CONVOCATION_SUPRV_EXT2' as SignatureNaturesEnum,
    CONVOCATIONSUPRVEXT3: 'SIGNATURE_CONVOCATION_SUPRV_EXT3' as SignatureNaturesEnum,
    SUPRVEXT1: 'SIGNATURE_SUPRV_EXT1' as SignatureNaturesEnum,
    SUPRVEXT2: 'SIGNATURE_SUPRV_EXT2' as SignatureNaturesEnum,
    SUPRVEXT3: 'SIGNATURE_SUPRV_EXT3' as SignatureNaturesEnum,
  };
}
