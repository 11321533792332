import { Action, createReducer, on } from '@ngrx/store';
import { LazyLoadResponse, BlockEquipmentToImportSummaryData, BlockEquipmentDetailsData } from '../../../../../../../../core/models';

import * as fromActions from './block-equipment-dialog.actions';

export interface EquipmentDialogState {
  equipmentDetails: BlockEquipmentDetailsData;
}

export interface ImportDialogState {
  equipments: LazyLoadResponse<BlockEquipmentToImportSummaryData[]>;
  importEquipmentLoading: boolean;
  reset: boolean;
}

export interface BlockEquipmentDialogState {
  equipmentDialogState: EquipmentDialogState;
  importDialogState: ImportDialogState;
}

const initialEquipmentDialogState = (): EquipmentDialogState => ({
  equipmentDetails: {} as BlockEquipmentDetailsData,
});

const initialImportDialogState = (): ImportDialogState => ({
  equipments: {} as LazyLoadResponse<BlockEquipmentToImportSummaryData[]>,
  importEquipmentLoading: false,
  reset: false,
});

export const initialBlockEquipmentDialogState = () => ({
  equipmentDialogState: initialEquipmentDialogState(),
  importDialogState: initialImportDialogState(),
});

const reducer = createReducer<BlockEquipmentDialogState>(
  initialBlockEquipmentDialogState(),
  on(
    fromActions.getBlockEquipmentDetailsSuccess,
    (state, { equipmentDetails }): BlockEquipmentDialogState => ({ ...state, equipmentDialogState: { ...state.equipmentDialogState, equipmentDetails } }),
  ),
  on(
    fromActions.loadEquipmentsSuccess,
    (state, { equipments, totalCount, filteredTotalCount, reset }): BlockEquipmentDialogState => ({
      ...state,
      importDialogState: {
        reset,
        ...state.importDialogState,
        equipments: reset
          ? { payload: equipments, totalCount, filteredTotalCount }
          : { ...state.importDialogState.equipments, payload: [...(state.importDialogState?.equipments?.payload || []), ...equipments] },
      },
    }),
  ),
  on(
    fromActions.setImportEquipmentLoading,
    (state, { importEquipmentLoading }): BlockEquipmentDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importEquipmentLoading } }),
  ),
  on(
    fromActions.importEquipmentSuccess,
    (state, { emmUuidEntity }): BlockEquipmentDialogState => ({
      ...state,
      importDialogState: {
        ...state.importDialogState,
        equipments: {
          ...state.importDialogState.equipments,
          payload: state.importDialogState.equipments.payload.map(emm => (emm.uuidEntity === emmUuidEntity ? { ...emm, frozen: true } : emm)),
        },
      },
    }),
  ),
);

export function blockEquipmentDialogReducer(state: BlockEquipmentDialogState | undefined, action: Action): BlockEquipmentDialogState {
  return reducer(state, action);
}
