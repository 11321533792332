import { compact, isArray } from 'lodash';

import { CustomFieldDetailsResponseDTO } from '../../generated/models/customFieldDetailsResponseDTO';
import {
  CustomFieldRequest,
  CustomFieldValuesRequest,
  PhaseCustomAttributeValueResponseDTO,
  PhaseUpsertCustomAttributeBodyDTO,
  StepCustomAttributeValueResponseDTO,
  StepUpsertCustomAttributeBodyDTO,
} from '../../generated/models/models';

import { ApplicationFileLightData } from '../application-file/application-file';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BusinessClassNameEnum, FieldTypeEnum, StringUtilsEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface CustomFieldData {
  uuidType: string;
  uuidEntity: string;
  createDate: Date;
  modificationDate: Date;
  name: string;
  fieldType: FieldTypeEnum;
  defaultValue: string;
  orderField: string;
  value: string | string[] | boolean;
  placeholder: string;
  tooltip: string;
  pdfFieldName: string;
  help: string;
  readOnly: boolean;
  required: boolean;
  labelFamily: LabelFamilyLightData;
  selectItems: (SingleSelectItemData<string> | MultiSelectItemData)[];
  file: ApplicationFileLightData;
  localFile?: File[];
  linked?: boolean;
}

export namespace CustomFieldData {
  export const comparePredicate = (node: SingleSelectItemData<string>, item: string) => node.value === item;

  export function mapFromApiValue(customField: CustomFieldDetailsResponseDTO): CustomFieldData {
    const fieldType: FieldTypeEnum = FieldTypeEnum.convertFromApiValue(customField.longText)?.getValue(customField.typeField);

    return {
      uuidType: customField.uuidType,
      uuidEntity: customField.uuidEntity,
      createDate: customField.createDate,
      modificationDate: customField.modificationDate,
      name: customField.name,
      fieldType: fieldType,
      defaultValue: customField.defaultValue,
      orderField: customField.orderField,
      value: mapValueFromApiValue(customField.value, fieldType),
      placeholder: customField.placeholder,
      tooltip: customField.tooltip,
      pdfFieldName: customField.pdfFieldName,
      help: customField.description,
      readOnly: customField.readOnly,
      required: customField.required,
      labelFamily: (customField.labelFamily && LabelFamilyLightData.mapFromApiValue(customField.labelFamily)) || LabelFamilyLightData.defaultData,
      selectItems: (customField.enums || '').split(StringUtilsEnum.DoubleUnderscore).map((val: string) => ({ value: val, title: val })),
      file: customField.file && ApplicationFileLightData.mapFromApiValue(customField.file),
    };
  }

  export function mapFromPhaseStepCustomAttributeApiValue(customField: PhaseCustomAttributeValueResponseDTO | StepCustomAttributeValueResponseDTO): CustomFieldData {
    const fieldType: FieldTypeEnum = FieldTypeEnum.convertFromCustomAttributesApiValue(customField.longText)?.getValue(customField.dataType);

    return {
      uuidType: null,
      uuidEntity: customField.reference,
      createDate: null,
      modificationDate: null,
      name: customField.name,
      fieldType: fieldType,
      defaultValue: customField.defaultValue,
      orderField: customField.orderField,
      value: mapValueFromApiValue(customField.value || customField.defaultValue, fieldType),
      placeholder: customField.placeholder,
      tooltip: customField.tooltip,
      pdfFieldName: null,
      help: customField.help,
      readOnly: customField.readOnly,
      required: customField.required,
      labelFamily: null,
      selectItems: null,
      file: null,
      linked: customField.linked,
    };
  }

  export function mapToPhaseStepCustomAttributeApiValue(customFields: CustomFieldData[]): PhaseUpsertCustomAttributeBodyDTO | StepUpsertCustomAttributeBodyDTO {
    const customFieldValue = (field: CustomFieldData) => (isArray(field.value) ? field.value.join(StringUtilsEnum.SemicolonSeparator) : field.value + '');

    return {
      data: (customFields || []).map(customField => ({
        reference: {
          value: customField.uuidEntity,
        },
        linked: customField.linked,
        value: (() => {
          let value: string;
          if (customField.fieldType === FieldTypeEnum.DateTime) {
            value = DateUtils.toZonedDateTime(customField.value as string);
          } else if (customField.value || customField.value === false) {
            value = customFieldValue(customField);
          } else {
            value = null;
          }

          return value;
        })(),
      })),
    };
  }

  export function mapToPageRequestApiValue(classUuidEntity: string, labelFamilyUuidEntity: string, businessClassName: BusinessClassNameEnum): CustomFieldRequest {
    return {
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(businessClassName),
      businessClassUuide: classUuidEntity,
      labelFamilyUuide: labelFamilyUuidEntity,
    };
  }

  export function mapToValuesPageRequestApiValue(classUuidEntity: string, customFields: CustomFieldData[], businessClassName: BusinessClassNameEnum): CustomFieldValuesRequest {
    return {
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(businessClassName),
      businessClassUuide: classUuidEntity,
      customFieldValues: (customFields || []).map(field => ({
        customFieldUuidType: field.uuidType,
        value: field.value || field.value === false ? mapValueToApiValue(field.value, field.fieldType) : null,
      })),
    };
  }

  function mapValueFromApiValue(value: string, typeField: FieldTypeEnum): string[] | string | boolean {
    switch (typeField) {
      case FieldTypeEnum.MultiSelect:
        return compact((value || '').split(StringUtilsEnum.SemicolonSeparator));
      case FieldTypeEnum.BooleanField:
        return value?.toLowerCase() === 'true';
      case FieldTypeEnum.DateTime:
        return DateUtils.toCalendarFormat(value);
      default:
        return value;
    }
  }

  function mapValueToApiValue(value: string[] | string | boolean, typeField: FieldTypeEnum): string {
    switch (typeField) {
      case FieldTypeEnum.DateTime:
        return DateUtils.toZonedDateTime(value as string);
      default:
        return isArray(value) ? value.join(StringUtilsEnum.SemicolonSeparator) : value + '';
    }
  }
}
