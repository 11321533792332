import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum QuestionIconEnum {
  Question = 1,
  Clock = 2,
  ExclamationTriangle = 3,
  Wrench = 4,
  Flag = 5,
  InfoCircle = 6,
  Bell = 7,
  Key = 8,
  HandPaper = 9,
  LightBulb = 10,
  Calendar = 11,
  Eye = 12,
  Default = 13,
}

export namespace QuestionIconEnum {
  export const values: QuestionIconEnum[] = [
    QuestionIconEnum.Question,
    QuestionIconEnum.Clock,
    QuestionIconEnum.ExclamationTriangle,
    QuestionIconEnum.Wrench,
    QuestionIconEnum.Flag,
    QuestionIconEnum.InfoCircle,
    QuestionIconEnum.Bell,
    QuestionIconEnum.Key,
    QuestionIconEnum.HandPaper,
    QuestionIconEnum.LightBulb,
    QuestionIconEnum.Calendar,
    QuestionIconEnum.Eye,
  ];

  export const convertFromApiValue: SfxMap<string, QuestionIconEnum> = new SfxMap<string, QuestionIconEnum>([
    ['question', QuestionIconEnum.Question],
    ['clock', QuestionIconEnum.Clock],
    ['exclamation-triangle', QuestionIconEnum.ExclamationTriangle],
    ['wrench', QuestionIconEnum.Wrench],
    ['flag', QuestionIconEnum.Flag],
    ['info-circle', QuestionIconEnum.InfoCircle],
    ['bell', QuestionIconEnum.Bell],
    ['key', QuestionIconEnum.Key],
    ['hand-paper', QuestionIconEnum.HandPaper],
    ['lightbulb', QuestionIconEnum.LightBulb],
    ['calendar-alt', QuestionIconEnum.Calendar],
    ['eye', QuestionIconEnum.Eye],
  ]);

  export const convertToApiValue: SfxMap<QuestionIconEnum, string> = new SfxMap<QuestionIconEnum, string>([
    [QuestionIconEnum.Question, 'question'],
    [QuestionIconEnum.Clock, 'clock'],
    [QuestionIconEnum.ExclamationTriangle, 'exclamation-triangle'],
    [QuestionIconEnum.Wrench, 'wrench'],
    [QuestionIconEnum.Flag, 'flag'],
    [QuestionIconEnum.InfoCircle, 'info-circle'],
    [QuestionIconEnum.Bell, 'bell'],
    [QuestionIconEnum.Key, 'key'],
    [QuestionIconEnum.HandPaper, 'hand-paper'],
    [QuestionIconEnum.LightBulb, 'lightbulb'],
    [QuestionIconEnum.Calendar, 'calendar-alt'],
    [QuestionIconEnum.Eye, 'eye'],
  ]);

  export const iconData: SfxMap<QuestionIconEnum, IconData> = new SfxMap<QuestionIconEnum, IconData>([
    [QuestionIconEnum.Question, { name: 'question', prefix: 'fas' }],
    [QuestionIconEnum.Clock, { name: 'clock', prefix: 'fas' }],
    [QuestionIconEnum.ExclamationTriangle, { name: 'exclamation-triangle', prefix: 'fas' }],
    [QuestionIconEnum.Wrench, { name: 'wrench', prefix: 'fas' }],
    [QuestionIconEnum.Flag, { name: 'flag', prefix: 'fas' }],
    [QuestionIconEnum.InfoCircle, { name: 'info-circle', prefix: 'fas' }],
    [QuestionIconEnum.Bell, { name: 'bell', prefix: 'fas' }],
    [QuestionIconEnum.Key, { name: 'key', prefix: 'fas' }],
    [QuestionIconEnum.HandPaper, { name: 'hand-paper', prefix: 'fas' }],
    [QuestionIconEnum.LightBulb, { name: 'lightbulb', prefix: 'fas' }],
    [QuestionIconEnum.Calendar, { name: 'calendar-alt', prefix: 'fas' }],
    [QuestionIconEnum.Eye, { name: 'eye', prefix: 'fas' }],
  ]);
}
