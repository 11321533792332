import { MoveEnum } from '../../enums';
import { MoveElementOnParentRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';

export interface RubricToMoveData {
  uuidEntity: string;
  targetRubric: EntityLightData;
  move?: MoveEnum;
}

export namespace RubricToMoveData {
  export function mapToRequestApiValue(moveRequest: RubricToMoveData): MoveElementOnParentRequestDTO {
    return {
      uuidEntity: moveRequest.uuidEntity,
      siblingUuidEntity: moveRequest.targetRubric && moveRequest.targetRubric.uuidEntity,
      relativePosition: MoveEnum.convertToApiValue.getValue(moveRequest.move || (moveRequest.targetRubric ? MoveEnum.After : MoveEnum.Start)),
    };
  }
}
