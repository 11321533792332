/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MoveElementRequestDTO {
  siblingUuidEntity?: string;
  uuidEntity: string;
  relativePosition: MoveElementRequestDTO.RelativePositionEnum;
}
export namespace MoveElementRequestDTO {
  export type RelativePositionEnum = 'START' | 'BEFORE' | 'AFTER' | 'END';
  export const RelativePositionEnum = {
    START: 'START' as RelativePositionEnum,
    BEFORE: 'BEFORE' as RelativePositionEnum,
    AFTER: 'AFTER' as RelativePositionEnum,
    END: 'END' as RelativePositionEnum,
  };
}
