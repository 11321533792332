import { RouterPaths } from '../../constant/route.constant';
import { SfxMap } from '../../utils/enum-utils';

import { RouteIdEnum } from '../router-enums/route.enum';

export enum ProjectModalTabEnum {
  General = 'general',
  CustomField = 'customField',
  Options = 'options',
}

export namespace ProjectModalTabEnum {
  export function getValues(): ProjectModalTabEnum[] {
    return [ProjectModalTabEnum.General, ProjectModalTabEnum.CustomField, ProjectModalTabEnum.Options];
  }

  export const toTab: SfxMap<RouteIdEnum, ProjectModalTabEnum> = new SfxMap<RouteIdEnum, ProjectModalTabEnum>([
    [RouteIdEnum.ProjectDialogGeneralTabModal, ProjectModalTabEnum.General],
    [RouteIdEnum.ProjectDialogAttributeTabModal, ProjectModalTabEnum.CustomField],
    [RouteIdEnum.ProjectDialogOptionsTabModal, ProjectModalTabEnum.Options],
  ]);

  export const routePath: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.ProjectDialogGeneralTabModal, RouterPaths.TabPaths.generalTabPath],
    [RouteIdEnum.ProjectDialogAttributeTabModal, RouterPaths.TabPaths.attributeTabPath],
    [RouteIdEnum.ProjectDialogOptionsTabModal, RouterPaths.TabPaths.optionsTabPath],
  ]);
}
