import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { RubricOptionDetailsResponseDTO } from '../../generated/models/rubricOptionDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum ToasterTypeEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Default = 'default',
  Bug = 'bug',
}

export namespace ToasterTypeEnum {
  export const alerts = [ToasterTypeEnum.Success, ToasterTypeEnum.Error, ToasterTypeEnum.Warning, ToasterTypeEnum.Info];

  const key = 'toaster.types.';
  export const toString = new SfxMap<ToasterTypeEnum, string>([
    [ToasterTypeEnum.Success, key + 'success'],
    [ToasterTypeEnum.Error, key + 'error'],
    [ToasterTypeEnum.Warning, key + 'warning'],
    [ToasterTypeEnum.Info, key + 'info'],
    [ToasterTypeEnum.Default, key + 'default'],
  ]);

  export const iconData = new SfxMap<ToasterTypeEnum, IconData>(
    [
      [ToasterTypeEnum.Success, { name: 'check-circle', prefix: 'fas' }],
      [ToasterTypeEnum.Error, { name: 'exclamation-circle', prefix: 'fas' }],
      [ToasterTypeEnum.Info, { name: 'info-circle', prefix: 'fas' }],
      [ToasterTypeEnum.Warning, { name: 'exclamation-triangle', prefix: 'fas' }],
      [ToasterTypeEnum.Bug, { name: 'bug', prefix: 'fas' }],
    ],
    { name: 'question-circle', prefix: 'fas' },
  );

  export const mapFromAlertNature: SfxMap<RubricOptionDetailsResponseDTO.NatureAlertEnum, ToasterTypeEnum> = new SfxMap<
    RubricOptionDetailsResponseDTO.NatureAlertEnum,
    ToasterTypeEnum
  >([
    [RubricOptionDetailsResponseDTO.NatureAlertEnum.ERROR, ToasterTypeEnum.Error],
    [RubricOptionDetailsResponseDTO.NatureAlertEnum.INFO, ToasterTypeEnum.Info],
    [RubricOptionDetailsResponseDTO.NatureAlertEnum.WARN, ToasterTypeEnum.Warning],
    [RubricOptionDetailsResponseDTO.NatureAlertEnum.TRACE, ToasterTypeEnum.Success],
  ]);

  export const mapToAlertNature: SfxMap<ToasterTypeEnum, RubricOptionDetailsResponseDTO.NatureAlertEnum> = new SfxMap<
    ToasterTypeEnum,
    RubricOptionDetailsResponseDTO.NatureAlertEnum
  >([
    [ToasterTypeEnum.Error, RubricOptionDetailsResponseDTO.NatureAlertEnum.ERROR],
    [ToasterTypeEnum.Info, RubricOptionDetailsResponseDTO.NatureAlertEnum.INFO],
    [ToasterTypeEnum.Warning, RubricOptionDetailsResponseDTO.NatureAlertEnum.WARN],
    [ToasterTypeEnum.Success, RubricOptionDetailsResponseDTO.NatureAlertEnum.TRACE],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<ToasterTypeEnum>[]> => of(alerts.map(type => ({ value: type, title: ToasterTypeEnum.toString.getValue(type) })));

  export const comparePredicate = (node: SingleSelectItemData<ToasterTypeEnum>, item: ToasterTypeEnum) => node.value === item;
}
