/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PhaseCustomAttributeValueResponseDTO {
  name?: string;
  dataType?: PhaseCustomAttributeValueResponseDTO.DataTypeEnum;
  value?: string;
  reference?: string;
  defaultValue?: string;
  readOnly?: boolean;
  required?: boolean;
  tooltip?: string;
  placeholder?: string;
  help?: string;
  linked?: boolean;
  orderField?: string;
  longText?: boolean;
}
export namespace PhaseCustomAttributeValueResponseDTO {
  export type DataTypeEnum = 'TEXT' | 'DATETIME' | 'FLOAT' | 'INTEGER' | 'BOOLEAN' | 'DATE';
  export const DataTypeEnum = {
    TEXT: 'TEXT' as DataTypeEnum,
    DATETIME: 'DATETIME' as DataTypeEnum,
    FLOAT: 'FLOAT' as DataTypeEnum,
    INTEGER: 'INTEGER' as DataTypeEnum,
    BOOLEAN: 'BOOLEAN' as DataTypeEnum,
    DATE: 'DATE' as DataTypeEnum,
  };
}
