import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../sfx-dialog/state/dialog-toaster-data';

import {
  ApplicationFileLightData,
  OptionToMoveData,
  OptionDetailsData,
  QuestionDetailsData,
  QuestionToDuplicateData,
  QuestionToMoveData,
  RubricDetailsData,
  RubricToMoveData,
  GenericOptionData,
  VersionRubricOptionResponseData,
} from '../../../../core/models';

import { FormEntityEnum, ModalIdEnum } from '../../../../core/enums';

// Question actions
export const loadQuestionDetails = createAction('[Question/dialog] load question details');
export const loadQuestionDetailsSuccess = createAction('[Question/dialog] load question details success', props<{ questionDetails: QuestionDetailsData }>());
export const loadQuestionDetailsFail = createAction('[Question/dialog] load question details fail', props<DialogToasterData>());

export const updateQuestion = createAction('[Question/dialog] update question', props<{ questionDetails: QuestionDetailsData }>());
export const updateQuestionSuccess = createAction('[Question/dialog] update question success', props<{ questionDetails: QuestionDetailsData }>());
export const updateQuestionFail = createAction('[Question/dialog] update question fail', props<DialogToasterData>());

export const setQuestionLoading = createAction('[Question/dialog] set question loading', props<{ loading: boolean }>());

export const unifyCells = createAction('[Question/dialog] unify cells', props<{ unifyCell: boolean }>());
export const unifyCellsFail = createAction('[Question/dialog] unify cells fail', props<DialogToasterData>());

export const duplicateQuestion = createAction('[Question/dialog] duplicate question', props<QuestionToDuplicateData>());
export const duplicateQuestionSuccess = createAction('[Question/dialog] duplicate question success');
export const duplicateQuestionFail = createAction('[Question/dialog] duplicate question fail', props<DialogToasterData>());

export const deleteQuestion = createAction('[Question/dialog] delete question', props<{ questionDetails: QuestionDetailsData }>());
export const deleteQuestionFail = createAction('[Question/dialog] delete question fail', props<DialogToasterData>());

export const openMoveQuestionDialog = createAction('[Question/dialog] open move question dialog', props<{ questionDetails: QuestionDetailsData }>());

export const moveQuestion = createAction('[Question/dialog] move question', props<QuestionToMoveData>());
export const moveQuestionSuccess = createAction('[Question/dialog] move question success');
export const moveQuestionFail = createAction('[Question/dialog] question fail', props<DialogToasterData>());

export const createOption = createAction('[Option/dialog] create option', props<{ name: string }>());
export const createOptionFail = createAction('[Option/dialog] create option fail', props<DialogToasterData>());

export const editOption = createAction('[Option/dialog] edit option', props<{ option: OptionDetailsData }>());
export const editOptionFail = createAction('[Option/dialog] edit option fail', props<DialogToasterData>());

export const deleteOption = createAction('[Option/dialog] delete option', props<{ optionUuidEntity: string }>());
export const deleteOptionFail = createAction('[Option/dialog] delete option fail', props<DialogToasterData>());

export const deleteOptionImages = createAction('[Option/dialog] delete option images', props<{ optionDetails: OptionDetailsData }>());
export const deleteOptionImagesFail = createAction('[Option/dialog] delete option images fail', props<DialogToasterData>());

export const moveOption = createAction('[Option/dialog] move option', props<OptionToMoveData>());
export const moveOptionFail = createAction('[Option/dialog] move option fail', props<DialogToasterData>());

// Rubric actions
export const loadRubricDetails = createAction('[Rubric/dialog] load rubric details', props<{ rubricUuidEntity?: string }>());
export const loadRubricDetailsSuccess = createAction('[Rubric/dialog] load rubric details success', props<{ rubricDetails: RubricDetailsData }>());
export const loadRubricDetailsFail = createAction('[Rubric/dialog] load rubric details fail', props<DialogToasterData>());

export const updateRubric = createAction('[Rubric/dialog] update rubric', props<{ rubricDetails: RubricDetailsData }>());
export const updateRubricSuccess = createAction('[Rubric/dialog] update rubric success', props<{ rubricDetails: RubricDetailsData }>());
export const updateRubricFail = createAction('[Rubric/dialog] update rubric fail', props<DialogToasterData>());

export const createRubric = createAction('[Rubric/dialog] create rubric', props<{ rubricDetails: RubricDetailsData; files: File[] }>());
export const createRubricSuccess = createAction('[Rubric/dialog] create rubric success');
export const createRubricFail = createAction('[Rubric/dialog] create rubric fail', props<DialogToasterData>());

export const navigateThroughRubrics = createAction('[Rubric/dialog] Navigate through rubrics', props<{ question: number }>());
export const checkBeforeNavigateThrowRubrics = createAction('[Rubric/dialog] Check before navigate throw rubrics', props<{ rubric: RubricDetailsData; question: number }>());

export const duplicateRubric = createAction('[Rubric/dialog] duplicate rubric');
export const duplicateRubricSuccess = createAction('[Rubric/dialog] duplicate rubric success');
export const duplicateRubricFail = createAction('[Rubric/dialog] duplicate rubric fail', props<DialogToasterData>());

export const deleteRubric = createAction('[Rubric/dialog] delete rubric');
export const deleteRubricFail = createAction('[Rubric/dialog] delete rubric fail', props<DialogToasterData>());

export const openMoveRubricDialog = createAction('[Rubric/dialog] open move rubric dialog', props<{ rubricDetails: RubricDetailsData }>());

export const moveRubric = createAction('[Rubric/dialog] move rubric', props<RubricToMoveData>());
export const moveRubricSuccess = createAction('[Rubric/dialog] move rubric success');
export const moveRubricFail = createAction('[Rubric/dialog] rubric fail', props<DialogToasterData>());

export const loadImages = createAction('[Rubric/dialog] load images', props<{ entity: FormEntityEnum }>());
export const loadImagesSuccess = createAction('[Rubric/dialog] load images success', props<{ images: ApplicationFileLightData[] }>());
export const loadImagesFail = createAction('[Rubric/dialog] load images fail', props<DialogToasterData>());
export const setImagesEntity = createAction('[Rubric/dialog] set images entity', props<{ entity: FormEntityEnum }>());

export const uploadImages = createAction('[Rubric/dialog] upload images', props<{ files: File[]; entity: FormEntityEnum; rubricUuidEntity?: string }>());
export const uploadImagesFail = createAction('[Rubric/dialog] upload images fail', props<DialogToasterData>());

export const deleteImage = createAction('[Rubric/dialog] delete image', props<{ uuidEntity: string; entity?: FormEntityEnum }>());
export const deleteImageFail = createAction('[Rubric/dialog] delete image fail', props<DialogToasterData>());

// Option details
export const loadOptionDetails = createAction('[Rubric/dialog] load option details');
export const loadOptionDetailsSuccess = createAction('[Rubric/dialog] load option details success', props<{ optionDetails: OptionDetailsData }>());
export const loadOptionDetailsFail = createAction('[Rubric/dialog] load option details fail', props<DialogToasterData>());

export const updateOption = createAction('[Rubric/dialog] update option', props<{ optionDetails: OptionDetailsData }>());
export const updateOptionFail = createAction('[Rubric/dialog] update option fail', props<DialogToasterData>());

export const updateOptions = createAction('[Rubric/dialog] update options', props<{ genericOption: GenericOptionData }>());
export const updateOptionsFail = createAction('[Rubric/dialog] update options fail', props<DialogToasterData>());

export const resetOptionState = createAction('[Rubric/dialog] reset option state');

export const formCloseModal = createAction('[Question/dialog] form close modal', props<{ modalId: ModalIdEnum }>());

export const setAnnotateImageData = createAction('[Image/dialog] set annotate image data', props<{ localMode: boolean; entity: FormEntityEnum }>());
export const setViewImageData = createAction('[Image/dialog] set view image data', props<{ localMode: boolean }>());
export const updateImage = createAction('[Image/dialog] update image', props<{ file: File }>());
export const updateImageSuccess = createAction('[Image/dialog] update image success');
export const updateImageFail = createAction('[Image/dialog] update image fail', props<DialogToasterData>());
export const loadQuestionChangesHistory = createAction('[Rubric/dialog] load question changes history', props<{ versionRubricQuestionUuid: string }>());
export const loadQuestionChangesHistorySuccess = createAction(
  '[Rubric/dialog] load question changes history success',
  props<{ questionChangesHistory: VersionRubricOptionResponseData[] }>(),
);
export const loadQuestionChangesHistoryFailed = createAction('[Question/dialog] load question changes history', props<DialogToasterData>());
export const removeImage = createAction('[Image/dialog] remove locked application file', props<{ imageUuidEntity: string }>());
