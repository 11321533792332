/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

export interface PageElementEmmRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  families?: Array<string>;
  applicabilities?: Array<string>;
  customFilter?: string;
  flowUuidEntity?: string;
  archiveStatus?: PageElementEmmRequestDTO.ArchiveStatusEnum;
}
export namespace PageElementEmmRequestDTO {
  export type ArchiveStatusEnum = 'ARCHIVED' | 'UNARCHIVED' | 'ALL';
  export const ArchiveStatusEnum = {
    ARCHIVED: 'ARCHIVED' as ArchiveStatusEnum,
    UNARCHIVED: 'UNARCHIVED' as ArchiveStatusEnum,
    ALL: 'ALL' as ArchiveStatusEnum,
  };
}
