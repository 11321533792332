import { Observable, throwError } from 'rxjs';
import { IntercomData } from '../../models/help-center/intercom-data';
import { ZendeskData } from '../../models/help-center/zendesk-data';
import { SfxMap } from '../../utils/enum-utils';

export enum HelpCenterEnum {
  Zendesk = 1,
  Intercom,
}

export namespace HelpCenterEnum {
  export const scriptSource = new SfxMap<HelpCenterEnum, string>([
    [HelpCenterEnum.Zendesk, ZendeskData.scriptFile],
    [HelpCenterEnum.Intercom, IntercomData.scriptFile],
  ]);

  export function shutdown(): void {
    ZendeskData.shutdown();
    IntercomData.shutdown();
  }

  export function loadScript(id: string, source: string, type?: string): Observable<void> {
    return new Observable(sub => {
      const s = document.createElement('script');

      s.setAttribute('id', id);
      s.setAttribute('src', source);
      if (type) {
        s.setAttribute('type', type);
      }
      s.addEventListener('load', () => sub.next());
      s.addEventListener('error', () => throwError(() => 'Script load failed'));

      document.head.appendChild(s);
    });
  }
}
