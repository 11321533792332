import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../modules/shared/components/select-v2/utils/select-item-data';
import { ProjectDetailsResponseDTO } from '../generated/models/models';
import { SfxMap } from '../utils/enum-utils';
import { MapSupportedLanguageEnum } from './map-supported-language.enum';

export enum LanguageEnum {
  None = 1,
  Fr = 2,
  En = 3,
  De = 4,
  It = 5,
}

export namespace LanguageEnum {
  const values = [LanguageEnum.None, LanguageEnum.Fr, LanguageEnum.En, LanguageEnum.De, LanguageEnum.It];
  const displayedValuesLanguages = [LanguageEnum.Fr, LanguageEnum.En, LanguageEnum.De, LanguageEnum.It];
  const languages = [LanguageEnum.Fr, LanguageEnum.En, LanguageEnum.De, LanguageEnum.It];

  export const supportedLanguages = ['fr', 'en', 'de', 'it'];
  export const defaultLanguage = 'en';
  export const convertFromApiValue: SfxMap<ProjectDetailsResponseDTO.ExportLanguageOneEnum, LanguageEnum> = new SfxMap<
    ProjectDetailsResponseDTO.ExportLanguageOneEnum,
    LanguageEnum
  >([
    [ProjectDetailsResponseDTO.ExportLanguageOneEnum.NONE, LanguageEnum.None],
    [ProjectDetailsResponseDTO.ExportLanguageOneEnum.FR, LanguageEnum.Fr],
    [ProjectDetailsResponseDTO.ExportLanguageOneEnum.EN, LanguageEnum.En],
    [ProjectDetailsResponseDTO.ExportLanguageOneEnum.DE, LanguageEnum.De],
    [ProjectDetailsResponseDTO.ExportLanguageOneEnum.IT, LanguageEnum.It],
  ]);

  export const convertToApiValue: SfxMap<LanguageEnum, ProjectDetailsResponseDTO.ExportLanguageOneEnum> = new SfxMap<LanguageEnum, ProjectDetailsResponseDTO.ExportLanguageOneEnum>(
    [
      [LanguageEnum.None, ProjectDetailsResponseDTO.ExportLanguageOneEnum.NONE],
      [LanguageEnum.Fr, ProjectDetailsResponseDTO.ExportLanguageOneEnum.FR],
      [LanguageEnum.En, ProjectDetailsResponseDTO.ExportLanguageOneEnum.EN],
      [LanguageEnum.De, ProjectDetailsResponseDTO.ExportLanguageOneEnum.DE],
      [LanguageEnum.It, ProjectDetailsResponseDTO.ExportLanguageOneEnum.IT],
    ],
  );

  export const convertToMapSupportedLanguage: SfxMap<string, MapSupportedLanguageEnum> = new SfxMap<string, MapSupportedLanguageEnum>(
    [
      ['en', MapSupportedLanguageEnum.ENGLISH],
      ['fr', MapSupportedLanguageEnum.FRENCH],
      ['de', MapSupportedLanguageEnum.GERMAN],
      ['it', MapSupportedLanguageEnum.ITALIAN],
    ],
    MapSupportedLanguageEnum.NGT,
  );

  export const convertFromApiSupportedLanguage: SfxMap<LanguageEnum, string> = new SfxMap<LanguageEnum, string>([
    [LanguageEnum.En, 'en'],
    [LanguageEnum.Fr, 'fr'],
    [LanguageEnum.It, 'it'],
    [LanguageEnum.De, 'de'],
  ]);

  const key = 'flowApp.language.';
  export const toString: SfxMap<LanguageEnum, string> = new SfxMap<LanguageEnum, string>([
    [LanguageEnum.None, key + 'withoutLanguage'],
    [LanguageEnum.Fr, key + 'fr'],
    [LanguageEnum.En, key + 'en'],
    [LanguageEnum.De, key + 'de'],
    [LanguageEnum.It, key + 'it'],
  ]);

  export const itemsResolver = (excludeLanguage?: LanguageEnum): Observable<SingleSelectItemData<LanguageEnum>[]> =>
    of((excludeLanguage ? values.filter(value => value !== excludeLanguage) : values).map(value => ({ value, title: toString.getValue(value) })));

  export const singleSelectItemsLanguages = (): Observable<SingleSelectItemData<LanguageEnum>[]> =>
    of(displayedValuesLanguages.map(value => ({ value, title: toString.getValue(value) })));

  export const singleSelectLanguages = (): Observable<SingleSelectItemData<LanguageEnum>[]> => of(languages.map(value => ({ value, title: toString.getValue(value) })));

  export const comparePredicate = (node: SingleSelectItemData<LanguageEnum>, item: LanguageEnum) => node.value === item;
}
