import { UpdateFormsFlowsRequestDTO } from '../../generated/models/updateFormsFlowsRequestDTO';

export interface FormConfirmRefreshRequest {
  formUuidEntity: string;
  projectUuidEntity: string;
  confirm?: boolean;
}

export namespace FormConfirmRefreshRequest {
  export function mapToApiValue(form: FormConfirmRefreshRequest): UpdateFormsFlowsRequestDTO {
    return {
      formUuidEntity: form.formUuidEntity,
      projectUuidEntity: form.projectUuidEntity,
      confirmed: form.confirm,
    };
  }
}
