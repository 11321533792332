import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { UpdateFormsResponseDTO } from '../../generated/models/updateFormsResponseDTO';
import { FormFlowsData } from './form-flows-data';

export interface FormRefreshConflictData {
  success: boolean;
  labeledLinks: FormFlowsData[];
}

export namespace FormRefreshConflictData {
  export function mapToApiValue(form: UpdateFormsResponseDTO): FormRefreshConflictData {
    return {
      success: form.success,
      labeledLinks: (form.updateFormResponses || []).map(link => FormFlowsData.mapFromApiValue(link)),
    };
  }

  export function mapToConflictData(form: FormRefreshConflictData): ConflictData[] {
    return (form.labeledLinks || []).map(data => FormFlowsData.mapToConflictData(data));
  }
}
