import { createAction, props } from '@ngrx/store';

import {
  ProjectForUpdate,
  ProjectForDuplicate,
  PgacApplicationEventData,
  CustomFileApplicationEventData,
  PgacConflictsData,
  EntityLightData,
  CheckExistedReferenceRequest,
  CustomFieldData,
  CustomFileLightData,
  LazyLoadResponse,
  ApplicationEventLightData,
} from '../../../../core/models';
import { DialogToasterData } from '../../../sfx-dialog/state/dialog-toaster-data';

// Project dialog actions
export const addProject = createAction('[projectDialog] add new project', props<{ project: ProjectForUpdate; closeModal?: boolean; nextTab?: string }>());
export const addProjectSuccess = createAction('[projectDialog] add new project success', props<{ projectDetails: ProjectForUpdate; closeModal?: boolean; nextTab?: string }>());
export const addProjectFail = createAction('[projectDialog] add new project fail', props<DialogToasterData>());

export const updateProject = createAction('[projectDialog] update project', props<{ project: ProjectForUpdate; closeModal: boolean }>());
export const updateProjectSuccess = createAction('[projectDialog] update project success', props<{ projectDetails: ProjectForUpdate; closeModal: boolean }>());
export const updateProjectFail = createAction('[projectDialog] update project fail', props<DialogToasterData>());

export const saveProjectCustomFields = createAction('[projectDialog] save project custom fields', props<{ customFields: CustomFieldData[] }>());
export const saveProjectCustomFieldsSuccess = createAction('[projectDialog] save project custom fields success');
export const saveProjectCustomFieldsFail = createAction('[projectDialog] save project custom fields fail', props<DialogToasterData>());

export const getProjectDetailsSuccess = createAction('[projectDialog] get project details success', props<{ projectDetails: ProjectForUpdate }>());
export const getProjectDetailsFail = createAction('[projectDialog] get project details fail', props<DialogToasterData>());

export const loadProjectCustomFields = createAction('[projectDialog] load project custom fields', props<{ project: ProjectForUpdate }>());
export const loadProjectCustomFieldsSuccess = createAction('[projectDialog] load project custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadProjectCustomFieldsFail = createAction('[projectDialog] load project custom fields fail', props<DialogToasterData>());

export const initializeProjectDetails = createAction('[projectDialog] initialize project details', props<{ projectDetails: ProjectForUpdate }>());
export const initializeProjectCustomFields = createAction('[projectDialog] initialize project custom fields', props<{ customFields: CustomFieldData[] }>());

export const saveChangesProjectDialog = createAction(
  '[projectDialog] save changes project dialog',
  props<{ project: ProjectForUpdate; customFields: CustomFieldData[]; editMode: boolean; nextTab: string }>(),
);

export const saveAll = createAction(
  '[projectDialog] save all (project and custom fields)',
  props<{
    project: ProjectForUpdate;
    initialProject: ProjectForUpdate;
    customFields: CustomFieldData[];
    initialCustomFields: CustomFieldData[];
    close?: boolean;
    nextTab?: string;
    url?: string;
  }>(),
);

export const updateProjectLocally = createAction('[projectDialog] update project locally', props<ProjectForUpdate>());
export const checkGeneratedRefs = createAction('[projectDialog] check generated interne-external', props<CheckExistedReferenceRequest>());
export const checkGeneratedRefsSuccess = createAction(
  '[projectDialog] check generated interne-external success',
  props<{ hasRefIntGenerated: boolean; hasRefExtGenerated: boolean; reset: boolean }>(),
);
export const checkGeneratedRefsFail = createAction('[projectDialog] check references generated interne-external fail', props<DialogToasterData>());

// to be removed: individual check
export const checkRefIntGenerated = createAction('[projectDialog] check reference interne generated', props<CheckExistedReferenceRequest>());
export const checkRefIntGeneratedFail = createAction('[projectDialog] check reference interne generated fail', props<DialogToasterData>());
export const checkRefIntGeneratedSuccess = createAction('[projectDialog] check reference interne generated success', props<{ hasRefIntGenerated: boolean }>());
export const checkRefExtGenerated = createAction('[projectDialog] check reference external generated', props<CheckExistedReferenceRequest>());
export const checkRefExtGeneratedFail = createAction('[projectDialog] check reference external generated fail', props<DialogToasterData>());
export const checkRefExtGeneratedSuccess = createAction('[projectDialog] check reference external generated success', props<{ hasRefExtGenerated: boolean }>());

export const changeIsContractual = createAction('[projectDialog] change project is contractual', props<{ isContractual: boolean }>());
export const setVisibleLanguageBlock = createAction('[projectDialog] set visible language block', props<{ isVisibleLanguageBlock: boolean }>());

// PGAC project dialog actions
export const loadPgacEventList = createAction('[projectDialog] load pgac event list');
export const loadPgacEventListSuccess = createAction(
  '[projectDialog] load pgac event list success',
  props<{ events: PgacApplicationEventData[]; totalCount: number; reset?: boolean }>(),
);
export const loadPgacEventListFail = createAction('[projectDialog] load pgac event list fail', props<DialogToasterData>());

export const loadMorePgacEventList = createAction('[projectDialog] load more pgac event list');

export const importEpsilonOutFiles = createAction('[projectDialog] import epsilonOut files', props<{ files: File[] }>());
export const importEpsilonOutFilesFail = createAction('[projectDialog] import epsilonOut files fail', props<DialogToasterData>());
export const importEpsilonOutFilesSuccess = createAction('[projectDialog] import epsilonOut files success');

export const exportEpsilonOutFile = createAction('[projectDialog] export epsilonOut file');
export const exportEpsilonOutFileFail = createAction('[projectDialog] export epsilonOut file fail', props<DialogToasterData>());
export const exportEpsilonOutFileSuccess = createAction('[projectDialog] export epsilonOut file success');

export const downloadApplicationEvent = createAction('[projectDialog] download application event', props<{ fileUuidEntity: string }>());
export const downloadApplicationEventFail = createAction('[projectDialog] download application event fail', props<DialogToasterData>());
export const downloadApplicationEventSuccess = createAction('[projectDialog] download application event success');

// Processing File dialog actions
export const loadCustomFileEventList = createAction('[projectDialog] load custom file event list');
export const loadCustomFileEventListSuccess = createAction(
  '[projectDialog] load custom file event list success',
  props<{ events: CustomFileApplicationEventData[]; totalCount: number; reset?: boolean }>(),
);
export const loadCustomFileEventListFail = createAction('[projectDialog] load custom file event list fail', props<DialogToasterData>());
export const loadMoreCustomFileEventList = createAction('[projectDialog] load more custom file event list');

export const importCustomFiles = createAction('[projectDialog] import custom file files', props<{ files: File[]; customFile: EntityLightData; separator: string }>());
export const importCustomFilesFail = createAction('[projectDialog] import custom file files fail', props<DialogToasterData>());
export const importCustomFilesSuccess = createAction('[projectDialog] import custom file files success');

export const exportCustomFile = createAction('[projectDialog] export custom file file', props<{ customFile: CustomFileLightData; projects: string[] }>());
export const exportCustomFileFail = createAction('[projectDialog] export custom file file fail', props<DialogToasterData>());
export const exportCustomFileSuccess = createAction('[projectDialog] export custom file file success');

export const downloadCustomFileApplicationEvent = createAction('[projectDialog] download custom file application event', props<{ fileUuidEntity: string }>());
export const downloadCustomFileApplicationEventFail = createAction('[projectDialog] download custom file application event fail', props<DialogToasterData>());
export const downloadCustomFileApplicationEventSuccess = createAction('[projectDialog] download custom file application event success');

// Duplicate dialog actions
export const getDuplicatedProjectFail = createAction('[projectDialog] get duplicated project fail', props<DialogToasterData>());
export const getDuplicatedProjectSuccess = createAction('[projectDialog] get duplicated project success', props<{ duplicatedProject: ProjectForDuplicate }>());

export const duplicateProject = createAction('[projectDialog] duplicate project', props<{ duplicatedProject: ProjectForDuplicate }>());
export const duplicateProjectFail = createAction('[projectDialog] duplicate project fail', props<DialogToasterData>());
export const duplicateProjectSuccess = createAction('[projectDialog] duplicate project success');

export const duplicateFolderProject = createAction('[projectDialog] duplicate folder project', props<{ duplicatedFolderProject: ProjectForDuplicate }>());

export const loadConflictList = createAction('[projectDialog] load conflict list');
export const loadConflictListSuccess = createAction(
  '[projectDialog] load pgac conflicts list success',
  props<{ conflicts: PgacConflictsData[]; totalCount: number; reset?: boolean }>(),
);
export const loadConflictListFail = createAction('[projectDialog] load pgac conflicts list fail', props<DialogToasterData>());
export const loadMoreConflictList = createAction('[projectDialog] load more conflicts list');

export const updateFolderProject = createAction('[projectDialog] update folder project', props<{ project: ProjectForUpdate }>());
export const updateFolderProjectSuccess = createAction('[projectDialog] update folder project success', props<{ projectDetails: ProjectForUpdate }>());

export const prepareRefFlow = createAction('[ProjectFolder/API] prepare referential', props<{ project: ProjectForUpdate; atLeastOneFlow: boolean }>());
export const completePrepareRefFlow = createAction('[ProjectFolder/API] complete prepare referential ');

export const loadHistoryListSuccess = createAction('[projectDialog] Load history list success', props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>());
export const loadHistoryListNextPage = createAction('[projectDialog] Load history list next page');
