import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResetPasswordRequestDTO, AuthServerConfig } from '../generated/models/models';

import { HttpParamsType, UserCredentials, AuthServerConfigData } from '../models';
import { UserConnection } from '../models';
import { BaseHttpService } from './base-http.service';

import { ApiUrlsEnum } from './helpers/api-url';
import { apiUrlMatcher } from './helpers/api-url-matcher';
import { AuthMethodEnum } from '../enums/auth-method.enum';

@Injectable()
export class LoginService {
  constructor(private baseHttpService: BaseHttpService) {}

  public login(params: HttpParamsType<UserCredentials>): Observable<UserConnection> {
    return this.baseHttpService.post<{ id_token: string }>(apiUrlMatcher(ApiUrlsEnum.Authenticate), params).pipe(map(response => UserConnection.mapFromApiValue(response)));
  }

  public resetPwd(params: HttpParamsType<ResetPasswordRequestDTO>): Observable<boolean> {
    return this.baseHttpService.post<void>(apiUrlMatcher(ApiUrlsEnum.resetPasswordInit), params).pipe(map(() => true));
  }

  public resetPwdFinish(params: HttpParamsType<{ key: string; newPassword: string }>): Observable<boolean> {
    return this.baseHttpService.post<void>(apiUrlMatcher(ApiUrlsEnum.resetPasswordFinish), params).pipe(map(() => true));
  }

  public getAuthorizationServerConfig(): Observable<AuthServerConfigData> {
    return this.baseHttpService.get<AuthServerConfig>(apiUrlMatcher(ApiUrlsEnum.AuthServerConfig)).pipe(map(authData => AuthServerConfigData.mapFromApiValue(authData)));
  }

  public getAuthMethod(): Observable<AuthMethodEnum> {
    return this.baseHttpService.get<string>(apiUrlMatcher(ApiUrlsEnum.AuthMethod)).pipe(map(value => AuthMethodEnum.convertFromApiValue.getValue(value)));
  }
}
