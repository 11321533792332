import { PermissionActionEnum } from '../permission-action.enum';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum ResponseActionEnum {
  Update = 1,
  UpdateReference = 2,
  GenerateDocument = 3,
  UpdateState = 4,
  Delete = 5,
  History = 6,
  Share = 7,
  Duplicate = 8,
}

export namespace ResponseActionEnum {
  export const actionWhereResponseIsInProgress = [
    ResponseActionEnum.Update,
    ResponseActionEnum.UpdateReference,
    ResponseActionEnum.GenerateDocument,
    ResponseActionEnum.Duplicate,
    ResponseActionEnum.Share,
    ResponseActionEnum.History,
    ResponseActionEnum.Delete,
  ];
  export const actionWhenAResponseIsDone = [
    ResponseActionEnum.GenerateDocument,
    ResponseActionEnum.Duplicate,
    ResponseActionEnum.Share,
    ResponseActionEnum.UpdateState,
    ResponseActionEnum.History,
    ResponseActionEnum.Delete,
  ];

  const key = 'responses.table.actions.';
  export const toString: SfxMap<ResponseActionEnum, string> = new SfxMap<ResponseActionEnum, string>([
    [ResponseActionEnum.Update, key + 'update'],
    [ResponseActionEnum.UpdateReference, key + 'updateReference'],
    [ResponseActionEnum.GenerateDocument, key + 'generateDocument'],
    [ResponseActionEnum.UpdateState, key + 'updateStatus'],
    [ResponseActionEnum.History, key + 'history'],
    [ResponseActionEnum.Delete, key + 'delete'],
    [ResponseActionEnum.Share, key + 'share'],
    [ResponseActionEnum.Duplicate, key + 'duplicate'],
  ]);

  export const iconData: SfxMap<ResponseActionEnum, IconData> = new SfxMap<ResponseActionEnum, IconData>([
    [ResponseActionEnum.Update, { name: 'edit', prefix: 'far' }],
    [ResponseActionEnum.UpdateReference, { name: 'pencil-alt', prefix: 'fas' }],
    [ResponseActionEnum.GenerateDocument, { name: 'file-invoice', prefix: 'fas' }],
    [ResponseActionEnum.UpdateState, { name: 'retweet', prefix: 'fas' }],
    [ResponseActionEnum.History, { name: 'flag', prefix: 'fas' }],
    [ResponseActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [ResponseActionEnum.Share, { name: 'share-square', prefix: 'fas' }],
    [ResponseActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
  ]);

  export function getActionMenu(): ActionSelectItemData<ResponseActionEnum>[] {
    return [
      mapToActionMenuItem(ResponseActionEnum.Update, PermissionActionEnum.Edit),
      mapToActionMenuItem(ResponseActionEnum.UpdateReference, PermissionActionEnum.Edit),
      mapToActionMenuItem(ResponseActionEnum.GenerateDocument),
      mapToActionMenuItem(ResponseActionEnum.Duplicate, PermissionActionEnum.Duplicate),
      mapToActionMenuItem(ResponseActionEnum.Share, PermissionActionEnum.Share),
      mapToActionMenuItem(ResponseActionEnum.UpdateState, PermissionActionEnum.EditStatus),
      mapToActionMenuItem(ResponseActionEnum.History),
      mapToActionMenuItem(ResponseActionEnum.Delete, PermissionActionEnum.Delete),
    ];
  }

  export function mapToActionMenuItem(responseActionMenu: ResponseActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<ResponseActionEnum> {
    return {
      name: ResponseActionEnum.toString.getValue(responseActionMenu),
      permissionAction,
      value: responseActionMenu,
      icon: ResponseActionEnum.iconData.getValue(responseActionMenu),
    };
  }
}
