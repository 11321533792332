import { LibraryContributorSummaryData } from '../../models/library-contributor/library-contributor-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum LibraryContributorColumnEnum {
  Function = 'function',
  Name = 'name',
  Hierarchy = 'hierarchy',
  Authority = 'authority',
  Status = 'status',
  Skills = 'skills',
  Company = 'company',
  Actions = 'actions',
}

export namespace LibraryContributorColumnEnum {
  export type libraryContributorSortField = keyof LibraryContributorSummaryData;

  export const allColumns = [
    LibraryContributorColumnEnum.Function,
    LibraryContributorColumnEnum.Name,
    LibraryContributorColumnEnum.Skills,
    LibraryContributorColumnEnum.Hierarchy,
    LibraryContributorColumnEnum.Authority,
    LibraryContributorColumnEnum.Company,
    LibraryContributorColumnEnum.Status,
    LibraryContributorColumnEnum.Actions,
  ];

  export const operatorsColumns = [
    LibraryContributorColumnEnum.Function,
    LibraryContributorColumnEnum.Name,
    LibraryContributorColumnEnum.Skills,
    LibraryContributorColumnEnum.Hierarchy,
    LibraryContributorColumnEnum.Company,
    LibraryContributorColumnEnum.Status,
    LibraryContributorColumnEnum.Actions,
  ];

  export const reorganizeColumns = [
    LibraryContributorColumnEnum.Function,
    LibraryContributorColumnEnum.Name,
    LibraryContributorColumnEnum.Skills,
    LibraryContributorColumnEnum.Hierarchy,
    LibraryContributorColumnEnum.Company,
  ];

  const key = 'contributors.table.header.';
  export const toString = new SfxMap<LibraryContributorColumnEnum, string>([
    [LibraryContributorColumnEnum.Function, key + 'function'],
    [LibraryContributorColumnEnum.Name, key + 'name'],
    [LibraryContributorColumnEnum.Hierarchy, key + 'hierarchy'],
    [LibraryContributorColumnEnum.Authority, key + 'authority'],
    [LibraryContributorColumnEnum.Status, key + 'status'],
    [LibraryContributorColumnEnum.Company, key + 'company'],
    [LibraryContributorColumnEnum.Skills, key + 'skills'],
    [LibraryContributorColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField = new SfxMap<LibraryContributorColumnEnum, libraryContributorSortField>([
    [LibraryContributorColumnEnum.Function, 'function'],
    [LibraryContributorColumnEnum.Name, 'name'],
  ]);
}
