import { TechnicalObjectUpdateStatusRequestDTO } from '../../generated/models/models';
import { TechnicalObjectDetailsData } from './technical-object-details';

export type TechnicalObjectUpdate = Partial<TechnicalObjectDetailsData>;

export namespace TechnicalObjectForUpdate {
  export function mapToUpdateRequestApiValue(object: TechnicalObjectUpdate): TechnicalObjectUpdateStatusRequestDTO {
    return {
      uuidEntity: object.uuidEntity,
      customStatusUuidEntity: object.customStatus && object.customStatus.uuidEntity,
    };
  }
}
