/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SectionSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  type: SectionSummaryResponseDTO.TypeEnum;
  order: number;
  documentsCount: number;
  includePreviousVersions: boolean;
  disabled: boolean;
}
export namespace SectionSummaryResponseDTO {
  export type TypeEnum =
    | 'SECTION_REPORT'
    | 'SECTION_DOCUMENT'
    | 'SECTION_CERTIFICATE'
    | 'SECTION_REFERENCE'
    | 'SECTION_CLIENT_RESPONSE'
    | 'SECTION_DOCUMENT_LARGE_FILE'
    | 'SECTION_RFI';
  export const TypeEnum = {
    REPORT: 'SECTION_REPORT' as TypeEnum,
    DOCUMENT: 'SECTION_DOCUMENT' as TypeEnum,
    CERTIFICATE: 'SECTION_CERTIFICATE' as TypeEnum,
    REFERENCE: 'SECTION_REFERENCE' as TypeEnum,
    CLIENTRESPONSE: 'SECTION_CLIENT_RESPONSE' as TypeEnum,
    DOCUMENTLARGEFILE: 'SECTION_DOCUMENT_LARGE_FILE' as TypeEnum,
    RFI: 'SECTION_RFI' as TypeEnum,
  };
}
