import { compact } from 'lodash';

import { DocumentFlowFindLazyByProjectRequestDTO } from '../../generated/models/models';

import { DateFilterData } from '../utils/date-filter-data';
import { DashboardDocumentSummaryData } from './dashboard-document-summary';
import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { DocumentProvenanceEnum, InnerSortDirectionEnum } from '../../enums';
import { DocumentFilterStateEnum } from '../../enums/document/document-filter-state.enum';

export interface DashboardDocumentReorganizeQueryRequest extends BaseQuery<DashboardDocumentSummaryData> {
  projectUuidEntity: string;
  provenances?: DocumentProvenanceEnum[];
  families?: string[];
  categories?: string[];
  categories2?: string[];
  states?: DocumentFilterStateEnum[];
  flows?: string[];
  customWording?: string[];
  dateFilter?: DateFilterData;
}

export namespace DashboardDocumentReorganizeQueryRequest {
  export function mapToApiValue(queries: DashboardDocumentReorganizeQueryRequest): DocumentFlowFindLazyByProjectRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      textSearch: queries.textSearch || undefined,
      provenance: (queries.provenances && queries.provenances.map(provenance => DocumentProvenanceEnum.convertToApiValue.getValue(provenance))) || undefined,
      familyUuidEntity: queries.families || undefined,
      categoryPrincipalUuidEntity: queries.categories || undefined,
      categorySecondaryUuidEntity: queries.categories2 || undefined,
      filterEtat: queries.states?.length ? queries.states.map(state => DocumentFilterStateEnum.convertToApiValue.getValue(state)) : undefined,
      flowUuidEntity: queries.flows || undefined,
      customWordingUuidEntities: compact(queries.customWording),
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
    };
  }

  export function initialize(
    projectUuidEntity: string,
    listQueryParam: {
      provenances: string[];
      families: string[];
      categories: string[];
      categories2: string[];
      customWording: CustomWordingStateData[];
      flows: string[];
    },
    textSearch: string,
    sort: SortFilter<DashboardDocumentSummaryData>,
    dateFilter: DateFilterData,
  ): DashboardDocumentReorganizeQueryRequest {
    return {
      ...initializeQueries<DashboardDocumentSummaryData>({ key: sort.key as keyof DashboardDocumentSummaryData, direction: sort.direction }),
      projectUuidEntity,
      textSearch: textSearch || undefined,
      provenances: (listQueryParam.provenances || []).length
        ? listQueryParam.provenances.map(provenance => DocumentProvenanceEnum.convertFromParamsValue.getValue(provenance))
        : undefined,
      families: (listQueryParam.families || []).length ? listQueryParam.families : undefined,
      categories: (listQueryParam.categories || []).length ? listQueryParam.categories : undefined,
      categories2: (listQueryParam.categories2 || []).length ? listQueryParam.categories2 : undefined,
      states: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(cw => cw.actionKey) : undefined,
      flows: (listQueryParam.flows || []).length ? listQueryParam.flows : undefined,
      customWording: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(cw => cw.customWordingUuidEntity) : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
