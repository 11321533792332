/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ResponseErrorUploadUserDTO {
  contractType?: string;
  email?: string;
  error?: ResponseErrorUploadUserDTO.ErrorEnum;
  firstName?: string;
  _function?: string;
  group?: string;
  hierarchy?: string;
  lastName?: string;
  legalEntity?: string;
  matricule?: string;
  profession?: string;
  role?: string;
  ssoIdentifier?: string;
  trigram?: string;
  type?: string;
}
export namespace ResponseErrorUploadUserDTO {
  export type ErrorEnum = 'DUPLICATED' | 'INVALID_FAMILY' | 'INVALID_RIGHT' | 'MANDATORY_PARAMETER' | 'INVALID_EMAIL' | 'INVALID_COMPANY';
  export const ErrorEnum = {
    DUPLICATED: 'DUPLICATED' as ErrorEnum,
    INVALIDFAMILY: 'INVALID_FAMILY' as ErrorEnum,
    INVALIDRIGHT: 'INVALID_RIGHT' as ErrorEnum,
    MANDATORYPARAMETER: 'MANDATORY_PARAMETER' as ErrorEnum,
    INVALIDEMAIL: 'INVALID_EMAIL' as ErrorEnum,
    INVALIDCOMPANY: 'INVALID_COMPANY' as ErrorEnum,
  };
}
