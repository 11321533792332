import { EntityChronoSummaryResponseDTO } from '../../generated/models/entityChronoSummaryResponseDTO';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { PageEntityChronoRequest } from '../../generated/models/models';
import { SortFilter } from '../utils/sort-filter';

import { BusinessClassNameEnum, FieldNameEnum, InnerSortDirectionEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface ReferenceSummaryData {
  uuidEntity: string;
  nature: BusinessClassNameEnum;
  field: string;
  nomenclature: string;
  archived: boolean;
}

export namespace ReferenceSummaryData {
  const sortFields = ['nature', 'fieldName'];
  export interface QueryRequest extends BaseQuery<ReferenceSummaryData> {
    nature?: BusinessClassNameEnum;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(data: EntityChronoSummaryResponseDTO): ReferenceSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      nature: BusinessClassNameEnum.convertFromApiValue.getValue(data.nature),
      field:
        data.fieldName &&
        FieldNameEnum.toString(FieldNameEnum.convertFromApiValue.getValue(data.fieldName)).getValue(BusinessClassNameEnum.convertFromApiValue.getValue(data.nature)),
      nomenclature: data.paternChrono,
      archived: data['archived'],
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageEntityChronoRequest {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(queries.nature),
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, nature: string, sort: SortFilter<ReferenceSummaryData>, state?: string): QueryRequest {
    return {
      ...initializeQueries<ReferenceSummaryData>(sort),
      textSearch: searchText || undefined,
      nature: BusinessClassNameEnum.convertFromParamsValue.getValue(nature) || undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }
}
