import { createAction, props } from '@ngrx/store';
import { ResetValidationExecuteFlow } from '../../../../../core/models/flows/reset-validation-to-execute-flow';
import { ResetValidationFlowData } from '../../../../../core/models';

import {
  FlowDetailsData,
  CustomFieldData,
  FlowToImportSummaryData,
  ErrorResponseData,
  LazyLoadResponse,
  ApplicationEventLightData,
  FlowSummaryData,
} from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

// Flow referential dialog actions
export const updateReferentialFlow = createAction('[dReferentialFlowDialog] update referential flow', props<{ flowDetails: FlowDetailsData; closeModal?: boolean }>());
export const updateReferentialFlowSuccess = createAction(
  '[dReferentialFlowDialog] update referential flow success',
  props<{ flowDetails: FlowDetailsData; closeModal?: boolean }>(),
);
export const updateReferentialFlowFail = createAction('[dReferentialFlowDialog] update referential flow fail', props<DialogToasterData>());

export const getReferentialFlowDetailsSuccess = createAction('[dReferentialFlowDialog] get referential flow details success', props<{ flowDetails: FlowDetailsData }>());
export const getReferentialFlowDetailsFail = createAction('[dReferentialFlowDialog] get referential flow details fail', props<DialogToasterData>());

export const loadReferentialFlowCustomFields = createAction('[dReferentialFlowDialog] load referential flow custom fields', props<{ referentialFlow: FlowDetailsData }>());
export const loadReferentialFlowCustomFieldsSuccess = createAction(
  '[dReferentialFlowDialog] load referential flow custom fields success',
  props<{ customFields: CustomFieldData[] }>(),
);
export const loadReferentialFlowCustomFieldsFail = createAction('[dReferentialFlowDialog] load referential flow custom fields fail', props<DialogToasterData>());

export const initializeReferentialFlowDetails = createAction('[dReferentialFlowDialog] initialize referential flow details', props<{ flowDetails: FlowDetailsData }>());

export const navigateThroughReferentialFlows = createAction(
  '[dReferentialFlowDialog] navigate through referential flows',
  props<{ uuidEntity: string; step: number; flowDetails: FlowDetailsData; customFields: CustomFieldData[] }>(),
);

export const navigateThroughReferentialFlowsSuccess = createAction(
  '[dReferentialFlowDialog] Navigate through referential flows success',
  props<{ uuidEntity: string; step: number }>(),
);

export const loadImportReferentialList = createAction('[dReferentialFlowDialog] Load import referential flow list');
export const loadImportReferentialListSuccess = createAction(
  '[dReferentialFlowDialog] Load import referential flow list success',
  props<{ flows: FlowToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadImportReferentialListNextPage = createAction('[dReferentialFlowDialog] Load import referential flow list next page');
export const loadImportReferentialListFails = createAction('[dReferentialFlowDialog] Load import referential flow list fails', props<DialogToasterData>());

export const importReferentialFlows = createAction('[dReferentialFlowDialog] import referential flows', props<{ selectedFlows: string[] }>());
export const importReferentialFlowsFail = createAction('[dReferentialFlowDialog] import referential flows fail', props<{ error: ErrorResponseData }>());

export const setSelectedReferentialFlows = createAction('[dReferentialFlowDialog] set selected referential flows', props<{ selectedReferentialFlowsUuidEntity: string[] }>());

export const updateImportedReferentialFlows = createAction('[dFormDialog] update imported referential flows', props<{ selectedFlows: string[] }>());
export const updateImportedReferentialFlowsFail = createAction('[dFormDialog] update imported referential flows fail', props<DialogToasterData>());

export const loadFlowRefHistorySuccess = createAction(
  '[dReferentialFlowDialog] Load history list success',
  props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>(),
);
export const loadFlowRefHistoryNextPage = createAction('[dReferentialFlowDialog] Load history list next page');
export const loadFlowRefHistoryFails = createAction('[dReferentialFlowDialog] Load history list fails', props<DialogToasterData>());

export const loadExecuteList = createAction('[dReferentialFlowDialog] Load execute list');
export const loadExecuteListNextPage = createAction('[dReferentialFlowDialog] Load execute list next page');
export const loadExecuteListFails = createAction('[dReferentialFlowDialog] Load execute list fails', props<DialogToasterData>());
export const loadExecuteListSuccess = createAction(
  '[dReferentialFlowDialog] load execute flow list success',
  props<{ executeFlowData: FlowSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);

export const executeFlow = createAction('[dReferentialFlowDialog] execute flow', props<{ request: ResetValidationFlowData }>());
export const executeFlowFail = createAction('[dReferentialFlowDialog] execute flow fail');
export const executeFlowSuccess = createAction('[dReferentialFlowDialog] execute flow success');

export const saveReferentiaFlowCustomFields = createAction('[dReferentialFlowDialog] save flow custom fields', props<{ customFields: CustomFieldData[]; closeModal?: boolean }>());

export const executeFlowList = createAction(
  '[dReferentialFlowDialog] execute flow list',
  props<{ flowEntityUuids: string[]; count: number; request: ResetValidationExecuteFlow }>(),
);
