import { BlocContentUpdateRequestDTO, BlocPrDetailsResponseDTO } from '../../generated/models/models';

export interface BlockPrDetailsData {
  content: string;
  uuidEntity: string;
}

export namespace BlockPrDetailsData {
  export function mapFromApiValue(blockPr: BlocPrDetailsResponseDTO): BlockPrDetailsData {
    return {
      content: blockPr.content,
      uuidEntity: blockPr.uuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(content: string, uuidEntity: string): BlocContentUpdateRequestDTO {
    return { content, uuidEntity };
  }
}
