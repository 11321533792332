import { FlowLinkResponseDTO } from '../../generated/models/models';
import { StringUtilsEnum } from '../../enums';

export interface DeleteFlowLink {
  name: string;
  refInt: string;
  project: string;
}

export namespace DeleteFlowLink {
  export function mapFromApiValue(flowLink: FlowLinkResponseDTO): DeleteFlowLink {
    return {
      name: flowLink.name,
      refInt: flowLink.refIntern,
      project: flowLink?.project?.name,
    };
  }

  export function toString(flowLink: DeleteFlowLink): string {
    return (flowLink.refInt ? flowLink.refInt.concat(StringUtilsEnum.DashSeparator) : '').concat(
      flowLink.name ? flowLink.name.concat(StringUtilsEnum.DashSeparator) : '',
      flowLink.project,
    );
  }
}
