import { HttpResponse, HttpHeaders, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { ErrorResponse } from '../../models';
import { LocalStorageEnum } from '../../enums/local-storage.enum';

export const mockToken = 'mock__http__auth__token';

export function apiUrlMockChecker(baseUrl: string, url: string): boolean {
  const regexUuid = '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}';

  return new RegExp(baseUrl.replace(/\s/g, '').replace(new RegExp(/\{(.*?)\}/), regexUuid)).test(url);
}

export function ok<T>(body?: T, headers?: HttpHeaders): Observable<HttpResponse<T>> {
  return of(new HttpResponse({ status: 200, body, headers }));
}

export function exception(path: string, status: number, message: string): Observable<HttpResponse<ErrorResponse>> {
  const error: ErrorResponse = {
    status,
    path,
    message,
    title: 'Mock data exception error title',
    type: 'Mock data exception error type',
  };

  return throwError(() => error);
}

export function isLoggedIn(headers: HttpHeaders): boolean {
  return headers.get('Authorization') === `Bearer ${localStorage.getItem(LocalStorageEnum.AuthToken).replace(/"/g, '')}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mockRouteResponse(request: HttpRequest<any>, next: HttpHandler) {
  switch (true) {
    // case apiUrlMockChecker(`${apiUrl}${ApiUrlsEnum.CheckTokenValidity}`, request.url):
    //   return MockSessionService.checkTokenValidity(request);

    default:
      return next.handle(request);
  }
}
