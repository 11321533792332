import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum } from './../../enums/common/sort-direction.enum';

import { SequencePhaseSummaryResponseDTO, PagePhaseRequestDTO } from './../../generated/models/models';

export interface SequencePhaseSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
  customNodeOrder: string;
  nodeOrder: number;
  stepsCount: number;
  selected: boolean;
  frozen: boolean;
}
export namespace SequencePhaseSummaryData {
  export interface QueryRequest extends BaseQuery<SequencePhaseSummaryData> {
    projectUuidEntity: string;
  }

  export function mapFromApiValue(sequencePhase: SequencePhaseSummaryResponseDTO): SequencePhaseSummaryData {
    return {
      uuidEntity: sequencePhase.uuidEntity,
      name: sequencePhase.name,
      description: sequencePhase.description,
      customNodeOrder: sequencePhase.customNodeOrder,
      nodeOrder: sequencePhase.nodeOrder,
      stepsCount: sequencePhase.stepsCount,
      selected: sequencePhase.selected,
      frozen: sequencePhase.disabled,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PagePhaseRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
      sort: queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      families: [],
      categories: [],
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<SequencePhaseSummaryData>(),
      projectUuidEntity,
    };
  }
}
