import { SfxMap } from '../utils/enum-utils';

export enum CompaniesColumnsEnum {
  Name = 'name',
  ActivityArea = 'activityArea',
  Action = 'action',
}

export namespace CompaniesColumnsEnum {
  export const companiesColumns = [CompaniesColumnsEnum.Name, CompaniesColumnsEnum.ActivityArea, CompaniesColumnsEnum.Action];

  const key = 'companies.';
  export const toString = new SfxMap<CompaniesColumnsEnum, string>([
    [CompaniesColumnsEnum.Name, key + 'name'],
    [CompaniesColumnsEnum.ActivityArea, key + 'activityArea'],
    [CompaniesColumnsEnum.Action, key + 'action'],
  ]);
}
