import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { ErrorResponseData, EquipmentDetailsData, LibraryEquipmentSummaryData, EquipmentReorganizeData } from '../../../../../core/models';

export const setFileToUploadEquipments = createAction('[lEquipments/API] set file to upload equipments', props<{ equipmentsFile: File; forceApplicabilityOrga: boolean }>());
export const setFileToUploadEquipmentsSuccess = createAction('[lEquipments/API] set file to upload users equipments success');
export const stopImportFileLoading = createAction('[lEquipments/API] stop Import File Loading');
export const setFileToUploadEquipmentsFail = createAction('[lEquipments/API] set file to upload equipments fail', props<DialogToasterData>());

export const downloadInitialEmmTramFile = createAction('[lEquipments/API] download initial emm tram');
export const downloadInitialEmmTramFileSuccess = createAction('[lEquipments/API] download initial emm tram success');
export const downloadInitialEmmTramFileFail = createAction('[lEquipments/API] download initial emm tram fail', props<DialogToasterData>());

export const createEquipment = createAction(
  '[lEquipments/API] create Equipment',
  props<{ equipment: EquipmentDetailsData; fileRef: File; certificateFileRef: File; addNewOne?: boolean }>(),
);
export const createEquipmentSuccess = createAction('[lEquipments/API] create Equipment success', props<{ equipment: EquipmentDetailsData; addNewOne?: boolean }>());
export const createEquipmentFail = createAction('[lEquipments/API] create Equipment fail', props<DialogToasterData>());

export const loadEquipmentDetailDataSuccess = createAction('[lEquipments/API] load equipment data data success', props<{ equipmentDetails: EquipmentDetailsData }>());
export const loadEquipmentDetailDataFail = createAction('[lEquipments/API] load equipment data data fail', props<DialogToasterData>());

export const updateEquipment = createAction(
  '[lEquipments/API] update user',
  props<{ equipment: EquipmentDetailsData; fileRef: File; certificateFileRef: File; addNewOne?: boolean }>(),
);
export const updateEquipmentSuccess = createAction('[lEquipments/API] update user success', props<{ equipment: EquipmentDetailsData; addNewOne?: boolean; navigate?: boolean }>());
export const updateEquipmentFail = createAction('[lEquipments/API] update user fail', props<{ error: ErrorResponseData }>());

export const equipmentsDetailNavigation = createAction('[lEquipments/API] Navigate through details equipments', props<{ equipment: EquipmentDetailsData; step: number }>());
export const confirmDetailNavigation = createAction('[lEquipments/API] confirm navigate details equipments', props<{ equipment: EquipmentDetailsData; step: number }>());

export const downloadFile = createAction('[lEquipments] download file', props<{ uuidEntity: string }>());
export const downloadFileFail = createAction('[lEquipments] download file fail', props<DialogToasterData>());
export const downloadFileSuccess = createAction('[lEquipments] download file success');

export const reorganizeEquipments = createAction('[lEquipments/API] reorganize equipments', props<{ equipmentReorganize: EquipmentReorganizeData; count: number }>());
export const reorganizeEquipmentsFail = createAction('[lEquipments/API] reorganize equipments fail', props<DialogToasterData>());
export const reorganizeEquipmentsSuccess = createAction('[lEquipments/API] reorganize equipments success');

export const loadEquipmentReorganizeList = createAction('[lEquipments/API] load equipment reorganize list');
export const loadNextEquipmentReorganizePage = createAction('[lEquipments/API] load next equipment reorganize page ');
export const loadEquipmentReorganizeListSuccess = createAction(
  '[lEquipments/API] load equipment reorganize list success',
  props<{ equipments: LibraryEquipmentSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadEquipmentReorganizeListFail = createAction('[lEquipments/API] load equipment reorganize list fail', props<{ error: ErrorResponseData }>());
