import { NumberFormatEnum } from '../../enums/number-format.enum';
import { ApplicationCreateFromExistingRequestDTO } from '../../generated/models/applicationCreateFromExistingRequestDTO';
import { ApplicationDTO } from '../../generated/models/applicationDTO';
import { OrganizationConfigRequestDTO } from '../../generated/models/organizationConfigRequestDTO';
import { OrganizationConfigResponseDTO } from '../../generated/models/organizationConfigResponseDTO';
import { BaseQuery } from '../utils/base-query.model';

export interface OrganizationConfigDetails {
  allowedDownloadResponse: number;
  blockFormResponseEdition: boolean;
  diagramEnabled: boolean;
  linkExpirationDelayInDays: number;
  lmsTrainingUrl: string;
  localFormAllowed: boolean;
  mapViewDisabled: boolean;
  numberFormatEnum: NumberFormatEnum;
  rubricPermissionsEnabled: boolean;
  tabletPositionDisabled: boolean;
  uuidOrganization: string;
  zendeskEnabled: boolean;
}

export interface ApplicationData {
  companyName: string;
  uuidEntity: string;
}

export namespace OrganizationConfigDetails {
  export interface ApplicationsQuery {
    pageSize: number;
    pageIndex: number;
  }

  export function mapFromApplication(application: ApplicationDTO): ApplicationData {
    return {
      companyName: application.name,
      uuidEntity: application.uuidEntity,
    };
  }

  export function mapToApplicationCreateFromExisting(application: ApplicationData): ApplicationCreateFromExistingRequestDTO {
    return {
      companyName: application.companyName,
      applicationUuidEntity: application.uuidEntity,
    };
  }

  export function mapFromApiValue(config: OrganizationConfigResponseDTO): OrganizationConfigDetails {
    return {
      allowedDownloadResponse: config.allowedDownloadResponse,
      blockFormResponseEdition: config.blockFormResponseEdition,
      diagramEnabled: config.diagramEnabled,
      linkExpirationDelayInDays: config.linkExpirationDelayInDays,
      lmsTrainingUrl: config.lmsTrainingUrl,
      localFormAllowed: config.localFormAllowed,
      mapViewDisabled: config.mapViewDisabled,
      numberFormatEnum: NumberFormatEnum.convertFromApiValue.getValue(config.numberFormatEnum),
      rubricPermissionsEnabled: config.rubricPermissionsEnabled,
      tabletPositionDisabled: config.tabletPositionDisabled,
      uuidOrganization: config.uuidOrganization,
      zendeskEnabled: config.zendeskEnabled,
    };
  }

  export function mapToUpdateOrganizationConfig({
    allowedDownloadResponse,
    blockFormResponseEdition,
    diagramEnabled,
    linkExpirationDelayInDays,
    localFormAllowed,
    numberFormatEnum,
    rubricPermissionsEnabled,
    uuidOrganization,
    zendeskEnabled,
  }: OrganizationConfigDetails): OrganizationConfigRequestDTO {
    return {
      allowedDownloadResponse,
      blockFormResponseEdition,
      diagramEnabled,
      linkExpirationDelayInDays,
      localFormAllowed,
      numberFormatEnum: NumberFormatEnum.convertToApiValue.getValue(numberFormatEnum),
      rubricPermissionsEnabled,
      uuidOrganization,
      zendeskEnabled,
    };
  }

  export function convertToApplicationsRequest(query: BaseQuery<{}>): ApplicationsQuery {
    return {
      pageSize: query.size,
      pageIndex: query.page,
    };
  }
}
