import dayjs from 'dayjs';

import { PlanLinkTypeEnum } from '../../enums';
import { ElementPlanLinkSummaryResponseDTO } from '../../generated/models/models';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

export interface ElementPlanLinkSummaryData {
  name: string;
  color: string;
  linkUuidEntity: string;
  linkType: PlanLinkTypeEnum;
  sourceUuidEntity: string;
  destinationUuidEntity: string;
  status: CustomStatusLightData;
  labelFamilyName: string;
  endDate: string;
  startDate: string;
  datesOverlap?: boolean;
}

export namespace ElementPlanLinkSummaryData {
  export function mapFromApiValue(value: ElementPlanLinkSummaryResponseDTO): ElementPlanLinkSummaryData {
    return {
      name: value.name,
      color: value.color,
      linkUuidEntity: value.elementLinkUuidEntity,
      linkType: PlanLinkTypeEnum.convertFromApiValue.getValue(value.dependencyType),
      destinationUuidEntity: value.elementDestinationUuidEntity,
      sourceUuidEntity: value.elementPlanSourceUuidEntity,
      labelFamilyName: value.flowLabelFamily,
      status: CustomStatusLightData.mapFromApiValue(value.flowCustomStatus),
      startDate: dayjs(value.startDate).toString(),
      endDate: dayjs(value.finishDate).toString(),
    };
  }
}
