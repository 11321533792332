import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityChronoDetailsResponseDTO } from '../../generated/models/entityChronoDetailsResponseDTO';

import { CounterTypeEnum, CounterFormatEnum, FieldNameEnum, LinkTypeEnum, BusinessClassNameEnum } from '../../enums';

export interface ReferenceDetailsData {
  uuidEntity: string;
  counterFormat: CounterFormatEnum;
  counterType: CounterTypeEnum;
  fieldName: FieldNameEnum;
  labelFamily: EntityLightData;
  linkType: LinkTypeEnum;
  nature: BusinessClassNameEnum;
  nomenclature: string;
  startValue: number;
}

export namespace ReferenceDetailsData {
  export function mapFromApiValue(data: EntityChronoDetailsResponseDTO): ReferenceDetailsData {
    return {
      counterFormat: data.counterFormat,
      counterType: data.counterType && CounterTypeEnum.convertFromApiValue.getValue(data.counterType),
      fieldName: data.fieldName && FieldNameEnum.convertFromApiValue.getValue(data.fieldName),
      labelFamily: data.labelFamily && LabelFamilyLightData.mapToEntityLightData(data.labelFamily),
      linkType: data.linkType && LinkTypeEnum.convertFromApiValue.getValue(data.linkType),
      nature: data.nature && BusinessClassNameEnum.convertFromApiValue.getValue(data.nature),
      nomenclature: data.paternChrono,
      startValue: data.startValue,
      uuidEntity: data.uuidEntity,
    };
  }
}
