import { ProgressionSignatoryResponseDTO } from '../../generated/models/models';
import { SignatureMetricsState } from './signature-metric-state';

export interface SignatureMetricsDetails {
  realization: SignatureMetricsState;
  intern: SignatureMetricsState;
  extern: SignatureMetricsState;
  technicalControl: SignatureMetricsState;
  total?: SignatureMetricsState;
}

export namespace SignatureMetricsDetails {
  export function mapFromApiValue(signature: ProgressionSignatoryResponseDTO): SignatureMetricsDetails {
    const signaturesData: SignatureMetricsDetails = {
      realization: SignatureMetricsState.mapFromApiValue(signature.implementation),
      intern: SignatureMetricsState.mapFromApiValue(signature.internal),
      extern: SignatureMetricsState.mapFromApiValue(signature.external),
      technicalControl: SignatureMetricsState.mapFromApiValue(signature.technicalControl),
    };

    const totalCount = signaturesData.realization.count + signaturesData.intern.count + signaturesData.extern.count + signaturesData.technicalControl.count;
    const totalSigned = signaturesData.realization.signed + signaturesData.intern.signed + signaturesData.extern.signed + signaturesData.technicalControl.signed;

    return {
      total: {
        count: totalCount,
        signed: totalSigned,
        percent: totalCount ? Math.round((totalSigned * 100) / totalCount) : 0,
        required: signaturesData.realization.required + signaturesData.intern.required + signaturesData.extern.required + signaturesData.technicalControl.required,
        webApp: signaturesData.realization.webApp + signaturesData.intern.webApp + signaturesData.extern.webApp + signaturesData.technicalControl.webApp,
        mobileApp: signaturesData.realization.mobileApp + signaturesData.intern.mobileApp + signaturesData.extern.mobileApp + signaturesData.technicalControl.mobileApp,
      },
      ...signaturesData,
    };
  }
}
