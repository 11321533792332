import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FlowImportReferentialsResponseDTO } from '../../generated/models/models';

export interface FlowImportReferentialResponseData {
  flowNames: string[];
  flowLinkedNames: string[];
  success: boolean;
}
export namespace FlowImportReferentialResponseData {
  export function mapFromApiValue(data: FlowImportReferentialsResponseDTO): FlowImportReferentialResponseData {
    return {
      success: data.success,
      flowNames: (data.flowsAlreadyImported && data.flowsAlreadyImported.map(flow => flow.name)) || [],
      flowLinkedNames: data.linkedFlow?.map(flow => flow.name) || [],
    };
  }

  export function mapToConflict(response: FlowImportReferentialResponseData): ConflictData {
    return {
      title: 'flows.alerts.linkedFlow.subtitle',
      links: response.flowLinkedNames,
    };
  }
}
