import { WebsocketPropertiesResponseDto } from '../../generated/models/websocketPropertiesResponseDto';

export interface WebsocketPropertyData {
  enabled: boolean;
  hostPrefix: string;
  wsDomain: string;
}

export namespace WebsocketPropertyData {
  export function mapFromWebsocketPropertiesResponseDto(dto: WebsocketPropertiesResponseDto): WebsocketPropertyData {
    return {
      enabled: dto.enabled,
      hostPrefix: dto.hostPrefix,
      wsDomain: dto.wsDomain,
    };
  }
}
