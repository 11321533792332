import { TechnicalObjectDuplicateRequestDTO } from '../../generated/models/models';
import { TechnicalObjectNatureEnum } from '../../enums';

export interface TechnicalObjectForDuplicate {
  sourceUuidEntity: string;
  sourceName: string;
  sourceNature: TechnicalObjectNatureEnum;
  name: string;
  nature: TechnicalObjectNatureEnum;
  parentUuidEntity?: string;
}

export namespace TechnicalObjectForDuplicate {
  export function mapToRequestApiValue(data: TechnicalObjectForDuplicate): TechnicalObjectDuplicateRequestDTO {
    return {
      technicalObjectUuidEntity: data.sourceUuidEntity,
      technicalObjectName: data.name,
      parentUuidEntity: data.parentUuidEntity,
    };
  }
}
