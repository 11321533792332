import { MoveEnum, QuestionTypeEnum } from '../../enums';
import { AddLineEntityRequestDTO } from '../../generated/models/addLineEntityRequestDTO';
import { RubricQuestionAddLineRequestDTO } from '../../generated/models/rubricQuestionAddLineRequestDTO';
import { RubricQuestionCreateRequestDTO } from '../../generated/models/rubricQuestionCreateRequestDTO';

export interface QuestionCreateData {
  questionType: QuestionTypeEnum;
  relativePosition?: MoveEnum;
  siblingUuidEntity?: string;
}

export namespace QuestionCreateData {
  export function mapToRubricTableRequestApiValue(name: string, siblingUuidEntity: string, uuidEntity: string, position?: MoveEnum): RubricQuestionAddLineRequestDTO {
    const moveDirection = siblingUuidEntity ? MoveEnum.After : MoveEnum.Start;

    return {
      name,
      siblingUuidEntity,
      relativePosition: position ? MoveEnum.convertToApiValue.getValue(position) : MoveEnum.convertToApiValue.getValue(moveDirection),
      uuidEntity,
      type: QuestionTypeEnum.convertToApiValue.getValue(QuestionTypeEnum.ShortAnswer),
    };
  }

  export function mapToAddLineEntityRequestDTO(name: string, uuideFormBusinessLink: string, uuidEntity: string): AddLineEntityRequestDTO {
    return {
      name,
      uuidEntity,
      uuideFormBusinessLink,
    };
  }

  export function mapToRequestApiValue(name: string, rubricUuidEntity: string): RubricQuestionCreateRequestDTO {
    return {
      name,
      rubricUuidEntity,
      type: QuestionTypeEnum.convertToApiValue.getValue(QuestionTypeEnum.ShortAnswer),
    };
  }
}
