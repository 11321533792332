import * as L from 'leaflet';

import { GeometryDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum GeometryIconEnum {
  Default = 1,
  WithoutIcon = 2,
  Truck = 3,
  Snowplow = 4,
  Warehouse = 5,
  Archive = 6,
  HardHat = 7,
  Toolbox = 8,
  Rocket = 9,
  Caravan = 10,
}

export namespace GeometryIconEnum {
  const values: GeometryIconEnum[] = [
    GeometryIconEnum.WithoutIcon,
    GeometryIconEnum.Default,
    GeometryIconEnum.Truck,
    GeometryIconEnum.Snowplow,
    GeometryIconEnum.Warehouse,
    GeometryIconEnum.Archive,
    GeometryIconEnum.HardHat,
    GeometryIconEnum.Toolbox,
    GeometryIconEnum.Rocket,
    GeometryIconEnum.Caravan,
  ];

  export const convertFromApiValue: SfxMap<GeometryDTO.GeometryIconEnum, GeometryIconEnum> = new SfxMap<GeometryDTO.GeometryIconEnum, GeometryIconEnum>([
    [GeometryDTO.GeometryIconEnum.DEFAULT, GeometryIconEnum.Default],
    [GeometryDTO.GeometryIconEnum.WITHOUTICON, GeometryIconEnum.WithoutIcon],
    [GeometryDTO.GeometryIconEnum.TRUCK, GeometryIconEnum.Truck],
    [GeometryDTO.GeometryIconEnum.SNOWPLOW, GeometryIconEnum.Snowplow],
    [GeometryDTO.GeometryIconEnum.WAREHOUSE, GeometryIconEnum.Warehouse],
    [GeometryDTO.GeometryIconEnum.ARCHIVE, GeometryIconEnum.Archive],
    [GeometryDTO.GeometryIconEnum.HARDHAT, GeometryIconEnum.HardHat],
    [GeometryDTO.GeometryIconEnum.TOOLBOX, GeometryIconEnum.Toolbox],
    [GeometryDTO.GeometryIconEnum.ROCKET, GeometryIconEnum.Rocket],
    [GeometryDTO.GeometryIconEnum.CARAVAN, GeometryIconEnum.Caravan],
  ]);

  export const convertToApiValue: SfxMap<GeometryIconEnum, GeometryDTO.GeometryIconEnum> = new SfxMap<GeometryIconEnum, GeometryDTO.GeometryIconEnum>([
    [GeometryIconEnum.Default, GeometryDTO.GeometryIconEnum.DEFAULT],
    [GeometryIconEnum.WithoutIcon, GeometryDTO.GeometryIconEnum.WITHOUTICON],
    [GeometryIconEnum.Truck, GeometryDTO.GeometryIconEnum.TRUCK],
    [GeometryIconEnum.Snowplow, GeometryDTO.GeometryIconEnum.SNOWPLOW],
    [GeometryIconEnum.Warehouse, GeometryDTO.GeometryIconEnum.WAREHOUSE],
    [GeometryIconEnum.Archive, GeometryDTO.GeometryIconEnum.ARCHIVE],
    [GeometryIconEnum.HardHat, GeometryDTO.GeometryIconEnum.HARDHAT],
    [GeometryIconEnum.Toolbox, GeometryDTO.GeometryIconEnum.TOOLBOX],
    [GeometryIconEnum.Rocket, GeometryDTO.GeometryIconEnum.ROCKET],
    [GeometryIconEnum.Caravan, GeometryDTO.GeometryIconEnum.CARAVAN],
  ]);

  const key = 'customCells.geometryIcons.';
  export const toString: SfxMap<GeometryIconEnum, string> = new SfxMap<GeometryIconEnum, string>([
    [GeometryIconEnum.Default, key + 'default'],
    [GeometryIconEnum.WithoutIcon, key + 'withoutIcon'],
    [GeometryIconEnum.Truck, key + 'truck'],
    [GeometryIconEnum.Snowplow, key + 'snowplow'],
    [GeometryIconEnum.Warehouse, key + 'warehouse'],
    [GeometryIconEnum.Archive, key + 'archive'],
    [GeometryIconEnum.HardHat, key + 'hardhat'],
    [GeometryIconEnum.Toolbox, key + 'toolbox'],
    [GeometryIconEnum.Rocket, key + 'rocket'],
    [GeometryIconEnum.Caravan, key + 'caravan'],
  ]);

  export const buildIcon = (cdnBaseUrl: string) => (_color: string): SfxMap<GeometryIconEnum, L.Icon<L.IconOptions>> => {
    const color = buildIconColor.getValue(_color);

    return new SfxMap<GeometryIconEnum, L.Icon<L.IconOptions>>(
      [
        [
          GeometryIconEnum.Truck,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/truck-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Snowplow,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/snowplow-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Warehouse,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/warehouse-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Archive,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/archive-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.HardHat,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/hard-hat-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Toolbox,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/toolbox-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Rocket,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/rocket-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.Caravan,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/caravan-${color}.png`,
            iconSize: [50, 50],
          }),
        ],
        [
          GeometryIconEnum.WithoutIcon,
          L.icon({
            iconUrl: `${cdnBaseUrl}/marker/circle-${color}.svg`,
            iconSize: [25, 25],
          }),
        ],
      ],
      L.icon({
        iconUrl: `${cdnBaseUrl}/marker/map-marker-alt-${color}.png`,
        iconSize: [50, 50],
      }),
    );
  };

  export const buildIconColor: SfxMap<string, string> = new SfxMap<string, string>(
    [
      ['#43375A', 'deep-purple'],
      ['#25485D', 'blue-oil'],
      ['#08AE6A', 'light-green'],
      ['#17AAA2', 'teal'],
      ['#B5848C', 'indian-red'],
      ['#DB7663', 'deep-orange'],
      ['#7F9BD7', 'indigo'],
      ['#0C7FAD', 'blue'],
      ['#85B296', 'lime'],
      ['#EE965D', 'orange'],
      ['#83C5F8', 'light-blue'],
      ['#E0AAB9', 'light-pink'],
      ['#C6A241', 'gold'],
      ['#F5C684', 'skyn'],
      ['#BFE2E3', 'cyan'],
      ['#6A768C', 'blue-grey'],
      ['#B34F75', 'light-red'],
      ['#A4997C', 'sand'],
      ['#8F44AD', 'purple'],
      ['#D93C78', 'pink'],
      ['#B23939', 'red'],
      ['#FFB141', 'amber'],
      ['#FFDA79', 'yellow'],
      ['#C6D3E1', 'light-grey'],
      ['#2D3436', 'black'],
      ['#7D6608', 'khaki'],
      ['#7B7D7D', 'grey'],
      ['#186A3B', 'green'],
      ['#6E2C00', 'brown'],
      ['#765D69', 'chocolate'],
    ],
    'grey',
  );

  export const selectItems: SingleSelectItemData<GeometryIconEnum>[] = values.map(geometryIcon => ({
    value: geometryIcon,
    title: toString.getValue(geometryIcon),
  }));

  export const itemsResolver = (): Observable<SingleSelectItemData<GeometryIconEnum>[]> =>
    of(
      values.map(geometryIcon => ({
        value: geometryIcon,
        title: toString.getValue(geometryIcon),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<GeometryIconEnum>, item: GeometryIconEnum) => node.value === item;
}
