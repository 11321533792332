import { UserNatureEnum, UserRoleEnum } from '../../enums';
import { EntityLightData } from '../utils/entity-light-data';
import { TeamRoleReorganizeRequestDTO } from '../../generated/models/teamRoleReorganizeRequestDTO';
import { ContributorSummaryData } from './contributor-summary-data';

export interface TeamRoleForReorganize {
  allSelected: boolean;
  contributorQueryRequest?: ContributorSummaryData.QueryRequest;
  teamRolesUuidEntity?: string[];
  hierarchies?: EntityLightData[];
  role?: UserRoleEnum;
}

export namespace TeamRoleForReorganize {
  export function mapToRequestApiValue(teamRoleForReorganize: TeamRoleForReorganize): TeamRoleReorganizeRequestDTO {
    return {
      teamRolesUuidEntity: !teamRoleForReorganize.allSelected ? teamRoleForReorganize.teamRolesUuidEntity || [] : undefined,
      hierarchiesUuidEntity: teamRoleForReorganize.hierarchies ? teamRoleForReorganize.hierarchies.map(hierarchy => hierarchy.uuidEntity) : undefined,
      role: teamRoleForReorganize.role ? UserRoleEnum.convertToApiValue.getValue(teamRoleForReorganize.role) : undefined,
      pageTeamRoleRequest: teamRoleForReorganize.allSelected
        ? teamRoleForReorganize.contributorQueryRequest &&
          ContributorSummaryData.mapToPageRequestApiValue({ queries: teamRoleForReorganize.contributorQueryRequest, userNatures: [UserNatureEnum.Normal, UserNatureEnum.Extern] })
        : undefined,
    };
  }
}
