import { Action, createReducer, on } from '@ngrx/store';
import { ReferenceDetailsData } from '../../../../../core/models';
import * as fromReferenceDialogActions from './reference-dialog.actions';

export interface AddReferenceDialogState {
  saveLoading: boolean;
  referenceDetails: ReferenceDetailsData;
}

const initialAddReferenceDialogState = (): AddReferenceDialogState => ({
  saveLoading: false,
  referenceDetails: {} as ReferenceDetailsData,
});

export interface ReferenceDialogState {
  addReference: AddReferenceDialogState;
}

export const initialReferenceDialogState = () => ({
  addReference: initialAddReferenceDialogState(),
});

const reducer = createReducer<ReferenceDialogState>(
  initialReferenceDialogState(),

  on(fromReferenceDialogActions.createReference, (state): ReferenceDialogState => ({ ...state, addReference: { ...state.addReference, saveLoading: true } })),
  on(
    fromReferenceDialogActions.createReferenceFail,
    fromReferenceDialogActions.updateReferenceFail,
    (state): ReferenceDialogState => ({ ...state, addReference: { ...state.addReference, saveLoading: false } }),
  ),
  on(
    fromReferenceDialogActions.createReferenceSuccess,
    (state, { reference, addNewOne }): ReferenceDialogState => ({
      ...state,
      addReference: {
        ...state.addReference,
        referenceDetails: addNewOne ? { ...reference, nomenclature: null, counterFormat: null, startValue: null, counterType: null, uuidEntity: null } : reference,
        saveLoading: false,
      },
    }),
  ),
  on(fromReferenceDialogActions.updateReferenceFail, (state): ReferenceDialogState => ({ ...state, addReference: { ...state.addReference, saveLoading: false } })),
  on(
    fromReferenceDialogActions.loadReferenceDetailDataSuccess,
    (state, { referenceDetails }): ReferenceDialogState => ({ ...state, addReference: { ...state.addReference, referenceDetails, saveLoading: false } }),
  ),
);

export function referenceDialogReducer(state: ReferenceDialogState | undefined, action: Action): ReferenceDialogState {
  return reducer(state, action);
}
