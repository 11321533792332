import { EpsilonOutLastUpdateFromFormResponseDTO } from '../../generated/models/models';

export interface EpsilonOutLastUpdateFromFormData {
  lastUpdateFromForm: Date;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}

export namespace EpsilonOutLastUpdateFromFormData {
  export function mapFromApiValue(epsilonOut: EpsilonOutLastUpdateFromFormResponseDTO): EpsilonOutLastUpdateFromFormData {
    return {
      lastUpdateFromForm: epsilonOut.lastUpdateFromForm,
      uuid: epsilonOut.uuid,
      uuidEntity: epsilonOut.uuidEntity,
      uuidOrganization: epsilonOut.uuidOrganization,
      uuidType: epsilonOut.uuidType,
    };
  }
}
