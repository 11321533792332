import { DateUtils } from '../../utils/date.utils';

import { BlocFormSummaryResponseDTO, BlocFormUpdateGuideRequestDTO, BlocFormUpdateRequestDTO, PageBlocRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { BlockFormRubricLightData } from '../block-form/block-form-rubric-light';

import { FormNatureEnum, InnerSortDirectionEnum } from '../../enums';

export interface BlockFormSummaryData {
  uuidEntity: string;
  name: string;
  modificationDate: string;
  nature: FormNatureEnum;
  formIndex: string;
  reference: string;
  family: LabelFamilyLightData;
  nextRubricName: string;
  customStatus: CustomStatusLightData;
  mandatory?: boolean;
  guided?: boolean;
  unique?: boolean;
  formUuidEntity?: string;
  frozen?: boolean;
  completedResponsesCount?: number;
  disabled?: boolean;
  additionalComment?: string;
  responsesCount?: number;
  restrictedRubrics: string;
}

export namespace BlockFormSummaryData {
  const sortFields = ['family', 'name', 'reference', 'indice', 'modificationDate'];
  export interface QueryRequest extends BaseQuery<BlockFormSummaryData> {
    projectUuidEntity?: string;
    blockUuidEntity?: string;
  }

  export function mapFromApiValue(formBlockSummary: BlocFormSummaryResponseDTO): BlockFormSummaryData {
    return {
      uuidEntity: formBlockSummary.uuidEntity,
      name: formBlockSummary.name,
      family: formBlockSummary.family && LabelFamilyLightData.mapFromApiValue(formBlockSummary.family),
      formIndex: formBlockSummary.index,
      reference: formBlockSummary.reference,
      nature: FormNatureEnum.convertFromApiValue.getValue(formBlockSummary.nature),
      modificationDate: DateUtils.toDateFormat(formBlockSummary.modificationDate),
      customStatus: formBlockSummary.customStatus && CustomStatusLightData.mapFromApiValue(formBlockSummary.customStatus),
      mandatory: formBlockSummary.requiredResponse,
      guided: formBlockSummary.guided,
      unique: formBlockSummary.uniqueResponse,
      nextRubricName: formBlockSummary.nextRubric && BlockFormRubricLightData.mapFromApiValue(formBlockSummary.nextRubric)?.name,
      formUuidEntity: formBlockSummary.formUuidEntity,
      completedResponsesCount: formBlockSummary.completedResponsesCount,
      disabled: formBlockSummary.disabled,
      additionalComment: formBlockSummary.additionalComment,
      responsesCount: formBlockSummary.responsesCount,
      restrictedRubrics: formBlockSummary.allowedRubricsFormatted,
    };
  }

  export function mapToUpdateRequestApiValue(projectUuidEntity: string, blockForm: BlockFormSummaryData): BlocFormUpdateRequestDTO {
    return {
      uuideProject: projectUuidEntity,
      uuideFormBusinesslink: blockForm.uuidEntity,
      requiredResponse: blockForm.mandatory,
      uniqueResponse: blockForm.unique,
    };
  }

  export function mapToGuideUpdateRequestApiValue(formUuidEntity: string, projectUuidEntity: string, rubricUuidEntity: string): BlocFormUpdateGuideRequestDTO {
    return {
      uuideProject: projectUuidEntity,
      uuideFormBusinesslink: formUuidEntity,
      uuidTypeInitRubric: rubricUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: undefined,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string, sort: SortFilter<BlockFormSummaryData>): QueryRequest {
    return {
      ...initializeQueries<BlockFormSummaryData>(sort),
      projectUuidEntity,
      blockUuidEntity,
    };
  }
}
