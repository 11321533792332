import { MoveRubricOptionRequestDTO } from '../../generated/models/moveRubricOptionRequestDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum OptionTypeEnum {
  Normal = 1,
  Collaborator = 2,
}

export namespace OptionTypeEnum {
  export const convertToApiValue: SfxMap<OptionTypeEnum, MoveRubricOptionRequestDTO.TypeEnum> = new SfxMap<OptionTypeEnum, MoveRubricOptionRequestDTO.TypeEnum>([
    [OptionTypeEnum.Normal, MoveRubricOptionRequestDTO.TypeEnum.NORMAL],
    [OptionTypeEnum.Collaborator, MoveRubricOptionRequestDTO.TypeEnum.COLLABORATOR],
  ]);
}
