import { BlocFormResponseCreateRequestDTO } from '../../generated/models/models';
import { BlockFormResponseLightData } from './block-form-response-light';

export interface BlockFormResponseData {
  reference: string;
  newResponse: boolean;
  projectUuidEntity?: string;
  formUuidEntity?: string;
  response?: BlockFormResponseLightData;
}

export namespace BlockFormResponseData {
  export function mapToCreateRequestApiValue(responseData: BlockFormResponseData): BlocFormResponseCreateRequestDTO {
    return {
      uuideProject: responseData.projectUuidEntity,
      uuideFormBusinesslink: responseData.formUuidEntity,
      newResponse: responseData.newResponse,
      referenceResponse: responseData.reference,
      uuideSelectedResponse: responseData.response?.uuidEntity,
    };
  }
}
