import { VersionDocumentFlowLightDTO } from '../../generated/models/versionDocumentFlowLightDTO';

import { DocumentVersionDetailsData } from './document-version-details';

import { DocumentStatusEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';
import { DocumentVersionStatusEnum } from '../../enums/document/document-version-status.enum';

export interface DocumentVersionSummaryData {
  uuidEntity: string;
  state: DocumentStatusEnum;
  docVersionIndex: string;
  bpeDate: string;
  docVersionStatus?: DocumentVersionStatusEnum;
}

export namespace DocumentVersionSummaryData {
  export function mapFromApiValue(docVersion: VersionDocumentFlowLightDTO): DocumentVersionSummaryData {
    return {
      uuidEntity: docVersion.uuidEntity,
      state: DocumentStatusEnum.convertFromApiValue.getValue(docVersion.etat),
      docVersionIndex: docVersion.indice,
      bpeDate: DateUtils.toDateFormat(docVersion.bpeDate),
      docVersionStatus: DocumentVersionStatusEnum.convertFromApiValue.getValue(docVersion.status),
    };
  }

  export function mapFromDocVersionDetails(docVersionDetails: DocumentVersionDetailsData): DocumentVersionSummaryData {
    return {
      uuidEntity: docVersionDetails.uuidEntity,
      state: docVersionDetails.lastDocumentVersionStatus,
      docVersionIndex: docVersionDetails.versionIndex,
      bpeDate: docVersionDetails.bpeDate,
    };
  }
}
