import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum CounterFormatEnum {
  four = 4,
  five = 5,
  six = 6,
  seven = 7,
  eight = 8,
}

export namespace CounterFormatEnum {
  const values: CounterFormatEnum[] = [CounterFormatEnum.four, CounterFormatEnum.five, CounterFormatEnum.six, CounterFormatEnum.seven, CounterFormatEnum.eight];

  export const toString: SfxMap<CounterFormatEnum, string> = new SfxMap<CounterFormatEnum, string>([
    [CounterFormatEnum.four, '4'],
    [CounterFormatEnum.five, '5'],
    [CounterFormatEnum.six, '6'],
    [CounterFormatEnum.seven, '7'],
    [CounterFormatEnum.eight, '8'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<CounterFormatEnum>[]> => of(values.map(format => ({ value: format, title: toString.getValue(format) })));

  export const comparePredicate = (node: SingleSelectItemData<CounterFormatEnum>, item: CounterFormatEnum) => item === node.value;
}
