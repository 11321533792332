import { createAction, props } from '@ngrx/store';

import {
  AppConfigLightData,
  ApplicationFileTmpData,
  ConflictUploadElements,
  ErrorResponseData,
  ExchangeCodeRequest,
  LazyLoadResponse,
  LoginSuccessData,
  NavMenu,
  UserCredentials,
  UserData,
  UserForUpdate,
  UserQrCodeData,
} from '../../../core/models';
import { ConflictData } from '../../shared/interfaces/conflict-data';
import { DialogToasterData } from '../../sfx-dialog/state/dialog-toaster-data';
import { AccessHistoryItem } from '../../shared/interfaces/access-history-item';
import { AuthMethodEnum, StateName } from '../../../core/enums';
import { OrganizationConfigDetails } from '../../../core/models/organization-config/organization-config-details';

export const setCurrentLanguage = createAction('[Main] Set current language', props<{ language: string; temporary?: boolean }>());
export const setMenu = createAction('[Main] Set app menu', props<{ menu: NavMenu }>());
export const setMenuTitle = createAction('[Main] Set app menu title', props<{ title: string }>());
export const setMenuSubtitle = createAction('[Main] Set app menu subtitle', props<{ subtitle: string }>());
export const setMenuIconClass = createAction('[Main] Set app menu icon class', props<{ iconClass: string }>());

export const getUserAccount = createAction('[Main] Get user account data');
export const getUserAccountSuccess = createAction('[Main] Set current user data Success', props<{ userInfo: UserData }>());
export const getUserAccountFail = createAction('[Main] Set current user data Failed', props<{ error: ErrorResponseData }>());

export const login = createAction('[Main] login', props<{ credentials: UserCredentials }>());
export const loginSuccess = createAction('[Main] login success', props<{ data: LoginSuccessData }>());
export const loginFail = createAction('[Main] login fail', props<{ error: ErrorResponseData }>());
export const logOut = createAction('[Main] logOut', props<{ redirect: boolean }>());

export const resetStates = createAction('[Main] reset states', props<{ statesNames: StateName[] }>());
export const dummyAction = createAction('[Main] Dummy action');

export const updateUserAccount = createAction('[Main/API] Update user account', props<{ userAccount: UserForUpdate }>());
export const updateUserAccountSuccess = createAction('[Main/API] Update user account success', props<{ userAccount: UserData }>());
export const updateUserAccountFail = createAction('[Main/API] Update user account fail', props<{ error: ErrorResponseData }>());
export const updateSequenceViewStatus = createAction('[Main] Set current sequence display', props<{ isActive: boolean }>());

export const loadOrganizationConfig = createAction('[Main] load Organization Config data', props<{ organizationUuid: string }>());
export const loadOrganizationConfigSuccess = createAction('[Main] load Organization Config data success', props<{ config: OrganizationConfigDetails }>());
export const loadOrganizationConfigFail = createAction('[lMain] load Organization Config data fail', props<DialogToasterData>());

export const getAppConfig = createAction('[Main] Get appConfig data');
export const getAppConfigSuccess = createAction('[Main] Get appConfig data success', props<{ appConfig: AppConfigLightData }>());
export const getAppConfigFail = createAction('[Main] Get appConfig data failed', props<{ error: ErrorResponseData }>());
export const updatePgacVisibility = createAction('[Main] Update pgac visibility', props<{ isVisible: boolean }>());
export const updateFileProcessingVisibility = createAction('[Main] Update file treatment visibility', props<{ isVisible: boolean }>());

export const deleteEntityFail = createAction('[Main] Delete entity fail', props<{ error: ConflictData }>());
export const uploadElementsEntityFail = createAction('[Main]Upload elements entity fail', props<{ error: ConflictUploadElements[] }>());
export const sso = createAction('[Main] Single Sign-On');
export const exchangeCode = createAction('[Main] Exchange code', props<{ request: ExchangeCodeRequest }>());
export const tokensRefreshed = createAction('[Main] Tokens refreshed');
export const singleOauth2Logout = createAction('[Main] Single OAuth2 logout');
export const getAuthMethod = createAction('[Main] Get authentication method');
export const setAuthMethod = createAction('[Main] Set authentication method', props<{ authMethod: AuthMethodEnum }>());

export const loadQrCodeData = createAction('[lMain/API] load qr code data', props<{ password: string }>());
export const loadQrCodeDataSuccess = createAction('[lMain/API] load qr code data success', props<UserQrCodeData>());
export const loadQrCodeDataFail = createAction('[lMain/API] load qr code data fail', props<DialogToasterData>());

export const unlinkUser = createAction('[lMain/API] unlink user', props<{ password: string }>());
export const unlinkUserSuccess = createAction('[lMain/API] unlink user success', props<{ password: string }>());
export const unlinkUserFail = createAction('[lMain/API] unlink user fail', props<DialogToasterData>());

export const resetQuickConnection = createAction('[lMain/API] reset quick connection');

export const setAccessHistoryItem = createAction('[lMain/API] set access history item', props<{ item: AccessHistoryItem }>());
export const removeAccessHistoryItem = createAction('[lMain/API] remove access history item', props<{ item: AccessHistoryItem }>());
export const removeAccessHistoryItemByUuidEntity = createAction('[lMain/API] remove access history item by uuid entity', props<{ uuidEntity: string }>());
export const loadAccessHistoryData = createAction('[lMain/API] load access history item', props<{ items: AccessHistoryItem[] }>());

export const setAppVersion = createAction('[lMain/API] set app version', props<{ appVersion: string }>());
export const setCdnWebappUrl = createAction('[lMain/API] set cdn webapp url', props<{ cdnWebappUrl: string }>());
export const loadApplicationFileTmp = createAction('[lMain/API] load application file tmp data');
export const loadMoreApplicationFileTmp = createAction('[lMain/API] load more application file tmp data');
export const loadApplicationFileTmpSuccess = createAction(
  '[lMain/API] load application file tmp data success',
  props<{ response: LazyLoadResponse<ApplicationFileTmpData[]>; reset?: boolean }>(),
);
export const loadApplicationFileTmpFail = createAction('[lMain/API] load application file tmp data fail', props<{ error: ErrorResponseData }>());

// HelpCenter
export const initializeHelpCenter = createAction('[Main/HelpCenter] Initialize Intercom or Zendesk');
export const configureIntercom = createAction('[Main/HelpCenter] configure intercom helpCenter', props<{ userData: UserData }>());
export const openZendeskHelpCenter = createAction('[Main/HelpCenter] start process to open zendesk helpCenter', props<{ helpCenterLink: string }>());

// Zendesk
export const zendeskCreateOrUpdateOrganizationAndUser = createAction('[Main/Zendesk] create a user in an organization or update information');
export const initializeZendesk = createAction('[Main/Zendesk] initialize zendesk webwidget');
