import { AppConfigLightDTO } from '../../generated/models/models';

import { DateFormatData } from './date-format-data';

import { DocumentNatureIndexEnum, LanguageEnum, UserRightEnum } from '../../enums';
import { NumberFormatEnum } from '../../enums/number-format.enum';
export interface AppConfigLightData {
  isModulePgacActive: boolean;
  firstLanguage: LanguageEnum;
  secondLanguage: LanguageEnum;
  isModuleFileProcessingActive: boolean;
  natureIndice: DocumentNatureIndexEnum;
  numberFormatEnum: NumberFormatEnum;
  webDateFormat: DateFormatData;
  authoritiesBlackList: UserRightEnum[];
  autoSignature: boolean;
  passwordMinLength: number;
}

export namespace AppConfigLightData {
  export function mapFromApiValue(appConfig: AppConfigLightDTO): AppConfigLightData {
    return {
      isModulePgacActive: appConfig.modulePgac,
      firstLanguage: LanguageEnum.convertFromApiValue.getValue(appConfig.exportLanguageOne),
      secondLanguage: LanguageEnum.convertFromApiValue.getValue(appConfig.exportLanguageTwo),
      isModuleFileProcessingActive: appConfig.moduleFileProcessing,
      natureIndice: DocumentNatureIndexEnum.convertFromApiValue.getValue(appConfig.natureIndice),
      webDateFormat: appConfig.webAppDisplayConfig && DateFormatData.mapFromApiValue(appConfig.webAppDisplayConfig),
      authoritiesBlackList: (appConfig.authoritiesBlackList || []).map(role => UserRightEnum.convertFromApiValue.getValue(role)),
      autoSignature: appConfig.autoSignature,
      numberFormatEnum: NumberFormatEnum.convertFromApiValue.getValue(appConfig.numberFormatEnum),
      passwordMinLength: appConfig.passwordMinLength,
    };
  }
}
