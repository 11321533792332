import { CustomCellSummaryResponseDTO, CustomCellPageRequestDTO } from '../../generated/models/models';

import { CustomFileLightData } from '../custom-file/custom-file-light';
import { CustomFieldLightData } from '../custom-fields/custom-field.light';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { CustomCellFieldEnum, CustomCellFormatEnum, CustomCellNatureEnum, InnerSortDirectionEnum } from '../../enums';

export interface CustomCellSummaryData {
  name: string;
  format: CustomCellFormatEnum;
  nature: CustomCellNatureEnum;
  uuidEntity: string;
  customFile: CustomFileLightData;
  customField: CustomFieldLightData;
  field: CustomCellFieldEnum;
  updatable: boolean;
  nodeOrder: number;
}
export namespace CustomCellSummaryData {
  export interface QueryRequest extends BaseQuery<CustomCellSummaryData> {
    customFileUuidEntity: string;
    format: CustomCellFormatEnum;
    nature: CustomCellNatureEnum;
  }

  export function mapFromApiValue(customCell: CustomCellSummaryResponseDTO): CustomCellSummaryData {
    return {
      name: customCell.name,
      uuidEntity: customCell.uuidEntity,
      customFile: customCell.customFile && CustomFileLightData.mapFromApiValue(customCell.customFile),
      format: CustomCellFormatEnum.convertFromApiValue.getValue(customCell.format),
      nature: CustomCellNatureEnum.convertFromApiValue.getValue(customCell.valueSource),
      updatable: customCell.updatable,
      field: CustomCellFieldEnum.convertFromApiValue.getValue(customCell.valueDestination),
      customField: customCell.customField && CustomFieldLightData.mapFromApiValue(customCell.customField),
      nodeOrder: customCell.nodeOrder,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): CustomCellPageRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: queries.sort
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : { attribute: 'order', direction: InnerSortDirectionEnum.convertToApiValue.getValue(InnerSortDirectionEnum.Asc) },
      textSearch: queries.textSearch || undefined,
      customFileUuidEntity: queries.customFileUuidEntity,
      format: CustomCellFormatEnum.convertToApiValue.getValue(queries.format) || undefined,
      nature: CustomCellNatureEnum.convertToApiValue.getValue(queries.nature) || undefined,
    };
  }

  export function initializeQueryRequest(
    customFileUuidEntity: string,
    format: string,
    nature: string,
    searchText: string,
    sort: SortFilter<CustomCellSummaryData>,
    page?: number,
  ): QueryRequest {
    return {
      ...initializeQueries<CustomCellSummaryData>(sort),
      page: page,
      textSearch: searchText || undefined,
      format: CustomCellFormatEnum.convertFromParamsValue.getValue(format) || undefined,
      nature: CustomCellNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      customFileUuidEntity: customFileUuidEntity || undefined,
    };
  }
}
