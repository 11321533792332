import { SfxMap } from '../../utils/enum-utils';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomCellNatureEnum } from './custom-cell-nature.enum';
import { CustomFileNatureEnum } from '../custom-file/custom-file-nature.enum';

export enum CustomCellFieldEnum {
  // Common fields
  Name = 1,
  Description,

  // Flow fields
  LabelFamilyApplicationParent,
  LabelFamilyApplicationChild,
  LabelFamilyProjectParent,
  LabelFamilyProjectChild,
  ReferenceIntern,
  FlowStatus,
  LocationSite,
  LocationArea,
  LocationLocal,
  ElementarySystem,
  FunctionalMarker,
  TechnicalObject,
  StartDate,
  FinishDate,
  EstimatedStartDate,
  EstimatedEndDate,
  AchievedStartDate,
  AchievedEndDate,
  Type,

  // Project fields
  ClientReference,
  BusinessReference,
  LocationLibelle,
  ContractReference,
  InternalCommand,
  ExternalCommand,
  MarketNumber,
  TechnicalDeal,
  Keywords,
  BusinessDomain,
  NationalProgram,

  // Phase && Step fields
  Order,

  // Geometry fields
  GeometryLatitude,
  GeometryLongitude,
  GeometryAltitude,

  // Technical Object
  Parent,
  ReferenceExtern,
  Status,
  Nature,
  AdditionalComment,
}

export namespace CustomCellFieldEnum {
  export interface AlreadyUsed {
    field: CustomCellFieldEnum;
    nature: CustomCellNatureEnum;
  }

  interface CustomCellField {
    value: CustomCellFieldEnum;
    updatable?: boolean;
    mandatory?: boolean;
    nature?: CustomFileNatureEnum;
  }

  const buildField = (field: CustomCellFieldEnum, updatable?: boolean, mandatory?: boolean, nature?: CustomFileNatureEnum): CustomCellField => ({
    value: field,
    updatable,
    mandatory,
    nature,
  });

  // Exported for .spec file
  export const fields = new SfxMap<CustomCellNatureEnum, CustomCellField[]>(
    [
      [
        CustomCellNatureEnum.Project,
        [
          buildField(CustomCellFieldEnum.Name, false, true),
          buildField(CustomCellFieldEnum.Description, true),
          buildField(CustomCellFieldEnum.BusinessReference, true),
          buildField(CustomCellFieldEnum.LocationLibelle, true),
          buildField(CustomCellFieldEnum.ContractReference, true),
          buildField(CustomCellFieldEnum.InternalCommand, true),
          buildField(CustomCellFieldEnum.ExternalCommand, true),
          buildField(CustomCellFieldEnum.MarketNumber, true),
          buildField(CustomCellFieldEnum.TechnicalDeal, true),
          buildField(CustomCellFieldEnum.Keywords, true),
          buildField(CustomCellFieldEnum.BusinessDomain, true),
          buildField(CustomCellFieldEnum.NationalProgram, true),
        ],
      ],
      [
        CustomCellNatureEnum.Flow,
        [
          buildField(CustomCellFieldEnum.Type),
          buildField(CustomCellFieldEnum.Name, false, true),
          buildField(CustomCellFieldEnum.Description, true),
          buildField(CustomCellFieldEnum.ReferenceIntern, true),
          buildField(CustomCellFieldEnum.LabelFamilyApplicationParent, false, true),
          buildField(CustomCellFieldEnum.LabelFamilyApplicationChild, false, true),
          buildField(CustomCellFieldEnum.LabelFamilyProjectParent, true),
          buildField(CustomCellFieldEnum.LabelFamilyProjectChild, true),
          buildField(CustomCellFieldEnum.FlowStatus, true),
          buildField(CustomCellFieldEnum.LocationSite, true),
          buildField(CustomCellFieldEnum.LocationArea, true),
          buildField(CustomCellFieldEnum.LocationLocal, true),
          buildField(CustomCellFieldEnum.ElementarySystem, true),
          buildField(CustomCellFieldEnum.FunctionalMarker, true),
          buildField(CustomCellFieldEnum.TechnicalObject, true),
          buildField(CustomCellFieldEnum.StartDate, true),
          buildField(CustomCellFieldEnum.FinishDate, true),
          buildField(CustomCellFieldEnum.EstimatedStartDate, true),
          buildField(CustomCellFieldEnum.EstimatedEndDate, true),
          buildField(CustomCellFieldEnum.AchievedStartDate, true),
          buildField(CustomCellFieldEnum.AchievedEndDate, true),
        ],
      ],
      [
        CustomCellNatureEnum.Geometry,
        [buildField(CustomCellFieldEnum.GeometryLatitude, true), buildField(CustomCellFieldEnum.GeometryLongitude, true), buildField(CustomCellFieldEnum.GeometryAltitude, true)],
      ],
      [
        CustomCellNatureEnum.Phase,
        [
          buildField(CustomCellFieldEnum.Name, false, true),
          buildField(CustomCellFieldEnum.Description),
          buildField(CustomCellFieldEnum.Order),
          buildField(CustomCellFieldEnum.AdditionalComment),
        ],
      ],
      [
        CustomCellNatureEnum.Step,
        [
          buildField(CustomCellFieldEnum.Name, false, true),
          buildField(CustomCellFieldEnum.Description),
          buildField(CustomCellFieldEnum.Order),
          buildField(CustomCellFieldEnum.AdditionalComment),
        ],
      ],
      [
        CustomCellNatureEnum.TechnicalObject,
        [
          buildField(CustomCellFieldEnum.Parent),
          buildField(CustomCellFieldEnum.Nature, false, true),
          buildField(CustomCellFieldEnum.LabelFamilyApplicationParent, false, true),
          buildField(CustomCellFieldEnum.LabelFamilyApplicationChild, false, true),
          buildField(CustomCellFieldEnum.Name, false, true),
          buildField(CustomCellFieldEnum.Description, true),
          buildField(CustomCellFieldEnum.ReferenceIntern, true),
          buildField(CustomCellFieldEnum.ReferenceExtern, true),
          buildField(CustomCellFieldEnum.Status, true),
        ],
      ],
      [
        CustomCellNatureEnum.FlowReferential,
        [buildField(CustomCellFieldEnum.Name, false, true), buildField(CustomCellFieldEnum.Description), buildField(CustomCellFieldEnum.ReferenceIntern)],
      ],
    ],
    [],
  );

  export const convertFromApiValue: SfxMap<string, CustomCellFieldEnum> = new SfxMap<string, CustomCellFieldEnum>([
    ['name', CustomCellFieldEnum.Name],
    ['description', CustomCellFieldEnum.Description],
    ['labelFamilyApplicationParent', CustomCellFieldEnum.LabelFamilyApplicationParent],
    ['labelFamilyApplication', CustomCellFieldEnum.LabelFamilyApplicationChild],
    ['labelFamilyProjectParent', CustomCellFieldEnum.LabelFamilyProjectParent],
    ['labelFamilyProjectChild', CustomCellFieldEnum.LabelFamilyProjectChild],
    ['refInt', CustomCellFieldEnum.ReferenceIntern],
    ['refExt', CustomCellFieldEnum.ReferenceExtern],
    ['statusFlow', CustomCellFieldEnum.FlowStatus],
    ['locationSite', CustomCellFieldEnum.LocationSite],
    ['locationArea', CustomCellFieldEnum.LocationArea],
    ['locationLocal', CustomCellFieldEnum.LocationLocal],
    ['elementarySystem', CustomCellFieldEnum.ElementarySystem],
    ['functionalMarker', CustomCellFieldEnum.FunctionalMarker],
    ['technicalObject', CustomCellFieldEnum.TechnicalObject],
    ['startDate', CustomCellFieldEnum.StartDate],
    ['finishDate', CustomCellFieldEnum.FinishDate],
    ['estimatedStartDate', CustomCellFieldEnum.EstimatedStartDate],
    ['estimatedEndDate', CustomCellFieldEnum.EstimatedEndDate],
    ['achievedStartDate', CustomCellFieldEnum.AchievedStartDate],
    ['achievedEndDate', CustomCellFieldEnum.AchievedEndDate],
    ['clientRef', CustomCellFieldEnum.ClientReference],
    ['businessRef', CustomCellFieldEnum.BusinessReference],
    ['locationLibelle', CustomCellFieldEnum.LocationLibelle],
    ['contractRef', CustomCellFieldEnum.ContractReference],
    ['internalCommand', CustomCellFieldEnum.InternalCommand],
    ['externalCommand', CustomCellFieldEnum.ExternalCommand],
    ['marketNumber', CustomCellFieldEnum.MarketNumber],
    ['technicalDeal', CustomCellFieldEnum.TechnicalDeal],
    ['keywords', CustomCellFieldEnum.Keywords],
    ['businessDomain', CustomCellFieldEnum.BusinessDomain],
    ['nationalProgram', CustomCellFieldEnum.NationalProgram],
    ['customNodeOrder', CustomCellFieldEnum.Order],
    ['geometryLat', CustomCellFieldEnum.GeometryLatitude],
    ['geometryLng', CustomCellFieldEnum.GeometryLongitude],
    ['geometryAlt', CustomCellFieldEnum.GeometryAltitude],
    ['parent', CustomCellFieldEnum.Parent],
    ['status', CustomCellFieldEnum.Status],
    ['nature', CustomCellFieldEnum.Nature],
    ['type', CustomCellFieldEnum.Type],
    ['additionalComment', CustomCellFieldEnum.AdditionalComment],
  ]);

  export const convertToApiValue: SfxMap<CustomCellFieldEnum, string> = new SfxMap<CustomCellFieldEnum, string>([
    [CustomCellFieldEnum.Name, 'name'],
    [CustomCellFieldEnum.Description, 'description'],
    [CustomCellFieldEnum.LabelFamilyApplicationParent, 'labelFamilyApplicationParent'],
    [CustomCellFieldEnum.LabelFamilyApplicationChild, 'labelFamilyApplication'],
    [CustomCellFieldEnum.LabelFamilyProjectParent, 'labelFamilyProjectParent'],
    [CustomCellFieldEnum.LabelFamilyProjectChild, 'labelFamilyProjectChild'],
    [CustomCellFieldEnum.ReferenceIntern, 'refInt'],
    [CustomCellFieldEnum.ReferenceExtern, 'refExt'],
    [CustomCellFieldEnum.FlowStatus, 'statusFlow'],
    [CustomCellFieldEnum.LocationSite, 'locationSite'],
    [CustomCellFieldEnum.LocationArea, 'locationArea'],
    [CustomCellFieldEnum.LocationLocal, 'locationLocal'],
    [CustomCellFieldEnum.ElementarySystem, 'elementarySystem'],
    [CustomCellFieldEnum.FunctionalMarker, 'functionalMarker'],
    [CustomCellFieldEnum.TechnicalObject, 'technicalObject'],
    [CustomCellFieldEnum.StartDate, 'startDate'],
    [CustomCellFieldEnum.FinishDate, 'finishDate'],
    [CustomCellFieldEnum.EstimatedStartDate, 'estimatedStartDate'],
    [CustomCellFieldEnum.EstimatedEndDate, 'estimatedEndDate'],
    [CustomCellFieldEnum.AchievedStartDate, 'achievedStartDate'],
    [CustomCellFieldEnum.AchievedEndDate, 'achievedEndDate'],
    [CustomCellFieldEnum.ClientReference, 'clientRef'],
    [CustomCellFieldEnum.BusinessReference, 'businessRef'],
    [CustomCellFieldEnum.LocationLibelle, 'locationLibelle'],
    [CustomCellFieldEnum.ContractReference, 'contractRef'],
    [CustomCellFieldEnum.InternalCommand, 'internalCommand'],
    [CustomCellFieldEnum.ExternalCommand, 'externalCommand'],
    [CustomCellFieldEnum.MarketNumber, 'marketNumber'],
    [CustomCellFieldEnum.TechnicalDeal, 'technicalDeal'],
    [CustomCellFieldEnum.Keywords, 'keywords'],
    [CustomCellFieldEnum.BusinessDomain, 'businessDomain'],
    [CustomCellFieldEnum.NationalProgram, 'nationalProgram'],
    [CustomCellFieldEnum.Order, 'customNodeOrder'],
    [CustomCellFieldEnum.GeometryLatitude, 'geometryLat'],
    [CustomCellFieldEnum.GeometryLongitude, 'geometryLng'],
    [CustomCellFieldEnum.GeometryAltitude, 'geometryAlt'],
    [CustomCellFieldEnum.Parent, 'parent'],
    [CustomCellFieldEnum.Status, 'status'],
    [CustomCellFieldEnum.Nature, 'nature'],
    [CustomCellFieldEnum.Type, 'type'],
    [CustomCellFieldEnum.AdditionalComment, 'additionalComment'],
  ]);

  export const toString = (nature?: CustomCellNatureEnum, mandatory?: boolean): SfxMap<CustomCellFieldEnum, string> => {
    const key = `customCells.fields.${mandatory ? 'mandatory.' : ''}`;

    return new SfxMap<CustomCellFieldEnum, string>([
      [CustomCellFieldEnum.Name, key + 'name'],
      [CustomCellFieldEnum.Description, key + getNatureText(CustomCellNatureEnum.Project)],
      [CustomCellFieldEnum.LabelFamilyApplicationParent, key + 'labelFamilyApplicationParent'],
      [CustomCellFieldEnum.LabelFamilyApplicationChild, key + 'labelFamilyApplicationChild'],
      [CustomCellFieldEnum.LabelFamilyProjectParent, key + 'labelFamilyProjectParent'],
      [CustomCellFieldEnum.LabelFamilyProjectChild, key + 'labelFamilyProjectChild'],
      [CustomCellFieldEnum.ReferenceIntern, key + 'refInt'],
      [CustomCellFieldEnum.ReferenceExtern, key + 'refExt'],
      [CustomCellFieldEnum.FlowStatus, key + 'flowStatus'],
      [CustomCellFieldEnum.LocationSite, key + 'locationSite'],
      [CustomCellFieldEnum.LocationArea, key + 'locationArea'],
      [CustomCellFieldEnum.LocationLocal, key + 'locationLocal'],
      [CustomCellFieldEnum.ElementarySystem, key + 'elementarySystem'],
      [CustomCellFieldEnum.FunctionalMarker, key + 'functionalMarker'],
      [CustomCellFieldEnum.TechnicalObject, key + 'technicalObject'],
      [CustomCellFieldEnum.StartDate, key + 'startDate'],
      [CustomCellFieldEnum.FinishDate, key + 'finishDate'],
      [CustomCellFieldEnum.EstimatedStartDate, key + 'estimatedStartDate'],
      [CustomCellFieldEnum.EstimatedEndDate, key + 'estimatedEndDate'],
      [CustomCellFieldEnum.AchievedStartDate, key + 'achievedStartDate'],
      [CustomCellFieldEnum.AchievedEndDate, key + 'achievedEndDate'],
      [CustomCellFieldEnum.ClientReference, key + 'clientReference'],
      [CustomCellFieldEnum.BusinessReference, key + 'businessReference'],
      [CustomCellFieldEnum.LocationLibelle, key + 'locationLibelle'],
      [CustomCellFieldEnum.ContractReference, key + 'contractRef'],
      [CustomCellFieldEnum.InternalCommand, key + 'internalCommand'],
      [CustomCellFieldEnum.ExternalCommand, key + 'externalCommand'],
      [CustomCellFieldEnum.MarketNumber, key + 'marketNumber'],
      [CustomCellFieldEnum.TechnicalDeal, key + 'technicalDeal'],
      [CustomCellFieldEnum.Keywords, key + 'keywords'],
      [CustomCellFieldEnum.BusinessDomain, key + 'businessDomain'],
      [CustomCellFieldEnum.NationalProgram, key + 'nationalProgram'],
      [CustomCellFieldEnum.Order, key + (nature === CustomCellNatureEnum.Phase ? 'phaseOrder' : 'stepOrder')],
      [CustomCellFieldEnum.GeometryLatitude, key + 'geometryLatitude'],
      [CustomCellFieldEnum.GeometryLongitude, key + 'geometryLongitude'],
      [CustomCellFieldEnum.GeometryAltitude, key + 'geometryAltitude'],
      [CustomCellFieldEnum.Parent, key + 'parent'],
      [CustomCellFieldEnum.Status, key + 'flowStatus'],
      [CustomCellFieldEnum.Nature, key + 'nature'],
      [CustomCellFieldEnum.Type, key + 'type'],
      [CustomCellFieldEnum.AdditionalComment, key + 'additionalComment'],
    ]);
  };

  export function isUpdatable(updateField: CustomCellFieldEnum, nature: CustomCellNatureEnum): boolean {
    return !!updateField && fields.getValue(nature).find(field => field.value === updateField).updatable;
  }

  export const mapToSingleSelectItem = (
    cellNature: CustomCellNatureEnum,
    fileNature: CustomFileNatureEnum,
    alreadyUsed: CustomCellFieldEnum.AlreadyUsed[],
  ): SingleSelectItemData<CustomCellFieldEnum>[] =>
    fields
      .getValue(cellNature)
      .filter(field => !field.nature || field.nature === fileNature)
      .map(field => {
        const disabled = !!alreadyUsed.find(cell => cell.field === field.value && cell.nature === cellNature);

        return {
          value: field.value,
          title: toString(cellNature, field.mandatory).getValue(field.value),
          subtitle: disabled && 'customCells.fields.alreadyUsed',
          disabled,
        };
      });

  export const comparePredicate = (node: SingleSelectItemData<CustomCellFieldEnum>, item: CustomCellFieldEnum) => node.value === item;

  export function getNatureText(nature: CustomCellNatureEnum): string {
    if (nature === CustomCellNatureEnum.Project || nature === CustomCellNatureEnum.Flow) {
      return 'description';
    }

    return CustomCellNatureEnum.Phase === nature ? 'phaseDescription' : 'stepDescription';
  }
}
