<div class="app-route-container">
  <div *ngIf="isNotBlink" class="alert-container">
    <div class="alert-wrapper">
      <div class="alert-content" data-cy="alert-message">
        <div class="alert-message-container">
          <div class="wave-hand"></div>
          <sfx-label [text]="'global.compatibilityAlert' | translate"></sfx-label>
        </div>
        <sfx-icon name="times" class="alert-icon-container" (click)="isNotBlink = false" onKeyDown="isNotBlink = false"></sfx-icon>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <router-outlet name="modals"></router-outlet>
</div>
