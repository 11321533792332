import { ResponseDetailsResponseDTO, ResponseCreateRequestDTO } from '../../generated/models/models';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { ResponseProvenanceEnum, FormNatureEnum } from '../../enums';

export interface FormResponseDetailsData {
  reference: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  createDate: Date;
  nature: FormNatureEnum;
  customStatus: CustomStatusLightData;
  indice: string;
  provenance: ResponseProvenanceEnum;
  rubricOrder: number;
}

export namespace FormResponseDetailsData {
  export function mapFromApiValue(response: ResponseDetailsResponseDTO): FormResponseDetailsData {
    return {
      reference: response.reference,
      uuid: response.uuid,
      uuidType: response.uuidType,
      uuidEntity: response.uuidEntity,
      createDate: response.creationDate,
      indice: response.indice,
      nature: FormNatureEnum.convertFromApiValue.getValue(response.nature),
      customStatus: response.customStatus && CustomStatusLightData.mapFromApiValue(response.customStatus),
      provenance: ResponseProvenanceEnum.convertFromApiValue.getValue(response.provenance),
      rubricOrder: response.rubricOrder,
    };
  }

  export function mapToCreateRequestApiValue(reference: string, formUuidEntity: string): ResponseCreateRequestDTO {
    return {
      formUuidEntity,
      reference,
    };
  }
}
