import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { PageSkillRequest } from '../../generated/models/models';
import { SkillSummaryData } from '../../models/contributors/skill-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SkillsStateEnum {
  Active = 1,
  Inactive = 2,
}

export namespace SkillsStateEnum {
  const values = [SkillsStateEnum.Active, SkillsStateEnum.Inactive];

  export const convertToApiValue: SfxMap<SkillsStateEnum, PageSkillRequest.StatusEnum> = new SfxMap<SkillsStateEnum, PageSkillRequest.StatusEnum>([
    [SkillsStateEnum.Active, PageSkillRequest.StatusEnum.ACTIF],
    [SkillsStateEnum.Inactive, PageSkillRequest.StatusEnum.EXPIRED],
  ]);

  export const convertFromParamsValue: SfxMap<string, SkillsStateEnum> = new SfxMap<string, SkillsStateEnum>([
    ['Active', SkillsStateEnum.Active],
    ['Expired', SkillsStateEnum.Inactive],
  ]);

  export const convertToParamsValue: SfxMap<SkillsStateEnum, string> = new SfxMap<SkillsStateEnum, string>([
    [SkillsStateEnum.Active, 'Active'],
    [SkillsStateEnum.Inactive, 'Expired'],
  ]);

  const key = 'contributors.modals.skillTable.';
  export const toString: SfxMap<SkillsStateEnum, string> = new SfxMap<SkillsStateEnum, string>(
    [
      [SkillsStateEnum.Active, key + 'actifStatus'],
      [SkillsStateEnum.Inactive, key + 'inactiveStatus'],
    ],
    key + 'status',
  );

  export const className: SfxMap<SkillsStateEnum, string> = new SfxMap<SkillsStateEnum, string>([
    [SkillsStateEnum.Active, 'sfx-active-status'],
    [SkillsStateEnum.Inactive, 'sfx-expired-status'],
  ]);

  export function getStatus(skill: SkillSummaryData): SkillsStateEnum {
    if (isEndDateDefinedAndBeforeToday() || isStartDateDefinedAndAfterToday()) {
      return SkillsStateEnum.Inactive;
    }

    return SkillsStateEnum.Active;

    function isStartDateDefinedAndAfterToday(): boolean {
      return skill.startDate && dayjs(skill.startDate, 'DD/MM/YYYY').startOf('day').diff(dayjs().startOf('day'), 'day', true) > 0;
    }

    function isEndDateDefinedAndBeforeToday(): boolean {
      return skill.endDate && dayjs(skill.endDate, 'DD/MM/YYYY').startOf('day').diff(dayjs().startOf('day'), 'day', true) < 0;
    }
  }

  export const itemsResolver = (): Observable<SingleSelectItemData<SkillsStateEnum>[]> =>
    of(
      values.map(state => ({
        value: state,
        title: toString.getValue(state),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<SkillsStateEnum>, item: SkillsStateEnum) => node.value === item;
}
