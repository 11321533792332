import { AppConfigLightDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum DocumentNatureIndexEnum {
  NumericZero = 1,
  Numeric = 2,
  Alpha = 3,
  NumericAllZero = 4,
  NumericAllZeroStartZero = 5,
}

export namespace DocumentNatureIndexEnum {
  const values = [
    DocumentNatureIndexEnum.NumericZero,
    DocumentNatureIndexEnum.Numeric,
    DocumentNatureIndexEnum.Alpha,
    DocumentNatureIndexEnum.NumericAllZero,
    DocumentNatureIndexEnum.NumericAllZeroStartZero,
  ];

  export const convertFromApiValue: SfxMap<AppConfigLightDTO.NatureIndiceEnum, DocumentNatureIndexEnum> = new SfxMap<AppConfigLightDTO.NatureIndiceEnum, DocumentNatureIndexEnum>([
    [AppConfigLightDTO.NatureIndiceEnum.NUMERICZERO, DocumentNatureIndexEnum.NumericZero],
    [AppConfigLightDTO.NatureIndiceEnum.NUMERIC, DocumentNatureIndexEnum.Numeric],
    [AppConfigLightDTO.NatureIndiceEnum.ALPHA, DocumentNatureIndexEnum.Alpha],
    [AppConfigLightDTO.NatureIndiceEnum.NUMERICALLZERO, DocumentNatureIndexEnum.NumericAllZero],
    [AppConfigLightDTO.NatureIndiceEnum.NUMERICALLZEROSTARTZERO, DocumentNatureIndexEnum.NumericAllZeroStartZero],
  ]);

  export const convertToApiValue: SfxMap<DocumentNatureIndexEnum, AppConfigLightDTO.NatureIndiceEnum> = new SfxMap<DocumentNatureIndexEnum, AppConfigLightDTO.NatureIndiceEnum>([
    [DocumentNatureIndexEnum.NumericZero, AppConfigLightDTO.NatureIndiceEnum.NUMERICZERO],
    [DocumentNatureIndexEnum.Numeric, AppConfigLightDTO.NatureIndiceEnum.NUMERIC],
    [DocumentNatureIndexEnum.Alpha, AppConfigLightDTO.NatureIndiceEnum.ALPHA],
    [DocumentNatureIndexEnum.NumericAllZero, AppConfigLightDTO.NatureIndiceEnum.NUMERICALLZERO],
    [DocumentNatureIndexEnum.NumericAllZeroStartZero, AppConfigLightDTO.NatureIndiceEnum.NUMERICALLZEROSTARTZERO],
  ]);

  const key = 'document.natureIndex.';
  export const toString: SfxMap<DocumentNatureIndexEnum, string> = new SfxMap<DocumentNatureIndexEnum, string>([
    [DocumentNatureIndexEnum.NumericZero, key + 'NUMERIC_ZERO'],
    [DocumentNatureIndexEnum.Numeric, key + 'NUMERIC'],
    [DocumentNatureIndexEnum.NumericAllZero, key + 'NUMERIC_ALL_ZERO'],
    [DocumentNatureIndexEnum.NumericAllZeroStartZero, key + 'NUMERIC_ALL_ZERO_START_ZERO'],
    [DocumentNatureIndexEnum.Alpha, key + 'ALPHA'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<DocumentNatureIndexEnum>[]> =>
    of(
      values.map(natureIndex => ({
        value: natureIndex,
        title: toString.getValue(natureIndex),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DocumentNatureIndexEnum>, item: DocumentNatureIndexEnum) => node.value === item;
}
