/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicationFileLightDTO } from './applicationFileLightDTO';
import { UserLightDTO } from './userLightDTO';
import { WordingConfigurationFieldResponseDTO } from './wordingConfigurationFieldResponseDTO';

export interface VersionDocumentFlowDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  indice: string;
  version: number;
  refReponse: string;
  libelleReponse: string;
  etat: VersionDocumentFlowDetailsResponseDTO.EtatEnum;
  modificationDate: Date;
  diffusionDate: Date;
  reponseDate: Date;
  visa: string;
  nextVisa: string;
  description: string;
  commentaireDiffusion: string;
  commentaireResponse: string;
  path: string;
  compressedPath: string;
  compressionLevel: string;
  responseFile: ApplicationFileLightDTO;
  compressedResponsePath: string;
  clientCoverPageFile: ApplicationFileLightDTO;
  compressedClientCoverPagePath: string;
  compressedFileSize: string;
  creator: UserLightDTO;
  redactor: UserLightDTO;
  verificator: UserLightDTO;
  fileSize: string;
  approbator: UserLightDTO;
  qualityController: UserLightDTO;
  safetyController: UserLightDTO;
  userBpe: UserLightDTO;
  bpeDate: Date;
  bpeComment: string;
  diffusionDateR: Date;
  diffusionDateV: Date;
  diffusionDateA: Date;
  diffusionDateQ: Date;
  diffusionDateS: Date;
  userBpa: UserLightDTO;
  bpaDate: Date;
  bpaComment: string;
  subIndex: number;
  complementRefInt: string;
  complementRefExt: string;
  status: VersionDocumentFlowDetailsResponseDTO.StatusEnum;
  extern: boolean;
  last: boolean;
  customWording: WordingConfigurationFieldResponseDTO;
  dynamicDate: Date;
  dynamicComment: string;
}
export namespace VersionDocumentFlowDetailsResponseDTO {
  export type EtatEnum = 'PREL' | 'BPO' | 'BPE' | 'AUTRE' | 'TEMP' | 'BPA';
  export const EtatEnum = {
    PREL: 'PREL' as EtatEnum,
    BPO: 'BPO' as EtatEnum,
    BPE: 'BPE' as EtatEnum,
    AUTRE: 'AUTRE' as EtatEnum,
    TEMP: 'TEMP' as EtatEnum,
    BPA: 'BPA' as EtatEnum,
  };
  export type StatusEnum = 'DIFFUSION' | 'DIFFUSION_BPA' | 'CLIENT_RETURN' | 'BPE' | 'BPE_VALID' | 'CLIENT_RETURN_BPA' | 'BPA_VALID' | 'UPLOAD_FILE' | 'BPA_REFUSED' | 'NULL';
  export const StatusEnum = {
    DIFFUSION: 'DIFFUSION' as StatusEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as StatusEnum,
    CLIENTRETURN: 'CLIENT_RETURN' as StatusEnum,
    BPE: 'BPE' as StatusEnum,
    BPEVALID: 'BPE_VALID' as StatusEnum,
    CLIENTRETURNBPA: 'CLIENT_RETURN_BPA' as StatusEnum,
    BPAVALID: 'BPA_VALID' as StatusEnum,
    UPLOADFILE: 'UPLOAD_FILE' as StatusEnum,
    BPAREFUSED: 'BPA_REFUSED' as StatusEnum,
    NULL: 'NULL' as StatusEnum,
  };
}
