import { PageUserRequestDTO } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum UserTypeEnum {
  Operator = 1,
  User = 2,
}

export namespace UserTypeEnum {
  const values: UserTypeEnum[] = [UserTypeEnum.User, UserTypeEnum.Operator];

  export const convertFromApiValue: SfxMap<PageUserRequestDTO.TypeEnum, UserTypeEnum> = new SfxMap<PageUserRequestDTO.TypeEnum, UserTypeEnum>([
    [PageUserRequestDTO.TypeEnum.OPERATOR, UserTypeEnum.Operator],
    [PageUserRequestDTO.TypeEnum.USER, UserTypeEnum.User],
  ]);

  export const convertToApiValue: SfxMap<UserTypeEnum, PageUserRequestDTO.TypeEnum> = new SfxMap<UserTypeEnum, PageUserRequestDTO.TypeEnum>([
    [UserTypeEnum.Operator, PageUserRequestDTO.TypeEnum.OPERATOR],
    [UserTypeEnum.User, PageUserRequestDTO.TypeEnum.USER],
  ]);

  export const convertFromParamsValue: SfxMap<string, UserTypeEnum> = new SfxMap<string, UserTypeEnum>([
    ['operator', UserTypeEnum.Operator],
    ['user', UserTypeEnum.User],
  ]);

  export const convertToParamsValue: SfxMap<UserTypeEnum, string> = new SfxMap<UserTypeEnum, string>([
    [UserTypeEnum.User, 'user'],
    [UserTypeEnum.Operator, 'operator'],
  ]);

  const key = 'contributors.filters.';
  export const toString: SfxMap<UserTypeEnum, string> = new SfxMap<UserTypeEnum, string>([
    [UserTypeEnum.User, key + 'user'],
    [UserTypeEnum.Operator, key + 'operator'],
  ]);

  export const tooltip: SfxMap<UserTypeEnum, string> = new SfxMap<UserTypeEnum, string>([
    [UserTypeEnum.User, key + 'userTooltip'],
    [UserTypeEnum.Operator, key + 'operatorTooltip'],
  ]);

  export const iconData: SfxMap<UserTypeEnum, IconData> = new SfxMap<UserTypeEnum, IconData>([
    [UserTypeEnum.Operator, { name: 'user-astronaut', prefix: 'fas', iconClassName: 'sfx-chip-operator' }],
    [UserTypeEnum.User, { name: 'user-tie', prefix: 'fas', iconClassName: 'sfx-chip-primary' }],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<UserTypeEnum>[]> => of(values.map(type => ({ value: type, title: toString.getValue(type) })));

  export const comparePredicate = (node: SingleSelectItemData<UserTypeEnum>, item: UserTypeEnum) => node.value === item;
}
