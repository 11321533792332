import { createAction, props } from '@ngrx/store';

import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { DocumentDiffusionData } from '../../../../../../core/models/document/document-diffusion';
import { CustomFieldData } from '../../../../../../core/models/custom-fields/custom-field';
import { DialogToasterData } from '../../../../../sfx-dialog/state/dialog-toaster-data';
import { ApplicationFileLightData, ErrorResponseData } from '../../../../../../core/models';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentTabsEnum } from '../../../../../../core/enums/entity-modal-tab/document-tabs.enum';

/* Add document actions */
export const loadDocumentDetails = createAction('[AddDashDoc] Load document details', props<{ documentUuidEntity: string; reset?: boolean }>());
export const loadDocumentDetailsFail = createAction('[AddDashDoc] Load document details fail', props<DialogToasterData>());
export const loadDocumentDetailsSuccess = createAction('[AddDashDoc] Load document details success', props<{ documentDetails: DocumentDetailsData }>());

export const saveDocumentChanges = createAction(
  '[AddDashDoc] Save document changes',
  props<{
    documentDetails: DocumentDetailsData;
    customFields: CustomFieldData[];
    nextTab: DocumentTabsEnum;
    fileRefs: File[];
    nextDocStep?: number;
  }>(),
);
export const stopSaveLoading = createAction('[AddDashDoc] Stop save loading');
export const createDocumentFail = createAction('[AddDashDoc] Create document fail', props<DialogToasterData>());
export const updateDocumentFail = createAction('[AddDashDoc] Update document fail', props<DialogToasterData>());
export const createDocumentSuccess = createAction('[AddDashDoc] Create document success', props<{ documentDetails: DocumentDetailsData }>());
export const updateDocumentSuccess = createAction(
  '[AddDashDoc] Update documents success',
  props<{ documentDetails: DocumentDetailsData; customFields: CustomFieldData[]; nextTab: DocumentTabsEnum }>(),
);

export const checkGeneratedRefs = createAction('[AddDashDoc] check generated references', props<{ familyUuidEntity: string; reset: boolean }>());
export const checkGeneratedRefsFail = createAction('[AddDashDoc] check generated references fail', props<DialogToasterData>());
export const checkGeneratedRefsSuccess = createAction(
  '[AddDashDoc] check generated references success',
  props<{ familyUuidEntity: string; genRefInt: boolean; genRefExt: boolean; genCompRefInt: boolean; genCompRefExt: boolean; reset: boolean }>(),
);

export const loadDocumentCustomFields = createAction('[AddDashDoc] load document custom fields', props<{ documentDetails: DocumentDetailsData }>());
export const loadDocumentCustomFieldsSuccess = createAction('[AddDashDoc] load document custom fields success', props<{ documentCustomFields: CustomFieldData[] }>());
export const loadDocumentCustomFieldsFail = createAction('[AddDashDoc] load document custom fields fail', props<DialogToasterData>());

export const navigateThroughDocuments = createAction(
  '[AddDashDoc] navigate through Documents',
  props<{ documentDetails: DocumentDetailsData; customFields: CustomFieldData[]; fileRefs: File[]; step: number }>(),
);
export const navigateThroughDocumentsSuccess = createAction('[AddDashDoc] navigate through Documents Success', props<{ uuidEntity: string; step: number }>());

export const checkUpdatedDocuments = createAction('[AddDashDoc] check Updated Documents', props<{ diffusionData: DocumentDiffusionData; documentFlowUuidEntity: string }>());

export const compressSelectedDocs = createAction('[AddDashDoc] compress selected documents');
export const finishCompressingDocs = createAction('[AddDashDoc] finish compressing the selected documents');

// document files actions
export const loadDocumentFiles = createAction('[AddDashDoc] load document file');
export const loadMoreDocumentFiles = createAction('[AddDashDoc] load more documents file');
export const loadDocumentFilesFail = createAction('[AddDashDoc] load document file fail', props<DialogToasterData>());
export const loadDocumentFilesSuccess = createAction(
  '[AddDashDoc] load document  file success',
  props<{ files: ApplicationFileLightData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);

export const resetFilesState = createAction('[AddDashDoc] reset document file state');

export const moveDocumentFile = createAction('[AddDashDoc] move documents file', props<{ fileUuidEntity: string; siblingFileUuidEntity: string }>());
export const moveDocumentFileFail = createAction('[AddDashDoc] move documents file fail', props<{ error: ErrorResponseData }>());

export const viewDocumentFile = createAction('[AddDashDoc] view document file', props<{ fileUuidEntity: string }>());
export const viewDocumentFileSuccess = createAction('[AddDashDoc] view document file success');
export const viewDocumentFileFail = createAction('[AddDashDoc] view document file fail');
export const deleteDocumentFile = createAction('[AddDashDoc] delete document file', props<{ fileUuidEntity: string }>());
export const deleteDocumentFileSuccess = createAction('[AddDashDoc] delete document file success', props<{ fileUuidEntity: string }>());

export const downloadDocumentFiles = createAction('[AddDashDoc] download documents files', props<{ documentUuidEntity: string }>());
export const downloadDocumentFilesSuccess = createAction('[AddDashDoc] download documents files success');

// custom wording actions
export const loadCustomWordingDetails = createAction('[AddDashDoc] Load custom wording details', props<{ labelFamilyUuidEntity: string }>());
export const loadCustomWordingDetailsFail = createAction('[AddDashDoc] Load custom wording details fail', props<DialogToasterData>());
export const loadCustomWordingDetailsSuccess = createAction('[AddDashDoc] Load custom wording details success', props<{ documentCustomWording: CustomWordingsDocumentData }>());
