import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectTypeEnum, StateName, UserRoleEnum } from '../../../core/enums';
import * as fromDashboard from './dashboard.reducer';

const dashboardState = createFeatureSelector<fromDashboard.DashboardState>(StateName.Dashboard);

export const getProjectDetails = createSelector(dashboardState, state => state?.projectDetails);
export const getProjectLabelFamily = createSelector(dashboardState, state => state?.projectDetails?.labelFamilyApplication);
export const getCurrentProjectDesignation = createSelector(getProjectDetails, projectDetails => projectDetails?.designation || '');
export const getCurrentProjectIconClass = createSelector(
  getProjectDetails,
  projectDetails => (projectDetails && ProjectTypeEnum.iconClass.getValue(projectDetails.projectType)) || 'default',
);
export const getCurrentProjectType = createSelector(getProjectDetails, projectDetails => projectDetails?.projectType);
export const isProjectPGAC = createSelector(getProjectDetails, projectDetails => projectDetails?.projectType === ProjectTypeEnum.Pgac);
export const isProjectSupervised = createSelector(getProjectDetails, projectDetails => projectDetails?.isSupervised);
export const isRefProject = createSelector(getProjectDetails, projectDetails => projectDetails?.projectType === ProjectTypeEnum.Referential);
export const isLocalFormAllowed = createSelector(getProjectDetails, projectDetails => projectDetails?.localFormAllowed);

// Note: User roles selectors < Roles >
// Note: Responsible can manage all AND Expert can manage only less than his role.
export const userRolesCanBeManaged = (userRole: UserRoleEnum) =>
  createSelector(
    getProjectDetails,
    projectDetails =>
      projectDetails?.currentUserRoles.includes(UserRoleEnum.Responsible) || (projectDetails?.currentUserRoles.includes(UserRoleEnum.Expert) && UserRoleEnum.Expert > userRole),
  );

export const isUserGuest = createSelector(getProjectDetails, project => project?.currentUserRoles.includes(UserRoleEnum.ReadOnly));

export const getFocusedFlow = createSelector(dashboardState, state => state.focusedFlow);
