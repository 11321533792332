import { EntityLightData } from '../utils/entity-light-data';
import { FlowDuplicateRequestDTO } from '../../generated/models/models';
import { ProjectTypeEnum, FlowTypeEnum } from '../../enums';

export interface FlowForDuplicate {
  uuidEntity: string;
  name: string;
  project: EntityLightData;
  projectType: ProjectTypeEnum;
  sourceName: string;
  sourceType: FlowTypeEnum;
}

export namespace FlowForDuplicate {
  export function mapToRequestApiValue(data: FlowForDuplicate): FlowDuplicateRequestDTO {
    return {
      flowUuidEntity: data.uuidEntity,
      projectUuidEntity: data.project.uuidEntity,
      flowName: data.name,
    };
  }
}
