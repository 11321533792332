import { EntityLightData } from '../utils/entity-light-data';

import { EquipmentReorganizeRequestDTO } from '../../generated/models/models';
import { EquipmentReorganizeTypeEnum } from '../../enums';
import { LibraryEquipmentSummaryData } from './library-equipment-summary-data';

export interface EquipmentReorganizeData {
  allSelected: boolean;
  equipmentsUuidEntity: string[];
  reorganize: EntityLightData;
  type: EquipmentReorganizeTypeEnum;
  queries: LibraryEquipmentSummaryData.QueryRequest;
}

export namespace EquipmentReorganizeData {
  export function mapToRequestApiValue(equipmentReorganize: EquipmentReorganizeData): EquipmentReorganizeRequestDTO {
    return {
      equipmentsUuidEntity: !equipmentReorganize.allSelected ? equipmentReorganize.equipmentsUuidEntity || [] : undefined,
      familyUuidEntity: EquipmentReorganizeTypeEnum.Family === equipmentReorganize.type && equipmentReorganize.reorganize && equipmentReorganize.reorganize.uuidEntity,
      pageEquipmentRequest: equipmentReorganize.allSelected
        ? equipmentReorganize.queries && LibraryEquipmentSummaryData.mapToPageRequestApiValue(equipmentReorganize.queries)
        : undefined,
    };
  }
}
