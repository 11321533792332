/**
 * @deprecated
 */
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum RevisionActionEnum {
  Close = 1,
  Create = 2,
  Disable = 3,
  Enable = 4,
  None = 5,
  Reopen = 6,
  Update = 7,
  Delete = 8,
}

export namespace RevisionActionEnum {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  export const convertFromApiValue: SfxMap<any, RevisionActionEnum> = new SfxMap([
    ['CLOSE', RevisionActionEnum.Close],
    ['CREATE', RevisionActionEnum.Create],
    ['DISABLE', RevisionActionEnum.Disable],
    ['ENABLE', RevisionActionEnum.Enable],
    ['NONE', RevisionActionEnum.None],
    ['REOPEN', RevisionActionEnum.Reopen],
    ['UPDATE', RevisionActionEnum.Update],
  ]);

  export const className: SfxMap<RevisionActionEnum, string> = new SfxMap([
    [RevisionActionEnum.Close, 'sfx-chip-grey'],
    [RevisionActionEnum.Create, 'sfx-active-status'],
    [RevisionActionEnum.Disable, 'sfx-chip-error'],
    [RevisionActionEnum.Enable, 'sfx-active-status'],
    [RevisionActionEnum.Reopen, 'sfx-chip-grey'],
    [RevisionActionEnum.Update, 'sfx-chip-primary'],
  ]);

  export const color: SfxMap<RevisionActionEnum, string> = new SfxMap([
    [RevisionActionEnum.Close, '#6a768c'],
    [RevisionActionEnum.Create, '#326202'],
    [RevisionActionEnum.Disable, '#b10b0b'],
    [RevisionActionEnum.Enable, '#326202'],
    [RevisionActionEnum.Reopen, '#6a768c'],
    [RevisionActionEnum.Update, '#0d4e8a'],
  ]);

  const key = 'flowContentNavigation.dialog.review.actions.';
  export const toString: SfxMap<RevisionActionEnum, string> = new SfxMap([
    [RevisionActionEnum.Close, key + 'close'],
    [RevisionActionEnum.Create, key + 'create'],
    [RevisionActionEnum.Disable, key + 'disable'],
    [RevisionActionEnum.Enable, key + 'enable'],
    [RevisionActionEnum.None, key + 'none'],
    [RevisionActionEnum.Reopen, key + 'reopen'],
    [RevisionActionEnum.Update, key + 'update'],
    [RevisionActionEnum.Delete, key + 'delete'],
  ]);

  export const iconData: SfxMap<RevisionActionEnum, IconData> = new SfxMap<RevisionActionEnum, IconData>([
    [RevisionActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [RevisionActionEnum.Enable, { name: 'eye', prefix: 'fas' }],
    [RevisionActionEnum.Disable, { name: 'eye-slash', prefix: 'fas' }],
  ]);

  export const getFlowContentActionMenu = (disabled: boolean): ActionSelectItemData<RevisionActionEnum>[] => [
    disabled ? mapToActionMenuItem(RevisionActionEnum.Enable, PermissionActionEnum.Edit) : mapToActionMenuItem(RevisionActionEnum.Disable, PermissionActionEnum.Edit),
    mapToActionMenuItem(RevisionActionEnum.Delete, PermissionActionEnum.Delete),
  ];

  export function mapToActionMenuItem(flowActionMenu: RevisionActionEnum, permissionAction: PermissionActionEnum): ActionSelectItemData<RevisionActionEnum> {
    return {
      name: RevisionActionEnum.toString.getValue(flowActionMenu),
      permissionAction,
      value: flowActionMenu,
      icon: RevisionActionEnum.iconData.getValue(flowActionMenu),
    };
  }
}
