import { compact } from 'lodash';

import { FormDeleteResponseDTO } from '../../generated/models/models';
import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { FormResponseSummaryData } from '../form-response/form-response-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FormStepLinkDeleteData } from './form-step-link-delete';

export interface FormDeleteResponseData {
  success: boolean;
  documentFlowLinks: DocumentFlowSummaryData[];
  stepLinks: FormStepLinkDeleteData[];
  responseLinks: FormResponseSummaryData[];
}

export namespace FormDeleteResponseData {
  export function mapFromApiValue(data: FormDeleteResponseDTO): FormDeleteResponseData {
    return {
      success: data.success,
      documentFlowLinks: data.documentLinks && data.documentLinks.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
      responseLinks: data.responseLinks && data.responseLinks.map(response => FormResponseSummaryData.mapFromApiValue(response)),
      stepLinks: data.stepLinks && data.stepLinks.map(step => FormStepLinkDeleteData.mapFromApiValue(step)),
    };
  }

  export function mapToConflictData(deleteResponse: FormDeleteResponseData): ConflictData[] {
    return compact([
      deleteResponse.documentFlowLinks &&
        deleteResponse.documentFlowLinks.length && {
          title: 'global.entityConflict.hint.form.documentFlow',
          links: deleteResponse.documentFlowLinks.map(documentFlow => DocumentFlowSummaryData.toString(documentFlow)),
        },
      deleteResponse.stepLinks &&
        deleteResponse.stepLinks.length && {
          title: 'global.entityConflict.hint.form.step',
          links: deleteResponse.stepLinks.map(step => FormStepLinkDeleteData.toString(step)),
        },
      deleteResponse.responseLinks &&
        deleteResponse.responseLinks.length && {
          title: 'global.entityConflict.hint.form.response',
          links: deleteResponse.responseLinks.map(response => FormResponseSummaryData.toString(response)),
        },
    ]);
  }
}
