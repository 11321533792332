import { sortBy } from 'lodash';

import { FlowLightData } from '../flows/flow-light-data';
import { PhaseSummaryResponseDTO } from '../../generated/models/models';
import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { FlowTypeEnum } from '../../enums';
import { StepSummaryData } from '../step/step-summary-data';
import { PhaseSummaryData } from './phase-summary-data';

export interface PhaseTreeData {
  phase: FlowMenuItemData;
  steps: FlowMenuItemData[];
}

export namespace PhaseTreeData {
  export function mapFromApiValue(flowType: FlowTypeEnum, phase: PhaseSummaryResponseDTO): PhaseTreeData {
    const steps: FlowMenuItemData[] = [];

    (phase.steps || [])
      .filter(step => !!step)
      .forEach(step => {
        steps.push(StepSummaryData.mapToFlowMenuItemData(step, flowType));
        if (step.flowLight) {
          steps.push(FlowLightData.mapToFlowMenuItemData(step));
        }
      });

    return {
      phase: PhaseSummaryData.mapToFlowMenuItemData(phase),
      steps: sortBy(steps, 'nodeOrder'),
    };
  }
}
