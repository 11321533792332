import { DocumentFlowSummaryToImportResponseDTO } from '../../generated/models/documentFlowSummaryToImportResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum SectionDocumentColumnEnum {
  Order = 'order',
  Family = 'family',
  Name = 'name',
  RefIntern = 'refIntern',
  Index = 'index',
  Status = 'status',
  DiffusionDate = 'diffusionDate',
}

export namespace SectionDocumentColumnEnum {
  export type DocumentsSortField = keyof DocumentFlowSummaryToImportResponseDTO;

  export const allColumns = [
    SectionDocumentColumnEnum.Family,
    SectionDocumentColumnEnum.Name,
    SectionDocumentColumnEnum.RefIntern,
    SectionDocumentColumnEnum.Index,
    SectionDocumentColumnEnum.Status,
  ];

  export const orderTableColumns = [
    SectionDocumentColumnEnum.Order,
    SectionDocumentColumnEnum.Family,
    SectionDocumentColumnEnum.Name,
    SectionDocumentColumnEnum.RefIntern,
    SectionDocumentColumnEnum.Index,
    SectionDocumentColumnEnum.Status,
    SectionDocumentColumnEnum.DiffusionDate,
  ];

  const key = 'closingFolder.modal.table.header.';
  export const toString: SfxMap<SectionDocumentColumnEnum, string> = new SfxMap<SectionDocumentColumnEnum, string>([
    [SectionDocumentColumnEnum.Family, key + 'family'],
    [SectionDocumentColumnEnum.Name, key + 'name'],
    [SectionDocumentColumnEnum.RefIntern, key + 'refIntern'],
    [SectionDocumentColumnEnum.Index, key + 'index'],
    [SectionDocumentColumnEnum.Status, key + 'status'],
    [SectionDocumentColumnEnum.DiffusionDate, key + 'diffusionDate'],
  ]);

  export const sortField: SfxMap<SectionDocumentColumnEnum, DocumentsSortField> = new SfxMap<SectionDocumentColumnEnum, DocumentsSortField>([
    [SectionDocumentColumnEnum.Family, 'family'],
    [SectionDocumentColumnEnum.Name, 'name'],
    [SectionDocumentColumnEnum.RefIntern, 'refInt'],
    [SectionDocumentColumnEnum.Index, 'indice'],
    [SectionDocumentColumnEnum.Status, 'etat'],
    [SectionDocumentColumnEnum.DiffusionDate, 'diffusionDate'],
  ]);
}
