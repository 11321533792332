import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { RubricLightDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum RubricNatureEnum {
  Normal = 1,
  TableRow = 2,
  TableColumn = 3,
  Table = 4,
}

export namespace RubricNatureEnum {
  export const tableNatures: RubricNatureEnum[] = [RubricNatureEnum.TableRow, RubricNatureEnum.TableColumn];

  export const convertFromApiValue: SfxMap<RubricLightDTO.RubricTypeEnum, RubricNatureEnum> = new SfxMap<RubricLightDTO.RubricTypeEnum, RubricNatureEnum>([
    [RubricLightDTO.RubricTypeEnum.NORMAL, RubricNatureEnum.Normal],
    [RubricLightDTO.RubricTypeEnum.TABLE, RubricNatureEnum.TableRow],
    [RubricLightDTO.RubricTypeEnum.TABLECOLUMN, RubricNatureEnum.TableColumn],
  ]);

  export const convertToApiValue: SfxMap<RubricNatureEnum, RubricLightDTO.RubricTypeEnum> = new SfxMap<RubricNatureEnum, RubricLightDTO.RubricTypeEnum>([
    [RubricNatureEnum.Normal, RubricLightDTO.RubricTypeEnum.NORMAL],
    [RubricNatureEnum.TableRow, RubricLightDTO.RubricTypeEnum.TABLE],
    [RubricNatureEnum.TableColumn, RubricLightDTO.RubricTypeEnum.TABLECOLUMN],
  ]);

  export const questionModalTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'questions.title'],
    [RubricNatureEnum.TableColumn, 'questions.columnTitle'],
    [RubricNatureEnum.TableRow, 'questions.rowTitle'],
  ]);

  export const moveModalTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'questions.dialog.move.title.normal'],
    [RubricNatureEnum.TableColumn, 'questions.dialog.move.title.column'],
    [RubricNatureEnum.TableRow, 'questions.dialog.move.title.row'],
  ]);

  export const labelPosition: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'questions.dialog.move.before.normal'],
    [RubricNatureEnum.TableColumn, 'questions.dialog.move.before.column'],
    [RubricNatureEnum.TableRow, 'questions.dialog.move.before.row'],
  ]);

  export const optionModalTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'option.title'],
    [RubricNatureEnum.TableColumn, 'option.tableColumnTitle'],
    [RubricNatureEnum.TableRow, 'option.tableRowTitle'],
  ]);

  export const rubricModalTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'rubrics.dialog.rubricTypes.rubric'],
    [RubricNatureEnum.Table, 'rubrics.dialog.rubricTypes.table'],
    [RubricNatureEnum.TableRow, 'rubrics.dialog.tableTypes.row'],
    [RubricNatureEnum.TableColumn, 'rubrics.dialog.tableTypes.column'],
  ]);

  const key = 'rubrics.';
  export const toString: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, key + 'rubric'],
    [RubricNatureEnum.Table, key + 'table'],
    [RubricNatureEnum.TableRow, key + 'tableRow'],
    [RubricNatureEnum.TableColumn, key + 'tableColumn'],
  ]);

  export const bulkConfigTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'questions.dialog.genericParams.button.normal'],
    [RubricNatureEnum.TableRow, 'questions.dialog.genericParams.button.tableRow'],
    [RubricNatureEnum.TableColumn, 'questions.dialog.genericParams.button.tableColumn'],
  ]);

  export const toLetter: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'R'],
    [RubricNatureEnum.TableRow, 'T'],
    [RubricNatureEnum.TableColumn, 'T'],
  ]);

  export const navBarTitle: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'rubrics.navbar.rubric.navbarTitle'],
    [RubricNatureEnum.TableRow, 'rubrics.navbar.tableRow.navbarTitle'],
    [RubricNatureEnum.TableColumn, 'rubrics.navbar.tableColumn.navbarTitle'],
  ]);

  export const questionType: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'rubrics.navbar.rubric.questionType'],
    [RubricNatureEnum.TableRow, 'rubrics.navbar.tableRow.questionType'],
    [RubricNatureEnum.TableColumn, 'rubrics.navbar.tableColumn.questionType'],
  ]);

  export const questionName: SfxMap<RubricNatureEnum, string> = new SfxMap<RubricNatureEnum, string>([
    [RubricNatureEnum.Normal, 'rubrics.navbar.rubric.questionName'],
    [RubricNatureEnum.TableRow, 'rubrics.navbar.tableRow.lineName'],
    [RubricNatureEnum.TableColumn, 'rubrics.navbar.tableColumn.columnName'],
  ]);

  export const selectedItems = (rubricTypeSelection?: boolean): SingleSelectItemData<RubricNatureEnum>[] =>
    rubricTypeSelection
      ? [
          { value: RubricNatureEnum.Normal, title: rubricModalTitle.getValue(RubricNatureEnum.Normal) },
          { value: RubricNatureEnum.TableColumn, title: rubricModalTitle.getValue(RubricNatureEnum.Table) },
        ]
      : [
          { value: RubricNatureEnum.TableColumn, title: rubricModalTitle.getValue(RubricNatureEnum.TableColumn) },
          { value: RubricNatureEnum.TableRow, title: rubricModalTitle.getValue(RubricNatureEnum.TableRow) },
        ];

  export const itemsResolver = (): Observable<SingleSelectItemData<RubricNatureEnum>[]> =>
    of([
      { value: RubricNatureEnum.TableColumn, title: rubricModalTitle.getValue(RubricNatureEnum.TableColumn) },
      { value: RubricNatureEnum.TableRow, title: rubricModalTitle.getValue(RubricNatureEnum.TableRow) },
    ]);

  export const comparePredicate = (node: SingleSelectItemData<RubricNatureEnum>, item: RubricNatureEnum) => node.value === item;
}
