import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { TeamRoleDeviceDetailsResponseDTO } from '../../generated/models/teamRoleDeviceDetailsResponseDTO';
import { TabletStatusEnum } from '../../enums';

export interface TabletQrCodeData {
  refExtern: string;
  status: TabletStatusEnum;
  labelFamily: LabelFamilyLightData;
  uniqueDeviceId: string;
  qrCode: string;
  uuidEntity: string;
  deviceUuidEntity: string;
  applicabilityName: string;
}

export namespace TabletQrCodeData {
  export function mapFromApiValue(data: TeamRoleDeviceDetailsResponseDTO): TabletQrCodeData {
    return {
      refExtern: data.refExtern,
      status: data.status && TabletStatusEnum.convertFromApiValue.getValue(data.status),
      labelFamily: (data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily)) || LabelFamilyLightData.defaultData,
      uniqueDeviceId: data.uniqueDeviceId,
      qrCode: `data:image/png;base64,${data.qrCode}`,
      uuidEntity: data.uuidEntity,
      deviceUuidEntity: data.deviceUuidEntity,
      applicabilityName: data.applicabilityName,
    };
  }
}
