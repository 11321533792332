import { SfxMap } from '../../utils/enum-utils';

export enum ImportFormResponsesColumnEnum {
  Reference = 'reference',
  Status = 'status',
  Index = 'index',
  Date = 'date',
  Action = 'action',
}

export namespace ImportFormResponsesColumnEnum {
  export const allColumns = [
    ImportFormResponsesColumnEnum.Reference,
    ImportFormResponsesColumnEnum.Status,
    ImportFormResponsesColumnEnum.Index,
    ImportFormResponsesColumnEnum.Date,
    ImportFormResponsesColumnEnum.Action,
  ];

  const key = 'blocks.form.dialogs.importResponses.table.header.';
  export const toString: SfxMap<ImportFormResponsesColumnEnum, string> = new SfxMap<ImportFormResponsesColumnEnum, string>([
    [ImportFormResponsesColumnEnum.Reference, key + 'reference'],
    [ImportFormResponsesColumnEnum.Status, key + 'status'],
    [ImportFormResponsesColumnEnum.Index, key + 'index'],
    [ImportFormResponsesColumnEnum.Date, key + 'date'],
    [ImportFormResponsesColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<ImportFormResponsesColumnEnum, string> = new SfxMap<ImportFormResponsesColumnEnum, string>([
    [ImportFormResponsesColumnEnum.Reference, 'reference'],
    [ImportFormResponsesColumnEnum.Status, 'status'],
    [ImportFormResponsesColumnEnum.Index, 'index'],
    [ImportFormResponsesColumnEnum.Date, 'date'],
  ]);
}
