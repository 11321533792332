import { EntityChronoUpdateRequestDTO } from '../../generated/models/models';

export interface ReferenceForUpdate {
  uuidEntity: string;
  nomenclature: string;
}

export namespace ReferenceForUpdate {
  export function mapToUpdateRequestApiValue(data: ReferenceForUpdate, referenceUuidEntity?: string): EntityChronoUpdateRequestDTO {
    return {
      uuidEntity: referenceUuidEntity ? referenceUuidEntity : data.uuidEntity,
      paternChrono: data.nomenclature,
    };
  }
}
