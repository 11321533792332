import { CheckQuestionResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum QuestionErrorTypeEnum {
  Invalid = 1,
  Required = 2,
}

export namespace QuestionErrorTypeEnum {
  export const convertFromApiValue: SfxMap<CheckQuestionResponseDTO.ErrorTypeEnum, QuestionErrorTypeEnum> = new SfxMap<
    CheckQuestionResponseDTO.ErrorTypeEnum,
    QuestionErrorTypeEnum
  >([
    [CheckQuestionResponseDTO.ErrorTypeEnum.INVALID, QuestionErrorTypeEnum.Invalid],
    [CheckQuestionResponseDTO.ErrorTypeEnum.REQUIRED, QuestionErrorTypeEnum.Required],
  ]);
}
