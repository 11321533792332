import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';

import { UserRightEnum, UserRoleEnum } from '../../enums';
import { TeamRoleUpdateRequestDTO, TeamRoleDetailsResponseDTO } from '../../generated/models/models';

export interface ContributorDetailsData {
  teamRole: UserRoleEnum;
  function: LabelFamilyLightData;
  legal: LabelFamilyLightData;
  contract: LabelFamilyLightData;
  hierarchies: EntityLightData[];
  group: EntityLightData;
  name: string;
  teamRoleOrder: number;
  uuidEntity: string;
  uuidOrganization: string;
  applicabilityName: string;
  complement: string;
  authority: UserRightEnum;
}

export namespace ContributorDetailsData {
  export function mapFromApiValue(data: TeamRoleDetailsResponseDTO): ContributorDetailsData {
    return {
      teamRole: data.choixTeamRole && UserRoleEnum.convertFromApiValue.getValue(data.choixTeamRole),
      function: data.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(data.labelFamilyFunction),
      legal: data.legalEntity && LabelFamilyLightData.mapFromApiValue(data.legalEntity),
      contract: data.contratType && LabelFamilyLightData.mapFromApiValue(data.contratType, null, false, true),
      hierarchies: (data.labelFamilyHierarchies || []).map(hierarchy => LabelFamilyLightData.mapToEntityLightData(hierarchy)),
      group: data.labelFamilyTeam && LabelFamilyLightData.mapToEntityLightData(data.labelFamilyTeam),
      name: data.name,
      teamRoleOrder: data.teamRoleOrder,
      uuidEntity: data.uuidEntity,
      uuidOrganization: data.uuidOrganization,
      applicabilityName: data.applicabilityName,
      complement: data.profession,
      authority: UserRightEnum.convertFromApiValue.getValue(data.userRight?.name),
    };
  }

  export function mapToUpdateRequestApiValue(contributor: ContributorDetailsData): TeamRoleUpdateRequestDTO {
    return {
      labelFamilyHierarchiesUuidEntity: (contributor.hierarchies || []).length ? contributor.hierarchies.map(hierarchy => hierarchy.uuidEntity) : undefined,
      role: UserRoleEnum.convertToApiValue.getValue(contributor.teamRole),
      uuidEntity: contributor.uuidEntity,
    };
  }
}
