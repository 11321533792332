import { PageSectionRfiTemplateRequestDTO, SectionRfiTemplateSummaryResponseDTO } from '../../generated/models/models';

import { BaseQuery } from '../utils/base-query.model';

import { SectionTypeEnum, InnerSortDirectionEnum } from '../../enums';

export interface SectionSummaryData {
  uuidEntity: string;
  name: string;
  order: number;
  type: SectionTypeEnum;
}

export namespace SectionSummaryData {
  const sortFields = ['name'];

  export interface QueryRequest extends BaseQuery<SectionSummaryData> {
    folderUuidEntity: string;
  }

  export function mapFromApiValue(data: SectionRfiTemplateSummaryResponseDTO): SectionSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
      order: data.order,
      type: SectionTypeEnum.convertFromApiValue.getValue(data.type),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageSectionRfiTemplateRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      rfiTemplateUuide: queries.folderUuidEntity,
      textSearch: queries.textSearch || undefined,
    };
  }
}
