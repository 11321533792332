import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomFieldVariableNameResponseDTO } from '../../generated/models/customFieldVariableNameResponseDTO';

export interface DynamicVariableData {
  uuidEntity: string;
  name: string;
  variableName: string;
}

export namespace DynamicVariableData {
  export function mapFromApiValue(dynamicVariable: CustomFieldVariableNameResponseDTO): DynamicVariableData {
    return {
      uuidEntity: dynamicVariable.uuidEntity,
      name: dynamicVariable.name,
      variableName: dynamicVariable.variableName,
    };
  }

  export function mapToSelectItem(dynamicVariable: CustomFieldVariableNameResponseDTO): MultiSelectItemData {
    return {
      value: dynamicVariable.variableName,
      title: dynamicVariable.name,
    };
  }
}
