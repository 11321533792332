/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PageSectionDocumentReorderRequestDTO {
  sectionUuide: string;
  type: PageSectionDocumentReorderRequestDTO.TypeEnum;
  direction: PageSectionDocumentReorderRequestDTO.DirectionEnum;
}
export namespace PageSectionDocumentReorderRequestDTO {
  export type TypeEnum = 'CREATION_DATE' | 'MODIFICATION_DATE' | 'DATE_OF_ADDITION' | 'NAME' | 'INTERN_REFERENCE';
  export const TypeEnum = {
    CREATIONDATE: 'CREATION_DATE' as TypeEnum,
    MODIFICATIONDATE: 'MODIFICATION_DATE' as TypeEnum,
    DATEOFADDITION: 'DATE_OF_ADDITION' as TypeEnum,
    NAME: 'NAME' as TypeEnum,
    INTERNREFERENCE: 'INTERN_REFERENCE' as TypeEnum,
  };
  export type DirectionEnum = 'ASC' | 'DESC';
  export const DirectionEnum = {
    ASC: 'ASC' as DirectionEnum,
    DESC: 'DESC' as DirectionEnum,
  };
}
