import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SectionTabEnum {
  General = 'general',
  Content = 'content',
  Order = 'order',
}

export namespace SectionTabEnum {
  export const allTabs = [SectionTabEnum.General, SectionTabEnum.Content, SectionTabEnum.Order];
  export const documentTabs = [SectionTabEnum.General, SectionTabEnum.Content];

  const key = 'sections.tabs.';
  export const toString: SfxMap<SectionTabEnum, string> = new SfxMap<SectionTabEnum, string>([
    [SectionTabEnum.General, key + 'general'],
    [SectionTabEnum.Content, key + 'content'],
    [SectionTabEnum.Order, key + 'order'],
  ]);

  export const iconData: SfxMap<SectionTabEnum, IconData> = new SfxMap<SectionTabEnum, IconData>([
    [SectionTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [SectionTabEnum.Content, { name: 'box', prefix: 'fas' }],
    [SectionTabEnum.Order, { name: 'sort', prefix: 'fas' }],
  ]);
}
