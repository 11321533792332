import { DocumentInfoSharedLinkResponseDto } from '../../generated/models/documentInfoSharedLinkResponseDto';

export interface DocShareData {
  reference: string;
  name: string;
  index: string;
  uuidEntity: string;
  asyncGeneration: boolean;
}

export namespace DocShareData {
  export function mapFromApiValue(data: DocumentInfoSharedLinkResponseDto): DocShareData {
    return {
      reference: data.reference,
      name: data.name,
      index: data.index,
      uuidEntity: data.uuidEntity,
      asyncGeneration: data.asyncGeneration,
    };
  }
}
