import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum DynamicVariableModalTabEnum {
  Project = 'project',
  Flow = 'flow',
  Form = 'form',
  CustomField = 'customField',
}

export namespace DynamicVariableModalTabEnum {
  const key = 'questions.dynamicVariable.tabs.';
  export const toString: SfxMap<DynamicVariableModalTabEnum, string> = new SfxMap<DynamicVariableModalTabEnum, string>([
    [DynamicVariableModalTabEnum.Project, key + 'project'],
    [DynamicVariableModalTabEnum.Flow, key + 'flow'],
    [DynamicVariableModalTabEnum.Form, key + 'form'],
    [DynamicVariableModalTabEnum.CustomField, key + 'customField'],
  ]);

  export const iconData: SfxMap<DynamicVariableModalTabEnum, IconData> = new SfxMap<DynamicVariableModalTabEnum, IconData>([
    [DynamicVariableModalTabEnum.Project, { name: 'folder-open', prefix: 'fas' }],
    [DynamicVariableModalTabEnum.Flow, { name: 'sitemap', prefix: 'fas' }],
    [DynamicVariableModalTabEnum.Form, { name: 'list-alt', prefix: 'fas' }],
    [DynamicVariableModalTabEnum.CustomField, { name: 'project-diagram', prefix: 'fas' }],
  ]);
}
