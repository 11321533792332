import { ImportReferentialFlowsRequestDTO } from '../../generated/models/models';
import { FlowToImportSummaryData } from './flow-to-import-summary';

export interface ImportReferentialFlowRequest {
  uuidEntityDest: string;
  referentialFlowsUuidEntity: string[];
  allSelected?: boolean;
  queries?: FlowToImportSummaryData.QueryRequest;
}

export namespace ImportReferentialFlowRequest {
  export function mapToRequestApiValue(request: ImportReferentialFlowRequest): ImportReferentialFlowsRequestDTO {
    return {
      uuidEntityDest: request.uuidEntityDest,
      referentialFlowsUuidEntity: !request.allSelected ? request.referentialFlowsUuidEntity || [] : undefined,
      allSelected: undefined,
      filterFlowImportReferentialRequest: request.allSelected && request.queries ? FlowToImportSummaryData.mapToPageFilterReferentialRequestApiValue(request.queries) : undefined,
      confirmed: undefined,
    };
  }
}
