import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { CustomStatusDetailsResponseDTO } from '../../generated/models/customStatusDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum CustomStatusCategoryEnum {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Done = 'Done',
}

export namespace CustomStatusCategoryEnum {
  export const values = [CustomStatusCategoryEnum.ToDo, CustomStatusCategoryEnum.InProgress, CustomStatusCategoryEnum.Done];

  const key = 'status.category.';
  export const toString = new SfxMap<CustomStatusCategoryEnum, string>([
    [CustomStatusCategoryEnum.ToDo, key + 'todo'],
    [CustomStatusCategoryEnum.InProgress, key + 'inProgress'],
    [CustomStatusCategoryEnum.Done, key + 'done'],
  ]);
  const entityKey = 'global.status.category.';
  export const entityToString = new SfxMap<CustomStatusCategoryEnum, string>([
    [CustomStatusCategoryEnum.ToDo, entityKey + 'todo'],
    [CustomStatusCategoryEnum.InProgress, entityKey + 'inProgress'],
    [CustomStatusCategoryEnum.Done, entityKey + 'done'],
  ]);

  export const iconData: SfxMap<CustomStatusCategoryEnum, IconData> = new SfxMap<CustomStatusCategoryEnum, IconData>([
    [CustomStatusCategoryEnum.ToDo, { name: 'square', iconClassName: 'sfx-todo' }],
    [CustomStatusCategoryEnum.InProgress, { name: 'square', iconClassName: 'sfx-in-progress' }],
    [CustomStatusCategoryEnum.Done, { name: 'square', iconClassName: 'sfx-done' }],
  ]);

  export const convertFromApiValue: SfxMap<string, CustomStatusCategoryEnum> = new SfxMap<string, CustomStatusCategoryEnum>([
    [CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.Todo, CustomStatusCategoryEnum.ToDo],
    [CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.InProgress, CustomStatusCategoryEnum.InProgress],
    [CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.Done, CustomStatusCategoryEnum.Done],
  ]);

  export const convertToApiValue: SfxMap<CustomStatusCategoryEnum, string> = new SfxMap<CustomStatusCategoryEnum, string>(
    [
      [CustomStatusCategoryEnum.ToDo, CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.Todo],
      [CustomStatusCategoryEnum.InProgress, CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.InProgress],
      [CustomStatusCategoryEnum.Done, CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.Done],
    ],
    CustomStatusDetailsResponseDTO.CustomStatusCategoryEnum.Todo,
  );

  export const singleSelectItemsForCustomStatus = (): Observable<SingleSelectItemData<CustomStatusCategoryEnum>[]> =>
    of(values.map(value => ({ value, title: toString.getValue(value), icons: [iconData.getValue(value)] })));

  export const singleSelectItemsForCustomStatusComparePredicate = (node: SingleSelectItemData<CustomStatusCategoryEnum>, item: CustomStatusCategoryEnum) => node.value === item;
}
