import { compact } from 'lodash';

import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

import { FlowTypeEnum } from '../flow/flow-type.enum';
import { PermissionActionEnum } from '../permission-action.enum';

export enum FlowContentActionEnum {
  ImportFlow = 1,
  UpdateLibrary = 2,
  InternValidation = 3,
  EditFlow = 4,
  FlowSourceInfo = 5,
  ResetFlow = 7,
  Revision = 8,
  Sequence = 9,
  UpdateContent = 10,
  UpdateDocument = 11,
  ViewHistory = 12,
  OrphanResponses = 13,
}

export namespace FlowContentActionEnum {
  const isFalse = false; // Note: Hard code to deactivate functionality temporary...

  export const getActions = (flowType: FlowTypeEnum, revisionMode?: boolean, hideUpdate?: boolean, isAdmin?: boolean): FlowContentActionEnum[] =>
    compact([
      !revisionMode && FlowTypeEnum.flowsWhereImportFlowContentIsEnabled.includes(flowType) && FlowContentActionEnum.ImportFlow,
      !revisionMode && ![FlowTypeEnum.Group, FlowTypeEnum.GroupReferential].includes(flowType) && FlowContentActionEnum.UpdateLibrary,
      isFalse && !revisionMode && FlowContentActionEnum.UpdateDocument, // REVIEW: @Sajir @Majdi to be enabled later
      !revisionMode && FlowTypeEnum.flowsWhereImportFlowContentIsEnabled.includes(flowType) && FlowContentActionEnum.FlowSourceInfo,
      FlowTypeEnum.flowsWhereBlocksCanBeValidated.includes(flowType) && FlowContentActionEnum.InternValidation,
      FlowContentActionEnum.EditFlow,
      FlowTypeEnum.flowsWhereSequenceCanBeAdded.includes(flowType) && FlowContentActionEnum.Sequence,
      FlowContentActionEnum.ResetFlow,
      !hideUpdate && FlowTypeEnum.flowsWhereCanDeleteAndResetContent.includes(flowType) && FlowContentActionEnum.UpdateContent,
      FlowContentActionEnum.ViewHistory,
      isAdmin && !FlowTypeEnum.flowsWithoutOrphanResponses.includes(flowType) && FlowContentActionEnum.OrphanResponses,
    ]);

  const key = 'flowContentNavigation.options.';

  export const toString: SfxMap<FlowContentActionEnum, string> = new SfxMap([
    [FlowContentActionEnum.ImportFlow, key + 'importFlow'],
    [FlowContentActionEnum.UpdateLibrary, key + 'updateLibrary'],
    [FlowContentActionEnum.UpdateDocument, key + 'updateDocument'],
    [FlowContentActionEnum.InternValidation, key + 'internValidation'],
    [FlowContentActionEnum.EditFlow, key + 'editFlow'],
    [FlowContentActionEnum.FlowSourceInfo, key + 'referentialInfo'],
    [FlowContentActionEnum.ResetFlow, key + 'resetFlow'],
    [FlowContentActionEnum.Revision, key + 'revision'],
    [FlowContentActionEnum.Sequence, key + 'sequence'],
    [FlowContentActionEnum.UpdateContent, key + 'updateContent'],
    [FlowContentActionEnum.ViewHistory, key + 'viewHistory'],
    [FlowContentActionEnum.OrphanResponses, key + 'orphanResponses'],
  ]);

  export const actionPermission = new SfxMap([[FlowContentActionEnum.ResetFlow, PermissionActionEnum.Reset]]);

  export const iconData: SfxMap<FlowContentActionEnum, IconData> = new SfxMap([
    [FlowContentActionEnum.ImportFlow, { name: 'cart-arrow-down' }],
    [FlowContentActionEnum.UpdateLibrary, { name: 'shopping-basket' }],
    [FlowContentActionEnum.UpdateDocument, { name: 'file-alt' }],
    [FlowContentActionEnum.InternValidation, { name: 'user-check' }],
    [FlowContentActionEnum.EditFlow, { name: 'edit', prefix: 'far' }],
    [FlowContentActionEnum.FlowSourceInfo, { name: 'pencil-ruler' }],
    [FlowContentActionEnum.ResetFlow, { name: 'undo-alt' }],
    [FlowContentActionEnum.Revision, { name: 'paint-roller' }],
    [FlowContentActionEnum.Sequence, { name: 'film' }],
    [FlowContentActionEnum.UpdateContent, { name: 'pencil-ruler', prefix: 'fas' }],
    [FlowContentActionEnum.ViewHistory, { name: 'flag', prefix: 'fas' }],
    [FlowContentActionEnum.OrphanResponses, { name: 'list-alt', prefix: 'fas' }],
  ]);

  export const selectItems = (flowType: FlowTypeEnum, revisionMode?: boolean, hideUpdate?: boolean, isAdmin?: boolean): ActionSelectItemData<FlowContentActionEnum>[] =>
    getActions(flowType, revisionMode, hideUpdate, isAdmin).map(action => ({
      value: action,
      name: toString.getValue(action),
      icon: iconData.getValue(action),
      permissionAction: actionPermission.getValue(action),
    }));
}
