import { ListSummaryResponseDTO, PageListRequestDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum, ListsTypeEnum } from '../../enums';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface ListsSummaryData {
  description: string;
  name: string;
  refInt: string;
  typeFlow: ListsTypeEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  labelFamilyProject: LabelFamilyLightData;
  isReferential: boolean;
}

export namespace ListsSummaryData {
  const sortFields = ['typeFlow', 'name'];
  export interface QueryRequest extends BaseQuery<ListsSummaryData> {
    types: ListsTypeEnum[];
    categories: string[];
    projectUuidEntity: string;
  }

  export function mapFromApiValue(list: ListSummaryResponseDTO): ListsSummaryData {
    return {
      description: list.description,
      name: list.name,
      refInt: list.refInt,
      typeFlow: ListsTypeEnum.convertFromApiValue.getValue(list.typeFlow),
      uuid: list.uuid,
      uuidEntity: list.uuidEntity,
      uuidOrganization: list.uuidOrganization,
      uuidType: list.uuidType,
      labelFamilyProject: {
        ...(list.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(list.labelFamilyProject, ListsTypeEnum.convertFromApiValue.getValue(list.typeFlow))),
        color: ListsTypeEnum.color.getValue(ListsTypeEnum.convertFromApiValue.getValue(list.typeFlow)),
      },
      isReferential: list.isReferential,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageListRequestDTO {
    return {
      categories: queries.categories,
      page: queries.page,
      projectUuidEntity: queries.projectUuidEntity,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
      types: (queries.types || []).map(type => ListsTypeEnum.convertToApiValue.getValue(type)),
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, categories: string[], searchText: string, type: string, sort: SortFilter<ListsSummaryData>): QueryRequest {
    return {
      ...initializeQueries<ListsSummaryData>(sort),
      textSearch: searchText || undefined,
      types: (type && [ListsTypeEnum.convertFromParamsValue.getValue(type)]) || [ListsTypeEnum.Emm, ListsTypeEnum.Adr],
      categories: categories,
      projectUuidEntity,
    };
  }

  export function mapToSingleSelectItemData(response: ListSummaryResponseDTO): SingleSelectItemData {
    return {
      value: { uuidEntity: response?.uuidEntity, name: response?.name },
      title: response.name,
    };
  }
}
