import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './dash-document-dialogs.actions';

import { DashboardDocumentSummaryData, ErrorResponseData } from '../../../../../core/models';

const importAdapter: EntityAdapter<DashboardDocumentSummaryData> = createEntityAdapter<DashboardDocumentSummaryData>({
  selectId: document => document.uuidEntity,
});

const reorganizeAdapter: EntityAdapter<DashboardDocumentSummaryData> = createEntityAdapter<DashboardDocumentSummaryData>({
  selectId: document => document.uuidEntity,
});

export interface DocumentsToImportState extends EntityState<DashboardDocumentSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  disabledFilteredTotalCount?: number;
  reset: boolean;
}

export const importDocumentInitialState: DocumentsToImportState = importAdapter.getInitialState<DocumentsToImportState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  disabledFilteredTotalCount: undefined,
  reset: false,
});

export interface ReorganizeDocuments extends EntityState<DashboardDocumentSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  filteredTotalCount: number;
  reset: boolean;
}

export const reorganizeDocumentsInitialState: ReorganizeDocuments = importAdapter.getInitialState<ReorganizeDocuments>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  filteredTotalCount: undefined,
  reset: false,
});

export interface DashboardDocumentsDialogState {
  toImport: DocumentsToImportState;
  reorganize: ReorganizeDocuments;
  error: ErrorResponseData;
}

export const initialDashboardDocumentsDialogState = () => ({
  toImport: importDocumentInitialState,
  reorganize: reorganizeDocumentsInitialState,
  error: null,
});

const reducer = createReducer<DashboardDocumentsDialogState>(
  initialDashboardDocumentsDialogState(),
  on(
    fromActions.loadDocumentToImportSuccess,
    (state, { documents, filteredTotalCount, disabledFilteredTotalCount, reset }): DashboardDocumentsDialogState => {
      const newImportState: DocumentsToImportState = { ...state.toImport, loading: false, reset, filteredTotalCount, disabledFilteredTotalCount };
      const newState = reset ? importAdapter.setAll(documents, newImportState) : importAdapter.addMany(documents, newImportState);

      return { ...state, toImport: newState };
    },
  ),
  on(
    fromActions.loadReorganizeDocumentsSuccess,
    (state, { documents, totalCount, filteredTotalCount, reset }): DashboardDocumentsDialogState => {
      const newReorganizeState: DocumentsToImportState = { ...state.reorganize, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? reorganizeAdapter.setAll(documents, newReorganizeState) : reorganizeAdapter.addMany(documents, newReorganizeState);

      return { ...state, reorganize: newState };
    },
  ),
);

export function dashboardDocumentDialogReducer(state: DashboardDocumentsDialogState | undefined, action: Action): DashboardDocumentsDialogState {
  return reducer(state, action);
}

export const selectAllToImport = importAdapter.getSelectors().selectAll;
export const selectAllReorganize = reorganizeAdapter.getSelectors().selectAll;
