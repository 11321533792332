import { CheckShareLinkCodeResponseDTO } from '../../generated/models/checkShareLinkCodeResponseDTO';

export interface LoginShareData {
  idToken: string;
}

export namespace LoginShareData {
  export function mapFromApiValue(data: CheckShareLinkCodeResponseDTO): LoginShareData {
    return { idToken: data.idToken };
  }
}
