import { CustomStatusLabelFamilyLightDTO, CustomStatusSummaryResponseDTO, CustomStatusPageRequestDTO } from '../../generated/models/models';
import { LabelFamilyRelativeNatureEnum, BusinessClassNameEnum, InnerSortDirectionEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface CustomStatusSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
  color: string;
  customStatusOrder: number;
  downloadable: boolean;
  importable: boolean;
  exportable: boolean;
  nodeOrder: number;
  childrenCount?: number;
  relativeNature?: LabelFamilyRelativeNatureEnum;
  isParent?: boolean;
  parent?: CustomStatusSummaryData;
  archived: boolean;
  hideEntity?: boolean;
}

export namespace CustomStatusSummaryData {
  const sortFields = ['family'];
  export interface QueryRequest extends BaseQuery<CustomStatusSummaryData> {
    businessClass: BusinessClassNameEnum;
    familyUuidEntity: string;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(response: CustomStatusSummaryResponseDTO): CustomStatusSummaryData {
    return {
      uuidEntity: response.uuidEntity,
      name: response.name,
      color: response.color,
      importable: response.importable,
      exportable: response.exportable,
      downloadable: response.downloadable,
      nodeOrder: response.nodeOrder,
      description: null,
      customStatusOrder: null,
      archived: response.archived,
      hideEntity: response.hideEntity,
    };
  }

  export function mapFromFamilyLight(response: CustomStatusLabelFamilyLightDTO): CustomStatusSummaryData {
    return {
      uuidEntity: response.uuidEntity,
      name: response.name,
      color: response.color || '#0d4e8a',
      importable: false,
      exportable: false,
      downloadable: false,
      nodeOrder: undefined,
      childrenCount: response.childCount,
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(response.relativeNature),
      description: null,
      customStatusOrder: null,
      isParent: true,
      archived: response['archived'], // Review: MakeModels: archived not found in CustomStatusLabelFamilyLightDTO
      hideEntity: response['hideEntity'],
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): CustomStatusPageRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(queries.businessClass),
      familyUuidEntity: queries.familyUuidEntity,
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(businessClass: string, searchText: string, sort: SortFilter<CustomStatusSummaryData>, state?: string): QueryRequest {
    return {
      ...initializeQueries<CustomStatusSummaryData>(sort),
      textSearch: searchText || undefined,
      businessClass: BusinessClassNameEnum.convertFromParamsValue.getValue(businessClass),
      familyUuidEntity: null,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }
}
