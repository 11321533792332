import { OptionSignatoryDetailsResponseDTO } from '../../generated/models/models';
import { UserForFormPageRequestDTO } from '../../generated/models/userForFormPageRequestDTO';
import { DateUtils } from '../../utils/date.utils';
import { UserLightData } from '../user/user-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';
import { ContributorTypeEnum } from '../../enums';

export interface QuestionContributorData {
  firstName: string;
  lastName: string;
  email: string;
  function: string;
  userRef: string;
  signatureDate: string;
  signatureProxiedDate?: string;
  disableSignature: boolean;
  locked: boolean;
  imageSignature: string;
  typeContributor: ContributorTypeEnum;
  selectedUser?: UserLightData & Partial<EntityLightData>;
  proxied: boolean;
  commentSignature?: string;
  signatoryName?: string;
}

export namespace QuestionContributorData {
  export interface QueryRequest extends BaseQuery<QuestionContributorData> {
    projectUuidEntity?: string;
  }

  export function mapFromApiValue(option: OptionSignatoryDetailsResponseDTO): QuestionContributorData {
    let signatoryName;
    if (option && option.firstName && option.lastName) {
      signatoryName = option.proxied ? option.firstName : option.firstName + ' ' + option.lastName;
    }

    return {
      firstName: option.firstName,
      lastName: option.lastName,
      email: option.email,
      function: option.profession,
      userRef: option.userRef,
      disableSignature: option.disableSignature,
      imageSignature: option.imageSignature,
      signatureDate: DateUtils.toCalendarFormat(option.signatureDate),
      typeContributor: ContributorTypeEnum.convertFromApiValue.getValue(option.typeContributor) || ContributorTypeEnum.Intern,
      selectedUser: option.firstName && option.lastName && ({ firstName: option.firstName, lastName: option.lastName, uuidEntity: option.userRef } as UserLightData),
      locked: option.locked,
      proxied: option.proxied,
      commentSignature: option.commentSignature,
      signatureProxiedDate: DateUtils.toCalendarFormat(option['signatureProxiedDate']), // make models
      signatoryName,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): UserForFormPageRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function initializeFilterQueries(page: number, textSearch?: string, projectUuidEntity?: string): QueryRequest {
    return {
      ...initializeQueries<QuestionContributorData>(),
      page,
      textSearch: textSearch || undefined,
      projectUuidEntity,
    };
  }
}
