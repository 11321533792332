import { NgModule } from '@angular/core';
import { ContainsPipe } from './contains.pipe';
import { FilterByFieldPipe } from './filter-by-field/filter-by-field.pipe';
import { FilterPipe } from './filter.pipe';
import { HighlightTextPipe } from './highlight-text/highlight-text.pipe';
import { MultiSwitchCasePipe } from './multi-switch-case.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { QuestionDurationDataPipe } from './question-duration-data/question-duration-data.pipe';

const pipes = [FilterByFieldPipe, HighlightTextPipe, MultiSwitchCasePipe, SafeUrlPipe, ContainsPipe, FilterPipe, QuestionDurationDataPipe];
@NgModule({
  declarations: pipes,
  exports: pipes,
})
export class SfxPipesModule {}
