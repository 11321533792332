import { SfxMap } from '../../utils/enum-utils';

export enum TrashErrorsEnum {
  RestorePgac = 1,
  DocumentOrphan = 2,
  DocumentConflict = 3,
  FormAlreadyUsed = 4,
  FlowOrphan = 5,
  RestoreFlowPgac = 6,
  FlowConflict = 7,
}

export namespace TrashErrorsEnum {
  export const convertFromApiValue: SfxMap<string, TrashErrorsEnum> = new SfxMap<string, TrashErrorsEnum>([
    ['error.project.cantRestorePgac', TrashErrorsEnum.RestorePgac],
    ['error.documentFlow.restoreParentDeleted', TrashErrorsEnum.DocumentOrphan],
    ['error.documentFlow.typeDocAlreadyExists', TrashErrorsEnum.DocumentConflict],
    ['error.form.formImportedInProject', TrashErrorsEnum.FormAlreadyUsed],
    ['error.flow.restoreParentDeleted', TrashErrorsEnum.FlowOrphan],
    ['error.flow.restoreInPgacParent', TrashErrorsEnum.RestoreFlowPgac],
    ['error.flow.flowImportedInProject', TrashErrorsEnum.FlowConflict],
  ]);

  const key = 'trash.restore.error.';
  export const toString: SfxMap<TrashErrorsEnum, string> = new SfxMap<TrashErrorsEnum, string>(
    [
      [TrashErrorsEnum.RestorePgac, key + 'project.cantRestorePgac'],
      [TrashErrorsEnum.DocumentOrphan, key + 'documentFlow.restoreParentDeleted'],
      [TrashErrorsEnum.DocumentConflict, key + 'documentFlow.typeDocAlreadyExists'],
      [TrashErrorsEnum.FormAlreadyUsed, key + 'form.formImportedInProject'],
      [TrashErrorsEnum.FlowOrphan, key + 'flow.restoreParentDeleted'],
      [TrashErrorsEnum.RestoreFlowPgac, key + 'flow.restoreInPgacParent'],
      [TrashErrorsEnum.FlowConflict, key + 'flow.flowImportedInProject'],
    ],
    key + 'default',
  );
}
