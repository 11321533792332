/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { FormLightResponseDTO } from './formLightResponseDTO';
import { LabelApplicabilityResponseDTO } from './labelApplicabilityResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { TemplateParamsResponseDTO } from './templateParamsResponseDTO';

export interface FormDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  createDate: Date;
  modificationDate: Date;
  description: string;
  reference: string;
  customStatus: CustomStatusLightDTO;
  nature: FormDetailsResponseDTO.NatureEnum;
  typeForm: FormDetailsResponseDTO.TypeFormEnum;
  refUserCreator: string;
  dateResponse: Date;
  localDateSubmission: Date;
  dateSubmission: Date;
  refUserSubmitter: string;
  indice: string;
  referenceResponse: string;
  formParent: FormLightResponseDTO;
  deleted: boolean;
  useTemplate: boolean;
  uniqueReply: boolean;
  icon: string;
  responseProvenance: FormDetailsResponseDTO.ResponseProvenanceEnum;
  template: TemplateParamsResponseDTO;
  labelFamily: LabelFamilyLightDTO;
  applicability: LabelApplicabilityResponseDTO;
  totalNumberOfResponses?: number;
  lockForm: boolean;
}
export namespace FormDetailsResponseDTO {
  export type NatureEnum = 'Model' | 'Generic' | 'Autre' | 'LOCAL';
  export const NatureEnum = {
    Model: 'Model' as NatureEnum,
    Generic: 'Generic' as NatureEnum,
    Autre: 'Autre' as NatureEnum,
    LOCAL: 'LOCAL' as NatureEnum,
  };
  export type TypeFormEnum = 'FORM' | 'RESPONSE' | 'RESPONSE_DONE' | 'AUTRE';
  export const TypeFormEnum = {
    FORM: 'FORM' as TypeFormEnum,
    RESPONSE: 'RESPONSE' as TypeFormEnum,
    RESPONSEDONE: 'RESPONSE_DONE' as TypeFormEnum,
    AUTRE: 'AUTRE' as TypeFormEnum,
  };
  export type ResponseProvenanceEnum = 'MOBILE' | 'WEB';
  export const ResponseProvenanceEnum = {
    MOBILE: 'MOBILE' as ResponseProvenanceEnum,
    WEB: 'WEB' as ResponseProvenanceEnum,
  };
}
