import { compact } from 'lodash';

import { SfxMap } from '../../utils/enum-utils';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { PermissionActionEnum } from '../permission-action.enum';
import { ProjectTypeEnum } from '../project/project-type.enum';
import { FormNatureEnum } from '../form/form-nature.enum';

export enum FormActionEnum {
  Reply = 1,
  Edit = 2,
  Overview = 3,
  Summarize = 4,
  Duplicate = 5,
  Export = 6,
  Refresh = 7,
  Delete = 8,
  Property = 9,
  LocalCopy = 10,
  EditInfo = 11,
  EditContent = 12,
  History = 13,
  Share = 14,
  Activate = 15,
  Deactivate = 16,
}

export namespace FormActionEnum {
  const key = 'forms.table.actions.';

  export const toString: SfxMap<FormActionEnum, string> = new SfxMap<FormActionEnum, string>([
    [FormActionEnum.Reply, key + 'reply'],
    [FormActionEnum.Edit, key + 'edit'],
    [FormActionEnum.Overview, key + 'overview'],
    [FormActionEnum.Summarize, key + 'summarize'],
    [FormActionEnum.Duplicate, key + 'duplicate'],
    [FormActionEnum.Export, key + 'export'],
    [FormActionEnum.Refresh, key + 'refresh'],
    [FormActionEnum.History, key + 'history'],
    [FormActionEnum.Delete, key + 'delete'],
    [FormActionEnum.Property, key + 'properties'],
    [FormActionEnum.LocalCopy, key + 'localCopy'],
    [FormActionEnum.EditInfo, key + 'editInfo'],
    [FormActionEnum.EditContent, key + 'editContent'],
    [FormActionEnum.Share, key + 'share'],
    [FormActionEnum.Activate, key + 'activate'],
    [FormActionEnum.Deactivate, key + 'deactivate'],
  ]);

  export const iconData: SfxMap<FormActionEnum, IconData> = new SfxMap<FormActionEnum, IconData>([
    [FormActionEnum.Reply, { name: 'envelope-open-text', prefix: 'fas' }],
    [FormActionEnum.Edit, { name: 'edit', prefix: 'far' }],
    [FormActionEnum.Overview, { name: 'eye', prefix: 'far' }],
    [FormActionEnum.Summarize, { name: 'chart-pie', prefix: 'fas' }],
    [FormActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [FormActionEnum.Export, { name: 'file-excel', prefix: 'far' }],
    [FormActionEnum.Refresh, { name: 'sync-alt', prefix: 'fas' }],
    [FormActionEnum.History, { name: 'flag', prefix: 'fas' }],
    [FormActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [FormActionEnum.Property, { name: 'info', prefix: 'fas' }],
    [FormActionEnum.LocalCopy, { name: 'code-branch', prefix: 'fas' }],
    [FormActionEnum.EditInfo, { name: 'edit', prefix: 'far' }],
    [FormActionEnum.EditContent, { name: 'folder-open', prefix: 'far' }],
    [FormActionEnum.Share, { name: 'share-square', prefix: 'fas' }],
    [FormActionEnum.Activate, { name: 'eye', prefix: 'far' }],
    [FormActionEnum.Deactivate, { name: 'eye-slash', prefix: 'far' }],
  ]);

  export const getActionMenu = (formType?: FormNatureEnum, allowLocalForm?: boolean, archived?: boolean): SfxMap<ProjectTypeEnum, ActionSelectItemData<FormActionEnum>[]> => {
    const projectMenu = compact([
      mapToActionMenuItem(FormActionEnum.Reply),
      FormNatureEnum.Local === formType ? mapToActionMenuItem(FormActionEnum.EditInfo, PermissionActionEnum.Edit) : undefined,
      FormNatureEnum.Local === formType ? mapToActionMenuItem(FormActionEnum.EditContent, PermissionActionEnum.Edit) : undefined,
      mapToActionMenuItem(FormActionEnum.Overview),
      mapToActionMenuItem(FormActionEnum.Summarize),
      [FormNatureEnum.Local, FormNatureEnum.Model].includes(formType) && allowLocalForm ? mapToActionMenuItem(FormActionEnum.LocalCopy, PermissionActionEnum.Add) : undefined,
      mapToActionMenuItem(FormActionEnum.Share, PermissionActionEnum.Share),
      mapToActionMenuItem(FormActionEnum.Property),
      FormNatureEnum.Local !== formType ? mapToActionMenuItem(FormActionEnum.Refresh, PermissionActionEnum.Edit) : undefined,
      mapToActionMenuItem(FormActionEnum.Delete, PermissionActionEnum.Delete),
    ]);

    const pgacMenu = [
      mapToActionMenuItem(FormActionEnum.Reply),
      mapToActionMenuItem(FormActionEnum.Overview),
      mapToActionMenuItem(FormActionEnum.Summarize),
      mapToActionMenuItem(FormActionEnum.Share),
      mapToActionMenuItem(FormActionEnum.Property),
      mapToActionMenuItem(FormActionEnum.Refresh, PermissionActionEnum.Edit),
      mapToActionMenuItem(FormActionEnum.Delete, PermissionActionEnum.Delete),
    ];

    const referentialOrTemplateMenu = [
      mapToActionMenuItem(FormActionEnum.Refresh, PermissionActionEnum.Edit),
      mapToActionMenuItem(FormActionEnum.Delete, PermissionActionEnum.Delete),
    ];

    return new SfxMap<ProjectTypeEnum, ActionSelectItemData<FormActionEnum>[]>(
      [
        [ProjectTypeEnum.Project, projectMenu],
        [ProjectTypeEnum.Pgac, pgacMenu],
        [ProjectTypeEnum.Referential, referentialOrTemplateMenu],
        [ProjectTypeEnum.Template, referentialOrTemplateMenu],
      ],
      compact([
        mapToActionMenuItem(FormActionEnum.EditInfo),
        mapToActionMenuItem(FormActionEnum.EditContent, PermissionActionEnum.Edit),
        archived && mapToActionMenuItem(FormActionEnum.Activate, PermissionActionEnum.Archive),
        !archived && mapToActionMenuItem(FormActionEnum.Deactivate, PermissionActionEnum.Archive),
        mapToActionMenuItem(FormActionEnum.Summarize),
        mapToActionMenuItem(FormActionEnum.History),
        mapToActionMenuItem(FormActionEnum.Property),
        mapToActionMenuItem(FormActionEnum.Duplicate),
        mapToActionMenuItem(FormActionEnum.Export, PermissionActionEnum.Export),
        mapToActionMenuItem(FormActionEnum.Delete),
      ]),
    );
  };

  export function mapToActionMenuItem(formActionMenu: FormActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<FormActionEnum> {
    return {
      name: FormActionEnum.toString.getValue(formActionMenu),
      permissionAction,
      value: formActionMenu,
      icon: FormActionEnum.iconData.getValue(formActionMenu),
    };
  }
}
