import { createAction, props } from '@ngrx/store';

import { BlockRiskParadeSummaryData, BlockRiskDetailsData, ParadeDetailsData, AdrElementToImportSummaryData, BlockRiskForUpdate } from '../../../../../../../../core/models';
import { DialogToasterData } from '../../../../../../../sfx-dialog/state/dialog-toaster-data';

// View dialog actions
export const getBlockRiskDetailsFail = createAction('[bRiskDialog] get block risk details fail', props<DialogToasterData>());
export const getBlockRiskDetailsSuccess = createAction('[bRiskDialog] get block risk details success', props<{ riskDetails: BlockRiskDetailsData }>());

export const loadParadeList = createAction('[bRiskDialog] load parade List', props<{ riskUuidEntity: string }>());
export const loadMoreParadeList = createAction('[bRiskDialog] load more block risk parade');
export const loadBlockRiskParadeList = createAction('[bRiskDialog] load block risk parade list', props<{ generic: boolean }>());
export const loadParadeListSuccess = createAction(
  '[bRiskDialog] load block risk parade list success',
  props<{ parades: BlockRiskParadeSummaryData[]; totalCount: number; reset?: boolean }>(),
);
export const loadParadeListFail = createAction('[bRiskDialog] load block risk parade list fail', props<DialogToasterData>());

export const viewParadeFile = createAction('[bRiskDialog] view parade file', props<{ fileUuidEntity: string }>());
export const viewParadeFileFail = createAction('[bRiskDialog] view parade file fail', props<DialogToasterData>());

// Import dialog actions
export const loadRisks = createAction('[bRiskDialog] load risks');
export const loadRisksFail = createAction('[bRiskDialog] load risks fail', props<DialogToasterData>());
export const loadRisksSuccess = createAction(
  '[bRiskDialog] load risks success',
  props<{ risks: AdrElementToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadMoreRisks = createAction('[bRiskDialog] load more risks');

export const setEditBlockRiskLoading = createAction('[bRiskDialog] set edit block risk loading', props<{ loading: boolean }>());
export const editBlockRisk = createAction('[bRiskDialog] edit block risk', props<{ riskData: BlockRiskDetailsData }>());
export const editBlockRiskFail = createAction('[bRiskDialog] edit block risk fail', props<DialogToasterData>());
export const editBlockRiskSuccess = createAction('[bRiskDialog] edit block risk success', props<{ adrElement: BlockRiskForUpdate }>());

export const importRisk = createAction('[bRiskDialog] import risk', props<{ riskUuidEntity: string }>());
export const importRiskSuccess = createAction('[bRiskDialog] import risk success', props<{ riskUuidEntity: string }>());
export const importRiskFail = createAction('[bRiskDialog] import risk fail', props<DialogToasterData>());

// specific parades
export const openSpecificParadePopOver = createAction('[bRiskDialog] open specific parade popover', props<{ origin: HTMLElement; parade: ParadeDetailsData }>());

// delete specific parade
export const deleteParade = createAction('[bRiskDialog] delete specific parade', props<{ parade: ParadeDetailsData }>());
export const deleteParadeSuccess = createAction('[bRiskDialog] delete specific parade success', props<{ toasterTitle?: string }>());
export const deleteParadeFail = createAction('[bRiskDialog] delete specific parade fail', props<DialogToasterData>());

// add specific parade
export const addParade = createAction('[bRiskDialog] add new specific parade', props<{ parade: ParadeDetailsData; fileRef: File }>());
export const addParadeSuccess = createAction('[bRiskDialog] add new specific parade success', props<{ toasterTitle?: string }>());
export const addParadeFail = createAction('[bRiskDialog] add new specific parade fail', props<DialogToasterData>());

// update specific parade
export const updateParade = createAction('[bRiskDialog] update specific parade', props<{ parade: ParadeDetailsData; fileRef: File }>());
export const updateParadeSuccess = createAction('[bRiskDialog] update specific parade success', props<{ toasterTitle?: string }>());
export const updateParadeFail = createAction('[bRiskDialog] update specific parade fail', props<DialogToasterData>());
