import { createAction, props } from '@ngrx/store';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { ReferenceForCreate, ReferenceDetailsData, ReferenceForUpdate } from '../../../../../core/models';

export const createReference = createAction('[sReference/API] create Reference', props<{ reference: ReferenceForCreate; addNewOne?: boolean }>());
export const createReferenceSuccess = createAction('[sReference/API] create Reference success', props<{ reference: ReferenceDetailsData; addNewOne?: boolean }>());
export const createReferenceFail = createAction('[sReference/API] create Reference fail', props<DialogToasterData>());

export const loadReferenceDetailDataSuccess = createAction('[sReference/API] load reference data data success', props<{ referenceDetails: ReferenceDetailsData }>());
export const loadReferenceDetailDataFail = createAction('[sReference/API] load reference data data fail', props<DialogToasterData>());

export const updateReference = createAction('[sReference/API] update reference', props<{ reference: ReferenceForUpdate; closeModel?: boolean }>());
export const updateReferenceSuccess = createAction('[sReference/API] update reference success', props<{ reference: ReferenceForUpdate; closeModal?: boolean }>());
export const updateReferenceFail = createAction('[sReference/API] update reference fail', props<DialogToasterData>());

export const referenceDetailNavigation = createAction('[sReference/API] Navigate through details references', props<{ referenceUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[sReference/API] confirm navigate details references', props<{ reference: ReferenceDetailsData; step: number }>());
