import { ClientDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ClientNotebookColumnEnum {
  Name = 'name',
  NameComp = 'nameComp',
  NameComp2 = 'nameComp2',
  Actions = 'actions',
}

export namespace ClientNotebookColumnEnum {
  export type clientNotebookField = keyof ClientDTO;

  export const allColumns = [ClientNotebookColumnEnum.Name, ClientNotebookColumnEnum.NameComp, ClientNotebookColumnEnum.NameComp2, ClientNotebookColumnEnum.Actions];

  const key = 'clients.table.header.';
  export const toString: SfxMap<ClientNotebookColumnEnum, string> = new SfxMap<ClientNotebookColumnEnum, string>([
    [ClientNotebookColumnEnum.Name, key + 'name'],
    [ClientNotebookColumnEnum.NameComp, key + 'nameComp'],
    [ClientNotebookColumnEnum.NameComp2, key + 'nameComp2'],
    [ClientNotebookColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ClientNotebookColumnEnum, clientNotebookField> = new SfxMap<ClientNotebookColumnEnum, clientNotebookField>([
    [ClientNotebookColumnEnum.Name, 'name'],
    [ClientNotebookColumnEnum.NameComp, 'nameComp'],
    [ClientNotebookColumnEnum.NameComp2, 'nameComp2'],
  ]);
}
