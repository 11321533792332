import { PictogramEnum } from '../../enums';
import { BlocAdrDetailsResponseDTO } from '../../generated/models/models';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface BlockRiskDetailsData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  applicability: LabelApplicabilityLightData;
  name: string;
  source: string;
  reference: string;
  pictogram: PictogramEnum;
  landmark: LabelFamilyLightData;
  major: boolean;
}

export namespace BlockRiskDetailsData {
  export function mapFromApiValue(blockRisk: BlocAdrDetailsResponseDTO): BlockRiskDetailsData {
    return {
      uuidEntity: blockRisk.uuidEntity,
      family: blockRisk.family && LabelFamilyLightData.mapFromApiValue(blockRisk.family),
      applicability: blockRisk.applicability && LabelApplicabilityLightData.mapFromApiValue(blockRisk.applicability),
      name: blockRisk.name,
      source: blockRisk.source,
      reference: blockRisk.reference,
      pictogram: PictogramEnum.convertFromApiValue.getValue(blockRisk.pictogram),
      landmark: blockRisk.landmark && LabelFamilyLightData.mapFromApiValue(blockRisk.landmark),
      major: blockRisk.major,
    };
  }
}
