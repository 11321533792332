import { ElementPrSummaryResponseDTO, PageElementPrRequest } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SignatureSummaryData } from './../signatures/signature-summary';
import { UserLightData } from './../user/user-light-data';
import { CustomStatusLightData } from './../custom-status/custom-status-light-data';
import { DateUtils } from '../../utils/date.utils';
import { DefaultValuesEnum, StringUtilsEnum } from '../../enums';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { EntityLightData } from '../utils/entity-light-data';

export interface ElementPrSummaryData {
  uuidEntity: string;
  elementOrder: number;
  name: string;
  nameAutoFilled: boolean;
  observation: string;
  content: string;
  signatory: SignatureSummaryData;
  customStatus: CustomStatusLightData;
  lastModifiedBy: UserLightData;
  modificationDate: string;
  childrenCount?: number;
  disabled?: boolean;
  hideOnExport?: boolean;
}

export namespace ElementPrSummaryData {
  const hTag = '#';
  const allBlocks = DefaultValuesEnum.convertToApiValue.getValue(DefaultValuesEnum.AllBlocks);
  export interface QueryRequest extends BaseQuery<ElementPrSummaryData> {
    blockUuidEntity: string;
  }

  export function mapFromApiValue(elementPr: ElementPrSummaryResponseDTO): ElementPrSummaryData {
    return {
      uuidEntity: elementPr.uuidEntity,
      elementOrder: elementPr.elementOrder,
      name: elementPr.name,
      content: elementPr.content,
      nameAutoFilled: elementPr.nameAutoFilled,
      observation: elementPr.observation,
      signatory: elementPr.signatory && SignatureSummaryData.mapFromElementPrApiValue(elementPr.signatory),
      customStatus: elementPr.customStatus && CustomStatusLightData.mapFromApiValue(elementPr.customStatus),
      lastModifiedBy: elementPr.lastModifiedBy ? UserLightData.mapFromApiValue(elementPr.lastModifiedBy) : undefined,
      modificationDate: elementPr.modifcationDate && DateUtils.toDateFormat(elementPr.modifcationDate),
      childrenCount: 1,
      disabled: elementPr.disabled,
      hideOnExport: elementPr.hideOnExport,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageElementPrRequest {
    return {
      page: queries.page,
      size: queries.size,
      blockUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(blockUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<ElementPrSummaryData>(),
      blockUuidEntity,
    };
  }
  export function initializeFilterQueries(page: number, blockUuidEntity: string, searchText: string): QueryRequest {
    return {
      ...initializeQueries<ElementPrSummaryData>(),
      textSearch: searchText || undefined,
      page,
      blockUuidEntity: blockUuidEntity,
    };
  }

  export function mapToSelectItem(element: ElementPrSummaryResponseDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: { uuidEntity: element.uuidEntity, name: element.name },
      title: element.uuidEntity && element.uuidEntity === allBlocks ? element.name : hTag.concat(element.elementOrder + StringUtilsEnum.DashSeparator.concat(element.name)),
    };
  }

  export function mapToEntityLightData(elementSummary: ElementPrSummaryData): EntityLightData {
    return {
      uuidEntity: elementSummary.uuidEntity,
      name: elementSummary.name,
    };
  }
}
