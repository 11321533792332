import { AddLineEntityRequestDTO } from '../../generated/models/addLineEntityRequestDTO';
import { RubricOptionAddLineRequestDTO } from '../../generated/models/rubricOptionAddLineRequestDTO';

export namespace OptionCreateData {
  export function mapToRequestApiValue(name: string, order: number, uuidEntity: string): RubricOptionAddLineRequestDTO {
    return { name, uuidEntity, order };
  }

  export function mapToAddLineEntityRequestDTO(name: string, uuideFormBusinessLink: string, uuidEntity: string): AddLineEntityRequestDTO {
    return { name, uuidEntity, uuideFormBusinessLink };
  }
}
