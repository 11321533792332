import { Directive, ElementRef, Inject, Renderer2, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Subscription, combineLatest } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { AppState } from '../../app.state';
import { getDialogEditMode, getDialogLoading, getCurrentTab, getFocusIndex } from '../../../modules/sfx-dialog/state';

@Directive({ selector: '[sfxFocus]', standalone: true })
export class FocusDirective implements OnDestroy, AfterViewInit {
  @Input() sfxFocusActive = true;
  private regexFocused = '[preferred-focus="1"]';
  private regexDefault = 'input:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.readonly), div.sfx-radio-container:not(.disabled)[tabindex="0"]';
  private regexFocusless = 'div[class="modal-container"][tabindex="-1"]';
  private subscription = new Subscription();

  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2, private store: Store<AppState>) {}

  ngAfterViewInit(): void {
    const disabledFocus = this.element.nativeElement.querySelectorAll(this.regexFocusless);

    if (this.sfxFocusActive && disabledFocus.length === 0) {
      this.subscription = combineLatest([
        this.store.pipe(select(getDialogLoading), withLatestFrom(this.store.pipe(select(getDialogEditMode)))),
        this.store.pipe(select(getCurrentTab)),
        this.store.pipe(select(getFocusIndex)),
      ]).subscribe(([[loading, editMode], currentTab, focusIndex]) => (!editMode || !loading || currentTab || focusIndex) && setTimeout(() => this.focusFirstField()));
      setTimeout(() => this.appendBoardElements());
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private appendBoardElements(): void {
    const parentNode = this.renderer.selectRootElement(this.element.nativeElement, true);
    const firstChildNode = this.renderer.selectRootElement(parentNode.firstChild, true);

    const firstSpan = this.renderer.createElement('span');
    this.renderer.addClass(firstSpan, 'first-span');
    this.renderer.setAttribute(firstSpan, 'tabindex', '0');
    this.renderer.setAttribute(firstSpan, 'onFocus', "setTimeout(() => document.querySelector('.last-focus').focus())");
    this.renderer.insertBefore(parentNode, firstSpan, firstChildNode);

    const lastSpan = this.renderer.createElement('span');
    this.renderer.addClass(lastSpan, 'last-span');
    this.renderer.setAttribute(lastSpan, 'tabindex', '0');
    this.renderer.setAttribute(lastSpan, 'onFocus', "setTimeout(() => document.querySelector('.first-focus').focus())");
    this.renderer.appendChild(parentNode, lastSpan);
  }

  private focusFirstField(): void {
    const preferredFocus = this.element.nativeElement.querySelectorAll(this.regexFocused);
    const defaultFocus = this.element.nativeElement.querySelectorAll(this.regexDefault);
    if (defaultFocus && defaultFocus.length) {
      this.renderer.addClass(defaultFocus[0], 'first-focus');
      this.renderer.addClass(defaultFocus[defaultFocus.length - 1], 'last-focus');
    }

    if (preferredFocus && preferredFocus[0]) {
      this.renderer.selectRootElement(preferredFocus[0], true).focus();
    } else if (defaultFocus && defaultFocus[0]) {
      this.renderer.selectRootElement(defaultFocus[0], true).focus();
    }
  }
}
