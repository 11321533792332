import { DocumentCategoryTypeEnum } from '../../enums';
import { DocumentFlowReorganizeCategoryRequestDTO } from '../../generated/models/models';

export interface DashboardDocumentReorganizeCategoryRequest {
  categoryType: DocumentCategoryTypeEnum;
  documentUuidEntities: string[];
  labelFamilyUuidEntity: string;
  selectedCategory?: string;
}

export namespace DashboardDocumentReorganizeCategoryRequest {
  export function mapToApiValue(query: DashboardDocumentReorganizeCategoryRequest): DocumentFlowReorganizeCategoryRequestDTO {
    return {
      categoryType: DocumentCategoryTypeEnum.convertToApiValue.getValue(query.categoryType),
      documentFlowUuidEntityList: query.documentUuidEntities || [],
      labelFamilyUuidEntity: query.labelFamilyUuidEntity,
    };
  }
}
