import { PageRfiTemplateRequestDTO } from '../../generated/models/pageRfiTemplateRequestDTO';
import { RfiTemplateSummaryResponseDTO } from '../../generated/models/rfiTemplateSummaryResponseDTO';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { InnerSortDirectionEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface FolderSummaryData {
  uuidEntity: string;
  name: string;
  childrenCount?: number;
  description?: string;
}

export namespace FolderSummaryData {
  const sortFields = ['name'];

  export function mapFromApiValue(data: RfiTemplateSummaryResponseDTO): FolderSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
      childrenCount: data.sectionsCount,
      description: data.description,
    };
  }

  export function mapToSingleSelectItemData(data: RfiTemplateSummaryResponseDTO): SingleSelectItemData {
    return {
      value: { name: data.name, uuidEntity: data.uuidEntity },
      title: data.name,
    };
  }

  export type PageFolderQueryRequest = BaseQuery<FolderSummaryData>;

  export function mapToPageRequestApiValue(queries: PageFolderQueryRequest, projectUuidEntity?: string): PageRfiTemplateRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity,
    };
  }

  export function initializeQueryRequest(searchText: string, sort: SortFilter<FolderSummaryData>): PageFolderQueryRequest {
    return {
      ...initializeQueries<FolderSummaryData>(sort),
      textSearch: searchText || undefined,
    };
  }
}
