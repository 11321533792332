import { FlowSummaryResponseDTO, SignatoryDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { SignatureStatusEnum } from '../../enums';
import { BlockDsiDetailsData, SignatureDetailsData } from '../../models';
import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SearchStrategyEnum } from '../../../modules/shared/components/select-v2/utils/search-strategy.enum';
import { compact } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export enum SignatureNatureEnum {
  Realization = 'Realization',
  ControlTech = 'ControlTech',
  BreakPointIntern = 'BreakPointIntern',
  BreakPointIntern2 = 'BreakPointIntern2',
  BreakPointIntern3 = 'BreakPointIntern3',
  BreakPointExtern = 'BreakPointExtern',
  BreakPointExtern2 = 'BreakPointExtern2',
  BreakPointExtern3 = 'BreakPointExtern3',
  ConvocationIntern = 'ConvocationIntern',
  ConvocationIntern2 = 'ConvocationIntern2',
  ConvocationIntern3 = 'ConvocationIntern3',
  ConvocationExtern = 'ConvocationExtern',
  ConvocationExtern2 = 'ConvocationExtern2',
  ConvocationExtern3 = 'ConvocationExtern3',
  // Signature Filter
  AllConvocation = 'AllConvocation',
  AllBreakPoint = 'AllBreakPoint',
}

export namespace SignatureNatureEnum {
  export const realizationSignatures = [SignatureNatureEnum.Realization, SignatureNatureEnum.ControlTech];
  export const breakPointSignature = [
    SignatureNatureEnum.BreakPointIntern,
    SignatureNatureEnum.BreakPointIntern2,
    SignatureNatureEnum.BreakPointIntern3,
    SignatureNatureEnum.ConvocationIntern,
    SignatureNatureEnum.ConvocationIntern2,
    SignatureNatureEnum.ConvocationIntern3,
    SignatureNatureEnum.BreakPointExtern,
    SignatureNatureEnum.BreakPointExtern2,
    SignatureNatureEnum.BreakPointExtern3,
    SignatureNatureEnum.ConvocationExtern,
    SignatureNatureEnum.ConvocationExtern2,
    SignatureNatureEnum.ConvocationExtern3,
  ];

  export const supervisorIntern1 = [SignatureNatureEnum.BreakPointIntern, SignatureNatureEnum.ConvocationIntern];
  export const supervisorIntern2 = [SignatureNatureEnum.BreakPointIntern2, SignatureNatureEnum.ConvocationIntern2];
  export const supervisorIntern3 = [SignatureNatureEnum.BreakPointIntern3, SignatureNatureEnum.ConvocationIntern3];
  export const supervisorExtern1 = [SignatureNatureEnum.BreakPointExtern, SignatureNatureEnum.ConvocationExtern];
  export const supervisorExtern2 = [SignatureNatureEnum.BreakPointExtern2, SignatureNatureEnum.ConvocationExtern2];
  export const supervisorExtern3 = [SignatureNatureEnum.BreakPointExtern3, SignatureNatureEnum.ConvocationExtern3];

  export const metricSignatures = [SignatureNatureEnum.Realization, SignatureNatureEnum.BreakPointIntern, SignatureNatureEnum.BreakPointExtern, SignatureNatureEnum.ControlTech];

  export const convertFromApiValue = new SfxMap<SignatoryDetailsResponseDTO.SignatureNatureEnum, SignatureNatureEnum>([
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.REALISATION, SignatureNatureEnum.Realization],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTCT, SignatureNatureEnum.ControlTech],
    // internal breakpoints
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI1, SignatureNatureEnum.BreakPointIntern],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI2, SignatureNatureEnum.BreakPointIntern2],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI3, SignatureNatureEnum.BreakPointIntern3],
    // internal convocations
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI1, SignatureNatureEnum.ConvocationIntern],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI2, SignatureNatureEnum.ConvocationIntern2],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI3, SignatureNatureEnum.ConvocationIntern3],
    // external convocations
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT1, SignatureNatureEnum.ConvocationExtern],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT2, SignatureNatureEnum.ConvocationExtern2],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT3, SignatureNatureEnum.ConvocationExtern3],
    // external breakpoints
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT1, SignatureNatureEnum.BreakPointExtern],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT2, SignatureNatureEnum.BreakPointExtern2],
    [SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT3, SignatureNatureEnum.BreakPointExtern3],
  ]);

  export const convertToApiValue = new SfxMap<SignatureNatureEnum, SignatoryDetailsResponseDTO.SignatureNatureEnum>([
    [SignatureNatureEnum.Realization, SignatoryDetailsResponseDTO.SignatureNatureEnum.REALISATION],
    [SignatureNatureEnum.ControlTech, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTCT],
    [SignatureNatureEnum.BreakPointIntern, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI1],
    [SignatureNatureEnum.BreakPointIntern2, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI2],
    [SignatureNatureEnum.BreakPointIntern3, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVINTPI3],
    [SignatureNatureEnum.BreakPointExtern, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT1],
    [SignatureNatureEnum.BreakPointExtern2, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT2],
    [SignatureNatureEnum.BreakPointExtern3, SignatoryDetailsResponseDTO.SignatureNatureEnum.SUPRVEXT3],
    [SignatureNatureEnum.ConvocationExtern, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT1],
    [SignatureNatureEnum.ConvocationExtern2, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT2],
    [SignatureNatureEnum.ConvocationExtern3, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVEXT3],
    [SignatureNatureEnum.ConvocationIntern, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI1],
    [SignatureNatureEnum.ConvocationIntern2, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI2],
    [SignatureNatureEnum.ConvocationIntern3, SignatoryDetailsResponseDTO.SignatureNatureEnum.CONVOCATIONSUPRVINTPI3],
  ]);

  export const convertFromParamsValue = new SfxMap<string, SignatureNatureEnum>([
    ['realization', SignatureNatureEnum.Realization],
    ['controlTech', SignatureNatureEnum.ControlTech],
    ['allConvocation', SignatureNatureEnum.AllConvocation],
    ['allBreakPoint', SignatureNatureEnum.AllBreakPoint],
    ['breakPointIntern', SignatureNatureEnum.BreakPointIntern],
    ['breakPointIntern2', SignatureNatureEnum.BreakPointIntern2],
    ['breakPointIntern3', SignatureNatureEnum.BreakPointIntern3],
    ['breakPointExtern', SignatureNatureEnum.BreakPointExtern],
    ['breakPointExtern2', SignatureNatureEnum.BreakPointExtern2],
    ['breakPointExtern3', SignatureNatureEnum.BreakPointExtern3],
    ['convocationExtern', SignatureNatureEnum.ConvocationExtern],
    ['convocationExtern2', SignatureNatureEnum.ConvocationExtern2],
    ['convocationExtern3', SignatureNatureEnum.ConvocationExtern3],
    ['convocationIntern', SignatureNatureEnum.ConvocationIntern],
    ['convocationIntern2', SignatureNatureEnum.ConvocationIntern2],
    ['convocationIntern3', SignatureNatureEnum.ConvocationIntern3],
  ]);

  export const convertToParamsValue = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, 'realization'],
    [SignatureNatureEnum.ControlTech, 'controlTech'],
    [SignatureNatureEnum.AllConvocation, 'allConvocation'],
    [SignatureNatureEnum.AllBreakPoint, 'allBreakPoint'],
    [SignatureNatureEnum.BreakPointIntern, 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern2, 'breakPointIntern2'],
    [SignatureNatureEnum.BreakPointIntern3, 'breakPointIntern3'],
    [SignatureNatureEnum.BreakPointExtern, 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern2, 'breakPointExtern2'],
    [SignatureNatureEnum.BreakPointExtern3, 'breakPointExtern3'],
    [SignatureNatureEnum.ConvocationExtern, 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern2, 'convocationExtern2'],
    [SignatureNatureEnum.ConvocationExtern3, 'convocationExtern3'],
    [SignatureNatureEnum.ConvocationIntern, 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern2, 'convocationIntern2'],
    [SignatureNatureEnum.ConvocationIntern3, 'convocationIntern3'],
  ]);

  const key = 'blocks.dsi.signatureNature.label.';
  export const toString = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, key + 'realization'],
    [SignatureNatureEnum.ControlTech, key + 'controlTech'],
    [SignatureNatureEnum.AllConvocation, key + 'allConvocation'],
    [SignatureNatureEnum.AllBreakPoint, key + 'allBreakPoint'],
    [SignatureNatureEnum.ConvocationIntern, key + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern2, key + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern3, key + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationExtern, key + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern2, key + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern3, key + 'convocationExtern'],
    [SignatureNatureEnum.BreakPointIntern, key + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern2, key + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern3, key + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointExtern, key + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern2, key + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern3, key + 'breakPointExtern'],
  ]);

  const trigramKey = 'blocks.dsi.signatureNature.trigram.';
  export const trigram = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, trigramKey + 'realization'],
    [SignatureNatureEnum.ControlTech, trigramKey + 'controlTech'],
    [SignatureNatureEnum.ConvocationIntern, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.ConvocationIntern2, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.ConvocationIntern3, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.ConvocationExtern, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.ConvocationExtern2, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.ConvocationExtern3, trigramKey + 'convocationPoint'],
    [SignatureNatureEnum.BreakPointIntern, trigramKey + 'breakPoint'],
    [SignatureNatureEnum.BreakPointIntern2, trigramKey + 'breakPoint'],
    [SignatureNatureEnum.BreakPointIntern3, trigramKey + 'breakPoint'],
    [SignatureNatureEnum.BreakPointExtern, trigramKey + 'breakPoint'],
    [SignatureNatureEnum.BreakPointExtern2, trigramKey + 'breakPoint'],
    [SignatureNatureEnum.BreakPointExtern3, trigramKey + 'breakPoint'],
  ]);

  export const color = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, '#162B4D'],
    [SignatureNatureEnum.ControlTech, '#B10B0B'],

    [SignatureNatureEnum.ConvocationIntern, '#0D4E8A'],
    [SignatureNatureEnum.ConvocationIntern2, '#0D4E8A'],
    [SignatureNatureEnum.ConvocationIntern3, '#0D4E8A'],
    [SignatureNatureEnum.BreakPointIntern, '#0D4E8A'],
    [SignatureNatureEnum.BreakPointIntern2, '#0D4E8A'],
    [SignatureNatureEnum.BreakPointIntern3, '#0D4E8A'],

    [SignatureNatureEnum.ConvocationExtern, '#FE8C25'],
    [SignatureNatureEnum.ConvocationExtern2, '#FE8C25'],
    [SignatureNatureEnum.ConvocationExtern3, '#FE8C25'],
    [SignatureNatureEnum.BreakPointExtern, '#FE8C25'],
    [SignatureNatureEnum.BreakPointExtern2, '#FE8C25'],
    [SignatureNatureEnum.BreakPointExtern3, '#FE8C25'],
  ]);

  export const metricsTotalColor = '#101836';
  export const metricsColor = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, '#1F5086'],
    [SignatureNatureEnum.ControlTech, '#AC0907'],
    [SignatureNatureEnum.BreakPointIntern, '#416F99'],
    [SignatureNatureEnum.BreakPointExtern, '#D76E13'],
  ]);

  export const iconClass = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, 'dark-blue'],
    [SignatureNatureEnum.ControlTech, 'danger'],

    [SignatureNatureEnum.ConvocationIntern, 'default'],
    [SignatureNatureEnum.ConvocationIntern2, 'default'],
    [SignatureNatureEnum.ConvocationIntern3, 'default'],
    [SignatureNatureEnum.BreakPointIntern, 'default'],
    [SignatureNatureEnum.BreakPointIntern2, 'default'],
    [SignatureNatureEnum.BreakPointIntern3, 'default'],

    [SignatureNatureEnum.ConvocationExtern, 'orange'],
    [SignatureNatureEnum.ConvocationExtern2, 'orange'],
    [SignatureNatureEnum.ConvocationExtern3, 'orange'],
    [SignatureNatureEnum.BreakPointExtern, 'orange'],
    [SignatureNatureEnum.BreakPointExtern2, 'orange'],
    [SignatureNatureEnum.BreakPointExtern3, 'orange'],
  ]);

  export const className = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, 'sfx-dark-purple'],
    [SignatureNatureEnum.ControlTech, 'sfx-danger-inverted'],

    [SignatureNatureEnum.ConvocationIntern, 'sfx-inverted'],
    [SignatureNatureEnum.ConvocationIntern2, 'sfx-inverted'],
    [SignatureNatureEnum.ConvocationIntern3, 'sfx-inverted'],
    [SignatureNatureEnum.BreakPointIntern, 'sfx-inverted'],
    [SignatureNatureEnum.BreakPointIntern2, 'sfx-inverted'],
    [SignatureNatureEnum.BreakPointIntern3, 'sfx-inverted'],

    [SignatureNatureEnum.ConvocationExtern, 'sfx-warning-inverted'],
    [SignatureNatureEnum.ConvocationExtern2, 'sfx-warning-inverted'],
    [SignatureNatureEnum.ConvocationExtern3, 'sfx-warning-inverted'],
    [SignatureNatureEnum.BreakPointExtern, 'sfx-warning-inverted'],
    [SignatureNatureEnum.BreakPointExtern2, 'sfx-warning-inverted'],
    [SignatureNatureEnum.BreakPointExtern3, 'sfx-warning-inverted'],
  ]);

  export const modalTitle = (
    editMode: boolean,
    signature: SignatureDetailsData,
    { isMonoExternSupervisor, isMonoInternSupervisor }: BlockDsiDetailsData,
  ): SfxMap<SignatureNatureEnum, string> => {
    let titleKey = `blocks.dsi.signatureNature.${editMode ? 'editMode.' : 'readMode.'}`;
    if (signature.signatureState === SignatureStatusEnum.NotPossible) {
      titleKey += 'nonApplicable.';
    }

    return new SfxMap<SignatureNatureEnum, string>([
      [SignatureNatureEnum.Realization, titleKey + 'realization'],
      [SignatureNatureEnum.ControlTech, titleKey + 'controlTech'],

      [SignatureNatureEnum.ConvocationIntern, titleKey + `${!isMonoInternSupervisor ? 'convocationIntern' : 'monoConvocationIntern'}`],
      [SignatureNatureEnum.ConvocationIntern2, titleKey + `${!isMonoInternSupervisor ? 'convocationIntern' : 'monoConvocationIntern'}`],
      [SignatureNatureEnum.ConvocationIntern3, titleKey + `${!isMonoInternSupervisor ? 'convocationIntern' : 'monoConvocationIntern'}`],

      [SignatureNatureEnum.ConvocationExtern, titleKey + `${!isMonoExternSupervisor ? 'convocationExtern' : 'monoConvocationExtern'}`],
      [SignatureNatureEnum.ConvocationExtern2, titleKey + `${!isMonoExternSupervisor ? 'convocationExtern' : 'monoConvocationExtern'}`],
      [SignatureNatureEnum.ConvocationExtern3, titleKey + `${!isMonoExternSupervisor ? 'convocationExtern' : 'monoConvocationExtern'}`],

      [SignatureNatureEnum.BreakPointIntern, titleKey + `${!isMonoInternSupervisor ? 'breakPointIntern' : 'monoBreakPointIntern'}`],
      [SignatureNatureEnum.BreakPointIntern2, titleKey + `${!isMonoInternSupervisor ? 'breakPointIntern' : 'monoBreakPointIntern'}`],
      [SignatureNatureEnum.BreakPointIntern3, titleKey + `${!isMonoInternSupervisor ? 'breakPointIntern' : 'monoBreakPointIntern'}`],

      [SignatureNatureEnum.BreakPointExtern, titleKey + `${!isMonoExternSupervisor ? 'breakPointExtern' : 'monoBreakPointExtern'}`],
      [SignatureNatureEnum.BreakPointExtern2, titleKey + `${!isMonoExternSupervisor ? 'breakPointExtern' : 'monoBreakPointExtern'}`],
      [SignatureNatureEnum.BreakPointExtern3, titleKey + `${!isMonoExternSupervisor ? 'breakPointExtern' : 'monoBreakPointExtern'}`],
    ]);
  };

  export function supervisorBySignatureNature(blockDsiDetails: BlockDsiDetailsData, signatureNature: SignatureNatureEnum): string {
    switch (signatureNature) {
      case SignatureNatureEnum.BreakPointIntern:
      case SignatureNatureEnum.ConvocationIntern:
        return blockDsiDetails?.firstInternSupervisor?.name || '';

      case SignatureNatureEnum.BreakPointExtern:
      case SignatureNatureEnum.ConvocationExtern:
        return blockDsiDetails?.firstExternSupervisor?.name || '';

      case SignatureNatureEnum.BreakPointIntern2:
      case SignatureNatureEnum.ConvocationIntern2:
        return blockDsiDetails?.secondInternSupervisor?.name || '';

      case SignatureNatureEnum.BreakPointExtern2:
      case SignatureNatureEnum.ConvocationExtern2:
        return blockDsiDetails?.secondExternSupervisor?.name || '';

      case SignatureNatureEnum.BreakPointIntern3:
      case SignatureNatureEnum.ConvocationIntern3:
        return blockDsiDetails?.thirdInternSupervisor?.name || '';

      case SignatureNatureEnum.BreakPointExtern3:
      case SignatureNatureEnum.ConvocationExtern3:
        return blockDsiDetails?.thirdExternSupervisor?.name || '';

      default:
        return '';
    }
  }

  const toggleLabelKey = 'blocks.dsi.signatureNature.toggleLabel.';

  export const toggleLabel = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, toggleLabelKey + 'realization'],
    [SignatureNatureEnum.ControlTech, toggleLabelKey + 'controlTech'],

    [SignatureNatureEnum.ConvocationIntern, toggleLabelKey + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern2, toggleLabelKey + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern3, toggleLabelKey + 'convocationIntern'],

    [SignatureNatureEnum.ConvocationExtern, toggleLabelKey + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern2, toggleLabelKey + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern3, toggleLabelKey + 'convocationExtern'],

    [SignatureNatureEnum.BreakPointIntern, toggleLabelKey + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern2, toggleLabelKey + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern3, toggleLabelKey + 'breakPointIntern'],

    [SignatureNatureEnum.BreakPointExtern, toggleLabelKey + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern2, toggleLabelKey + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern3, toggleLabelKey + 'breakPointExtern'],
  ]);

  const tooltipKey = 'flowSignature.tooltips.';
  export const tooltip = new SfxMap<SignatureNatureEnum, string>([
    [SignatureNatureEnum.Realization, tooltipKey + 'realization'],
    [SignatureNatureEnum.ControlTech, tooltipKey + 'controlTech'],

    [SignatureNatureEnum.ConvocationIntern, tooltipKey + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern2, tooltipKey + 'convocationIntern'],
    [SignatureNatureEnum.ConvocationIntern3, tooltipKey + 'convocationIntern'],

    [SignatureNatureEnum.ConvocationExtern, tooltipKey + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern2, tooltipKey + 'convocationExtern'],
    [SignatureNatureEnum.ConvocationExtern3, tooltipKey + 'convocationExtern'],

    [SignatureNatureEnum.BreakPointIntern, tooltipKey + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern2, tooltipKey + 'breakPointIntern'],
    [SignatureNatureEnum.BreakPointIntern3, tooltipKey + 'breakPointIntern'],

    [SignatureNatureEnum.BreakPointExtern, tooltipKey + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern2, tooltipKey + 'breakPointExtern'],
    [SignatureNatureEnum.BreakPointExtern3, tooltipKey + 'breakPointExtern'],
  ]);

  export const isExtern = new SfxMap<SignatureNatureEnum, boolean>(
    [
      [SignatureNatureEnum.ConvocationExtern, true],
      [SignatureNatureEnum.ConvocationExtern2, true],
      [SignatureNatureEnum.ConvocationExtern3, true],
      [SignatureNatureEnum.BreakPointExtern, true],
      [SignatureNatureEnum.BreakPointExtern2, true],
      [SignatureNatureEnum.BreakPointExtern3, true],
    ],
    false,
  );

  export const multiSelectItemResolver = (
    _: SearchStrategyEnum,
    flow: FlowSummaryResponseDTO,
    translateService: TranslateService,
  ): Observable<MultiSelectItemData<SignatureNatureEnum>[]> => {
    const isMonoInternSupervisor = !flow.supervisionIntern1 && !flow.supervisionIntern2 && !flow.supervisionIntern3;
    const isMonoExternSupervisor = !flow.supervision1 && !flow.supervision2 && !flow.supervision3;
    const values: MultiSelectItemData<SignatureNatureEnum>[] = !flow.simplifiedSignatureMode
      ? [
          {
            value: SignatureNatureEnum.Realization,
            title: toString.getValue(SignatureNatureEnum.Realization),
            parent: { value: SignatureNatureEnum.Realization } as MultiSelectItemData<SignatureNatureEnum>,
          },
          {
            value: SignatureNatureEnum.ControlTech,
            title: toString.getValue(SignatureNatureEnum.ControlTech),
            parent: { value: SignatureNatureEnum.ControlTech } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]
      : [];

    const internConvocation: MultiSelectItemData<SignatureNatureEnum>[] = isMonoInternSupervisor
      ? [
          {
            value: SignatureNatureEnum.ConvocationIntern,
            title: toString.getValue(SignatureNatureEnum.ConvocationIntern),
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]
      : compact([
          flow.supervisionIntern1 && {
            value: SignatureNatureEnum.ConvocationIntern,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationIntern)) + ' - ' + flow.internalSupervisorName1,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervisionIntern2 && {
            value: SignatureNatureEnum.ConvocationIntern2,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationIntern2)) + ' - ' + flow.internalSupervisorName2,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervisionIntern3 && {
            value: SignatureNatureEnum.ConvocationIntern3,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationIntern3)) + ' - ' + flow.internalSupervisorName3,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]);

    const externConvocation: MultiSelectItemData<SignatureNatureEnum>[] = isMonoExternSupervisor
      ? [
          {
            value: SignatureNatureEnum.ConvocationExtern,
            title: toString.getValue(SignatureNatureEnum.ConvocationExtern),
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]
      : compact([
          flow.supervision1 && {
            value: SignatureNatureEnum.ConvocationExtern,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationExtern)) + ' - ' + flow.externalSupervisorName1,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervision2 && {
            value: SignatureNatureEnum.ConvocationExtern2,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationExtern2)) + ' - ' + flow.externalSupervisorName2,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervision3 && {
            value: SignatureNatureEnum.ConvocationExtern3,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.ConvocationExtern3)) + ' - ' + flow.externalSupervisorName3,
            parent: { value: SignatureNatureEnum.AllConvocation } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]);

    const internBreakPoint: MultiSelectItemData<SignatureNatureEnum>[] = isMonoInternSupervisor
      ? [
          {
            value: SignatureNatureEnum.BreakPointIntern,
            title: toString.getValue(SignatureNatureEnum.BreakPointIntern),
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]
      : compact([
          flow.supervisionIntern1 && {
            value: SignatureNatureEnum.BreakPointIntern,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointIntern)) + ' - ' + flow.internalSupervisorName1,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervisionIntern2 && {
            value: SignatureNatureEnum.BreakPointIntern2,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointIntern2)) + ' - ' + flow.internalSupervisorName2,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervisionIntern3 && {
            value: SignatureNatureEnum.BreakPointIntern3,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointIntern3)) + ' - ' + flow.internalSupervisorName3,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]);

    const externBreakPoint: MultiSelectItemData<SignatureNatureEnum>[] = isMonoExternSupervisor
      ? [
          {
            value: SignatureNatureEnum.BreakPointExtern,
            title: toString.getValue(SignatureNatureEnum.BreakPointExtern),
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]
      : compact([
          flow.supervision1 && {
            value: SignatureNatureEnum.BreakPointExtern,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointExtern)) + ' - ' + flow.externalSupervisorName1,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervision2 && {
            value: SignatureNatureEnum.BreakPointExtern2,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointExtern2)) + ' - ' + flow.externalSupervisorName2,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
          flow.supervision3 && {
            value: SignatureNatureEnum.BreakPointExtern3,
            title: translateService.instant(toString.getValue(SignatureNatureEnum.BreakPointExtern3)) + ' - ' + flow.externalSupervisorName3,
            parent: { value: SignatureNatureEnum.AllBreakPoint } as MultiSelectItemData<SignatureNatureEnum>,
          },
        ]);

    values.push(
      {
        value: SignatureNatureEnum.AllConvocation,
        title: toString.getValue(SignatureNatureEnum.AllConvocation),
        children: [...internConvocation, ...externConvocation],
      },
      {
        value: SignatureNatureEnum.AllBreakPoint,
        title: toString.getValue(SignatureNatureEnum.AllBreakPoint),
        children: [...internBreakPoint, ...externBreakPoint],
      },
    );

    return of(values);
  };
}
