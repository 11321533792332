import { SfxMap } from '../../utils/enum-utils';

export enum DynamicVariableColumn {
  Name = 'name',
  Description = 'description',
  Action = 'action',
}

export namespace DynamicVariableColumn {
  export const allColumns: DynamicVariableColumn[] = [DynamicVariableColumn.Name, DynamicVariableColumn.Description, DynamicVariableColumn.Action];

  const key = 'forms.questions.dynamicVariable.table.header.';
  export const toString: SfxMap<DynamicVariableColumn, string> = new SfxMap<DynamicVariableColumn, string>([
    [DynamicVariableColumn.Name, key + 'name'],
    [DynamicVariableColumn.Description, key + 'description'],
    [DynamicVariableColumn.Action, key + 'action'],
  ]);
}
