import { CustomFileCreateRequestDTO, CustomFileDetailsResponseDTO, CustomFileUpdateRequestDTO } from '../../generated/models/models';

import { CustomFileNatureEnum, CustomFileExtensionEnum } from '../../enums';
import { EntityLightData } from '../utils/entity-light-data';
import { CustomFileTypeEnum } from '../../enums/custom-file/custom-file-type.enum';

export interface CustomFileDetailsData {
  uuidEntity: string;
  name: string;
  nature: CustomFileNatureEnum;
  extension: CustomFileExtensionEnum;
  customFileType: CustomFileTypeEnum;
  description: string;
}
export namespace CustomFileDetailsData {
  export function mapFromApiValue(customFile: CustomFileDetailsResponseDTO): CustomFileDetailsData {
    return {
      name: customFile.name,
      uuidEntity: customFile.uuidEntity,
      nature: CustomFileNatureEnum.convertFromApiValue.getValue(customFile.nature),
      extension: CustomFileExtensionEnum.convertFromApiValue.getValue(customFile.extension),
      customFileType: CustomFileTypeEnum.convertFromApiValue.getValue(customFile.customFileType),
      description: customFile.description,
    };
  }

  export function mapToCreateRequestApiValue(customFile: CustomFileDetailsData): CustomFileCreateRequestDTO {
    return {
      nature: CustomFileNatureEnum.convertToApiValue.getValue(customFile.nature),
      extension: CustomFileExtensionEnum.convertToApiValue.getValue(customFile.extension),
      customFileType: CustomFileTypeEnum.convertToApiValue.getValue(customFile.customFileType),
      name: customFile.name,
      description: customFile.description,
    };
  }

  export function mapToUpdateRequestApiValue(customFile: CustomFileDetailsData): CustomFileUpdateRequestDTO {
    return {
      uuidEntity: customFile.uuidEntity,
      name: customFile.name,
      description: customFile.description,
      customFileType: CustomFileTypeEnum.convertToApiValue.getValue(customFile.customFileType),
    };
  }

  export function mapToEntityLightData(customFile: CustomFileDetailsData): EntityLightData {
    return {
      uuidEntity: customFile.uuidEntity,
      name: customFile.name,
    };
  }
}
