import { compact } from 'lodash';
import { initializeQueries } from '../utils/base-query.model';
import { ClientSummaryResponseDTO } from '../../generated/models/models';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { ClientSummaryData } from './client-summary';

export namespace DropdownClientData {
  export function initializeFilterQueries(page: number, searchText?: string): ClientSummaryData.QueryRequest {
    return {
      ...initializeQueries<ClientSummaryData>({ key: 'name' }),
      page,
      textSearch: searchText || undefined,
    };
  }

  export function mapToMultiSelectItemData(client: ClientSummaryResponseDTO): MultiSelectItemData {
    return {
      value: client.uuidEntity,
      title: client.name,
      tooltip: compact([client.name, client.nameComp, client.nameComp2]).join(' - '),
    };
  }

  export function mapToSingleSelectItemData(client: ClientSummaryResponseDTO): SingleSelectItemData {
    return {
      value: { name: client.name, uuidEntity: client.uuidEntity },
      title: client.name,
      tooltip: compact([client.name, client.nameComp, client.nameComp2]).join(' - '),
    };
  }
}
