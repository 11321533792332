export enum StateName {
  SfxRouter = 'sfxRouter',
  SfxDialog = 'sfxDialog',
  Main = 'main',
  FlowManagement = 'flowManagement',
  Plan = 'plan',
  ReferentialFlow = 'referentialFlow',
  Dashboard = 'dashboard',
  Projects = 'projects',
  ProjectsFolder = 'projectsFolder',
  Library = 'library',
  DashboardCategories = 'dCategories',
  customFields = 'customFields',
  DefaultCategories = 'sCategories',
  ObjectManagement = 'objectManagement',
  TagsManagement = 'tagsManagement',
  Contributors = 'contributors',
  ContributorsDashboard = 'contributorsDashboard',
  UsersLibrary = 'usersLibrary',
  OperatorsLibrary = 'operatorsLibrary',
  TabletsDashboard = 'tabletsDashboard',
  LibraryRisks = 'libraryRisks',
  LibraryTags = 'libraryTags',
  Settings = 'settings',
  Profile = 'profile',
  Tablets = 'tablets',
  SettingsFamilies = 'sFamilies',
  SettingsAccount = 'settings-account',
  SettingsCustomFile = 'settingsCustomFile',
  SettingsCustomCell = 'settingsCustomCell',
  ClientNotebook = 'clientNotebook',
  AccessApi = 'accessApi',
  DashboardForms = 'dForms',
  DashboardLists = 'dLists',
  ScheduleTasks = 'dScheduleTasks',
  DashboardFieldFeedback = 'dFieldFeedback',
  LibraryForms = 'lForms',
  EquipmentsLibrary = 'equipmentsLibrary',
  Trash = 'trash',
  CustomStatus = 'customStatus',
  Signatures = 'signatures',
  SettingsReferences = 'sReferences',
  SettingsTrams = 'SettingsTrams',
  SettingsWordingConfiguration = 'settingsWordingConfiguration',
  FlowContent = 'flowContent',
  StepContent = 'stepContent',
  BlockContent = 'blockContent',
  BlockRhpContent = 'blockRhpContent',
  BlockPrContent = 'blockPrContent',
  BlockMultiPrContent = 'blockMultiPrContent',
  BlockObjectContent = 'blockObjectContent',
  BlockEmmContent = 'blockEmmContent',
  BlockAdrContent = 'blockAdrContent',
  BlockDsiContent = 'blockDsiContent',
  BlockFormContent = 'blockFormContent',
  BlockTxtContent = 'blockTxtContent',
  BlockMobileVueContent = 'blockMobileVueContent',
  ClosingFolder = 'closingFolder',
  AppSetting = 'appSetting',
  SettingsFolders = 'settingsFolders',
  SettingsBackups = 'settingsBackups',
  SettingsLogsPush = 'settingsLogsPush',
  LibraryDocuments = 'libraryDocuments',
  DashboardDocuments = 'dashboardDocuments',
  RubricContent = 'rubricContent',
  Form = 'form',
  DashboardMetrics = 'dashboardMetrics',
  Share = 'share',
  SettingsKpis = 'settingsKpis',
  ExternalUsers = 'externalUsers',
  Schedule = 'schedule',

  OrganizationConfig = 'organizationConfig',
  SettingsCompanies = 'settingsCompanies',
}
