/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SortDto {
  attribute: string;
  direction: SortDto.DirectionEnum;
}
export namespace SortDto {
  export type DirectionEnum = 'ASC' | 'DESC';
  export const DirectionEnum = {
    ASC: 'ASC' as DirectionEnum,
    DESC: 'DESC' as DirectionEnum,
  };
}
