import { EntityLightData } from '../utils/entity-light-data';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomFileNatureEnum } from '../../enums';
import { CustomFileLightDTO } from '../../generated/models/customFileLightDTO';
import { CustomFileTypeEnum } from '../../enums/custom-file/custom-file-type.enum';

export interface CustomFileLightData {
  uuidEntity: string;
  name: string;
  customFileOrder: number;
  customFileNature: CustomFileNatureEnum;
  customFileType: CustomFileTypeEnum;
  haveChildren: boolean;
}

export namespace CustomFileLightData {
  export function mapFromApiValue(customFile: CustomFileLightDTO): CustomFileLightData {
    return {
      name: customFile.name,
      uuidEntity: customFile.uuidEntity,
      customFileOrder: customFile.customFileOrder,
      customFileNature: CustomFileNatureEnum.convertFromApiValue.getValue(customFile.customFileNature),
      customFileType: CustomFileTypeEnum.convertFromApiValue.getValue(customFile.customFileType),
      haveChildren: customFile.haveChildren,
    };
  }

  export function mapToEntityLightData(customFile: CustomFileLightDTO): EntityLightData {
    return {
      uuidEntity: customFile.uuidEntity,
      name: customFile.name,
    };
  }

  export function mapToSingleSelectForFileProcessingModal(customFile: CustomFileLightDTO): SingleSelectItemData<CustomFileLightDTO> {
    return {
      value: customFile,
      title: customFile.name,
      disabled: !customFile.haveChildren,
      tooltip: customFile?.description,
      subtitle: !customFile.haveChildren
        ? 'fileProcessing.modals.models.error.haveNoChildren'
        : customFile.customFileNature.length && CustomFileNatureEnum.toString.getValue(CustomFileNatureEnum.convertFromApiValue.getValue(customFile.customFileNature)),
    };
  }

  export function mapToSingleSelectData(customFile: CustomFileLightDTO): SingleSelectItemData<CustomFileLightData> {
    return {
      value: CustomFileLightData.mapFromApiValue(customFile),
      title: customFile.name,
      tooltip: customFile?.description,
      subtitle: customFile.customFileNature.length && CustomFileNatureEnum.toString.getValue(CustomFileNatureEnum.convertFromApiValue.getValue(customFile.customFileNature)),
    };
  }
}
