import { Action, createReducer, on } from '@ngrx/store';

import { MarginData, OffsetData, TramSummaryData } from '../../../../../core/models';
import { TramTypeEnum } from '../../../../../core/enums';
import * as fromTramDialogActions from './tram-dialog.actions';

export interface TramDialogState {
  saveLoading: boolean;
  tramDetails: TramSummaryData;
}

export const initialTramDialogState = () => ({
  saveLoading: false,
  tramDetails: { margin: {} as MarginData, numPage: {} as OffsetData, naturePage: TramTypeEnum.DEFAULT } as TramSummaryData,
  filesToUpload: undefined,
});

const reducer = createReducer<TramDialogState>(
  initialTramDialogState(),
  on(fromTramDialogActions.createTram, fromTramDialogActions.updateTram, (state): TramDialogState => ({ ...state, saveLoading: true })),
  on(
    fromTramDialogActions.createTramFail,
    fromTramDialogActions.updateTramFail,
    fromTramDialogActions.updateTramSuccess,
    fromTramDialogActions.createTramSuccess,
    (state): TramDialogState => ({ ...state, saveLoading: false }),
  ),
  on(
    fromTramDialogActions.createTramSuccess,
    (state, { tram: tram, addNewOne }): TramDialogState => ({
      ...state,
      tramDetails: addNewOne ? { ...state.tramDetails, pageType: undefined } : tram,
    }),
  ),
  on(
    fromTramDialogActions.loadTramDetailDataSuccess,
    (state, { tramDetails: referenceDetails }): TramDialogState => ({ ...state, tramDetails: referenceDetails, saveLoading: false }),
  ),
);

export function tramDialogReducer(state: TramDialogState | undefined, action: Action): TramDialogState {
  return reducer(state, action);
}
