/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';
import { SortDto } from './sortDto';

export interface PageFlowForTechnicalObjectRequest {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  nature: PageFlowForTechnicalObjectRequest.NatureEnum;
  content?: Array<string>;
  familiesUuidEntity?: Array<string>;
  projectUuidEntity?: string;
  dateFilterRequest?: DateFilterRequest;
}
export namespace PageFlowForTechnicalObjectRequest {
  export type NatureEnum = 'CONTENT_RELATION' | 'HISTORIC_RELATION';
  export const NatureEnum = {
    CONTENTRELATION: 'CONTENT_RELATION' as NatureEnum,
    HISTORICRELATION: 'HISTORIC_RELATION' as NatureEnum,
  };
}
