import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as fromFlowPlanActions from './plan-dialog.actions';

import { MilestoneSummaryData } from '../../../../../../../core/models/plan/milestone-summary-data';
import { ElementPlanToImportData, FlowDetailsData, PlanSummaryData } from '../../../../../../../core/models';
import { PlanScaleEnum } from '../../../../../../../core/enums';

const adapter: EntityAdapter<Partial<ElementPlanToImportData>> = createEntityAdapter<Partial<ElementPlanToImportData>>({
  selectId: item => item.uuidEntity,
});

export interface PlanDetailsDialogState {
  planDetails: PlanSummaryData;
  milestoneDetails: MilestoneSummaryData;
  saveLoading: boolean;
}

const initialPlanDetailsDialogState = (): PlanDetailsDialogState => ({
  planDetails: { scale: PlanScaleEnum.Temporal } as PlanSummaryData,
  milestoneDetails: {} as MilestoneSummaryData,
  saveLoading: false,
});

export interface ImportPlanState extends EntityState<ElementPlanToImportData> {
  ids: string[];
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  disabledFilteredTotalCount: number;
  reset: boolean;
}

export const initialImportPlanState = (): ImportPlanState =>
  adapter.getInitialState<ImportPlanState>({
    ids: [],
    entities: {},
    loading: false,
    totalCount: undefined,
    filteredTotalCount: undefined,
    disabledFilteredTotalCount: undefined,
    reset: false,
  });

export interface PlanDialogState {
  planState: PlanDetailsDialogState;
  importPlanState: ImportPlanState;
  flowDetails: FlowDetailsData;
  assignmentSaveLoading: boolean;
}

export const initialPlanDialogState = (): PlanDialogState => ({
  planState: initialPlanDetailsDialogState(),
  importPlanState: initialImportPlanState(),
  flowDetails: {} as FlowDetailsData,
  assignmentSaveLoading: false,
});

const reducer = createReducer<PlanDialogState>(
  initialPlanDialogState(),
  on(fromFlowPlanActions.loadFlowDetailsSuccess, (state, { flowDetails }): PlanDialogState => ({ ...state, flowDetails })),
  on(fromFlowPlanActions.addPlan, fromFlowPlanActions.editPlan, (state, _): PlanDialogState => ({ ...state, planState: { ...state.planState, saveLoading: true } })),
  on(fromFlowPlanActions.addPlanFail, fromFlowPlanActions.editPlanFail, (state, _): PlanDialogState => ({ ...state, planState: { ...state.planState, saveLoading: false } })),
  on(fromFlowPlanActions.loadPlanDetails, (state, { plan }): PlanDialogState => ({ ...state, planState: { ...state.planState, planDetails: plan } })),
  on(fromFlowPlanActions.loadMileStoneSuccess, (state, { milestone }): PlanDialogState => ({ ...state, planState: { ...state.planState, milestoneDetails: milestone } })),
  on(fromFlowPlanActions.loadImportItems, (state): PlanDialogState => ({ ...state, importPlanState: { ...state.importPlanState, loading: true } })),
  on(
    fromFlowPlanActions.loadImportItemsSuccess,
    (state, { rows, totalCount, filteredTotalCount, disabledFilteredTotalCount, reset }): PlanDialogState => {
      const newState: ImportPlanState = { ...state.importPlanState, loading: false, reset, totalCount, filteredTotalCount, disabledFilteredTotalCount };

      return { ...state, importPlanState: reset ? adapter.setAll(rows, newState) : adapter.addMany(rows, newState) };
    },
  ),
  on(fromFlowPlanActions.loadImportItemsFails, (state): PlanDialogState => ({ ...state, importPlanState: { ...state.importPlanState, loading: false } })),
);

export function PlanDialogReducer(state: PlanDialogState | undefined, action: Action): PlanDialogState {
  return reducer(state, action);
}

export const selectAllItems = adapter.getSelectors().selectAll;
