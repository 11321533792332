import { SfxMap } from '../../utils/enum-utils';

export enum FormNavbarItemTypeEnum {
  Rubric = 1,
  Question = 2,
}

export namespace FormNavbarItemTypeEnum {
  export const toString: SfxMap<FormNavbarItemTypeEnum, string> = new SfxMap([
    [FormNavbarItemTypeEnum.Rubric, 'rubrics.rubric'],
    [FormNavbarItemTypeEnum.Question, 'questions.question'],
  ]);

  export const tooltip: SfxMap<FormNavbarItemTypeEnum, string> = new SfxMap([
    [FormNavbarItemTypeEnum.Rubric, 'rubrics.addRubric'],
    [FormNavbarItemTypeEnum.Question, 'questions.addQuestion'],
  ]);
}
