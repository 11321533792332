/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WordingConfigurationFieldResponseDTO {
  actionKey: WordingConfigurationFieldResponseDTO.ActionKeyEnum;
  color: string;
  icon: string;
  tooltip: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  value: string;
}
export namespace WordingConfigurationFieldResponseDTO {
  export type ActionKeyEnum =
    | 'PREL'
    | 'BPO'
    | 'BPE'
    | 'AUTRE'
    | 'TEMP'
    | 'BPA'
    | 'DIFFUSION_BPA'
    | 'BPE_VALID'
    | 'CLIENT_RETURN_BPA'
    | 'BPA_VALID'
    | 'BPA_REFUSED'
    | 'CLIENT_RETURN_BPO'
    | 'DIFFUSION_BPO'
    | 'VAO'
    | 'VSO'
    | 'VSOSR'
    | 'MODIFICATION_BPA'
    | 'VSO_BPA'
    | 'VAO_BPA'
    | 'MODIFICATION_BPO';
  export const ActionKeyEnum = {
    PREL: 'PREL' as ActionKeyEnum,
    BPO: 'BPO' as ActionKeyEnum,
    BPE: 'BPE' as ActionKeyEnum,
    AUTRE: 'AUTRE' as ActionKeyEnum,
    TEMP: 'TEMP' as ActionKeyEnum,
    BPA: 'BPA' as ActionKeyEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as ActionKeyEnum,
    BPEVALID: 'BPE_VALID' as ActionKeyEnum,
    CLIENTRETURNBPA: 'CLIENT_RETURN_BPA' as ActionKeyEnum,
    BPAVALID: 'BPA_VALID' as ActionKeyEnum,
    BPAREFUSED: 'BPA_REFUSED' as ActionKeyEnum,
    CLIENTRETURNBPO: 'CLIENT_RETURN_BPO' as ActionKeyEnum,
    DIFFUSIONBPO: 'DIFFUSION_BPO' as ActionKeyEnum,
    VAO: 'VAO' as ActionKeyEnum,
    VSO: 'VSO' as ActionKeyEnum,
    VSOSR: 'VSOSR' as ActionKeyEnum,
    MODIFICATIONBPA: 'MODIFICATION_BPA' as ActionKeyEnum,
    VSOBPA: 'VSO_BPA' as ActionKeyEnum,
    VAOBPA: 'VAO_BPA' as ActionKeyEnum,
    MODIFICATIONBPO: 'MODIFICATION_BPO' as ActionKeyEnum,
  };
}
