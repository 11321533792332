import { EntityLightData } from '../utils/entity-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { TechnicalObjectDetailsResponseDTO, TechnicalObjectCreateRequestDTO, TechnicalObjectUpdateRequestDTO } from '../../generated/models/models';
import { TechnicalObjectNatureEnum } from '../../enums';

export interface TechnicalObjectDetailsData {
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  name: string;
  description: string;
  nature: TechnicalObjectNatureEnum;
  refInt: string;
  refExt: string;
  customStatus: CustomStatusLightData;
  labelFamily: EntityLightData;
  applicability: EntityLightData;
  createDate: Date;
  modificationDate: Date;
  nodeOrder: number;
  parentUuidEntity?: string;
}

export namespace TechnicalObjectDetailsData {
  export function mapFromApiValue(technicalObjectDetails: TechnicalObjectDetailsResponseDTO): TechnicalObjectDetailsData {
    return {
      uuid: technicalObjectDetails.uuid,
      uuidType: technicalObjectDetails.uuidType,
      uuidEntity: technicalObjectDetails.uuidEntity,
      name: technicalObjectDetails.name,
      description: technicalObjectDetails.description,
      nature: technicalObjectDetails.nature && TechnicalObjectNatureEnum.convertFromApiValue.getValue(technicalObjectDetails.nature),
      refInt: technicalObjectDetails.refInt,
      refExt: technicalObjectDetails.refExt,
      customStatus: technicalObjectDetails.customStatus && CustomStatusLightData.mapFromApiValue(technicalObjectDetails.customStatus),
      labelFamily: technicalObjectDetails.labelFamily && LabelFamilyLightData.mapToEntityLightData(technicalObjectDetails.labelFamily),
      applicability: technicalObjectDetails.applicability && LabelApplicabilityLightData.mapToEntityLightData(technicalObjectDetails.applicability),
      createDate: technicalObjectDetails.createDate,
      modificationDate: technicalObjectDetails.modificationDate,
      nodeOrder: technicalObjectDetails.nodeOrder,
    };
  }

  export function mapToCreateRequestApiValue(object: TechnicalObjectDetailsData): TechnicalObjectCreateRequestDTO {
    return {
      name: object.name,
      description: object.description,
      refInt: object.refInt,
      refExt: object.refExt,
      nature: TechnicalObjectNatureEnum.convertToApiValue.getValue(object.nature),
      labelFamilyUuidEntity: object.labelFamily && object.labelFamily.uuidEntity,
      applicabilityUuidEntity: object.applicability && object.applicability.uuidEntity,
      customStatusUuidEntity: object.customStatus && object.customStatus.uuidEntity,
      parentUuidEntity: object.parentUuidEntity || undefined,
    };
  }

  export function mapToUpdateRequestApiValue(technicalObject: TechnicalObjectDetailsData): TechnicalObjectUpdateRequestDTO {
    return {
      name: technicalObject.name,
      description: technicalObject.description,
      refInt: technicalObject.refInt,
      refExt: technicalObject.refExt,
      nature: TechnicalObjectNatureEnum.convertToApiValue.getValue(technicalObject.nature),
      labelFamilyUuidEntity: technicalObject.labelFamily && technicalObject.labelFamily.uuidEntity,
      applicabilityUuidEntity: technicalObject.applicability && technicalObject.applicability.uuidEntity,
      customStatusUuidEntity: technicalObject.customStatus && technicalObject.customStatus.uuidEntity,
      uuidEntity: technicalObject.uuidEntity,
    };
  }
}
