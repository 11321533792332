import { BlocEmmImportRequestDTO } from '../../generated/models/models';
import { BlockEquipmentToImportSummaryData } from './block-equipment-to-import-summary';

export interface EquipmentForImportData {
  blockUuidEntity: string;
  uuidEntity: string;
  quantity: number;
  unit: string;
  consumable: boolean;
}

export namespace EquipmentForImportData {
  export function mapToRequestApiValue(equipment: EquipmentForImportData): BlocEmmImportRequestDTO {
    return {
      blocUuidEntity: equipment.blockUuidEntity,
      elementEmmUuidEntity: equipment.uuidEntity,
      quantity: equipment.quantity,
      unit: equipment.unit,
      consumable: equipment.consumable,
    };
  }

  export function mapFromEmmElementSummaryData(blockUuidEntity: string, equipment: BlockEquipmentToImportSummaryData): EquipmentForImportData {
    return {
      blockUuidEntity,
      quantity: equipment.quantity,
      unit: equipment.unit || 'U',
      consumable: equipment.consumable,
      uuidEntity: equipment.uuidEntity,
    };
  }
}
