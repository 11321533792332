/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { TagLightDTO } from './tagLightDTO';
import { UserLightDTO } from './userLightDTO';

export interface FlowCustomDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  refInt: string;
  refExt: string;
  unlocked: boolean;
  flowReferentialLocal: boolean;
  typeFlow: FlowCustomDetailsResponseDTO.TypeFlowEnum;
  description: string;
  version: string;
  deleted: boolean;
  aipFlow: boolean;
  locationActivated: boolean;
  locationSite: string;
  locationArea: string;
  locationLocal: string;
  elementarySystem: string;
  functionalMarker: string;
  technicalObject: string;
  materialProperty: boolean;
  espn: boolean;
  eipsRanking: boolean;
  qualityRanking: string;
  regulation: boolean;
  regulationCode: FlowCustomDetailsResponseDTO.RegulationCodeEnum;
  secondaryCodeCoef: number;
  technicalOffer: string;
  categoryCode: string;
  customStatusFlow: CustomStatusLightDTO;
  createDate: Date;
  modificationDate: Date;
  startDate: Date;
  finishDate: Date;
  reporterUuideRef: string;
  assigneeUuideRef: string;
  refUuideActor1: string;
  refUuideActor2: string;
  refUuideActor3: string;
  supervision1: boolean;
  supervision2: boolean;
  supervision3: boolean;
  simplifiedSignatureMode: boolean;
  externalSupervisorName1: string;
  externalSupervisorName2: string;
  externalSupervisorName3: string;
  supervisionIntern1: boolean;
  supervisionIntern2: boolean;
  supervisionIntern3: boolean;
  internalSupervisorName1: string;
  internalSupervisorName2: string;
  internalSupervisorName3: string;
  sourceFlowRef: string;
  labelFamilyApplication: LabelFamilyLightDTO;
  labelFamilyProject: LabelFamilyLightDTO;
  flowBarColor: string;
  tags: Array<TagLightDTO>;
  estimatedStartDate: Date;
  estimatedEndDate: Date;
  achievedStartDate: Date;
  achievedEndDate: Date;
  hasFlowSource: boolean;
  reporterUser: UserLightDTO;
  assigneeUser: UserLightDTO;
  actor1: UserLightDTO;
  actor2: UserLightDTO;
  actor3: UserLightDTO;
  zc: boolean;
  earthquakeRisk: boolean;
  earthquakeADR: boolean;
  earthquakeParade: boolean;
  revisionMode: boolean;
  canEditSupervision: boolean;
  modificationStatus: FlowCustomDetailsResponseDTO.ModificationStatusEnum;
  internalSupervised?: boolean;
  externalSupervised?: boolean;
}
export namespace FlowCustomDetailsResponseDTO {
  export type TypeFlowEnum =
    | 'GENERIC'
    | 'REFERENTIAL'
    | 'TEMPLATE'
    | 'SPECIFIC'
    | 'GROUP'
    | 'LIST_EMM'
    | 'LIST_ADR'
    | 'FLOW_FORM'
    | 'GROUP_REFERENTIAL'
    | 'LOCAL_REFERENTIAL'
    | 'REFERENTIAL_GENERIC'
    | 'REFERENTIAL_INTERVENTION';
  export const TypeFlowEnum = {
    GENERIC: 'GENERIC' as TypeFlowEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeFlowEnum,
    TEMPLATE: 'TEMPLATE' as TypeFlowEnum,
    SPECIFIC: 'SPECIFIC' as TypeFlowEnum,
    GROUP: 'GROUP' as TypeFlowEnum,
    LISTEMM: 'LIST_EMM' as TypeFlowEnum,
    LISTADR: 'LIST_ADR' as TypeFlowEnum,
    FLOWFORM: 'FLOW_FORM' as TypeFlowEnum,
    GROUPREFERENTIAL: 'GROUP_REFERENTIAL' as TypeFlowEnum,
    LOCALREFERENTIAL: 'LOCAL_REFERENTIAL' as TypeFlowEnum,
    REFERENTIALGENERIC: 'REFERENTIAL_GENERIC' as TypeFlowEnum,
    REFERENTIALINTERVENTION: 'REFERENTIAL_INTERVENTION' as TypeFlowEnum,
  };
  export type RegulationCodeEnum = 'RCCM' | 'CODAP' | 'CODETI';
  export const RegulationCodeEnum = {
    RCCM: 'RCCM' as RegulationCodeEnum,
    CODAP: 'CODAP' as RegulationCodeEnum,
    CODETI: 'CODETI' as RegulationCodeEnum,
  };
  export type ModificationStatusEnum = 'DISABLED' | 'ENABLED' | 'WARNING';
  export const ModificationStatusEnum = {
    DISABLED: 'DISABLED' as ModificationStatusEnum,
    ENABLED: 'ENABLED' as ModificationStatusEnum,
    WARNING: 'WARNING' as ModificationStatusEnum,
  };
}
