import { FlowUpdateCustomStatusRequestDTO } from '../../generated/models/models';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

export interface FlowCustomStatusForUpdate {
  customStatus: CustomStatusLightData;
  projectUuidEntity: string;
  flowUuidEntity?: string;
  comment?: string;
  isEntityCustomStatus?: boolean;
}

export namespace FlowCustomStatusForUpdate {
  export function mapToUpdateRequestApiValue(flow: FlowCustomStatusForUpdate): FlowUpdateCustomStatusRequestDTO {
    return {
      uuidEntity: flow.flowUuidEntity,
      customStatusFlowUuidEntity: flow.customStatus?.uuidEntity,
      comment: flow.comment,
    };
  }
}
