/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AppConfigResponseDTO } from './appConfigResponseDTO';
import { ApplicationDetailsResponseDTO } from './applicationDetailsResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { ProjectDetailsResponseDTO } from './projectDetailsResponseDTO';

export interface LabelFamilyDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  createDate: Date;
  modificationDate: Date;
  fullName: string;
  color: string;
  logo: string;
  deleted: boolean;
  readonly: boolean;
  nature: LabelFamilyDetailsResponseDTO.NatureEnum;
  type: LabelFamilyDetailsResponseDTO.TypeEnum;
  qualityReference: string;
  application: ApplicationDetailsResponseDTO;
  project: ProjectDetailsResponseDTO;
  appConfig: AppConfigResponseDTO;
  labelFamilyProvenance: LabelFamilyDetailsResponseDTO.LabelFamilyProvenanceEnum;
  relativeNature: LabelFamilyDetailsResponseDTO.RelativeNatureEnum;
  priority: number;
  familyParent: LabelFamilyLightDTO;
  childs?: Array<LabelFamilyLightDTO>;
}
export namespace LabelFamilyDetailsResponseDTO {
  export type NatureEnum =
    | 'ADR'
    | 'EMM'
    | 'TEAM'
    | 'DOCUMENT'
    | 'FLOW'
    | 'FORM'
    | 'RHPFUNCTION'
    | 'LIST'
    | 'DEVICE'
    | 'PROJECT'
    | 'HIERARCHY'
    | 'SKILL'
    | 'TECHNICAL_OBJECT'
    | 'LEGAL'
    | 'CONTRAT'
    | 'ACTIVITY_DOMAIN';
  export const NatureEnum = {
    ADR: 'ADR' as NatureEnum,
    EMM: 'EMM' as NatureEnum,
    TEAM: 'TEAM' as NatureEnum,
    DOCUMENT: 'DOCUMENT' as NatureEnum,
    FLOW: 'FLOW' as NatureEnum,
    FORM: 'FORM' as NatureEnum,
    RHPFUNCTION: 'RHPFUNCTION' as NatureEnum,
    LIST: 'LIST' as NatureEnum,
    DEVICE: 'DEVICE' as NatureEnum,
    PROJECT: 'PROJECT' as NatureEnum,
    HIERARCHY: 'HIERARCHY' as NatureEnum,
    SKILL: 'SKILL' as NatureEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as NatureEnum,
    LEGAL: 'LEGAL' as NatureEnum,
    CONTRAT: 'CONTRAT' as NatureEnum,
    ACTIVITYDOMAIN: 'ACTIVITY_DOMAIN' as NatureEnum,
  };
  export type TypeEnum = 'APPLICATION' | 'PROJECT' | 'CONFIGURATION';
  export const TypeEnum = {
    APPLICATION: 'APPLICATION' as TypeEnum,
    PROJECT: 'PROJECT' as TypeEnum,
    CONFIGURATION: 'CONFIGURATION' as TypeEnum,
  };
  export type LabelFamilyProvenanceEnum = 'CONFIGURATION' | 'LOCAL';
  export const LabelFamilyProvenanceEnum = {
    CONFIGURATION: 'CONFIGURATION' as LabelFamilyProvenanceEnum,
    LOCAL: 'LOCAL' as LabelFamilyProvenanceEnum,
  };
  export type RelativeNatureEnum = 'PARENT' | 'CHILD';
  export const RelativeNatureEnum = {
    PARENT: 'PARENT' as RelativeNatureEnum,
    CHILD: 'CHILD' as RelativeNatureEnum,
  };
}
