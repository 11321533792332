import { Observable, of } from 'rxjs';
import { MultiSelectItemData, SingleSelectItemData } from '../../modules/shared/components/select-v2/utils/select-item-data';
import { AppConfigUpdateRequestDTO, UserDTO } from '../generated/models/models';
import { SfxMap } from '../utils/enum-utils';

// Note: this list is Ordered by role weight < Desc >, pay attention when changing the order!
export enum UserRightEnum {
  AdminSiteFlow = 1,
  AdminAppGlobal,
  AdminApp,
  UserAdvanced,
  NormalUser,
  UserOperator,
  SimpleUser,
  Anonymous,
  UserMobile,
  UserExternApiAccess,
  UserGenericForm,
  UserModelForm,
  UserManageDiffusion,
  UserContractor,
  OperatorContractor,
  DeviceContractor,
}

export namespace UserRightEnum {
  export const rolesWhereOrganizationApplicabilityCanBeModified = [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal];
  export const rolesAsAdmins = [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal];
  export const rolesAsAdminsAndAdvanced = [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced];
  export const rolesWhereUserCanEditApplicability = [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal];
  export const referencesRoles = [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced];
  export const rolesWhereAdditionalRoleCanBeManaged = [UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor];

  export const noReorganizedRoles = [
    UserRightEnum.AdminSiteFlow,
    UserRightEnum.AdminApp,
    UserRightEnum.AdminAppGlobal,
    UserRightEnum.UserContractor,
    UserRightEnum.OperatorContractor,
    UserRightEnum.DeviceContractor,
  ];

  export const contractors = [UserRightEnum.UserContractor, UserRightEnum.OperatorContractor, UserRightEnum.DeviceContractor];

  const values: UserRightEnum[] = [UserRightEnum.SimpleUser, UserRightEnum.NormalUser, UserRightEnum.UserAdvanced, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal];

  const displayedConfigValues = [UserRightEnum.SimpleUser, UserRightEnum.NormalUser, UserRightEnum.UserAdvanced, UserRightEnum.AdminApp];
  const nonAdminValues = [UserRightEnum.SimpleUser, UserRightEnum.NormalUser, UserRightEnum.UserAdvanced];

  export const convertFromApiValue = new SfxMap<UserDTO.AuthoritiesEnum, UserRightEnum>(
    [
      [UserDTO.AuthoritiesEnum.ADMIN, UserRightEnum.AdminSiteFlow],
      [UserDTO.AuthoritiesEnum.ADMINAPP, UserRightEnum.AdminApp],
      [UserDTO.AuthoritiesEnum.USER, UserRightEnum.NormalUser],
      [UserDTO.AuthoritiesEnum.USERADVANCED, UserRightEnum.UserAdvanced],
      [UserDTO.AuthoritiesEnum.USERBASIC, UserRightEnum.SimpleUser],
      [UserDTO.AuthoritiesEnum.USEROPERATOR, UserRightEnum.UserOperator],
      [UserDTO.AuthoritiesEnum.ANONYMOUS, UserRightEnum.Anonymous],
      [UserDTO.AuthoritiesEnum.USERMOBILE, UserRightEnum.UserMobile],
      [UserDTO.AuthoritiesEnum.USEREXTERNALAPIACCESS, UserRightEnum.UserExternApiAccess],
      [UserDTO.AuthoritiesEnum.ADMINAPPGLOBAL, UserRightEnum.AdminAppGlobal],
      [UserDTO.AuthoritiesEnum.QHSE, UserRightEnum.UserGenericForm],
      [UserDTO.AuthoritiesEnum.USERMODELFORM, UserRightEnum.UserModelForm],
      [UserDTO.AuthoritiesEnum.MANAGEDIFFUSION, UserRightEnum.UserManageDiffusion],
      ['CO_CONTRACTOR_USER' as UserDTO.AuthoritiesEnum, UserRightEnum.UserContractor], // Review: makeModels
      ['CO_CONTRACTOR_OPERATOR' as UserDTO.AuthoritiesEnum, UserRightEnum.OperatorContractor], // Review: makeModels
      ['CO_CONTRACTOR_DEVICE' as UserDTO.AuthoritiesEnum, UserRightEnum.DeviceContractor], // Review: makeModels
    ],
    null,
  );

  export const convertToApiValue = new SfxMap<UserRightEnum, UserDTO.AuthoritiesEnum>([
    [UserRightEnum.AdminSiteFlow, UserDTO.AuthoritiesEnum.ADMIN],
    [UserRightEnum.AdminApp, UserDTO.AuthoritiesEnum.ADMINAPP],
    [UserRightEnum.NormalUser, UserDTO.AuthoritiesEnum.USER],
    [UserRightEnum.UserAdvanced, UserDTO.AuthoritiesEnum.USERADVANCED],
    [UserRightEnum.SimpleUser, UserDTO.AuthoritiesEnum.USERBASIC],
    [UserRightEnum.UserOperator, UserDTO.AuthoritiesEnum.USEROPERATOR],
    [UserRightEnum.Anonymous, UserDTO.AuthoritiesEnum.ANONYMOUS],
    [UserRightEnum.UserMobile, UserDTO.AuthoritiesEnum.USERMOBILE],
    [UserRightEnum.UserExternApiAccess, UserDTO.AuthoritiesEnum.USEREXTERNALAPIACCESS],
    [UserRightEnum.AdminAppGlobal, UserDTO.AuthoritiesEnum.ADMINAPPGLOBAL],
    [UserRightEnum.UserGenericForm, UserDTO.AuthoritiesEnum.QHSE],
    [UserRightEnum.UserModelForm, UserDTO.AuthoritiesEnum.USERMODELFORM],
    [UserRightEnum.UserManageDiffusion, UserDTO.AuthoritiesEnum.MANAGEDIFFUSION],
    [UserRightEnum.UserContractor, 'CO_CONTRACTOR_USER' as UserDTO.AuthoritiesEnum], // Review: makeModels
    [UserRightEnum.OperatorContractor, 'CO_CONTRACTOR_OPERATOR' as UserDTO.AuthoritiesEnum], // Review: makeModels
    [UserRightEnum.DeviceContractor, 'CO_CONTRACTOR_DEVICE' as UserDTO.AuthoritiesEnum], // Review: makeModels
  ]);

  export const convertToAuthoritiesBlackListApiValue = new SfxMap<UserRightEnum, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum>([
    [UserRightEnum.AdminSiteFlow, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.ADMIN],
    [UserRightEnum.AdminApp, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.ADMINAPP],
    [UserRightEnum.NormalUser, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USER],
    [UserRightEnum.UserAdvanced, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USERADVANCED],
    [UserRightEnum.SimpleUser, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USERBASIC],
    [UserRightEnum.UserOperator, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USEROPERATOR],
    [UserRightEnum.Anonymous, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.ANONYMOUS],
    [UserRightEnum.UserMobile, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USERMOBILE],
    [UserRightEnum.UserExternApiAccess, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.USEREXTERNALAPIACCESS],
    [UserRightEnum.AdminAppGlobal, AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum.ADMINAPPGLOBAL],
    [UserRightEnum.UserContractor, 'CO_CONTRACTOR_USER' as UserDTO.AuthoritiesEnum], // Review: makeModels
    [UserRightEnum.OperatorContractor, 'CO_CONTRACTOR_OPERATOR' as UserDTO.AuthoritiesEnum], // Review: makeModels
    [UserRightEnum.DeviceContractor, 'CO_CONTRACTOR_DEVICE' as UserDTO.AuthoritiesEnum], // Review: makeModels
  ]);

  export const convertFromParamsValue = new SfxMap<string, UserRightEnum>([
    ['simple', UserRightEnum.SimpleUser],
    ['user', UserRightEnum.NormalUser],
    ['advanced', UserRightEnum.UserAdvanced],
    ['adminApp', UserRightEnum.AdminApp],
    ['adminAppGlobal', UserRightEnum.AdminAppGlobal],
  ]);

  export const convertToParamsValue = new SfxMap<UserRightEnum, string>([
    [UserRightEnum.SimpleUser, 'simple'],
    [UserRightEnum.NormalUser, 'user'],
    [UserRightEnum.UserAdvanced, 'advanced'],
    [UserRightEnum.AdminApp, 'adminApp'],
    [UserRightEnum.AdminAppGlobal, 'adminAppGlobal'],
  ]);

  const key = 'users.filters.';
  export const toString = new SfxMap<UserRightEnum, string>([
    [UserRightEnum.SimpleUser, key + 'simple'],
    [UserRightEnum.NormalUser, key + 'normal'],
    [UserRightEnum.UserAdvanced, key + 'advanced'],
    [UserRightEnum.AdminApp, key + 'administrator'],
    [UserRightEnum.AdminSiteFlow, key + 'adminSiteflow'],
    [UserRightEnum.AdminAppGlobal, key + 'globalAdministrator'],
    [UserRightEnum.UserContractor, key + 'contractor'],
    [UserRightEnum.OperatorContractor, key + 'contractor'],
    [UserRightEnum.DeviceContractor, key + 'contractor'],
  ]);

  export const multiSelectItemsConfig = (): Observable<MultiSelectItemData<UserRightEnum>[]> =>
    of(displayedConfigValues.map(value => ({ value, title: toString.getValue(value) })));

  export const selectItemsConfigResolver = (): Observable<SingleSelectItemData<UserRightEnum>[]> =>
    of(displayedConfigValues.map(value => ({ value, title: toString.getValue(value) })));

  export const nonAdminValuesResolver = (): Observable<SingleSelectItemData<UserRightEnum>[]> => of(nonAdminValues.map(value => ({ title: toString.getValue(value), value })));

  export const comparePredicate = (node: SingleSelectItemData<UserRightEnum>, item: UserRightEnum) => node.value === item;

  export const itemsResolverFilter = (userAuthorities: UserRightEnum[]): Observable<SingleSelectItemData<UserRightEnum>[]> =>
    of(
      (userAuthorities?.length ? values.filter(role => userAuthorities.every(userRole => userRole <= role)) : values).map(role => ({
        value: role,
        title: toString.getValue(role),
      })),
    );
}
