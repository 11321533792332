import { sortBy } from 'lodash';
import { DateFrequencyEnum } from '../../enums';
import {
  KpisResponseDTOKpiAudienceResponseDTO,
  KpisResponseDTOKpiFieldResponseDTO,
  KpisResponseDTOKpiFlowResponseDTO,
  KpisResponseDTOKpiFormResponseDTO,
  KpisResponseDTOKpiLicencesResponseDTO,
  KpisResponseDTOKpiOfficeResponseDTO,
  KpisResponseDTOKpiPreparationResponseDTO,
} from '../../generated/models/models';
import { LineChartDatasets } from './line-chart-datasets';

export interface ChartDataDetails {
  scale: ScaleDetails[];
  data: LineChartDatasets[];
}
interface ScaleDetails {
  displayFormat: string;
  date: Date;
}

export namespace ChartDataDetails {
  export const initialize: ChartDataDetails = { scale: [], data: [] };

  export function mapPreparationMetrics(data: KpisResponseDTOKpiPreparationResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapPreparationDatasets(sortedData),
    };
  }

  export function mapAudienceMetrics(data: KpisResponseDTOKpiAudienceResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapAudienceDatasets(sortedData),
    };
  }

  export function mapOfficeMetrics(data: KpisResponseDTOKpiOfficeResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapOfficeDatasets(sortedData),
    };
  }

  export function mapFieldMetrics(data: KpisResponseDTOKpiFieldResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapFieldDatasets(sortedData),
    };
  }

  export function mapFormMetrics(data: KpisResponseDTOKpiFormResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapFormDatasets(sortedData),
    };
  }

  export function mapFlowMetrics(data: KpisResponseDTOKpiFlowResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapFlowDatasets(sortedData),
    };
  }

  export function mapLicensesDetailsMetrics(data: KpisResponseDTOKpiLicencesResponseDTO): ChartDataDetails {
    const interval = DateFrequencyEnum.mapFromApiValue.getValue(data.interval);
    const sortedData = sortBy(data.kpis, 'startAt');

    return {
      scale: sortedData.map(kpi => ({
        displayFormat: DateFrequencyEnum.toDisplayFormat.getValue(interval),
        date: kpi.startAt,
      })),
      data: LineChartDatasets.mapLicensesDetailsDatasets(sortedData),
    };
  }
}
