/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentFlowFindLazyByProjectRequestDTO } from './documentFlowFindLazyByProjectRequestDTO';
import { PageFlowRequest } from './pageFlowRequest';

export interface UpdateDocumentFlowProjectRequestDTO {
  categorySecondaryUuidEntity?: string;
  categoryUuidEntity?: string;
  clientCoverPage?: boolean;
  complementRefExt?: string;
  complementRefInt?: string;
  descriptionCoverPage?: string;
  documentFlowFindLazyByProjectRequest?: DocumentFlowFindLazyByProjectRequestDTO;
  dsiChildRendered?: boolean;
  exportLanguageOne?: UpdateDocumentFlowProjectRequestDTO.ExportLanguageOneEnum;
  exportLanguageTwo?: UpdateDocumentFlowProjectRequestDTO.ExportLanguageTwoEnum;
  glossary?: string;
  groupedResponses?: boolean;
  hideCoverPage?: boolean;
  hideIndexColumn?: boolean;
  hideMultiPrTitleOnSummary?: boolean;
  includeAnnexeInterlayer?: boolean;
  includeContributorsPage?: boolean;
  includeControlBlock?: boolean;
  includeEmmDoc?: boolean;
  includeEmptyOption?: boolean;
  includeFormDoc?: boolean;
  includeFormReferences?: boolean;
  includeInstructionBlock?: boolean;
  includeLinkedDocumentsInfos?: boolean;
  includeMultiPrObs?: boolean;
  includePhaseNumber?: boolean;
  includeRefForm?: boolean;
  includeResponseDoc?: boolean;
  includeRhContent?: boolean;
  includeSkeletonForm?: boolean;
  includeTxtContent?: boolean;
  labelFamilyUuidEntity: string;
  name: string;
  natureFile: UpdateDocumentFlowProjectRequestDTO.NatureFileEnum;
  natureIndice?: UpdateDocumentFlowProjectRequestDTO.NatureIndiceEnum;
  navigationConditions?: boolean;
  pageFlowRequest?: PageFlowRequest;
  preamble?: string;
  refExt?: string;
  refFlow?: string;
  refInt?: string;
  refResponsesList?: Array<string>;
  refUuideForm?: string;
  refUuideResponse?: string;
  responsesStrategy?: UpdateDocumentFlowProjectRequestDTO.ResponsesStrategyEnum;
  revisionOnMainIndices?: boolean;
  selectedElementsUuidEntities?: Array<string>;
  showAnnexes?: boolean;
  showLinkedEntitiesReferences?: boolean;
  showSkillsSummary?: boolean;
  subject?: string;
  uuidEntity: string;
}
export namespace UpdateDocumentFlowProjectRequestDTO {
  export type ExportLanguageOneEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageOneEnum = {
    FR: 'FR' as ExportLanguageOneEnum,
    EN: 'EN' as ExportLanguageOneEnum,
    DE: 'DE' as ExportLanguageOneEnum,
    IT: 'IT' as ExportLanguageOneEnum,
    NONE: 'NONE' as ExportLanguageOneEnum,
  };
  export type ExportLanguageTwoEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageTwoEnum = {
    FR: 'FR' as ExportLanguageTwoEnum,
    EN: 'EN' as ExportLanguageTwoEnum,
    DE: 'DE' as ExportLanguageTwoEnum,
    IT: 'IT' as ExportLanguageTwoEnum,
    NONE: 'NONE' as ExportLanguageTwoEnum,
  };
  export type NatureFileEnum =
    | 'PR'
    | 'EMM'
    | 'ADR'
    | 'DSI'
    | 'LDA'
    | 'LFA'
    | 'LCA'
    | 'PROJECT'
    | 'AUTRE'
    | 'RFI'
    | 'PFORM'
    | 'FFORM'
    | 'BFORM'
    | 'PREVIEW_FORM'
    | 'MULTI_FLOW_FORM'
    | 'LIST_ADR'
    | 'LIST_EMM'
    | 'LIST_ADR_FLOW'
    | 'LIST_EMM_FLOW'
    | 'MULTI_PR'
    | 'FIELD_FEEDBACK'
    | 'DIAGRAM';
  export const NatureFileEnum = {
    PR: 'PR' as NatureFileEnum,
    EMM: 'EMM' as NatureFileEnum,
    ADR: 'ADR' as NatureFileEnum,
    DSI: 'DSI' as NatureFileEnum,
    LDA: 'LDA' as NatureFileEnum,
    LFA: 'LFA' as NatureFileEnum,
    LCA: 'LCA' as NatureFileEnum,
    PROJECT: 'PROJECT' as NatureFileEnum,
    AUTRE: 'AUTRE' as NatureFileEnum,
    RFI: 'RFI' as NatureFileEnum,
    PFORM: 'PFORM' as NatureFileEnum,
    FFORM: 'FFORM' as NatureFileEnum,
    BFORM: 'BFORM' as NatureFileEnum,
    PREVIEWFORM: 'PREVIEW_FORM' as NatureFileEnum,
    MULTIFLOWFORM: 'MULTI_FLOW_FORM' as NatureFileEnum,
    LISTADR: 'LIST_ADR' as NatureFileEnum,
    LISTEMM: 'LIST_EMM' as NatureFileEnum,
    LISTADRFLOW: 'LIST_ADR_FLOW' as NatureFileEnum,
    LISTEMMFLOW: 'LIST_EMM_FLOW' as NatureFileEnum,
    MULTIPR: 'MULTI_PR' as NatureFileEnum,
    FIELDFEEDBACK: 'FIELD_FEEDBACK' as NatureFileEnum,
    DIAGRAM: 'DIAGRAM' as NatureFileEnum,
  };
  export type NatureIndiceEnum = 'NUMERIC_ZERO' | 'NUMERIC' | 'ALPHA' | 'NUMERIC_ALL_ZERO' | 'NUMERIC_ALL_ZERO_START_ZERO';
  export const NatureIndiceEnum = {
    NUMERICZERO: 'NUMERIC_ZERO' as NatureIndiceEnum,
    NUMERIC: 'NUMERIC' as NatureIndiceEnum,
    ALPHA: 'ALPHA' as NatureIndiceEnum,
    NUMERICALLZERO: 'NUMERIC_ALL_ZERO' as NatureIndiceEnum,
    NUMERICALLZEROSTARTZERO: 'NUMERIC_ALL_ZERO_START_ZERO' as NatureIndiceEnum,
  };
  export type ResponsesStrategyEnum = 'NONE' | 'ALL' | 'SELECTED';
  export const ResponsesStrategyEnum = {
    NONE: 'NONE' as ResponsesStrategyEnum,
    ALL: 'ALL' as ResponsesStrategyEnum,
    SELECTED: 'SELECTED' as ResponsesStrategyEnum,
  };
}
