import { BlocContentUpdateRequestDTO, BlocTxtDetailsResponseDTO, BlocTxtUpdateRequestDTO } from '../../generated/models/models';

export interface BlockTxtDetailsData {
  uuidEntity: string;
  content: string;
  documentView: boolean;
}

export namespace BlockTxtDetailsData {
  export function mapFromApiValue(blockTxt: BlocTxtDetailsResponseDTO): BlockTxtDetailsData {
    return {
      content: blockTxt.content,
      uuidEntity: blockTxt.uuidEntity,
      documentView: !blockTxt.internalTxt,
    };
  }

  export function mapToUpdateRequestApiValue(content: string, uuidEntity: string): BlocContentUpdateRequestDTO {
    return { content, uuidEntity };
  }

  export function mapToUpdateTxtRequestApiValue(documentView: boolean, uuidEntity: string): BlocTxtUpdateRequestDTO {
    return { internalTxt: !documentView, uuidEntity };
  }
}
