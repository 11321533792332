import { Observable, of } from 'rxjs';

import { DateUtils } from '../../utils/date.utils';
import { SfxMap } from '../../utils/enum-utils';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export namespace ScheduleFrequencyEnum {
  const monthKey = 'schedule.months.';
  export const toMonthString = new SfxMap<number, string>([
    [1, monthKey + 'january'],
    [2, monthKey + 'february'],
    [3, monthKey + 'march'],
    [4, monthKey + 'april'],
    [5, monthKey + 'may'],
    [6, monthKey + 'june'],
    [7, monthKey + 'july'],
    [8, monthKey + 'august'],
    [9, monthKey + 'september'],
    [10, monthKey + 'october'],
    [11, monthKey + 'november'],
    [12, monthKey + 'december'],
  ]);

  const monthExpressionKey = 'schedule.months.expression.';
  export const toExpressionMonthString = new SfxMap<number, string>([
    [1, monthExpressionKey + 'january'],
    [2, monthExpressionKey + 'february'],
    [3, monthExpressionKey + 'march'],
    [4, monthExpressionKey + 'april'],
    [5, monthExpressionKey + 'may'],
    [6, monthExpressionKey + 'june'],
    [7, monthExpressionKey + 'july'],
    [8, monthExpressionKey + 'august'],
    [9, monthExpressionKey + 'september'],
    [10, monthExpressionKey + 'october'],
    [11, monthExpressionKey + 'november'],
    [12, monthExpressionKey + 'december'],
  ]);
  export const comparePredicateMonths = (node: SingleSelectItemData<number>, item: number) => node.value === item;
  export const comparePredicateDays = (node: SingleSelectItemData<number>, item: number) => node.value === item;

  export const monthSelectedItemsValues = (): Observable<SingleSelectItemData<number>[]> =>
    of(
      Array.from({ length: 12 }, (_, i) => i + 1).map(month => ({
        value: month,
        title: toMonthString.getValue(month),
      })),
    );

  export const daysPerMonthSelectedItemsValues = (month: number) => (): Observable<SingleSelectItemData<number>[]> =>
    of(
      Array.from({ length: DateUtils.dayPerMonth(month) }, (_, i) => i + 1).map(day => ({
        value: day,
        title: day.toString(),
      })),
    );
}
