import { TabletSummaryData } from '../../models/tablets/tablet-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum TabletColumnEnum {
  Family = 'family',
  Name = 'name',
  BatteryLevel = 'batteryLevel',
  Position = 'position',
  Company = 'company',
  Status = 'status',
  Actions = 'actions',
}

export namespace TabletColumnEnum {
  export type tabletSortField = keyof TabletSummaryData;

  export const allColumns = [
    TabletColumnEnum.Family,
    TabletColumnEnum.Name,
    TabletColumnEnum.BatteryLevel,
    TabletColumnEnum.Position,
    TabletColumnEnum.Company,
    TabletColumnEnum.Status,
    TabletColumnEnum.Actions,
  ];

  export const columnsFiltered = (toExclude: Set<TabletColumnEnum>) => allColumns.filter((column: TabletColumnEnum) => !toExclude.has(column));

  export const importColumns = [TabletColumnEnum.Family, TabletColumnEnum.Name, TabletColumnEnum.Company, TabletColumnEnum.Status];

  const key = 'tablets.table.header.';
  export const toString = new SfxMap<TabletColumnEnum, string>([
    [TabletColumnEnum.Family, key + 'family'],
    [TabletColumnEnum.Name, key + 'name'],
    [TabletColumnEnum.BatteryLevel, key + 'batteryLevel'],
    [TabletColumnEnum.Position, key + 'position'],
    [TabletColumnEnum.Company, key + 'company'],
    [TabletColumnEnum.Status, key + 'status'],
    [TabletColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField = new SfxMap<TabletColumnEnum, tabletSortField>([
    [TabletColumnEnum.Family, 'group' as tabletSortField], // HACK: @wissem we will fix this later
    [TabletColumnEnum.Name, 'refExtern' as tabletSortField], // HACK: @wissem
    [TabletColumnEnum.BatteryLevel, 'batteryLevel'],
    [TabletColumnEnum.Status, 'deviceStatus' as tabletSortField], // HACK: @wissem
    [TabletColumnEnum.Position, 'position' as tabletSortField], // HACK: @wissem
  ]);
}
