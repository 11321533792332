import { HistorySharedLinkResponseContentDto } from '../../generated/models/historySharedLinkResponseContentDto';
import { HistorySharedLinkRequest } from '../../generated/models/historySharedLinkRequest';

import { BusinessClassNameEnum, ShareHistoryStatusEnum } from '../../enums';

import { DateUtils } from '../../utils/date.utils';

export interface ShareHistoryData {
  name: string;
  email: string;
  status: ShareHistoryStatusEnum;
  businessClass: BusinessClassNameEnum;
  createDate: string;
  expiredDate: string;
  uuidEntity: string;
}

export namespace ShareHistoryData {
  export function mapFromApiValue(data: HistorySharedLinkResponseContentDto): ShareHistoryData {
    return {
      name: data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : '',
      email: data.login,
      businessClass: BusinessClassNameEnum.convertFromApiValue.getValue(data.businessClass),
      createDate: DateUtils.toDateFormat(data.createdAt),
      expiredDate: DateUtils.toDateTimeFormat(data.dueAt),
      status: ShareHistoryStatusEnum.convertFromApiValue.getValue(data.status),
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToApiRequestValue(uuidEntity: string, businessClass: BusinessClassNameEnum): HistorySharedLinkRequest {
    return { businessClass: BusinessClassNameEnum.convertToApiValue.getValue(businessClass), refUuide: uuidEntity };
  }
}
