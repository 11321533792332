import { createAction, props } from '@ngrx/store';

import { AccessApiDetailsData } from '../../../../../../core/models';
import { DialogToasterData } from '../../../../../sfx-dialog/state/dialog-toaster-data';

export const setLoadingData = createAction('[accessApi] set loading state', props<{ saveLoading: boolean }>());

export const addAccessApi = createAction('[accessApi] add new category', props<{ accessApi: AccessApiDetailsData }>());
export const addAccessApiSuccess = createAction('[accessApi] add new accessApi success');
export const addAccessApiFail = createAction('[accessApi] add new accessApi fail', props<Error>());

export const updateAccessApi = createAction('[accessApi] update accessApi', props<{ accessApi: AccessApiDetailsData }>());
export const updateAccessApiSuccess = createAction('[accessApi] update accessApi success');
export const updateAccessApiFail = createAction('[accessApi] update accessApi fail', props<Error>());

export const getAccessApiDetailsSuccess = createAction('[accessApi] get accessApi details success', props<{ accessApi: AccessApiDetailsData }>());
export const getAccessApiDetailsFail = createAction('[accessApi] get accessApi details fail', props<DialogToasterData>());
