import { CompanyLightDTO, PageImportUserRequestDTO, UserSummaryResponseDTO } from '../../generated/models/models';
import { UserCustomSummaryResponseDTO } from '../../generated/models/userCustomSummaryResponseDTO';

import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { UserNatureEnum, UserTypeEnum, UserRoleEnum, InnerSortDirectionEnum, UserStatusEnum } from '../../enums';

export interface ContributorImportData {
  name: string;
  uuid: string;
  uuidEntity: string;
  teamRole: UserRoleEnum;
  status: UserStatusEnum;
  function: LabelFamilyLightData;
  hierarchies: LabelFamilyLightData[];
  group: EntityLightData;
  registerNumber: string;
  disabledRow?: boolean;
  selectedRow?: boolean;
  company: CompanyLightDTO;
}

export namespace ContributorImportData {
  const sortFields = ['name', 'group', 'function'];

  export interface QueryRequest extends BaseQuery<ContributorImportData> {
    page: number;
    size: number;
    applicabilityUuides?: string[];
    labelFamilyFunctionUuides?: string[];
    labelFamilyHierarchyUuides?: string[];
    labelFamilySkillUuides?: string[];
    activityDomainsUuides: string[];
    legals?: string[];
    contracts?: string[];
    textSearch?: string;
    userLabelFamilyTeamUuides?: string[];
    userNatures?: UserNatureEnum[];
    userType?: UserTypeEnum;
    projectUuidEntity?: string;
  }

  export function mapFromApiValue(response: UserCustomSummaryResponseDTO): ContributorImportData {
    const user = response.user;

    return {
      uuid: user.uuid,
      uuidEntity: user.uuidEntity,
      name: user.name,
      status: UserStatusEnum.convertFromApiValue.getValue(user.status),
      teamRole: user.type === UserSummaryResponseDTO.TypeEnum.OPERATOR ? UserRoleEnum.Operator : UserRoleEnum.Writer,
      function: user.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(user.labelFamilyFunction),
      hierarchies: user.labelFamilyHierarchies?.map(hierarchy => LabelFamilyLightData.mapFromApiValue(hierarchy, null, true)),
      group: user.labelFamilyTeam && LabelFamilyLightData.mapToEntityLightData(user.labelFamilyTeam),
      registerNumber: user.matricule,
      disabledRow: response.alreadyImported,
      selectedRow: response.alreadyImported,
      company: response?.user?.userRight?.company,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageImportUserRequestDTO {
    return {
      pageUserRequestDTO: {
        applicabilityUuides: queries.applicabilityUuides || undefined,
        labelFamilyFunctionUuides: queries.labelFamilyFunctionUuides || undefined,
        labelFamilyHierarchyUuides: queries.labelFamilyHierarchyUuides || undefined,
        labelFamilySkillUuides: queries.labelFamilySkillUuides || undefined,
        labelFamilyTeamUuides: queries.userLabelFamilyTeamUuides || undefined,
        activityDomainsUuides: queries.activityDomainsUuides || undefined,
        legalEntitiesUuides: queries.legals || undefined,
        contratTypeUuides: queries.contracts || undefined,
        page: queries.page,
        size: queries.size,
        sort: sortFields.includes(queries?.sort?.key)
          ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
          : undefined,
        textSearch: queries.textSearch || undefined,
        type: UserTypeEnum.convertToApiValue.getValue(queries.userType),
      },
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function initializeQueryRequest(
    queries: {
      applicabilities: string[];
      functions: string[];
      legals: string[];
      contracts: string[];
      groups: string[];
      hierarchies: string[];
    },
    skills: string[],
    searchText: string,
    type: string,
    domains: string[],
    sort: SortFilter<ContributorImportData>,
  ): QueryRequest {
    return {
      ...initializeQueries<ContributorImportData>(sort),
      textSearch: searchText || undefined,
      userLabelFamilyTeamUuides: queries && (queries.groups || []).length ? queries.groups : undefined,
      labelFamilyFunctionUuides: queries && (queries.functions || []).length ? queries.functions : undefined,
      labelFamilyHierarchyUuides: (queries.hierarchies || []).length ? queries.hierarchies : undefined,
      legals: (queries.legals || []).length ? queries.legals : undefined,
      contracts: (queries.contracts || []).length ? queries.contracts : undefined,
      activityDomainsUuides: (domains || []).length ? domains : undefined,
      labelFamilySkillUuides: (skills || []).length ? skills : undefined,
      userType: UserTypeEnum.convertFromParamsValue.getValue(type) || undefined,
      applicabilityUuides: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
    };
  }
}
