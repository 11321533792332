import { BlocDsiDetailsResponsesDTO, SignatoryLightDTO } from '../../generated/models/models';

import { SignatureNatureEnum, SignatureStatusEnum } from '../../enums';

export interface SignatureLightData {
  uuidEntity: string;
  signatureNature: SignatureNatureEnum;
  description?: string;
  signatureState?: SignatureStatusEnum;
}

export namespace SignatureLightData {
  export function mapFromApiValue(signature: SignatoryLightDTO, blockDsi: BlocDsiDetailsResponsesDTO): SignatureLightData {
    const nature = SignatureNatureEnum.convertFromApiValue.getValue(signature.natureSiganture);

    return {
      signatureNature: nature,
      uuidEntity: signature.uuidEntity,
      description: nature === SignatureNatureEnum.ControlTech && blockDsi.technicalControlDetails,
      signatureState: SignatureStatusEnum.convertFromApiValue.getValue(signature.signatureState),
    };
  }

  export function getSupervisorsName(nature: SignatureNatureEnum, blockDsi: BlocDsiDetailsResponsesDTO): string {
    if (SignatureNatureEnum.supervisorIntern1.includes(nature)) {
      return blockDsi.supervisionNameIntern1;
    }
    if (SignatureNatureEnum.supervisorIntern2.includes(nature)) {
      return blockDsi.supervisionNameIntern2;
    }
    if (SignatureNatureEnum.supervisorIntern3.includes(nature)) {
      return blockDsi.supervisionNameIntern3;
    }
    if (SignatureNatureEnum.supervisorExtern1.includes(nature)) {
      return blockDsi.supervisionNameExtern1;
    }
    if (SignatureNatureEnum.supervisorExtern2.includes(nature)) {
      return blockDsi.supervisionNameExtern2;
    }
    if (SignatureNatureEnum.supervisorExtern3.includes(nature)) {
      return blockDsi.supervisionNameExtern3;
    }

    return '';
  }
}
