import { SfxMap } from '../../utils/enum-utils';

export enum DocumentColumnEnum {
  Family = 'family',
  Name = 'name',
  RefIntern = 'refIntern',
  NameRefIntern = 'nameRerIntern',
  Index = 'index',
  BlockIndex = 'blockIndex',
  Status = 'status',
  Actions = 'actions',
  MobileAppActions = 'mobileAppActions',
  ProjectActions = 'projectActions',
}

export namespace DocumentColumnEnum {
  export const allColumns: DocumentColumnEnum[] = [
    DocumentColumnEnum.Family,
    DocumentColumnEnum.Name,
    DocumentColumnEnum.RefIntern,
    DocumentColumnEnum.Index,
    DocumentColumnEnum.Status,
    DocumentColumnEnum.Actions,
    DocumentColumnEnum.ProjectActions,
  ];

  export const blockDocumentColumns: DocumentColumnEnum[] = [
    DocumentColumnEnum.Family,
    DocumentColumnEnum.Name,
    DocumentColumnEnum.RefIntern,
    DocumentColumnEnum.BlockIndex,
    DocumentColumnEnum.Status,
    DocumentColumnEnum.Actions,
  ];

  export const blockMobileAppDocumentColumns: DocumentColumnEnum[] = [DocumentColumnEnum.NameRefIntern, DocumentColumnEnum.MobileAppActions];

  export const projectDocumentColumns: DocumentColumnEnum[] = [
    DocumentColumnEnum.Family,
    DocumentColumnEnum.Name,
    DocumentColumnEnum.RefIntern,
    DocumentColumnEnum.Index,
    DocumentColumnEnum.Status,
    DocumentColumnEnum.ProjectActions,
  ];

  const key = 'document.table.header.';
  export const toString: SfxMap<DocumentColumnEnum, string> = new SfxMap<DocumentColumnEnum, string>([
    [DocumentColumnEnum.Family, key + 'family'],
    [DocumentColumnEnum.Name, key + 'name'],
    [DocumentColumnEnum.NameRefIntern, key + 'name'],
    [DocumentColumnEnum.RefIntern, key + 'refIntern'],
    [DocumentColumnEnum.Index, key + 'index'],
    [DocumentColumnEnum.Status, key + 'status'],
    [DocumentColumnEnum.Actions, key + 'actions'],
    [DocumentColumnEnum.MobileAppActions, key + 'actions'],
    [DocumentColumnEnum.ProjectActions, key + 'projectActions'],
  ]);

  export const sortField: SfxMap<DocumentColumnEnum, string> = new SfxMap<DocumentColumnEnum, string>([
    [DocumentColumnEnum.Name, 'name'],
    [DocumentColumnEnum.Family, 'labelFamilyApplication'],
    [DocumentColumnEnum.RefIntern, 'refInt'],
    [DocumentColumnEnum.Index, 'indice'],
    [DocumentColumnEnum.Status, 'etat'],
  ]);
}
