import { ExternalApiApplicabilityDTO, LabelApplicabilityLightDTO, LabelApplicabilityResponseDTO, PageLabelApplicabilityRequest } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum } from '../../enums';

export interface LabelApplicabilityLightData {
  uuidEntity: string;
  name: string;
  color: string;
  priority: number;
  type?: LabelApplicabilityResponseDTO.TypeEnum;
}

export namespace LabelApplicabilityLightData {
  const missingName = 'Without Applicability';
  const defaultColor = '#5e5a5a';

  export const defaultData: LabelApplicabilityLightData = {
    uuidEntity: null,
    name: missingName,
    color: defaultColor,
    priority: null,
  };

  export interface QueryRequest extends BaseQuery<LabelApplicabilityLightData> {
    withoutDefaultVaule?: boolean;
  }

  export function mapFromApiValue(labelApplicability: LabelApplicabilityResponseDTO): LabelApplicabilityLightData {
    return {
      uuidEntity: labelApplicability.uuidEntity,
      name: labelApplicability.name,
      color: labelApplicability.color,
      priority: labelApplicability.priority,
      type: labelApplicability.type,
    };
  }

  export function mapToEntityLightData(labelApplicability: LabelApplicabilityLightDTO | LabelApplicabilityResponseDTO | ExternalApiApplicabilityDTO): EntityLightData {
    return {
      uuidEntity: labelApplicability.uuidEntity,
      name: labelApplicability.name,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageLabelApplicabilityRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      withoutDefaultValue: queries.withoutDefaultVaule,
    };
  }

  export function initializeQueryRequest(page: number, searchText?: string, withoutDefaultValue?: boolean): QueryRequest {
    return {
      ...initializeQueries<LabelApplicabilityLightData>({ key: 'priority' }),
      page,
      textSearch: searchText || undefined,
      withoutDefaultVaule: withoutDefaultValue || undefined,
    };
  }
}
