import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { LabelFamilyTypeEnum } from './label-family-type.enum';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyDetailsResponseDTO } from '../../generated/models/labelFamilyDetailsResponseDTO';

export enum LabelFamilyNatureEnum {
  Adr = 1,
  Emm = 2,
  Team = 3,
  Document = 4,
  Flow = 5,
  Form = 6,
  RhpFunction = 7,
  Legal = 8,
  List = 9,
  Device = 10,
  Project = 11,
  Hierarchy = 12,
  Skill = 13,
  Contract = 14,
  TechnicalObject = 15,
  ActivityDomain = 16,
}

export namespace LabelFamilyNatureEnum {
  export const values = (category?: boolean) =>
    compact([
      !category && LabelFamilyNatureEnum.Project,
      LabelFamilyNatureEnum.Flow,
      LabelFamilyNatureEnum.Document,
      !category && LabelFamilyNatureEnum.Form,
      !category && LabelFamilyNatureEnum.Adr,
      !category && LabelFamilyNatureEnum.Emm,
      !category && LabelFamilyNatureEnum.Device,
      !category && LabelFamilyNatureEnum.Team,
      !category && LabelFamilyNatureEnum.RhpFunction,
      !category && LabelFamilyNatureEnum.Legal,
      !category && LabelFamilyNatureEnum.Skill,
      !category && LabelFamilyNatureEnum.Contract,
      !category && LabelFamilyNatureEnum.TechnicalObject,
      !category && LabelFamilyNatureEnum.ActivityDomain,
      category && LabelFamilyNatureEnum.List,
      category && LabelFamilyNatureEnum.Hierarchy,
    ]);

  export const initialValues = (category?: boolean) =>
    compact([
      !category && { nature: LabelFamilyNatureEnum.Project, uuidEntity: 'entity_container_uuid_entity' },
      { nature: LabelFamilyNatureEnum.Flow, uuidEntity: 'flow_container_uuid_entity' },
      { nature: LabelFamilyNatureEnum.Document, uuidEntity: 'document_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Form, uuidEntity: 'form_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Adr, uuidEntity: 'adr_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Emm, uuidEntity: 'emm_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Device, uuidEntity: 'device_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Team, uuidEntity: 'team_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.RhpFunction, uuidEntity: 'rhpFunction_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Legal, uuidEntity: 'legal_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Skill, uuidEntity: 'skill_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.Contract, uuidEntity: 'contract_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.TechnicalObject, uuidEntity: 'technical_container_uuid_entity' },
      !category && { nature: LabelFamilyNatureEnum.ActivityDomain, uuidEntity: 'activity_domain_uuid_entity' },
      category && { nature: LabelFamilyNatureEnum.List, uuidEntity: 'list_container_uuid_entity' },
      category && { nature: LabelFamilyNatureEnum.Hierarchy, uuidEntity: 'hierarchy_container_uuid_entity' },
    ]);

  export const relativeUuidEntities: SfxMap<LabelFamilyNatureEnum, string> = new SfxMap<LabelFamilyNatureEnum, string>([
    [LabelFamilyNatureEnum.Project, 'entity_container_uuid_entity'],
    [LabelFamilyNatureEnum.Flow, 'flow_container_uuid_entity'],
    [LabelFamilyNatureEnum.Document, 'document_container_uuid_entity'],
    [LabelFamilyNatureEnum.Form, 'form_container_uuid_entity'],
    [LabelFamilyNatureEnum.Adr, 'adr_container_uuid_entity'],
    [LabelFamilyNatureEnum.Emm, 'emm_container_uuid_entity'],
    [LabelFamilyNatureEnum.Device, 'device_container_uuid_entity'],
    [LabelFamilyNatureEnum.Team, 'team_container_uuid_entity'],
    [LabelFamilyNatureEnum.RhpFunction, 'rhpFunction_container_uuid_entity'],
    [LabelFamilyNatureEnum.Legal, 'legal_container_uuid_entity'],
    [LabelFamilyNatureEnum.Skill, 'skill_container_uuid_entity'],
    [LabelFamilyNatureEnum.Contract, 'contract_container_uuid_entity'],
    [LabelFamilyNatureEnum.TechnicalObject, 'technical_container_uuid_entity'],
    [LabelFamilyNatureEnum.ActivityDomain, 'activity_domain_uuid_entity'],
    [LabelFamilyNatureEnum.List, 'list_container_uuid_entity'],
    [LabelFamilyNatureEnum.Hierarchy, 'hierarchy_container_uuid_entity'],
  ]);

  export const convertFromApiValue: SfxMap<LabelFamilyDetailsResponseDTO.NatureEnum, LabelFamilyNatureEnum> = new SfxMap<
    LabelFamilyDetailsResponseDTO.NatureEnum,
    LabelFamilyNatureEnum
  >([
    [LabelFamilyDetailsResponseDTO.NatureEnum.ADR, LabelFamilyNatureEnum.Adr],
    [LabelFamilyDetailsResponseDTO.NatureEnum.EMM, LabelFamilyNatureEnum.Emm],
    [LabelFamilyDetailsResponseDTO.NatureEnum.TEAM, LabelFamilyNatureEnum.Team],
    [LabelFamilyDetailsResponseDTO.NatureEnum.DOCUMENT, LabelFamilyNatureEnum.Document],
    [LabelFamilyDetailsResponseDTO.NatureEnum.FLOW, LabelFamilyNatureEnum.Flow],
    [LabelFamilyDetailsResponseDTO.NatureEnum.FORM, LabelFamilyNatureEnum.Form],
    [LabelFamilyDetailsResponseDTO.NatureEnum.RHPFUNCTION, LabelFamilyNatureEnum.RhpFunction],
    [LabelFamilyDetailsResponseDTO.NatureEnum.LEGAL, LabelFamilyNatureEnum.Legal],
    [LabelFamilyDetailsResponseDTO.NatureEnum.LIST, LabelFamilyNatureEnum.List],
    [LabelFamilyDetailsResponseDTO.NatureEnum.CONTRAT, LabelFamilyNatureEnum.Contract],
    [LabelFamilyDetailsResponseDTO.NatureEnum.DEVICE, LabelFamilyNatureEnum.Device],
    [LabelFamilyDetailsResponseDTO.NatureEnum.PROJECT, LabelFamilyNatureEnum.Project],
    [LabelFamilyDetailsResponseDTO.NatureEnum.HIERARCHY, LabelFamilyNatureEnum.Hierarchy],
    [LabelFamilyDetailsResponseDTO.NatureEnum.SKILL, LabelFamilyNatureEnum.Skill],
    [LabelFamilyDetailsResponseDTO.NatureEnum.TECHNICALOBJECT, LabelFamilyNatureEnum.TechnicalObject],
    [LabelFamilyDetailsResponseDTO.NatureEnum.ACTIVITYDOMAIN, LabelFamilyNatureEnum.ActivityDomain],
  ]);

  export const convertToApiValue: SfxMap<LabelFamilyNatureEnum, LabelFamilyDetailsResponseDTO.NatureEnum> = new SfxMap<
    LabelFamilyNatureEnum,
    LabelFamilyDetailsResponseDTO.NatureEnum
  >([
    [LabelFamilyNatureEnum.Adr, LabelFamilyDetailsResponseDTO.NatureEnum.ADR],
    [LabelFamilyNatureEnum.Emm, LabelFamilyDetailsResponseDTO.NatureEnum.EMM],
    [LabelFamilyNatureEnum.Team, LabelFamilyDetailsResponseDTO.NatureEnum.TEAM],
    [LabelFamilyNatureEnum.Document, LabelFamilyDetailsResponseDTO.NatureEnum.DOCUMENT],
    [LabelFamilyNatureEnum.Flow, LabelFamilyDetailsResponseDTO.NatureEnum.FLOW],
    [LabelFamilyNatureEnum.Form, LabelFamilyDetailsResponseDTO.NatureEnum.FORM],
    [LabelFamilyNatureEnum.RhpFunction, LabelFamilyDetailsResponseDTO.NatureEnum.RHPFUNCTION],
    [LabelFamilyNatureEnum.Legal, LabelFamilyDetailsResponseDTO.NatureEnum.LEGAL],
    [LabelFamilyNatureEnum.List, LabelFamilyDetailsResponseDTO.NatureEnum.LIST],
    [LabelFamilyNatureEnum.Device, LabelFamilyDetailsResponseDTO.NatureEnum.DEVICE],
    [LabelFamilyNatureEnum.Project, LabelFamilyDetailsResponseDTO.NatureEnum.PROJECT],
    [LabelFamilyNatureEnum.Hierarchy, LabelFamilyDetailsResponseDTO.NatureEnum.HIERARCHY],
    [LabelFamilyNatureEnum.Skill, LabelFamilyDetailsResponseDTO.NatureEnum.SKILL],
    [LabelFamilyNatureEnum.Contract, LabelFamilyDetailsResponseDTO.NatureEnum.CONTRAT],
    [LabelFamilyNatureEnum.TechnicalObject, LabelFamilyDetailsResponseDTO.NatureEnum.TECHNICALOBJECT],
    [LabelFamilyNatureEnum.ActivityDomain, LabelFamilyDetailsResponseDTO.NatureEnum.ACTIVITYDOMAIN],
  ]);

  export const convertFromParamsValue: SfxMap<string, LabelFamilyNatureEnum> = new SfxMap<string, LabelFamilyNatureEnum>([
    ['risk', LabelFamilyNatureEnum.Adr],
    ['equipment', LabelFamilyNatureEnum.Emm],
    ['collaborators', LabelFamilyNatureEnum.Team],
    ['document', LabelFamilyNatureEnum.Document],
    ['flow', LabelFamilyNatureEnum.Flow],
    ['form', LabelFamilyNatureEnum.Form],
    ['functions', LabelFamilyNatureEnum.RhpFunction],
    ['legal', LabelFamilyNatureEnum.Legal],
    ['list', LabelFamilyNatureEnum.List],
    ['tablets', LabelFamilyNatureEnum.Device],
    ['entity', LabelFamilyNatureEnum.Project],
    ['hierarchy', LabelFamilyNatureEnum.Hierarchy],
    ['skills', LabelFamilyNatureEnum.Skill],
    ['contract', LabelFamilyNatureEnum.Contract],
    ['technicalObject', LabelFamilyNatureEnum.TechnicalObject],
    ['activityDomain', LabelFamilyNatureEnum.ActivityDomain],
  ]);

  export const convertToParamsValue: SfxMap<LabelFamilyNatureEnum, string> = new SfxMap<LabelFamilyNatureEnum, string>([
    [LabelFamilyNatureEnum.Adr, 'risk'],
    [LabelFamilyNatureEnum.Emm, 'equipment'],
    [LabelFamilyNatureEnum.Team, 'collaborators'],
    [LabelFamilyNatureEnum.Document, 'document'],
    [LabelFamilyNatureEnum.Flow, 'flow'],
    [LabelFamilyNatureEnum.Form, 'form'],
    [LabelFamilyNatureEnum.RhpFunction, 'functions'],
    [LabelFamilyNatureEnum.Legal, 'legal'],
    [LabelFamilyNatureEnum.List, 'list'],
    [LabelFamilyNatureEnum.Device, 'tablets'],
    [LabelFamilyNatureEnum.Project, 'entity'],
    [LabelFamilyNatureEnum.Hierarchy, 'hierarchy'],
    [LabelFamilyNatureEnum.Skill, 'skills'],
    [LabelFamilyNatureEnum.Contract, 'contract'],
    [LabelFamilyNatureEnum.TechnicalObject, 'technicalObject'],
    [LabelFamilyNatureEnum.ActivityDomain, 'activityDomain'],
  ]);

  const key = 'labelFamilyNature.';
  export const toString: SfxMap<LabelFamilyNatureEnum, string> = new SfxMap<LabelFamilyNatureEnum, string>(
    [
      [LabelFamilyNatureEnum.Adr, key + 'risk'],
      [LabelFamilyNatureEnum.Emm, key + 'mean'],
      [LabelFamilyNatureEnum.Team, key + 'group'],
      [LabelFamilyNatureEnum.Document, key + 'document'],
      [LabelFamilyNatureEnum.Flow, key + 'flow'],
      [LabelFamilyNatureEnum.Form, key + 'form'],
      [LabelFamilyNatureEnum.RhpFunction, key + 'function'],
      [LabelFamilyNatureEnum.Legal, key + 'legal'],
      [LabelFamilyNatureEnum.List, key + 'list'],
      [LabelFamilyNatureEnum.Device, key + 'tablet'],
      [LabelFamilyNatureEnum.Project, key + 'entity'],
      [LabelFamilyNatureEnum.Hierarchy, key + 'hierarchy'],
      [LabelFamilyNatureEnum.Skill, key + 'skill'],
      [LabelFamilyNatureEnum.Contract, key + 'contract'],
      [LabelFamilyNatureEnum.TechnicalObject, key + 'technicalObject'],
      [LabelFamilyNatureEnum.ActivityDomain, key + 'activityDomain'],
    ],
    key + 'nature',
  );

  export const iconData: SfxMap<LabelFamilyNatureEnum, IconData> = new SfxMap<LabelFamilyNatureEnum, IconData>([
    [LabelFamilyNatureEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Flow, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [LabelFamilyNatureEnum.List, { name: 'file-alt', prefix: 'far' }],
    [LabelFamilyNatureEnum.Hierarchy, { name: 'user-friends', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Adr, { name: 'exclamation-triangle', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Emm, { name: 'tools', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Project, { name: 'folder-open', prefix: 'far' }],
    [LabelFamilyNatureEnum.Form, { name: 'list-alt', prefix: 'far' }],
    [LabelFamilyNatureEnum.Device, { name: 'tablet-alt', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Team, { name: 'user-tag', prefix: 'fas' }],
    [LabelFamilyNatureEnum.RhpFunction, { name: 'id-card-alt', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Legal, { name: 'balance-scale', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Skill, { name: 'graduation-cap', prefix: 'fas' }],
    [LabelFamilyNatureEnum.Contract, { name: 'file-contract', prefix: 'fas' }],
    [LabelFamilyNatureEnum.TechnicalObject, { name: 'object-group', prefix: 'fas' }],
    [LabelFamilyNatureEnum.ActivityDomain, { name: 'hiking', prefix: 'fas' }],
  ]);

  const emptyKey = 'users.filters.';

  export const filterWithout = (type: LabelFamilyTypeEnum) =>
    new SfxMap<LabelFamilyNatureEnum, string>(
      [
        [LabelFamilyNatureEnum.Skill, emptyKey + 'withoutSkills'],
        [LabelFamilyNatureEnum.Hierarchy, emptyKey + 'withoutHierarchy'],
        [LabelFamilyNatureEnum.Contract, emptyKey + 'withoutContract'],
        [LabelFamilyNatureEnum.Legal, emptyKey + 'withoutLegal'],
      ],
      type === LabelFamilyTypeEnum.Application ? 'customCells.dialogs.emptyFamily' : 'customCells.dialogs.emptyCategory',
    );

  export const itemsResolver = (category?: boolean): Observable<SingleSelectItemData<LabelFamilyNatureEnum>[]> =>
    of(
      values(category).map(family => ({
        value: family,
        title: toString.getValue(family),
        icons: [iconData.getValue(family)],
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<LabelFamilyNatureEnum>, item: LabelFamilyNatureEnum) => node.value === item;
}
