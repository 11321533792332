import { AccountUpdateRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LanguageEnum } from '../../enums';

export interface UserForUpdate {
  uuidEntity: string;
  email?: string;
  ssoIdentifier?: string;
  firstName?: string;
  labelFamilyFunction?: EntityLightData;
  language?: LanguageEnum;
  lastName?: string;
  profession?: string;
  signature?: string;
  trigram?: string;
  modificationDate?: string;
}

export namespace UserForUpdate {
  export function mapToUpdateRequestApiValue(userData: UserForUpdate): AccountUpdateRequestDTO {
    return {
      ssoIdentifier: userData.ssoIdentifier || null,
      firstName: userData.firstName,
      lastName: userData.lastName,

      profession: userData.profession,
      signature: userData.signature,
      langKey: (LanguageEnum.convertToApiValue.getValue(userData.language) || 'en').toLowerCase(),
      labelFamilyFunction: (userData.labelFamilyFunction && EntityLightData.mapToApiValue(userData.labelFamilyFunction)) || undefined,
      trigram: userData.trigram?.toLocaleUpperCase(),
    };
  }
}
