import { SfxMap } from '../../utils/enum-utils';
import { IconData } from '../../../modules/shared/interfaces/icon-data';

export enum UserTabsEnum {
  General = 'general',
  AdditionalRights = 'additionalRights',
}

export namespace UserTabsEnum {
  export const values = [UserTabsEnum.General, UserTabsEnum.AdditionalRights];

  const key = 'users.modals.tabs.';
  export const toString = new SfxMap<UserTabsEnum, string>([
    [UserTabsEnum.General, key + 'general'],
    [UserTabsEnum.AdditionalRights, key + 'additionalRights'],
  ]);

  export const iconData = new SfxMap<UserTabsEnum, IconData>([
    [UserTabsEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [UserTabsEnum.AdditionalRights, { name: 'user-graduate', prefix: 'fas' }],
  ]);
}
