/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompanyLightDTO } from './companyLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { ProjectLightDTO } from './projectLightDTO';

export interface TeamRoleDeviceSummaryResponseDTO {
  alreadyImported: boolean;
  applicabilityName: string;
  batteryLevel: string;
  choixTeamRole: TeamRoleDeviceSummaryResponseDTO.ChoixTeamRoleEnum;
  company: CompanyLightDTO;
  deviceUuidEntity: string;
  labelFamily: LabelFamilyLightDTO;
  latitude: string;
  longitude: string;
  modificationDate: Date;
  nature: TeamRoleDeviceSummaryResponseDTO.NatureEnum;
  project: ProjectLightDTO;
  refExtern: string;
  refIntern: string;
  status: TeamRoleDeviceSummaryResponseDTO.StatusEnum;
  teamRoleOrder: number;
  uniqueDeviceId: string;
  userStatus: TeamRoleDeviceSummaryResponseDTO.UserStatusEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}
export namespace TeamRoleDeviceSummaryResponseDTO {
  export type ChoixTeamRoleEnum = 'ROLE_NORMAL' | 'ROLE_ADVANCED' | 'ROLE_READONLY' | 'ROLE_ADMIN_PROJECT' | 'ROLE_OPERATOR';
  export const ChoixTeamRoleEnum = {
    NORMAL: 'ROLE_NORMAL' as ChoixTeamRoleEnum,
    ADVANCED: 'ROLE_ADVANCED' as ChoixTeamRoleEnum,
    READONLY: 'ROLE_READONLY' as ChoixTeamRoleEnum,
    ADMINPROJECT: 'ROLE_ADMIN_PROJECT' as ChoixTeamRoleEnum,
    OPERATOR: 'ROLE_OPERATOR' as ChoixTeamRoleEnum,
  };
  export type NatureEnum = 'NORMAL' | 'EXTERN' | 'DEVICE' | 'DEVICE_EXT' | 'EXTERNAL_API_ACCESS' | 'USER_CYPRESS' | 'EXTERNAL_CONTRIBUTOR';
  export const NatureEnum = {
    NORMAL: 'NORMAL' as NatureEnum,
    EXTERN: 'EXTERN' as NatureEnum,
    DEVICE: 'DEVICE' as NatureEnum,
    DEVICEEXT: 'DEVICE_EXT' as NatureEnum,
    EXTERNALAPIACCESS: 'EXTERNAL_API_ACCESS' as NatureEnum,
    USERCYPRESS: 'USER_CYPRESS' as NatureEnum,
    EXTERNALCONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR' as NatureEnum,
  };
  export type StatusEnum = 'ACTIVE' | 'DISABLE' | 'BROKEN' | 'DOWN' | 'MAINTENANCE';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    DISABLE: 'DISABLE' as StatusEnum,
    BROKEN: 'BROKEN' as StatusEnum,
    DOWN: 'DOWN' as StatusEnum,
    MAINTENANCE: 'MAINTENANCE' as StatusEnum,
  };
  export type UserStatusEnum = 'ONBOARDING' | 'ACTIVATED' | 'BLOCKED' | 'DEACTIVATED' | 'DELETED';
  export const UserStatusEnum = {
    ONBOARDING: 'ONBOARDING' as UserStatusEnum,
    ACTIVATED: 'ACTIVATED' as UserStatusEnum,
    BLOCKED: 'BLOCKED' as UserStatusEnum,
    DEACTIVATED: 'DEACTIVATED' as UserStatusEnum,
    DELETED: 'DELETED' as UserStatusEnum,
  };
}
