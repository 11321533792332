/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MobileAppDisplayRequestConfigDto } from './mobileAppDisplayRequestConfigDto';
import { PdfDisplayConfigRequestDto } from './pdfDisplayConfigRequestDto';
import { WebAppDisplayConfigRequestDto } from './webAppDisplayConfigRequestDto';

export interface AppConfigUpdateRequestDTO {
  waterMark?: string;
  showLogo?: boolean;
  logoWidth?: number;
  logoHeight?: number;
  indiceNature?: AppConfigUpdateRequestDTO.IndiceNatureEnum;
  exportLanguageOne?: AppConfigUpdateRequestDTO.ExportLanguageOneEnum;
  exportLanguageTwo?: AppConfigUpdateRequestDTO.ExportLanguageTwoEnum;
  modulePgac?: boolean;
  moduleFileProcessing?: boolean;
  progressLog?: boolean;
  subContractor?: string;
  multiPrTitleHidden?: boolean;
  autoSignature?: boolean;
  authoritiesBlackList?: Array<AppConfigUpdateRequestDTO.AuthoritiesBlackListEnum>;
  webAppDisplayConfig: WebAppDisplayConfigRequestDto;
  mobileAppDisplayConfig: MobileAppDisplayRequestConfigDto;
  pdfDisplayConfig: PdfDisplayConfigRequestDto;
  lockForm?: boolean;
  ylogoPosition?: number;
  xlogoPosition?: number;
}
export namespace AppConfigUpdateRequestDTO {
  export type IndiceNatureEnum = 'NUMERIC_ZERO' | 'NUMERIC' | 'ALPHA' | 'NUMERIC_ALL_ZERO' | 'NUMERIC_ALL_ZERO_START_ZERO';
  export const IndiceNatureEnum = {
    NUMERICZERO: 'NUMERIC_ZERO' as IndiceNatureEnum,
    NUMERIC: 'NUMERIC' as IndiceNatureEnum,
    ALPHA: 'ALPHA' as IndiceNatureEnum,
    NUMERICALLZERO: 'NUMERIC_ALL_ZERO' as IndiceNatureEnum,
    NUMERICALLZEROSTARTZERO: 'NUMERIC_ALL_ZERO_START_ZERO' as IndiceNatureEnum,
  };
  export type ExportLanguageOneEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageOneEnum = {
    FR: 'FR' as ExportLanguageOneEnum,
    EN: 'EN' as ExportLanguageOneEnum,
    DE: 'DE' as ExportLanguageOneEnum,
    IT: 'IT' as ExportLanguageOneEnum,
    NONE: 'NONE' as ExportLanguageOneEnum,
  };
  export type ExportLanguageTwoEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageTwoEnum = {
    FR: 'FR' as ExportLanguageTwoEnum,
    EN: 'EN' as ExportLanguageTwoEnum,
    DE: 'DE' as ExportLanguageTwoEnum,
    IT: 'IT' as ExportLanguageTwoEnum,
    NONE: 'NONE' as ExportLanguageTwoEnum,
  };
  export type AuthoritiesBlackListEnum =
    | 'ROLE_ADMIN'
    | 'ROLE_USER'
    | 'ROLE_ADMIN_APP'
    | 'ROLE_USER_ADVANCED'
    | 'ROLE_USER_OPERATOR'
    | 'ROLE_USER_BASIC'
    | 'ROLE_ANONYMOUS'
    | 'ROLE_USER_MOBILE'
    | 'ROLE_USER_EXTERNAL_API_ACCESS'
    | 'ROLE_ADMIN_APP_GLOBAL'
    | 'ROLE_QHSE'
    | 'ROLE_USER_MODEL_FORM'
    | 'ROLE_EXTERNAL_CONTRIBUTOR'
    | 'ROLE_MANAGE_DIFFUSION';
  export const AuthoritiesBlackListEnum = {
    ADMIN: 'ROLE_ADMIN' as AuthoritiesBlackListEnum,
    USER: 'ROLE_USER' as AuthoritiesBlackListEnum,
    ADMINAPP: 'ROLE_ADMIN_APP' as AuthoritiesBlackListEnum,
    USERADVANCED: 'ROLE_USER_ADVANCED' as AuthoritiesBlackListEnum,
    USEROPERATOR: 'ROLE_USER_OPERATOR' as AuthoritiesBlackListEnum,
    USERBASIC: 'ROLE_USER_BASIC' as AuthoritiesBlackListEnum,
    ANONYMOUS: 'ROLE_ANONYMOUS' as AuthoritiesBlackListEnum,
    USERMOBILE: 'ROLE_USER_MOBILE' as AuthoritiesBlackListEnum,
    USEREXTERNALAPIACCESS: 'ROLE_USER_EXTERNAL_API_ACCESS' as AuthoritiesBlackListEnum,
    ADMINAPPGLOBAL: 'ROLE_ADMIN_APP_GLOBAL' as AuthoritiesBlackListEnum,
    QHSE: 'ROLE_QHSE' as AuthoritiesBlackListEnum,
    USERMODELFORM: 'ROLE_USER_MODEL_FORM' as AuthoritiesBlackListEnum,
    EXTERNALCONTRIBUTOR: 'ROLE_EXTERNAL_CONTRIBUTOR' as AuthoritiesBlackListEnum,
    MANAGEDIFFUSION: 'ROLE_MANAGE_DIFFUSION' as AuthoritiesBlackListEnum,
  };
}
