import { PropertiesResponseDto } from '../../generated/models/propertiesResponseDto';
import { WebsocketPropertyData } from './websocket-properties';

export interface Properties {
  websocket: WebsocketPropertyData;
}

export namespace Properties {
  export function mapFromPropertiesResponseDto(dto: PropertiesResponseDto): Properties {
    return {
      websocket: WebsocketPropertyData.mapFromWebsocketPropertiesResponseDto(dto.websocket),
    };
  }
}
