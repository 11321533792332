import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FlowDeleteResponseDTO } from '../../generated/models/models';

import { NatureLinkEnum } from '../../enums';
import { DeleteStepLink } from './delete-step-link';
import { DeleteFlowLink } from './delete-flow-link';

export interface DeleteFlowResponse {
  success: boolean;
  natureLink: NatureLinkEnum;
  documentFlowLinks: DocumentFlowSummaryData[];
  flowLinks: DeleteFlowLink[];
  stepLinks: DeleteStepLink[];
}

export namespace DeleteFlowResponse {
  export function mapFromApiValue(data: FlowDeleteResponseDTO): DeleteFlowResponse {
    return {
      success: data.success,
      natureLink: NatureLinkEnum.convertFromApiValue.getValue(data.natureLink),
      flowLinks: data.flowLinks?.map(flow => DeleteFlowLink.mapFromApiValue(flow)),
      documentFlowLinks: data.documentFlowLinks?.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
      stepLinks: data.stepLinks?.map(stepLink => DeleteStepLink.mapFromApiValue(stepLink)),
    };
  }

  export function mapToConflictData(deleteFlowResponse: DeleteFlowResponse): ConflictData {
    switch (deleteFlowResponse.natureLink) {
      case NatureLinkEnum.FlowLink:
        return { title: 'global.entityConflict.hint.flow.flowsLink', links: deleteFlowResponse.flowLinks.map(flow => DeleteFlowLink.toString(flow)) };

      case NatureLinkEnum.DocumentFlowLink:
        return {
          title: 'global.entityConflict.hint.flow.documentFlowsLink',
          links: deleteFlowResponse.documentFlowLinks.map(documentFlow => DocumentFlowSummaryData.toString(documentFlow)),
        };

      case NatureLinkEnum.StepLink:
        return {
          title: 'global.entityConflict.hint.flow.stepsLink',
          links: deleteFlowResponse.stepLinks.map(step => DeleteStepLink.toString(step)),
        };

      default:
        return null;
    }
  }
}
