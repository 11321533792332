import { EpsilonOutRejectEnum } from '../../enums';
import { EpsilonOutRejectRequestDTO } from '../../generated/models/models';

export interface EpsilonOutForUpdate {
  comment: string;
  epsilonF: EpsilonOutRejectEnum;
  uuidEntity: string;
}

export namespace EpsilonOutForUpdate {
  export function mapToUpdateRequestApiValue(data: EpsilonOutForUpdate, epsilonFString: string): EpsilonOutRejectRequestDTO {
    return {
      doublonComment: data.comment,
      epsilonF: epsilonFString,
      uuidEntity: data.uuidEntity,
    };
  }
}
