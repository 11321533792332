import { SectionRfiTemplateSummaryResponseDTO } from '../../generated/models/sectionRfiTemplateSummaryResponseDTO';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum SectionTypeEnum {
  Document = 1,
  Report = 2,
  Certificate = 3,
  Reference = 4,
  ClientResponse = 5,
  DocumentLargeFile = 6,
  ClosingFolder = 7,
}

export namespace SectionTypeEnum {
  const values: SectionTypeEnum[] = [
    SectionTypeEnum.Document,
    SectionTypeEnum.Report,
    SectionTypeEnum.Certificate,
    SectionTypeEnum.Reference,
    SectionTypeEnum.ClientResponse,
    SectionTypeEnum.DocumentLargeFile,
  ];

  export const toString = (longString?: boolean): SfxMap<SectionTypeEnum, string> => {
    const key = longString ? 'folders.modals.section.typesLong.' : 'folders.section.types.';

    return new SfxMap<SectionTypeEnum, string>([
      [SectionTypeEnum.Document, key + 'document'],
      [SectionTypeEnum.Report, key + 'report'],
      [SectionTypeEnum.Certificate, key + 'certificate'],
      [SectionTypeEnum.Reference, key + 'reference'],
      [SectionTypeEnum.ClientResponse, key + 'clientResponse'],
      [SectionTypeEnum.DocumentLargeFile, key + 'documentLargeFile'],
      [SectionTypeEnum.ClosingFolder, key + 'sectionRfi'],
    ]);
  };

  export const iconData: SfxMap<SectionTypeEnum, IconData> = new SfxMap<SectionTypeEnum, IconData>([
    [SectionTypeEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [SectionTypeEnum.Certificate, { name: 'tools', prefix: 'fas' }],
    [SectionTypeEnum.Report, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [SectionTypeEnum.ClientResponse, { name: 'file-invoice', prefix: 'fas' }],
    [SectionTypeEnum.Reference, { name: 'tools', prefix: 'fas' }],
    [SectionTypeEnum.DocumentLargeFile, { name: 'archive', prefix: 'fas' }],
    [SectionTypeEnum.ClosingFolder, { name: 'archive', prefix: 'fas' }],
  ]);

  export const convertToApiValue: SfxMap<SectionTypeEnum, SectionRfiTemplateSummaryResponseDTO.TypeEnum> = new SfxMap<
    SectionTypeEnum,
    SectionRfiTemplateSummaryResponseDTO.TypeEnum
  >([
    [SectionTypeEnum.Document, SectionRfiTemplateSummaryResponseDTO.TypeEnum.DOCUMENT],
    [SectionTypeEnum.Report, SectionRfiTemplateSummaryResponseDTO.TypeEnum.REPORT],
    [SectionTypeEnum.Certificate, SectionRfiTemplateSummaryResponseDTO.TypeEnum.CERTIFICATE],
    [SectionTypeEnum.Reference, SectionRfiTemplateSummaryResponseDTO.TypeEnum.REFERENCE],
    [SectionTypeEnum.ClientResponse, SectionRfiTemplateSummaryResponseDTO.TypeEnum.CLIENTRESPONSE],
    [SectionTypeEnum.DocumentLargeFile, SectionRfiTemplateSummaryResponseDTO.TypeEnum.DOCUMENTLARGEFILE],
    [SectionTypeEnum.ClosingFolder, SectionRfiTemplateSummaryResponseDTO.TypeEnum.RFI],
  ]);

  export const convertFromApiValue: SfxMap<SectionRfiTemplateSummaryResponseDTO.TypeEnum, SectionTypeEnum> = new SfxMap<
    SectionRfiTemplateSummaryResponseDTO.TypeEnum,
    SectionTypeEnum
  >([
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.DOCUMENT, SectionTypeEnum.Document],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.REPORT, SectionTypeEnum.Report],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.CERTIFICATE, SectionTypeEnum.Certificate],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.REFERENCE, SectionTypeEnum.Reference],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.CLIENTRESPONSE, SectionTypeEnum.ClientResponse],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.DOCUMENTLARGEFILE, SectionTypeEnum.DocumentLargeFile],
    [SectionRfiTemplateSummaryResponseDTO.TypeEnum.RFI, SectionTypeEnum.ClosingFolder],
  ]);

  export const selectItems: SingleSelectItemData<SectionTypeEnum>[] = values.map(type => ({
    value: type,
    title: toString(true).getValue(type),
    icons: [iconData.getValue(type)],
  }));
}
