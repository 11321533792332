import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { DocumentFilterStateEnum } from '../../enums';
import { WordingConfigurationStateDocumentFlowDTO } from '../../generated/models/wordingConfigurationStateDocumentFlowDTO';
import { SfxMap } from '../../utils/enum-utils';

export interface CustomWordingStateData {
  uuidEntity: string;
  customWordingUuidEntity?: string;
  actionKey: DocumentFilterStateEnum;
  value?: string;
}

export namespace CustomWordingStateData {
  const key = 'document.filters.customWording.';
  const toString = (value: string) => new SfxMap<string, string>([[WordingConfigurationStateDocumentFlowDTO.ActionKeyEnum.WITHOUT, key + 'without']], value);

  const separator = '¿';
  export const convertFromParamsValue = (customWording: string): CustomWordingStateData => {
    const customWordingSplitted = customWording.split(separator);

    return {
      uuidEntity: customWordingSplitted[0],
      customWordingUuidEntity: customWordingSplitted[1],
      actionKey: DocumentFilterStateEnum.convertFromParamsValue.getValue(customWordingSplitted[2]),
    };
  };

  export const convertToParamsValue = (customWording: CustomWordingStateData): string =>
    Object.entries(customWording)
      .map(([_, value]) => (typeof value === 'string' ? value : DocumentFilterStateEnum.convertToParamsValue.getValue(value)))
      .join(separator);

  export const mapToMultiSelectResolver = (customWording: WordingConfigurationStateDocumentFlowDTO): MultiSelectItemData<CustomWordingStateData> => ({
    title: toString(customWording.value).getValue(customWording.actionKey),
    subtitle: customWording.tooltip,
    value: {
      uuidEntity: customWording.uuidEntity,
      customWordingUuidEntity: customWording.customWordingUuidEntity,
      actionKey: DocumentFilterStateEnum.convertFromApiValue.getValue(customWording.actionKey),
    },
  });

  export const comparePredicate = (node: MultiSelectItemData<WordingConfigurationStateDocumentFlowDTO>, item: CustomWordingStateData) =>
    [node.value.actionKey, item.actionKey].every(value => value === DocumentFilterStateEnum.Other) || node.value.uuidEntity === item.uuidEntity;
}
