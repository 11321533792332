import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { TabletSummaryData } from './tablet-summary-data';
// import { DeviceDeleteResponseDTO } from '../../generated/models/models';

export interface CTabletDeleteResponseData {
  success: boolean;
  links: TabletSummaryData[];
}

export namespace CTabletDeleteResponseData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapFromApiValue(data: any /* DeviceDeleteResponseDTO */): CTabletDeleteResponseData {
    return {
      success: data.success,
      links: data.links && data.links.map(teamRole => TabletSummaryData.mapFromApiValue(teamRole)),
    };
  }

  export function mapToConflictData(deleteResponse: CTabletDeleteResponseData): ConflictData {
    return {
      title: 'global.entityConflict.hint.tablet.flowsLink',
      links: (deleteResponse.links || []).map(role => TabletSummaryData.toString(role)),
    };
  }
}
