import { RfiTemplateSummaryResponseDTO } from '../../generated/models/rfiTemplateSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum FolderColumnEnum {
  name = 'name',
  actions = 'action',
}

export namespace FolderColumnEnum {
  export type folderSortField = keyof RfiTemplateSummaryResponseDTO;

  export const allColumns = [FolderColumnEnum.name, FolderColumnEnum.actions];

  const key = 'folders.table.header.';
  export const toString: SfxMap<FolderColumnEnum, string> = new SfxMap<FolderColumnEnum, string>([
    [FolderColumnEnum.name, key + 'name'],
    [FolderColumnEnum.actions, key + 'action'],
  ]);

  export const sortField: SfxMap<FolderColumnEnum, folderSortField> = new SfxMap<FolderColumnEnum, folderSortField>([[FolderColumnEnum.name, 'name']]);
}
