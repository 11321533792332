import { SfxMap } from '../../utils/enum-utils';

export enum BarChartSizeEnum {
  Small = 1,
  Medium = 2,
  Large = 3,
}

export namespace BarChartSizeEnum {
  export const toSize: SfxMap<BarChartSizeEnum, number> = new SfxMap<BarChartSizeEnum, number>(
    [
      [BarChartSizeEnum.Small, 2],
      [BarChartSizeEnum.Medium, 5],
      [BarChartSizeEnum.Large, 7],
    ],
    5,
  );
}
