<ul class="context-menu">
  <li class="menu-item" *ngFor="let action of CustomMenuActionEnum.selectItems" (click)="sendAction(action.value)">
    <sfx-icon class="alert-icon-container" [name]="action.icons[0]?.name"></sfx-icon>
    <sfx-label [text]="action.title | translate"></sfx-label>
  </li>
  <li class="menu-item caret-right">
    <sfx-label [text]="CustomMenuActionEnum.toString.getValue(CustomMenuActionEnum.SpecialCharacters) | translate"></sfx-label>
    <ul class="context-submenu">
      <ng-container *ngFor="let item of SpecialCharactersEnum.selectItems">
        <li class="submenu-item" [cdkCopyToClipboard]="item?.value" (click)="sendAction(CustomMenuActionEnum.Paste)">
          <sfx-label [text]="item?.title | translate"></sfx-label>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
