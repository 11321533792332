import { ParadeAdrData } from '../../models/lists/adr-parade-summary';
import { SfxMap } from '../../utils/enum-utils';

export enum AdrParadeColumnEnum {
  Name = 'name',
  Pdf = 'pdf',
  Picto = 'picto',
}

export namespace AdrParadeColumnEnum {
  export type AdrParadeSortField = keyof ParadeAdrData;

  export const allColumns = [AdrParadeColumnEnum.Name, AdrParadeColumnEnum.Picto, AdrParadeColumnEnum.Pdf];

  const key = 'lists.visualization.table.header.';
  export const toString: SfxMap<AdrParadeColumnEnum, string> = new SfxMap<AdrParadeColumnEnum, string>([
    [AdrParadeColumnEnum.Name, key + 'name'],
    [AdrParadeColumnEnum.Picto, key + 'picto'],
    [AdrParadeColumnEnum.Pdf, key + 'pdf'],
  ]);

  export const sortField: SfxMap<AdrParadeColumnEnum, AdrParadeSortField> = new SfxMap<AdrParadeColumnEnum, AdrParadeSortField>([
    [AdrParadeColumnEnum.Name, 'name'],
    [AdrParadeColumnEnum.Pdf, 'fileUuidEntity'],
  ]);
}
