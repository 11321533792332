import { compact } from 'lodash';

import { DocumentFlowFindLazySharedRequestDTO } from '../../generated/models/models';

import { DateFilterData } from '../utils/date-filter-data';
import { DashboardDocumentSummaryData } from './dashboard-document-summary';
import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum } from '../../enums';
import { DocumentFilterStateEnum } from '../../enums/document/document-filter-state.enum';

export interface DashboardDocumentToImportQueryRequest extends BaseQuery<DashboardDocumentSummaryData> {
  projectUuidEntity: string;
  applicabilities?: string[];
  families?: string[];
  categories?: string[];
  categories2?: string[];
  states?: DocumentFilterStateEnum[];
  customWording?: string[];
  dateFilter?: DateFilterData;
}

export namespace DashboardDocumentToImportQueryRequest {
  const sortFields = ['family', 'name', 'refInt', 'indice', 'etat'];

  export function mapToApiValue(queries: DashboardDocumentToImportQueryRequest): DocumentFlowFindLazySharedRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      projectUuidEntity: queries.projectUuidEntity,
      textSearch: queries.textSearch || undefined,
      applicabilityUuidEntity: queries.applicabilities || undefined,
      familyUuidEntity: queries.families || undefined,
      categoryPrincipalUuidEntity: queries.categories || undefined,
      categorySecondaryUuidEntity: queries.categories2 || undefined,
      filterEtat: queries.states && queries.states.length ? queries.states.map(state => DocumentFilterStateEnum.convertToApiValue.getValue(state)) : undefined,
      customWordingUuidEntities: compact(queries.customWording),
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
    };
  }

  export function initialize(
    projectUuidEntity: string,
    listQueryParam: {
      applicabilities: string[];
      families: string[];
      categories: string[];
      categories2: string[];
      customWording: CustomWordingStateData[];
    },
    textSearch: string,
    sort: SortFilter<DashboardDocumentSummaryData>,
    dateFilter: DateFilterData,
  ): DashboardDocumentToImportQueryRequest {
    return {
      ...initializeQueries<DashboardDocumentSummaryData>({ key: sort.key as keyof DashboardDocumentSummaryData, direction: sort.direction }),
      projectUuidEntity,
      textSearch: textSearch || undefined,
      applicabilities: (listQueryParam.applicabilities || []).length ? listQueryParam.applicabilities : undefined,
      families: (listQueryParam.families || []).length ? listQueryParam.families : undefined,
      categories: (listQueryParam.categories || []).length ? listQueryParam.categories : undefined,
      categories2: (listQueryParam.categories2 || []).length ? listQueryParam.categories2 : undefined,
      states: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(state => state.actionKey) : undefined,
      customWording: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(state => state.customWordingUuidEntity) : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
