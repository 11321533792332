import { NavigationFlowDetailsResponseDTO } from '../../generated/models/navigationFlowDetailsResponseDTO';
import { PageNavigationFlowRequestDTO } from '../../generated/models/pageNavigationFlowRequestDTO';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { PhaseSummaryData } from '../phase/phase-summary-data';
import { StepNavigationTypeEnum, InnerSortDirectionEnum } from '../../enums';
import { NavigationFlowCreateRequestDTO, NavigationFlowUpdateRequestDTO } from '../../generated/models/models';
import { StepSummaryData } from './step-summary-data';

export interface StepNavigationData {
  uuidEntity: string;
  type: StepNavigationTypeEnum;
  comment: string;
  nodeOrder: number;
  phase: PhaseSummaryData;
  step: StepSummaryData;
}

export namespace StepNavigationData {
  export const newData: StepNavigationData = {
    comment: null,
    nodeOrder: null,
    phase: null,
    step: null,
    type: StepNavigationTypeEnum.Trace,
    uuidEntity: null,
  };

  export interface QueryRequest extends BaseQuery<StepNavigationData> {
    stepUuidEntity: string;
  }

  export function mapFromApiValue(stepNavigation: NavigationFlowDetailsResponseDTO): StepNavigationData {
    return {
      uuidEntity: stepNavigation.uuidEntity,
      type: StepNavigationTypeEnum.convertFromApiValue.getValue(stepNavigation.natureStyle),
      comment: stepNavigation.description,
      phase: stepNavigation.linkedPhase && PhaseSummaryData.mapFromApiValue(stepNavigation.linkedPhase),
      step: stepNavigation.linkedStep && stepNavigation.linkedPhase && StepSummaryData.mapFromApiValue(stepNavigation.linkedStep, stepNavigation.linkedPhase.nodeOrderToDisplay),
      nodeOrder: stepNavigation.navigationOrder,
    };
  }

  export function mapToCreateRequestApiValue(currentStepUuidEntity: string, stepNavigation: StepNavigationData): NavigationFlowCreateRequestDTO {
    return {
      natureStyle: StepNavigationTypeEnum.convertToApiValue.getValue(stepNavigation.type),
      description: stepNavigation.comment,
      linkedPhaseUuidEntity: stepNavigation.phase.uuidEntity,
      linkedStepUuidEntity: stepNavigation.step.uuidEntity,
      currentStepUuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(stepNavigation: StepNavigationData): NavigationFlowUpdateRequestDTO {
    return {
      natureStyle: StepNavigationTypeEnum.convertToApiValue.getValue(stepNavigation.type),
      description: stepNavigation.comment,
      linkedPhaseUuidEntity: stepNavigation.phase.uuidEntity,
      linkedStepUuidEntity: stepNavigation.step.uuidEntity,
      navigationFlowUuidEntity: stepNavigation.uuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageNavigationFlowRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      stepUuidEntity: queries.stepUuidEntity,
    };
  }

  export function initializeQueryRequest(stepUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<StepNavigationData>({ key: 'navigationOrder', direction: InnerSortDirectionEnum.Asc }),
      stepUuidEntity,
    };
  }
}
