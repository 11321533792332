export interface SelectAllData {
  selectedUuids?: string[];
  excludedUuids?: string[];
  allSelected?: boolean;
  totalCount?: number;
  selectedCount?: number;
  disabledFilteredTotalCount?: number;
  atLeastOneItemSelected?: boolean;
}

export function getSelectedCount(selectedUuids: string[], excludedUuids: string[], allSelected: boolean, totalCount: number, disabledFilteredTotalCount?: number): number {
  return allSelected ? totalCount - (excludedUuids?.length || 0) - (disabledFilteredTotalCount || 0) : selectedUuids?.length;
}

export function isAtLeastOneItemSelected(selectedUuids: string[], allSelected: boolean): boolean {
  return allSelected || selectedUuids?.length > 0;
}
