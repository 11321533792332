/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';
import { SortDto } from './sortDto';

/**
 * PageFormResponseRequestDTO
 */
export interface PageFormResponseRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  formUuidEntity?: string;
  projectUuidEntity: string;
  entityProvenance?: PageFormResponseRequestDTO.EntityProvenanceEnum;
  provenance?: PageFormResponseRequestDTO.ProvenanceEnum;
  flowUuidEntities?: Array<string>;
  customStatusUuidEntity?: Array<string>;
  state?: PageFormResponseRequestDTO.StateEnum;
  dateFilterRequest?: DateFilterRequest;
}
export namespace PageFormResponseRequestDTO {
  export type EntityProvenanceEnum = 'FLOW' | 'PROJECT';
  export const EntityProvenanceEnum = {
    FLOW: 'FLOW' as EntityProvenanceEnum,
    PROJECT: 'PROJECT' as EntityProvenanceEnum,
  };
  export type ProvenanceEnum = 'MOBILE' | 'WEB';
  export const ProvenanceEnum = {
    MOBILE: 'MOBILE' as ProvenanceEnum,
    WEB: 'WEB' as ProvenanceEnum,
  };
  export type StateEnum = 'FORM' | 'RESPONSE' | 'RESPONSE_DONE' | 'AUTRE';
  export const StateEnum = {
    FORM: 'FORM' as StateEnum,
    RESPONSE: 'RESPONSE' as StateEnum,
    RESPONSEDONE: 'RESPONSE_DONE' as StateEnum,
    AUTRE: 'AUTRE' as StateEnum,
  };
}
