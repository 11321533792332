import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import { dummyAction } from '../../../../main/state/main.actions';

import { CustomFileDetailsData } from '../../../../../core/models';
import * as fromActions from './custom-file-dialog.actions';

export interface SettingsCustomFileDialogState {
  customFileDetails: CustomFileDetailsData;
  initialCustomFileDetails: CustomFileDetailsData;
  saveLoading: boolean;
}

export const initialSettingsCustomFileDialogState = (): SettingsCustomFileDialogState => ({
  customFileDetails: {} as CustomFileDetailsData,
  initialCustomFileDetails: {} as CustomFileDetailsData,
  saveLoading: false,
});

const reducer = createReducer<SettingsCustomFileDialogState>(
  initialSettingsCustomFileDialogState(),
  on(fromActions.addCustomFile, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.addCustomFileFail, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.addCustomFileSuccess, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.updateCustomFile, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateCustomFileFail, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.updateCustomFileSuccess, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.getCustomFileDetailsSuccess, (state, { customFileDetails }): SettingsCustomFileDialogState => ({ ...state, customFileDetails })),
  on(
    fromActions.initializeCustomFileDetails,
    (state, { customFileDetails }): SettingsCustomFileDialogState => ({
      ...state,
      initialCustomFileDetails: cloneDeep(customFileDetails),
    }),
  ),
  on(
    fromActions.initializeAnotherCustomFile,
    (state, { customFileDetails }): SettingsCustomFileDialogState => {
      const anotherCustomFile = {
        nature: customFileDetails.nature,
        extension: customFileDetails.extension,
        customFileType: customFileDetails.customFileType,
      } as CustomFileDetailsData;

      return {
        ...state,
        customFileDetails: anotherCustomFile,
        initialCustomFileDetails: cloneDeep(anotherCustomFile),
      };
    },
  ),
  on(dummyAction, (state): SettingsCustomFileDialogState => ({ ...state, saveLoading: false })),
);

export function settingsCustomFileDialogReducer(state: SettingsCustomFileDialogState | undefined, action: Action): SettingsCustomFileDialogState {
  return reducer(state, action);
}
