import { Action, createReducer, on } from '@ngrx/store';
import { FieldNameEnum } from '../../../../../core/enums';
import { ApplicationEventLightData, FormResponseSummaryData, FormToImportSummaryData, LazyLoadResponse } from '../../../../../core/models';
import { FormReplyDetails } from '../../../../../core/models/form/form-reply-details';
import * as fromFormActions from './form-dialog.actions';

export interface ImportDialogState {
  importForms: LazyLoadResponse<FormToImportSummaryData[]>;
  loading: boolean;
  reset: boolean;
  refresh: boolean;
  importLoading: boolean;
}

export interface ResponseDialogState {
  formResponses: LazyLoadResponse<FormResponseSummaryData[]>;
  actionsLoading: string;
  loading: boolean;
  reset: boolean;
  generateDocumentHasRefIntGenerated: boolean;
  generateDocumentHasRefExtGenerated: boolean;
  generateDocumentLoading: boolean;
  editReferenceLoading: boolean;
  editStatusLoading: boolean;
  loadingExportExcel: boolean;
  loadingExportImages: boolean;
  selectedResponses: string[];
  allResponseSelected: boolean;
}

export interface ReplyDialogState {
  replyLoading: boolean;
  formReplyDetails: FormReplyDetails;
}

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

export interface DashboardFormDialogState {
  importDialogState: ImportDialogState;
  responseDialogState: ResponseDialogState;
  replyDialogState: ReplyDialogState;
  historyDialogState: HistoryDialogState;
}

const initialImportDialogState = (): ImportDialogState => ({
  importForms: {} as LazyLoadResponse<FormToImportSummaryData[]>,
  loading: false,
  reset: false,
  refresh: false,
  importLoading: false,
});

const initialReplyDialogState = (): ReplyDialogState => ({
  replyLoading: false,
  formReplyDetails: {} as FormReplyDetails,
});

const initialResponseDialogState = (): ResponseDialogState => ({
  formResponses: {} as LazyLoadResponse<FormResponseSummaryData[]>,
  loading: false,
  actionsLoading: null,
  reset: false,
  generateDocumentHasRefIntGenerated: false,
  generateDocumentHasRefExtGenerated: false,
  generateDocumentLoading: false,
  editReferenceLoading: false,
  editStatusLoading: false,
  loadingExportExcel: false,
  loadingExportImages: false,
  selectedResponses: [],
  allResponseSelected: false,
});

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

export const initialDashboardFormDialogState = () => ({
  importDialogState: initialImportDialogState(),
  replyDialogState: initialReplyDialogState(),
  responseDialogState: initialResponseDialogState(),
  historyDialogState: initialHistoryDialogState(),
});

const reducer = createReducer<DashboardFormDialogState>(
  initialDashboardFormDialogState(),
  on(fromFormActions.loadForms, (state): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, loading: true } })),
  on(fromFormActions.updateImportedFormsRefresh, (state, { refresh }): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, refresh } })),
  on(fromFormActions.loadFormsFail, (state): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, loading: false } })),
  on(
    fromFormActions.loadFormsSuccess,
    (state, { forms, totalCount, filteredTotalCount, reset }): DashboardFormDialogState => ({
      ...state,
      importDialogState: {
        ...state.importDialogState,
        loading: false,
        reset,
        importForms: reset
          ? { payload: forms, totalCount, filteredTotalCount }
          : { ...state.importDialogState.importForms, payload: [...state.importDialogState.importForms.payload, ...forms] },
      },
    }),
  ),

  on(fromFormActions.importForms, (state): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: true } })),
  on(fromFormActions.importFormsFail, (state): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: false } })),
  on(fromFormActions.importFormsSuccess, (state): DashboardFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: false } })),

  on(fromFormActions.replyForm, (state): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: true } })),
  on(fromFormActions.replyFormFail, (state): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: false } })),
  on(fromFormActions.replyFormSuccess, (state): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: false } })),
  on(fromFormActions.loadFormReplyDetails, (state): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: true } })),
  on(
    fromFormActions.loadFormReplyDetailsSuccess,
    (state, { formReplyDetails }): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: false, formReplyDetails } }),
  ),
  on(fromFormActions.loadFormReplyDetailsFails, (state): DashboardFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: false } })),
  on(fromFormActions.loadResponsesFail, (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loading: false } })),
  on(
    fromFormActions.loadResponsesSuccess,
    (state, { responses, filteredTotalCount, reset }): DashboardFormDialogState => ({
      ...state,
      responseDialogState: {
        ...state.responseDialogState,
        loading: false,
        reset,
        formResponses: reset
          ? { payload: responses, filteredTotalCount }
          : { ...state.responseDialogState.formResponses, payload: [...(state.responseDialogState.formResponses.payload || []), ...responses] },
      },
    }),
  ),
  on(
    fromFormActions.setEditResponseReferenceLoading,
    (state, { loading }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, editReferenceLoading: loading } }),
  ),
  on(
    fromFormActions.setGenerateDocumentLoading,
    (state, { loading }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, generateDocumentLoading: loading } }),
  ),
  on(
    fromFormActions.setEditResponseStatusLoading,
    (state, { loading }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, editStatusLoading: loading } }),
  ),
  on(
    fromFormActions.setSelectedResponses,
    (state, { responsesUuidEntity }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, selectedResponses: responsesUuidEntity } }),
  ),
  on(
    fromFormActions.checkGenerateDocumentHasRefGeneratedSuccess,
    (state, { generateDocumentHasRefGenerated, fieldName }): DashboardFormDialogState => ({
      ...state,
      responseDialogState: {
        ...state.responseDialogState,
        generateDocumentHasRefIntGenerated: fieldName === FieldNameEnum.RefInt ? generateDocumentHasRefGenerated : state.responseDialogState.generateDocumentHasRefIntGenerated,
        generateDocumentHasRefExtGenerated: fieldName === FieldNameEnum.RefExt ? generateDocumentHasRefGenerated : state.responseDialogState.generateDocumentHasRefExtGenerated,
      },
    }),
  ),
  on(fromFormActions.exportExcelFile, (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportExcel: true } })),
  on(
    fromFormActions.setExportExcelLoader,
    fromFormActions.exportExcelFileFail,
    fromFormActions.exportExcelFileSuccess,
    (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportExcel: false } }),
  ),

  on(fromFormActions.exportImageFile, (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportImages: true } })),
  on(
    fromFormActions.setExportImageLoader,
    fromFormActions.exportImageFileSuccess,
    fromFormActions.exportImageFileFail,
    (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportImages: false } }),
  ),
  on(
    fromFormActions.loadHistoryListSuccess,
    (state, { data, reset }): DashboardFormDialogState => ({
      ...state,
      historyDialogState: {
        ...state.historyDialogState,
        reset,
        historyList: reset ? data : { ...state.historyDialogState.historyList, payload: [...state.historyDialogState.historyList.payload, ...data.payload] },
      },
    }),
  ),
  on(
    fromFormActions.duplicateResponse,
    (state, { responseUuidEntity }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, actionsLoading: responseUuidEntity } }),
  ),
  on(
    fromFormActions.duplicateResponseSuccess,
    fromFormActions.duplicateResponseFail,
    (state): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, actionsLoading: null } }),
  ),
  on(
    fromFormActions.setAllResponseSelected,
    (state, { allResponseSelected }): DashboardFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, allResponseSelected: allResponseSelected } }),
  ),
);

export function dashboardFormDialogReducer(state: DashboardFormDialogState | undefined, action: Action): DashboardFormDialogState {
  return reducer(state, action);
}
