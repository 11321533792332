/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';

export interface ResponseDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  reference: string;
  nature: ResponseDetailsResponseDTO.NatureEnum;
  provenance: ResponseDetailsResponseDTO.ProvenanceEnum;
  indice: string;
  creationDate: Date;
  rubricOrder: number;
  customStatus: CustomStatusLightDTO;
  lockForm: boolean;
}
export namespace ResponseDetailsResponseDTO {
  export type NatureEnum = 'Model' | 'Generic' | 'Autre' | 'LOCAL';
  export const NatureEnum = {
    Model: 'Model' as NatureEnum,
    Generic: 'Generic' as NatureEnum,
    Autre: 'Autre' as NatureEnum,
    LOCAL: 'LOCAL' as NatureEnum,
  };
  export type ProvenanceEnum = 'MOBILE' | 'WEB';
  export const ProvenanceEnum = {
    MOBILE: 'MOBILE' as ProvenanceEnum,
    WEB: 'WEB' as ProvenanceEnum,
  };
}
