import { Pipe, PipeTransform } from '@angular/core';
import { QuestionDurationData } from '../../../../core/models';
import { durationHandler } from '../../../../core/utils/time.utils';

@Pipe({
  name: 'questionDurationData',
})
export class QuestionDurationDataPipe implements PipeTransform {
  transform(value?: string | QuestionDurationData): QuestionDurationData {
    if (!value) {
      return {};
    }

    if (this.isQuestionDurationData(value)) {
      return value;
    }

    return {
      hours: durationHandler(value.substring(0, 2)),
      minutes: durationHandler(value.substring(3, 5), true),
    };
  }

  private isQuestionDurationData(value: string | QuestionDurationData): value is QuestionDurationData {
    return typeof value === 'object' && 'hours' in value && 'minutes' in value;
  }
}
