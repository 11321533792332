import { TooltipPositionType } from '../../../modules/shared/interfaces/tooltip-data';
import { KpiMetricEnum } from '../../enums';
import {
  KpiAudienceResponseDTO,
  KpiFieldResponseDTO,
  KpiFlowResponseDTO,
  KpiFormResponseDTO,
  KpiLicencesResponseDTO,
  KpiOfficeResponseDTO,
  KpiPreparationResponseDTO,
} from '../../generated/models/models';
import { ChartNodeData } from './chart-node-data';

export interface LineChartDatasets {
  label: string;
  data: ChartNodeData[];
  metric: KpiMetricEnum;
  color: string;
  size?: number;
  backgroundColor?: string;
  order?: number;
  stack?: string;
  type?: 'bar' | 'line';
  yAxis?: string;
  tooltipText?: string;
  tooltipCss?: string;
  tooltipPosition?: TooltipPositionType;
}

export namespace LineChartDatasets {
  export function mapToDataset(chart: KpiMetricEnum, nodes: ChartNodeData[]): LineChartDatasets {
    return {
      data: nodes,
      metric: chart,
      label: KpiMetricEnum.toString.getValue(chart),
      color: KpiMetricEnum.color.getValue(chart),
      backgroundColor: KpiMetricEnum.bgColor.getValue(chart),
      size: KpiMetricEnum.toSize.getValue(chart),
      type: KpiMetricEnum.toChartType.getValue(chart),
      stack: KpiMetricEnum.toStack.getValue(chart),
      order: KpiMetricEnum.toOrder.getValue(chart),
      yAxis: KpiMetricEnum.yAxis.getValue(chart),
      tooltipText: KpiMetricEnum.legendTooltip.getValue(chart),
      tooltipCss: KpiMetricEnum.legendTooltipCss.getValue(chart),
      tooltipPosition: KpiMetricEnum.legendTooltipPosition.getValue(chart),
    };
  }

  export function mapPreparationDatasets(data: KpiPreparationResponseDTO[]): LineChartDatasets[] {
    return [
      LineChartDatasets.mapToDataset(
        KpiMetricEnum.EntityReferential,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.preparedReferentialEntityCount, kpi.startAt, kpi.endAt)),
      ),
      LineChartDatasets.mapToDataset(
        KpiMetricEnum.FlowReferential,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.preparedReferentialFlowCount, kpi.startAt, kpi.endAt)),
      ),
      LineChartDatasets.mapToDataset(
        KpiMetricEnum.FormLibrary,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.preparedLibraryFormCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapAudienceDatasets(data: KpiAudienceResponseDTO[]): LineChartDatasets[] {
    return [
      mapToDataset(
        KpiMetricEnum.UserActive,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeUserCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.Users,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.totalUserCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.OperatorActive,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeOperatorCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.Operators,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.totalOperatorCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapOfficeDatasets(data: KpiOfficeResponseDTO[]): LineChartDatasets[] {
    return [
      mapToDataset(
        KpiMetricEnum.EntityProject,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdProjectEntityCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.Flows,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdFlowCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FormLocal,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdLocalFormCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapFieldDatasets(data: KpiFieldResponseDTO[]): LineChartDatasets[] {
    return [
      mapToDataset(
        KpiMetricEnum.SignatureRealized,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.signatoryCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FormResponse,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.formResponseCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapFormDatasets(data: KpiFormResponseDTO[]): LineChartDatasets[] {
    return [
      mapToDataset(
        KpiMetricEnum.FormGeneric,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdGenericFormResponseCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FormModel,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdModelFormResponseCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FormResponseLocal,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdLocalFormResponseCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FormViaTablet,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.formResponseSentFromMobileAppCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapFlowDatasets(data: KpiFlowResponseDTO[]): LineChartDatasets[] {
    return [
      // mapToDataset(
      //   KpiMetricEnum.FlowEmm,
      //   data.map(kpi => ChartNodeData.mapToNodeData(kpi.createdLocalFormResponseCount, kpi.startAt, kpi.endAt)),
      // ),
      mapToDataset(
        KpiMetricEnum.FlowSpecific,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeSpecificFlowCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FlowGeneric,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeGenericFlowCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FlowGroup,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeGroupFlowCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.FlowForm,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeInterventionCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }

  export function mapLicensesDetailsDatasets(data: KpiLicencesResponseDTO[]): LineChartDatasets[] {
    return [
      mapToDataset(
        KpiMetricEnum.WebUsers,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.webUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.WebUsersActive,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeWebUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.MobileUsers,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.mobileUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.MobileUsersActive,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeMobileUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.MobileUsersActiveUnregistered,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.externalMobileUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.DeviceUsers,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.tabletUsersCount, kpi.startAt, kpi.endAt)),
      ),
      mapToDataset(
        KpiMetricEnum.DeviceActive,
        data.map(kpi => ChartNodeData.mapToNodeData(kpi.activeTabletUsersCount, kpi.startAt, kpi.endAt)),
      ),
    ];
  }
}
