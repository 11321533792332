import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { FlowSummaryResponseDTO } from '../../generated/models/models';

import { SfxMap } from '../../utils/enum-utils';
import { ProjectTypeEnum } from '../project/project-type.enum';

export enum FlowTypeEnum {
  Generic = 1,
  Referential, // standard
  Template,
  Specific,
  Group,
  FlowForm,
  GroupReferential, // standard head
  LocalReferential,
  ReferentialGeneric, // standard generic
  ReferentialForm, // standard form
}

export namespace FlowTypeEnum {
  export const values = [FlowTypeEnum.Specific, FlowTypeEnum.Generic, FlowTypeEnum.Group, FlowTypeEnum.FlowForm];
  export const displayGroup = [FlowTypeEnum.GroupReferential, FlowTypeEnum.Group];
  export const flowsReferentialGeneric = [FlowTypeEnum.Generic, FlowTypeEnum.ReferentialGeneric];
  export const flowsWithoutOrphanResponses = [FlowTypeEnum.Generic, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.Template];
  export const referentialValues = [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm];
  export const templateValues = [FlowTypeEnum.Template];
  export const referentialEntityValues = [FlowTypeEnum.Referential, FlowTypeEnum.Group, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm];
  export const flowsReferentialForExternalParentValidation = [FlowTypeEnum.GroupReferential];
  export const flowsReferentialForExternalValidation = [FlowTypeEnum.Referential, FlowTypeEnum.ReferentialForm];
  export const referentialValuesForExecuteFlow = [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialForm];
  export const flowsWhereDocumentCanBeImported = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereFormHistoryCanBeViewed = [FlowTypeEnum.Specific, FlowTypeEnum.Generic, FlowTypeEnum.Group, FlowTypeEnum.FlowForm, FlowTypeEnum.ReferentialForm];
  export const flowsWhereBlockStatusPropertyCanBeViewed = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
  ];
  export const flowsWhereBlockDsiHistoryCanBeViewed = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereBlocksCanBeValidated = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
  ];
  export const flowsWhereFormCanBeReplied = [FlowTypeEnum.Specific, FlowTypeEnum.FlowForm, FlowTypeEnum.Group];
  export const flowsWhereFormActionCanBeDisplayed = [FlowTypeEnum.FlowForm, FlowTypeEnum.ReferentialForm];
  export const flowsWhereFormCanBeShared = [FlowTypeEnum.Specific, FlowTypeEnum.FlowForm];
  export const flowsWhereFormRexCanBeImported = [FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.GroupReferential];
  export const flowsWhereSummaryCanBeDiaplayed = [FlowTypeEnum.Specific, FlowTypeEnum.FlowForm, FlowTypeEnum.Group, FlowTypeEnum.ReferentialForm];
  export const flowsWhereMenuCanBeViewed = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Template,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
  ];
  export const flowsWhereStepCanBeLinked = [FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.GroupReferential, FlowTypeEnum.Referential];
  export const signatoryFlows = [FlowTypeEnum.Specific, FlowTypeEnum.Group];
  export const flowsWhereReferentialFlowsCanBeImported = [FlowTypeEnum.Specific];
  export const firstFlowTypeToImport = new SfxMap<FlowTypeEnum, FlowTypeEnum>(
    [
      [FlowTypeEnum.Generic, FlowTypeEnum.ReferentialGeneric],
      [FlowTypeEnum.Specific, FlowTypeEnum.Referential],
      [FlowTypeEnum.FlowForm, FlowTypeEnum.ReferentialForm],
    ],
    FlowTypeEnum.Template,
  );
  export const flowsWhereCanImportTemplate = [FlowTypeEnum.Template, FlowTypeEnum.Specific, FlowTypeEnum.Generic, FlowTypeEnum.Referential, FlowTypeEnum.ReferentialGeneric];
  export const flowsWhereImportFlowContentIsEnabled = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Template,
    FlowTypeEnum.Referential,
    FlowTypeEnum.ReferentialGeneric,
  ];
  export const flowsWhereFormCanOverviewFromAction = [FlowTypeEnum.Template, FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential];
  export const flowsWhereFormCanOverviewFromMenuAction = [
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereDuplicateFLow = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.Template,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.FlowForm,
  ];
  export const flowsWhereInfoTitleCanBeViewed = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereInfoTextCanBeViewed = [FlowTypeEnum.Generic, FlowTypeEnum.Group, FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric];
  export const flowsWhereAdditionalInfoCanBeViewed = [FlowTypeEnum.Specific];
  export const flowsWhereInterventionInfoCanBeViewed = [FlowTypeEnum.FlowForm, FlowTypeEnum.ReferentialForm];
  export const flowsWhereBlocksCanBeCopied = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Template,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
  ];
  export const flowsWhereRevisionCanBeDone = [FlowTypeEnum.Specific];
  export const flowsWhereHaveAllTab = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereBlockMultiPrStatusCanBeChanged = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.Group,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsWhereSequenceCanBeAdded = [FlowTypeEnum.Specific];
  export const flowsWhereSequenceViewCanBeToggled = [FlowTypeEnum.Group, FlowTypeEnum.GroupReferential];
  export const flowsWhereFromStepCanBeCreated = [FlowTypeEnum.Group, FlowTypeEnum.GroupReferential];
  export const flowsTypesWhereFromStepCanBeCreated = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Generic,
    FlowTypeEnum.FlowForm,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.ReferentialGeneric,
    FlowTypeEnum.ReferentialForm,
  ];
  export const flowsTypesWhichCanBeLinked = [FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.Generic, FlowTypeEnum.FlowForm];
  export const flowsRefTypesWhichCanBeLinked = [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm];
  export const flowsWhereCanBackToReferentialModule = [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm];
  export const flowsWhereCanSetSupervisions = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Group,
    FlowTypeEnum.Referential,
    FlowTypeEnum.GroupReferential,
    FlowTypeEnum.LocalReferential,
    FlowTypeEnum.Template,
  ];

  export const flowsWhereCanDeleteAndResetContent = [
    FlowTypeEnum.Specific,
    FlowTypeEnum.Referential,
    FlowTypeEnum.Template,
    FlowTypeEnum.Generic,
    FlowTypeEnum.LocalReferential,
    FlowTypeEnum.ReferentialGeneric,
  ];

  export const displayHideColumn = (projectType: ProjectTypeEnum, docLinkedFlowType?: FlowTypeEnum) =>
    compact([
      projectType === ProjectTypeEnum.Project && FlowTypeEnum.Specific,
      projectType === ProjectTypeEnum.Project && FlowTypeEnum.Group,
      projectType === ProjectTypeEnum.Project && docLinkedFlowType === FlowTypeEnum.Referential && FlowTypeEnum.Referential,
      projectType === ProjectTypeEnum.Referential && FlowTypeEnum.Referential,
      projectType === ProjectTypeEnum.Referential && FlowTypeEnum.GroupReferential,
    ]);

  export const convertFromApiValue: SfxMap<FlowSummaryResponseDTO.TypeFlowEnum, FlowTypeEnum> = new SfxMap<FlowSummaryResponseDTO.TypeFlowEnum, FlowTypeEnum>([
    [FlowSummaryResponseDTO.TypeFlowEnum.GENERIC, FlowTypeEnum.Generic],
    [FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIAL, FlowTypeEnum.Referential],
    [FlowSummaryResponseDTO.TypeFlowEnum.TEMPLATE, FlowTypeEnum.Template],
    [FlowSummaryResponseDTO.TypeFlowEnum.SPECIFIC, FlowTypeEnum.Specific],
    [FlowSummaryResponseDTO.TypeFlowEnum.GROUP, FlowTypeEnum.Group],
    [FlowSummaryResponseDTO.TypeFlowEnum.FLOWFORM, FlowTypeEnum.FlowForm],
    [FlowSummaryResponseDTO.TypeFlowEnum.GROUPREFERENTIAL, FlowTypeEnum.GroupReferential],
    [FlowSummaryResponseDTO.TypeFlowEnum.LOCALREFERENTIAL, FlowTypeEnum.LocalReferential],
    [FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIALGENERIC, FlowTypeEnum.ReferentialGeneric],
    [FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIALINTERVENTION, FlowTypeEnum.ReferentialForm],
  ]);

  export const convertToApiValue = new SfxMap<FlowTypeEnum, FlowSummaryResponseDTO.TypeFlowEnum>([
    [FlowTypeEnum.Generic, FlowSummaryResponseDTO.TypeFlowEnum.GENERIC],
    [FlowTypeEnum.Referential, FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIAL],
    [FlowTypeEnum.Template, FlowSummaryResponseDTO.TypeFlowEnum.TEMPLATE],
    [FlowTypeEnum.Specific, FlowSummaryResponseDTO.TypeFlowEnum.SPECIFIC],
    [FlowTypeEnum.Group, FlowSummaryResponseDTO.TypeFlowEnum.GROUP],
    [FlowTypeEnum.FlowForm, FlowSummaryResponseDTO.TypeFlowEnum.FLOWFORM],
    [FlowTypeEnum.GroupReferential, FlowSummaryResponseDTO.TypeFlowEnum.GROUPREFERENTIAL],
    [FlowTypeEnum.LocalReferential, FlowSummaryResponseDTO.TypeFlowEnum.LOCALREFERENTIAL],
    [FlowTypeEnum.ReferentialGeneric, FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIALGENERIC],
    [FlowTypeEnum.ReferentialForm, FlowSummaryResponseDTO.TypeFlowEnum.REFERENTIALINTERVENTION],
  ]);

  export const convertFromParamsValue = new SfxMap<string, FlowTypeEnum>([
    ['specific', FlowTypeEnum.Specific],
    ['generic', FlowTypeEnum.Generic],
    ['group', FlowTypeEnum.Group],
    ['template', FlowTypeEnum.Template],
    ['referential', FlowTypeEnum.Referential],
    ['intervention', FlowTypeEnum.FlowForm],
    ['referentialGroup', FlowTypeEnum.GroupReferential],
    ['localReferential', FlowTypeEnum.LocalReferential],
    ['genericReferential', FlowTypeEnum.ReferentialGeneric],
    ['formReferential', FlowTypeEnum.ReferentialForm],
  ]);

  export const convertToParamsValue = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Specific, 'specific'],
    [FlowTypeEnum.Generic, 'generic'],
    [FlowTypeEnum.Template, 'template'],
    [FlowTypeEnum.Referential, 'referential'],
    [FlowTypeEnum.Group, 'group'],
    [FlowTypeEnum.FlowForm, 'intervention'],
    [FlowTypeEnum.GroupReferential, 'referentialGroup'],
    [FlowTypeEnum.LocalReferential, 'localReferential'],
    [FlowTypeEnum.ReferentialGeneric, 'genericReferential'],
    [FlowTypeEnum.ReferentialForm, 'formReferential'],
  ]);

  const key = 'flows.types.';
  export const toString = (isProjectPGAC?: boolean): SfxMap<FlowTypeEnum, string> =>
    new SfxMap<FlowTypeEnum, string>(
      [
        [FlowTypeEnum.Specific, key + `${isProjectPGAC ? 'pgac' : 'specific'}`],
        [FlowTypeEnum.Generic, key + 'generic'],
        [FlowTypeEnum.Group, key + 'group'],
        [FlowTypeEnum.FlowForm, key + 'form'],
        [FlowTypeEnum.Template, key + 'template'],
        [FlowTypeEnum.Referential, key + 'ref'],
        [FlowTypeEnum.GroupReferential, key + 'groupReferential'],
        [FlowTypeEnum.LocalReferential, key + 'localReferential'],
        [FlowTypeEnum.ReferentialGeneric, key + 'genericReferential'],
        [FlowTypeEnum.ReferentialForm, key + 'formReferential'],
      ],
      'Type',
    );

  const letterKey = 'flows.letter.';
  export const toLetter: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Generic, letterKey + 'generic'],
    [FlowTypeEnum.Specific, letterKey + 'specific'],
    [FlowTypeEnum.Referential, letterKey + 'referential'],
    [FlowTypeEnum.Group, letterKey + 'group'],
    [FlowTypeEnum.FlowForm, letterKey + 'flowForm'],
    [FlowTypeEnum.Template, letterKey + 'template'],
    [FlowTypeEnum.GroupReferential, letterKey + 'group'],
    [FlowTypeEnum.ReferentialGeneric, letterKey + 'generic'],
    [FlowTypeEnum.ReferentialForm, letterKey + 'flowForm'],
  ]);

  export const color: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Generic, 'sfx-chip-blue-pigment'],
    [FlowTypeEnum.Specific, 'sfx-chip-sage'],
    [FlowTypeEnum.Referential, 'sfx-chip-purple-ref'],
    [FlowTypeEnum.Group, 'sfx-chip-blue-clair'],
    [FlowTypeEnum.FlowForm, 'sfx-chip-sfx-black'],
    [FlowTypeEnum.Template, 'sfx-chip-mint'],
    [FlowTypeEnum.GroupReferential, 'sfx-chip-blue-clair'],
    [FlowTypeEnum.ReferentialGeneric, 'sfx-chip-blue-pigment'],
    [FlowTypeEnum.ReferentialForm, 'sfx-chip-sfx-black'],
  ]);

  export const hexaColor: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Generic, '#3b3b98'],
    [FlowTypeEnum.Specific, '#bdc581'],
    [FlowTypeEnum.Referential, '#b171c9'],
    [FlowTypeEnum.Group, '#d9e3ec'],
    [FlowTypeEnum.FlowForm, '#000000'],
    [FlowTypeEnum.Template, '#36b37e'],
    [FlowTypeEnum.GroupReferential, '#d9e3ec'],
    [FlowTypeEnum.ReferentialGeneric, '#3b3b98'],
    [FlowTypeEnum.ReferentialForm, '#000000'],
  ]);

  export const buttonColor: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Generic, 'sfx-button-blue-pigment'],
    [FlowTypeEnum.Specific, 'sfx-button-sage'],
    [FlowTypeEnum.Referential, 'sfx-button-purple-ref'],
    [FlowTypeEnum.Group, 'sfx-button-blue-clair'],
    [FlowTypeEnum.FlowForm, 'sfx-button-sfx-black'],
    [FlowTypeEnum.Template, 'sfx-button-mint'],
    [FlowTypeEnum.GroupReferential, 'sfx-button-blue-clair'],
    [FlowTypeEnum.ReferentialGeneric, 'sfx-button-blue-pigment'],
    [FlowTypeEnum.ReferentialForm, 'sfx-button-sfx-black'],
  ]);
  export const defaultColorClass = (isProjectPGAC?: boolean): SfxMap<FlowTypeEnum, string> =>
    new SfxMap<FlowTypeEnum, string>(
      [
        [FlowTypeEnum.Specific, isProjectPGAC ? 'project-pgac fa-rotate-270' : 'sage fa-rotate-270'],
        [FlowTypeEnum.Generic, 'blue-pigment fa-rotate-270'],
        [FlowTypeEnum.Group, 'blue-clair fa-rotate-270'],
        [FlowTypeEnum.FlowForm, 'black fa-rotate-270'],
        [FlowTypeEnum.Template, 'project-model fa-rotate-270'],
        [FlowTypeEnum.Referential, 'project-referential fa-rotate-270'],
        [FlowTypeEnum.GroupReferential, 'blue-clair fa-rotate-270'],
        [FlowTypeEnum.ReferentialGeneric, 'blue-pigment fa-rotate-270'],
        [FlowTypeEnum.ReferentialForm, 'black fa-rotate-270'],
      ],
      'default fa-rotate-270',
    );

  const tooltipKey = 'flows.types.tooltip.';
  export const tooltip: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Specific, tooltipKey + 'specific'],
    [FlowTypeEnum.Generic, tooltipKey + 'generic'],
    [FlowTypeEnum.Group, tooltipKey + 'group'],
    [FlowTypeEnum.FlowForm, tooltipKey + 'form'],
    [FlowTypeEnum.Referential, tooltipKey + 'ref'],
    [FlowTypeEnum.GroupReferential, tooltipKey + 'groupReferential'],
    [FlowTypeEnum.ReferentialGeneric, tooltipKey + 'genericReferential'],
    [FlowTypeEnum.ReferentialForm, tooltipKey + 'formReferential'],
  ]);

  const duplicateTitleKey = 'flows.modals.duplicateFlow.titles.';
  export const duplicateTitle: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Specific, duplicateTitleKey + 'specific'],
    [FlowTypeEnum.Referential, duplicateTitleKey + 'referential'],
    [FlowTypeEnum.Template, duplicateTitleKey + 'template'],
    [FlowTypeEnum.Generic, duplicateTitleKey + 'generic'],
    [FlowTypeEnum.Group, duplicateTitleKey + 'group'],
    [FlowTypeEnum.FlowForm, duplicateTitleKey + 'flowForm'],
    [FlowTypeEnum.ReferentialGeneric, duplicateTitleKey + 'genericReferential'],
    [FlowTypeEnum.ReferentialForm, duplicateTitleKey + 'formReferential'],
  ]);

  const infoKey = 'flows.modals.duplicateFlow.info.';
  export const displayedInfo: SfxMap<FlowTypeEnum, string> = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Generic, infoKey + 'content'],
    [FlowTypeEnum.Group, infoKey + 'content'],
    [FlowTypeEnum.Referential, infoKey + 'content'],
    [FlowTypeEnum.Specific, infoKey + 'specificContent'],
    [FlowTypeEnum.FlowForm, infoKey + 'interventionContent'],
    [FlowTypeEnum.ReferentialGeneric, infoKey + 'content'],
    [FlowTypeEnum.ReferentialForm, infoKey + 'interventionContent'],
  ]);

  const executeModalTitleKey = 'referentialFlows.executeFlow.title.';
  export const executeModalTitle = new SfxMap<FlowTypeEnum, string>([
    [FlowTypeEnum.Referential, executeModalTitleKey + 'referential'],
    [FlowTypeEnum.GroupReferential, executeModalTitleKey + 'referentialGroup'],
    [FlowTypeEnum.ReferentialForm, executeModalTitleKey + 'formReferential'],
  ]);

  export function mapToMultiSelectItemData(type: FlowTypeEnum) {
    return {
      value: type,
      color: color.getValue(type),
      title: toLetter.getValue(type),
      tooltip: tooltip.getValue(type),
    };
  }

  export const selectItems: MultiSelectItemData<FlowTypeEnum>[] = values.map(mapToMultiSelectItemData);

  export const itemsResolver = (): Observable<SingleSelectItemData<FlowTypeEnum>[]> =>
    of(
      values.map(value => ({
        value,
        color: color.getValue(value),
        title: toLetter.getValue(value),
        tooltip: tooltip.getValue(value),
      })),
    );

  export const selectReferentialItems: MultiSelectItemData<FlowTypeEnum>[] = referentialValues.map(mapToMultiSelectItemData);
  export const selectReferentialItemsToExecute: MultiSelectItemData<FlowTypeEnum>[] = referentialValuesForExecuteFlow.map(mapToMultiSelectItemData);

  export const selectFullItems = (projectType: ProjectTypeEnum): Observable<SingleSelectItemData<FlowTypeEnum>[]> =>
    of(
      (projectType === ProjectTypeEnum.Referential ? referentialValues : values).map(type => ({
        value: type,
        title: toString(projectType === ProjectTypeEnum.Pgac).getValue(type),
      })),
    );

  export const multiSelectFullItems = (projectType: ProjectTypeEnum) => (): Observable<MultiSelectItemData<FlowTypeEnum>[]> =>
    of(
      (projectType === ProjectTypeEnum.Referential ? referentialValues : values).map(type => ({
        value: type,
        title: toString(projectType === ProjectTypeEnum.Pgac).getValue(type),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FlowTypeEnum>, item: FlowTypeEnum) => node.value === item;

  export const selectedItemValues = values.map(type => ({ value: type, title: toString().getValue(type) }));

  export const multiSelectItemsValues = (): Observable<MultiSelectItemData<FlowTypeEnum>[]> => of(values.map(type => ({ value: type, title: toString().getValue(type) })));

  export const selectedItemValuesForSteps = flowsTypesWhichCanBeLinked.map(type => ({ value: type, title: toString().getValue(type) }));
  export const selectedReferentialItemValues = referentialValues.map(type => ({ value: type, title: toString().getValue(type) }));
  export const selectedReferentialEntityItemValues = referentialValues.map(type => ({ value: type, title: toString().getValue(type) }));

  export const multiSelectedReferentialEntityItemValues = (): Observable<MultiSelectItemData<FlowTypeEnum>[]> =>
    of(referentialValues.map(type => ({ value: type, title: toString().getValue(type) })));

  export function isNotLastVersionOrIsReferential(documentType: FlowTypeEnum, isRefProject: boolean, isLastDocumentVersion: boolean): boolean {
    const isReferential = FlowTypeEnum.referentialValues.includes(documentType) && !isRefProject;
    return isReferential || !isLastDocumentVersion;
  }
}
