import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';

export enum ObjectFlowActionEnum {
  Delete = 1,
  Display = 2,
}

export namespace ObjectFlowActionEnum {
  const key = 'flows.table.actions.';

  export const toString: SfxMap<ObjectFlowActionEnum, string> = new SfxMap<ObjectFlowActionEnum, string>(
    [
      [ObjectFlowActionEnum.Delete, key + 'delete'],
      [ObjectFlowActionEnum.Display, key + 'display'],
    ],
    'Unknown',
  );

  export const iconData: SfxMap<ObjectFlowActionEnum, IconData> = new SfxMap<ObjectFlowActionEnum, IconData>([
    [ObjectFlowActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [ObjectFlowActionEnum.Display, { name: 'external-link-alt', prefix: 'fas' }],
  ]);

  export function mapToActionMenuItem(column: ObjectFlowActionEnum, permission: PermissionActionEnum): ActionSelectItemData<ObjectFlowActionEnum> {
    return {
      name: toString.getValue(column),
      permissionAction: permission,
      value: column,
      icon: iconData.getValue(column),
    };
  }

  export function getActionMenu(historyFlowTable: boolean): ActionSelectItemData<ObjectFlowActionEnum> {
    return historyFlowTable
      ? mapToActionMenuItem(ObjectFlowActionEnum.Display, PermissionActionEnum.Read)
      : mapToActionMenuItem(ObjectFlowActionEnum.Delete, PermissionActionEnum.Delete);
  }
}
