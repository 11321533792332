import { TagLightDTO } from '../../generated/models/models';

export interface TagLightData {
  uuidEntity: string;
  name: string;
  description?: string;
  color?: string;
}

export namespace TagLightData {
  export function mapFromApiValue(tagLight: TagLightDTO): TagLightData {
    return { uuidEntity: tagLight.uuidEntity, name: tagLight.name, description: tagLight.description, color: tagLight.color };
  }
}
