import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { ClientDetailsData } from '../../../../../core/models';

export const addClient = createAction('[sClientDialog] add new client', props<{ client: ClientDetailsData }>());
export const addClientSuccess = createAction('[sClientDialog] add new client success', props<{ clientDetails: ClientDetailsData }>());
export const addClientFail = createAction('[sClientDialog] add new client fail', props<DialogToasterData>());

export const updateClient = createAction('[sClientDialog] update client', props<{ client: ClientDetailsData }>());
export const updateClientSuccess = createAction('[sClientDialog] update client success', props<{ clientDetails: ClientDetailsData }>());
export const updateClientFail = createAction('[sClientDialog] update client fail', props<DialogToasterData>());

export const getClientDetailsSuccess = createAction('[sClientDialog] get client details success', props<{ clientDetails: ClientDetailsData }>());
export const getClientDetailsFail = createAction('[sClientDialog] get client details fail', props<DialogToasterData>());

export const initializeClientDetails = createAction('[sClientDialog] initialize client details', props<{ clientDetails: ClientDetailsData }>());

export const saveChangesClientDialog = createAction('[sClientDialog] save changes client dialog', props<{ client: ClientDetailsData; fileRef: File }>());

export const uploadPdfFrameClientFail = createAction('[sClientDialog] upload pdf frame client fail', props<DialogToasterData>());

export const uploadLogoClient = createAction('[sClientDialog] upload logo client', props<{ logo: string }>());
