import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { AnimationEvent } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../icon/icon.component';
import { LabelComponent } from '../label/label.component';

import { ToastOverlayRef } from './toast-overlay-ref';
import { toastAnimations } from './toast-animation';
import { ToasterData, configData } from './toaster-config';
import { ToastAnimationStateEnum } from '../../../../core/enums/utils/toast-animation.enum';
import { ToasterTypeEnum } from '../../../../core/enums/utils/toaster-type.enum';

@Component({
  selector: 'sfx-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [toastAnimations.toastAnimation],
  standalone: true,
  imports: [CommonModule, TranslateModule, OverlayModule, IconComponent, LabelComponent],
})
export class ToasterComponent implements OnInit, OnDestroy {
  public animationState = ToastAnimationStateEnum.Default;
  public iconName: string;
  public configData = configData;
  private timeoutIntervalID: NodeJS.Timer;

  constructor(public data: ToasterData, private toastOverlayRef: ToastOverlayRef) {
    this.iconName = ToasterTypeEnum.iconData.getValue(data.type).name;
  }

  ngOnInit(): void {
    this.timeoutIntervalID = setTimeout(() => (this.animationState = ToastAnimationStateEnum.Closing), this.data?.delay || configData.delay);
  }

  ngOnDestroy(): void {
    if (this.timeoutIntervalID) {
      clearTimeout(this.timeoutIntervalID);
    }
    document.getElementsByClassName('sfx-tooltip')?.item(0)?.remove();
  }

  public close(): void {
    this.toastOverlayRef.close();
  }

  public animationDone(event: AnimationEvent): void {
    const fadeOutFinished = (event.toState as ToastAnimationStateEnum) === ToastAnimationStateEnum.Closing;
    const animationDone = this.animationState === ToastAnimationStateEnum.Closing;

    if (fadeOutFinished && animationDone) {
      this.close();
    }
  }
}
