export function durationHandler(value: string, isMinute?: boolean): string {
  const maxHours = 23;
  const maxMinutes = 59;
  if (!value || isNaN(+value)) {
    value = '00';
  }
  let duration = value;
  if (duration?.length === 1) {
    duration = `0${duration}`;
  }

  if (duration?.length >= 2) {
    duration = duration.substring(0, 2);
    const max = isMinute ? maxMinutes : maxHours;
    duration = (+duration > max ? '00' : duration) + '';
  }
  return duration;
}
