import { FileTreatmentRequestDTO } from '../../generated/models/models';

export namespace FileProcessingRequest {
  export function mapToRequestApiValue(uuideCustomFile: string, uuideApplicationFile: string, separator: string): FileTreatmentRequestDTO {
    return {
      uuideCustomFile,
      uuideApplicationFile,
      separator,
    };
  }
}
