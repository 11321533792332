import { CreateFormContributionResponseDto } from '../../generated/models/createFormContributionResponseDto';

export interface CreateContributionResponseData {
  uuidEntity: string;
}

export namespace CreateContributionResponseData {
  export function mapFromApiValue(data: CreateFormContributionResponseDto): CreateContributionResponseData {
    return { uuidEntity: data.responseUuide };
  }
}
