import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum RiskModalTabEnum {
  General = 'general',
  Parade = 'parade',
}

export namespace RiskModalTabEnum {
  const key = 'riskLibrary.dialog.';
  export const toString: SfxMap<RiskModalTabEnum, string> = new SfxMap<RiskModalTabEnum, string>([
    [RiskModalTabEnum.General, key + 'general'],
    [RiskModalTabEnum.Parade, key + 'parade'],
  ]);

  export const iconData: SfxMap<RiskModalTabEnum, IconData> = new SfxMap<RiskModalTabEnum, IconData>([
    [RiskModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [RiskModalTabEnum.Parade, { name: 'shield-alt', prefix: 'fas' }],
  ]);
}
