import { DateUtils } from '../../utils/date.utils';

import { FlowPropsResponseDTO } from '../../generated/models/models';

import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserLightData } from '../user/user-light-data';

import { FlowTypeEnum } from '../../enums';

export interface FlowPropertiesData {
  name: string;
  type: FlowTypeEnum;
  family: EntityLightData;
  applicabilityName: string;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
  entitySource: string;
  entityIndex: string;
}

export namespace FlowPropertiesData {
  export function mapFromApiValue(flowProperties: FlowPropsResponseDTO): FlowPropertiesData {
    return {
      name: flowProperties.name,
      type: flowProperties.type && FlowTypeEnum.convertFromApiValue.getValue(flowProperties.type),
      family: flowProperties.family && LabelFamilyLightData.mapToEntityLightData(flowProperties.family),
      applicabilityName: flowProperties.applicabilityName,
      creationDate: DateUtils.toDateTimeFormat(flowProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(flowProperties.modifcationDate),
      createdBy: flowProperties.createdBy && UserLightData.mapFromApiValue(flowProperties.createdBy),
      lastModifiedBy: flowProperties.lastModifiedBy && UserLightData.mapFromApiValue(flowProperties.lastModifiedBy),
      entitySource: flowProperties.entitySource,
      entityIndex: flowProperties.entityIndex,
    };
  }
}
