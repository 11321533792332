import { AccessApiV2SummaryData } from '../../models';
import { SfxMap } from '../../utils/enum-utils';

export enum AccessApiV2ColumnEnum {
  Name = 'name',
  Applicability = 'applicability',
  AccessLevel = 'accessLevel',
  Status = 'status',
  Actions = 'action',
}

export namespace AccessApiV2ColumnEnum {
  export type accessApiSortField = keyof AccessApiV2SummaryData;

  export const allColumns = [
    AccessApiV2ColumnEnum.Name,
    AccessApiV2ColumnEnum.Applicability,
    AccessApiV2ColumnEnum.AccessLevel,
    AccessApiV2ColumnEnum.Status,
    AccessApiV2ColumnEnum.Actions,
  ];

  const key = 'accessApi.v2.table.header.';
  export const toString: SfxMap<AccessApiV2ColumnEnum, string> = new SfxMap<AccessApiV2ColumnEnum, string>([
    [AccessApiV2ColumnEnum.Name, key + 'name'],
    [AccessApiV2ColumnEnum.Applicability, key + 'applicability'],
    [AccessApiV2ColumnEnum.AccessLevel, key + 'accessLevel'],
    [AccessApiV2ColumnEnum.Status, key + 'status'],
    [AccessApiV2ColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<AccessApiV2ColumnEnum, accessApiSortField> = new SfxMap<AccessApiV2ColumnEnum, accessApiSortField>([[AccessApiV2ColumnEnum.Name, 'name']]);
}
