import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getDateFilters, getListQueryParam, getQueryParam, getRouteParam, getSortFilters } from '../../../../modules/router';

import { RouteParamEnum, RouteQueryParamEnum, StateName } from '../../../../core/enums';
import { FlowSummaryData, SignatureSummaryData } from '../../../../core/models';
import * as fromSignatures from './signatures.reducer';

const signaturesState = createFeatureSelector<fromSignatures.SignaturesState>(StateName.Signatures);

export const getSelectedFlow = createSelector(signaturesState, state => state.selectedFlow);
export const getSelectedFlowName = createSelector(getSelectedFlow, flowLightData => (flowLightData && flowLightData.name) || 'flowSignature.table.noSelectedComment');

const getLoadedPhases = createSelector(signaturesState, fromSignatures.selectAllPhases);
const getPhasesCount = createSelector(signaturesState, state => state.phasesCount);
const getReset = createSelector(signaturesState, state => state.reset);
export const getLoadedSteps = createSelector(signaturesState, state => state.steps);
export const getSignaturePhases = createSelector(getLoadedPhases, getPhasesCount, getReset, getLoadedSteps, (phases, totalCount, reset, steps) => ({
  phases,
  totalCount,
  reset,
  steps,
}));

const getSignedSignatures = createSelector(signaturesState, state => state.progressSignatures && state.progressSignatures.signaturesSigned);
export const getFilteredSignaturesCount = createSelector(signaturesState, state => state.progressSignatures && state.progressSignatures.filteredCount);
export const getSignaturesCount = createSelector(signaturesState, state => state.progressSignatures && state.progressSignatures.signaturesCount);
export const getProgress = createSelector(getSignedSignatures, getSignaturesCount, (signed, count) => (signed && count && Math.round((signed / count) * 100)) || 0);
export const getSignaturesLoading = createSelector(signaturesState, state => state.loading);

// Flow filters:
export const getSignaturesFlowFilters = createSelector(
  getListQueryParam(RouteQueryParamEnum.Status),
  getListQueryParam(RouteQueryParamEnum.Family), // not yet implemented
  getListQueryParam(RouteQueryParamEnum.Category), // not yet implemented
  getListQueryParam(RouteQueryParamEnum.Assignee), // not yet implemented
  status => !!status?.length,
);

// Flow queries:
export const getSignaturesFlowsQueryParams = createSelector(
  getRouteParam(RouteParamEnum.ProjectUuidEntity),
  getListQueryParam(RouteQueryParamEnum.Family),
  getListQueryParam(RouteQueryParamEnum.Category),
  getListQueryParam(RouteQueryParamEnum.Status),
  getListQueryParam(RouteQueryParamEnum.Assignee),
  FlowSummaryData.initializeSignatureQueryRequest,
);

const getFilters = createSelector(
  getListQueryParam(RouteQueryParamEnum.Type),
  getListQueryParam(RouteQueryParamEnum.State),
  getListQueryParam(RouteQueryParamEnum.Option),
  getListQueryParam(RouteQueryParamEnum.Method),
  getListQueryParam(RouteQueryParamEnum.Collaborator),
  (types: string[], status: string[], options: string[], methods: string[], contributors: string[]) => ({
    types: types.length ? types : undefined,
    status: status.length ? status : undefined,
    options: options.length ? options : undefined,
    methods: methods.length ? methods : undefined,
    contributors: contributors.length ? contributors : undefined,
  }),
);

// Signature queries:
export const getSignatureQueryParams = createSelector(
  getRouteParam(RouteParamEnum.ProjectUuidEntity),
  getQueryParam(RouteQueryParamEnum.TextSearch),
  getFilters,
  getDateFilters,
  getSortFilters,
  SignatureSummaryData.initializeQueryRequest,
);
