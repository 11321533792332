import { LabelFamilyPlanTreeFilterRequestDTO } from '../../generated/models/labelFamilyPlanTreeFilterRequestDTO';
import { TeamRoleLightDTO } from '../../generated/models/teamRoleLightDTO';
import { UserLightDTO } from '../../generated/models/userLightDTO';
import { PageTeamRoleRequest } from '../../generated/models/pageTeamRoleRequest';

import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { UserLightData } from './user-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { InnerSortDirectionEnum, UserNatureEnum } from '../../enums';

export namespace DropdownUserData {
  export interface QueryRequest extends BaseQuery<UserLightData> {
    userNatures: UserNatureEnum[];
    onlyUsedInProject: boolean;
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageTeamRoleRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      userNatures: (queries.userNatures && queries.userNatures.map(nature => UserNatureEnum.convertToApiValue.getValue(nature))) || [],
      onlyUsedInProject: queries.onlyUsedInProject || undefined,
    };
  }

  export function mapToPlanPageRequestApiValue(textSearch: string, planUuidEntity: string): LabelFamilyPlanTreeFilterRequestDTO {
    return { textSearch, planUuidEntity };
  }

  export function mapToProjectPageRequestApiValue(textSearch: string, projectUuidEntity: string): LabelFamilyPlanTreeFilterRequestDTO {
    return { textSearch, projectUuidEntity };
  }

  export function initializeFilterQueries(page: number, textSearch?: string, natures?: UserNatureEnum[], onlyUsedInProject?: boolean): QueryRequest {
    return {
      ...initializeQueries<UserLightData>(),
      page,
      textSearch: textSearch || undefined,
      userNatures: natures,
      onlyUsedInProject: onlyUsedInProject,
    };
  }
  export function mapTeamRoleToSingleSelectItemData(teamRole: TeamRoleLightDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: UserLightData.mapTeamRoleToEntityLightData(teamRole),
      title: UserLightData.toString(teamRole.user),
    };
  }
  export function mapToSingleSelectItemData(user: UserLightDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: UserLightData.mapToEntityLightData(user),
      title: UserLightData.toString(user),
    };
  }

  export function mapToAdvancedSearchSingleSelectItemData(user: UserLightDTO): MultiSelectItemData<string> {
    return {
      value: user.uuidEntity,
      title: UserLightData.toString(user),
    };
  }

  export function mapToMultiSelectItemData(user: UserLightDTO): MultiSelectItemData {
    return {
      value: user.uuidEntity,
      title: UserLightData.toString(user),
    };
  }
}
