import { createReducer, on, Action } from '@ngrx/store';

import { LazyLoadResponse, SignatureDetailsData, BlockDsiHistoryData } from '../../../../../../../../core/models';
import * as fromActions from './block-dsi-dialog.actions';

export interface BlockDsiDialogState {
  signatureDetails: SignatureDetailsData;
  history: LazyLoadResponse<BlockDsiHistoryData[]>;
  reset: boolean;
}

export const blockDsiDialogInitialState = (): BlockDsiDialogState => ({
  signatureDetails: null,
  history: {} as LazyLoadResponse<BlockDsiHistoryData[]>,
  reset: false,
});

const _blockDsiDialogReducer = createReducer<BlockDsiDialogState>(
  blockDsiDialogInitialState(),
  on(fromActions.loadSignatureDetailsSuccess, (state, { signatureDetails }): BlockDsiDialogState => ({ ...state, signatureDetails })),
  on(
    fromActions.loadDsiHistorySuccess,
    (state, { dsiEvents, reset }): BlockDsiDialogState => ({
      ...state,
      history: reset ? dsiEvents : { ...state.history, payload: [...state.history.payload, ...dsiEvents.payload] },
      reset,
    }),
  ),
);

export function blockDsiDialogReducer(state: BlockDsiDialogState | undefined, action: Action): BlockDsiDialogState {
  return _blockDsiDialogReducer(state, action);
}
