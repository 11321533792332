import { QuestionTypeEnum } from '../../enums';
import { OptionSignatoryDetailsResponseDTO, RubricOptionDetailsResponseDTO, RubricQuestionDetailsResponseDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';

export interface TableHeaderCellData {
  headerName: string;
  nodeOrder: number;
  uuidEntity: string;
  shared?: boolean;
  mandatory?: boolean;
  lockable?: boolean;
  allowBackDating?: boolean;
  type?: QuestionTypeEnum;
  description?: string;
  comment?: string;
  observation?: string;
  color?: string;
  lastSignatoryDate?: string;
  hasHistory?: boolean;
}

export namespace TableHeaderCellData {
  const emptyHeaderName = '_';
  export function mapFromQuestionValue(question: RubricQuestionDetailsResponseDTO): TableHeaderCellData {
    return {
      headerName: question.name,
      comment: question.additionalComment,
      observation: question.additionalObservation,
      shared: question.shared,
      type: QuestionTypeEnum.convertFromApiValue.getValue(question.type),
      nodeOrder: question.questionOrder,
      mandatory: question.mandatory,
      lockable: question.lockable,
      allowBackDating: question.allowBackDating,
      description: question.description,
      color: question.backgroundColor,
      uuidEntity: question.uuidEntity,
      lastSignatoryDate: DateUtils.toCalendarFormat(question.lastSignatoryDate),
      hasHistory: !!question.versionHistoryCount,
    };
  }

  export function mapFromOptionValue(option: RubricOptionDetailsResponseDTO & OptionSignatoryDetailsResponseDTO, questionType: QuestionTypeEnum): TableHeaderCellData {
    const headerName = (questionType !== QuestionTypeEnum.Collaborator && option.optionName) || option.name;

    return {
      headerName: headerName?.trim() || emptyHeaderName,
      nodeOrder: option.optionOrder,
      description: option.description,
      uuidEntity: option.uuidEntity,
      type: questionType,
    };
  }
}
