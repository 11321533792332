import { FrequencyNatureEnum } from '../../enums';

export const SECONDS = 60;
export const SECOND_PER_MINUTE = 3600;
export const SECOND_PER_HOUR = 86400;
export const SECOND_PER_MONTH = 2.628e6;
export const SECOND_PER_YEAR = 3.154e7;

export interface ScheduleToleranceData {
  tolerance: number;
  frequency: FrequencyNatureEnum;
}

export namespace ScheduleToleranceData {
  export const initialize = () => ({ tolerance: 0, frequency: FrequencyNatureEnum.Daily });

  export function mapMillisecondToTolerance(seconds: number): ScheduleToleranceData {
    const minutes = Math.floor(seconds / (SECONDS * 1000));
    const hours = Math.floor(seconds / (SECOND_PER_MINUTE * 1000));
    const days = Math.floor(seconds / (SECOND_PER_HOUR * 1000));
    const months = Math.floor(seconds / (SECOND_PER_MONTH * 1000));
    const years = Math.floor(seconds / (SECOND_PER_YEAR * 1000));

    if (years) {
      return { tolerance: years, frequency: FrequencyNatureEnum.Yearly };
    } else if (months) {
      return { tolerance: months, frequency: FrequencyNatureEnum.Monthly };
    } else if (days) {
      return { tolerance: days, frequency: FrequencyNatureEnum.Daily };
    } else if (hours) {
      return { tolerance: hours, frequency: FrequencyNatureEnum.Hourly };
    } else if (minutes) {
      return { tolerance: minutes, frequency: FrequencyNatureEnum.Minute };
    }

    return initialize();
  }

  export function mapToleranceToMillisecond(data: ScheduleToleranceData): number {
    switch (data.frequency) {
      case FrequencyNatureEnum.Minute:
        return data.tolerance * SECONDS * 1000;

      case FrequencyNatureEnum.Hourly:
        return data.tolerance * SECOND_PER_MINUTE * 1000;

      case FrequencyNatureEnum.Daily:
        return data.tolerance * SECOND_PER_HOUR * 1000;

      case FrequencyNatureEnum.Monthly:
        return data.tolerance * SECOND_PER_MONTH * 1000;

      case FrequencyNatureEnum.Yearly:
        return data.tolerance * SECOND_PER_YEAR * 1000;

      default:
        return 0;
    }
  }
}
