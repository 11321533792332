import { AssignElementAdrToListRequestDTO } from '../../generated/models/assignElementAdrToListRequestDTO';

export interface ImportAdrOptions {
  uuidEntity: string;
  projectUuidEntity: string;
  flowUuidEntity: string;
}
export namespace ImportAdrOptions {
  export function mapToRequestApiValue(data: ImportAdrOptions): AssignElementAdrToListRequestDTO {
    return {
      elementAdrUuidEntity: data.uuidEntity,
      flowUuidEntity: data.flowUuidEntity,
      projectUuidEntity: data.projectUuidEntity,
    };
  }
}
