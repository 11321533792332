import { FormNatureEnum, FormTypeEnum } from '../../enums';
import { FormLightResponseDTO } from '../../generated/models/formLightResponseDTO';
import { DateUtils } from '../../utils/date.utils';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightDTO } from '../../generated/models/labelApplicabilityLightDTO';

export interface FormSummaryData {
  additionalComment?: string;
  uuidEntity: string;
  name: string;
  description: string;
  nature: FormNatureEnum;
  family: LabelFamilyLightData;
  formType: FormTypeEnum;
  referenceResponse: string;
  initialRubricUuidType: string;
  dateResponse: string;
  lockForm?: boolean;
  applicability?: LabelApplicabilityLightDTO;
}

export namespace FormSummaryData {
  export function mapFromApiValue(form: FormLightResponseDTO): FormSummaryData {
    return {
      uuidEntity: form.uuidEntity,
      name: form.name,
      description: form.description,
      nature: form.nature && FormNatureEnum.convertFromApiValue.getValue(form.nature),
      family: form.family && LabelFamilyLightData.mapFromApiValue(form.family, undefined, true),
      formType: form.formType && FormTypeEnum.convertFromApiValue.getValue(form.formType),
      referenceResponse: form.responseReference,
      initialRubricUuidType: form.initialRubricUuidType,
      additionalComment: form.additionalComment,
      dateResponse: form.dateResponse && DateUtils.toDateTimeFormat(form.dateResponse),
      lockForm: form.lockForm,
      applicability: form.applicability,
    };
  }

  export function mapToEntityLightData(form: FormSummaryData): EntityLightData {
    return {
      uuidEntity: form.uuidEntity,
      name: form.name,
    };
  }
}
