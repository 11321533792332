import { DateUtils } from '../../utils/date.utils';
import { SortFilter } from '../utils/sort-filter';

import {
  PageFormResponseRequestDTO,
  RequestOrEntityUuidsRequestDTOPageFormResponseRequestDTO,
  ResponseDetailsResponseDTO,
  ResponseSummaryResponseDTO,
} from '../../generated/models/models';

import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FlowLightData } from '../flows/flow-light-data';
import { FormSummaryData } from '../form-builder/form-summary';
import { UserLightData } from '../user/user-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { DateFilterData } from '../utils/date-filter-data';

import { BusinessClassNameEnum, InnerSortDirectionEnum, ResponseProvenanceEnum, ResponseStateEnum, StringUtilsEnum } from '../../enums';

export interface FormResponseSummaryData {
  reference: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  responseDate: string;
  createDate: string;
  customStatus: CustomStatusLightData;
  state: ResponseStateEnum;
  submitter: UserLightData;
  indice: string;
  provenance: ResponseProvenanceEnum;
  businessClass: BusinessClassNameEnum;
  businessClassName: string;
  flow: FlowLightData;
  form: FormSummaryData;
  formBusinessLinkUuidEntity: string;
  frozen: boolean;
}

export namespace FormResponseSummaryData {
  export const exportXlsErrorKey = 'form-resp-empty';
  const sortFields = ['provenance', 'reference', 'customStatus', 'index', 'dateResponse'];
  export interface QueryRequest extends BaseQuery<FormResponseSummaryData> {
    provenance: ResponseProvenanceEnum;
    state: ResponseStateEnum;
    statuses: string[];
    flowsUuidEntities: string[];
    formUuidEntity: string;
    dateFilter: DateFilterData;
  }

  export const multiSelectProjectItemValue = 'PROJECT';

  export function mapFromApiValue(response: ResponseSummaryResponseDTO): FormResponseSummaryData {
    const customStatus = response.customStatus && CustomStatusLightData.mapFromApiValue(response.customStatus);

    return {
      reference: response.reference,
      uuid: response.uuid,
      uuidType: response.uuidType,
      uuidEntity: response.uuidEntity,
      responseDate: DateUtils.toDateTimeFormat(response.responseDate),
      createDate: DateUtils.toDateTimeFormat(response.createDate),
      indice: response.indice,
      customStatus,
      state: ResponseStateEnum.convertFromApiValue.getValue(response.state),
      submitter: response.submitter && UserLightData.mapFromApiValue(response.submitter),
      provenance: ResponseProvenanceEnum.convertFromApiValue.getValue(response.provenance),
      businessClass: BusinessClassNameEnum.convertFromApiValue.getValue(response.businessClass),
      businessClassName: response.businessClassName,
      flow: response.flow && FlowLightData.mapFromApiResponse(response.flow),
      form: response.form && FormSummaryData.mapFromApiValue(response.form),
      formBusinessLinkUuidEntity: response.formBusinessLinkUuidEntity,
      frozen: !customStatus?.exportable,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest, projectUuidEntity: string, formUuidEntity: string): PageFormResponseRequestDTO {
    const flowUuidEntities: string[] = queries.flowsUuidEntities ? [...queries.flowsUuidEntities] : [];
    const projectFilterIndex: number = flowUuidEntities.findIndex(item => item === multiSelectProjectItemValue);
    let entityProvenance: PageFormResponseRequestDTO.EntityProvenanceEnum;

    if (projectFilterIndex > -1) {
      flowUuidEntities.splice(projectFilterIndex, 1);
      entityProvenance = flowUuidEntities.length ? null : PageFormResponseRequestDTO.EntityProvenanceEnum.PROJECT;
    } else if (flowUuidEntities.length) {
      entityProvenance = PageFormResponseRequestDTO.EntityProvenanceEnum.FLOW;
    }
    const entityProvenanceFilter =
      ResponseProvenanceEnum.provenanceEntities.includes(queries.provenance) &&
      (ResponseProvenanceEnum.convertToApiValue.getValue(queries.provenance) as PageFormResponseRequestDTO.EntityProvenanceEnum);

    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      provenance:
        (ResponseProvenanceEnum.values(true).includes(queries.provenance) &&
          (ResponseProvenanceEnum.convertToApiValue.getValue(queries.provenance) as ResponseDetailsResponseDTO.ProvenanceEnum)) ||
        undefined,
      entityProvenance: entityProvenance || entityProvenanceFilter || undefined,
      state: ResponseStateEnum.convertToApiValue.getValue(queries.state) || undefined,
      customStatusUuidEntity: queries.statuses?.length ? queries.statuses : undefined,
      flowUuidEntities: flowUuidEntities.length ? flowUuidEntities : undefined,
      textSearch: queries.textSearch || undefined,
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
      projectUuidEntity,
      formUuidEntity,
    };
  }

  export function mapToExportFormResponseRequestDTO(
    responses: FormResponseSummaryData[],
    onlyExportableValues: boolean,
    customFileUuidEntity?: string,
    request?: PageFormResponseRequestDTO,
    isAllSelected: boolean = false,
  ): RequestOrEntityUuidsRequestDTOPageFormResponseRequestDTO {
    return {
      request: isAllSelected ? request : null,
      entityUuids: !isAllSelected ? responses.map(res => res.uuidEntity) : null,
      customFileUuidEntity: customFileUuidEntity,
      onlyExportableValues: onlyExportableValues,
    };
  }

  export function initializeQueryRequest(
    provenance: string,
    state: string,
    statuses: string[],
    flowsUuidEntities: string[],
    searchText: string,
    sort: SortFilter<FormResponseSummaryData>,
    dateFilter: DateFilterData,
    formUuidEntity?: string,
  ): QueryRequest {
    return {
      ...initializeQueries<FormResponseSummaryData>(sort),
      textSearch: searchText || undefined,
      provenance: ResponseProvenanceEnum.convertFromParamsValue.getValue(provenance) || undefined,
      state: ResponseStateEnum.convertFromParamsValue.getValue(state) || undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      flowsUuidEntities: (flowsUuidEntities || []).length ? flowsUuidEntities : undefined,
      formUuidEntity: formUuidEntity || undefined,
      dateFilter: dateFilter || undefined,
    };
  }

  export function toString(responseSummary: FormResponseSummaryData): string {
    const index = responseSummary.indice ? responseSummary.indice.concat(StringUtilsEnum.DashSeparator) : '';
    const reference = responseSummary.reference ? responseSummary.reference : '';

    return responseSummary.reference ? index.concat(reference) : 'entityConflict.hint.form.answerWithoutReference';
  }
}
