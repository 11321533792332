import { FlowTeamRoleRequestDTO, PageTeamRolePlanRequestDTO } from '../../generated/models/models';
import { EntityLightData, FlowDetailsData } from '../../models';
import { SfxMap } from '../../utils/enum-utils';
import { UserNatureEnum } from '../user/user-nature.enum';

export enum FlowTeamRolesEnum {
  Reporter = 1,
  Responsible,
  Tablet_1,
  Tablet_2,
  Tablet_3,
  Contributors,
}

export namespace FlowTeamRolesEnum {
  export const teamRoleTablets = [FlowTeamRolesEnum.Tablet_1, FlowTeamRolesEnum.Tablet_2, FlowTeamRolesEnum.Tablet_3];
  export const values = [
    FlowTeamRolesEnum.Reporter,
    FlowTeamRolesEnum.Responsible,
    FlowTeamRolesEnum.Tablet_1,
    FlowTeamRolesEnum.Tablet_2,
    FlowTeamRolesEnum.Tablet_3,
    FlowTeamRolesEnum.Contributors,
  ];

  export const convertFromParamValue = new SfxMap<string, FlowTeamRolesEnum>([
    ['responsible', FlowTeamRolesEnum.Responsible],
    ['reporter', FlowTeamRolesEnum.Reporter],
    ['tablet1', FlowTeamRolesEnum.Tablet_1],
    ['tablet2', FlowTeamRolesEnum.Tablet_2],
    ['tablet3', FlowTeamRolesEnum.Tablet_3],
    ['contributors', FlowTeamRolesEnum.Contributors],
  ]);

  export const convertToParamValue = new SfxMap<FlowTeamRolesEnum, string>([
    [FlowTeamRolesEnum.Responsible, 'responsible'],
    [FlowTeamRolesEnum.Reporter, 'reporter'],
    [FlowTeamRolesEnum.Tablet_1, 'tablet1'],
    [FlowTeamRolesEnum.Tablet_2, 'tablet2'],
    [FlowTeamRolesEnum.Tablet_3, 'tablet3'],
    [FlowTeamRolesEnum.Contributors, 'contributors'],
  ]);

  export const convertToApiValue = new SfxMap<FlowTeamRolesEnum, FlowTeamRoleRequestDTO.NatureEnum>([
    [FlowTeamRolesEnum.Responsible, FlowTeamRoleRequestDTO.NatureEnum.ASSIGNE],
    [FlowTeamRolesEnum.Reporter, FlowTeamRoleRequestDTO.NatureEnum.REPORTER],
    [FlowTeamRolesEnum.Tablet_1, FlowTeamRoleRequestDTO.NatureEnum.TABLET1],
    [FlowTeamRolesEnum.Tablet_2, FlowTeamRoleRequestDTO.NatureEnum.TABLET2],
    [FlowTeamRolesEnum.Tablet_3, FlowTeamRoleRequestDTO.NatureEnum.TABLET3],
    [FlowTeamRolesEnum.Contributors, FlowTeamRoleRequestDTO.NatureEnum.COLLABORATOR],
  ]);

  export const mapToUserNatures = (nature?: UserNatureEnum) =>
    new SfxMap<FlowTeamRolesEnum, PageTeamRolePlanRequestDTO.UserNaturesEnum[]>([
      [FlowTeamRolesEnum.Responsible, [PageTeamRolePlanRequestDTO.UserNaturesEnum.NORMAL]],
      [FlowTeamRolesEnum.Reporter, [PageTeamRolePlanRequestDTO.UserNaturesEnum.NORMAL]],
      [FlowTeamRolesEnum.Contributors, [PageTeamRolePlanRequestDTO.UserNaturesEnum.NORMAL, PageTeamRolePlanRequestDTO.UserNaturesEnum.EXTERN]],
      [
        FlowTeamRolesEnum.Tablet_1,
        nature ? [UserNatureEnum.convertToApiValue.getValue(nature)] : [PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICE, PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICEEXT],
      ],
      [
        FlowTeamRolesEnum.Tablet_2,
        nature ? [UserNatureEnum.convertToApiValue.getValue(nature)] : [PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICE, PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICEEXT],
      ],
      [
        FlowTeamRolesEnum.Tablet_3,
        nature ? [UserNatureEnum.convertToApiValue.getValue(nature)] : [PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICE, PageTeamRolePlanRequestDTO.UserNaturesEnum.DEVICEEXT],
      ],
    ]);

  export const toIcon = new SfxMap<FlowTeamRolesEnum, string>(
    [
      [FlowTeamRolesEnum.Tablet_1, 'tablet-alt'],
      [FlowTeamRolesEnum.Tablet_2, 'tablet-alt'],
      [FlowTeamRolesEnum.Tablet_3, 'tablet-alt'],
    ],
    'users',
  );

  const key = 'PlanTranslate.affectationModal.';
  export const toTitle = new SfxMap<FlowTeamRolesEnum, string>([
    [FlowTeamRolesEnum.Responsible, key + 'assignee.title'],
    [FlowTeamRolesEnum.Reporter, key + 'reporter.title'],
    [FlowTeamRolesEnum.Tablet_1, key + 'tablet.title'],
    [FlowTeamRolesEnum.Tablet_2, key + 'tablet.title'],
    [FlowTeamRolesEnum.Tablet_3, key + 'tablet.title'],
    [FlowTeamRolesEnum.Contributors, key + 'collaborators.title'],
  ]);

  export const toTitleWithoutDate = new SfxMap<FlowTeamRolesEnum, string>([
    [FlowTeamRolesEnum.Responsible, key + 'assignee2.title'],
    [FlowTeamRolesEnum.Reporter, key + 'reporter2.title'],
    [FlowTeamRolesEnum.Tablet_1, key + 'tablet1.title'],
    [FlowTeamRolesEnum.Tablet_2, key + 'tablet2.title'],
    [FlowTeamRolesEnum.Tablet_3, key + 'tablet3.title'],
    [FlowTeamRolesEnum.Contributors, key + 'collaborators2.title'],
  ]);

  export const findUuidRefFrom = (data: FlowDetailsData) =>
    new SfxMap<FlowTeamRolesEnum, string>([
      [FlowTeamRolesEnum.Reporter, data.reporterUuideRef],
      [FlowTeamRolesEnum.Responsible, data.assigneeUuideRef],
      [FlowTeamRolesEnum.Tablet_1, data.refUuideActor1],
      [FlowTeamRolesEnum.Tablet_2, data.refUuideActor2],
      [FlowTeamRolesEnum.Tablet_3, data.refUuideActor3],
    ]);

  export const findAffectationFrom = (data: FlowDetailsData) =>
    new SfxMap<FlowTeamRolesEnum, EntityLightData>([
      [FlowTeamRolesEnum.Reporter, data.reporterUser],
      [FlowTeamRolesEnum.Responsible, data.assignedUser],
      [FlowTeamRolesEnum.Tablet_1, data.userActor1],
      [FlowTeamRolesEnum.Tablet_2, data.userActor2],
      [FlowTeamRolesEnum.Tablet_3, data.userActor3],
    ]);

  export const isTablet = (role: FlowTeamRolesEnum) => teamRoleTablets.includes(role);

  const key2 = 'planTranslate.affectationPopover.';
  export const affectationTitle = new SfxMap<FlowTeamRolesEnum, string>([
    [FlowTeamRolesEnum.Reporter, key2 + 'reporter'],
    [FlowTeamRolesEnum.Responsible, key2 + 'manager'],
    [FlowTeamRolesEnum.Tablet_1, key2 + 'tablet1'],
    [FlowTeamRolesEnum.Tablet_2, key2 + 'tablet2'],
    [FlowTeamRolesEnum.Tablet_3, key2 + 'tablet3'],
    [FlowTeamRolesEnum.Contributors, key2 + 'collaborators'],
  ]);
}
