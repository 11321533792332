export function createRandom(requiredLength: number): string {
  if (requiredLength <= 0) {
    return '';
  }

  if (requiredLength > 0 && requiredLength < 7) {
    requiredLength = 10;
  }

  const length = requiredLength - 6;
  const arr = new Uint8Array(length / 2);
  getCrypto().getRandomValues(arr);

  return Array.from(arr, toHexadecimal).join('') + randomString(7);
}

export function toHexadecimal(decimal: number): string {
  return ('0' + decimal.toString(16)).slice(-2);
}

export function randomString(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const values = new Uint32Array(length);
  getCrypto().getRandomValues(values);
  for (let i = 0; i < length; i++) {
    result += characters[values[i] % characters.length];
  }

  return result;
}

export function getCrypto(): Crypto {
  return window.crypto || window['msCrypto'];
}
