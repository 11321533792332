import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { FamilyDetailsData } from '../../../../../core/models';

import { LabelFamilyNatureEnum } from '../../../../../core/enums';

export const checkForFirstParent = createAction('[dFamily] Check for first parent', props<{ nature: LabelFamilyNatureEnum }>());
export const checkForFirstParentSuccess = createAction('[dFamily] Check for first parent success', props<{ exist: boolean; keepCurrentType: boolean }>());
export const checkForFirstParentFail = createAction('[dFamily] Check for first parent fail', props<DialogToasterData>());

export const addFamily = createAction('[dFamily] add new Family', props<{ family: FamilyDetailsData; addNewOne?: boolean }>());
export const addFamilySuccess = createAction('[dFamily] add new Family success', props<{ family: FamilyDetailsData; addNewOne?: boolean }>());
export const addFamilyFail = createAction('[dFamily] add new Family fail', props<DialogToasterData>());

export const updateFamily = createAction('[dFamily] update Family', props<{ family: FamilyDetailsData; closeModal?: boolean }>());
export const updateFamilySuccess = createAction('[dFamily] update Family success', props<{ family: FamilyDetailsData; closeModal?: boolean }>());
export const updateFamilyFail = createAction('[dFamily] update Family fail', props<DialogToasterData>());

export const getFamilyDetailsSuccess = createAction('[dFamily] get Family details success', props<{ family: FamilyDetailsData }>());
export const getFamilyDetailsFail = createAction('[dFamily] get Family details fail', props<DialogToasterData>());

export const navigateThroughFamilies = createAction('[dFamily] Navigate through categories', props<{ family: FamilyDetailsData; step: number }>());

export const stopSaveLoading = createAction('[dFamily] Stop save loading');
