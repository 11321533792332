import { FormLocalCreateRequestDTO } from '../../generated/models/models';

export interface FormCopyRequest {
  formUuidEntity: string;
  comment?: string;
}

export namespace FormCopyRequest {
  export function mapToApiValue(data: FormCopyRequest): FormLocalCreateRequestDTO {
    return {
      sourceUuidEntity: data.formUuidEntity,
      additionalComment: data.comment,
    };
  }
}
