import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum CustomColumnsConfigurationEnum {
  Default = 'default',
  Customized = 'customized',
}

export namespace CustomColumnsConfigurationEnum {
  const values = [CustomColumnsConfigurationEnum.Default, CustomColumnsConfigurationEnum.Customized];

  export const toString: SfxMap<CustomColumnsConfigurationEnum, string> = new SfxMap<CustomColumnsConfigurationEnum, string>(
    [[CustomColumnsConfigurationEnum.Customized, 'flows.customColumns.customized']],
    'flows.customColumns.default',
  );

  export const selectItems = (): Observable<SingleSelectItemData<CustomColumnsConfigurationEnum>[]> =>
    of(
      values.map(type => ({
        value: type,
        title: toString.getValue(type),
      })),
    );
}
