import { UserLightDTO } from '../../generated/models/models';
import { UserNatureEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface BlockActorData {
  name: string;
  uuidEntity: string;
  date: string;
  time: string;
  natureUser: UserNatureEnum;
  refExtern: string;
  refIntern: string;
}

export namespace BlockActorData {
  export function mapFromApiValue(dateAction: Date, user: UserLightDTO): BlockActorData {
    return {
      name: `${user.firstName} ${user.lastName}`,
      uuidEntity: user.uuidEntity,
      date: DateUtils.toDateFormat(dateAction),
      time: DateUtils.toTimeFormat(dateAction),
      natureUser: UserNatureEnum.convertFromApiValue.getValue(user.natureUser),
      refExtern: user.refExtern,
      refIntern: user.refIntern,
    };
  }
}
