import { SfxMap } from '../../utils/enum-utils';

export enum SequencePhaseColumnEnum {
  phase = 'phase',
  name = 'name',
}

export namespace SequencePhaseColumnEnum {
  export const allColumns = [SequencePhaseColumnEnum.phase, SequencePhaseColumnEnum.name];
  const key = 'flowContentNavigation.dialog.sequence.phases.table.header.';
  export const toString: SfxMap<SequencePhaseColumnEnum, string> = new SfxMap<SequencePhaseColumnEnum, string>([
    [SequencePhaseColumnEnum.phase, key + 'phase'],
    [SequencePhaseColumnEnum.name, key + 'name'],
  ]);
}
