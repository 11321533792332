import { compact } from 'lodash';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { PageClientRequest, ClientSummaryResponseDTO } from '../../generated/models/models';
import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum } from '../../enums';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface ClientSummaryData {
  uuidEntity: string;
  name: string;
  nameComp: string;
  nameComp2: string;
}

export namespace ClientSummaryData {
  export type QueryRequest = BaseQuery<ClientSummaryData>;
  const sortFields = ['name', 'nameComp', 'nameComp2'];

  export function mapFromApiValue(client: ClientSummaryResponseDTO): ClientSummaryData {
    return {
      uuidEntity: client.uuidEntity,
      name: client.name,
      nameComp: client.nameComp,
      nameComp2: client.nameComp2,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageClientRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(search: string, sort: SortFilter<ClientSummaryData>): QueryRequest {
    return {
      ...initializeQueries<ClientSummaryData>(sort),
      textSearch: search || undefined,
    };
  }
  export function initializeFilterQueries(page: number, searchText?: string): QueryRequest {
    return {
      ...initializeQueries<ClientSummaryData>({ key: 'name' }),
      page,
      textSearch: searchText || undefined,
    };
  }

  export function mapToMultiSelectItemData(client: ClientSummaryResponseDTO): MultiSelectItemData {
    return {
      value: client.uuidEntity,
      title: client.name,
      tooltip: compact([client.name, client.nameComp, client.nameComp2]).join(' - '),
    };
  }

  export function mapToSingleSelectItemData(client: ClientSummaryResponseDTO): SingleSelectItemData {
    return {
      value: { name: client.name, uuidEntity: client.uuidEntity },
      title: client.name,
      tooltip: compact([client.name, client.nameComp, client.nameComp2]).join(' - '),
    };
  }
}
