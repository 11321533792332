import { initializeQueries, BaseQuery } from '../utils/base-query.model';
import { CategoryPageRequest, CategorySummaryResponseDTO } from '../../generated/models/models';
import { NatureLabelFamilyResponseDTO } from '../../generated/models/natureLabelFamilyResponseDTO';
import { LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum, LabelFamilyProvenanceEnum, InnerSortDirectionEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface CategorySummaryData {
  childrenCount: number;
  color: string;
  name: string;
  nature: LabelFamilyNatureEnum;
  relativeNature: LabelFamilyRelativeNatureEnum;
  provenance: LabelFamilyProvenanceEnum;
  priority: number;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  parent?: CategorySummaryData;
  archived?: boolean;
}

export namespace CategorySummaryData {
  export interface QueryRequest extends BaseQuery<CategorySummaryData> {
    provenance?: LabelFamilyProvenanceEnum;
    projectUuidEntity?: string;
    nature?: LabelFamilyNatureEnum;
    relativeNature?: LabelFamilyRelativeNatureEnum;
    uuidEntityFamilyParent?: string;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(category: CategorySummaryResponseDTO): CategorySummaryData {
    return {
      childrenCount: category.childTotalCount,
      color: category.color || '#0d4e8a',
      name: (category.fullName && category.name + ' - ' + category.fullName) || category.name,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(category.nature),
      provenance: LabelFamilyProvenanceEnum.convertFromApiValue.getValue(category.labelFamilyProvenance),
      priority: category.priority,
      uuid: category.uuid,
      uuidEntity: category.uuidEntity,
      uuidOrganization: category.uuidOrganization,
      uuidType: category.uuidType,
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(category.relativeNature),
      archived: category.archived,
    };
  }

  export function mapFromNatureFamilyApiValue(natureFamily: NatureLabelFamilyResponseDTO): CategorySummaryData {
    return {
      uuidEntity: LabelFamilyNatureEnum.relativeUuidEntities.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      relativeNature: LabelFamilyRelativeNatureEnum.Container,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature),
      name: LabelFamilyNatureEnum.toString.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      color: '#000',
      childrenCount: natureFamily.childTotalCount,
    } as CategorySummaryData;
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): CategoryPageRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      uuideProject: queries.projectUuidEntity,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(queries.nature),
      uuideFamilyParent: queries.uuidEntityFamilyParent,
      choixLabelFamilyProvenance: LabelFamilyProvenanceEnum.convertToApiValue.getValue(queries.provenance),
      choixRelativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(queries.relativeNature),
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, provenance: string, nature: string, projectUuidEntity: string, state?: string): QueryRequest {
    return {
      ...initializeQueries<CategorySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      provenance: LabelFamilyProvenanceEnum.convertFromParamsValue.getValue(provenance) || undefined,
      nature: LabelFamilyNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      projectUuidEntity,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }

  export function initializeFilterQueries(
    page: number,
    searchText: string,
    projectUuidEntity: string,
    nature: LabelFamilyNatureEnum,
    relativeNature?: LabelFamilyRelativeNatureEnum,
    state?: FilterArchivedEnum,
  ): QueryRequest {
    return {
      ...initializeQueries<CategorySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      page,
      nature,
      projectUuidEntity,
      relativeNature,
      state,
    };
  }

  export function toString(categorySummary: CategorySummaryData): string {
    return categorySummary.name;
  }

  export const initialRows: CategorySummaryData[] = LabelFamilyNatureEnum.initialValues(true).map(
    initialRow =>
      ({
        uuidEntity: initialRow.uuidEntity,
        relativeNature: LabelFamilyRelativeNatureEnum.Container,
        nature: initialRow.nature,
        name: LabelFamilyNatureEnum.toString.getValue(initialRow.nature),
        color: '#000',
        childrenCount: 1,
      } as CategorySummaryData),
  );

  export const getInitialRows = (nature: LabelFamilyNatureEnum, categories: CategorySummaryData[]): CategorySummaryData[] =>
    nature ? categories.filter(row => row.nature === nature) : categories;
}
