import { PictogramEnum } from '../../enums/risk/pictogram.enum';
import { ParadeDetailsResponseDTO } from '../../generated/models/models';
import { BaseQuery } from '../utils/base-query.model';

export interface ParadeAdrData {
  uuidEntity: string;
  name: string;
  fileUuidEntity: string;
  pictogram: PictogramEnum;
}
export namespace ParadeAdrData {
  export type QueryRequest = BaseQuery<ParadeAdrData>;

  export function mapFromApiValue(parade: ParadeDetailsResponseDTO): ParadeAdrData {
    return {
      uuidEntity: parade.uuidEntity,
      name: parade.name,
      fileUuidEntity: parade.applicationFile && parade.applicationFile.uuidEntity,
      pictogram: PictogramEnum.convertFromApiValue.getValue(parade.pictogram),
    };
  }
}
