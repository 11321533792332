import { compact } from 'lodash';
import { UserRightEnum } from '../../enums';
import { AdditionalAuthEnum } from '../../enums/user/additionalAuth.enum';
import { ConvertExternalUserToNormalUserRequestDTO } from '../../generated/models/convertExternalUserToNormalUserRequestDTO';
import { FillExternalContributorInfoRequestDto } from '../../generated/models/fillExternalContributorInfoRequestDto';
import { SharedLinkExternalUserResponseDto } from '../../generated/models/sharedLinkExternalUserResponseDto';
import { CompanySummaryData } from '../companies/companies-summary-data';
import { LibraryContributorDetailsData } from '../library-contributor/library-contributor-details-data';

export interface ExternalUserDetailsData {
  companyName: string;
  firstName: string;
  lastName: string;
  profession: string;
  email: string;
  uuidEntity: string;
}

export namespace ExternalUserDetailsData {
  export function mapFromApiValue(data: SharedLinkExternalUserResponseDto): ExternalUserDetailsData {
    return {
      profession: data._function,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToApiValue(data: ExternalUserDetailsData): FillExternalContributorInfoRequestDto {
    return {
      _function: data.profession,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
    };
  }

  export function mapToLibraryContributorDetailsData(data: ExternalUserDetailsData): LibraryContributorDetailsData {
    return {
      applicabilityName: '',
      authority: undefined,
      company: {} as CompanySummaryData,
      complement: '',
      contract: undefined,
      function: undefined,
      group: undefined,
      hierarchies: [],
      legalEstablishment: undefined,
      registerNumber: '',
      ssoIdentifier: '',
      trigram: '',
      userGenericForm: false,
      userManageDiffusion: false,
      userModelForm: false,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToConvertExternalUserApiValue(data: LibraryContributorDetailsData): ConvertExternalUserToNormalUserRequestDTO {
    return {
      firstName: data.firstName,
      name: data.lastName,
      trigram: data.trigram,
      companyUuidEntity: data.company?.uuidEntity,
      functionId: data.function?.uuidEntity,
      groupId: data.group?.uuidEntity,
      authority: UserRightEnum.convertToApiValue.getValue(data.authority),
      defaultHierarchyIds: (data.hierarchies || []).map(hierarchy => hierarchy.uuidEntity),
      serialNumber: data.registerNumber,
      legalEntityUuidEntity: data.legalEstablishment?.uuidEntity,
      contratTypeUuidEntity: data.contract?.uuidEntity,
      additionalAuthorities: compact([
        data.userGenericForm ? AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.QHSE) : '',
        data.userModelForm ? AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.UserModelForm) : '',
        data.userManageDiffusion ? AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.ManageDiffusion) : '',
      ]),
      ssoIdentifier: data.ssoIdentifier || null,
    };
  }
}
