/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { KpiPreparationResponseDTO } from './kpiPreparationResponseDTO';

export interface KpisResponseDTOKpiPreparationResponseDTO {
  interval?: KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum;
  kpis?: Array<KpiPreparationResponseDTO>;
}
export namespace KpisResponseDTOKpiPreparationResponseDTO {
  export type IntervalEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'TWO_MONTH' | 'QUARTER' | 'YEARLY';
  export const IntervalEnum = {
    DAILY: 'DAILY' as IntervalEnum,
    WEEKLY: 'WEEKLY' as IntervalEnum,
    MONTHLY: 'MONTHLY' as IntervalEnum,
    TWOMONTH: 'TWO_MONTH' as IntervalEnum,
    QUARTER: 'QUARTER' as IntervalEnum,
    YEARLY: 'YEARLY' as IntervalEnum,
  };
}
