import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { BaseHttpService } from './base-http.service';

import { apiUrlMatcher } from './helpers/api-url-matcher';
import { FroalaEditorConfig } from '../models/app-config/froalaEditor-config';
import { ApiUrlsEnum } from './helpers/api-url';

@Injectable()
export class FroalaService {
  constructor(private baseHttpService: BaseHttpService) {}

  public loadFroalaLicense(): Observable<string> {
    return this.baseHttpService.get(apiUrlMatcher(ApiUrlsEnum.froalaLicenseKey)).pipe(map((config: FroalaEditorConfig) => config?.key));
  }
}
