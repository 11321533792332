import { FlowSummaryData } from '../flows/flow-summary';
import { ContributorSummaryData } from '../contributors/contributor-summary-data';
import { CategoryDeleteResponseDTO } from '../../generated/models/categoryDeleteResponseDTO';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { CategoryDeleteNatureEnum } from '../../enums';
import { CategorySummaryData } from './category-summary-data';

export interface DeleteCategoryResponse {
  success: boolean;
  nature: CategoryDeleteNatureEnum;
  flowLinks: FlowSummaryData[];
  listLinks: FlowSummaryData[];
  documentLinks: DocumentFlowSummaryData[];
  teamRoleLinks: ContributorSummaryData[];
  childLinks: CategorySummaryData[];
}

export namespace DeleteCategoryResponse {
  export function mapFromApiValue(data: CategoryDeleteResponseDTO): DeleteCategoryResponse {
    return {
      success: data.success,
      nature: CategoryDeleteNatureEnum.convertFromApiValue.getValue(data.nature),
      flowLinks: data.flowLinks && data.flowLinks.map(flow => FlowSummaryData.mapFromApiValue(flow)),
      listLinks: data.listLinks && data.listLinks.map(flow => FlowSummaryData.mapFromApiValue(flow)),
      documentLinks: data.documentLinks && data.documentLinks.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
      teamRoleLinks: data.teamRoleLinks && data.teamRoleLinks.map(teamRole => ContributorSummaryData.mapFromApiValue(teamRole)),
      childLinks: data.childsLinks && data.childsLinks.map(childLink => CategorySummaryData.mapFromApiValue(childLink)),
    };
  }

  export function mapToConflictData(response: DeleteCategoryResponse): ConflictData {
    switch (response.nature) {
      case CategoryDeleteNatureEnum.Flow:
        return { title: 'global.entityConflict.hint.category.flow', links: response.flowLinks.map(flow => FlowSummaryData.toString(flow)) };

      case CategoryDeleteNatureEnum.List:
        return { title: 'global.entityConflict.hint.category.list', links: response.listLinks.map(flow => FlowSummaryData.toString(flow)) };

      case CategoryDeleteNatureEnum.Hierarchy:
        return { title: 'global.entityConflict.hint.category.team', links: response.teamRoleLinks.map(flow => ContributorSummaryData.toString(flow)) };

      case CategoryDeleteNatureEnum.Document:
        return {
          title: 'global.entityConflict.hint.category.document',
          links: response.documentLinks.map(document => DocumentFlowSummaryData.toString(document)),
        };

      case CategoryDeleteNatureEnum.Child:
        return { title: 'global.entityConflict.hint.category.child', links: response.childLinks.map(document => CategorySummaryData.toString(document)) };

      default:
        return null;
    }
  }
}
