import { PageTeamRolePlanRequestDTO } from '../../generated/models/models';
import { TeamRolePlanResponseDTO } from '../../generated/models/teamRolePlanResponseDTO';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { SortFilter } from '..';
import { FlowTeamRolesEnum, InnerSortDirectionEnum, TabletStatusEnum, UserNatureEnum, UserRoleEnum, UserStatusEnum, UserTypeEnum } from '../../enums';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface FlowAffectationData {
  reporter: PlanTeamRoleResponseData;
  assignee: PlanTeamRoleResponseData;
  tablet1: PlanTeamRoleResponseData;
  tablet2: PlanTeamRoleResponseData;
  tablet3: PlanTeamRoleResponseData;
  collaborators: PlanTeamRoleResponseData[];
}
export interface PlanTeamRoleResponseData {
  uuidEntity: string;
  name: string;
  alreadyImported: boolean;
  userRole: UserRoleEnum;
  userStatus: UserStatusEnum;
  tabletStatus: TabletStatusEnum;
  natureTablet: UserNatureEnum;
  teamRoleOrder: number;
  labelFamilyHierarchies: LabelFamilyLightData[];
  skills: LabelFamilyLightData[];
  labelFamilyFunction: LabelFamilyLightData;
  labelFamilyTeam: LabelFamilyLightData;
  flowName: string;
  userUuidEntity: string;
  applicabilityName: string;
  flows: string;
  startDate: Date;
  endDate: Date;
  alreadySelected?: boolean;
}
export namespace PlanTeamRoleResponseData {
  const sortFields = ['name', 'family', 'group', 'hierarchy', 'function'];

  export interface QueryRequest extends BaseQuery<PlanTeamRoleResponseData> {
    projectUuidEntity: string;
    flowUuidEntity: string;
    userType: UserTypeEnum;
    userRole: UserRoleEnum;
    tabletType: UserNatureEnum;
    tabletStatus: TabletStatusEnum;
    role: FlowTeamRolesEnum;
    applicabilityUuids: string[];
    functionUuids: string[];
    legalUuids: string[];
    contractUuids: string[];
    groupUuids: string[];
    hierarchyUuids: string[];
    skillUuids: string[];
    domainsUuids: string[];
    tabletFamilyUuids: string[];
  }

  export function mapFromApi(planTeamRole: TeamRolePlanResponseDTO): PlanTeamRoleResponseData {
    return {
      uuidEntity: planTeamRole.uuidEntity,
      name: planTeamRole.name,
      userRole: UserRoleEnum.convertFromApiValue.getValue(planTeamRole.choixTeamRole),
      natureTablet: UserNatureEnum.convertFromApiValue.getValue(planTeamRole.nature),
      teamRoleOrder: planTeamRole.teamRoleOrder,
      labelFamilyHierarchies: (planTeamRole.labelFamilyHierarchies || []).map(labelFamily => LabelFamilyLightData.mapFromApiValue(labelFamily)),
      skills: (planTeamRole['labelFamilySkills'] || []).map(labelFamily => LabelFamilyLightData.mapFromApiValue(labelFamily)),
      labelFamilyFunction: planTeamRole.labelFamilyFunction ? LabelFamilyLightData.mapFromApiValue(planTeamRole.labelFamilyFunction) : LabelFamilyLightData.defaultData,
      labelFamilyTeam: planTeamRole.labelFamilyTeam ? LabelFamilyLightData.mapFromApiValue(planTeamRole.labelFamilyTeam) : LabelFamilyLightData.defaultData,
      flowName: `${planTeamRole.flow?.refExt || planTeamRole.flow?.refInt} - ${planTeamRole.flow?.name}`,
      applicabilityName: planTeamRole.applicabilityName,
      userStatus: UserStatusEnum.convertFromApiValue.getValue(planTeamRole.status),
      tabletStatus: TabletStatusEnum.convertFromApiValue.getValue(planTeamRole['deviceStatus']),
      userUuidEntity: planTeamRole.userUuidEntity,
      alreadyImported: planTeamRole.alreadyImported,
      flows: `${planTeamRole.flows} flow(s)`,
      startDate: planTeamRole.flow?.startDate,
      endDate: planTeamRole.flow?.finishDate,
    };
  }

  export function initializeQueryRequest(
    queries: {
      projectUuidEntity: string;
      flowUuidEntity: string;
      teamRole: string;
    },
    reporterQueries: {
      applicabilityUuids: string[]; // Applicabilité
      userType: string; // Type
      functionUuids: string[]; // Fonction
      legalUuids: string[]; // Administratif
      contractUuids: string[]; // Contrat
      groupUuids: string[]; // Group
      hierarchies: string[]; // Hiérarchie
      userRole: string; // Rôle
      skillUuids: string[]; // Compétence
      domainUuids: string[]; // Domaine
    },
    tabletQueries: {
      type: string; // Type
      families: string[]; // Famille
      status: string; // Etat
    },
    searchText: string,
    sort: SortFilter<PlanTeamRoleResponseData>,
  ): QueryRequest {
    return {
      ...initializeQueries<PlanTeamRoleResponseData>(sort),
      projectUuidEntity: queries.projectUuidEntity,
      flowUuidEntity: queries.flowUuidEntity,
      textSearch: searchText || undefined,
      applicabilityUuids: reporterQueries?.applicabilityUuids?.length ? reporterQueries?.applicabilityUuids : undefined,
      userType: UserTypeEnum.convertFromParamsValue.getValue(reporterQueries.userType),
      functionUuids: reporterQueries?.functionUuids?.length ? reporterQueries?.functionUuids : undefined,
      legalUuids: reporterQueries?.legalUuids?.length ? reporterQueries?.legalUuids : undefined,
      contractUuids: reporterQueries?.contractUuids?.length ? reporterQueries?.contractUuids : undefined,
      groupUuids: reporterQueries.groupUuids?.length ? reporterQueries?.groupUuids : undefined,
      hierarchyUuids: reporterQueries?.hierarchies?.length ? reporterQueries?.hierarchies : undefined,
      userRole: UserRoleEnum.convertFromParamsValue.getValue(reporterQueries.userRole),
      skillUuids: reporterQueries.skillUuids?.length ? reporterQueries.skillUuids : undefined,
      domainsUuids: reporterQueries.domainUuids?.length ? reporterQueries.domainUuids : undefined,
      tabletType: UserNatureEnum.convertFromParamsValue.getValue(tabletQueries.type),
      tabletFamilyUuids: tabletQueries.families?.length ? tabletQueries.families : undefined,
      tabletStatus: TabletStatusEnum.convertFromParamsValue.getValue(tabletQueries.status),
      role: FlowTeamRolesEnum.convertFromParamValue.getValue(queries.teamRole),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageTeamRolePlanRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      projectUuidEntity: queries.projectUuidEntity,
      flowUuidEntity: queries.flowUuidEntity,
      applicabilityUuides: queries.applicabilityUuids,
      userType: UserTypeEnum.convertToParamsValue.getValue(queries.userType),
      labelFamilyFunctionUuides: queries.functionUuids,
      legalEntitiesUuides: queries.legalUuids,
      contratTypeUuides: queries.contractUuids,
      userLabelFamilyTeamUuides: queries.groupUuids || queries.tabletFamilyUuids,
      labelFamilyHierarchyUuides: queries.hierarchyUuids,
      choixTeamRole: UserRoleEnum.convertToApiValue.getValue(queries.userRole),
      labelFamilySkillUuides: queries.skillUuids,
      activityDomainsUuides: queries.domainsUuids,
      statuses: queries.tabletStatus ? [TabletStatusEnum.convertToApiValue.getValue(queries.tabletStatus)] : undefined,
      role: FlowTeamRolesEnum.convertToApiValue.getValue(queries.role),
      userNatures: FlowTeamRolesEnum.mapToUserNatures(queries.tabletType).getValue(queries.role),
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }
}
