import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ErrorResponseData, ListEmmSummaryData, ListAdrSummaryData, EmmSummaryData, AdrSummaryData, ListDetailsData } from '../../../../../core/models';
import * as fromActions from './lists-dialog-actions';

const emmAdapter: EntityAdapter<ListEmmSummaryData> = createEntityAdapter<ListEmmSummaryData>({
  selectId: emmList => emmList.uuidEntity,
});

const adrAdapter: EntityAdapter<ListAdrSummaryData> = createEntityAdapter<ListAdrSummaryData>({
  selectId: adrList => adrList.uuidEntity,
});

export interface DashboardListsDialogState {
  loading: boolean;
  isSaving: boolean;
  listDetails: ListDetailsData;
  emmList: EmmListDialogState;
  adrList: AdrListDialogState;
  error: ErrorResponseData;
}

export interface EmmListDialogState extends EntityState<ListEmmSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  details: EmmSummaryData;
  reset: boolean;
}

export interface AdrListDialogState extends EntityState<ListAdrSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  details: AdrSummaryData;
  reset: boolean;
}

export const emmListInitialState: EmmListDialogState = {
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  details: undefined,
  reset: false,
};

export const adrListInitialState: AdrListDialogState = {
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  details: undefined,
  reset: false,
};

export const initialDashboardListsDialogState = () => ({
  loading: false,
  isSaving: false,
  listDetails: {} as ListDetailsData,
  emmList: emmAdapter.getInitialState<EmmListDialogState>(emmListInitialState),
  adrList: adrAdapter.getInitialState<AdrListDialogState>(adrListInitialState),
  error: null,
});

const reducer = createReducer<DashboardListsDialogState>(
  initialDashboardListsDialogState(),
  on(fromActions.loadListDetailsSuccess, (state, list): DashboardListsDialogState => ({ ...state, listDetails: list })),
  on(fromActions.createList, (state): DashboardListsDialogState => ({ ...state, isSaving: true })),
  on(fromActions.createListSuccess, (state, { list }): DashboardListsDialogState => ({ ...state, listDetails: list, isSaving: false })),
  on(fromActions.updateList, (state): DashboardListsDialogState => ({ ...state, isSaving: true })),
  on(fromActions.updateListSuccess, (state, { list }): DashboardListsDialogState => ({ ...state, listDetails: list, isSaving: false })),

  on(
    fromActions.loadEmmList,
    fromActions.loadImportedEmmList,
    (state): DashboardListsDialogState => ({ ...state, emmList: emmAdapter.setAll([], { ...state.emmList, filteredTotalCount: undefined, loading: true }) }),
  ),
  on(
    fromActions.loadAdrList,
    fromActions.loadImportedAdrList,
    (state): DashboardListsDialogState => ({ ...state, adrList: adrAdapter.setAll([], { ...state.adrList, filteredTotalCount: undefined, loading: true }) }),
  ),
  on(
    fromActions.loadEmmListSuccess,
    fromActions.loadImportedEmmListSuccess,
    (state, { lazyResponse: { payload, totalCount, filteredTotalCount }, reset }): DashboardListsDialogState => {
      const newState: EmmListDialogState = { ...state.emmList, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, emmList: reset ? emmAdapter.setAll(payload, newState) : emmAdapter.addMany(payload, newState) };
    },
  ),
  on(
    fromActions.loadAdrListSuccess,
    fromActions.loadImportedAdrListSuccess,
    (state, { lazyResponse: { payload, totalCount, filteredTotalCount }, reset }): DashboardListsDialogState => {
      const adrState: AdrListDialogState = { ...state.adrList, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, adrList: reset ? adrAdapter.setAll(payload, adrState) : adrAdapter.addMany(payload, adrState) };
    },
  ),
  on(fromActions.loadEmmDetailsSuccess, (state, { details }): DashboardListsDialogState => ({ ...state, emmList: { ...state.emmList, details } })),
  on(fromActions.loadAdrDetailsSuccess, (state, { details }): DashboardListsDialogState => ({ ...state, adrList: { ...state.adrList, details } })),
);

export function dashboardListsDialogReducer(state: DashboardListsDialogState | undefined, action: Action): DashboardListsDialogState {
  return reducer(state, action);
}

export const selectAllEmmList = emmAdapter.getSelectors().selectAll;
export const selectAllAdrList = adrAdapter.getSelectors().selectAll;
