<div class="sfx-label-container">
  <span
    *ngIf="!editModeEnabled || !toggleEditMode"
    class="sfx-label truncated {{ sfxClass }}"
    [sfxTooltipCss]="innerTooltipCss"
    [position]="innerTooltipPosition || 'bottom'"
    [sfxTooltip]="showTooltip ? (text | translate) : ''"
    [enableTag]="tooltipInnerTag"
    [onTruncated]="!tooltipInnerTag"
    [innerHtml]="text | highlightText: searchText | translate"
    (click)="(editModeEnabled && (toggleEditMode = !toggleEditMode)) || $event"
  ></span>
  <sfx-input
    *ngIf="toggleEditMode"
    class="text-input"
    sfxFocus
    [ngModel]="text"
    (ngModelChange)="textValue = $event"
    (keyup.enter)="toggleEditMode = false; textValue?.trim() && textChange.emit(textValue)"
    (blur)="textChange.emit(textValue)"
    (blur)="toggleEditMode = false"
  ></sfx-input>
</div>
