/**
 * @deprecated
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { RevisionStatusEnum, InnerSortDirectionEnum } from '../../enums';

export interface RevisionSummaryData {
  uuidEntity: string;
  reference: string;
  comment: string;
  status: RevisionStatusEnum;
}

export namespace RevisionSummaryData {
  export interface QueryRequest extends BaseQuery<RevisionSummaryData> {
    flowUuidEntity: string;
  }

  export function mapFromApiValue(revision: any): RevisionSummaryData {
    return {
      reference: revision.reference,
      comment: revision.comment,
      status: RevisionStatusEnum.convertFromApiValue.getValue(revision.status),
      uuidEntity: revision.uuidEntity,
    };
  }

  export function mapToCreateRequestApiValue(event: RevisionSummaryData, flowUuidEntity: string, projectUuidEntity: string): any {
    return {
      uuideProject: projectUuidEntity,
      reference: event.reference,
      comment: event.comment,
      flowUuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(event: RevisionSummaryData, projectUuidEntity: string): any {
    return {
      projectUuidEntity,
      reference: event.reference,
      revisionUuidEntity: event.uuidEntity,
      comment: event.comment,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): any {
    return {
      page: queries.page,
      size: queries.size,
      flowUuidEntity: queries.flowUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(page: number, searchText?: string, flowUuidEntity?: string): QueryRequest {
    return {
      ...initializeQueries<RevisionSummaryData>({ key: 'reference' }),
      page,
      textSearch: searchText || undefined,
      flowUuidEntity,
    };
  }

  export function mapToSingleSelectItemFilter(revision: any): SingleSelectItemData<RevisionSummaryData> {
    return {
      value: mapFromApiValue(revision),
      title: revision.reference,
      tooltip: revision.comment,
      icons: revision.status === 'INPROGRESS' ? [{ name: 'door-open' }] : [],
    };
  }
}
