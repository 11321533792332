import { FlowSummaryData } from '../flows/flow-summary';
import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { FamilyDeleteResponseDTO } from '../../generated/models/models';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FamilyDeleteNatureEnum } from '../../enums';
import { RiskSummaryData } from '../library-risk/risk-summary-data';
import { FormLibrarySummaryData } from '../form-library/form-library-summary';
import { SkillSummaryData } from '../contributors/skill-summary-data';
import { LibraryEquipmentSummaryData } from '../library-equipment/library-equipment-summary-data';
import { ProjectSummaryData } from '../projects/project-summary';
import { LibraryContributorSummaryData } from '../library-contributor/library-contributor-summary-data';
import { CTabletSummaryData } from '../tablets/cTablet-summary-data';
import { FamilySummaryData } from './families-summary-data';
import { CustomFieldLightData, CustomStatusLightData } from '../../models';

export interface DeleteFamilyResponse {
  success: boolean;
  nature: FamilyDeleteNatureEnum;
  flowLinks: FlowSummaryData[];
  documentLinks: DocumentFlowSummaryData[];
  riskLinks: RiskSummaryData[];
  equipmentLinks: LibraryEquipmentSummaryData[];
  projectLinks: ProjectSummaryData[];
  formLinks: FormLibrarySummaryData[];
  skillLinks: SkillSummaryData[];
  userLinks: LibraryContributorSummaryData[];
  deviceLinks: CTabletSummaryData[];
  childLinks: FamilySummaryData[];
  statusLinks: CustomStatusLightData[];
  attributeLinks: CustomFieldLightData[];
}

export namespace DeleteFamilyResponse {
  export function mapFromApiValue(data: FamilyDeleteResponseDTO): DeleteFamilyResponse {
    return {
      success: data.success,
      nature: FamilyDeleteNatureEnum.convertFromApiValue.getValue(data.nature),
      flowLinks: (data.flowLinks || []).map(flow => FlowSummaryData.mapFromApiValue(flow)),
      documentLinks: (data.documentLinks || []).map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
      riskLinks: (data.adrLinks || []).map(risk => RiskSummaryData.mapFromApiValue(risk)),
      equipmentLinks: (data.emmLinks || []).map(equipment => LibraryEquipmentSummaryData.mapFromApiValue(equipment)),
      projectLinks: (data.projectLinks || []).map(project => ProjectSummaryData.mapFromApiValue(project)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formLinks: (data.formLinks || []).map(form => FormLibrarySummaryData.mapFromApiValue(form as any)),
      skillLinks: (data.skillLinks || []).map(skill => SkillSummaryData.mapFromApiValue(skill)),
      deviceLinks: (data.deviceLinks || []).map(device => CTabletSummaryData.mapFromApiValue(device)),
      userLinks: (data.userLinks || []).map(user => LibraryContributorSummaryData.mapFromApiValue(user)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      childLinks: (data.childsLinks || []).map(child => FamilySummaryData.mapFromApiValue(child as any)),
      statusLinks: (data.statusLinks || []).map(status => CustomStatusLightData.mapFromApiValue(status)),
      attributeLinks: (data.attributeLinks || []).map(attribute => CustomFieldLightData.mapFromApiValue(attribute)),
    };
  }

  export function mapToConflictData(response: DeleteFamilyResponse): ConflictData {
    switch (response.nature) {
      case FamilyDeleteNatureEnum.Flow:
        return { title: 'global.entityConflict.hint.family.flow', links: response.flowLinks.map(flow => FlowSummaryData.toString(flow)) };

      case FamilyDeleteNatureEnum.Document:
        return {
          title: 'global.entityConflict.hint.family.document',
          links: response.documentLinks.map(document => DocumentFlowSummaryData.toString(document)),
        };

      case FamilyDeleteNatureEnum.Risk:
        return {
          title: 'global.entityConflict.hint.family.risk',
          links: response.riskLinks.map(risk => RiskSummaryData.toString(risk)),
        };

      case FamilyDeleteNatureEnum.Equipment:
        return {
          title: 'global.entityConflict.hint.family.equipment',
          links: response.equipmentLinks.map(equipment => LibraryEquipmentSummaryData.toString(equipment)),
        };

      case FamilyDeleteNatureEnum.Project:
        return {
          title: 'global.entityConflict.hint.family.entity',
          links: response.projectLinks.map(project => ProjectSummaryData.toString(project)),
        };

      case FamilyDeleteNatureEnum.Form:
        return {
          title: 'global.entityConflict.hint.family.form',
          links: response.formLinks.map(form => FormLibrarySummaryData.toString(form)),
        };

      case FamilyDeleteNatureEnum.Function:
        return {
          title: 'global.entityConflict.hint.family.function',
          links: response.userLinks.map(user => LibraryContributorSummaryData.toString(user)),
        };

      case FamilyDeleteNatureEnum.Team:
        return {
          title: 'global.entityConflict.hint.family.team',
          links: response.userLinks.map(user => LibraryContributorSummaryData.toString(user)),
        };

      case FamilyDeleteNatureEnum.Skill:
        return {
          title: 'global.entityConflict.hint.family.skill',
          links: response.skillLinks.map(user => SkillSummaryData.toString(user)),
        };

      case FamilyDeleteNatureEnum.Device:
        return {
          title: 'global.entityConflict.hint.family.device',
          links: response.deviceLinks.map(device => CTabletSummaryData.toString(device)),
        };

      case FamilyDeleteNatureEnum.Child:
        return { title: 'global.entityConflict.hint.family.child', links: response.childLinks.map(document => FamilySummaryData.toString(document)) };

      case FamilyDeleteNatureEnum.Status:
        return {
          title: 'global.entityConflict.hint.family.status',
          links: response.statusLinks.map(status => status.name),
        };

      case FamilyDeleteNatureEnum.Attribute:
        return {
          title: 'global.entityConflict.hint.family.attribute',
          links: response.attributeLinks.map(attribute => attribute.name),
        };

      default:
        return null;
    }
  }
}
