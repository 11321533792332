import { ExchangeCodeResponse } from '../../generated/models/models';

export interface ExchangeCodeData {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  resource: string;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  scope: string;
  idToken: string;
}

export namespace ExchangeCodeData {
  export function mapFromApiValue(data: ExchangeCodeResponse): ExchangeCodeData {
    return {
      accessToken: data.access_token,
      tokenType: data.token_type,
      expiresIn: data.expires_in,
      resource: data.resource,
      refreshToken: data.refresh_token,
      refreshTokenExpiresIn: data.refresh_token_expires_in,
      scope: data.scope,
      idToken: data.id_token,
    };
  }
}
