/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApplicationFileVersionOptionResponseDTO {
  name: string;
  action: ApplicationFileVersionOptionResponseDTO.ActionEnum;
}
export namespace ApplicationFileVersionOptionResponseDTO {
  export type ActionEnum = 'ADD' | 'DELETE';
  export const ActionEnum = {
    ADD: 'ADD' as ActionEnum,
    DELETE: 'DELETE' as ActionEnum,
  };
}
