/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomFieldLightDTO } from './customFieldLightDTO';
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { DeviceSummaryResponseDTO } from './deviceSummaryResponseDTO';
import { DocumentFlowSummaryResponseDTO } from './documentFlowSummaryResponseDTO';
import { ElementAdrSummaryResponseDTO } from './elementAdrSummaryResponseDTO';
import { ElementEmmSummaryResponseDTO } from './elementEmmSummaryResponseDTO';
import { FlowSummaryResponseDTO } from './flowSummaryResponseDTO';
import { FormSummaryResponseDTO } from './formSummaryResponseDTO';
import { LabelFamilySummaryResponseDTO } from './labelFamilySummaryResponseDTO';
import { ProjectSummaryResponseDTO } from './projectSummaryResponseDTO';
import { SkillSummaryResponseDTO } from './skillSummaryResponseDTO';
import { UserSummaryResponseDTO } from './userSummaryResponseDTO';

export interface FamilyDeleteResponseDTO {
  success: boolean;
  nature: FamilyDeleteResponseDTO.NatureEnum;
  childsLinks: Array<LabelFamilySummaryResponseDTO>;
  projectLinks: Array<ProjectSummaryResponseDTO>;
  flowLinks: Array<FlowSummaryResponseDTO>;
  documentLinks: Array<DocumentFlowSummaryResponseDTO>;
  formLinks: Array<FormSummaryResponseDTO>;
  adrLinks: Array<ElementAdrSummaryResponseDTO>;
  emmLinks: Array<ElementEmmSummaryResponseDTO>;
  deviceLinks: Array<DeviceSummaryResponseDTO>;
  userLinks: Array<UserSummaryResponseDTO>;
  skillLinks: Array<SkillSummaryResponseDTO>;
  attributeLinks: Array<CustomFieldLightDTO>;
  statusLinks: Array<CustomStatusLightDTO>;
}
export namespace FamilyDeleteResponseDTO {
  export type NatureEnum =
    | 'DOCUMENT'
    | 'FLOW'
    | 'LIST'
    | 'HIERARCHY'
    | 'CHILD'
    | 'ADR'
    | 'EMM'
    | 'TEAM'
    | 'FUNCTION'
    | 'SKILL'
    | 'PROJECT'
    | 'FORM'
    | 'DEVICE'
    | 'STATUS'
    | 'ATTRIBUTE';
  export const NatureEnum = {
    DOCUMENT: 'DOCUMENT' as NatureEnum,
    FLOW: 'FLOW' as NatureEnum,
    LIST: 'LIST' as NatureEnum,
    HIERARCHY: 'HIERARCHY' as NatureEnum,
    CHILD: 'CHILD' as NatureEnum,
    ADR: 'ADR' as NatureEnum,
    EMM: 'EMM' as NatureEnum,
    TEAM: 'TEAM' as NatureEnum,
    FUNCTION: 'FUNCTION' as NatureEnum,
    SKILL: 'SKILL' as NatureEnum,
    PROJECT: 'PROJECT' as NatureEnum,
    FORM: 'FORM' as NatureEnum,
    DEVICE: 'DEVICE' as NatureEnum,
    STATUS: 'STATUS' as NatureEnum,
    ATTRIBUTE: 'ATTRIBUTE' as NatureEnum,
  };
}
