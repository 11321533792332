import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum TabletModalTabEnum {
  General = 'general',
  Information = 'information',
  PersonToNotify = 'personToNotify',
  Options = 'option',
}

export namespace TabletModalTabEnum {
  const key = 'tablets.modals.main.tabs.';
  export const toString: SfxMap<TabletModalTabEnum, string> = new SfxMap<TabletModalTabEnum, string>([
    [TabletModalTabEnum.General, key + 'general'],
    [TabletModalTabEnum.Information, key + 'informations'],
    [TabletModalTabEnum.PersonToNotify, key + 'personToNotify'],
    [TabletModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<TabletModalTabEnum, IconData> = new SfxMap<TabletModalTabEnum, IconData>([
    [TabletModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [TabletModalTabEnum.Information, { name: 'binoculars', prefix: 'fas' }],
    [TabletModalTabEnum.PersonToNotify, { name: 'truck', prefix: 'fas' }],
    [TabletModalTabEnum.Options, { name: 'wrench', prefix: 'fas' }],
  ]);
}
