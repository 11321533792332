import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FormUpdateCustomStatusRequestDTO } from '../../generated/models/formUpdateCustomStatusRequestDTO';

export interface FormResponseForUpdate {
  uuidEntity: string;
  customStatus: CustomStatusLightData;
  projectUuidEntity: string;
  formFamilyUuidEntity: string;
  comment?: string;
}

export namespace FormResponseForUpdate {
  export function mapToUpdateRequestApiValue(response: FormResponseForUpdate): FormUpdateCustomStatusRequestDTO {
    return {
      formUuidEntity: response.uuidEntity,
      customStatusUuidEntity: response.customStatus?.uuidEntity,
      comment: response.comment,
    };
  }
}
