import { DocumentStatusEnum } from '../../enums';
import { RfiSummaryResponseDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { UserLightData } from '../user/user-light-data';

export interface FolderForPropertiesData {
  name: string;
  state: DocumentStatusEnum;
  refIntern: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserLightData;
  updatedBy: UserLightData;
}

export namespace FolderForPropertiesData {
  export function mapToPropertiesData(folder: RfiSummaryResponseDTO): FolderForPropertiesData {
    return {
      name: folder.name,
      state: DocumentStatusEnum.convertFromApiValue.getValue(folder.etat),
      refIntern: folder.refInt,
      createdAt: folder.createDate && DateUtils.toDateTimeFormat(folder.createDate),
      updatedAt: folder.modificationDate && DateUtils.toDateTimeFormat(folder.modificationDate),
      createdBy: folder.createdBy && UserLightData.mapFromApiValue(folder.createdBy),
      updatedBy: folder.lastModifiedBy && UserLightData.mapFromApiValue(folder.lastModifiedBy),
    };
  }
}
