import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromObjectActions from './object-dialog.actions';
import { dummyAction } from '../../../../main/state/main.actions';

import {
  CustomFieldData,
  ObjectFlowSummaryData,
  FlowToImportSummaryData,
  TechnicalObjectForDuplicate,
  FlowDetailsData,
  TechnicalObjectDetailsData,
} from '../../../../../core/models';

export interface ObjectDialogState {
  objectDetails: TechnicalObjectDetailsData;
  customFields: CustomFieldData[];
  initialObjectDetails: TechnicalObjectDetailsData;
  initialCustomFields: CustomFieldData[];
  contentFlows: { flows: ObjectFlowSummaryData[]; totalCount: number; page?: number };
  historyFlows: { flows: ObjectFlowSummaryData[]; totalCount: number; page?: number };
  hasRefIntGenerated: boolean;
  hasRefExtGenerated: boolean;
  saveLoading: boolean;
}

export interface AssignDialogState {
  assignFlows: { flows: FlowToImportSummaryData[]; totalCount: number; filteredTotalCount: number; page?: number };
  assignLoading: boolean;
}

export interface GenerateDialogState {
  generatedFlow: FlowDetailsData;
  generateLoading: boolean;
  flowHasRefGenerated: boolean;
}

export interface DuplicateDialogState {
  duplicatedObject: TechnicalObjectForDuplicate;
  duplicateLoading: boolean;
}

export interface LibraryObjectDialogState {
  objectDialogState: ObjectDialogState;
  assignDialogState: AssignDialogState;
  generateDialogState: GenerateDialogState;
  duplicateDialogState: DuplicateDialogState;
}

const initialObjectDialogState: ObjectDialogState = {
  objectDetails: null,
  customFields: null,
  initialObjectDetails: null,
  initialCustomFields: null,
  contentFlows: null,
  historyFlows: null,
  hasRefIntGenerated: false,
  hasRefExtGenerated: false,
  saveLoading: false,
};

const initialAssignDialogState: AssignDialogState = {
  assignFlows: undefined,
  assignLoading: false,
};

const initialGenerateDialogState: GenerateDialogState = {
  generatedFlow: null,
  generateLoading: false,
  flowHasRefGenerated: false,
};

const initialDuplicateDialogState: DuplicateDialogState = {
  duplicatedObject: null,
  duplicateLoading: false,
};

export const initialLibraryObjectDialogState = () => ({
  objectDialogState: { ...initialObjectDialogState },
  assignDialogState: { ...initialAssignDialogState },
  generateDialogState: { ...initialGenerateDialogState },
  duplicateDialogState: { ...initialDuplicateDialogState },
});

const reducer = createReducer<LibraryObjectDialogState>(
  initialLibraryObjectDialogState(),
  on(
    fromObjectActions.loadObjectDetailsSuccess,
    (state, { objectDetails }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: {
        ...state.objectDialogState,
        objectDetails,
        initialObjectDetails: cloneDeep(objectDetails),
        customFields: [],
        initialCustomFields: [],
      },
    }),
  ),
  on(
    fromObjectActions.saveChangesObjectDialog,
    fromObjectActions.updateObject,
    fromObjectActions.saveObjectCustomFields,
    fromObjectActions.loadObjectCustomFields,
    (state): LibraryObjectDialogState => ({ ...state, objectDialogState: { ...state.objectDialogState, saveLoading: true } }),
  ),
  on(
    fromObjectActions.addObjectFail,
    fromObjectActions.updateObjectFail,
    fromObjectActions.saveObjectCustomFieldsFail,
    fromObjectActions.loadObjectCustomFieldsFail,
    (state): LibraryObjectDialogState => ({ ...state, objectDialogState: { ...state.objectDialogState, saveLoading: false } }),
  ),
  on(
    fromObjectActions.addObjectSuccess,
    (state, { objectDetails }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: { ...state.objectDialogState, objectDetails, initialObjectDetails: cloneDeep(objectDetails), saveLoading: false },
    }),
  ),
  on(
    fromObjectActions.loadObjectCustomFieldsSuccess,
    (state, { customFields }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: { ...state.objectDialogState, customFields, saveLoading: false, initialCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromObjectActions.saveObjectCustomFieldsSuccess,
    (state, { customFields }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: { ...state.objectDialogState, saveLoading: false, initialCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromObjectActions.initializeObjectDetails,
    (state, { objectDetails, createAnother }): LibraryObjectDialogState => {
      const newObject = !createAnother
        ? objectDetails
        : ({
            nature: objectDetails.nature,
            applicability: objectDetails.applicability,
            labelFamily: objectDetails.labelFamily,
            parentUuidEntity: objectDetails.parentUuidEntity,
          } as TechnicalObjectDetailsData);

      return {
        ...state,
        objectDialogState: {
          ...state.objectDialogState,
          objectDetails: newObject,
          initialObjectDetails: cloneDeep(newObject),
          customFields: createAnother ? [] : state.objectDialogState.customFields,
          initialCustomFields: createAnother ? [] : state.objectDialogState.initialCustomFields,
        },
      };
    },
  ),
  on(
    fromObjectActions.checkAllReferenceGeneratedSuccess,
    (state, { hasRefExtGenerated, hasRefIntGenerated, reset }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: {
        ...state.objectDialogState,
        hasRefIntGenerated,
        hasRefExtGenerated,
        objectDetails: {
          ...state.objectDialogState.objectDetails,
          refInt: hasRefIntGenerated && reset ? null : state.objectDialogState?.objectDetails?.refInt,
          refExt: hasRefExtGenerated && reset ? null : state.objectDialogState?.objectDetails?.refExt,
        },
      },
    }),
  ),
  on(
    fromObjectActions.loadContentFlowsSuccess,
    (state, { flows, totalCount, reset }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: {
        ...state.objectDialogState,
        contentFlows: reset ? { flows, totalCount, page: 0 } : { ...state.objectDialogState.contentFlows, flows: [...state.objectDialogState.contentFlows.flows, ...flows] },
      },
    }),
  ),
  on(
    fromObjectActions.loadHistoryFlowsSuccess,
    (state, { flows, totalCount, reset }): LibraryObjectDialogState => ({
      ...state,
      objectDialogState: {
        ...state.objectDialogState,
        historyFlows: reset ? { flows, totalCount, page: 0 } : { ...state.objectDialogState.historyFlows, flows: [...state.objectDialogState.historyFlows.flows, ...flows] },
      },
    }),
  ),
  on(
    fromObjectActions.loadAssignFlowsSuccess,
    (state, { flows, totalCount, filteredTotalCount, reset }): LibraryObjectDialogState => ({
      ...state,
      assignDialogState: {
        ...state.assignDialogState,
        assignFlows: reset
          ? { flows, totalCount, filteredTotalCount, page: 0 }
          : { ...state.assignDialogState.assignFlows, flows: [...state.assignDialogState.assignFlows.flows, ...flows] },
      },
    }),
  ),
  on(fromObjectActions.assignFlows, (state): LibraryObjectDialogState => ({ ...state, assignDialogState: { ...state.assignDialogState, assignLoading: true } })),
  on(fromObjectActions.assignFlowsFail, (state): LibraryObjectDialogState => ({ ...state, assignDialogState: { ...state.assignDialogState, assignLoading: false } })),
  on(fromObjectActions.assignFlowsSuccess, (state): LibraryObjectDialogState => ({ ...state, assignDialogState: { ...state.assignDialogState, assignLoading: false } })),

  on(
    fromObjectActions.initializeGeneratedFlow,
    (state, { generatedFlow }): LibraryObjectDialogState => ({ ...state, generateDialogState: { ...state.generateDialogState, generatedFlow } }),
  ),
  on(fromObjectActions.generateFlow, (state): LibraryObjectDialogState => ({ ...state, generateDialogState: { ...state.generateDialogState, generateLoading: true } })),
  on(fromObjectActions.generateFlowFail, (state): LibraryObjectDialogState => ({ ...state, generateDialogState: { ...state.generateDialogState, generateLoading: false } })),
  on(fromObjectActions.generateFlowSuccess, (state): LibraryObjectDialogState => ({ ...state, generateDialogState: { ...state.generateDialogState, generateLoading: false } })),
  on(
    fromObjectActions.checkFlowHasRefGeneratedSuccess,
    (state, { flowHasRefGenerated }): LibraryObjectDialogState => ({ ...state, generateDialogState: { ...state.generateDialogState, flowHasRefGenerated } }),
  ),

  on(fromObjectActions.getDuplicatedObjectSuccess, (state, { duplicatedObject }) => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicatedObject } })),

  on(fromObjectActions.duplicateObject, (state): LibraryObjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: true } })),
  on(fromObjectActions.duplicateObjectFail, (state): LibraryObjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } })),
  on(
    fromObjectActions.duplicateObjectSuccess,
    (state): LibraryObjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } }),
  ),

  on(dummyAction, (state): LibraryObjectDialogState => ({ ...state, objectDialogState: { ...state.objectDialogState, saveLoading: false } })),
);

export function libraryObjectDialogReducer(state: LibraryObjectDialogState | undefined, action: Action): LibraryObjectDialogState {
  return reducer(state, action);
}
