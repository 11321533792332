import { ReorganizeModalTypeEnum, UserRightEnum } from '../../enums';
import { UserReorganizeRequestDTO } from '../../generated/models/userReorganizeRequestDTO';
import { EntityLightData } from '../utils/entity-light-data';

export interface LibraryReorganizeContributorUsersData {
  usersUuidEntity: string[];
  selectedLabels: EntityLightData[];
  nature: ReorganizeModalTypeEnum;
  userRoleLabels?: UserRightEnum[];
}

export namespace LibraryReorganizeContributorUsersData {
  export function mapToRequestApiValue(data: LibraryReorganizeContributorUsersData): UserReorganizeRequestDTO {
    return {
      usersUuides: data.usersUuidEntity || [],
      nature: data?.nature ? ReorganizeModalTypeEnum.convertToApiValue.getValue(data.nature) : undefined,
      labelsUuidEntity: data?.selectedLabels?.length ? data.selectedLabels.map(label => label.uuidEntity) : undefined,
      userRoleLabels: data?.userRoleLabels?.length ? data.userRoleLabels.map(role => UserRightEnum.convertToApiValue.getValue(role)) : undefined,
    };
  }
}
