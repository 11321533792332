import { WordingConfigurationLightDTO } from '../../generated/models/wordingConfigurationLightDTO';

export interface WordingConfigLightData {
  uuidEntity: string;
  name: string;
}

export namespace WordingConfigLightData {
  export function mapFromApiValue(data: WordingConfigurationLightDTO): WordingConfigLightData {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
    };
  }
}
