import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { CustomStatusDetailsData } from '../../../../../core/models';

import { BusinessClassNameEnum } from '../../../../../core/enums';

export const setCustomStatus = createAction('[sStatuses] Set customStatus details', props<{ customStatus: CustomStatusDetailsData }>());
export const getCustomStatusDetailsFail = createAction('[sStatuses] get customStatus details fail', props<DialogToasterData>());

export const addCustomStatus = createAction('[sStatuses] add new customStatus', props<{ customStatus: CustomStatusDetailsData; addNewOne?: boolean }>());
export const addCustomStatusSuccess = createAction('[sStatuses] add new customStatus success', props<{ customStatus: CustomStatusDetailsData; addNewOne?: boolean }>());
export const addCustomStatusFail = createAction('[sStatuses] add new customStatus fail', props<DialogToasterData>());

export const updateCustomStatus = createAction('[sStatuses] update customStatus', props<{ customStatus: CustomStatusDetailsData; closeModal?: boolean }>());
export const updateCustomStatusSuccess = createAction('[sStatuses] update customStatus success', props<{ customStatus: CustomStatusDetailsData; closeModal?: boolean }>());
export const updateCustomStatusFail = createAction('[sStatuses] update customStatus fail', props<DialogToasterData>());

export const navigateThroughCustomStatus = createAction('[sStatuses] Navigate through customStatus', props<{ customStatus: CustomStatusDetailsData; step: number }>());

export const setStatusColor = createAction('[sStatus] set customStatus color success', props<{ color: string }>());
export const checkExistingDefaultStatus = createAction('[sStatus] Check existing default status', props<{ statusNature: BusinessClassNameEnum; familyUuidEntity: string }>());
export const setStatusAsDefault = createAction('[sStatus] Set the status as Default', props<{ defaultStatus: boolean }>());
