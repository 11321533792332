import { SignatorySummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum SignatureSourceEnum {
  WEB = 1,
  MOBILE,
}

export namespace SignatureSourceEnum {
  const tooltipKey = 'flowSignature.tooltips.';
  export const tooltip = new SfxMap<SignatureSourceEnum, string>([
    [SignatureSourceEnum.WEB, tooltipKey + 'web'],
    [SignatureSourceEnum.MOBILE, tooltipKey + 'mobile'],
  ]);

  export const convertFromAPiValue = new SfxMap<SignatorySummaryResponseDTO.SignatorySourceEnum, SignatureSourceEnum>([
    [SignatorySummaryResponseDTO.SignatorySourceEnum.WEB, SignatureSourceEnum.WEB],
    [SignatorySummaryResponseDTO.SignatorySourceEnum.MOBILE, SignatureSourceEnum.MOBILE],
  ]);
}
