import { BusinessClassNameEnum, LanguageEnum } from '../../enums';
import { SendShareLinkCodeResponseDto } from '../../generated/models/sendShareLinkCodeResponseDto';

import { UserShareData } from './user-share-data';

export interface EntityShareData {
  actionUser: UserShareData;
  contributor: UserShareData;
  uuidEntity: string;
  businessClass: BusinessClassNameEnum;
  refUuidEntity: string; // info: not used should be removed
  contributionUuidEntity: string;
  language: LanguageEnum;
}

export namespace EntityShareData {
  export function mapFromApiValue(data: SendShareLinkCodeResponseDto): EntityShareData {
    return {
      actionUser: data.actionUser && UserShareData.mapFromUserLightDTOV15(data.actionUser),
      contributor: data.contributor && UserShareData.mapFromSharedLinkContributor(data.contributor),
      uuidEntity: data.uuidEntity,
      businessClass: BusinessClassNameEnum.convertFromApiValue.getValue(data.businessClass),
      refUuidEntity: data.refUuide,
      contributionUuidEntity: data.refUuideSecondary,
      language: LanguageEnum.convertFromApiValue.getValue(data.language),
    };
  }
}
