export interface UserConnection {
  token: string;
}

export namespace UserConnection {
  export function mapFromApiValue(loginData: { id_token: string }): UserConnection {
    return {
      token: loginData.id_token,
    };
  }
}
