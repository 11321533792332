import { ResponseUpdateRequestDTO } from '../../generated/models/models';

export interface FormResponseReferenceForUpdate {
  uuidEntity: string;
  reference: string;
}

export namespace FormResponseReferenceForUpdate {
  export function mapToUpdateRequestApiValue(response: FormResponseReferenceForUpdate): ResponseUpdateRequestDTO {
    return {
      uuidEntity: response.uuidEntity,
      reference: response.reference,
    };
  }
}
