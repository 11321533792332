import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FrequencyTypeEnum {
  Simple = 1,
  Complex = 2,
}

export namespace FrequencyTypeEnum {
  const values = [FrequencyTypeEnum.Simple, FrequencyTypeEnum.Complex];

  const key = 'schedule.frequencyType.';

  export const toStringYearly = new SfxMap<FrequencyTypeEnum, string>([
    [FrequencyTypeEnum.Simple, key + 'simple'],
    [FrequencyTypeEnum.Complex, key + 'complex'],
  ]);

  export const toStringMonthly = new SfxMap<FrequencyTypeEnum, string>([
    [FrequencyTypeEnum.Simple, key + 'complex'],
    [FrequencyTypeEnum.Complex, key + 'complex'],
  ]);

  export const yearlySelectedItems: SingleSelectItemData<FrequencyTypeEnum>[] = values.map(month => ({ value: month, title: toStringYearly.getValue(month) }));

  export const monthlySelectedItems: SingleSelectItemData<FrequencyTypeEnum>[] = values.map(month => ({ value: month, title: toStringMonthly.getValue(month) }));
}
