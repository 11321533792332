import { AppConfigResponseDTO } from '../../generated/models/appConfigResponseDTO';
import { AppConfigUpdateRequestDTO } from '../../generated/models/appConfigUpdateRequestDTO';

import { DateFormatData } from './date-format-data';

import { DocumentNatureIndexEnum, LanguageEnum, UserRightEnum } from '../../enums';

export interface AppConfigDetailsData {
  isModulePgacActive: boolean;
  autoSignature: boolean;
  firstLanguage: LanguageEnum;
  secondLanguage: LanguageEnum;
  isModuleFileProcessingActive: boolean;
  natureIndex: DocumentNatureIndexEnum;
  waterMark: string;
  showLogo: boolean;
  xLogoPosition: number;
  YLogoPosition: number;
  logoHeight: number;
  logoWidth: number;
  subContractor: string;
  multiPrTitleHidden: boolean;
  authoritiesBlackList: UserRightEnum[];
  webDateFormat: DateFormatData;
  mobileDateFormat: DateFormatData;
  pdfDateFormat: DateFormatData;
  lockForm?: boolean;
}

export namespace AppConfigDetailsData {
  export function mapFromApiValue(appConfig: AppConfigResponseDTO): AppConfigDetailsData {
    return {
      isModulePgacActive: appConfig.modulePgac,
      autoSignature: appConfig.autoSignature,
      firstLanguage: LanguageEnum.convertFromApiValue.getValue(appConfig.exportLanguageOne),
      secondLanguage: LanguageEnum.convertFromApiValue.getValue(appConfig.exportLanguageTwo),
      isModuleFileProcessingActive: appConfig.moduleFileProcessing,
      natureIndex: DocumentNatureIndexEnum.convertFromApiValue.getValue(appConfig.indiceNature),
      waterMark: appConfig.waterMark,
      showLogo: appConfig.showLogo,
      xLogoPosition: appConfig.xlogoPosition,
      YLogoPosition: appConfig.ylogoPosition,
      logoHeight: appConfig.logoHeight,
      logoWidth: appConfig.logoWidth,
      subContractor: appConfig.subContractor,
      multiPrTitleHidden: appConfig.multiPrTitleHidden,
      authoritiesBlackList: (appConfig.authoritiesBlackList || []).map(role => UserRightEnum.convertFromApiValue.getValue(role)),
      webDateFormat: appConfig.webAppDisplayConfig && DateFormatData.mapFromApiValue(appConfig.webAppDisplayConfig),
      pdfDateFormat: appConfig.pdfDisplayConfig && DateFormatData.mapFromApiValue(appConfig.pdfDisplayConfig),
      mobileDateFormat: appConfig.mobileAppDisplayConfig && DateFormatData.mapFromApiValue(appConfig.mobileAppDisplayConfig),
      lockForm: appConfig.lockForm,
    };
  }

  export function mapToUpdateRequestApiValue(appConfig: AppConfigDetailsData): AppConfigUpdateRequestDTO {
    return {
      modulePgac: appConfig.isModulePgacActive,
      autoSignature: appConfig.autoSignature,
      exportLanguageOne: LanguageEnum.convertToApiValue.getValue(appConfig.firstLanguage),
      exportLanguageTwo: LanguageEnum.convertToApiValue.getValue(appConfig.secondLanguage),
      moduleFileProcessing: appConfig.isModuleFileProcessingActive,
      indiceNature: DocumentNatureIndexEnum.convertToApiValue.getValue(appConfig.natureIndex),
      waterMark: appConfig.waterMark,
      showLogo: appConfig.showLogo,
      xlogoPosition: appConfig.xLogoPosition,
      ylogoPosition: appConfig.YLogoPosition,
      logoHeight: appConfig.logoHeight,
      logoWidth: appConfig.logoWidth,
      subContractor: appConfig.subContractor,
      multiPrTitleHidden: appConfig.multiPrTitleHidden,
      authoritiesBlackList: (appConfig.authoritiesBlackList || []).map(role => UserRightEnum.convertToAuthoritiesBlackListApiValue.getValue(role)),
      mobileAppDisplayConfig: DateFormatData.mapToApiValue(appConfig.mobileDateFormat),
      webAppDisplayConfig: DateFormatData.mapToApiValue(appConfig.webDateFormat),
      pdfDisplayConfig: DateFormatData.mapToApiValue(appConfig.pdfDateFormat),
      lockForm: appConfig.lockForm,
    };
  }
}
