import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import {
  LazyLoadResponse,
  ErrorResponseData,
  ApplicationFileLightData,
  LibraryReorganizeContributorUsersData,
  LibraryContributorSummaryData,
  SkillDetailsData,
  LibraryContributorDetailsData,
  SkillSummaryData,
} from '../../../../../core/models';
import { OperatorErrorEnum, PopoverPositionEnum } from '../../../../../core/enums';

export const loadSkillsOperatorList = createAction('[sOperator/API] Load skills operators list');
export const loadSkillsOperatorListSuccess = createAction(
  '[sOperator/API] Load skills operators list success',
  props<{ data: LazyLoadResponse<SkillSummaryData[]>; reset?: boolean }>(),
);
export const loadSkillOperatorListFails = createAction('[lOperator/API] Load skills operators list fails', props<DialogToasterData>());

export const loadMoreSkillsOperator = createAction('[sUsers/API] Load more skills operators');

export const loadUserDetailDataSuccess = createAction('[sUsersLoadData] load user data data success', props<LibraryContributorDetailsData>());
export const loadUserDetailDataFail = createAction('[sUsersLoadData] load user data data fail', props<DialogToasterData>());

export const addOperator = createAction('[lOperator] add new operators', props<{ operator: LibraryContributorDetailsData; addNewOne?: boolean }>());
export const addOperatorSuccess = createAction('[lOperator] add new operators success', props<{ addNewOne?: boolean }>());
export const addOperatorFail = createAction('[lOperator] add new operators fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());

export const updateOperator = createAction('[lOperator] update operators', props<{ operator: LibraryContributorDetailsData; closeModal?: boolean }>());
export const updateOperatorSuccess = createAction('[lOperator] update operators success', props<{ closeModal?: boolean }>());
export const updateOperatorFail = createAction('[lOperator] update operators fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());

export const openSkillPopover = createAction('[lOperator] open skill popover', props<{ origin: HTMLElement; position: PopoverPositionEnum; skillUuidEntity?: string }>());
export const openPdfPopover = createAction('[lOperator] open pdf popover', props<{ origin: HTMLElement; position: PopoverPositionEnum; skillUuidEntity?: string }>());

export const addSkillOperator = createAction('[lOperator] add skill', props<{ skill: SkillDetailsData }>());
export const addSkillOperatorSuccess = createAction('[lOperator] add skill success');
export const addSkillOperatorFail = createAction('[lOperator] add skill fail', props<{ error: ErrorResponseData }>());

export const loadSkillDetailData = createAction('[sUsersLoadSkill] load Skill data ', props<{ uuidESkill: string }>());
export const loadSkillDetailDataSuccess = createAction('[sUsersLoadSkillDataSuccess] load user data success', props<SkillDetailsData>());
export const loadSkillDetailDataFail = createAction('[sUsersLoadSkillDataFail] load user data data fail', props<DialogToasterData>());

export const updateSkillOperator = createAction('[lOperator] update skill', props<{ skill: SkillDetailsData }>());
export const updateSkillOperatorSuccess = createAction('[lOperator] update skill success');
export const updateSkillOperatorFail = createAction('[lOperator] update skill fail', props<{ error: ErrorResponseData }>());

export const deleteSkill = createAction('[lOperator] delete skill', props<{ uuidESkill: string }>());
export const deleteSkillFail = createAction('[lOperator] delete skill  fails', props<DialogToasterData>());

export const uploadFileSkill = createAction('[lOperator] upload file skill', props<{ skillUuidEntity: string }>());
export const setFileToUpload = createAction('[lOperator] set file to upload', props<{ selectedFiles: File[] }>());
export const uploadFileSkillSuccess = createAction('[lOperator] upload file skill success', props<{ skillUuidEntity: string; pdfFiles: ApplicationFileLightData[] }>());
export const uploadFileSkillFail = createAction('[lOperator] upload file skill fail', props<DialogToasterData>());

export const operatorSkillsNavigation = createAction('[sUsers/API] Navigate through skills operators', props<{ operatorUuidEntity?: string; step: number }>());
export const operatorDetailNavigation = createAction('[sUsers/API] Navigate through details operators', props<{ operatorUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[sUsers/API] confirm navigate details operators', props<{ user: LibraryContributorDetailsData; step: number }>());

export const deletePdfFile = createAction('[lOperator] delete file skill', props<{ skillUuidEntity: string; fileUuidEntity: string }>());
export const deletePdfFileSuccess = createAction('[lOperator] delete file skill success', props<{ skillUuidEntity: string; fileUuidEntity: string }>());

export const setFileToUploadUsers = createAction('[lOperator] set file to upload users', props<{ usersFile: File }>());
export const setFileToUploadUsersSuccess = createAction('[lOperator] set file to upload users success');
export const setFileToUploadUsersFail = createAction('[lOperator] set file to upload users fail', props<DialogToasterData>());
export const stopImportFileLoading = createAction('[lOperator] stop Import File Loading');

export const loadReorganizeOperatorList = createAction('[sUser/API] Load reorganize operators list');
export const loadReorganizeOperatorListSuccess = createAction(
  '[sUser/API] Load reorganize operators list success',
  props<{ operators: LibraryContributorSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadReorganizeNextOperatorListPage = createAction('[sUser/API] Load next reorganize operator list page');
export const loadReorganizeOperatorListFails = createAction('[sUser/API] Load reorganize operator list fails', props<DialogToasterData>());

export const reorganizeOperators = createAction('[sUser/API] reorganize operators', props<{ data: LibraryReorganizeContributorUsersData; count: number }>());
export const reorganizeOperatorsFail = createAction('[sUser/API] reorganize operators fail', props<DialogToasterData>());

export const changeOperatorApplicability = createAction('[lOperator/API] change Operator applicability', props<{ applicabilityUuidEntity: string }>());
export const changeOperatorApplicabilitySuccess = createAction('[lOperator/API] change Operator applicability success');
export const changeOperatorApplicabilityFail = createAction('[lOperator/API] change Operator applicability fail', props<DialogToasterData>());
