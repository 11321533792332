import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { RubricOptionUpdateRequestDTO } from '../../generated/models/rubricOptionUpdateRequestDTO';
import { RubricQuestionAddLineRequestDTO } from '../../generated/models/rubricQuestionAddLineRequestDTO';
import { VersionRubricOptionResponseDTO } from '../../generated/models/versionRubricOptionResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { RubricNatureEnum } from '../rubric/rubric-nature.enum';

export enum QuestionTypeEnum {
  ShortAnswer = 1,
  LongAnswer = 2,
  MultipleAnswers = 3,
  UniqueChoice = 4,
  MultipleChoices = 5,
  ListOfUniqueChoices = 6,
  ListOfMultipleChoices = 7,
  Date = 8,
  DateTime = 9,
  Duration = 10,
  ValueUnit = 11,
  Photo = 12,
  Collaborator = 13,
  Location = 14,
  Formula = 15,
  File = 16,
  TextEditorAnswer = 17,
}

export namespace QuestionTypeEnum {
  export const values = (rubricType: RubricNatureEnum): QuestionTypeEnum[] =>
    compact([
      QuestionTypeEnum.ShortAnswer,
      QuestionTypeEnum.LongAnswer,
      rubricType === RubricNatureEnum.Normal && QuestionTypeEnum.MultipleAnswers,
      QuestionTypeEnum.UniqueChoice,
      QuestionTypeEnum.MultipleChoices,
      QuestionTypeEnum.ListOfUniqueChoices,
      QuestionTypeEnum.ListOfMultipleChoices,
      QuestionTypeEnum.Date,
      QuestionTypeEnum.DateTime,
      QuestionTypeEnum.Duration,
      QuestionTypeEnum.ValueUnit,
      QuestionTypeEnum.Photo,
      rubricType === RubricNatureEnum.Normal && QuestionTypeEnum.File,
      QuestionTypeEnum.Collaborator,
      QuestionTypeEnum.Location,
      QuestionTypeEnum.Formula,
      rubricType === RubricNatureEnum.Normal && QuestionTypeEnum.TextEditorAnswer,
    ]);
  export const descriptionBlock = [QuestionTypeEnum.ShortAnswer, QuestionTypeEnum.LongAnswer, QuestionTypeEnum.ValueUnit];
  export const fileQuestions = [QuestionTypeEnum.Photo, QuestionTypeEnum.File];
  export const choicesBlock = [
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Collaborator,
  ];
  export const listChoicesBlock = [QuestionTypeEnum.ListOfUniqueChoices, QuestionTypeEnum.ListOfMultipleChoices];
  export const questionTypesHasOptionTitle = [QuestionTypeEnum.MultipleAnswers, QuestionTypeEnum.UniqueChoice, QuestionTypeEnum.MultipleChoices];
  export const displayRequiredOption = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.ListOfMultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Photo,
    QuestionTypeEnum.Collaborator,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
    QuestionTypeEnum.File,
  ];

  export const validationDate = [QuestionTypeEnum.Date, QuestionTypeEnum.DateTime];
  export const validationValueUnitOrFormulas = [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula];
  export const hideExportXlsPdf = [QuestionTypeEnum.TextEditorAnswer, QuestionTypeEnum.File];

  /* Option blocks business cases */
  export const displayPlaceholderBlock = [QuestionTypeEnum.ShortAnswer, QuestionTypeEnum.LongAnswer, QuestionTypeEnum.MultipleAnswers];
  export const displayNameBlock = (isNormalRubric?: boolean) =>
    compact([isNormalRubric && QuestionTypeEnum.Collaborator, QuestionTypeEnum.UniqueChoice, QuestionTypeEnum.MultipleChoices, QuestionTypeEnum.MultipleAnswers]);
  export const displayEvaluationEnumBlock = [QuestionTypeEnum.ListOfMultipleChoices, QuestionTypeEnum.ListOfUniqueChoices];
  export const displayFormulaBlock = [QuestionTypeEnum.Formula];
  export const disableSignatureBlock = [QuestionTypeEnum.Collaborator];
  export const displayValueUnitTextBlock = [QuestionTypeEnum.ValueUnit];
  export const displayDescriptionBlock = [
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
  ];

  export const lockableQuestionsWithMultipleOptions = [QuestionTypeEnum.MultipleAnswers, QuestionTypeEnum.MultipleChoices, QuestionTypeEnum.ValueUnit];

  export const displayPreFillBlock = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Photo,
    QuestionTypeEnum.TextEditorAnswer,
  ];

  export const preFillingTextQuestions = [QuestionTypeEnum.ShortAnswer, QuestionTypeEnum.LongAnswer, QuestionTypeEnum.MultipleAnswers];
  export const preFillingNotTextQuestions = [QuestionTypeEnum.Date, QuestionTypeEnum.DateTime, QuestionTypeEnum.Location, QuestionTypeEnum.ValueUnit];

  export const displayRedirectionBlock = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.ListOfMultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
  ];

  export const acceptedFormulaValues = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
  ];

  export const displayValidationBlock = [QuestionTypeEnum.Date, QuestionTypeEnum.DateTime, QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula];

  export const displayDisableOptionOnRubricTableColumn = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.ListOfMultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Photo,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
    QuestionTypeEnum.File,
    QuestionTypeEnum.TextEditorAnswer,
  ];
  export const displayDisableOption = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.TextEditorAnswer,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
  ];
  export const displayUploadImagesBlock = [QuestionTypeEnum.MultipleAnswers, QuestionTypeEnum.UniqueChoice, QuestionTypeEnum.MultipleChoices];
  export const displayAlertBlock = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.ListOfMultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
    QuestionTypeEnum.Location,
    QuestionTypeEnum.Formula,
  ];

  export const displayExportableValueBlock = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.LongAnswer,
    QuestionTypeEnum.MultipleAnswers,
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
  ];

  export const displayAntiBackdatingOption = [QuestionTypeEnum.Date, QuestionTypeEnum.DateTime];

  export const displayDivider = [QuestionTypeEnum.Collaborator];
  export const displaySubtitle = [QuestionTypeEnum.File, QuestionTypeEnum.TextEditorAnswer];

  export const displayChartsBlock = [
    QuestionTypeEnum.UniqueChoice,
    QuestionTypeEnum.MultipleChoices,
    QuestionTypeEnum.ListOfUniqueChoices,
    QuestionTypeEnum.ListOfMultipleChoices,
    QuestionTypeEnum.Date,
    QuestionTypeEnum.DateTime,
    QuestionTypeEnum.Duration,
    QuestionTypeEnum.ValueUnit,
  ];

  export const displayGenericParams = (isTableRubric: boolean) =>
    compact([
      isTableRubric && QuestionTypeEnum.ShortAnswer,
      isTableRubric && QuestionTypeEnum.LongAnswer,
      !isTableRubric && QuestionTypeEnum.MultipleAnswers,
      QuestionTypeEnum.UniqueChoice,
      QuestionTypeEnum.MultipleChoices,
      isTableRubric && QuestionTypeEnum.ListOfUniqueChoices,
      isTableRubric && QuestionTypeEnum.ListOfMultipleChoices,
      isTableRubric && QuestionTypeEnum.Date,
      isTableRubric && QuestionTypeEnum.DateTime,
      isTableRubric && QuestionTypeEnum.Duration,
      QuestionTypeEnum.ValueUnit,
      isTableRubric && QuestionTypeEnum.Photo,
      QuestionTypeEnum.Collaborator,
      isTableRubric && QuestionTypeEnum.Location,
      isTableRubric && QuestionTypeEnum.Formula,
    ]);

  export const displayPreFillGenericBlock = (isTableRubric?: boolean) =>
    compact([
      QuestionTypeEnum.ShortAnswer,
      QuestionTypeEnum.LongAnswer,
      QuestionTypeEnum.MultipleAnswers,
      isTableRubric && QuestionTypeEnum.MultipleChoices,
      QuestionTypeEnum.Date,
      QuestionTypeEnum.DateTime,
      QuestionTypeEnum.Duration,
      QuestionTypeEnum.ValueUnit,
      QuestionTypeEnum.Location,
    ]);

  export const displayNameGenericBlock = [QuestionTypeEnum.UniqueChoice, QuestionTypeEnum.MultipleChoices];

  export const convertFromApiValue: SfxMap<RubricQuestionAddLineRequestDTO.TypeEnum, QuestionTypeEnum> = new SfxMap<RubricQuestionAddLineRequestDTO.TypeEnum, QuestionTypeEnum>([
    [RubricQuestionAddLineRequestDTO.TypeEnum.Text, QuestionTypeEnum.ShortAnswer],
    [RubricQuestionAddLineRequestDTO.TypeEnum.TextLong, QuestionTypeEnum.LongAnswer],
    [RubricQuestionAddLineRequestDTO.TypeEnum.TextMultiple, QuestionTypeEnum.MultipleAnswers],
    [RubricQuestionAddLineRequestDTO.TypeEnum.RadioSelect, QuestionTypeEnum.UniqueChoice],
    [RubricQuestionAddLineRequestDTO.TypeEnum.CheckBox, QuestionTypeEnum.MultipleChoices],
    [RubricQuestionAddLineRequestDTO.TypeEnum.EnumSelection, QuestionTypeEnum.ListOfUniqueChoices],
    [RubricQuestionAddLineRequestDTO.TypeEnum.EnumMultipleSelections, QuestionTypeEnum.ListOfMultipleChoices],
    [RubricQuestionAddLineRequestDTO.TypeEnum.Date, QuestionTypeEnum.Date],
    [RubricQuestionAddLineRequestDTO.TypeEnum.DateTime, QuestionTypeEnum.DateTime],
    [RubricQuestionAddLineRequestDTO.TypeEnum.Time, QuestionTypeEnum.Duration],
    [RubricQuestionAddLineRequestDTO.TypeEnum.ValueUnit, QuestionTypeEnum.ValueUnit],
    [RubricQuestionAddLineRequestDTO.TypeEnum.File, QuestionTypeEnum.Photo],
    [RubricQuestionAddLineRequestDTO.TypeEnum.FileDocument, QuestionTypeEnum.File],
    [RubricQuestionAddLineRequestDTO.TypeEnum.Contributor, QuestionTypeEnum.Collaborator],
    [RubricQuestionAddLineRequestDTO.TypeEnum.Localisation, QuestionTypeEnum.Location],
    [RubricQuestionAddLineRequestDTO.TypeEnum.Formulas, QuestionTypeEnum.Formula],
    [RubricQuestionAddLineRequestDTO.TypeEnum.TextEditor, QuestionTypeEnum.TextEditorAnswer],
  ]);

  export const convertFromLockedQuestionType: SfxMap<VersionRubricOptionResponseDTO.QuestionTypeEnum, QuestionTypeEnum> = new SfxMap<
    RubricQuestionAddLineRequestDTO.TypeEnum,
    QuestionTypeEnum
  >([
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.Text, QuestionTypeEnum.ShortAnswer],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.TextLong, QuestionTypeEnum.LongAnswer],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.TextMultiple, QuestionTypeEnum.MultipleAnswers],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.RadioSelect, QuestionTypeEnum.UniqueChoice],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.CheckBox, QuestionTypeEnum.MultipleChoices],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.EnumSelection, QuestionTypeEnum.ListOfUniqueChoices],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.EnumMultipleSelections, QuestionTypeEnum.ListOfMultipleChoices],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.Date, QuestionTypeEnum.Date],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.DateTime, QuestionTypeEnum.DateTime],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.Time, QuestionTypeEnum.Duration],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.ValueUnit, QuestionTypeEnum.ValueUnit],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.Localisation, QuestionTypeEnum.Location],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.Formulas, QuestionTypeEnum.Formula],
    [VersionRubricOptionResponseDTO.QuestionTypeEnum.TextEditor, QuestionTypeEnum.TextEditorAnswer],
  ]);

  export const convertToApiValue: SfxMap<QuestionTypeEnum, RubricQuestionAddLineRequestDTO.TypeEnum> = new SfxMap<QuestionTypeEnum, RubricQuestionAddLineRequestDTO.TypeEnum>([
    [QuestionTypeEnum.ShortAnswer, RubricQuestionAddLineRequestDTO.TypeEnum.Text],
    [QuestionTypeEnum.LongAnswer, RubricQuestionAddLineRequestDTO.TypeEnum.TextLong],
    [QuestionTypeEnum.MultipleAnswers, RubricQuestionAddLineRequestDTO.TypeEnum.TextMultiple],
    [QuestionTypeEnum.UniqueChoice, RubricQuestionAddLineRequestDTO.TypeEnum.RadioSelect],
    [QuestionTypeEnum.MultipleChoices, RubricQuestionAddLineRequestDTO.TypeEnum.CheckBox],
    [QuestionTypeEnum.ListOfUniqueChoices, RubricQuestionAddLineRequestDTO.TypeEnum.EnumSelection],
    [QuestionTypeEnum.ListOfMultipleChoices, RubricQuestionAddLineRequestDTO.TypeEnum.EnumMultipleSelections],
    [QuestionTypeEnum.Date, RubricQuestionAddLineRequestDTO.TypeEnum.Date],
    [QuestionTypeEnum.DateTime, RubricQuestionAddLineRequestDTO.TypeEnum.DateTime],
    [QuestionTypeEnum.Duration, RubricQuestionAddLineRequestDTO.TypeEnum.Time],
    [QuestionTypeEnum.ValueUnit, RubricQuestionAddLineRequestDTO.TypeEnum.ValueUnit],
    [QuestionTypeEnum.Photo, RubricQuestionAddLineRequestDTO.TypeEnum.File],
    [QuestionTypeEnum.File, RubricQuestionAddLineRequestDTO.TypeEnum.FileDocument],
    [QuestionTypeEnum.Collaborator, RubricQuestionAddLineRequestDTO.TypeEnum.Contributor],
    [QuestionTypeEnum.Location, RubricQuestionAddLineRequestDTO.TypeEnum.Localisation],
    [QuestionTypeEnum.Formula, RubricQuestionAddLineRequestDTO.TypeEnum.Formulas],
    [QuestionTypeEnum.TextEditorAnswer, RubricQuestionAddLineRequestDTO.TypeEnum.TextEditor],
  ]);

  const key = 'questions.types.';
  export const toString = (rubricType: RubricNatureEnum): SfxMap<QuestionTypeEnum, string> =>
    new SfxMap<QuestionTypeEnum, string>([
      [QuestionTypeEnum.ShortAnswer, key + 'shortAnswer'],
      [QuestionTypeEnum.LongAnswer, key + 'longAnswer'],
      [QuestionTypeEnum.MultipleAnswers, key + 'multipleAnswers'],
      [QuestionTypeEnum.UniqueChoice, key + 'uniqueChoice'],
      [QuestionTypeEnum.MultipleChoices, key + (rubricType === RubricNatureEnum.Normal ? 'multipleChoices' : 'checkbox')],
      [QuestionTypeEnum.ListOfUniqueChoices, key + 'listOfUniqueChoices'],
      [QuestionTypeEnum.ListOfMultipleChoices, key + 'listOfMultipleChoices'],
      [QuestionTypeEnum.Date, key + 'date'],
      [QuestionTypeEnum.DateTime, key + 'dateTime'],
      [QuestionTypeEnum.Duration, key + 'duration'],
      [QuestionTypeEnum.ValueUnit, key + 'valueUnit'],
      [QuestionTypeEnum.Photo, key + 'photo'],
      [QuestionTypeEnum.File, key + 'file'],
      [QuestionTypeEnum.Collaborator, key + 'collaborator'],
      [QuestionTypeEnum.Location, key + 'location'],
      [QuestionTypeEnum.Formula, key + 'formula'],
      [QuestionTypeEnum.TextEditorAnswer, key + 'textEditorAnswer'],
    ]);

  export const iconData: SfxMap<QuestionTypeEnum, IconData> = new SfxMap<QuestionTypeEnum, IconData>([
    [QuestionTypeEnum.ShortAnswer, { name: 'minus', prefix: 'fas' }],
    [QuestionTypeEnum.LongAnswer, { name: 'align-left', prefix: 'fas' }],
    [QuestionTypeEnum.MultipleAnswers, { name: 'list', prefix: 'fas' }],
    [QuestionTypeEnum.UniqueChoice, { name: 'dot-circle', prefix: 'fas' }],
    [QuestionTypeEnum.MultipleChoices, { name: 'check-square', prefix: 'fas' }],
    [QuestionTypeEnum.ListOfUniqueChoices, { name: 'chevron-circle-down', prefix: 'fas' }],
    [QuestionTypeEnum.ListOfMultipleChoices, { name: 'caret-square-down', prefix: 'fas' }],
    [QuestionTypeEnum.Date, { name: 'calendar-alt', prefix: 'fas' }],
    [QuestionTypeEnum.DateTime, { name: 'clock', prefix: 'fas' }],
    [QuestionTypeEnum.Duration, { name: 'stopwatch', prefix: 'fas' }],
    [QuestionTypeEnum.ValueUnit, { name: 'ruler-combined', prefix: 'fas' }],
    [QuestionTypeEnum.Photo, { name: 'camera', prefix: 'fas' }],
    [QuestionTypeEnum.File, { name: 'file-pdf', prefix: 'fas' }],
    [QuestionTypeEnum.Collaborator, { name: 'user-circle', prefix: 'fas' }],
    [QuestionTypeEnum.Location, { name: 'map-marker-alt', prefix: 'fas' }],
    [QuestionTypeEnum.Formula, { name: 'calculator', prefix: 'fas' }],
    [QuestionTypeEnum.TextEditorAnswer, { name: 'book-open', prefix: 'fas' }],
  ]);

  export const convertToRubricUpdateType: SfxMap<QuestionTypeEnum, RubricOptionUpdateRequestDTO.TypeEnum> = new SfxMap(
    [[QuestionTypeEnum.Collaborator, RubricOptionUpdateRequestDTO.TypeEnum.COLLABORATOR]],
    RubricOptionUpdateRequestDTO.TypeEnum.NORMAL,
  );

  export const styleClass = (rubricType: RubricNatureEnum): SfxMap<QuestionTypeEnum, string> =>
    new SfxMap<QuestionTypeEnum, string>([
      [QuestionTypeEnum.Collaborator, rubricType === RubricNatureEnum.Normal ? 'sfx-collaborator' : 'sfx-v-tab-collaborator'],
      [QuestionTypeEnum.Location, rubricType === RubricNatureEnum.Normal ? 'sfx-location' : 'sfx-tab-location'],
      [QuestionTypeEnum.Duration, rubricType === RubricNatureEnum.Normal ? 'sfx-rubric-chrono' : 'sfx-tab-chrono'],
      [QuestionTypeEnum.ShortAnswer, 'sfx-tab-short-answer'],
      [QuestionTypeEnum.MultipleAnswers, 'sfx-tab-multiple-answer'],
      [QuestionTypeEnum.UniqueChoice, 'sfx-tab-unique-choice'],
      [QuestionTypeEnum.MultipleChoices, 'sfx-tab-multiple-choice'],
      [QuestionTypeEnum.DateTime, 'sfx-tab-Date-time'],
      [QuestionTypeEnum.Date, 'sfx-tab-Date'],
      [QuestionTypeEnum.LongAnswer, 'sfx-long-answer'],
      [QuestionTypeEnum.ListOfUniqueChoices, 'sfx-list-unique-choice'],
      [QuestionTypeEnum.ListOfMultipleChoices, 'sfx-list-multiple-choice'],
      [QuestionTypeEnum.ValueUnit, 'sfx-value-unit'],
      [QuestionTypeEnum.Photo, 'sfx-tab-photo'],
      [QuestionTypeEnum.File, 'sfx-tab-photo'],
      [QuestionTypeEnum.Formula, 'sfx-tab-formula'],
      [QuestionTypeEnum.TextEditorAnswer, 'sfx-tab-text-editor-answer'],
    ]);

  export const additionalCssClass = (rubricType: RubricNatureEnum): SfxMap<QuestionTypeEnum, string> =>
    new SfxMap<QuestionTypeEnum, string>([[QuestionTypeEnum.Collaborator, rubricType === RubricNatureEnum.Normal ? 'wrapper-content' : '']]);

  export const itemsResolver = (rubricType: RubricNatureEnum): Observable<SingleSelectItemData<QuestionTypeEnum>[]> =>
    of(
      values(rubricType).map(question => ({
        value: question,
        title: toString(rubricType).getValue(question),
        icons: [iconData.getValue(question)],
      })),
    );

  export const rowHeight = new SfxMap<QuestionTypeEnum, number>(
    [
      [QuestionTypeEnum.Collaborator, 98],
      [QuestionTypeEnum.ValueUnit, 63],
    ],
    40,
  );

  export const comparePredicate = (node: SingleSelectItemData<QuestionTypeEnum>, item: QuestionTypeEnum) => node.value === item;
}
