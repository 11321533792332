import { SfxMap } from '../../utils/enum-utils';

export enum LibraryExternalUsersColumnEnum {
  Name = 'name',
  Email = 'email',
  CompanyName = 'companyName',
  Profession = 'profession',
  Status = 'status',
  Actions = 'actions',
}

export namespace LibraryExternalUsersColumnEnum {
  export const allColumns = [
    LibraryExternalUsersColumnEnum.Email,
    LibraryExternalUsersColumnEnum.Name,
    LibraryExternalUsersColumnEnum.CompanyName,
    LibraryExternalUsersColumnEnum.Profession,
    LibraryExternalUsersColumnEnum.Status,
    LibraryExternalUsersColumnEnum.Actions,
  ];

  const key = 'externalUsers.table.header.';
  export const toString = new SfxMap<LibraryExternalUsersColumnEnum, string>([
    [LibraryExternalUsersColumnEnum.Email, key + 'email'],
    [LibraryExternalUsersColumnEnum.Name, key + 'name'],
    [LibraryExternalUsersColumnEnum.CompanyName, key + 'companyName'],
    [LibraryExternalUsersColumnEnum.Profession, key + 'profession'],
    [LibraryExternalUsersColumnEnum.Status, key + 'status'],
    [LibraryExternalUsersColumnEnum.Actions, key + 'actions'],
  ]);
  export const sortField = new SfxMap<LibraryExternalUsersColumnEnum, string>([
    [LibraryExternalUsersColumnEnum.Email, 'email'],
    [LibraryExternalUsersColumnEnum.Name, 'name'],
    [LibraryExternalUsersColumnEnum.CompanyName, 'companyName'],
    [LibraryExternalUsersColumnEnum.Profession, 'profession'],
  ]);
}
