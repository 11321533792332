import { BlocFormResponseCheckRequestDTO, BlocFormResponseLightDTO, PageInProgressResponseRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface BlockFormResponseLightData {
  uuidEntity: string;
  reference: string;
  name: string;
  dateResponse: Date;
}

export namespace BlockFormResponseLightData {
  export interface QueryRequest extends BaseQuery<BlockFormResponseLightData> {
    projectUuidEntity: string;
    formUuidEntity: string;
    flowUuidEntity: string;
  }

  export function mapFromApiValue(response: BlocFormResponseLightDTO): BlockFormResponseLightData {
    return {
      uuidEntity: response.uuidEntity,
      reference: response.reference,
      name: response && (response.reference ? response.reference.concat(StringUtilsEnum.DashSeparator) : '').concat(DateUtils.toDateTimeFormat(response.responseDate)),
      dateResponse: response.responseDate,
    };
  }

  export function mapToSelectItemData(response: BlocFormResponseLightDTO): SingleSelectItemData<BlockFormResponseLightData> {
    return {
      value: mapFromApiValue(response),
      title: response && (response.reference ? response.reference.concat(StringUtilsEnum.DashSeparator) : '').concat(DateUtils.toDateTimeFormat(response.responseDate)),
    };
  }

  /**
   * @param page causes high complexity issue it's already set by initializeQueries<BlockFormResponseLightData>,
   * Having this parameter make writing clean queries initialization from state difficult.
   */
  export function initializeQueryRequest(page: number, searchText: string, projectUuidEntity: string, formUuidEntity: string, flowUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockFormResponseLightData>({ key: 'dateResponse', direction: InnerSortDirectionEnum.Desc }),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
      formUuidEntity,
      flowUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageInProgressResponseRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      formBusinessLinkUuidEntity: queries.formUuidEntity,
      flowUuidEntity: queries.flowUuidEntity,
    };
  }

  export function mapToCheckRequestApiValue(projectUuidEntity: string, formBusinessLinkUuidEntity: string, flowUuidEntity: string): BlocFormResponseCheckRequestDTO {
    return {
      projectUuidEntity,
      formBusinessLinkUuidEntity,
      flowUuidEntity,
    };
  }
}
