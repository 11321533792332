import { CategoryInRfiResponseDTO } from '../../generated/models/models';

export interface VerificationSectionData {
  uuidEntity: string;
  categoryName: string;
  totalChildCategories: number;
  usedChildCategories: number;
  verified: boolean;
}

export namespace VerificationSectionData {
  export function mapToSummaryData(category: CategoryInRfiResponseDTO): VerificationSectionData {
    return {
      uuidEntity: category.uuidEntity,
      categoryName: category.categoryName,
      totalChildCategories: category.totalChildCategories,
      usedChildCategories: category.usedChildCategories,
      verified: category.totalChildCategories === category.usedChildCategories,
    };
  }
}
