import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum PgacEpsilonAfEnum {
  Planned = 1,
  Fortuitous = 2,
}

export namespace PgacEpsilonAfEnum {
  const values = [PgacEpsilonAfEnum.Planned, PgacEpsilonAfEnum.Fortuitous];

  const key = 'flows.modals.PgacDetails.generalTabContent.';

  export const convertFromApiValue: SfxMap<string, PgacEpsilonAfEnum> = new SfxMap<string, PgacEpsilonAfEnum>([
    ['Prévue', PgacEpsilonAfEnum.Planned],
    ['Fortuit', PgacEpsilonAfEnum.Fortuitous],
  ]);

  export const convertToApiValue: SfxMap<PgacEpsilonAfEnum, string> = new SfxMap<PgacEpsilonAfEnum, string>([
    [PgacEpsilonAfEnum.Planned, 'Prévue'],
    [PgacEpsilonAfEnum.Fortuitous, 'Fortuit'],
  ]);

  export const toString: SfxMap<PgacEpsilonAfEnum, string> = new SfxMap<PgacEpsilonAfEnum, string>([
    [PgacEpsilonAfEnum.Planned, key + 'planned'],
    [PgacEpsilonAfEnum.Fortuitous, key + 'fortuitous'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<PgacEpsilonAfEnum>[]> =>
    of(
      values.map(item => ({
        value: item,
        title: toString.getValue(item),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<PgacEpsilonAfEnum>, item: PgacEpsilonAfEnum) => node.value === item;
}
