import { FormRecapRequestDTO } from '../../generated/models/models';

import { ResponseStateEnum } from '../../enums/response/response-state.enum';
import { DateFilterData } from '../utils/date-filter-data';

export interface FormRecapRequestData {
  formUuidEntity: string;
  responseState: ResponseStateEnum;
  statusUuids: string[];
  dateFilter?: DateFilterData;
  formBusinessLinkUuidEntity?: string;
}

export namespace FormRecapRequestData {
  export function mapToRequestApiValue(queries: FormRecapRequestData): FormRecapRequestDTO {
    return {
      formUuidEntity: queries.formUuidEntity || undefined,
      formBusinessLinkUuidEntity: queries.formBusinessLinkUuidEntity,
      states: queries.responseState ? [ResponseStateEnum.convertToApiValue.getValue(queries.responseState)] : undefined,
      statusUuides: queries.statusUuids,
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
    };
  }

  export function initializeQueryRequest(
    formUuidEntity: string,
    formBusinessLinkUuidEntity: string,
    responseState: string,
    statusUuids: string[],
    dateFilter: DateFilterData,
  ): FormRecapRequestData {
    return {
      formUuidEntity: formUuidEntity || undefined,
      dateFilter: dateFilter || undefined,
      formBusinessLinkUuidEntity: formBusinessLinkUuidEntity || undefined,
      responseState: ResponseStateEnum.convertFromParamsValue.getValue(responseState),
      statusUuids,
    };
  }
}
