import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { FormLibraryDeleteResponseDTO } from '../../generated/models/models';
import { ProjectSummaryData } from '../projects/project-summary';

export interface FormLibraryDeleteResponseData {
  success: boolean;
  projectLinks: ProjectSummaryData[];
}

export namespace FormLibraryDeleteResponseData {
  export function mapFromApiValue(data: FormLibraryDeleteResponseDTO): FormLibraryDeleteResponseData {
    return {
      success: data.success,
      projectLinks: data.projectLinks && data.projectLinks.map(project => ProjectSummaryData.mapFromApiValue(project)),
    };
  }

  export function mapToConflictData(formLibraryDeleteResponse: FormLibraryDeleteResponseData, formName: string): ConflictData {
    if (formLibraryDeleteResponse.projectLinks && formLibraryDeleteResponse.projectLinks.length) {
      return {
        title: 'global.entityConflict.hint.form.project',
        links: formLibraryDeleteResponse.projectLinks.map(project => project.designation),
        modalTitle: 'global.entityConflict.hint.form.modalTitle',
        modalFooter: 'global.entityConflict.hint.form.modalFooter',
        tooltipParams: { formName },
      };
    }
  }
}
