import { FormBusinessLinkRubricPermissionAndNameDTO } from '../../generated/models/formBusinessLinkRubricPermissionAndNameDTO';

export interface BlockFormRubricsRestrictionData {
  uuidEntity: string;
  readonly: boolean;
  name: string;
}

export namespace BlockFormRubricsRestrictionData {
  export function mapFromApiValue(blockFormRubric: FormBusinessLinkRubricPermissionAndNameDTO): BlockFormRubricsRestrictionData {
    return {
      uuidEntity: blockFormRubric?.rubricUuid,
      readonly: blockFormRubric?.readonly,
      name: blockFormRubric?.name,
    };
  }

  export function mapToApiValue(blockFormRubric: BlockFormRubricsRestrictionData): { rubricUuid: string; readonly: boolean } {
    return {
      rubricUuid: blockFormRubric?.uuidEntity,
      readonly: blockFormRubric?.readonly,
    };
  }
}
