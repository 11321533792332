import { props, createAction } from '@ngrx/store';

import { LazyLoadResponse, BlockDsiHistoryData, SignatureDetailsData } from '../../../../../../../../core/models';
import { DialogToasterData } from '../../../../../../../sfx-dialog/state/dialog-toaster-data';

export const loadSignatureDetails = createAction('[block dsi/dialog] load signature details', props<{ projectUuidEntity: string; signatureUuidEntity: string }>());
export const loadSignatureDetailsSuccess = createAction('[block dsi/dialog] load signature details success', props<{ signatureDetails: SignatureDetailsData }>());
export const loadSignatureDetailsFail = createAction('[block dsi/dialog] load signature details fail', props<DialogToasterData>());

export const createSignature = createAction('[block dsi/dialog] create signature', props<{ signatureDetails: SignatureDetailsData }>());

export const updateSignature = createAction('[block dsi/dialog] update signature', props<{ signatureDetails: SignatureDetailsData }>());
export const updateSignatureFail = createAction('[block dsi/dialog] update signature fail', props<DialogToasterData>());
export const showDisabledUpdateToaster = createAction('[block dsi/dialog] show disabled update toaster');

// History dialog actions
export const loadDsiHistory = createAction('[block dsi/dialog] load dsi history');
export const loadMoreDsiHistory = createAction('[block dsi/dialog] load more dsi history');
export const loadDsiHistoryFail = createAction('[block dsi/dialog] load dsi history fail', props<DialogToasterData>());
export const loadDsiHistorySuccess = createAction('[block dsi/dialog] load dsi history success', props<{ dsiEvents: LazyLoadResponse<BlockDsiHistoryData[]>; reset?: boolean }>());
