/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { UserDTO } from './userDTO';

export interface UserUpdateRequestDTO {
  additionalAuthorities?: Array<UserUpdateRequestDTO.AdditionalAuthoritiesEnum>;
  authority: UserDTO.AuthoritiesEnum;
  companyUuidEntity: string;
  complement?: string;
  contratTypeUuidEntity?: string;
  firstName: string;
  functionUuidEntity: string;
  hierarchiesUuidEntity?: Array<string>;
  lastName: string;
  legalEntityUuidEntity?: string;
  matricule?: string;
  ssoIdentifier?: string;
  teamUuidEntity: string;
  trigram: string;
  uuidEntity: string;
}
export namespace UserUpdateRequestDTO {
  export type AdditionalAuthoritiesEnum =
    | 'ROLE_ADMIN'
    | 'ROLE_USER'
    | 'ROLE_ADMIN_APP'
    | 'ROLE_USER_ADVANCED'
    | 'ROLE_USER_OPERATOR'
    | 'ROLE_USER_BASIC'
    | 'ROLE_ANONYMOUS'
    | 'ROLE_USER_MOBILE'
    | 'ROLE_USER_EXTERNAL_API_ACCESS'
    | 'ROLE_ADMIN_APP_GLOBAL'
    | 'ROLE_QHSE'
    | 'ROLE_USER_MODEL_FORM'
    | 'ROLE_EXTERNAL_CONTRIBUTOR'
    | 'ROLE_MANAGE_DIFFUSION';
  export const AdditionalAuthoritiesEnum = {
    ADMIN: 'ROLE_ADMIN' as AdditionalAuthoritiesEnum,
    USER: 'ROLE_USER' as AdditionalAuthoritiesEnum,
    ADMINAPP: 'ROLE_ADMIN_APP' as AdditionalAuthoritiesEnum,
    USERADVANCED: 'ROLE_USER_ADVANCED' as AdditionalAuthoritiesEnum,
    USEROPERATOR: 'ROLE_USER_OPERATOR' as AdditionalAuthoritiesEnum,
    USERBASIC: 'ROLE_USER_BASIC' as AdditionalAuthoritiesEnum,
    ANONYMOUS: 'ROLE_ANONYMOUS' as AdditionalAuthoritiesEnum,
    USERMOBILE: 'ROLE_USER_MOBILE' as AdditionalAuthoritiesEnum,
    USEREXTERNALAPIACCESS: 'ROLE_USER_EXTERNAL_API_ACCESS' as AdditionalAuthoritiesEnum,
    ADMINAPPGLOBAL: 'ROLE_ADMIN_APP_GLOBAL' as AdditionalAuthoritiesEnum,
    QHSE: 'ROLE_QHSE' as AdditionalAuthoritiesEnum,
    USERMODELFORM: 'ROLE_USER_MODEL_FORM' as AdditionalAuthoritiesEnum,
    EXTERNALCONTRIBUTOR: 'ROLE_EXTERNAL_CONTRIBUTOR' as AdditionalAuthoritiesEnum,
    MANAGEDIFFUSION: 'ROLE_MANAGE_DIFFUSION' as AdditionalAuthoritiesEnum,
  };
}
