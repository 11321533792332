import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FileProcessingTabEnum {
  History = 'fileProcessingHistory',
  Import = 'import',
  Export = 'export',
}

export namespace FileProcessingTabEnum {
  const key = 'projects.fileProcessing.modals.project.table.tabs.';
  export const toString: SfxMap<FileProcessingTabEnum, string> = new SfxMap<FileProcessingTabEnum, string>([
    [FileProcessingTabEnum.History, key + 'historique'],
    [FileProcessingTabEnum.Import, key + 'import'],
    [FileProcessingTabEnum.Export, key + 'export'],
  ]);

  export const iconData: SfxMap<FileProcessingTabEnum, IconData> = new SfxMap<FileProcessingTabEnum, IconData>([
    [FileProcessingTabEnum.History, { name: 'history', prefix: 'fas' }],
    [FileProcessingTabEnum.Import, { name: 'upload', prefix: 'fas' }],
    [FileProcessingTabEnum.Export, { name: 'download', prefix: 'fas' }],
  ]);

  const errorKey = 'fileProcessing.modals.project.table.subtitle.';
  export const processingError = new SfxMap<string, string>([
    ['fileProcessing.incorrectSeparatorType', errorKey + 'incorrectSeparatorType'],
    ['fileProcessing.missingRequiredCells', errorKey + 'missingRequiredCells'],
  ]);
}
