/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApplicationEventLightDTO {
  uuidEntity: string;
  businessClassRef: string;
  businessClassParentRef: string;
  actionDate: Date;
  actionNature: ApplicationEventLightDTO.ActionNatureEnum;
  userLibelle: string;
  userColor: string;
  entityName: string;
  entityColor: string;
  businessClassName: ApplicationEventLightDTO.BusinessClassNameEnum;
  eventSource: ApplicationEventLightDTO.EventSourceEnum;
  comment: string;
  businessParentName: string;
}
export namespace ApplicationEventLightDTO {
  export type ActionNatureEnum =
    | 'CREATE'
    | 'UPDATE'
    | 'DELETE'
    | 'ACCESS'
    | 'VIEW'
    | 'IMPORT'
    | 'IMPORT_IN_PROGRESS'
    | 'EXPORT'
    | 'EXPORT_IN_PROGRESS'
    | 'IMPORT_ERROR'
    | 'EXPORT_ERROR'
    | 'SQL_DUMP_IMPORT'
    | 'USER_SESSION'
    | 'LINK'
    | 'DUPLICATE_IN_PROGRESS'
    | 'DUPLICATE_ERROR'
    | 'DUPLICATE_SUCCESS'
    | 'BLOC_REDACTION'
    | 'BLOC_VERIFICATION'
    | 'BLOC_APROBATION'
    | 'BLOC_REJECTION'
    | 'BLOC_VALID'
    | 'ENABLE'
    | 'DISABLE'
    | 'EXPORT_DUMP_V2'
    | 'DEACTIVATE_SIGNATURE';
  export const ActionNatureEnum = {
    CREATE: 'CREATE' as ActionNatureEnum,
    UPDATE: 'UPDATE' as ActionNatureEnum,
    DELETE: 'DELETE' as ActionNatureEnum,
    ACCESS: 'ACCESS' as ActionNatureEnum,
    VIEW: 'VIEW' as ActionNatureEnum,
    IMPORT: 'IMPORT' as ActionNatureEnum,
    IMPORTINPROGRESS: 'IMPORT_IN_PROGRESS' as ActionNatureEnum,
    EXPORT: 'EXPORT' as ActionNatureEnum,
    EXPORTINPROGRESS: 'EXPORT_IN_PROGRESS' as ActionNatureEnum,
    IMPORTERROR: 'IMPORT_ERROR' as ActionNatureEnum,
    EXPORTERROR: 'EXPORT_ERROR' as ActionNatureEnum,
    SQLDUMPIMPORT: 'SQL_DUMP_IMPORT' as ActionNatureEnum,
    USERSESSION: 'USER_SESSION' as ActionNatureEnum,
    LINK: 'LINK' as ActionNatureEnum,
    DUPLICATEINPROGRESS: 'DUPLICATE_IN_PROGRESS' as ActionNatureEnum,
    DUPLICATEERROR: 'DUPLICATE_ERROR' as ActionNatureEnum,
    DUPLICATESUCCESS: 'DUPLICATE_SUCCESS' as ActionNatureEnum,
    BLOCREDACTION: 'BLOC_REDACTION' as ActionNatureEnum,
    BLOCVERIFICATION: 'BLOC_VERIFICATION' as ActionNatureEnum,
    BLOCAPROBATION: 'BLOC_APROBATION' as ActionNatureEnum,
    BLOCREJECTION: 'BLOC_REJECTION' as ActionNatureEnum,
    BLOCVALID: 'BLOC_VALID' as ActionNatureEnum,
    ENABLE: 'ENABLE' as ActionNatureEnum,
    DISABLE: 'DISABLE' as ActionNatureEnum,
    EXPORTDUMPV2: 'EXPORT_DUMP_V2' as ActionNatureEnum,
    DEACTIVATESIGNATURE: 'DEACTIVATE_SIGNATURE' as ActionNatureEnum,
  };
  export type BusinessClassNameEnum =
    | 'PROJECT'
    | 'DOCUMENT_FLOW'
    | 'FLOW'
    | 'PHASE'
    | 'STEP'
    | 'BLOC'
    | 'PGAC'
    | 'CUSTOM_FIELD'
    | 'ELEMENT_RHP'
    | 'ELEMENT_ADR'
    | 'ELEMENT_EMM'
    | 'ELEMENT_DSI'
    | 'SIGNATORY'
    | 'TEAM_ROLE'
    | 'PARADE'
    | 'RUBRIC'
    | 'FORM'
    | 'QUESTION'
    | 'RUBRIC_OPTION'
    | 'OPTION_SIGNATORY'
    | 'APPLICATION_FILE'
    | 'FBL'
    | 'BLOC_DOCUMENT_FLOW'
    | 'APPLICATION'
    | 'USER'
    | 'RFI_TEMPLATE'
    | 'SKILL'
    | 'LABEL_FAMILY_SKILLS'
    | 'NAVIGATION_FLOW'
    | 'CUSTOM_STATUS'
    | 'GEOMETRY'
    | 'CUSTOM_FILE'
    | 'CUSTOM_FIELD_PROJECT'
    | 'CUSTOM_FIELD_FLOW'
    | 'TECHNICAL_OBJECT'
    | 'REVISION'
    | 'BLOC_PR'
    | 'BLOC_TXT'
    | 'ELEMENT_PR'
    | 'TEMPLATE_PARAMS'
    | 'CLIENT'
    | 'BLOC_MULTI_PR'
    | 'FORM_RESPONSE'
    | 'CUSTOM_FIELD_TECHNICAL_OBJECT'
    | 'FLOW_REFERENTIAL'
    | 'SCHEDULING_ITEM';
  export const BusinessClassNameEnum = {
    PROJECT: 'PROJECT' as BusinessClassNameEnum,
    DOCUMENTFLOW: 'DOCUMENT_FLOW' as BusinessClassNameEnum,
    FLOW: 'FLOW' as BusinessClassNameEnum,
    PHASE: 'PHASE' as BusinessClassNameEnum,
    STEP: 'STEP' as BusinessClassNameEnum,
    BLOC: 'BLOC' as BusinessClassNameEnum,
    PGAC: 'PGAC' as BusinessClassNameEnum,
    CUSTOMFIELD: 'CUSTOM_FIELD' as BusinessClassNameEnum,
    ELEMENTRHP: 'ELEMENT_RHP' as BusinessClassNameEnum,
    ELEMENTADR: 'ELEMENT_ADR' as BusinessClassNameEnum,
    ELEMENTEMM: 'ELEMENT_EMM' as BusinessClassNameEnum,
    ELEMENTDSI: 'ELEMENT_DSI' as BusinessClassNameEnum,
    SIGNATORY: 'SIGNATORY' as BusinessClassNameEnum,
    TEAMROLE: 'TEAM_ROLE' as BusinessClassNameEnum,
    PARADE: 'PARADE' as BusinessClassNameEnum,
    RUBRIC: 'RUBRIC' as BusinessClassNameEnum,
    FORM: 'FORM' as BusinessClassNameEnum,
    QUESTION: 'QUESTION' as BusinessClassNameEnum,
    RUBRICOPTION: 'RUBRIC_OPTION' as BusinessClassNameEnum,
    OPTIONSIGNATORY: 'OPTION_SIGNATORY' as BusinessClassNameEnum,
    APPLICATIONFILE: 'APPLICATION_FILE' as BusinessClassNameEnum,
    FBL: 'FBL' as BusinessClassNameEnum,
    BLOCDOCUMENTFLOW: 'BLOC_DOCUMENT_FLOW' as BusinessClassNameEnum,
    APPLICATION: 'APPLICATION' as BusinessClassNameEnum,
    USER: 'USER' as BusinessClassNameEnum,
    RFITEMPLATE: 'RFI_TEMPLATE' as BusinessClassNameEnum,
    SKILL: 'SKILL' as BusinessClassNameEnum,
    LABELFAMILYSKILLS: 'LABEL_FAMILY_SKILLS' as BusinessClassNameEnum,
    NAVIGATIONFLOW: 'NAVIGATION_FLOW' as BusinessClassNameEnum,
    CUSTOMSTATUS: 'CUSTOM_STATUS' as BusinessClassNameEnum,
    GEOMETRY: 'GEOMETRY' as BusinessClassNameEnum,
    CUSTOMFILE: 'CUSTOM_FILE' as BusinessClassNameEnum,
    CUSTOMFIELDPROJECT: 'CUSTOM_FIELD_PROJECT' as BusinessClassNameEnum,
    CUSTOMFIELDFLOW: 'CUSTOM_FIELD_FLOW' as BusinessClassNameEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as BusinessClassNameEnum,
    REVISION: 'REVISION' as BusinessClassNameEnum,
    BLOCPR: 'BLOC_PR' as BusinessClassNameEnum,
    BLOCTXT: 'BLOC_TXT' as BusinessClassNameEnum,
    ELEMENTPR: 'ELEMENT_PR' as BusinessClassNameEnum,
    TEMPLATEPARAMS: 'TEMPLATE_PARAMS' as BusinessClassNameEnum,
    CLIENT: 'CLIENT' as BusinessClassNameEnum,
    BLOCMULTIPR: 'BLOC_MULTI_PR' as BusinessClassNameEnum,
    FORMRESPONSE: 'FORM_RESPONSE' as BusinessClassNameEnum,
    CUSTOMFIELDTECHNICALOBJECT: 'CUSTOM_FIELD_TECHNICAL_OBJECT' as BusinessClassNameEnum,
    FLOWREFERENTIAL: 'FLOW_REFERENTIAL' as BusinessClassNameEnum,
    SCHEDULINGITEM: 'SCHEDULING_ITEM' as BusinessClassNameEnum,
  };
  export type EventSourceEnum = 'WebApp' | 'MobileApp' | 'API_EXT';
  export const EventSourceEnum = {
    WebApp: 'WebApp' as EventSourceEnum,
    MobileApp: 'MobileApp' as EventSourceEnum,
    APIEXT: 'API_EXT' as EventSourceEnum,
  };
}
