import { CategoryDeleteResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum CategoryDeleteNatureEnum {
  Document = 1,
  Flow = 2,
  List = 3,
  Hierarchy = 4,
  Child = 5,
}

export namespace CategoryDeleteNatureEnum {
  export const convertFromApiValue: SfxMap<CategoryDeleteResponseDTO.NatureEnum, CategoryDeleteNatureEnum> = new SfxMap<
    CategoryDeleteResponseDTO.NatureEnum,
    CategoryDeleteNatureEnum
  >([
    [CategoryDeleteResponseDTO.NatureEnum.DOCUMENT, CategoryDeleteNatureEnum.Document],
    [CategoryDeleteResponseDTO.NatureEnum.FLOW, CategoryDeleteNatureEnum.Flow],
    [CategoryDeleteResponseDTO.NatureEnum.LIST, CategoryDeleteNatureEnum.List],
    [CategoryDeleteResponseDTO.NatureEnum.HIERARCHY, CategoryDeleteNatureEnum.Hierarchy],
    [CategoryDeleteResponseDTO.NatureEnum.CHILD, CategoryDeleteNatureEnum.Child],
  ]);
}
