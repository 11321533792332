import { compact } from 'lodash';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';

export enum ElementPrMenuActionEnum {
  InsertAbove = 1,
  InsertBelow,
  Duplicate,
  Enable,
  Disable,
  Delete,
  HideWhenExportingPdf,
  ShowWhenExportingPdf,
}

export namespace ElementPrMenuActionEnum {
  export const values = (disabled: boolean, hideOnExport?: boolean) =>
    compact([
      ElementPrMenuActionEnum.InsertAbove,
      ElementPrMenuActionEnum.InsertBelow,
      ElementPrMenuActionEnum.Duplicate,
      hideOnExport ? ElementPrMenuActionEnum.ShowWhenExportingPdf : ElementPrMenuActionEnum.HideWhenExportingPdf,
      disabled && ElementPrMenuActionEnum.Enable,
      !disabled && ElementPrMenuActionEnum.Disable,
      ElementPrMenuActionEnum.Delete,
    ]);

  export const toString = new SfxMap<ElementPrMenuActionEnum, string>([
    [ElementPrMenuActionEnum.InsertAbove, 'blocks.froala.options.insertAbove'],
    [ElementPrMenuActionEnum.InsertBelow, 'blocks.froala.options.insertBelow'],
    [ElementPrMenuActionEnum.Duplicate, 'blocks.froala.options.duplicate'],
    [ElementPrMenuActionEnum.Delete, 'blocks.froala.options.delete'],
    [ElementPrMenuActionEnum.Enable, 'blocks.froala.options.enable'],
    [ElementPrMenuActionEnum.Disable, 'blocks.froala.options.disable'],
    [ElementPrMenuActionEnum.ShowWhenExportingPdf, 'blocks.froala.options.showWhenExportingPdf'],
    [ElementPrMenuActionEnum.HideWhenExportingPdf, 'blocks.froala.options.hideWhenExportingPdf'],
  ]);

  export const actionPermission = new SfxMap<ElementPrMenuActionEnum, PermissionActionEnum>(
    [
      [ElementPrMenuActionEnum.Duplicate, PermissionActionEnum.Duplicate],
      [ElementPrMenuActionEnum.Delete, PermissionActionEnum.Delete],
      [ElementPrMenuActionEnum.Disable, PermissionActionEnum.Deactivate],
      [ElementPrMenuActionEnum.ShowWhenExportingPdf, PermissionActionEnum.Edit],
      [ElementPrMenuActionEnum.HideWhenExportingPdf, PermissionActionEnum.Edit],
    ],
    null,
  );

  export const iconData: SfxMap<ElementPrMenuActionEnum, IconData> = new SfxMap<ElementPrMenuActionEnum, IconData>([
    [ElementPrMenuActionEnum.InsertAbove, { name: 'caret-square-up', prefix: 'far' }],
    [ElementPrMenuActionEnum.InsertBelow, { name: 'caret-square-down', prefix: 'far' }],
    [ElementPrMenuActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [ElementPrMenuActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [ElementPrMenuActionEnum.Enable, { name: 'eye' }],
    [ElementPrMenuActionEnum.Disable, { name: 'eye-slash' }],
    [ElementPrMenuActionEnum.ShowWhenExportingPdf, { name: 'file-alt' }],
    [ElementPrMenuActionEnum.HideWhenExportingPdf, { name: 'file-alt', prefix: 'far' }],
  ]);

  export const getActions = (disabled: boolean, hideOnExport?: boolean): ActionSelectItemData<ElementPrMenuActionEnum>[] =>
    values(disabled, hideOnExport).map(action => ({
      name: toString.getValue(action),
      value: action,
      icon: iconData.getValue(action),
      permissionAction: actionPermission.getValue(action),
    }));
}
