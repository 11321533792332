import { compact } from 'lodash';
import { Observable, of } from 'rxjs';

import { ProjectCreateRequestDTO, ProjectDetailsResponseDTO } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { SfxMap } from '../../utils/enum-utils';

export enum ProjectTypeEnum {
  Project = 1,
  Referential = 2,
  Template = 3,
  Pgac = 4,
  Folder = 5,
}

export namespace ProjectTypeEnum {
  export const projectsWhereTabletNotUsed = [ProjectTypeEnum.Folder, ProjectTypeEnum.Referential, ProjectTypeEnum.Template];
  export const allProjects = [ProjectTypeEnum.Referential, ProjectTypeEnum.Template, ProjectTypeEnum.Pgac, ProjectTypeEnum.Project];
  export const projectTypesWhereWeCanGenerateFlow: ProjectTypeEnum[] = [ProjectTypeEnum.Project, ProjectTypeEnum.Pgac];
  export const referentialProjectValues: ProjectTypeEnum[] = [ProjectTypeEnum.Project, ProjectTypeEnum.Referential];
  export const projectPgacValues: ProjectTypeEnum[] = [ProjectTypeEnum.Project, ProjectTypeEnum.Pgac];
  export const projectPgacReferentialValues: ProjectTypeEnum[] = [ProjectTypeEnum.Project, ProjectTypeEnum.Pgac, ProjectTypeEnum.Referential];

  export const convertFromApiValue: SfxMap<ProjectDetailsResponseDTO.TypeProjectEnum, ProjectTypeEnum> = new SfxMap<ProjectDetailsResponseDTO.TypeProjectEnum, ProjectTypeEnum>([
    [ProjectDetailsResponseDTO.TypeProjectEnum.PROJECT, ProjectTypeEnum.Project],
    [ProjectDetailsResponseDTO.TypeProjectEnum.REFERENTIAL, ProjectTypeEnum.Referential],
    [ProjectDetailsResponseDTO.TypeProjectEnum.TEMPLATE, ProjectTypeEnum.Template],
    [ProjectDetailsResponseDTO.TypeProjectEnum.PGAC, ProjectTypeEnum.Pgac],
    [ProjectDetailsResponseDTO.TypeProjectEnum.FOLDER, ProjectTypeEnum.Folder],
  ]);

  export const convertToApiValue: SfxMap<ProjectTypeEnum, ProjectDetailsResponseDTO.TypeProjectEnum> = new SfxMap<ProjectTypeEnum, ProjectDetailsResponseDTO.TypeProjectEnum>([
    [ProjectTypeEnum.Project, ProjectDetailsResponseDTO.TypeProjectEnum.PROJECT],
    [ProjectTypeEnum.Referential, ProjectDetailsResponseDTO.TypeProjectEnum.REFERENTIAL],
    [ProjectTypeEnum.Template, ProjectDetailsResponseDTO.TypeProjectEnum.TEMPLATE],
    [ProjectTypeEnum.Pgac, ProjectDetailsResponseDTO.TypeProjectEnum.PGAC],
    [ProjectTypeEnum.Folder, ProjectDetailsResponseDTO.TypeProjectEnum.FOLDER],
  ]);

  export const convertToCreateApiValue: SfxMap<ProjectTypeEnum, ProjectCreateRequestDTO.TypeProjectEnum> = new SfxMap<ProjectTypeEnum, ProjectCreateRequestDTO.TypeProjectEnum>([
    [ProjectTypeEnum.Project, ProjectCreateRequestDTO.TypeProjectEnum.PROJECT],
    [ProjectTypeEnum.Referential, ProjectCreateRequestDTO.TypeProjectEnum.REFERENTIAL],
    [ProjectTypeEnum.Template, ProjectCreateRequestDTO.TypeProjectEnum.TEMPLATE],
    [ProjectTypeEnum.Pgac, ProjectCreateRequestDTO.TypeProjectEnum.PGAC],
  ]);

  export const convertFromParamsValue: SfxMap<string, ProjectTypeEnum> = new SfxMap<string, ProjectTypeEnum>([
    ['project', ProjectTypeEnum.Project],
    ['referential', ProjectTypeEnum.Referential],
    ['model', ProjectTypeEnum.Template],
    ['pgac', ProjectTypeEnum.Pgac],
    ['folder', ProjectTypeEnum.Folder],
  ]);

  export const convertToParamsValue: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, 'project'],
    [ProjectTypeEnum.Referential, 'referential'],
    [ProjectTypeEnum.Folder, 'folder'],
    [ProjectTypeEnum.Template, 'model'],
    [ProjectTypeEnum.Pgac, 'pgac'],
  ]);

  const key = 'projects.modal.types.';
  export const toString: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, key + 'project'],
    [ProjectTypeEnum.Referential, key + 'referential'],
    [ProjectTypeEnum.Template, key + 'model'],
    [ProjectTypeEnum.Pgac, key + 'pgac'],
    [ProjectTypeEnum.Folder, key + 'refFolder'],
  ]);

  const projectKey = 'flows.project.types.';
  export const toFlowProjectValue: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, projectKey + 'project'],
    [ProjectTypeEnum.Referential, projectKey + 'referential'],
    [ProjectTypeEnum.Template, projectKey + 'model'],
    [ProjectTypeEnum.Pgac, projectKey + 'pgac'],
    [ProjectTypeEnum.Folder, projectKey + 'refFolder'],
  ]);

  export const getProjectType = (projectType: ProjectTypeEnum, isSubFolder?: boolean) =>
    isSubFolder && projectType !== ProjectTypeEnum.Folder ? key + 'subFolder' : ProjectTypeEnum.toString.getValue(projectType);

  export const iconClass: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, 'project'],
    [ProjectTypeEnum.Referential, 'project-referential'],
    [ProjectTypeEnum.Template, 'project-model'],
    [ProjectTypeEnum.Folder, 'project-referential'],
    [ProjectTypeEnum.Pgac, 'project-pgac'],
  ]);

  const editTitleKey = 'projects.modal.title.edit.';

  export const editTitle: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, editTitleKey + 'project'],
    [ProjectTypeEnum.Referential, editTitleKey + 'referential'],
    [ProjectTypeEnum.Template, editTitleKey + 'template'],
    [ProjectTypeEnum.Pgac, editTitleKey + 'pgac'],
    [ProjectTypeEnum.Folder, editTitleKey + 'folder'],
  ]);

  const duplicateTitleKey = 'projects.modal.duplicateProject.titles.';

  export const duplicateTitle: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>([
    [ProjectTypeEnum.Project, duplicateTitleKey + 'project'],
    [ProjectTypeEnum.Referential, duplicateTitleKey + 'referential'],
    [ProjectTypeEnum.Template, duplicateTitleKey + 'template'],
    [ProjectTypeEnum.Pgac, duplicateTitleKey + 'pgac'],
    [ProjectTypeEnum.Folder, duplicateTitleKey + 'folder'],
  ]);

  export const textContent: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>(
    [
      [ProjectTypeEnum.Project, 'projects.modal.duplicateProject.info.projectContent'],
      [ProjectTypeEnum.Referential, 'projects.modal.duplicateProject.info.modelContent'],
      [ProjectTypeEnum.Template, 'projects.modal.duplicateProject.info.modelContent'],
    ],
    '',
  );

  const letterKey = 'projects.letter.';

  export const toLetter: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>(
    [
      [ProjectTypeEnum.Project, letterKey + 'project'],
      [ProjectTypeEnum.Referential, letterKey + 'standard'],
      [ProjectTypeEnum.Template, letterKey + 'template'],
      [ProjectTypeEnum.Pgac, letterKey + 'pgac'],
      [ProjectTypeEnum.Folder, letterKey + 'standard'],
    ],
    'Unknown',
  );

  const tooltipKey = 'projects.tooltips.filters.types.';

  export const tooltip: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>(
    [
      [ProjectTypeEnum.Project, tooltipKey + 'project'],
      [ProjectTypeEnum.Referential, tooltipKey + 'referential'],
      [ProjectTypeEnum.Template, tooltipKey + 'model'],
      [ProjectTypeEnum.Pgac, tooltipKey + 'pgac'],
      [ProjectTypeEnum.Folder, tooltipKey + 'refFolder'],
    ],
    'Unknown',
  );

  export const color: SfxMap<ProjectTypeEnum, string> = new SfxMap<ProjectTypeEnum, string>(
    [
      [ProjectTypeEnum.Project, 'sfx-chip-project'],
      [ProjectTypeEnum.Referential, 'sfx-chip-purple-ref'],
      [ProjectTypeEnum.Folder, 'sfx-chip-purple-ref'],
      [ProjectTypeEnum.Template, 'sfx-chip-mint'],
      [ProjectTypeEnum.Pgac, 'sfx-chip-sfx-orange'],
    ],
    'sfx-chip-black',
  );

  export function mapToSelectItemData(projectType: ProjectTypeEnum): MultiSelectItemData<ProjectTypeEnum> {
    return {
      value: projectType,
      color: color.getValue(projectType),
      title: toLetter.getValue(projectType),
      tooltip: tooltip.getValue(projectType),
      icons: [projectType === ProjectTypeEnum.Folder ? { name: 'folder' } : ({} as IconData)],
    };
  }

  export function mapToSingleSelectItemDataForAddModal(projectType: ProjectTypeEnum): SingleSelectItemData<ProjectTypeEnum> {
    return {
      value: projectType,
      color: color.getValue(projectType),
      title: toString.getValue(projectType),
    };
  }

  export const values = (pgacVisible: boolean, enableDuplicateFlow?: boolean, folderExistence?: boolean): ProjectTypeEnum[] =>
    compact([
      ProjectTypeEnum.Project,
      enableDuplicateFlow && ProjectTypeEnum.Referential,
      folderExistence && ProjectTypeEnum.Folder,
      ProjectTypeEnum.Template,
      pgacVisible && enableDuplicateFlow && ProjectTypeEnum.Pgac,
    ]);

  export const selectItems = (isPgac: boolean, folderExistence: boolean) => values(isPgac, true, folderExistence).map(value => mapToSelectItemData(value));
  export const selectListItems = (isPgac: boolean, hideFolder?: boolean) => values(isPgac, true, hideFolder).map(value => mapToSingleSelectItemDataForAddModal(value));

  export const selectCustomListenSingleItems = (isPgac: boolean, enableDuplicateFlow: boolean) => (): Observable<SingleSelectItemData<ProjectTypeEnum>[]> =>
    of(
      values(isPgac, enableDuplicateFlow).map(value => ({
        value,
        color: color.getValue(value),
        title: toString.getValue(value),
      })),
    );

  export const singleSelectListItems = (isPgac: boolean, hideFolder?: boolean) => (): Observable<SingleSelectItemData<ProjectTypeEnum>[]> =>
    of(values(isPgac, null, hideFolder).map(value => ({ value, title: toString.getValue(value) })));

  export const comparePredicate = (node: SingleSelectItemData<ProjectTypeEnum>, item: ProjectTypeEnum) => node.value === item;
}
