import { WordingConfigurationFieldResponseDTO } from '../../generated/models/wordingConfigurationFieldResponseDTO';

import { SfxMap } from '../../utils/enum-utils';

import { DocumentStatusEnum } from '../document/document-status.enum';

export enum WordingConfigActionEnum {
  Other = 1, // @Majdi: to check with @Sajir if we will remove it later...
  Bpa = 2,
  BpaValid = 3,
  Bpe = 4,
  BpeValid = 5,
  Bpo = 6, // @Majdi: to check with @Sajir if we will remove it later...
  ClientReturnBpa = 7,
  ClientReturnBpo = 8,
  DiffusionBpa = 9,
  DiffusionBpo = 10,
  ModificationBpa = 11, // @Majdi: to check with @Sajir if we will remove it later...
  ModificationBpo = 12, // @Majdi: to check with @Sajir if we will remove it later...
  Prel = 13,
  Temp = 14,
  Vao = 15,
  VaoBpa = 16,
  Vso = 17,
  VsoSr = 18,
  VsoBpa = 19,
  BpaRefused = 20,
}

export namespace WordingConfigActionEnum {
  export const values = [
    WordingConfigActionEnum.Other,
    WordingConfigActionEnum.Bpa,
    WordingConfigActionEnum.BpaValid,
    WordingConfigActionEnum.Bpe,
    WordingConfigActionEnum.BpeValid,
    WordingConfigActionEnum.Bpo,
    WordingConfigActionEnum.ClientReturnBpa,
    WordingConfigActionEnum.ClientReturnBpo,
    WordingConfigActionEnum.DiffusionBpa,
    WordingConfigActionEnum.DiffusionBpo,
    WordingConfigActionEnum.ModificationBpa,
    WordingConfigActionEnum.ModificationBpo,
    WordingConfigActionEnum.Prel,
    WordingConfigActionEnum.Temp,
    WordingConfigActionEnum.Vao,
    WordingConfigActionEnum.VaoBpa,
    WordingConfigActionEnum.Vso,
    WordingConfigActionEnum.VsoSr,
    WordingConfigActionEnum.VsoBpa,
    WordingConfigActionEnum.BpaRefused,
  ];

  export const convertFromApiValue = new SfxMap<WordingConfigurationFieldResponseDTO.ActionKeyEnum, WordingConfigActionEnum>([
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.AUTRE, WordingConfigActionEnum.Other],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPA, WordingConfigActionEnum.Bpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPAVALID, WordingConfigActionEnum.BpaValid],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPE, WordingConfigActionEnum.Bpe],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPEVALID, WordingConfigActionEnum.BpeValid],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPO, WordingConfigActionEnum.Bpo],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.CLIENTRETURNBPA, WordingConfigActionEnum.ClientReturnBpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.CLIENTRETURNBPO, WordingConfigActionEnum.ClientReturnBpo],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.DIFFUSIONBPA, WordingConfigActionEnum.DiffusionBpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.DIFFUSIONBPO, WordingConfigActionEnum.DiffusionBpo],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.MODIFICATIONBPA, WordingConfigActionEnum.ModificationBpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.MODIFICATIONBPO, WordingConfigActionEnum.ModificationBpo],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.PREL, WordingConfigActionEnum.Prel],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.TEMP, WordingConfigActionEnum.Temp],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.VAO, WordingConfigActionEnum.Vao],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.VAOBPA, WordingConfigActionEnum.VaoBpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSO, WordingConfigActionEnum.Vso],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSOSR, WordingConfigActionEnum.VsoSr],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSOBPA, WordingConfigActionEnum.VsoBpa],
    [WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPAREFUSED, WordingConfigActionEnum.BpaRefused],
  ]);

  export const convertToApiValue = new SfxMap<WordingConfigActionEnum, WordingConfigurationFieldResponseDTO.ActionKeyEnum>([
    [WordingConfigActionEnum.Other, WordingConfigurationFieldResponseDTO.ActionKeyEnum.AUTRE],
    [WordingConfigActionEnum.Bpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPA],
    [WordingConfigActionEnum.BpaValid, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPAVALID],
    [WordingConfigActionEnum.Bpe, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPE],
    [WordingConfigActionEnum.BpeValid, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPEVALID],
    [WordingConfigActionEnum.Bpo, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPO],
    [WordingConfigActionEnum.ClientReturnBpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.CLIENTRETURNBPA],
    [WordingConfigActionEnum.ClientReturnBpo, WordingConfigurationFieldResponseDTO.ActionKeyEnum.CLIENTRETURNBPO],
    [WordingConfigActionEnum.DiffusionBpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.DIFFUSIONBPA],
    [WordingConfigActionEnum.DiffusionBpo, WordingConfigurationFieldResponseDTO.ActionKeyEnum.DIFFUSIONBPO],
    [WordingConfigActionEnum.ModificationBpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.MODIFICATIONBPA],
    [WordingConfigActionEnum.ModificationBpo, WordingConfigurationFieldResponseDTO.ActionKeyEnum.MODIFICATIONBPO],
    [WordingConfigActionEnum.Prel, WordingConfigurationFieldResponseDTO.ActionKeyEnum.PREL],
    [WordingConfigActionEnum.Temp, WordingConfigurationFieldResponseDTO.ActionKeyEnum.TEMP],
    [WordingConfigActionEnum.Vao, WordingConfigurationFieldResponseDTO.ActionKeyEnum.VAO],
    [WordingConfigActionEnum.VaoBpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.VAOBPA],
    [WordingConfigActionEnum.Vso, WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSO],
    [WordingConfigActionEnum.VsoSr, WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSOSR],
    [WordingConfigActionEnum.VsoBpa, WordingConfigurationFieldResponseDTO.ActionKeyEnum.VSOBPA],
    [WordingConfigActionEnum.BpaRefused, WordingConfigurationFieldResponseDTO.ActionKeyEnum.BPAREFUSED],
  ]);

  const key = 'wordingConfiguration.modals.languageConfig.';
  export const valuePlaceholders: SfxMap<WordingConfigActionEnum, string> = new SfxMap<WordingConfigActionEnum, string>([
    [WordingConfigActionEnum.Bpa, key + 'value.bpa'],
    [WordingConfigActionEnum.BpaValid, key + 'value.bpa'],
    [WordingConfigActionEnum.Bpe, key + 'value.bpe'],
    [WordingConfigActionEnum.BpeValid, key + 'value.bpeValid'],
    [WordingConfigActionEnum.ClientReturnBpa, key + 'value.bpaRc'],
    [WordingConfigActionEnum.ClientReturnBpo, key + 'value.bpoRc'],
    [WordingConfigActionEnum.DiffusionBpa, key + 'value.bpaAd'],
    [WordingConfigActionEnum.DiffusionBpo, key + 'value.bpoAd'],
    [WordingConfigActionEnum.Prel, key + 'value.prel'],
    [WordingConfigActionEnum.Temp, key + 'value.bpeTemp'],
    [WordingConfigActionEnum.Vao, key + 'value.vao'],
    [WordingConfigActionEnum.VaoBpa, key + 'value.bpaKo'],
    [WordingConfigActionEnum.Vso, key + 'value.vso'],
    [WordingConfigActionEnum.VsoSr, key + 'value.vsoSr'],
    [WordingConfigActionEnum.VsoBpa, key + 'value.bpaOk'],
    [WordingConfigActionEnum.BpaRefused, key + 'value.bpaRefused'],
    [WordingConfigActionEnum.ModificationBpa, key + 'value.bpaModification'],
    [WordingConfigActionEnum.ModificationBpo, key + 'value.bpoModification'],
  ]);

  export const tooltipPlaceholders: SfxMap<WordingConfigActionEnum, string> = new SfxMap<WordingConfigActionEnum, string>([
    [WordingConfigActionEnum.Bpa, key + 'placeholders.bpa'],
    [WordingConfigActionEnum.Bpe, key + 'placeholders.bpe'],
    [WordingConfigActionEnum.BpeValid, key + 'placeholders.bpeValid'],
    [WordingConfigActionEnum.ClientReturnBpa, key + 'placeholders.bpaRc'],
    [WordingConfigActionEnum.ClientReturnBpo, key + 'placeholders.bpoRc'],
    [WordingConfigActionEnum.DiffusionBpa, key + 'placeholders.bpaAd'],
    [WordingConfigActionEnum.DiffusionBpo, key + 'placeholders.bpoAd'],
    [WordingConfigActionEnum.Prel, key + 'placeholders.prel'],
    [WordingConfigActionEnum.Temp, key + 'placeholders.bpeTemp'],
    [WordingConfigActionEnum.Vao, key + 'placeholders.vao'],
    [WordingConfigActionEnum.VaoBpa, key + 'placeholders.bpaKo'],
    [WordingConfigActionEnum.Vso, key + 'placeholders.vso'],
    [WordingConfigActionEnum.VsoSr, key + 'placeholders.vsoSr'],
    [WordingConfigActionEnum.VsoBpa, key + 'placeholders.bpaOk'],
    [WordingConfigActionEnum.BpaRefused, key + 'placeholders.bpaRefused'],
    [WordingConfigActionEnum.ModificationBpa, key + 'placeholders.bpaModification'],
    [WordingConfigActionEnum.ModificationBpo, key + 'placeholders.bpoModification'],
  ]);

  export const toDocumentStatusEnum: SfxMap<WordingConfigActionEnum, DocumentStatusEnum> = new SfxMap<WordingConfigActionEnum, DocumentStatusEnum>([
    [WordingConfigActionEnum.Prel, DocumentStatusEnum.Prel],
    [WordingConfigActionEnum.Bpe, DocumentStatusEnum.Bpe],
    [WordingConfigActionEnum.DiffusionBpo, DocumentStatusEnum.Bpo],
    [WordingConfigActionEnum.Temp, DocumentStatusEnum.Temp],
    [WordingConfigActionEnum.Other, DocumentStatusEnum.Other],
    [WordingConfigActionEnum.BpaValid, DocumentStatusEnum.Bpa],
  ]);
}
