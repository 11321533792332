import { createAction, props } from '@ngrx/store';

import { BlockEquipmentDetailsData, BlockEquipmentToImportSummaryData } from '../../../../../../../../core/models';
import { DialogToasterData } from '../../../../../../../../modules/sfx-dialog/state/dialog-toaster-data';

// View dialog actions
export const getBlockEquipmentDetailsFail = createAction('[bEquipmentDialog] get block equipment details fail', props<DialogToasterData>());
export const getBlockEquipmentDetailsSuccess = createAction('[bEquipmentDialog] get block equipment details success', props<{ equipmentDetails: BlockEquipmentDetailsData }>());

// Import dialog actions
export const loadEquipments = createAction('[bEquipmentDialog] load equipments');
export const loadEquipmentsFail = createAction('[bEquipmentDialog] load equipments fail', props<DialogToasterData>());
export const loadEquipmentsSuccess = createAction(
  '[bEquipmentDialog] load equipments success',
  props<{ equipments: BlockEquipmentToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadMoreEquipments = createAction('[bEquipmentDialog] load more equipments');

export const openImportEquipment = createAction('[bEquipmentDialog] open import equipment popover', props<{ equipment: BlockEquipmentToImportSummaryData; origin: HTMLElement }>());
export const setImportEquipmentLoading = createAction('[bEquipmentDialog] set import equipment loading', props<{ importEquipmentLoading: boolean }>());
export const importEquipmentFail = createAction('[bEquipmentDialog] import equipment fail', props<DialogToasterData>());
export const importEquipmentSuccess = createAction('[bEquipmentDialog] import equipment success', props<{ emmUuidEntity: string }>());
