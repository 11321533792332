import { MoveEnum, RubricNatureEnum } from '../../enums';
import { MoveElementOnParentRequestDTO } from '../../generated/models/moveElementOnParentRequestDTO';
import { EntityLightData } from '../utils/entity-light-data';

export interface QuestionToMoveData {
  targetQuestion: EntityLightData;
  targetRubric: EntityLightData;
  currentRubricUuidEntity: string;
  currentQuestionUuidEntity: string;
  move?: MoveEnum;
  rubricNature?: RubricNatureEnum;
}

export namespace QuestionToMoveData {
  export function mapToRequestApiValue(moveRequest: QuestionToMoveData): MoveElementOnParentRequestDTO {
    return {
      parentUuidEntity: moveRequest.targetRubric.uuidEntity,
      uuidEntity: moveRequest.currentQuestionUuidEntity,
      siblingUuidEntity: moveRequest.targetQuestion && moveRequest.targetQuestion.uuidEntity,
      relativePosition: MoveEnum.convertToApiValue.getValue(moveRequest.move || (moveRequest.targetQuestion ? MoveEnum.After : MoveEnum.Start)),
    };
  }
}
