import { FormProjectSummaryResponseDTO, PageFormProjectRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';
import { InnerSortDirectionEnum, FormNatureEnum } from '../../enums';

export interface FormProjectSummaryData {
  name: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  modificationDate: string;
  nature: FormNatureEnum;
  indice: string;
  reference: string;
  labelFamily: LabelFamilyLightData;
  customStatus: CustomStatusLightData;
  useTemplate: boolean;
  description: string;
  localFormAllowed?: boolean;
  additionalComment?: string;
  updated?: boolean;
  archivedParent?: boolean;
}

export namespace FormProjectSummaryData {
  const sortFields = ['family', 'name', 'reference', 'index', 'modificationDate'];
  export interface QueryRequest extends BaseQuery<FormProjectSummaryData> {
    type: FormNatureEnum;
    families: string[];
    flows: string[];
  }

  export function mapFromApiValue(formProjectSummary: FormProjectSummaryResponseDTO): FormProjectSummaryData {
    return {
      uuidEntity: formProjectSummary.uuidEntity,
      uuidType: formProjectSummary.uuidType,
      uuid: formProjectSummary.uuid,
      name: formProjectSummary.name,
      description: formProjectSummary.description,
      modificationDate: DateUtils.toDateFormat(formProjectSummary.modificationDate),
      indice: formProjectSummary.indice,
      reference: formProjectSummary.reference,
      useTemplate: formProjectSummary.useTemplate,
      nature: FormNatureEnum.convertFromApiValue.getValue(formProjectSummary.nature),
      labelFamily: formProjectSummary.labelFamily && LabelFamilyLightData.mapFromApiValue(formProjectSummary.labelFamily),
      customStatus: formProjectSummary.customStatus && CustomStatusLightData.mapFromApiValue(formProjectSummary.customStatus),
      localFormAllowed: formProjectSummary.localFormAllowed,
      additionalComment: formProjectSummary.additionalComment,
      updated: formProjectSummary.updated,
      archivedParent: formProjectSummary.archivedParent,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageFormProjectRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      flowsUuidEntity: (queries.flows || []).length ? queries.flows : undefined,
      familiesUuidEntity: (queries.families || []).length ? queries.families : undefined,
      type: FormNatureEnum.convertToApiValue.getValue(queries.type) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(type: string, families: string[], flows: string[], searchText: string, sort: SortFilter<FormProjectSummaryData>): QueryRequest {
    return {
      ...initializeQueries<FormProjectSummaryData>(sort),
      textSearch: searchText || undefined,
      type: FormNatureEnum.convertFromParamsValue.getValue(type) || undefined,
      families: (families || []).length ? families : undefined,
      flows: (flows || []).length ? flows : undefined,
    };
  }
}
