import { DateUtils } from '../../utils/date.utils';
import { SfxMap } from '../../utils/enum-utils';
import { VersionRubricOptionResponseDTO } from '../../generated/models/versionRubricOptionResponseDTO';
import { ApplicationFileVersionOptionResponseDTO } from '../../generated/models/models';
import { QuestionTypeEnum, ResponseProvenanceEnum } from '../../enums';
export interface VersionRubricOptionResponseData {
  name: string;
  modificationDateTime: string;
  provenance: ResponseProvenanceEnum;
  newValue: string;
  oldValue: string;
  optionName: string;
  signatureComment: string;
  questionType: QuestionTypeEnum;
  applicationFiles: { name: string; deleted: boolean }[];
}

export namespace VersionRubricOptionResponseData {
  const mapApplicationFileAction = new SfxMap<ApplicationFileVersionOptionResponseDTO.ActionEnum, boolean>([
    [ApplicationFileVersionOptionResponseDTO.ActionEnum.ADD, false],
    [ApplicationFileVersionOptionResponseDTO.ActionEnum.DELETE, true],
  ]);

  export function mapFromApi(historyItem: VersionRubricOptionResponseDTO): VersionRubricOptionResponseData {
    const questionType = QuestionTypeEnum.convertFromLockedQuestionType.getValue(historyItem?.questionType);

    return {
      name: `${historyItem?.firstName} ${historyItem?.lastName} `,
      modificationDateTime: DateUtils.toSensitiveTimeFormat(historyItem?.modificationDate),
      provenance: ResponseProvenanceEnum.convertFromApiValue.getValue(historyItem?.provenance),
      newValue: questionType === QuestionTypeEnum.DateTime ? DateUtils.toDateTimeFormat(historyItem.newValue) : historyItem.newValue,
      oldValue: questionType === QuestionTypeEnum.DateTime ? DateUtils.toDateTimeFormat(historyItem.oldValue) : historyItem.oldValue,
      optionName: historyItem?.optionName,
      questionType,
      signatureComment: historyItem.signatureComment,
      applicationFiles: (historyItem.applicationFiles || []).map(applicationFile => ({ ...applicationFile, deleted: mapApplicationFileAction.getValue(applicationFile?.action) })),
    };
  }
}
