import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { UpdateFlowReferentialResponseDTO } from '../../generated/models/models';

export interface FlowUpdateReferentialResponseData {
  flowLinkedNames: string[];
  success: boolean;
}

export namespace FlowUpdateReferentialResponseData {
  export function mapFromApiValue(data: UpdateFlowReferentialResponseDTO): FlowUpdateReferentialResponseData {
    return {
      success: data.success,
      flowLinkedNames: data.linkedFlows?.map(flow => flow.name) || [],
    };
  }

  export function mapToConflict(response: FlowUpdateReferentialResponseData): ConflictData {
    return {
      title: 'flows.alerts.refreshRefFlow.subtitle',
      links: response.flowLinkedNames,
    };
  }
}
