import { FlowTypeEnum } from '../../enums';
import { FlowsRequestProgressionDTO } from '../../generated/models/models';
import { DateFilterData } from '../utils/date-filter-data';

export interface MetricsFlowsQueryParams {
  projectUuidEntity: string;
  familyUuidEntities?: string[];
  flowUuidEntities?: string[];
  categoryUuidEntities?: string[];
  types?: FlowTypeEnum[];
  dateFilter?: DateFilterData;
}

export namespace MetricsFlowsQueryParams {
  export function mapToRequestApiValue(queries: MetricsFlowsQueryParams): FlowsRequestProgressionDTO {
    return {
      familyUuidEntities: (queries.familyUuidEntities || []).length ? queries.familyUuidEntities : undefined,
      flowUuidEntities: (queries.flowUuidEntities || []).length ? queries.flowUuidEntities : undefined,
      categoryUuidEntities: (queries.categoryUuidEntities || []).length ? queries.categoryUuidEntities : undefined,
      types: (queries.types || []).length ? (queries.types || []).map(type => FlowTypeEnum.convertToApiValue.getValue(type)) : undefined,
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
    };
  }

  export function initializeQueryRequest(
    projectUuidEntity: string,
    families: string[],
    categories: string[],
    types: string[],
    flows: string[],
    dateFilter: DateFilterData,
  ): MetricsFlowsQueryParams {
    return {
      projectUuidEntity,
      familyUuidEntities: families,
      flowUuidEntities: flows,
      categoryUuidEntities: categories,
      types: (types || []).map(type => FlowTypeEnum.convertFromParamsValue.getValue(type)),
      dateFilter: dateFilter || undefined,
    };
  }
}
