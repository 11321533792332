/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompanyLightDTO } from './companyLightDTO';
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface FormProjectSummaryResponseDTO {
  additionalComment: string;
  archivedParent?: boolean;
  customStatus: CustomStatusLightDTO;
  description: string;
  indice: string;
  labelFamily: LabelFamilyLightDTO;
  localFormAllowed?: boolean;
  lockForm: boolean;
  modificationDate: Date;
  name: string;
  nature: FormProjectSummaryResponseDTO.NatureEnum;
  reference: string;
  updated: boolean;
  useTemplate: boolean;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}
export namespace FormProjectSummaryResponseDTO {
  export type NatureEnum = 'Model' | 'Generic' | 'Autre' | 'LOCAL';
  export const NatureEnum = {
    Model: 'Model' as NatureEnum,
    Generic: 'Generic' as NatureEnum,
    Autre: 'Autre' as NatureEnum,
    LOCAL: 'LOCAL' as NatureEnum,
  };
}
