import { ControlValueAccessor } from '@angular/forms';

export abstract class SfxControlValueAccessor<T> implements ControlValueAccessor {
  protected state: T;

  set value(value: T) {
    if (this.state !== value) {
      this.state = value;
      this.onChangeValue();
      this.onChange(this.state);
      this.onTouch(this.state);
    }
  }

  get value(): T {
    return this.onGetValue() || this.state !== undefined ? this.state : null;
  }

  writeValue(value: T): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  protected onChange: Function = () => {};
  protected onTouch: Function = () => {};

  protected abstract onChangeValue(): void;
  protected abstract onGetValue(): T;
}
