/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ResponseErrorUploadEmmDTO {
  error?: ResponseErrorUploadEmmDTO.ErrorEnum;
  name?: string;
  reference?: string;
  familyName?: string;
}
export namespace ResponseErrorUploadEmmDTO {
  export type ErrorEnum = 'DUPLICATED' | 'NOT_CONFORM' | 'INVALID_FAMILY' | 'INVALID_DATE' | 'INVALID_NAME' | 'BREAKS_LINE';
  export const ErrorEnum = {
    DUPLICATED: 'DUPLICATED' as ErrorEnum,
    NOTCONFORM: 'NOT_CONFORM' as ErrorEnum,
    INVALIDFAMILY: 'INVALID_FAMILY' as ErrorEnum,
    INVALIDDATE: 'INVALID_DATE' as ErrorEnum,
    INVALIDNAME: 'INVALID_NAME' as ErrorEnum,
    BREAKSLINE: 'BREAKS_LINE' as ErrorEnum,
  };
}
