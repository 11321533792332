import { DocumentStatusEnum } from '../../enums';
import { DocumentsProgressionResponseDTO } from '../../generated/models/models';

export interface DocumentMetricsDetails {
  totalCount: number;
  documents: Array<{ status: DocumentStatusEnum; count: number }>;
}

export namespace DocumentMetricsDetails {
  export function mapFromApiValue(document: DocumentsProgressionResponseDTO): DocumentMetricsDetails {
    return {
      totalCount: Object.values(document).reduce((u, v) => u + v, 0),
      documents: [
        { status: DocumentStatusEnum.Prel, count: document.prelDocumentCount ?? 0 },
        { status: DocumentStatusEnum.Bpo, count: document.bpoDocumentCount ?? 0 },
        { status: DocumentStatusEnum.Bpe, count: document.bpeDocumentCount ?? 0 },
        { status: DocumentStatusEnum.Bpa, count: document.bpaDocumentCount ?? 0 },
        { status: DocumentStatusEnum.Other, count: document.documentWithoutStateCount ?? 0 },
      ],
    };
  }
}
