import { ToasterTypeEnum } from '../../enums';
import { RubricOptionDetailsResponseDTO } from '../../generated/models/models';

export interface OptionAlertData {
  nature: ToasterTypeEnum;
  alertMessage: string;
}

export namespace OptionAlertData {
  export function mapFromApiValue(option: RubricOptionDetailsResponseDTO): OptionAlertData {
    return { alertMessage: option.alertText, nature: (option.natureAlert && ToasterTypeEnum.mapFromAlertNature.getValue(option.natureAlert)) || ToasterTypeEnum.Warning };
  }
}
