import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ObjectModalTabEnum {
  General = 'general',
  CustomField = 'customField',
  Content = 'content',
  History = 'history',
}

export namespace ObjectModalTabEnum {
  export function getValues(): string[] {
    return [ObjectModalTabEnum.General, ObjectModalTabEnum.CustomField];
  }

  const key = 'technicalObjects.modals.createOrEdit.';
  export const toString: SfxMap<ObjectModalTabEnum, string> = new SfxMap<ObjectModalTabEnum, string>([
    [ObjectModalTabEnum.General, key + 'general'],
    [ObjectModalTabEnum.CustomField, key + 'customField'],
    [ObjectModalTabEnum.Content, key + 'content'],
    [ObjectModalTabEnum.History, key + 'history'],
  ]);

  export const iconData: SfxMap<ObjectModalTabEnum, IconData> = new SfxMap<ObjectModalTabEnum, IconData>([
    [ObjectModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [ObjectModalTabEnum.CustomField, { name: 'paint-roller', prefix: 'fas' }],
    [ObjectModalTabEnum.Content, { name: 'box', prefix: 'fas' }],
    [ObjectModalTabEnum.History, { name: 'history', prefix: 'fas' }],
  ]);
}
