import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { PhaseDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum StepSequenceTypeEnum {
  Linear = 1,
  Parallel = 2,
}

export namespace StepSequenceTypeEnum {
  export const values = [StepSequenceTypeEnum.Linear, StepSequenceTypeEnum.Parallel];
  export const convertFromApiValue: SfxMap<PhaseDetailsResponseDTO.StepSequenceTypeEnum, StepSequenceTypeEnum> = new SfxMap<
    PhaseDetailsResponseDTO.StepSequenceTypeEnum,
    StepSequenceTypeEnum
  >([
    [PhaseDetailsResponseDTO.StepSequenceTypeEnum.LINEAR, StepSequenceTypeEnum.Linear],
    [PhaseDetailsResponseDTO.StepSequenceTypeEnum.PARALLEL, StepSequenceTypeEnum.Parallel],
  ]);

  export const convertToApiValue: SfxMap<StepSequenceTypeEnum, PhaseDetailsResponseDTO.StepSequenceTypeEnum> = new SfxMap<
    StepSequenceTypeEnum,
    PhaseDetailsResponseDTO.StepSequenceTypeEnum
  >([
    [StepSequenceTypeEnum.Linear, PhaseDetailsResponseDTO.StepSequenceTypeEnum.LINEAR],
    [StepSequenceTypeEnum.Parallel, PhaseDetailsResponseDTO.StepSequenceTypeEnum.PARALLEL],
  ]);

  const key = 'phases.sequenceType.';
  export const toString: SfxMap<StepSequenceTypeEnum, string> = new SfxMap<StepSequenceTypeEnum, string>([
    [StepSequenceTypeEnum.Linear, key + 'linear'],
    [StepSequenceTypeEnum.Parallel, key + 'parallel'],
  ]);

  export const iconData: SfxMap<StepSequenceTypeEnum, IconData> = new SfxMap([
    [StepSequenceTypeEnum.Linear, { name: 'long-arrow-alt-right' }],
    [StepSequenceTypeEnum.Parallel, { name: 'random' }],
  ]);

  export const selectItems: SingleSelectItemData<StepSequenceTypeEnum>[] = values.map(sequenceType => ({
    value: sequenceType,
    title: toString.getValue(sequenceType),
    icons: [iconData.getValue(sequenceType)],
  }));
}
