import { SfxMap } from '../../utils/enum-utils';

export enum OperatorErrorEnum {
  userConflict = 1,
  UnavailableMatricule = 2,
  LongFirstName = 3,
  LongLastName = 4,
  LongEmail = 5,
  ssoIdentifierConflict = 6,
}

export namespace OperatorErrorEnum {
  export const convertFromApiValues: SfxMap<string, OperatorErrorEnum> = new SfxMap<string, OperatorErrorEnum>([
    ['error.userexists', OperatorErrorEnum.userConflict],
    ['error.user.unavailableUserMatricule', OperatorErrorEnum.UnavailableMatricule],
    ['error.firstNameTooLong', OperatorErrorEnum.LongFirstName],
    ['error.lastNameTooLong', OperatorErrorEnum.LongLastName],
    ['error.emailTooLong', OperatorErrorEnum.LongEmail],
    ['error.ssoIdentifierConflict', OperatorErrorEnum.ssoIdentifierConflict],
  ]);

  const errorKey = 'users.modals.new.';

  export const errorTitle = (editMode?: boolean) =>
    new SfxMap<OperatorErrorEnum, string>(
      [
        [OperatorErrorEnum.userConflict, errorKey + 'addContributorUserExistFail'],
        [OperatorErrorEnum.UnavailableMatricule, errorKey + (editMode ? 'editContributorRegisterNumberExistFail' : 'addContributorRegisterNumberExistFail')],
        [OperatorErrorEnum.LongFirstName, errorKey + 'addContributorUserExistFail'],
        [OperatorErrorEnum.LongLastName, errorKey + 'addContributorUserFail'],
        [OperatorErrorEnum.LongEmail, errorKey + 'addContributorUserFail'],
        [OperatorErrorEnum.ssoIdentifierConflict, errorKey + 'ssoIdentifierConflict'],
      ],
      editMode ? 'users.modals.edit.updateFail' : errorKey + 'addContributorFail',
    );

  export const errorSubtitle = new SfxMap<OperatorErrorEnum, string>([
    [OperatorErrorEnum.userConflict, errorKey + 'addContributorUserExistFailSubtitle'],
    [OperatorErrorEnum.UnavailableMatricule, errorKey + 'addContributorRegisterNumberExistFailSubtitle'],
    [OperatorErrorEnum.LongFirstName, errorKey + 'addContributorLongFirstNameFailSubtitle'],
    [OperatorErrorEnum.LongLastName, errorKey + 'addContributorLongLastNameFailSubtitle'],
    [OperatorErrorEnum.LongEmail, errorKey + 'addContributorLongEmailFailSubtitle'],
  ]);
}
