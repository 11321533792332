import { compact } from 'lodash';
import { SfxMap } from '../../utils/enum-utils';

export enum FlowMenuItemTypeEnum {
  Phase = 1,
  Step = 2,
  Flow = 3,
}

export namespace FlowMenuItemTypeEnum {
  export const addableItems = (revisionMode: boolean) => compact([FlowMenuItemTypeEnum.Step, !revisionMode && FlowMenuItemTypeEnum.Phase]);
  export const childItems = [FlowMenuItemTypeEnum.Flow, FlowMenuItemTypeEnum.Step];

  export const toString: SfxMap<FlowMenuItemTypeEnum, string> = new SfxMap([
    [FlowMenuItemTypeEnum.Phase, 'flowContentNavigation.phase'],
    [FlowMenuItemTypeEnum.Step, 'flowContentNavigation.step'],
  ]);

  export const tooltip: SfxMap<FlowMenuItemTypeEnum, string> = new SfxMap([
    [FlowMenuItemTypeEnum.Phase, 'flowContentNavigation.tooltip.addPhase'],
    [FlowMenuItemTypeEnum.Step, 'flowContentNavigation.tooltip.addStep'],
  ]);

  export const entityInfoText: SfxMap<FlowMenuItemTypeEnum, string> = new SfxMap([
    [FlowMenuItemTypeEnum.Phase, 'flowContentNavigation.dialog.entityInfo.phaseProgress'],
    [FlowMenuItemTypeEnum.Step, 'flowContentNavigation.dialog.entityInfo.stepProgress'],
  ]);
}
