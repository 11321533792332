import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ExportableValueEnum {
  EpsilonAn = 1,
  EpsilonAp = 2,
  EpsilonAq = 3,
  EpsilonAs = 4,
  EpsilonBf = 5,
  EpsilonAt = 6,
  EpsilonAu = 7,
  EpsilonAv = 8,
  EpsilonAw = 9,
  EpsilonAx = 10,
  EpsilonAy = 11,
  EpsilonAz = 12,
  EpsilonBa = 13,

  CaloDeposeUniquement = 14,
  CaloReposeUniquement = 15,
  CaloDeposeRepose = 16,
  CaloDle = 17,
  CaloEhCsp = 18,
  CaloEh = 19,
  CaloDemontable = 20,
  CaloClassique = 21,
  CaloCiment = 22,
  CaloHzc = 23,
  CaloZc = 24,
  CaloInox = 25,
  CaloGalva = 26,
  CaloAlu = 27,
  CaloCoquille = 28,
  CaloNappe = 29,
  CaloTelisol = 30,
  CaloArmaflex = 31,
  CaloStyrodur = 32,
  CaloReleveDiametre = 33,
  CaloReleveEpaisseurCouche1 = 34,
  CaloReleveEpaisseurCouche2 = 35,
  CaloRelevePartieDroite = 36,
  CaloVannesVissees = 37,
  CaloVannesClipsees = 38,
  CaloVannesEncapsulees = 39,
  CaloBridesVissees = 40,
  CaloBridesClipsees = 41,
  CaloBridesEncapsulees = 42,
  CaloCoudeVisses = 43,
  CaloCoudeClipses = 44,
  CaloCoudeEncapsulees = 45,
  CaloPiquagesVisses = 46,
  CaloPiquagesClipses = 47,
  CaloPiquagesEncapsulees = 48,
  CaloReductionsVissees = 49,
  CaloReductionsClipsees = 50,
  CaloReductionsEncapsulees = 51,
  CaloSupportsVisses = 52,
  CaloSupportsClipses = 53,
  CaloSupportsEncapsules = 54,
  CaloArretVisses = 55,
  CaloArretClipses = 56,
  CaloArretEncapsules = 57,
  CaloReleveAppareilDiametreAppareil = 58,
  CaloReleveAppareilEpaisseurCouche1 = 59,
  CaloReleveAppareilEpaisseurCouche2 = 60,
  CaloReleveAppareilSurAppareil = 61,
  CaloReleveAppareilNombreFondBallon = 62,
  CaloReleveAppareilVirole = 63,
  CaloDateSignatureForm = 64,
  CaloDddPrevisionnel = 65,
  CaloDddReel = 66,
  CaloDoseCollectivePrevisionnel = 67,
  CaloDoseCollectiveReel = 68,
  CaloDoseIndividuelPrevisionnel = 69,
  CaloDoseIndividuelReelle = 70,

  EchafZc = 71,
  EchafHzc = 72,
  EchafDle = 73,
  EchafAccesDifficile = 74,
  EchafSuspendu = 75,
  EchafPlancherAjuste = 76,
  EchafSuspenduAvecPlancherAjuste = 77,
  EchafInterventionEnCapacite = 78,
  EchafPlancherCondenseur = 79,
  EchafPlancherAlternateur = 80,
  EchafSansObjet = 81,
  EchafLongueur = 82,
  EchafLargeur = 83,
  EchafHauteur = 84,
  EchafPlancherDeTravail = 85,
  EchafPlancherAcces = 86,
  EchafDddPrevisionnel = 87,
  EchafDddPReel = 88,
  EchafDoseCollectivePrevisionnel = 89,
  EchafDoseCollectiveReel = 90,
  EchafDoseIndividuelPrevisionnel = 91,
  EchafDoseIndividuelReelle = 92,
  EchafDateReception = 93,
  EchafBacheGta = 94,
  EchafCordiste = 95,
  EchafInterventionFondPiscine = 96,
  EchafSapineLongeur1 = 97,
  EchafSapineLongeur2 = 98,
  EchafSapineLongeur3 = 99,
  EchafSapineLongeur4 = 100,
  EchafSapineLargeur1 = 101,
  EchafSapineLargeur2 = 102,
  EchafSapineLargeur3 = 103,
  EchafSapineLargeur4 = 104,
  EchafSapineHauteur1 = 105,
  EchafSapineHauteur2 = 106,
  EchafSapineHauteur3 = 107,
  EchafSapineHauteur4 = 108,
  EchafSapineNombre1 = 109,
  EchafSapineNombre2 = 110,
  EchafSapineNombre3 = 111,
  EchafSapineNombre4 = 112,
  EchafSapineVolume1 = 113,
  EchafSapineVolume2 = 114,
  EchafSapineVolume3 = 115,
  EchafSapineVolume4 = 116,
  Deport1Longueur = 117,
  Deport2Longueur = 118,
  Deport3Longueur = 119,
  Deport4Longueur = 120,
  Deport1Largeur = 121,
  Deport2Largeur = 122,
  Deport3Largeur = 123,
  Deport4Largeur = 124,
  Deport1Nombre = 125,
  Deport2Nombre = 126,
  Deport3Nombre = 127,
  Deport4Nombre = 128,
  Deport1M3 = 129,
  Deport2M3 = 130,
  Deport3M3 = 131,
  Deport4M3 = 132,
  DeportsM3Total = 133,
  EchafSansDeportM3Total = 134,
  EchafAvecDeportM3Total = 136,
  MarchePied = 137,

  MdsDle = 138,
  MdsAccesDifficile = 139,
  MdsCapacite = 140,
  MdsSansObjet = 141,
  MdsZc = 142,
  MdsLongueur = 143,
  MdsLargeur = 144,
  MdsHauteur = 145,
  MdsPir = 146,
  MdsEchelle = 147,
  MdsNacelle = 148,
  MdsEdf = 149,

  OuvrageZc = 150,
  OuvrageHzc = 151,
  OuvrageDle = 152,
  OuvrageAccesDifficile = 153,
  OuvrageCapacite = 154,
  OuvrageSansObjet = 155,
  OuvrageLongueur = 156,
  OuvrageLargeur = 157,
  OuvrageHauteur = 158,
  OuvrageDateReception = 159,
  OuvragePassageCables = 160,
  OuvrageEtabli = 161,
  OuvrageSupportProbio = 162,
  OuvrageGardeCorps = 163,
  OuvrageStructureSAS = 164,
  OuvragePotence = 165,
  OuvrageRambardeCaillebotisCharpenteTole = 166,

  RambardeDddPrevisionnel = 167,
  RambardeDddReel = 168,
  RambardeDoseCollectivePrevisionnel = 169,
  RambardeDoseCollectiveReel = 170,
  RambardeDoseIndividuelPrevisionnel = 171,
  RambardeDoseIndividuelReelle = 172,
  PrestationRealisee = 173,
  RepliRealisee = 174,

  Calo = 175,
  Echaf = 176,
  Ouvrage = 177,
  Rambarde = 178,
  Mds = 179,
}

export namespace ExportableValueEnum {
  const values: ExportableValueEnum[] = [
    ExportableValueEnum.EpsilonAn,
    ExportableValueEnum.EpsilonAp,
    ExportableValueEnum.EpsilonAq,
    ExportableValueEnum.EpsilonAs,
    ExportableValueEnum.EpsilonBf,
    ExportableValueEnum.EpsilonAt,
    ExportableValueEnum.EpsilonAu,
    ExportableValueEnum.EpsilonAv,
    ExportableValueEnum.EpsilonAw,
    ExportableValueEnum.EpsilonAx,
    ExportableValueEnum.EpsilonAy,
    ExportableValueEnum.EpsilonAz,
    ExportableValueEnum.EpsilonBa,
    ExportableValueEnum.Calo,
    ExportableValueEnum.CaloDeposeUniquement,
    ExportableValueEnum.CaloReposeUniquement,
    ExportableValueEnum.CaloDeposeRepose,
    ExportableValueEnum.CaloDle,
    ExportableValueEnum.CaloEhCsp,
    ExportableValueEnum.CaloEh,
    ExportableValueEnum.CaloDemontable,
    ExportableValueEnum.CaloClassique,
    ExportableValueEnum.CaloCiment,
    ExportableValueEnum.CaloHzc,
    ExportableValueEnum.CaloZc,
    ExportableValueEnum.CaloInox,
    ExportableValueEnum.CaloGalva,
    ExportableValueEnum.CaloAlu,
    ExportableValueEnum.CaloCoquille,
    ExportableValueEnum.CaloNappe,
    ExportableValueEnum.CaloTelisol,
    ExportableValueEnum.CaloArmaflex,
    ExportableValueEnum.CaloStyrodur,
    ExportableValueEnum.CaloReleveDiametre,
    ExportableValueEnum.CaloReleveEpaisseurCouche1,
    ExportableValueEnum.CaloReleveEpaisseurCouche2,
    ExportableValueEnum.CaloRelevePartieDroite,
    ExportableValueEnum.CaloVannesVissees,
    ExportableValueEnum.CaloVannesClipsees,
    ExportableValueEnum.CaloVannesEncapsulees,
    ExportableValueEnum.CaloBridesVissees,
    ExportableValueEnum.CaloBridesClipsees,
    ExportableValueEnum.CaloBridesEncapsulees,
    ExportableValueEnum.CaloCoudeVisses,
    ExportableValueEnum.CaloCoudeClipses,
    ExportableValueEnum.CaloCoudeEncapsulees,
    ExportableValueEnum.CaloPiquagesVisses,
    ExportableValueEnum.CaloPiquagesClipses,
    ExportableValueEnum.CaloPiquagesEncapsulees,
    ExportableValueEnum.CaloReductionsVissees,
    ExportableValueEnum.CaloReductionsClipsees,
    ExportableValueEnum.CaloReductionsEncapsulees,
    ExportableValueEnum.CaloSupportsVisses,
    ExportableValueEnum.CaloSupportsClipses,
    ExportableValueEnum.CaloSupportsEncapsules,
    ExportableValueEnum.CaloArretVisses,
    ExportableValueEnum.CaloArretClipses,
    ExportableValueEnum.CaloArretEncapsules,
    ExportableValueEnum.CaloReleveAppareilDiametreAppareil,
    ExportableValueEnum.CaloReleveAppareilEpaisseurCouche1,
    ExportableValueEnum.CaloReleveAppareilEpaisseurCouche2,
    ExportableValueEnum.CaloReleveAppareilSurAppareil,
    ExportableValueEnum.CaloReleveAppareilNombreFondBallon,
    ExportableValueEnum.CaloReleveAppareilVirole,
    ExportableValueEnum.CaloDateSignatureForm,
    ExportableValueEnum.CaloDddPrevisionnel,
    ExportableValueEnum.CaloDddReel,
    ExportableValueEnum.CaloDoseCollectivePrevisionnel,
    ExportableValueEnum.CaloDoseCollectiveReel,
    ExportableValueEnum.CaloDoseIndividuelPrevisionnel,
    ExportableValueEnum.CaloDoseIndividuelReelle,
    ExportableValueEnum.Echaf,
    ExportableValueEnum.EchafZc,
    ExportableValueEnum.EchafHzc,
    ExportableValueEnum.EchafDle,
    ExportableValueEnum.EchafAccesDifficile,
    ExportableValueEnum.EchafSuspendu,
    ExportableValueEnum.EchafPlancherAjuste,
    ExportableValueEnum.EchafSuspenduAvecPlancherAjuste,
    ExportableValueEnum.EchafInterventionEnCapacite,
    ExportableValueEnum.EchafPlancherCondenseur,
    ExportableValueEnum.EchafPlancherAlternateur,
    ExportableValueEnum.EchafSansObjet,
    ExportableValueEnum.EchafLongueur,
    ExportableValueEnum.EchafLargeur,
    ExportableValueEnum.EchafHauteur,
    ExportableValueEnum.EchafPlancherDeTravail,
    ExportableValueEnum.EchafPlancherAcces,
    ExportableValueEnum.EchafDddPrevisionnel,
    ExportableValueEnum.EchafDddPReel,
    ExportableValueEnum.EchafDoseCollectivePrevisionnel,
    ExportableValueEnum.EchafDoseCollectiveReel,
    ExportableValueEnum.EchafDoseIndividuelPrevisionnel,
    ExportableValueEnum.EchafDoseIndividuelReelle,
    ExportableValueEnum.EchafDateReception,
    ExportableValueEnum.EchafBacheGta,
    ExportableValueEnum.EchafCordiste,
    ExportableValueEnum.EchafInterventionFondPiscine,
    ExportableValueEnum.EchafSapineLongeur1,
    ExportableValueEnum.EchafSapineLongeur2,
    ExportableValueEnum.EchafSapineLongeur3,
    ExportableValueEnum.EchafSapineLongeur4,
    ExportableValueEnum.EchafSapineLargeur1,
    ExportableValueEnum.EchafSapineLargeur2,
    ExportableValueEnum.EchafSapineLargeur3,
    ExportableValueEnum.EchafSapineLargeur4,
    ExportableValueEnum.EchafSapineHauteur1,
    ExportableValueEnum.EchafSapineHauteur2,
    ExportableValueEnum.EchafSapineHauteur3,
    ExportableValueEnum.EchafSapineHauteur4,
    ExportableValueEnum.EchafSapineNombre1,
    ExportableValueEnum.EchafSapineNombre2,
    ExportableValueEnum.EchafSapineNombre3,
    ExportableValueEnum.EchafSapineNombre4,
    ExportableValueEnum.EchafSapineVolume1,
    ExportableValueEnum.EchafSapineVolume2,
    ExportableValueEnum.EchafSapineVolume3,
    ExportableValueEnum.EchafSapineVolume4,
    ExportableValueEnum.Deport1Longueur,
    ExportableValueEnum.Deport2Longueur,
    ExportableValueEnum.Deport3Longueur,
    ExportableValueEnum.Deport4Longueur,
    ExportableValueEnum.Deport1Largeur,
    ExportableValueEnum.Deport2Largeur,
    ExportableValueEnum.Deport3Largeur,
    ExportableValueEnum.Deport4Largeur,
    ExportableValueEnum.Deport1Nombre,
    ExportableValueEnum.Deport2Nombre,
    ExportableValueEnum.Deport3Nombre,
    ExportableValueEnum.Deport4Nombre,
    ExportableValueEnum.Deport1M3,
    ExportableValueEnum.Deport2M3,
    ExportableValueEnum.Deport3M3,
    ExportableValueEnum.Deport4M3,
    ExportableValueEnum.DeportsM3Total,
    ExportableValueEnum.EchafSansDeportM3Total,
    ExportableValueEnum.EchafAvecDeportM3Total,
    ExportableValueEnum.MarchePied,
    ExportableValueEnum.Mds,
    ExportableValueEnum.MdsDle,
    ExportableValueEnum.MdsAccesDifficile,
    ExportableValueEnum.MdsCapacite,
    ExportableValueEnum.MdsSansObjet,
    ExportableValueEnum.MdsZc,
    ExportableValueEnum.MdsLongueur,
    ExportableValueEnum.MdsLargeur,
    ExportableValueEnum.MdsHauteur,
    ExportableValueEnum.MdsPir,
    ExportableValueEnum.MdsEchelle,
    ExportableValueEnum.MdsNacelle,
    ExportableValueEnum.MdsEdf,
    ExportableValueEnum.Ouvrage,
    ExportableValueEnum.OuvrageZc,
    ExportableValueEnum.OuvrageHzc,
    ExportableValueEnum.OuvrageDle,
    ExportableValueEnum.OuvrageAccesDifficile,
    ExportableValueEnum.OuvrageCapacite,
    ExportableValueEnum.OuvrageSansObjet,
    ExportableValueEnum.OuvrageLongueur,
    ExportableValueEnum.OuvrageLargeur,
    ExportableValueEnum.OuvrageHauteur,
    ExportableValueEnum.OuvrageDateReception,
    ExportableValueEnum.OuvragePassageCables,
    ExportableValueEnum.OuvrageEtabli,
    ExportableValueEnum.OuvrageSupportProbio,
    ExportableValueEnum.OuvrageGardeCorps,
    ExportableValueEnum.OuvrageStructureSAS,
    ExportableValueEnum.OuvragePotence,
    ExportableValueEnum.OuvrageRambardeCaillebotisCharpenteTole,
    ExportableValueEnum.Rambarde,
    ExportableValueEnum.RambardeDddPrevisionnel,
    ExportableValueEnum.RambardeDddReel,
    ExportableValueEnum.RambardeDoseCollectivePrevisionnel,
    ExportableValueEnum.RambardeDoseCollectiveReel,
    ExportableValueEnum.RambardeDoseIndividuelPrevisionnel,
    ExportableValueEnum.RambardeDoseIndividuelReelle,
    ExportableValueEnum.PrestationRealisee,
    ExportableValueEnum.RepliRealisee,
  ];

  const disabledValues: ExportableValueEnum[] = [
    ExportableValueEnum.Calo,
    ExportableValueEnum.Echaf,
    ExportableValueEnum.Mds,
    ExportableValueEnum.Ouvrage,
    ExportableValueEnum.Rambarde,
  ];

  const key = 'exportableValue.';
  export const toString: SfxMap<ExportableValueEnum, string> = new SfxMap<ExportableValueEnum, string>([
    [ExportableValueEnum.EpsilonAn, key + 'epsilonAn'],
    [ExportableValueEnum.EpsilonAp, key + 'epsilonAp'],
    [ExportableValueEnum.EpsilonAq, key + 'epsilonAq'],
    [ExportableValueEnum.EpsilonAs, key + 'epsilonAs'],
    [ExportableValueEnum.EpsilonBf, key + 'epsilonBf'],
    [ExportableValueEnum.EpsilonAt, key + 'epsilonAt'],
    [ExportableValueEnum.EpsilonAu, key + 'epsilonAu'],
    [ExportableValueEnum.EpsilonAv, key + 'epsilonAv'],
    [ExportableValueEnum.EpsilonAw, key + 'epsilonAw'],
    [ExportableValueEnum.EpsilonAx, key + 'epsilonAx'],
    [ExportableValueEnum.EpsilonAy, key + 'epsilonAy'],
    [ExportableValueEnum.EpsilonAz, key + 'epsilonAz'],
    [ExportableValueEnum.EpsilonBa, key + 'epsilonBa'],
    [ExportableValueEnum.CaloDeposeUniquement, key + 'caloDeposeUniquement'],
    [ExportableValueEnum.CaloReposeUniquement, key + 'caloReposeUniquement'],
    [ExportableValueEnum.CaloDeposeRepose, key + 'caloDeposeRepose'],
    [ExportableValueEnum.CaloDle, key + 'caloDle'],
    [ExportableValueEnum.CaloEhCsp, key + 'caloEhCsp'],
    [ExportableValueEnum.CaloEh, key + 'caloEh'],
    [ExportableValueEnum.CaloDemontable, key + 'caloDemontable'],
    [ExportableValueEnum.CaloClassique, key + 'caloClassique'],
    [ExportableValueEnum.CaloCiment, key + 'caloCiment'],
    [ExportableValueEnum.CaloHzc, key + 'caloHzc'],
    [ExportableValueEnum.CaloZc, key + 'caloZc'],
    [ExportableValueEnum.CaloInox, key + 'caloInox'],
    [ExportableValueEnum.CaloGalva, key + 'caloGalva'],
    [ExportableValueEnum.CaloAlu, key + 'caloAlu'],
    [ExportableValueEnum.CaloCoquille, key + 'caloCoquille'],
    [ExportableValueEnum.CaloNappe, key + 'caloNappe'],
    [ExportableValueEnum.CaloTelisol, key + 'caloTelisol'],
    [ExportableValueEnum.CaloArmaflex, key + 'caloArmaflex'],
    [ExportableValueEnum.CaloStyrodur, key + 'caloStyrodur'],
    [ExportableValueEnum.CaloReleveDiametre, key + 'caloReleveDiametre'],
    [ExportableValueEnum.CaloReleveEpaisseurCouche1, key + 'caloReleveEpaisseurCouche1'],
    [ExportableValueEnum.CaloReleveEpaisseurCouche2, key + 'caloReleveEpaisseurCouche2'],
    [ExportableValueEnum.CaloRelevePartieDroite, key + 'caloRelevePartieDroite'],
    [ExportableValueEnum.CaloVannesVissees, key + 'caloVannesVissees'],
    [ExportableValueEnum.CaloVannesClipsees, key + 'caloVannesClipsees'],
    [ExportableValueEnum.CaloVannesEncapsulees, key + 'caloVannesEncapsulees'],
    [ExportableValueEnum.CaloBridesVissees, key + 'caloBridesVissees'],
    [ExportableValueEnum.CaloBridesClipsees, key + 'caloBridesClipsees'],
    [ExportableValueEnum.CaloBridesEncapsulees, key + 'caloBridesEncapsulees'],
    [ExportableValueEnum.CaloCoudeVisses, key + 'caloCoudeVisses'],
    [ExportableValueEnum.CaloCoudeClipses, key + 'caloCoudeClipses'],
    [ExportableValueEnum.CaloCoudeEncapsulees, key + 'caloCoudeEncapsulees'],
    [ExportableValueEnum.CaloPiquagesVisses, key + 'caloPiquagesVisses'],
    [ExportableValueEnum.CaloPiquagesClipses, key + 'caloPiquagesClipses'],
    [ExportableValueEnum.CaloPiquagesEncapsulees, key + 'caloPiquagesEncapsulees'],
    [ExportableValueEnum.CaloReductionsVissees, key + 'caloReductionsVissees'],
    [ExportableValueEnum.CaloReductionsClipsees, key + 'caloReductionsClipsees'],
    [ExportableValueEnum.CaloReductionsEncapsulees, key + 'caloReductionsEncapsulees'],
    [ExportableValueEnum.CaloSupportsVisses, key + 'caloSupportsVisses'],
    [ExportableValueEnum.CaloSupportsClipses, key + 'caloSupportsClipses'],
    [ExportableValueEnum.CaloSupportsEncapsules, key + 'caloSupportsEncapsules'],
    [ExportableValueEnum.CaloArretVisses, key + 'caloArretVisses'],
    [ExportableValueEnum.CaloArretClipses, key + 'caloArretClipses'],
    [ExportableValueEnum.CaloArretEncapsules, key + 'caloArretEncapsules'],
    [ExportableValueEnum.CaloReleveAppareilDiametreAppareil, key + 'caloReleveAppareilDiametreAppareil'],
    [ExportableValueEnum.CaloReleveAppareilEpaisseurCouche1, key + 'caloReleveAppareilEpaisseurCouche1'],
    [ExportableValueEnum.CaloReleveAppareilEpaisseurCouche2, key + 'caloReleveAppareilEpaisseurCouche2'],
    [ExportableValueEnum.CaloReleveAppareilSurAppareil, key + 'caloReleveAppareilSurAppareil'],
    [ExportableValueEnum.CaloReleveAppareilNombreFondBallon, key + 'caloReleveAppareilNombreFondBallon'],
    [ExportableValueEnum.CaloReleveAppareilVirole, key + 'caloReleveAppareilVirole'],
    [ExportableValueEnum.CaloDateSignatureForm, key + 'caloDateSignatureForm'],
    [ExportableValueEnum.CaloDddPrevisionnel, key + 'caloDddPrevisionnel'],
    [ExportableValueEnum.CaloDddReel, key + 'caloDddReel'],
    [ExportableValueEnum.CaloDoseCollectivePrevisionnel, key + 'caloDoseCollectivePrevisionnel'],
    [ExportableValueEnum.CaloDoseCollectiveReel, key + 'caloDoseCollectiveReel'],
    [ExportableValueEnum.CaloDoseIndividuelPrevisionnel, key + 'caloDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.CaloDoseIndividuelReelle, key + 'caloDoseIndividuelReelle'],
    [ExportableValueEnum.EchafZc, key + 'echafZc'],
    [ExportableValueEnum.EchafHzc, key + 'echafHzc'],
    [ExportableValueEnum.EchafDle, key + 'echafDle'],
    [ExportableValueEnum.EchafAccesDifficile, key + 'echafAccesDifficile'],
    [ExportableValueEnum.EchafSuspendu, key + 'echafSuspendu'],
    [ExportableValueEnum.EchafPlancherAjuste, key + 'echafPlancherAjuste'],
    [ExportableValueEnum.EchafSuspenduAvecPlancherAjuste, key + 'echafSuspenduAvecPlancherAjuste'],
    [ExportableValueEnum.EchafInterventionEnCapacite, key + 'echafInterventionEnCapacite'],
    [ExportableValueEnum.EchafPlancherCondenseur, key + 'echafPlancherCondenseur'],
    [ExportableValueEnum.EchafPlancherAlternateur, key + 'echafPlancherAlternateur'],
    [ExportableValueEnum.EchafSansObjet, key + 'echafSansObjet'],
    [ExportableValueEnum.EchafLongueur, key + 'echafLongueur'],
    [ExportableValueEnum.EchafLargeur, key + 'echafLargeur'],
    [ExportableValueEnum.EchafHauteur, key + 'echafHauteur'],
    [ExportableValueEnum.EchafPlancherDeTravail, key + 'echafPlancherDeTravail'],
    [ExportableValueEnum.EchafPlancherAcces, key + 'echafPlancherAcces'],
    [ExportableValueEnum.EchafDddPrevisionnel, key + 'echafDddPrevisionnel'],
    [ExportableValueEnum.EchafDddPReel, key + 'echafDddPReel'],
    [ExportableValueEnum.EchafDoseCollectivePrevisionnel, key + 'echafDoseCollectivePrevisionnel'],
    [ExportableValueEnum.EchafDoseCollectiveReel, key + 'echafDoseCollectiveReel'],
    [ExportableValueEnum.EchafDoseIndividuelPrevisionnel, key + 'echafDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.EchafDoseIndividuelReelle, key + 'echafDoseIndividuelReelle'],
    [ExportableValueEnum.EchafDateReception, key + 'echafDateReception'],
    [ExportableValueEnum.EchafBacheGta, key + 'echafBacheGta'],
    [ExportableValueEnum.EchafCordiste, key + 'echafCordiste'],
    [ExportableValueEnum.EchafInterventionFondPiscine, key + 'echafInterventionFondPiscine'],
    [ExportableValueEnum.EchafSapineLongeur1, key + 'echafSapineLongeur1'],
    [ExportableValueEnum.EchafSapineLongeur2, key + 'echafSapineLongeur2'],
    [ExportableValueEnum.EchafSapineLongeur3, key + 'echafSapineLongeur3'],
    [ExportableValueEnum.EchafSapineLongeur4, key + 'echafSapineLongeur4'],
    [ExportableValueEnum.EchafSapineLargeur1, key + 'echafSapineLargeur1'],
    [ExportableValueEnum.EchafSapineLargeur2, key + 'echafSapineLargeur2'],
    [ExportableValueEnum.EchafSapineLargeur3, key + 'echafSapineLargeur3'],
    [ExportableValueEnum.EchafSapineLargeur4, key + 'echafSapineLargeur4'],
    [ExportableValueEnum.EchafSapineHauteur1, key + 'echafSapineHauteur1'],
    [ExportableValueEnum.EchafSapineHauteur2, key + 'echafSapineHauteur2'],
    [ExportableValueEnum.EchafSapineHauteur3, key + 'echafSapineHauteur3'],
    [ExportableValueEnum.EchafSapineHauteur4, key + 'echafSapineHauteur4'],
    [ExportableValueEnum.EchafSapineNombre1, key + 'echafSapineNombre1'],
    [ExportableValueEnum.EchafSapineNombre2, key + 'echafSapineNombre2'],
    [ExportableValueEnum.EchafSapineNombre3, key + 'echafSapineNombre3'],
    [ExportableValueEnum.EchafSapineNombre4, key + 'echafSapineNombre4'],
    [ExportableValueEnum.EchafSapineVolume1, key + 'echafSapineVolume1'],
    [ExportableValueEnum.EchafSapineVolume2, key + 'echafSapineVolume2'],
    [ExportableValueEnum.EchafSapineVolume3, key + 'echafSapineVolume3'],
    [ExportableValueEnum.EchafSapineVolume4, key + 'echafSapineVolume4'],
    [ExportableValueEnum.Deport1Longueur, key + 'deport1Longueur'],
    [ExportableValueEnum.Deport2Longueur, key + 'deport2Longueur'],
    [ExportableValueEnum.Deport3Longueur, key + 'deport3Longueur'],
    [ExportableValueEnum.Deport4Longueur, key + 'deport4Longueur'],
    [ExportableValueEnum.Deport1Largeur, key + 'deport1Largeur'],
    [ExportableValueEnum.Deport2Largeur, key + 'deport2Largeur'],
    [ExportableValueEnum.Deport3Largeur, key + 'deport3Largeur'],
    [ExportableValueEnum.Deport4Largeur, key + 'deport4Largeur'],
    [ExportableValueEnum.Deport1Nombre, key + 'deport1Nombre'],
    [ExportableValueEnum.Deport2Nombre, key + 'deport2Nombre'],
    [ExportableValueEnum.Deport3Nombre, key + 'deport3Nombre'],
    [ExportableValueEnum.Deport4Nombre, key + 'deport4Nombre'],
    [ExportableValueEnum.Deport1M3, key + 'deport1M3'],
    [ExportableValueEnum.Deport2M3, key + 'deport2M3'],
    [ExportableValueEnum.Deport3M3, key + 'deport3M3'],
    [ExportableValueEnum.Deport4M3, key + 'deport4M3'],
    [ExportableValueEnum.DeportsM3Total, key + 'deportsM3Total'],
    [ExportableValueEnum.EchafSansDeportM3Total, key + 'echafSansDeportM3Total'],
    [ExportableValueEnum.EchafAvecDeportM3Total, key + 'echafAvecDeportM3Total'],
    [ExportableValueEnum.MarchePied, key + 'marchePied'],
    [ExportableValueEnum.MdsDle, key + 'mdsDle'],
    [ExportableValueEnum.MdsAccesDifficile, key + 'mdsAccesDifficile'],
    [ExportableValueEnum.MdsCapacite, key + 'mdsCapacite'],
    [ExportableValueEnum.MdsSansObjet, key + 'mdsSansObjet'],
    [ExportableValueEnum.MdsZc, key + 'mdsZc'],
    [ExportableValueEnum.MdsLongueur, key + 'mdsLongueur'],
    [ExportableValueEnum.MdsLargeur, key + 'mdsLargeur'],
    [ExportableValueEnum.MdsHauteur, key + 'mdsHauteur'],
    [ExportableValueEnum.MdsPir, key + 'mdsPir'],
    [ExportableValueEnum.MdsEchelle, key + 'mdsEchelle'],
    [ExportableValueEnum.MdsNacelle, key + 'mdsNacelle'],
    [ExportableValueEnum.MdsEdf, key + 'mdsEdf'],
    [ExportableValueEnum.OuvrageZc, key + 'ouvrageZc'],
    [ExportableValueEnum.OuvrageHzc, key + 'ouvrageHzc'],
    [ExportableValueEnum.OuvrageDle, key + 'ouvrageDle'],
    [ExportableValueEnum.OuvrageAccesDifficile, key + 'ouvrageAccesDifficile'],
    [ExportableValueEnum.OuvrageCapacite, key + 'ouvrageCapacite'],
    [ExportableValueEnum.OuvrageSansObjet, key + 'ouvrageSansObjet'],
    [ExportableValueEnum.OuvrageLongueur, key + 'ouvrageLongueur'],
    [ExportableValueEnum.OuvrageLargeur, key + 'ouvrageLargeur'],
    [ExportableValueEnum.OuvrageHauteur, key + 'ouvrageHauteur'],
    [ExportableValueEnum.OuvrageDateReception, key + 'ouvrageDateReception'],
    [ExportableValueEnum.OuvragePassageCables, key + 'ouvragePassageCables'],
    [ExportableValueEnum.OuvrageEtabli, key + 'ouvrageEtabli'],
    [ExportableValueEnum.OuvrageSupportProbio, key + 'ouvrageSupportProbio'],
    [ExportableValueEnum.OuvrageGardeCorps, key + 'ouvrageGardeCorps'],
    [ExportableValueEnum.OuvrageStructureSAS, key + 'ouvrageStructureSAS'],
    [ExportableValueEnum.OuvragePotence, key + 'ouvragePotence'],
    [ExportableValueEnum.OuvrageRambardeCaillebotisCharpenteTole, key + 'ouvrageRambardeCaillebotisCharpenteTole'],
    [ExportableValueEnum.RambardeDddPrevisionnel, key + 'rambardeDddPrevisionnel'],
    [ExportableValueEnum.RambardeDddReel, key + 'rambardeDddReel'],
    [ExportableValueEnum.RambardeDoseCollectivePrevisionnel, key + 'rambardeDoseCollectivePrevisionnel'],
    [ExportableValueEnum.RambardeDoseCollectiveReel, key + 'rambardeDoseCollectiveReel'],
    [ExportableValueEnum.RambardeDoseIndividuelPrevisionnel, key + 'rambardeDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.RambardeDoseIndividuelReelle, key + 'rambardeDoseIndividuelReelle'],
    [ExportableValueEnum.PrestationRealisee, key + 'prestationRealisee'],
    [ExportableValueEnum.RepliRealisee, key + 'repliRealisee'],

    [ExportableValueEnum.Calo, key + 'calo'],
    [ExportableValueEnum.Echaf, key + 'echaf'],
    [ExportableValueEnum.Mds, key + 'mds'],
    [ExportableValueEnum.Ouvrage, key + 'ouvrage'],
    [ExportableValueEnum.Rambarde, key + 'rambarde'],
  ]);

  export const convertFromApiValue: SfxMap<string, ExportableValueEnum> = new SfxMap<string, ExportableValueEnum>([
    ['epsilonAn', ExportableValueEnum.EpsilonAn],
    ['epsilonAp', ExportableValueEnum.EpsilonAp],
    ['epsilonAq', ExportableValueEnum.EpsilonAq],
    ['epsilonAs', ExportableValueEnum.EpsilonAs],
    ['epsilonBf', ExportableValueEnum.EpsilonBf],
    ['epsilonAt', ExportableValueEnum.EpsilonAt],
    ['epsilonAu', ExportableValueEnum.EpsilonAu],
    ['epsilonAv', ExportableValueEnum.EpsilonAv],
    ['epsilonAw', ExportableValueEnum.EpsilonAw],
    ['epsilonAx', ExportableValueEnum.EpsilonAx],
    ['epsilonAy', ExportableValueEnum.EpsilonAy],
    ['epsilonAz', ExportableValueEnum.EpsilonAz],
    ['epsilonBa', ExportableValueEnum.EpsilonBa],
    ['caloDeposeUniquement', ExportableValueEnum.CaloDeposeUniquement],
    ['caloReposeUniquement', ExportableValueEnum.CaloReposeUniquement],
    ['caloDeposeRepose', ExportableValueEnum.CaloDeposeRepose],
    ['caloDle', ExportableValueEnum.CaloDle],
    ['caloEhCsp', ExportableValueEnum.CaloEhCsp],
    ['caloEh', ExportableValueEnum.CaloEh],
    ['caloDemontable', ExportableValueEnum.CaloDemontable],
    ['caloClassique', ExportableValueEnum.CaloClassique],
    ['caloCiment', ExportableValueEnum.CaloCiment],
    ['caloHzc', ExportableValueEnum.CaloHzc],
    ['caloZc', ExportableValueEnum.CaloZc],
    ['caloInox', ExportableValueEnum.CaloInox],
    ['caloGalva', ExportableValueEnum.CaloGalva],
    ['caloAlu', ExportableValueEnum.CaloAlu],
    ['caloCoquille', ExportableValueEnum.CaloCoquille],
    ['caloNappe', ExportableValueEnum.CaloNappe],
    ['caloTelisol', ExportableValueEnum.CaloTelisol],
    ['caloArmaflex', ExportableValueEnum.CaloArmaflex],
    ['caloStyrodur', ExportableValueEnum.CaloStyrodur],
    ['caloReleveDiametre', ExportableValueEnum.CaloReleveDiametre],
    ['caloReleveEpaisseurCouche1', ExportableValueEnum.CaloReleveEpaisseurCouche1],
    ['caloReleveEpaisseurCouche2', ExportableValueEnum.CaloReleveEpaisseurCouche2],
    ['caloRelevePartieDroite', ExportableValueEnum.CaloRelevePartieDroite],
    ['caloVannesVissees', ExportableValueEnum.CaloVannesVissees],
    ['caloVannesClipsees', ExportableValueEnum.CaloVannesClipsees],
    ['caloVannesEncapsulees', ExportableValueEnum.CaloVannesEncapsulees],
    ['caloBridesVissees', ExportableValueEnum.CaloBridesVissees],
    ['caloBridesClipsees', ExportableValueEnum.CaloBridesClipsees],
    ['caloBridesEncapsulees', ExportableValueEnum.CaloBridesEncapsulees],
    ['caloCoudeVisses', ExportableValueEnum.CaloCoudeVisses],
    ['caloCoudeClipses', ExportableValueEnum.CaloCoudeClipses],
    ['caloCoudeEncapsulees', ExportableValueEnum.CaloCoudeEncapsulees],
    ['caloPiquagesVisses', ExportableValueEnum.CaloPiquagesVisses],
    ['caloPiquagesClipses', ExportableValueEnum.CaloPiquagesClipses],
    ['caloPiquagesEncapsulees', ExportableValueEnum.CaloPiquagesEncapsulees],
    ['caloReductionsVissees', ExportableValueEnum.CaloReductionsVissees],
    ['caloReductionsClipsees', ExportableValueEnum.CaloReductionsClipsees],
    ['caloReductionsEncapsulees', ExportableValueEnum.CaloReductionsEncapsulees],
    ['caloSupportsVisses', ExportableValueEnum.CaloSupportsVisses],
    ['caloSupportsClipses', ExportableValueEnum.CaloSupportsClipses],
    ['caloSupportsEncapsules', ExportableValueEnum.CaloSupportsEncapsules],
    ['caloArretVisses', ExportableValueEnum.CaloArretVisses],
    ['caloArretClipses', ExportableValueEnum.CaloArretClipses],
    ['caloArretEncapsules', ExportableValueEnum.CaloArretEncapsules],
    ['caloReleveAppareilDiametreAppareil', ExportableValueEnum.CaloReleveAppareilDiametreAppareil],
    ['caloReleveAppareilEpaisseurCouche1', ExportableValueEnum.CaloReleveAppareilEpaisseurCouche1],
    ['caloReleveAppareilEpaisseurCouche2', ExportableValueEnum.CaloReleveAppareilEpaisseurCouche2],
    ['caloReleveAppareilSurAppareil', ExportableValueEnum.CaloReleveAppareilSurAppareil],
    ['caloReleveAppareilNombreFondBallon', ExportableValueEnum.CaloReleveAppareilNombreFondBallon],
    ['caloReleveAppareilVirole', ExportableValueEnum.CaloReleveAppareilVirole],
    ['caloDateSignatureForm', ExportableValueEnum.CaloDateSignatureForm],
    ['caloDddPrevisionnel', ExportableValueEnum.CaloDddPrevisionnel],
    ['caloDddReel', ExportableValueEnum.CaloDddReel],
    ['caloDoseCollectivePrevisionnel', ExportableValueEnum.CaloDoseCollectivePrevisionnel],
    ['caloDoseCollectiveReel', ExportableValueEnum.CaloDoseCollectiveReel],
    ['caloDoseIndividuelPrevisionnel', ExportableValueEnum.CaloDoseIndividuelPrevisionnel],
    ['caloDoseIndividuelReelle', ExportableValueEnum.CaloDoseIndividuelReelle],
    ['echafZc', ExportableValueEnum.EchafZc],
    ['echafHzc', ExportableValueEnum.EchafHzc],
    ['echafDle', ExportableValueEnum.EchafDle],
    ['echafAccesDifficile', ExportableValueEnum.EchafAccesDifficile],
    ['echafSuspendu', ExportableValueEnum.EchafSuspendu],
    ['echafPlancherAjuste', ExportableValueEnum.EchafPlancherAjuste],
    ['echafSuspenduAvecPlancherAjuste', ExportableValueEnum.EchafSuspenduAvecPlancherAjuste],
    ['echafInterventionEnCapacite', ExportableValueEnum.EchafInterventionEnCapacite],
    ['echafPlancherCondenseur', ExportableValueEnum.EchafPlancherCondenseur],
    ['echafPlancherAlternateur', ExportableValueEnum.EchafPlancherAlternateur],
    ['echafSansObjet', ExportableValueEnum.EchafSansObjet],
    ['echafLongueur', ExportableValueEnum.EchafLongueur],
    ['echafLargeur', ExportableValueEnum.EchafLargeur],
    ['echafHauteur', ExportableValueEnum.EchafHauteur],
    ['echafPlancherDeTravail', ExportableValueEnum.EchafPlancherDeTravail],
    ['echafPlancherAcces', ExportableValueEnum.EchafPlancherAcces],
    ['echafDddPrevisionnel', ExportableValueEnum.EchafDddPrevisionnel],
    ['echafDddPReel', ExportableValueEnum.EchafDddPReel],
    ['echafDoseCollectivePrevisionnel', ExportableValueEnum.EchafDoseCollectivePrevisionnel],
    ['echafDoseCollectiveReel', ExportableValueEnum.EchafDoseCollectiveReel],
    ['echafDoseIndividuelPrevisionnel', ExportableValueEnum.EchafDoseIndividuelPrevisionnel],
    ['echafDoseIndividuelReelle', ExportableValueEnum.EchafDoseIndividuelReelle],
    ['echafDateReception', ExportableValueEnum.EchafDateReception],
    ['echafBacheGta', ExportableValueEnum.EchafBacheGta],
    ['echafCordiste', ExportableValueEnum.EchafCordiste],
    ['echafInterventionFondPiscine', ExportableValueEnum.EchafInterventionFondPiscine],
    ['echafSapineLongeur1', ExportableValueEnum.EchafSapineLongeur1],
    ['echafSapineLongeur2', ExportableValueEnum.EchafSapineLongeur2],
    ['echafSapineLongeur3', ExportableValueEnum.EchafSapineLongeur3],
    ['echafSapineLongeur4', ExportableValueEnum.EchafSapineLongeur4],
    ['echafSapineLargeur1', ExportableValueEnum.EchafSapineLargeur1],
    ['echafSapineLargeur2', ExportableValueEnum.EchafSapineLargeur2],
    ['echafSapineLargeur3', ExportableValueEnum.EchafSapineLargeur3],
    ['echafSapineLargeur4', ExportableValueEnum.EchafSapineLargeur4],
    ['echafSapineHauteur1', ExportableValueEnum.EchafSapineHauteur1],
    ['echafSapineHauteur2', ExportableValueEnum.EchafSapineHauteur2],
    ['echafSapineHauteur3', ExportableValueEnum.EchafSapineHauteur3],
    ['echafSapineHauteur4', ExportableValueEnum.EchafSapineHauteur4],
    ['echafSapineNombre1', ExportableValueEnum.EchafSapineNombre1],
    ['echafSapineNombre2', ExportableValueEnum.EchafSapineNombre2],
    ['echafSapineNombre3', ExportableValueEnum.EchafSapineNombre3],
    ['echafSapineNombre4', ExportableValueEnum.EchafSapineNombre4],
    ['echafSapineVolume1', ExportableValueEnum.EchafSapineVolume1],
    ['echafSapineVolume2', ExportableValueEnum.EchafSapineVolume2],
    ['echafSapineVolume3', ExportableValueEnum.EchafSapineVolume3],
    ['echafSapineVolume4', ExportableValueEnum.EchafSapineVolume4],
    ['deport1Longueur', ExportableValueEnum.Deport1Longueur],
    ['deport2Longueur', ExportableValueEnum.Deport2Longueur],
    ['deport3Longueur', ExportableValueEnum.Deport3Longueur],
    ['deport4Longueur', ExportableValueEnum.Deport4Longueur],
    ['deport1Largeur', ExportableValueEnum.Deport1Largeur],
    ['deport2Largeur', ExportableValueEnum.Deport2Largeur],
    ['deport3Largeur', ExportableValueEnum.Deport3Largeur],
    ['deport4Largeur', ExportableValueEnum.Deport4Largeur],
    ['deport1Nombre', ExportableValueEnum.Deport1Nombre],
    ['deport2Nombre', ExportableValueEnum.Deport2Nombre],
    ['deport3Nombre', ExportableValueEnum.Deport3Nombre],
    ['deport4Nombre', ExportableValueEnum.Deport4Nombre],
    ['deport1M3', ExportableValueEnum.Deport1M3],
    ['deport2M3', ExportableValueEnum.Deport2M3],
    ['deport3M3', ExportableValueEnum.Deport3M3],
    ['deport4M3', ExportableValueEnum.Deport4M3],
    ['deportsM3Total', ExportableValueEnum.DeportsM3Total],
    ['echafSansDeportM3Total', ExportableValueEnum.EchafSansDeportM3Total],
    ['echafAvecDeportM3Total', ExportableValueEnum.EchafAvecDeportM3Total],
    ['marchePied', ExportableValueEnum.MarchePied],
    ['mdsDle', ExportableValueEnum.MdsDle],
    ['mdsAccesDifficile', ExportableValueEnum.MdsAccesDifficile],
    ['mdsCapacite', ExportableValueEnum.MdsCapacite],
    ['mdsSansObjet', ExportableValueEnum.MdsSansObjet],
    ['mdsZc', ExportableValueEnum.MdsZc],
    ['mdsLongueur', ExportableValueEnum.MdsLongueur],
    ['mdsLargeur', ExportableValueEnum.MdsLargeur],
    ['mdsHauteur', ExportableValueEnum.MdsHauteur],
    ['mdsPir', ExportableValueEnum.MdsPir],
    ['mdsEchelle', ExportableValueEnum.MdsEchelle],
    ['mdsNacelle', ExportableValueEnum.MdsNacelle],
    ['mdsEdf', ExportableValueEnum.MdsEdf],
    ['ouvrageZc', ExportableValueEnum.OuvrageZc],
    ['ouvrageHzc', ExportableValueEnum.OuvrageHzc],
    ['ouvrageDle', ExportableValueEnum.OuvrageDle],
    ['ouvrageAccesDifficile', ExportableValueEnum.OuvrageAccesDifficile],
    ['ouvrageCapacite', ExportableValueEnum.OuvrageCapacite],
    ['ouvrageSansObjet', ExportableValueEnum.OuvrageSansObjet],
    ['ouvrageLongueur', ExportableValueEnum.OuvrageLongueur],
    ['ouvrageLargeur', ExportableValueEnum.OuvrageLargeur],
    ['ouvrageHauteur', ExportableValueEnum.OuvrageHauteur],
    ['ouvrageDateReception', ExportableValueEnum.OuvrageDateReception],
    ['ouvragePassageCables', ExportableValueEnum.OuvragePassageCables],
    ['ouvrageEtabli', ExportableValueEnum.OuvrageEtabli],
    ['ouvrageSupportProbio', ExportableValueEnum.OuvrageSupportProbio],
    ['ouvrageGardeCorps', ExportableValueEnum.OuvrageGardeCorps],
    ['ouvrageStructureSAS', ExportableValueEnum.OuvrageStructureSAS],
    ['ouvragePotence', ExportableValueEnum.OuvragePotence],
    ['ouvrageRambardeCaillebotisCharpenteTole', ExportableValueEnum.OuvrageRambardeCaillebotisCharpenteTole],
    ['rambardeDddPrevisionnel', ExportableValueEnum.RambardeDddPrevisionnel],
    ['rambardeDddReel', ExportableValueEnum.RambardeDddReel],
    ['rambardeDoseCollectivePrevisionnel', ExportableValueEnum.RambardeDoseCollectivePrevisionnel],
    ['rambardeDoseCollectiveReel', ExportableValueEnum.RambardeDoseCollectiveReel],
    ['rambardeDoseIndividuelPrevisionnel', ExportableValueEnum.RambardeDoseIndividuelPrevisionnel],
    ['rambardeDoseIndividuelReelle', ExportableValueEnum.RambardeDoseIndividuelReelle],
    ['prestationRealisee', ExportableValueEnum.PrestationRealisee],
    ['repliRealisee', ExportableValueEnum.RepliRealisee],
  ]);

  export const convertToApiValue: SfxMap<ExportableValueEnum, string> = new SfxMap<ExportableValueEnum, string>([
    [ExportableValueEnum.EpsilonAn, 'epsilonAn'],
    [ExportableValueEnum.EpsilonAp, 'epsilonAp'],
    [ExportableValueEnum.EpsilonAq, 'epsilonAq'],
    [ExportableValueEnum.EpsilonAs, 'epsilonAs'],
    [ExportableValueEnum.EpsilonBf, 'epsilonBf'],
    [ExportableValueEnum.EpsilonAt, 'epsilonAt'],
    [ExportableValueEnum.EpsilonAu, 'epsilonAu'],
    [ExportableValueEnum.EpsilonAv, 'epsilonAv'],
    [ExportableValueEnum.EpsilonAw, 'epsilonAw'],
    [ExportableValueEnum.EpsilonAx, 'epsilonAx'],
    [ExportableValueEnum.EpsilonAy, 'epsilonAy'],
    [ExportableValueEnum.EpsilonAz, 'epsilonAz'],
    [ExportableValueEnum.EpsilonBa, 'epsilonBa'],
    [ExportableValueEnum.CaloDeposeUniquement, 'caloDeposeUniquement'],
    [ExportableValueEnum.CaloReposeUniquement, 'caloReposeUniquement'],
    [ExportableValueEnum.CaloDeposeRepose, 'caloDeposeRepose'],
    [ExportableValueEnum.CaloDle, 'caloDle'],
    [ExportableValueEnum.CaloEhCsp, 'caloEhCsp'],
    [ExportableValueEnum.CaloEh, 'caloEh'],
    [ExportableValueEnum.CaloDemontable, 'caloDemontable'],
    [ExportableValueEnum.CaloClassique, 'caloClassique'],
    [ExportableValueEnum.CaloCiment, 'caloCiment'],
    [ExportableValueEnum.CaloHzc, 'caloHzc'],
    [ExportableValueEnum.CaloZc, 'caloZc'],
    [ExportableValueEnum.CaloInox, 'caloInox'],
    [ExportableValueEnum.CaloGalva, 'caloGalva'],
    [ExportableValueEnum.CaloAlu, 'caloAlu'],
    [ExportableValueEnum.CaloCoquille, 'caloCoquille'],
    [ExportableValueEnum.CaloNappe, 'caloNappe'],
    [ExportableValueEnum.CaloTelisol, 'caloTelisol'],
    [ExportableValueEnum.CaloArmaflex, 'caloArmaflex'],
    [ExportableValueEnum.CaloStyrodur, 'caloStyrodur'],
    [ExportableValueEnum.CaloReleveDiametre, 'caloReleveDiametre'],
    [ExportableValueEnum.CaloReleveEpaisseurCouche1, 'caloReleveEpaisseurCouche1'],
    [ExportableValueEnum.CaloReleveEpaisseurCouche2, 'caloReleveEpaisseurCouche2'],
    [ExportableValueEnum.CaloRelevePartieDroite, 'caloRelevePartieDroite'],
    [ExportableValueEnum.CaloVannesVissees, 'caloVannesVissees'],
    [ExportableValueEnum.CaloVannesClipsees, 'caloVannesClipsees'],
    [ExportableValueEnum.CaloVannesEncapsulees, 'caloVannesEncapsulees'],
    [ExportableValueEnum.CaloBridesVissees, 'caloBridesVissees'],
    [ExportableValueEnum.CaloBridesClipsees, 'caloBridesClipsees'],
    [ExportableValueEnum.CaloBridesEncapsulees, 'caloBridesEncapsulees'],
    [ExportableValueEnum.CaloCoudeVisses, 'caloCoudeVisses'],
    [ExportableValueEnum.CaloCoudeClipses, 'caloCoudeClipses'],
    [ExportableValueEnum.CaloCoudeEncapsulees, 'caloCoudeEncapsulees'],
    [ExportableValueEnum.CaloPiquagesVisses, 'caloPiquagesVisses'],
    [ExportableValueEnum.CaloPiquagesClipses, 'caloPiquagesClipses'],
    [ExportableValueEnum.CaloPiquagesEncapsulees, 'caloPiquagesEncapsulees'],
    [ExportableValueEnum.CaloReductionsVissees, 'caloReductionsVissees'],
    [ExportableValueEnum.CaloReductionsClipsees, 'caloReductionsClipsees'],
    [ExportableValueEnum.CaloReductionsEncapsulees, 'caloReductionsEncapsulees'],
    [ExportableValueEnum.CaloSupportsVisses, 'caloSupportsVisses'],
    [ExportableValueEnum.CaloSupportsClipses, 'caloSupportsClipses'],
    [ExportableValueEnum.CaloSupportsEncapsules, 'caloSupportsEncapsules'],
    [ExportableValueEnum.CaloArretVisses, 'caloArretVisses'],
    [ExportableValueEnum.CaloArretClipses, 'caloArretClipses'],
    [ExportableValueEnum.CaloArretEncapsules, 'caloArretEncapsules'],
    [ExportableValueEnum.CaloReleveAppareilDiametreAppareil, 'caloReleveAppareilDiametreAppareil'],
    [ExportableValueEnum.CaloReleveAppareilEpaisseurCouche1, 'caloReleveAppareilEpaisseurCouche1'],
    [ExportableValueEnum.CaloReleveAppareilEpaisseurCouche2, 'caloReleveAppareilEpaisseurCouche2'],
    [ExportableValueEnum.CaloReleveAppareilSurAppareil, 'caloReleveAppareilSurAppareil'],
    [ExportableValueEnum.CaloReleveAppareilNombreFondBallon, 'caloReleveAppareilNombreFondBallon'],
    [ExportableValueEnum.CaloReleveAppareilVirole, 'caloReleveAppareilVirole'],
    [ExportableValueEnum.CaloDateSignatureForm, 'caloDateSignatureForm'],
    [ExportableValueEnum.CaloDddPrevisionnel, 'caloDddPrevisionnel'],
    [ExportableValueEnum.CaloDddReel, 'caloDddReel'],
    [ExportableValueEnum.CaloDoseCollectivePrevisionnel, 'caloDoseCollectivePrevisionnel'],
    [ExportableValueEnum.CaloDoseCollectiveReel, 'caloDoseCollectiveReel'],
    [ExportableValueEnum.CaloDoseIndividuelPrevisionnel, 'caloDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.CaloDoseIndividuelReelle, 'caloDoseIndividuelReelle'],
    [ExportableValueEnum.EchafZc, 'echafZc'],
    [ExportableValueEnum.EchafHzc, 'echafHzc'],
    [ExportableValueEnum.EchafDle, 'echafDle'],
    [ExportableValueEnum.EchafAccesDifficile, 'echafAccesDifficile'],
    [ExportableValueEnum.EchafSuspendu, 'echafSuspendu'],
    [ExportableValueEnum.EchafPlancherAjuste, 'echafPlancherAjuste'],
    [ExportableValueEnum.EchafSuspenduAvecPlancherAjuste, 'echafSuspenduAvecPlancherAjuste'],
    [ExportableValueEnum.EchafInterventionEnCapacite, 'echafInterventionEnCapacite'],
    [ExportableValueEnum.EchafPlancherCondenseur, 'echafPlancherCondenseur'],
    [ExportableValueEnum.EchafPlancherAlternateur, 'echafPlancherAlternateur'],
    [ExportableValueEnum.EchafSansObjet, 'echafSansObjet'],
    [ExportableValueEnum.EchafLongueur, 'echafLongueur'],
    [ExportableValueEnum.EchafLargeur, 'echafLargeur'],
    [ExportableValueEnum.EchafHauteur, 'echafHauteur'],
    [ExportableValueEnum.EchafPlancherDeTravail, 'echafPlancherDeTravail'],
    [ExportableValueEnum.EchafPlancherAcces, 'echafPlancherAcces'],
    [ExportableValueEnum.EchafDddPrevisionnel, 'echafDddPrevisionnel'],
    [ExportableValueEnum.EchafDddPReel, 'echafDddPReel'],
    [ExportableValueEnum.EchafDoseCollectivePrevisionnel, 'echafDoseCollectivePrevisionnel'],
    [ExportableValueEnum.EchafDoseCollectiveReel, 'echafDoseCollectiveReel'],
    [ExportableValueEnum.EchafDoseIndividuelPrevisionnel, 'echafDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.EchafDoseIndividuelReelle, 'echafDoseIndividuelReelle'],
    [ExportableValueEnum.EchafDateReception, 'echafDateReception'],
    [ExportableValueEnum.EchafBacheGta, 'echafBacheGta'],
    [ExportableValueEnum.EchafCordiste, 'echafCordiste'],
    [ExportableValueEnum.EchafInterventionFondPiscine, 'echafInterventionFondPiscine'],
    [ExportableValueEnum.EchafSapineLongeur1, 'echafSapineLongeur1'],
    [ExportableValueEnum.EchafSapineLongeur2, 'echafSapineLongeur2'],
    [ExportableValueEnum.EchafSapineLongeur3, 'echafSapineLongeur3'],
    [ExportableValueEnum.EchafSapineLongeur4, 'echafSapineLongeur4'],
    [ExportableValueEnum.EchafSapineLargeur1, 'echafSapineLargeur1'],
    [ExportableValueEnum.EchafSapineLargeur2, 'echafSapineLargeur2'],
    [ExportableValueEnum.EchafSapineLargeur3, 'echafSapineLargeur3'],
    [ExportableValueEnum.EchafSapineLargeur4, 'echafSapineLargeur4'],
    [ExportableValueEnum.EchafSapineHauteur1, 'echafSapineHauteur1'],
    [ExportableValueEnum.EchafSapineHauteur2, 'echafSapineHauteur2'],
    [ExportableValueEnum.EchafSapineHauteur3, 'echafSapineHauteur3'],
    [ExportableValueEnum.EchafSapineHauteur4, 'echafSapineHauteur4'],
    [ExportableValueEnum.EchafSapineNombre1, 'echafSapineNombre1'],
    [ExportableValueEnum.EchafSapineNombre2, 'echafSapineNombre2'],
    [ExportableValueEnum.EchafSapineNombre3, 'echafSapineNombre3'],
    [ExportableValueEnum.EchafSapineNombre4, 'echafSapineNombre4'],
    [ExportableValueEnum.EchafSapineVolume1, 'echafSapineVolume1'],
    [ExportableValueEnum.EchafSapineVolume2, 'echafSapineVolume2'],
    [ExportableValueEnum.EchafSapineVolume3, 'echafSapineVolume3'],
    [ExportableValueEnum.EchafSapineVolume4, 'echafSapineVolume4'],
    [ExportableValueEnum.Deport1Longueur, 'deport1Longueur'],
    [ExportableValueEnum.Deport2Longueur, 'deport2Longueur'],
    [ExportableValueEnum.Deport3Longueur, 'deport3Longueur'],
    [ExportableValueEnum.Deport4Longueur, 'deport4Longueur'],
    [ExportableValueEnum.Deport1Largeur, 'deport1Largeur'],
    [ExportableValueEnum.Deport2Largeur, 'deport2Largeur'],
    [ExportableValueEnum.Deport3Largeur, 'deport3Largeur'],
    [ExportableValueEnum.Deport4Largeur, 'deport4Largeur'],
    [ExportableValueEnum.Deport1Nombre, 'deport1Nombre'],
    [ExportableValueEnum.Deport2Nombre, 'deport2Nombre'],
    [ExportableValueEnum.Deport3Nombre, 'deport3Nombre'],
    [ExportableValueEnum.Deport4Nombre, 'deport4Nombre'],
    [ExportableValueEnum.Deport1M3, 'deport1M3'],
    [ExportableValueEnum.Deport2M3, 'deport2M3'],
    [ExportableValueEnum.Deport3M3, 'deport3M3'],
    [ExportableValueEnum.Deport4M3, 'deport4M3'],
    [ExportableValueEnum.DeportsM3Total, 'deportsM3Total'],
    [ExportableValueEnum.EchafSansDeportM3Total, 'echafSansDeportM3Total'],
    [ExportableValueEnum.EchafAvecDeportM3Total, 'echafAvecDeportM3Total'],
    [ExportableValueEnum.MarchePied, 'marchePied'],
    [ExportableValueEnum.MdsDle, 'mdsDle'],
    [ExportableValueEnum.MdsAccesDifficile, 'mdsAccesDifficile'],
    [ExportableValueEnum.MdsCapacite, 'mdsCapacite'],
    [ExportableValueEnum.MdsSansObjet, 'mdsSansObjet'],
    [ExportableValueEnum.MdsZc, 'mdsZc'],
    [ExportableValueEnum.MdsLongueur, 'mdsLongueur'],
    [ExportableValueEnum.MdsLargeur, 'mdsLargeur'],
    [ExportableValueEnum.MdsHauteur, 'mdsHauteur'],
    [ExportableValueEnum.MdsPir, 'mdsPir'],
    [ExportableValueEnum.MdsEchelle, 'mdsEchelle'],
    [ExportableValueEnum.MdsNacelle, 'mdsNacelle'],
    [ExportableValueEnum.MdsEdf, 'mdsEdf'],
    [ExportableValueEnum.OuvrageZc, 'ouvrageZc'],
    [ExportableValueEnum.OuvrageHzc, 'ouvrageHzc'],
    [ExportableValueEnum.OuvrageDle, 'ouvrageDle'],
    [ExportableValueEnum.OuvrageAccesDifficile, 'ouvrageAccesDifficile'],
    [ExportableValueEnum.OuvrageCapacite, 'ouvrageCapacite'],
    [ExportableValueEnum.OuvrageSansObjet, 'ouvrageSansObjet'],
    [ExportableValueEnum.OuvrageLongueur, 'ouvrageLongueur'],
    [ExportableValueEnum.OuvrageLargeur, 'ouvrageLargeur'],
    [ExportableValueEnum.OuvrageHauteur, 'ouvrageHauteur'],
    [ExportableValueEnum.OuvrageDateReception, 'ouvrageDateReception'],
    [ExportableValueEnum.OuvragePassageCables, 'ouvragePassageCables'],
    [ExportableValueEnum.OuvrageEtabli, 'ouvrageEtabli'],
    [ExportableValueEnum.OuvrageSupportProbio, 'ouvrageSupportProbio'],
    [ExportableValueEnum.OuvrageGardeCorps, 'ouvrageGardeCorps'],
    [ExportableValueEnum.OuvrageStructureSAS, 'ouvrageStructureSAS'],
    [ExportableValueEnum.OuvragePotence, 'ouvragePotence'],
    [ExportableValueEnum.OuvrageRambardeCaillebotisCharpenteTole, 'ouvrageRambardeCaillebotisCharpenteTole'],
    [ExportableValueEnum.RambardeDddPrevisionnel, 'rambardeDddPrevisionnel'],
    [ExportableValueEnum.RambardeDddReel, 'rambardeDddReel'],
    [ExportableValueEnum.RambardeDoseCollectivePrevisionnel, 'rambardeDoseCollectivePrevisionnel'],
    [ExportableValueEnum.RambardeDoseCollectiveReel, 'rambardeDoseCollectiveReel'],
    [ExportableValueEnum.RambardeDoseIndividuelPrevisionnel, 'rambardeDoseIndividuelPrevisionnel'],
    [ExportableValueEnum.RambardeDoseIndividuelReelle, 'rambardeDoseIndividuelReelle'],
    [ExportableValueEnum.PrestationRealisee, 'prestationRealisee'],
    [ExportableValueEnum.RepliRealisee, 'repliRealisee'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<ExportableValueEnum>[]> =>
    of(
      values.map(type => ({
        value: type,
        title: ExportableValueEnum.toString.getValue(type),
        disabled: disabledValues.includes(type),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<ExportableValueEnum>, item: ExportableValueEnum) => node.value === item;
}
