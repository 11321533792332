import { BlocAdrUpdateRequestDTO } from '../../generated/models/models';

export interface BlockRiskForUpdate {
  uuidEntity: string;
  major: boolean;
}

export namespace BlockRiskForUpdate {
  export function mapToUpdateRequestApiValue(risk: BlockRiskForUpdate): BlocAdrUpdateRequestDTO {
    return {
      uuidEntity: risk.uuidEntity,
      major: risk.major,
    };
  }
}
