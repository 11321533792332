import { ApplicationFileLightDTO } from '../../generated/models/applicationFileLightDTO';
import { ApplicationFileSummaryResponseDTO } from '../../generated/models/applicationFileSummaryResponseDTO';

import { BaseQuery } from '../utils/base-query.model';
import { DateUtils } from '../../utils/date.utils';

import { CustomFileExtensionEnum, InnerSortDirectionEnum } from '../../enums';
import { ApplicationFilePageRequest } from '../../generated/models/applicationFilePageRequest';

export interface ApplicationFileLightData {
  id: number;
  uuidEntity: string;
  createDate?: string;
  extension: string;
  extensionEnumType?: CustomFileExtensionEnum;
  name: string;
  path: string;
  order?: number;
  size?: string;
  deleted?: boolean;
}
export namespace ApplicationFileLightData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export type FileType = any;

  export function mapFromApiValue(file: ApplicationFileLightDTO | ApplicationFileSummaryResponseDTO): ApplicationFileLightData {
    return {
      id: file['id'], // MakeModels: @sajir: Backend issue
      uuidEntity: file.uuidEntity,
      createDate: DateUtils.toDateTimeFormat(file['createDate']),
      extension: file.extension,
      extensionEnumType: CustomFileExtensionEnum.convertFromParamsValue.getValue(file.extension?.toLowerCase()),
      name: file.name || file['fileName'], // MakeModels: @sajir: Backend issue
      path: file.path,
      size: file['fileSize'] || file['size'], // MakeModels: @sajir: Backend issue
      order: file['order'], // MakeModels: @sajir: Backend issue
    };
  }

  export function mapFromSummaryApiValue(file: ApplicationFileSummaryResponseDTO): ApplicationFileLightData {
    return {
      id: file['id'],
      uuidEntity: file.uuidEntity,
      extension: file.extension,
      name: file.name || file['fileName'], // MakeModels: @sajir: Backend issue
      path: file.path,
    };
  }

  export function checkFileValidation(fileData: FileType, fileType: 'PDF' | 'IMG'): boolean {
    return fileData && ((fileType === 'PDF' && fileData.type === 'application/pdf') || (fileType === 'IMG' && (fileData.type === 'image/jpeg' || fileData.type === 'image/png')));
  }

  const types = [
    { extension: 'pdf', mime: 'application/pdf' },
    { extension: 'xls', mime: 'application/vnd.ms-excel' },
    { extension: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: 'csv', mime: 'text/csv' },
    { extension: 'csv', mime: 'application/vnd.ms-excel' },
    { extension: 'jpg', mime: 'image/jpeg' },
    { extension: 'jpg', mime: 'image/jpg' },
    { extension: 'png', mime: 'image/png' },
    { extension: 'gif', mime: 'image/gif' },
    { extension: 'doc', mime: 'application/msword' },
    { extension: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extension: 'txt', mime: 'text/plain' },
    { extension: 'zip', mime: 'application/zip' },
    { extension: 'zip', mime: 'application/x-zip-compressed' },
    { extension: 'zip', mime: 'application/octet-stream' },
  ];

  export function checkFileValidationFileType(extension: string, mime: string): boolean {
    return types.some(type => type.extension === extension?.toLocaleLowerCase() && type.mime === mime?.toLocaleLowerCase());
  }

  export function mapToPageRequestApiValue(queries: BaseQuery<ApplicationFileLightData>): ApplicationFilePageRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
    };
  }
}
