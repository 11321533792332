import { VersionRequestDTO } from '../../generated/models/versionRequestDTO';
import { optionValueType, QuestionOptionData } from '../form-builder/question-option-data';
import { TableCellData } from '../form-builder/table-cell-data';
import { OptionDetailsData } from '../option/option-details-data';
import { SignatoryRequestData } from '../signatures/signatory-request-data';

import { QuestionTypeEnum } from '../../enums';

export interface VersionRequestData {
  signatory: SignatoryRequestData;
  versionOptions: (TableCellData<optionValueType> | OptionDetailsData | QuestionOptionData<optionValueType>)[];
}

export namespace VersionRequestData {
  export function mapToApiValue(data: VersionRequestData, questionType: QuestionTypeEnum): VersionRequestDTO {
    return {
      signatory: SignatoryRequestData.mapToApiValue(data.signatory),
      versionOptions: data.versionOptions?.map(vOption => QuestionOptionData.mapToApiValue(vOption, questionType)),
    };
  }
}
