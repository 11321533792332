import { EntityChronoCreateRequestDTO } from '../../generated/models/entityChronoCreateRequestDTO';
import { EntityLightData } from '../utils/entity-light-data';
import { ErrorResponseData } from '../utils/http-params.type';
import { DialogToasterData } from '../../../modules/sfx-dialog/state/dialog-toaster-data';

import { CounterTypeEnum, CounterFormatEnum, FieldNameEnum, LinkTypeEnum, BusinessClassNameEnum, ApiErrorResponsesEnum } from '../../enums';

export interface ReferenceForCreate {
  counterFormat: CounterFormatEnum;
  counterType: CounterTypeEnum;
  fieldName: FieldNameEnum;
  labelFamily: EntityLightData;
  linkType: LinkTypeEnum;
  nature: BusinessClassNameEnum;
  nomenclature: string;
  startValue: number;
}

export namespace ReferenceForCreate {
  const existingReferenceErrorKey = 'sameEntityChrono';
  const invalidNomenclatureErrorKey = 'patternNotValid';

  export function mapToCreateRequestApiValue(data: ReferenceForCreate): EntityChronoCreateRequestDTO {
    return {
      counterFormat: data.counterFormat,
      counterType: CounterTypeEnum.convertToApiValue.getValue(data.counterType),
      fieldName: FieldNameEnum.convertToApiValue.getValue(data.fieldName),
      labelFamilyUuide: data.labelFamily && data.labelFamily.uuidEntity,
      linkType: LinkTypeEnum.convertToApiValueRegardingNature(data.nature).getValue(data.linkType),
      nature: BusinessClassNameEnum.convertToApiValue.getValue(data.nature),
      paternChrono: data.nomenclature,
      startValue: data.startValue,
    };
  }

  export function getErrorKey(error: ErrorResponseData): DialogToasterData {
    const subtitle =
      (error.errorKey === invalidNomenclatureErrorKey && 'referenceTranslateKey.modals.toasters.nomenclatureInvalid') ||
      (error.errorKey === existingReferenceErrorKey && 'referenceTranslateKey.modals.toasters.refExist');

    return error.status === ApiErrorResponsesEnum.Conflict
      ? { title: 'referenceTranslateKey.modals.toasters.saveErrorTitle', subtitle: subtitle || '' }
      : { title: 'referenceTranslateKey.modals.toasters.createFail' };
  }
}
