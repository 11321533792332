import { Action, createReducer, on } from '@ngrx/store';
import { AppConfigLightData, ApplicationFileTmpData, ConflictUploadElements, ErrorResponseData, LazyLoadResponse, NavMenu, UserData, UserQrCodeData } from '../../../core/models';
import { ConflictData } from '../../shared/interfaces/conflict-data';
import { AuthMethodEnum } from '../../../core/enums';
import { AccessHistoryItem } from '../../shared/interfaces/access-history-item';
import * as fromMainActions from './main.actions';
import { OrganizationConfigDetails } from '../../../core/models/organization-config/organization-config-details';

export interface MainState {
  currentLanguage: string;
  sequenceViewIsActive: boolean;
  menuItems: NavMenu;
  menuTitle: string;
  menuSubtitle: string;
  menuTitleIconClass: string;
  userData: UserData;
  error: ErrorResponseData;
  configLight: AppConfigLightData;
  updateProfileLoading: boolean;
  deleteEntityError: ConflictData;
  elementUploadEntityError: ConflictUploadElements[];
  authMethod: AuthMethodEnum;
  quickConnection: QuickConnectionDialogState;
  entityAccessHistoryData: AccessHistoryItem[];
  appVersion: string;
  cdnWebappUrl: string;
  organizationConfig: OrganizationConfigDetails;
  applicationFileTmpData: LazyLoadResponse<ApplicationFileTmpData[]>;
  isLoading?: boolean;
  unreadEvolutions?: number;
}

export interface QuickConnectionDialogState {
  qrCodeData: UserQrCodeData;
  unlinkUserLoading: boolean;
}

export const initialMainState: MainState = {
  currentLanguage: 'en',
  sequenceViewIsActive: false,
  menuItems: null,
  menuTitle: null,
  menuSubtitle: null,
  menuTitleIconClass: null,
  userData: null,
  error: null,
  configLight: null,
  updateProfileLoading: false,
  deleteEntityError: undefined,
  elementUploadEntityError: [],
  authMethod: null,
  quickConnection: null,
  entityAccessHistoryData: [],
  appVersion: null,
  cdnWebappUrl: null,
  applicationFileTmpData: undefined,
  isLoading: false,
  organizationConfig: null,
};

const mainReducer = createReducer<MainState>(
  initialMainState,
  on(fromMainActions.setCurrentLanguage, (state, { language }): MainState => ({ ...state, currentLanguage: language })),
  on(fromMainActions.setMenu, (state, { menu }): MainState => ({ ...state, menuItems: menu })),
  on(fromMainActions.setMenuTitle, (state, { title }): MainState => ({ ...state, menuTitle: title })),
  on(fromMainActions.setMenuSubtitle, (state, { subtitle }): MainState => ({ ...state, menuSubtitle: subtitle })),
  on(fromMainActions.setMenuIconClass, (state, { iconClass }): MainState => ({ ...state, menuTitleIconClass: iconClass })),
  on(fromMainActions.getUserAccountSuccess, (state, { userInfo }): MainState => ({ ...state, userData: userInfo })),
  on(fromMainActions.updatePgacVisibility, (state, { isVisible }): MainState => ({ ...state, configLight: { ...state.configLight, isModulePgacActive: isVisible } })),
  on(
    fromMainActions.updateFileProcessingVisibility,
    (state, { isVisible }): MainState => ({ ...state, configLight: { ...state.configLight, isModuleFileProcessingActive: isVisible } }),
  ),
  on(fromMainActions.updateSequenceViewStatus, (state, { isActive }): MainState => ({ ...state, sequenceViewIsActive: isActive })),
  on(fromMainActions.getUserAccountFail, (state, { error }): MainState => ({ ...state, error })),
  on(fromMainActions.updateUserAccount, (state): MainState => ({ ...state, updateProfileLoading: true })),
  on(fromMainActions.updateUserAccountSuccess, (state, { userAccount }): MainState => ({ ...state, userData: userAccount, updateProfileLoading: false })),
  on(fromMainActions.updateUserAccountFail, (state, { error }): MainState => ({ ...state, error, updateProfileLoading: false })),
  on(fromMainActions.getAppConfigSuccess, (state, { appConfig }): MainState => ({ ...state, configLight: appConfig })),
  on(fromMainActions.getAppConfigFail, (state, { error }): MainState => ({ ...state, error })),
  on(fromMainActions.setAuthMethod, (state, { authMethod }): MainState => ({ ...state, authMethod })),
  on(fromMainActions.loadOrganizationConfigSuccess, (state, { config }): MainState => ({ ...state, organizationConfig: config })),

  on(fromMainActions.login, (state, _): MainState => ({ ...state, error: null, isLoading: true })),
  on(fromMainActions.loginSuccess, (state, _): MainState => ({ ...state, isLoading: false })),
  on(fromMainActions.loginFail, (state, { error }): MainState => ({ ...state, error, isLoading: false })),

  on(fromMainActions.deleteEntityFail, (state, { error }): MainState => ({ ...state, deleteEntityError: error })),
  on(fromMainActions.uploadElementsEntityFail, (state, { error }): MainState => ({ ...state, elementUploadEntityError: error })),
  on(fromMainActions.loadQrCodeDataSuccess, (state, qrCodeData): MainState => ({ ...state, quickConnection: { ...state.quickConnection, qrCodeData } })),
  on(fromMainActions.unlinkUser, (state): MainState => ({ ...state, quickConnection: { ...state.quickConnection, unlinkUserLoading: true } })),
  on(
    fromMainActions.unlinkUserSuccess,
    (state): MainState => ({
      ...state,
      quickConnection: { ...state.quickConnection, qrCodeData: { ...state.quickConnection.qrCodeData, uniqueDeviceId: null }, unlinkUserLoading: false },
    }),
  ),
  on(fromMainActions.unlinkUserFail, (state): MainState => ({ ...state, quickConnection: { ...state.quickConnection, unlinkUserLoading: false } })),
  on(fromMainActions.resetQuickConnection, (state): MainState => ({ ...state, quickConnection: null })),
  on(fromMainActions.loadAccessHistoryData, (state, { items }): MainState => ({ ...state, entityAccessHistoryData: items })),
  on(
    fromMainActions.removeAccessHistoryItem,
    (state, { item }): MainState => ({
      ...state,
      entityAccessHistoryData: state.entityAccessHistoryData.filter(_item => _item.id !== item.id),
    }),
  ),
  on(
    fromMainActions.removeAccessHistoryItemByUuidEntity,
    (state, { uuidEntity }): MainState => ({
      ...state,
      entityAccessHistoryData: state.entityAccessHistoryData.filter(_item => _item.uuidEntity !== uuidEntity),
    }),
  ),
  on(
    fromMainActions.setAccessHistoryItem,
    (state, { item }): MainState => {
      if (state.entityAccessHistoryData.length === 6) {
        state.entityAccessHistoryData.pop();
      }
      const historyItemAlreadyExist = !!state.entityAccessHistoryData.find(_item => _item.id === item.id);

      return historyItemAlreadyExist
        ? {
            ...state,
            entityAccessHistoryData: state.entityAccessHistoryData.map(_item => (_item.id === item.id ? { ...item } : _item)),
          }
        : { ...state, entityAccessHistoryData: [item, ...state.entityAccessHistoryData] };
    },
  ),
  on(fromMainActions.setAppVersion, (state, { appVersion }): MainState => ({ ...state, appVersion })),
  on(fromMainActions.setCdnWebappUrl, (state, { cdnWebappUrl }): MainState => ({ ...state, cdnWebappUrl })),
  on(
    fromMainActions.loadApplicationFileTmpSuccess,
    (state, { response, reset }): MainState => ({
      ...state,
      applicationFileTmpData: reset ? response : { ...state.applicationFileTmpData, payload: [...state.applicationFileTmpData.payload, ...response.payload] },
    }),
  ),
);

export function mainAppReducer(state: MainState | undefined, action: Action): MainState {
  return mainReducer(state, action);
}
