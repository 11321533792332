import { sortBy } from 'lodash';

import { lastOneUuidEntity } from '../../../modules/shared/interfaces/select-item';
import { PhaseDetailsResponseDTO, PhaseDuplicateRequestDTO } from '../../generated/models/models';
import { PhaseSummaryData } from './phase-summary-data';

export interface PhaseToDuplicateData {
  phaseDestination: PhaseSummaryData;
}

export namespace PhaseToDuplicateData {
  export function mapToRequestApiValue(phaseUuidEntity: string, projectUuidEntity: string, phase: PhaseToDuplicateData): PhaseDuplicateRequestDTO {
    return {
      uuidePhaseDestination: phase.phaseDestination.uuidEntity,
      uuideProject: projectUuidEntity,
      uuide: phaseUuidEntity,
      lastPosition: phase.phaseDestination.uuidEntity === lastOneUuidEntity,
    };
  }

  export function mapToDuplicatedPhase(phase: PhaseDetailsResponseDTO): { newPhaseUuidEntity: string; firstStepUuidEntity?: string } {
    const firstStep = sortBy(phase.steps || [], 'nodeOrder')[0];

    return { newPhaseUuidEntity: phase.uuidEntity, firstStepUuidEntity: firstStep && firstStep.uuidEntity };
  }
}
