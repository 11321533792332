import { Pipe, PipeTransform } from '@angular/core';
import { findIndex, get, isObject } from 'lodash';

/**
 * A pipe to check if an array contains a value
 * Example: <div *ngIf="valuesArray | contains:value">
 * @param array Array to search the item in
 * @param item The item to search, can be an object or a string
 * @param comparisonPath The path of the property to compare (optionnal)
 *
 */
@Pipe({
  name: 'contains',
})
export class ContainsPipe implements PipeTransform {
  transform<T>(array: T[], item: T, comparisonPath?: string): boolean {
    // Search object
    if (isObject(item)) {
      return findIndex(array, item) > -1;
    } else {
      // Search string
      if (comparisonPath) {
        // Search by nested property
        return findIndex(array, arrayItem => get(arrayItem, comparisonPath) === item) > -1;
      }

      return array && array.includes(item);
    }
  }
}
