import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from '../input/input.component';
import { SfxPipesModule } from '../../pipes/pipes.modules';
import { SfxTooltipDirective } from '../../directives/tooltip/sfx-tooltip.directive';
import { FocusDirective } from '../../directives/focus-element.directive';

@Component({
  selector: 'sfx-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, InputComponent, SfxPipesModule, SfxTooltipDirective, FocusDirective],
})
export class LabelComponent {
  @Input() text: string;
  @Input() searchText: string;
  @Input() innerTooltipCss: string;
  @Input() innerTooltipPosition: string;
  @Input() showTooltip = true;
  @Input() tooltipInnerTag = false;
  @Input() editModeEnabled = false;
  @Input() sfxClass: string; // INFO: already we implement only a custom classes <nb-line-${number from 1 to 50}> to fix the nbLine for ellipsis text.
  @Output() textChange = new EventEmitter<string>();

  public toggleEditMode = false;
  public textValue: string;
}
