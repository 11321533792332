import { BlockStatusEnum, BlockTypeEnum } from '../../enums';
import { BlocLightDTO } from '../../generated/models/models';

export interface BlockLightData {
  uuidEntity: string;
  disabled: boolean;
  type: BlockTypeEnum;
  status: BlockStatusEnum;
}

export namespace BlockLightData {
  export function convertFromApiValue(data: BlocLightDTO): Partial<BlockLightData> {
    return {
      type: data.natureBloc && BlockTypeEnum.convertFromApiValue.getValue(data.natureBloc),
      status: data.statusBloc && BlockStatusEnum.convertFromApiValue.getValue(data.statusBloc),
    };
  }
}
