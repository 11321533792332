import { BlocEmmUpdateRequestDTO } from '../../generated/models/models';
import { EmmElementSummaryData } from './block-equipment-summary';

export interface BlockEquipmentForUpdate {
  uuidEntity: string;
  consumable: boolean;
  comment: string;
  quantity: number;
  unit: string;
  displayComment: boolean;
}

export namespace BlockEquipmentForUpdate {
  export function mapToUpdateRequest(equipment: BlockEquipmentForUpdate | EmmElementSummaryData): BlocEmmUpdateRequestDTO & BlockEquipmentForUpdate {
    return {
      uuidEntity: equipment.uuidEntity,
      consumable: equipment.consumable,
      comment: equipment.comment,
      quantity: equipment.quantity,
      unit: equipment.unit,
      displayComment: equipment.displayComment,
    };
  }
}
