import { SfxMap } from '../../utils/enum-utils';

export enum ClosingFolderLevelsEnum {
  Folder = 1,
  Section = 2,
  Document = 3,
  EmmPdf = 4,
}

export namespace ClosingFolderLevelsEnum {
  const key = 'closingFolder.table.tooltip.';
  export const cannotExpandTooltip: SfxMap<ClosingFolderLevelsEnum, string> = new SfxMap<ClosingFolderLevelsEnum, string>([
    [ClosingFolderLevelsEnum.Folder, key + 'folder.cannotExpand'],
  ]);

  export const expandTooltip: SfxMap<ClosingFolderLevelsEnum, string> = new SfxMap<ClosingFolderLevelsEnum, string>([
    [ClosingFolderLevelsEnum.Folder, key + 'folder.expand'],
    [ClosingFolderLevelsEnum.Section, key + 'section.expand'],
  ]);

  export const reduceTooltip: SfxMap<ClosingFolderLevelsEnum, string> = new SfxMap<ClosingFolderLevelsEnum, string>([
    [ClosingFolderLevelsEnum.Folder, key + 'folder.reduce'],
    [ClosingFolderLevelsEnum.Section, key + 'section.reduce'],
  ]);

  export const count: SfxMap<ClosingFolderLevelsEnum, string> = new SfxMap<ClosingFolderLevelsEnum, string>([
    [ClosingFolderLevelsEnum.Folder, key + 'folder.count'],
    [ClosingFolderLevelsEnum.Section, key + 'section.count'],
  ]);
}
