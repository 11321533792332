import { Dictionary } from 'lodash';
import { createReducer, Action, on } from '@ngrx/store';
import { FormQuestionRecap, FormSummaryDetails, MapConfigData } from '../../../../core/models';

import * as fromActions from './form-summary-dialog.actions';

export interface FormSummaryDialogState {
  loading: boolean;
  loadingRubrics: string[];
  formData: FormSummaryDetails;
  questions: Dictionary<FormQuestionRecap[]>;
  mapConfig: MapConfigData;
}

export const initialFormSummaryDialogState: FormSummaryDialogState = {
  loading: false,
  loadingRubrics: [],
  formData: {} as FormSummaryDetails,
  questions: {},
  mapConfig: undefined,
};

const _formSummaryDialogReducer = createReducer<FormSummaryDialogState>(
  initialFormSummaryDialogState,
  on(fromActions.loadFormRubrics, (state): FormSummaryDialogState => ({ ...state, loading: true })),
  on(fromActions.loadFormRubricsSuccess, (state, { formData }): FormSummaryDialogState => ({ ...state, formData, loading: false })),
  on(fromActions.loadFormRubricsFail, (state): FormSummaryDialogState => ({ ...state, loading: false })),
  on(fromActions.loadRubricQuestions, (state, { rubricUuidEntity }): FormSummaryDialogState => ({ ...state, loadingRubrics: [...state.loadingRubrics, rubricUuidEntity] })),
  on(
    fromActions.loadRubricQuestionsSuccess,
    (state, { rubricUuidEntity, questions }): FormSummaryDialogState => ({
      ...state,
      loadingRubrics: state.loadingRubrics.filter(uuid => uuid !== rubricUuidEntity),
      questions: { ...state.questions, [rubricUuidEntity]: questions },
    }),
  ),
  on(fromActions.loadMapConfigSuccess, (state, { mapConfigData }): FormSummaryDialogState => ({ ...state, mapConfig: mapConfigData })),
);

export function formSummaryDialogReducer(state: FormSummaryDialogState | undefined, action: Action): FormSummaryDialogState {
  return _formSummaryDialogReducer(state, action);
}
