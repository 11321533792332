import { CategorySummaryData } from '../../models/category/category-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum CategoriesColumnEnum {
  Name = 'name',
  Actions = 'action',
}

export namespace CategoriesColumnEnum {
  export type categoriesSortField = keyof CategorySummaryData;

  export const allColumns = [CategoriesColumnEnum.Name, CategoriesColumnEnum.Actions];

  const key = 'categories.table.header.';
  export const toString: SfxMap<CategoriesColumnEnum, string> = new SfxMap<CategoriesColumnEnum, string>([
    [CategoriesColumnEnum.Name, key + 'name'],
    [CategoriesColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<CategoriesColumnEnum, string> = new SfxMap<CategoriesColumnEnum, string>([[CategoriesColumnEnum.Name, 'name']]);
}
