import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import {
  CheckExistedReferenceRequest,
  ErrorResponseData,
  FormLibrarySummaryData,
  FormTemplateParamsData,
  FormReorganizeData,
  FormLibraryForDuplicate,
  FormLibraryDetailsData,
  LazyLoadResponse,
  ApplicationEventLightData,
} from '../../../../../core/models';

// Form dialog actions
export const addForm = createAction('[lFormDialog] add new form', props<{ form: FormLibraryDetailsData }>());
export const addFormSuccess = createAction('[lFormDialog] add new form success', props<{ formDetails: FormLibraryDetailsData }>());
export const addFormFail = createAction('[lFormDialog] add new form fail', props<DialogToasterData>());

export const updateLocalForm = createAction('[lFormDialog] update local form', props<{ form: FormLibraryDetailsData; closeModal?: boolean }>());
export const updateForm = createAction('[lFormDialog] update form', props<{ form: FormLibraryDetailsData; closeModal?: boolean }>());
export const updateFormSuccess = createAction('[lFormDialog] update form success', props<{ formDetails: FormLibraryDetailsData; closeModal?: boolean }>());
export const updateFormFail = createAction('[lFormDialog] update form fail', props<DialogToasterData>());

export const toggleFormTemplateParams = createAction('[lFormDialog] toggle form template params', props<{ useTemplate: boolean }>());
export const updateFormTemplateParams = createAction(
  '[lFormDialog] update form template params',
  props<{ formUuidEntity: string; formTemplateParams: FormTemplateParamsData; closeModal?: boolean }>(),
);
export const updateLocalFormTemplateParams = createAction(
  '[lFormDialog] update local form template params',
  props<{ formUuidEntity: string; formTemplateParams: FormTemplateParamsData; closeModal?: boolean }>(),
);

export const getFormDetailsSuccess = createAction('[lFormDialog] get form details success', props<{ formDetails: FormLibraryDetailsData }>());
export const getFormDetailsFail = createAction('[lFormDialog] get form details fail', props<DialogToasterData>());

export const initializeFormDetails = createAction('[lFormDialog] initialize form details', props<{ formDetails: FormLibraryDetailsData }>());

export const saveChangesLocalFormDialog = createAction('[lFormDialog] save changes for local form', props<{ form: FormLibraryDetailsData }>());
export const saveChangesFormDialog = createAction('[lFormDialog] save changes form dialog', props<{ form: FormLibraryDetailsData; local?: boolean }>());
export const saveFormDetailsFormDialog = createAction('[lFormDialog] save form details data dialog', props<{ form: FormLibraryDetailsData }>());

export const navigateThroughForms = createAction('[lFormDialog] navigate through forms', props<{ uuidEntity: string; step: number; formDetails: FormLibraryDetailsData }>());
export const navigateThroughFormsSuccess = createAction('[lFormDialog] navigate through forms success', props<{ uuidEntity: string; step: number }>());

export const checkReferenceGenerated = createAction('[lFormDialog] check reference generated', props<CheckExistedReferenceRequest>());
export const checkReferenceGeneratedSuccess = createAction('[lFormDialog] check reference generated success', props<{ hasReferenceGenerated: boolean; reset: boolean }>());
export const checkReferenceGeneratedFail = createAction('[lFormDialog] check reference generated fail', props<DialogToasterData>());

// Form duplicate dialog actions
export const getDuplicatedFormFail = createAction('[lFormDialog] get duplicated form fail', props<DialogToasterData>());
export const getDuplicatedFormSuccess = createAction('[lFormDialog] get duplicated form success', props<{ duplicatedForm: FormLibraryForDuplicate }>());

export const duplicateForm = createAction('[lFormDialog] duplicate form', props<{ duplicatedForm: FormLibraryForDuplicate }>());
export const duplicateFormFail = createAction('[lFormDialog] duplicate form fail', props<DialogToasterData>());
export const duplicateFormSuccess = createAction('[lFormDialog] duplicate form success');

export const reorganizeForms = createAction('[lFormDialog] reorganize forms', props<{ formReorganize: FormReorganizeData; count: number }>());
export const reorganizeFormsFail = createAction('[lFormDialog] reorganize forms fail', props<DialogToasterData>());
export const reorganizeFormsSuccess = createAction('[lFormDialog] reorganize forms success');

export const loadFormReorganizeList = createAction('[lFormDialog] load form reorganize list');
export const loadNextFormReorganizePage = createAction('[lFormDialog] load next form reorganize page ');
export const loadFormReorganizeListSuccess = createAction(
  '[lFormDialog] load form reorganize list success',
  props<{ forms: FormLibrarySummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadFormReorganizeListFail = createAction('[lFormDialog] load form reorganize list fail', props<{ error: ErrorResponseData }>());

export const loadHistoryListSuccess = createAction('[lFormDialog] Load history list success', props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>());
export const loadHistoryListNextPage = createAction('[lFormDialog] Load history list next page');
export const loadHistoryListFails = createAction('[lFormDialog] Load history list fails', props<DialogToasterData>());
