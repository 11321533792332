import { Pipe, PipeTransform } from '@angular/core';

export type searchStrategyType = 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'advanced';
const bannedHtmlTagsFromHighlight = ['</strong>', '<br>', '<br/>', '</a>'];

@Pipe({ name: 'highlightText' })
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, filter: string, searchStrategy: searchStrategyType = 'contains', cssClass: string = 'highlight-text'): string {
    value = (value || '') + '';
    const highlightedValue = bannedHtmlTagsFromHighlight.some(tag => value.includes(tag))
      ? value
      : value.replace(new RegExp('<', 'gmi'), '&#60;').replace(new RegExp('>', 'gmi'), '&#62;');

    if (!filter || !highlightedValue) {
      return highlightedValue;
    }
    const filterValue = filter
      .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      .replace(/</gim, '&#60;')
      .replace(/>/gim, '&#62;')
      .replace(/([aàâä])/gi, '[aàâä]')
      .replace(/([eèéêë])/gi, '[eèéêë]')
      .replace(/([iìîï])/gi, '[iìîï]')
      .replace(/([oòÔö])/gi, '[oòÔö]')
      .replace(/([uùûü])/gi, '[uùûü]')
      .replace(/([yÿ])/gi, '[yÿ]')
      .replace(/([cç])/gi, '[cç]');

    const advancedFilterValue = filter
      .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      .replace(/</gim, '&#60;')
      .replace(/>/gim, '&#62;');

    switch (searchStrategy) {
      case undefined:
      case 'contains':
        return highlightedValue.replace(new RegExp(`${filterValue}`, 'gmi'), match => `<span class="${cssClass}">${match}</span>`);

      case 'startsWith':
        return highlightedValue.replace(new RegExp(`^${filterValue}`, 'gi'), match => `<span class="${cssClass}">${match}</span>`);

      case 'endsWith':
        return highlightedValue.replace(new RegExp(`${filterValue}\$`, 'gi'), match => `<span class="${cssClass}">${match}</span>`);

      case 'equals':
        return highlightedValue.replace(new RegExp(`^${filterValue}\$`, 'gi'), match => `<span class="${cssClass}">${match}</span>`);

      case 'advanced':
        return highlightedValue.replace(
          new RegExp(
            advancedFilterValue.endsWith('%')
              ? `${advancedFilterValue.substring(0, advancedFilterValue.length - 1).replace(/%/g, '.')}`
              : `${advancedFilterValue.replace(/%/g, '.')}`,
            'gi',
          ),
          match => {
            let i = 0;

            return match
              .split('')
              .map(part => (part.toUpperCase() === advancedFilterValue.charAt(i++).toUpperCase() ? `<span class="${cssClass}">${part}</span>` : part))
              .join('');
          },
        );

      default:
        return highlightedValue;
    }
  }
}
