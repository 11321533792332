import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { ElementEmmDetailsResponseDTO, ElementEmmCreateRequestDTO, ElementEmmUpdateRequestDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';
import { DateUtils } from '../../utils/date.utils';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';

export interface EquipmentDetailsData {
  applicability: EntityLightData;
  certificate: ApplicationFileLightData;
  comment: string;
  displayComment: boolean;
  labelFamily: LabelFamilyLightData;
  name: string;
  organization: string;
  provider: string;
  reference: string;
  referenceFile: ApplicationFileLightData;
  subjectToVerification: boolean;
  uuidEntity: string;
  validityDate: string;
  fileChanged?: boolean;
}

export namespace EquipmentDetailsData {
  export function mapFromApiValue(data: ElementEmmDetailsResponseDTO): EquipmentDetailsData {
    return {
      uuidEntity: data.uuidEntity,
      applicability: data.applicability && LabelApplicabilityLightData.mapToEntityLightData(data.applicability),
      labelFamily: data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily),
      name: data.name,
      provider: data.provider,
      reference: data.reference,
      certificate: data.certificate && ApplicationFileLightData.mapFromApiValue(data.certificate),
      comment: data.commentaire,
      displayComment: data.displayComment,
      organization: data.organisme,
      subjectToVerification: data.submittedVerification,
      validityDate: data.validityDate && DateUtils.toCalendarFormat(data.validityDate),
      referenceFile: data.referenceFile && ApplicationFileLightData.mapFromApiValue(data.referenceFile),
    };
  }

  export function mapToCreateRequestApiValue(data: EquipmentDetailsData, uuidReferenceEntity?: string): ElementEmmCreateRequestDTO {
    return {
      applicabilityUuidEntity: data.applicability && data.applicability.uuidEntity,
      commentaire: data.comment,
      displayComment: data.displayComment,
      labelFamilyUuidEntity: data.labelFamily && data.labelFamily.uuidEntity,
      name: data.name,
      provider: data.provider,
      referenceFileUuidEntity: uuidReferenceEntity ? uuidReferenceEntity : data.referenceFile && data.referenceFile.uuidEntity,
      refrence: data.reference,
    };
  }

  export function mapToUpdateRequestApiValue(data: EquipmentDetailsData, certificateFileUuidEntity?: string, referenceFileUuidEntity?: string): ElementEmmUpdateRequestDTO {
    return {
      applicabilityUuidEntity: data.applicability?.uuidEntity,
      commentaire: data.comment,
      displayComment: data.displayComment,
      labelFamilyUuidEntity: data.labelFamily?.uuidEntity,
      name: data.name,
      provider: data.provider,
      referenceFileUuidEntity: referenceFileUuidEntity || data.referenceFile?.uuidEntity,
      reference: data.reference,
      validityDate: DateUtils.toZonedDateTime(data.validityDate),
      certificateUuidEntity: certificateFileUuidEntity || data.certificate?.uuidEntity,
      organisme: data.organization,
      uuidEntity: data.uuidEntity,
    };
  }
}
