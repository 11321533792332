import { RubricQuestionDetailsResponseDTO } from '../../generated/models/rubricQuestionDetailsResponseDTO';
import { RubricQuestionUpdateRequestDTO } from '../../generated/models/rubricQuestionUpdateRequestDTO';

import { ApplicationFileLightData } from '../application-file/application-file';
import { questionOptionResponseType } from '../form-builder/question-option-data';
import { EntityLightData } from '../utils/entity-light-data';
import { RubricSummaryData } from '../rubric/rubric-summary';
import { OptionDetailsData } from '../option/option-details-data';

import { ColorPickerPaletteEnum, PhotoSizeEnum, QuestionIconEnum, QuestionTypeEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface QuestionDetailsData {
  uuidEntity: string;
  type: QuestionTypeEnum;
  name: string;
  description: string;
  color: string;
  icon: QuestionIconEnum;
  nodeOrder: number;
  mandatory: boolean;
  lockable: boolean;
  allowBackDating: boolean;
  withObservation: boolean;
  hideImagesOnBottomTable: boolean;
  unifyCells: boolean;
  observation: string;
  images: ApplicationFileLightData[];
  imagesUuidEntity: string[];
  options: OptionDetailsData[];
  photoSize: PhotoSizeEnum;
  rubric: RubricSummaryData;
  shared: boolean;
  collapsed?: boolean;
  additionalComment?: string;
  additionalObservation?: string;
  newOptionName?: string;
  hideInExportPDF?: boolean;
  lastSignatoryDate?: string;
  hasHistory?: boolean;
}

export namespace QuestionDetailsData {
  export function mapFromApiValue(question: RubricQuestionDetailsResponseDTO): QuestionDetailsData {
    const type = QuestionTypeEnum.convertFromApiValue.getValue(question.type);
    const questionOptions = type === QuestionTypeEnum.Collaborator ? question.optionSignatories : question.rubricOptions;

    return {
      uuidEntity: question.uuidEntity,
      type,
      name: question.name,
      description: question.description,
      nodeOrder: question.questionOrder,
      mandatory: question.mandatory,
      lockable: question.lockable,
      observation: question.observation,
      withObservation: question.withObservation,
      hideImagesOnBottomTable: question.hideImagesOnBottomTable,
      unifyCells: question.unifyCells,
      images: (question.applicationFiles || []).map(applicationFile => ApplicationFileLightData.mapFromApiValue(applicationFile)),
      imagesUuidEntity: (question.applicationFiles || []).map(applicationFile => applicationFile.uuidEntity),
      color: question.backgroundColor || ColorPickerPaletteEnum.withoutColor,
      icon: QuestionIconEnum.convertFromApiValue.getValue(question.iconStyle),
      options: (questionOptions || []).map((option: questionOptionResponseType) => OptionDetailsData.mapFromApiValue(option, { type } as QuestionDetailsData)),
      photoSize: PhotoSizeEnum.convertFromApiValue.getValue(question.attachementWidth),
      rubric: question.rubric && RubricSummaryData.mapFromApiValue(question.rubric),
      allowBackDating: question.allowBackDating,
      additionalComment: question.additionalComment,
      additionalObservation: question.additionalObservation,
      shared: question.shared,
      hideInExportPDF: question.hideInExportPDF,
      lastSignatoryDate: DateUtils.toCalendarFormat(question.lastSignatoryDate),
      hasHistory: !!question.versionHistoryCount,
    };
  }

  export function mapToEntityLightData(question: QuestionDetailsData): EntityLightData {
    return {
      name: question.name,
      uuidEntity: question.uuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(question: QuestionDetailsData, defaultOptionName: string): RubricQuestionUpdateRequestDTO {
    return {
      uuidEntity: question.uuidEntity,
      name: question.name,
      description: question.description,
      type: QuestionTypeEnum.convertToApiValue.getValue(question.type),
      iconStyle: QuestionIconEnum.convertToApiValue.getValue(question.icon),
      backgroundColor: question.color === ColorPickerPaletteEnum.withoutColor ? null : question.color,
      withObservation: question.withObservation,
      hideImagesOnBottomTable: question.hideImagesOnBottomTable,
      mandatory: question.mandatory,
      lockable: question.lockable,
      allowBackDating: question.allowBackDating,
      attachementWidth: PhotoSizeEnum.convertToApiValue.getValue(question.photoSize),
      additionalComment: question.additionalComment,
      additionalObservation: question.additionalObservation,
      defaultOptionName,
      shared: question.shared,
      hideInExportPDF: question.hideInExportPDF,
    } as RubricQuestionUpdateRequestDTO;
  }
}
