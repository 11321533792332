import { compact } from 'lodash';

import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';
import { ClosingFolderSummaryData } from './closing-folder-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum } from '../../enums';
import { DocumentFilterStateEnum } from '../../enums/document/document-filter-state.enum';

export interface ClosingFoldersQueryRequest extends BaseQuery<ClosingFolderSummaryData> {
  projectUuidEntity?: string;
  status?: DocumentFilterStateEnum[];
  customWording?: string[];
}

export namespace ClosingFoldersQueryRequest {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToApiValue(queries: ClosingFoldersQueryRequest): any {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      filterEtat: (queries.status && queries.status.map(etat => DocumentFilterStateEnum.convertToApiValue.getValue(etat))) || undefined,
      customWordingUuidEntities: compact(queries.customWording),
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function initialize(projectUuidEntity: string, searchText: string, customWording: string[], sort: SortFilter<ClosingFolderSummaryData>): ClosingFoldersQueryRequest {
    const parsedCustomWording = customWording.map(cw => CustomWordingStateData.convertFromParamsValue(cw));

    return {
      ...initializeQueries<ClosingFolderSummaryData>(sort),
      projectUuidEntity,
      textSearch: searchText || undefined,
      status: (parsedCustomWording || []).length ? parsedCustomWording.map(cw => cw.actionKey) : undefined,
      customWording: (parsedCustomWording || []).length ? parsedCustomWording.map(cw => cw.customWordingUuidEntity) : undefined,
    };
  }
}
