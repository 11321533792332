import { AccessApiSummaryData } from '../../models/access-api/access-api-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum AccessApiColumnEnum {
  FirstName = 'firstName',
  Actions = 'action',
}

export namespace AccessApiColumnEnum {
  export type accessApiSortField = keyof AccessApiSummaryData;

  export const allColumns = [AccessApiColumnEnum.FirstName, AccessApiColumnEnum.Actions];

  const key = 'accessApi.table.header.';
  export const toString: SfxMap<AccessApiColumnEnum, string> = new SfxMap<AccessApiColumnEnum, string>([
    [AccessApiColumnEnum.FirstName, key + 'firstName'],
    [AccessApiColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<AccessApiColumnEnum, accessApiSortField> = new SfxMap<AccessApiColumnEnum, accessApiSortField>([[AccessApiColumnEnum.FirstName, 'firstName']]);
}
