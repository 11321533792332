import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ContributorDeleteResponseData } from '../contributors/contributor-delete-response';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface FieldError {
  objectName: string;
  field: string;
  message: string;
}

export interface ErrorResponse {
  /**
   * Error type
   */
  type: string;
  /**
   * Error title
   */
  title: string;
  /**
   * Error status code
   */
  status: number;
  /**
   * Error type
   */
  path: string;
  /**
   * Error type
   */
  message: string;
  /**
   * Array of field error details
   */
  fieldErrors?: Array<FieldError>;
  /**
   * Error detail
   */
  detail?: string;
  /**
   * Parameters sent back by the api for interpolation
   */
  parameters?: { [key: string]: string };
}

export interface RequestOptions {
  params?: HttpParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}

export interface HttpParamsType<T = {}> {
  queryParams?: ISearchParams;
  body?: T;
}

export interface ISearchParams {
  [key: string]: string | string[] | number | boolean;
}

export interface LazyLoadResponse<T> {
  payload: T;
  totalCount?: number;
  filteredTotalCount?: number;
  deepFilteredTotalCount?: number;
  disabledFilteredTotalCount?: number;
}

/**
 * Do not save this interface in the state ! This is not possible
 * because getLinksConflict is not serializable
 */
export interface ErrorResponseData<T = string> {
  status: number;
  errorKey: string;
  name: string;
  title: string;
  message: T;
  details?: string;
  path?: string;
  fieldErrors?: string[];
  usersAttachedToCompany?: { nameToDisplay: string }[];
  formsAttachedToCompany?: { formName: string }[];
  /** @deprecated This property should no be use anymore. Please consider using getLinksConflict  */
  conflictData?: ContributorDeleteResponseData;
  /** @deprecated This property should no be use anymore. Please consider using getLinksConflict  */
  conflictNavData?: { links: string[] };
  getLinksConflict?: <U>(field: string, targets: Array<keyof U>, separator?: string) => string[];
}

export function mapErrorResponseToErrorResponseData(httpError: HttpErrorResponse): ErrorResponseData {
  return {
    status: httpError.status,
    errorKey: httpError.error?.errorKey,
    name: httpError.name,
    title: httpError.error ? httpError.error.title : 'Http Error',
    message: httpError.error ? httpError.error.message : '',
    details: httpError.error && httpError.error.detail,
    path: httpError.error && httpError.error.path,
    usersAttachedToCompany: httpError.error?.usersAttachedToCompany,
    formsAttachedToCompany: httpError.error?.formsAttachedToCompany,
    fieldErrors: httpError.error?.fieldErrors?.map(fieldError => fieldError.field),
    conflictData: { success: false, links: httpError.error?.linkedProjects?.map(data => data?.designation) },
    conflictNavData: { links: httpError.error?.linkedNavigationFlows?.map(data => data?.linkedPhase + ' - ' + data?.linkedStep) },
    getLinksConflict: <T>(target: string, properties: Array<keyof T>, separator = ' - '): string[] =>
      (((httpError.error && httpError.error[target]) || []) as T[]).map(item => properties.map(property => item[property]).join(separator)),
  };
}

export const totalCountKey = 'x-total-count';
export const filteredTotalCountKey = 'x-total-count-filtred';
export const deepFilteredTotalCountKey = 'x-total-count-deep-filtered';
export const disabledFilteredTotalCountKey = 'x-total-count-filtered-disabled';
