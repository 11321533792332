import { EpsilonOutSummaryResponseDTO, PageEpsilonOutRequest } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { DateFilterData } from '../utils/date-filter-data';
import { SortFilter } from '../utils/sort-filter';

import { EpsilonOutStateEnum, DuplicateStateEnum, InnerSortDirectionEnum, EpsilonOutRejectEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface EpsilonOutSummaryData {
  duplicate: boolean;
  duplicateComment: string;
  epsilonA: string;
  inEpsilonAe: string;
  inEpsilonAf: string;
  inEpsilonAv: string;
  inEpsilonAg: string;
  inEpsilonAi: boolean;
  inEpsilonAp: string;
  inEpsilonD: string;
  inEpsilonH: string;
  statusEpsilon: EpsilonOutStateEnum;
  uuidEntity: string;
  epsilonF: EpsilonOutRejectEnum;
}

export namespace EpsilonOutSummaryData {
  const sortFields = ['epsilonA', 'inEpsilonAf', 'inEpsilonAg', 'inEpsilonAe', 'inEpsilonAp', 'inEpsilonAi', 'inEpsilonD', 'inEpsilonH', 'statusEpsilon'];
  export interface QueryRequest extends BaseQuery<EpsilonOutSummaryData> {
    duplicate: DuplicateStateEnum;
    locals: string[];
    landmarks: string[];
    states: EpsilonOutStateEnum[];
    dateFilter?: DateFilterData;
  }

  export function mapFromApiValue(epsilonOutSummary: EpsilonOutSummaryResponseDTO): EpsilonOutSummaryData {
    return {
      duplicate: epsilonOutSummary.doublon,
      duplicateComment: epsilonOutSummary.doublonComment,
      epsilonA: epsilonOutSummary.epsilonA,
      inEpsilonAe: epsilonOutSummary.inEpsilonAe,
      inEpsilonAf: epsilonOutSummary.inEpsilonAf,
      inEpsilonAv: epsilonOutSummary.inEpsilonAv,
      inEpsilonAg: epsilonOutSummary.inEpsilonAg,
      inEpsilonAi: epsilonOutSummary.inEpsilonAi,
      inEpsilonAp: epsilonOutSummary.inEpsilonAp,
      inEpsilonD: DateUtils.toDateFormat(epsilonOutSummary.inEpsilonD),
      inEpsilonH: DateUtils.toDateFormat(epsilonOutSummary.inEpsilonH),
      statusEpsilon: EpsilonOutStateEnum.convertFromApiValue.getValue(epsilonOutSummary.statusEpsilon),
      uuidEntity: epsilonOutSummary.uuidEntity,
      epsilonF: EpsilonOutRejectEnum.convertFromApiValue.getValue(epsilonOutSummary.epsilonF),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageEpsilonOutRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
      doublon: queries.duplicate === DuplicateStateEnum.Rejected,
      locals: queries.locals || undefined,
      reperes: queries.landmarks || undefined,
      states: (queries.states && queries.states.map(state => EpsilonOutStateEnum.convertToApiValue.getValue(state))) || undefined,
    };
  }

  export function initializeQueryRequest(
    duplicate: string,
    locals: string[],
    landmarks: string[],
    states: string[],
    searchText: string,
    sort: SortFilter<EpsilonOutSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<EpsilonOutSummaryData>(sort),
      textSearch: searchText || undefined,
      locals: (locals || []).length ? locals : undefined,
      landmarks: (landmarks || []).length ? landmarks : undefined,
      states: (states || []).length ? states.map(state => EpsilonOutStateEnum.convertFromParamsValue.getValue(state)) : undefined,
      duplicate: (duplicate && DuplicateStateEnum.convertFromParamsValue.getValue(duplicate)) || DuplicateStateEnum.Active,
    };
  }

  export function mapToSelectItem(value: string): MultiSelectItemData {
    return { value, title: value };
  }
}
