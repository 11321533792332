import { LabelFamilyLightDTO, PageLabelFamilyTreeRequest } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';

import { FlowTypeEnum, InnerSortDirectionEnum, LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum, LabelFamilyTypeEnum, ListsTypeEnum } from '../../enums';
import { TypeUuidEntityEnum } from '../../enums/utils/type-uuid-entity-enum';

export interface LabelFamilyLightData {
  uuidEntity: string;
  name: string;
  fullName: string;
  color: string;
  priority: number;
  nature: LabelFamilyRelativeNatureEnum;
  parentName: string;
}

export namespace LabelFamilyLightData {
  export const missingName = TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT);
  export const defaultColor = '#5e5a5a';

  export const defaultData: LabelFamilyLightData = {
    name: missingName,
    color: defaultColor,
    fullName: null,
    priority: null,
    uuidEntity: null,
    parentName: null,
    nature: null,
  };

  export interface QueryRequest extends BaseQuery<LabelFamilyLightData> {
    projectUuidEntity?: string;
    type?: LabelFamilyTypeEnum;
    nature?: LabelFamilyNatureEnum;
    relativeNature?: LabelFamilyRelativeNatureEnum;
    onlyUsedInProject?: boolean;
    flowTypes?: FlowTypeEnum[];
  }

  export function mapFromApiValue(labelFamily: LabelFamilyLightDTO, typeFlow?: ListsTypeEnum, shortName?: boolean, hideWithout?: boolean): LabelFamilyLightData {
    return {
      name: (!shortName && labelFamily.fullName ? `${labelFamily.name} - ${labelFamily.fullName}` : labelFamily.name) || (!hideWithout && missingName),
      color: typeFlow ? ListsTypeEnum.color.getValue(typeFlow) : labelFamily.color || defaultColor,
      uuidEntity: labelFamily.uuidEntity || (!hideWithout && missingName),
      priority: labelFamily.priority,
      fullName: labelFamily.fullName,
      parentName: labelFamily.parentName,
      nature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(labelFamily.relativeNature),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageLabelFamilyTreeRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      uuideProject: queries.projectUuidEntity,
      type: LabelFamilyTypeEnum.convertToApiValue.getValue(queries.type),
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(queries.nature),
      onlyUsedInProject: queries.onlyUsedInProject,
      flowTypes: (queries.flowTypes && queries.flowTypes.map(type => FlowTypeEnum.convertToApiValue.getValue(type))) || undefined,
      relativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(queries.relativeNature),
    };
  }

  export function mapToEntityLightData(labelFamily: LabelFamilyLightDTO): EntityLightData {
    return {
      uuidEntity: labelFamily.uuidEntity,
      name: labelFamily.fullName ? `${labelFamily.name} - ${labelFamily.fullName}` : labelFamily.name,
      shortName: labelFamily.name,
      color: labelFamily.color,
      parentName: labelFamily.parentName,
    };
  }

  export function mapFromEntityLightData(labelFamily: EntityLightData): LabelFamilyLightData {
    return {
      uuidEntity: labelFamily.uuidEntity,
      name: labelFamily.name,
      fullName: labelFamily.fullName,
      color: labelFamily.color,
      parentName: labelFamily.parentName,
      priority: null,
      nature: null,
    };
  }

  export const initializeQueryRequest = (
    page: number,
    searchText?: string,
    type?: LabelFamilyTypeEnum,
    nature?: LabelFamilyNatureEnum,
    relativeNature?: LabelFamilyRelativeNatureEnum,
    projectUuidEntity?: string,
    onlyUsedInProject?: boolean,
    flowTypes?: FlowTypeEnum[],
  ): QueryRequest => ({
    ...initializeQueries<LabelFamilyLightData>({ key: 'priority' }),
    page,
    textSearch: searchText || undefined,
    type,
    nature,
    relativeNature,
    projectUuidEntity,
    onlyUsedInProject,
    flowTypes,
  });
}
