import { PageBlocFormRubricRequestDTO, RubricInitResponseDTO, RubricLightDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { InnerSortDirectionEnum, RubricNatureEnum, StringUtilsEnum } from '../../enums';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface BlockFormRubricLightData {
  uuidEntity: string;
  uuidType: string;
  name: string;
  rubricOrder: number;
}

export namespace BlockFormRubricLightData {
  export interface QueryRequest extends BaseQuery<BlockFormRubricLightData> {
    projectUuidEntity: string;
    formUuidEntity: string;
  }

  export function mapFromApiValue(rubric: RubricLightDTO | RubricInitResponseDTO): BlockFormRubricLightData {
    const nature = RubricNatureEnum.convertFromApiValue.getValue(rubric.rubricType);

    return {
      uuidEntity: rubric.uuidEntity,
      uuidType: rubric.uuidType,
      name: (nature ? RubricNatureEnum.toLetter.getValue(nature).concat(`${rubric.rubricOrder}`).concat(StringUtilsEnum.DashSeparator) : '').concat(rubric.name),
      rubricOrder: rubric.rubricOrder,
    };
  }

  export function mapToSelectItemData(rubricLight: RubricLightDTO): SingleSelectItemData<BlockFormRubricLightData> {
    const rubric = rubricLight && mapFromApiValue(rubricLight);

    return {
      value: rubric,
      title: rubric.name,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocFormRubricRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      formBusinessLinkUuidEntity: queries.formUuidEntity,
    };
  }

  export function initializeQueryRequest(page: number, searchText: string, projectUuidEntity: string, formUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockFormRubricLightData>({ key: 'rubricOrder', direction: InnerSortDirectionEnum.Desc }),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
      formUuidEntity,
    };
  }
}
