import { SfxMap } from '../../utils/enum-utils';

export const historyElementsCount = 5;

export enum InnerSortDirectionEnum {
  Asc = 1,
  Desc = 2,
}

export namespace InnerSortDirectionEnum {
  // Note: This Enum should be generated from Swagger...
  type SortDirectionType = 'ASC' | 'DESC';
  enum SortDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC',
  }

  export const invertDirection = new SfxMap<InnerSortDirectionEnum, InnerSortDirectionEnum>(
    [
      [InnerSortDirectionEnum.Asc, InnerSortDirectionEnum.Desc],
      [InnerSortDirectionEnum.Desc, null],
    ],
    InnerSortDirectionEnum.Asc,
  );

  export const convertFromApiValue = new SfxMap<SortDirectionType, InnerSortDirectionEnum>([[SortDirectionEnum.DESC, InnerSortDirectionEnum.Desc]], InnerSortDirectionEnum.Asc);

  export const convertToApiValue = new SfxMap<InnerSortDirectionEnum, SortDirectionType>([[InnerSortDirectionEnum.Desc, SortDirectionEnum.DESC]], SortDirectionEnum.ASC);

  export const convertToParamsValue = new SfxMap<InnerSortDirectionEnum, string>([
    [InnerSortDirectionEnum.Asc, 'asc'],
    [InnerSortDirectionEnum.Desc, 'desc'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, InnerSortDirectionEnum>([
    ['asc', InnerSortDirectionEnum.Asc],
    ['desc', InnerSortDirectionEnum.Desc],
  ]);
}
