import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockStyleTypeEnum {
  Info = 1,
  Waning = 2,
  Error = 3,
  Note = 4,
  Success = 5,
}

export namespace BlockStyleTypeEnum {
  export const values = [BlockStyleTypeEnum.Info, BlockStyleTypeEnum.Waning, BlockStyleTypeEnum.Error, BlockStyleTypeEnum.Note, BlockStyleTypeEnum.Success];
  export const toString: SfxMap<BlockStyleTypeEnum, string> = new SfxMap([
    [BlockStyleTypeEnum.Info, 'flowApp.flowTab.blocStyle.info'],
    [BlockStyleTypeEnum.Waning, 'flowApp.flowTab.blocStyle.warning'],
    [BlockStyleTypeEnum.Error, 'flowApp.flowTab.blocStyle.error'],
    [BlockStyleTypeEnum.Note, 'flowApp.flowTab.blocStyle.note'],
    [BlockStyleTypeEnum.Success, 'flowApp.flowTab.blocStyle.success'],
  ]);

  export const iconData: SfxMap<BlockStyleTypeEnum, IconData> = new SfxMap([
    [BlockStyleTypeEnum.Info, { name: 'fa-info-circle' }],
    [BlockStyleTypeEnum.Waning, { name: 'fa-exclamation-triangle' }],
    [BlockStyleTypeEnum.Error, { name: 'fa-times-circle' }],
    [BlockStyleTypeEnum.Note, { name: 'fa-book' }],
    [BlockStyleTypeEnum.Success, { name: 'fa-check-circle' }],
  ]);

  export const color: SfxMap<BlockStyleTypeEnum, string> = new SfxMap([
    [BlockStyleTypeEnum.Info, 'rgb(0, 82, 204)'],
    [BlockStyleTypeEnum.Waning, 'rgb(255, 153, 31)'],
    [BlockStyleTypeEnum.Error, 'rgb(222, 53, 11)'],
    [BlockStyleTypeEnum.Note, 'rgb(82, 67, 170)'],
    [BlockStyleTypeEnum.Success, 'rgb(0, 135, 90)'],
  ]);

  export const backgroundColor: SfxMap<BlockStyleTypeEnum, string> = new SfxMap([
    [BlockStyleTypeEnum.Info, 'rgb(222, 235, 255)'],
    [BlockStyleTypeEnum.Waning, 'rgb(255, 250, 230)'],
    [BlockStyleTypeEnum.Error, 'rgb(255, 235, 230)'],
    [BlockStyleTypeEnum.Note, 'rgb(234, 230, 255)'],
    [BlockStyleTypeEnum.Success, 'rgb(227, 252, 239)'],
  ]);

  export const iconUnicode: SfxMap<BlockStyleTypeEnum, string> = new SfxMap([
    [BlockStyleTypeEnum.Info, 'xf05a'],
    [BlockStyleTypeEnum.Waning, 'xf071'],
    [BlockStyleTypeEnum.Error, 'xf057'],
    [BlockStyleTypeEnum.Note, 'xf02d'],
    [BlockStyleTypeEnum.Success, 'xf058'],
  ]);
}
