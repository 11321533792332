import { Params } from '@angular/router';

export enum DialogRouteQueryParam {
  SortBy = '_sortBy',
  SortDirection = '_direction',
  TextSearch = '_textSearch',
  Format = '_format',
  TypeDate = '_typeDate',
  ShareResponse = '_shareResponse',

  Type = '_type',
  Status = '_status',
  Schedulings = '_schedulings',
  Family = '_family',
  Category = '_category',
  Category2 = '_category2',
  Client = '_client',
  Assignee = '_assignee',
  Applicability = '_applicability',
  Function = '_function',
  Legal = '_legal',
  Group = '_group',
  Roles = '_roles',
  Hierarchy = '_hierarchy',
  Skill = '_skill',
  Contract = '_contract',
  Domain = '_domain',
  Authority = '_authority',
  Role = '_role',
  ProjectRef = '_projectRef',
  SubProjectRef = '_SubProjectRef',
  RevisionRef = '_revisionRef',
  SequenceRef = '_sequenceRef',
  SequenceFlowRef = '_sequenceFlowRef',
  SequenceStepRef = '_sequenceStepRef',
  CurrentTab = '_currentTab',
  Parent = '_parent',
  Locals = '_locals',
  Landmarks = '_landmarks',
  State = '_state',
  Content = '_content',
  Provenance = '_Provenance',
  Flow = '_flow',
  TeamRole = '_teamRole',
  UserNatures = '_userNatures',
  StartDate = '_startDate',
  EndDate = '_endDate',
  FieldDate = '_fieldDate',
  DisableNavigation = '_disableNavigation',
  Link = '_link',
  Block = '_block',
  Tags = '_tags',
  CustomWording = '_customWording',
}

export namespace DialogRouteQueryParam {
  export const params = Object.values(DialogRouteQueryParam);

  export function getInitialDialogQueries(): Params {
    const initialQueries: Params = {};
    Object.keys(DialogRouteQueryParam).forEach(key => (initialQueries[DialogRouteQueryParam[key]] = undefined));

    return initialQueries;
  }
}
