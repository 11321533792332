import { SfxMap } from '../../utils/enum-utils';

export enum DefaultValuesEnum {
  AllFlow = 1,
  AllBlocks = 2,
}

export namespace DefaultValuesEnum {
  export const convertToApiValue: SfxMap<DefaultValuesEnum, string> = new SfxMap<DefaultValuesEnum, string>([
    [DefaultValuesEnum.AllFlow, 'allFlow'],
    [DefaultValuesEnum.AllBlocks, 'allBlocks'],
  ]);

  export const convertFromApiValue: SfxMap<string, DefaultValuesEnum> = new SfxMap<string, DefaultValuesEnum>([
    ['allFlow', DefaultValuesEnum.AllFlow],
    ['allBlocks', DefaultValuesEnum.AllBlocks],
  ]);
}
