import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SectionSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum SectionNatureEnum {
  Certificate = 1,
  ClientResponse = 2,
  Document = 3,
  Reference = 4,
  Report = 5,
  DocumentLargeFile = 6,
  Rfi = 7,
}

export namespace SectionNatureEnum {
  export const allTypes = [
    SectionNatureEnum.Document,
    SectionNatureEnum.Report,
    SectionNatureEnum.Certificate,
    SectionNatureEnum.Reference,
    SectionNatureEnum.ClientResponse,
    SectionNatureEnum.DocumentLargeFile,
    SectionNatureEnum.Rfi,
  ];
  export const typesHasOrderTab = [
    SectionNatureEnum.Document,
    SectionNatureEnum.Certificate,
    SectionNatureEnum.Reference,
    SectionNatureEnum.ClientResponse,
    SectionNatureEnum.DocumentLargeFile,
    SectionNatureEnum.Rfi,
  ];

  export const toString: SfxMap<SectionNatureEnum, string> = new SfxMap<SectionNatureEnum, string>([
    [SectionNatureEnum.Certificate, 'sections.certificate'],
    [SectionNatureEnum.ClientResponse, 'sections.clientResponse'],
    [SectionNatureEnum.Document, 'sections.document'],
    [SectionNatureEnum.Reference, 'sections.reference'],
    [SectionNatureEnum.Report, 'sections.report'],
    [SectionNatureEnum.DocumentLargeFile, 'sections.documentLargeFile'],
    [SectionNatureEnum.Rfi, 'sections.sectionRfi'],
  ]);

  export const isSectionEmm = [SectionNatureEnum.Reference, SectionNatureEnum.Certificate];

  const key = 'sections.longName.';
  export const getLongName: SfxMap<SectionNatureEnum, string> = new SfxMap<SectionNatureEnum, string>([
    [SectionNatureEnum.Certificate, key + 'certificate'],
    [SectionNatureEnum.ClientResponse, key + 'clientResponse'],
    [SectionNatureEnum.Document, key + 'document'],
    [SectionNatureEnum.Reference, key + 'reference'],
    [SectionNatureEnum.Report, key + 'report'],
    [SectionNatureEnum.DocumentLargeFile, key + 'documentLargeFile'],
    [SectionNatureEnum.Rfi, key + 'sectionRfi'],
  ]);

  export const iconData: SfxMap<SectionNatureEnum, IconData> = new SfxMap<SectionNatureEnum, IconData>([
    [SectionNatureEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [SectionNatureEnum.Report, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [SectionNatureEnum.Certificate, { name: 'tools', prefix: 'fas' }],
    [SectionNatureEnum.Reference, { name: 'tools', prefix: 'fas' }],
    [SectionNatureEnum.ClientResponse, { name: 'file-invoice', prefix: 'fas' }],
    [SectionNatureEnum.DocumentLargeFile, { name: 'folder', prefix: 'fas' }],
    [SectionNatureEnum.Rfi, { name: 'archive', prefix: 'fas' }],
  ]);

  export const color: SfxMap<SectionNatureEnum, string> = new SfxMap<SectionNatureEnum, string>(
    [
      [SectionNatureEnum.Certificate, '#0C7FAD'],
      [SectionNatureEnum.ClientResponse, '#0C7FAD'],
      [SectionNatureEnum.Document, '#0C7FAD'],
      [SectionNatureEnum.Reference, '#0C7FAD'],
      [SectionNatureEnum.Report, '#0C7FAD'],
      [SectionNatureEnum.DocumentLargeFile, '#0C7FAD'],
      [SectionNatureEnum.Rfi, '#0C7FAD'],
    ],
    '#CEE5EF',
  );

  export const convertFromApiValue: SfxMap<SectionSummaryResponseDTO.TypeEnum, SectionNatureEnum> = new SfxMap<SectionSummaryResponseDTO.TypeEnum, SectionNatureEnum>([
    [SectionSummaryResponseDTO.TypeEnum.CERTIFICATE, SectionNatureEnum.Certificate],
    [SectionSummaryResponseDTO.TypeEnum.CLIENTRESPONSE, SectionNatureEnum.ClientResponse],
    [SectionSummaryResponseDTO.TypeEnum.DOCUMENT, SectionNatureEnum.Document],
    [SectionSummaryResponseDTO.TypeEnum.REFERENCE, SectionNatureEnum.Reference],
    [SectionSummaryResponseDTO.TypeEnum.REPORT, SectionNatureEnum.Report],
    [SectionSummaryResponseDTO.TypeEnum.DOCUMENTLARGEFILE, SectionNatureEnum.DocumentLargeFile],
    [SectionSummaryResponseDTO.TypeEnum.RFI, SectionNatureEnum.Rfi],
  ]);

  export const convertToApiValue: SfxMap<SectionNatureEnum, SectionSummaryResponseDTO.TypeEnum> = new SfxMap<SectionNatureEnum, SectionSummaryResponseDTO.TypeEnum>([
    [SectionNatureEnum.Certificate, SectionSummaryResponseDTO.TypeEnum.CERTIFICATE],
    [SectionNatureEnum.ClientResponse, SectionSummaryResponseDTO.TypeEnum.CLIENTRESPONSE],
    [SectionNatureEnum.Document, SectionSummaryResponseDTO.TypeEnum.DOCUMENT],
    [SectionNatureEnum.Reference, SectionSummaryResponseDTO.TypeEnum.REFERENCE],
    [SectionNatureEnum.Report, SectionSummaryResponseDTO.TypeEnum.REPORT],
    [SectionNatureEnum.DocumentLargeFile, SectionSummaryResponseDTO.TypeEnum.DOCUMENTLARGEFILE],
    [SectionNatureEnum.Rfi, SectionSummaryResponseDTO.TypeEnum.RFI],
  ]);

  export const allSingleTypeToSelectItems = (): Observable<SingleSelectItemData<SectionNatureEnum>[]> =>
    of(
      allTypes.map(value => ({
        value,
        title: SectionNatureEnum.getLongName.getValue(value),
        icons: [iconData.getValue(value)],
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<SectionNatureEnum>, item: SectionNatureEnum) => node.value === item;
}
