import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { KpisResponseDTOKpiPreparationResponseDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';

import { SfxMap } from '../../utils/enum-utils';

export enum DateFrequencyEnum {
  Yearly = 'yearly',
  Monthly = 'monthly',
  TwoMonth = 'twoMonth',
  Weekly = 'weekly',
  Quarter = 'quarter',
  Daily = 'daily',
  Custom = 'custom',
}

export namespace DateFrequencyEnum {
  const values: DateFrequencyEnum[] = [DateFrequencyEnum.Yearly, DateFrequencyEnum.Monthly, DateFrequencyEnum.Weekly, DateFrequencyEnum.Custom];

  const key = 'global.dateFilter.kpis.';

  export const defaultFrequency = DateFrequencyEnum.Monthly;

  export const toString: SfxMap<DateFrequencyEnum, string> = new SfxMap<DateFrequencyEnum, string>([
    [DateFrequencyEnum.Yearly, key + 'previousYear'],
    [DateFrequencyEnum.Monthly, key + 'previousMonth'],
    [DateFrequencyEnum.Weekly, key + 'previousWeek'],
    [DateFrequencyEnum.Custom, key + 'custom'],
  ]);

  export const mapFromApiValue: SfxMap<KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum, DateFrequencyEnum> = new SfxMap<
    KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum,
    DateFrequencyEnum
  >([
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.YEARLY, DateFrequencyEnum.Yearly],
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.MONTHLY, DateFrequencyEnum.Monthly],
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.TWOMONTH, DateFrequencyEnum.TwoMonth],
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.QUARTER, DateFrequencyEnum.Quarter],
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.WEEKLY, DateFrequencyEnum.Weekly],
    [KpisResponseDTOKpiPreparationResponseDTO.IntervalEnum.DAILY, DateFrequencyEnum.Daily],
  ]);

  export const convertFromParamsValue: SfxMap<string, DateFrequencyEnum> = new SfxMap<string, DateFrequencyEnum>([
    ['yearly', DateFrequencyEnum.Yearly],
    ['monthly', DateFrequencyEnum.Monthly],
    ['weekly', DateFrequencyEnum.Weekly],
    ['custom', DateFrequencyEnum.Custom],
  ]);

  export const convertToParamsValue: SfxMap<DateFrequencyEnum, string> = new SfxMap<DateFrequencyEnum, string>([
    [DateFrequencyEnum.Yearly, 'yearly'],
    [DateFrequencyEnum.Monthly, 'monthly'],
    [DateFrequencyEnum.Weekly, 'weekly'],
    [DateFrequencyEnum.Custom, 'custom'],
  ]);

  const dateFormatKey = 'settingsKpi.dateFormat.';
  export const toDisplayFormat: SfxMap<DateFrequencyEnum, string> = new SfxMap<DateFrequencyEnum, string>(
    [
      [DateFrequencyEnum.Yearly, 'YYYY'],
      [DateFrequencyEnum.Monthly, 'MMM'],
      [DateFrequencyEnum.TwoMonth, 'MMMM YY'],
      [DateFrequencyEnum.Quarter, dateFormatKey + 'quarter'],
      [DateFrequencyEnum.Weekly, dateFormatKey + 'weeks'],
      [DateFrequencyEnum.Daily, 'ddd'],
    ],
    'DD MMMM YYYY',
  );

  export const toFormattedDate: SfxMap<DateFrequencyEnum, { startDate: string; endDate: string }> = new SfxMap<DateFrequencyEnum, { startDate: string; endDate: string }>([
    [
      DateFrequencyEnum.Yearly,
      {
        startDate: DateUtils.toCalendarFormat(dayjs().startOf('year').toDate(), true),
        endDate: DateUtils.toCalendarFormat(dayjs().endOf('year').toDate(), true),
      },
    ],
    [
      DateFrequencyEnum.Monthly,
      {
        startDate: DateUtils.toCalendarFormat(dayjs().startOf('month').toDate(), true),
        endDate: DateUtils.toCalendarFormat(dayjs().endOf('month').toDate(), true),
      },
    ],
    [
      DateFrequencyEnum.Weekly,
      {
        startDate: DateUtils.toCalendarFormat(dayjs().startOf('week').toDate(), true),
        endDate: DateUtils.toCalendarFormat(dayjs().endOf('week').toDate(), true),
      },
    ],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<DateFrequencyEnum>[]> =>
    of(
      values.map(dateType => ({
        value: dateType,
        title: toString.getValue(dateType),
        selected: dateType === DateFrequencyEnum.Yearly,
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DateFrequencyEnum>, item: DateFrequencyEnum) => node.value === item;
}
