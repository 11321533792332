import { ElementEmmSummaryResponseDTO } from '../../generated/models/elementEmmSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum DashboardListEmmColumnEnum {
  Family = 'family',
  Name = 'name',
  Reference = 'reference',
  Supplier = 'supplier',
  QuantityUnit = 'quantityUnit',
  ImportAction = 'importAction',
  ContentAction = 'contentAction',
}

export namespace DashboardListEmmColumnEnum {
  export type EmmListSortField = keyof ElementEmmSummaryResponseDTO;

  export const importColumns = [
    DashboardListEmmColumnEnum.Family,
    DashboardListEmmColumnEnum.Name,
    DashboardListEmmColumnEnum.Reference,
    DashboardListEmmColumnEnum.Supplier,
    DashboardListEmmColumnEnum.ImportAction,
  ];
  export const contentColumns = [
    DashboardListEmmColumnEnum.Family,
    DashboardListEmmColumnEnum.Name,
    DashboardListEmmColumnEnum.QuantityUnit,
    DashboardListEmmColumnEnum.Reference,
    DashboardListEmmColumnEnum.Supplier,
    DashboardListEmmColumnEnum.ContentAction,
  ];

  const key = 'lists.dialog.table.import.emm.header.';
  export const toString: SfxMap<DashboardListEmmColumnEnum, string> = new SfxMap<DashboardListEmmColumnEnum, string>([
    [DashboardListEmmColumnEnum.Family, key + 'family'],
    [DashboardListEmmColumnEnum.Name, key + 'name'],
    [DashboardListEmmColumnEnum.Reference, key + 'reference'],
    [DashboardListEmmColumnEnum.Supplier, key + 'supplier'],
    [DashboardListEmmColumnEnum.QuantityUnit, key + 'quantityUnit'],
    [DashboardListEmmColumnEnum.ImportAction, key + 'importAction'],
    [DashboardListEmmColumnEnum.ContentAction, key + 'contentAction'],
  ]);

  export const sortField: SfxMap<DashboardListEmmColumnEnum, EmmListSortField> = new SfxMap<DashboardListEmmColumnEnum, EmmListSortField>([
    [DashboardListEmmColumnEnum.Name, 'name'],
    [DashboardListEmmColumnEnum.Family, 'labelFamily'],
    [DashboardListEmmColumnEnum.Reference, 'reference'],
    [DashboardListEmmColumnEnum.Supplier, 'provider'],
  ]);
}
