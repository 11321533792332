import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, tap, catchError } from 'rxjs/operators';
import { mockRouteResponse } from './helpers';

@Injectable()
export class HttpRequestInterceptor<T> implements HttpInterceptor {
  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request);
  }
}

@Injectable()
export class MockHttpInterceptor<T> implements HttpInterceptor {
  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return of(null).pipe(
      tap(() => {
        console.info('%c *********REQUEST_DATA**********', 'color: #00ddff');
        console.info('%c MATCHED_URL: ', 'color: #00ddff', request.url);
        console.info('%c REQUEST_METHOD: ', 'color: #00ddff', request.method);
        console.info('%c REQUEST_BODY: ', 'color: #00ddff', request.body);
      }),
      mergeMap(() => mockRouteResponse(request, next)),
      tap(response => {
        console.info('%c *********REQUEST_RESPONSE**********', 'color: #65e000');
        console.info('%c RESPONSE_BODY: ', 'color: #65e000', response['body']);
        console.info('%c RESPONSE_HEADER: ', 'color: #65e000', response['headers']);
      }),
      catchError(error => {
        console.info('%c *********REQUEST_ERROR**********', 'color: #fa003a');
        console.info('%c ERROR: ', 'color: #fa003a', error);

        return of(error);
      }),
      materialize(),
      delay(300),
      dematerialize(),
    );
  }
}
