import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { CustomFileSummaryData, CustomFileDetailsData } from '../../../../../core/models';

// Custom file dialog actions
export const addCustomFile = createAction('[sCustomFileDialog] add new custom file', props<{ customFile: CustomFileDetailsData; createAnother?: boolean }>());
export const addCustomFileSuccess = createAction('[sCustomFileDialog] add new custom file success', props<{ customFileSummary: CustomFileSummaryData; createAnother?: boolean }>());
export const addCustomFileFail = createAction('[sCustomFileDialog] add new custom file fail', props<DialogToasterData>());

export const updateCustomFile = createAction('[sCustomFileDialog] update custom file', props<{ customFile: CustomFileDetailsData }>());
export const updateCustomFileSuccess = createAction('[sCustomFileDialog] update custom file success', props<{ customFileSummary: CustomFileSummaryData }>());
export const updateCustomFileFail = createAction('[sCustomFileDialog] update custom file fail', props<DialogToasterData>());

export const getCustomFileDetailsSuccess = createAction('[sCustomFileDialog] get custom file details success', props<{ customFileDetails: CustomFileDetailsData }>());
export const getCustomFileDetailsFail = createAction('[sCustomFileDialog] get custom file details fail', props<DialogToasterData>());

export const initializeCustomFileDetails = createAction('[sCustomFileDialog] initialize custom file details', props<{ customFileDetails: CustomFileDetailsData }>());

export const initializeAnotherCustomFile = createAction('[sCustomFileDialog] initialize another custom file', props<{ customFileDetails: CustomFileDetailsData }>());

export const saveCustomFileChanges = createAction('[sCustomFileDialog] save custom file changes', props<{ customFile: CustomFileDetailsData; createAnother: boolean }>());

export const askSaveBeforeNavigation = createAction('[sCustomFileDialog] Ask save before navigation', props<{ fileData: CustomFileDetailsData; step: number }>());
export const navigateToNextFile = createAction('[sCustomFileDialog] Navigate to next file details', props<{ fileUuidEntity?: string; step: number }>());
