import { ElementAdrCreateRequestDTO, ElementAdrDetailsResponseDTO, ElementAdrUpdateRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { PictogramEnum } from '../../enums';

export interface RiskDetailsData {
  applicability: EntityLightData;
  labelFamily: LabelFamilyLightData;
  name: string;
  reference: string;
  source: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  pictogram: PictogramEnum;
}

export namespace RiskDetailsData {
  export function mapFromApiValue(data: ElementAdrDetailsResponseDTO): RiskDetailsData {
    return {
      applicability: data.applicability && LabelApplicabilityLightData.mapToEntityLightData(data.applicability),
      labelFamily: data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily),
      name: data.name,
      reference: data.reference,
      source: data.source,
      uuid: data.uuid,
      uuidEntity: data.uuidEntity,
      uuidOrganization: data.uuidOrganization,
      uuidType: data.uuidType,
      pictogram: PictogramEnum.convertFromApiValue.getValue(data.pictogram),
    };
  }

  export function mapToCreateRequestApiValue(risk: RiskDetailsData): ElementAdrCreateRequestDTO {
    return {
      applicabilityUuidEntity: risk.applicability.uuidEntity,
      labelFamilyUuidEntity: risk.labelFamily.uuidEntity,
      name: risk.name,
      reference: risk.reference,
      source: risk.source,
      pictogram: PictogramEnum.convertToApiValue.getValue(risk.pictogram),
    };
  }

  export function mapToUpdateRequestApiValue(risk: RiskDetailsData): ElementAdrUpdateRequestDTO {
    return {
      applicabilityUuidEntity: risk.applicability.uuidEntity,
      labelFamilyUuidEntity: risk.labelFamily.uuidEntity,
      name: risk.name,
      reference: risk.reference,
      source: risk.source,
      uuidEntity: risk.uuidEntity,
      pictogram: PictogramEnum.convertToApiValue.getValue(risk.pictogram),
    };
  }
}
