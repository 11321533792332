import { Params } from '@angular/router';

import { RouterPaths } from '../../constant/route.constant';
import { RouteIdEnum } from '../../enums';

export enum NavItemMenuIdEnum {
  FlowManagement = 1,
  FlowList,
  Plan,
  FlowKanban,
  FlowMap,
  ProjectDocuments,
  ProjectForms,
  Advancement,
  Signature,
  FieldFeedback,
  ClosingFolder,
  Information,
  Categories,
  SignatureList,
  SignatureTemporal,
  LibraryRisks,
  LibraryEquipments,
  LibraryForms,
  LibraryDocuments,
  LibraryObjectManagement,
  LibraryTechnicalObjectTree,
  LibraryTechnicalObjectList,
  LibraryTechnicalObjectMap,
  SettingsAccount,
  SettingsClientBook,
  SettingsFamilies,
  SettingsCategories,
  SettingsCompanies,
  SettingsStatus,
  SettingsReference,
  SettingsCustomField,
  SettingsSections,
  SettingsGeneral,
  SettingsModules,
  SettingsTrams,
  SettingsBackups,
  SettingsLogsPush,
  SettingsKpis,
  ProjectTablets,
  ProjectContributors,
  ProjectReferential,
  ProjectScheduleTasks,
  ProjectLists,
  ContributorUsers,
  ContributorOperators,
  ContributorTablets,
  ProfileInformation,
  ProfileResetPassword,
  SettingsTrash,
  SettingsCustomFileManagement,
  SettingsCustomFile,
  SettingsCustomCell,
  ContributorExternalAccessAPI,
  ExternalAccessApiV1,
  ExternalAccessApiV2,
  ProfileTablet,
  LibraryTags,
  ContributorExternalUsers,
  SettingsGeneralAccount,
  SettingsGeneralOrganization,
  SettingsWordingConfiguration,
  FlowDiagram,
}

export interface NavItemMenu {
  id: NavItemMenuIdEnum;
  routeId?: RouteIdEnum;
  routeIds?: RouteIdEnum[];
  title: string;
  iconPrefix?: string;
  iconName: string;
  iconClassName?: string;
  segments: string[];
  subItems?: NavItemMenu[];
  queryParams?: Params;
  checkPermission?: boolean;
  isHidden(): boolean;
}

export interface NavGroupMenu {
  groupName?: string;
  visible?: boolean;
  checkPermission?: boolean;
  routeId?: RouteIdEnum;
  items: NavItemMenu[];
}

export interface NavMenu {
  title?: string;
  subtitle?: string;
  titleIconClass?: string;
  titleIconName: string;
  iconPrefix?: string;
  routeId: RouteIdEnum;
  groups: NavGroupMenu[];
}

export namespace NavMenu {
  export function toString(navItemId: NavItemMenuIdEnum): string {
    switch (navItemId) {
      case NavItemMenuIdEnum.FlowManagement:
        return 'appMenu.dashboard.flows.title';

      case NavItemMenuIdEnum.FlowList:
        return 'appMenu.dashboard.flows.viewList';

      case NavItemMenuIdEnum.Plan:
        return 'appMenu.dashboard.flows.viewPlan';

      case NavItemMenuIdEnum.FlowMap:
        return 'appMenu.dashboard.flows.viewMap';

      case NavItemMenuIdEnum.FlowDiagram:
        return 'appMenu.dashboard.flows.viewDiagram';

      case NavItemMenuIdEnum.FlowKanban:
        return 'appMenu.dashboard.flows.viewKanban';

      case NavItemMenuIdEnum.ProjectReferential:
        return 'appMenu.dashboard.referential.title';

      case NavItemMenuIdEnum.ProjectDocuments:
        return 'appMenu.dashboard.documents.title';

      case NavItemMenuIdEnum.ProjectForms:
        return 'appMenu.dashboard.forms.title';

      case NavItemMenuIdEnum.ProjectScheduleTasks:
        return 'appMenu.dashboard.scheduleTasks.title';

      case NavItemMenuIdEnum.ProjectLists:
        return 'appMenu.dashboard.lists.title';

      case NavItemMenuIdEnum.ProjectContributors:
        return 'appMenu.dashboard.contributors.title';

      case NavItemMenuIdEnum.ProjectTablets:
        return 'appMenu.dashboard.tablets.title';

      case NavItemMenuIdEnum.Advancement:
        return 'appMenu.dashboard.advancement.title';

      case NavItemMenuIdEnum.Signature:
        return 'appMenu.dashboard.signatures.title';

      case NavItemMenuIdEnum.SignatureList:
        return 'appMenu.dashboard.signatures.viewList';

      // REVIEW: To be Hidden on december version
      // case NavItemMenuIdEnum.SignatureTemporal:
      //   return 'appMenu.dashboard.signatures.viewTemporal';

      case NavItemMenuIdEnum.FieldFeedback:
        return 'appMenu.dashboard.fieldFeedback.title';

      case NavItemMenuIdEnum.ClosingFolder:
        return 'appMenu.dashboard.closingFolder.title';

      case NavItemMenuIdEnum.Information:
        return 'appMenu.dashboard.information.title';

      case NavItemMenuIdEnum.Categories:
        return 'appMenu.dashboard.categories.title';

      case NavItemMenuIdEnum.LibraryRisks:
        return 'appMenu.library.risks.title';

      case NavItemMenuIdEnum.LibraryEquipments:
        return 'appMenu.library.equipments.title';

      case NavItemMenuIdEnum.LibraryDocuments:
        return 'appMenu.library.documents.title';

      case NavItemMenuIdEnum.LibraryForms:
        return 'appMenu.library.forms.title';

      case NavItemMenuIdEnum.LibraryObjectManagement:
        return 'appMenu.library.objects.title';

      case NavItemMenuIdEnum.LibraryTechnicalObjectTree:
        return 'appMenu.library.objects.viewTree';

      case NavItemMenuIdEnum.LibraryTechnicalObjectList:
        return 'appMenu.library.objects.viewList';

      case NavItemMenuIdEnum.LibraryTechnicalObjectMap:
        return 'appMenu.library.objects.viewMap';

      case NavItemMenuIdEnum.SettingsAccount:
        return 'appMenu.settings.account.title';

      case NavItemMenuIdEnum.SettingsClientBook:
        return 'appMenu.settings.clientBook.title';

      case NavItemMenuIdEnum.SettingsCompanies:
        return 'appMenu.settings.companies';

      case NavItemMenuIdEnum.SettingsFamilies:
        return 'appMenu.settings.families.title';

      case NavItemMenuIdEnum.SettingsCategories:
        return 'appMenu.settings.categories.title';

      case NavItemMenuIdEnum.SettingsStatus:
        return 'appMenu.settings.statuses.title';

      case NavItemMenuIdEnum.SettingsReference:
        return 'appMenu.settings.references.title';

      case NavItemMenuIdEnum.SettingsCustomField:
        return 'appMenu.settings.attributes.title';

      case NavItemMenuIdEnum.SettingsSections:
        return 'appMenu.settings.folders.title';

      case NavItemMenuIdEnum.SettingsGeneral:
        return 'appMenu.settings.general.title';

      case NavItemMenuIdEnum.SettingsGeneralAccount:
        return 'appMenu.settings.general.account';

      case NavItemMenuIdEnum.SettingsGeneralOrganization:
        return 'appMenu.settings.general.organization';

      case NavItemMenuIdEnum.SettingsModules:
        return 'appMenu.settings.modules.title';

      case NavItemMenuIdEnum.SettingsTrams:
        return 'appMenu.settings.trams.title';

      case NavItemMenuIdEnum.SettingsWordingConfiguration:
        return 'appMenu.settings.wordingConfiguration.title';

      case NavItemMenuIdEnum.SettingsBackups:
        return 'appMenu.settings.backups.title';

      case NavItemMenuIdEnum.SettingsLogsPush:
        return 'appMenu.settings.logsPush.title';

      case NavItemMenuIdEnum.SettingsKpis:
        return 'appMenu.settings.kpis.title';

      case NavItemMenuIdEnum.SettingsTrash:
        return 'appMenu.settings.trash.title';

      case NavItemMenuIdEnum.ContributorUsers:
        return 'appMenu.contributors.users.title';

      case NavItemMenuIdEnum.ContributorOperators:
        return 'appMenu.contributors.operators.title';

      case NavItemMenuIdEnum.ContributorTablets:
        return 'appMenu.contributors.tablets.title';

      case NavItemMenuIdEnum.ProfileInformation:
        return 'appMenu.profile.information';

      case NavItemMenuIdEnum.ProfileResetPassword:
        return 'appMenu.profile.resetPassword';

      case NavItemMenuIdEnum.ProfileTablet:
        return 'appMenu.profile.tablet';

      case NavItemMenuIdEnum.SettingsCustomFile:
        return 'appMenu.settings.customFile.title';

      case NavItemMenuIdEnum.SettingsCustomCell:
        return 'appMenu.settings.customCell.title';

      case NavItemMenuIdEnum.SettingsCustomFileManagement:
        return 'appMenu.settings.customFileSettings.title';

      case NavItemMenuIdEnum.ContributorExternalAccessAPI:
        return 'appMenu.dashboard.externalApi.title';

      case NavItemMenuIdEnum.ExternalAccessApiV1:
        return 'appMenu.dashboard.externalApiV1.title';

      case NavItemMenuIdEnum.ExternalAccessApiV2:
        return 'appMenu.dashboard.externalApiV2.title';

      case NavItemMenuIdEnum.LibraryTags:
        return 'appMenu.library.tags.title';

      case NavItemMenuIdEnum.ContributorExternalUsers:
        return 'appMenu.contributors.externalUsers.title';

      default:
        return 'Messing : Go to NavMenu namespace !';
    }
  }

  export function getIconName(navItemId: NavItemMenuIdEnum): string {
    switch (navItemId) {
      case NavItemMenuIdEnum.FlowManagement:
        return 'sitemap';

      case NavItemMenuIdEnum.FlowList:
        return 'list';

      case NavItemMenuIdEnum.Plan:
        return 'map';

      case NavItemMenuIdEnum.FlowMap:
        return 'map-marked-alt';

      case NavItemMenuIdEnum.FlowDiagram:
        return 'project-diagram';

      case NavItemMenuIdEnum.FlowKanban:
        return 'columns';

      case NavItemMenuIdEnum.ProjectReferential:
        return 'pencil-ruler';

      case NavItemMenuIdEnum.LibraryDocuments:
      case NavItemMenuIdEnum.ProjectDocuments:
        return 'file-invoice';

      case NavItemMenuIdEnum.LibraryForms:
      case NavItemMenuIdEnum.ProjectForms:
        return 'list-alt';

      case NavItemMenuIdEnum.ProjectScheduleTasks:
        return 'calendar';

      case NavItemMenuIdEnum.ProjectLists:
        return 'file-alt';

      case NavItemMenuIdEnum.ProjectContributors:
        return 'user-friends';

      case NavItemMenuIdEnum.ProfileTablet:
      case NavItemMenuIdEnum.ProjectTablets:
        return 'tablet-alt';

      case NavItemMenuIdEnum.Advancement:
        return 'chart-line';

      case NavItemMenuIdEnum.Signature:
        return 'signature';

      case NavItemMenuIdEnum.SignatureList:
        return 'table';

      case NavItemMenuIdEnum.SignatureTemporal:
        return 'clock';

      case NavItemMenuIdEnum.FieldFeedback:
        return 'comments';

      case NavItemMenuIdEnum.ClosingFolder:
        return 'archive';

      case NavItemMenuIdEnum.Information:
        return 'id-card';

      case NavItemMenuIdEnum.Categories:
        return 'tag';

      case NavItemMenuIdEnum.LibraryRisks:
        return 'exclamation-triangle';

      case NavItemMenuIdEnum.LibraryEquipments:
        return 'tools';

      case NavItemMenuIdEnum.LibraryObjectManagement:
        return 'object-group';

      case NavItemMenuIdEnum.LibraryTechnicalObjectTree:
        return 'project-diagram';

      case NavItemMenuIdEnum.LibraryTechnicalObjectList:
        return 'table';

      case NavItemMenuIdEnum.LibraryTechnicalObjectMap:
        return 'map-marked-alt';

      case NavItemMenuIdEnum.SettingsAccount:
      case NavItemMenuIdEnum.SettingsGeneralAccount:
        return 'building';

      case NavItemMenuIdEnum.SettingsClientBook:
        return 'address-book';

      case NavItemMenuIdEnum.SettingsCompanies:
        return 'people-arrows';

      case NavItemMenuIdEnum.SettingsFamilies:
        return 'tag';

      case NavItemMenuIdEnum.SettingsCategories:
        return 'tags';

      case NavItemMenuIdEnum.SettingsStatus:
        return 'flag';

      case NavItemMenuIdEnum.SettingsReference:
        return 'toolbox';

      case NavItemMenuIdEnum.SettingsCustomField:
        return 'project-diagram';

      case NavItemMenuIdEnum.SettingsSections:
        return 'archive';

      case NavItemMenuIdEnum.SettingsGeneral:
        return 'sliders-h';

      case NavItemMenuIdEnum.SettingsModules:
        return 'th-large';

      case NavItemMenuIdEnum.SettingsTrams:
        return 'file-code';

      case NavItemMenuIdEnum.SettingsWordingConfiguration:
        return 'file-signature';

      case NavItemMenuIdEnum.SettingsBackups:
        return 'database';

      case NavItemMenuIdEnum.SettingsLogsPush:
        return 'database';

      case NavItemMenuIdEnum.SettingsKpis:
        return 'chart-bar';

      case NavItemMenuIdEnum.SettingsTrash:
        return 'trash-alt';

      case NavItemMenuIdEnum.ContributorUsers:
        return 'user-tie';

      case NavItemMenuIdEnum.ContributorOperators:
        return 'user-astronaut';

      case NavItemMenuIdEnum.ContributorTablets:
        return 'tablet-alt';

      case NavItemMenuIdEnum.ProfileInformation:
        return 'passport';

      case NavItemMenuIdEnum.SettingsCustomFile:
        return 'sticky-note';

      case NavItemMenuIdEnum.SettingsCustomCell:
      case NavItemMenuIdEnum.SettingsGeneralOrganization:
        return 'border-all';

      case NavItemMenuIdEnum.ProfileResetPassword:
        return 'unlock-alt';

      case NavItemMenuIdEnum.SettingsCustomFileManagement:
        return 'file-code';

      case NavItemMenuIdEnum.ContributorExternalAccessAPI:
        return 'key';

      case NavItemMenuIdEnum.LibraryTags:
        return 'barcode';

      case NavItemMenuIdEnum.ContributorExternalUsers:
        return 'user-secret';

      default:
        return 'Messing : Go to NavMenu namespace !';
    }
  }

  export function getIconPrefix(menuItem: NavItemMenuIdEnum): 'fas' | 'far' | 'fab' {
    switch (menuItem) {
      case NavItemMenuIdEnum.ProjectLists:
      case NavItemMenuIdEnum.ProjectScheduleTasks:
      case NavItemMenuIdEnum.SettingsAccount:
      case NavItemMenuIdEnum.SettingsCustomFileManagement:
      case NavItemMenuIdEnum.SettingsCustomFile:
        return 'far';

      default:
        return undefined;
    }
  }

  export function getSegments(navItemId: NavItemMenuIdEnum): string[] {
    switch (navItemId) {
      case NavItemMenuIdEnum.FlowList:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowManagementPath, RouterPaths.DashboardPaths.vueListPath];

      case NavItemMenuIdEnum.Plan:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowManagementPath, RouterPaths.DashboardPaths.planPath];

      case NavItemMenuIdEnum.FlowKanban:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowManagementPath, RouterPaths.DashboardPaths.flowKanbanPath];

      case NavItemMenuIdEnum.FlowMap:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowManagementPath, RouterPaths.DashboardPaths.flowMapPath];

      case NavItemMenuIdEnum.FlowDiagram:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowManagementPath, RouterPaths.DashboardPaths.flowDiagramPath];

      case NavItemMenuIdEnum.ProjectDocuments:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.documentsPath];

      case NavItemMenuIdEnum.ProjectReferential:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowReferentialPath];

      case NavItemMenuIdEnum.ProjectForms:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.formsPath];

      case NavItemMenuIdEnum.ProjectScheduleTasks:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.scheduleTasksPath];

      case NavItemMenuIdEnum.ProjectLists:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.listsPath];

      case NavItemMenuIdEnum.ProjectContributors:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.collaboratorsPath];

      case NavItemMenuIdEnum.ProjectTablets:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.tabletsPath];

      case NavItemMenuIdEnum.Advancement:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.metricsPath];

      case NavItemMenuIdEnum.SignatureList:
        return [
          RouterPaths.DashboardPaths.dashboardPath,
          RouterPaths.DashboardPaths.signaturesPath,
          RouterPaths.DashboardPaths.vueListPath,
          RouterPaths.DashboardPaths.signatureEmptyPath,
        ];

      case NavItemMenuIdEnum.SignatureTemporal:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.signaturesPath, RouterPaths.DashboardPaths.signatureTemporalPath];

      case NavItemMenuIdEnum.FieldFeedback:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.fieldFeedbackPath];

      case NavItemMenuIdEnum.ClosingFolder:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.closingFolderPath];

      case NavItemMenuIdEnum.Information:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.informationPath];

      case NavItemMenuIdEnum.Categories:
        return [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.categoriesPath];

      case NavItemMenuIdEnum.ContributorUsers:
        return [RouterPaths.CollaboratorPaths.usersPath];

      case NavItemMenuIdEnum.ContributorOperators:
        return [RouterPaths.CollaboratorPaths.operatorsPath];

      case NavItemMenuIdEnum.ContributorTablets:
        return [RouterPaths.CollaboratorPaths.tabletsPath];

      case NavItemMenuIdEnum.ContributorExternalAccessAPI:
        return [RouterPaths.CollaboratorPaths.externalApiPath];

      case NavItemMenuIdEnum.ExternalAccessApiV1:
        return [RouterPaths.CollaboratorPaths.externalApiPath, RouterPaths.CollaboratorPaths.externalApiV1Path];

      case NavItemMenuIdEnum.ExternalAccessApiV2:
        return [RouterPaths.CollaboratorPaths.externalApiPath, RouterPaths.CollaboratorPaths.externalApiV2Path];

      case NavItemMenuIdEnum.LibraryRisks:
        return [RouterPaths.LibraryPaths.risksPath];

      case NavItemMenuIdEnum.LibraryEquipments:
        return [RouterPaths.LibraryPaths.equipmentsPath];

      case NavItemMenuIdEnum.LibraryDocuments:
        return [RouterPaths.LibraryPaths.documentsPath];

      case NavItemMenuIdEnum.LibraryTags:
        return [RouterPaths.LibraryPaths.tagsPath];

      case NavItemMenuIdEnum.LibraryForms:
        return [RouterPaths.LibraryPaths.formsPath];

      case NavItemMenuIdEnum.LibraryTechnicalObjectTree:
        return [RouterPaths.LibraryPaths.objectManagementPath, RouterPaths.ObjectManagementPaths.treeVuePath];

      case NavItemMenuIdEnum.LibraryTechnicalObjectList:
        return [RouterPaths.LibraryPaths.objectManagementPath, RouterPaths.ObjectManagementPaths.listVuePath];

      case NavItemMenuIdEnum.LibraryTechnicalObjectMap:
        return [RouterPaths.LibraryPaths.objectManagementPath, RouterPaths.ObjectManagementPaths.mapVuePath];

      case NavItemMenuIdEnum.ProfileInformation:
        return [RouterPaths.ProfilePaths.userInfoPath];

      case NavItemMenuIdEnum.ProfileResetPassword:
        return [RouterPaths.ProfilePaths.resetPasswordPath];

      case NavItemMenuIdEnum.ProfileTablet:
        return [RouterPaths.ProfilePaths.tabletPath];

      case NavItemMenuIdEnum.SettingsAccount:
        return [RouterPaths.SettingsPaths.accountPath];

      case NavItemMenuIdEnum.SettingsClientBook:
        return [RouterPaths.SettingsPaths.clientsPath];

      case NavItemMenuIdEnum.SettingsCompanies:
        return [RouterPaths.SettingsPaths.companiesPath];

      case NavItemMenuIdEnum.SettingsFamilies:
        return [RouterPaths.SettingsPaths.familiesPath];

      case NavItemMenuIdEnum.SettingsCategories:
        return [RouterPaths.SettingsPaths.categoriesPath];

      case NavItemMenuIdEnum.SettingsStatus:
        return [RouterPaths.SettingsPaths.statusesPath];

      case NavItemMenuIdEnum.SettingsReference:
        return [RouterPaths.SettingsPaths.referencesPath];

      case NavItemMenuIdEnum.SettingsCustomField:
        return [RouterPaths.SettingsPaths.customFieldsPath];

      case NavItemMenuIdEnum.SettingsSections:
        return [RouterPaths.SettingsPaths.sectionsPath];

      case NavItemMenuIdEnum.SettingsGeneralAccount:
        return [RouterPaths.SettingsPaths.generalAccountPath];

      case NavItemMenuIdEnum.SettingsGeneralOrganization:
        return [RouterPaths.SettingsPaths.generalOrganizationPath];

      case NavItemMenuIdEnum.SettingsTrams:
        return [RouterPaths.SettingsPaths.tramsPath];

      case NavItemMenuIdEnum.SettingsWordingConfiguration:
        return [RouterPaths.SettingsPaths.wordingConfigurationPath];

      case NavItemMenuIdEnum.SettingsBackups:
        return [RouterPaths.SettingsPaths.backupsPath];

      case NavItemMenuIdEnum.SettingsLogsPush:
        return [RouterPaths.SettingsPaths.logsPushPath];

      case NavItemMenuIdEnum.SettingsKpis:
        return [RouterPaths.SettingsPaths.kpiPath];

      case NavItemMenuIdEnum.SettingsTrash:
        return [RouterPaths.SettingsPaths.trashPath];

      case NavItemMenuIdEnum.SettingsCustomFile:
        return [RouterPaths.SettingsPaths.customFilesPath];

      case NavItemMenuIdEnum.SettingsCustomCell:
        return [RouterPaths.SettingsPaths.customCellsPath];

      case NavItemMenuIdEnum.ContributorExternalUsers:
        return [RouterPaths.CollaboratorPaths.externalUsersPath];

      default:
        return ['Messing : Go to NavMenu namespace !'];
    }
  }
}
