import { StepLinkFlowRequestDTO } from '../../generated/models/models';

export interface StepToLinkFlowData {
  stepUuidEntity: string;
  flowUuidEntity: string;
  projectUuidEntity: string;
}

export namespace StepToLinkFlowData {
  export function mapToRequestApiValue(linkRequest: StepToLinkFlowData): StepLinkFlowRequestDTO {
    return {
      uuideStep: linkRequest.stepUuidEntity,
      uuideFlow: linkRequest.flowUuidEntity,
      uuideProject: linkRequest.projectUuidEntity,
    };
  }
}
