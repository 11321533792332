import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromUsersDialogActions from './users-dialog.actions';

import { LazyLoadResponse, SkillSummaryData, LibraryContributorSummaryData, SkillDetailsData, LibraryContributorDetailsData } from '../../../../../core/models';

const adapter: EntityAdapter<LibraryContributorSummaryData> = createEntityAdapter<LibraryContributorSummaryData>({
  selectId: libraryContributor => libraryContributor.uuidEntity,
});

export interface ReorganizeUsersDialogState extends EntityState<LibraryContributorSummaryData> {
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export interface EditContributorDialogState {
  saveLoading: boolean;
  userDetails: LibraryContributorDetailsData;
}

export interface UploadUsersDialogState {
  uploadLoading: boolean;
}

const initialUploadUsersDialogState = (): UploadUsersDialogState => ({
  uploadLoading: false,
});

export const reorganizeContributorsInitialState: ReorganizeUsersDialogState = adapter.getInitialState<ReorganizeUsersDialogState>({
  ids: [],
  entities: {},
  loading: false,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
});

export interface SkillsContributorDialogState {
  skillsData: LazyLoadResponse<SkillSummaryData[]>;
  skillDetailData: SkillDetailsData;
  uploadLoading: boolean;
  filesToUpload: File[];
  loadingPdfSummary: boolean;
  reset: boolean;
}

export interface UserDialogState {
  userData: EditContributorDialogState;
  skills: SkillsContributorDialogState;
  uploadUsers: UploadUsersDialogState;
  reorganizeUsers: ReorganizeUsersDialogState;
}

const initialContributorDetailsDialogState = (): EditContributorDialogState => ({
  saveLoading: false,
  userDetails: { hierarchies: [] } as LibraryContributorDetailsData,
});

const initialSkillsContributorDialogState = (): SkillsContributorDialogState => ({
  skillsData: {} as LazyLoadResponse<SkillSummaryData[]>,
  skillDetailData: {} as SkillDetailsData,
  filesToUpload: [],
  uploadLoading: false,
  loadingPdfSummary: null,
  reset: false,
});

export const initialLibraryContributorDialogState = () => ({
  userData: initialContributorDetailsDialogState(),
  skills: initialSkillsContributorDialogState(),
  uploadUsers: initialUploadUsersDialogState(),
  reorganizeUsers: reorganizeContributorsInitialState,
});

const reducer = createReducer<UserDialogState>(
  initialLibraryContributorDialogState(),
  on(fromUsersDialogActions.loadUserDetailDataSuccess, (state, { userDetails }): UserDialogState => ({ ...state, userData: { ...state.userData, userDetails } })),
  on(fromUsersDialogActions.updateUser, (state): UserDialogState => ({ ...state, userData: { ...state.userData, saveLoading: true } })),
  on(fromUsersDialogActions.updateUserFail, (state): UserDialogState => ({ ...state, userData: { ...state.userData, saveLoading: false } })),
  on(fromUsersDialogActions.updateUserSuccess, (state): UserDialogState => ({ ...state, userData: { ...state.userData, saveLoading: false } })),

  on(fromUsersDialogActions.addUser, (state): UserDialogState => ({ ...state, userData: { ...state.userData, saveLoading: true } })),
  on(fromUsersDialogActions.addUserFail, (state): UserDialogState => ({ ...state, userData: { ...state.userData, saveLoading: false } })),
  on(
    fromUsersDialogActions.addUserSuccess,
    (state, { userDetails, addNewOne }): UserDialogState => ({
      ...state,
      userData: {
        ...state.userData,
        userDetails: addNewOne
          ? ({ hierarchies: [] } as LibraryContributorDetailsData)
          : {
              ...state.userData.userDetails,
              uuidEntity: userDetails.uuidEntity,
              userGenericForm: userDetails.userGenericForm,
              userModelForm: userDetails.userModelForm,
            },
        saveLoading: false,
      },
    }),
  ),
  on(
    fromUsersDialogActions.loadSkillsUserListSuccess,
    (state, { data, reset }): UserDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        reset,
        skillsData: reset ? data : { ...state.skills.skillsData, payload: [...state.skills.skillsData.payload, ...data.payload] },
      },
    }),
  ),
  on(fromUsersDialogActions.loadSkillDetailDataSuccess, (state, skill): UserDialogState => ({ ...state, skills: { ...state.skills, skillDetailData: skill } })),
  on(fromUsersDialogActions.uploadFileSkill, (state): UserDialogState => ({ ...state, skills: { ...state.skills, uploadLoading: true } })),
  on(fromUsersDialogActions.uploadFileSkillFail, (state): UserDialogState => ({ ...state, skills: { ...state.skills, uploadLoading: false } })),
  on(
    fromUsersDialogActions.setFileToUpload,
    (state, { selectedFiles }): UserDialogState => ({ ...state, skills: { ...state.skills, filesToUpload: [...state.skills.filesToUpload, ...selectedFiles] } }),
  ),

  on(
    fromUsersDialogActions.uploadFileSkillSuccess,
    (state, { skillUuidEntity, pdfFiles }): UserDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        uploadLoading: false,
        filesToUpload: [],
        skillsData: {
          ...state.skills.skillsData,
          payload:
            state.skills.skillsData &&
            state.skills.skillsData.payload.map(skill => (skill.uuidEntity === skillUuidEntity ? { ...skill, applicationFiles: [...skill.applicationFiles, ...pdfFiles] } : skill)),
        },
      },
    }),
  ),
  on(
    fromUsersDialogActions.deletePdfFileSuccess,
    (state, { skillUuidEntity, fileUuidEntity }): UserDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        skillsData: {
          ...state.skills.skillsData,
          payload:
            state.skills.skillsData.payload &&
            state.skills.skillsData.payload.map(skill =>
              skill.uuidEntity === skillUuidEntity ? { ...skill, applicationFiles: skill.applicationFiles.filter(file => file.uuidEntity !== fileUuidEntity) } : skill,
            ),
        },
      },
    }),
  ),
  on(fromUsersDialogActions.setFileToUploadUsers, (state): UserDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: true } })),
  on(fromUsersDialogActions.setFileToUploadUsersSuccess, (state): UserDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(fromUsersDialogActions.setFileToUploadUsersFail, (state): UserDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(fromUsersDialogActions.stopImportFileLoading, (state): UserDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(fromUsersDialogActions.viewSummaryPdf, (state): UserDialogState => ({ ...state, skills: { ...state.skills, loadingPdfSummary: true } })),
  on(fromUsersDialogActions.viewSummaryPdfSuccess, (state): UserDialogState => ({ ...state, skills: { ...state.skills, loadingPdfSummary: false } })),
  on(fromUsersDialogActions.viewSummaryPdfFail, (state): UserDialogState => ({ ...state, skills: { ...state.skills, loadingPdfSummary: false } })),
  on(fromUsersDialogActions.loadReorganizeUserList, (state): UserDialogState => ({ ...state, reorganizeUsers: { ...state.reorganizeUsers, loading: true } })),
  on(
    fromUsersDialogActions.loadReorganizeUserListSuccess,
    (state, { users, totalCount, filteredTotalCount, reset }): UserDialogState => {
      const newReorganizeState: ReorganizeUsersDialogState = { ...state.reorganizeUsers, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? adapter.setAll(users, newReorganizeState) : adapter.addMany(users, newReorganizeState);

      return { ...state, reorganizeUsers: newState };
    },
  ),
);

export function usersDialogReducer(state: UserDialogState | undefined, action: Action): UserDialogState {
  return reducer(state, action);
}

export const selectAllContributors = adapter.getSelectors().selectAll;
