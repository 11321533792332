import { ApplicationEventActionEnum, ApplicationEventSourceEnum, BusinessClassNameEnum, DefaultValuesEnum, InnerSortDirectionEnum, SignatureNatureEnum } from '../../enums';
import { BlocMultiPrHistoryDetailsResponseDTO, PageBlocMultiPrRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface BlockMultiPrHistoryData {
  uuidEntity: string;
  action: ApplicationEventActionEnum;
  userName: string;
  signatureNature: SignatureNatureEnum;
  actionSource: ApplicationEventSourceEnum;
  actionDate: string;
  actionTime: string;
  entityName: string;
  entityColor: string;
  previousItemName: string;
  previousItemColor: string;
  eventSource: string;
  comment: string;
  businessClass: BusinessClassNameEnum;
  elementOrder: number;
}
export namespace BlockMultiPrHistoryData {
  export interface QueryRequest extends BaseQuery<BlockMultiPrHistoryData> {
    blockUuidEntity: string;
    flowUuidEntity: string;
    stepUuidEntity: string;
  }
  export function mapFromApiValue(blockMultiPrHistory: BlocMultiPrHistoryDetailsResponseDTO): BlockMultiPrHistoryData {
    return {
      uuidEntity: blockMultiPrHistory.uuidEntity,
      action: ApplicationEventActionEnum.convertFromApiValue.getValue(blockMultiPrHistory.actionNature),
      userName: blockMultiPrHistory.userLibelle,
      actionDate: blockMultiPrHistory.actionDate && DateUtils.toDateFormat(blockMultiPrHistory.actionDate),
      actionTime: blockMultiPrHistory.actionDate && DateUtils.toTimeFormat(blockMultiPrHistory.actionDate),
      signatureNature: SignatureNatureEnum.convertFromApiValue.getValue(blockMultiPrHistory.signatureNature),
      actionSource: ApplicationEventSourceEnum.convertFromApiValue.getValue(blockMultiPrHistory.eventSource),
      entityName: blockMultiPrHistory.entityName,
      entityColor: blockMultiPrHistory.entityColor,
      previousItemName: blockMultiPrHistory.previousItemName,
      previousItemColor: blockMultiPrHistory.previousItemColor,
      eventSource: blockMultiPrHistory.eventSource,
      comment: blockMultiPrHistory.comment,
      businessClass: BusinessClassNameEnum.convertFromApiValue.getValue(blockMultiPrHistory.businessClassName),
      elementOrder: blockMultiPrHistory.elementOrder,
    };
  }

  export function initializeQueryRequest(blockUuidEntity: string, flowUuidEntity: string, stepUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockMultiPrHistoryData>(),
      blockUuidEntity,
      flowUuidEntity,
      stepUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocMultiPrRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      blocUuidEntity:
        queries.blockUuidEntity && queries.blockUuidEntity !== DefaultValuesEnum.convertToApiValue.getValue(DefaultValuesEnum.AllBlocks) ? queries.blockUuidEntity : undefined,
      flowUuidEntity: queries.flowUuidEntity,
      stepUuidEntity: queries.stepUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }
}
