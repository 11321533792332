import { ElementEmmToImportSummaryResponseDTO, PageImportElementEmmRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum } from '../../enums';

export interface BlockEquipmentToImportSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  name: string;
  quantity: number;
  unit: string;
  reference: string;
  consumable: boolean;
  frozen: boolean;
  provider: string;
  isExpired: boolean;
  verification: boolean;
}

export namespace BlockEquipmentToImportSummaryData {
  const sortFields = ['family', 'name', 'quantity', 'reference', 'provider'];
  export interface QueryRequest extends BaseQuery<BlockEquipmentToImportSummaryData> {
    applicabilities?: string[];
    families?: string[];
    blockUuidEntity?: string;
  }

  export function mapFromApiValue(emmElement: ElementEmmToImportSummaryResponseDTO): BlockEquipmentToImportSummaryData {
    return {
      uuidEntity: emmElement.uuidEntity,
      name: emmElement.name,
      family: emmElement.labelFamily && LabelFamilyLightData.mapFromApiValue(emmElement.labelFamily),
      quantity: emmElement.quantity,
      unit: emmElement.unit,
      reference: emmElement.reference,
      consumable: emmElement.consumable,
      provider: emmElement.provider,
      frozen: emmElement.alreadyImported,
      verification: emmElement.verification,
      isExpired: emmElement.expired,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageImportElementEmmRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      blocUuidEntity: queries.blockUuidEntity,
      applicabilities: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
      families: (queries.families || []).length ? queries.families : undefined,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    families: string[],
    searchText: string,
    blockUuidEntity: string,
    sort: SortFilter<BlockEquipmentToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<BlockEquipmentToImportSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      blockUuidEntity,
    };
  }
}
