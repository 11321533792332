import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { InnerSortDirectionEnum } from '../../enums';
import { TagCreateResponseDTO, TagPageRequestDTO, UpdateTagRequestDTO, CreateTagRequestDTO, TagListRequestDTO } from '../../generated/models/models';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';

export interface LibraryTagDetailsData {
  uuidEntity: string;
  name: string;
  description: string;
  color: string;
  applicability?: EntityLightData;
  parentTagUuidEntity?: string;
}

export namespace LibraryTagDetailsData {
  export interface Filters {
    textSearch?: string;
    applicability?: string;
  }

  export interface QueryRequest extends BaseQuery<LibraryTagDetailsData> {
    parentUuidEntity?: string;
    applicability?: string;
  }

  export function mapFromApiValue(data: TagCreateResponseDTO): LibraryTagDetailsData {
    return {
      uuidEntity: data.uuidEntity,
      applicability: data.applicability && LabelApplicabilityLightData.mapToEntityLightData(data.applicability),
      name: data.name,
      description: data.description,
      color: data.color,
      parentTagUuidEntity: data.parentTagEntityUuid,
    };
  }

  export function mapToCreateRequestApiValue(data: LibraryTagDetailsData): CreateTagRequestDTO {
    return {
      applicabilityUuidEntity: data.applicability && data.applicability.uuidEntity,
      name: data.name,
      description: data.description,
      color: data.color,
      parentTagUuidEntity: data.parentTagUuidEntity || undefined,
    };
  }

  export function mapToUpdateRequestApiValue(data: LibraryTagDetailsData): UpdateTagRequestDTO {
    return {
      name: data.name,
      description: data.description,
      color: data.color,
      parentTagUuidEntity: data.parentTagUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): TagPageRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
    };
  }

  export function mapToListRequestApiValue(queries: QueryRequest): TagListRequestDTO {
    return {
      textSearch: queries.textSearch,
      applicabilityUuidEntities: !queries.applicability ? null : queries.applicability.split(','),
    };
  }

  export function mapToEntityLightData(tag: LibraryTagDetailsData): EntityLightData {
    return {
      uuidEntity: tag.uuidEntity,
      name: tag.name,
      color: tag.color,
    };
  }

  export function mapToMultiSelectItem(tag: LibraryTagDetailsData): MultiSelectItemData<EntityLightData> {
    return {
      value: mapToEntityLightData(tag),
      title: tag.name,
    };
  }

  export function mapToAdvancedSearchSelectItem(tag: LibraryTagDetailsData): MultiSelectItemData {
    return {
      value: tag.uuidEntity,
      title: tag.name,
    };
  }

  export const initializeQueryRequest = (tagsFilters: Filters): QueryRequest => ({
    ...initializeQueries<LibraryTagDetailsData>({ key: 'modificationDate', direction: InnerSortDirectionEnum.Desc }),
    ...tagsFilters,
  });
}
