import { LabelFamilyDetailsResponseDTO } from '../../generated/models/labelFamilyDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum LabelFamilyTypeEnum {
  Application = 1,
  Project = 2,
  Configuration = 3,
}

export namespace LabelFamilyTypeEnum {
  export const convertFromApiValue: SfxMap<LabelFamilyDetailsResponseDTO.TypeEnum, LabelFamilyTypeEnum> = new SfxMap<LabelFamilyDetailsResponseDTO.TypeEnum, LabelFamilyTypeEnum>([
    [LabelFamilyDetailsResponseDTO.TypeEnum.APPLICATION, LabelFamilyTypeEnum.Application],
    [LabelFamilyDetailsResponseDTO.TypeEnum.PROJECT, LabelFamilyTypeEnum.Project],
    [LabelFamilyDetailsResponseDTO.TypeEnum.CONFIGURATION, LabelFamilyTypeEnum.Configuration],
  ]);

  export const convertToApiValue: SfxMap<LabelFamilyTypeEnum, LabelFamilyDetailsResponseDTO.TypeEnum> = new SfxMap<LabelFamilyTypeEnum, LabelFamilyDetailsResponseDTO.TypeEnum>([
    [LabelFamilyTypeEnum.Application, LabelFamilyDetailsResponseDTO.TypeEnum.APPLICATION],
    [LabelFamilyTypeEnum.Project, LabelFamilyDetailsResponseDTO.TypeEnum.PROJECT],
    [LabelFamilyTypeEnum.Configuration, LabelFamilyDetailsResponseDTO.TypeEnum.CONFIGURATION],
  ]);
}
