import { createReducer, Action, on } from '@ngrx/store';

import { DefaultCategoryDetailsData } from '../../../../../core/models';
import { LabelFamilyRelativeNatureEnum } from '../../../../../core/enums';
import * as fromCategoryActions from './category-dialog.actions';

export interface DefaultCategoryDialogState {
  saveLoading: boolean;
  isFirstParentToCreate: boolean;
  categoryDetailsData: DefaultCategoryDetailsData;
}

export const initialDefaultCategoryDialogState = () => ({
  saveLoading: false,
  isFirstParentToCreate: false,
  categoryDetailsData: {} as DefaultCategoryDetailsData,
});

const reducer = createReducer<DefaultCategoryDialogState>(
  initialDefaultCategoryDialogState(),
  on(fromCategoryActions.updateDefaultCategory, (state): DefaultCategoryDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.updateDefaultCategoryFail, (state): DefaultCategoryDialogState => ({ ...state, saveLoading: false })),
  on(fromCategoryActions.updateDefaultCategorySuccess, (state): DefaultCategoryDialogState => ({ ...state, saveLoading: false })),
  on(fromCategoryActions.addDefaultCategory, (state): DefaultCategoryDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.addDefaultCategoryFail, (state): DefaultCategoryDialogState => ({ ...state, saveLoading: false })),
  on(
    fromCategoryActions.addDefaultCategorySuccess,
    (state, { category, addNewOne }): DefaultCategoryDialogState => ({
      ...state,
      saveLoading: false,
      isFirstParentToCreate: false,
      categoryDetailsData: addNewOne ? { ...category, shortName: null, longName: null, color: null } : state.categoryDetailsData,
    }),
  ),
  on(fromCategoryActions.getDefaultCategoryDetailsSuccess, (state, { category }): DefaultCategoryDialogState => ({ ...state, categoryDetailsData: category })),
  on(
    fromCategoryActions.checkForFirstDefaultParentSuccess,
    (state, { exist, keepCurrentType }): DefaultCategoryDialogState => ({
      ...state,
      isFirstParentToCreate: !exist,
      categoryDetailsData: {
        ...state.categoryDetailsData,
        relativeNature: !exist ? LabelFamilyRelativeNatureEnum.Parent : keepCurrentType ? state.categoryDetailsData.relativeNature : undefined,
      },
    }),
  ),
);

export function defaultCategoryDialogReducer(state: DefaultCategoryDialogState | undefined, action: Action): DefaultCategoryDialogState {
  return reducer(state, action);
}
