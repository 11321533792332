import { SearchStrategyEnum } from '../../../modules/shared/components/select-v2/utils/search-strategy.enum';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { CustomStatusLightResponseDTO } from '../../generated/models/customStatusLightResponseDTO';
import { LabelFamilyLinkFlowFilterRequestDTO } from '../../generated/models/labelFamilyLinkFlowFilterRequestDTO';
import { CustomStatusOrphansResponseRequestDTO } from '../../generated/models/customStatusOrphansResponseRequestDTO';

export namespace DropdownCustomStatusData {
  export function mapToSelectItemAffectation(data: CustomStatusLightResponseDTO): SingleSelectItemData {
    return { value: { name: data.name, uuidEntity: data.uuidEntity }, title: data.name, tooltip: data.description, icons: mapToIconData(data) };
  }

  export function mapToMultiSelectItemData(data: CustomStatusLightResponseDTO): MultiSelectItemData {
    return { value: data.uuidEntity, title: data.name, tooltip: data.description, icons: mapToIconData(data) };
  }

  export function mapToTreeMultiSelectItemData(data: CustomStatusLightResponseDTO, parent?: CustomStatusLightResponseDTO): MultiSelectItemData {
    return {
      title: data.name,
      value: data.uuidEntity,
      tooltip: data.description,
      children: (data.children || []).map(child => mapToTreeMultiSelectItemData(child, data)),
      parent: parent ? ({ value: parent.uuidEntity } as MultiSelectItemData) : undefined,
      icons: mapToIconData(data),
    };
  }

  function mapToIconData(customStatus: CustomStatusLightResponseDTO): IconData[] {
    const icons: IconData[] = [];

    if (customStatus.importable) {
      icons.push({ name: 'cart-arrow-down', prefix: 'fas' });
    }

    if (customStatus.downloadable) {
      icons.push({ name: 'tablet-alt', prefix: 'fas' });
    }

    if (customStatus.exportable) {
      icons.push({ name: 'file-export', prefix: 'fas' });
    }

    return icons;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToLibraryFormAffectationPageRequestApiValue(textSearch: string, labelFamilyUuidEntity: string): any {
    return { textSearch, labelFamilyUuidEntity };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToLibraryFormFilterPageRequestApiValue(textSearch: string): any {
    return { textSearch };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToTechnicalObjectAffectationPageRequestApiValue(textSearch: string, technicalObjectUuidEntity: string, technicalObjectLabelFamilyUuidEntity: string): any {
    return { textSearch, technicalObjectUuidEntity, technicalObjectLabelFamilyUuidEntity };
  }

  export function mapToProjectFormResponseFilterPageRequestApiValue(
    textSearch: string,
    formUuidEntity: string,
    projectUuidEntity: string,
    formBusinessLinkUuidEntity?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    return { textSearch, formUuidEntity, projectUuidEntity, formBusinessLinkUuidEntity };
  }

  export function mapToOrphansResponseFilterPageRequestApiValue(textSearch: string, flowUuidEntity: string): CustomStatusOrphansResponseRequestDTO {
    return { textSearch, flowUuidEntity };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToProjectFormResponseAffectationPageRequestApiValue(textSearch: string, formUuidEntity: string): any {
    return { textSearch, formUuidEntity };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToProjectPageRequestApiValue(textSearch: string, projectUuidEntity: string): any {
    return { textSearch, projectUuidEntity };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToPlanPageRequestApiValue(textSearch: string, planUuidEntity: string): any {
    return { textSearch, planUuidEntity };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function mapToProjectFieldFeedbackPageRequestApiValue(textSearch: string, searchStrategy: SearchStrategyEnum, projectUuidEntity: string): any {
    return { textSearch, projectUuidEntity, searchOnChildren: searchStrategy === SearchStrategyEnum.Child };
  }

  export function mapToLinkFlowFilterApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, flowUuidEntity: string): LabelFamilyLinkFlowFilterRequestDTO {
    return {
      textSearch,
      searchOnChildren: searchStrategy === SearchStrategyEnum.Child,
      flowUuidEntity,
    };
  }
}
