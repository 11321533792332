import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { CustomStatusCategoryEnum } from '../../enums';
import { CustomStatusLightDTO, CustomStatusLightResponseDTO, CustomStatusSummaryResponseDTO } from '../../generated/models/models';

export interface CustomStatusLightData {
  uuidEntity: string;
  name: string;
  description: string;
  color: string;
  customStatusOrder: number;
  importable: boolean;
  exportable: boolean;
  downloadable: boolean;
  hideEntity: boolean;
}

export namespace CustomStatusLightData {
  export function mapFromApiValue(customStatus: Partial<CustomStatusSummaryResponseDTO & CustomStatusLightDTO>): CustomStatusLightData {
    return {
      uuidEntity: customStatus.uuidEntity,
      name: customStatus.name,
      description: customStatus.description,
      color: customStatus.color,
      customStatusOrder: customStatus.customStatusOrder,
      importable: customStatus.importable,
      downloadable: customStatus.downloadable,
      exportable: customStatus.exportable,
      hideEntity: customStatus.hideEntity,
    };
  }

  export function mapToSingleSelectItemData(status: CustomStatusLightResponseDTO): SingleSelectItemData {
    return {
      value: { uuidEntity: status.uuidEntity, name: status?.name },
      title: status.name,
      icons: mapToIconData(status),
    };
  }

  export function mapToExpandableMultiSelectItemData(statuses: CustomStatusLightResponseDTO[]): MultiSelectItemData<CustomStatusCategoryEnum | string>[] {
    return CustomStatusCategoryEnum.values
      .map(state => ({
        value: state,
        title: CustomStatusCategoryEnum.entityToString.getValue(state),
        children: statuses
          .filter(status => status?.customStatusCategory && CustomStatusCategoryEnum.convertFromApiValue.getValue(status?.customStatusCategory) === state)
          .map(status => ({
            value: status.uuidEntity,
            title: status.name,
            parent: { value: state } as MultiSelectItemData<CustomStatusCategoryEnum>,
          })),
      }))
      .filter(status => !!status?.children?.length);
  }

  function mapToIconData(customStatus: CustomStatusLightResponseDTO): IconData[] {
    const icons: IconData[] = [];

    if (customStatus.importable) {
      icons.push({ name: 'cart-arrow-down', prefix: 'fas' });
    }

    if (customStatus.downloadable) {
      icons.push({ name: 'tablet-alt', prefix: 'fas' });
    }

    if (customStatus.exportable) {
      icons.push({ name: 'file-export', prefix: 'fas' });
    }

    if (customStatus?.hideEntity) {
      icons.push({ name: 'eye-slash', prefix: 'far' });
    }

    return icons;
  }
}
