import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { PlanSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum PlanScaleEnum {
  Temporal = 1,
  Default = 2,
  Scheduling = 3,
}

export namespace PlanScaleEnum {
  export const values = [PlanScaleEnum.Temporal, PlanScaleEnum.Scheduling];
  export const temporalPlans = [PlanScaleEnum.Temporal, PlanScaleEnum.Default];

  export const convertFromApiValue: SfxMap<PlanSummaryResponseDTO.ScaleEnum, PlanScaleEnum> = new SfxMap<PlanSummaryResponseDTO.ScaleEnum, PlanScaleEnum>([
    [PlanSummaryResponseDTO.ScaleEnum.TEMPORAL, PlanScaleEnum.Temporal],
    [PlanSummaryResponseDTO.ScaleEnum.SCHEDULING, PlanScaleEnum.Scheduling],
    [PlanSummaryResponseDTO.ScaleEnum.DEFAULT, PlanScaleEnum.Default],
  ]);

  export const convertToApiValue: SfxMap<PlanScaleEnum, PlanSummaryResponseDTO.ScaleEnum> = new SfxMap<PlanScaleEnum, PlanSummaryResponseDTO.ScaleEnum>([
    [PlanScaleEnum.Temporal, PlanSummaryResponseDTO.ScaleEnum.TEMPORAL],
    [PlanScaleEnum.Scheduling, PlanSummaryResponseDTO.ScaleEnum.SCHEDULING],
    [PlanScaleEnum.Default, PlanSummaryResponseDTO.ScaleEnum.DEFAULT],
  ]);

  export const toString: SfxMap<PlanScaleEnum, string> = new SfxMap<PlanScaleEnum, string>(
    [
      [PlanScaleEnum.Temporal, 'planTranslate.scale.time'],
      [PlanScaleEnum.Scheduling, 'planTranslate.scale.scheduling'],
      [PlanScaleEnum.Default, 'planTranslate.scale.default'],
    ],
    'planTranslate.fields.scale',
  );

  export const iconData: SfxMap<PlanScaleEnum, IconData> = new SfxMap<PlanScaleEnum, IconData>([
    [PlanScaleEnum.Temporal, { name: 'calendar-alt' }],
    [PlanScaleEnum.Default, { name: 'calendar-alt' }],
    [PlanScaleEnum.Scheduling, { name: 'users' }],
  ]);

  export const title: SfxMap<PlanScaleEnum, string> = new SfxMap<PlanScaleEnum, string>(
    [
      [PlanScaleEnum.Temporal, 'planTranslate.title.time'],
      [PlanScaleEnum.Scheduling, 'planTranslate.title.scheduling'],
      [PlanScaleEnum.Default, 'planTranslate.title.default'],
    ],
    'planTranslate.title.initial',
  );

  export const color = (backgroundColor: string): SfxMap<string, string> =>
    new SfxMap<string, string>(
      [
        ['fake-achieved', backgroundColor],
        ['fake-estimated', `${'repeating-linear-gradient(135deg,white,white 7px, ' + backgroundColor + ' 7px,' + backgroundColor + ' 14px)'}`],
      ],
      backgroundColor + '80',
    );

  export const itemsResolver = (): Observable<SingleSelectItemData<PlanScaleEnum>[]> => of(values.map(value => ({ value, title: PlanScaleEnum.toString.getValue(value) })));

  export const comparePredicate = (node: SingleSelectItemData<PlanScaleEnum>, item: PlanScaleEnum) => node.value === item;
}
