import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SignatureActionEnum {
  Edit = 1,
  Conflict = 2,
  Delete = 3,
}

export namespace SignatureActionEnum {
  const values: SignatureActionEnum[] = [SignatureActionEnum.Edit, SignatureActionEnum.Delete];

  const key = 'blocks.dsi.actions.';

  export const toString: SfxMap<SignatureActionEnum, string> = new SfxMap<SignatureActionEnum, string>([
    [SignatureActionEnum.Edit, key + 'edit'],
    [SignatureActionEnum.Conflict, key + 'conflict'],
    [SignatureActionEnum.Delete, key + 'delete'],
  ]);

  export const iconData: SfxMap<SignatureActionEnum, IconData> = new SfxMap<SignatureActionEnum, IconData>([
    [SignatureActionEnum.Edit, { name: 'edit', prefix: 'far' }],
    [SignatureActionEnum.Conflict, { name: 'exclamation-triangle', prefix: 'fas' }],
    [SignatureActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
  ]);

  export const getActionMenu = (): ActionSelectItemData<SignatureActionEnum>[] =>
    values.map(action => ({ value: action, name: toString.getValue(action), icon: iconData.getValue(action) }));
}
