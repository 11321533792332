import { Observable, of } from 'rxjs';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { TemplateParamListRequestDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { TramPageTypeEnum } from './tram-page-type.enum';

export enum TramTypeEnum {
  DEFAULT = 1,
  CLIENT = 2,
  FAMILY = 3,
}

export namespace TramTypeEnum {
  const values: TramTypeEnum[] = [TramTypeEnum.DEFAULT, TramTypeEnum.CLIENT, TramTypeEnum.FAMILY];

  export const convertFromApiValue: SfxMap<TemplateParamListRequestDTO.NaturePageEnum, TramTypeEnum> = new SfxMap<TemplateParamListRequestDTO.NaturePageEnum, TramTypeEnum>([
    [TemplateParamListRequestDTO.NaturePageEnum.DEFAULT, TramTypeEnum.DEFAULT],
    [TemplateParamListRequestDTO.NaturePageEnum.CLIENT, TramTypeEnum.CLIENT],
    [TemplateParamListRequestDTO.NaturePageEnum.FAMILY, TramTypeEnum.FAMILY],
  ]);

  export const convertToApiValue: SfxMap<TramTypeEnum, TemplateParamListRequestDTO.NaturePageEnum> = new SfxMap<TramTypeEnum, TemplateParamListRequestDTO.NaturePageEnum>([
    [TramTypeEnum.DEFAULT, TemplateParamListRequestDTO.NaturePageEnum.DEFAULT],
    [TramTypeEnum.CLIENT, TemplateParamListRequestDTO.NaturePageEnum.CLIENT],
    [TramTypeEnum.FAMILY, TemplateParamListRequestDTO.NaturePageEnum.FAMILY],
  ]);

  export const convertFromParamsValue: SfxMap<string, TramTypeEnum> = new SfxMap<string, TramTypeEnum>([
    ['default', TramTypeEnum.DEFAULT],
    ['client', TramTypeEnum.CLIENT],
    ['family', TramTypeEnum.FAMILY],
  ]);

  export const convertToParamValue: SfxMap<TramTypeEnum, string> = new SfxMap<TramTypeEnum, string>([
    [TramTypeEnum.DEFAULT, 'default'],
    [TramTypeEnum.CLIENT, 'client'],
    [TramTypeEnum.FAMILY, 'family'],
  ]);

  const key = 'trams.filter.';
  export const toString: SfxMap<TramTypeEnum, string> = new SfxMap<TramTypeEnum, string>([
    [TramTypeEnum.DEFAULT, key + 'default'],
    [TramTypeEnum.CLIENT, key + 'client'],
    [TramTypeEnum.FAMILY, key + 'family'],
  ]);

  export function selectItems(selectedTramPage?: TramPageTypeEnum): SingleSelectItemData<TramTypeEnum>[] {
    return values.map(type => ({
      value: type,
      title: toString.getValue(type),
      disabled:
        !!selectedTramPage &&
        ((selectedTramPage === TramPageTypeEnum.CoverPageClient && type !== TramTypeEnum.CLIENT) ||
          (TramPageTypeEnum.disableFamilyByForms.includes(selectedTramPage) && type === TramTypeEnum.FAMILY)),
      selected: !!selectedTramPage && selectedTramPage === TramPageTypeEnum.CoverPageClient && type === TramTypeEnum.CLIENT,
    }));
  }

  export const itemsResolver = (): Observable<SingleSelectItemData<TramTypeEnum>[]> => of(values.map(type => ({ value: type, title: toString.getValue(type), selected: false })));

  export const comparePredicate = (node: SingleSelectItemData<TramTypeEnum>, item: TramTypeEnum) => node.value === item;
}
