import { SfxMap } from '../../utils/enum-utils';

export enum ColorPickerPaletteEnum {
  Solid = 'solid',
  Cover = 'cover',
  Annotate = 'annotate',
}

export namespace ColorPickerPaletteEnum {
  export const defaultColor = '#0d4e8a';
  export const withoutColor = '#ffffff';

  export const colors: SfxMap<ColorPickerPaletteEnum, string[]> = new SfxMap<ColorPickerPaletteEnum, string[]>(
    [
      [
        ColorPickerPaletteEnum.Solid,
        [
          '#43375A',
          '#25485D',
          '#08AE6A',
          '#17AAA2',
          '#B5848C',
          '#DB7663',
          '#7F9BD7',
          '#0C7FAD',
          '#85B296',
          '#EE965D',
          '#83C5F8',
          '#E0AAB9',
          '#C6A241',
          '#F5C684',
          '#BFE2E3',
          '#6A768C',
          '#B34F75',
          '#A4997C',
          '#8F44AD',
          '#D93C78',
          '#B23939',
          '#FFB141',
          '#FFDA79',
          '#C6D3E1',
          '#2D3436',
          '#7D6608',
          '#7B7D7D',
          '#186A3B',
          '#6E2C00',
          '#765D69',
        ],
      ],
      [ColorPickerPaletteEnum.Cover, ['#FF6B64', '#D5A09E', '#F9E8D4', '#F4D1AB', '#D8DFCE', '#B1BF9D', '#D1DCE7', '#A3B7CE', withoutColor]],
      [ColorPickerPaletteEnum.Annotate, ['#000000', '#FFAD4A', '#FF6B64', '#4DBFFF', '#8A76FF', withoutColor]],
    ],
    [],
  );

  export const changeColorsDensity = new SfxMap<string, string>([
    ['#0D4E8A', '#D0DAE5'],
    ['#0d4e8a', '#D0DAE5'],
    ['#43375A', '#D7D5DC'],
    ['#25485D', '#D3D8DC'],
    ['#08AE6A', '#DAECE0'],
    ['#17AAA2', '#DAEBEA'],
    ['#B5848C', '#EDE5E6'],
    ['#DB7663', '#F3E3DF'],
    ['#7F9BD7', '#E4E9F4'],
    ['#0C7FAD', '#D5E3EC'],
    ['#85B296', '#E6EDE8'],
    ['#EE965D', '#F7E9DF'],
    ['#83C5F8', '#E7F1FB'],
    ['#E0AAB9', '#F5ECEF'],
    ['#C6A241', '#F1EADA'],
    ['#F5C684', '#FAF2E6'],
    ['#BFE2E3', '#F2F7F7'],
    ['#6A768C', '#E0E2E6'],
    ['#B34F75', '#EBDBE1'],
    ['#A4997C', '#EAE9E3'],
    ['#8F44AD', '#E5D9EC'],
    ['#D93C78', '#F2D9E2'],
    ['#B23939', '#EBD7D6'],
    ['#FFB141', '#FBEEDC'],
    ['#FFDA79', '#FCF6E5'],
    ['#C6D3E1', '#F2F4F7'],
    ['#2D3436', '#D3D4D5'],
    ['#7D6608', '#E2DED1'],
    ['#7B7D7D', '#E3E3E3'],
    ['#186A3B', '#D4DFD7'],
    ['#6E2C00', '#DED4CD'],
    ['#765D69', '#E1DDDF'],
    ['#A672AC', '#D1BEDF'],
    ['#E46C0B', '#EECEB0'],
  ]);

  export const textColor: SfxMap<string, string> = new SfxMap<string, string>([
    ['#0D4E8A', '#1F5086'],
    ['#0d4e8a', '#1F5086'],
    ['#43375A', '#413858'],
    ['#25485D', '#233D51'],
    ['#08AE6A', '#005116'],
    ['#17AAA2', '#004D47'],
    ['#B5848C', '#5E363C'],
    ['#DB7663', '#7E2B18'],
    ['#7F9BD7', '#2E457C'],
    ['#0C7FAD', '#00426D'],
    ['#85B296', '#2A4C34'],
    ['#EE965D', '#7E3503'],
    ['#83C5F8', '#194C7B'],
    ['#E0AAB9', '#6A3E4B'],
    ['#C6A241', '#5D3E00'],
    ['#F5C684', '#6C450B'],
    ['#BFE2E3', '#304B4C'],
    ['#6A768C', '#3A4458'],
    ['#B34F75', '#752342'],
    ['#A4997C', '#52492F'],
    ['#8F44AD', '#602683'],
    ['#D93C78', '#830431'],
    ['#B23939', '#7D1A16'],
    ['#FFB141', '#7C3B00'],
    ['#FFDA79', '#6D4E00'],
    ['#C6D3E1', '#46515E'],
    ['#2D3436', '#2E3436'],
    ['#7D6608', '#523E00'],
    ['#7B7D7D', '#3F4141'],
    ['#186A3B', '#154A21'],
    ['#6E2C00', '#66300E'],
    ['#765D69', '#4A3640'],
  ]);
}
