import { PhaseSummaryResponseDTO } from '../../generated/models/phaseSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum SignatureColumnEnum {
  Order = 'order',
  Name = 'name',
  Control = 'control',
  Convocation = 'convocation',
  Comment = 'comment',
}

export namespace SignatureColumnEnum {
  export const allColumns = [SignatureColumnEnum.Order, SignatureColumnEnum.Name, SignatureColumnEnum.Control, SignatureColumnEnum.Convocation, SignatureColumnEnum.Comment];

  export type SignatureSortField = keyof PhaseSummaryResponseDTO;

  const key = 'flowSignature.table.header.';
  export const toString: SfxMap<SignatureColumnEnum, string> = new SfxMap<SignatureColumnEnum, string>([
    [SignatureColumnEnum.Order, key + 'order'],
    [SignatureColumnEnum.Name, key + 'name'],
    [SignatureColumnEnum.Control, key + 'control'],
    [SignatureColumnEnum.Convocation, key + 'convocation'],
    [SignatureColumnEnum.Comment, key + 'comment'],
  ]);

  export const sortField: SfxMap<SignatureColumnEnum, SignatureSortField> = new SfxMap<SignatureColumnEnum, SignatureSortField>([
    [SignatureColumnEnum.Order, 'nodeOrder'],
    [SignatureColumnEnum.Name, 'name'],
  ]);
}
