import { MoveEnum, PhotoSizeEnum, RubricNatureEnum } from '../../enums';
import { RubricCreateRequestDTO } from '../../generated/models/rubricCreateRequestDTO';
import { RubricDetailsResponseDTO } from '../../generated/models/rubricDetailsResponseDTO';
import { RubricUpdateRequestDTO } from '../../generated/models/rubricUpdateRequestDTO';
import { ApplicationFileLightData } from '../application-file/application-file';
import { EntityLightData } from '../utils/entity-light-data';

export interface RubricDetailsData {
  uuidEntity: string;
  name: string;
  description: string;
  nature: RubricNatureEnum;
  horizontalPdf: boolean;
  hiddenInMobile: boolean;
  nodeOrder: number;
  allowAddImage: boolean;
  allowAddColumn: boolean;
  allowAddRow: boolean;
  attachmentWidth: PhotoSizeEnum;
  attachmentHight: PhotoSizeEnum;
  images: ApplicationFileLightData[];
  initialNature?: RubricNatureEnum;
  formUuidEntity?: string;
  siblingUuidEntity?: string;
  relativePosition?: MoveEnum;
  additionalComment?: string;
  additionalObservation?: string;
  firstColumnIsHidden?: boolean;
  hideInExportPDF?: boolean;
  importXlsxEnabled?: boolean;
}

export namespace RubricDetailsData {
  export function mapFromApiValue(rubric: RubricDetailsResponseDTO): RubricDetailsData {
    const nature = RubricNatureEnum.convertFromApiValue.getValue(rubric.rubricType);

    return {
      uuidEntity: rubric.uuidEntity,
      name: rubric.name,
      description: rubric.description,
      horizontalPdf: rubric.horizontalePdf,
      hiddenInMobile: rubric.hiddenInMobile,
      allowAddImage: rubric.allowAddImage,
      allowAddRow: nature === RubricNatureEnum.TableColumn ? rubric.allowAddOption : rubric.allowAddQuestion,
      allowAddColumn: nature === RubricNatureEnum.TableColumn ? rubric.allowAddQuestion : rubric.allowAddOption,
      nodeOrder: rubric.rubricOrder,
      attachmentWidth: PhotoSizeEnum.convertFromApiValue.getValue(rubric.attachementWidth),
      attachmentHight: PhotoSizeEnum.convertFromApiValue.getValue(rubric.attachementHeight),
      nature,
      images: (rubric.applicationFiles || []).map(applicationFile => ApplicationFileLightData.mapFromApiValue(applicationFile)),
      additionalComment: rubric.additionalComment,
      additionalObservation: rubric.additionalObservation,
      firstColumnIsHidden: rubric.firstColumnHidden,
      hideInExportPDF: rubric.hideInExportPDF,
      importXlsxEnabled: rubric.importXlsxEnabled,
    };
  }

  export function mapToEntityLightData(rubric: RubricDetailsData): EntityLightData {
    return {
      name: rubric.name,
      uuidEntity: rubric.uuidEntity,
    };
  }

  export function mapToCreateRequestApiValue(rubric: RubricDetailsData, defaultColumnName: string, defaultLineName: string): RubricCreateRequestDTO {
    return {
      name: rubric.name,
      description: rubric.description,
      rubricType: RubricNatureEnum.convertToApiValue.getValue(rubric.nature),
      horizontalPdf: rubric.horizontalPdf,
      hiddenInMobile: rubric.hiddenInMobile,
      allowAddImage: rubric.allowAddImage,
      attachementWidth: PhotoSizeEnum.convertToApiValue.getValue(rubric.attachmentWidth),
      attachementHeight: PhotoSizeEnum.convertToApiValue.getValue(rubric.attachmentHight),
      siblingUuidEntity: rubric.siblingUuidEntity,
      formUuidEntity: rubric.formUuidEntity,
      relativePosition: MoveEnum.convertToApiValue.getValue(rubric.relativePosition),
      additionalComment: rubric.additionalComment,
      additionalObservation: rubric.additionalObservation,
      columnNameKey: defaultColumnName,
      lineNameKey: defaultLineName,
      hideInExportPDF: rubric.hideInExportPDF,
    };
  }

  export function mapToUpdateRequestApiValue(rubric: RubricDetailsData): RubricUpdateRequestDTO {
    return {
      rubricUuidEntity: rubric.uuidEntity,
      name: rubric.name,
      description: rubric.description,
      horizontalPdf: rubric.horizontalPdf,
      hiddenInMobile: rubric.hiddenInMobile,
      allowAddImage: rubric.allowAddImage,
      allowAddOption: rubric.nature === RubricNatureEnum.TableColumn ? rubric.allowAddRow : rubric.allowAddColumn,
      allowAddQuestion: rubric.nature === RubricNatureEnum.TableColumn ? rubric.allowAddColumn : rubric.allowAddRow,
      attachementWidth: PhotoSizeEnum.convertToApiValue.getValue(rubric.attachmentWidth),
      attachementHeight: PhotoSizeEnum.convertToApiValue.getValue(rubric.attachmentHight),
      additionalComment: rubric.additionalComment,
      additionalObservation: rubric.additionalObservation,
      firstColumnHidden: rubric.firstColumnIsHidden,
      hideInExportPDF: rubric.hideInExportPDF,
      importXlsxEnabled: rubric.importXlsxEnabled,
    };
  }
}
