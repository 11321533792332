import { QuestionTypeEnum } from '../../enums';
import { optionValueType, QuestionOptionData, questionOptionResponseType } from './question-option-data';
import { TableHeaderCellData } from './table-header-cell-data';

export interface TableCellData<optionValueType> extends QuestionOptionData<optionValueType> {
  parent: TableHeaderCellData;
  preEvaluationValue: string;
  headerName: string;
  hasSignatory?: boolean;
}

export namespace TableCellData {
  const emptyHeaderName = '_';
  export function mapFromApiValue(option: questionOptionResponseType, parent: TableHeaderCellData): TableCellData<optionValueType> {
    const headerName = (parent && parent.type !== QuestionTypeEnum.Collaborator && option.optionName) || option.name || '';

    return {
      ...QuestionOptionData.mapFromApiValue(option, parent && parent.type),
      parent,
      headerName: headerName?.trim() || emptyHeaderName,
      preEvaluationValue: option.preEvaluationValue,
      hasSignatory: !!option.imageSignature,
    };
  }
}
