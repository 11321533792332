import { CustomColumnsConfigurationEnum, FlowColumnEnum } from '../../enums';

export interface CustomColumnsLocalStorageData {
  configuration: string;
  uuidEntities: string[];
  sizes?: { [key: string]: number };
}

export namespace CustomColumnsLocalStorageData {
  export const initialize = (forPgac: boolean): CustomColumnsLocalStorageData => ({
    configuration: CustomColumnsConfigurationEnum.Default,
    uuidEntities: forPgac ? FlowColumnEnum.pgacDndColumns : FlowColumnEnum.flowDndColumns,
    sizes: (forPgac ? FlowColumnEnum.pgacDndColumns : FlowColumnEnum.flowDndColumns).reduce((obj, item) => ({ ...obj, [item]: 250 }), {}),
  });

  export function getCustomColumnsUuidEntities(list: string[]): string[] {
    return list.filter(it => !FlowColumnEnum.all.includes(it as FlowColumnEnum));
  }
}
