import { SectionRfiTemplateSummaryResponseDTO } from '../../generated/models/models';
import { SectionRfiTemplateCreateRequestDTO } from '../../generated/models/sectionRfiTemplateCreateRequestDTO';
import { SectionRfiTemplateDetailsResponseDTO } from '../../generated/models/sectionRfiTemplateDetailsResponseDTO';
import { SectionRfiTemplateUpdateRequestDTO } from '../../generated/models/sectionRfiTemplateUpdateRequestDTO';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SectionNatureEnum, StringUtilsEnum } from '../../enums';

export interface SectionDetailsData {
  description?: string;
  name: string;
  uuidEntity: string;
  type?: SectionNatureEnum;
  category: EntityLightData;
  folderUuidEntity?: string;
  includePreviousVersions?: boolean;
}

export namespace SectionDetailsData {
  export function mapFromApiValue(data: SectionRfiTemplateDetailsResponseDTO): SectionDetailsData {
    return {
      name: data.name,
      description: data.description,
      uuidEntity: data.uuidEntity,
      type: data.type && SectionNatureEnum.convertFromApiValue.getValue(data.type),
      category: data.category && LabelFamilyLightData.mapToEntityLightData(data.category),
      includePreviousVersions: data.includePreviousVersions,
    };
  }

  export function mapToCreateRequestApiValue(data: SectionDetailsData, folderUuidEntity: string): SectionRfiTemplateCreateRequestDTO {
    return {
      name: data.name,
      description: data.description,
      type: SectionNatureEnum.convertToApiValue.getValue(data.type),
      categoryUuide: data.category && data.category.uuidEntity,
      rfiTemplateUuide: folderUuidEntity ? folderUuidEntity : data.folderUuidEntity,
      includePreviousVersions: data.includePreviousVersions,
    };
  }

  export function mapToUpdateRequestApiValue(data: SectionDetailsData): SectionRfiTemplateUpdateRequestDTO {
    return {
      sectionUuide: data.uuidEntity,
      name: data.name,
      description: data.description,
      type: SectionNatureEnum.convertToApiValue.getValue(data.type),
      categoryUuide: data.category && data.category.uuidEntity,
      includePreviousVersions: data.includePreviousVersions,
    };
  }

  export function mapFromSummaryResponseDTO(data: SectionRfiTemplateSummaryResponseDTO): SectionDetailsData {
    return {
      name: data.name,
      type: data.type && SectionNatureEnum.convertFromApiValue.getValue(data.type),
    } as SectionDetailsData;
  }

  export function toString(sectionSummary: SectionDetailsData): string {
    return SectionNatureEnum.toString.getValue(sectionSummary.type).concat(StringUtilsEnum.DashSeparator.concat(sectionSummary.name));
  }
}
