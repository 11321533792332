import { SfxMap } from '../../../core/utils/enum-utils';

export enum FormNavigationTabsEnum {
  Guide = 'guide',
  Restrict = 'restrict',
}

export namespace FormNavigationTabsEnum {
  export const getHelpCenterLink: SfxMap<FormNavigationTabsEnum, string> = new SfxMap<FormNavigationTabsEnum, string>(
    [
      [FormNavigationTabsEnum.Guide, '12477166287122'],
      [FormNavigationTabsEnum.Restrict, '17694231848466'],
    ],
    '17694231848466',
  );
}
