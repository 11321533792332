import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SpecialCharactersEnum {
  Alpha = 1,
  Beta = 2,
  Gamma = 3,
  Pi = 4,
  Rho = 5,
  Micro = 6,
  Diameter = 7,
  Omega = 8,
  Sigma = 9,
  Delta = 10,
  Sum = 11,
  Multiply = 12,
  Division = 13,
  MoreLess = 14,
  Different = 15,
  PowerTwo = 16,
  PowerThree = 17,
  OneQuart = 18,
  Half = 19,
  ThreeQuart = 20,
  GreatEqual = 21,
  LessEqual = 22,
  Left = 23,
  Right = 24,
  Up = 25,
  Down = 26,
  LeftRight = 27,
  UpDown = 28,
}

export namespace SpecialCharactersEnum {
  const values: SpecialCharactersEnum[] = [
    SpecialCharactersEnum.Alpha,
    SpecialCharactersEnum.Beta,
    SpecialCharactersEnum.Gamma,
    SpecialCharactersEnum.Pi,
    SpecialCharactersEnum.Rho,
    SpecialCharactersEnum.Micro,
    SpecialCharactersEnum.Diameter,
    SpecialCharactersEnum.Omega,
    SpecialCharactersEnum.Sigma,
    SpecialCharactersEnum.Delta,
    SpecialCharactersEnum.Sum,
    SpecialCharactersEnum.Multiply,
    SpecialCharactersEnum.Division,
    SpecialCharactersEnum.MoreLess,
    SpecialCharactersEnum.Different,
    SpecialCharactersEnum.PowerTwo,
    SpecialCharactersEnum.PowerThree,
    SpecialCharactersEnum.OneQuart,
    SpecialCharactersEnum.Half,
    SpecialCharactersEnum.ThreeQuart,
    SpecialCharactersEnum.GreatEqual,
    SpecialCharactersEnum.LessEqual,
    SpecialCharactersEnum.Left,
    SpecialCharactersEnum.Right,
    SpecialCharactersEnum.Up,
    SpecialCharactersEnum.Down,
    SpecialCharactersEnum.LeftRight,
    SpecialCharactersEnum.UpDown,
  ];

  const key = 'customMenu.specialCharacters.';
  export const toString: SfxMap<SpecialCharactersEnum, string> = new SfxMap<SpecialCharactersEnum, string>([
    [SpecialCharactersEnum.Alpha, key + 'alpha'],
    [SpecialCharactersEnum.Beta, key + 'beta'],
    [SpecialCharactersEnum.Gamma, key + 'gamma'],
    [SpecialCharactersEnum.Pi, key + 'pi'],
    [SpecialCharactersEnum.Rho, key + 'rho'],
    [SpecialCharactersEnum.Micro, key + 'micro'],
    [SpecialCharactersEnum.Diameter, key + 'diameter'],
    [SpecialCharactersEnum.Omega, key + 'omega'],
    [SpecialCharactersEnum.Sigma, key + 'sigma'],
    [SpecialCharactersEnum.Delta, key + 'delta'],
    [SpecialCharactersEnum.Sum, key + 'sum'],
    [SpecialCharactersEnum.Multiply, key + 'multiply'],
    [SpecialCharactersEnum.Division, key + 'division'],
    [SpecialCharactersEnum.MoreLess, key + 'moreLess'],
    [SpecialCharactersEnum.Different, key + 'different'],
    [SpecialCharactersEnum.PowerTwo, key + 'powerTwo'],
    [SpecialCharactersEnum.PowerThree, key + 'powerThree'],
    [SpecialCharactersEnum.OneQuart, key + 'oneQuart'],
    [SpecialCharactersEnum.Half, key + 'half'],
    [SpecialCharactersEnum.ThreeQuart, key + 'threeQuart'],
    [SpecialCharactersEnum.GreatEqual, key + 'greatEqual'],
    [SpecialCharactersEnum.LessEqual, key + 'lessEqual'],
    [SpecialCharactersEnum.Left, key + 'left'],
    [SpecialCharactersEnum.Right, key + 'right'],
    [SpecialCharactersEnum.Up, key + 'up'],
    [SpecialCharactersEnum.Down, key + 'down'],
    [SpecialCharactersEnum.LeftRight, key + 'leftRight'],
    [SpecialCharactersEnum.UpDown, key + 'upDown'],
  ]);

  export const toSpecialCharacter: SfxMap<SpecialCharactersEnum, string> = new SfxMap<SpecialCharactersEnum, string>([
    [SpecialCharactersEnum.Alpha, 'α'],
    [SpecialCharactersEnum.Beta, 'β'],
    [SpecialCharactersEnum.Gamma, 'γ'],
    [SpecialCharactersEnum.Pi, 'π'],
    [SpecialCharactersEnum.Rho, 'ρ'],
    [SpecialCharactersEnum.Micro, 'µ'],
    [SpecialCharactersEnum.Diameter, 'Φ'],
    [SpecialCharactersEnum.Omega, 'Ω'],
    [SpecialCharactersEnum.Sigma, 'σ'],
    [SpecialCharactersEnum.Delta, 'Δ'],
    [SpecialCharactersEnum.Sum, '∑'],
    [SpecialCharactersEnum.Multiply, '×'],
    [SpecialCharactersEnum.Division, '/'],
    [SpecialCharactersEnum.MoreLess, '±'],
    [SpecialCharactersEnum.Different, '≠'],
    [SpecialCharactersEnum.PowerTwo, '²'],
    [SpecialCharactersEnum.PowerThree, '³'],
    [SpecialCharactersEnum.OneQuart, '¼'],
    [SpecialCharactersEnum.Half, '½'],
    [SpecialCharactersEnum.ThreeQuart, '¾'],
    [SpecialCharactersEnum.GreatEqual, '≥'],
    [SpecialCharactersEnum.LessEqual, '≤'],
    [SpecialCharactersEnum.Left, '←'],
    [SpecialCharactersEnum.Right, '→'],
    [SpecialCharactersEnum.Up, '↑'],
    [SpecialCharactersEnum.Down, '↓'],
    [SpecialCharactersEnum.LeftRight, '↔'],
    [SpecialCharactersEnum.UpDown, '↕'],
  ]);

  export const selectItems: SingleSelectItemData<string>[] = values.map(action => ({ value: toSpecialCharacter.getValue(action), title: toString.getValue(action) }));
}
