import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, filter, first, map, switchMap, finalize, take } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

import { AppState } from '../../app.state';
import * as fromMainActions from '../../main/state/main.actions';
import { getRouteUrl } from '../../router';

import { ActivityWsService } from '../../../core/services/activity-ws.service';
import { ToasterService } from '../components/toaster/toaster.service';
import { SessionService } from './session.service';

import { ApiUrlsEnum } from '../../../core/services/helpers/api-url';

import { RouterPaths } from '../../../core/constant/route.constant';

import { RefreshTokenResponseData } from '../../../core/models';

import { environment } from '../../../../../environments/environment';
import { LocalStorageEnum } from '../../../core/enums/local-storage.enum';
import { RouteQueryParamEnum } from '../../../core/enums/router-enums/query-params.enum';
import { ToasterTypeEnum } from '../../../core/enums/utils/toaster-type.enum';

@Injectable()
export class SfxInterceptorService implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenStore = new BehaviorSubject<string>(null);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toasterService: ToasterService,
    private localStorageService: LocalStorageService,
    private sessionService: SessionService,
    private activityWsService: ActivityWsService,
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (request.url.match(ApiUrlsEnum.Authenticate)) {
          return throwError(error);
        }

        if (error instanceof HttpErrorResponse && error?.status === 401) {
          if (this.sessionService.isAuth2()) {
            const refreshToken = this.sessionService.getRefreshToken();
            if (!this.isRefreshingToken) {
              this.isRefreshingToken = true;
              // Reset here so that the following requests wait until the token
              // comes back from the refreshToken call.
              this.tokenStore.next(null);

              return this.sessionService.refreshToken({ body: refreshToken }).pipe(
                switchMap((refreshTokenResponse: RefreshTokenResponseData) => {
                  if (refreshTokenResponse && refreshTokenResponse.idToken) {
                    this.tokenStore.next(refreshTokenResponse.idToken);
                    this.localStorageService.store(LocalStorageEnum.AuthToken, refreshTokenResponse.idToken);

                    const action = fromMainActions.tokensRefreshed();
                    this.store.dispatch(action);

                    return next.handle(this.addToken(request, refreshTokenResponse.idToken));
                  }

                  // If we don't get a new token, we are in trouble so logout.
                  return this.logout(next, request);
                }),
                catchError(err => {
                  this.logout(next, request).subscribe();

                  return throwError(err);
                }),
                finalize(() => (this.isRefreshingToken = false)),
              );
            } else {
              return this.tokenStore.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => next.handle(this.addToken(request, token))),
              );
            }
          }

          return this.logout(next, request);
        }

        return throwError(error);
      }),
    );
  }

  private logout<T>(_: HttpHandler, __: HttpRequest<T>): Observable<T> {
    this.localStorageService.clear(LocalStorageEnum.AuthToken);

    return this.store.pipe(
      select(getRouteUrl),
      first(),
      map(url => {
        if (url?.indexOf(RouteQueryParamEnum.ReturnUrl) === -1) {
          this.toasterService.show({ type: ToasterTypeEnum.Warning, title: 'login.sessionExpired.title', subtitle: 'login.sessionExpired.subTitle' });
          const extras = { replaceUrl: true, queryParams: { [RouteQueryParamEnum.ReturnUrl]: url === '/' ? undefined : url } };
          this.router.navigate(['/', RouterPaths.EntryPaths.loginPath], extras);
        }

        if (environment.production) {
          this.activityWsService.disconnect();
        }

        return null;
      }),
    );
  }

  private addToken<T>(req: HttpRequest<T>, token: string): HttpRequest<T> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }
}
