import { compact } from 'lodash';
import { EntityLightData } from '../utils/entity-light-data';
import { ClientDTO, ClientSummaryResponseDTO } from '../../generated/models/models';
import { ClientLightDTO } from '../../generated/models/clientLightDTO';

export interface ClientLightData {
  uuidEntity: string;
  name: string;
}
export namespace ClientLightData {
  export function mapFromApiValue(client: ClientLightDTO): ClientLightData {
    return {
      uuidEntity: client.uuidEntity,
      name: client.name,
    };
  }

  export function mapToEntityLightData(client: ClientSummaryResponseDTO | ClientDTO): EntityLightData {
    return {
      uuidEntity: client.uuidEntity,
      name: compact([client.name || '', client.nameComp || '', client.nameComp2 || '']).join(' - '),
    };
  }
}
