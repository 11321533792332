import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SfxMap } from '../../utils/enum-utils';

import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SignatoryFilterDTO } from '../../generated/models/models';

export enum SignatureStatusEnum {
  Signed = 1,
  Waiting = 2,
  Free = 3,
  NotPossible = 4,
}

export namespace SignatureStatusEnum {
  const values: SignatureStatusEnum[] = [SignatureStatusEnum.Signed, SignatureStatusEnum.Waiting, SignatureStatusEnum.Free, SignatureStatusEnum.NotPossible];

  export const convertFromApiValue = new SfxMap<SignatoryFilterDTO.SignatureStateEnum, SignatureStatusEnum>(
    compact([
      [SignatoryFilterDTO.SignatureStateEnum.SIGNED, SignatureStatusEnum.Signed],
      [SignatoryFilterDTO.SignatureStateEnum.WAITING, SignatureStatusEnum.Waiting],
      [SignatoryFilterDTO.SignatureStateEnum.FREE, SignatureStatusEnum.Free],
      [SignatoryFilterDTO.SignatureStateEnum.SIGNATURENOTPOSSIBLE, SignatureStatusEnum.NotPossible],
    ]),
  );

  export const convertToApiValue = new SfxMap<SignatureStatusEnum, SignatoryFilterDTO.SignatureStateEnum>(
    [
      [SignatureStatusEnum.Signed, SignatoryFilterDTO.SignatureStateEnum.SIGNED],
      [SignatureStatusEnum.Waiting, SignatoryFilterDTO.SignatureStateEnum.WAITING],
      [SignatureStatusEnum.Free, SignatoryFilterDTO.SignatureStateEnum.FREE],
      [SignatureStatusEnum.NotPossible, SignatoryFilterDTO.SignatureStateEnum.SIGNATURENOTPOSSIBLE],
    ],
    SignatoryFilterDTO.SignatureStateEnum.SIGNED,
  );

  export const convertFromParamsValue = new SfxMap<string, SignatureStatusEnum>([
    ['signed', SignatureStatusEnum.Signed],
    ['waiting', SignatureStatusEnum.Waiting],
    ['notSigned', SignatureStatusEnum.Free],
    ['notApplicable', SignatureStatusEnum.NotPossible],
  ]);

  export const convertToParamsValue = new SfxMap<SignatureStatusEnum, string>([
    [SignatureStatusEnum.Signed, 'signed'],
    [SignatureStatusEnum.Waiting, 'waiting'],
    [SignatureStatusEnum.Free, 'notSigned'],
    [SignatureStatusEnum.NotPossible, 'notApplicable'],
  ]);

  const key = 'flowSignature.label.';
  export const toString = new SfxMap<SignatureStatusEnum, string>([
    [SignatureStatusEnum.Signed, key + 'signed'],
    [SignatureStatusEnum.Waiting, key + 'waiting'],
    [SignatureStatusEnum.Free, key + 'free'],
    [SignatureStatusEnum.NotPossible, key + 'notPossible'],
  ]);

  export const multiSelectItemResolver = (): Observable<MultiSelectItemData<SignatureStatusEnum>[]> =>
    of(
      values.map(status => ({
        value: status,
        title: toString.getValue(status),
      })),
    );
}
