import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockEquipmentModalTabEnum {
  General = 'general',
  Verification = 'verification',
}

export namespace BlockEquipmentModalTabEnum {
  const key = 'blocks.emm.dialogs.view.';

  export const toString: SfxMap<BlockEquipmentModalTabEnum, string> = new SfxMap<BlockEquipmentModalTabEnum, string>([
    [BlockEquipmentModalTabEnum.General, key + 'general'],
    [BlockEquipmentModalTabEnum.Verification, key + 'verification'],
  ]);

  export const iconData: SfxMap<BlockEquipmentModalTabEnum, IconData> = new SfxMap<BlockEquipmentModalTabEnum, IconData>([
    [BlockEquipmentModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [BlockEquipmentModalTabEnum.Verification, { name: 'gavel', prefix: 'fas' }],
  ]);
}
