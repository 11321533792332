import { TemplateRef, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { PermissionActionEnum, PopoverEventTypeEnum, PopoverPositionEnum, RouteIdEnum } from '../../../../core/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type popoverContentType = TemplateRef<any> | Type<any> | string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PopoverCloseEvent<T = any> {
  type: PopoverEventTypeEnum;
  data: T;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PopoverData<T, R = any> {
  origin: HTMLElement;
  content: popoverContentType;
  preferredPosition: PopoverPositionEnum;
  hasBackdrop?: boolean;
  closeWhenScrolling?: boolean; // FIXME: this option still have issues with scrollable content inside the popover
  showAnchor?: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  panelClass?: string;
  data?: T;
  checkPermission?: { routeId: RouteIdEnum; action: PermissionActionEnum; uuidContext?: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeClose$?: (beforeCloseData?: R) => Observable<any>;
}

export interface PopoverConfig {
  animationFadeIn?: number;
  animationFadeOut?: number;
}

export const configData: PopoverConfig = {
  animationFadeIn: 200,
};
