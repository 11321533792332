import { compact } from 'lodash';

import { PageFormResponseRequestDTO, ResponseDetailsResponseDTO, VersionRubricOptionResponseDTO } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum ResponseProvenanceEnum {
  Web = 1,
  Mobile = 2,
  Project = 3,
  Flow = 4,
}

export namespace ResponseProvenanceEnum {
  export const values = (deviceProvenanceOnly?: boolean): ResponseProvenanceEnum[] =>
    compact([
      ResponseProvenanceEnum.Web,
      ResponseProvenanceEnum.Mobile,
      !deviceProvenanceOnly && ResponseProvenanceEnum.Project,
      !deviceProvenanceOnly && ResponseProvenanceEnum.Flow,
    ]);

  export const provenanceEntities: ResponseProvenanceEnum[] = [ResponseProvenanceEnum.Project, ResponseProvenanceEnum.Flow];

  export const convertFromApiValue: SfxMap<ResponseDetailsResponseDTO.ProvenanceEnum, ResponseProvenanceEnum> = new SfxMap<
    ResponseDetailsResponseDTO.ProvenanceEnum,
    ResponseProvenanceEnum
  >(
    [
      [ResponseDetailsResponseDTO.ProvenanceEnum.WEB, ResponseProvenanceEnum.Web],
      [ResponseDetailsResponseDTO.ProvenanceEnum.MOBILE, ResponseProvenanceEnum.Mobile],
    ],
    ResponseProvenanceEnum.Web,
  );

  export const convertToApiValue: SfxMap<ResponseProvenanceEnum, ResponseDetailsResponseDTO.ProvenanceEnum | PageFormResponseRequestDTO.EntityProvenanceEnum> = new SfxMap<
    ResponseProvenanceEnum,
    ResponseDetailsResponseDTO.ProvenanceEnum | PageFormResponseRequestDTO.EntityProvenanceEnum
  >([
    [ResponseProvenanceEnum.Web, ResponseDetailsResponseDTO.ProvenanceEnum.WEB],
    [ResponseProvenanceEnum.Mobile, ResponseDetailsResponseDTO.ProvenanceEnum.MOBILE],
    [ResponseProvenanceEnum.Project, PageFormResponseRequestDTO.EntityProvenanceEnum.PROJECT],
    [ResponseProvenanceEnum.Flow, PageFormResponseRequestDTO.EntityProvenanceEnum.FLOW],
  ]);

  export const convertFromParamsValue: SfxMap<string, ResponseProvenanceEnum> = new SfxMap<string, ResponseProvenanceEnum>([
    ['web', ResponseProvenanceEnum.Web],
    ['mobile', ResponseProvenanceEnum.Mobile],
    ['project', ResponseProvenanceEnum.Project],
    ['flow', ResponseProvenanceEnum.Flow],
  ]);

  export const convertToParamsValue: SfxMap<ResponseProvenanceEnum, string> = new SfxMap<ResponseProvenanceEnum, string>([
    [ResponseProvenanceEnum.Web, 'web'],
    [ResponseProvenanceEnum.Mobile, 'mobile'],
    [ResponseProvenanceEnum.Project, 'project'],
    [ResponseProvenanceEnum.Flow, 'flow'],
  ]);

  export const color: SfxMap<ResponseProvenanceEnum, string> = new SfxMap<ResponseProvenanceEnum, string>(
    [
      [ResponseProvenanceEnum.Web, 'sfx-chip-primary'],
      [ResponseProvenanceEnum.Mobile, 'sfx-chip-sfx-orange'],
    ],
    'sfx-chip-primary',
  );

  export const iconData: SfxMap<ResponseProvenanceEnum, IconData> = new SfxMap<ResponseProvenanceEnum, IconData>([
    [ResponseProvenanceEnum.Web, { name: 'desktop', prefix: 'fas' }],
    [ResponseProvenanceEnum.Mobile, { name: 'mobile-alt', prefix: 'fas' }],
  ]);

  const key = 'flowApp.responseProvenance.';

  export const toString: SfxMap<ResponseProvenanceEnum, string> = new SfxMap<ResponseProvenanceEnum, string>(
    [
      [ResponseProvenanceEnum.Web, key + 'web'],
      [ResponseProvenanceEnum.Mobile, key + 'mobile'],
      [ResponseProvenanceEnum.Project, key + 'project'],
      [ResponseProvenanceEnum.Flow, key + 'flow'],
    ],
    'global.filters.provenance',
  );

  export const itemsResolver = (deviceProvenanceOnly?: boolean): Observable<SingleSelectItemData<ResponseProvenanceEnum>[]> =>
    of(
      values(deviceProvenanceOnly).map(provenance => ({
        value: provenance,
        title: toString.getValue(provenance),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<ResponseProvenanceEnum>, item: ResponseProvenanceEnum) => node.value === item;

  export const convertToQuestionChangesProvenance = new SfxMap<VersionRubricOptionResponseDTO.ProvenanceEnum, ResponseProvenanceEnum>(
    [[VersionRubricOptionResponseDTO.ProvenanceEnum.MOBILE, ResponseProvenanceEnum.Mobile]],
    ResponseProvenanceEnum.Mobile,
  );

  const questionHistoryProvenanceKey = 'questions.response.provenance.';
  export const questionHistoryProvenanceToString = new SfxMap<ResponseProvenanceEnum, string>([
    [ResponseProvenanceEnum.Mobile, questionHistoryProvenanceKey + 'mobileApp'],
    [ResponseProvenanceEnum.Web, questionHistoryProvenanceKey + 'webApp'],
  ]);
}
