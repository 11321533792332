import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { RouterPaths } from '../../constant/route.constant';
import { SfxMap } from '../../utils/enum-utils';

import { RouteIdEnum } from '../router-enums/route.enum';

export namespace DashboardListModalTabEnum {
  const key = 'lists.dialog.tab.';

  export const toString: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.DashboardListDialogGeneralTabModal, key + 'dListDialogGeneralTabModal'],
    [RouteIdEnum.DashboardListDialogImportationTabModal, key + 'dListDialogImportationTabModal'],
    [RouteIdEnum.DashboardListDialogContentTabModal, key + 'dListDialogContentTabModal'],
  ]);

  export const iconData = (isRisk: boolean): SfxMap<RouteIdEnum, IconData> =>
    new SfxMap<RouteIdEnum, IconData>([
      [RouteIdEnum.DashboardListDialogGeneralTabModal, { name: 'sliders-h', prefix: 'fas' }],
      [RouteIdEnum.DashboardListDialogImportationTabModal, isRisk ? { name: 'exclamation-triangle', prefix: 'fas' } : { name: 'tools', prefix: 'fas' }],
      [RouteIdEnum.DashboardListDialogContentTabModal, { name: 'box', prefix: 'fas' }],
    ]);

  export const routePath: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.DashboardListDialogGeneralTabModal, RouterPaths.TabPaths.generalTabPath],
    [RouteIdEnum.DashboardListDialogImportationTabModal, RouterPaths.TabPaths.importTabPath],
    [RouteIdEnum.DashboardListDialogContentTabModal, RouterPaths.TabPaths.contentTabPath],
  ]);

  export const nextTab: SfxMap<RouteIdEnum, RouteIdEnum> = new SfxMap<RouteIdEnum, RouteIdEnum>([
    [RouteIdEnum.DashboardListDialogGeneralTabModal, RouteIdEnum.DashboardListDialogImportationTabModal],
    [RouteIdEnum.DashboardListDialogImportationTabModal, RouteIdEnum.DashboardListDialogContentTabModal],
  ]);
}
