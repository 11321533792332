import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

import { PermissionActionEnum } from '../permission-action.enum';

export enum AccessAPiV2ActionEnum {
  Activate = 1,
  Deactivate,
  Properties,
  Delete,
}

export namespace AccessAPiV2ActionEnum {
  const key = 'accessApi.v2.action.';
  export const toString: SfxMap<AccessAPiV2ActionEnum, string> = new SfxMap<AccessAPiV2ActionEnum, string>([
    [AccessAPiV2ActionEnum.Activate, key + 'enable'],
    [AccessAPiV2ActionEnum.Deactivate, key + 'disable'],
    [AccessAPiV2ActionEnum.Properties, key + 'properties'],
    [AccessAPiV2ActionEnum.Delete, key + 'delete'],
  ]);

  export const iconData: SfxMap<AccessAPiV2ActionEnum, IconData> = new SfxMap<AccessAPiV2ActionEnum, IconData>([
    [AccessAPiV2ActionEnum.Activate, { name: 'eye', prefix: 'fas' }],
    [AccessAPiV2ActionEnum.Deactivate, { name: 'eye-slash', prefix: 'fas' }],
    [AccessAPiV2ActionEnum.Properties, { name: 'info', prefix: 'fas' }],
    [AccessAPiV2ActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
  ]);

  export const getActionMenu = (active: boolean) => [
    mapToActionMenuItem(active ? AccessAPiV2ActionEnum.Deactivate : AccessAPiV2ActionEnum.Activate, PermissionActionEnum.Edit),
    mapToActionMenuItem(AccessAPiV2ActionEnum.Properties),
    mapToActionMenuItem(AccessAPiV2ActionEnum.Delete, PermissionActionEnum.Delete),
  ];

  export function mapToActionMenuItem(action: AccessAPiV2ActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<AccessAPiV2ActionEnum> {
    return {
      name: AccessAPiV2ActionEnum.toString.getValue(action),
      permissionAction,
      value: action,
      icon: AccessAPiV2ActionEnum.iconData.getValue(action),
    };
  }
}
