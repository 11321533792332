import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertiesResponseDto } from '../generated/models/propertiesResponseDto';
import { Properties } from '../models/properties/properties';
import { BaseHttpService } from './base-http.service';
import { ApiUrlsEnum } from './helpers/api-url';

@Injectable({ providedIn: 'root' })
export class PropertiesService {
  constructor(private baseHttpService: BaseHttpService) {}

  public findPropertiesConfiguration(): Observable<Properties> {
    return this.baseHttpService.get<PropertiesResponseDto>(ApiUrlsEnum.Properties).pipe(map(response => Properties.mapFromPropertiesResponseDto(response)));
  }
}
