import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromCategoryActions from './family-dialog.actions';

import { FamilyDetailsData } from '../../../../../core/models';

import { LabelFamilyRelativeNatureEnum } from '../../../../../core/enums';

export interface SettingsFamilyDialogState {
  saveLoading: boolean;
  isFirstParentToCreate: boolean;
  familyDetailsData: FamilyDetailsData;
  initialFamilyDetailsData: FamilyDetailsData;
}

export const initialSettingsFamilyDialogState = () => ({
  saveLoading: false,
  isFirstParentToCreate: false,
  familyDetailsData: { clientSuperVision: true, clientSuperVisionSubIndex: true, color: '#0d4e8a' } as FamilyDetailsData,
  initialFamilyDetailsData: { clientSuperVision: true, clientSuperVisionSubIndex: true, color: '#0d4e8a' } as FamilyDetailsData,
});

const reducer = createReducer<SettingsFamilyDialogState>(
  initialSettingsFamilyDialogState(),
  on(fromCategoryActions.updateFamily, (state): SettingsFamilyDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.updateFamilyFail, fromCategoryActions.stopSaveLoading, (state): SettingsFamilyDialogState => ({ ...state, saveLoading: false })),
  on(fromCategoryActions.updateFamilySuccess, (state): SettingsFamilyDialogState => ({ ...state, saveLoading: false })),
  on(fromCategoryActions.addFamily, (state): SettingsFamilyDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.addFamilyFail, (state): SettingsFamilyDialogState => ({ ...state, saveLoading: false })),
  on(
    fromCategoryActions.addFamilySuccess,
    (state, { family, addNewOne }): SettingsFamilyDialogState => ({
      ...state,
      saveLoading: false,
      isFirstParentToCreate: false,
      familyDetailsData: addNewOne ? { ...family, shortName: null, longName: null, color: null } : state.familyDetailsData,
    }),
  ),
  on(
    fromCategoryActions.getFamilyDetailsSuccess,
    (state, { family }): SettingsFamilyDialogState => ({ ...state, initialFamilyDetailsData: cloneDeep(family), familyDetailsData: family }),
  ),
  on(
    fromCategoryActions.checkForFirstParentSuccess,
    (state, { exist, keepCurrentType }): SettingsFamilyDialogState => ({
      ...state,
      isFirstParentToCreate: !exist,
      familyDetailsData: {
        ...state.familyDetailsData,
        relativeNature: !exist ? LabelFamilyRelativeNatureEnum.Parent : keepCurrentType ? state.familyDetailsData.relativeNature : undefined,
      },
    }),
  ),
);

export function settingsFamilyDialogReducer(state: SettingsFamilyDialogState | undefined, action: Action): SettingsFamilyDialogState {
  return reducer(state, action);
}
