import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FormModalTabEnum {
  General = 'general',
  Options = 'options',
}

export namespace FormModalTabEnum {
  export function getValues(): string[] {
    return [FormModalTabEnum.General, FormModalTabEnum.Options];
  }

  const key = 'forms.dialogs.createOrEdit.';
  export const toString: SfxMap<FormModalTabEnum, string> = new SfxMap<FormModalTabEnum, string>([
    [FormModalTabEnum.General, key + 'general'],
    [FormModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<FormModalTabEnum, IconData> = new SfxMap<FormModalTabEnum, IconData>([
    [FormModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [FormModalTabEnum.Options, { name: 'file-invoice', prefix: 'fas' }],
  ]);

  export const nextTab: SfxMap<FormModalTabEnum, FormModalTabEnum> = new SfxMap<FormModalTabEnum, FormModalTabEnum>([[FormModalTabEnum.General, FormModalTabEnum.Options]]);
}
