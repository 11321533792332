import { AuthServerConfig } from '../../generated/models/models';

export interface AuthServerConfigData {
  issuer: string;
  authorizationTokenEndpoint: string;
  clientId: string;
  endSessionEndpoint: string;
  scope: string;
}

export namespace AuthServerConfigData {
  export function mapFromApiValue(authData: AuthServerConfig): AuthServerConfigData {
    return {
      issuer: authData.issuer,
      authorizationTokenEndpoint: authData.authorizationTokenEndpoint,
      clientId: authData.clientId,
      endSessionEndpoint: authData.endSessionEndpoint,
      scope: authData.scope,
    };
  }
}
