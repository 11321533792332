import { createAction, props } from '@ngrx/store';

import { LazyLoadResponse } from '../../../../../core/models';
import { ReorganizeLibraryDocumentsRequest } from '../../../../../core/models/document/document-reorganize-request';
import { DocumentSummaryData } from '../../../../../core/models/document/document-summary';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';

// ***** Reorganize documents actions ******
export const loadReorganizeDocuments = createAction('[libDocDialog] load reorganize documents data');
export const loadReorganizeDocumentsSuccess = createAction(
  '[libDocDialog] load reorganize documents data success',
  props<{ data: LazyLoadResponse<DocumentSummaryData[]>; reset?: boolean }>(),
);
export const loadMoreReorganizeDocuments = createAction('[libDocDialog] load more reorganize documents data');
export const loadReorganizeDocumentsFail = createAction('[libDocDialog] load reorganize documents data fail', props<DialogToasterData>());

export const reorganizeDocuments = createAction('[libDocDialog] reorganize documents', props<{ queries: ReorganizeLibraryDocumentsRequest; count: number }>());
export const reorganizeDocumentsSuccess = createAction('[libDocDialog] reorganize documents success');
export const reorganizeDocumentsFail = createAction('[libDocDialog] reorganize documents fail', props<DialogToasterData>());
