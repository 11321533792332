/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RubricLightDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  rubricType?: RubricLightDTO.RubricTypeEnum;
  rubricOrder?: number;
  allowAddQuestion?: boolean;
  allowAddOption?: boolean;
  allowAddImage?: boolean;
}
export namespace RubricLightDTO {
  export type RubricTypeEnum = 'NORMAL' | 'TABLE' | 'TABLE_COLUMN';
  export const RubricTypeEnum = {
    NORMAL: 'NORMAL' as RubricTypeEnum,
    TABLE: 'TABLE' as RubricTypeEnum,
    TABLECOLUMN: 'TABLE_COLUMN' as RubricTypeEnum,
  };
}
