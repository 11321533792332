import { createAction, props } from '@ngrx/store';
import { BlockRhpDetailsData } from '../../../../../../../../core/models';
import { DialogToasterData } from '../../../../../../../../modules/sfx-dialog/state/dialog-toaster-data';

// View dialog actions
export const addElementRhp = createAction('[Rhp/Dialog] add new element rhp', props<{ team: BlockRhpDetailsData; createAnother: boolean }>());
export const addElementRhpSuccess = createAction('[Rhp/Dialog] add new element rhp success', props<{ createAnother: boolean }>());
export const addElementRhpFail = createAction('[Rhp/Dialog] add new element rhp fail', props<DialogToasterData>());

export const updateElementRhp = createAction('[Rhp/Dialog] update element rhp', props<{ team: BlockRhpDetailsData }>());
export const updateElementRhpSuccess = createAction('[Rhp/Dialog] update element rhp success');
export const updateElementRhpFail = createAction('[Rhp/Dialog] update element rhp fail', props<DialogToasterData>());

export const addElementRhpSkill = createAction('[Rhp/Dialog] add element rhp new skill');

export const getElementRhpDetailsFail = createAction('[Rhp/Dialog] get element rhp details fail', props<DialogToasterData>());
export const getElementRhpDetailsSuccess = createAction('[Rhp/Dialog] get element rhp details success', props<{ teamDetails: BlockRhpDetailsData }>());
