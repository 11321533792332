import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ErrorResponseData, LazyLoadResponse, ContributorImportData, ContributorSummaryData, SkillSummaryData, ContributorDetailsData } from '../../../../../core/models';

import * as fromActions from './dashboard-contributor-dialog.actions';

const adapter: EntityAdapter<ContributorImportData> = createEntityAdapter<ContributorImportData>({
  selectId: dContributor => dContributor.uuidEntity,
});

const reorganizeAdapter: EntityAdapter<ContributorSummaryData> = createEntityAdapter<ContributorSummaryData>({
  selectId: reorganizer => reorganizer.uuidEntity,
});

export interface ImportContributorDialogState extends EntityState<ContributorImportData> {
  totalCount: number;
  filteredTotalCount: number;
  loading: boolean;
  reset: boolean;
  error: ErrorResponseData;
}

export interface ReorganizeContributorsDialogState extends EntityState<ContributorSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export const importContributorsInitialState: ImportContributorDialogState = adapter.getInitialState<ImportContributorDialogState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
});

export const reorganizeContributorsInitialState: ReorganizeContributorsDialogState = reorganizeAdapter.getInitialState<ReorganizeContributorsDialogState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
});

export interface SkillsContributorDialogState {
  skillsData: LazyLoadResponse<SkillSummaryData[]>;
  skillDetailData: ContributorSummaryData;
  currentPdfId: string;
  reset: boolean;
}

const initialSkillsContributorDialogState = (): SkillsContributorDialogState => ({
  skillsData: {} as LazyLoadResponse<SkillSummaryData[]>,
  skillDetailData: {} as ContributorSummaryData,
  currentPdfId: null,
  reset: false,
});

export interface DashboardContributorDialogState {
  contributorDetails: ContributorDetailsData;
  importContributor: ImportContributorDialogState;
  skillsContributor: SkillsContributorDialogState;
  reorganizeContributor: ReorganizeContributorsDialogState;
  error: ErrorResponseData;
}

export const initialDashboardContributorDialogState = (): DashboardContributorDialogState => ({
  contributorDetails: { hierarchies: [] } as ContributorDetailsData,
  importContributor: importContributorsInitialState,
  skillsContributor: initialSkillsContributorDialogState(),
  reorganizeContributor: reorganizeContributorsInitialState,
  error: null,
});

const reducer = createReducer<DashboardContributorDialogState>(
  initialDashboardContributorDialogState(),
  on(fromActions.loadContributorSuccess, (state, { contributor }) => ({ ...state, contributorDetails: contributor })),
  on(
    fromActions.loadImportContributorListSuccess,
    (state, { contributors, totalCount, filteredTotalCount, reset }): DashboardContributorDialogState => {
      const newImportState: ImportContributorDialogState = { ...state.importContributor, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? adapter.setAll(contributors, newImportState) : adapter.addMany(contributors, newImportState);

      return { ...state, importContributor: newState };
    },
  ),
  on(fromActions.setSkillDetailData, (state, skill): DashboardContributorDialogState => ({ ...state, skillsContributor: { ...state.skillsContributor, skillDetailData: skill } })),
  on(fromActions.setCurrentPdfId, (state, { currentPdfId }): DashboardContributorDialogState => ({ ...state, skillsContributor: { ...state.skillsContributor, currentPdfId } })),
  on(
    fromActions.loadSkillContributorListSuccess,
    (state, { data, reset }): DashboardContributorDialogState => ({
      ...state,
      skillsContributor: {
        ...state.skillsContributor,
        reset,
        skillsData: reset ? data : { ...state.skillsContributor.skillsData, payload: [...state.skillsContributor.skillsData.payload, ...data.payload] },
      },
    }),
  ),
  on(fromActions.loadReorganizeContributorsList, state => ({ ...state, reorganizeContributor: { ...state.reorganizeContributor, loading: true } })),
  on(
    fromActions.loadReorganizeContributorsListSuccess,
    (state, { users, totalCount, filteredTotalCount, reset }): DashboardContributorDialogState => {
      const newReorganizeState: ReorganizeContributorsDialogState = { ...state.reorganizeContributor, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? reorganizeAdapter.setAll(users, newReorganizeState) : reorganizeAdapter.addMany(users, newReorganizeState);

      return { ...state, reorganizeContributor: newState };
    },
  ),
);

export function dashboardContributorDialogReducer(state: DashboardContributorDialogState | undefined, action: Action): DashboardContributorDialogState {
  return reducer(state, action);
}

export const selectAllContributors = adapter.getSelectors().selectAll;
export const selectAllReorganizeContributors = reorganizeAdapter.getSelectors().selectAll;
