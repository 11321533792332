/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApplicationEventDTO {
  userLibelle: string;
  natureAction: ApplicationEventDTO.NatureActionEnum;
  uuideFile: string;
  id: number;
  actionDate: Date;
  message: string;
}
export namespace ApplicationEventDTO {
  export type NatureActionEnum =
    | 'CREATE'
    | 'UPDATE'
    | 'DELETE'
    | 'ACCESS'
    | 'VIEW'
    | 'IMPORT'
    | 'IMPORT_IN_PROGRESS'
    | 'EXPORT'
    | 'EXPORT_IN_PROGRESS'
    | 'IMPORT_ERROR'
    | 'EXPORT_ERROR'
    | 'SQL_DUMP_IMPORT'
    | 'USER_SESSION'
    | 'LINK'
    | 'DUPLICATE_IN_PROGRESS'
    | 'DUPLICATE_ERROR'
    | 'DUPLICATE_SUCCESS'
    | 'BLOC_REDACTION'
    | 'BLOC_VERIFICATION'
    | 'BLOC_APROBATION'
    | 'BLOC_REJECTION'
    | 'BLOC_VALID'
    | 'ENABLE'
    | 'DISABLE'
    | 'EXPORT_DUMP_V2'
    | 'DEACTIVATE_SIGNATURE';
  export const NatureActionEnum = {
    CREATE: 'CREATE' as NatureActionEnum,
    UPDATE: 'UPDATE' as NatureActionEnum,
    DELETE: 'DELETE' as NatureActionEnum,
    ACCESS: 'ACCESS' as NatureActionEnum,
    VIEW: 'VIEW' as NatureActionEnum,
    IMPORT: 'IMPORT' as NatureActionEnum,
    IMPORTINPROGRESS: 'IMPORT_IN_PROGRESS' as NatureActionEnum,
    EXPORT: 'EXPORT' as NatureActionEnum,
    EXPORTINPROGRESS: 'EXPORT_IN_PROGRESS' as NatureActionEnum,
    IMPORTERROR: 'IMPORT_ERROR' as NatureActionEnum,
    EXPORTERROR: 'EXPORT_ERROR' as NatureActionEnum,
    SQLDUMPIMPORT: 'SQL_DUMP_IMPORT' as NatureActionEnum,
    USERSESSION: 'USER_SESSION' as NatureActionEnum,
    LINK: 'LINK' as NatureActionEnum,
    DUPLICATEINPROGRESS: 'DUPLICATE_IN_PROGRESS' as NatureActionEnum,
    DUPLICATEERROR: 'DUPLICATE_ERROR' as NatureActionEnum,
    DUPLICATESUCCESS: 'DUPLICATE_SUCCESS' as NatureActionEnum,
    BLOCREDACTION: 'BLOC_REDACTION' as NatureActionEnum,
    BLOCVERIFICATION: 'BLOC_VERIFICATION' as NatureActionEnum,
    BLOCAPROBATION: 'BLOC_APROBATION' as NatureActionEnum,
    BLOCREJECTION: 'BLOC_REJECTION' as NatureActionEnum,
    BLOCVALID: 'BLOC_VALID' as NatureActionEnum,
    ENABLE: 'ENABLE' as NatureActionEnum,
    DISABLE: 'DISABLE' as NatureActionEnum,
    EXPORTDUMPV2: 'EXPORT_DUMP_V2' as NatureActionEnum,
    DEACTIVATESIGNATURE: 'DEACTIVATE_SIGNATURE' as NatureActionEnum,
  };
}
