import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IconComponent } from '../../icon/icon.component';
import { MinDateTimeDirective } from '../../../directives/min-date-time-validator.directive';
import { NumberControlDirective } from '../../../directives/number-control.directive';
import { ToasterService } from '../../toaster/toaster.service';
import { SfxTooltipDirective } from '../../../directives/tooltip/sfx-tooltip.directive';

import { ToasterTypeEnum } from '../../../../../core/enums/utils/toaster-type.enum';

@Component({
  selector: 'sfx-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, IconComponent, ClipboardModule, SfxTooltipDirective, NumberControlDirective, MinDateTimeDirective],
})
export class CopyToClipboardComponent {
  @Input() text: string;

  public copied = false;

  constructor(private toasterService: ToasterService, private translate: TranslateService) {}

  public onCopyToClipboard(): void {
    if (!this.copied) {
      this.copied = true;
      this.toasterService.show({ type: ToasterTypeEnum.Success, title: this.translate.instant('global.copied') });

      setTimeout(() => {
        this.copied = false;
      }, 2500);
    }
  }
}
