import { SfxMap } from '../../utils/enum-utils';

export enum ImportAdrColumnEnum {
  Family = 'family',
  Risk = 'risk',
  Source = 'source',
  Action = 'action',
}

export namespace ImportAdrColumnEnum {
  export const allColumns = [ImportAdrColumnEnum.Family, ImportAdrColumnEnum.Risk, ImportAdrColumnEnum.Source, ImportAdrColumnEnum.Action];

  const key = 'blocks.adr.table.header.';
  export const toString: SfxMap<ImportAdrColumnEnum, string> = new SfxMap<ImportAdrColumnEnum, string>([
    [ImportAdrColumnEnum.Family, key + 'family'],
    [ImportAdrColumnEnum.Risk, key + 'risk'],
    [ImportAdrColumnEnum.Source, key + 'source'],
    [ImportAdrColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<ImportAdrColumnEnum, string> = new SfxMap<ImportAdrColumnEnum, string>([
    [ImportAdrColumnEnum.Family, 'family'],
    [ImportAdrColumnEnum.Risk, 'risk'],
    [ImportAdrColumnEnum.Source, 'source'],
  ]);
}
