import { ApplicationFileUpdateSkillRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';

export interface ApplicationFileUpdateSkill {
  skill: EntityLightData;
  filesUuidEntities: string[];
}

export namespace ApplicationFileUpdateSkill {
  export function mapToUpdateRequestApiValue(data: ApplicationFileUpdateSkill): ApplicationFileUpdateSkillRequestDTO {
    return {
      skill: { ...data.skill, id: undefined },
      applicationFilesUuidEntityList: data.filesUuidEntities,
    };
  }
}
