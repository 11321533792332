import { EntityChronoCreateRequestDTO } from '../../generated/models/entityChronoCreateRequestDTO';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum FieldNameEnum {
  RefExt = 1,
  RefInt = 2,
  ComplementRefInt = 3,
  ComplementRefExt = 4,
}

export namespace FieldNameEnum {
  export const values: SfxMap<BusinessClassNameEnum, FieldNameEnum[]> = new SfxMap<BusinessClassNameEnum, FieldNameEnum[]>(
    [
      [BusinessClassNameEnum.Project, [FieldNameEnum.RefExt, FieldNameEnum.RefInt]],
      [BusinessClassNameEnum.Flow, [FieldNameEnum.RefInt]],
      [BusinessClassNameEnum.Form, [FieldNameEnum.RefInt]],
      [BusinessClassNameEnum.Document, [FieldNameEnum.RefExt, FieldNameEnum.RefInt, FieldNameEnum.ComplementRefInt, FieldNameEnum.ComplementRefExt]],
      [BusinessClassNameEnum.TechnicalObject, [FieldNameEnum.RefExt, FieldNameEnum.RefInt]],
    ],
    [],
  );

  export const convertFromApiValue: SfxMap<EntityChronoCreateRequestDTO.FieldNameEnum, FieldNameEnum> = new SfxMap<EntityChronoCreateRequestDTO.FieldNameEnum, FieldNameEnum>([
    [EntityChronoCreateRequestDTO.FieldNameEnum.REFEXT, FieldNameEnum.RefExt],
    [EntityChronoCreateRequestDTO.FieldNameEnum.REFINT, FieldNameEnum.RefInt],
    [EntityChronoCreateRequestDTO.FieldNameEnum.COMPLEMENTREFEXT, FieldNameEnum.ComplementRefExt],
    [EntityChronoCreateRequestDTO.FieldNameEnum.COMPLEMENTREFINT, FieldNameEnum.ComplementRefInt],
  ]);

  export const convertToApiValue: SfxMap<FieldNameEnum, EntityChronoCreateRequestDTO.FieldNameEnum> = new SfxMap<FieldNameEnum, EntityChronoCreateRequestDTO.FieldNameEnum>([
    [FieldNameEnum.RefExt, EntityChronoCreateRequestDTO.FieldNameEnum.REFEXT],
    [FieldNameEnum.RefInt, EntityChronoCreateRequestDTO.FieldNameEnum.REFINT],
    [FieldNameEnum.ComplementRefExt, EntityChronoCreateRequestDTO.FieldNameEnum.COMPLEMENTREFEXT],
    [FieldNameEnum.ComplementRefInt, EntityChronoCreateRequestDTO.FieldNameEnum.COMPLEMENTREFINT],
  ]);

  const key = 'referenceTranslateKey.modals.new.';
  const refIntToString = (field: FieldNameEnum): string => (field === FieldNameEnum.RefInt ? key + 'entity.refInt' : null);

  export const toString = (field?: FieldNameEnum): SfxMap<BusinessClassNameEnum, string> =>
    new SfxMap<BusinessClassNameEnum, string>([
      [BusinessClassNameEnum.Project, field === FieldNameEnum.RefExt ? key + 'entity.refExt' : refIntToString(field)],
      [BusinessClassNameEnum.Flow, field === FieldNameEnum.RefInt ? key + 'flow.refInt' : null],
      [BusinessClassNameEnum.Form, field === FieldNameEnum.RefInt ? key + 'form.refInt' : null],
      [BusinessClassNameEnum.Document, getBusinessClassNameEnumToString(field, BusinessClassNameEnum.Document)],
      [BusinessClassNameEnum.TechnicalObject, getBusinessClassNameEnumToString(field, BusinessClassNameEnum.TechnicalObject)],
    ]);

  export const natureToFieldName = (field?: FieldNameEnum): SfxMap<BusinessClassNameEnum, FieldNameEnum> =>
    new SfxMap<BusinessClassNameEnum, FieldNameEnum>([
      [BusinessClassNameEnum.Project, field || FieldNameEnum.RefExt],
      [BusinessClassNameEnum.Flow, FieldNameEnum.RefInt],
      [BusinessClassNameEnum.Form, FieldNameEnum.RefInt],
      [BusinessClassNameEnum.Document, field || FieldNameEnum.RefExt],
      [BusinessClassNameEnum.TechnicalObject, field || FieldNameEnum.RefExt],
    ]);

  export const itemsResolver = (nature: BusinessClassNameEnum): Observable<SingleSelectItemData<FieldNameEnum>[]> =>
    of(
      values.getValue(nature).map(field => ({
        value: natureToFieldName(field).getValue(nature),
        title: toString(field).getValue(nature),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FieldNameEnum>, item: FieldNameEnum) => node.value === item;

  export function getBusinessClassNameEnumToString(field: FieldNameEnum, businessClassNameEnum?: BusinessClassNameEnum) {
    if (businessClassNameEnum === BusinessClassNameEnum.Document && field !== undefined) {
      if (FieldNameEnum.RefExt === field) {
        return key + 'document.refExt';
      }

      if (FieldNameEnum.RefInt === field) {
        return key + 'document.refInt';
      }

      if (FieldNameEnum.ComplementRefInt === field) {
        return key + 'document.complementRefInt';
      }

      return FieldNameEnum.ComplementRefExt === field ? key + 'document.complementRefExt' : null;
    }

    if (businessClassNameEnum === BusinessClassNameEnum.TechnicalObject) {
      if (field === FieldNameEnum.RefExt) {
        return key + 'technicalObject.refExt';
      }

      return field === FieldNameEnum.RefInt ? key + 'technicalObject.refInt' : null;
    }

    return null;
  }
}
