import { FormRecapResponseDTO } from '../../generated/models/models';
import { FormRubricLightData } from './form-rubric-light-data';

export interface FormSummaryDetails {
  name: string;
  rubrics: FormRubricLightData[];
}

export namespace FormSummaryDetails {
  export function mapFromApiValue(data: FormRecapResponseDTO): FormSummaryDetails {
    return {
      name: data.formName,
      rubrics: (data.rubrics || []).map(FormRubricLightData.mapFromApiValue),
    };
  }
}
