export const apiUrl = 'api/';
export const cypressUrl = 'cypress/';

export enum ApiUrlsEnum {
  // app file loader
  AppFileLoader = '1.5/application-files/download-file-by-uuide/{ fileUuidEntity }?access_token={ accessToken }',
  AppFileLoaderById = 'application-files/download-file/{ fileUuidEntity }?access_token={ accessToken }',
  AppFileLoaderByIdPrefix = 'application-files/download-file', // Note: needed to clean froala html content

  LazyProjectForms = '1.5/forms/by-project/lazy',
  LazyLibraryForms = '1.5/library-forms/lazy',
  LazyFormsToImport = '1.5/forms/to-import/lazy',
  LazyCategory = '1.5/categories/lazy',
  LazyDefaultCategory = '1.5/default-categories/lazy',
  ArchiveDefaultCategory = '1.5/families-categories/archive',
  LazyCategoryCount = '1.5/categories/count',
  LazyDefaultCategoryCount = '1.5/default-categories/count',
  refreshCategoriesFromConfig = '1.5/categories/by-project/{ projectUuidEntity }/update-defaultCategories',
  DeleteCategories = '1.5/categories/{ categoryUuid }',
  DeleteDefaultCategories = '1.5/default-categories/{ categoryUuid }',
  CategoryExistingParent = '1.5/categories/existing-parent',
  DefaultCategoryExistingParent = '1.5/default-categories/existing-parent',
  CategoryDetails = '1.5/categories/{ labelFamilyUuidEntity }',
  DefaultCategoryDetails = '1.5/default-categories/{ labelFamilyUuidEntity }',
  Categories = '1.5/categories',
  DefaultCategories = '1.5/default-categories',
  MoveCategory = '1.5/move-elements/category',
  MoveDefaultCategory = '1.5/move-elements/default-category',
  LazyFlows = '1.5/flows/lazy',
  FlowsForStepLink = '1.5/flows/step/{ stepUuidEntity }/to-link/lazy',
  LazyReferentialFlows = '1.5/flows/by-project/{ projectUuidEntity }/referential/lazy',
  FlowProjectReferential = '1.5/flows/project/referential',
  FlowFblResponse = '1.5/flows/project/responses',
  FlowDocumentFilter = '1.5/flows/document/tree/filter',
  FlowDocumentRfiFilter = '1.5/flows/document/rfi/tree/filter',
  FlowMapReferential = '1.5/flows/map/referential',
  LazyReferentialFlowsToImport = '1.5/flows/referential-to-import/lazy',
  ExecuteFlowList = '1.5/flows/execute-referential-flows',
  ExecuteFlows = '1.5/flows/{ flowUuidEntity }/execute',
  FlowHistoryEventsLazy = '1.5/flows/events/lazy',
  FlowDelete = '1.5/flows/{ flowUuidEntity }/details',
  GenerateFlowQrCode = '1.5/flows/{ flowUuidEntity }/qr-code-flow',
  FlowsStatus = '1.5/flows/customStatus',
  ReorganizeFlows = '1.5/flows/reorganize',
  RefreshFlow = '1.5/flows/update-from-source',
  DuplicateFlow = '1.5/flows/duplicate',
  MoveFlow = '1.5/flows/{flowUuidEntity}/move-to-pgac/{projectUuidEntity}',
  ProjectToDuplicateFlow = '1.5/projects/by-application/destination-flow-duplication',
  ProjectCustomStatusAffectation = '1.5/project/custom-status/affectation',
  EntityProjectCustomStatusFilter = '1.5/project/custom-status/filter',
  ProjectCustomStatusUpdate = '1.5/projects/customStatus',
  FlowDetails = '1.5/flows/{ flowUuidEntity }',
  FlowDetailsLight = '1.5/flows/light/{ flowUuidEntity }',
  FlowProperties = '1.5/flows/{ flowUuidEntity }/properties',
  FlowSourceProperties = '1.5/flows/{ flowUuidEntity }/flow-source/properties',
  ProjectDetails = '1.5/projects/{ projectUuidEntity }',
  RefreshReferentialFlow = '1.5/flows/referential/update',
  RefreshImportedReferentialFlows = '1.5/flows/update-referentials',
  Projects = '1.5/projects',
  Authenticate = '1.5/authenticate',
  Register = '1.5/0bddd663847ae9e8cfdb9b8694e9breg',
  onBoardingInit = '1.5/account/onboarding/{ key }/init',
  onBoardingFinish = '1.5/account/onboarding/{ key }/finish',
  ActivateAccount = '1.5/activate',
  LazyProjects = '1.5/projects/lazy',
  LazyBackups = '1.5/application-events/backup/mobile/lazy',
  LogsPush = '1.5/mobile-dump-errors',
  LazyReferentialProjects = '1.5/projects/referential/lazy',
  LazyProjectsForObjects = '1.5/projects/for-technical-object/lazy',
  ConvertToRefProject = '1.5/projects/{projectUuidEntity}/convert-to-folder',
  PrepareReferentialFlow = '1.5/projects/{projectUuidEntity}/prepare/referential',
  FolderExistence = '1.5/existFolder',
  ProjectCustomFields = '1.5/custom-field/business-class',
  SaveCustomFields = '1.5/custom-field/values',
  ListCustomFields = '1.5/custom-fields/fetch-by-uuid-entities',
  CheckTokenValidity = '1.5/check-token-validity',
  Application = '1.5/applications',
  DuplicateApplication = '1.5/create-applications-from-existing',
  CurrentApplication = '1.5/applications/currentApplication',
  UserAccount = '1.5/account',
  UserAccountPwdUpdate = '1.5/account/change-password',
  UsersApplication = '1.5/users/to-import/lazy',
  LibraryContributor = '1.5/users-operators/lazy',
  TrashContributors = '1.5/users-operators-trash/lazy',
  LibraryEquipments = '1.5/element-emms-library/lazy',
  LibraryEquipmentDetail = '1.5/element-emms-library/{ uuidEntity }',
  LibraryEquipmentViewDetail = '1.5/element-emms-library/view/{ uuidEntity }',
  LibraryEquipmentsCreate = '1.5/element-emms-library',
  LibraryEquipmentsUpdate = '1.5/element-emms-library',
  LibraryEquipmentsDelete = '1.5/element-emms-library/{ uuidEntity }',
  LibraryEquipmentsDuplicate = '1.5/element-emms-library/{ uuidEntity }/duplicate',
  LibraryEquipmentsProperties = '1.5/element-emms-library/{uuidEntity}/properties',
  LibraryEquipmentsReorganize = '1.5/element-emms-library/reorganize',
  LibraryEquipmentsArchive = '1.5/element-emms-library/archive',
  DeleteLibraryContributor = '1.5/users-operators/{ uuidEntity }',
  Client = '1.5/clients',
  TeamRolesV15 = '1.5/team-roles/by-project/{ projectUuidEntity }/lazy',
  FlowTeamRole = '1.5/users/project/affectation',
  PlanTeamRole = '1.5/users/plan/affectation',
  MapTeamRole = '1.5/users/map/affectation',
  TeamRole = '1.5/team-roles/{ uuidEntity }',
  UpdateTeamRole = '1.5/team-roles',
  ReorganizeTeamRoles = '1.5/team-roles/reorganize',
  ReorganizeContributorsUsers = '1.5/users/reorganize',
  TeamRoleUsers = '1.5/team-roles/by-project/{ projectUuidEntity }/users/lazy',
  LazyTablets = '1.5/devices/lazy',
  TabletDetails = '1.5/devices/{ tabletUuidEntity }',
  TabletProperties = '1.5/devices/{ tabletUuidEntity }/properties',
  CreateTablet = '1.5/devices',
  UpdateTablet = '1.5/devices',
  UnblockTablet = '1.5/users/{ tabletUuidEntity }/unblock',
  SendEmail = '1.5/users/on-boarding-device',
  DeleteTablet = '1.5/devices/{ uuidEntity }',
  TeamRoleTablets = '1.5/team-roles/by-project/{ projectUuidEntity }/devices/lazy',
  TabletQrCode = '1.5/team-roles/device/{ tabletUuidEntity }',
  LTabletQrCode = '1.5/users/generate-qrcode',
  UnlinkTablet = '1.5/users/unpair-device/{ tabletUuidEntity }',
  UnlinkProjectTablet = '1.5/users/project/{ projectUuidEntity }/unpair-device/{ tabletUuidEntity }',
  UnlinkMyTablet = '1.5/users/unpair-my-device',
  TabletToImport = '1.5/users/devices-to-import/lazy',
  CustomFields = '1.5/custom-field',
  TeamRoleByProject = '1.5/team-roles/by-project/{ projectUuidEntity }/team-role-hierarchy/lazy',
  TeamRoleFlow = '1.5/team-roles/by-project/{ projectUuidEntity }/flow/lazy',
  TeamRoleAffectation = '1.5/flows/{flowUuidEntity}/team-role-hierarchy',
  TeamRoleRestricted = '1.5/flows/{flowUuidEntity}/flow-team-role-hierarchies',

  // User Filters
  UserProjectFilter = '1.5/users/projects/filter/lazy',

  // Document Files Apis
  DownloadFile = '1.5/application-files/download-file-by-uuide/{ fileUuidEntity }',
  DownloadBackup = '1.5/application-files/download-backup/{ fileUuidEntity }',
  DownloadDocumentFlowBySection = '1.5/version-document-flows/by-section/{ sectionUuidEntity }',
  DownloadDocumentFlowByBlock = '1.5/blocs/{ blockUuidEntity }/generate-last-pdf',
  DownloadSummarySkillFile = '1.5/skills/by-user/{ userUuidEntity }/pdf',
  DownloadRubricTableFile = '1.5/rubrics/{rubricUuidEntity}/download-xlsx-structure',
  UploadFileByBusinessClassName = '1.5/application-files/upload',
  UploadEmmXlsFile = '1.5/element-emms-library/uploadXlsxFile',
  UploadOnUpdateSkill = '1.5/application-files/update-skill',
  DeleteFile = '1.5/application-files/{ uuidEntity }',
  RefreshDocumentFromConfig = '1.5/version-document-flow/{ vdfUuidEntity }/refresh-from-config',
  RefreshDocumentFromLastIndex = '1.5/version-document-flow/{ vdfUuidEntity }/refresh-from-last-vdf',

  DownloadInitialTramUSerFile = '1.0/users/template',
  UploadUserXlsFile = '1.5/users/upload-xlsx-file',
  DownloadInitialTramEmmFile = '1.5/element-emms-library/template',

  // for temporary application files
  GetLazyApplicationFileTmp = '1.5/application-file-tmp/lazy',
  DownloadTemporaryFile = '1.5/application-files-tmp/{ fileUuidEntity }/download',

  /** ******  Document Files Apis ************/
  // Flow Apis
  AddUpdateFlow = '1.5/flows',
  DocumentFlowsByFlow = '1.5/document-flows-project/by-flow/{ flowUuidEntity }',
  RefreshAllDocumentFlow = '1.5/document-flows-project/update-all-imported',
  RefreshDocumentFlow = '1.5/document-flows-project/{ documentUuidEntity }/update-imported',
  ImportDocuments = '1.5/document-flows-project/import-documents',
  ReorganizeDashboardDocuments = '1.5/document-flows/update-categories',
  MapConfig = '1.5/map/config',
  PgacConflict = '1.5/flows/conflicts',
  CreateGeometryElement = '1.5/map/geometry',
  GetGeometryElements = '1.5/map/by-project/{ projectUuidEntity }/lazy',
  DeleteGeometryElements = '1.5/map/geometry/{ uuide }',
  UploadGeoJsonFile = '1.5/map/{ projectUuidEntity }/import-from-geo-json',
  GetGeoJsonFileElements = '1.5/map/by-project/{projectUuidEntity}/geoJson',
  DeleteGeoJsonFileElements = '1.5/map/geo-json/{ elementUuidEntity }',
  DeactivateGeoJsonFile = '1.5/map/geo-json/{uuidEntity}/deactivate',
  ChangeGeoJsonColor = '1.5/map/geo-json',
  FindFlowByUuidEntity = '1.5/flows/{ flowUuidEntity }',
  UnlockFlowReferential = '1.5/flows/{ flowUuidEntity }/unlock',
  PublishProject = '1.5/projects/publish',
  GetSearchAddress = 'https://atlas.microsoft.com/search/address/json?api-version=1.0',
  DuplicateProject = '1.5/projects/{ projectUuidEntity }/duplicate',
  DuplicateFolderProject = '1.5/projects/{ folderUuidEntity }/duplicate-sub-folder',
  PropertiesProject = '1.5/projects/{ projectUuidEntity }/properties',
  ImportReferentialMemberFlow = '1.5/flows/import-member-referential-flows',
  ImportFlowContentTemplate = '1.5/flows/import-content-template',
  ImportFlowContentReferential = '1.5/flows/import-content-referential',
  GetLazyPgacEvents = '1.5/application-events/pgac/lazy',
  ExportEpsilonOutFile = '1.5/epsilonOut/export',
  ExportFileProcessing = '1.5/fileProcessing/export',
  PgacFileTreatment = '1.5/epsilonOut/treatment/{ fileUuidEntity }',
  GetCurrentAppConfigLight = '1.5/app-configs/current-config/light',
  GetCurrentAppConfigDetails = '1.5/app-configs/current-config',
  UpdateCurrentAppConfig = '1.5/app-configs/current-config',
  GetTimeZones = '1.5/app-configs/time-zones',
  GetLazyEpsilonOut = '1.5/epsilonOut/flow/{ projectUuidEntity }/lazy',
  RejectEpsilonOut = '1.5/epsilonOut/flow/reject',
  ActivateEpsilonOut = '1.5/epsilonOut/flow/{ epsilonOutUuidEntity }/activate',
  resetEpsilonState = '1.5/epsilonOut/flow/resetEpsilonStatus',
  EpsilonOutLocals = '1.5/epsilonOut/flow/locals',
  EpsilonOutLandmarks = '1.5/epsilonOut/flow/landmarks',
  resetPasswordInit = '1.5/account/reset-password/init',
  resetPasswordFinish = '1.0/account/reset-password/finish',
  EpsilonOutDetails = '1.5/epsilonOut/flow/{flowUuidEntity}',
  PasteEpsilonOut = '1.5/epsilonOut/flow/paste/{refFlowSource}/{refFlowDestination}',
  GetEpsilonOutLastUpdateDateFromForm = '1.5/epsilonOut/flow/{flowUuidEntity}/last-update-date-from-form',
  EpsilonOutUpdate = '1.5/epsilonOut/flow/update',
  EpsilonOutCreate = '1.5/epsilonOut/flow/create',
  UpdateEpsilonOutFromFormBusinessLink = '1.5/epsilonOut/flow/{flowUuidEntity}/update-from-fbl',
  LazyApplicationEventCustomStatus = '1.5/application-events/custom-status/lazy',
  customFileLight = '1.5/custom-file/light/lazy',
  FileProcessingTreatment = '1.5/fileProcessing/treatment',
  GetLazyCustomFileEvents = '1.5/application-events/custom-file/lazy',
  ExportManyProjectCsv = '1.5/responses/export-many/by-project/zip',
  Properties = '/api/1.5/properties',
  Affectation = '1.5/flows/{flowUuidEntity}/affectation',
  AffectationReporter = '1.5/flows/{flowUuidEntity}/affectation/reporter',
  AffectationAssignee = '1.5/flows/{flowUuidEntity}/affectation/assignee',
  AffectationTablet1 = '1.5/flows/{flowUuidEntity}/affectation/tablet1',
  AffectationTablet2 = '1.5/flows/{flowUuidEntity}/affectation/tablet2',
  AffectationTablet3 = '1.5/flows/{flowUuidEntity}/affectation/tablet3',
  AffectationCollaborators = '1.5/flows/{flowUuidEntity}/affectation/collaborators',
  AffectationDeleteCollaborator = '1.5/flows/{flowUuidEntity}/affectation/collaborators/{teamRoleUuidEntity}',
  // Object paths
  LazyTechnicalObjects = '1.5/technical-object/lazy',
  ObjectStatus = '1.5/technical-object/custom-status',
  ObjectDelete = '1.5/technical-object/{ objectUuidEntity }',
  ObjectDetails = '1.5/technical-object/{ objectUuidEntity }',
  ObjectDuplicate = '1.5/technical-object/duplicate',
  ObjectProperties = '1.5/technical-object/{ objectUuidEntity }/properties',
  RefreshObject = '1.5/technical-object/{ objectUuidEntity }/update',
  CreateUpdateObject = '1.5/technical-object',
  DeleteReferentialFlowInObject = '1.5/flows/technical-object/{ relationUuidEntity }',
  MoveObject = '1.5/move-elements/technical-object',
  CreateFlowUsingObject = '1.5/flows/using-technical-object',
  LazyReferentialFlowsToImportForObject = '1.5/flows/referential-to-import/technical-object/lazy',
  LazyFlowsByObject = '1.5/flows/by-technical-object/{ technicalObjectUuidEntity }/lazy',
  ImportReferentialFlowsToObject = '1.5/flows/import-list-referential/technical-object',

  // tags paths
  TagsAll = '1.5/tags/all',
  TagsLazy = '1.5/tags/lazy',
  TagsTree = '1.5/tags/tree',
  TagsLinks = '1.5/tags/links',
  TagDelete = '1.5/tags/{tagUuidEntity}',
  checkAuthority = '1.5/flows/{flowUuidEntity}/authorization',
  Tag = '1.5/tags',
  TagByUuidEntity = '1.5/tags/{tagUuidEntity}',
  MoveTag = '1.5/move-elements/tag',

  // Object paths */
  EntityChronoExist = '1.5/entity-chronos/business-class/exists',
  ImportTeamRoles = '1.5/team-roles/project/{projectUuidEntity}',
  ImportTablets = '1.5/team-roles/project-devices/{projectUuidEntity}',
  ImportForms = '1.5/forms/import-list',
  FormLocalCopy = '1.5/local-forms',
  FormUsed = '1.5/local-forms/{ formUuidEntity }/already-use',
  FormReplyDetails = '1.5/form/{formUuidEntity}/details',
  RefreshForms = '1.5/forms/refresh',
  RefreshForm = '1.5/forms/{ formUuidEntity }/refresh',
  PropertiesForm = '1.5/forms/{ formUuidEntity }/properties',
  DeleteLibraryForm = '1.5/library-forms/{ formUuidEntity }',
  ArchiveLibraryForm = '1.5/forms-library/archive',
  CreateUpdateLibraryForm = '1.5/library-forms',
  UpdateLocalForm = '1.5/local-forms',
  UpdateLocalFormTemplate = '1.5/local-forms/{ formUuidEntity }/templateParams',
  UpdateLibraryFormTemplate = '1.5/library-forms/{ formUuidEntity }/templateParams',
  LibraryFormDuplicate = '1.5/library-forms/duplicate',
  LibraryFormReorganize = '1.5/library-forms/reorganize',
  PhaseMobileAppReadOnly = '1.5/phases/{ phaseUuidEntity }/readOnlyOnMobileApp',
  DisablePhase = '1.5/phases/update/disable',
  RefreshTemplateParams = '1.5/version-document-flow/refresh-old-vdf-from-app-config',
  LazyResponses = '1.5/responses/lazy',
  ResponsesByProject = '1.5/forms/projects/{ projectUuidEntity }/all-responses',
  DuplicateResponse = '1.5/responses/duplicate',
  ExportManyExcelFile = '1.5/responses/export-many/zip',
  ExportManyImageFile = '1.5/responses/export/images',
  ExportRubricTable = '1.5/rubrics/{ rubricUuidEntity }/fill-rubric-response',
  ExportLibraryForm = '1.5/forms/export/xslx/{ formUuidType }/all',
  SkillsLazy = '1.5/skills/lazy',
  AuthServerConfig = '1.5/auth-server-config',
  ExchangeCode = '1.5/exchange-code',
  RefreshToken = '1.5/refresh-token',
  AuthMethod = '1.5/auth-method',
  DeleteFamilies = '1.5/families/{uuide}',
  FamilyExistingParent = '1.5/families/existing-parent',
  FamilyDetails = '1.5/families/{ labelFamilyUuidEntity }',
  Families = '1.5/families',
  LazyFamilyCount = '1.5/families/count',
  LazyFamily = '1.5/families/lazy',
  NatureLabelFamily = '1.5/families/nature',
  NatureDefaultLabelFamily = '1.5/default-categories/nature',
  NatureCategory = '1.5/categories/nature',
  ArchiveLabelFamily = '1.5/families-categories/archive',
  MoveFamily = '1.5/move-elements/family',
  GetUser = '1.5/users-operators/{ uuidEntity }',
  GetUserProperties = '1.5/users/{ userUuidEntity }/properties',
  resendOnBoardingEmail = '1.5/users/resendOnBoardingEmail/{ userUuidEntity }',
  deactivateUser = '1.5/user-operator/{ userUuidEntity }/deactivate',
  deactivateTabletOrOperator = '1.5/device-operator/{ tabletUuidEntity }/deactivate',
  unblockUser = '1.5/users/{ userUuidEntity }/unblock',
  restoreUser = '1.5/users/{ userUuidEntity }/restore',
  restoreOperator = '1.5/operators/{ operatorUuidEntity }/restore',
  reactivateTabletOrOperator = '1.5/device-operator/{ tabletUuidEntity }/reactivate',
  getOperatorSecureQrCode = '1.5/users/{operatorUuidEntity}/qr-code-signature',
  getOperatorsSecureQrCode = '1.5/users/qr-code-signature/all',
  MoveApplicabilityUser = '1.5/users/{ userUuidEntity }/move-applicability',
  UpdateUser = '1.5/users-operators',
  CreateUser = '1.5/users-operators',
  ConvertExternalUser = '1.5/external-users/{userUuidEntity}/to-users',
  CreateSkill = '1.5/skills',
  DeleteSkill = '1.5/skills/{ uuidEntity }',
  GetSkill = '1.5/skills/{ uuidEntity }',
  PutSkill = '1.5/skills',
  LazyClients = '1.5/clients/lazy',
  ProjectLazyClients = '1.5/clients/projects/filter/lazy',
  ClientDetails = '1.5/clients/{ uuidEntity }',

  // Users
  loadUsersByProject = '1.5/users/current-on-project/{ projectUuidEntity }',
  loadUserDsiSignature = '1.5/blocs/{blocUuidEntity}/dsi-signature-user',

  // externalApi V1
  GetOrDeleteExternalApi = '1.5/users/external_api_access/{uuidEntity}',
  LazyExternalApi = '1.5/users/external_api_access/lazy',
  ExternalApi = '1.5/users/external_api_access',

  // externalApi V2
  LazyExternalApiV2 = '2.0/api_consumer/external_api_access/lazy',
  GetOrDeleteExternalApiV2 = '2.0/api_consumer/external_api_access/{uuidEntity}',
  ExternalApiV2 = '2.0/api_consumer/external_api_access',
  ExternalApiV2Properties = '2.0/api_consumer/properties/{uuidEntity}',

  // response forms
  Response = '1.5/responses',
  UpdateFormStatus = '1.5/forms/customStatus',
  ResetResponseState = '1.5/responses/reset-validation',
  DeleteResponse = '1.5/responses/{responseUuidEntity}',
  DeleteBlockFormResponse = '1.5/responses/fbl/{responseUuidEntity}',

  //  summary form
  SummaryFormRubrics = '1.5/forms/recap-rubrics',
  RubricQuestionsRecap = '1.5/rubrics/{ rubricUuidEntity }/recaps',

  // Document versions
  DocumentFlowByResponse = '1.5/document-flows-project/by-response',
  DocumentFlowByProject = '1.5/document-flows-project/by-project/lazy',
  SharedDocumentFlows = '1.5/document-flows/shared/lazy',
  DownloadLastPdfVersion = '1.5/document-flows-project/{ documentUuidEntity }/download-last-pdf',
  DownloadPdfByLastDocVersion = '1.5/version-document-flows/{ docVersionUuidEntity }/download',
  GenerateVersionDocumentFlow = '1.5/version-document-flows/{ docVersionUuidEntity }/generate',
  DeleteDashDocument = '1.5/document-flows-project/{ documentUuidEntity }/archive',
  downloadDocumentFilesZip = '1.5/version-document-flows/{documentUuidEntity}/zip',

  // Facturation
  ExportUserFile = '1.5/board/metrics/custom/download',

  // Dashboard lists
  LazyLists = '1.5/lists/lazy',
  Lists = '1.5/lists',
  List = '1.5/lists/{listUuidEntity}',
  RefreshList = '1.5/lists/update-from-library',
  LazyEmmList = '1.5/lists/element-emms/to-import/lazy',
  LazyImportedEmmList = '1.5/lists/element-emms/flow/lazy',
  LazyAdrList = '1.5/lists/element-adr/to-import/lazy',
  LazyImportedAdrList = '1.5/lists/element-adr/flow/lazy',
  PutEmmList = '1.5/lists/element-emms/assign',
  PutAdrList = '1.5/lists/element-adr/assign',
  ElementEmm = '1.5/lists/element-emms/{ uuidEntity }?uuideProject={ uuidProject }',
  ElementAdr = '1.5/lists/element-adr/{ uuidEntity }?uuideProject={ uuidProject }',
  Emm = '1.5/lists/element-emms/{uuidEntity}?uuideProject={ uuidProject }',
  Adr = '1.5/lists/element-adr/{uuidEntity}?uuideProject={ uuidProject }',
  AdrParade = '1.5/parades-library/by-elementAdr/{ uuidEntity }',

  // Risks
  LazyRisks = '1.5/element-adrs-library/lazy',
  risk = '1.5/element-adrs-library',
  paradeList = '1.5/parades-library/by-elementAdr/{riskUuidEntity}',
  ViewParadeList = '1.5/parades-library/view/by-elementAdr/{ riskUuidEntity }',
  DuplicateRisk = '1.5/element-adrs-library/{riskUuidEntity}/duplicate',
  PropertiesRisk = '1.5/element-adrs-library/{riskUuidEntity}/properties',
  ReorganizeRisk = '1.5/element-adrs-library/reorganize',
  DuplicateParade = '1.5/parades-library/duplicate/{paradeUuidEntity}',
  DeleteParade = '1.5/parades-library/{paradeUuidEntity}',
  RiskByUuidEntity = '1.5/element-adrs-library/{riskUuidEntity}',
  RiskViewByUuiEntity = '1.5/element-adrs-library/view/{riskUuidEntity}',
  parade = '1.5/parades-library',
  addSpecificParade = '1.5/parades/flow-content',
  RiskXlsFile = '1.5/element-adrs-library/uploadXlsxFile',
  InitialAdrTemplateFile = '1.5/element-adrs-library/template',
  LibraryRiskArchive = '1.5/element-adrs-library/archive',

  // Trash
  TrashLazy = '1.5/archive/lazy',
  TrashRestore = '1.5/archive/restore',

  // customStatus
  CustomStatus = '1.5/custom-status',
  GetLazyCustomStatus = '1.5/custom-status/lazy',
  GetLazyFamiliesForCustomStatus = '1.5/families/custom-status/lazy',
  GetCustomStatusDetails = '1.5/custom-status/{ customStatusUuidEntity }',
  LazyCustomStatusCount = '1.5/custom-status/count',
  DeleteCustomStatus = '1.5/custom-status/{uuide}',
  CheckExistingDefaultStatus = '1.5/custom-status/existing-default',
  moveCustomStatus = '1.5/move-elements/custom-status',
  FlowRefProjectCustomStatus = '1.5/project/custom-status/referential/flow/filter',
  SignatureCustomStatus = '1.5/project/custom-status/signature/flow/filter',
  FlowRefToImportCustomStatus = '1.5/project/referential-to-import/custom-status/flow/filter',
  ArchiveCustomStatus = '1.5/custom-status/archive',

  // form custom status
  LibraryFromCustomStatusFilter = '1.5/library/custom-status/form/filter',
  FromSummaryCustomStatusFilter = '1.5/library/custom-status/form-summary/filter',
  LibraryFromCustomStatusAffectation = '1.5/library/custom-status/form/affectation',
  TechnicalObjectCustomStatusAffectationAffectation = '1.5/library/custom-status/technical-object/affectation',
  ProjectFormResponseCustomStatusFilter = '1.5/project/custom-status/response-form/filter',
  ProjectFieldFeedbackFormResponseCustomStatusFilter = '1.5/project/custom-status/response-form/general/filter',
  ProjectFormResponseCustomStatusAffectation = '1.5/project/form-response/affectation',
  FlowCustomStatusFilter = '1.5/project/custom-status/flow/filter',
  FlowMapCustomStatusFilter = '1.5/custom-status/project/map/flow/filter',
  FlowPlanCustomStatusFilter = '1.5/custom-status/project/plan/flow/filter',
  FlowCustomStatusAffectation = '1.5/project/custom-status/flow/affectation',
  ElementPrCustomStatusAffectation = '1.5/project/custom-status/element-pr/affectation',

  // customFields
  CustomFieldVariableName = '1.5/custom-field/variable-name',
  SettingsCustomFields = '1.5/custom-field',
  CreateCustomFieldPreVerification = '1.5/custom-field/pre-verify-creation',
  UpdateCustomFieldPreVerification = '1.5/custom-field/pre-verify-update',
  ProjectFamiliesCustomFields = '1.5/families/project/{ projectUuidEntity }/custom-fields/lazy',
  GetCustomFieldsDetails = '1.5/custom-field/{ customFieldsUuidEntity }',
  LazyCustomFieldsCount = '1.5/custom-field/count',
  DeleteCustomFields = '1.5/custom-field/{uuide}',
  moveCustomFields = '1.5/move-elements/custom-field',
  advancedSearchCustomFields = '1.5/custom-fields/flow/advanced-search',
  ArchiveCustomFields = '1.5/custom-field/archive',

  // Signature Urls:
  SignatureCount = '1.5/board/metrics/signatories/{ flowUuidEntity }',
  SignatureFlowPhases = '1.5/flows/{ flowUuidEntity }/phases/signatories/lazy',
  SignatureFlowSteps = '1.5/steps/lazy/{ phaseUuidEntity }',

  // App Initialization
  BackendVersion = '1.5/application-web-version',
  StaticAssets = '1.5/static-assets',

  // Reference
  ReferenceLazy = '1.5/entity-chronos/lazy',
  DeleteReference = '1.5/entity-chronos/{ referenceUuidEntity }',
  CreateReference = '1.5/entity-chronos',
  GetReference = '1.5/entity-chronos/{ referenceUuidEntity }',
  UpdateReference = '1.5/entity-chronos',
  ArchiveReference = '1.5/entity-chronos/archive',

  // trams
  TramsLazy = '1.5/template-params/lazy',
  DeleteTrams = '1.5/template-params/{tramsUuidEntity}',
  getTram = '1.5/template-params/{tramsUuidEntity}',
  tram = '1.5/template-params',

  // Flow content
  LazyPhases = '1.5/flows/{ flowUuidEntity }/phases/lazy',
  Phases = '1.5/phases',
  DuplicatePhase = '1.5/phases/duplicate',
  MovePhase = '1.5/move-elements/phase',
  Phase = '1.5/phases/{ phaseUuidEntity }',
  PhaseAttributes = '1.5/phases/{ phaseUuidEntity }/custom-attributes',
  LazySteps = '1.5/phases/{ phaseUuidEntity }/steps/lazy',
  StepsList = '1.5/steps/by-phase/{ phaseUuidEntity }',
  Steps = '1.5/steps',
  DuplicateStep = '1.5/steps/duplicate',
  MoveStep = '1.5/move-elements/step',
  Step = '1.5/steps/{ stepUuidEntity }',
  StepAttributes = '1.5/steps/{ stepUuidEntity }/custom-attributes',
  LinkFlowToStep = '1.5/steps/link-flow',
  RemoveLinkedFlow = '1.5/steps/{ stepUuidEntity }/remove-linked-flow',
  EnableStep = '1.5/steps/enable',
  StepMobileAppReadOnly = '1.5/steps/{ stepUuidEntity }/readOnlyOnMobileApp',
  DisableStep = '1.5/steps/update/disable',
  activateBlock = '1.5/steps/reactivate/bloc',
  StepDetailsViewData = '1.5/steps/details-view',
  StepNavigations = '1.5/navigation-flows/by-step/lazy',
  StepNavigation = '1.5/navigation-flows',
  DeleteStepNavigation = '1.5/navigation-flows/ { navigationUuidEntity }',
  MoveStepNavigation = '1.5/move-elements/navigation-flow',
  ValidateBlocks = '1.5/flows/validate-blocs',
  ToRedactionBlocks = '1.5/flows/redaction-blocs',
  CheckRejection = '1.5/blocs/check-rejection/{blockUuidEntity}',
  UpdateFlowContentLibrary = '1.5/flows/update-library-elements',
  UpdateFlowDocument = '1.5/flows/{flowUuidEntity}/blocs/all-documents',
  BlockProperties = '1.5/blocs/{blockUuidEntity}/properties',
  BlockValidation = '1.5/blocs/validation',
  ResetFlow = '1.5/flows/{flowUuidEntity}/reset',
  BlockStatusHistoryLazy = '1.5/blocs/validation-history/lazy',
  BlockCopyPaste = '1.5/blocs/copyPaste',
  BlockHideMultiPrTitle = '1.5/blocs/hideMultiPrTitle',
  toggleStatusAndSignatureMultiPr = '1.5/blocs/multi-pr/status-signatory',
  toggleElementEmm = '1.5/element-emms/update/disable',
  toggleElementAdr = '1.5/element-adrs/update/disable',
  toggleElementForm = '1.5/form-businesslinks/update/disable',
  toggleElementPR = '1.5/element-pr/update/disable',
  toggleElementRHP = '1.5/bloc-rhps/update/disable',
  toggleElementHideOnExport = '1.5/element-pr/{uuidEntity}/hide-on-export',
  froalaLicenseKey = '1.5/froala-licences',
  // Block equipment
  LazyBlockEquipment = '1.5/bloc-emms/lazy',
  LazyEmmToImport = '1.5/element-emms/to-import/lazy',
  UpdateBlockEquipment = '1.5/bloc-emms',
  BlockEquipment = '1.5/bloc-emms/{ equipmentUuidEntity }',
  ImportEquipment = '1.5/bloc-emms/import',

  // Block risk
  LazyBlockRisk = '1.5/bloc-adrs/lazy',
  LazyAdrToImport = '1.5/element-adrs/to-import/lazy',
  UpdateBlockRisk = '1.5/bloc-adrs',
  BlockRisk = '1.5/bloc-adrs/{ riskUuidEntity }',
  LazyParade = '1.5/parades/lazy',
  ImportRisk = '1.5/bloc-adrs/import',

  // Block team
  LazyBlockTeam = '1.5/bloc-rhps/lazy',
  BlockTeams = '1.5/bloc-rhps',
  BlockTeam = '1.5/bloc-rhps/{ teamUuidEntity }',

  // Block form
  LazyBlockForm = '1.5/bloc-forms/lazy',
  LazyFormsToImportForBlock = '1.5/forms/to-import-for-bloc/lazy',
  BlockForm = '1.5/bloc-forms/{ formUuidEntity }',
  BlockFormInitialRubric = '1.5/bloc-forms/initial-rubric/{ formUuidEntity }',
  UpdateBlockForm = '1.5/bloc-forms',
  LazyBlockFormResponsesInProgress = '1.5/bloc-forms/light-responses/lazy',
  LazyBlockFormRubric = '1.5/bloc-forms/rubrics/lazy',
  BlockFormGuide = '1.5/bloc-forms/guide',
  BlockFormResponse = '1.5/bloc-forms/response',
  BlockFormCheckResponses = '1.5/bloc-forms/check-responses',
  BlockFormImport = '1.5/bloc-forms/import',
  LazyBlockFormResponse = '1.5/bloc-forms/responses/lazy',
  LazyBlockImportableFormResponses = '1.5/bloc-forms/responses-to-import/lazy',
  ImportFormResponses = '1.5/bloc-forms/{fblUuidEntity}/responses/import',
  BlockFormHistoryLazy = '1.5/bloc-forms/response-history/lazy',
  BlockFormManage = '1.5/block-forms/manage',
  CountFormResponse = '1.5/form-responses/{formUuidEntity}/by-project/{projectUuidEntity}',
  BlockFormRubricsToRestrict = '1.5/form-businesslinks/{formBusinessLinkUuid}/rubric-permissions',
  OrphanResponses = '1.5/forms/orphans-responses',
  RepairResponses = '1.5/forms/orphans-responses/repair',
  FormsToRepairResponse = '1.5/forms/orphans-responses/formBusinessLinks',
  OrphanResponsesCustomStatus = '1.5/forms/orphans-responses/status/filter',

  // Block PR
  BlockPr = '1.5/bloc-prs/{ blockPrUuidEntity }',
  UpdateBlockContent = '1.5/blocs/content',

  // Block multi PR
  LazyPrElements = '1.5/element-pr/lazy',
  UpdateElementPr = '1.5/element-pr',
  GetElementPr = '1.5/element-pr/{uuidEntity}',
  GetElementPrContent = '1.5/element-pr/{uuidEntity}/content',
  UpdateElementPrStatus = '1.5/element-pr/{uuidEntity}/status/{uuidEntityStatus}',
  UpdateElementPrObservation = '1.5/element-pr/{uuidEntity}/observation',
  UpdateElementPrSignatory = '1.5/element-pr/signatory',
  DeleteElementPrSignatory = '1.5/element-pr/{elementPrUuidEntity}/signatory/{signatureUuidEntity}',
  DeleteElementPr = '1.5/element-pr/{uuidEntity}',
  GetElementPrSignatory = '1.5/element-pr/signatory/{uuidEntity}',
  MoveElementPr = '1.5/move-elements/element-pr',
  DuplicateElementPr = '1.5/element-pr/duplicate',
  AutoCompleteSignatoryElementPr = '1.5/element-pr/{uuidEntity}/autocomple-signatory',

  // Block Txt
  BlockTxt = '1.5/bloc-txts/{ blockTxtUuidEntity }',
  BlockTxts = '1.5/bloc-txts',

  // Block dsi
  BlockDsi = '1.5/bloc-dsis/{ blockUuidEntity }/element-dsi',
  UpdateBlockDsi = '1.5/bloc-dsis',
  SignatureDetails = '1.5/bloc-dsis/signatures/{ signatureUuidEntity }',
  Signatures = '1.5/bloc-dsis/signatories',
  ProjectDocument = '1.5/document-flows-project/to-import/lazy',
  BlockDocuments = '1.5/blocs/documents/lazy',
  AssignBlockDocuments = '1.5/blocs/documents/import',
  UnassignBlockDocument = '1.5/blocs/documents/remove',
  UpdateAssignmentBlockDocument = '1.5/blocs/documents/update',
  BlockDsiHistoryLazy = '1.5/bloc-dsis/signatories-history/lazy',
  BlockMultiPrHistoryLazy = '1.5/blocs/multi-pr/history/lazy',

  // Block Object
  LazyBlockObject = '1.5/bloc-object',
  UnlinkObject = '1.5/bloc-object/{blocUuidEntity}/unlink-technical-object/{technicalObjectUuidEntity}',
  LazyObjectsToImport = '1.5/technical-object/to-import-for-bloc/lazy',
  ImportObjects = '1.5/bloc-object/import',

  // Dashboard Documents
  LoadDashboardDocument = '1.5/document-flows-project/{ documentUuidEntity }',
  AddDashboardDocument = '1.5/document-flows-project',
  EditDashboardDocument = '1.5/document-flows-project',
  DeleteDashboardDocument = '1.5/document-flows-project/{ documentUuidEntity }/archive',
  CheckUniqueDocFlow = '1.5/document-flows-project/check-unique',
  CreateDocsFromForm = '1.5/document-flows-project/form/create-multiple',
  CreateDocsFromFlow = '1.5/document-flows-project/flow/create-multiple',
  CompressSelectedDocs = '1.5/version-document-flows/{ documentUuidEntity }/download-zip',
  DownloadDocument = '1.5/shared-links/{ shareLinkUuidEntity }/download',
  DownloadGeneratedDocument = '1.5/shared-links/{ shareLinkUuidEntity }/application-file-tmps/{ applicationFileUuidEntity }/download',
  DocumentFilters = '1.5/document-flows-project/{ projectUuidEntity }/state/filter',
  LinkDocumentStateFilters = '1.5/document-flows-project-bloc/{projectUuidEntity}/state/filter',
  UpdateDocumentDiagramImage = '1.5/document-flows-project/{ documentUuidEntity }/set-diagram-image',

  // Library Documents
  LoadLibraryDocument = '1.5/document-flows-library/{ documentUuidEntity }',
  AddLibraryDocument = '1.5/document-flows-library',
  EditLibraryDocument = '1.5/document-flows-library',
  DeleteLibraryDocument = '1.5/document-flows-library/{ documentUuidEntity }',
  LibraryDocuments = '1.5/document-flows/shared/lazy',
  ReorganizeLibraryDocuments = '1.5/document-flows-library/update-categories',
  DocumentLibraryFilters = '1.5/document-flows-library/state/filter',

  // Application file
  deleteDocumentFile = '1.5/application-files/{fileUuidEntity}',
  downloadDocumentFile = '1.5/application-files/download-file-by-uuide/{fileUuidEntity}',
  loadDocumentFile = '1.5/application-files/document-autre/{ documentUuidEntity }',
  moveDocumentFile = '1.5/move-elements/document/files',
  moveLibDocumentFile = '1.5/move-elements/library/document/files',

  UploadDocumentVersion = '1.5/version-document-flows/{ documentUuidEntity }/upload',
  VersionDocumentFlow = '1.5/version-document-flows/lazy',
  GetVdfIndex = '1.5/version-document-flows/index/lazy',
  AddVdf = '1.5/version-document-flows/free-version/add',
  addSubIndex = '1.5/version-document-flows/free-version/add-sub-index',
  Diffusion = '1.5/version-document-flows/diffusion',
  EditDiffusionFree = '1.5/version-document-flows/visa-free',
  DiffusionBpa = '1.5/version-document-flows/diffusion-bpa',
  ConvertToBpa = '1.5/version-document-flows/visa/bpa/{ docVersionUuidEntity }',
  CheckFlowFormResponse = '1.5/document-flows-project/{ docVersionUuidEntity }/new-form-responses',
  ConvertToBpeValid = '1.5/version-document-flows/visa/bpe-valid',
  DeleteLastDocumentVersion = '1.5/version-document-flows/last-version-by-document/{ documentUuidEntity }',
  CancelDiffusion = '1.5/version-document-flows/{ versionDocumentUuidEntity }/cancel-diffusion',
  CancelDiffusionBpa = '1.5/version-document-flows/{ versionDocumentUuidEntity }/cancel-diffusion-bpa',
  particularization = '1.5/version-document-flows/update-complement-reference',
  uploadVdfFile = '1.5/version-document-flows/{ docVersionUuidEntity }/upload',
  uploadVdfFileInResponse = '1.5/version-document-flows/{ docVersionUuidEntity }/response-client',
  downloadVdfFileInResponse = '1.5/version-document-flows/{ docVersionUuidEntity }/downloadResponse',
  uploadClientCoverPage = '1.5/version-document-flows/{ docVersionUuidEntity }/client-cover-page',
  downloadClientCoverPage = '1.5/version-document-flows/{ docVersionUuidEntity }/downloadClientCoverPage',
  clientResponse = '1.5/version-document-flows/visa/client-response',
  updateClientResponse = '1.5/version-document-flows/client-response',
  clientResponseBpa = '1.5/version-document-flows/visa/client-response-bpa',
  refreshContributors = '1.5/version-document-flows/refresh-linked-users/{docVersionUuidEntity}',
  loadDocCollaborators = '1.5/version-document-flows/contributors',

  // RFI Documents
  LoadRfiDocument = '1.5/document-flows-rfi/{ folderUuidEntity }',
  AddRfiDocument = '1.5/document-flows-rfi',
  EditRfiDocument = '1.5/document-flows-rfi',
  deleteRFIDocument = '1.5/document-flows-rfi/{ folderUuidEntity }',
  checkDiffusedDocuments = '1.5/document-flows-rfi/{uuidEntity}/undiffused',
  DocumentRfiFilters = '1.5/document-flows-rfi/{projectUuidEntity}/state/filter',
  DocumentRfiSectionFilters = '1.5/document-flows-rfi/sections/{uuidEntity}/state/filter',

  // Custom file
  LazyCustomFiles = '1.5/custom-file/lazy',
  DeleteCustomFile = '1.5/custom-file/{ customFileUuidEntity }',
  CustomFileDetails = '1.5/custom-file/{ customFileUuidEntity }',
  CustomFile = '1.5/custom-file',

  // Custom cell
  LazyCustomCells = '1.5/custom-cell/lazy',
  DeleteCustomCell = '1.5/custom-cell/{ customCellUuidEntity }',
  CustomCellDetails = '1.5/custom-cell/{ customCellUuidEntity }',
  CustomCell = '1.5/custom-cell',
  MoveCustomCell = '1.5/move-elements/custom-cell',

  // cypress
  AppConfig = '1.5/cypress-data/config',
  CypressUsers = '1.5/cypress-data/users',
  CypressTokenRegister = '1.5/token-register',
  CypressRegister = '1.5/register',
  CypressSecurityDataStock = '1.5/data-stock/security/generate',
  CypressFFBDataStock = '1.5/data-stock/fast-feedback/generate',
  // Dashboard Closing folder
  Rfis = '1.5/document-flows-rfi/{ folderUuidEntity }',
  RfiProperties = '1.5/document-flows-rfi/{ folderUuidEntity }/properties',
  RfisLazy = '1.5/document-flows-rfi/lazy',
  RfisSectionsLazy = '1.5/rfis/sections/lazy',
  RfisSectionDownloadZipLargeFile = '1.5/rfis/sections/{sectionUuidEntity}/zip-large-files',
  RfisSectionDocumentsLazy = '1.5/rfis/sections/documents/lazy',
  RfisSectionEmmsLazy = '1.5/rfis/sections/emms/lazy',
  RfisSectionEmmsLazyPdf = '1.5/rfis/sections/emms/pdf/lazy',
  RfisSectionEntity = '1.5/rfis/sections/{ sectionUuidEntity }',
  RfisSections = '1.5/rfis/sections',
  ReorganizeRfisSections = '1.5/rfis/sections/documents/reorder',
  RfisSectionsCertificate = '1.5/rfis/sections/certificate-reference',
  RfisSectionsLinkFlow = '1.5/rfis/sections/link-flow',
  RfiDocumentsToImport = '1.5/document-flows-rfi/sections/import-documents/lazy',
  RfisImportDocuments = '1.5/rfis/sections/import-documents',
  RfisMoveDocument = '1.5/move-elements/section-document',
  RfisMoveSectionEmm = '1.5/move-elements/section-element-emm',
  RfisVerification = '1.5/document-flows-rfi/{ sectionUuidEntity }/verification',
  EmmByFlow = '1.5/element-emms/by-flow/lazy',
  MoveDashboardSection = '1.5/move-elements/section',
  RfisRefreshDocuments = '1.5/document-flows-rfi/sections/refresh/{sectionUuidEntity}',
  RfisRefreshEmms = '1.5/rfis/sections/refresh/emm/{sectionUuidEntity}',
  // Revision
  RevisionLazy = '1.5/revision/lazy',
  RevisionEventLazy = '1.5/revision-event/revision/lazy',
  Revision = '1.5/revision',
  RevisionByUuidEntity = '1.5/revision/{revisionUuidEntity}',
  RevisionClose = '1.5/revision/close',
  RevisionOpen = '1.5/revision/re-open',

  // setting wording configuration
  WordingConfigsLazy = '1.5/wording-configurations/lazy',
  WordingConfigsAffectation = '1.5/wording-configurations/affectation',
  WordingConfigsDefault = '1.5/wording-configurations/default',
  CreateWordingConfig = '1.5/wording-configurations',
  UpdateWordingConfig = '1.5/wording-configurations',
  GetWordingConfig = '1.5/wording-configurations/{wordingConfigUuidEntity}',
  DeleteWordingConfig = '1.5/wording-configurations/{wordingConfigUuidEntity}',

  LanguageConfigsLazy = '1.5/wording-configuration-languages/lazy',
  CreateLanguageConfig = '1.5/wording-configuration-languages',
  UpdateLanguageConfig = '1.5/wording-configuration-languages',
  GetLanguageConfig = '1.5/wording-configuration-languages/{languageConfigUuidEntity}',
  DeleteLanguageConfig = '1.5/wording-configuration-languages/{languageConfigUuidEntity}',

  LoadDocWordingConfig = '1.5/wording-configurations/label-family/{labelFamilyUuidEntity}',

  // Setting folder
  FoldersLazy = '1.5/rfi-templates/lazy',
  DeleteFolder = '1.5/rfi-templates/{ folderUuidEntity }',
  DeleteSection = '1.5/rfi-templates/sections/{ sectionUuidEntity }',
  SectionsLazy = '1.5/rfi-templates/sections/lazy',
  CreateFolder = '1.5/rfi-templates',
  UpdateFolder = '1.5/rfi-templates',
  GetFolder = '1.5/rfi-templates/{ folderUuidEntity }',
  CreateSection = '1.5/rfi-templates/sections',
  UpdateSection = '1.5/rfi-templates/sections',
  GetSection = '1.5/rfi-templates/sections/{ sectionUuidEntity }',
  MoveSection = '1.5/move-elements/section-template',

  // Sequence
  Sequence = '1.5/sequence',
  SequenceLazy = '1.5/sequence/lazy',
  SequencePhaseLazy = '1.5/sequence/{ sequenceUuidEntity }/phases/lazy',
  SequenceDelete = '1.5/sequence/{ sequenceUuidEntity }',
  updateSequencePhase = '1.5/sequence/phase',
  linkSequenceToStep = '1.5/sequence/link-step',

  // Plan
  PlanLazy = '1.5/plan/lazy',
  Plan = '1.5/plan',
  PlanByUuidEntity = '1.5/plan/{ planUuidEntity }',

  // Temporal elements plan
  FlowForPlan = '1.5/flows/plan/{ planUuidEntity }/to-link/lazy',
  ElementPlanTemporalImport = '1.5/element-plan/temporal/import',
  TemporalPlanGroupedBy = '1.5/element-plan/temporal/grouped-by',
  ElementPlanTemporal = '1.5/element-plan/temporal',
  TemporalElementPlanLazy = '1.5/element-plan/temporal/lazy',
  ElementPlanLink = '1.5/element-plan-link',
  ElementPlanMove = '1.5/move-elements/element-plan',
  ElementPlanLinks = '1.5/element-plan-link/{ uuidEntity }',

  // default
  DefaultElementPlanLazy = '1.5/element-plan/default/{ projectUuidEntity }/lazy',
  DefaultPlanGroupedBy = '1.5/element-plan/default/{ projectUuidEntity }/grouped-by',
  ElementPlanDefault = '1.5/element-plan/default',

  // Scheduler elements plan
  UsersForPlan = '1.5/team-roles/scheduling/plan/{ planUuidEntity }/users/to-link/lazy',
  DevicesForPlan = '1.5/team-roles/scheduling/plan/{ planUuidEntity }/devices/to-link/lazy',
  ElementPlanSchedulerImportFromUser = '1.5/element-plan/scheduling/import/from-user',
  ElementPlanSchedulerImportFromTablet = '1.5/element-plan/scheduling/import/from-tablet',
  ElementPlanScheduler = '1.5/element-plan/scheduling',
  SchedulingElementPlanLazy = '1.5/element-plan/scheduling/lazy',
  CreateSchedulerElementPlan = '1.5/element-plan/scheduling/create',

  Milestone = '1.5/milestone',
  MilestoneByUuid = '1.5/milestone/{ mileStoneUuidEntity }',
  Milestones = '1.5/milestone/plan/{ planUuidEntity }',

  ElementPlanLight = '1.5/element-plan/temporal/light/lazy',
  ElementPlan = '1.5/element-plan/{ uuidEntity }',
  DeleteTeamRole = '1.5/element-plan/scheduling/{ planUuidEntity }/team-role/{ teamRoleUuidEntity }',

  PlanTeamRoleLazy = '1.5/team-roles/{projectUuidEntity}/flows/plan/lazy',
  PlanCollaboratorsAssignment = '1.5/flows/{flowUuidEntity}/collaborators',
  // to validate all flowForm blocs
  validateFlowFormBlocs = '1.5/validate-flow-form-blocs',

  // dashboard documents
  DashboardDocumentProperties = '1.5/document-flows-project/{ documentUuidEntity }/properties',
  checkUpdatedDocuments = '1.5/document-flows-project/by-lda/{documentFlowUuidEntity}/not-updated',

  // Form
  PreviewPdf = '1.5/forms/{ formUuidEntity }/preview-pdf',
  FormDetails = '1.5/forms/{ formUuidEntity }',
  FormLight = '1.5/forms/light',
  GetLazyLightForms = '1.5/forms/light/lazy',
  LazyRubrics = '1.5/forms/{ formUuidEntity }/rubrics',
  LazyQuestions = '1.5/library-questions/lazy',
  Rubric = '1.5/library-rubrics',
  RubricResponses = '1.5/rubrics/responses',
  ValidateResponse = '1.5/forms/responses',
  CheckResponse = '1.5/forms/responses/{ responseUuidEntity }/check',
  CheckRubric = '1.5/rubrics/{ rubricUuidEntity }/check',
  Question = '1.5/library-questions',
  LibraryOption = '1.5/library-options',
  LibraryOptionSignatory = '1.5/library-option-signatories',
  RubricByUuidEntity = '1.5/rubrics/{ rubricUuidEntity }',
  DeleteRubric = '1.5/library-rubrics/{ rubricUuidEntity }',
  QuestionsByRubric = '1.5/rubrics/{ rubricUuidEntity }/questions',
  QuestionByUuidEntity = '1.5/library-questions/{ questionUuidEntity }',
  LibraryQuestionGenericParams = '1.5/library-questions/generic-params',
  LibraryOptionByUuidEntity = '1.5/library-options/{ optionUuidEntity }',
  LibraryOptionSignatoryByUuidEntity = '1.5/library-option-signatories/{ optionUuidEntity }',
  MoveQuestion = '1.5/move-elements/library-questions',
  MoveRubric = '1.5/move-elements/library-rubrics',
  MoveOption = '1.5/move-elements/library-options',
  DuplicateRubric = '1.5/library-rubrics/{ rubricUuidEntity }/duplicate',
  DuplicateQuestion = '1.5/library-questions/{ questionUuidEntity }/duplicate',
  QuestionsTree = '1.5/rubrics/{ rubricUuidEntity }/questions/tree',
  UpdateQuestionName = '1.5/library-questions/name',
  UpdateOptionName = '1.5/library-options/name',
  DeleteOptionByNodeOrder = '1.5/table/{ rubricUuidEntity }/library-options/by-nodeOrder/{ optionNodeOrder }',
  AddNewQuestion = '1.5/library-questions/add-new-question',
  AddNewQuestionForResponse = '1.5/rubric-questions/add-new-question',
  AddNewOption = '1.5/library-options/add-new-option',
  AddNewOptionForResponse = '1.5/rubric-options/add-new-option',
  RubricImages = '1.5/application-files/rubric/{ rubricUuidEntity }',
  QuestionImages = '1.5/application-files/rubric-question/{ questionUuidEntity }',
  OptionImages = '1.5/application-files/rubric-option/{ optionUuidEntity }',
  UploadRubricFile = '1.5/application-files/rubric/upload',
  UploadQuestionFile = '1.5/application-files/rubric-question/upload',
  UploadOptionFile = '1.5/application-files/rubric-option/upload',
  UpdateExistingImage = '1.5/application-files/update-image',
  UpdateExistingFileById = '1.5/application-files/update-file',
  UsersForForm = '1.5/users/form/lazy',
  optionValues = '1.5/options/value',
  EvaluateOption = '1.5/rubric-options/{ optionUuidEntity }/evaluate',
  UnifyCells = '1.5/library-questions/{ questionUuidEntity }/unify-options',
  DivideCells = '1.5/library-questions/{ questionUuidEntity }/divide-option',
  DuplicateLastCell = '1.5/library-options/duplicate',
  FormRubrics = '1.5/forms/recap-rubrics',
  UpdateLockedFormRubricVersion = '1.5/version/rubric-option',
  QuestionChangesHistory = '1.5/version/rubric-options/{ uuideRubricQuestion }',

  // Metrics
  MetricsFlows = '1.5/progression/{ projectUuidEntity }/flows',
  MetricsBlocks = '1.5/progression/{ projectUuidEntity }/blocs',
  MetricsSignatures = '1.5/progression/{ projectUuidEntity }/signatures',
  MetricsDocuments = '1.5/progression/{ projectUuidEntity }/documents',

  // Shared link
  InitAuth = '1.5/shared-links/init-auth/{ shareUuidEntity }',
  FormFinish = '1.5/shared-links/{ shareUuidEntity }/form/finish',
  FormValidate = '1.5/shared-links/{ shareUuidEntity }/form/validate',
  CreateFormContribution = '1.5/shared-links/{ shareUuidEntity }/form/create-contribution',
  SaveContributorInfo = '1.5/external-contributors/{ contributorUuidEntity }',
  Login = '1.5/shared-links/auth/{ shareUuidEntity }',
  CheckSessionValidity = '1.5/shared-links/check/{ shareUuidEntity }',
  ShareDocInfo = '1.5/shared-links/{ shareUuidEntity }/document/get-info',
  ShareFormInfo = '1.5/shared-links/{ shareUuidEntity }/form/get-info',
  CheckUser = '1.5/shared-links/check-user',
  AutoCompleteUser = '1.5/shared-links/users/auto-complete',
  ShareHistory = '1.5/shared-links/history',
  DeleteSharedLink = '1.5/shared-links/{ shareUuidEntity }',
  SharedLink = '1.5/shared-links',
  ResendSharedLink = '1.5/shared-links/{ uuidEntity }/resend',
  CheckForSharedQuestion = '1.5/forms/{ formUuidEntity }/at-least-one-question-shared',
  FormFinishLater = '1.5/shared-links/{ shareUuidEntity }/form/pregenerate',

  // kpis
  KpiGlobal = '1.5/kpi/global',
  KpiPreparation = '1.5/kpi/preparation',
  KpiAudience = '1.5/kpi/audience',
  KpiOffice = '1.5/kpi/office',
  KpiField = '1.5/kpi/field',
  KpiForms = '1.5/kpi/form',
  KpiFlows = '1.5/kpi/flows',
  KpiGlobalLicenses = '1.5/kpi/licences/global',
  KpiLicensesDetails = '1.5/kpi/licences/details',

  // External users
  ExternalUser = '1.5/external-users/{ userUuidEntity }',
  ExternalUsersLazy = '1.5/external-users/lazy',

  // label family 2.0
  ProjectLabelFamilyAffectation = '1.5/label-families/project/tree/affectation',
  ProjectLabelFamilyFilter = '1.5/label-families/project/tree/filter',
  FlowLabelFamilyAffectation = '1.5/label-families/flow/tree/affectation',
  FlowLabelFamilyFilter = '1.5/label-families/flow/tree/filter',
  FlowPlanLabelFamilyFilter = '1.5/label-families/plan/flow/tree/filter',
  FlowMapLabelFamilyFilter = '1.5/label-families/map/flow/tree/filter',
  FlowReferentialLabelFamilyFilter = '1.5/label-families/flow/referential/tree/filter',
  DocumentProjectLabelFamilyFilter = '1.5/label-families/project/document/tree/filter',
  DocumentRfiProjectLabelFamilyFilter = '1.5/label-families/document/rfi/tree/filter',
  DocumentLibraryLabelFamilyFilter = '1.5/label-families/library/document/tree/filter',
  DocumentLabelFamilyAffectation = '1.5/label-families/document/tree/affectation',
  FormProjectLabelFamilyFilter = '1.5/label-families/project/form/tree/filter',
  FormLibraryLabelFamilyFilter = '1.5/label-families/library/form/tree/filter',
  FormLabelFamilyAffectation = '1.5/label-families/form/tree/affectation',
  AdrProjectLabelFamilyFilter = '1.5/label-families/list/adr/tree/filter ',
  AdrLibraryLabelFamilyFilter = '1.5/label-families/library/adr/tree/filter',
  AdrLabelFamilyAffectation = '1.5/label-families/library/adr/tree/affectation',
  EmmProjectLabelFamilyFilter = '1.5/label-families/list/emm/tree/filter ',
  EmmLibraryLabelFamilyFilter = '1.5/label-families/library/emm/tree/filter',
  EmmLabelFamilyAffectation = '1.5/label-families/library/emm/tree/affectation',
  DeviceProjectLabelFamilyFilter = '1.5/label-families/project/device/tree/filter',
  DevicePlanLabelFamilyFilter = '1.5/label-families/plan/device/tree/filter',
  DeviceLibraryLabelFamilyFilter = '1.5/label-families/library/device/tree/filter',
  DeviceLibraryLabelFamilyAffectation = '1.5/label-families/library/device/tree/affectation',
  TeamProjectLabelFamilyFilter = '1.5/label-families/project/team/tree/filter',
  TeamPlanLabelFamilyFilter = '1.5/label-families/plan/team/tree/filter',
  TeamLibraryLabelFamilyFilter = '1.5/label-families/library/team/tree/filter',
  TeamLibraryLabelFamilyAffectation = '1.5/label-families/library/team/tree/affectation',
  RhpProjectLabelFamilyFilter = '1.5/label-families/project/rhp/tree/filter',
  RhpPlanLabelFamilyFilter = '1.5/label-families/plan/rhp/tree/filter',
  RhpLibraryLabelFamilyFilter = '1.5/label-families/library/rhp/tree/filter',
  RhpLibraryLabelFamilyAffectation = '1.5/label-families/library/rhp/tree/affectation',
  LegalProjectLabelFamilyFilter = '1.5/label-families/project/legal/tree/filter',
  LegalLibraryLabelFamilyFilter = '1.5/label-families/library/legal/tree/filter',
  LegalLibraryLabelFamilyAffectation = '1.5/label-families/library/legal/tree/affectation',
  SkillProjectLabelFamilyFilter = '1.5/label-families/project/skill/tree/filter',
  SkillPlanLabelFamilyFilter = '1.5/label-families/plan/skill/tree/filter',
  SkillLibraryLabelFamilyFilter = '1.5/label-families/library/skill/tree/filter',
  SkillLibraryLabelFamilyAffectation = '1.5/label-families/library/skill/tree/affectation',
  ContractProjectLabelFamilyFilter = '1.5/label-families/project/contract/tree/filter',
  ContractLibraryLabelFamilyFilter = '1.5/label-families/library/contract/tree/filter',
  ContractLibraryLabelFamilyAffectation = '1.5/label-families/library/contract/tree/affectation',
  ActivityDomainProjectLabelFamilyFilter = '1.5/label-families/project/activity-domain/tree/filter',
  ActivityDomainLibraryLabelFamilyFilter = '1.5/label-families/library/activity-domain/tree/filter',
  ActivityDomainLibraryLabelFamilyAffectation = '1.5/label-families/library/activity-domain/tree/affectation',
  TechnicalObjectLibraryLabelFamilyFilter = '1.5/label-families/library/technical-object/tree/filter',
  TechnicalObjectLibraryLabelFamilyAffectation = '1.5/label-families/library/technical-object/tree/affectation',
  TechnicalObjectLibraryFlowLabelFamilyFilter = '1.5/label-families/technical-object/flow/tree/filter',
  ParentLabelFamilyAffectation = '1.5/label-families/parents/affectation',
  LinkedFlowLabelFamilyFilter = '1.5/label-families/step/flow/linked/filter',

  // applicability v2
  ApplicabilityAffectation = '1.5/applicabilities/affectation',
  ApplicabilityAdrFilter = '1.5/applicabilities/library/adr/filter',
  ApplicabilityEmmFilter = '1.5/applicabilities/library/emm/filter',
  ApplicabilityDocumentFilter = '1.5/applicabilities/library/document/filter',
  ApplicabilityFormFilter = '1.5/applicabilities/library/form/filter',
  ApplicabilityUserFilter = '1.5/applicabilities/library/user-operator/filter',
  ApplicabilityDeviceFilter = '1.5/applicabilities/library/device/filter',
  ApplicabilityProjectFilter = '1.5/applicabilities/library/project/filter',
  ApplicabilityTechnicalObjectFilter = '1.5/applicabilities/library/technical-object/filter',
  ApplicabilityTagFilter = '1.5/applicabilities/library/tag/filter',
  ApplicabilityProjectUserOperatorFilter = '1.5/applicabilities/project/user-operator/filter',
  ApplicabilityProjectDeviceFilter = '1.5/applicabilities/project/device/filter',
  ApplicabilityProjectFormFilter = '1.5/applicabilities/project/form/filter',

  // category v2
  DocumentProjectCategoryAffectation = '1.5/categories/project/document/tree/affectation',
  DocumentProjectCategoryFilter = '1.5/categories/project/document/tree/filter',
  FlowProjectCategoryAffectation = '1.5/categories/project/flow/tree/affectation',
  FlowProjectCategoryFilter = '1.5/categories/project/flow/tree/filter',
  FlowMapProjectCategoryFilter = '1.5/categories/map/flow/tree/filter',
  FlowPlanCategoryFilter = '1.5/categories/plan/flow/tree/filter',
  HierarchyProjectCategoryAffectation = '1.5/categories/project/hierarchy/tree/affectation',
  HierarchyProjectCategoryFilter = '1.5/categories/project/hierarchy/tree/filter',
  ListProjectCategoryAffectation = '1.5/categories/project/list/tree/affectation',
  ListProjectCategoryFilter = '1.5/categories/project/list/tree/filter',
  DocumentLibraryCategoryAffectation = '1.5/categories/library/document/tree/affectation',
  DocumentLibraryCategoryFilter = '1.5/categories/library/document/tree/filter',
  HierarchyLibraryCategoryAffectation = '1.5/categories/library/hierarchy/tree/affectation',
  HierarchyLibraryCategoryFilter = '1.5/categories/library/hierarchy/tree/filter',
  ParentCategoryAffectation = '1.5/categories/library/parents/affectation',
  ParentProjectCategoryAffectation = '1.5/categories/project/parents/affectation',
  FlowReferentialProjectCategoryFilter = '1.5/categories/project/flow/referential/filter',
  DocumentRfiCategoryFilter = '1.5/categories/document/rfi/tree/filter',
  LinkedFlowCategoryFilter = '1.5/categories/step/flow/linked/filter',
  ArchiveCategory = '1.5/families-categories/archive',
  ContributorProjectHierarchies = '1.5/categories/project/hierarchy/tree/affectation/{teamRoleUuidEntity}',

  // general organization config
  OrganizationConfiguration = '1.5/organization-config/{uuidOrganization}',
  UpdateOrganizationConfig = '1.5/organization-config',
  RepairLockedOptions = '1.5/repair-locked-options',

  // link flow filter
  LinkFlowLabelFamiliesFilter = '1.5/label-families/link-flow/tree/filter',
  LinkFlowCategoriesFilter = '1.5/categories/link-flow/tree/filter',
  LinkFlowCustomStatusesFilter = '1.5/custom-status/link-flow/tree/filter',

  // Schedule apis
  ScheduleList = '1.5/schedulings/lazy/{flowUuidEntity}',
  AddSchedule = '1.5/schedulings',
  UpdateSchedule = '1.5/schedulings/{schedulingUuidEntity}',
  ScheduleDetails = '1.5/schedulings/{schedulingUuidEntity}',
  ArchiveSchedule = '1.5/schedulings/{schedulingUuidEntity}/activate',
  StartSchedule = '1.5/schedulings/{schedulingUuidEntity}/start',
  EndSchedule = '1.5/schedulings/{schedulingUuidEntity}/end',
  DuplicateSchedule = '1.5/schedulings/{schedulingUuidEntity}/duplicate',
  DeleteSchedule = '1.5/schedulings/{schedulingUuidEntity}',
  LinkToFlowInProjectSchedulingsFilter = '1.5/schedulings/linked-to-flow-in-project',

  // Schedule tasks apis
  ScheduleTasks = '1.5/scheduling-items/lazy/{ projectUuidEntity }',
  GenerateScheduleTasksFlows = '1.5/scheduling-items/generate-flows',
  UpdateTaskPriority = '1.5/scheduling-items/update-priority/{taskUuidEntity}',

  // zendesk
  ZendeskApiUrl = 'https://siteflow.zendesk.com/api/',
  ZendeskCreateOrUpdateUser = 'v2/users/create_or_update',
  ZendeskCreateOrUpdateOrganization = 'v2/organizations/create_or_update',
  ZendeskSingleSignOnToken = '1.5/zendesk/single-sign-on/generate/token',
  ZendeskEndUserAuthToken = '1.5/zendesk/end-user-auth/generate/token',

  // application
  CreateApplication = '1.5/create-applications',
  SearchApplicationsLazy = '1.5/applications/lazy',
  SearchApplications = '1.5/applications',

  // Custom Attributes
  CustomAttribute = '1.5/custom-attributes',

  // Companies
  Companies = '1.5/companies',
  CompanyById = '1.5/companies/{ companyUuidEntity }',
  ParentCompany = '1.5/parent-company',
  UserCompaniesFilter = '1.5/users/companies',
  OperatorCompaniesFilter = '1.5/operators/companies',
  TabletCompaniesFilter = '1.5/devices/companies',
  LibraryFormCompaniesFilter = '1.5/library-forms/companies',
  DashCollaboratorCompaniesFilter = '1.5/project/{ projectUuidEntity }/users/companies',
  DashTabletCompaniesFilter = '1.5/project/{ projectUuidEntity }/devices/companies',

  // Diagram
  CreateDiagram = '1.5/diagrams',
  Diagram = '1.5/diagrams/{ diagramUuidEntity }',
  DiagramByProject = '1.5/diagrams/{ projectUuidEntity }',
  DiagramFlows = '1.5/diagrams/{ diagramUuidEntity }/flows',
  DiagramFlowsToImport = '1.5/diagrams/{ diagramUuidEntity }/flows-to-import',
}
