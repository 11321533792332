import { createAction, props } from '@ngrx/store';
import { BusinessClassNameEnum } from '../../../../../core/enums';

import { ShareLinkData, ShareHistoryData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

export const removeCheckedUser = createAction('[ShareDialog/API] remove checked user');
export const checkUser = createAction('[ShareDialog/API] check new user', props<{ email: string; checkedUsers: string[] }>());
export const checkUserSuccess = createAction('[ShareDialog/API] check new user success', props<{ email: string }>());
export const checkUserFail = createAction('[ShareDialog/API] check new user fail', props<DialogToasterData>());

export const loadShareHistory = createAction('[ShareDialog/API] load share history');
export const loadShareHistorySuccess = createAction('[ShareDialog/API] load share history success', props<{ history: ShareHistoryData[] }>());
export const loadShareHistoryFail = createAction('[ShareDialog/API] load share history fail', props<DialogToasterData>());

export const deleteSharedLink = createAction('[ShareDialog/API] delete shared link', props<{ uuidEntity: string }>());
export const deleteSharedLinkSuccess = createAction('[ShareDialog/API] delete shared link success', props<{ uuidEntity: string }>());
export const deleteSharedLinkFail = createAction('[ShareDialog/API] delete shared link fail', props<DialogToasterData>());
export const stopLoadingButton = createAction('[ShareDialog/API] stop loading button');

export const shareLink = createAction('[ShareDialog/API] share link', props<{ data: ShareLinkData }>());
export const shareLinkSuccess = createAction('[ShareDialog/API] share link success', props<DialogToasterData>());
export const shareLinkFail = createAction('[ShareDialog/API] share link fail', props<DialogToasterData>());

export const resendSharedLink = createAction('[ShareDialog/API] share dialog', props<{ uuidEntity: string; businessClass: BusinessClassNameEnum }>());
export const resendSharedLinkSuccess = createAction('[ShareDialog/API] share dialog success', props<DialogToasterData>());
export const resendSharedLinkFail = createAction('[ShareDialog/API] share dialog fail', props<DialogToasterData>());

export const sendShareLink = createAction(
  '[ShareDialog/API] send share link',
  props<{ data: ShareLinkData; elementUuidEntity: string; fblUuidEntity: string; businessClass: string }>(),
);
