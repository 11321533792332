import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { RubricOptionDetailsResponseDTO } from '../../generated/models/rubricOptionDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { QuestionTypeEnum } from '../question/question-type.enum';

export enum ValidationNatureEnum {
  MoreThan = 1,
  MoreThanEqual = 2,
  LessThen = 3,
  LessThanEqual = 4,
  Equal = 5,
  NotEqual = 6,
  Between = 7,
  NotIncludedBetween = 8,
  BeforeDate = 9,
  AfterDate = 10,
  IncludedBetweenDates = 11,
  NotIncludedBetweenDates = 12,
}

export namespace ValidationNatureEnum {
  export const displayMaxValue = [
    ValidationNatureEnum.Between,
    ValidationNatureEnum.NotIncludedBetween,
    ValidationNatureEnum.IncludedBetweenDates,
    ValidationNatureEnum.NotIncludedBetweenDates,
  ];

  export const numberValidation: ValidationNatureEnum[] = [
    ValidationNatureEnum.MoreThan,
    ValidationNatureEnum.MoreThanEqual,
    ValidationNatureEnum.LessThen,
    ValidationNatureEnum.LessThanEqual,
    ValidationNatureEnum.Equal,
    ValidationNatureEnum.NotEqual,
    ValidationNatureEnum.Between,
    ValidationNatureEnum.NotIncludedBetween,
  ];

  const values = (questionType: QuestionTypeEnum): ValidationNatureEnum[] =>
    compact([
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.MoreThan,
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.MoreThanEqual,
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.LessThen,
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.LessThanEqual,
      ValidationNatureEnum.Equal,
      ValidationNatureEnum.NotEqual,
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.Between,
      [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) && ValidationNatureEnum.NotIncludedBetween,
      QuestionTypeEnum.validationDate.includes(questionType) && ValidationNatureEnum.BeforeDate,
      QuestionTypeEnum.validationDate.includes(questionType) && ValidationNatureEnum.AfterDate,
      QuestionTypeEnum.validationDate.includes(questionType) && ValidationNatureEnum.IncludedBetweenDates,
      QuestionTypeEnum.validationDate.includes(questionType) && ValidationNatureEnum.NotIncludedBetweenDates,
    ]);

  export const toString: SfxMap<ValidationNatureEnum, string> = new SfxMap<ValidationNatureEnum, string>([
    [ValidationNatureEnum.MoreThan, 'forms.builder.label.moreThan'],
    [ValidationNatureEnum.MoreThanEqual, 'forms.builder.label.moreThanEqual'],
    [ValidationNatureEnum.LessThen, 'forms.builder.label.lessThen'],
    [ValidationNatureEnum.LessThanEqual, 'forms.builder.label.lessThanEqual'],
    [ValidationNatureEnum.Equal, 'forms.builder.label.equal'],
    [ValidationNatureEnum.NotEqual, 'forms.builder.label.notEqual'],
    [ValidationNatureEnum.Between, 'forms.builder.label.between'],
    [ValidationNatureEnum.NotIncludedBetween, 'forms.builder.label.notIncludedBetween'],
    [ValidationNatureEnum.BeforeDate, 'forms.builder.label.beforeDate'],
    [ValidationNatureEnum.AfterDate, 'forms.builder.label.afterDate'],
    [ValidationNatureEnum.IncludedBetweenDates, 'forms.builder.label.includedBetweenDates'],
    [ValidationNatureEnum.NotIncludedBetweenDates, 'forms.builder.label.notIncludedBetweenDates'],
  ]);

  export const validationInfo: SfxMap<ValidationNatureEnum, string> = new SfxMap<ValidationNatureEnum, string>([
    [ValidationNatureEnum.MoreThan, 'forms.builder.validationInfo.moreThan'],
    [ValidationNatureEnum.MoreThanEqual, 'forms.builder.validationInfo.moreThanEqual'],
    [ValidationNatureEnum.LessThen, 'forms.builder.validationInfo.lessThen'],
    [ValidationNatureEnum.LessThanEqual, 'forms.builder.validationInfo.lessThanEqual'],
    [ValidationNatureEnum.Equal, 'forms.builder.validationInfo.equal'],
    [ValidationNatureEnum.NotEqual, 'forms.builder.validationInfo.notEqual'],
    [ValidationNatureEnum.Between, 'forms.builder.validationInfo.between'],
    [ValidationNatureEnum.NotIncludedBetween, 'forms.builder.validationInfo.notIncludedBetween'],
    [ValidationNatureEnum.BeforeDate, 'forms.builder.validationInfo.beforeDate'],
    [ValidationNatureEnum.AfterDate, 'forms.builder.validationInfo.afterDate'],
    [ValidationNatureEnum.IncludedBetweenDates, 'forms.builder.validationInfo.includedBetweenDates'],
    [ValidationNatureEnum.NotIncludedBetweenDates, 'forms.builder.validationInfo.notIncludedBetweenDates'],
  ]);

  export const mapFromApiValue: SfxMap<RubricOptionDetailsResponseDTO.NatureValidationEnum, ValidationNatureEnum> = new SfxMap<
    RubricOptionDetailsResponseDTO.NatureValidationEnum,
    ValidationNatureEnum
  >([
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.MORETHAN, ValidationNatureEnum.MoreThan],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.MORETHANEQUAL, ValidationNatureEnum.MoreThanEqual],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.LESSTHAN, ValidationNatureEnum.LessThen],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.LESSTHANEQUAL, ValidationNatureEnum.LessThanEqual],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.EQUAL, ValidationNatureEnum.Equal],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTEQUAL, ValidationNatureEnum.NotEqual],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.BETWEEN, ValidationNatureEnum.Between],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTINCLUDEDBETWEEN, ValidationNatureEnum.NotIncludedBetween],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.BEFOREDATE, ValidationNatureEnum.BeforeDate],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.AFTERDATE, ValidationNatureEnum.AfterDate],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.INCLUDEDBETWEENDATES, ValidationNatureEnum.IncludedBetweenDates],
    [RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTINCLUDEDBETWEENDATES, ValidationNatureEnum.NotIncludedBetweenDates],
  ]);

  export const mapToApiValue: SfxMap<ValidationNatureEnum, RubricOptionDetailsResponseDTO.NatureValidationEnum> = new SfxMap<
    ValidationNatureEnum,
    RubricOptionDetailsResponseDTO.NatureValidationEnum
  >([
    [ValidationNatureEnum.MoreThan, RubricOptionDetailsResponseDTO.NatureValidationEnum.MORETHAN],
    [ValidationNatureEnum.MoreThanEqual, RubricOptionDetailsResponseDTO.NatureValidationEnum.MORETHANEQUAL],
    [ValidationNatureEnum.LessThen, RubricOptionDetailsResponseDTO.NatureValidationEnum.LESSTHAN],
    [ValidationNatureEnum.LessThanEqual, RubricOptionDetailsResponseDTO.NatureValidationEnum.LESSTHANEQUAL],
    [ValidationNatureEnum.Equal, RubricOptionDetailsResponseDTO.NatureValidationEnum.EQUAL],
    [ValidationNatureEnum.NotEqual, RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTEQUAL],
    [ValidationNatureEnum.Between, RubricOptionDetailsResponseDTO.NatureValidationEnum.BETWEEN],
    [ValidationNatureEnum.NotIncludedBetween, RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTINCLUDEDBETWEEN],
    [ValidationNatureEnum.BeforeDate, RubricOptionDetailsResponseDTO.NatureValidationEnum.BEFOREDATE],
    [ValidationNatureEnum.AfterDate, RubricOptionDetailsResponseDTO.NatureValidationEnum.AFTERDATE],
    [ValidationNatureEnum.IncludedBetweenDates, RubricOptionDetailsResponseDTO.NatureValidationEnum.INCLUDEDBETWEENDATES],
    [ValidationNatureEnum.NotIncludedBetweenDates, RubricOptionDetailsResponseDTO.NatureValidationEnum.NOTINCLUDEDBETWEENDATES],
  ]);

  export const itemsResolver = (questionType: QuestionTypeEnum): Observable<SingleSelectItemData<ValidationNatureEnum>[]> =>
    of(values(questionType).map(validation => ({ value: validation, title: toString.getValue(validation) })));

  export const comparePredicate = (node: SingleSelectItemData<ValidationNatureEnum>, item: ValidationNatureEnum) => node.value === item;
}
