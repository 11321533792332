import { CustomCellCreateRequestDTO, CustomCellDetailsResponseDTO, CustomCellUpdateRequestDTO } from '../../generated/models/models';
import { CustomFieldLightData } from '../custom-fields/custom-field.light';

import { CustomCellFieldEnum, CustomCellFormatEnum, CustomCellNatureEnum, GeometryLabelEnum } from '../../enums';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomFileLightData } from '../custom-file/custom-file-light';
import { GeometryIconEnum } from '../../enums/geometry/geometry-icon.enum';

export interface CustomCellDetailsData {
  uuidEntity: string;
  name: string;
  description: string;
  format: CustomCellFormatEnum;
  nature: CustomCellNatureEnum;
  updatable: boolean;
  field: CustomCellFieldEnum;
  customFile: CustomFileLightData;
  customField: CustomFieldLightData;
  geometryLabel: GeometryLabelEnum;
  geometryIcon: GeometryIconEnum;
  labelFamily: LabelFamilyLightData;
}

export namespace CustomCellDetailsData {
  export function mapFromApiValue(customCell: CustomCellDetailsResponseDTO): CustomCellDetailsData {
    return {
      uuidEntity: customCell.uuidEntity,
      name: customCell.name,
      description: customCell.description,
      format: CustomCellFormatEnum.convertFromApiValue.getValue(customCell.format),
      nature: CustomCellNatureEnum.convertFromApiValue.getValue(customCell.valueSource),
      updatable: customCell.updatable,
      field: CustomCellFieldEnum.convertFromApiValue.getValue(customCell.valueDestination),
      customFile: customCell.customFile && CustomFileLightData.mapFromApiValue(customCell.customFile),
      customField: customCell.customField && CustomFieldLightData.mapFromApiValue(customCell.customField),
      geometryLabel: GeometryLabelEnum.convertFromApiValue.getValue(customCell.geometryLabel),
      geometryIcon: GeometryIconEnum.convertFromApiValue.getValue(customCell.geometryIcon),
      labelFamily: customCell.labelFamily && LabelFamilyLightData.mapFromApiValue(customCell.labelFamily),
    };
  }

  export function mapToCreateRequestApiValue(customCell: CustomCellDetailsData): CustomCellCreateRequestDTO {
    return {
      name: customCell.name,
      customFileUuidEntity: customCell.customFile && customCell.customFile.uuidEntity,
      format: CustomCellFormatEnum.convertToApiValue.getValue(customCell.format),
      updatable: customCell.updatable,
      valueSource: CustomCellNatureEnum.convertToApiValue.getValue(customCell.nature),
      valueDestination: (customCell.customField && customCell.customField.uuidType) || CustomCellFieldEnum.convertToApiValue.getValue(customCell.field),
      geometryLabel: GeometryLabelEnum.convertToApiValue.getValue(customCell.geometryLabel),
      geometryIcon: GeometryIconEnum.convertToApiValue.getValue(customCell.geometryIcon),
      description: customCell.description,
    };
  }

  export function mapToUpdateRequestApiValue(customCell: CustomCellDetailsData): CustomCellUpdateRequestDTO {
    return {
      uuidEntity: customCell.uuidEntity,
      name: customCell.name,
      valueSource: CustomCellNatureEnum.convertToApiValue.getValue(customCell.nature),
      valueDestination: (customCell.customField && customCell.customField.uuidType) || CustomCellFieldEnum.convertToApiValue.getValue(customCell.field),
      geometryLabel: GeometryLabelEnum.convertToApiValue.getValue(customCell.geometryLabel),
      geometryIcon: GeometryIconEnum.convertToApiValue.getValue(customCell.geometryIcon),
      updatable: customCell.updatable,
      description: customCell.description,
    };
  }
}
