import { createReducer, Action, on } from '@ngrx/store';

import { AccessApiV2DetailsData, AccessApiV2PropertiesData } from '../../../../../../core/models';
import * as fromAccessApiActions from './access-api-v2-dialog.actions';

export interface AccessApiV2DialogState {
  accessApiDetails: AccessApiV2DetailsData;
  properties: AccessApiV2PropertiesData;
}

export const initialAccessApiV2DialogState = () => ({
  accessApiDetails: {} as AccessApiV2DetailsData,
  properties: {} as AccessApiV2PropertiesData,
});

const reducer = createReducer<AccessApiV2DialogState>(
  initialAccessApiV2DialogState(),
  on(
    fromAccessApiActions.getAccessApiV2DetailsSuccess,
    (state, { accessApi }): AccessApiV2DialogState => ({ ...state, accessApiDetails: { ...state.accessApiDetails, ...accessApi } }),
  ),
  on(fromAccessApiActions.getAccessApiV2PropertiesSuccess, (state, { properties }): AccessApiV2DialogState => ({ ...state, properties })),
);

export function AccessApiV2DialogReducer(state: AccessApiV2DialogState | undefined, action: Action): AccessApiV2DialogState {
  return reducer(state, action);
}
