import { CategoryDetailsResponseDTO } from '../../generated/models/categoryDetailsResponseDTO';
import { CategoryCreateRequestDTO, CategoryUpdateRequestDTO } from '../../generated/models/models';
import { LabelFamilyProvenanceEnum, LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum } from '../../enums';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface CategoryDetailsData {
  uuidEntity: string;
  nature: LabelFamilyNatureEnum;
  shortName: string;
  longName: string;
  color: string;
  relativeNature: LabelFamilyRelativeNatureEnum;
  provenance: LabelFamilyProvenanceEnum;
  parentCategory: LabelFamilyLightData;
}

export namespace CategoryDetailsData {
  export function mapFromApiValue(value: CategoryDetailsResponseDTO): CategoryDetailsData {
    return {
      uuidEntity: value.uuidEntity,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(value.nature),
      shortName: value.name,
      longName: value.fullName,
      color: value.color,
      provenance: LabelFamilyProvenanceEnum.convertFromApiValue.getValue(value.labelFamilyProvenance),
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(value.relativeNature),
      parentCategory: value.familyParent && LabelFamilyLightData.mapFromApiValue(value.familyParent),
    };
  }

  export function mapToCreateRequestApiValue(projectUuidEntity: string, value: CategoryDetailsData): CategoryCreateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      color: value.color,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(value.nature),
      projectUuidEntity,
      relativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(value.relativeNature),
      categoryParentUuidEntity: (value.parentCategory && value.parentCategory.uuidEntity) || undefined,
    };
  }

  export function mapToUpdateRequestApiValue(value: CategoryDetailsData): CategoryUpdateRequestDTO {
    return {
      name: value.shortName,
      fullName: value.longName,
      color: value.color,
      uuidEntity: value.uuidEntity,
    };
  }
}
