import { DateUtils } from '../../utils/date.utils';

import { FlowCreateRequestDTO, FlowUpdateRequestDTO, FlowCustomDetailsResponseDTO, FlowCreateUsingTechnicalObjectRequestDTO } from '../../generated/models/models';

import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { RevisionSummaryData } from '../revision/revision-summary-data';
import { UserLightData } from '../user/user-light-data';
import { TagLightData } from '../library-tag/tag-light-data';

import { ColorPickerPaletteEnum, FlowTypeEnum } from '../../enums';
import { FlowStatusEnum } from '../../enums/flow/flow-status.enum';
import { EntityLightData } from '../utils/entity-light-data';

export interface FlowDetailsData {
  uuidEntity: string;
  name: string;
  description: string;
  flowType: FlowTypeEnum;
  flowCustomStatus: CustomStatusLightData;
  currentRevision?: RevisionSummaryData;
  version: string;
  labelFamilyApplication: EntityLightData;
  labelFamilyProject: EntityLightData;
  assignedUser: EntityLightData;
  reporterUser: EntityLightData;
  userActor1: EntityLightData;
  userActor2: EntityLightData;
  userActor3: EntityLightData;
  supervision1: boolean;
  supervision2: boolean;
  supervision3: boolean;
  supervisionIntern1: boolean;
  supervisionIntern2: boolean;
  supervisionIntern3: boolean;
  refInt: string;
  refExt: string;
  createDate: Date;
  modificationDate: Date;
  modificationStatus: FlowStatusEnum;
  finishDate: string;
  startDate: string;
  estimatedStartDate: string;
  estimatedEndDate: string;
  achievedStartDate: string;
  achievedEndDate: string;
  locationSite: string;
  locationArea: string;
  locationLocal: string;
  color: string;
  functionalMarker: string;
  elementarySystem: string;
  technicalObject: string;
  technicalOffer: string;
  customStatusChangeComment?: string;
  locationActivated: boolean;
  unlocked: boolean;
  importedFlowUuidEntity?: string;
  zc?: boolean;
  earthquakeRisk?: boolean;
  earthquakeAdr?: boolean;
  earthquakeParade?: boolean;
  tags?: TagLightData[];
  internalSupervisorName1?: string;
  internalSupervisorName2?: string;
  internalSupervisorName3?: string;
  externalSupervisorName1?: string;
  externalSupervisorName2?: string;
  externalSupervisorName3?: string;
  canEditSupervision: boolean;
  hasFlowSource?: boolean;
  simplifiedSignatureMode?: boolean;
  // TeamRoleRefs
  assigneeUuideRef: string;
  reporterUuideRef: string;
  refUuideActor1: string;
  refUuideActor2: string;
  refUuideActor3: string;
}

export namespace FlowDetailsData {
  export function mapFromApiValue(flow: FlowCustomDetailsResponseDTO): FlowDetailsData {
    return {
      uuidEntity: flow.uuidEntity,
      name: flow.name || '',
      description: flow.description,
      version: flow.version,
      labelFamilyApplication: (flow.labelFamilyApplication && LabelFamilyLightData.mapToEntityLightData(flow.labelFamilyApplication)) || ({} as EntityLightData),
      labelFamilyProject: (flow.labelFamilyProject && LabelFamilyLightData.mapToEntityLightData(flow.labelFamilyProject)) || ({} as EntityLightData),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flow.typeFlow),
      flowCustomStatus: flow.customStatusFlow && CustomStatusLightData.mapFromApiValue(flow.customStatusFlow),
      startDate: flow.startDate && DateUtils.toCalendarFormat(flow.startDate),
      finishDate: flow.finishDate && DateUtils.toCalendarFormat(flow.finishDate),
      createDate: flow.createDate,
      modificationDate: flow.modificationDate,
      modificationStatus: FlowStatusEnum.convertFromApiValue.getValue(flow.modificationStatus),
      reporterUser: flow.reporterUser && UserLightData.mapToEntityLightData(flow.reporterUser),
      assignedUser: flow.assigneeUser && UserLightData.mapToEntityLightData(flow.assigneeUser),
      userActor1: flow.actor1 && UserLightData.mapToEntityLightData(flow.actor1),
      userActor2: flow.actor2 && UserLightData.mapToEntityLightData(flow.actor2),
      userActor3: flow.actor3 && UserLightData.mapToEntityLightData(flow.actor3),
      color: flow.flowBarColor || ColorPickerPaletteEnum.defaultColor,
      supervision1: flow.supervision1,
      supervision2: flow.supervision2,
      supervision3: flow.supervision3,
      supervisionIntern1: flow.supervisionIntern1,
      supervisionIntern2: flow.supervisionIntern2,
      supervisionIntern3: flow.supervisionIntern3,
      refInt: flow.refInt,
      refExt: flow.refExt,
      locationSite: flow.locationSite,
      locationArea: flow.locationArea,
      locationLocal: flow.locationLocal,
      functionalMarker: flow.functionalMarker,
      elementarySystem: flow.elementarySystem,
      technicalObject: flow.technicalObject,
      technicalOffer: flow.technicalOffer,
      locationActivated: flow.locationActivated,
      importedFlowUuidEntity: flow.sourceFlowRef,
      unlocked: flow.unlocked,
      zc: flow.zc,
      earthquakeRisk: flow.earthquakeRisk,
      earthquakeAdr: flow.earthquakeADR,
      earthquakeParade: flow.earthquakeParade,
      tags: (flow.tags || []).map(tag => TagLightData.mapFromApiValue(tag)),
      estimatedStartDate: flow.estimatedStartDate && DateUtils.toCalendarFormat(flow.estimatedStartDate),
      estimatedEndDate: flow.estimatedEndDate && DateUtils.toCalendarFormat(flow.estimatedEndDate),
      achievedStartDate: flow.achievedStartDate && DateUtils.toCalendarFormat(flow.achievedStartDate),
      achievedEndDate: flow.achievedEndDate && DateUtils.toCalendarFormat(flow.achievedEndDate),
      internalSupervisorName1: flow.internalSupervisorName1,
      internalSupervisorName2: flow.internalSupervisorName2,
      internalSupervisorName3: flow.internalSupervisorName3,
      externalSupervisorName1: flow.externalSupervisorName1,
      externalSupervisorName2: flow.externalSupervisorName2,
      externalSupervisorName3: flow.externalSupervisorName3,
      canEditSupervision: flow.canEditSupervision,
      hasFlowSource: flow.hasFlowSource,
      simplifiedSignatureMode: flow.simplifiedSignatureMode,
      assigneeUuideRef: flow.assigneeUuideRef,
      reporterUuideRef: flow.reporterUuideRef,
      refUuideActor1: flow.refUuideActor1,
      refUuideActor2: flow.refUuideActor2,
      refUuideActor3: flow.refUuideActor3,
    };
  }

  export function mapToCreateRequestApiValue(flow: FlowDetailsData, productUuidEntity: string): FlowCreateRequestDTO {
    return {
      projectUuidEntity: productUuidEntity,
      sourceFlowRef: undefined,
      name: flow.name,
      description: flow.description,
      typeFlow: FlowTypeEnum.convertToApiValue.getValue(flow.flowType),
      labelFamilyApplicationUuidEntity: flow.labelFamilyApplication && flow.labelFamilyApplication.uuidEntity,
      labelFamilyProjectUuidEntity: flow.labelFamilyProject && flow.labelFamilyProject.uuidEntity,
      customStatusFlowUuidEntity: flow.flowCustomStatus && flow.flowCustomStatus.uuidEntity,
      submitterComment: flow.customStatusChangeComment,
      tagUuidEntities: (flow.tags || []).map(tag => tag.uuidEntity),
      locationSite: flow.locationSite,
      locationArea: flow.locationArea,
      locationLocal: flow.locationLocal,
      elementarySystem: flow.elementarySystem,
      functionalMarker: flow.functionalMarker,
      technicalObject: flow.technicalObject,
      technicalOffer: flow.technicalOffer,
      locationActivated: flow.locationActivated,
      reporterUuideRef: flow.reporterUser && flow.reporterUser.uuidEntity,
      assigneeUuideRef: flow.assignedUser && flow.assignedUser.uuidEntity,
      refUuideActor1: flow.userActor1 && flow.userActor1.uuidEntity,
      refUuideActor2: flow.userActor2 && flow.userActor2.uuidEntity,
      refUuideActor3: flow.userActor3 && flow.userActor3.uuidEntity,
      supervision1: flow.supervision1,
      supervision2: flow.supervision2,
      supervision3: flow.supervision3,
      supervisionIntern1: flow.supervisionIntern1,
      supervisionIntern2: flow.supervisionIntern2,
      supervisionIntern3: flow.supervisionIntern3,
      refExt: flow.refExt,
      refInt: flow.refInt,
      startDate: (flow.startDate && DateUtils.toZonedDateTime(flow.startDate)) || undefined,
      finishDate: (flow.finishDate && DateUtils.toZonedDateTime(flow.finishDate)) || undefined,
    };
  }

  export function mapToUpdateRequestApiValue(flow: FlowDetailsData, productUuidEntity: string): FlowUpdateRequestDTO {
    return {
      ...mapToCreateRequestApiValue(flow, productUuidEntity),
      uuidEntity: flow.uuidEntity,
      estimatedStartDate: (flow.estimatedStartDate && DateUtils.toZonedDateTime(flow.estimatedStartDate)) || undefined,
      estimatedEndDate: (flow.estimatedEndDate && DateUtils.toZonedDateTime(flow.estimatedEndDate)) || undefined,
      achievedStartDate: (flow.achievedStartDate && DateUtils.toZonedDateTime(flow.achievedStartDate)) || undefined,
      achievedEndDate: (flow.achievedEndDate && DateUtils.toZonedDateTime(flow.achievedEndDate)) || undefined,
      internalSupervisorName1: flow.internalSupervisorName1,
      internalSupervisorName2: flow.internalSupervisorName2,
      internalSupervisorName3: flow.internalSupervisorName3,
      externalSupervisorName1: flow.externalSupervisorName1,
      externalSupervisorName2: flow.externalSupervisorName2,
      externalSupervisorName3: flow.externalSupervisorName3,
      simplifiedSignatureMode: flow.simplifiedSignatureMode,
    };
  }

  export function mapToRequestApiValue(
    flow: FlowDetailsData,
    projectUuidEntity: string,
    technicalObjectUuidEntity: string,
    contentRefUuidEntity: string,
  ): FlowCreateUsingTechnicalObjectRequestDTO {
    return {
      projectUuidEntity,
      contentRefUuidEntity,
      name: flow.name,
      description: flow.description,
      internalReference: flow.refInt,
      technicalObjectUuidEntity,
      familyUuidEntity: flow?.labelFamilyApplication?.uuidEntity,
      categoryUuidEntity: flow?.labelFamilyProject?.uuidEntity,
      statusUuidEntity: flow?.flowCustomStatus?.uuidEntity,
      submitterComment: flow.customStatusChangeComment,
    };
  }
}
