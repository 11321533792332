import { SfxMap } from '../utils/enum-utils';

export enum ExternalUserStatusEnum {
  Active = 1,
  Inactive = 2,
  Onboarding = 3,
  Blocked = 4,
  Deleted = 5,
}

export namespace ExternalUserStatusEnum {
  const key = 'externalUsers.status.';
  export const toString: SfxMap<ExternalUserStatusEnum, string> = new SfxMap<ExternalUserStatusEnum, string>([
    [ExternalUserStatusEnum.Active, key + 'active'],
    [ExternalUserStatusEnum.Inactive, key + 'inactive'],
    [ExternalUserStatusEnum.Onboarding, key + 'pending'],
    [ExternalUserStatusEnum.Blocked, key + 'blocked'],
    [ExternalUserStatusEnum.Deleted, key + 'deleted'],
  ]);
}
