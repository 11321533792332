import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SchedulingEndUpdateRequestDTO } from '../../generated/models/schedulingEndUpdateRequestDTO';
import { ScheduleStartConditionEnum } from './schedule-start-condition.enum';

export enum ScheduleEndConditionEnum {
  Time = 1,
  Status = 2,
  Amount = 3,
}

export namespace ScheduleEndConditionEnum {
  export const values = [ScheduleEndConditionEnum.Status, ScheduleEndConditionEnum.Time, ScheduleEndConditionEnum.Amount];

  export const convertFromApiValue = new SfxMap<SchedulingEndUpdateRequestDTO.ConditionEnum, ScheduleEndConditionEnum>([
    [SchedulingEndUpdateRequestDTO.ConditionEnum.TIME, ScheduleEndConditionEnum.Time],
    [SchedulingEndUpdateRequestDTO.ConditionEnum.STATUS, ScheduleEndConditionEnum.Status],
    [SchedulingEndUpdateRequestDTO.ConditionEnum.AMOUNT, ScheduleEndConditionEnum.Amount],
  ]);

  export const convertToApiValue = new SfxMap<ScheduleEndConditionEnum, SchedulingEndUpdateRequestDTO.ConditionEnum>([
    [ScheduleEndConditionEnum.Time, SchedulingEndUpdateRequestDTO.ConditionEnum.TIME],
    [ScheduleEndConditionEnum.Status, SchedulingEndUpdateRequestDTO.ConditionEnum.STATUS],
    [ScheduleEndConditionEnum.Amount, SchedulingEndUpdateRequestDTO.ConditionEnum.AMOUNT],
  ]);

  export const fromStartCondition = new SfxMap<ScheduleStartConditionEnum, ScheduleEndConditionEnum[]>(
    [
      [ScheduleStartConditionEnum.Time, [ScheduleEndConditionEnum.Time, ScheduleEndConditionEnum.Amount]],
      [ScheduleStartConditionEnum.Status, [ScheduleEndConditionEnum.Status, ScheduleEndConditionEnum.Time, ScheduleEndConditionEnum.Amount]],
    ],
    [],
  );

  const key = 'schedule.table.condition.';

  export const toString = new SfxMap<ScheduleEndConditionEnum, string>([
    [ScheduleEndConditionEnum.Time, key + 'time'],
    [ScheduleEndConditionEnum.Status, key + 'status'],
    [ScheduleEndConditionEnum.Amount, key + 'amount'],
  ]);

  export const comparePredicate = (node: SingleSelectItemData<ScheduleEndConditionEnum>, item: ScheduleEndConditionEnum) => node.value === item;

  export const selectedItemValues = values.map(priority => ({ value: priority, title: toString.getValue(priority) }));
  export const selectedItemForStartCondition = (startCondition: ScheduleStartConditionEnum) =>
    fromStartCondition.getValue(startCondition).map(priority => ({ value: priority, title: toString.getValue(priority) }));
}
