import { createReducer, Action, on } from '@ngrx/store';
import { CategoryDetailsData } from '../../../../../core/models';

import { LabelFamilyRelativeNatureEnum } from '../../../../../core/enums';
import * as fromCategoryActions from './category-dialog.actions';

export interface DashboardCategoryDialogState {
  saveLoading: boolean;
  isFirstParentToCreate: boolean;
  categoryDetailsData: CategoryDetailsData;
}

export const initialDashboardCategoryDialogState = () => ({
  saveLoading: false,
  isFirstParentToCreate: false,
  categoryDetailsData: {} as CategoryDetailsData,
});

const reducer = createReducer<DashboardCategoryDialogState>(
  initialDashboardCategoryDialogState(),
  on(fromCategoryActions.updateCategory, (state): DashboardCategoryDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.updateCategoryFail, (state): DashboardCategoryDialogState => ({ ...state, saveLoading: false })),
  on(fromCategoryActions.addCategory, (state): DashboardCategoryDialogState => ({ ...state, saveLoading: true })),
  on(fromCategoryActions.addCategoryFail, (state): DashboardCategoryDialogState => ({ ...state, saveLoading: false })),
  on(
    fromCategoryActions.addCategorySuccess,
    (state, { category, addNewOne }): DashboardCategoryDialogState => ({
      ...state,
      saveLoading: false,
      isFirstParentToCreate: false,
      categoryDetailsData: addNewOne ? { ...category, shortName: null, longName: null, color: null } : ({} as CategoryDetailsData),
    }),
  ),
  on(fromCategoryActions.getCategoryDetailsSuccess, (state, { category }): DashboardCategoryDialogState => ({ ...state, categoryDetailsData: category })),
  on(
    fromCategoryActions.checkForFirstParentSuccess,
    (state, { exist, keepCurrentType }): DashboardCategoryDialogState => ({
      ...state,
      isFirstParentToCreate: !exist,
      categoryDetailsData: {
        ...state.categoryDetailsData,
        relativeNature: !exist ? LabelFamilyRelativeNatureEnum.Parent : keepCurrentType ? state.categoryDetailsData.relativeNature : undefined,
      },
    }),
  ),
);

export function dashboardCategoryDialogReducer(state: DashboardCategoryDialogState | undefined, action: Action): DashboardCategoryDialogState {
  return reducer(state, action);
}
