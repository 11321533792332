import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Actions, ofType } from '@ngrx/effects';

import { first, tap, withLatestFrom } from 'rxjs/operators';

import { resetStates } from '../../main/state/main.actions';
import { getNavigationRouteSegmentsIds } from '../../router';

import { SfxDialogComponent } from '../../sfx-dialog/sfx-dialog.component';

export const ResetStateGuard: CanDeactivateFn<SfxDialogComponent> = (_, route, __) => {
  const store = inject(Store);
  inject(Actions)
    .pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      first(),
      withLatestFrom(store.pipe(select(getNavigationRouteSegmentsIds))),
      tap(([___, segments]) => route.data && !segments.includes(route.data.routeId) && store.dispatch(resetStates({ statesNames: route.data.stateToReset }))),
    )
    .subscribe();

  return true;
};
