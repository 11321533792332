import dayjs from 'dayjs';

import { PlanLinkTypeEnum } from '../../enums';
import { ElementPlanLinkContentResponseDTO } from '../../generated/models/models';
import { ElementPlanLinkSummaryData } from './element-plan-link-summary-data';

export interface ElementPlanLinksData {
  target: ElementPlanLinkSummaryData;
  blocks: ElementPlanLinkSummaryData[];
  blockedBy: ElementPlanLinkSummaryData[];
}

export namespace ElementPlanLinksData {
  export function mapFromApiValue(targetUuidEntity: string, data: ElementPlanLinkContentResponseDTO): ElementPlanLinksData {
    const target = ElementPlanLinkSummaryData.mapFromApiValue(data.target);
    const links = (data.links || []).map(link => ElementPlanLinkSummaryData.mapFromApiValue(link));

    const blocks = links
      .filter(
        link =>
          (link.linkType === PlanLinkTypeEnum.Blocks && link.sourceUuidEntity === targetUuidEntity) ||
          (link.linkType === PlanLinkTypeEnum.BlockedBy && link.destinationUuidEntity === targetUuidEntity),
      )
      .map(link => ({ ...link, datesOverlap: dayjs(link.startDate).isBefore(target.endDate) }));

    const blockedBy = links
      .filter(
        link =>
          (link.linkType === PlanLinkTypeEnum.Blocks && link.destinationUuidEntity === targetUuidEntity) ||
          (link.linkType === PlanLinkTypeEnum.BlockedBy && link.sourceUuidEntity === targetUuidEntity),
      )
      .map(link => ({ ...link, datesOverlap: dayjs(link.endDate).isAfter(target.startDate) }));

    return { target, blocks, blockedBy };
  }
}
