import { FlowSummaryForStepResponseDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { DateUtils } from '../../utils/date.utils';

import { FlowTypeEnum } from '../../enums';

export interface FlowSummaryForStepData {
  name: string;
  labelFamilyApplication: LabelFamilyLightData;
  labelFamilyProject: LabelFamilyLightData;
  modificationDate: string;
  refInt: string;
  flowCustomStatus: CustomStatusLightData;
  flowType: FlowTypeEnum;
  uuidEntity: string;
  frozen?: boolean;
  unlocked?: boolean;
}

export namespace FlowSummaryForStepData {
  export function mapFromApiValue(flowSummary: FlowSummaryForStepResponseDTO): FlowSummaryForStepData {
    return {
      name: flowSummary.name || '',
      labelFamilyApplication: (flowSummary.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      labelFamilyProject: flowSummary.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyProject),
      modificationDate: DateUtils.toDateFormat(flowSummary.modificationDate),
      flowCustomStatus: flowSummary.customStatusFlow && CustomStatusLightData.mapFromApiValue(flowSummary.customStatusFlow),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flowSummary.typeFlow),
      uuidEntity: flowSummary.uuidEntity,
      refInt: flowSummary.refInt,
      frozen: flowSummary.alreadyImported,
      unlocked: flowSummary['unlocked'], // MakeModels: @aymen  change it when model is updated
    };
  }
}
