import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromActions from './custom-cell-dialog.actions';
import { dummyAction } from '../../../../main/state/main.actions';

import { CustomFileDetailsData } from '../../../../../core/models';
import { CustomCellDetailsData } from '../../../../../core/models/custom-cell/custom-cell-details';

export interface SettingsCustomCellDialogState {
  customCellDetails: CustomCellDetailsData;
  initialCustomCellDetails: CustomCellDetailsData;
  initialCustomFileDetails: CustomFileDetailsData;
  saveLoading: boolean;
}

export const initialSettingsCustomCellDialogState = (): SettingsCustomCellDialogState => ({
  customCellDetails: {} as CustomCellDetailsData,
  initialCustomCellDetails: {} as CustomCellDetailsData,
  initialCustomFileDetails: {} as CustomFileDetailsData,
  saveLoading: false,
});

const reducer = createReducer<SettingsCustomCellDialogState>(
  initialSettingsCustomCellDialogState(),
  on(fromActions.addCustomCell, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.addCustomCellFail, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.addCustomCellSuccess, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.updateCustomCell, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateCustomCellFail, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.updateCustomCellSuccess, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.getCustomCellDetailsSuccess, (state, { customCellDetails }): SettingsCustomCellDialogState => ({ ...state, customCellDetails })),
  on(fromActions.initCustomFile, (state, { customFile }): SettingsCustomCellDialogState => ({ ...state, initialCustomFileDetails: customFile })),
  on(
    fromActions.initializeCustomCellDetails,
    (state, { customCellDetails }): SettingsCustomCellDialogState => ({ ...state, initialCustomCellDetails: cloneDeep(customCellDetails) }),
  ),
  on(
    fromActions.initializeAnotherCustomCell,
    (state, { customCellDetails }): SettingsCustomCellDialogState => {
      const anotherCustomCell = {
        format: customCellDetails.format,
        nature: customCellDetails.nature,
        customFile: customCellDetails.customFile,
        updatable: customCellDetails.updatable,
      } as CustomCellDetailsData;

      return {
        ...state,
        customCellDetails: anotherCustomCell,
        initialCustomCellDetails: cloneDeep(anotherCustomCell),
      };
    },
  ),
  on(dummyAction, (state): SettingsCustomCellDialogState => ({ ...state, saveLoading: false })),
);

export function settingsCustomCellDialogReducer(state: SettingsCustomCellDialogState | undefined, action: Action): SettingsCustomCellDialogState {
  return reducer(state, action);
}
