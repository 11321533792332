import { DocumentFlowSummaryResponseDTO } from '../../generated/models/models';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { DocumentNatureEnum, DocumentProvenanceEnum, StringUtilsEnum } from '../../enums';

export interface DocumentFlowSummaryData {
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  name: string;
  refInt: string;
  refExt: string;
  index: string;
  nature: DocumentNatureEnum;
  labelFamilyApplication: LabelFamilyLightData;
  versionUuidEntity: string;
  provenance: DocumentProvenanceEnum;
}

export namespace DocumentFlowSummaryData {
  export function mapFromApiValue(documentFlowSummary: DocumentFlowSummaryResponseDTO): DocumentFlowSummaryData {
    return {
      uuidEntity: documentFlowSummary.uuidEntity,
      uuidType: documentFlowSummary.uuidType,
      uuid: documentFlowSummary.uuid,
      name: documentFlowSummary.name,
      refInt: documentFlowSummary.refInt,
      refExt: documentFlowSummary.refExt,
      index: documentFlowSummary.indice,
      nature: DocumentNatureEnum.convertFromApiValue.getValue(documentFlowSummary.natureFile),
      labelFamilyApplication: documentFlowSummary.family && LabelFamilyLightData.mapFromApiValue(documentFlowSummary.family),
      versionUuidEntity: documentFlowSummary.lastVdf?.uuidEntity,
      provenance: DocumentProvenanceEnum.convertFromApiValue.getValue(documentFlowSummary.provenance),
    };
  }

  export function toString(documentFlowSummary: DocumentFlowSummaryData): string {
    return (
      documentFlowSummary.labelFamilyApplication &&
      documentFlowSummary.labelFamilyApplication.name
        .concat(StringUtilsEnum.DashSeparator.concat(documentFlowSummary.name))
        .concat(StringUtilsEnum.DashSeparator.concat(documentFlowSummary.refInt))
        .concat(documentFlowSummary.refExt ? StringUtilsEnum.SlashSeparator.concat(documentFlowSummary.refExt) : '')
    );
  }
}
