import { SfxMap } from '../utils/enum-utils';

export enum AuthMethodEnum {
  BasicAuth = 1,
  SsoAuth = 2,
  BasicSsoAuth = 3,
  SsoWebapp = 4,
}
export namespace AuthMethodEnum {
  export const values: AuthMethodEnum[] = [AuthMethodEnum.BasicAuth, AuthMethodEnum.SsoAuth, AuthMethodEnum.BasicSsoAuth, AuthMethodEnum.SsoWebapp];
  export const ssoValues: AuthMethodEnum[] = [AuthMethodEnum.SsoAuth, AuthMethodEnum.BasicSsoAuth, AuthMethodEnum.SsoWebapp];
  export const ssoOnlyValues: AuthMethodEnum[] = [AuthMethodEnum.SsoAuth, AuthMethodEnum.SsoWebapp];
  export const basicAuthValues: AuthMethodEnum[] = [AuthMethodEnum.BasicAuth, AuthMethodEnum.BasicSsoAuth];

  export const convertFromApiValue: SfxMap<string, AuthMethodEnum> = new SfxMap<string, AuthMethodEnum>([
    ['BASIC_AUTH', AuthMethodEnum.BasicAuth],
    ['SSO_AUTH', AuthMethodEnum.SsoAuth],
    ['BASIC_SSO_AUTH', AuthMethodEnum.BasicSsoAuth],
    ['SSO_WEBAPP', AuthMethodEnum.SsoWebapp],
  ]);

  export const convertToApiValue: SfxMap<AuthMethodEnum, string> = new SfxMap<AuthMethodEnum, string>([
    [AuthMethodEnum.BasicAuth, 'BASIC_AUTH'],
    [AuthMethodEnum.SsoAuth, 'SSO_AUTH'],
    [AuthMethodEnum.BasicSsoAuth, 'BASIC_SSO_AUTH'],
    [AuthMethodEnum.SsoWebapp, 'SSO_WEBAPP'],
  ]);
}
