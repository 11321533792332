import { CTabletSummaryData } from '../../models/tablets/cTablet-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ContributorTabletColumnEnum {
  Family = 'family',
  Name = 'name',
  MobileVersion = 'mobileVersion',
  LastPush = 'lastPush',
  Position = 'position',
  Status = 'status',
  State = 'state',
  Company = 'company',
  Actions = 'actions',
}

export namespace ContributorTabletColumnEnum {
  export type tabletSortField = keyof CTabletSummaryData;

  export const allColumns = [
    ContributorTabletColumnEnum.Family,
    ContributorTabletColumnEnum.Name,
    ContributorTabletColumnEnum.LastPush,
    ContributorTabletColumnEnum.MobileVersion,
    ContributorTabletColumnEnum.Position,
    ContributorTabletColumnEnum.Company,
    ContributorTabletColumnEnum.Status,
    ContributorTabletColumnEnum.Actions,
  ];

  export const columnsFiltered = (toExclude: Set<ContributorTabletColumnEnum>) => allColumns.filter((column: ContributorTabletColumnEnum) => !toExclude.has(column));

  export const importColumns = [ContributorTabletColumnEnum.Family, ContributorTabletColumnEnum.Name, ContributorTabletColumnEnum.Status];

  const key = 'tablets.table.header.';
  export const toString = new SfxMap<ContributorTabletColumnEnum, string>([
    [ContributorTabletColumnEnum.Family, key + 'family'],
    [ContributorTabletColumnEnum.Name, key + 'name'],
    [ContributorTabletColumnEnum.MobileVersion, key + 'mobileVersion'],
    [ContributorTabletColumnEnum.LastPush, key + 'lastPush'],
    [ContributorTabletColumnEnum.Position, key + 'position'],
    [ContributorTabletColumnEnum.State, key + 'state'],
    [ContributorTabletColumnEnum.Company, key + 'company'],
    [ContributorTabletColumnEnum.Status, key + 'status'],
    [ContributorTabletColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField = new SfxMap<ContributorTabletColumnEnum, tabletSortField>([
    [ContributorTabletColumnEnum.Family, 'group' as tabletSortField],
    [ContributorTabletColumnEnum.Name, 'refExtern'],
    [ContributorTabletColumnEnum.LastPush, 'lastPush'],
  ]);
}
