import { SfxMap } from '../../utils/enum-utils';

export enum BlockDsiColumnEnum {
  Name = 'name',
  Action = 'action',
  MobileAction = 'mobileAction',
  TabletAction = 'tabletAction',
}

export namespace BlockDsiColumnEnum {
  export const allColumns = [BlockDsiColumnEnum.Name, BlockDsiColumnEnum.Action];
  export const mobileColumns = [BlockDsiColumnEnum.Name, BlockDsiColumnEnum.MobileAction];
  export const tabletColumns = [BlockDsiColumnEnum.Name, BlockDsiColumnEnum.TabletAction];

  const key = 'blocks.dsi.table.header.';
  export const toString: SfxMap<BlockDsiColumnEnum, string> = new SfxMap<BlockDsiColumnEnum, string>([
    [BlockDsiColumnEnum.Name, key + 'name'],
    [BlockDsiColumnEnum.Action, key + 'action'],
    [BlockDsiColumnEnum.MobileAction, key + 'action'],
    [BlockDsiColumnEnum.TabletAction, key + 'action'],
  ]);
}
