import { MoveElementOnParentRequestDTO } from '../../generated/models/moveElementOnParentRequestDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum MoveEnum {
  Start = 1,
  Before = 2,
  After = 3,
  End = 4,
}

export namespace MoveEnum {
  export const convertToApiValue: SfxMap<MoveEnum, MoveElementOnParentRequestDTO.RelativePositionEnum> = new SfxMap<MoveEnum, MoveElementOnParentRequestDTO.RelativePositionEnum>([
    [MoveEnum.Start, MoveElementOnParentRequestDTO.RelativePositionEnum.START],
    [MoveEnum.Before, MoveElementOnParentRequestDTO.RelativePositionEnum.BEFORE],
    [MoveEnum.After, MoveElementOnParentRequestDTO.RelativePositionEnum.AFTER],
    [MoveEnum.End, MoveElementOnParentRequestDTO.RelativePositionEnum.END],
  ]);
}
