<div
  class="popover-container"
  [class.without-anchor]="!popoverRef.popoverInitData.showAnchor"
  [ngClass]="
    popoverRef.popoverInitData.showAnchor &&
    PopoverPositionEnum.toAnchorClassName(popoverRef.overlay, popoverRef.popoverInitData.origin).getValue(popoverRef.popoverInitData.preferredPosition)
  "
  [@fadeAnimation]="{ value: animationState, params: { fadeIn: configData.animationFadeIn } }"
>
  <ng-container [ngSwitch]="contentType">
    <div *ngSwitchCase="'text'" [innerHTML]="content"></div>
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
