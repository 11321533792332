import { TemplateParamCreateRequestDTO, TemplateParamSummaryResponseDTO, TemplateParamUpdateRequestDTO, TemplateParamListRequestDTO } from '../../generated/models/models';

import { EntityLightData, LabelFamilyLightData, BaseQuery, initializeQueries, ErrorResponseData } from '../../models';
import { ApplicationFileLightData } from '../application-file/application-file';
import { ClientLightData } from '../client/client-light';
import { OffsetData } from './offset-data';
import { MarginData } from './margin-data';
import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';
import { DialogToasterData } from '../../../modules/sfx-dialog/state/dialog-toaster-data';

import { InnerSortDirectionEnum, TramPageTypeEnum, TramTypeEnum } from '../../enums';

export interface TramSummaryData {
  margin: MarginData;
  naturePage: TramTypeEnum;
  numPage: OffsetData;
  pageType: TramPageTypeEnum;
  template: ApplicationFileLightData;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  client: ClientLightData;
  family: EntityLightData;
  indiceMax: number;
  createdDate: string;
}

export namespace TramSummaryData {
  const alreadyExistsErrorKey = 'alreadyExists';
  const erasingDefaultFrame = 'erasingDefaultFrame';
  const updateAlreadyExistsErrorKey = 'templateParams.alreadyExists';
  const sortFields = ['marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'xNumPage', 'yNumPage', 'createDate'];

  export interface QueryRequest extends BaseQuery<TramSummaryData> {
    naturePage?: TramTypeEnum;
    pageType?: TramPageTypeEnum[];
  }

  export function mapFromApiValue(tram: TemplateParamSummaryResponseDTO): TramSummaryData {
    return {
      margin: tram.margin && MarginData.mapFromApiValue(tram.margin),
      naturePage: TramTypeEnum.convertFromApiValue.getValue(tram.naturePage),
      pageType: TramPageTypeEnum.convertFromApiValue.getValue(tram.pageType),
      numPage: tram.numPage && OffsetData.mapFromApiValue(tram.numPage),
      template: tram.template && ApplicationFileLightData.mapFromApiValue(tram.template),
      uuid: tram.uuid,
      uuidEntity: tram.uuidEntity,
      uuidOrganization: tram.uuidOrganization,
      uuidType: tram.uuidType,
      client: tram.client && ClientLightData.mapFromApiValue(tram.client),
      family: tram.labelFamily && LabelFamilyLightData.mapToEntityLightData(tram.labelFamily),
      indiceMax: tram.indiceMax,
      createdDate: DateUtils.toDateFormat(tram.createDate),
    };
  }

  export function mapToCreateRequestApiValue(tram: TramSummaryData): TemplateParamCreateRequestDTO {
    return {
      margin: MarginData.mapToRequestApiValue(tram.margin),
      naturePage: TramTypeEnum.convertToApiValue.getValue(tram.naturePage),
      numPage: OffsetData.mapToRequestApiValue(tram.numPage),
      pageType: TramPageTypeEnum.convertToApiValue.getValue(tram.pageType),
      templateUuidEntity: tram.template && tram.template.uuidEntity,
      clientUuidEntity: tram.client && tram.client.uuidEntity,
      labelFamilyUuidEntity: tram.client ? null : tram?.family?.uuidEntity,
      indiceMax: tram.indiceMax,
    };
  }

  export function mapToUpdateRequestApiValue(tram: TramSummaryData): TemplateParamUpdateRequestDTO {
    return {
      uuidEntity: tram.uuidEntity,
      margin: MarginData.mapToRequestApiValue(tram.margin),
      naturePage: TramTypeEnum.convertToApiValue.getValue(tram.naturePage),
      numPage: OffsetData.mapToRequestApiValue(tram.numPage),
      pageType: TramPageTypeEnum.convertToApiValue.getValue(tram.pageType),
      templateUuidEntity: tram.template && tram.template.uuidEntity,
      clientUuidEntity: tram.client && tram.client.uuidEntity,
      labelFamilyUuidEntity: tram.client ? null : tram?.family?.uuidEntity,
      indiceMax: tram.indiceMax,
    };
  }

  export function mapToPageRequestApiValue(tramRequest: QueryRequest): TemplateParamListRequestDTO {
    return {
      naturePage: (tramRequest.naturePage && TramTypeEnum.convertToApiValue.getValue(tramRequest.naturePage)) || undefined,
      page: tramRequest.page,
      size: tramRequest.size,
      sort: sortFields.includes(tramRequest?.sort?.key)
        ? { attribute: tramRequest.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(tramRequest.sort.direction) }
        : undefined,
      textSearch: tramRequest.textSearch,
      pageType: tramRequest.pageType && tramRequest.pageType.map(type => TramPageTypeEnum.convertToApiValue.getValue(type)),
    };
  }

  export function initializeQueryRequest(tramPageType: string[], naturePage: string, sort: SortFilter<TramSummaryData>): QueryRequest {
    return {
      ...initializeQueries<TramSummaryData>(sort),
      naturePage: TramTypeEnum.convertFromParamsValue.getValue(naturePage) || undefined,
      pageType: tramPageType.map(type => TramPageTypeEnum.convertFromParamsValue.getValue(type)),
    };
  }

  export function getErrorKey(error: ErrorResponseData): DialogToasterData {
    return [alreadyExistsErrorKey, updateAlreadyExistsErrorKey].includes(error.errorKey)
      ? { title: 'trams.dialog.toasters.alreadyExists', subtitle: 'trams.dialog.toasters.alreadyExistsSubTitle' }
      : error.errorKey === erasingDefaultFrame
      ? { title: 'trams.dialog.toasters.erasingDefaultFrame' }
      : { title: 'trams.dialog.toasters.createFail' };
  }
}
