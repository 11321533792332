import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum EquipmentReorganizeTypeEnum {
  Family = 'family',
}

export namespace EquipmentReorganizeTypeEnum {
  export const reorganizeTypes = [EquipmentReorganizeTypeEnum.Family];

  export const toString: SfxMap<EquipmentReorganizeTypeEnum, string> = new SfxMap<EquipmentReorganizeTypeEnum, string>([
    [EquipmentReorganizeTypeEnum.Family, 'equipment.modals.reorganize.types.family'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<EquipmentReorganizeTypeEnum>[]> =>
    of(
      reorganizeTypes.map(value => ({
        value,
        title: EquipmentReorganizeTypeEnum.toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<EquipmentReorganizeTypeEnum>, item: EquipmentReorganizeTypeEnum) => node.value === item;
}
