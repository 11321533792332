import { SfxMap } from '../../utils/enum-utils';

export enum FormProvenanceEnum {
  Library = 'library',
  Project = 'project',
  LocalCopy = 'local',
}

export namespace FormProvenanceEnum {
  export const convertFormParamsValue: SfxMap<string, FormProvenanceEnum> = new SfxMap([
    ['library', FormProvenanceEnum.Library],
    ['local', FormProvenanceEnum.LocalCopy],
  ]);
}
