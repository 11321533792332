import { FormImportResponseDTO } from '../../generated/models/models';

export interface FormImportResponseData {
  success: boolean;
  formNames: string[];
}

export namespace FormImportResponseData {
  export function mapFromApiValue(data: FormImportResponseDTO): FormImportResponseData {
    return {
      success: data.success,
      formNames: (data.formsAlreadyImported && data.formsAlreadyImported.map(form => form.name)) || [],
    };
  }
}
