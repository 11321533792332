import { FlowImportContentRequest } from '../../generated/models/models';

export interface FlowContentToImport {
  phaseUuidEntity: string;
  flowUuidEntity: string;
  selectedFlowUuidEntity: string;
}

export namespace FlowContentToImport {
  export function mapToRequestApiValue(importRequest: FlowContentToImport): FlowImportContentRequest {
    return {
      currentPhaseUuidEntity: importRequest.phaseUuidEntity,
      flowSourceuuidEntity: importRequest.selectedFlowUuidEntity,
      flowUuidEntity: importRequest.flowUuidEntity,
    };
  }
}
