import { ExternalApiUserSummaryResponseDTO, PageExternalApiUserAccessRequestDTO } from '../../generated/models/models';
import { InnerSortDirectionEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface AccessApiSummaryData {
  uuidEntity: string;
  login: string;
  firstName: string;
  clientSecret: string;
  uuid: string;
  uuidOrganization: string;
  uuidType: string;
}

export namespace AccessApiSummaryData {
  export type QueryRequest = BaseQuery<AccessApiSummaryData>;
  const sortFields = ['firstName'];

  export function mapFromApiValue(user: ExternalApiUserSummaryResponseDTO): AccessApiSummaryData {
    return {
      uuidEntity: user.uuidEntity,
      login: user.login,
      firstName: user.name,
      clientSecret: user['clientSecret'], // make models issues
      uuid: user.uuid,
      uuidOrganization: user.uuidOrganization,
      uuidType: user.uuidType,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageExternalApiUserAccessRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, sort: SortFilter<AccessApiSummaryData>): QueryRequest {
    return {
      ...initializeQueries<AccessApiSummaryData>(sort),
      textSearch: searchText || undefined,
    };
  }
}
