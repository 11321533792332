import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { UserSummaryResponseDTO } from '../../generated/models/userSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum UserStatusEnum {
  Active = 1,
  Inactive = 2,
  Onboarding = 3,
  Blocked = 4,
  Deleted = 5,
}
export namespace UserStatusEnum {
  const defaultClass = 'sfx-rounded sfx-small';

  const values = (isAdmin?: boolean, user?: boolean): UserStatusEnum[] =>
    compact([UserStatusEnum.Active, UserStatusEnum.Inactive, user && UserStatusEnum.Onboarding, user && UserStatusEnum.Blocked, isAdmin && UserStatusEnum.Deleted]);

  export const convertFromApiValue = new SfxMap<UserSummaryResponseDTO.StatusEnum, UserStatusEnum>([
    [UserSummaryResponseDTO.StatusEnum.ACTIVATED, UserStatusEnum.Active],
    [UserSummaryResponseDTO.StatusEnum.DEACTIVATED, UserStatusEnum.Inactive],
    [UserSummaryResponseDTO.StatusEnum.BLOCKED, UserStatusEnum.Blocked],
    [UserSummaryResponseDTO.StatusEnum.ONBOARDING, UserStatusEnum.Onboarding],
    [UserSummaryResponseDTO.StatusEnum.DELETED, UserStatusEnum.Deleted],
  ]);

  export const convertToApiValue = new SfxMap<UserStatusEnum, UserSummaryResponseDTO.StatusEnum>([
    [UserStatusEnum.Active, UserSummaryResponseDTO.StatusEnum.ACTIVATED],
    [UserStatusEnum.Inactive, UserSummaryResponseDTO.StatusEnum.DEACTIVATED],
    [UserStatusEnum.Blocked, UserSummaryResponseDTO.StatusEnum.BLOCKED],
    [UserStatusEnum.Onboarding, UserSummaryResponseDTO.StatusEnum.ONBOARDING],
    [UserStatusEnum.Deleted, UserSummaryResponseDTO.StatusEnum.DELETED],
  ]);

  export const convertFromParamsValue = new SfxMap<string, UserStatusEnum>([
    ['active', UserStatusEnum.Active],
    ['inactive', UserStatusEnum.Inactive],
    ['onboarding', UserStatusEnum.Onboarding],
    ['blocked', UserStatusEnum.Blocked],
    ['deleted', UserStatusEnum.Deleted],
  ]);

  export const convertToParamsValue = new SfxMap<UserStatusEnum, string>([
    [UserStatusEnum.Active, 'active'],
    [UserStatusEnum.Inactive, 'inactive'],
    [UserStatusEnum.Onboarding, 'onboarding'],
    [UserStatusEnum.Blocked, 'blocked'],
    [UserStatusEnum.Deleted, 'deleted'],
  ]);

  const key = 'users.table.';
  export const toString = new SfxMap<UserStatusEnum, string>([
    [UserStatusEnum.Active, key + 'active'],
    [UserStatusEnum.Inactive, key + 'inactive'],
    [UserStatusEnum.Onboarding, key + 'pending'],
    [UserStatusEnum.Blocked, key + 'blocked'],
    [UserStatusEnum.Deleted, key + 'deleted'],
  ]);

  export const iconData = new SfxMap<UserStatusEnum, IconData>([[UserStatusEnum.Onboarding, { name: 'sync-alt', prefix: 'fas' }]]);

  export const className = new SfxMap<UserStatusEnum, string>([
    [UserStatusEnum.Active, 'sfx-active-status'],
    [UserStatusEnum.Inactive, 'sfx-expired-status'],
    [UserStatusEnum.Onboarding, 'sfx-pending-status cursor-pointer'],
    [UserStatusEnum.Blocked, 'sfx-expired-status'],
    [UserStatusEnum.Deleted, 'sfx-deleted-status'],
  ]);

  export const functionClassName = new SfxMap<UserStatusEnum, string>(
    [
      [UserStatusEnum.Active, defaultClass],
      [UserStatusEnum.Inactive, `${defaultClass} sfx-disabled-user`],
      [UserStatusEnum.Onboarding, `${defaultClass} sfx-disabled-user`],
      [UserStatusEnum.Blocked, `${defaultClass} sfx-disabled-user`],
      [UserStatusEnum.Deleted, `${defaultClass} sfx-deleted-user`],
    ],
    defaultClass,
  );

  export const tooltip = new SfxMap<UserStatusEnum, string>([[UserStatusEnum.Onboarding, key + 'inactiveTooltip']]);

  const prefix = 'users.tooltips.table.';
  export const statusTooltip = new SfxMap<UserStatusEnum, string>([
    [UserStatusEnum.Active, prefix + 'active'],
    [UserStatusEnum.Inactive, prefix + 'inactive'],
    [UserStatusEnum.Onboarding, prefix + 'inactive'],
    [UserStatusEnum.Blocked, prefix + 'blocked'],
    [UserStatusEnum.Deleted, prefix + 'deleted'],
  ]);

  export const color = new SfxMap<UserStatusEnum, string>([
    [UserStatusEnum.Active, '#57b09d'],
    [UserStatusEnum.Inactive, '#af1018'],
    [UserStatusEnum.Onboarding, '#ffb141'],
    [UserStatusEnum.Blocked, '#af1018'],
    [UserStatusEnum.Deleted, '#9e9e9e'],
  ]);

  export const multiSelectResolver = (isAdmin?: boolean, user?: boolean): Observable<MultiSelectItemData<UserStatusEnum>[]> =>
    of(values(isAdmin, user).map(status => ({ value: status, title: toString.getValue(status) })));
}
