/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserLightDTOV15 } from './userLightDTOV15';

export interface ArchiveSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  type: ArchiveSummaryResponseDTO.TypeEnum;
  archiveDate: Date;
  modifiedBy: UserLightDTOV15;
}
export namespace ArchiveSummaryResponseDTO {
  export type TypeEnum = 'PROJECT' | 'DOCUMENT' | 'FORM' | 'FORM_RESPONSE' | 'FLOW' | 'CATEGORY' | 'LIST' | 'RFI';
  export const TypeEnum = {
    PROJECT: 'PROJECT' as TypeEnum,
    DOCUMENT: 'DOCUMENT' as TypeEnum,
    FORM: 'FORM' as TypeEnum,
    FORMRESPONSE: 'FORM_RESPONSE' as TypeEnum,
    FLOW: 'FLOW' as TypeEnum,
    CATEGORY: 'CATEGORY' as TypeEnum,
    LIST: 'LIST' as TypeEnum,
    RFI: 'RFI' as TypeEnum,
  };
}
