import { SfxMap } from '../../utils/enum-utils';
import { TramSummaryData } from '../../models/tram/tram-Summary.data';

export enum TramColumnEnum {
  Link = 'link',
  Type = 'type',
  Tram = 'tram',
  MarginTop = 'marginTop',
  MarginBottom = 'marginBottom',
  MarginLeft = 'marginLeft',
  MarginRight = 'marginRight',
  PositionX = 'offsetX',
  PositionY = 'offsetY',
  CreatedDate = 'createdDate',
  Actions = 'actions',
}

export namespace TramColumnEnum {
  export type TramSortField = keyof TramSummaryData;

  export const allColumns = [
    TramColumnEnum.Link,
    TramColumnEnum.Type,
    TramColumnEnum.Tram,
    TramColumnEnum.MarginTop,
    TramColumnEnum.MarginBottom,
    TramColumnEnum.MarginLeft,
    TramColumnEnum.MarginRight,
    TramColumnEnum.PositionX,
    TramColumnEnum.PositionY,
    TramColumnEnum.CreatedDate,
    TramColumnEnum.Actions,
  ];

  const key = 'trams.table.';
  export const toString: SfxMap<TramColumnEnum, string> = new SfxMap<TramColumnEnum, string>([
    [TramColumnEnum.Link, key + 'link'],
    [TramColumnEnum.Type, key + 'type'],
    [TramColumnEnum.Tram, key + 'tram'],
    [TramColumnEnum.MarginTop, key + 'marginTop'],
    [TramColumnEnum.MarginBottom, key + 'marginBottom'],
    [TramColumnEnum.MarginLeft, key + 'marginLeft'],
    [TramColumnEnum.MarginRight, key + 'marginRight'],
    [TramColumnEnum.PositionX, key + 'offsetX'],
    [TramColumnEnum.PositionY, key + 'offsetY'],
    [TramColumnEnum.CreatedDate, key + 'createdDate'],
    [TramColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<TramColumnEnum, TramSortField> = new SfxMap<TramColumnEnum, TramSortField>([
    [TramColumnEnum.MarginTop, 'marginTop' as TramSortField], // HACK: @wissem
    [TramColumnEnum.MarginBottom, 'marginBottom' as TramSortField], // HACK: @wissem
    [TramColumnEnum.MarginLeft, 'marginLeft' as TramSortField], // HACK: @wissem
    [TramColumnEnum.MarginRight, 'marginRight' as TramSortField], // HACK: @wissem
    [TramColumnEnum.PositionX, 'xNumPage' as TramSortField], // HACK: @wissem
    [TramColumnEnum.PositionY, 'yNumPage' as TramSortField], // HACK: @wissem
    [TramColumnEnum.CreatedDate, 'createDate' as TramSortField], // HACK: @wissem
  ]);
}
