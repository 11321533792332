import { InnerSortDirectionEnum } from '../../enums';
import { PageSectionDocumentRequestDTO } from '../../generated/models/models';
import { BaseQuery, defaultPageSize } from '../utils/base-query.model';
import { DocumentSummaryData } from './document-data';

export interface DocumentQueryRequest extends BaseQuery<DocumentSummaryData> {
  projectUuidEntity: string;
  sectionUuidEntity: string;
}

export namespace DocumentQueryRequest {
  export function mapToApiValue(queries: DocumentQueryRequest): PageSectionDocumentRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      sectionUuide: queries.sectionUuidEntity,
    };
  }

  export function initialize(projectUuidEntity: string, sectionUuidEntity: string, page: number, textSearch?: string): DocumentQueryRequest {
    return {
      page: page,
      textSearch,
      size: defaultPageSize,
      sort: { key: 'order', direction: InnerSortDirectionEnum.Asc },
      projectUuidEntity,
      sectionUuidEntity,
    };
  }
}
