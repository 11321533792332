import { RfiTemplateCreateRequestDTO } from '../../generated/models/rfiTemplateCreateRequestDTO';
import { RfiTemplateDetailsResponseDTO } from '../../generated/models/rfiTemplateDetailsResponseDTO';
import { RfiTemplateUpdateRequestDTO } from '../../generated/models/rfiTemplateUpdateRequestDTO';

export interface FolderDetailsData {
  uuidEntity: string;
  name: string;
  description: string;
}

export namespace FolderDetailsData {
  export function mapRequestApiValue(data: RfiTemplateDetailsResponseDTO | FolderDetailsData): FolderDetailsData & RfiTemplateUpdateRequestDTO {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
      description: data.description,
    };
  }

  export function mapToCreateRequestApiValue(data: FolderDetailsData): RfiTemplateCreateRequestDTO {
    return {
      name: data.name,
      description: data.description,
    };
  }
}
