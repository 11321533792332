import { createAction, props } from '@ngrx/store';

import { LanguageConfigDetailsData } from '../../../../../core/models/wording-config-setting/language-config-details-data';
import { WordingConfigDetailsData } from '../../../../../core/models/wording-config-setting/wording-config-details-data';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

export const createWordingConfig = createAction('[wordingConfigurationsDialog/API] create wording configuration', props<{ wordingConfig: WordingConfigDetailsData }>());
export const createWordingConfigSuccess = createAction('[wordingConfigurationsDialog/API] create wording configuration success');
export const createWordingConfigFail = createAction('[wordingConfigurationsDialog/API] create wording configuration fail', props<DialogToasterData>());

export const loadWordingConfigDetailsDataSuccess = createAction(
  '[wordingConfigurationsDialog/API] load wording configuration data data success',
  props<{ wordingConfigDetails: WordingConfigDetailsData }>(),
);
export const loadWordingConfigDetailsDataFail = createAction('[wordingConfigurationsDialog/API] load wording configuration data data fail', props<DialogToasterData>());

export const updateWordingConfig = createAction('[wordingConfigurationsDialog/API] update wording configuration', props<{ wordingConfig: WordingConfigDetailsData }>());
export const updateWordingConfigSuccess = createAction('[wordingConfigurationsDialog/API] update wording configuration success');
export const updateWordingConfigFail = createAction('[wordingConfigurationsDialog/API] update wording configuration fail', props<DialogToasterData>());

export const createLanguageConfig = createAction(
  '[wordingConfigurationsDialog/API] create language config',
  props<{ languageConfig: LanguageConfigDetailsData; addNewOne?: boolean }>(),
);
export const createLanguageConfigSuccess = createAction(
  '[wordingConfigurationsDialog/API] create language config success',
  props<{ languageConfig: LanguageConfigDetailsData; wordingConfigUuidEntity: string; addNewOne?: boolean }>(),
);
export const createLanguageConfigFail = createAction('[wordingConfigurationsDialog/API] create language config fail', props<{ title?: string; subtitle?: string }>());

export const loadLanguageConfigDetailsDataSuccess = createAction(
  '[wordingConfigurationsDialog/API] load language config data success',
  props<{ languageConfig: LanguageConfigDetailsData }>(),
);
export const loadLanguageConfigDetailsDataFail = createAction('[wordingConfigurationsDialog/API] load language config data fail', props<DialogToasterData>());

export const updateLanguageConfig = createAction(
  '[wordingConfigurationsDialog/API] update language config',
  props<{ languageConfig: LanguageConfigDetailsData; closeModal?: boolean }>(),
);
export const updateLanguageConfigSuccess = createAction(
  '[wordingConfigurationsDialog/API] update language config success',
  props<{ languageConfig: LanguageConfigDetailsData; closeModal: boolean }>(),
);
export const updateLanguageConfigFail = createAction('[wordingConfigurationsDialog/API] update language config fail', props<{ title?: string; subtitle?: string }>());
