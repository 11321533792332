/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

/**
 * PageProjectRequest
 */
export interface PageProjectRequest {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  typesProject?: Array<PageProjectRequest.TypesProjectEnum>;
  statusProject?: PageProjectRequest.StatusProjectEnum;
  labelFamilyUuides?: Array<string>;
  usersUuides?: Array<string>;
  clientsUuides?: Array<string>;
  folderUuidEntity?: string;
  applicabilties?: Array<string>;
  shareType?: PageProjectRequest.ShareTypeEnum;
  allTypesProjectExceptFolder?: Array<PageProjectRequest.AllTypesProjectExceptFolderEnum>;
}
export namespace PageProjectRequest {
  export type TypesProjectEnum = 'PROJECT' | 'REFERENTIAL' | 'TEMPLATE' | 'PGAC' | 'FOLDER';
  export const TypesProjectEnum = {
    PROJECT: 'PROJECT' as TypesProjectEnum,
    REFERENTIAL: 'REFERENTIAL' as TypesProjectEnum,
    TEMPLATE: 'TEMPLATE' as TypesProjectEnum,
    PGAC: 'PGAC' as TypesProjectEnum,
    FOLDER: 'FOLDER' as TypesProjectEnum,
  };
  export type StatusProjectEnum = 'ACTIF' | 'ARCHIVE';
  export const StatusProjectEnum = {
    ACTIF: 'ACTIF' as StatusProjectEnum,
    ARCHIVE: 'ARCHIVE' as StatusProjectEnum,
  };
  export type ShareTypeEnum = 'ALL' | 'PUBLIC' | 'PRIVATE';
  export const ShareTypeEnum = {
    ALL: 'ALL' as ShareTypeEnum,
    PUBLIC: 'PUBLIC' as ShareTypeEnum,
    PRIVATE: 'PRIVATE' as ShareTypeEnum,
  };
  export type AllTypesProjectExceptFolderEnum = 'PROJECT' | 'REFERENTIAL' | 'TEMPLATE' | 'PGAC' | 'FOLDER';
  export const AllTypesProjectExceptFolderEnum = {
    PROJECT: 'PROJECT' as AllTypesProjectExceptFolderEnum,
    REFERENTIAL: 'REFERENTIAL' as AllTypesProjectExceptFolderEnum,
    TEMPLATE: 'TEMPLATE' as AllTypesProjectExceptFolderEnum,
    PGAC: 'PGAC' as AllTypesProjectExceptFolderEnum,
    FOLDER: 'FOLDER' as AllTypesProjectExceptFolderEnum,
  };
}
