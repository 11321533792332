/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicationFileLightDTO } from './applicationFileLightDTO';
import { EntityLight } from './entityLight';
import { LabelApplicabilityLightDTO } from './labelApplicabilityLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface CustomFieldDetailsResponseDTO {
  displayInFlowCard: boolean;
  displayInFlowBanner: boolean;
  displayOnFlowOpenning: boolean;
  uuidEntity: string;
  uuidType: string;
  name: string;
  createDate: Date;
  modificationDate: Date;
  typeField: CustomFieldDetailsResponseDTO.TypeFieldEnum;
  businessClass: CustomFieldDetailsResponseDTO.BusinessClassEnum;
  businessClassUuide: string;
  description: string;
  defaultValue: string;
  orderField: string;
  value: string;
  enums: string;
  placeholder: string;
  tooltip: string;
  pdfFieldName: string;
  variableName: string;
  readOnly: boolean;
  required: boolean;
  deleted: boolean;
  labelFamily: LabelFamilyLightDTO;
  application: EntityLight;
  file: ApplicationFileLightDTO;
  choixLinkType: CustomFieldDetailsResponseDTO.ChoixLinkTypeEnum;
  selectTypeValues: string;
  dynamicVariable: string;
  textDisplay: string;
  applicability: LabelApplicabilityLightDTO;
  longText?: boolean;
}
export namespace CustomFieldDetailsResponseDTO {
  export type TypeFieldEnum =
    | 'STRING'
    | 'LABEL'
    | 'INTEGER'
    | 'DOUBLE'
    | 'BOOLEAN'
    | 'DATE'
    | 'DATE_TIME'
    | 'ENUM_SINGLE_SELECTION'
    | 'ENUM_MULTIPLE_SELECTIONS'
    | 'FILE'
    | 'IMAGE'
    | 'METADATA'
    | 'LINK';
  export const TypeFieldEnum = {
    STRING: 'STRING' as TypeFieldEnum,
    LABEL: 'LABEL' as TypeFieldEnum,
    INTEGER: 'INTEGER' as TypeFieldEnum,
    DOUBLE: 'DOUBLE' as TypeFieldEnum,
    BOOLEAN: 'BOOLEAN' as TypeFieldEnum,
    DATE: 'DATE' as TypeFieldEnum,
    DATETIME: 'DATE_TIME' as TypeFieldEnum,
    ENUMSINGLESELECTION: 'ENUM_SINGLE_SELECTION' as TypeFieldEnum,
    ENUMMULTIPLESELECTIONS: 'ENUM_MULTIPLE_SELECTIONS' as TypeFieldEnum,
    FILE: 'FILE' as TypeFieldEnum,
    IMAGE: 'IMAGE' as TypeFieldEnum,
    METADATA: 'METADATA' as TypeFieldEnum,
    LINK: 'LINK' as TypeFieldEnum,
  };
  export type BusinessClassEnum =
    | 'PROJECT'
    | 'DOCUMENT_FLOW'
    | 'FLOW'
    | 'PHASE'
    | 'STEP'
    | 'BLOC'
    | 'PGAC'
    | 'CUSTOM_FIELD'
    | 'ELEMENT_RHP'
    | 'ELEMENT_ADR'
    | 'ELEMENT_EMM'
    | 'ELEMENT_DSI'
    | 'SIGNATORY'
    | 'TEAM_ROLE'
    | 'PARADE'
    | 'RUBRIC'
    | 'FORM'
    | 'QUESTION'
    | 'RUBRIC_OPTION'
    | 'OPTION_SIGNATORY'
    | 'APPLICATION_FILE'
    | 'FBL'
    | 'BLOC_DOCUMENT_FLOW'
    | 'APPLICATION'
    | 'USER'
    | 'RFI_TEMPLATE'
    | 'SKILL'
    | 'LABEL_FAMILY_SKILLS'
    | 'NAVIGATION_FLOW'
    | 'CUSTOM_STATUS'
    | 'GEOMETRY'
    | 'CUSTOM_FILE'
    | 'CUSTOM_FIELD_PROJECT'
    | 'CUSTOM_FIELD_FLOW'
    | 'TECHNICAL_OBJECT'
    | 'REVISION'
    | 'BLOC_PR'
    | 'BLOC_TXT'
    | 'ELEMENT_PR'
    | 'TEMPLATE_PARAMS'
    | 'CLIENT'
    | 'BLOC_MULTI_PR'
    | 'FORM_RESPONSE'
    | 'CUSTOM_FIELD_TECHNICAL_OBJECT'
    | 'FLOW_REFERENTIAL'
    | 'SCHEDULING_ITEM';
  export const BusinessClassEnum = {
    PROJECT: 'PROJECT' as BusinessClassEnum,
    DOCUMENTFLOW: 'DOCUMENT_FLOW' as BusinessClassEnum,
    FLOW: 'FLOW' as BusinessClassEnum,
    PHASE: 'PHASE' as BusinessClassEnum,
    STEP: 'STEP' as BusinessClassEnum,
    BLOC: 'BLOC' as BusinessClassEnum,
    PGAC: 'PGAC' as BusinessClassEnum,
    CUSTOMFIELD: 'CUSTOM_FIELD' as BusinessClassEnum,
    ELEMENTRHP: 'ELEMENT_RHP' as BusinessClassEnum,
    ELEMENTADR: 'ELEMENT_ADR' as BusinessClassEnum,
    ELEMENTEMM: 'ELEMENT_EMM' as BusinessClassEnum,
    ELEMENTDSI: 'ELEMENT_DSI' as BusinessClassEnum,
    SIGNATORY: 'SIGNATORY' as BusinessClassEnum,
    TEAMROLE: 'TEAM_ROLE' as BusinessClassEnum,
    PARADE: 'PARADE' as BusinessClassEnum,
    RUBRIC: 'RUBRIC' as BusinessClassEnum,
    FORM: 'FORM' as BusinessClassEnum,
    QUESTION: 'QUESTION' as BusinessClassEnum,
    RUBRICOPTION: 'RUBRIC_OPTION' as BusinessClassEnum,
    OPTIONSIGNATORY: 'OPTION_SIGNATORY' as BusinessClassEnum,
    APPLICATIONFILE: 'APPLICATION_FILE' as BusinessClassEnum,
    FBL: 'FBL' as BusinessClassEnum,
    BLOCDOCUMENTFLOW: 'BLOC_DOCUMENT_FLOW' as BusinessClassEnum,
    APPLICATION: 'APPLICATION' as BusinessClassEnum,
    USER: 'USER' as BusinessClassEnum,
    RFITEMPLATE: 'RFI_TEMPLATE' as BusinessClassEnum,
    SKILL: 'SKILL' as BusinessClassEnum,
    LABELFAMILYSKILLS: 'LABEL_FAMILY_SKILLS' as BusinessClassEnum,
    NAVIGATIONFLOW: 'NAVIGATION_FLOW' as BusinessClassEnum,
    CUSTOMSTATUS: 'CUSTOM_STATUS' as BusinessClassEnum,
    GEOMETRY: 'GEOMETRY' as BusinessClassEnum,
    CUSTOMFILE: 'CUSTOM_FILE' as BusinessClassEnum,
    CUSTOMFIELDPROJECT: 'CUSTOM_FIELD_PROJECT' as BusinessClassEnum,
    CUSTOMFIELDFLOW: 'CUSTOM_FIELD_FLOW' as BusinessClassEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as BusinessClassEnum,
    REVISION: 'REVISION' as BusinessClassEnum,
    BLOCPR: 'BLOC_PR' as BusinessClassEnum,
    BLOCTXT: 'BLOC_TXT' as BusinessClassEnum,
    ELEMENTPR: 'ELEMENT_PR' as BusinessClassEnum,
    TEMPLATEPARAMS: 'TEMPLATE_PARAMS' as BusinessClassEnum,
    CLIENT: 'CLIENT' as BusinessClassEnum,
    BLOCMULTIPR: 'BLOC_MULTI_PR' as BusinessClassEnum,
    FORMRESPONSE: 'FORM_RESPONSE' as BusinessClassEnum,
    CUSTOMFIELDTECHNICALOBJECT: 'CUSTOM_FIELD_TECHNICAL_OBJECT' as BusinessClassEnum,
    FLOWREFERENTIAL: 'FLOW_REFERENTIAL' as BusinessClassEnum,
    SCHEDULINGITEM: 'SCHEDULING_ITEM' as BusinessClassEnum,
  };
  export type ChoixLinkTypeEnum = 'DEFAULT' | 'ENTITY_LABEL_FAMILY' | 'ENTITY_LABEL_FAMILY_PARENT' | 'ENTITY_PARENT_LABEL_FAMILY' | 'ENTITY_PARENT_LABEL_FAMILY_PARENT';
  export const ChoixLinkTypeEnum = {
    DEFAULT: 'DEFAULT' as ChoixLinkTypeEnum,
    ENTITYLABELFAMILY: 'ENTITY_LABEL_FAMILY' as ChoixLinkTypeEnum,
    ENTITYLABELFAMILYPARENT: 'ENTITY_LABEL_FAMILY_PARENT' as ChoixLinkTypeEnum,
    ENTITYPARENTLABELFAMILY: 'ENTITY_PARENT_LABEL_FAMILY' as ChoixLinkTypeEnum,
    ENTITYPARENTLABELFAMILYPARENT: 'ENTITY_PARENT_LABEL_FAMILY_PARENT' as ChoixLinkTypeEnum,
  };
}
