import { compact } from 'lodash';

import { DocumentFlowSummaryResponseDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { FlowLightData } from '../flows/flow-light-data';
import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';
import { DateUtils } from '../../utils/date.utils';

import { DocumentNatureEnum, DocumentProvenanceEnum, DocumentSourceEnum, DocumentStatusEnum } from '../../enums';

export interface DashboardDocumentSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  name: string;
  refInt: string;
  documentIndex: string;
  state: DocumentStatusEnum;
  diffusionDate: string;
  principalCategory: LabelFamilyLightData;
  secondaryCategory: LabelFamilyLightData;
  provenance: DocumentProvenanceEnum;
  frozen: boolean;
  fullName?: string;
  undiffusedConflictTitle?: string;
  subtitle?: string;
  source: DocumentSourceEnum;
  refFlowData: FlowLightData;
  documentNature: DocumentNatureEnum;
  unlocked?: boolean;
  lastVdf: DocumentVersionLightData;
  complementRefInt?: string;
  largeFile?: boolean;
  lastDocVersionUuidEntity?: string;
  customWording: CustomWordingsData;
}

export namespace DashboardDocumentSummaryData {
  export function mapFromApiValue(document: DocumentFlowSummaryResponseDTO): DashboardDocumentSummaryData {
    return {
      uuidEntity: document.uuidEntity,
      name: document.name,
      refInt: document.refInt,
      documentIndex: document.indice,
      state: DocumentStatusEnum.convertFromApiValue.getValue(document.etat),
      family: document.family && LabelFamilyLightData.mapFromApiValue(document.family),
      source: DocumentSourceEnum.convertFromApiValue.getValue(document.documentSource),
      diffusionDate: document.diffusionDate && DateUtils.toDateFormat(document.diffusionDate),
      principalCategory: document.categoryPrincipal && LabelFamilyLightData.mapFromApiValue(document.categoryPrincipal),
      secondaryCategory: document.categorySecondary && LabelFamilyLightData.mapFromApiValue(document.categorySecondary),
      provenance: document.provenance && DocumentProvenanceEnum.convertFromApiValue.getValue(document.provenance),
      frozen: document.alreadyImported,
      fullName: compact([document.family && LabelFamilyLightData.mapFromApiValue(document.family)?.name, document?.name, document?.indice]).join(' - '),
      undiffusedConflictTitle: compact([document.family && LabelFamilyLightData.mapFromApiValue(document.family)?.name, document?.name, document?.refInt]).join(' - '),
      refFlowData: document.flowLight && FlowLightData.mapFromApiValue(document.flowLight),
      subtitle: compact([document.flowLight?.refInt, document.flowLight?.name]).join(' - '),
      documentNature: DocumentNatureEnum.convertFromApiValue.getValue(document.natureFile),
      unlocked: document.unlocked,
      complementRefInt: document.complementRefInt,
      lastVdf: document?.lastVdf && DocumentVersionLightData.mapFromApiValue(document?.lastVdf),
      largeFile: document.largeFile,
      lastDocVersionUuidEntity: document?.lastVersionDocumentFlowUuide,
      customWording: document.customWording && CustomWordingsData.mapFromApiValue(document.customWording),
    };
  }
}
