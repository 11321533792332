import { PlanLinkTypeEnum } from '../../enums';
import { ElementPlanLinkCreateRequestDTO, ElementPlanLinkLightResponseDTO } from '../../generated/models/models';

export interface ElementPlanLinkData {
  linkType: PlanLinkTypeEnum;
  sourceUuidEntity: string;
  destinationUuidEntity: string;
}

export namespace ElementPlanLinkData {
  export function mapToCreateRequest(projectUuidEntity: string, elementPlanLinkData: ElementPlanLinkData): ElementPlanLinkCreateRequestDTO {
    return {
      elementSourceUuidEntity: elementPlanLinkData.sourceUuidEntity,
      elementDestinationUuidEntity: elementPlanLinkData.destinationUuidEntity,
      projectUuidEntity,
      dependencyType: PlanLinkTypeEnum.convertToApiValue.getValue(elementPlanLinkData.linkType),
    };
  }

  export function mapFromApiValue(result: ElementPlanLinkLightResponseDTO): ElementPlanLinkData {
    return {
      sourceUuidEntity: result.elementPlanSourceUuidEntity,
      destinationUuidEntity: result.elementPlanDestinationUuidEntity,
      linkType: PlanLinkTypeEnum.convertFromApiValue.getValue(result.dependencyType),
    };
  }
}
