import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { FilterFlowImportReferentialRequestDTO, FlowReferentialSummaryResponseDTO, PageFlowReferentialRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { ColorPickerPaletteEnum, FlowTypeEnum, InnerSortDirectionEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface FlowToImportSummaryData {
  name: string;
  description: string;
  labelFamilyApplication: LabelFamilyLightData;
  labelFamilyProject: LabelFamilyLightData;
  modificationDate: string;
  refInt: string;
  refExt: string;
  flowCustomStatus: CustomStatusLightData;
  flowType: FlowTypeEnum;
  functionMarker: string;
  locationLocal: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  startDate: string;
  finishDate: string;
  color: string;
  order: number;
  frozen: boolean;
  availableUpdate: boolean;
}

export namespace FlowToImportSummaryData {
  const sortFields = ['name', 'labelFamilyApplication', 'modificationDate', 'refExt', 'functionalMarker', 'locationLocal', 'customStatusFlow', 'modificationDate'];
  export interface QueryRequest extends BaseQuery<FlowToImportSummaryData> {
    categories?: string[];
    families?: string[];
    statuses?: string[];
    types?: FlowTypeEnum[];
    referentialProjectUuidEntity?: string;
    uuidEntityDest?: string;
  }

  export function mapFromApiValue(flow: FlowReferentialSummaryResponseDTO): FlowToImportSummaryData {
    return {
      name: flow.name || '',
      description: flow.description || '',
      labelFamilyApplication: (flow.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(flow.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      labelFamilyProject: flow.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(flow.labelFamilyProject),
      modificationDate: DateUtils.toDateFormat(flow.modificationDate),
      flowCustomStatus: flow.customStatusFlow && CustomStatusLightData.mapFromApiValue(flow.customStatusFlow),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flow.typeFlow),
      functionMarker: flow.functionMarker,
      locationLocal: flow.locationLocal,
      uuid: flow.uuid,
      uuidType: flow.uuidType,
      uuidEntity: flow.uuidEntity,
      frozen: flow.alreadyImported,
      refInt: flow.refInt,
      refExt: flow.refExt,
      startDate: DateUtils.toDateFormat(flow.startDate),
      finishDate: DateUtils.toDateFormat(flow.finishDate),
      color: flow.flowBarColor || ColorPickerPaletteEnum.defaultColor,
      order: flow.flowBarOrder,
      availableUpdate: flow.availableUpdate,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageFlowReferentialRequestDTO {
    return {
      pageFlowRequest: {
        page: queries.page,
        size: queries.size,
        sort: sortFields.includes(queries?.sort?.key)
          ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
          : undefined,
        categories: queries.categories || undefined,
        families: queries.families || undefined,
        types: (queries.types && queries.types.map(type => FlowTypeEnum.convertToApiValue.getValue(type))) || undefined,
        customStatuses: queries.statuses || undefined,
        textSearch: queries.textSearch || undefined,
      },
      projectUuidEntity: queries.referentialProjectUuidEntity,
      uuidEntityDest: queries.uuidEntityDest,
    };
  }

  export function mapToPageFilterReferentialRequestApiValue(queries: QueryRequest): FilterFlowImportReferentialRequestDTO {
    return {
      categories: queries.categories || undefined,
      families: queries.families || undefined,
      statuses: queries.statuses || undefined,
      types: (queries.types && queries.types.map(type => FlowTypeEnum.convertToApiValue.getValue(type))) || undefined,
      projectUuidEntity: queries.referentialProjectUuidEntity,
    };
  }

  export function initializeQueryRequest(
    families: string[],
    categories: string[],
    statuses: string[],
    referentialProjectUuidEntity: string,
    uuidEntityDest: string,
    types: string[],
    searchText: string,
    sort: SortFilter<FlowToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<FlowToImportSummaryData>(sort),
      textSearch: searchText || undefined,
      families: (families || []).length ? families : undefined,
      categories: (categories || []).length ? categories : undefined,
      referentialProjectUuidEntity: referentialProjectUuidEntity || undefined,
      uuidEntityDest: uuidEntityDest || undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      types: (types && types.map(type => FlowTypeEnum.convertFromParamsValue.getValue(type))) || undefined,
    };
  }
}
