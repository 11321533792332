/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LabelFamilyLightDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name?: string;
  fullName: string;
  color: string;
  nature: LabelFamilyLightDTO.NatureEnum;
  priority: number;
  relativeNature: LabelFamilyLightDTO.RelativeNatureEnum;
  parentName: string;
  childrenCount: number;
  archived: boolean;
}
export namespace LabelFamilyLightDTO {
  export type NatureEnum =
    | 'ADR'
    | 'EMM'
    | 'TEAM'
    | 'DOCUMENT'
    | 'FLOW'
    | 'FORM'
    | 'RHPFUNCTION'
    | 'LIST'
    | 'DEVICE'
    | 'PROJECT'
    | 'HIERARCHY'
    | 'SKILL'
    | 'TECHNICAL_OBJECT'
    | 'LEGAL'
    | 'CONTRAT'
    | 'ACTIVITY_DOMAIN';
  export const NatureEnum = {
    ADR: 'ADR' as NatureEnum,
    EMM: 'EMM' as NatureEnum,
    TEAM: 'TEAM' as NatureEnum,
    DOCUMENT: 'DOCUMENT' as NatureEnum,
    FLOW: 'FLOW' as NatureEnum,
    FORM: 'FORM' as NatureEnum,
    RHPFUNCTION: 'RHPFUNCTION' as NatureEnum,
    LIST: 'LIST' as NatureEnum,
    DEVICE: 'DEVICE' as NatureEnum,
    PROJECT: 'PROJECT' as NatureEnum,
    HIERARCHY: 'HIERARCHY' as NatureEnum,
    SKILL: 'SKILL' as NatureEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as NatureEnum,
    LEGAL: 'LEGAL' as NatureEnum,
    CONTRAT: 'CONTRAT' as NatureEnum,
    ACTIVITYDOMAIN: 'ACTIVITY_DOMAIN' as NatureEnum,
  };
  export type RelativeNatureEnum = 'PARENT' | 'CHILD';
  export const RelativeNatureEnum = {
    PARENT: 'PARENT' as RelativeNatureEnum,
    CHILD: 'CHILD' as RelativeNatureEnum,
  };
}
