/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface ListSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  description: string;
  refInt: string;
  typeFlow: ListSummaryResponseDTO.TypeFlowEnum;
  labelFamilyProject: LabelFamilyLightDTO;
  isReferential: boolean;
}
export namespace ListSummaryResponseDTO {
  export type TypeFlowEnum =
    | 'GENERIC'
    | 'REFERENTIAL'
    | 'TEMPLATE'
    | 'SPECIFIC'
    | 'GROUP'
    | 'LIST_EMM'
    | 'LIST_ADR'
    | 'FLOW_FORM'
    | 'GROUP_REFERENTIAL'
    | 'LOCAL_REFERENTIAL'
    | 'REFERENTIAL_GENERIC'
    | 'REFERENTIAL_INTERVENTION';
  export const TypeFlowEnum = {
    GENERIC: 'GENERIC' as TypeFlowEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeFlowEnum,
    TEMPLATE: 'TEMPLATE' as TypeFlowEnum,
    SPECIFIC: 'SPECIFIC' as TypeFlowEnum,
    GROUP: 'GROUP' as TypeFlowEnum,
    LISTEMM: 'LIST_EMM' as TypeFlowEnum,
    LISTADR: 'LIST_ADR' as TypeFlowEnum,
    FLOWFORM: 'FLOW_FORM' as TypeFlowEnum,
    GROUPREFERENTIAL: 'GROUP_REFERENTIAL' as TypeFlowEnum,
    LOCALREFERENTIAL: 'LOCAL_REFERENTIAL' as TypeFlowEnum,
    REFERENTIALGENERIC: 'REFERENTIAL_GENERIC' as TypeFlowEnum,
    REFERENTIALINTERVENTION: 'REFERENTIAL_INTERVENTION' as TypeFlowEnum,
  };
}
