import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum VisualizationEmmTabEnum {
  General = 'general',
  Verification = 'verification',
}

export namespace VisualizationEmmTabEnum {
  export const allTabs = [VisualizationEmmTabEnum.General, VisualizationEmmTabEnum.Verification];

  const key = 'lists.visualization.tab.';
  export const toString: SfxMap<VisualizationEmmTabEnum, string> = new SfxMap<VisualizationEmmTabEnum, string>([
    [VisualizationEmmTabEnum.General, key + 'general'],
    [VisualizationEmmTabEnum.Verification, key + 'verification'],
  ]);

  export const iconData: SfxMap<VisualizationEmmTabEnum, IconData> = new SfxMap<VisualizationEmmTabEnum, IconData>([
    [VisualizationEmmTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [VisualizationEmmTabEnum.Verification, { name: 'tools', prefix: 'fas' }],
  ]);
}
