import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { PageProjectRequest } from '../../generated/models/pageProjectRequest';
import { SfxMap } from '../../utils/enum-utils';

export enum ProjectPublishedEnum {
  Public = 'public',
  Private = 'private',
  All = 'all',
}

export namespace ProjectPublishedEnum {
  const values = [ProjectPublishedEnum.All, ProjectPublishedEnum.Public, ProjectPublishedEnum.Private];

  const key = 'projects.filters.published.';
  export const toString = new SfxMap<ProjectPublishedEnum, string>([
    [ProjectPublishedEnum.Public, key + 'public'],
    [ProjectPublishedEnum.Private, key + 'private'],
    [ProjectPublishedEnum.All, key + 'all'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, ProjectPublishedEnum>([
    ['public', ProjectPublishedEnum.Public],
    ['private', ProjectPublishedEnum.Private],
    ['all', ProjectPublishedEnum.All],
  ]);

  export const convertToApiValue = new SfxMap<string, PageProjectRequest.ShareTypeEnum>([
    ['public', PageProjectRequest.ShareTypeEnum.PUBLIC],
    ['private', PageProjectRequest.ShareTypeEnum.PRIVATE],
    ['all', PageProjectRequest.ShareTypeEnum.ALL],
  ]);

  export const convertToParamsValue = new SfxMap<ProjectPublishedEnum, string>([
    [ProjectPublishedEnum.Public, 'public'],
    [ProjectPublishedEnum.Private, 'private'],
    [ProjectPublishedEnum.All, 'all'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<ProjectPublishedEnum>[]> =>
    of(
      values.map((value: ProjectPublishedEnum) => ({
        value,
        title: toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<ProjectPublishedEnum>, item: ProjectPublishedEnum) => node.value === item;
}
