import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { UpdateFormResponseDTO } from '../../generated/models/updateFormResponseDTO';
import { FlowLightData } from '../flows/flow-light-data';
import { FormResponseLightData } from './form-light-response';

export interface FormFlowsData {
  form: FormResponseLightData;
  labeledLinks: FlowLightData[];
}

export namespace FormFlowsData {
  export function mapFromApiValue(data: UpdateFormResponseDTO): FormFlowsData {
    return {
      form: FormResponseLightData.mapFromApiResponse(data.form),
      labeledLinks: (data.flows || []).map(flow => FlowLightData.mapToResponseEntityLightData(flow)),
    };
  }

  export function mapToConflictData(data: FormFlowsData): ConflictData {
    return {
      title: (data.form.reference ? data.form.reference.concat(' - ') : '').concat(data.form.name),
      labeledLinks: (data.labeledLinks || []).map(flow => ({ name: flow.name, label: flow.status })),
    };
  }
}
