import { createAction, props } from '@ngrx/store';
import { FieldNameEnum } from '../../../../../core/enums';
import { ApplicationEventLightData, FormResponseDetailsData, FormResponseSummaryData, FormToImportSummaryData, LazyLoadResponse } from '../../../../../core/models';
import { FormReplyDetails } from '../../../../../core/models/form/form-reply-details';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

// Import dialog actions
export const loadForms = createAction('[dFormDialog] load forms');
export const loadFormsFail = createAction('[dFormDialog] load forms fail', props<DialogToasterData>());
export const loadFormsSuccess = createAction(
  '[dFormDialog] load forms success',
  props<{ forms: FormToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);

export const loadMoreForms = createAction('[dFormDialog] load more forms');

export const importForms = createAction('[dFormDialog] import forms', props<{ selectedForms: string[]; allSelected?: boolean }>());
export const importFormsFail = createAction('[dFormDialog] import forms fail', props<DialogToasterData>());
export const importFormsSuccess = createAction('[dFormDialog] import forms success');

export const updateImportedForms = createAction('[dFormDialog] update imported forms');
export const updateImportedFormsFail = createAction('[dFormDialog] update imported forms fail', props<DialogToasterData>());
export const updateImportedFormsRefresh = createAction('[dFormDialog] update imported forms refresh', props<{ refresh: boolean }>());

// Reply dialog actions
export const replyForm = createAction('[dFormDialog] reply form', props<{ response: string }>());
export const replyFormFail = createAction('[dFormDialog] reply form fail', props<DialogToasterData>());
export const replyFormSuccess = createAction('[dFormDialog] reply form success', props<{ response: FormResponseDetailsData }>());

// response dialog actions
export const loadResponses = createAction('[dFormDialog] load responses list');
export const loadResponsesFail = createAction('[dFormDialog] load responses fail', props<DialogToasterData>());
export const loadResponsesSuccess = createAction(
  '[dFormDialog] load responses success',
  props<{ responses: FormResponseSummaryData[]; filteredTotalCount: number; reset?: boolean }>(),
);

export const loadMoreResponses = createAction('[dFormDialog] load more responses');

export const openFormResponseFilterDatePopover = createAction('[dFormDialog] open form response filter date popover', props<{ origin: HTMLElement }>());

export const openExportXlsFilePopover = createAction('[dFormDialog] open xsl file popover', props<{ origin: HTMLElement }>());
export const exportExcelFile = createAction('[dFormDialog] export responses in excel file', props<{ onlyExportableValues: boolean; customFileUuidEntity: string }>());
export const setExportExcelLoader = createAction('[dFormDialog] export responses in excel file loader');
export const exportExcelFileSuccess = createAction('[dFormDialog] export responses in excel file success');
export const exportExcelFileFail = createAction('[dFormDialog] export responses in excel file fail', props<DialogToasterData>());

export const exportImageFile = createAction('[dFormDialog] export responses in image file ');
export const setExportImageLoader = createAction('[dFormDialog] export responses in image file loader');
export const exportImageFileSuccess = createAction('[dFormDialog] export responses in image file success');
export const exportImageFileFail = createAction('[dFormDialog] export responses in image file fail', props<DialogToasterData>());

export const overviewResponse = createAction('[dFormDialog] overview response', props<{ response: FormResponseSummaryData }>());
export const editResponse = createAction('[dFormDialog] edit response', props<{ response: FormResponseSummaryData }>());

export const openEditResponseReference = createAction('[dFormDialog] open edit response reference', props<{ response: FormResponseSummaryData; origin: HTMLElement }>());

export const setEditResponseReferenceLoading = createAction('[dFormDialog] set edit response reference loading', props<{ loading: boolean }>());
export const editResponseReferenceFail = createAction('[dFormDialog] edit response reference fail', props<DialogToasterData>());

export const setSelectedResponses = createAction('[dFormDialog] set selected responses', props<{ responsesUuidEntity: string[] }>());

export const openGenerateDocument = createAction('[dFormDialog] open generate document', props<{ response: FormResponseSummaryData; origin: HTMLElement }>());
export const openGenerateMultipleDocument = createAction('[dFormDialog] open generate multiple document', props<{ origin: HTMLElement }>());
export const openGenerateDocumentFail = createAction('[dFormDialog] open generate document fail', props<DialogToasterData>());

export const generateDocumentFail = createAction('[dFormDialog] generate document from response fail', props<DialogToasterData>());

export const checkGenerateDocumentHasRefGenerated = createAction(
  '[dFormDialog] check generate document has generated reference',
  props<{ labelFamilyUuidEntity: string; fieldName: FieldNameEnum }>(),
);
export const checkGenerateDocumentHasRefGeneratedFail = createAction('[dFormDialog] check generate document has generated reference fail', props<DialogToasterData>());
export const checkGenerateDocumentHasRefGeneratedSuccess = createAction(
  '[dFormDialog] check generate document has generated reference success',
  props<{ generateDocumentHasRefGenerated: boolean; fieldName: FieldNameEnum }>(),
);

export const displayDocumentFail = createAction('[dFormDialog] display document fail', props<DialogToasterData>());
export const setGenerateDocumentLoading = createAction('[dFormDialog] set generate document loading', props<{ loading: boolean }>());

export const resetResponseState = createAction('[dFormDialog] reset response state', props<{ responseUuidEntity: string }>());
export const resetResponseStateFail = createAction('[dFormDialog] reset response state fail', props<DialogToasterData>());

export const duplicateResponse = createAction('[dFormDialog] duplicate response', props<{ responseUuidEntity: string; formBusinessLinkUuidEntity: string }>());
export const duplicateResponseSuccess = createAction('[dFormDialog] duplicate response success');
export const duplicateResponseFail = createAction('[dFormDialog] duplicate response fail', props<DialogToasterData>());

export const deleteResponse = createAction('[dFormDialog] delete response', props<{ responseUuidEntity: string; formBusinessLinkUuidEntity: string }>());
export const deleteResponseFail = createAction('[dFormDialog] delete response fail', props<DialogToasterData>());

export const openEditResponseStatus = createAction('[dFormDialog] open edit response status', props<{ response: FormResponseSummaryData; origin: HTMLElement }>());

export const setEditResponseStatusLoading = createAction('[dFormDialog] set edit response status loading', props<{ loading: boolean }>());
export const editResponseStatusFail = createAction('[dFormDialog] edit response status fail', props<DialogToasterData>());

export const loadHistoryListSuccess = createAction('[dFormDialog] Load history list success', props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>());
export const loadHistoryListNextPage = createAction('[dFormDialog] Load history list next page');
export const loadHistoryListFails = createAction('[dFormDialog] Load history list fails', props<DialogToasterData>());

export const loadFormReplyDetails = createAction('[dFormDialog] Load form reply details');
export const loadFormReplyDetailsSuccess = createAction('[dFormDialog] Load form reply details success', props<{ formReplyDetails: FormReplyDetails }>());
export const loadFormReplyDetailsFails = createAction('[dFormDialog] Load form reply details fails', props<DialogToasterData>());
export const setAllResponseSelected = createAction('[dFormDialog] set all responses selected', props<{ allResponseSelected: boolean }>());
