/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { LabelApplicabilityResponseDTO } from './labelApplicabilityResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { TechnicalObjectLightDTO } from './technicalObjectLightDTO';

export interface TechnicalObjectDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  createDate: Date;
  modificationDate: Date;
  name: string;
  description: string;
  refInt: string;
  refExt: string;
  nodeOrder: number;
  nature: TechnicalObjectDetailsResponseDTO.NatureEnum;
  applicability: LabelApplicabilityResponseDTO;
  labelFamily: LabelFamilyLightDTO;
  customStatus: CustomStatusLightDTO;
  parent: TechnicalObjectLightDTO;
}
export namespace TechnicalObjectDetailsResponseDTO {
  export type NatureEnum = 'TECHNICAL_OBJECT' | 'GEOGRAPHIC_OBJECT';
  export const NatureEnum = {
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as NatureEnum,
    GEOGRAPHICOBJECT: 'GEOGRAPHIC_OBJECT' as NatureEnum,
  };
}
