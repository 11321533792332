<div class="input-container {{ cssClass }}" [class.input-disabled]="disabled" [class.error]="infoBlock && (sfxInput.errors || invalid || manualControl)">
  <div class="input-text-container">
    <input
      #input
      #sfxInput="ngModel"
      sfxNumberControl
      class="sfx-input-text {{ sfxClass }}"
      [class.filter-active]="highlightOnFilterActive && !!value"
      [class.sfx-icon]="iconName"
      [class.disabled]="disabled"
      [class.has-indent]="!!iconName && iconPosition === 'left'"
      [attr.preferred-focus]="preferredFocus"
      [type]="type"
      [placeholder]="(placeholder | translate) || ''"
      [disabled]="disabled"
      [required]="required"
      [readonly]="readonly"
      [tabindex]="readonly || disabled ? -1 : 0"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [min]="min"
      [max]="max"
      [step]="step"
      [pattern]="pattern"
      [applyControl]="isNumber || isDecimal"
      [decimal]="isDecimal"
      [sfxContextMenu]="contextMenu && !disabled"
      [(ngModel)]="value"
      (keydown)="(type !== 'number' || $event.code !== KeyboardKeyEnum.KeyE)"
      (contextMenuPaste)="value = $event"
      (blur)="blur.emit(value)"
      (keyup)="keyUp.emit(input)"
      (pasteEvent)="pasteEvent($event)"
    />
    <sfx-copy-to-clipboard *ngIf="copyToClipboard" [text]="value"></sfx-copy-to-clipboard>
  </div>
  <sfx-icon *ngIf="iconName" data-cy="icon" class="input-icon {{ iconPosition }}" [name]="iconName" (click)="iconToggled.emit()"></sfx-icon>
  <ng-content select=".info-message"></ng-content>
  <div *ngIf="infoBlock" class="sfx-control-validation-messages-container control-message">
    <ng-content select=".validation-message"></ng-content>
  </div>
</div>
