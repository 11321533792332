import { VersionDocumentFlowLightDTO } from '../../generated/models/versionDocumentFlowLightDTO';

import { DocumentStatusEnum } from '../../enums';
import { DocumentVersionStatusEnum } from '../../enums/document/document-version-status.enum';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface DocumentVersionLightData {
  uuidEntity: string;
  index: string;
  state: DocumentStatusEnum;
  docVersionStatus: DocumentVersionStatusEnum;
}
export namespace DocumentVersionLightData {
  const emptyDocumentIndex = '-';

  export function mapFromApiValue(docVersion: VersionDocumentFlowLightDTO): DocumentVersionLightData {
    return {
      uuidEntity: docVersion.uuidEntity,
      index: docVersion.indice || emptyDocumentIndex,
      state: DocumentStatusEnum.convertFromApiValue.getValue(docVersion.etat),
      docVersionStatus: DocumentVersionStatusEnum.convertFromApiValue.getValue(docVersion.status),
    };
  }

  export function mapToSelectItem(
    docVersion: VersionDocumentFlowLightDTO,
    isMultiple: boolean,
    selectedDocVersions?: string[],
  ): MultiSelectItemData<DocumentVersionLightData> | SingleSelectItemData<DocumentVersionLightData | string> {
    return {
      value: isMultiple ? docVersion.uuidEntity : DocumentVersionLightData.mapFromApiValue(docVersion),
      title: DocumentStatusEnum.concatDocIndex(DocumentVersionStatusEnum.convertFromApiValue.getValue(docVersion?.status)).getValue(
        DocumentStatusEnum.convertFromApiValue.getValue(docVersion.etat),
      ),
      translateParams: { docIndex: docVersion.indice },
      disabled: (isMultiple && docVersion && (selectedDocVersions || []).includes(docVersion.uuidEntity)) || docVersion.files.length === 0,
    };
  }
}
