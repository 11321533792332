import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum VisualizationAdrTabEnum {
  General = 'general',
  Parade = 'parade',
}

export namespace VisualizationAdrTabEnum {
  export const allTabs = [VisualizationAdrTabEnum.General, VisualizationAdrTabEnum.Parade];

  const key = 'lists.visualization.tab.';
  export const toString: SfxMap<VisualizationAdrTabEnum, string> = new SfxMap<VisualizationAdrTabEnum, string>([
    [VisualizationAdrTabEnum.General, key + 'general'],
    [VisualizationAdrTabEnum.Parade, key + 'parade'],
  ]);

  export const iconData: SfxMap<VisualizationAdrTabEnum, IconData> = new SfxMap<VisualizationAdrTabEnum, IconData>([
    [VisualizationAdrTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [VisualizationAdrTabEnum.Parade, { name: 'shield-alt', prefix: 'fas' }],
  ]);
}
