import { ResetValidationFlowData } from '..';
import { UnlockFlowRequestDTO } from '../../generated/models/unlockFlowRequestDTO';

export type ResetValidationUnlockFlow = Partial<ResetValidationFlowData>;

export namespace ResetValidationUnlockFlow {
  export function mapToRequestApiValue(object: ResetValidationUnlockFlow, projectUuidEntity: string): UnlockFlowRequestDTO {
    return {
      projectUuidEntity,
      resetInternalValidation: object.resetInternalValidation,
      resetExternalValidation: object.resetExternalValidation,
      resetInternalParentValidation: object.resetInternalParentValidation,
      resetInternalChildValidation: object.resetInternalChildValidation,
      resetExternalParentValidation: object.resetExternalParentValidation,
      resetExternalChildValidation: object.resetExternalChildValidation,
    };
  }
}
