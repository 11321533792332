import { SfxMap } from '../../utils/enum-utils';

export enum BlockAdrColumnEnum {
  Family = 'family',
  Name = 'risk',
  Picto = 'picto',
  Source = 'source',
  Major = 'major',
  Action = 'action',
  MobileAppName = 'mobileAppName',
  MobileAppAction = 'mobileAppAction',
}

export namespace BlockAdrColumnEnum {
  export const allColumns = [
    BlockAdrColumnEnum.Family,
    BlockAdrColumnEnum.Picto,
    BlockAdrColumnEnum.Name,
    BlockAdrColumnEnum.Source,
    BlockAdrColumnEnum.Major,
    BlockAdrColumnEnum.Action,
  ];

  export const blockMobileAppAdrColumns = [BlockAdrColumnEnum.MobileAppName, BlockAdrColumnEnum.Picto, BlockAdrColumnEnum.Major, BlockAdrColumnEnum.MobileAppAction];

  const key = 'blocks.adr.table.header.';
  export const toString: SfxMap<BlockAdrColumnEnum, string> = new SfxMap<BlockAdrColumnEnum, string>([
    [BlockAdrColumnEnum.Family, key + 'family'],
    [BlockAdrColumnEnum.Name, key + 'risk'],
    [BlockAdrColumnEnum.Picto, key + 'picto'],
    [BlockAdrColumnEnum.Source, key + 'source'],
    [BlockAdrColumnEnum.Major, key + 'major'],
    [BlockAdrColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<BlockAdrColumnEnum, string> = new SfxMap<BlockAdrColumnEnum, string>([
    [BlockAdrColumnEnum.Family, 'labelFamily'],
    [BlockAdrColumnEnum.Name, 'risk'],
    [BlockAdrColumnEnum.Source, 'riskSource'],
    [BlockAdrColumnEnum.Major, 'riskMajeur'],
  ]);
}
