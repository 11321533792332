import { initializeQueries, BaseQuery } from '../utils/base-query.model';
import { DefaultCategoryPageRequest, DefaultCategorySummaryResponseDTO } from '../../generated/models/models';
import { NatureLabelFamilyResponseDTO } from '../../generated/models/natureLabelFamilyResponseDTO';
import { LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum, InnerSortDirectionEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface DefaultCategorySummaryData {
  childrenCount: number;
  color: string;
  name: string;
  nature: LabelFamilyNatureEnum;
  relativeNature: LabelFamilyRelativeNatureEnum;
  priority: number;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  parent?: DefaultCategorySummaryData;
  archived?: boolean;
}

export namespace DefaultCategorySummaryData {
  export interface QueryRequest extends BaseQuery<DefaultCategorySummaryData> {
    nature?: LabelFamilyNatureEnum;
    relativeNature?: LabelFamilyRelativeNatureEnum;
    uuidEntityFamilyParent?: string;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(category: DefaultCategorySummaryResponseDTO): DefaultCategorySummaryData {
    return {
      childrenCount: category.childTotalCount,
      color: category.color || '#0d4e8a',
      name: (category.fullName && category.name + ' - ' + category.fullName) || category.name,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(category.nature),
      priority: category.priority,
      uuid: category.uuid,
      uuidEntity: category.uuidEntity,
      uuidOrganization: category.uuidOrganization,
      uuidType: category.uuidType,
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(category.relativeNature),
      archived: category.archived,
    };
  }

  export function mapFromNatureFamilyApiValue(natureFamily: NatureLabelFamilyResponseDTO): DefaultCategorySummaryData {
    return {
      uuidEntity: LabelFamilyNatureEnum.relativeUuidEntities.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      relativeNature: LabelFamilyRelativeNatureEnum.Container,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature),
      name: LabelFamilyNatureEnum.toString.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      color: '#000',
      childrenCount: natureFamily.childTotalCount,
    } as DefaultCategorySummaryData;
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): DefaultCategoryPageRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(queries.nature),
      uuideFamilyParent: queries.uuidEntityFamilyParent,
      choixRelativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(queries.relativeNature),
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, nature: string, state?: string): QueryRequest {
    return {
      ...initializeQueries<DefaultCategorySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      nature: LabelFamilyNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }

  export function initializeFilterQueries(page: number, searchText: string, nature: LabelFamilyNatureEnum): QueryRequest {
    return {
      ...initializeQueries<DefaultCategorySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      page,
      nature,
      relativeNature: LabelFamilyRelativeNatureEnum.Parent,
    };
  }

  export function toString(categorySummary: DefaultCategorySummaryData): string {
    return categorySummary.name;
  }

  export const initialRows: DefaultCategorySummaryData[] = LabelFamilyNatureEnum.initialValues(true).map(
    initialRow =>
      ({
        uuidEntity: initialRow.uuidEntity,
        relativeNature: LabelFamilyRelativeNatureEnum.Container,
        nature: initialRow.nature,
        name: LabelFamilyNatureEnum.toString.getValue(initialRow.nature),
        color: '#000',
        childrenCount: 1,
      } as DefaultCategorySummaryData),
  );

  export const getInitialRows = (nature: LabelFamilyNatureEnum, defaultCategories: DefaultCategorySummaryData[]): DefaultCategorySummaryData[] =>
    nature ? defaultCategories.filter(row => row.nature === nature) : defaultCategories;
}
