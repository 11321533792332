import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum SectionCertificateEnum {
  AllEmms = 1,
  SomeEmms = 2,
}

export namespace SectionCertificateEnum {
  const key = 'sections.certificateType.';

  export const toString = (isCertificate?: boolean): SfxMap<SectionCertificateEnum, string> =>
    new SfxMap<SectionCertificateEnum, string>([
      [SectionCertificateEnum.AllEmms, key + `${isCertificate ? 'allEmms' : 'allEmmsRef'}`],
      [SectionCertificateEnum.SomeEmms, key + 'someEmms'],
    ]);

  export const selectItems = (isCertificate: boolean, selectedType: SectionCertificateEnum): SingleSelectItemData<SectionCertificateEnum>[] =>
    [SectionCertificateEnum.AllEmms, SectionCertificateEnum.SomeEmms].map(type => ({
      value: type,
      title: SectionCertificateEnum.toString(isCertificate).getValue(type),
      selected: type === selectedType,
    }));
}
