import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum EpsilonOutRejectEnum {
  Comment1 = 1,
  Comment2 = 2,
  Comment3 = 3,
  Comment4 = 4,
  Comment5 = 5,
  Comment6 = 6,
  Comment7 = 7,
  Comment8 = 8,
  Comment9 = 9,
  Comment10 = 10,
}

export namespace EpsilonOutRejectEnum {
  const values = [
    EpsilonOutRejectEnum.Comment1,
    EpsilonOutRejectEnum.Comment2,
    EpsilonOutRejectEnum.Comment3,
    EpsilonOutRejectEnum.Comment4,
    EpsilonOutRejectEnum.Comment5,
    EpsilonOutRejectEnum.Comment6,
    EpsilonOutRejectEnum.Comment7,
    EpsilonOutRejectEnum.Comment8,
    EpsilonOutRejectEnum.Comment9,
    EpsilonOutRejectEnum.Comment10,
  ];

  const key = 'pgacs.modals.epsilonOut.comments.';

  export const convertFromApiValue: SfxMap<string, EpsilonOutRejectEnum> = new SfxMap<string, EpsilonOutRejectEnum>([
    ['comment1', EpsilonOutRejectEnum.Comment1],
    ['comment2', EpsilonOutRejectEnum.Comment2],
    ['comment3', EpsilonOutRejectEnum.Comment3],
    ['comment4', EpsilonOutRejectEnum.Comment4],
    ['comment5', EpsilonOutRejectEnum.Comment5],
    ['comment6', EpsilonOutRejectEnum.Comment6],
    ['comment7', EpsilonOutRejectEnum.Comment7],
    ['comment8', EpsilonOutRejectEnum.Comment8],
    ['comment9', EpsilonOutRejectEnum.Comment9],
    ['comment10', EpsilonOutRejectEnum.Comment10],
  ]);

  export const convertToApiValue: SfxMap<EpsilonOutRejectEnum, string> = new SfxMap<EpsilonOutRejectEnum, string>([
    [EpsilonOutRejectEnum.Comment1, 'comment1'],
    [EpsilonOutRejectEnum.Comment2, 'comment2'],
    [EpsilonOutRejectEnum.Comment3, 'comment3'],
    [EpsilonOutRejectEnum.Comment4, 'comment4'],
    [EpsilonOutRejectEnum.Comment5, 'comment5'],
    [EpsilonOutRejectEnum.Comment6, 'comment6'],
    [EpsilonOutRejectEnum.Comment7, 'comment7'],
    [EpsilonOutRejectEnum.Comment8, 'comment8'],
    [EpsilonOutRejectEnum.Comment9, 'comment9'],
    [EpsilonOutRejectEnum.Comment10, 'comment10'],
  ]);

  export const toString: SfxMap<EpsilonOutRejectEnum, string> = new SfxMap<EpsilonOutRejectEnum, string>([
    [EpsilonOutRejectEnum.Comment1, key + 'comment1'],
    [EpsilonOutRejectEnum.Comment2, key + 'comment2'],
    [EpsilonOutRejectEnum.Comment3, key + 'comment3'],
    [EpsilonOutRejectEnum.Comment4, key + 'comment4'],
    [EpsilonOutRejectEnum.Comment5, key + 'comment5'],
    [EpsilonOutRejectEnum.Comment6, key + 'comment6'],
    [EpsilonOutRejectEnum.Comment7, key + 'comment7'],
    [EpsilonOutRejectEnum.Comment8, key + 'comment8'],
    [EpsilonOutRejectEnum.Comment9, key + 'comment9'],
    [EpsilonOutRejectEnum.Comment10, key + 'comment10'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<EpsilonOutRejectEnum>[]> =>
    of(
      values.map(comment => ({
        value: comment,
        title: toString.getValue(comment),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<EpsilonOutRejectEnum>, item: EpsilonOutRejectEnum) => node.value === item;
}
