/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategorySummaryResponseDTO } from './categorySummaryResponseDTO';
import { DocumentFlowSummaryResponseDTO } from './documentFlowSummaryResponseDTO';
import { FlowSummaryResponseDTO } from './flowSummaryResponseDTO';
import { TeamRoleSummaryResponseDTO } from './teamRoleSummaryResponseDTO';

export interface CategoryDeleteResponseDTO {
  success: boolean;
  nature: CategoryDeleteResponseDTO.NatureEnum;
  childsLinks: Array<CategorySummaryResponseDTO>;
  flowLinks: Array<FlowSummaryResponseDTO>;
  listLinks: Array<FlowSummaryResponseDTO>;
  documentLinks: Array<DocumentFlowSummaryResponseDTO>;
  teamRoleLinks: Array<TeamRoleSummaryResponseDTO>;
}
export namespace CategoryDeleteResponseDTO {
  export type NatureEnum = 'DOCUMENT' | 'FLOW' | 'LIST' | 'HIERARCHY' | 'CHILD';
  export const NatureEnum = {
    DOCUMENT: 'DOCUMENT' as NatureEnum,
    FLOW: 'FLOW' as NatureEnum,
    LIST: 'LIST' as NatureEnum,
    HIERARCHY: 'HIERARCHY' as NatureEnum,
    CHILD: 'CHILD' as NatureEnum,
  };
}
