import { DateUtils } from '../../utils/date.utils';

import { ApplicationEventLightDTO, PageApplicationEventRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { ApplicationEventActionEnum, ApplicationEventSourceEnum, BusinessClassNameEnum, InnerSortDirectionEnum } from '../../enums';

export interface ApplicationEventLightData {
  actionDate: string;
  actionTime: string;
  actionNature: ApplicationEventActionEnum;
  userName: string;
  userColor: string;
  entityName: string;
  entityColor: string;
  businessClassName: BusinessClassNameEnum;
  businessName: string;
  eventSource: ApplicationEventSourceEnum;
  comment: string;
}

export const missingUserColor = '#0040FF';

export namespace ApplicationEventLightData {
  export interface QueryRequest extends BaseQuery<ApplicationEventLightData> {
    businessClassName: BusinessClassNameEnum;
    businessClassParentReference: string;
    textSearch?: string;
  }
  export function mapFromApiValue(customApplicationEvent: ApplicationEventLightDTO): ApplicationEventLightData {
    return {
      actionDate: customApplicationEvent.actionDate && DateUtils.toDateFormat(customApplicationEvent.actionDate),
      actionTime: customApplicationEvent.actionDate && DateUtils.toTimeFormat(customApplicationEvent.actionDate),
      actionNature: ApplicationEventActionEnum.convertFromApiValue.getValue(customApplicationEvent.actionNature),
      userName: customApplicationEvent.userLibelle,
      userColor: customApplicationEvent.userColor || missingUserColor,
      entityName: customApplicationEvent.entityName,
      entityColor: customApplicationEvent.entityColor,
      businessClassName: BusinessClassNameEnum.convertFromApiValue.getValue(customApplicationEvent.businessClassName),
      eventSource: ApplicationEventSourceEnum.convertFromApiValue.getValue(customApplicationEvent.eventSource),
      comment: customApplicationEvent.comment,
      businessName: customApplicationEvent.businessParentName,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageApplicationEventRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: { attribute: queries?.sort?.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch || undefined,
      businessClassName: BusinessClassNameEnum.convertToApiValue.getValue(queries.businessClassName),
      businessClassParentRef: queries.businessClassParentReference,
    };
  }

  export function initializeQueryRequest(uuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<ApplicationEventLightData>({ key: 'actionDate' }),
      businessClassName: BusinessClassNameEnum.CustomStatus,
      businessClassParentReference: uuidEntity,
    };
  }
}
