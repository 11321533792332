import { AnimationTriggerMetadata, trigger, state, transition, style, animate } from '@angular/animations';

export enum PopoverAnimationStateEnum {
  Default = 'default',
  Closing = 'closing',
}

export const popoverAnimations: {
  readonly popoverAnimation: AnimationTriggerMetadata;
} = {
  popoverAnimation: trigger('fadeAnimation', [
    state(PopoverAnimationStateEnum.Default, style({ opacity: 1 })),
    transition('void => *', [style({ opacity: 0 }), animate('{{ fadeIn }}ms')]),
  ]),
};
