import { SfxMap } from '../../utils/enum-utils';
import { SchedulingResponseDTO } from '../../generated/models/schedulingResponseDTO';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum SchedulePriorityEnum {
  Low = 1,
  Medium = 2,
  High = 3,
}

export namespace SchedulePriorityEnum {
  export const values = [SchedulePriorityEnum.Low, SchedulePriorityEnum.Medium, SchedulePriorityEnum.High];

  export const convertFromApiValue = new SfxMap<SchedulingResponseDTO.PriorityEnum, SchedulePriorityEnum>([
    [SchedulingResponseDTO.PriorityEnum.LOW, SchedulePriorityEnum.Low],
    [SchedulingResponseDTO.PriorityEnum.MEDIUM, SchedulePriorityEnum.Medium],
    [SchedulingResponseDTO.PriorityEnum.HIGH, SchedulePriorityEnum.High],
  ]);

  export const convertToApiValue = new SfxMap<SchedulePriorityEnum, SchedulingResponseDTO.PriorityEnum>([
    [SchedulePriorityEnum.Low, SchedulingResponseDTO.PriorityEnum.LOW],
    [SchedulePriorityEnum.Medium, SchedulingResponseDTO.PriorityEnum.MEDIUM],
    [SchedulePriorityEnum.High, SchedulingResponseDTO.PriorityEnum.HIGH],
  ]);

  export const convertToParamsValue = new SfxMap<SchedulePriorityEnum, string>([
    [SchedulePriorityEnum.Low, 'low'],
    [SchedulePriorityEnum.Medium, 'medium'],
    [SchedulePriorityEnum.High, 'high'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, SchedulePriorityEnum>([
    ['low', SchedulePriorityEnum.Low],
    ['medium', SchedulePriorityEnum.Medium],
    ['high', SchedulePriorityEnum.High],
  ]);

  export const color = new SfxMap<SchedulePriorityEnum, string>([
    [SchedulePriorityEnum.Low, '#6cc183'],
    [SchedulePriorityEnum.Medium, '#ffad4a'],
    [SchedulePriorityEnum.High, '#dc3545'],
  ]);

  const key = 'schedule.addModal.priority.';

  export const toString = new SfxMap<SchedulePriorityEnum, string>([
    [SchedulePriorityEnum.Low, key + 'low'],
    [SchedulePriorityEnum.Medium, key + 'medium'],
    [SchedulePriorityEnum.High, key + 'high'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<SchedulePriorityEnum>[]> => of(values.map(state => ({ value: state, title: toString.getValue(state) })));

  export const comparePredicate = (node: SingleSelectItemData<SchedulePriorityEnum>, item: SchedulePriorityEnum) => node.value === item;

  export const selectedItemValues = values.map(priority => ({ value: priority, title: toString.getValue(priority) }));
}
