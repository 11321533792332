import { GeoJsonFileDTO } from '../../generated/models/geoJsonFileDTO';
import { DateUtils } from '../../utils/date.utils';

export interface GeoJsonSummaryData {
  uuidEntity: string;
  name: string;
  createDate: string;
  deactivated: boolean;
  color?: string;
  enabled?: boolean;
}

export namespace GeoJsonSummaryData {
  export function mapFromApiValue(geoJsonFileDTO: GeoJsonFileDTO): GeoJsonSummaryData {
    return {
      uuidEntity: geoJsonFileDTO.uuidEntity,
      name: geoJsonFileDTO.name,
      color: geoJsonFileDTO.color,
      enabled: geoJsonFileDTO.enabled,
      createDate: DateUtils.toDateFormat(geoJsonFileDTO.createDate),
      deactivated: geoJsonFileDTO.deactivated,
    };
  }
}
