import { compact } from 'lodash';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { ResponseErrorUploadAdrDTO, ResponseErrorUploadEmmDTO } from '../../generated/models/models';
import { ResponseErrorUploadUserDTO } from '../../generated/models/responseErrorUploadUserDTO';
import { ConflictUploadNatureEnum, UserRightEnum } from '../../enums';

export interface ConflictUploadElements {
  source?: string;
  details?: string;
  natureError?: ConflictUploadNatureEnum;
  name?: string;
  reference?: string;
  familyName?: string;
  risk?: string;
  riskSource?: string;
  trigram?: string;
  email?: string;
  labelFamilyTeam?: LabelFamilyLightData;
  authority?: UserRightEnum;
  function?: string;
  firstName?: string;
  lastName?: string;
  type?: string;
}

export namespace ConflictUploadElements {
  export function mapAdrFromApiValue(data: ResponseErrorUploadAdrDTO): ConflictUploadElements {
    return {
      natureError: ConflictUploadNatureEnum.convertEmmAdrFromApiValue.getValue(data.error),
      name: data.risk,
      reference: data.reference,
      familyName: data.familyName,
      source: data.source,
      details: compact([data.familyName, data.risk, data.source]).join(' - '),
    };
  }

  export function mapEmmFromApiValue(data: ResponseErrorUploadEmmDTO): ConflictUploadElements {
    return {
      natureError: ConflictUploadNatureEnum.convertEmmAdrFromApiValue.getValue(data.error),
      name: data.name,
      reference: data.reference,
      familyName: data.familyName,
      details: compact([data.familyName, data.name]).join('-'),
    };
  }

  export function mapUserFromApiValue(data: ResponseErrorUploadUserDTO): ConflictUploadElements {
    return {
      natureError: ConflictUploadNatureEnum.convertUserFromApiValue.getValue(data.error),
      trigram: data.trigram,
      email: data.email,
      function: data._function,
      firstName: data.firstName,
      lastName: data.lastName,
      type: data.type,
      details: compact([data.type, data.firstName, data.lastName, data.email]).join(' - '),
    };
  }
}
