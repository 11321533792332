import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum GeometryLabelEnum {
  FlowName = 1,
  ReferenceIntern = 2,
}

export namespace GeometryLabelEnum {
  export const values: GeometryLabelEnum[] = [GeometryLabelEnum.FlowName, GeometryLabelEnum.ReferenceIntern];

  export const convertFromApiValue: SfxMap<string, GeometryLabelEnum> = new SfxMap<string, GeometryLabelEnum>([
    ['flowName', GeometryLabelEnum.FlowName],
    ['refInt', GeometryLabelEnum.ReferenceIntern],
  ]);

  export const convertToApiValue: SfxMap<GeometryLabelEnum, string> = new SfxMap<GeometryLabelEnum, string>([
    [GeometryLabelEnum.FlowName, 'flowName'],
    [GeometryLabelEnum.ReferenceIntern, 'refInt'],
  ]);

  const key = 'customCells.geometryLabels.';
  export const toString: SfxMap<GeometryLabelEnum, string> = new SfxMap<GeometryLabelEnum, string>([
    [GeometryLabelEnum.FlowName, key + 'flowName'],
    [GeometryLabelEnum.ReferenceIntern, key + 'referenceIntern'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<GeometryLabelEnum>[]> =>
    of(
      values.map(geometryLabel => ({
        value: geometryLabel,
        title: toString.getValue(geometryLabel),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<GeometryLabelEnum>, item: GeometryLabelEnum) => item === node.value;
}
