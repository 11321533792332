import { SfxMap } from '../../utils/enum-utils';

export enum TypeUuidEntityEnum {
  WITHOUT = 1,
  EXPIRED = 2,
}
export namespace TypeUuidEntityEnum {
  export const convertToApiValue: SfxMap<TypeUuidEntityEnum, string> = new SfxMap<TypeUuidEntityEnum, string>([
    [TypeUuidEntityEnum.WITHOUT, 'WITHOUT'],
    [TypeUuidEntityEnum.EXPIRED, 'EXPIRED'],
  ]);

  export const allTypes = [convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT), convertToApiValue.getValue(TypeUuidEntityEnum.EXPIRED)];
}
