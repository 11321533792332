import { DocumentStatusEnum, InnerSortDirectionEnum } from '../../enums';
import { PageSectionRequestDTO } from '../../generated/models/models';
import { BaseQuery, defaultPageSize } from '../utils/base-query.model';
import { FolderSectionSummaryData } from './folder-section-data';

export interface SectionQueryRequest extends BaseQuery<FolderSectionSummaryData> {
  projectUuidEntity?: string;
  folderUuidEntity?: string;
  status?: DocumentStatusEnum[];
}

export namespace SectionQueryRequest {
  export function mapToApiValue(queries: SectionQueryRequest): PageSectionRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      rfiUuide: queries.folderUuidEntity,
    };
  }

  export function initialize(projectUuidEntity: string, folderUuidEntity: string, page: number, textSearch?: string): SectionQueryRequest {
    return {
      page: page,
      textSearch,
      size: defaultPageSize,
      sort: { key: 'order', direction: InnerSortDirectionEnum.Asc },
      projectUuidEntity,
      folderUuidEntity,
    };
  }
}
