import { ProjectUpdateScoopRequestDTO } from '../../generated/models/models';

export interface ProjectScoopForUpdate {
  publish: boolean;
  uuidEntity: string;
}

export namespace ProjectScoopForUpdate {
  export function mapToRequestApiValue(data: ProjectScoopForUpdate): ProjectUpdateScoopRequestDTO {
    return {
      published: data.publish,
      uuidEntity: data.uuidEntity,
    };
  }
}
