import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { ErrorResponseData, RiskSummaryData, RiskReorganizeData, ParadeDetailsData, RiskDetailsData } from '../../../../../core/models';

export const addRisk = createAction('[LRiskDialog] add new Risk', props<{ risk: RiskDetailsData; addNewOne?: boolean }>());
export const addRiskSuccess = createAction('[LRiskDialog] add new Risk success', props<{ risk: RiskDetailsData; addNewOne?: boolean }>());
export const addRiskFail = createAction('[LRiskDialog] add new Risk fail', props<DialogToasterData>());

export const loadParade = createAction('[LRiskDialog] load parade List', props<{ riskUuidEntity: string }>());
export const loadParadeSuccess = createAction('[LRiskDialog] load parade List success', props<{ parades: ParadeDetailsData[] }>());
export const loadParadeFail = createAction('[LRiskDialog] load parade List fail', props<DialogToasterData>());

export const addParade = createAction('[LRiskDialog] add new parade', props<{ parade: ParadeDetailsData; fileRef: File }>());
export const addParadeSuccess = createAction('[LRiskDialog] add new parade success', props<{ parade: ParadeDetailsData }>());
export const addParadeFail = createAction('[LRiskDialog] add new parade fail', props<DialogToasterData>());

export const duplicateParade = createAction('[LRiskDialog] duplicate new parade', props<{ parade: ParadeDetailsData }>());
export const duplicateParadeFail = createAction('[LRiskDialog] duplicate new parade fail', props<DialogToasterData>());

export const deleteParade = createAction('[LRiskDialog] delete new parade', props<{ parade: ParadeDetailsData }>());
export const deleteParadeFail = createAction('[LRiskDialog] delete new parade fail', props<DialogToasterData>());

export const updateParade = createAction('[LRiskDialog] update parade', props<{ parade: ParadeDetailsData; fileRef: File }>());
export const updateParadeSuccess = createAction('[LRiskDialog] update parade success', props<{ parade: ParadeDetailsData }>());
export const updateParadeFail = createAction('[LRiskDialog] update parade fail', props<DialogToasterData>());

export const updateRisk = createAction('[LRiskDialog] update Risk', props<{ risk: RiskDetailsData }>());
export const updateRiskSuccess = createAction('[LRiskDialog] update Risk success', props<{ risk: RiskDetailsData; closeModal?: boolean }>());
export const updateRiskFail = createAction('[LRiskDialog] update Risk fail', props<DialogToasterData>());

export const getRiskDetailsSuccess = createAction('[LRiskDialog] get Risk details success', props<{ risk: RiskDetailsData }>());
export const getRiskDetailsFail = createAction('[LRiskDialog] get risk details fail', props<DialogToasterData>());

export const setFileToUploadRiskFail = createAction('[LRiskDialog] set file to upload Risk fail', props<DialogToasterData>());

export const downloadInitialRiskTramFile = createAction('[LRiskDialog] download initial Risk tram');
export const downloadInitialRiskTramFileFail = createAction('[LRiskDialog] download initial Risk tram fail', props<DialogToasterData>());

export const importRisk = createAction('[LRiskDialog] import risk', props<{ file: File; forceApplicabilityOrga: boolean }>());
export const stopImportFileLoading = createAction('[LRiskDialog] stop Import File Loading');

export const openParadePopOver = createAction('[LRiskDialog] open parade popover', props<{ origin: HTMLElement; parade: ParadeDetailsData }>());

export const displayParadePdf = createAction('[LRiskDialog] display parade pdf', props<{ fileId: string }>());

export const navigateThroughRisks = createAction('[LRiskDialog] navigate through risks', props<{ risk: RiskDetailsData; step: number }>());
export const confirmNavigateThroughRisks = createAction('[LRiskDialog] confirm navigate through risks', props<{ risk: RiskDetailsData; step: number }>());

export const reorganizeRisks = createAction('[LRiskDialog] reorganize risks', props<{ riskReorganize: RiskReorganizeData; count: number }>());
export const reorganizeRisksFail = createAction('[LRiskDialog] reorganize risks fail', props<DialogToasterData>());
export const reorganizeRisksSuccess = createAction('[LRiskDialog] reorganize risks success');

export const loadRiskReorganizeList = createAction('[LRiskDialog] Load risk reorganize list');
export const loadNextRiskReorganizePage = createAction('[LRiskDialog] Load next risk reorganize page ');
export const loadRiskReorganizeListSuccess = createAction(
  '[LRiskDialog] Load risk reorganize list success',
  props<{ risks: RiskSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadRiskReorganizeListFail = createAction('[LRiskDialog] Load risk reorganize list fail', props<{ error: ErrorResponseData }>());
