import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import { ErrorResponseData, RiskSummaryData, ParadeDetailsData, RiskDetailsData } from '../../../../../core/models';
import * as fromActions from './risk-dialog.actions';

const reorganizeAdapter: EntityAdapter<RiskSummaryData> = createEntityAdapter<RiskSummaryData>({
  selectId: risk => risk.uuidEntity,
});

export interface RiskReorganizeState extends EntityState<RiskSummaryData> {
  ids: string[];
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export const initialRiskReorganizeState = (): RiskReorganizeState =>
  reorganizeAdapter.getInitialState<RiskReorganizeState>({
    ids: [],
    entities: {},
    loading: false,
    error: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export interface UploadRisksDialogState {
  uploadLoading: boolean;
}
export interface LibraryRiskDialogState {
  uploadRisks: UploadRisksDialogState;
  saveLoading: boolean;
  riskDetailsData: RiskDetailsData;
  parades: ParadeDetailsData[];
  riskReorganizeState: RiskReorganizeState;
}

const initialUploadRisksDialogState = (): UploadRisksDialogState => ({
  uploadLoading: false,
});

export const initialLibraryRiskDialogState = () => ({
  uploadRisks: initialUploadRisksDialogState(),
  saveLoading: false,
  riskDetailsData: {} as RiskDetailsData,
  parades: [],
  riskReorganizeState: initialRiskReorganizeState(),
});

const reducer = createReducer<LibraryRiskDialogState>(
  initialLibraryRiskDialogState(),
  on(fromActions.importRisk, (state): LibraryRiskDialogState => ({ ...state, uploadRisks: { ...state.uploadRisks, uploadLoading: true } })),
  on(fromActions.stopImportFileLoading, (state): LibraryRiskDialogState => ({ ...state, uploadRisks: { ...state.uploadRisks, uploadLoading: false } })),
  on(fromActions.setFileToUploadRiskFail, (state): LibraryRiskDialogState => ({ ...state, uploadRisks: { ...state.uploadRisks, uploadLoading: false } })),
  on(fromActions.updateRisk, (state): LibraryRiskDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateRiskFail, (state): LibraryRiskDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.addRisk, (state): LibraryRiskDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.addRiskFail, (state): LibraryRiskDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.loadParadeSuccess, (state, { parades }): LibraryRiskDialogState => ({ ...state, parades })),
  on(
    fromActions.addRiskSuccess,
    (state, { risk, addNewOne }): LibraryRiskDialogState => ({
      ...state,
      saveLoading: false,
      riskDetailsData: addNewOne ? ({ labelFamily: risk.labelFamily } as RiskDetailsData) : risk,
      parades: [],
    }),
  ),
  on(fromActions.getRiskDetailsSuccess, (state, { risk }): LibraryRiskDialogState => ({ ...state, riskDetailsData: risk })),
  on(fromActions.loadRiskReorganizeList, (state): LibraryRiskDialogState => ({ ...state, riskReorganizeState: { ...state.riskReorganizeState, loading: true } })),
  on(
    fromActions.loadRiskReorganizeListSuccess,
    (state, { risks, totalCount, filteredTotalCount, reset }): LibraryRiskDialogState => {
      const newState: RiskReorganizeState = { ...state.riskReorganizeState, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, riskReorganizeState: reset ? reorganizeAdapter.setAll(risks, newState) : reorganizeAdapter.addMany(risks, newState) };
    },
  ),
  on(
    fromActions.loadRiskReorganizeListFail,
    (state, { error }): LibraryRiskDialogState => ({ ...state, riskReorganizeState: { ...state.riskReorganizeState, error, loading: false } }),
  ),
);

export function LibraryRiskDialogReducer(state: LibraryRiskDialogState | undefined, action: Action): LibraryRiskDialogState {
  return reducer(state, action);
}
