import { SfxMap } from '../../utils/enum-utils';

export enum CustomFileColumnEnum {
  Nature = 'nature',
  Extension = 'extension',
  Name = 'name',
  Action = 'action',
  Type = 'type,',
}

export namespace CustomFileColumnEnum {
  export const allColumns = [CustomFileColumnEnum.Nature, CustomFileColumnEnum.Type, CustomFileColumnEnum.Extension, CustomFileColumnEnum.Name, CustomFileColumnEnum.Action];

  const key = 'customFiles.table.header.';
  export const toString: SfxMap<CustomFileColumnEnum, string> = new SfxMap<CustomFileColumnEnum, string>([
    [CustomFileColumnEnum.Nature, key + 'nature'],
    [CustomFileColumnEnum.Extension, key + 'extension'],
    [CustomFileColumnEnum.Name, key + 'name'],
    [CustomFileColumnEnum.Action, key + 'action'],
    [CustomFileColumnEnum.Type, key + 'type'],
  ]);

  export const sortField: SfxMap<CustomFileColumnEnum, string> = new SfxMap<CustomFileColumnEnum, string>([
    [CustomFileColumnEnum.Extension, 'extension'],
    [CustomFileColumnEnum.Name, 'name'],
  ]);
}
