import { UpdateProjectDeviceRequest } from '../../generated/models/models';
import { TabletImportData } from './tablet-import-data';

export interface TabletForImport {
  queries?: TabletImportData.QueryRequest;
  uuides?: string[];
  allSelected?: boolean;
}

export namespace TabletForImport {
  export function mapToRequestApiValue(request: TabletForImport): UpdateProjectDeviceRequest {
    return {
      devicesUuidEntity: !request.allSelected ? request.uuides || [] : undefined,
      pageUserDeviceRequestDTO: request.allSelected ? request.queries && TabletImportData.mapToPageRequestApiValue(request.queries) : undefined,
    };
  }
}
