/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WordingConfigurationStateDocumentFlowDTO {
  actionKey?: WordingConfigurationStateDocumentFlowDTO.ActionKeyEnum;
  customWordingUuidEntity?: string;
  tooltip?: string;
  uuidEntity?: string;
  value?: string;
}
export namespace WordingConfigurationStateDocumentFlowDTO {
  export type ActionKeyEnum =
    | 'PREL'
    | 'CLIENT_RETURN_BPO'
    | 'DIFFUSION_BPO'
    | 'BPE'
    | 'BPE_SUITE_VSOSR'
    | 'BPE_VALID'
    | 'CLIENT_RETURN_BPA'
    | 'DIFFUSION_BPA'
    | 'BPA_VALID'
    | 'WITHOUT';
  export const ActionKeyEnum = {
    PREL: 'PREL' as ActionKeyEnum,
    CLIENTRETURNBPO: 'CLIENT_RETURN_BPO' as ActionKeyEnum,
    DIFFUSIONBPO: 'DIFFUSION_BPO' as ActionKeyEnum,
    BPE: 'BPE' as ActionKeyEnum,
    BPESUITEVSOSR: 'BPE_SUITE_VSOSR' as ActionKeyEnum,
    BPEVALID: 'BPE_VALID' as ActionKeyEnum,
    CLIENTRETURNBPA: 'CLIENT_RETURN_BPA' as ActionKeyEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as ActionKeyEnum,
    BPAVALID: 'BPA_VALID' as ActionKeyEnum,
    WITHOUT: 'WITHOUT' as ActionKeyEnum,
  };
}
