import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum AlertModalTypeEnum {
  Delete = 1,
  Info,
  Duplicate,
  Refresh,
  Warning,
  Danger,
  Activate,
  Deactivate,
  Reset,
  PastePGAC,
  Error,
  Send,
  Replace,
  Edit,
  ResetBlocks,
  Unblock,
  ImportFlow,
  NavigationConflict,
  WarningNotification,
  ResetFlow,
  Unlink,
}

export namespace AlertModalTypeEnum {
  const key = 'global.alert.types.';

  export const notificationAlerts = [AlertModalTypeEnum.WarningNotification, AlertModalTypeEnum.NavigationConflict];

  export const toString: SfxMap<AlertModalTypeEnum, string> = new SfxMap<AlertModalTypeEnum, string>([
    [AlertModalTypeEnum.Info, key + 'confirm'],
    [AlertModalTypeEnum.PastePGAC, key + 'confirm'],
    [AlertModalTypeEnum.Delete, key + 'delete'],
    [AlertModalTypeEnum.Duplicate, key + 'duplicate'],
    [AlertModalTypeEnum.Refresh, key + 'refresh'],
    [AlertModalTypeEnum.ResetFlow, key + 'confirm'],
    [AlertModalTypeEnum.Activate, key + 'activate'],
    [AlertModalTypeEnum.Send, key + 'send'],
    [AlertModalTypeEnum.Deactivate, key + 'deactivate'],
    [AlertModalTypeEnum.Warning, key + 'warning'],
    [AlertModalTypeEnum.Danger, key + 'warning'],
    [AlertModalTypeEnum.Error, key + 'error'],
    [AlertModalTypeEnum.Reset, key + 'reset'],
    [AlertModalTypeEnum.Replace, key + 'replace'],
    [AlertModalTypeEnum.Edit, key + 'edit'],
    [AlertModalTypeEnum.ResetBlocks, key + 'resetBlocks'],
    [AlertModalTypeEnum.Unblock, key + 'unblock'],
    [AlertModalTypeEnum.ImportFlow, key + 'importFlow'],
    [AlertModalTypeEnum.NavigationConflict, key + 'warning'],
    [AlertModalTypeEnum.Unlink, key + 'confirm'],
  ]);

  export const iconData: SfxMap<AlertModalTypeEnum, IconData> = new SfxMap<AlertModalTypeEnum, IconData>([
    [AlertModalTypeEnum.Activate, { name: 'exclamation-circle', prefix: 'fas' }],
    [AlertModalTypeEnum.Send, { name: 'exclamation-circle', prefix: 'fas' }],
    [AlertModalTypeEnum.Deactivate, { name: 'exclamation-circle', prefix: 'fas' }],
    [AlertModalTypeEnum.Info, { name: 'exclamation-circle', prefix: 'fas' }],
    [AlertModalTypeEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [AlertModalTypeEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [AlertModalTypeEnum.Refresh, { name: 'sync-alt', prefix: 'fas' }],
    [AlertModalTypeEnum.ResetFlow, { name: 'sync-alt', prefix: 'fas' }],
    [AlertModalTypeEnum.Warning, { name: 'exclamation-triangle', prefix: 'fas' }],
    [AlertModalTypeEnum.Error, { name: 'exclamation-triangle', prefix: 'fas' }],
    [AlertModalTypeEnum.Danger, { name: 'exclamation-circle', prefix: 'fas' }],
    [AlertModalTypeEnum.Reset, { name: 'undo-alt', prefix: 'fas' }],
    [AlertModalTypeEnum.PastePGAC, { name: 'file-csv', prefix: 'fas' }],
    [AlertModalTypeEnum.Replace, { name: 'exchange-alt', prefix: 'fas' }],
    [AlertModalTypeEnum.Edit, { name: 'edit', prefix: 'fas' }],
    [AlertModalTypeEnum.ResetBlocks, { name: 'undo-alt', prefix: 'fas' }],
    [AlertModalTypeEnum.Unblock, { name: 'unlock', prefix: 'fas' }],
    [AlertModalTypeEnum.ImportFlow, { name: 'cart-arrow-down', prefix: 'fas' }],
    [AlertModalTypeEnum.NavigationConflict, { name: 'directions', prefix: 'fas' }],
    [AlertModalTypeEnum.WarningNotification, { name: 'exclamation-triangle', prefix: 'fas' }],
    [AlertModalTypeEnum.Unlink, { name: 'link', prefix: 'fas' }],
  ]);

  export const modalIconClassName: SfxMap<AlertModalTypeEnum, string> = new SfxMap<AlertModalTypeEnum, string>([
    [AlertModalTypeEnum.Activate, 'info'],
    [AlertModalTypeEnum.Send, 'info'],
    [AlertModalTypeEnum.Deactivate, 'info'],
    [AlertModalTypeEnum.Info, 'info'],
    [AlertModalTypeEnum.Delete, 'delete'],
    [AlertModalTypeEnum.Duplicate, 'duplicate'],
    [AlertModalTypeEnum.Unblock, 'warning'],
    [AlertModalTypeEnum.Refresh, 'refresh'],
    [AlertModalTypeEnum.ResetFlow, 'refresh'],
    [AlertModalTypeEnum.Warning, 'warning'],
    [AlertModalTypeEnum.Error, 'warning'],
    [AlertModalTypeEnum.Reset, 'danger'],
    [AlertModalTypeEnum.Danger, 'danger'],
    [AlertModalTypeEnum.PastePGAC, 'pastePGAC'],
    [AlertModalTypeEnum.Replace, 'replace'],
    [AlertModalTypeEnum.Edit, 'refresh'],
    [AlertModalTypeEnum.ResetBlocks, 'reset-blocks'],
    [AlertModalTypeEnum.ImportFlow, 'refresh'],
    [AlertModalTypeEnum.NavigationConflict, 'warning'],
    [AlertModalTypeEnum.WarningNotification, 'warning'],
    [AlertModalTypeEnum.Unlink, 'info'],
  ]);

  export const buttonActionClassName: SfxMap<AlertModalTypeEnum, string> = new SfxMap<AlertModalTypeEnum, string>(
    [
      [AlertModalTypeEnum.Reset, 'sfx-danger-inverted'],
      [AlertModalTypeEnum.Delete, 'sfx-danger-inverted'],
      [AlertModalTypeEnum.Danger, 'sfx-danger-inverted'],
      [AlertModalTypeEnum.Warning, 'sfx-warning-inverted'],
      [AlertModalTypeEnum.Error, 'sfx-warning-inverted'],
      [AlertModalTypeEnum.Duplicate, 'sfx-success-inverted'],
      [AlertModalTypeEnum.PastePGAC, 'sfx-pgac-inverted'],
      [AlertModalTypeEnum.Replace, 'sfx-inverted'],
      [AlertModalTypeEnum.ResetBlocks, 'sfx-blue-inverted'],
      [AlertModalTypeEnum.Unblock, 'sfx-warning-inverted'],
      [AlertModalTypeEnum.ImportFlow, 'sfx-inverted'],
      [AlertModalTypeEnum.NavigationConflict, 'sfx-warning-inverted'],
    ],
    'sfx-inverted',
  );
}
