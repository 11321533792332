import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { DeleteRfiResponseDTO } from '../../generated/models/models';
import { SectionDetailsData } from '../section-settings/section-detail-data';

export interface FolderDeleteResponseData {
  success: boolean;
  links: SectionDetailsData[];
}

export namespace FolderDeleteResponseData {
  export function mapFromApiValue(deleteResponse: DeleteRfiResponseDTO): FolderDeleteResponseData {
    return {
      success: deleteResponse.success,
      links: deleteResponse.sectionsRfiTemplate && deleteResponse.sectionsRfiTemplate.map(section => SectionDetailsData.mapFromSummaryResponseDTO(section)),
    };
  }

  export function mapToConflictData(deleteResponse: FolderDeleteResponseData): ConflictData {
    return {
      title: 'global.entityConflict.hint.folder.folderLink',
      links: deleteResponse.links && deleteResponse.links.map(section => SectionDetailsData.toString(section)),
    };
  }
}
