import { DateUtils } from '../../utils/date.utils';

export interface ChartNodeData {
  value: number;
  startDate: string;
  endDate: string;
}

export namespace ChartNodeData {
  export function mapToNodeData(value: number, startAt: Date, endAt: Date): ChartNodeData {
    return {
      value,
      startDate: DateUtils.toCustomDateFormat(startAt, 'DD MMMM YYYY'),
      endDate: DateUtils.toCustomDateFormat(endAt, 'DD MMMM YYYY'),
    };
  }
}
