/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersionDocumentFlowClientResponseVisaRequestDTO {
  versionDocumentFlowUuidEntity: string;
  visa: VersionDocumentFlowClientResponseVisaRequestDTO.VisaEnum;
  refReponse?: string;
  libelleReponse?: string;
  reponseDate: Date;
  comment?: string;
  resetCoverFile?: boolean; // Manually Added attributes
  resetDocumentFile?: boolean; // Manually Added attributes
}
export namespace VersionDocumentFlowClientResponseVisaRequestDTO {
  export type VisaEnum = 'DIFFUSION' | 'BPE_VALIDE' | 'VAO' | 'VSO' | 'VSOSR' | 'MODIFICATION_BPA' | 'DIFFUSION_BPA' | 'VSO_BPA' | 'VAO_BPA' | 'MODIFICATION';
  export const VisaEnum = {
    DIFFUSION: 'DIFFUSION' as VisaEnum,
    BPEVALIDE: 'BPE_VALIDE' as VisaEnum,
    VAO: 'VAO' as VisaEnum,
    VSO: 'VSO' as VisaEnum,
    VSOSR: 'VSOSR' as VisaEnum,
    MODIFICATIONBPA: 'MODIFICATION_BPA' as VisaEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as VisaEnum,
    VSOBPA: 'VSO_BPA' as VisaEnum,
    VAOBPA: 'VAO_BPA' as VisaEnum,
    MODIFICATION: 'MODIFICATION' as VisaEnum,
  };
}
