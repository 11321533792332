import { MobileAppDisplayConfigResponseDto, PdfDisplayConfigRequestDto, PdfDisplayConfigResponseDto, WebAppDisplayConfigResponseDto } from '../../generated/models/models';

export interface DateFormatData {
  dateFormat: string;
  dateTimeFormat: string;
  timeFormat: string;
  eventDateTimeFormat: string;
  sensitiveDateFormat?: string;
  sensitiveDateTimeFormat?: string;
  sensitiveTimeFormat?: string;
  zoneId?: string;
}

export namespace DateFormatData {
  export function mapFromApiValue(data: PdfDisplayConfigResponseDto | MobileAppDisplayConfigResponseDto | WebAppDisplayConfigResponseDto): DateFormatData {
    return {
      dateFormat: data.dateFormat,
      dateTimeFormat: data.dateTimeFormat,
      timeFormat: data.timeFormat,
      sensitiveDateFormat: (data as PdfDisplayConfigResponseDto).sensitiveDateFormat,
      sensitiveDateTimeFormat: (data as WebAppDisplayConfigResponseDto | PdfDisplayConfigResponseDto).sensitiveDateTimeFormat,
      sensitiveTimeFormat: (data as PdfDisplayConfigResponseDto).sensitiveTimeFormat,
      eventDateTimeFormat: (data as WebAppDisplayConfigResponseDto).eventDateTimeFormat,
      zoneId: (data as PdfDisplayConfigResponseDto).zoneId,
    };
  }

  export function mapToApiValue(data: DateFormatData): PdfDisplayConfigRequestDto | MobileAppDisplayConfigResponseDto | WebAppDisplayConfigResponseDto {
    return {
      dateFormat: data.dateFormat,
      dateTimeFormat: data.dateTimeFormat,
      timeFormat: data.timeFormat,
      sensitiveDateFormat: data.sensitiveDateFormat,
      sensitiveDateTimeFormat: data.sensitiveDateTimeFormat,
      sensitiveTimeFormat: data.sensitiveTimeFormat,
      eventDateTimeFormat: data.eventDateTimeFormat,
      zoneId: data.zoneId,
    };
  }
}
