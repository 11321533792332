import { createAction, props } from '@ngrx/store';
import { BlockFormResponseSummaryData, LazyLoadResponse } from '../../../../../../core/models';

// Flow content actions
export const loadOrphanResponses = createAction('[OrphanResponses] load responses');
export const loadNextOrphanResponses = createAction('[OrphanResponses] load next responses');
export const loadOrphanResponsesSuccess = createAction(
  '[OrphanResponses] load responses success',
  props<{ responses: LazyLoadResponse<BlockFormResponseSummaryData[]>; reset: boolean }>(),
);
export const loadOrphanResponsesFail = createAction('[OrphanResponses] load responses fail');

export const repairResponse = createAction('[OrphanResponses] Repair response', props<{ responseUuidEntity: string; fblUuidEntity: string }>());

export const overviewResponse = createAction('[OrphanResponses] overview response', props<{ response: BlockFormResponseSummaryData }>());
