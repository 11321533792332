import { ProjectTypeEnum, LanguageEnum } from '../../enums';
import { ProjectCreateRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { ProjectDetailsData } from './project-details';

export interface ProjectForInsert extends Partial<ProjectDetailsData> {
  projectType: ProjectTypeEnum;
  designation: string;
  client: EntityLightData;
  folderUuidEntity?: string;
  customStatusUuidEntity?: string;
}

export namespace ProjectForInsert {
  export function mapToCreateRequestApiValue(projectForInsert: ProjectForInsert): ProjectCreateRequestDTO {
    return {
      designation: projectForInsert.designation,
      typeProject: ProjectTypeEnum.convertToCreateApiValue.getValue(projectForInsert.projectType),
      clientRef: projectForInsert.client.uuidEntity,
      labelFamilyApplicationUuidEntity: (projectForInsert.labelFamilyApplication && projectForInsert.labelFamilyApplication.uuidEntity) || undefined,
      isContractual: projectForInsert.isContractual || undefined,
      isSupervised: projectForInsert.isSupervised || undefined,
      locationLibelle: projectForInsert.location || undefined,
      businessRef: projectForInsert.businessRef || undefined,
      description: projectForInsert.description || undefined,
      contractRef: projectForInsert.contractRef || undefined,
      internalCommand: projectForInsert.internalCommand || undefined,
      externalCommand: projectForInsert.externalCommand || undefined,
      technicalDeal: projectForInsert.technicalDeal || undefined,
      marketNumber: projectForInsert.marketNumber || undefined,
      keywords: projectForInsert.keywords || undefined,
      businessDomain: projectForInsert.businessDomain || undefined,
      nationalProgram: projectForInsert.nationalProgram || undefined,
      exportLanguageOne: LanguageEnum.convertToApiValue.getValue(projectForInsert.firstLanguage) || undefined,
      exportLanguageTwo: LanguageEnum.convertToApiValue.getValue(projectForInsert.secondLanguage) || undefined,
      supervision1: projectForInsert.firstSupervision || undefined,
      supervision2: projectForInsert.secondSupervision || undefined,
      supervision3: projectForInsert.thirdSupervision || undefined,
      codeProject: projectForInsert.codeProject || undefined,
      pgacActivities: (projectForInsert.pgacActivities && projectForInsert.pgacActivities.join(';')) || undefined,
      startDate: projectForInsert.startDate || undefined,
      endDate: projectForInsert.endDate || undefined,
      complementRefOne: projectForInsert.complementRefOne || undefined,
      complementRefTwo: projectForInsert.complementRefTwo || undefined,
      folderUuidEntity: projectForInsert.folderUuidEntity,
      customStatusUuidEntity: projectForInsert.customStatus?.uuidEntity,
    };
  }
}
