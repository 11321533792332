import { Component, OnInit, TemplateRef } from '@angular/core';

import { PopoverPositionEnum } from '../../../../core/enums';
import { popoverContentType, configData } from './popover-config';
import { popoverAnimations, PopoverAnimationStateEnum } from './popover-animation';
import { PopoverOverlayRef } from './popover-overlay-ref';

@Component({
  selector: 'sfx-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  animations: [popoverAnimations.popoverAnimation],
})
export class PopoverComponent implements OnInit {
  public animationState: PopoverAnimationStateEnum = PopoverAnimationStateEnum.Default;
  public configData = configData;

  public content: popoverContentType;

  public contentType: 'template' | 'component' | 'text' = 'component';

  public context: { close: () => void };

  public PopoverPositionEnum = PopoverPositionEnum;

  constructor(public popoverRef: PopoverOverlayRef) {}

  ngOnInit(): void {
    this.content = this.popoverRef.popoverInitData.content;

    if (typeof this.content === 'string') {
      this.contentType = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
