import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { NavigationFlowCreateRequestDTO } from '../../generated/models/navigationFlowCreateRequestDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum StepNavigationTypeEnum {
  Trace = 1,
  Info = 2,
  Waning = 3,
  Error = 4,
  Success = 5,
}

export namespace StepNavigationTypeEnum {
  const values: StepNavigationTypeEnum[] = [
    StepNavigationTypeEnum.Trace,
    StepNavigationTypeEnum.Info,
    StepNavigationTypeEnum.Waning,
    StepNavigationTypeEnum.Error,
    StepNavigationTypeEnum.Success,
  ];

  export const convertFromApiValue: SfxMap<NavigationFlowCreateRequestDTO.NatureStyleEnum, StepNavigationTypeEnum> = new SfxMap([
    [NavigationFlowCreateRequestDTO.NatureStyleEnum.TRACE, StepNavigationTypeEnum.Trace],
    [NavigationFlowCreateRequestDTO.NatureStyleEnum.INFO, StepNavigationTypeEnum.Info],
    [NavigationFlowCreateRequestDTO.NatureStyleEnum.WARN, StepNavigationTypeEnum.Waning],
    [NavigationFlowCreateRequestDTO.NatureStyleEnum.ERROR, StepNavigationTypeEnum.Error],
    [NavigationFlowCreateRequestDTO.NatureStyleEnum.SUCCESS, StepNavigationTypeEnum.Success],
  ]);

  export const convertToApiValue: SfxMap<StepNavigationTypeEnum, NavigationFlowCreateRequestDTO.NatureStyleEnum> = new SfxMap([
    [StepNavigationTypeEnum.Trace, NavigationFlowCreateRequestDTO.NatureStyleEnum.TRACE],
    [StepNavigationTypeEnum.Info, NavigationFlowCreateRequestDTO.NatureStyleEnum.INFO],
    [StepNavigationTypeEnum.Waning, NavigationFlowCreateRequestDTO.NatureStyleEnum.WARN],
    [StepNavigationTypeEnum.Error, NavigationFlowCreateRequestDTO.NatureStyleEnum.ERROR],
    [StepNavigationTypeEnum.Success, NavigationFlowCreateRequestDTO.NatureStyleEnum.SUCCESS],
  ]);
  const key = 'flowContentNavigation.redirection.step.type.';
  export const toString: SfxMap<StepNavigationTypeEnum, string> = new SfxMap([
    [StepNavigationTypeEnum.Trace, key + 'default'],
    [StepNavigationTypeEnum.Info, key + 'information'],
    [StepNavigationTypeEnum.Waning, key + 'warning'],
    [StepNavigationTypeEnum.Error, key + 'error'],
    [StepNavigationTypeEnum.Success, key + 'success'],
  ]);

  export const iconData: SfxMap<StepNavigationTypeEnum, IconData> = new SfxMap([
    [StepNavigationTypeEnum.Trace, { name: 'font' }],
    [StepNavigationTypeEnum.Info, { name: 'info-circle' }],
    [StepNavigationTypeEnum.Waning, { name: 'exclamation-circle' }],
    [StepNavigationTypeEnum.Error, { name: 'times-circle' }],
    [StepNavigationTypeEnum.Success, { name: 'check-circle' }],
  ]);

  export const itemResolver = (): Observable<SingleSelectItemData<StepNavigationTypeEnum>[]> =>
    of(
      values.map(type => ({
        title: toString.getValue(type),
        value: type,
        icons: [iconData.getValue(type)],
        selected: type === StepNavigationTypeEnum.Trace,
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<StepNavigationTypeEnum>, item: StepNavigationTypeEnum) => node.value === item;
}
