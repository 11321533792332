import { FamilyDeleteResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum FamilyDeleteNatureEnum {
  Document = 1,
  Flow,
  Risk,
  Equipment,
  Project,
  Form,
  Skill,
  Team,
  Function,
  Device,
  Child,
  Status,
  Attribute,
}

export namespace FamilyDeleteNatureEnum {
  export const convertFromApiValue: SfxMap<FamilyDeleteResponseDTO.NatureEnum, FamilyDeleteNatureEnum> = new SfxMap<FamilyDeleteResponseDTO.NatureEnum, FamilyDeleteNatureEnum>([
    [FamilyDeleteResponseDTO.NatureEnum.DOCUMENT, FamilyDeleteNatureEnum.Document],
    [FamilyDeleteResponseDTO.NatureEnum.FLOW, FamilyDeleteNatureEnum.Flow],
    [FamilyDeleteResponseDTO.NatureEnum.ADR, FamilyDeleteNatureEnum.Risk],
    [FamilyDeleteResponseDTO.NatureEnum.EMM, FamilyDeleteNatureEnum.Equipment],
    [FamilyDeleteResponseDTO.NatureEnum.PROJECT, FamilyDeleteNatureEnum.Project],
    [FamilyDeleteResponseDTO.NatureEnum.FORM, FamilyDeleteNatureEnum.Form],
    [FamilyDeleteResponseDTO.NatureEnum.SKILL, FamilyDeleteNatureEnum.Skill],
    [FamilyDeleteResponseDTO.NatureEnum.TEAM, FamilyDeleteNatureEnum.Team],
    [FamilyDeleteResponseDTO.NatureEnum.FUNCTION, FamilyDeleteNatureEnum.Function],
    [FamilyDeleteResponseDTO.NatureEnum.DEVICE, FamilyDeleteNatureEnum.Device],
    [FamilyDeleteResponseDTO.NatureEnum.CHILD, FamilyDeleteNatureEnum.Child],
    [FamilyDeleteResponseDTO.NatureEnum.STATUS, FamilyDeleteNatureEnum.Status],
    [FamilyDeleteResponseDTO.NatureEnum.ATTRIBUTE, FamilyDeleteNatureEnum.Attribute],
  ]);
}
