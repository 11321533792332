import { Observable, of } from 'rxjs';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { ExternalApiConsumerSummaryResponseDTO } from '../../generated/models/externalApiConsumerSummaryResponseDTO';

export enum StatusAccessApiEnum {
  Enabled = 1,
  Disabled,
}
export namespace StatusAccessApiEnum {
  const values: StatusAccessApiEnum[] = [StatusAccessApiEnum.Enabled, StatusAccessApiEnum.Disabled];

  export const mapFromApiValue = new SfxMap<ExternalApiConsumerSummaryResponseDTO.StatusEnum, StatusAccessApiEnum>([
    [ExternalApiConsumerSummaryResponseDTO.StatusEnum.ENABLED, StatusAccessApiEnum.Enabled],
    [ExternalApiConsumerSummaryResponseDTO.StatusEnum.DISABLED, StatusAccessApiEnum.Disabled],
  ]);

  export const mapToApiValue = new SfxMap<StatusAccessApiEnum, ExternalApiConsumerSummaryResponseDTO.StatusEnum>([
    [StatusAccessApiEnum.Enabled, ExternalApiConsumerSummaryResponseDTO.StatusEnum.ENABLED],
    [StatusAccessApiEnum.Disabled, ExternalApiConsumerSummaryResponseDTO.StatusEnum.DISABLED],
  ]);

  export const convertFromParamsValue = new SfxMap<string, StatusAccessApiEnum>([
    ['active', StatusAccessApiEnum.Enabled],
    ['inactive', StatusAccessApiEnum.Disabled],
  ]);

  export const convertToParamsValue = new SfxMap<StatusAccessApiEnum, string>([
    [StatusAccessApiEnum.Enabled, 'active'],
    [StatusAccessApiEnum.Disabled, 'inactive'],
  ]);

  const key = 'accessApi.v2.filters.';
  export const toString = new SfxMap<StatusAccessApiEnum, string>([
    [StatusAccessApiEnum.Enabled, key + 'enabled'],
    [StatusAccessApiEnum.Disabled, key + 'disabled'],
  ]);

  export const color = new SfxMap<StatusAccessApiEnum, string>([
    [StatusAccessApiEnum.Enabled, '#57b09d'],
    [StatusAccessApiEnum.Disabled, '#af1018'],
  ]);

  export const className = new SfxMap<StatusAccessApiEnum, string>([
    [StatusAccessApiEnum.Enabled, 'sfx-active-status'],
    [StatusAccessApiEnum.Disabled, 'sfx-expired-status'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<StatusAccessApiEnum>[]> => of(values.map(status => ({ value: status, title: toString.getValue(status) })));

  export const comparePredicate = (node: SingleSelectItemData<StatusAccessApiEnum>, item: StatusAccessApiEnum) => node.value === item;
}
