import { DocumentFlowSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum DashboardDocumentsColumnEnum {
  Family = 'family',
  Name = 'name',
  RefInt = 'refInt',
  DocumentIndex = 'documentIndex',
  State = 'state',
  DiffusionDate = 'diffusionDate',
  ImportationDate = 'importationDate',
  Actions = 'actions',
  ImportActions = 'importActions',
  MultiPdfActions = 'multiPdfActions',
}

export namespace DashboardDocumentsColumnEnum {
  export type documentListSortField = keyof DocumentFlowSummaryResponseDTO;

  export const allColumns = [
    DashboardDocumentsColumnEnum.Family,
    DashboardDocumentsColumnEnum.Name,
    DashboardDocumentsColumnEnum.RefInt,
    DashboardDocumentsColumnEnum.DocumentIndex,
    DashboardDocumentsColumnEnum.State,
    DashboardDocumentsColumnEnum.DiffusionDate,
    DashboardDocumentsColumnEnum.Actions,
  ];

  export const importColumns = [
    DashboardDocumentsColumnEnum.Family,
    DashboardDocumentsColumnEnum.Name,
    DashboardDocumentsColumnEnum.RefInt,
    DashboardDocumentsColumnEnum.DocumentIndex,
    DashboardDocumentsColumnEnum.State,
    DashboardDocumentsColumnEnum.ImportActions,
  ];

  export const multiPdfColumns = [DashboardDocumentsColumnEnum.Name, DashboardDocumentsColumnEnum.ImportationDate, DashboardDocumentsColumnEnum.MultiPdfActions];

  export const reorganizeColumns = [
    DashboardDocumentsColumnEnum.Family,
    DashboardDocumentsColumnEnum.Name,
    DashboardDocumentsColumnEnum.RefInt,
    DashboardDocumentsColumnEnum.DocumentIndex,
    DashboardDocumentsColumnEnum.State,
  ];

  const key = 'dashboardDocuments.table.header.';
  export const toString: SfxMap<DashboardDocumentsColumnEnum, string> = new SfxMap<DashboardDocumentsColumnEnum, string>([
    [DashboardDocumentsColumnEnum.Family, key + 'family'],
    [DashboardDocumentsColumnEnum.Name, key + 'name'],
    [DashboardDocumentsColumnEnum.RefInt, key + 'refInt'],
    [DashboardDocumentsColumnEnum.DocumentIndex, key + 'documentIndex'],
    [DashboardDocumentsColumnEnum.State, key + 'state'],
    [DashboardDocumentsColumnEnum.DiffusionDate, key + 'diffusionDate'],
    [DashboardDocumentsColumnEnum.ImportationDate, key + 'importationDate'],
    [DashboardDocumentsColumnEnum.Actions, key + 'action'],
    [DashboardDocumentsColumnEnum.ImportActions, key + 'action'],
    [DashboardDocumentsColumnEnum.MultiPdfActions, key + 'action'],
  ]);

  export const sortField: SfxMap<DashboardDocumentsColumnEnum, documentListSortField> = new SfxMap<DashboardDocumentsColumnEnum, documentListSortField>([
    [DashboardDocumentsColumnEnum.Family, 'family'],
    [DashboardDocumentsColumnEnum.Name, 'name'],
    [DashboardDocumentsColumnEnum.RefInt, 'refInt'],
    [DashboardDocumentsColumnEnum.DocumentIndex, 'indice'],
    [DashboardDocumentsColumnEnum.State, 'etat'],
    [DashboardDocumentsColumnEnum.DiffusionDate, 'diffusionDate'],
  ]);
}
