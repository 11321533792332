import { ResponseUpdateRequestDTO } from '../../generated/models/models';

export interface BlockFormResponseReferenceForUpdate {
  uuidEntity: string;
  reference: string;
  formUuidEntity: string;
}

export namespace BlockFormResponseReferenceForUpdate {
  export function mapToUpdateRequestApiValue(response: BlockFormResponseReferenceForUpdate): ResponseUpdateRequestDTO {
    return {
      uuidEntity: response.uuidEntity,
      reference: response.reference,
      formBusinessLinkUuidEntity: response.formUuidEntity,
    };
  }
}
