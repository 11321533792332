import { compact } from 'lodash';

import {
  FormLightResponseDTO,
  FormProjectSummaryResponseDTO,
  FormSummaryResponseDTO,
  PageLightFormRequestDTO,
  ResponseSummaryResponseDTO,
  FormBusinessLinkToRepairFormResponseDTO,
} from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum, ResponseStateEnum } from '../../enums';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { FormResponseLightResponseDTO } from '../../generated/models/formResponseLightResponseDTO';

export interface FormResponseLightData {
  uuidEntity: string;
  name: string;
  reference?: string;
  fullName?: string;
  isResponseInPdf?: boolean;
}

export namespace FormResponseLightData {
  export interface QueryRequest extends BaseQuery<FormResponseLightData> {
    projectUuidEntity: string;
    flowsUuidEntity: string[];
  }

  export function mapFromApiValue(formLight: FormLightResponseDTO | FormProjectSummaryResponseDTO): FormResponseLightData {
    return {
      uuidEntity: formLight.uuidEntity,
      name: formLight.name,
      fullName: compact([formLight.name, (formLight as FormProjectSummaryResponseDTO).reference, (formLight as FormProjectSummaryResponseDTO).indice]).join(' - '),
    };
  }

  export function mapFromApiResponse(response: FormSummaryResponseDTO): FormResponseLightData {
    return {
      uuidEntity: response.uuidEntity,
      name: response.name,
      reference: response.reference,
    };
  }

  export function mapResponseFromApiValue(response: FormLightResponseDTO): FormResponseLightData {
    return {
      uuidEntity: response.uuidEntity,
      name: response.responseReference,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageLightFormRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      flowsUuidEntity: queries.flowsUuidEntity || undefined,
      projectUuidEntity: queries.projectUuidEntity || undefined,
    };
  }

  export function initializeFilterQueries(page: number, searchText?: string, projectUuidEntity?: string, flowsUuidEntity?: string[]): QueryRequest {
    return {
      ...initializeQueries<FormResponseLightData>(),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity: projectUuidEntity || undefined,
      flowsUuidEntity: flowsUuidEntity || undefined,
    };
  }

  export function mapToSingleSelectItemData(form: FormLightResponseDTO | FormProjectSummaryResponseDTO): SingleSelectItemData<FormResponseLightData> {
    return {
      value: FormResponseLightData.mapFromApiValue(form),
      title: compact([form.name, form.reference, form.indice]).join(' - '),
    };
  }

  export function mapFormRepairToSingleSelectItemData(form: FormBusinessLinkToRepairFormResponseDTO): SingleSelectItemData<FormResponseLightData> {
    return {
      value: { uuidEntity: form.formBusinessLinkUuidEntity, name: form.name },
      title: compact([form.name, form.phaseNodeOrderToDisplay, form.phaseName, form.stepNodeOrderToDisplay, form.stepName]).join(' - '),
    };
  }

  export function mapResponseToSelectItem(response: ResponseSummaryResponseDTO): SingleSelectItemData<FormResponseLightData> {
    return {
      value: { uuidEntity: response.uuidEntity, name: response.reference },
      title: response?.reference,
      tooltip: ResponseStateEnum.toString.getValue(ResponseStateEnum.convertFromApiValue.getValue(response.state)),
      disabled: !response.responseInPdf,
    };
  }

  export function mapFormResponseToSelectItem(response: FormResponseLightResponseDTO, parentUuidEntity?: string): MultiSelectItemData {
    return {
      value: response.uuidEntity,
      parent: parentUuidEntity ? ({ value: parentUuidEntity } as MultiSelectItemData) : undefined,
      children: (response.children || []).map(child => mapFormResponseToSelectItem(child, response.uuidEntity)),
      title: response.name,
      subtitle: response.subtitle,
    };
  }
}
