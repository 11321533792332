import { ProjectUpdateRequestDTO } from '../../generated/models/models';

import { ProjectDetailsData } from './project-details';

import { LanguageEnum } from '../../enums/language.enum';
import { ProjectStatusEnum } from '../../enums';

export interface ProjectForUpdate extends Partial<ProjectDetailsData> {
  designation: string;
  uuidEntity: string;
  index: string;
  status: ProjectStatusEnum;
  atLeastOneFlow?: boolean;
  customStatusUuidEntity?: string;
}

export namespace ProjectForUpdate {
  export function mapToUpdateRequestApiValue(projectForUpdate: ProjectForUpdate): ProjectUpdateRequestDTO {
    return {
      designation: projectForUpdate.designation,
      uuidEntity: projectForUpdate.uuidEntity,
      labelFamilyApplicationUuidEntity: (projectForUpdate.labelFamilyApplication && projectForUpdate.labelFamilyApplication.uuidEntity) || undefined,
      isContractual: projectForUpdate.isContractual || undefined,
      isSupervised: projectForUpdate.isSupervised || undefined,
      internSupervised: projectForUpdate.isInternSupervised || undefined,
      locationLibelle: projectForUpdate.location || undefined,
      businessRef: projectForUpdate.businessRef || undefined,
      description: projectForUpdate.description || undefined,
      contractRef: projectForUpdate.contractRef || undefined,
      internalCommand: projectForUpdate.internalCommand || undefined,
      externalCommand: projectForUpdate.externalCommand || undefined,
      technicalDeal: projectForUpdate.technicalDeal || undefined,
      marketNumber: projectForUpdate.marketNumber || undefined,
      keywords: projectForUpdate.keywords || undefined,
      businessDomain: projectForUpdate.businessDomain || undefined,
      nationalProgram: projectForUpdate.nationalProgram || undefined,
      supervision1: projectForUpdate.firstSupervision || undefined,
      supervision2: projectForUpdate.secondSupervision || undefined,
      supervision3: projectForUpdate.thirdSupervision || undefined,
      supervisionIntern1: projectForUpdate.firstInternSupervision || undefined,
      supervisionIntern2: projectForUpdate.secondInternSupervision || undefined,
      supervisionIntern3: projectForUpdate.thirdInternSupervision || undefined,
      codeProject: projectForUpdate.codeProject || undefined,
      pgacActivities: (projectForUpdate.pgacActivities && projectForUpdate.pgacActivities.join(';')) || undefined,
      startDate: projectForUpdate.startDate || undefined,
      endDate: projectForUpdate.endDate || undefined,
      exportLanguageOne: LanguageEnum.convertToApiValue.getValue(projectForUpdate.firstLanguage),
      exportLanguageTwo: LanguageEnum.convertToApiValue.getValue(projectForUpdate.secondLanguage),
      complementRefOne: projectForUpdate.complementRefOne || undefined,
      complementRefTwo: projectForUpdate.complementRefTwo || undefined,
      index: projectForUpdate.index,
      status: ProjectStatusEnum.convertToApiValue.getValue(projectForUpdate.status),
      atLeastOneFlow: projectForUpdate?.atLeastOneFlow,
      customStatusUuidEntity: projectForUpdate?.customStatus?.uuidEntity,
      customStatusComment: projectForUpdate?.customStatusComment,
    } as ProjectUpdateRequestDTO; // MakeModels: @Backend:  atLeastOneFlow does not exist on the dto;
  }
}
