import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Overlay, PositionStrategy, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Observable } from 'rxjs';

import { PopoverPositionEnum, ToasterTypeEnum } from '../../../../core/enums';
import { PopoverData } from './popover-config';
import { PopoverOverlayRef } from './popover-overlay-ref';
import { PopoverComponent } from './popover.component';
import { PermissionService } from '../../services/permission.service';
import { ToasterService } from '../toaster/toaster.service';

@Injectable()
export class PopoverService {
  private permission$: Observable<boolean>;
  private popoverRef: PopoverOverlayRef;

  get isAllowed(): boolean {
    let isAllowed: boolean;
    this.permission$.subscribe(value => (isAllowed = value)).unsubscribe();

    return isAllowed;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly defaultPopoverData: Partial<PopoverData<any>> = {
    showAnchor: true,
    hasBackdrop: true,
    closeWhenScrolling: false,
  };
  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    private permissionService: PermissionService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public open<T, R = any>(params: PopoverData<T, R>): PopoverOverlayRef<T> {
    params = { ...this.defaultPopoverData, ...params };
    const overlayRef = this.overlay.create(
      new OverlayConfig({
        hasBackdrop: params.hasBackdrop,
        maxWidth: params.maxWidth,
        maxHeight: params.maxHeight,
        minWidth: params.minWidth,
        minHeight: params.minHeight,
        panelClass: params.panelClass,
        backdropClass: 'popover-backdrop', // FIXME: @Wajdi add it later if needed !
        positionStrategy: this.getOverlayPosition(params.origin, params.preferredPosition),
        scrollStrategy: params.closeWhenScrolling ? this.overlay.scrollStrategies.close() : this.overlay.scrollStrategies.reposition(),
      }),
    );
    this.popoverRef = new PopoverOverlayRef<T>(overlayRef, params);
    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [
        { provide: PopoverOverlayRef, useValue: this.popoverRef },
        { provide: OverlayRef, useValue: overlayRef },
      ],
      name: 'overlayPopoverRef',
    });

    if (params?.checkPermission) {
      this.permission$ = params.checkPermission.uuidContext
        ? this.permissionService.checkUserRole(params.checkPermission.routeId, params.checkPermission.uuidContext, params.checkPermission.action)
        : this.permissionService.checkUserRight(params.checkPermission.routeId, params.checkPermission.action);

      if (!this.isAllowed) {
        this.toasterService.show({
          type: ToasterTypeEnum.Warning,
          title: this.translateService.instant('global.permission.alert.title'),
          subtitle: this.translateService.instant('global.permission.alert.subtitle'),
          dismissible: true,
          dataCy: 'security',
        });
      } else {
        overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
      }
    } else {
      overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
    }

    return this.popoverRef;
  }

  public closePopover(): void {
    if (this.popoverRef) {
      this.popoverRef.close();
    }

    this.popoverRef = undefined;
  }

  private getOverlayPosition(origin: HTMLElement, preferredPosition: PopoverPositionEnum): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(PopoverPositionEnum.positions.getValue(preferredPosition))
      .withFlexibleDimensions(false)
      .withPush(true);
  }
}
