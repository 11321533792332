import { ElementPrRequestDTO } from './../../generated/models/elementPrRequestDTO';

export interface BlockPrRequest {
  projectUuidEntity: string;
  blockUuidEntity: string;
  uuidEntity?: string;
  name?: string;
  content?: string;
  nameAutoFilled: boolean;
  elementOrder: number;
  firstPosition: boolean;
  previousElementUuidEntity: string;
}

export namespace BlockPrRequest {
  export function mapToUpdateRequestApiValue(request: BlockPrRequest): ElementPrRequestDTO {
    return {
      projectUuidEntity: request.projectUuidEntity,
      blocUuidEntity: request.blockUuidEntity,
      uuidEntity: request.uuidEntity,
      name: request.name,
      content: request.content,
      elementOrder: request.elementOrder,
      nameAutoFilled: request.nameAutoFilled,
      firstPosition: request.firstPosition,
      previousElementUuidEntity: request.previousElementUuidEntity,
    };
  }
}
