import { BackupResponseDTO, PageApplicationEventBackUpZipRequest } from '../../generated/models/models';

import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';

import { InnerSortDirectionEnum } from '../../enums';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface BackupSummaryData {
  actionDate: string;
  firstName: string;
  lastName: string;
  natureAction: BackupResponseDTO.NatureActionEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  fileUuidEntity: string;
}

export namespace BackupSummaryData {
  export type QueryRequest = BaseQuery<BackupSummaryData>;
  const sortFields = ['firstName', 'actionDate', 'natureAction'];

  export function mapFromApiValue(backup: BackupResponseDTO): BackupSummaryData {
    return {
      actionDate: backup.actionDate && DateUtils.toDateTimeFormat(backup.actionDate),
      firstName: backup.firstName,
      lastName: backup.lastName,
      natureAction: backup.natureAction,
      uuid: backup.uuid,
      uuidEntity: backup.uuidEntity,
      uuidOrganization: backup.uuidOrganization,
      uuidType: backup.uuidType,
      fileUuidEntity: backup.uuideFile,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageApplicationEventBackUpZipRequest {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : { attribute: 'actionDate', direction: InnerSortDirectionEnum.convertToApiValue.getValue(InnerSortDirectionEnum.Desc) },
    };
  }
  export function initializeQueryRequest(search: string, sort: SortFilter<BackupSummaryData>): QueryRequest {
    return {
      ...initializeQueries<BackupSummaryData>(sort),
      textSearch: search || undefined,
    };
  }
}
