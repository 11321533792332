/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomFieldLightDTO } from './customFieldLightDTO';
import { CustomFileLightDTO } from './customFileLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface CustomCellDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  format: CustomCellDetailsResponseDTO.FormatEnum;
  valueSource: string;
  valueDestination: string;
  geometryLabel: string;
  geometryIcon: CustomCellDetailsResponseDTO.GeometryIconEnum;
  description: string;
  customFile: CustomFileLightDTO;
  customField: CustomFieldLightDTO;
  labelFamily: LabelFamilyLightDTO;
  updatable: boolean;
}
export namespace CustomCellDetailsResponseDTO {
  export type FormatEnum = 'STRING' | 'INTEGER' | 'DOUBLE' | 'IMAGE' | 'DATE' | 'DATETIME';
  export const FormatEnum = {
    STRING: 'STRING' as FormatEnum,
    INTEGER: 'INTEGER' as FormatEnum,
    DOUBLE: 'DOUBLE' as FormatEnum,
    IMAGE: 'IMAGE' as FormatEnum,
    DATE: 'DATE' as FormatEnum,
    DATETIME: 'DATETIME' as FormatEnum,
  };
  export type GeometryIconEnum = 'DEFAULT' | 'WITHOUT_ICON' | 'TRUCK' | 'SNOWPLOW' | 'WAREHOUSE' | 'ARCHIVE' | 'HARD_HAT' | 'TOOLBOX' | 'ROCKET' | 'CARAVAN';
  export const GeometryIconEnum = {
    DEFAULT: 'DEFAULT' as GeometryIconEnum,
    WITHOUTICON: 'WITHOUT_ICON' as GeometryIconEnum,
    TRUCK: 'TRUCK' as GeometryIconEnum,
    SNOWPLOW: 'SNOWPLOW' as GeometryIconEnum,
    WAREHOUSE: 'WAREHOUSE' as GeometryIconEnum,
    ARCHIVE: 'ARCHIVE' as GeometryIconEnum,
    HARDHAT: 'HARD_HAT' as GeometryIconEnum,
    TOOLBOX: 'TOOLBOX' as GeometryIconEnum,
    ROCKET: 'ROCKET' as GeometryIconEnum,
    CARAVAN: 'CARAVAN' as GeometryIconEnum,
  };
}
