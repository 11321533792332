import { createAction, props } from '@ngrx/store';

import { ExternalUserDetailsData, LibraryContributorDetailsData, ErrorResponseData } from '../../../../../core/models';
import { OperatorErrorEnum } from '../../../../../core/enums';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

export const loadExternalUserDetailsSuccess = createAction('[ExternalUsers/Dialog] load external user details success', props<{ externalUser: ExternalUserDetailsData }>());
export const setSubmittedConvertDialog = createAction('[ExternalUsers/Dialog] set submitted', props<{ submitted: boolean }>());
export const loadExternalUserDetailsFail = createAction('[ExternalUsers/Dialog] load external user details fail', props<{ error: DialogToasterData }>());

export const updateExternalUser = createAction('[ExternalUsers/Dialog] update external user', props<{ user: ExternalUserDetailsData }>());
export const updateExternalUserFail = createAction('[ExternalUsers/Dialog] update external user fail', props<{ error: DialogToasterData }>());

export const convertUser = createAction('[ExternalUsers/Dialog] convert external user', props<{ user: LibraryContributorDetailsData; closeModal?: boolean }>());
export const convertUserSuccess = createAction('[ExternalUsers/Dialog] convert external user success', props<{ closeModal?: boolean }>());
export const convertUserFail = createAction('[ExternalUsers/Dialog] convert external user fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());
