import { PermissionActionEnum } from '../../../core/enums';
import { IconData } from './icon-data';

export const lastOneUuidEntity = null;

export interface SelectItemData<T = string> {
  value: T;
  name: string;
  comment?: string;
  color?: string;
  icons?: IconData[];
  tooltip?: string;
  disabled?: boolean;
  selected?: boolean;
  permission?: PermissionActionEnum;
}
