import { Observable, of } from 'rxjs';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { CustomFieldValuesRequest } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyNatureEnum } from '../label-family/label-family-nature.enum';

export enum BusinessClassNameEnum {
  Project = 1,
  Flow,
  CustomStatus,
  TechnicalObject,
  Document,
  Form,
  Revision,
  Emm,
  Adr,
  Pr,
  Step,
  Rhp,
  Fbl,
  Txt,
  MultiPr,
  CustomField,
  BlockDocument,
  TemplateParams,
  User,
  Client,
  TeamRole,
  Signature,
  FormResponse,
  Task,
  Phase,
}

export namespace BusinessClassNameEnum {
  export const shareFormValues = [BusinessClassNameEnum.Form, BusinessClassNameEnum.Fbl];

  export const values = new SfxMap<BusinessClassNameEnum, BusinessClassNameEnum[]>(
    [
      [
        BusinessClassNameEnum.CustomStatus,
        [
          BusinessClassNameEnum.Project,
          BusinessClassNameEnum.Flow,
          BusinessClassNameEnum.Form,
          BusinessClassNameEnum.FormResponse,
          BusinessClassNameEnum.TechnicalObject,
          BusinessClassNameEnum.MultiPr,
        ],
      ],
      [
        BusinessClassNameEnum.CustomField,
        [
          BusinessClassNameEnum.Project,
          BusinessClassNameEnum.Flow,
          BusinessClassNameEnum.Phase,
          BusinessClassNameEnum.Step,
          BusinessClassNameEnum.TechnicalObject,
          BusinessClassNameEnum.Document,
        ],
      ],
    ],
    [BusinessClassNameEnum.Project, BusinessClassNameEnum.Flow, BusinessClassNameEnum.Document, BusinessClassNameEnum.Form, BusinessClassNameEnum.TechnicalObject],
  );

  export const businessClassWhereCanSelectFamilyInCustomStatusModule = [
    BusinessClassNameEnum.Flow,
    BusinessClassNameEnum.FormResponse,
    BusinessClassNameEnum.Form,
    BusinessClassNameEnum.TechnicalObject,
    BusinessClassNameEnum.Project,
  ];
  export const businessClassWhereCanCheckDefaultInCustomStatusModule = [
    BusinessClassNameEnum.Flow,
    BusinessClassNameEnum.Form,
    BusinessClassNameEnum.FormResponse,
    BusinessClassNameEnum.TechnicalObject,
    BusinessClassNameEnum.Project,
  ];
  export const businessClassWhereCanCheckImportableInCustomStatusModule = [BusinessClassNameEnum.Flow, BusinessClassNameEnum.Form];
  export const businessClassWhereCanCheckExportableInCustomStatusModule = [BusinessClassNameEnum.FormResponse, BusinessClassNameEnum.Flow, BusinessClassNameEnum.TechnicalObject];
  export const businessClassWhereCanCheckDownloadableInCustomStatusModule = [
    BusinessClassNameEnum.Flow,
    BusinessClassNameEnum.Form /* BusinessClassNameEnum.Project   waiting for the mobile US */,
  ];
  export const businessClassWithoutOptions = [BusinessClassNameEnum.MultiPr];

  export const businessClassWhereCanHaveProjectLabelFamilyInCustomStatusModule = [BusinessClassNameEnum.Flow, BusinessClassNameEnum.Project];

  export const businessClassWithoutPdfFieldsInCustomFieldsModule = [BusinessClassNameEnum.TechnicalObject, BusinessClassNameEnum.Phase, BusinessClassNameEnum.Step];

  export const convertToApiValue = new SfxMap<BusinessClassNameEnum, CustomFieldValuesRequest.BusinessClassEnum>(
    [
      [BusinessClassNameEnum.Project, CustomFieldValuesRequest.BusinessClassEnum.PROJECT],
      [BusinessClassNameEnum.Flow, CustomFieldValuesRequest.BusinessClassEnum.FLOW],
      [BusinessClassNameEnum.CustomStatus, CustomFieldValuesRequest.BusinessClassEnum.CUSTOMSTATUS],
      [BusinessClassNameEnum.TechnicalObject, CustomFieldValuesRequest.BusinessClassEnum.TECHNICALOBJECT],
      [BusinessClassNameEnum.Document, CustomFieldValuesRequest.BusinessClassEnum.DOCUMENTFLOW],
      [BusinessClassNameEnum.Form, CustomFieldValuesRequest.BusinessClassEnum.FORM],
      [BusinessClassNameEnum.Fbl, CustomFieldValuesRequest.BusinessClassEnum.FBL],
      [BusinessClassNameEnum.MultiPr, CustomFieldValuesRequest.BusinessClassEnum.ELEMENTPR],
      [BusinessClassNameEnum.TemplateParams, CustomFieldValuesRequest.BusinessClassEnum.TEMPLATEPARAMS],
      [BusinessClassNameEnum.Emm, CustomFieldValuesRequest.BusinessClassEnum.ELEMENTEMM],
      [BusinessClassNameEnum.Adr, CustomFieldValuesRequest.BusinessClassEnum.ELEMENTADR],
      [BusinessClassNameEnum.Form, CustomFieldValuesRequest.BusinessClassEnum.FORM],
      [BusinessClassNameEnum.FormResponse, CustomFieldValuesRequest.BusinessClassEnum.FORMRESPONSE],
      [BusinessClassNameEnum.User, CustomFieldValuesRequest.BusinessClassEnum.USER],
      [BusinessClassNameEnum.Client, CustomFieldValuesRequest.BusinessClassEnum.CLIENT],
      [BusinessClassNameEnum.TeamRole, CustomFieldValuesRequest.BusinessClassEnum.TEAMROLE],
      [BusinessClassNameEnum.Signature, CustomFieldValuesRequest.BusinessClassEnum.SIGNATORY],
      [BusinessClassNameEnum.Task, CustomFieldValuesRequest.BusinessClassEnum.SCHEDULINGITEM],
      [BusinessClassNameEnum.Step, CustomFieldValuesRequest.BusinessClassEnum.STEP],
      [BusinessClassNameEnum.Phase, CustomFieldValuesRequest.BusinessClassEnum.PHASE],
    ],
    null,
  );

  export const convertFromApiValue = new SfxMap<CustomFieldValuesRequest.BusinessClassEnum, BusinessClassNameEnum>([
    [CustomFieldValuesRequest.BusinessClassEnum.PROJECT, BusinessClassNameEnum.Project],
    [CustomFieldValuesRequest.BusinessClassEnum.FLOW, BusinessClassNameEnum.Flow],
    [CustomFieldValuesRequest.BusinessClassEnum.CUSTOMSTATUS, BusinessClassNameEnum.CustomStatus],
    [CustomFieldValuesRequest.BusinessClassEnum.TECHNICALOBJECT, BusinessClassNameEnum.TechnicalObject],
    [CustomFieldValuesRequest.BusinessClassEnum.DOCUMENTFLOW, BusinessClassNameEnum.Document],
    [CustomFieldValuesRequest.BusinessClassEnum.BLOCDOCUMENTFLOW, BusinessClassNameEnum.BlockDocument],
    [CustomFieldValuesRequest.BusinessClassEnum.FORM, BusinessClassNameEnum.Form],
    [CustomFieldValuesRequest.BusinessClassEnum.FORMRESPONSE, BusinessClassNameEnum.FormResponse],
    [CustomFieldValuesRequest.BusinessClassEnum.REVISION, BusinessClassNameEnum.Revision],
    [CustomFieldValuesRequest.BusinessClassEnum.ELEMENTEMM, BusinessClassNameEnum.Emm],
    [CustomFieldValuesRequest.BusinessClassEnum.ELEMENTADR, BusinessClassNameEnum.Adr],
    [CustomFieldValuesRequest.BusinessClassEnum.ELEMENTRHP, BusinessClassNameEnum.Rhp],
    [CustomFieldValuesRequest.BusinessClassEnum.ELEMENTPR, BusinessClassNameEnum.MultiPr],
    [CustomFieldValuesRequest.BusinessClassEnum.FBL, BusinessClassNameEnum.Fbl],
    [CustomFieldValuesRequest.BusinessClassEnum.BLOCPR, BusinessClassNameEnum.Pr],
    [CustomFieldValuesRequest.BusinessClassEnum.BLOCTXT, BusinessClassNameEnum.Txt],
    [CustomFieldValuesRequest.BusinessClassEnum.STEP, BusinessClassNameEnum.Step],
    [CustomFieldValuesRequest.BusinessClassEnum.TEMPLATEPARAMS, BusinessClassNameEnum.TemplateParams],
    [CustomFieldValuesRequest.BusinessClassEnum.TEAMROLE, BusinessClassNameEnum.TeamRole],
    [CustomFieldValuesRequest.BusinessClassEnum.SIGNATORY, BusinessClassNameEnum.Signature],
    [CustomFieldValuesRequest.BusinessClassEnum.SCHEDULINGITEM, BusinessClassNameEnum.Task],
    [CustomFieldValuesRequest.BusinessClassEnum.PHASE, BusinessClassNameEnum.Phase],
  ]);

  export const convertFromParamsValue = new SfxMap<string, BusinessClassNameEnum>([
    ['customStatus', BusinessClassNameEnum.CustomStatus],
    ['document', BusinessClassNameEnum.Document],
    ['flow', BusinessClassNameEnum.Flow],
    ['project', BusinessClassNameEnum.Project],
    ['technicalObject', BusinessClassNameEnum.TechnicalObject],
    ['form', BusinessClassNameEnum.Form],
    ['formResponse', BusinessClassNameEnum.FormResponse],
    ['multiPr', BusinessClassNameEnum.MultiPr],
    ['fbl', BusinessClassNameEnum.Fbl],
    ['task', BusinessClassNameEnum.Task],
    ['phase', BusinessClassNameEnum.Phase],
    ['step', BusinessClassNameEnum.Step],
  ]);

  export const convertToParamsValue = new SfxMap<BusinessClassNameEnum, string>([
    [BusinessClassNameEnum.CustomStatus, 'customStatus'],
    [BusinessClassNameEnum.Document, 'document'],
    [BusinessClassNameEnum.Flow, 'flow'],
    [BusinessClassNameEnum.Project, 'project'],
    [BusinessClassNameEnum.TechnicalObject, 'technicalObject'],
    [BusinessClassNameEnum.Form, 'form'],
    [BusinessClassNameEnum.FormResponse, 'formResponse'],
    [BusinessClassNameEnum.MultiPr, 'multiPr'],
    [BusinessClassNameEnum.Fbl, 'fbl'],
    [BusinessClassNameEnum.Emm, 'emm'],
    [BusinessClassNameEnum.Adr, 'adr'],
    [BusinessClassNameEnum.User, 'user'],
    [BusinessClassNameEnum.Task, 'task'],
    [BusinessClassNameEnum.Phase, 'phase'],
    [BusinessClassNameEnum.Step, 'step'],
  ]);

  const key = 'status.filter.nature.';
  export const toString = new SfxMap<BusinessClassNameEnum, string>([
    [BusinessClassNameEnum.Project, key + 'project'],
    [BusinessClassNameEnum.Flow, key + 'flow'],
    [BusinessClassNameEnum.CustomStatus, key + 'customStatus'],
    [BusinessClassNameEnum.TechnicalObject, key + 'technicalObject'],
    [BusinessClassNameEnum.Document, key + 'documentFlow'],
    [BusinessClassNameEnum.BlockDocument, key + 'documentFlow'],
    [BusinessClassNameEnum.Form, key + 'form'],
    [BusinessClassNameEnum.FormResponse, key + 'formResponse'],
    [BusinessClassNameEnum.MultiPr, key + 'multiPr'],
    [BusinessClassNameEnum.Revision, key + 'revision'],
    [BusinessClassNameEnum.Step, key + 'step'],
    [BusinessClassNameEnum.Emm, key + 'emm'],
    [BusinessClassNameEnum.Rhp, key + 'rhp'],
    [BusinessClassNameEnum.Adr, key + 'adr'],
    [BusinessClassNameEnum.Fbl, key + 'fbl'],
    [BusinessClassNameEnum.Pr, key + 'pr'],
    [BusinessClassNameEnum.Txt, key + 'txt'],
    [BusinessClassNameEnum.Task, key + 'task'],
    [BusinessClassNameEnum.Phase, key + 'phase'],
  ]);

  export const convertToLabelFamilyNatureEnum = new SfxMap<BusinessClassNameEnum, LabelFamilyNatureEnum>([
    [BusinessClassNameEnum.Flow, LabelFamilyNatureEnum.Flow],
    [BusinessClassNameEnum.Project, LabelFamilyNatureEnum.Project],
    [BusinessClassNameEnum.TechnicalObject, LabelFamilyNatureEnum.TechnicalObject],
    [BusinessClassNameEnum.Form, LabelFamilyNatureEnum.Form],
    [BusinessClassNameEnum.FormResponse, LabelFamilyNatureEnum.Form],
    [BusinessClassNameEnum.Document, LabelFamilyNatureEnum.Document],
    [BusinessClassNameEnum.Project, LabelFamilyNatureEnum.Project],
    [BusinessClassNameEnum.Phase, LabelFamilyNatureEnum.Flow],
    [BusinessClassNameEnum.Step, LabelFamilyNatureEnum.Flow],
  ]);

  export const shareLoginTextTitle = new SfxMap<BusinessClassNameEnum, string>([
    [BusinessClassNameEnum.Form, 'share.formLoginTitle'],
    [BusinessClassNameEnum.Fbl, 'share.formLoginTitle'],
    [BusinessClassNameEnum.Document, 'share.documentLoginTitle'],
  ]);

  export const shareContributorInfoTitle = new SfxMap<BusinessClassNameEnum, string>([
    [BusinessClassNameEnum.Form, 'share.formContributorInfoTitle'],
    [BusinessClassNameEnum.Fbl, 'share.formContributorInfoTitle'],
    [BusinessClassNameEnum.Document, 'share.documentContributorInfoTitle'],
  ]);

  export const shareDialogTextTitle = new SfxMap<BusinessClassNameEnum, string>(
    [
      [BusinessClassNameEnum.Form, 'share.dialogs.shareFormDialogTitle'],
      [BusinessClassNameEnum.Fbl, 'share.dialogs.shareFormDialogTitle'],
      [BusinessClassNameEnum.Document, 'share.dialogs.shareDocDialogTitle'],
    ],
    'share.dialogs.shareFormResponseDialogTitle',
  );

  export const shareDialogSuccessTextTitle = new SfxMap<string, string>([
    ['form', 'share.dialogs.shareFormLinkSuccess'],
    ['fbl', 'share.dialogs.shareAnswerFormLinkSuccess'],
    ['document', 'share.dialogs.shareDocumentLinkSuccess'],
  ]);

  export const shareDialogFailedTextTitle = new SfxMap<string, string>([
    ['form', 'share.dialogs.shareFormLinkFail'],
    ['fbl', 'share.dialogs.shareAnswerFormLinkFail'],
    ['document', 'share.dialogs.shareDocumentLinkFail'],
  ]);

  export const shareDialogEmptyHistoryText = new SfxMap<BusinessClassNameEnum, string>(
    [
      [BusinessClassNameEnum.Form, 'share.dialogs.emptyHistory'],
      [BusinessClassNameEnum.Fbl, 'share.dialogs.emptyHistory'],
      [BusinessClassNameEnum.Document, 'share.dialogs.emptyDocHistory'],
    ],
    'share.dialogs.emptyResponseHistory',
  );

  export const downloadableStatusText = new SfxMap<BusinessClassNameEnum, string>(
    [[BusinessClassNameEnum.Form, 'status.dialog.options.downloadableForm']],
    'status.dialog.options.downloadable',
  );

  export const iconData = new SfxMap<BusinessClassNameEnum, IconData>(
    [
      [BusinessClassNameEnum.Flow, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
      [BusinessClassNameEnum.Form, { name: 'list-alt', prefix: 'far' }],
      [BusinessClassNameEnum.FormResponse, { name: 'list-alt', prefix: 'far' }],
      [BusinessClassNameEnum.TechnicalObject, { name: 'object-group', prefix: 'fas' }],
      [BusinessClassNameEnum.Project, { name: 'folder-open', prefix: 'far' }],
      [BusinessClassNameEnum.BlockDocument, { name: 'file-invoice' }],
      [BusinessClassNameEnum.Document, { name: 'file-invoice' }],
      [BusinessClassNameEnum.MultiPr, { name: 'check-square', prefix: 'fas' }],
      [BusinessClassNameEnum.Task, { name: 'tasks', prefix: 'fas' }],
      [BusinessClassNameEnum.Phase, { name: 'tasks', prefix: 'fas' }],
      [BusinessClassNameEnum.Step, { name: 'list', prefix: 'fas' }],
    ],
    { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' },
  );

  export const color = new SfxMap<BusinessClassNameEnum, string>(
    [
      [BusinessClassNameEnum.Project, '#bdc581'],
      [BusinessClassNameEnum.Flow, '#bdc581'],
    ],
    '#908E8E',
  );

  export const singleSelectItemsForCustomField = (): Observable<SingleSelectItemData<BusinessClassNameEnum>[]> =>
    of(
      values.getValue(BusinessClassNameEnum.CustomField).map(value => ({
        value,
        title: toString.getValue(value),
        icons: [iconData.getValue(value)],
        selected: value === BusinessClassNameEnum.Flow,
      })),
    );

  export const singleSelectItemsForCustomStatus = (): Observable<SingleSelectItemData<BusinessClassNameEnum>[]> =>
    of(values.getValue(BusinessClassNameEnum.CustomStatus).map(value => ({ value, title: toString.getValue(value), icons: [iconData.getValue(value)] })));

  export const singleSelectItemForReference = (): Observable<SingleSelectItemData<BusinessClassNameEnum>[]> =>
    of(values.getValue(undefined).map(value => ({ value, title: toString.getValue(value), icons: [iconData.getValue(value)] })));

  export const comparePredicate = (node: SingleSelectItemData<BusinessClassNameEnum>, item: BusinessClassNameEnum) => node.value === item;
}
