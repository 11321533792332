import { FlowTypeEnum } from '../../enums';
import { DiagramDTO, DiagramPatchRequestDTO, FlowDiagramDTO, FlowToImportDiagramDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FlowDetailsData } from '../flows/flow-details';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface DiagramData {
  uuidEntity: string;
  projectUuidEntity: string;
  config: string;
  readonly: boolean;
}

export interface FlowDiagramData {
  name: string;
  labelFamilyApplication: LabelFamilyLightData;
  refInt: string;
  flowCustomStatus: CustomStatusLightData;
  flowType: FlowTypeEnum;
  uuidEntity: string;
  startDate?: string;
  finishDate?: string;
  frozen?: boolean;
}

export namespace DiagramData {
  export function mapFromApiValue(diagramDto: DiagramDTO): DiagramData {
    return {
      uuidEntity: diagramDto.uuidEntity,
      projectUuidEntity: diagramDto.projectUuidEntity,
      config: diagramDto.config,
      readonly: diagramDto.readOnly,
    };
  }

  export function mapToUpdateDiagramRequest(diagram: DiagramData): DiagramPatchRequestDTO {
    return {
      config: diagram.config,
    };
  }

  export function mapFlowDiagramFromApiValue(flowDto: FlowDiagramDTO | FlowToImportDiagramDTO): FlowDiagramData {
    return {
      name: flowDto.name || '',
      labelFamilyApplication: (flowDto.labelFamily && LabelFamilyLightData.mapFromApiValue(flowDto.labelFamily)) || LabelFamilyLightData.defaultData,
      flowCustomStatus: flowDto.customStatus && CustomStatusLightData.mapFromApiValue(flowDto.customStatus),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flowDto.typeFlow),
      uuidEntity: flowDto.uuidEntity,
      refInt: flowDto.refInt,
      startDate: DateUtils.toDateFormat(flowDto.startDate),
      finishDate: DateUtils.toDateFormat(flowDto.endDate),
      frozen: (flowDto as FlowToImportDiagramDTO).alreadyImported,
    };
  }

  export function mapFlowDetailsDataToFlowDiagramData(flowDetailsData: FlowDetailsData): FlowDiagramData {
    return {
      uuidEntity: flowDetailsData.uuidEntity,
      name: flowDetailsData.name || '',
      labelFamilyApplication: LabelFamilyLightData.mapFromEntityLightData(flowDetailsData.labelFamilyApplication),
      flowCustomStatus: flowDetailsData.flowCustomStatus,
      flowType: flowDetailsData.flowType,
      refInt: flowDetailsData.refInt,
    };
  }
}
