import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as fromActions from './block-form-dialog.actions';

import {
  ApplicationEventLightData,
  BlockFormHistoryData,
  BlockFormResponseData,
  BlockFormResponseSummaryData,
  BlockFormRubricLightData,
  BlockFormToImportSummaryData,
  LazyLoadResponse,
} from '../../../../../../../../core/models';
import { ReplaceFormData } from '../../../../../../../../core/models/block-form/block-form-replace-data';
import { FormCheckResponseData } from '../../../../../../../../core/models/form-response/form-check-response-data';

import { FieldNameEnum, FormNatureEnum } from '../../../../../../../../core/enums';
import { BlockFormRubricsRestrictionData } from '../../../../../../../../core/models/block-form/block-form-rubrics-restriction.data';

export interface ReplyDialogState {
  formResponse: BlockFormResponseData;
  initialReference: string;
  formCheckResponseData: FormCheckResponseData;
  replyLoading: boolean;
}

export interface ImportDialogState {
  importForms: LazyLoadResponse<BlockFormToImportSummaryData[]>;
  reset: boolean;
  importLoading: boolean;
}

export interface FormResponsesImportDialogState {
  isImporting: boolean;
}

export interface NavigateDialogState {
  initialRubric: BlockFormRubricLightData;
  navigateLoading: boolean;
}

export interface ResponseDialogState {
  formResponses: LazyLoadResponse<BlockFormResponseSummaryData[]>;
  menuLoading: string;
  reset: boolean;
  generateDocumentHasRefIntGenerated: boolean;
  generateDocumentHasRefExtGenerated: boolean;
  generateDocumentLoading: boolean;
  editReferenceLoading: boolean;
  editStatusLoading: boolean;
  loadingExportExcel: boolean;
  loadingExportImages: boolean;
  selectedResponses: string[];
}

export interface FormHistoryDialogState {
  history: LazyLoadResponse<BlockFormHistoryData[]>;
  reset: boolean;
}

export interface ReplaceFormDialogState {
  replaceForm: ReplaceFormData;
  loading: boolean;
}

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

export interface BlockFormDialogState {
  replyDialogState: ReplyDialogState;
  navigateDialogState: NavigateDialogState;
  importDialogState: ImportDialogState;
  responseDialogState: ResponseDialogState;
  formHistoryDialogState: FormHistoryDialogState;
  replaceFormDialogState: ReplaceFormDialogState;
  historyDialogState: HistoryDialogState;
  rubricsToRestrictState: RubricToRestrictState;
  formResponsesImportDialogState: FormResponsesImportDialogState;
}

export interface RubricToRestrictState {
  rubricsToRestrict: BlockFormRubricsRestrictionData[];
  initialRubricsToRestrict: BlockFormRubricsRestrictionData[];
}

const initialRubricToRestrict: RubricToRestrictState = {
  rubricsToRestrict: [],
  initialRubricsToRestrict: [],
};

const initialReplyDialogState = (): ReplyDialogState => ({
  formResponse: null,
  initialReference: undefined,
  formCheckResponseData: null,
  replyLoading: false,
});

const initialNavigateDialogState = (): NavigateDialogState => ({
  initialRubric: null,
  navigateLoading: false,
});

const initialImportDialogState = (): ImportDialogState => ({
  importForms: {} as LazyLoadResponse<BlockFormToImportSummaryData[]>,
  reset: false,
  importLoading: false,
});

const initialFormResponsesImportDialogState = (): FormResponsesImportDialogState => ({
  isImporting: false,
});

const initialResponseDialogState = (): ResponseDialogState => ({
  formResponses: {} as LazyLoadResponse<BlockFormResponseSummaryData[]>,
  reset: false,
  menuLoading: null,
  generateDocumentHasRefIntGenerated: false,
  generateDocumentHasRefExtGenerated: false,
  generateDocumentLoading: false,
  editReferenceLoading: false,
  editStatusLoading: false,
  loadingExportExcel: false,
  loadingExportImages: false,
  selectedResponses: [],
});

const initialFormHistoryDialogState = (): FormHistoryDialogState => ({
  history: {} as LazyLoadResponse<BlockFormHistoryData[]>,
  reset: false,
});

const initialReplaceFormDialogState = (): ReplaceFormDialogState => ({
  replaceForm: null,
  loading: false,
});

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

export const initialBlockFormDialogState = () => ({
  replyDialogState: initialReplyDialogState(),
  navigateDialogState: initialNavigateDialogState(),
  importDialogState: initialImportDialogState(),
  responseDialogState: initialResponseDialogState(),
  formHistoryDialogState: initialFormHistoryDialogState(),
  replaceFormDialogState: initialReplaceFormDialogState(),
  historyDialogState: initialHistoryDialogState(),
  rubricsToRestrictState: initialRubricToRestrict,
  formResponsesImportDialogState: initialFormResponsesImportDialogState(),
});

const reducer = createReducer<BlockFormDialogState>(
  initialBlockFormDialogState(),
  on(fromActions.replyBlockForm, (state): BlockFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: true } })),
  on(
    fromActions.replyBlockFormFail,
    fromActions.replyBlockFormSuccess,
    (state): BlockFormDialogState => ({ ...state, replyDialogState: { ...state.replyDialogState, replyLoading: false } }),
  ),
  on(fromActions.initializeBlockFormResponse, (state, { formResponse, initialReference, formCheckResponseData }) => ({
    ...state,
    replyDialogState: { ...state.replyDialogState, formResponse, initialReference, formCheckResponseData },
  })),
  on(fromActions.initializeBlockFormInitialRubric, (state, { initialRubric }) => ({
    ...state,
    navigateDialogState: { ...state.navigateDialogState, initialRubric: initialRubric.uuidEntity && initialRubric },
  })),
  on(fromActions.guide, (state): BlockFormDialogState => ({ ...state, navigateDialogState: { ...state.navigateDialogState, navigateLoading: true } })),
  on(fromActions.guideFail, (state): BlockFormDialogState => ({ ...state, navigateDialogState: { ...state.navigateDialogState, navigateLoading: false } })),
  on(
    fromActions.loadBlockFormsSuccess,
    (state, { forms, filteredTotalCount, disabledFilteredTotalCount, reset }): BlockFormDialogState => ({
      ...state,
      importDialogState: {
        ...state.importDialogState,
        reset,
        importForms: reset
          ? { payload: forms, filteredTotalCount, disabledFilteredTotalCount }
          : { ...state.importDialogState.importForms, payload: [...state.importDialogState.importForms.payload, ...forms] },
      },
    }),
  ),

  on(fromActions.importBlockForms, (state): BlockFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: true } })),
  on(fromActions.importBlockFormsFail, (state): BlockFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: false } })),
  on(fromActions.importBlockFormsSuccess, (state): BlockFormDialogState => ({ ...state, importDialogState: { ...state.importDialogState, importLoading: false } })),

  on(
    fromActions.loadResponsesSuccess,
    (state, { responses, filteredTotalCount, reset }): BlockFormDialogState => ({
      ...state,
      responseDialogState: {
        ...state.responseDialogState,
        reset,
        formResponses: reset
          ? { payload: responses, filteredTotalCount }
          : { ...state.responseDialogState.formResponses, payload: [...(state.responseDialogState.formResponses.payload || []), ...responses] },
      },
    }),
  ),

  on(fromActions.importFormResponses, (state): BlockFormDialogState => ({ ...state, formResponsesImportDialogState: { isImporting: true } })),
  on(fromActions.importFormResponsesFail, (state): BlockFormDialogState => ({ ...state, formResponsesImportDialogState: { isImporting: false } })),
  on(fromActions.importFormResponsesSuccess, (state): BlockFormDialogState => ({ ...state, formResponsesImportDialogState: { isImporting: false } })),

  on(
    fromActions.setEditResponseReferenceLoading,
    (state, { loading }): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, editReferenceLoading: loading } }),
  ),
  on(
    fromActions.setGenerateDocumentLoading,
    (state, { loading }): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, generateDocumentLoading: loading } }),
  ),
  on(
    fromActions.setEditResponseStatusLoading,
    (state, { loading }): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, editStatusLoading: loading } }),
  ),
  on(
    fromActions.setSelectedResponses,
    (state, { responsesUuidEntity }): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, selectedResponses: responsesUuidEntity } }),
  ),
  on(
    fromActions.checkGenerateDocumentHasRefGeneratedSuccess,
    (state, { generateDocumentHasRefGenerated, fieldName }): BlockFormDialogState => ({
      ...state,
      responseDialogState: {
        ...state.responseDialogState,
        generateDocumentHasRefIntGenerated: fieldName === FieldNameEnum.RefInt ? generateDocumentHasRefGenerated : state.responseDialogState.generateDocumentHasRefIntGenerated,
        generateDocumentHasRefExtGenerated: fieldName === FieldNameEnum.RefExt ? generateDocumentHasRefGenerated : state.responseDialogState.generateDocumentHasRefExtGenerated,
      },
    }),
  ),
  on(fromActions.exportExcelFile, (state): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportExcel: true } })),
  on(
    fromActions.exportExcelFileFail,
    fromActions.exportExcelFileSuccess,
    (state): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportExcel: false } }),
  ),

  on(fromActions.exportImageFile, (state): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportImages: true } })),
  on(
    fromActions.exportImageFileSuccess,
    fromActions.exportImageFileFail,
    (state): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, loadingExportImages: false } }),
  ),
  on(
    fromActions.loadFormHistorySuccess,
    (state, { formEvents, reset }): BlockFormDialogState => ({
      ...state,
      formHistoryDialogState: {
        ...state.formHistoryDialogState,
        history: reset ? formEvents : { ...state.formHistoryDialogState.history, payload: [...state.formHistoryDialogState.history.payload, ...formEvents.payload] },
        reset,
      },
    }),
  ),
  on(
    fromActions.initializeReplaceForm,
    (state, { replaceForm }): BlockFormDialogState => ({
      ...state,
      replaceFormDialogState: { ...state.replaceFormDialogState, replaceForm: { ...replaceForm, newFormType: FormNatureEnum.Model } },
    }),
  ),
  on(fromActions.replaceForm, (state): BlockFormDialogState => ({ ...state, replaceFormDialogState: { ...state.replaceFormDialogState, loading: true } })),
  on(
    fromActions.cancelReplace,
    fromActions.replaceFormFail,
    fromActions.replaceFormSuccess,
    (state): BlockFormDialogState => ({ ...state, replaceFormDialogState: { ...state.replaceFormDialogState, loading: false } }),
  ),
  on(
    fromActions.loadHistoryListSuccess,
    (state, { data, reset }): BlockFormDialogState => ({
      ...state,
      historyDialogState: {
        ...state.historyDialogState,
        reset,
        historyList: reset ? data : { ...state.historyDialogState.historyList, payload: [...state.historyDialogState.historyList.payload, ...data.payload] },
      },
    }),
  ),
  on(
    fromActions.duplicateResponse,
    (state, { responseUuidEntity }): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, menuLoading: responseUuidEntity } }),
  ),
  on(
    fromActions.duplicateResponseSuccess,
    fromActions.duplicateResponseFail,
    (state): BlockFormDialogState => ({ ...state, responseDialogState: { ...state.responseDialogState, menuLoading: null } }),
  ),
  on(
    fromActions.loadRubricsToRestrictSuccess,
    (state, { rubricsToRestrict }): BlockFormDialogState => {
      const tmpRubricsToRestrict = rubricsToRestrict.map((rubric, index) => ({ ...rubric, name: `R${index + 1} - ${rubric.name}` }));

      return {
        ...state,
        rubricsToRestrictState: { ...state.rubricsToRestrictState, rubricsToRestrict: tmpRubricsToRestrict, initialRubricsToRestrict: cloneDeep(tmpRubricsToRestrict) },
      };
    },
  ),
);
export function blockFormDialogReducer(state: BlockFormDialogState | undefined, action: Action): BlockFormDialogState {
  return reducer(state, action);
}
