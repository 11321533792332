import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum PgacProjectTabEnum {
  General = 'general',
  History = 'pgacHistory',
  Conflict = 'pgacConflict',
}

export namespace PgacProjectTabEnum {
  const key = 'pgacs.modals.pgacProject.table.tabs.';
  export const toString: SfxMap<PgacProjectTabEnum, string> = new SfxMap<PgacProjectTabEnum, string>([
    [PgacProjectTabEnum.General, key + 'general'],
    [PgacProjectTabEnum.History, key + 'historique'],
    [PgacProjectTabEnum.Conflict, key + 'conflict'],
  ]);

  export const iconData: SfxMap<PgacProjectTabEnum, IconData> = new SfxMap<PgacProjectTabEnum, IconData>([
    [PgacProjectTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [PgacProjectTabEnum.History, { name: 'history', prefix: 'fas' }],
    [PgacProjectTabEnum.Conflict, { name: 'exclamation-triangle', prefix: 'fas' }],
  ]);
}
