/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BlocDsiCreateSignatoryRequestDTO {
  uuideProject: string;
  elementDsiUuide: string;
  firstName?: string;
  lastName?: string;
  profession?: string;
  companyName?: string;
  email?: string;
  userUuide?: string;
  date: Date;
  comment?: string;
  signatoryImage?: string;
  contributorType: BlocDsiCreateSignatoryRequestDTO.ContributorTypeEnum;
  signatoryNature: BlocDsiCreateSignatoryRequestDTO.SignatoryNatureEnum;
  signatureState: BlocDsiCreateSignatoryRequestDTO.SignatureStateEnum;
  proxied?: boolean;
  eventSource?: BlocDsiCreateSignatoryRequestDTO.EventSourceEnum;
  signatureProxiedDate?: Date;
}
export namespace BlocDsiCreateSignatoryRequestDTO {
  export type ContributorTypeEnum = 'INTERN' | 'EXTERN' | 'NO_LISTED_INTERN';
  export const ContributorTypeEnum = {
    INTERN: 'INTERN' as ContributorTypeEnum,
    EXTERN: 'EXTERN' as ContributorTypeEnum,
    NOLISTEDINTERN: 'NO_LISTED_INTERN' as ContributorTypeEnum,
  };
  export type SignatoryNatureEnum =
    | 'SIGNATURE_REALISATION'
    | 'SIGNATURE_SUPRV_INT_CT'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3'
    | 'SIGNATURE_SUPRV_INT_PI1'
    | 'SIGNATURE_SUPRV_INT_PI2'
    | 'SIGNATURE_SUPRV_INT_PI3'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT1'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT2'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT3'
    | 'SIGNATURE_SUPRV_EXT1'
    | 'SIGNATURE_SUPRV_EXT2'
    | 'SIGNATURE_SUPRV_EXT3';
  export const SignatoryNatureEnum = {
    REALISATION: 'SIGNATURE_REALISATION' as SignatoryNatureEnum,
    SUPRVINTCT: 'SIGNATURE_SUPRV_INT_CT' as SignatoryNatureEnum,
    CONVOCATIONSUPRVINTPI1: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1' as SignatoryNatureEnum,
    CONVOCATIONSUPRVINTPI2: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2' as SignatoryNatureEnum,
    CONVOCATIONSUPRVINTPI3: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3' as SignatoryNatureEnum,
    SUPRVINTPI1: 'SIGNATURE_SUPRV_INT_PI1' as SignatoryNatureEnum,
    SUPRVINTPI2: 'SIGNATURE_SUPRV_INT_PI2' as SignatoryNatureEnum,
    SUPRVINTPI3: 'SIGNATURE_SUPRV_INT_PI3' as SignatoryNatureEnum,
    CONVOCATIONSUPRVEXT1: 'SIGNATURE_CONVOCATION_SUPRV_EXT1' as SignatoryNatureEnum,
    CONVOCATIONSUPRVEXT2: 'SIGNATURE_CONVOCATION_SUPRV_EXT2' as SignatoryNatureEnum,
    CONVOCATIONSUPRVEXT3: 'SIGNATURE_CONVOCATION_SUPRV_EXT3' as SignatoryNatureEnum,
    SUPRVEXT1: 'SIGNATURE_SUPRV_EXT1' as SignatoryNatureEnum,
    SUPRVEXT2: 'SIGNATURE_SUPRV_EXT2' as SignatoryNatureEnum,
    SUPRVEXT3: 'SIGNATURE_SUPRV_EXT3' as SignatoryNatureEnum,
  };
  export type SignatureStateEnum = 'SIGNATURE_NOT_POSSIBLE' | 'WAITING' | 'FREE' | 'SIGNED';
  export const SignatureStateEnum = {
    SIGNATURENOTPOSSIBLE: 'SIGNATURE_NOT_POSSIBLE' as SignatureStateEnum,
    WAITING: 'WAITING' as SignatureStateEnum,
    FREE: 'FREE' as SignatureStateEnum,
    SIGNED: 'SIGNED' as SignatureStateEnum,
  };
  export type EventSourceEnum = 'WebApp' | 'MobileApp' | 'API_EXT';
  export const EventSourceEnum = {
    WebApp: 'WebApp' as EventSourceEnum,
    MobileApp: 'MobileApp' as EventSourceEnum,
    APIEXT: 'API_EXT' as EventSourceEnum,
  };
}
