import { BlocHistoryDetailsResponseDTO, PageBlocRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { BlockStatusEnum, InnerSortDirectionEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface BlockStatusHistoryData {
  uuidEntity: string;
  currentStatus: BlockStatusEnum;
  previousStatus: BlockStatusEnum;
  userName: string;
  applicationName: string;
  comment: string;
  actionDate: string;
  actionTime: string;
}

export namespace BlockStatusHistoryData {
  export interface QueryRequest extends BaseQuery<BlockStatusHistoryData> {
    blockUuidEntity: string;
    projectUuidEntity: string;
  }

  export function mapFromApiValue(blockStatusHistory: BlocHistoryDetailsResponseDTO): BlockStatusHistoryData {
    return {
      uuidEntity: blockStatusHistory.uuidEntity,
      currentStatus: BlockStatusEnum.convertFromApiValue.getValue(blockStatusHistory.currentStatus),
      previousStatus: BlockStatusEnum.convertFromApiValue.getValue(blockStatusHistory.previousStatus),
      userName: blockStatusHistory.user && `${blockStatusHistory.user.firstName} ${blockStatusHistory.user.lastName}`,
      applicationName: blockStatusHistory.applicationName,
      comment: blockStatusHistory.comment,
      actionDate: blockStatusHistory.eventDate && DateUtils.toDateFormat(blockStatusHistory.eventDate),
      actionTime: blockStatusHistory.eventDate && DateUtils.toTimeFormat(blockStatusHistory.eventDate),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockStatusHistoryData>(),
      projectUuidEntity,
      blockUuidEntity,
    };
  }
}
