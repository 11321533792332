import { ElementPlanLinkLightResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum PlanLinkTypeEnum {
  BlockedBy = 1,
  Blocks = 2,
}

export namespace PlanLinkTypeEnum {
  export const convertFromApiValue: SfxMap<ElementPlanLinkLightResponseDTO.DependencyTypeEnum, PlanLinkTypeEnum> = new SfxMap<
    ElementPlanLinkLightResponseDTO.DependencyTypeEnum,
    PlanLinkTypeEnum
  >([
    [ElementPlanLinkLightResponseDTO.DependencyTypeEnum.ENDSTART, PlanLinkTypeEnum.Blocks],
    [ElementPlanLinkLightResponseDTO.DependencyTypeEnum.STARTEND, PlanLinkTypeEnum.BlockedBy],
  ]);

  export const convertToApiValue: SfxMap<PlanLinkTypeEnum, ElementPlanLinkLightResponseDTO.DependencyTypeEnum> = new SfxMap<
    PlanLinkTypeEnum,
    ElementPlanLinkLightResponseDTO.DependencyTypeEnum
  >([
    [PlanLinkTypeEnum.Blocks, ElementPlanLinkLightResponseDTO.DependencyTypeEnum.ENDSTART],
    [PlanLinkTypeEnum.BlockedBy, ElementPlanLinkLightResponseDTO.DependencyTypeEnum.STARTEND],
  ]);

  export const toString: SfxMap<PlanLinkTypeEnum, string> = new SfxMap<PlanLinkTypeEnum, string>([
    [PlanLinkTypeEnum.BlockedBy, 'planTranslate.linkType.blockedBy'],
    [PlanLinkTypeEnum.Blocks, 'planTranslate.linkType.blocks'],
  ]);
}
