import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ImportTeamRolePlanModalTabEnum {
  Users = '_users',
  Tablets = '_tablets',
}

export namespace ImportTeamRolePlanModalTabEnum {
  export function getValues(): string[] {
    return [ImportTeamRolePlanModalTabEnum.Users, ImportTeamRolePlanModalTabEnum.Tablets];
  }

  export const toString: SfxMap<ImportTeamRolePlanModalTabEnum, string> = new SfxMap<ImportTeamRolePlanModalTabEnum, string>([
    [ImportTeamRolePlanModalTabEnum.Users, 'planTranslate.import.contributor'],
    [ImportTeamRolePlanModalTabEnum.Tablets, 'planTranslate.import.tablet'],
  ]);

  export const iconData: SfxMap<ImportTeamRolePlanModalTabEnum, IconData> = new SfxMap<ImportTeamRolePlanModalTabEnum, IconData>([
    [ImportTeamRolePlanModalTabEnum.Users, { name: 'user-friends' }],
    [ImportTeamRolePlanModalTabEnum.Tablets, { name: 'tablet-alt' }],
  ]);

  export const convertToParamsValue: SfxMap<ImportTeamRolePlanModalTabEnum, string> = new SfxMap<ImportTeamRolePlanModalTabEnum, string>([
    [ImportTeamRolePlanModalTabEnum.Users, 'users'],
    [ImportTeamRolePlanModalTabEnum.Tablets, 'tablets'],
  ]);

  export const convertFromParamsValue: SfxMap<string, ImportTeamRolePlanModalTabEnum> = new SfxMap<string, ImportTeamRolePlanModalTabEnum>([
    ['users', ImportTeamRolePlanModalTabEnum.Users],
    ['tablets', ImportTeamRolePlanModalTabEnum.Tablets],
  ]);
}
