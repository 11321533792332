import { CustomFileDetailsResponseDTO } from '../../generated/models/customFileDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum CustomFileExtensionEnum {
  Csv = 1,
  Xls,
  Xlsx,
  Word,
  Pdf,
  Txt,
  Doc,
  Docx,
  Png,
  Jpg,
  Gif,
  Zip,
}

export namespace CustomFileExtensionEnum {
  export const disableDownloadFile = [CustomFileExtensionEnum.Jpg, CustomFileExtensionEnum.Png, CustomFileExtensionEnum.Gif];
  export const isImage = [CustomFileExtensionEnum.Jpg, CustomFileExtensionEnum.Png, CustomFileExtensionEnum.Gif];

  const values: CustomFileExtensionEnum[] = [
    CustomFileExtensionEnum.Csv,
    CustomFileExtensionEnum.Xls,
    CustomFileExtensionEnum.Xlsx,
    CustomFileExtensionEnum.Word,
    CustomFileExtensionEnum.Pdf,
    CustomFileExtensionEnum.Txt,
  ];

  export const convertFromApiValue: SfxMap<CustomFileDetailsResponseDTO.ExtensionEnum, CustomFileExtensionEnum> = new SfxMap<
    CustomFileDetailsResponseDTO.ExtensionEnum,
    CustomFileExtensionEnum
  >([
    [CustomFileDetailsResponseDTO.ExtensionEnum.CSV, CustomFileExtensionEnum.Csv],
    [CustomFileDetailsResponseDTO.ExtensionEnum.XLS, CustomFileExtensionEnum.Xls],
    [CustomFileDetailsResponseDTO.ExtensionEnum.XLSX, CustomFileExtensionEnum.Xlsx],
    [CustomFileDetailsResponseDTO.ExtensionEnum.WORD, CustomFileExtensionEnum.Word],
    [CustomFileDetailsResponseDTO.ExtensionEnum.PDF, CustomFileExtensionEnum.Pdf],
    [CustomFileDetailsResponseDTO.ExtensionEnum.TXT, CustomFileExtensionEnum.Txt],
  ]);

  export const convertToApiValue: SfxMap<CustomFileExtensionEnum, CustomFileDetailsResponseDTO.ExtensionEnum> = new SfxMap<
    CustomFileExtensionEnum,
    CustomFileDetailsResponseDTO.ExtensionEnum
  >([
    [CustomFileExtensionEnum.Csv, CustomFileDetailsResponseDTO.ExtensionEnum.CSV],
    [CustomFileExtensionEnum.Xls, CustomFileDetailsResponseDTO.ExtensionEnum.XLS],
    [CustomFileExtensionEnum.Xlsx, CustomFileDetailsResponseDTO.ExtensionEnum.XLSX],
    [CustomFileExtensionEnum.Word, CustomFileDetailsResponseDTO.ExtensionEnum.WORD],
    [CustomFileExtensionEnum.Pdf, CustomFileDetailsResponseDTO.ExtensionEnum.PDF],
    [CustomFileExtensionEnum.Txt, CustomFileDetailsResponseDTO.ExtensionEnum.TXT],
  ]);

  export const convertToParamsValue: SfxMap<CustomFileExtensionEnum, string> = new SfxMap<CustomFileExtensionEnum, string>([
    [CustomFileExtensionEnum.Csv, 'csv'],
    [CustomFileExtensionEnum.Xls, 'xls'],
    [CustomFileExtensionEnum.Xlsx, 'xlsx'],
    [CustomFileExtensionEnum.Word, 'word'],
    [CustomFileExtensionEnum.Pdf, 'pdf'],
    [CustomFileExtensionEnum.Txt, 'txt'],
  ]);

  export const convertFromParamsValue: SfxMap<string, CustomFileExtensionEnum> = new SfxMap<string, CustomFileExtensionEnum>([
    ['csv', CustomFileExtensionEnum.Csv],
    ['xls', CustomFileExtensionEnum.Xls],
    ['xlsx', CustomFileExtensionEnum.Xlsx],
    ['word', CustomFileExtensionEnum.Word],
    ['pdf', CustomFileExtensionEnum.Pdf],
    ['txt', CustomFileExtensionEnum.Txt],
    ['doc', CustomFileExtensionEnum.Doc],
    ['docx', CustomFileExtensionEnum.Docx],
    ['jpg', CustomFileExtensionEnum.Jpg],
    ['png', CustomFileExtensionEnum.Png],
    ['gif', CustomFileExtensionEnum.Gif],
    ['zip', CustomFileExtensionEnum.Zip],
  ]);

  export const toString = (forthcoming?: boolean): SfxMap<CustomFileExtensionEnum, string> => {
    const key = 'customFiles.extensions.' + (forthcoming ? 'forthcoming.' : '');

    return new SfxMap<CustomFileExtensionEnum, string>([
      [CustomFileExtensionEnum.Csv, key + 'csv'],
      [CustomFileExtensionEnum.Xls, key + 'xls'],
      [CustomFileExtensionEnum.Xlsx, key + 'xlsx'],
      [CustomFileExtensionEnum.Word, key + 'word'],
      [CustomFileExtensionEnum.Pdf, key + 'pdf'],
      [CustomFileExtensionEnum.Txt, key + 'txt'],
    ]);
  };

  export const isDisabled: SfxMap<CustomFileExtensionEnum, boolean> = new SfxMap<CustomFileExtensionEnum, boolean>([
    [CustomFileExtensionEnum.Xls, true],
    [CustomFileExtensionEnum.Xlsx, true],
    [CustomFileExtensionEnum.Word, true],
    [CustomFileExtensionEnum.Pdf, true],
    [CustomFileExtensionEnum.Txt, true],
  ]);

  export const iconData: SfxMap<string, IconData> = new SfxMap<string, IconData>(
    [
      ['pdf', { name: 'file-pdf', prefix: 'fas' }],
      ['xls', { name: 'file-excel', prefix: 'fas' }],
      ['xlsx', { name: 'file-excel', prefix: 'fas' }],
      ['doc', { name: 'file-word', prefix: 'fas' }],
      ['docx', { name: 'file-word', prefix: 'fas' }],
      ['txt', { name: 'file', prefix: 'fas' }],
      ['csv', { name: 'file-csv', prefix: 'fas' }],
      ['jpg', { name: 'file-image', prefix: 'fas' }],
      ['png', { name: 'file-image', prefix: 'fas' }],
      ['gif', { name: 'file-image', prefix: 'fas' }],
      ['zip', { name: 'file-archive', prefix: 'fas' }],
    ],
    { name: 'file', prefix: 'fas' },
  );

  export const getFileMimeType: SfxMap<CustomFileExtensionEnum, string> = new SfxMap<CustomFileExtensionEnum, string>([
    [CustomFileExtensionEnum.Pdf, 'application/pdf'],
    [CustomFileExtensionEnum.Xls, 'application/vnd.ms-excel'],
    [CustomFileExtensionEnum.Xlsx, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    [CustomFileExtensionEnum.Csv, 'text/csv;encoding:utf-8'],
    [CustomFileExtensionEnum.Jpg, 'image/jpeg'],
    [CustomFileExtensionEnum.Png, 'image/png'],
    [CustomFileExtensionEnum.Gif, 'image/gif'],
    [CustomFileExtensionEnum.Doc, 'application/msword'],
    [CustomFileExtensionEnum.Docx, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    [CustomFileExtensionEnum.Zip, 'application/zip'],
    [CustomFileExtensionEnum.Txt, 'text/plain'],
  ]);

  export const itemsResolver = (forthcoming?: boolean): Observable<SingleSelectItemData<CustomFileExtensionEnum>[]> =>
    of(
      values
        .filter(extension => !isDisabled.getValue(extension))
        .map(extension => ({
          value: extension,
          title: toString(forthcoming).getValue(extension),
        })),
    );

  export const comparePredicate = (node: SingleSelectItemData<CustomFileExtensionEnum>, item: CustomFileExtensionEnum) => node.value === item;
}
