import { OffsetDTO } from '../../generated/models/models';

export interface OffsetData {
  offsetX?: number;
  offsetY?: number;
}

export namespace OffsetData {
  export function mapFromApiValue(offset: OffsetDTO): OffsetData {
    return {
      offsetX: offset.x,
      offsetY: offset.y,
    };
  }

  export function mapToRequestApiValue(offset: OffsetData): OffsetDTO {
    return {
      x: offset.offsetX,
      y: offset.offsetY,
    };
  }
}
