import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ListSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ListsTypeEnum {
  Emm = 1,
  Adr = 2,
}

export namespace ListsTypeEnum {
  const values = [ListsTypeEnum.Emm, ListsTypeEnum.Adr];

  export const convertFromApiValue: SfxMap<ListSummaryResponseDTO.TypeFlowEnum, ListsTypeEnum> = new SfxMap<ListSummaryResponseDTO.TypeFlowEnum, ListsTypeEnum>([
    [ListSummaryResponseDTO.TypeFlowEnum.LISTEMM, ListsTypeEnum.Emm],
    [ListSummaryResponseDTO.TypeFlowEnum.LISTADR, ListsTypeEnum.Adr],
  ]);

  export const convertToApiValue: SfxMap<ListsTypeEnum, ListSummaryResponseDTO.TypeFlowEnum> = new SfxMap<ListsTypeEnum, ListSummaryResponseDTO.TypeFlowEnum>([
    [ListsTypeEnum.Emm, ListSummaryResponseDTO.TypeFlowEnum.LISTEMM],
    [ListsTypeEnum.Adr, ListSummaryResponseDTO.TypeFlowEnum.LISTADR],
  ]);

  export const convertToParamsValue: SfxMap<ListsTypeEnum, string> = new SfxMap<ListsTypeEnum, string>([
    [ListsTypeEnum.Emm, 'equipment'],
    [ListsTypeEnum.Adr, 'risk'],
  ]);

  export const convertFromParamsValue: SfxMap<string, ListsTypeEnum> = new SfxMap<string, ListsTypeEnum>([
    ['equipment', ListsTypeEnum.Emm],
    ['risk', ListsTypeEnum.Adr],
  ]);

  export const toString = (plural?: boolean): SfxMap<ListsTypeEnum, string> =>
    new SfxMap<ListsTypeEnum, string>([
      [ListsTypeEnum.Emm, plural ? 'lists.filter.equipmentList' : 'lists.types.emm'],
      [ListsTypeEnum.Adr, plural ? 'lists.filter.risksList' : 'lists.types.adr'],
    ]);

  export const iconData: SfxMap<ListsTypeEnum, IconData> = new SfxMap<ListsTypeEnum, IconData>([
    [ListsTypeEnum.Emm, { name: 'tools', prefix: 'fas' }],
    [ListsTypeEnum.Adr, { name: 'exclamation-triangle', prefix: 'fas' }],
  ]);

  export const color: SfxMap<ListsTypeEnum, string> = new SfxMap<ListsTypeEnum, string>([
    [ListsTypeEnum.Emm, '#b5838c'],
    [ListsTypeEnum.Adr, '#db7663'],
  ]);

  export const editPath: SfxMap<ListsTypeEnum, string> = new SfxMap<ListsTypeEnum, string>([
    [ListsTypeEnum.Emm, 'edit-emm'],
    [ListsTypeEnum.Adr, 'edit-adr'],
  ]);

  export const itemsResolver = (plural?: boolean): Observable<SingleSelectItemData<ListsTypeEnum>[]> =>
    of(values.map(type => ({ value: type, title: ListsTypeEnum.toString(plural).getValue(type) })));

  export const comparePredicate = (node: SingleSelectItemData<ListsTypeEnum>, item: ListsTypeEnum) => node.value === item;
}
