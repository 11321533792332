import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';
import { TechnicalObjectSummaryResponseDTO, PageTechnicalObjectRequestDTO } from '../../generated/models/models';
import { InnerSortDirectionEnum, TechnicalObjectNatureEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';

export interface TechnicalObjectSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
  nature: TechnicalObjectNatureEnum;
  refInt: string;
  refExt: string;
  nodeOrder: number;
  modificationDate: Date;
  customStatus: CustomStatusLightData;
  labelFamily: LabelFamilyLightData;
  applicability: EntityLightData;
  childrenCount: number;
  parent?: TechnicalObjectSummaryData;
  frozen?: boolean;
}

export namespace TechnicalObjectSummaryData {
  const sortFields = ['name', 'labelFamily', 'customStatus', 'refInt', 'refExt'];
  export interface QueryRequest extends BaseQuery<TechnicalObjectSummaryData> {
    applicabilities?: string[];
    families?: string[];
    parentUuidEntity?: string;
  }

  export function mapFromApiValue(technicalObjectSummary: TechnicalObjectSummaryResponseDTO): TechnicalObjectSummaryData {
    const objectNature = TechnicalObjectNatureEnum.convertFromApiValue.getValue(technicalObjectSummary.nature);

    return {
      uuidEntity: technicalObjectSummary.uuidEntity,
      name: technicalObjectSummary.name,
      description: technicalObjectSummary.description,
      nature: objectNature,
      refInt: technicalObjectSummary.refInt,
      refExt: technicalObjectSummary.refExt,
      nodeOrder: technicalObjectSummary.nodeOrder,
      modificationDate: technicalObjectSummary.modificationDate,
      customStatus:
        objectNature === TechnicalObjectNatureEnum.TechnicalObject &&
        technicalObjectSummary.customStatus &&
        CustomStatusLightData.mapFromApiValue(technicalObjectSummary.customStatus),
      labelFamily: (technicalObjectSummary.labelFamily && LabelFamilyLightData.mapFromApiValue(technicalObjectSummary.labelFamily)) || LabelFamilyLightData.defaultData,
      applicability: technicalObjectSummary.applicability && LabelApplicabilityLightData.mapToEntityLightData(technicalObjectSummary.applicability),
      childrenCount: technicalObjectSummary.childrenCount,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageTechnicalObjectRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : { attribute: 'nodeOrder', direction: InnerSortDirectionEnum.convertToApiValue.getValue(InnerSortDirectionEnum.Asc) },
      applicabilitiesUuidEntity: queries.applicabilities || undefined,
      familiesUuidEntity: queries.families || undefined,
      textSearch: queries.textSearch || undefined,
      parentUuidEntity: queries.parentUuidEntity || undefined,
    };
  }

  export function initializeQueryRequest(applicabilities: string[], families: string[], searchText: string, sort: SortFilter<TechnicalObjectSummaryData>): QueryRequest {
    return {
      ...initializeQueries<TechnicalObjectSummaryData>({ key: sort.key, direction: sort.direction }),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
    };
  }

  export function toString(objectSummary: TechnicalObjectSummaryData): string {
    return (objectSummary.refInt ? objectSummary.refInt.concat(' - ') : '').concat(objectSummary.name);
  }
}
