import { FlowTechnicalObjectSummaryResponseDTO, PageFlowForTechnicalObjectRequest } from '../../generated/models/models';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { DateFilterData } from '../utils/date-filter-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { ColorPickerPaletteEnum, FlowTypeEnum, InnerSortDirectionEnum, RelationNatureEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';
import { UserLightData } from '../user/user-light-data';

export interface ObjectFlowSummaryData {
  name: string;
  labelFamilyApplication: LabelFamilyLightData;
  labelFamilyProject: LabelFamilyLightData;
  modificationDate: string;
  refInt: string;
  refExt: string;
  flowCustomStatus: CustomStatusLightData;
  flowType: FlowTypeEnum;
  assignedUser: EntityLightData;
  reporterUser: EntityLightData;
  userActor1: EntityLightData;
  userActor2: EntityLightData;
  userActor3: EntityLightData;
  functionMarker: string;
  locationLocal: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  refFolder: string;
  startDate: string;
  finishDate: string;
  color: string;
  order: number;
  projectUuidEntity: string;
  relationUuidEntity: string;
}

export namespace ObjectFlowSummaryData {
  export interface QueryRequest extends BaseQuery<ObjectFlowSummaryData> {
    contents?: string[];
    project?: string;
    families?: string[];
    nature?: RelationNatureEnum;
    dateFilter?: DateFilterData;
  }

  export function mapFromApiValue(flow: FlowTechnicalObjectSummaryResponseDTO): ObjectFlowSummaryData {
    return {
      name: flow.name || '',
      assignedUser: flow.assigneeUser && UserLightData.mapUserToEntityLightData(flow.assigneeUser),
      labelFamilyApplication: (flow.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(flow.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      labelFamilyProject: flow.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(flow.labelFamilyProject),
      modificationDate: DateUtils.toDateFormat(flow.modificationDate),
      reporterUser: flow.reporterUser && UserLightData.mapUserToEntityLightData(flow.reporterUser),
      flowCustomStatus: flow.customStatusFlow && CustomStatusLightData.mapFromApiValue(flow.customStatusFlow),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flow.typeFlow),
      userActor1: flow.actor1 && UserLightData.mapUserToEntityLightData(flow.actor1),
      userActor2: flow.actor2 && UserLightData.mapUserToEntityLightData(flow.actor2),
      userActor3: flow.actor3 && UserLightData.mapUserToEntityLightData(flow.actor3),
      functionMarker: flow.functionMarker,
      locationLocal: flow.locationLocal,
      uuid: flow.uuid,
      uuidType: flow.uuidType,
      uuidEntity: flow.uuidEntity,
      refInt: flow.refInt,
      refExt: flow.refExt,
      refFolder: flow.refExt || 'global.defaultRefFolder',
      startDate: DateUtils.toDateFormat(flow.startDate),
      finishDate: DateUtils.toDateFormat(flow.finishDate),
      color: flow.flowBarColor || ColorPickerPaletteEnum.defaultColor,
      order: flow.flowBarOrder,
      projectUuidEntity: flow.projectUuidEntity,
      relationUuidEntity: flow.relationUuidEntity,
    };
  }

  export function mapToRequestApiValue(queries: QueryRequest): PageFlowForTechnicalObjectRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      content: queries.contents || undefined,
      projectUuidEntity: queries.project || undefined,
      familiesUuidEntity: queries.families || undefined,
      nature: RelationNatureEnum.convertToApiValue.getValue(queries.nature),
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(
    families: string[],
    searchText: string,
    sort: SortFilter<ObjectFlowSummaryData>,
    contents?: string[],
    project?: string,
    dateFilter?: DateFilterData,
  ): QueryRequest {
    return {
      ...initializeQueries<ObjectFlowSummaryData>(sort),
      textSearch: searchText || undefined,
      families: (families || []).length ? families : undefined,
      contents: (contents || []).length ? contents : undefined,
      project: project || undefined,
      dateFilter: dateFilter || undefined,
    };
  }

  export function initializeFilterQueries(page: number, searchText?: string): QueryRequest {
    return {
      ...initializeQueries<ObjectFlowSummaryData>(),
      page,
      textSearch: searchText || undefined,
      nature: RelationNatureEnum.Content,
    };
  }
}
