import { FrequencyNatureEnum, FrequencyQuarterEnum, FrequencyTypeEnum, FrequencyWeekDayEnum } from '../../enums';

export interface ScheduleRRuleData {
  frequency: FrequencyNatureEnum;
  byWeekDay?: FrequencyWeekDayEnum;
  byWeekDays?: FrequencyWeekDayEnum[];
  interval?: number;
  count?: number;
  byMonthDay?: number;
  byMonth?: number;
  quarter?: FrequencyQuarterEnum;
  frequencyType?: FrequencyTypeEnum;
}

export namespace ScheduleRRuleData {
  export const defaultData = { frequency: FrequencyNatureEnum.Yearly };
  export const initialValue = {
    frequencyType: FrequencyTypeEnum.Complex,
    frequency: FrequencyNatureEnum.Yearly,
  };
}
