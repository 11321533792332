import { SfxMap } from '../../utils/enum-utils';

import { FlowCustomDetailsResponseDTO } from '../../generated/models/models';

export enum FlowStatusEnum {
  Enabled = 1,
  Disabled = 2,
  Warning = 3,
}

export namespace FlowStatusEnum {
  export const convertFromApiValue = new SfxMap<FlowCustomDetailsResponseDTO.ModificationStatusEnum, FlowStatusEnum>(
    [
      [FlowCustomDetailsResponseDTO.ModificationStatusEnum.ENABLED, FlowStatusEnum.Enabled],
      [FlowCustomDetailsResponseDTO.ModificationStatusEnum.DISABLED, FlowStatusEnum.Disabled],
      [FlowCustomDetailsResponseDTO.ModificationStatusEnum.WARNING, FlowStatusEnum.Warning],
    ],
    FlowStatusEnum.Enabled,
  );
}
