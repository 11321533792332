import { CustomFileDetailsResponseDTO } from '../../generated/models/customFileDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum CustomFileNatureEnum {
  Importation = 1,
  Exportation,
}

export namespace CustomFileNatureEnum {
  const values: CustomFileNatureEnum[] = [CustomFileNatureEnum.Importation, CustomFileNatureEnum.Exportation];

  export const convertFromApiValue: SfxMap<CustomFileDetailsResponseDTO.NatureEnum, CustomFileNatureEnum> = new SfxMap<
    CustomFileDetailsResponseDTO.NatureEnum,
    CustomFileNatureEnum
  >([
    [CustomFileDetailsResponseDTO.NatureEnum.IN, CustomFileNatureEnum.Importation],
    [CustomFileDetailsResponseDTO.NatureEnum.OUT, CustomFileNatureEnum.Exportation],
  ]);

  export const convertToApiValue: SfxMap<CustomFileNatureEnum, CustomFileDetailsResponseDTO.NatureEnum> = new SfxMap<CustomFileNatureEnum, CustomFileDetailsResponseDTO.NatureEnum>(
    [
      [CustomFileNatureEnum.Importation, CustomFileDetailsResponseDTO.NatureEnum.IN],
      [CustomFileNatureEnum.Exportation, CustomFileDetailsResponseDTO.NatureEnum.OUT],
    ],
  );

  export const convertToParamsValue: SfxMap<CustomFileNatureEnum, string> = new SfxMap<CustomFileNatureEnum, string>([
    [CustomFileNatureEnum.Importation, 'importation'],
    [CustomFileNatureEnum.Exportation, 'exportation'],
  ]);

  export const convertFromParamsValue: SfxMap<string, CustomFileNatureEnum> = new SfxMap<string, CustomFileNatureEnum>([
    ['importation', CustomFileNatureEnum.Importation],
    ['exportation', CustomFileNatureEnum.Exportation],
  ]);

  const key = 'customFiles.natures.';
  export const toString: SfxMap<CustomFileNatureEnum, string> = new SfxMap<CustomFileNatureEnum, string>([
    [CustomFileNatureEnum.Importation, key + 'importation'],
    [CustomFileNatureEnum.Exportation, key + 'exportation'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<CustomFileNatureEnum>[]> =>
    of(
      values.map(nature => ({
        value: nature,
        title: toString.getValue(nature),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<CustomFileNatureEnum>, item: CustomFileNatureEnum) => node.value === item;
}
