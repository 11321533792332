import { createReducer, on, Action } from '@ngrx/store';
import { BlockMultiPrHistoryData, LazyLoadResponse, SignatureDetailsData } from '../../../../../../../../core/models';
import * as fromActions from './block-multi-pr-dialog.actions';

export interface BlockMultiPrDialogState {
  signatureDetails: SignatureDetailsData;
  history: LazyLoadResponse<BlockMultiPrHistoryData[]>;
  signatureLoading: boolean;
  saveLoading: boolean;
  reset: boolean;
}

export const blockMultiPrDialogInitialState = (): BlockMultiPrDialogState => ({
  signatureDetails: {} as SignatureDetailsData,
  history: {} as LazyLoadResponse<BlockMultiPrHistoryData[]>,
  signatureLoading: false,
  saveLoading: false,
  reset: false,
});

const _blockMultiPrDialogReducer = createReducer<BlockMultiPrDialogState>(
  blockMultiPrDialogInitialState(),
  on(fromActions.loadSignatureDetails, (state): BlockMultiPrDialogState => ({ ...state, signatureLoading: true })),
  on(fromActions.loadSignatureDetailsSuccess, (state, { signatureDetails }): BlockMultiPrDialogState => ({ ...state, signatureDetails, signatureLoading: false })),
  on(fromActions.loadSignatureDetailsFail, (state): BlockMultiPrDialogState => ({ ...state, signatureLoading: false })),
  on(fromActions.updateSignatory, (state): BlockMultiPrDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateSignatorySuccess, fromActions.updateSignatureFail, (state): BlockMultiPrDialogState => ({ ...state, saveLoading: false })),
  on(
    fromActions.loadMultiPrHistorySuccess,
    (state, { multiPrEvents, reset }): BlockMultiPrDialogState => ({
      ...state,
      history: reset ? multiPrEvents : { ...state.history, payload: [...state.history.payload, ...multiPrEvents.payload] },
      reset,
    }),
  ),
);

export function blocMultiPrDialogReducer(state: BlockMultiPrDialogState | undefined, action: Action): BlockMultiPrDialogState {
  return _blockMultiPrDialogReducer(state, action);
}
