import { FlowSummaryData } from '../flows/flow-summary';
import { ContributorSummaryData } from '../contributors/contributor-summary-data';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { DefaultCategoryDeleteResponseDTO } from '../../generated/models/models';
import { CategoryDeleteNatureEnum } from '../../enums';
import { DefaultCategorySummaryData } from './default-category-summary-data';

export interface DeleteDefaultCategoryResponse {
  success: boolean;
  nature: CategoryDeleteNatureEnum;
  flowLinks: FlowSummaryData[];
  listLinks: FlowSummaryData[];
  documentLinks: DocumentFlowSummaryData[];
  userLinks: ContributorSummaryData[];
  childLinks: DefaultCategorySummaryData[];
}

export namespace DeleteDefaultCategoryResponse {
  export function mapFromApiValue(data: DefaultCategoryDeleteResponseDTO): DeleteDefaultCategoryResponse {
    return {
      success: data.success,
      nature: CategoryDeleteNatureEnum.convertFromApiValue.getValue(data.nature),
      flowLinks: data.flowLinks && data.flowLinks.map(flow => FlowSummaryData.mapFromApiValue(flow)),
      listLinks: data.listLinks && data.listLinks.map(flow => FlowSummaryData.mapFromApiValue(flow)),
      documentLinks: data.documentLinks && data.documentLinks.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
      userLinks: data.userLinks && data.userLinks.map(user => ({ name: user.name } as ContributorSummaryData)), // FIXME: should be typed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      childLinks: data.childsLinks && data.childsLinks.map((childLink: any) => DefaultCategorySummaryData.mapFromApiValue(childLink)),
    };
  }

  export function mapToConflictData(response: DeleteDefaultCategoryResponse): ConflictData {
    switch (response.nature) {
      case CategoryDeleteNatureEnum.Flow:
        return { title: 'global.entityConflict.hint.category.flow', links: response.flowLinks.map(flow => FlowSummaryData.toString(flow)) };

      case CategoryDeleteNatureEnum.List:
        return { title: 'global.entityConflict.hint.category.list', links: response.listLinks.map(list => FlowSummaryData.toString(list)) };

      case CategoryDeleteNatureEnum.Hierarchy:
        return { title: 'global.entityConflict.hint.category.team', links: response.userLinks.map(user => ContributorSummaryData.toString(user)) };

      case CategoryDeleteNatureEnum.Document:
        return {
          title: 'global.entityConflict.hint.category.document',
          links: response.documentLinks.map(document => DocumentFlowSummaryData.toString(document)),
        };

      case CategoryDeleteNatureEnum.Child:
        return { title: 'global.entityConflict.hint.category.child', links: response.childLinks.map(child => DefaultCategorySummaryData.toString(child)) };

      default:
        return null;
    }
  }
}
