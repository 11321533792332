import { SfxMap } from '../../utils/enum-utils';
import { IconData } from '../../../modules/shared/interfaces/icon-data';

export enum FlowReferentialModalTabEnum {
  General = 'general',
  CustomField = 'customField',
  Options = 'options',
}

export namespace FlowReferentialModalTabEnum {
  export function getValues(): string[] {
    return [FlowReferentialModalTabEnum.General, FlowReferentialModalTabEnum.CustomField, FlowReferentialModalTabEnum.Options];
  }

  const key = 'referentialFlows.modals.edit.';
  export const toString: SfxMap<FlowReferentialModalTabEnum, string> = new SfxMap<FlowReferentialModalTabEnum, string>([
    [FlowReferentialModalTabEnum.General, key + 'general'],
    [FlowReferentialModalTabEnum.CustomField, key + 'customFields'],
    [FlowReferentialModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<FlowReferentialModalTabEnum, IconData> = new SfxMap<FlowReferentialModalTabEnum, IconData>([
    [FlowReferentialModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [FlowReferentialModalTabEnum.CustomField, { name: 'paint-roller', prefix: 'fas' }],
    [FlowReferentialModalTabEnum.Options, { name: 'file-invoice', prefix: 'fas' }],
  ]);
}
