/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StatusBlocResponseDTO {
  status: StatusBlocResponseDTO.StatusEnum;
  count: number;
  flowsCount: number;
}
export namespace StatusBlocResponseDTO {
  export type StatusEnum = 'REDACTION' | 'VERIFICATION' | 'APROBATION' | 'VALIDE';
  export const StatusEnum = {
    REDACTION: 'REDACTION' as StatusEnum,
    VERIFICATION: 'VERIFICATION' as StatusEnum,
    APROBATION: 'APROBATION' as StatusEnum,
    VALIDE: 'VALIDE' as StatusEnum,
  };
}
