/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NavigationFlowCreateRequestDTO {
  currentStepUuidEntity: string;
  linkedStepUuidEntity: string;
  linkedPhaseUuidEntity: string;
  description?: string;
  natureStyle: NavigationFlowCreateRequestDTO.NatureStyleEnum;
}
export namespace NavigationFlowCreateRequestDTO {
  export type NatureStyleEnum = 'TRACE' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
  export const NatureStyleEnum = {
    TRACE: 'TRACE' as NatureStyleEnum,
    INFO: 'INFO' as NatureStyleEnum,
    WARN: 'WARN' as NatureStyleEnum,
    ERROR: 'ERROR' as NatureStyleEnum,
    SUCCESS: 'SUCCESS' as NatureStyleEnum,
  };
}
