import { ApplicationEventDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ApplicationEventActionEnum {
  Create = 1,
  Update = 2,
  Delete = 3,
  View = 4,
  Import = 5,
  Export = 6,
  ImportInProgress = 8,
  ImportError = 9,
  DeactivateSignature = 10,
}

export namespace ApplicationEventActionEnum {
  export const convertFromApiValue = new SfxMap<ApplicationEventDTO.NatureActionEnum, ApplicationEventActionEnum>([
    [ApplicationEventDTO.NatureActionEnum.CREATE, ApplicationEventActionEnum.Create],
    [ApplicationEventDTO.NatureActionEnum.UPDATE, ApplicationEventActionEnum.Update],
    [ApplicationEventDTO.NatureActionEnum.DELETE, ApplicationEventActionEnum.Delete],
    [ApplicationEventDTO.NatureActionEnum.VIEW, ApplicationEventActionEnum.View],
    [ApplicationEventDTO.NatureActionEnum.IMPORT, ApplicationEventActionEnum.Import],
    [ApplicationEventDTO.NatureActionEnum.EXPORT, ApplicationEventActionEnum.Export],
    [ApplicationEventDTO.NatureActionEnum.IMPORTINPROGRESS, ApplicationEventActionEnum.ImportInProgress],
    [ApplicationEventDTO.NatureActionEnum.IMPORTERROR, ApplicationEventActionEnum.ImportError],
    [ApplicationEventDTO.NatureActionEnum.DEACTIVATESIGNATURE, ApplicationEventActionEnum.DeactivateSignature],
  ]);

  const key = 'projects.applicationEvent.types.';
  export const toString = new SfxMap<ApplicationEventActionEnum, string>([
    [ApplicationEventActionEnum.Create, key + 'create'],
    [ApplicationEventActionEnum.Delete, key + 'delete'],
    [ApplicationEventActionEnum.Update, key + 'update'],
    [ApplicationEventActionEnum.View, key + 'view'],
    [ApplicationEventActionEnum.Export, key + 'export'],
    [ApplicationEventActionEnum.Import, key + 'import'],
    [ApplicationEventActionEnum.ImportInProgress, key + 'importInProgress'],
    [ApplicationEventActionEnum.ImportError, key + 'importError'],
    [ApplicationEventActionEnum.DeactivateSignature, key + 'deactivateSignature'],
  ]);

  export const toVerb = (pastTense?: boolean, dsi?: boolean): SfxMap<ApplicationEventActionEnum, string> => {
    const verbKey = `flowApp.applicationEvent.actions.${pastTense ? 'pastTense.' : ''}`;

    return new SfxMap<ApplicationEventActionEnum, string>([
      [ApplicationEventActionEnum.Create, verbKey + `${dsi ? 'inquire' : 'create'}`],
      [ApplicationEventActionEnum.Delete, verbKey + 'delete'],
      [ApplicationEventActionEnum.Update, verbKey + 'update'],
      [ApplicationEventActionEnum.View, verbKey + 'view'],
      [ApplicationEventActionEnum.Export, verbKey + 'export'],
      [ApplicationEventActionEnum.Import, verbKey + 'import'],
    ]);
  };

  export const color: SfxMap<ApplicationEventActionEnum, string> = new SfxMap<ApplicationEventActionEnum, string>(
    [[ApplicationEventActionEnum.ImportError, 'sfx-chip-project']],
    'sfx-chip-primary',
  );
}
