import { Observable, of } from 'rxjs';

import { ExternalApiConsumerSummaryResponseDTO } from '../../generated/models/models';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum LevelAccessApiEnum {
  Basic = 1,
  Advanced,
  Expert,
}
export namespace LevelAccessApiEnum {
  const values: LevelAccessApiEnum[] = [LevelAccessApiEnum.Basic, LevelAccessApiEnum.Advanced, LevelAccessApiEnum.Expert];

  export const convertFromParamsValue = new SfxMap<string, LevelAccessApiEnum>([
    ['basic', LevelAccessApiEnum.Basic],
    ['advanced', LevelAccessApiEnum.Advanced],
    ['expert', LevelAccessApiEnum.Expert],
  ]);

  export const convertToParamsValue = new SfxMap<LevelAccessApiEnum, string>([
    [LevelAccessApiEnum.Basic, 'basic'],
    [LevelAccessApiEnum.Advanced, 'advanced'],
    [LevelAccessApiEnum.Expert, 'expert'],
  ]);

  export const mapFromApiValue = new SfxMap<ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum, LevelAccessApiEnum>([
    [ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.BASEACCESS, LevelAccessApiEnum.Basic],
    [ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.ADVANCEDACCESS, LevelAccessApiEnum.Advanced],
    [ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.PREMIUMACCESS, LevelAccessApiEnum.Expert],
  ]);

  export const mapToApiValue = new SfxMap<LevelAccessApiEnum, ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum>([
    [LevelAccessApiEnum.Basic, ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.BASEACCESS],
    [LevelAccessApiEnum.Advanced, ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.ADVANCEDACCESS],
    [LevelAccessApiEnum.Expert, ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum.PREMIUMACCESS],
  ]);

  const key = 'accessApi.v2.filters.';
  export const toString = new SfxMap<LevelAccessApiEnum, string>([
    [LevelAccessApiEnum.Basic, key + 'basic'],
    [LevelAccessApiEnum.Advanced, key + 'advanced'],
    [LevelAccessApiEnum.Expert, key + 'expert'],
  ]);

  export const comparePredicate = (node: SingleSelectItemData<LevelAccessApiEnum>, item: LevelAccessApiEnum) => node.value === item;
  export const itemsResolver = (): Observable<SingleSelectItemData<LevelAccessApiEnum>[]> => of(values.map(value => ({ value, title: toString.getValue(value) })));
}
