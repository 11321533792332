import { TooltipPositionType } from '../../../modules/shared/interfaces/tooltip-data';
import { SfxMap } from '../../utils/enum-utils';

export enum KpiMetricEnum {
  EntityReferential = 1,
  EntityProject = 2,
  Flows = 4,
  FlowReferential = 3,
  FlowEmm = 13,
  FlowSpecific = 14,
  FlowGeneric = 20,
  FlowGroup = 15,
  FlowForm = 16,
  FormLibrary = 5,
  FormLocal = 17,
  FormResponseLocal = 21,
  FormGeneric = 18,
  FormModel = 19,
  FormViaTablet = 6,
  SignatureRealized = 7,
  FormResponse = 8,
  UserActive = 9,
  Users = 10,
  OperatorActive = 11,
  Operators = 12,
  WebUsers = 22,
  WebUsersActive = 23,
  MobileUsers = 24,
  MobileUsersActive = 25,
  MobileUsersActiveUnregistered = 26,
  DeviceUsers = 27,
  DeviceActive = 28,
}

export namespace KpiMetricEnum {
  const key = 'settingsKpi.legends.';
  export const toString: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.EntityReferential, key + 'entityReferential'],
    [KpiMetricEnum.FlowReferential, key + 'flowReferential'],
    [KpiMetricEnum.FormLibrary, key + 'formLibrary'],

    [KpiMetricEnum.EntityProject, key + 'entityProject'],
    [KpiMetricEnum.Flows, key + 'flows'],
    [KpiMetricEnum.FormLocal, key + 'formLocal'],

    [KpiMetricEnum.SignatureRealized, key + 'signatureRealized'],
    [KpiMetricEnum.FormResponse, key + 'formResponse'],

    [KpiMetricEnum.FormGeneric, key + 'formGeneric'],
    [KpiMetricEnum.FormModel, key + 'formModel'],
    [KpiMetricEnum.FormResponseLocal, key + 'formResponseLocal'],
    [KpiMetricEnum.FormViaTablet, key + 'formViaTablet'],

    [KpiMetricEnum.FlowEmm, key + 'flowEmm'],
    [KpiMetricEnum.FlowSpecific, key + 'flowSpecific'],
    [KpiMetricEnum.FlowGeneric, key + 'flowGeneric'],
    [KpiMetricEnum.FlowGroup, key + 'flowGroup'],
    [KpiMetricEnum.FlowForm, key + 'flowForm'],

    [KpiMetricEnum.UserActive, key + 'userActive'],
    [KpiMetricEnum.Users, key + 'users'],
    [KpiMetricEnum.OperatorActive, key + 'operatorActive'],
    [KpiMetricEnum.Operators, key + 'operators'],

    [KpiMetricEnum.WebUsers, key + 'webUsers'],
    [KpiMetricEnum.WebUsersActive, key + 'webUsersActive'],
    [KpiMetricEnum.MobileUsers, key + 'mobileUsers'],
    [KpiMetricEnum.MobileUsersActive, key + 'mobileUsersActive'],
    [KpiMetricEnum.MobileUsersActiveUnregistered, key + 'mobileUsersActiveUnregistered'],
    [KpiMetricEnum.DeviceUsers, key + 'deviceUsers'],
    [KpiMetricEnum.DeviceActive, key + 'deviceActive'],
  ]);

  export const color: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.EntityReferential, '#A876C4'],
    [KpiMetricEnum.FlowReferential, '#3A3D93'],
    [KpiMetricEnum.FormLibrary, '#416F99'],

    [KpiMetricEnum.EntityProject, '#D6722E'],
    [KpiMetricEnum.Flows, '#BEC489'],
    [KpiMetricEnum.FormLocal, '#AF6FB0'],

    [KpiMetricEnum.SignatureRealized, '#AC0907'],
    [KpiMetricEnum.FormResponse, '#3A3D93'],

    [KpiMetricEnum.FormGeneric, '#ee965d'],
    [KpiMetricEnum.FormModel, '#7f9bd7'],
    [KpiMetricEnum.FormResponseLocal, '#6fafa0'],
    [KpiMetricEnum.FormViaTablet, '#17224d'],

    [KpiMetricEnum.FlowEmm, '#1f5086'],
    [KpiMetricEnum.FlowSpecific, '#bec489'],
    [KpiMetricEnum.FlowGeneric, '#3a3d93'],
    [KpiMetricEnum.FlowGroup, '#87a1bc'],
    [KpiMetricEnum.FlowForm, '#000000'],

    [KpiMetricEnum.UserActive, '#1F5086'],
    [KpiMetricEnum.Users, '#80A0BD'],
    [KpiMetricEnum.OperatorActive, '#D76E13'],
    [KpiMetricEnum.Operators, '#F7E2D0'],

    [KpiMetricEnum.WebUsers, '#80A0BD'],
    [KpiMetricEnum.WebUsersActive, '#1F5086'],
    [KpiMetricEnum.MobileUsers, '#F3AD3D'],
    [KpiMetricEnum.MobileUsersActive, '#D76E13'],
    [KpiMetricEnum.MobileUsersActiveUnregistered, '#E1C32A'],
    [KpiMetricEnum.DeviceUsers, '#A876C4'],
    [KpiMetricEnum.DeviceActive, '#008109'],
  ]);

  export const bgColor: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.EntityReferential, '#D5BDE2'],
    [KpiMetricEnum.FlowReferential, '#A7A9D9'],
    [KpiMetricEnum.FormLibrary, '#BFD5E2'],

    [KpiMetricEnum.EntityProject, '#F4CDAC'],
    [KpiMetricEnum.Flows, '#E3E4CA'],
    [KpiMetricEnum.FormLocal, '#D5BDE2'],

    [KpiMetricEnum.SignatureRealized, '#EECECD'],
    [KpiMetricEnum.FormResponse, '#A7A9D9'],

    [KpiMetricEnum.FlowEmm, '#87a1bc'],
  ]);

  // 2-5-7
  export const toSize: SfxMap<KpiMetricEnum, number> = new SfxMap<KpiMetricEnum, number>(
    [
      [KpiMetricEnum.FormGeneric, 7],
      [KpiMetricEnum.FormModel, 7],
      [KpiMetricEnum.FormResponseLocal, 7],
      [KpiMetricEnum.FlowSpecific, 3],
      [KpiMetricEnum.FlowGeneric, 3],
      [KpiMetricEnum.FlowGroup, 3],
      [KpiMetricEnum.FlowForm, 3],

      [KpiMetricEnum.WebUsers, 2],
      [KpiMetricEnum.WebUsersActive, 2],
      [KpiMetricEnum.MobileUsers, 2],
      [KpiMetricEnum.MobileUsersActive, 2],
      [KpiMetricEnum.MobileUsersActiveUnregistered, 2],
      [KpiMetricEnum.DeviceUsers, 2],
      [KpiMetricEnum.DeviceActive, 2],
    ],
    5,
  );

  export const toOrder: SfxMap<KpiMetricEnum, number> = new SfxMap<KpiMetricEnum, number>([
    [KpiMetricEnum.FormGeneric, 0],
    [KpiMetricEnum.FormModel, 0],
    [KpiMetricEnum.FormResponseLocal, 0],
    [KpiMetricEnum.FormViaTablet, 1],
  ]);

  export const toChartType: SfxMap<KpiMetricEnum, 'line' | 'bar'> = new SfxMap<KpiMetricEnum, 'line' | 'bar'>(
    [
      [KpiMetricEnum.UserActive, 'bar'],
      [KpiMetricEnum.Users, 'bar'],
      [KpiMetricEnum.OperatorActive, 'bar'],
      [KpiMetricEnum.Operators, 'bar'],
      [KpiMetricEnum.FormViaTablet, 'bar'],
    ],
    'line',
  );

  export const toStack: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.UserActive, 'xAxes1'],
    [KpiMetricEnum.Users, 'xAxes1'],
  ]);

  export const yAxis: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.FormGeneric, 'left'],
    [KpiMetricEnum.FormModel, 'left'],
    [KpiMetricEnum.FormResponseLocal, 'left'],
    [KpiMetricEnum.FormViaTablet, 'right'],
  ]);

  export const legendTooltip: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>([
    [KpiMetricEnum.EntityReferential, 'settingsKpi.tooltip.preparationEntity'],
    [KpiMetricEnum.FlowReferential, 'settingsKpi.tooltip.preparationFlow'],
    [KpiMetricEnum.FormLibrary, 'settingsKpi.tooltip.preparationForm'],

    [KpiMetricEnum.EntityProject, 'settingsKpi.tooltip.officeEntity'],
    [KpiMetricEnum.Flows, 'settingsKpi.tooltip.officeFlow'],
    [KpiMetricEnum.FormLocal, 'settingsKpi.tooltip.officeForm'],

    [KpiMetricEnum.SignatureRealized, 'settingsKpi.tooltip.signatureRealized'],

    [KpiMetricEnum.UserActive, 'settingsKpi.legends.userActive.tooltip'],
    [KpiMetricEnum.Users, 'settingsKpi.legends.users.tooltip'],
    [KpiMetricEnum.OperatorActive, 'settingsKpi.legends.operatorActive.tooltip'],
    [KpiMetricEnum.Operators, 'settingsKpi.legends.operators.tooltip'],

    [KpiMetricEnum.WebUsers, 'settingsKpi.tooltip.webUsers'],
    [KpiMetricEnum.WebUsersActive, 'settingsKpi.tooltip.webUsersActive'],
    [KpiMetricEnum.MobileUsers, 'settingsKpi.tooltip.mobileUsers'],
    [KpiMetricEnum.MobileUsersActive, 'settingsKpi.tooltip.mobileUsersActive'],
    [KpiMetricEnum.MobileUsersActiveUnregistered, 'settingsKpi.tooltip.mobileUsersActiveUnregistered'],
    [KpiMetricEnum.DeviceUsers, 'settingsKpi.tooltip.deviceUsers'],
    [KpiMetricEnum.DeviceActive, 'settingsKpi.tooltip.deviceActive'],
  ]);

  export const legendTooltipCss: SfxMap<KpiMetricEnum, string> = new SfxMap<KpiMetricEnum, string>(
    [
      [KpiMetricEnum.FormLocal, 'sfx-tooltip-bottom-right-3'],
      [KpiMetricEnum.FormLibrary, 'sfx-tooltip-bottom-right-1'],
      [KpiMetricEnum.DeviceActive, 'sfx-tooltip-top-left'],
    ],
    'sfx-tooltip-left',
  );

  export const legendTooltipPosition: SfxMap<KpiMetricEnum, TooltipPositionType> = new SfxMap<KpiMetricEnum, TooltipPositionType>(
    [
      [KpiMetricEnum.WebUsers, 'top'],
      [KpiMetricEnum.WebUsersActive, 'top'],
      [KpiMetricEnum.MobileUsers, 'top'],
      [KpiMetricEnum.MobileUsersActive, 'top'],
      [KpiMetricEnum.MobileUsersActiveUnregistered, 'top'],
      [KpiMetricEnum.DeviceUsers, 'top'],
      [KpiMetricEnum.DeviceActive, 'left'],
    ],
    'bottom',
  );
}
