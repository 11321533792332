import { CustomFileDeleteResponseDTO } from '../../generated/models/models';

import { CustomCellSummaryData } from '../custom-cell/custom-cell-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';

export interface CustomFileDeleteResponseData {
  success: boolean;
  customCellLinks: CustomCellSummaryData[];
}

export namespace CustomFileDeleteResponseData {
  export function mapFormApiValue(data: CustomFileDeleteResponseDTO): CustomFileDeleteResponseData {
    return {
      success: data.success,
      customCellLinks: data.customCellLinks && data.customCellLinks.map(customCell => CustomCellSummaryData.mapFromApiValue(customCell)),
    };
  }

  export function mapToConflictData(customFileDeleteResponse: CustomFileDeleteResponseData, customFileName: string): ConflictData {
    if (customFileDeleteResponse.customCellLinks && customFileDeleteResponse.customCellLinks.length) {
      return {
        title: 'global.entityConflict.hint.customFile.customCell',
        links: customFileDeleteResponse.customCellLinks.map(customCell => customCell.name),
        modalTitle: 'global.entityConflict.hint.customFile.modalTitle',
        modalFooter: 'global.entityConflict.hint.customFile.modalFooter',
        tooltipParams: { customFileName },
      };
    }
  }
}
