import { ValidationNatureEnum } from '../../enums';
import { RubricOptionDetailsResponseDTO } from '../../generated/models/models';

export interface OptionValidationData {
  nature: ValidationNatureEnum;
  validationMessage: string;
  blockValueToLimit: boolean;
  min: string;
  max: string;
}

export namespace OptionValidationData {
  export function mapFromApiValue(option: RubricOptionDetailsResponseDTO): OptionValidationData {
    return {
      min: option.valueOne,
      max: option.valueTwo,
      validationMessage: option.validationAlertText,
      nature: ValidationNatureEnum.mapFromApiValue.getValue(option.natureValidation),
      blockValueToLimit: option.checkedBlockingControl,
    };
  }
}
