import { ElementEmmListDetailsResponseDTO } from '../../generated/models/models';

import { EntityLightData } from '../utils/entity-light-data';
import { ApplicationFileLightData } from '../application-file/application-file';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface EmmSummaryData {
  uuidEntity: string;
  labelFamilyTeam: LabelFamilyLightData;
  name: string;
  applicability: EntityLightData;
  refInt: string;
  referenceFile: ApplicationFileLightData;
  supplier: string;
  comment: string;
  displayComment: boolean;
  validityDate: string;
  certificate: ApplicationFileLightData;
  organization: string;
}

export namespace EmmSummaryData {
  export function mapFromApiValue(list: ElementEmmListDetailsResponseDTO): EmmSummaryData {
    return {
      uuidEntity: list.uuidEntity,
      labelFamilyTeam: list.labelFamily ? LabelFamilyLightData.mapFromApiValue(list.labelFamily) : LabelFamilyLightData.defaultData,
      name: list.name,
      applicability: list.applicability ? LabelApplicabilityLightData.mapToEntityLightData(list.applicability) : LabelApplicabilityLightData.defaultData,
      refInt: list.reference,
      referenceFile: list.referenceFile && ApplicationFileLightData.mapFromApiValue(list.referenceFile),
      supplier: list.provider,
      comment: list.commentaire,
      displayComment: list.displayComment,
      validityDate: DateUtils.toDateFormat(list.validityDate),
      certificate: list.certificate && ApplicationFileLightData.mapFromApiValue(list.certificate),
      organization: list.organisme,
    };
  }
}
