import { TrashSummaryData } from '../../models/archive/trash-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum TrashColumnEnum {
  Type = 'type',
  Name = 'name',
  ArchiveDate = 'archiveDate',
  Action = 'action',
  ArchivedBy = 'archivedBy',
}

export namespace TrashColumnEnum {
  export type trashSortField = keyof TrashSummaryData;

  export const allColumns = [TrashColumnEnum.Type, TrashColumnEnum.Name, TrashColumnEnum.ArchivedBy, TrashColumnEnum.ArchiveDate, TrashColumnEnum.Action];

  const key = 'trash.table.header.';
  export const toString: SfxMap<TrashColumnEnum, string> = new SfxMap<TrashColumnEnum, string>([
    [TrashColumnEnum.Type, key + 'type'],
    [TrashColumnEnum.Name, key + 'name'],
    [TrashColumnEnum.ArchiveDate, key + 'archiveDate'],
    [TrashColumnEnum.ArchivedBy, key + 'archivedBy'],
    [TrashColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<TrashColumnEnum, trashSortField> = new SfxMap<TrashColumnEnum, trashSortField>([
    [TrashColumnEnum.Type, 'type'],
    [TrashColumnEnum.Name, 'name'],
    [TrashColumnEnum.ArchiveDate, 'archiveDate'],
  ]);
}
