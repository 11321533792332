import { MoveEnum } from '../../enums';
import { RubricDuplicateRequestDTO } from '../../generated/models/rubricDuplicateRequestDTO';

export namespace RubricToDuplicateData {
  export function mapToRequestApiValue(rubricUuidEntity: string): RubricDuplicateRequestDTO {
    return {
      siblingUuidEntity: rubricUuidEntity,
      relativePosition: MoveEnum.convertToApiValue.getValue(MoveEnum.After),
    };
  }
}
