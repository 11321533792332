import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

import { PermissionActionEnum } from '../permission-action.enum';
import { UserStatusEnum } from '../user/user-status.enum';
import { UserTypeEnum } from '../user/user-type.enum';

export enum LibraryContributorActionEnum {
  Skill = 1,
  QuickConnection = 2,
  Delete = 3,
  Unblock = 4,
  Restore = 5,
  Deactivate = 6,
  ResendEmail = 7,
  MoveApplicability = 8,
  ReactivateOperatorOrDevice = 9,
  Properties = 10,
  ViewQrCodeId = 11,
}

export namespace LibraryContributorActionEnum {
  const key = 'users.table.actions.';

  export const toString: SfxMap<LibraryContributorActionEnum, string> = new SfxMap<LibraryContributorActionEnum, string>([
    [LibraryContributorActionEnum.Skill, key + 'skill'],
    [LibraryContributorActionEnum.QuickConnection, key + 'quickConnection'],
    [LibraryContributorActionEnum.Delete, key + 'delete'],
    [LibraryContributorActionEnum.Unblock, key + 'unblock'],
    [LibraryContributorActionEnum.Restore, key + 'restore'],
    [LibraryContributorActionEnum.Deactivate, key + 'deactivate'],
    [LibraryContributorActionEnum.ResendEmail, key + 'reactivate'],
    [LibraryContributorActionEnum.ReactivateOperatorOrDevice, key + 'reactivate'],
    [LibraryContributorActionEnum.MoveApplicability, key + 'moveApplicability'],
    [LibraryContributorActionEnum.Properties, key + 'properties'],
    [LibraryContributorActionEnum.ViewQrCodeId, key + 'viewQrCode'],
  ]);

  export const iconData: SfxMap<LibraryContributorActionEnum, IconData> = new SfxMap<LibraryContributorActionEnum, IconData>([
    [LibraryContributorActionEnum.Skill, { name: 'graduation-cap', prefix: 'fas' }],
    [LibraryContributorActionEnum.QuickConnection, { name: 'qrcode', prefix: 'fas' }],
    [LibraryContributorActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [LibraryContributorActionEnum.Unblock, { name: 'unlock-alt', prefix: 'fas' }],
    [LibraryContributorActionEnum.Restore, { name: 'sync', prefix: 'fas' }],
    [LibraryContributorActionEnum.Deactivate, { name: 'users', prefix: 'fas', iconClassName: 'users-custom-icon' }],
    [LibraryContributorActionEnum.ResendEmail, { name: 'users', prefix: 'fas' }],
    [LibraryContributorActionEnum.ReactivateOperatorOrDevice, { name: 'users', prefix: 'fas' }],
    [LibraryContributorActionEnum.MoveApplicability, { name: 'plane', prefix: 'fas' }],
    [LibraryContributorActionEnum.Properties, { name: 'info', prefix: 'fas' }],
    [LibraryContributorActionEnum.ViewQrCodeId, { name: 'lock', prefix: 'fas' }],
  ]);

  export const getActionMenu = (type: UserTypeEnum, status: UserStatusEnum, isSsoOnly: boolean): ActionSelectItemData<LibraryContributorActionEnum>[] =>
    compact([
      status !== UserStatusEnum.Deleted && mapToActionMenuItem(LibraryContributorActionEnum.Skill),
      UserStatusEnum.Active === status && type !== UserTypeEnum.Operator && !isSsoOnly && mapToActionMenuItem(LibraryContributorActionEnum.QuickConnection),
      status !== UserStatusEnum.Deleted && mapToActionMenuItem(LibraryContributorActionEnum.MoveApplicability),
      status === UserStatusEnum.Active && mapToActionMenuItem(LibraryContributorActionEnum.Deactivate, PermissionActionEnum.Edit),
      mapToActionMenuItem(LibraryContributorActionEnum.Properties, PermissionActionEnum.Read),
      mapToActionMenuItem(LibraryContributorActionEnum.ViewQrCodeId, PermissionActionEnum.Export),
      status !== UserStatusEnum.Deleted && mapToActionMenuItem(LibraryContributorActionEnum.Delete, PermissionActionEnum.Delete),
      type === UserTypeEnum.User && status === UserStatusEnum.Blocked && mapToActionMenuItem(LibraryContributorActionEnum.Unblock, PermissionActionEnum.Unlock),
      status === UserStatusEnum.Deleted && mapToActionMenuItem(LibraryContributorActionEnum.Restore, PermissionActionEnum.Restore),
      type === UserTypeEnum.User && status === UserStatusEnum.Inactive && mapToActionMenuItem(LibraryContributorActionEnum.ResendEmail, PermissionActionEnum.Edit),
      type === UserTypeEnum.Operator &&
        status === UserStatusEnum.Inactive &&
        mapToActionMenuItem(LibraryContributorActionEnum.ReactivateOperatorOrDevice, PermissionActionEnum.Edit),
    ]);

  export function mapToActionMenuItem(
    contributorActionMenu: LibraryContributorActionEnum,
    permissionAction?: PermissionActionEnum,
  ): ActionSelectItemData<LibraryContributorActionEnum> {
    return {
      name: LibraryContributorActionEnum.toString.getValue(contributorActionMenu),
      permissionAction,
      value: contributorActionMenu,
      icon: LibraryContributorActionEnum.iconData.getValue(contributorActionMenu),
    };
  }
}
