import { BackupResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum BackupColumnEnum {
  Rapporteur = 'rapporteur',
  Date = 'date',
  UserAction = 'userAction',
  Actions = 'actions',
}

export namespace BackupColumnEnum {
  export type projectSortField = keyof BackupResponseDTO;

  export const allColumns = [BackupColumnEnum.Rapporteur, BackupColumnEnum.Date, BackupColumnEnum.UserAction, BackupColumnEnum.Actions];

  export const toString: SfxMap<BackupColumnEnum, string> = new SfxMap<BackupColumnEnum, string>(
    [
      [BackupColumnEnum.Rapporteur, 'backup.table.rapporteur'],
      [BackupColumnEnum.Date, 'backup.table.date'],
      [BackupColumnEnum.UserAction, 'backup.table.userAction'],
      [BackupColumnEnum.Actions, 'Action'],
    ],
    'Unknown',
  );

  export const sortField: SfxMap<BackupColumnEnum, projectSortField> = new SfxMap<BackupColumnEnum, projectSortField>([
    [BackupColumnEnum.Rapporteur, 'firstName'],
    [BackupColumnEnum.Date, 'actionDate'],
    [BackupColumnEnum.UserAction, 'natureAction'],
  ]);
}
