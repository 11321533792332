import { UserLightDTO, PageUserRequest, UserLightDTOV15, UserDTO, UsersProjectsFilterRequestDTO, TeamRoleLightDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { UserData } from '../account/user-data';

import { InnerSortDirectionEnum, UserNatureEnum, UserTypeEnum } from '../../enums';

export interface UserLightData {
  uuidEntity: string;
  firstName: string;
  lastName: string;
  fullName: string;
  color: string;
  trigram: string;
  natureUser: UserNatureEnum;
  refExtern: string;
  refIntern: string;
  email: string;
  signature?: string;
}

export namespace UserLightData {
  const defaultUserLightColor = '#0088CC';

  export interface QueryRequest extends BaseQuery<UserLightData> {
    type: UserTypeEnum;
    responsible: boolean;
    userNatures: UserNatureEnum[];
  }

  export function mapFromApiValue(user: UserLightDTO | UserLightDTOV15): UserLightData {
    return {
      uuidEntity: user.uuidEntity,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.firstName + ' ' + user.lastName,
      color: user.backgroundColor || defaultUserLightColor,
      trigram: user.trigram?.toLocaleUpperCase(),
      natureUser: UserNatureEnum.convertFromApiValue.getValue(user.natureUser),
      refExtern: user.refExtern,
      refIntern: user.refIntern,
      email: user.email,
      signature: (user as UserLightDTO).signature,
    };
  }

  export function mapTeamRoleToEntityLightData(teamRole: TeamRoleLightDTO): EntityLightData {
    return {
      uuidEntity: teamRole.uuidEntity,
      name: toString(teamRole.user),
      fullName: toString(teamRole.user),
      signature: teamRole.user.signature,
    };
  }

  export function mapToEntityLightData(user: UserLightDTO): EntityLightData {
    return {
      uuidEntity: user.uuidEntity,
      name: toString(user),
      fullName: toString(user),
      signature: user.signature,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageUserRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
      type: UserTypeEnum.convertToApiValue.getValue(queries.type),
      responsible: queries.responsible,
      userNatures: (queries.userNatures && queries.userNatures.map(nature => UserNatureEnum.convertToApiValue.getValue(nature))) || [],
    };
  }

  export function mapToProjectUserPageRequestApiValue(queries: QueryRequest): UsersProjectsFilterRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeFilterQueries(page: number, searchText?: string): QueryRequest {
    return {
      ...initializeQueries<UserLightData>({ key: 'firstName' }),
      page,
      sort: undefined,
      maxReached: false,
      textSearch: searchText || undefined,
      type: UserTypeEnum.User,
      userNatures: [UserNatureEnum.Normal, UserNatureEnum.Extern],
      responsible: true,
    };
  }

  export function initializeTrashFilterQueries(page: number, searchText?: string): QueryRequest {
    return {
      ...initializeQueries<UserLightData>({ key: 'firstName' }),
      page,
      sort: undefined,
      maxReached: false,
      textSearch: searchText || undefined,
      type: undefined,
      userNatures: [],
      responsible: undefined,
    };
  }

  export function mapToTrashFilterSelectItem(user: UserLightDTOV15): MultiSelectItemData {
    return {
      value: user.uuidEntity,
      title: toString(user),
    };
  }

  export function toString(user: Partial<UserLightDTO & EntityLightData>): string {
    if (!user) {
      return '';
    }

    if (user.name) {
      return user.name;
    }

    const prefix = (user.natureUser === UserDTO.NatureUserEnum.DEVICE
      ? 'Int'
      : user.natureUser === UserDTO.NatureUserEnum.DEVICEEXT
      ? 'Ext'
      : user.trigram
      ? `${user.trigram || ''}`
      : `${(user.firstName || '').charAt(0).toUpperCase()}${(user.lastName || '').substring(0, 2).toUpperCase()}`
    ).toLocaleUpperCase();

    const suffix = [UserDTO.NatureUserEnum.DEVICE, UserDTO.NatureUserEnum.DEVICEEXT].includes(user.natureUser) ? user.refExtern : `${user.firstName || ''} ${user.lastName || ''}`;

    return `${prefix} - ${suffix}`;
  }

  export function convertUserDataToEntityLight(user: UserData): Partial<UserLightData> {
    return {
      uuidEntity: user?.uuidEntity,
      firstName: user?.firstName,
      lastName: user?.lastName,
      fullName: user?.firstName + ' ' + user?.lastName,
      color: null,
      trigram: user?.trigram,
      email: user?.email,
      signature: user?.signature,
      natureUser: user.nature,
    };
  }

  export function mapUserToEntityLightData(user: UserLightDTO): EntityLightData {
    return {
      uuidEntity: user.uuidEntity,
      name: [UserLightDTO.NatureUserEnum.DEVICE, UserLightDTO.NatureUserEnum.DEVICEEXT].includes(user.natureUser)
        ? user.refExtern?.substring(0, 3)?.toLocaleUpperCase()
        : user.trigram?.toLocaleUpperCase(),
      tooltip: UserLightData.toString(user),
    };
  }
}
