import { compact } from 'lodash';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { IconData } from '../../../modules/shared/interfaces/icon-data';

import { SfxMap } from '../../utils/enum-utils';

import { PermissionActionEnum } from '../permission-action.enum';

export enum DashboardDocumentActionEnum {
  Maj = 1,
  Properties = 2,
  Delete = 3,
  Share = 4,
}

export namespace DashboardDocumentActionEnum {
  export const toString: SfxMap<DashboardDocumentActionEnum, string> = new SfxMap<DashboardDocumentActionEnum, string>([
    [DashboardDocumentActionEnum.Maj, 'dashboardDocuments.table.actions.maj'],
    [DashboardDocumentActionEnum.Properties, 'dashboardDocuments.table.actions.properties'],
    [DashboardDocumentActionEnum.Delete, 'dashboardDocuments.table.actions.delete'],
    [DashboardDocumentActionEnum.Share, 'dashboardDocuments.table.actions.share'],
  ]);

  export const iconData: SfxMap<DashboardDocumentActionEnum, IconData> = new SfxMap<DashboardDocumentActionEnum, IconData>([
    [DashboardDocumentActionEnum.Maj, { name: 'shopping-basket' }],
    [DashboardDocumentActionEnum.Properties, { name: 'info', prefix: 'fas' }],
    [DashboardDocumentActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [DashboardDocumentActionEnum.Share, { name: 'share-square', prefix: 'far' }],
  ]);

  export function getAllActionMenu(sharable?: boolean): ActionSelectItemData<DashboardDocumentActionEnum>[] {
    return compact([
      mapToActionMenuItem(DashboardDocumentActionEnum.Maj, PermissionActionEnum.Edit),
      mapToActionMenuItem(DashboardDocumentActionEnum.Properties),
      sharable && mapToActionMenuItem(DashboardDocumentActionEnum.Share),
      mapToActionMenuItem(DashboardDocumentActionEnum.Delete, PermissionActionEnum.Delete),
    ]);
  }

  export function getOtherActionMenu(sharable?: boolean): ActionSelectItemData<DashboardDocumentActionEnum>[] {
    return compact([
      mapToActionMenuItem(DashboardDocumentActionEnum.Properties),
      sharable && mapToActionMenuItem(DashboardDocumentActionEnum.Share),
      mapToActionMenuItem(DashboardDocumentActionEnum.Delete, PermissionActionEnum.Delete),
    ]);
  }

  export function mapToActionMenuItem(responseActionMenu: DashboardDocumentActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<DashboardDocumentActionEnum> {
    return {
      name: DashboardDocumentActionEnum.toString.getValue(responseActionMenu),
      value: responseActionMenu,
      icon: DashboardDocumentActionEnum.iconData.getValue(responseActionMenu),
      permissionAction,
    };
  }
}
