import { createReducer, Action, on } from '@ngrx/store';

import { TabletQrCodeData, TabletImportData, LazyLoadResponse } from '../../../../../core/models';

import * as fromTabletDialogActions from './dashboard-tablet-dialog.actions';

export interface QuickConnectionDialogState {
  qrCodeData: TabletQrCodeData;
  unlinkTabletLoading: boolean;
}

export interface ImportTabletDialogState {
  tablets: LazyLoadResponse<TabletImportData[]>;
  importLoading: boolean;
  reset: boolean;
}

export interface DashboardTabletDialogState {
  quickConnection: QuickConnectionDialogState;
  importTablet: ImportTabletDialogState;
}

export const initialDashboardTabletDialogState = () => ({
  quickConnection: {} as QuickConnectionDialogState,
  importTablet: {} as ImportTabletDialogState,
});

const reducer = createReducer<DashboardTabletDialogState>(
  initialDashboardTabletDialogState(),
  on(fromTabletDialogActions.loadQrCodeDataSuccess, (state, qrCodeData): DashboardTabletDialogState => ({ ...state, quickConnection: { ...state.quickConnection, qrCodeData } })),
  on(fromTabletDialogActions.unlinkTablet, (state): DashboardTabletDialogState => ({ ...state, quickConnection: { ...state.quickConnection, unlinkTabletLoading: true } })),
  on(
    fromTabletDialogActions.unlinkTabletSuccess,
    (state): DashboardTabletDialogState => ({
      ...state,
      quickConnection: { ...state.quickConnection, qrCodeData: { ...state.quickConnection.qrCodeData, uniqueDeviceId: null }, unlinkTabletLoading: false },
    }),
  ),
  on(fromTabletDialogActions.unlinkTabletFail, (state): DashboardTabletDialogState => ({ ...state, quickConnection: { ...state.quickConnection, unlinkTabletLoading: false } })),
  on(
    fromTabletDialogActions.loadImportTabletSuccess,
    (state, { data, reset }): DashboardTabletDialogState => ({
      ...state,
      importTablet: {
        ...state.importTablet,
        reset,
        tablets: reset ? data : { ...state.importTablet.tablets, payload: [...state.importTablet.tablets.payload, ...data.payload] },
      },
    }),
  ),
  on(fromTabletDialogActions.importTablet, (state): DashboardTabletDialogState => ({ ...state, importTablet: { ...state.importTablet, importLoading: true } })),
  on(fromTabletDialogActions.importTabletSuccess, (state): DashboardTabletDialogState => ({ ...state, importTablet: { ...state.importTablet, importLoading: false } })),
  on(fromTabletDialogActions.importTabletFail, (state): DashboardTabletDialogState => ({ ...state, importTablet: { ...state.importTablet, importLoading: false } })),
);

export function dashboardTabletDialogReducer(state: DashboardTabletDialogState | undefined, action: Action): DashboardTabletDialogState {
  return reducer(state, action);
}
