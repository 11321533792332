/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OptionSignatoryDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  createDate: Date;
  modificationDate: Date;
  name: string;
  description: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  profession: string;
  userRef: string;
  imageSignature: string;
  hashSignature: string;
  commentSignature: string;
  signatureDate: Date;
  localCreateDate: Date;
  disableSignature: boolean;
  typeContributor: OptionSignatoryDetailsResponseDTO.TypeContributorEnum;
  optionOrder: number;
  checkedPdfFieldName: boolean;
  pdfFieldName: string;
  checkedDisabledValue: boolean;
  exportXlsCheck: boolean;
  exportXlsFieldName: string;
  proxied: boolean;
  locked: boolean;
  signatureProxiedDate: Date;
}
export namespace OptionSignatoryDetailsResponseDTO {
  export type TypeContributorEnum = 'INTERN' | 'EXTERN' | 'NO_LISTED_INTERN';
  export const TypeContributorEnum = {
    INTERN: 'INTERN' as TypeContributorEnum,
    EXTERN: 'EXTERN' as TypeContributorEnum,
    NOLISTEDINTERN: 'NO_LISTED_INTERN' as TypeContributorEnum,
  };
}
