import { createAction, props } from '@ngrx/store';

import { ApplicationFileLightData, CustomFieldData, ErrorResponseData } from '../../../../../../core/models';
import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { DialogToasterData } from '../../../../../sfx-dialog/state/dialog-toaster-data';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentTabsEnum } from '../../../../../../core/enums/entity-modal-tab/document-tabs.enum';

// ***** Add documents actions ******
export const loadDocumentDetails = createAction('[addLibDoc] Load document details', props<{ documentUuidEntity: string; reset?: boolean }>());
export const loadDocumentDetailsFail = createAction('[addLibDoc] Load document details fail', props<DialogToasterData>());
export const loadDocumentDetailsSuccess = createAction('[addLibDoc] Load document details success', props<{ documentDetails: DocumentDetailsData }>());

export const saveDocumentChanges = createAction(
  '[addLibDoc] Save document changes',
  props<{
    documentDetails: DocumentDetailsData;
    customFields: CustomFieldData[];
    nextTab: DocumentTabsEnum;
    fileRefs: File[];
    nextDocStep?: number;
  }>(),
);
export const stopSaveLoading = createAction('[addLibDoc] Stop save loading');
export const createDocumentFail = createAction('[addLibDoc] Create document fail', props<DialogToasterData>());
export const updateDocumentFail = createAction('[addLibDoc] Update document fail', props<DialogToasterData>());
export const createDocumentSuccess = createAction('[addLibDoc] Create document success', props<{ documentDetails: DocumentDetailsData }>());
export const updateDocumentSuccess = createAction(
  '[addLibDoc] Update document success',
  props<{ documentDetails: DocumentDetailsData; customFields: CustomFieldData[]; nextTab: DocumentTabsEnum }>(),
);

export const checkGeneratedRefs = createAction('[addLibDoc] check generated references', props<{ familyUuidEntity: string; reset: boolean }>());
export const checkGeneratedRefsFail = createAction('[addLibDoc] check generated references fail', props<DialogToasterData>());
export const checkGeneratedRefsSuccess = createAction(
  '[addLibDoc] check generated references success',
  props<{ familyUuidEntity: string; genRefInt: boolean; genRefExt: boolean; genCompRefInt: boolean; genCompRefExt: boolean; reset: boolean }>(),
);

export const loadDocumentCustomFields = createAction('[addLibDoc] load document custom fields', props<{ documentDetails: DocumentDetailsData }>());
export const loadDocumentCustomFieldsFail = createAction('[addLibDoc] load document custom fields fail', props<DialogToasterData>());
export const loadDocumentCustomFieldsSuccess = createAction('[addLibDoc] load document custom fields success', props<{ documentCustomFields: CustomFieldData[] }>());

export const navigateThroughDocuments = createAction(
  '[addLibDoc] navigate through Documents',
  props<{ documentDetails: DocumentDetailsData; customFields: CustomFieldData[]; fileRefs: File[]; step: number }>(),
);
export const navigateThroughDocumentsSuccess = createAction('[addLibDoc] navigate through Documents Success', props<{ uuidEntity: string; step: number }>());

// document files actions
export const loadDocumentFiles = createAction('[AddLibDoc] load document files');
export const loadMoreDocumentFiles = createAction('[AddLibDoc] load more documents files');
export const loadDocumentFilesFail = createAction('[AddLibDoc] load document files fail', props<DialogToasterData>());
export const loadDocumentFilesSuccess = createAction(
  '[AddLibDoc] load document files success',
  props<{ files: ApplicationFileLightData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);

export const resetFilesState = createAction('[AddLibDoc] reset document file state');

export const moveDocumentFile = createAction('[AddLibDoc] move documents file', props<{ fileUuidEntity: string; siblingFileUuidEntity: string }>());
export const moveDocumentFileFail = createAction('[AddLibDoc] move documents file fail', props<{ error: ErrorResponseData }>());

export const viewDocumentFile = createAction('[AddLibDoc] view document file', props<{ fileUuidEntity: string }>());
export const viewDocumentFileSuccess = createAction('[AddLibDoc] view document file success');
export const viewDocumentFileFail = createAction('[AddLibDoc] view document file fail');

export const deleteDocumentFile = createAction('[AddLibDoc] delete document file', props<{ fileUuidEntity: string }>());
export const deleteDocumentFileSuccess = createAction('[AddLibDoc] delete document file success', props<{ fileUuidEntity: string }>());

export const downloadDocumentFiles = createAction('[AddLibDoc] download documents files', props<{ documentUuidEntity: string }>());
export const downloadDocumentFilesSuccess = createAction('[AddLibDoc] download documents files success');

// custom wording actions
export const loadCustomWordingDetails = createAction('[AddLibDoc] Load custom wording details', props<{ labelFamilyUuidEntity: string }>());
export const loadCustomWordingDetailsFail = createAction('[AddLibDoc] Load custom wording details fail', props<DialogToasterData>());
export const loadCustomWordingDetailsSuccess = createAction('[AddLibDoc] Load custom wording details success', props<{ documentCustomWording: CustomWordingsDocumentData }>());
