import { BusinessClassNameEnum, LanguageEnum } from '../../enums';
import { CreateSharedLinkRequestDto } from '../../generated/models/createSharedLinkRequestDto';

export interface ShareLinkData {
  emails: string[];
  comment?: string;
  language: LanguageEnum;
}

export namespace ShareLinkData {
  export function mapToApiValue(data: ShareLinkData, elementUuidEntity: string, businessClass: BusinessClassNameEnum, fblUuidEntity?: string): CreateSharedLinkRequestDto {
    return {
      logins: data.emails,
      comment: data.comment,
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(businessClass),
      refUuide: elementUuidEntity,
      refUuideSecondary: fblUuidEntity,
      language: LanguageEnum.convertToApiValue.getValue(data.language),
    };
  }
}
