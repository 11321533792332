/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlowTeamRoleRequestDTO {
  teamRoleUuidEntity?: string;
  nature: FlowTeamRoleRequestDTO.NatureEnum;
}
export namespace FlowTeamRoleRequestDTO {
  export type NatureEnum = 'ASSIGNE' | 'REPORTER' | 'TABLET_1' | 'TABLET_2' | 'TABLET_3' | 'COLLABORATOR';
  export const NatureEnum = {
    ASSIGNE: 'ASSIGNE' as NatureEnum,
    REPORTER: 'REPORTER' as NatureEnum,
    TABLET1: 'TABLET_1' as NatureEnum,
    TABLET2: 'TABLET_2' as NatureEnum,
    TABLET3: 'TABLET_3' as NatureEnum,
    COLLABORATOR: 'COLLABORATOR' as NatureEnum,
  };
}
