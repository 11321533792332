import { createAction, props } from '@ngrx/store';

import { CustomCellSummaryData, CustomFileDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { CustomCellDetailsData } from '../../../../../core/models/custom-cell/custom-cell-details';

// Custom cell dialog actions
export const addCustomCell = createAction('[sCustomCellDialog] add new custom cell', props<{ customCell: CustomCellDetailsData; createAnother?: boolean }>());
export const addCustomCellSuccess = createAction('[sCustomCellDialog] add new custom cell success', props<{ customCellSummary: CustomCellSummaryData; createAnother?: boolean }>());
export const addCustomCellFail = createAction('[sCustomCellDialog] add new custom cell fail', props<DialogToasterData>());

export const updateCustomCell = createAction('[sCustomCellDialog] update custom cell', props<{ customCell: CustomCellDetailsData }>());
export const updateCustomCellSuccess = createAction('[sCustomCellDialog] update custom cell success', props<{ customCellSummary: CustomCellSummaryData }>());
export const updateCustomCellFail = createAction('[sCustomCellDialog] update custom cell fail', props<DialogToasterData>());

export const getCustomCellDetailsSuccess = createAction('[sCustomCellDialog] get custom cell details success', props<{ customCellDetails: CustomCellDetailsData }>());
export const getCustomCellDetailsFail = createAction('[sCustomCellDialog] get custom cell details fail', props<DialogToasterData>());

export const initializeCustomCellDetails = createAction('[sCustomCellDialog] initialize custom cell details', props<{ customCellDetails: CustomCellDetailsData }>());

export const initializeAnotherCustomCell = createAction('[sCustomCellDialog] initialize another custom cell', props<{ customCellDetails: CustomCellDetailsData }>());

export const saveCustomCellChanges = createAction('[sCustomCellDialog] save custom cell changes', props<{ customCell: CustomCellDetailsData; createAnother: boolean }>());

export const askSaveBeforeNavigation = createAction('[sCustomCellDialog] Ask save before navigation', props<{ cellData: CustomCellDetailsData; step: number }>());
export const navigateToNextCell = createAction('[sCustomCellDialog] Navigate to next cell details', props<{ cellUuidEntity?: string; step: number }>());

export const initCustomFile = createAction('[sCustomCellDialog] init custom file', props<{ customFile: CustomFileDetailsData }>());
