import { OverlayRef } from '@angular/cdk/overlay';

export class ToastOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  public close(): void {
    this.overlayRef.dispose();
  }

  public getOverlayElement(): HTMLElement {
    return this.overlayRef?.overlayElement;
  }

  public getPosition(): DOMRect {
    return this.overlayRef.overlayElement.getBoundingClientRect();
  }
}
