/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';
import { SortDto } from './sortDto';

export interface DocumentFlowFindLazyByProjectRequestDTO {
  blocUuidEntity?: string;
  categoryPrincipalUuidEntity?: Array<string>;
  categorySecondaryUuidEntity?: Array<string>;
  customWordingUuidEntities?: Array<string>;
  dateFilterRequest?: DateFilterRequest;
  documentFlowUuidEntityToExclude?: string;
  familyUuidEntity?: Array<string>;
  filterEtat?: Array<DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum>;
  flowUuidEntity?: Array<string>;
  natureFile?: DocumentFlowFindLazyByProjectRequestDTO.NatureFileEnum;
  page: number;
  projectUuidEntity: string;
  provenance?: Array<DocumentFlowFindLazyByProjectRequestDTO.ProvenanceEnum>;
  size: number;
  sort?: SortDto;
  textSearch?: string;
}
export namespace DocumentFlowFindLazyByProjectRequestDTO {
  export type FilterEtatEnum =
    | 'PREL'
    | 'CLIENT_RETURN_BPO'
    | 'DIFFUSION_BPO'
    | 'BPE'
    | 'BPE_SUITE_VSOSR'
    | 'BPE_VALID'
    | 'CLIENT_RETURN_BPA'
    | 'DIFFUSION_BPA'
    | 'BPA_VALID'
    | 'WITHOUT';
  export const FilterEtatEnum = {
    PREL: 'PREL' as FilterEtatEnum,
    CLIENTRETURNBPO: 'CLIENT_RETURN_BPO' as FilterEtatEnum,
    DIFFUSIONBPO: 'DIFFUSION_BPO' as FilterEtatEnum,
    BPE: 'BPE' as FilterEtatEnum,
    BPESUITEVSOSR: 'BPE_SUITE_VSOSR' as FilterEtatEnum,
    BPEVALID: 'BPE_VALID' as FilterEtatEnum,
    CLIENTRETURNBPA: 'CLIENT_RETURN_BPA' as FilterEtatEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as FilterEtatEnum,
    BPAVALID: 'BPA_VALID' as FilterEtatEnum,
    WITHOUT: 'WITHOUT' as FilterEtatEnum,
  };
  export type NatureFileEnum =
    | 'PR'
    | 'EMM'
    | 'ADR'
    | 'DSI'
    | 'LDA'
    | 'LFA'
    | 'LCA'
    | 'PROJECT'
    | 'AUTRE'
    | 'RFI'
    | 'PFORM'
    | 'FFORM'
    | 'BFORM'
    | 'PREVIEW_FORM'
    | 'MULTI_FLOW_FORM'
    | 'LIST_ADR'
    | 'LIST_EMM'
    | 'LIST_ADR_FLOW'
    | 'LIST_EMM_FLOW'
    | 'MULTI_PR'
    | 'FIELD_FEEDBACK'
    | 'DIAGRAM';
  export const NatureFileEnum = {
    PR: 'PR' as NatureFileEnum,
    EMM: 'EMM' as NatureFileEnum,
    ADR: 'ADR' as NatureFileEnum,
    DSI: 'DSI' as NatureFileEnum,
    LDA: 'LDA' as NatureFileEnum,
    LFA: 'LFA' as NatureFileEnum,
    LCA: 'LCA' as NatureFileEnum,
    PROJECT: 'PROJECT' as NatureFileEnum,
    AUTRE: 'AUTRE' as NatureFileEnum,
    RFI: 'RFI' as NatureFileEnum,
    PFORM: 'PFORM' as NatureFileEnum,
    FFORM: 'FFORM' as NatureFileEnum,
    BFORM: 'BFORM' as NatureFileEnum,
    PREVIEWFORM: 'PREVIEW_FORM' as NatureFileEnum,
    MULTIFLOWFORM: 'MULTI_FLOW_FORM' as NatureFileEnum,
    LISTADR: 'LIST_ADR' as NatureFileEnum,
    LISTEMM: 'LIST_EMM' as NatureFileEnum,
    LISTADRFLOW: 'LIST_ADR_FLOW' as NatureFileEnum,
    LISTEMMFLOW: 'LIST_EMM_FLOW' as NatureFileEnum,
    MULTIPR: 'MULTI_PR' as NatureFileEnum,
    FIELDFEEDBACK: 'FIELD_FEEDBACK' as NatureFileEnum,
    DIAGRAM: 'DIAGRAM' as NatureFileEnum,
  };
  export type ProvenanceEnum = 'LIBRARY' | 'REFERENTIAL' | 'LIBRARY_CREATED' | 'REFERENTIAL_CREATED' | 'LOCAL' | 'PROVENANCE_LIBRARY' | 'PROVENANCE_REFERENTIAL';
  export const ProvenanceEnum = {
    LIBRARY: 'LIBRARY' as ProvenanceEnum,
    REFERENTIAL: 'REFERENTIAL' as ProvenanceEnum,
    LIBRARYCREATED: 'LIBRARY_CREATED' as ProvenanceEnum,
    REFERENTIALCREATED: 'REFERENTIAL_CREATED' as ProvenanceEnum,
    LOCAL: 'LOCAL' as ProvenanceEnum,
    PROVENANCELIBRARY: 'PROVENANCE_LIBRARY' as ProvenanceEnum,
    PROVENANCEREFERENTIAL: 'PROVENANCE_REFERENTIAL' as ProvenanceEnum,
  };
}
