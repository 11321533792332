import { createAction, props } from '@ngrx/store';
import { ScheduleStatusEnum } from '../../../../core/enums/schedule/schedule-status.enum';

import { FlowDetailsData } from '../../../../core/models';
import { ScheduleDetailsData } from '../../../../core/models/schedule/schedule-details-data';
import { ScheduleEndUpdateData } from '../../../../core/models/schedule/schedule-end-update-data';
import { ScheduleStartUpdateData } from '../../../../core/models/schedule/schedule-start-update-data';

export const loadFlowLight = createAction('[schedule/API] Load flow light', props<{ flowUuidEntity: string }>());
export const loadFlowLightSuccess = createAction('[schedule/API] Load flow light Success', props<{ flowLight: FlowDetailsData }>());

export const loadScheduleList = createAction('[schedule/API] Load schedule list', props<{ flowUuidEntity: string }>());
export const loadNextScheduleNextPage = createAction('[schedule/API] Load next schedule list page');
export const loadScheduleListSuccess = createAction(
  '[schedule/API] Load schedule list success',
  props<{ schedules: ScheduleDetailsData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadScheduleListFails = createAction('[schedule/API] Load schedule list fails');

export const resetState = createAction('[schedule/API] Reset schedule state');

export const changeScheduleState = createAction('[schedule/API] change schedule state', props<{ scheduleUuidEntity: string; status: ScheduleStatusEnum }>());
export const changeScheduleStateSuccess = createAction('[schedule/API] change schedule state success', props<{ scheduleUuidEntity: string; status: ScheduleStatusEnum }>());
export const changeScheduleStateFail = createAction('[schedule/API] change schedule state fail');

export const startSchedule = createAction('[schedule/API] start schedule', props<{ request: ScheduleStartUpdateData; scheduleUuidEntity: string }>());
export const startScheduleSuccess = createAction('[schedule/API] start schedule success', props<{ schedule: ScheduleDetailsData }>());
export const startScheduleFail = createAction('[schedule/API] start schedule state fail');

export const endSchedule = createAction('[schedule/API] end schedule', props<{ request: ScheduleEndUpdateData; scheduleUuidEntity: string }>());
export const endScheduleSuccess = createAction('[schedule/API] end schedule success', props<{ schedule: ScheduleDetailsData }>());
export const endScheduleFail = createAction('[schedule/API] end schedule state fail');

export const deleteSchedule = createAction('[schedule/API] delete schedule', props<ScheduleDetailsData>());
export const duplicateSchedule = createAction('[schedule/API] duplicate schedule', props<{ schedule: ScheduleDetailsData }>());
