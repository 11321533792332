import { BlocEmmDetailsResponseDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { ApplicationFileLightData } from '../application-file/application-file';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

export interface BlockEquipmentDetailsData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  applicability: LabelApplicabilityLightData;
  name: string;
  reference: string;
  comment: string;
  provider: string;
  referenceDocument: ApplicationFileLightData;
  certificateDocument: ApplicationFileLightData;
  photo: ApplicationFileLightData;
  validityDate: string;
  submittedVerification: boolean;
  verificationOrganism: string;
}

export namespace BlockEquipmentDetailsData {
  export function mapFromApiValue(blockEquipment: BlocEmmDetailsResponseDTO): BlockEquipmentDetailsData {
    return {
      uuidEntity: blockEquipment.uuidEntity,
      family: blockEquipment.family && LabelFamilyLightData.mapFromApiValue(blockEquipment.family),
      applicability: blockEquipment.applicability && LabelApplicabilityLightData.mapFromApiValue(blockEquipment.applicability),
      name: blockEquipment.name,
      reference: blockEquipment.reference,
      comment: blockEquipment.commentaire,
      provider: blockEquipment.provider,
      referenceDocument: blockEquipment.referenceFile && ApplicationFileLightData.mapFromApiValue(blockEquipment.referenceFile),
      certificateDocument: blockEquipment.certificate && ApplicationFileLightData.mapFromApiValue(blockEquipment.certificate),
      photo: blockEquipment.photo && ApplicationFileLightData.mapFromApiValue(blockEquipment.photo),
      validityDate: DateUtils.toCalendarFormat(blockEquipment.validityDate, true),
      submittedVerification: blockEquipment.submittedVerification,
      verificationOrganism: blockEquipment.verificationOrganism,
    };
  }
}
