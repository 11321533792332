/**
 * @deprecated
 */
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { BusinessClassNameEnum, RevisionActionEnum, InnerSortDirectionEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface RevisionEventSummaryData {
  action: RevisionActionEnum;
  actionDate: string;
  businessClassName: BusinessClassNameEnum;
  entityName: string;
  entityParentName: string;
  userName: string;
  userTrigram: string;
  uuidEntity: string;
}

export namespace RevisionEventSummaryData {
  export interface QueryRequest extends BaseQuery<RevisionEventSummaryData> {
    revisionUuidEntity: string;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  export function mapFromApiValue(event: any): RevisionEventSummaryData {
    return {
      userName: event.userName,
      userTrigram: (event.userTrigram || '').toLocaleUpperCase(),
      entityName: event.entityName,
      entityParentName: event.entityParentName,
      businessClassName: BusinessClassNameEnum.convertFromApiValue.getValue(event.businessClassName),
      actionDate: event.actionDate && DateUtils.toDateTimeFormat(event.actionDate),
      action: RevisionActionEnum.convertFromApiValue.getValue(event.action),
      uuidEntity: event.uuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): any {
    return {
      page: queries.page,
      size: queries.size,
      revisionUuidEntity: queries.revisionUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(revisionUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<RevisionEventSummaryData>(),
      revisionUuidEntity,
    };
  }
}
