import { PageFlowForTechnicalObjectRequest } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum RelationNatureEnum {
  Content = 1,
  Historic = 2,
}

export namespace RelationNatureEnum {
  export const convertFromApiValue: SfxMap<PageFlowForTechnicalObjectRequest.NatureEnum, RelationNatureEnum> = new SfxMap<
    PageFlowForTechnicalObjectRequest.NatureEnum,
    RelationNatureEnum
  >([
    [PageFlowForTechnicalObjectRequest.NatureEnum.CONTENTRELATION, RelationNatureEnum.Content],
    [PageFlowForTechnicalObjectRequest.NatureEnum.HISTORICRELATION, RelationNatureEnum.Historic],
  ]);

  export const convertToApiValue: SfxMap<RelationNatureEnum, PageFlowForTechnicalObjectRequest.NatureEnum> = new SfxMap<
    RelationNatureEnum,
    PageFlowForTechnicalObjectRequest.NatureEnum
  >([
    [RelationNatureEnum.Content, PageFlowForTechnicalObjectRequest.NatureEnum.CONTENTRELATION],
    [RelationNatureEnum.Historic, PageFlowForTechnicalObjectRequest.NatureEnum.HISTORICRELATION],
  ]);
}
