import { Observable, of } from 'rxjs';

import { SfxMap } from '../../utils/enum-utils';
import { ArchiveSummaryResponseDTO } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum TrashNatureEnum {
  Entity = 1,
  Flow = 2,
  Category = 3,
  Document = 4,
  List = 5,
  Form = 6,
  ClosingFolder = 7,
  FormResponse = 8,
}

export namespace TrashNatureEnum {
  const values: TrashNatureEnum[] = [
    TrashNatureEnum.Entity,
    TrashNatureEnum.Flow,
    TrashNatureEnum.Category,
    TrashNatureEnum.Document,
    TrashNatureEnum.List,
    TrashNatureEnum.Form,
    TrashNatureEnum.FormResponse,
    TrashNatureEnum.ClosingFolder,
  ];

  export const convertFromApiValue: SfxMap<ArchiveSummaryResponseDTO.TypeEnum, TrashNatureEnum> = new SfxMap<ArchiveSummaryResponseDTO.TypeEnum, TrashNatureEnum>([
    [ArchiveSummaryResponseDTO.TypeEnum.PROJECT, TrashNatureEnum.Entity],
    [ArchiveSummaryResponseDTO.TypeEnum.FLOW, TrashNatureEnum.Flow],
    [ArchiveSummaryResponseDTO.TypeEnum.FORM, TrashNatureEnum.Form],
    [ArchiveSummaryResponseDTO.TypeEnum.CATEGORY, TrashNatureEnum.Category],
    [ArchiveSummaryResponseDTO.TypeEnum.DOCUMENT, TrashNatureEnum.Document],
    [ArchiveSummaryResponseDTO.TypeEnum.LIST, TrashNatureEnum.List],
    [ArchiveSummaryResponseDTO.TypeEnum.RFI, TrashNatureEnum.ClosingFolder],
    [ArchiveSummaryResponseDTO.TypeEnum.FORMRESPONSE, TrashNatureEnum.FormResponse],
  ]);

  export const convertToApiValue: SfxMap<TrashNatureEnum, ArchiveSummaryResponseDTO.TypeEnum> = new SfxMap<TrashNatureEnum, ArchiveSummaryResponseDTO.TypeEnum>([
    [TrashNatureEnum.Entity, ArchiveSummaryResponseDTO.TypeEnum.PROJECT],
    [TrashNatureEnum.Flow, ArchiveSummaryResponseDTO.TypeEnum.FLOW],
    [TrashNatureEnum.Category, ArchiveSummaryResponseDTO.TypeEnum.CATEGORY],
    [TrashNatureEnum.List, ArchiveSummaryResponseDTO.TypeEnum.LIST],
    [TrashNatureEnum.Form, ArchiveSummaryResponseDTO.TypeEnum.FORM],
    [TrashNatureEnum.ClosingFolder, ArchiveSummaryResponseDTO.TypeEnum.RFI],
    [TrashNatureEnum.Document, ArchiveSummaryResponseDTO.TypeEnum.DOCUMENT],
    [TrashNatureEnum.FormResponse, ArchiveSummaryResponseDTO.TypeEnum.FORMRESPONSE],
  ]);

  export const convertFromParamsValue: SfxMap<string, TrashNatureEnum> = new SfxMap<string, TrashNatureEnum>([
    ['document', TrashNatureEnum.Document],
    ['flow', TrashNatureEnum.Flow],
    ['list', TrashNatureEnum.List],
    ['category', TrashNatureEnum.Category],
    ['closingFolder', TrashNatureEnum.ClosingFolder],
    ['entity', TrashNatureEnum.Entity],
    ['form', TrashNatureEnum.Form],
    ['formResponse', TrashNatureEnum.FormResponse],
  ]);

  export const convertToParamValue: SfxMap<TrashNatureEnum, string> = new SfxMap<TrashNatureEnum, string>([
    [TrashNatureEnum.Entity, 'entity'],
    [TrashNatureEnum.Flow, 'flow'],
    [TrashNatureEnum.Category, 'category'],
    [TrashNatureEnum.Document, 'document'],
    [TrashNatureEnum.List, 'list'],
    [TrashNatureEnum.Form, 'form'],
    [TrashNatureEnum.ClosingFolder, 'closingFolder'],
    [TrashNatureEnum.FormResponse, 'formResponse'],
  ]);

  const key = 'trash.filter.trashNatureEnum.';
  export const toString: SfxMap<TrashNatureEnum, string> = new SfxMap<TrashNatureEnum, string>(
    [
      [TrashNatureEnum.Entity, key + 'entity'],
      [TrashNatureEnum.Flow, key + 'flow'],
      [TrashNatureEnum.Category, key + 'category'],
      [TrashNatureEnum.List, key + 'list'],
      [TrashNatureEnum.Form, key + 'form'],
      [TrashNatureEnum.Document, key + 'document'],
      [TrashNatureEnum.ClosingFolder, key + 'closingFolder'],
      [TrashNatureEnum.FormResponse, key + 'formResponse'],
    ],
    key + 'nature',
  );

  export const iconData: SfxMap<TrashNatureEnum, IconData> = new SfxMap<TrashNatureEnum, IconData>([
    [TrashNatureEnum.Entity, { name: 'folder-open', prefix: 'far' }],
    [TrashNatureEnum.Flow, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [TrashNatureEnum.Category, { name: 'tag', prefix: 'fas' }],
    [TrashNatureEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [TrashNatureEnum.List, { name: 'file-alt', prefix: 'far' }],
    [TrashNatureEnum.Form, { name: 'list-alt', prefix: 'fas' }],
    [TrashNatureEnum.ClosingFolder, { name: 'archive', prefix: 'fas' }],
    [TrashNatureEnum.FormResponse, { name: 'envelope-open-text', prefix: 'fas' }],
  ]);

  export const singleSelectItemsNatureTrash = (): Observable<SingleSelectItemData<TrashNatureEnum>[]> =>
    of(
      values.map(nature => ({
        value: nature,
        title: toString.getValue(nature),
        icons: [iconData.getValue(nature)],
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<TrashNatureEnum>, item: TrashNatureEnum) => node.value === item;
}
