import { DevicePropertiesResponseDTO } from '../../generated/models/models';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { FlowLightData } from '../flows/flow-light-data';

import { TabletStatusEnum, UserNatureEnum, UserStatusEnum } from '../../enums';

export interface TabletPropertiesData {
  uuidEntity: string;
  name: string;
  type: UserNatureEnum;
  state: TabletStatusEnum;
  status: UserStatusEnum;
  labelFamily: LabelFamilyLightData;
  applicabilityName: string;
  flows: FlowLightData[];
}

export namespace TabletPropertiesData {
  export function mapFromApiValue(device: DevicePropertiesResponseDTO): TabletPropertiesData {
    return {
      uuidEntity: device.uuidEntity,
      labelFamily: (device.labelFamilyTeam && LabelFamilyLightData.mapFromApiValue(device.labelFamilyTeam)) || LabelFamilyLightData.defaultData,
      name: device.name,
      type: device.status && UserNatureEnum.convertFromApiValue.getValue(device.type),
      state: device.state && TabletStatusEnum.convertFromApiValue.getValue(device.state),
      status: device.status && UserStatusEnum.convertFromApiValue.getValue(device.status),
      flows: (device.flows || []).map(flow => FlowLightData.mapFromApiResponse(flow)),
      applicabilityName: device['applicability']?.fullName || device['applicability']?.name, // make models
    };
  }
}
