/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchedulingStartUpdateRequestDTO {
  condition: SchedulingStartUpdateRequestDTO.ConditionEnum;
  startDate?: Date;
  startCustomStatusUuidEntities?: Array<string>;
}
export namespace SchedulingStartUpdateRequestDTO {
  export type ConditionEnum = 'TIME' | 'USAGE' | 'STATUS';
  export const ConditionEnum = {
    TIME: 'TIME' as ConditionEnum,
    USAGE: 'USAGE' as ConditionEnum,
    STATUS: 'STATUS' as ConditionEnum,
  };
}
