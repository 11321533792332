import { ApplicationFileTmpSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum FileTypeEnum {
  Pdf = 1,
  Csv = 2,
  Xlsx = 3,
  Zip = 4,
  Word = 5,
  Img = 6,
  Json = 7,
}

export namespace FileTypeEnum {
  export const convertFromApiValue = new SfxMap<ApplicationFileTmpSummaryResponseDTO.FileTypeEnum, FileTypeEnum>([
    [ApplicationFileTmpSummaryResponseDTO.FileTypeEnum.PDF, FileTypeEnum.Pdf],
    [ApplicationFileTmpSummaryResponseDTO.FileTypeEnum.ZIP, FileTypeEnum.Zip],
  ]);

  export const mapToApiValue = new SfxMap<FileTypeEnum, string>([
    [FileTypeEnum.Pdf, 'application/pdf'],
    [FileTypeEnum.Csv, 'text/csv;encoding:utf-8'],
    [FileTypeEnum.Xlsx, 'application/vnd.ms-excel'],
    [FileTypeEnum.Zip, 'application/zip'],
    [FileTypeEnum.Word, 'application/msword'],
    [FileTypeEnum.Img, 'application/octet-stream'],
  ]);

  export const mapFromApiValue = new SfxMap<string, FileTypeEnum>([
    ['application/pdf', FileTypeEnum.Pdf],
    ['application/zip', FileTypeEnum.Zip],
  ]);

  // Info: https://www.thoughtco.com/mime-types-by-content-type-3469108
  export const acceptType = new SfxMap<FileTypeEnum, string>([
    [FileTypeEnum.Pdf, '.pdf'],
    [FileTypeEnum.Csv, '.csv'],
    [FileTypeEnum.Xlsx, '.xlsx'],
    [FileTypeEnum.Zip, '.zip'],
    [FileTypeEnum.Img, 'image/*'],
    [FileTypeEnum.Json, '.json'],
  ]);

  export const validExtensions = new SfxMap<FileTypeEnum, string[]>(
    [
      [FileTypeEnum.Pdf, ['pdf']],
      [FileTypeEnum.Csv, ['csv']],
      [FileTypeEnum.Xlsx, ['xlsx']],
      [FileTypeEnum.Zip, ['zip']],
      [FileTypeEnum.Img, ['jpeg', 'jpg', 'png']],
      [FileTypeEnum.Json, ['json']],
    ],
    [],
  );

  export const iconName = new SfxMap<FileTypeEnum, string>(
    [
      [FileTypeEnum.Pdf, 'file-pdf'],
      [FileTypeEnum.Csv, 'file-csv'],
      [FileTypeEnum.Xlsx, 'file-excel'],
      [FileTypeEnum.Zip, 'file-archive'],
      [FileTypeEnum.Word, 'file-word'],
      [FileTypeEnum.Img, 'file-image'],
    ],
    'file',
  );

  export const iconPrefix = new SfxMap<FileTypeEnum, string>([[FileTypeEnum.Pdf, 'far']]);
}
