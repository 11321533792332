import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';

export enum BlockResponseActionEnum {
  Update = 1,
  UpdateReference,
  GenerateDocument,
  UpdateState,
  Delete,
  History,
  Share,
  Duplicate,
}

export namespace BlockResponseActionEnum {
  export const actionWhereResponseIsInProgress = [
    BlockResponseActionEnum.Update,
    BlockResponseActionEnum.UpdateReference,
    BlockResponseActionEnum.GenerateDocument,
    BlockResponseActionEnum.Duplicate,
    BlockResponseActionEnum.Share,
    BlockResponseActionEnum.History,
    BlockResponseActionEnum.Delete,
  ];
  export const actionWhenAResponseIsDone = [
    BlockResponseActionEnum.GenerateDocument,
    BlockResponseActionEnum.Duplicate,
    BlockResponseActionEnum.Share,
    BlockResponseActionEnum.UpdateState,
    BlockResponseActionEnum.History,
    BlockResponseActionEnum.Delete,
  ];

  const key = 'blocks.form.dialogs.response.table.actions.';
  export const toString: SfxMap<BlockResponseActionEnum, string> = new SfxMap<BlockResponseActionEnum, string>([
    [BlockResponseActionEnum.Update, key + 'update'],
    [BlockResponseActionEnum.UpdateReference, key + 'updateReference'],
    [BlockResponseActionEnum.GenerateDocument, key + 'generateDocument'],
    [BlockResponseActionEnum.UpdateState, key + 'updateStatus'],
    [BlockResponseActionEnum.History, key + 'history'],
    [BlockResponseActionEnum.Delete, key + 'delete'],
    [BlockResponseActionEnum.Share, key + 'share'],
    [BlockResponseActionEnum.Duplicate, key + 'duplicate'],
  ]);

  export const iconData: SfxMap<BlockResponseActionEnum, IconData> = new SfxMap<BlockResponseActionEnum, IconData>([
    [BlockResponseActionEnum.Update, { name: 'edit', prefix: 'far' }],
    [BlockResponseActionEnum.UpdateReference, { name: 'pencil-alt', prefix: 'fas' }],
    [BlockResponseActionEnum.GenerateDocument, { name: 'file-invoice', prefix: 'fas' }],
    [BlockResponseActionEnum.UpdateState, { name: 'retweet', prefix: 'fas' }],
    [BlockResponseActionEnum.History, { name: 'flag', prefix: 'fas' }],
    [BlockResponseActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [BlockResponseActionEnum.Share, { name: 'share-square', prefix: 'fas' }],
    [BlockResponseActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
  ]);

  export function getActionMenu(): ActionSelectItemData<BlockResponseActionEnum>[] {
    return [
      mapToActionMenuItem(BlockResponseActionEnum.Update, PermissionActionEnum.Edit),
      mapToActionMenuItem(BlockResponseActionEnum.UpdateReference, PermissionActionEnum.Edit),
      mapToActionMenuItem(BlockResponseActionEnum.GenerateDocument),
      mapToActionMenuItem(BlockResponseActionEnum.Duplicate, PermissionActionEnum.Duplicate),
      mapToActionMenuItem(BlockResponseActionEnum.Share),
      mapToActionMenuItem(BlockResponseActionEnum.UpdateState, PermissionActionEnum.EditStatus),
      mapToActionMenuItem(BlockResponseActionEnum.History),
      mapToActionMenuItem(BlockResponseActionEnum.Delete, PermissionActionEnum.Delete),
    ];
  }

  export function mapToActionMenuItem(responseActionMenu: BlockResponseActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<BlockResponseActionEnum> {
    return {
      name: BlockResponseActionEnum.toString.getValue(responseActionMenu),
      value: responseActionMenu,
      icon: BlockResponseActionEnum.iconData.getValue(responseActionMenu),
      permissionAction,
    };
  }
}
