import { SignatureStateDTO } from '../../generated/models/models';

export interface SignatureMetricsState {
  count: number;
  signed: number;
  required: number;
  webApp: number;
  percent: number;
  mobileApp: number;
}

export namespace SignatureMetricsState {
  export function mapFromApiValue(signatureState: SignatureStateDTO): SignatureMetricsState {
    return {
      count: signatureState.count,
      signed: signatureState.signed,
      required: signatureState.mandatory,
      percent: signatureState.count ? Math.round((signatureState.signed * 100) / signatureState.count) : 0,
      webApp: signatureState.webApp,
      mobileApp: signatureState.mobileApp,
    };
  }
}
