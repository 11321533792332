import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationConfigRequestDTO } from '../generated/models/models';
import { OrganizationConfigResponseDTO } from '../generated/models/organizationConfigResponseDTO';
import { HttpParamsType, LazyLoadResponse } from '../models';
import { ApplicationData, OrganizationConfigDetails } from '../models/organization-config/organization-config-details';
import { BaseHttpService } from './base-http.service';
import { ApiUrlsEnum } from './helpers/api-url';
import { apiUrlMatcher } from './helpers/api-url-matcher';

@Injectable({ providedIn: 'root' })
export class OrganizationConfigService {
  constructor(private baseHttpService: BaseHttpService) {}

  public loadOrganizationConfig(uuidOrganization: string): Observable<OrganizationConfigDetails> {
    return this.baseHttpService
      .get<OrganizationConfigResponseDTO>(apiUrlMatcher(ApiUrlsEnum.OrganizationConfiguration, { uuidOrganization }))
      .pipe(map(organizationConfigData => OrganizationConfigDetails.mapFromApiValue(organizationConfigData)));
  }

  public updateOrganizationConfig(params: HttpParamsType<OrganizationConfigRequestDTO>): Observable<void> {
    return this.baseHttpService.put<void>(apiUrlMatcher(ApiUrlsEnum.UpdateOrganizationConfig), params);
  }

  public createApplication(params: HttpParamsType<{ companyName: string }>): Observable<void> {
    return this.baseHttpService.post<void>(apiUrlMatcher(ApiUrlsEnum.CreateApplication), params);
  }

  public queryLazyApplications(params: HttpParamsType<OrganizationConfigDetails.ApplicationsQuery>): Observable<LazyLoadResponse<ApplicationData[]>> {
    return this.baseHttpService.lazyLoad<ApplicationData[]>(apiUrlMatcher(ApiUrlsEnum.SearchApplicationsLazy), params);
  }

  public repairLockedOptions(): Observable<number> {
    return this.baseHttpService.get<number>(apiUrlMatcher(ApiUrlsEnum.RepairLockedOptions));
  }
}
