/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GroupedByElementResponseDTO {
  name: string;
  color: string;
  uuidEntity: string;
  childCount: number;
  businessClass: GroupedByElementResponseDTO.BusinessClassEnum;
}
export namespace GroupedByElementResponseDTO {
  export type BusinessClassEnum = 'LABEL_FAMILY' | 'CATEGORY' | 'CUSTOM_STATUS' | 'REPORTER' | 'ASSIGNEE' | 'DEVICE';
  export const BusinessClassEnum = {
    LABELFAMILY: 'LABEL_FAMILY' as BusinessClassEnum,
    CATEGORY: 'CATEGORY' as BusinessClassEnum,
    CUSTOMSTATUS: 'CUSTOM_STATUS' as BusinessClassEnum,
    REPORTER: 'REPORTER' as BusinessClassEnum,
    ASSIGNEE: 'ASSIGNEE' as BusinessClassEnum,
    DEVICE: 'DEVICE' as BusinessClassEnum,
  };
}
