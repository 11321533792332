import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CategoryDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum LabelFamilyProvenanceEnum {
  Local = 1,
  Configuration = 2,
}

export namespace LabelFamilyProvenanceEnum {
  const values: LabelFamilyProvenanceEnum[] = [LabelFamilyProvenanceEnum.Local, LabelFamilyProvenanceEnum.Configuration];

  export const convertFromApiValue: SfxMap<CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum, LabelFamilyProvenanceEnum> = new SfxMap<
    CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum,
    LabelFamilyProvenanceEnum
  >([
    [CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum.LOCAL, LabelFamilyProvenanceEnum.Local],
    [CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum.CONFIGURATION, LabelFamilyProvenanceEnum.Configuration],
  ]);

  export const convertToApiValue: SfxMap<LabelFamilyProvenanceEnum, CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum> = new SfxMap<
    LabelFamilyProvenanceEnum,
    CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum
  >([
    [LabelFamilyProvenanceEnum.Local, CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum.LOCAL],
    [LabelFamilyProvenanceEnum.Configuration, CategoryDetailsResponseDTO.LabelFamilyProvenanceEnum.CONFIGURATION],
  ]);

  export const convertFromParamsValue: SfxMap<string, LabelFamilyProvenanceEnum> = new SfxMap<string, LabelFamilyProvenanceEnum>([
    ['entity', LabelFamilyProvenanceEnum.Local],
    ['default', LabelFamilyProvenanceEnum.Configuration],
  ]);

  export const convertToParamsValue: SfxMap<LabelFamilyProvenanceEnum, string> = new SfxMap<LabelFamilyProvenanceEnum, string>([
    [LabelFamilyProvenanceEnum.Local, 'entity'],
    [LabelFamilyProvenanceEnum.Configuration, 'default'],
  ]);

  const key = 'categories.provenance.';

  export const toString: SfxMap<LabelFamilyProvenanceEnum, string> = new SfxMap<LabelFamilyProvenanceEnum, string>(
    [
      [LabelFamilyProvenanceEnum.Local, key + 'entity'],
      [LabelFamilyProvenanceEnum.Configuration, key + 'default'],
    ],
    'categories.dialog.provenance',
  );

  export const toLetter: SfxMap<LabelFamilyProvenanceEnum, string> = new SfxMap<LabelFamilyProvenanceEnum, string>([[LabelFamilyProvenanceEnum.Local, 'E']], 'D');

  export const color: SfxMap<LabelFamilyProvenanceEnum, string> = new SfxMap<LabelFamilyProvenanceEnum, string>(
    [[LabelFamilyProvenanceEnum.Local, 'sfx-chip-sfx-orange']],
    'sfx-chip-light-blue',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<LabelFamilyProvenanceEnum>[]> =>
    of(
      values.map(provenance => ({
        value: provenance,
        title: toString.getValue(provenance),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<LabelFamilyProvenanceEnum>, item: LabelFamilyProvenanceEnum) => node.value === item;
}
