import { SfxMap } from '../../utils/enum-utils';

export enum BlockFormColumnEnum {
  Family = 'family',
  Name = 'name',
  Reference = 'reference',
  Index = 'index',
  LastUpdate = 'lastUpdate',
  Action = 'action',
  Status = 'status',
  FormTabletAction = 'formTabletAction',
  FormMobileAction = 'formMobileAction',
}

export namespace BlockFormColumnEnum {
  export const allColumns = [
    BlockFormColumnEnum.Family,
    BlockFormColumnEnum.Name,
    BlockFormColumnEnum.Reference,
    BlockFormColumnEnum.Index,
    BlockFormColumnEnum.LastUpdate,
    BlockFormColumnEnum.Action,
  ];

  export const blockMobileAppFormColumns = [BlockFormColumnEnum.Name, BlockFormColumnEnum.FormMobileAction];
  export const blockTabletAppFormColumns = [BlockFormColumnEnum.Name, BlockFormColumnEnum.FormTabletAction];

  export const importTableColumns = [
    BlockFormColumnEnum.Family,
    BlockFormColumnEnum.Name,
    BlockFormColumnEnum.Reference,
    BlockFormColumnEnum.Index,
    BlockFormColumnEnum.LastUpdate,
  ];

  const key = 'blocks.form.table.header.';
  export const toString: SfxMap<BlockFormColumnEnum, string> = new SfxMap<BlockFormColumnEnum, string>([
    [BlockFormColumnEnum.Family, key + 'family'],
    [BlockFormColumnEnum.Name, key + 'name'],
    [BlockFormColumnEnum.Reference, key + 'reference'],
    [BlockFormColumnEnum.Index, key + 'index'],
    [BlockFormColumnEnum.LastUpdate, key + 'lastUpdate'],
    [BlockFormColumnEnum.Action, key + 'action'],
    [BlockFormColumnEnum.Status, key + 'status'],
  ]);

  export const sortField: SfxMap<BlockFormColumnEnum, string> = new SfxMap<BlockFormColumnEnum, string>([
    [BlockFormColumnEnum.Family, 'family'],
    [BlockFormColumnEnum.Name, 'name'],
    [BlockFormColumnEnum.Reference, 'reference'],
    [BlockFormColumnEnum.Index, 'indice'],
    [BlockFormColumnEnum.LastUpdate, 'modificationDate'],
    [BlockFormColumnEnum.Status, 'customStatus'],
  ]);
}
