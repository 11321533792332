import { SfxMap } from '../../utils/enum-utils';

export enum CustomCellColumnEnum {
  Format = 'format',
  Nature = 'nature',
  Name = 'name',
  Field = 'field',
  Action = 'action',
}

export namespace CustomCellColumnEnum {
  export const allColumns = [CustomCellColumnEnum.Format, CustomCellColumnEnum.Nature, CustomCellColumnEnum.Name, CustomCellColumnEnum.Field, CustomCellColumnEnum.Action];

  const key = 'customCells.table.header.';
  export const toString: SfxMap<CustomCellColumnEnum, string> = new SfxMap<CustomCellColumnEnum, string>([
    [CustomCellColumnEnum.Format, key + 'format'],
    [CustomCellColumnEnum.Nature, key + 'nature'],
    [CustomCellColumnEnum.Name, key + 'name'],
    [CustomCellColumnEnum.Field, key + 'field'],
    [CustomCellColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<CustomCellColumnEnum, string> = new SfxMap<CustomCellColumnEnum, string>([
    [CustomCellColumnEnum.Format, 'format'],
    [CustomCellColumnEnum.Nature, 'nature'],
    [CustomCellColumnEnum.Name, 'name'],
    [CustomCellColumnEnum.Field, 'field'],
  ]);
}
