import { ExecuteFlowRequestDTO } from '../../generated/models/executeFlowRequestDTO';

export interface ResetValidationFlowData {
  copyCount: number;
  resetInternalValidation: boolean;
  resetExternalValidation: boolean;
  resetInternalParentValidation: boolean;
  resetInternalChildValidation: boolean;
  resetExternalParentValidation: boolean;
  resetExternalChildValidation: boolean;
  resetDocumentFlows: boolean;
  resetParentDocumentFlows: boolean;
  resetChildDocumentFlows: boolean;
}

export namespace ResetValidationFlowData {
  export function mapToRequestApiValue(request: ResetValidationFlowData): ExecuteFlowRequestDTO {
    return {
      copyCount: request.copyCount,
      resetInternalValidation: request.resetInternalValidation,
      resetExternalValidation: request.resetExternalValidation,
      resetInternalParentValidation: request.resetInternalParentValidation,
      resetInternalChildValidation: request.resetInternalChildValidation,
      resetExternalParentValidation: request.resetExternalParentValidation,
      resetExternalChildValidation: request.resetExternalChildValidation,
      resetDocumentFlows: request.resetDocumentFlows,
      resetParentDocumentFlows: request.resetParentDocumentFlows,
      resetChildDocumentFlows: request.resetChildDocumentFlows,
    };
  }
}
