<div
  class="toaster-container {{ data.type }}"
  [attr.data-cy]="'toaster-container' + (data.dataCy ? '_' + data.dataCy : '')"
  [@fadeAnimation]="{ value: animationState, params: { fadeIn: configData.animationFadeIn, fadeOut: configData.animationFadeOut } }"
  (@fadeAnimation.done)="animationDone($event)"
>
  <div class="toaster-border"></div>
  <ng-container *ngIf="data.template; else defaultTemplateRef">
    <ng-container *ngTemplateOutlet="data.template; context: data.templateContext"> </ng-container>
  </ng-container>
</div>

<ng-template #defaultTemplateRef>
  <div class="toaster-content">
    <sfx-icon class="message-icon" [name]="iconName"></sfx-icon>
    <div class="message">
      <sfx-label [text]="data.title | translate" data-cy="toaster-title"></sfx-label>
      <sfx-label *ngIf="data.subtitle" class="sub-title" data-cy="toaster-message" [showTooltip]="false" [text]="data.subtitle | translate"></sfx-label>
    </div>
    <sfx-icon *ngIf="data.dismissible" (click)="close()" class="dismiss-toggler" data-cy="toaster-close-button" name="times"></sfx-icon>
  </div>
</ng-template>
