export class SfxMap<K, V> {
  private _defaultValue: V;
  private _map: Map<K, V>;

  constructor(entries?: readonly (readonly [K, V])[] | null, defaultValue?: V) {
    this._map = new Map(entries);
    this._defaultValue = defaultValue;
  }

  getValue(key: K): V | undefined {
    return this.has(key) ? this._map.get(key) : this._defaultValue;
  }

  clear(): void {
    this._map.clear();
  }

  delete(key: K): boolean {
    return this._map.delete(key);
  }

  has(key: K): boolean {
    return this._map.has(key);
  }

  set(key: K, value: V): Map<K, V> {
    return this._map.set(key, value);
  }

  get size(): number {
    return this._map.size;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    return this._map.forEach(callbackfn, thisArg);
  }
}
