export class SfsZendeskWidget {
  constructor(jwtToken) {
    zE('messenger', 'hide');
    zE('messenger:on', 'open', function () {
      zE('messenger', 'show');
    });
    zE('messenger', 'loginUser', function (callback) {
      callback(jwtToken);
    });
  }

  static hide() {
    if (window['zE']) {
      zE('messenger', 'hide');
    }
  }

  static open() {
    if (window['zE']) {
      zE('messenger', 'open');
    }
  }

  static logout() {
    if (window['zE']) {
      zE('messenger', 'logoutUser');
    }
  }
}
