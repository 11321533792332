import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomFieldDetailsResponseDTO } from '../../generated/models/customFieldDetailsResponseDTO';
import { PhaseCustomAttributeValueResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';

export enum FieldTypeEnum {
  Text = 1,
  TextArea,
  Label,
  Integer,
  Double,
  BooleanField,
  Date,
  DateTime,
  Select,
  MultiSelect,
  File,
  Image,
  Metadata,
  Link,
}

export namespace FieldTypeEnum {
  const values: FieldTypeEnum[] = [
    FieldTypeEnum.Text,
    FieldTypeEnum.TextArea,
    FieldTypeEnum.Label,
    FieldTypeEnum.Integer,
    FieldTypeEnum.Double,
    FieldTypeEnum.BooleanField,
    FieldTypeEnum.Date,
    FieldTypeEnum.DateTime,
    FieldTypeEnum.Select,
    FieldTypeEnum.MultiSelect,
    FieldTypeEnum.File,
    FieldTypeEnum.Image,
    FieldTypeEnum.Metadata,
    FieldTypeEnum.Link,
  ];

  export const valuesByBusinessClassName = new SfxMap<BusinessClassNameEnum, FieldTypeEnum[]>(
    [
      [
        BusinessClassNameEnum.Phase,
        [FieldTypeEnum.Text, FieldTypeEnum.TextArea, FieldTypeEnum.Integer, FieldTypeEnum.Double, FieldTypeEnum.BooleanField, FieldTypeEnum.Date, FieldTypeEnum.DateTime],
      ],
      [
        BusinessClassNameEnum.Step,
        [FieldTypeEnum.Text, FieldTypeEnum.TextArea, FieldTypeEnum.Integer, FieldTypeEnum.Double, FieldTypeEnum.BooleanField, FieldTypeEnum.Date, FieldTypeEnum.DateTime],
      ],
    ],
    values,
  );

  export const available = (type: FieldTypeEnum): boolean => [FieldTypeEnum.Text, FieldTypeEnum.TextArea].includes(type);

  export const convertFromApiValue = (longText: boolean = false): SfxMap<CustomFieldDetailsResponseDTO.TypeFieldEnum, FieldTypeEnum> =>
    new SfxMap<CustomFieldDetailsResponseDTO.TypeFieldEnum, FieldTypeEnum>([
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.STRING, longText ? FieldTypeEnum.TextArea : FieldTypeEnum.Text],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.LABEL, FieldTypeEnum.Label],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.INTEGER, FieldTypeEnum.Integer],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.DOUBLE, FieldTypeEnum.Double],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.BOOLEAN, FieldTypeEnum.BooleanField],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.DATE, FieldTypeEnum.Date],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.DATETIME, FieldTypeEnum.DateTime],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.ENUMSINGLESELECTION, FieldTypeEnum.Select],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.ENUMMULTIPLESELECTIONS, FieldTypeEnum.MultiSelect],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.FILE, FieldTypeEnum.File],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.IMAGE, FieldTypeEnum.Image],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.METADATA, FieldTypeEnum.Metadata],
      [CustomFieldDetailsResponseDTO.TypeFieldEnum.LINK, FieldTypeEnum.Link],
    ]);

  export const convertFromCustomAttributesApiValue = (longText: boolean = false): SfxMap<PhaseCustomAttributeValueResponseDTO.DataTypeEnum, FieldTypeEnum> =>
    new SfxMap<PhaseCustomAttributeValueResponseDTO.DataTypeEnum, FieldTypeEnum>([
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.TEXT, longText ? FieldTypeEnum.TextArea : FieldTypeEnum.Text],
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.INTEGER, FieldTypeEnum.Integer],
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.FLOAT, FieldTypeEnum.Double],
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.BOOLEAN, FieldTypeEnum.BooleanField],
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.DATE, FieldTypeEnum.Date],
      [PhaseCustomAttributeValueResponseDTO.DataTypeEnum.DATETIME, FieldTypeEnum.DateTime],
    ]);

  export const convertToApiValue: SfxMap<FieldTypeEnum, CustomFieldDetailsResponseDTO.TypeFieldEnum> = new SfxMap<FieldTypeEnum, CustomFieldDetailsResponseDTO.TypeFieldEnum>([
    [FieldTypeEnum.Text, CustomFieldDetailsResponseDTO.TypeFieldEnum.STRING],
    [FieldTypeEnum.TextArea, CustomFieldDetailsResponseDTO.TypeFieldEnum.STRING],
    [FieldTypeEnum.Label, CustomFieldDetailsResponseDTO.TypeFieldEnum.LABEL],
    [FieldTypeEnum.Integer, CustomFieldDetailsResponseDTO.TypeFieldEnum.INTEGER],
    [FieldTypeEnum.Double, CustomFieldDetailsResponseDTO.TypeFieldEnum.DOUBLE],
    [FieldTypeEnum.BooleanField, CustomFieldDetailsResponseDTO.TypeFieldEnum.BOOLEAN],
    [FieldTypeEnum.Date, CustomFieldDetailsResponseDTO.TypeFieldEnum.DATE],
    [FieldTypeEnum.DateTime, CustomFieldDetailsResponseDTO.TypeFieldEnum.DATETIME],
    [FieldTypeEnum.Select, CustomFieldDetailsResponseDTO.TypeFieldEnum.ENUMSINGLESELECTION],
    [FieldTypeEnum.MultiSelect, CustomFieldDetailsResponseDTO.TypeFieldEnum.ENUMMULTIPLESELECTIONS],
    [FieldTypeEnum.File, CustomFieldDetailsResponseDTO.TypeFieldEnum.FILE],
    [FieldTypeEnum.Image, CustomFieldDetailsResponseDTO.TypeFieldEnum.IMAGE],
    [FieldTypeEnum.Metadata, CustomFieldDetailsResponseDTO.TypeFieldEnum.METADATA],
    [FieldTypeEnum.Link, CustomFieldDetailsResponseDTO.TypeFieldEnum.LINK],
  ]);

  export const toString = (concatName?: boolean): SfxMap<FieldTypeEnum, string> => {
    const key = 'attributes.dialog.' + (concatName ? 'concatName.' : 'customFieldTypes.');

    return new SfxMap<FieldTypeEnum, string>([
      [FieldTypeEnum.Text, key + 'text'],
      [FieldTypeEnum.TextArea, key + 'LongText'],
      [FieldTypeEnum.Label, key + 'title'],
      [FieldTypeEnum.Integer, key + 'integer'],
      [FieldTypeEnum.Double, key + 'double'],
      [FieldTypeEnum.BooleanField, key + 'boolean'],
      [FieldTypeEnum.Date, key + 'date'],
      [FieldTypeEnum.DateTime, key + 'dateTime'],
      [FieldTypeEnum.Select, key + 'select'],
      [FieldTypeEnum.MultiSelect, key + 'multiSelect'],
      [FieldTypeEnum.File, key + 'file'],
      [FieldTypeEnum.Image, key + 'image'],
      [FieldTypeEnum.Metadata, key + 'metadata'],
      [FieldTypeEnum.Link, key + 'link'],
    ]);
  };

  export const itemsResolver = (businessClassName): Observable<SingleSelectItemData<FieldTypeEnum>[]> =>
    of(
      valuesByBusinessClassName.getValue(businessClassName).map(type => ({
        value: type,
        title: FieldTypeEnum.toString().getValue(type),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FieldTypeEnum>, item: FieldTypeEnum) => node.value === item;

  export const defaultValueType: SfxMap<FieldTypeEnum, string> = new SfxMap<FieldTypeEnum, string>(
    [
      [FieldTypeEnum.Integer, 'text'],
      [FieldTypeEnum.Double, 'text'],
      [FieldTypeEnum.Date, 'date'],
      [FieldTypeEnum.DateTime, 'datetime-local'],
      [FieldTypeEnum.Link, 'link'],
    ],
    'text',
  );

  export const typeWithoutRequiredOption = [FieldTypeEnum.BooleanField, FieldTypeEnum.Metadata, FieldTypeEnum.Link, FieldTypeEnum.Label];
  export const defaultValueControl = [FieldTypeEnum.Text, FieldTypeEnum.TextArea, FieldTypeEnum.Date, FieldTypeEnum.DateTime, FieldTypeEnum.Link];
  export const fieldOptionsControl = [FieldTypeEnum.Select, FieldTypeEnum.MultiSelect];
  export const placeholderControl = [
    FieldTypeEnum.Text,
    FieldTypeEnum.TextArea,
    FieldTypeEnum.Integer,
    FieldTypeEnum.Double,
    FieldTypeEnum.Select,
    FieldTypeEnum.MultiSelect,
    FieldTypeEnum.Link,
  ];
  export const pdfControl = [
    FieldTypeEnum.Text,
    FieldTypeEnum.TextArea,
    FieldTypeEnum.Integer,
    FieldTypeEnum.Double,
    FieldTypeEnum.Select,
    FieldTypeEnum.MultiSelect,
    FieldTypeEnum.BooleanField,
    FieldTypeEnum.Date,
    FieldTypeEnum.DateTime,
    FieldTypeEnum.Image,
    FieldTypeEnum.Metadata,
  ];
  export const dynamicVariableControl = [
    FieldTypeEnum.Text,
    FieldTypeEnum.TextArea,
    FieldTypeEnum.Integer,
    FieldTypeEnum.Double,
    FieldTypeEnum.Select,
    FieldTypeEnum.MultiSelect,
    FieldTypeEnum.BooleanField,
    FieldTypeEnum.Date,
    FieldTypeEnum.DateTime,
    FieldTypeEnum.Link,
  ];

  export const toStringBoolean: SfxMap<boolean, string> = new SfxMap<boolean, string>([
    [true, 'attributes.dialog.true'],
    [false, 'attributes.dialog.false'],
  ]);

  export const booleanSingleSelectItems = (): Observable<SingleSelectItemData<boolean>[]> => of([true, false].map(value => ({ value, title: toStringBoolean.getValue(value) })));

  export const cantHaveCustomCardAttributesDisplayProperties = [FieldTypeEnum.BooleanField, FieldTypeEnum.Image, FieldTypeEnum.File, FieldTypeEnum.Label];
  export const hasNoOptions = [FieldTypeEnum.Label];
}
