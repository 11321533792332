import { PlanBusinessClassNameEnum } from '../../enums';
import { GroupedByElementResponseDTO } from '../../generated/models/models';
import { BaseElementPlanSummaryData } from './base-element-plan-summary-data';

export interface GroupedByElementSummaryData extends BaseElementPlanSummaryData {
  businessClass: PlanBusinessClassNameEnum;
  color: string;
  childrenCount: number;
  tooltip?: string;
}

export namespace GroupedByElementSummaryData {
  export function mapFromApiValue(element: GroupedByElementResponseDTO): GroupedByElementSummaryData {
    return {
      name: element.name,
      businessClass: PlanBusinessClassNameEnum.convertFromApiValue.getValue(element.businessClass),
      uuidEntity: element.uuidEntity,
      color: element.color || '#0d4e8a',
      nodeOrder: null,
      childrenCount: element.childCount,
    };
  }
}
