import { createAction, props } from '@ngrx/store';

import { AccessApiV2DetailsData, AccessApiV2PropertiesData } from '../../../../../../core/models';
import { DialogToasterData } from '../../../../../sfx-dialog/state/dialog-toaster-data';

export const setLoadingData = createAction('[accessApiV2] set loading state', props<{ saveLoading: boolean }>());

export const addAccessApiV2 = createAction('[accessApiV2] add new category', props<{ accessApi: AccessApiV2DetailsData }>());
export const addAccessApiV2Success = createAction('[accessApiV2] add new accessApi success');
export const addAccessApiV2Fail = createAction('[accessApiV2] add new accessApi fail', props<Error>());

export const updateAccessApiV2 = createAction('[accessApiV2] update accessApi', props<{ accessApi: AccessApiV2DetailsData }>());
export const updateAccessApiV2Success = createAction('[accessApiV2] update accessApi success');
export const updateAccessApiV2Fail = createAction('[accessApiV2] update accessApi fail', props<Error>());

export const getAccessApiV2DetailsSuccess = createAction('[accessApiV2] get accessApi details success', props<{ accessApi: Partial<AccessApiV2DetailsData> }>());
export const getAccessApiV2DetailsFail = createAction('[accessApiV2] get accessApi details fail', props<DialogToasterData>());

export const getAccessApiV2PropertiesSuccess = createAction('[accessApiV2] get accessApi properties success', props<{ properties: AccessApiV2PropertiesData }>());
export const getAccessApiV2PropertiesFail = createAction('[accessApiV2] get accessApi properties fail', props<DialogToasterData>());
