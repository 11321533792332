import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { CTabletDetailsData, TabletSendEmailData } from '../../../../../core/models';

export const loadTabletDetails = createAction('[cTablets/API] Load tablet details data', props<CTabletDetailsData>());
export const loadTabletDetailsSuccess = createAction('[cTablets/API] load tablet details data success', props<CTabletDetailsData>());
export const loadTabletDetailsFail = createAction('[cTablets/API] load tablet details data fail', props<DialogToasterData>());

export const createTablet = createAction('[cTablets/API] create tablet', props<{ tablet: CTabletDetailsData; editMode: boolean; addNewOne?: boolean }>());
export const createTabletSuccess = createAction('[cTablets/API] create tablet success', props<{ tablet: CTabletDetailsData; addNewOne?: boolean }>());
export const createTabletFail = createAction('[cTablets/API] create tablet fail', props<DialogToasterData>());

export const updateTablet = createAction('[cTablets/API] update tablet', props<{ tablet: CTabletDetailsData; editMode: boolean; addNewOne?: boolean }>());
export const updateTabletSuccess = createAction('[cTablets/API] update tablet success', props<{ tablet: CTabletDetailsData; close?: boolean }>());
export const updateTabletFail = createAction('[cTablets/API] update tablet fail', props<DialogToasterData>());

export const sendEmail = createAction('[cTablets/API] tablet send email', props<TabletSendEmailData>());
export const sendEmailSuccess = createAction('[cTablets/API] tablet send email success');
export const sendEmailFail = createAction('[cTablets/API] tablet send email fail', props<DialogToasterData>());

export const unblockTablet = createAction('[cTablets/API] unblock tablet', props<{ tabletUuidEntity: string }>());
export const unblockTabletSuccess = createAction('[cTablets/API] unblock tablet success');
export const unblockTabletFail = createAction('[cTablets/API] unblock tablet fail', props<DialogToasterData>());

export const resetTabletDialogState = createAction('[cTablets/API] reset tablet dialog state');

export const tabletDetailNavigation = createAction('[cTablets/API] Navigate through details tablets', props<{ tabletUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[cTablets/API] confirm navigate details tablets', props<{ tablet: CTabletDetailsData; step: number }>());
