/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

export interface TemplateParamListRequestDTO {
  naturePage?: TemplateParamListRequestDTO.NaturePageEnum;
  page: number;
  pageType?: Array<TemplateParamListRequestDTO.PageTypeEnum>;
  size: number;
  sort?: SortDto;
  textSearch?: string;
}
export namespace TemplateParamListRequestDTO {
  export type NaturePageEnum = 'DEFAULT' | 'CLIENT' | 'FAMILY';
  export const NaturePageEnum = {
    DEFAULT: 'DEFAULT' as NaturePageEnum,
    CLIENT: 'CLIENT' as NaturePageEnum,
    FAMILY: 'FAMILY' as NaturePageEnum,
  };
  export type PageTypeEnum =
    | 'COVER_PAGE'
    | 'PAGE_CONTENT_SUIVI'
    | 'PAGE_CONTENT_H'
    | 'PAGE_CONTENT_V'
    | 'COVER_PAGE_DSI'
    | 'PAGE_CONTENT_SUIVI_DSI'
    | 'PAGE_SUIVI_SIGNATURE_DSI'
    | 'PAGE_H_DSI'
    | 'PAGE_V_DSI'
    | 'PAGE_EXPLICATION_DSI'
    | 'COVER_PAGE_RFI'
    | 'PAGE_CONTENT_RFI'
    | 'PAGE_SOMMAIRE_RFI'
    | 'PAGE_SECTION_RFI'
    | 'FORM_GENERIC'
    | 'FORM_MODEL'
    | 'FORM_GENERIC_H'
    | 'FORM_MODEL_H'
    | 'FORM_CONTENT'
    | 'FORM_TEMPLATE'
    | 'PAGE_ANNEXE'
    | 'COVER_PAGE_LDA'
    | 'PAGE_CONTENT_SUIVI_RFI'
    | 'PAGE_SKILLS'
    | 'PAGE_H_LCA'
    | 'COVER_PAGE_LCA'
    | 'COVER_PAGE_MULTI_PR'
    | 'PAGE_CONTENT_SUIVI_MULTI_PR'
    | 'PAGE_CONTENT_V_MULTI_PR'
    | 'PAGE_ANNEXE_MULTI_PR'
    | 'COVER_PAGE_CLIENT'
    | 'COVER_PAGE_DIAGRAM'
    | 'PAGE_H_DIAGRAM'
    | 'PAGE_CONTENT_SUIVI_DIAGRAM';
  export const PageTypeEnum = {
    COVERPAGE: 'COVER_PAGE' as PageTypeEnum,
    PAGECONTENTSUIVI: 'PAGE_CONTENT_SUIVI' as PageTypeEnum,
    PAGECONTENTH: 'PAGE_CONTENT_H' as PageTypeEnum,
    PAGECONTENTV: 'PAGE_CONTENT_V' as PageTypeEnum,
    COVERPAGEDSI: 'COVER_PAGE_DSI' as PageTypeEnum,
    PAGECONTENTSUIVIDSI: 'PAGE_CONTENT_SUIVI_DSI' as PageTypeEnum,
    PAGESUIVISIGNATUREDSI: 'PAGE_SUIVI_SIGNATURE_DSI' as PageTypeEnum,
    PAGEHDSI: 'PAGE_H_DSI' as PageTypeEnum,
    PAGEVDSI: 'PAGE_V_DSI' as PageTypeEnum,
    PAGEEXPLICATIONDSI: 'PAGE_EXPLICATION_DSI' as PageTypeEnum,
    COVERPAGERFI: 'COVER_PAGE_RFI' as PageTypeEnum,
    PAGECONTENTRFI: 'PAGE_CONTENT_RFI' as PageTypeEnum,
    PAGESOMMAIRERFI: 'PAGE_SOMMAIRE_RFI' as PageTypeEnum,
    PAGESECTIONRFI: 'PAGE_SECTION_RFI' as PageTypeEnum,
    FORMGENERIC: 'FORM_GENERIC' as PageTypeEnum,
    FORMMODEL: 'FORM_MODEL' as PageTypeEnum,
    FORMGENERICH: 'FORM_GENERIC_H' as PageTypeEnum,
    FORMMODELH: 'FORM_MODEL_H' as PageTypeEnum,
    FORMCONTENT: 'FORM_CONTENT' as PageTypeEnum,
    FORMTEMPLATE: 'FORM_TEMPLATE' as PageTypeEnum,
    PAGEANNEXE: 'PAGE_ANNEXE' as PageTypeEnum,
    COVERPAGELDA: 'COVER_PAGE_LDA' as PageTypeEnum,
    PAGECONTENTSUIVIRFI: 'PAGE_CONTENT_SUIVI_RFI' as PageTypeEnum,
    PAGESKILLS: 'PAGE_SKILLS' as PageTypeEnum,
    PAGEHLCA: 'PAGE_H_LCA' as PageTypeEnum,
    COVERPAGELCA: 'COVER_PAGE_LCA' as PageTypeEnum,
    COVERPAGEMULTIPR: 'COVER_PAGE_MULTI_PR' as PageTypeEnum,
    PAGECONTENTSUIVIMULTIPR: 'PAGE_CONTENT_SUIVI_MULTI_PR' as PageTypeEnum,
    PAGECONTENTVMULTIPR: 'PAGE_CONTENT_V_MULTI_PR' as PageTypeEnum,
    PAGEANNEXEMULTIPR: 'PAGE_ANNEXE_MULTI_PR' as PageTypeEnum,
    COVERPAGECLIENT: 'COVER_PAGE_CLIENT' as PageTypeEnum,
    COVERPAGEDIAGRAM: 'COVER_PAGE_DIAGRAM' as PageTypeEnum,
    PAGEHDIAGRAM: 'PAGE_H_DIAGRAM' as PageTypeEnum,
    PAGECONTENTSUIVIDIAGRAM: 'PAGE_CONTENT_SUIVI_DIAGRAM' as PageTypeEnum,
  };
}
