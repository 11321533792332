import { FlowSummaryData } from '../flows/flow-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { TeamRoleDeleteResponseDTO } from '../../generated/models/models';

export interface TabletDeleteResponseData {
  success: boolean;
  links: FlowSummaryData[];
}

export namespace TabletDeleteResponseData {
  export function mapFromApiValue(deleteResponse: TeamRoleDeleteResponseDTO): TabletDeleteResponseData {
    return {
      success: deleteResponse.success,
      links: (deleteResponse.links || []).map(flow => FlowSummaryData.mapFromApiValue(flow)),
    };
  }

  export function mapToConflictData(deleteResponse: TabletDeleteResponseData): ConflictData {
    return {
      title: 'global.entityConflict.hint.tablet.flowsLink',
      links: (deleteResponse.links || []).map(flow => FlowSummaryData.toString(flow)),
    };
  }
}
