import { EntityChronoCheckRequestDTO } from '../../generated/models/models';
import { BusinessClassNameEnum, FieldNameEnum } from '../../enums';

export interface CheckExistedReferenceRequest {
  labelFamilyUuidEntity: string;
  businessClass?: BusinessClassNameEnum;
  fieldName?: FieldNameEnum;
  parentLabelFamilyUuidEntity?: string;
  reset?: boolean;
}

export namespace CheckExistedReferenceRequest {
  export function mapToRequestApiValue(data: CheckExistedReferenceRequest): EntityChronoCheckRequestDTO {
    return {
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(data.businessClass),
      fieldName: FieldNameEnum.convertToApiValue.getValue(data.fieldName),
      labelFamilyUuidEntity: data.labelFamilyUuidEntity,
      parentLabelFamilyUuidEntity: data.parentLabelFamilyUuidEntity,
    };
  }
}
