import { Component, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TranslateModule } from '@ngx-translate/core';

import { ContextMenuService } from '../../../../core/services/context-menu.service';
import { IconComponent } from '../icon/icon.component';
import { LabelComponent } from '../label/label.component';

import { CustomMenuActionEnum, SpecialCharactersEnum } from '../../../../core/enums';

@Component({
  selector: 'sfx-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, ClipboardModule, TranslateModule, IconComponent, forwardRef(() => LabelComponent)],
})
export class VerticalMenuComponent {
  public SpecialCharactersEnum = SpecialCharactersEnum;
  public CustomMenuActionEnum = CustomMenuActionEnum;

  constructor(private contextMenuService: ContextMenuService) {}

  public sendAction(action: CustomMenuActionEnum): void {
    this.contextMenuService.sendAction(action);
  }
}
