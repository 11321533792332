import { EntityChronoSummaryResponseDTO } from '../../generated/models/entityChronoSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum ReferenceColumnEnum {
  Nature = 'nature',
  Field = 'field',
  Nomenclature = 'nomenclature',
  Action = 'action',
}

export namespace ReferenceColumnEnum {
  export type referenceSortField = keyof EntityChronoSummaryResponseDTO;

  export const allColumns = [ReferenceColumnEnum.Nature, ReferenceColumnEnum.Field, ReferenceColumnEnum.Nomenclature, ReferenceColumnEnum.Action];

  const key = 'referenceTranslateKey.table.header.';
  export const toString: SfxMap<ReferenceColumnEnum, string> = new SfxMap<ReferenceColumnEnum, string>([
    [ReferenceColumnEnum.Nature, key + 'nature'],
    [ReferenceColumnEnum.Field, key + 'field'],
    [ReferenceColumnEnum.Nomenclature, key + 'nomenclature'],
    [ReferenceColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<ReferenceColumnEnum, referenceSortField> = new SfxMap<ReferenceColumnEnum, referenceSortField>([
    [ReferenceColumnEnum.Nature, 'nature'],
    [ReferenceColumnEnum.Field, 'fieldName'],
  ]);
}
