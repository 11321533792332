import { createAction, props } from '@ngrx/store';
import { ApplicationEventLightData, LazyLoadResponse } from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

export const loadHistoryListSuccess = createAction(
  '[dFieldFeedbackDialog] Load history list success',
  props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>(),
);
export const loadHistoryListNextPage = createAction('[dFieldFeedbackDialog] Load history list next page');
export const loadHistoryListFails = createAction('[dFieldFeedbackDialog] Load history list fails', props<DialogToasterData>());
