import { BlocDsiDetailsResponsesDTO, BlocDsiUpdateRequestDTO } from '../../generated/models/models';
import { SignatureLightData } from '../signatures/signature-light-data';

import { BlockSignaturesStatusEnum, SignatureNatureEnum } from '../../enums';

export interface BlockDsiDetailsData {
  signatoryDisabledComment: string;
  technicalControlStep: boolean;
  technicalControlDetails: string;
  signatureStatus: BlockSignaturesStatusEnum;
  documentRequired: boolean;
  documentReference: string;
  observation: string;
  uuidEntity: string;
  aip: boolean;
  isMonoInternSupervisor?: boolean;
  isMonoExternSupervisor?: boolean;

  // supervisors

  firstInternSupervisor: BlockDsiDetailsData.SupervisionData;
  secondInternSupervisor: BlockDsiDetailsData.SupervisionData;
  thirdInternSupervisor: BlockDsiDetailsData.SupervisionData;

  firstExternSupervisor: BlockDsiDetailsData.SupervisionData;
  secondExternSupervisor: BlockDsiDetailsData.SupervisionData;
  thirdExternSupervisor: BlockDsiDetailsData.SupervisionData;

  // signatures
  realization: BlockDsiDetailsData.SignatureTypeData;
  technicalControl: BlockDsiDetailsData.SignatureTypeData;

  internConvocationFirstSupervisor: BlockDsiDetailsData.SignatureTypeData;
  internConvocationSecondSupervisor: BlockDsiDetailsData.SignatureTypeData;
  internConvocationThirdSupervisor: BlockDsiDetailsData.SignatureTypeData;

  externConvocationFirstSupervisor: BlockDsiDetailsData.SignatureTypeData;
  externConvocationSecondSupervisor: BlockDsiDetailsData.SignatureTypeData;
  externConvocationThirdSupervisor: BlockDsiDetailsData.SignatureTypeData;

  internBreakPointFirstSupervisor: BlockDsiDetailsData.SignatureTypeData;
  internBreakPointSecondSupervisor: BlockDsiDetailsData.SignatureTypeData;
  internBreakPointThirdSupervisor: BlockDsiDetailsData.SignatureTypeData;

  externBreakPointFirstSupervisor: BlockDsiDetailsData.SignatureTypeData;
  externBreakPointSecondSupervisor: BlockDsiDetailsData.SignatureTypeData;
  externBreakPointThirdSupervisor: BlockDsiDetailsData.SignatureTypeData;
}

export namespace BlockDsiDetailsData {
  export const protectedSignatures: (keyof BlockDsiDetailsData)[] = [
    'realization',
    'technicalControl',
    'internConvocationFirstSupervisor',
    'internConvocationSecondSupervisor',
    'internConvocationThirdSupervisor',
    'externConvocationFirstSupervisor',
    'externConvocationSecondSupervisor',
    'externConvocationThirdSupervisor',
    'internBreakPointFirstSupervisor',
    'internBreakPointSecondSupervisor',
    'internBreakPointThirdSupervisor',
    'externBreakPointFirstSupervisor',
    'externBreakPointSecondSupervisor',
    'externBreakPointThirdSupervisor',
  ];

  export interface SupervisionData {
    active: boolean;
    name: string;
  }

  export interface SignatureTypeData {
    signatureNature: SignatureNatureEnum;
    signature: SignatureLightData;
    active: boolean;
    disabled: boolean;
  }

  export interface BlockDsiSupervisorsData {
    intern: BlockDsiDetailsData.SupervisionData[];
    extern: BlockDsiDetailsData.SupervisionData[];
  }

  function getSignatureByNature(signatures: SignatureLightData[], nature: SignatureNatureEnum): SignatureLightData {
    return signatures.find(sign => sign.signatureNature === nature);
  }
  function getSignatureData(active: boolean, blockDsi: BlocDsiDetailsResponsesDTO, nature: SignatureNatureEnum): BlockDsiDetailsData.SignatureTypeData {
    const signatures = blockDsi.listSignatories.map(_signature => SignatureLightData.mapFromApiValue(_signature, blockDsi));
    const signature = getSignatureByNature(signatures, nature);

    return { active, disabled: !!signature, signature: signature || getEmptySignature(nature), signatureNature: nature };
  }

  export function mapFromApiValue(blockDsi: BlocDsiDetailsResponsesDTO): BlockDsiDetailsData {
    const isMonoInternSupervisor = !blockDsi.supervisionIntern1 && !blockDsi.supervisionIntern2 && !blockDsi.supervisionIntern3;
    const isMonoExternSupervisor = !blockDsi.supervisionExtern1 && !blockDsi.supervisionExtern2 && !blockDsi.supervisionExtern3;

    return {
      uuidEntity: blockDsi.uuidEntity,
      technicalControlStep: blockDsi.technicalControlStep,
      technicalControlDetails: blockDsi.technicalControlDetails,
      aip: blockDsi.aip,
      documentRequired: blockDsi.documentRequired,
      signatureStatus: BlockSignaturesStatusEnum.convertFromApiValue.getValue(blockDsi.signatoriesStatus),
      observation: blockDsi.observation,
      documentReference: blockDsi.reference,
      signatoryDisabledComment: blockDsi.signatoryDisabledComment,
      isMonoInternSupervisor,
      isMonoExternSupervisor,
      // first supervisor
      firstInternSupervisor: {
        active: isMonoInternSupervisor || blockDsi.supervisionIntern1,
        name: !isMonoInternSupervisor ? blockDsi.supervisionNameIntern1 : undefined,
      },
      // second supervisor
      secondInternSupervisor: { active: blockDsi.supervisionIntern2, name: !isMonoInternSupervisor ? blockDsi.supervisionNameIntern2 : undefined },
      // third supervisor
      thirdInternSupervisor: { active: blockDsi.supervisionIntern3, name: !isMonoInternSupervisor ? blockDsi.supervisionNameIntern3 : undefined },
      // first supervisor
      firstExternSupervisor: { active: blockDsi.supervisionExtern1 || isMonoExternSupervisor, name: !isMonoExternSupervisor ? blockDsi.supervisionNameExtern1 : undefined },
      // second supervisor
      secondExternSupervisor: { active: blockDsi.supervisionExtern2, name: !isMonoExternSupervisor ? blockDsi.supervisionNameExtern2 : undefined },
      // third supervisor
      thirdExternSupervisor: { active: blockDsi.supervisionExtern3, name: !isMonoExternSupervisor ? blockDsi.supervisionNameExtern3 : undefined },
      // signature realization
      realization: getSignatureData(true, blockDsi, SignatureNatureEnum.Realization),
      // signature technical control
      technicalControl: getSignatureData(blockDsi.technicalControl, blockDsi, SignatureNatureEnum.ControlTech),
      // signature intern convocation first supervisor
      internConvocationFirstSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionIntern1, blockDsi, SignatureNatureEnum.ConvocationIntern),
      // signature intern convocation second supervisor
      internConvocationSecondSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionIntern2, blockDsi, SignatureNatureEnum.ConvocationIntern2),
      // signature intern convocation third supervisor
      internConvocationThirdSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionIntern3, blockDsi, SignatureNatureEnum.ConvocationIntern3),
      // signature extern convocation first supervisor
      externConvocationFirstSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionExtern1, blockDsi, SignatureNatureEnum.ConvocationExtern),
      // signature extern convocation second supervisor
      externConvocationSecondSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionExtern2, blockDsi, SignatureNatureEnum.ConvocationExtern2),
      // signature extern convocation third supervisor
      externConvocationThirdSupervisor: getSignatureData(blockDsi.signatureConvocationSupervisionExtern3, blockDsi, SignatureNatureEnum.ConvocationExtern3),
      // signature intern breakPoint first supervisor
      internBreakPointFirstSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionIntern1, blockDsi, SignatureNatureEnum.BreakPointIntern),
      // signature intern breakPoint second supervisor
      internBreakPointSecondSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionIntern2, blockDsi, SignatureNatureEnum.BreakPointIntern2),
      // signature intern breakPoint third supervisor
      internBreakPointThirdSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionIntern3, blockDsi, SignatureNatureEnum.BreakPointIntern3),
      // signature extern breakPoint first supervisor
      externBreakPointFirstSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionExtern1, blockDsi, SignatureNatureEnum.BreakPointExtern),
      // signature extern breakPoint second supervisor
      externBreakPointSecondSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionExtern2, blockDsi, SignatureNatureEnum.BreakPointExtern2),
      // signature extern breakPoint third supervisor
      externBreakPointThirdSupervisor: getSignatureData(blockDsi.signatureBreakpointSupervisionExtern3, blockDsi, SignatureNatureEnum.BreakPointExtern3),
    };
  }

  export function mapToUpdateRequestApiValue(projectUuidEntity: string, blockDsi: BlockDsiDetailsData): BlocDsiUpdateRequestDTO {
    return {
      uuideProject: projectUuidEntity,
      elementDsiUuide: blockDsi.uuidEntity,
      signatoriesStatus: BlockSignaturesStatusEnum.convertToApiValue.getValue(blockDsi.signatureStatus),
      aip: blockDsi.aip,
      documentRequired: blockDsi.documentRequired,
      technicalControlDetails: blockDsi.technicalControlDetails,
      technicalControlStep: blockDsi.technicalControlStep,
      technicalControl: blockDsi.technicalControl?.active,
      signatoryDisabledComment: blockDsi.signatoryDisabledComment,
      observation: blockDsi.observation,
      reference: blockDsi.documentReference,

      signatureConvocationSupervisionIntern1: blockDsi.internConvocationFirstSupervisor.active,
      signatureConvocationSupervisionIntern2: blockDsi.internConvocationSecondSupervisor.active,
      signatureConvocationSupervisionIntern3: blockDsi.internConvocationThirdSupervisor.active,

      signatureBreakpointSupervisionIntern1: blockDsi.internBreakPointFirstSupervisor.active,
      signatureBreakpointSupervisionIntern2: blockDsi.internBreakPointSecondSupervisor.active,
      signatureBreakpointSupervisionIntern3: blockDsi.internBreakPointThirdSupervisor.active,

      signatureBreakpointSupervisionExtern1: blockDsi.externBreakPointFirstSupervisor.active,
      signatureBreakpointSupervisionExtern2: blockDsi.externBreakPointSecondSupervisor.active,
      signatureBreakpointSupervisionExtern3: blockDsi.externBreakPointThirdSupervisor.active,

      signatureConvocationSupervisionExtern1: blockDsi.externConvocationFirstSupervisor.active,
      signatureConvocationSupervisionExtern2: blockDsi.externConvocationSecondSupervisor.active,
      signatureConvocationSupervisionExtern3: blockDsi.externConvocationThirdSupervisor.active,
    };
  }
  const getEmptySignature = (nature: SignatureNatureEnum): SignatureLightData => (nature ? { uuidEntity: null, signatureNature: nature } : undefined);
}
