/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Project created by the user
 */
export interface ProjectCreateRequestDTO {
  typeProject: ProjectCreateRequestDTO.TypeProjectEnum;
  designation: string;
  businessRef?: string;
  contractRef?: string;
  codeProject?: string;
  pgacActivities?: string;
  clientRef: string;
  locationLibelle?: string;
  startDate?: string;
  endDate?: string;
  labelFamilyApplicationUuidEntity?: string;
  description?: string;
  isSupervised?: boolean;
  supervision1?: string;
  supervision2?: string;
  supervision3?: string;
  supervisionIntern1?: string;
  supervisionIntern2?: string;
  supervisionIntern3?: string;
  isContractual?: boolean;
  internalCommand?: string;
  externalCommand?: string;
  marketNumber?: string;
  technicalDeal?: string;
  keywords?: string;
  businessDomain?: string;
  nationalProgram?: string;
  exportLanguageOne?: ProjectCreateRequestDTO.ExportLanguageOneEnum;
  exportLanguageTwo?: ProjectCreateRequestDTO.ExportLanguageTwoEnum;
  complementRefOne?: string;
  complementRefTwo?: string;
  folderUuidEntity?: string;
  index?: string;
  customStatusUuidEntity?: string;
  internSupervised?: boolean;
}
export namespace ProjectCreateRequestDTO {
  export type TypeProjectEnum = 'PROJECT' | 'REFERENTIAL' | 'TEMPLATE' | 'PGAC';
  export const TypeProjectEnum = {
    PROJECT: 'PROJECT' as TypeProjectEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeProjectEnum,
    TEMPLATE: 'TEMPLATE' as TypeProjectEnum,
    PGAC: 'PGAC' as TypeProjectEnum,
  };
  export type ExportLanguageOneEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageOneEnum = {
    FR: 'FR' as ExportLanguageOneEnum,
    EN: 'EN' as ExportLanguageOneEnum,
    DE: 'DE' as ExportLanguageOneEnum,
    IT: 'IT' as ExportLanguageOneEnum,
    NONE: 'NONE' as ExportLanguageOneEnum,
  };
  export type ExportLanguageTwoEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageTwoEnum = {
    FR: 'FR' as ExportLanguageTwoEnum,
    EN: 'EN' as ExportLanguageTwoEnum,
    DE: 'DE' as ExportLanguageTwoEnum,
    IT: 'IT' as ExportLanguageTwoEnum,
    NONE: 'NONE' as ExportLanguageTwoEnum,
  };
}
