import { DateUtils } from '../../utils/date.utils';
import { SECONDS, SECOND_PER_HOUR, SECOND_PER_MINUTE, SECOND_PER_MONTH, SECOND_PER_YEAR } from './schedule-tolerance-data';

export interface ScheduleToleranceTime {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
}

export namespace ScheduleToleranceTime {
  export function timeLeft(date: string | Date): ScheduleToleranceTime {
    const seconds = Math.max(DateUtils.diffBy('second', date), 0);

    const years = Math.floor(seconds / SECOND_PER_YEAR);
    const months = Math.floor((seconds % SECOND_PER_YEAR) / SECOND_PER_MONTH);
    const days = Math.floor(((seconds % SECOND_PER_YEAR) % SECOND_PER_MONTH) / SECOND_PER_HOUR);
    const hours = Math.floor((seconds % (SECOND_PER_MINUTE * 24)) / SECOND_PER_MINUTE);
    const minutes = Math.floor((seconds % SECOND_PER_MINUTE) / SECONDS);

    return { years, months, days, hours, minutes };
  }
}
