import { GetFlowEventsLazyRequestDTO } from '../../generated/models/getFlowEventsLazyRequestDTO';
import { FlowEventTypeEnum, InnerSortDirectionEnum, SignatureNatureEnum } from '../../enums';
import { BaseQuery, DateFilterData, initializeQueries } from '../../models';
import { FlowEventDTO } from '../../generated/models/flowEventDTO';
import { DateUtils } from '../../../core/utils/date.utils';

export interface FlowEventData {
  createdDate: string;
  userFirstName?: string;
  userLastName?: string;
  userUuidEntity: string;
  flowEventTypeEnum: FlowEventTypeEnum;
  flowUuidEntity: string;
  flowName: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option6?: string;
  option7?: string;
  option8?: string;
  comment?: string;
  signature?: SignatureNatureEnum;
  supervisorName?: string;
}

export namespace FlowEventData {
  export interface FlowEventsQueryRequest extends BaseQuery<FlowEventData> {
    flowUuidEntity: string;
    flowTypeEnum?: FlowEventTypeEnum[];
    dateFilter: DateFilterData;
  }

  export function mapFromApiValue(flowEvent: FlowEventDTO): FlowEventData {
    return {
      flowEventTypeEnum: FlowEventTypeEnum.convertFromApiValue.getValue(flowEvent.flowEventTypeEnum),
      flowName: flowEvent.flowName,
      createdDate: DateUtils.toDateTimeFormat(flowEvent.createDate),
      flowUuidEntity: flowEvent.flowUuidEntity,
      option1: flowEvent.option1,
      option2: flowEvent.option2,
      option3: flowEvent.option3,
      option4: flowEvent.option4,
      option5: flowEvent.option5,
      option6: flowEvent.option6,
      option7: flowEvent.option7,
      option8: flowEvent.option8,
      userFirstName: flowEvent.userFirstName,
      userLastName: flowEvent.userLastName,
      signature: SignatureNatureEnum.convertFromApiValue.getValue(flowEvent.signature),
      supervisorName: flowEvent.supervisorName,
      userUuidEntity: flowEvent.userUuidEntity,
      comment: flowEvent.comment,
    };
  }

  export function mapToFlowEventsPageRequestApiValue(queries: FlowEventsQueryRequest): GetFlowEventsLazyRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      flowUuidEntity: queries.flowUuidEntity,
      flowEventTypeEnum: (queries.flowTypeEnum || []).map(event => FlowEventTypeEnum.convertToApiValue.getValue(event)),
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
      sort: { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
    };
  }

  export function initializeFlowEventsQueryRequest(flowUuidEntity: string, dateFilter: DateFilterData, events: string[]): FlowEventsQueryRequest {
    return {
      ...initializeQueries<FlowEventData>({ key: 'createdDate' }),
      flowUuidEntity,
      dateFilter: dateFilter || undefined,
      flowTypeEnum: (events || []).map(event => FlowEventTypeEnum.convertFromParamsValue.getValue(event)),
    };
  }
}
