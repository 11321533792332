import { BlockTypeEnum, BlockStatusEnum } from '../../enums';
import { BlocProgressionResponseDTO, NatureBlocResponseDTO, StatusBlocResponseDTO } from '../../generated/models/models';

interface BlockCounts {
  count: number;
  flowsCount: number;
  percent: number;
}

export type BlockStatusCount = {
  [key in BlockStatusEnum]?: number;
};

export type BlockStatus = {
  [key in BlockStatusEnum]?: BlockCounts;
};

type BlockType = {
  [key in BlockTypeEnum]?: BlockStatus;
};

export interface BlockMetricsDetails {
  stepsCount: number;
  statusCount: BlockStatusCount;
  blocks: BlockType;
}

export namespace BlockMetricsDetails {
  export function mapFromApiValue(data: BlocProgressionResponseDTO): BlockMetricsDetails {
    const toBlockStatusCount = (field: StatusBlocResponseDTO.StatusEnum) =>
      data.natureBlocs.reduce((sum, block) => (block.statusBlocs.find(_block => _block.status === field)?.count || 0) + sum, 0);

    const toActiveCount = (object: BlockStatus, field: keyof BlockCounts): number =>
      object[BlockStatusEnum.Redaction][field] +
      object[BlockStatusEnum.Verification][field] +
      object[BlockStatusEnum.Approbation][field] +
      object[BlockStatusEnum.Validation][field];

    const toBlockStatusTypes = (values: StatusBlocResponseDTO) => ({
      percent: (values.count * 100) / data.stepsCount,
      flowsCount: values.flowsCount,
      count: values.count,
    });

    function mapToBlockStatus(block: NatureBlocResponseDTO): BlockStatus {
      const inactive: BlockStatus = (block.statusBlocs || []).reduce((a, b) => ({ ...a, [BlockStatusEnum.convertFromApiValue.getValue(b.status)]: toBlockStatusTypes(b) }), {});
      inactive[BlockStatusEnum.Active] = {
        count: toActiveCount(inactive, 'count'),
        flowsCount: toActiveCount(inactive, 'flowsCount'),
        percent: toActiveCount(inactive, 'percent'),
      };

      return inactive;
    }

    const totalCounts: BlockStatusCount = {
      [BlockStatusEnum.Redaction]: toBlockStatusCount(StatusBlocResponseDTO.StatusEnum.REDACTION),
      [BlockStatusEnum.Verification]: toBlockStatusCount(StatusBlocResponseDTO.StatusEnum.VERIFICATION),
      [BlockStatusEnum.Approbation]: toBlockStatusCount(StatusBlocResponseDTO.StatusEnum.APROBATION),
      [BlockStatusEnum.Validation]: toBlockStatusCount(StatusBlocResponseDTO.StatusEnum.VALIDE),
    };

    return {
      stepsCount: data.stepsCount,
      statusCount: {
        ...totalCounts,
        [BlockStatusEnum.Active]:
          totalCounts[BlockStatusEnum.Redaction] + totalCounts[BlockStatusEnum.Verification] + totalCounts[BlockStatusEnum.Approbation] + totalCounts[BlockStatusEnum.Validation],
      },
      blocks: (data.natureBlocs || []).reduce((a, b) => ({ ...a, [BlockTypeEnum.convertFromApiValue.getValue(b.natureBloc)]: mapToBlockStatus(b) }), {}),
    };
  }
}
