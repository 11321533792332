/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DateFilterFlowRequest {
  dateFilterBy?: DateFilterFlowRequest.DateFilterByEnum;
  from?: Date;
  to?: Date;
}
export namespace DateFilterFlowRequest {
  export type DateFilterByEnum =
    | 'ESTIMATED_START_DATE'
    | 'ESTIMATED_END_DATE'
    | 'START_DATE'
    | 'END_DATE'
    | 'ACHIEVED_START_DATE'
    | 'ACHIEVED_END_DATE'
    | 'MODIFICATION_DATE'
    | 'CREATE_DATE'
    | 'IN_EPSILON_D'
    | 'IN_EPSILON_H'
    | 'EPSILON_B'
    | 'EPSILON_C'
    | 'EPSILON_D'
    | 'EPSILON_E';
  export const DateFilterByEnum = {
    ESTIMATEDSTARTDATE: 'ESTIMATED_START_DATE' as DateFilterByEnum,
    ESTIMATEDENDDATE: 'ESTIMATED_END_DATE' as DateFilterByEnum,
    STARTDATE: 'START_DATE' as DateFilterByEnum,
    ENDDATE: 'END_DATE' as DateFilterByEnum,
    ACHIEVEDSTARTDATE: 'ACHIEVED_START_DATE' as DateFilterByEnum,
    ACHIEVEDENDDATE: 'ACHIEVED_END_DATE' as DateFilterByEnum,
    MODIFICATIONDATE: 'MODIFICATION_DATE' as DateFilterByEnum,
    CREATEDATE: 'CREATE_DATE' as DateFilterByEnum,
    INEPSILOND: 'IN_EPSILON_D' as DateFilterByEnum,
    INEPSILONH: 'IN_EPSILON_H' as DateFilterByEnum,
    EPSILONB: 'EPSILON_B' as DateFilterByEnum,
    EPSILONC: 'EPSILON_C' as DateFilterByEnum,
    EPSILOND: 'EPSILON_D' as DateFilterByEnum,
    EPSILONE: 'EPSILON_E' as DateFilterByEnum,
  };
}
