import { SfxMap } from '../../core/utils/enum-utils';
import { OrganizationConfigResponseDTO } from '../../core/generated/models/organizationConfigResponseDTO';

export enum NumberFormatEnum {
  Fr = 'fr-FR',
  En = 'en-US',
}

export namespace NumberFormatEnum {
  export const formats: NumberFormatEnum[] = [NumberFormatEnum.Fr, NumberFormatEnum.En];

  export const convertFromApiValue: SfxMap<OrganizationConfigResponseDTO.NumberFormatEnumEnum, NumberFormatEnum> = new SfxMap<
    OrganizationConfigResponseDTO.NumberFormatEnumEnum,
    NumberFormatEnum
  >([
    [OrganizationConfigResponseDTO.NumberFormatEnumEnum.FR, NumberFormatEnum.Fr],
    [OrganizationConfigResponseDTO.NumberFormatEnumEnum.EN, NumberFormatEnum.En],
  ]);

  export const convertToApiValue: SfxMap<NumberFormatEnum, OrganizationConfigResponseDTO.NumberFormatEnumEnum> = new SfxMap<
    NumberFormatEnum,
    OrganizationConfigResponseDTO.NumberFormatEnumEnum
  >([
    [NumberFormatEnum.Fr, OrganizationConfigResponseDTO.NumberFormatEnumEnum.FR],
    [NumberFormatEnum.En, OrganizationConfigResponseDTO.NumberFormatEnumEnum.EN],
  ]);

  const key = 'generalSettings.organizationConfig.numberFormat.formats.';
  export const toString: SfxMap<NumberFormatEnum, string> = new SfxMap<NumberFormatEnum, string>([
    [NumberFormatEnum.Fr, key + 'fr'],
    [NumberFormatEnum.En, key + 'en'],
  ]);

  export const convertToLocalStorageValue: SfxMap<NumberFormatEnum, string> = new SfxMap<NumberFormatEnum, string>([
    [NumberFormatEnum.Fr, 'fr'],
    [NumberFormatEnum.En, 'en'],
  ]);

  export const convertFromLocalStorageValue: SfxMap<string, NumberFormatEnum> = new SfxMap<string, NumberFormatEnum>(
    [
      ['fr', NumberFormatEnum.Fr],
      ['en', NumberFormatEnum.En],
    ],
    NumberFormatEnum.Fr,
  );

  export const decimalCharacter: SfxMap<NumberFormatEnum, string> = new SfxMap<NumberFormatEnum, string>(
    [
      [NumberFormatEnum.Fr, ','],
      [NumberFormatEnum.En, '.'],
    ],
    '.',
  );

  export const textInfo: SfxMap<NumberFormatEnum, string> = new SfxMap<NumberFormatEnum, string>([
    [NumberFormatEnum.En, 'option.validation.info-en'],
    [NumberFormatEnum.Fr, 'option.validation.info-fr'],
  ]);
}
