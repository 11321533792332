import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyDetailsResponseDTO, UserReorganizeRequestDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { LabelFamilyNatureEnum } from '../label-family/label-family-nature.enum';
import { LabelFamilyTypeEnum } from '../label-family/label-family-type.enum';

export enum ReorganizeModalTypeEnum {
  Hierarchy = 'hierarchy',
  Function = 'function',
  Legal = 'legal',
  Contract = 'contract',
  Group = 'group',
  Role = 'role',
  Droit = 'droit',
}

export namespace ReorganizeModalTypeEnum {
  export const values = (forContributor?: boolean, forAdmin?: boolean): ReorganizeModalTypeEnum[] =>
    compact([
      !forContributor && ReorganizeModalTypeEnum.Function,
      !forContributor && ReorganizeModalTypeEnum.Legal,
      !forContributor && ReorganizeModalTypeEnum.Contract,
      !forContributor && ReorganizeModalTypeEnum.Group,
      ReorganizeModalTypeEnum.Hierarchy,
      forAdmin && (forContributor ? ReorganizeModalTypeEnum.Role : ReorganizeModalTypeEnum.Droit),
    ]);

  export const convertToApiValue: SfxMap<ReorganizeModalTypeEnum, UserReorganizeRequestDTO.NatureEnum> = new SfxMap<ReorganizeModalTypeEnum, UserReorganizeRequestDTO.NatureEnum>([
    [ReorganizeModalTypeEnum.Function, LabelFamilyDetailsResponseDTO.NatureEnum.RHPFUNCTION],
    [ReorganizeModalTypeEnum.Hierarchy, LabelFamilyDetailsResponseDTO.NatureEnum.HIERARCHY],
    [ReorganizeModalTypeEnum.Group, LabelFamilyDetailsResponseDTO.NatureEnum.TEAM],
    [ReorganizeModalTypeEnum.Legal, LabelFamilyDetailsResponseDTO.NatureEnum.LEGAL],
    [ReorganizeModalTypeEnum.Contract, LabelFamilyDetailsResponseDTO.NatureEnum.CONTRAT],
    [ReorganizeModalTypeEnum.Role, LabelFamilyDetailsResponseDTO.NatureEnum.ACTIVITYDOMAIN],
    [ReorganizeModalTypeEnum.Droit, LabelFamilyDetailsResponseDTO.NatureEnum.ACTIVITYDOMAIN],
  ]);

  const key = 'contributors.filters.';
  export const toString: SfxMap<ReorganizeModalTypeEnum, string> = new SfxMap<ReorganizeModalTypeEnum, string>(
    [
      [ReorganizeModalTypeEnum.Hierarchy, key + 'hierarchy'],
      [ReorganizeModalTypeEnum.Function, key + 'function'],
      [ReorganizeModalTypeEnum.Group, key + 'group'],
      [ReorganizeModalTypeEnum.Legal, key + 'legal'],
      [ReorganizeModalTypeEnum.Contract, key + 'contract'],
      [ReorganizeModalTypeEnum.Droit, key + 'droit'],
      [ReorganizeModalTypeEnum.Role, key + 'role'],
    ],
    key + 'role',
  );

  export const convertToLabelFamilyType: SfxMap<ReorganizeModalTypeEnum, LabelFamilyTypeEnum> = new SfxMap<ReorganizeModalTypeEnum, LabelFamilyTypeEnum>([
    [ReorganizeModalTypeEnum.Function, LabelFamilyTypeEnum.Application],
    [ReorganizeModalTypeEnum.Hierarchy, LabelFamilyTypeEnum.Configuration],
    [ReorganizeModalTypeEnum.Group, LabelFamilyTypeEnum.Application],
    [ReorganizeModalTypeEnum.Legal, LabelFamilyTypeEnum.Application],
    [ReorganizeModalTypeEnum.Contract, LabelFamilyTypeEnum.Application],
  ]);

  export const convertToLabelFamilyNature: SfxMap<ReorganizeModalTypeEnum, LabelFamilyNatureEnum> = new SfxMap<ReorganizeModalTypeEnum, LabelFamilyNatureEnum>([
    [ReorganizeModalTypeEnum.Function, LabelFamilyNatureEnum.RhpFunction],
    [ReorganizeModalTypeEnum.Hierarchy, LabelFamilyNatureEnum.Hierarchy],
    [ReorganizeModalTypeEnum.Group, LabelFamilyNatureEnum.Team],
    [ReorganizeModalTypeEnum.Legal, LabelFamilyNatureEnum.Legal],
    [ReorganizeModalTypeEnum.Contract, LabelFamilyNatureEnum.Contract],
  ]);

  export const itemsResolver = (forAdmin?: boolean): Observable<SingleSelectItemData<ReorganizeModalTypeEnum>[]> =>
    of(
      values(false, forAdmin).map(value => ({
        value,
        title: toString.getValue(value),
      })),
    );
  export const selectItemsResolverForContributor = (forAdmin?: boolean) => (): Observable<SingleSelectItemData<ReorganizeModalTypeEnum>[]> =>
    of(
      values(true, forAdmin).map(value => ({
        value,
        title: toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<ReorganizeModalTypeEnum>, item: ReorganizeModalTypeEnum) => node.value === item;
}
