import { MoveRubricOptionRequestDTO } from '../../generated/models/moveRubricOptionRequestDTO';
import { MoveEnum, OptionTypeEnum } from '../../enums';

export interface OptionToMoveData {
  currentOption: string;
  currentQuestion?: string;
  questionDestination?: string;
  optionDestination: string;
  optionType?: OptionTypeEnum;
}

export namespace OptionToMoveData {
  export function mapToRequestApiValue(moveRequest: OptionToMoveData): MoveRubricOptionRequestDTO {
    return {
      parentUuidEntity: moveRequest.questionDestination,
      uuidEntity: moveRequest.currentOption,
      siblingUuidEntity: moveRequest.optionDestination,
      relativePosition: MoveEnum.convertToApiValue.getValue(moveRequest.optionDestination ? MoveEnum.After : MoveEnum.Start),
      type: OptionTypeEnum.convertToApiValue.getValue(moveRequest.optionType),
    };
  }
}
