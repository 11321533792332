import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { logOut, resetStates } from './main/state/main.actions';
import { resetDialogsState } from './sfx-dialog/state/sfx-dialog.actions';
import { initialMainState, mainAppReducer, MainState } from './main/state/main.reducer';
import { routeReducer } from './router/router.reducer';

import { routerInitialState, SfxRouterState } from './router/router.state';
import { initialSfxDialogState, SfxDialogState } from './sfx-dialog/state/sfx-dialog.type';
import { StateName } from '../core/enums';

export interface AppState {
  sfxRouter: SfxRouterState;
  main: MainState;
  sfxDialog?: SfxDialogState;
}

export const initialAppState: AppState = {
  sfxRouter: routerInitialState,
  main: initialMainState,
};

export const appReducer: ActionReducerMap<AppState> = {
  sfxRouter: routeReducer,
  main: mainAppReducer,
};

export function reducerMetaData(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action & { statesNames: StateName[] }) => {
    switch (action.type) {
      case logOut.type:
        return reducer(
          {
            sfxRouter: state.sfxRouter,
            main: {
              currentLanguage: state.main.currentLanguage,
              cdnWebappUrl: state.main.cdnWebappUrl,
            } as MainState,
          },
          action,
        );

      case resetStates.type:
        const newState = { ...state };
        (action.statesNames || []).filter(stateName => stateName !== StateName.SfxDialog).forEach(stateName => (newState[stateName] = undefined));

        return reducer(newState, action);

      case resetDialogsState.type:
        return reducer({ ...state, [StateName.SfxDialog]: initialSfxDialogState() }, action);

      default:
        return reducer(state, action);
    }
  };
}

export const metaReducers: MetaReducer<AppState>[] = [reducerMetaData];
export const actionsBlockList = ['dummyAction.type', '@ngrx/effects/init', '@ngrx/router-store/request', '@ngrx/store/update-reducers', '@ngrx/router-store/navigated'];
