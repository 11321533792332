import { SfxMap } from '../../utils/enum-utils';

import { VersionDocumentFlowDetailsResponseDTO } from '../../generated/models/models';

import { IconData } from '../../../modules/shared/interfaces/icon-data';

export enum DocumentVersionStatusEnum {
  Bpa = 1,
  Bpe = 2,
  BpeValid = 3,
  ClientRes = 4,
  ClientResBpa = 5,
  Diffusion = 6,
  DiffusionBpa = 7,
  Null = 8,
  UploadFile = 9,
  BpaRefused = 10,
}
export namespace DocumentVersionStatusEnum {
  export const waitingClientReturn = [DocumentVersionStatusEnum.ClientRes, DocumentVersionStatusEnum.ClientResBpa];

  export const convertFromApiValue = new SfxMap<VersionDocumentFlowDetailsResponseDTO.StatusEnum, DocumentVersionStatusEnum>([
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPAVALID, DocumentVersionStatusEnum.Bpa],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPE, DocumentVersionStatusEnum.Bpe],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPEVALID, DocumentVersionStatusEnum.BpeValid],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.CLIENTRETURN, DocumentVersionStatusEnum.ClientRes],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.CLIENTRETURNBPA, DocumentVersionStatusEnum.ClientResBpa],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.DIFFUSION, DocumentVersionStatusEnum.Diffusion],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.DIFFUSIONBPA, DocumentVersionStatusEnum.DiffusionBpa],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.NULL, DocumentVersionStatusEnum.Null],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.UPLOADFILE, DocumentVersionStatusEnum.UploadFile],
    [VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPAREFUSED, DocumentVersionStatusEnum.BpaRefused],
  ]);

  export const convertToApiValue = new SfxMap<DocumentVersionStatusEnum, VersionDocumentFlowDetailsResponseDTO.StatusEnum>([
    [DocumentVersionStatusEnum.Bpa, VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPAVALID],
    [DocumentVersionStatusEnum.Bpe, VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPE],
    [DocumentVersionStatusEnum.BpeValid, VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPEVALID],
    [DocumentVersionStatusEnum.ClientRes, VersionDocumentFlowDetailsResponseDTO.StatusEnum.CLIENTRETURN],
    [DocumentVersionStatusEnum.ClientResBpa, VersionDocumentFlowDetailsResponseDTO.StatusEnum.CLIENTRETURNBPA],
    [DocumentVersionStatusEnum.Diffusion, VersionDocumentFlowDetailsResponseDTO.StatusEnum.DIFFUSION],
    [DocumentVersionStatusEnum.DiffusionBpa, VersionDocumentFlowDetailsResponseDTO.StatusEnum.DIFFUSIONBPA],
    [DocumentVersionStatusEnum.Null, VersionDocumentFlowDetailsResponseDTO.StatusEnum.NULL],
    [DocumentVersionStatusEnum.UploadFile, VersionDocumentFlowDetailsResponseDTO.StatusEnum.UPLOADFILE],
    [DocumentVersionStatusEnum.BpaRefused, VersionDocumentFlowDetailsResponseDTO.StatusEnum.BPAREFUSED],
  ]);

  export const iconData = (diffusionIconData: IconData) =>
    new SfxMap<DocumentVersionStatusEnum, IconData>([
      [DocumentVersionStatusEnum.BpeValid, { name: 'star-of-life', prefix: 'fas', order: 3 }],
      [DocumentVersionStatusEnum.ClientRes, { name: 'envelope-open-text', prefix: 'fas', order: 3 }],
      [DocumentVersionStatusEnum.ClientResBpa, { name: 'envelope-open-text', prefix: 'fas', order: 3 }],
      [DocumentVersionStatusEnum.Diffusion, diffusionIconData],
      [DocumentVersionStatusEnum.DiffusionBpa, { name: 'mail-bulk', prefix: 'fas', order: 3 }],
    ]);
}
