/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FlowLightDTO } from './flowLightDTO';
import { LabelApplicabilityResponseDTO } from './labelApplicabilityResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { VersionDocumentFlowLightDTO } from './versionDocumentFlowLightDTO';
import { WordingConfigurationFieldResponseDTO } from './wordingConfigurationFieldResponseDTO';

export interface DocumentFlowSummaryResponseDTO {
  alreadyImported: boolean;
  applicability: LabelApplicabilityResponseDTO;
  categoryPrincipal: LabelFamilyLightDTO;
  categorySecondary: LabelFamilyLightDTO;
  complementRefInt?: string;
  customWording: WordingConfigurationFieldResponseDTO;
  diffusionDate: Date;
  documentSource: DocumentFlowSummaryResponseDTO.DocumentSourceEnum;
  etat: DocumentFlowSummaryResponseDTO.EtatEnum;
  family: LabelFamilyLightDTO;
  flowLight: FlowLightDTO;
  indice: string;
  isBpeValid: boolean;
  largeFile: boolean;
  lastVdf: VersionDocumentFlowLightDTO;
  lastVersionDocumentFlowUuide: string;
  name: string;
  natureFile: DocumentFlowSummaryResponseDTO.NatureFileEnum;
  provenance: DocumentFlowSummaryResponseDTO.ProvenanceEnum;
  refExt: string;
  refInt: string;
  unlocked: boolean;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}
export namespace DocumentFlowSummaryResponseDTO {
  export type DocumentSourceEnum = 'FORMS' | 'FLOWS' | 'MODULES' | 'EXTRENS';
  export const DocumentSourceEnum = {
    FORMS: 'FORMS' as DocumentSourceEnum,
    FLOWS: 'FLOWS' as DocumentSourceEnum,
    MODULES: 'MODULES' as DocumentSourceEnum,
    EXTRENS: 'EXTRENS' as DocumentSourceEnum,
  };
  export type EtatEnum = 'PREL' | 'BPO' | 'BPE' | 'AUTRE' | 'TEMP' | 'BPA';
  export const EtatEnum = {
    PREL: 'PREL' as EtatEnum,
    BPO: 'BPO' as EtatEnum,
    BPE: 'BPE' as EtatEnum,
    AUTRE: 'AUTRE' as EtatEnum,
    TEMP: 'TEMP' as EtatEnum,
    BPA: 'BPA' as EtatEnum,
  };
  export type NatureFileEnum =
    | 'PR'
    | 'EMM'
    | 'ADR'
    | 'DSI'
    | 'LDA'
    | 'LFA'
    | 'LCA'
    | 'PROJECT'
    | 'AUTRE'
    | 'RFI'
    | 'PFORM'
    | 'FFORM'
    | 'BFORM'
    | 'PREVIEW_FORM'
    | 'MULTI_FLOW_FORM'
    | 'LIST_ADR'
    | 'LIST_EMM'
    | 'LIST_ADR_FLOW'
    | 'LIST_EMM_FLOW'
    | 'MULTI_PR'
    | 'FIELD_FEEDBACK'
    | 'DIAGRAM';
  export const NatureFileEnum = {
    PR: 'PR' as NatureFileEnum,
    EMM: 'EMM' as NatureFileEnum,
    ADR: 'ADR' as NatureFileEnum,
    DSI: 'DSI' as NatureFileEnum,
    LDA: 'LDA' as NatureFileEnum,
    LFA: 'LFA' as NatureFileEnum,
    LCA: 'LCA' as NatureFileEnum,
    PROJECT: 'PROJECT' as NatureFileEnum,
    AUTRE: 'AUTRE' as NatureFileEnum,
    RFI: 'RFI' as NatureFileEnum,
    PFORM: 'PFORM' as NatureFileEnum,
    FFORM: 'FFORM' as NatureFileEnum,
    BFORM: 'BFORM' as NatureFileEnum,
    PREVIEWFORM: 'PREVIEW_FORM' as NatureFileEnum,
    MULTIFLOWFORM: 'MULTI_FLOW_FORM' as NatureFileEnum,
    LISTADR: 'LIST_ADR' as NatureFileEnum,
    LISTEMM: 'LIST_EMM' as NatureFileEnum,
    LISTADRFLOW: 'LIST_ADR_FLOW' as NatureFileEnum,
    LISTEMMFLOW: 'LIST_EMM_FLOW' as NatureFileEnum,
    MULTIPR: 'MULTI_PR' as NatureFileEnum,
    FIELDFEEDBACK: 'FIELD_FEEDBACK' as NatureFileEnum,
    DIAGRAM: 'DIAGRAM' as NatureFileEnum,
  };
  export type ProvenanceEnum = 'LIBRARY' | 'REFERENTIAL' | 'LIBRARY_CREATED' | 'REFERENTIAL_CREATED' | 'LOCAL' | 'PROVENANCE_LIBRARY' | 'PROVENANCE_REFERENTIAL';
  export const ProvenanceEnum = {
    LIBRARY: 'LIBRARY' as ProvenanceEnum,
    REFERENTIAL: 'REFERENTIAL' as ProvenanceEnum,
    LIBRARYCREATED: 'LIBRARY_CREATED' as ProvenanceEnum,
    REFERENTIALCREATED: 'REFERENTIAL_CREATED' as ProvenanceEnum,
    LOCAL: 'LOCAL' as ProvenanceEnum,
    PROVENANCELIBRARY: 'PROVENANCE_LIBRARY' as ProvenanceEnum,
    PROVENANCEREFERENTIAL: 'PROVENANCE_REFERENTIAL' as ProvenanceEnum,
  };
}
