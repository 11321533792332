import { Observable, of } from 'rxjs';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { PageTeamRoleTabletRequestDTO, TeamRoleDeviceSummaryResponseDTO, TeamRolePlanResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum TabletStatusEnum {
  None = 1,
  Active = 2,
  Disable = 3,
  Broken = 4,
  Down = 5,
  Maintenance = 6,
}

export namespace TabletStatusEnum {
  const values: TabletStatusEnum[] = [TabletStatusEnum.Active, TabletStatusEnum.Disable, TabletStatusEnum.Broken, TabletStatusEnum.Down, TabletStatusEnum.Maintenance];

  export const convertFromApiValue: SfxMap<TeamRoleDeviceSummaryResponseDTO.StatusEnum | TeamRolePlanResponseDTO.StatusEnum, TabletStatusEnum> = new SfxMap<
    TeamRoleDeviceSummaryResponseDTO.StatusEnum,
    TabletStatusEnum
  >([
    [TeamRoleDeviceSummaryResponseDTO.StatusEnum.ACTIVE, TabletStatusEnum.Active],
    [TeamRoleDeviceSummaryResponseDTO.StatusEnum.DISABLE, TabletStatusEnum.Disable],
    [TeamRoleDeviceSummaryResponseDTO.StatusEnum.BROKEN, TabletStatusEnum.Broken],
    [TeamRoleDeviceSummaryResponseDTO.StatusEnum.DOWN, TabletStatusEnum.Down],
    [TeamRoleDeviceSummaryResponseDTO.StatusEnum.MAINTENANCE, TabletStatusEnum.Maintenance],
  ]);

  export const convertToApiValue: SfxMap<TabletStatusEnum, PageTeamRoleTabletRequestDTO.StatusesEnum> = new SfxMap<TabletStatusEnum, PageTeamRoleTabletRequestDTO.StatusesEnum>([
    [TabletStatusEnum.Active, PageTeamRoleTabletRequestDTO.StatusesEnum.ACTIVE],
    [TabletStatusEnum.Disable, PageTeamRoleTabletRequestDTO.StatusesEnum.DISABLE],
    [TabletStatusEnum.Broken, PageTeamRoleTabletRequestDTO.StatusesEnum.BROKEN],
    [TabletStatusEnum.Down, PageTeamRoleTabletRequestDTO.StatusesEnum.DOWN],
    [TabletStatusEnum.Maintenance, PageTeamRoleTabletRequestDTO.StatusesEnum.MAINTENANCE],
  ]);

  export const convertFromParamsValue: SfxMap<string, TabletStatusEnum> = new SfxMap<string, TabletStatusEnum>([
    ['active', TabletStatusEnum.Active],
    ['disable', TabletStatusEnum.Disable],
    ['broken', TabletStatusEnum.Broken],
    ['down', TabletStatusEnum.Down],
    ['maintenance', TabletStatusEnum.Maintenance],
  ]);

  export const convertToParamsValue: SfxMap<TabletStatusEnum, string> = new SfxMap<TabletStatusEnum, string>([
    [TabletStatusEnum.Active, 'active'],
    [TabletStatusEnum.Disable, 'disable'],
    [TabletStatusEnum.Broken, 'broken'],
    [TabletStatusEnum.Down, 'down'],
    [TabletStatusEnum.Maintenance, 'maintenance'],
  ]);

  const key = 'tablets.filters.statuses.';
  export const toString: SfxMap<TabletStatusEnum, string> = new SfxMap<TabletStatusEnum, string>(
    [
      [TabletStatusEnum.Active, key + 'active'],
      [TabletStatusEnum.Disable, key + 'disable'],
      [TabletStatusEnum.Broken, key + 'broken'],
      [TabletStatusEnum.Down, key + 'down'],
      [TabletStatusEnum.Maintenance, key + 'maintenance'],
    ],
    'tablets.filters.status',
  );

  export const color: SfxMap<TabletStatusEnum, string> = new SfxMap<TabletStatusEnum, string>([
    [TabletStatusEnum.Active, '#57b09d'],
    [TabletStatusEnum.Disable, '#af1018'],
    [TabletStatusEnum.Broken, '#9e9e9e'],
    [TabletStatusEnum.Down, '#9e9e9e'],
    [TabletStatusEnum.Maintenance, '#9e9e9e'],
  ]);

  export const className: SfxMap<TabletStatusEnum, string> = new SfxMap<TabletStatusEnum, string>([
    [TabletStatusEnum.Active, 'sfx-active-status'],
    [TabletStatusEnum.Disable, 'sfx-expired-status'],
    [TabletStatusEnum.Broken, 'sfx-expired-status'],
    [TabletStatusEnum.Down, 'sfx-expired-status'],
    [TabletStatusEnum.Maintenance, 'sfx-deleted-status'],
  ]);
  export const itemsResolver = (): Observable<SingleSelectItemData<TabletStatusEnum>[]> => of(values.map(status => ({ value: status, title: toString.getValue(status) })));
  export const comparePredicate = (node: SingleSelectItemData<TabletStatusEnum>, item: TabletStatusEnum) => node.value === item;

  export const multiItemsResolver = (): Observable<MultiSelectItemData<TabletStatusEnum>[]> => of(values.map(status => ({ value: status, title: toString.getValue(status) })));
}
