import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../modules/sfx-dialog/state/dialog-toaster-data';

import {
  ErrorResponseData,
  FlowDetailsData,
  FlowSourcePropertiesData,
  RevisionSummaryData,
  PhaseTreeData,
  FlowMenuItemData,
  LazyLoadResponse,
  StepToMoveData,
} from '../../../../core/models';
import { BlockTypeEnum, FlowTypeEnum } from '../../../../core/enums';

export const openFlowContentFilter = createAction('[FlowContent/API] open flow content filter', props<{ origin: HTMLElement }>());

export const loadFlowDetailsSuccess = createAction('[FlowContent/API] load flow details success', props<{ flow: FlowDetailsData }>());
export const loadFlowDetails = createAction('[FlowContent/API] load flow details');
export const resetCurrentFlowRevision = createAction('[FlowContent/API] reset current flow revision', props<{ revision: RevisionSummaryData }>());
export const addFlowRevision = createAction('[FlowContent/API] add flow revision', props<{ revision: RevisionSummaryData }>());
export const resetFlowSource = createAction('[FlowContent/API] reset flow source');

export const resetSteps = createAction('[Step/API] Reset steps', props<{ parentRow: FlowMenuItemData }>());

export const loadSteps = createAction('[Step/API] load steps', props<{ phaseUuidEntity: string }>());
export const loadStepsSuccess = createAction('[Step/API] load steps success', props<{ phaseUuidEntity: string; steps: FlowMenuItemData[] }>());
export const loadStepsFail = createAction('[Step/API] load steps fail', props<DialogToasterData>());

export const loadPhases = createAction('[Phase/API] load phases', props<{ flowType?: FlowTypeEnum; refresh?: boolean; resetFlow?: boolean }>());
export const loadPhasesSuccess = createAction('[Phase/API] load phases success', props<{ response: LazyLoadResponse<PhaseTreeData[]>; reset?: boolean; refresh?: boolean }>());
export const loadMorePhases = createAction('[Phase/API] load more phases');
export const loadPhasesFail = createAction('[Phase/API] load phases fail', props<DialogToasterData>());

export const updateBlock = createAction('Block/API update specific block', props<{ phaseUuidEntity: string; stepUuidEntity: string; blockType: BlockTypeEnum }>());
export const updateStep = createAction('[Step/API] update steps list', props<{ step: FlowMenuItemData }>());
export const updatePhase = createAction('[Phase/API] update phase', props<{ row: Update<FlowMenuItemData> }>());

export const updateFlowLibraryContent = createAction('[FlowContent/API] update Flow Library Content', props<{ force: boolean }>());
export const updateFlowDocumentContent = createAction('[FlowContent/API] update Flow Document Content');

export const resetFlow = createAction('[FlowContent/API] reset Flow');

export const removeLinkedFlow = createAction('[Step/dialog] remove linked flow', props<{ phaseUuidEntity: string; stepUuidEntity: string }>());
export const removeLinkedFlowFail = createAction('[Step/dialog] remove linked flow fail', props<DialogToasterData>());

export const loadFlowSourcePropertiesSuccess = createAction('[FlowContent/API] load source properties success', props<{ flowSourceData: FlowSourcePropertiesData }>());
export const loadFlowSourcePropertiesFail = createAction('[FlowContent/API] load source properties fail', props<DialogToasterData>());

export const moveStep = createAction('[FlowContent/API] move step', props<{ moveStepData: StepToMoveData; currentPhaseUuidEntity: string; currentStepUuidEntity: string }>());
export const moveStepFail = createAction('[FlowContent/API] move step fail', props<{ title: string }>());

export const movePhase = createAction('[FlowContent/API] move phase', props<{ phaseUuidEntity: string; siblingUuidEntity: string }>());
export const movePhaseFail = createAction('[FlowContent/API] move phase fail', props<{ title: string }>());

export const closeRevision = createAction('[FlowContent/API] close revision', props<{ uuidEntity: string }>());
export const closeRevisionFail = createAction('[FlowContent/API] close revision fail', props<{ error: ErrorResponseData }>());

export const focusElement = createAction('[FlowContent/API] Focus element in menu navigation', props<{ elementUuidEntity?: string }>());

export const resetFlowContent = createAction('[FlowContent/API] reset flow content', props<{ flowUuidEntity: string; isSpec: boolean }>());
