import { createAction, props } from '@ngrx/store';
import { BlockMultiPrHistoryData, LazyLoadResponse, SignatureDetailsData } from '../../../../../../../../core/models';

import { DialogToasterData } from '../../../../../../../sfx-dialog/state/dialog-toaster-data';

export const loadSignatureDetails = createAction('[MULTI-PR/dialog] load signature details', props<{ elementPrUuidEntity: string; projectUuidEntity: string }>());
export const loadSignatureDetailsSuccess = createAction('[MULTI-PR/dialog] load signature details success', props<{ signatureDetails: SignatureDetailsData }>());
export const loadSignatureDetailsFail = createAction('[MULTI-PR/dialog] load signature details fail', props<DialogToasterData>());

export const updateSignatory = createAction('[MULTI-PR/dialog] Update signatory', props<{ signatureDetails: SignatureDetailsData }>());
export const updateSignatorySuccess = createAction('[MULTI-PR/dialog] Update signatory success');
export const updateSignatureFail = createAction('[MULTI-PR/dialog] Update signature fail', props<DialogToasterData>());

export const updateObservation = createAction('[MULTI-PR/dialog] Update observation', props<{ observation: string }>());
export const updateObservationFail = createAction('[MULTI-PR/dialog] Update observation fail', props<DialogToasterData>());

export const loadMultiPrHistory = createAction('[MULTI-PR/dialog] load multi-pr history');
export const loadMoreMultiPrHistory = createAction('[MULTI-PR/dialog] load more multi-pr history');
export const loadMultiPrHistoryFail = createAction('[MULTI-PR/dialog] load multi-pr history fail', props<DialogToasterData>());
export const loadMultiPrHistorySuccess = createAction(
  '[MULTI-PR/dialog] load multi-pr history success',
  props<{ multiPrEvents: LazyLoadResponse<BlockMultiPrHistoryData[]>; reset?: boolean }>(),
);
