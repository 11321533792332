import { BlocAdrSummaryResponseDTO, PageBlocRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum, PictogramEnum } from '../../enums';

export interface AdrElementSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  name: string;
  source: string;
  major: boolean;
  pictogram: PictogramEnum;
  disabled: boolean;
}

export namespace AdrElementSummaryData {
  const sortFields = ['labelFamily', 'risk', 'riskSource', 'riskMajeur'];
  export interface QueryRequest extends BaseQuery<AdrElementSummaryData> {
    projectUuidEntity?: string;
    blockUuidEntity?: string;
  }

  export function mapFromApiValue(risk: BlocAdrSummaryResponseDTO): AdrElementSummaryData {
    return {
      uuidEntity: risk.uuidEntity,
      name: risk.name,
      family: risk.family && LabelFamilyLightData.mapFromApiValue(risk.family),
      source: risk.source,
      major: risk.major,
      pictogram: PictogramEnum.convertFromApiValue.getValue(risk.pictogram),
      disabled: risk['disabled'],
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string, sort: SortFilter<AdrElementSummaryData>): QueryRequest {
    return {
      ...initializeQueries<AdrElementSummaryData>(sort),
      blockUuidEntity,
      projectUuidEntity,
    };
  }
}
