import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';
import { ProjectTypeEnum } from '../project/project-type.enum';
import { FilterDateNatureEnum } from './filter-date-nature.enum';
import { DateFilterFlowRequest } from '../../generated/models/dateFilterFlowRequest';

export enum FilterDateEnum {
  StartDate = 1,
  FinishDate,
  ResponseDate,
  SubmissionResponseDate,
  ModificationDate,
  ModificationStatusDate,
  DocumentCreationDate,
  DocumentModificationDate,
  AchievementTaskDate,
  FallbackTaskDate,
  ScheduledServiceDate,
  ScheduledFallbackDate,
  PerformedServiceDate,
  RealizedFallbackDate,
  EstimatedStartDate,
  EstimatedEndDate,
  AchievedStartDate,
  AchievedEndDate,
  CreationDate,
  DueMaxDate,
}

export namespace FilterDateEnum {
  export function values(projectType: ProjectTypeEnum): SfxMap<FilterDateNatureEnum, FilterDateEnum[]> {
    return new SfxMap<FilterDateNatureEnum, FilterDateEnum[]>(
      [
        [FilterDateNatureEnum.Basic, [FilterDateEnum.ModificationDate]],
        [FilterDateNatureEnum.Operators, [FilterDateEnum.ModificationDate, FilterDateEnum.CreationDate]],
        [
          FilterDateNatureEnum.Normal,
          compact([
            FilterDateEnum.ModificationDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.EstimatedStartDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.EstimatedEndDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.StartDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.FinishDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.AchievedStartDate,
            projectType !== ProjectTypeEnum.Template && FilterDateEnum.AchievedEndDate,
          ]),
        ],
        [
          FilterDateNatureEnum.Response,
          [FilterDateEnum.ModificationDate, FilterDateEnum.CreationDate, FilterDateEnum.SubmissionResponseDate, FilterDateEnum.ModificationStatusDate],
        ],
        [FilterDateNatureEnum.Document, [FilterDateEnum.DocumentCreationDate, FilterDateEnum.DocumentModificationDate]],
        [
          FilterDateNatureEnum.PGAC,
          [
            FilterDateEnum.ModificationDate,
            FilterDateEnum.AchievementTaskDate,
            FilterDateEnum.FallbackTaskDate,
            FilterDateEnum.ScheduledServiceDate,
            FilterDateEnum.ScheduledFallbackDate,
            FilterDateEnum.PerformedServiceDate,
            FilterDateEnum.RealizedFallbackDate,
            FilterDateEnum.StartDate,
            FilterDateEnum.FinishDate,
            FilterDateEnum.EstimatedStartDate,
            FilterDateEnum.EstimatedEndDate,
            FilterDateEnum.AchievedStartDate,
            FilterDateEnum.AchievedEndDate,
          ],
        ],
      ],
      [FilterDateEnum.ModificationDate, FilterDateEnum.StartDate, FilterDateEnum.FinishDate],
    );
  }

  export const convertToApiValue: SfxMap<FilterDateEnum, string> = new SfxMap<FilterDateEnum, string>([
    [FilterDateEnum.ModificationDate, 'modificationDate'],
    [FilterDateEnum.CreationDate, 'createDate'],
    [FilterDateEnum.StartDate, 'startDate'],
    [FilterDateEnum.FinishDate, 'finishDate'],
    [FilterDateEnum.ResponseDate, 'dateResponse'],
    [FilterDateEnum.SubmissionResponseDate, 'dateSubmission'],
    [FilterDateEnum.ModificationStatusDate, 'modificationStatusDate'],
    [FilterDateEnum.DocumentCreationDate, 'createDate'],
    [FilterDateEnum.DocumentModificationDate, 'modificationDate'],
    [FilterDateEnum.AchievementTaskDate, 'inEpsilonD'],
    [FilterDateEnum.FallbackTaskDate, 'inEpsilonH'],
    [FilterDateEnum.ScheduledServiceDate, 'epsilonD'],
    [FilterDateEnum.ScheduledFallbackDate, 'epsilonE'],
    [FilterDateEnum.PerformedServiceDate, 'epsilonB'],
    [FilterDateEnum.RealizedFallbackDate, 'epsilonC'],
    [FilterDateEnum.EstimatedStartDate, 'estimatedStartDate'],
    [FilterDateEnum.EstimatedEndDate, 'estimatedEndDate'],
    [FilterDateEnum.AchievedStartDate, 'achievedStartDate'],
    [FilterDateEnum.AchievedEndDate, 'achievedEndDate'],
    [FilterDateEnum.DueMaxDate, 'dueDateMax'],
  ]);

  export const convertToDateFilterFlowApiValue: SfxMap<FilterDateEnum, DateFilterFlowRequest.DateFilterByEnum> = new SfxMap<FilterDateEnum, DateFilterFlowRequest.DateFilterByEnum>(
    [
      [FilterDateEnum.ModificationDate, DateFilterFlowRequest.DateFilterByEnum.MODIFICATIONDATE],
      [FilterDateEnum.CreationDate, DateFilterFlowRequest.DateFilterByEnum.CREATEDATE],
      [FilterDateEnum.StartDate, DateFilterFlowRequest.DateFilterByEnum.STARTDATE],
      [FilterDateEnum.FinishDate, DateFilterFlowRequest.DateFilterByEnum.ENDDATE],
      [FilterDateEnum.AchievementTaskDate, DateFilterFlowRequest.DateFilterByEnum.INEPSILOND],
      [FilterDateEnum.FallbackTaskDate, DateFilterFlowRequest.DateFilterByEnum.INEPSILONH],
      [FilterDateEnum.ScheduledServiceDate, DateFilterFlowRequest.DateFilterByEnum.EPSILOND],
      [FilterDateEnum.ScheduledFallbackDate, DateFilterFlowRequest.DateFilterByEnum.EPSILONE],
      [FilterDateEnum.PerformedServiceDate, DateFilterFlowRequest.DateFilterByEnum.EPSILONB],
      [FilterDateEnum.RealizedFallbackDate, DateFilterFlowRequest.DateFilterByEnum.EPSILONC],
      [FilterDateEnum.EstimatedStartDate, DateFilterFlowRequest.DateFilterByEnum.ESTIMATEDSTARTDATE],
      [FilterDateEnum.EstimatedEndDate, DateFilterFlowRequest.DateFilterByEnum.ESTIMATEDENDDATE],
      [FilterDateEnum.AchievedStartDate, DateFilterFlowRequest.DateFilterByEnum.ACHIEVEDSTARTDATE],
      [FilterDateEnum.AchievedEndDate, DateFilterFlowRequest.DateFilterByEnum.ACHIEVEDENDDATE],
    ],
  );

  export const convertFromParamsValue: SfxMap<string, FilterDateEnum> = new SfxMap<string, FilterDateEnum>([
    ['modificationDate', FilterDateEnum.ModificationDate],
    ['createDate', FilterDateEnum.CreationDate],
    ['startDate', FilterDateEnum.StartDate],
    ['finishDate', FilterDateEnum.FinishDate],
    ['dateResponse', FilterDateEnum.ResponseDate],
    ['dateSubmission', FilterDateEnum.SubmissionResponseDate],
    ['modificationStatusDate', FilterDateEnum.ModificationStatusDate],
    ['documentCreationDate', FilterDateEnum.DocumentCreationDate],
    ['documentModificationDate', FilterDateEnum.DocumentModificationDate],
    ['achievementTaskDate', FilterDateEnum.AchievementTaskDate],
    ['fallbackTaskDate', FilterDateEnum.FallbackTaskDate],
    ['scheduledServiceDate', FilterDateEnum.ScheduledServiceDate],
    ['scheduledFallbackDate', FilterDateEnum.ScheduledFallbackDate],
    ['performedServiceDate', FilterDateEnum.PerformedServiceDate],
    ['realizedFallbackDate', FilterDateEnum.RealizedFallbackDate],
    ['estimatedStartDate', FilterDateEnum.EstimatedStartDate],
    ['estimatedEndDate', FilterDateEnum.EstimatedEndDate],
    ['achievedStartDate', FilterDateEnum.AchievedStartDate],
    ['achievedEndDate', FilterDateEnum.AchievedEndDate],
    ['dueDateMax', FilterDateEnum.DueMaxDate],
  ]);

  export const convertToParamsValue: SfxMap<FilterDateEnum, string> = new SfxMap<FilterDateEnum, string>([
    [FilterDateEnum.ModificationDate, 'modificationDate'],
    [FilterDateEnum.CreationDate, 'createDate'],
    [FilterDateEnum.StartDate, 'startDate'],
    [FilterDateEnum.FinishDate, 'finishDate'],
    [FilterDateEnum.ResponseDate, 'dateResponse'],
    [FilterDateEnum.SubmissionResponseDate, 'dateSubmission'],
    [FilterDateEnum.ModificationStatusDate, 'modificationStatusDate'],
    [FilterDateEnum.DocumentCreationDate, 'documentCreationDate'],
    [FilterDateEnum.DocumentModificationDate, 'documentModificationDate'],
    [FilterDateEnum.AchievementTaskDate, 'achievementTaskDate'],
    [FilterDateEnum.FallbackTaskDate, 'fallbackTaskDate'],
    [FilterDateEnum.ScheduledServiceDate, 'scheduledServiceDate'],
    [FilterDateEnum.ScheduledFallbackDate, 'scheduledFallbackDate'],
    [FilterDateEnum.PerformedServiceDate, 'performedServiceDate'],
    [FilterDateEnum.RealizedFallbackDate, 'realizedFallbackDate'],
    [FilterDateEnum.EstimatedStartDate, 'estimatedStartDate'],
    [FilterDateEnum.EstimatedEndDate, 'estimatedEndDate'],
    [FilterDateEnum.AchievedStartDate, 'achievedStartDate'],
    [FilterDateEnum.AchievedEndDate, 'achievedEndDate'],
    [FilterDateEnum.DueMaxDate, 'dueDateMax'],
  ]);

  export const toString = (businessClassName?: BusinessClassNameEnum): SfxMap<FilterDateEnum, string> => {
    const key = 'global.dateFilter.';

    return new SfxMap<FilterDateEnum, string>([
      [FilterDateEnum.ModificationDate, key + 'modificationDate'],
      [FilterDateEnum.CreationDate, key + 'createDate'],
      [FilterDateEnum.StartDate, key + (businessClassName === BusinessClassNameEnum.Flow ? 'planned.startDate' : 'startDate')],
      [FilterDateEnum.FinishDate, key + (businessClassName === BusinessClassNameEnum.Flow ? 'planned.endDate' : 'endDate')],
      [FilterDateEnum.ResponseDate, key + 'responseDate'],
      [FilterDateEnum.SubmissionResponseDate, key + 'submissionResponseDate'],
      [FilterDateEnum.ModificationStatusDate, key + 'statusModificationDate'],
      [FilterDateEnum.DocumentCreationDate, key + 'documentCreationDate'],
      [FilterDateEnum.DocumentModificationDate, key + 'documentModificationDate'],
      [FilterDateEnum.AchievementTaskDate, key + 'achievementTaskDate'],
      [FilterDateEnum.FallbackTaskDate, key + 'fallbackTaskDate'],
      [FilterDateEnum.ScheduledServiceDate, key + 'scheduledServiceDate'],
      [FilterDateEnum.ScheduledFallbackDate, key + 'scheduledFallbackDate'],
      [FilterDateEnum.PerformedServiceDate, key + 'performedServiceDate'],
      [FilterDateEnum.RealizedFallbackDate, key + 'realizedFallbackDate'],
      [FilterDateEnum.EstimatedStartDate, key + 'estimatedStartDate'],
      [FilterDateEnum.EstimatedEndDate, key + 'estimatedEndDate'],
      [FilterDateEnum.AchievedStartDate, key + 'achievedStartDate'],
      [FilterDateEnum.AchievedEndDate, key + 'achievedEndDate'],
    ]);
  };

  export const itemsResolver = (
    filterDateNature: FilterDateNatureEnum,
    projectType: ProjectTypeEnum,
    businessClassName?: BusinessClassNameEnum,
  ): Observable<SingleSelectItemData<FilterDateEnum>[]> =>
    of(
      values(projectType)
        .getValue(filterDateNature)
        .map(value => ({
          value,
          title: toString(businessClassName).getValue(value),
          selected: value === FilterDateEnum.ModificationDate,
        })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FilterDateEnum>, item: FilterDateEnum) => node.value === item;
}
