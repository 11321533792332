import { ApplicationEventDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum PgacProjectColumnEnum {
  ActionType = 'natureAction',
  Responsible = 'responsible',
  ActionDate = 'actionDate',
  ErrorMessage = 'errorMessage',
  Action = 'action',
  Reference = 'reference',
  Entities = 'entities',
}

export namespace PgacProjectColumnEnum {
  export type PgacProjectSortField = keyof ApplicationEventDTO;

  export const allColumns = [
    PgacProjectColumnEnum.ActionType,
    PgacProjectColumnEnum.Responsible,
    PgacProjectColumnEnum.ActionDate,
    PgacProjectColumnEnum.ErrorMessage,
    PgacProjectColumnEnum.Action,
  ];

  export const historyColumns = [PgacProjectColumnEnum.ActionType, PgacProjectColumnEnum.Responsible, PgacProjectColumnEnum.ActionDate, PgacProjectColumnEnum.Action];
  export const allConflictColumns = [PgacProjectColumnEnum.Reference, PgacProjectColumnEnum.Entities];

  const key = 'projects.pgacs.modals.pgacProject.table.header.';
  export const toString: SfxMap<PgacProjectColumnEnum, string> = new SfxMap<PgacProjectColumnEnum, string>([
    [PgacProjectColumnEnum.ActionType, key + 'natureAction'],
    [PgacProjectColumnEnum.Responsible, key + 'responsible'],
    [PgacProjectColumnEnum.ActionDate, key + 'actionDate'],
    [PgacProjectColumnEnum.ErrorMessage, key + 'errorMessage'],
    [PgacProjectColumnEnum.Action, key + 'action'],
    [PgacProjectColumnEnum.Reference, key + 'reference'],
    [PgacProjectColumnEnum.Entities, key + 'entities'],
  ]);

  export const sortField: SfxMap<PgacProjectColumnEnum, PgacProjectSortField> = new SfxMap<PgacProjectColumnEnum, PgacProjectSortField>([
    [PgacProjectColumnEnum.ActionType, 'natureAction'],
    [PgacProjectColumnEnum.Responsible, 'userLibelle'],
    [PgacProjectColumnEnum.ActionDate, 'actionDate'],
  ]);
}
