import { Data } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import { ModalIdEnum } from '../../../core/enums';
import { DialogToasterData } from './dialog-toaster-data';

export const openModal = createAction('[sfxDialog] open modal', props<{ routeData: Data }>());
export const refreshModal = createAction('[sfxDialog] refresh modal', props<{ routeData: Data }>());
export const closeModal = createAction('[sfxDialog] close modal', props<{ modalId?: ModalIdEnum }>());
export const resetDialogsState = createAction('[sfxDialog] reset dialog state');

export const setModalId = createAction('[sfxDialog] set modal id', props<{ modalId: ModalIdEnum }>());
export const setDialogEditMode = createAction('[sfxDialog] set dialog edit mode', props<{ editMode: boolean }>());
export const setDialogConvertMode = createAction('[sfxDialog] set dialog convert mode', props<{ convertMode: boolean }>());
export const setKeepCreating = createAction('[sfxDialog] set dialog create mode', props<{ keepCreate: boolean }>());
export const setDialogViewMode = createAction('[sfxDialog] set dialog view mode', props<{ viewMode: boolean }>());
export const setDialogLoadingState = createAction('[sfxDialog] set dialog loading state', props<{ loadingState: boolean }>());
export const setDialogErrorData = createAction('[sfxDialog] set dialog error data', props<{ error: DialogToasterData }>());
export const setDialogSuccessData = createAction('[sfxDialog] set dialog success data', props<{ success: DialogToasterData }>());
export const setDialogWarningData = createAction('[sfxDialog] set dialog warning data', props<{ warning: DialogToasterData }>());
export const setCurrentTab = createAction('[sfxDialog] set current tab', props<{ currentTab: string; submitted?: boolean }>());
export const changeFocus = createAction('[sfxDialog] changeFocus');
export const setSubmitted = createAction('[sfxDialog] set submitted', props<{ submitted: boolean }>());
export const setCreateAnother = createAction('[sfxDialog] set create another', props<{ createAnother: boolean }>());
export const setIsFormValid = createAction('[sfxDialog] set is form valid', props<{ formValid: boolean }>());
