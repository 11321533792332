export const environment = {
  production: true,
  debugInfoEnabled: false,
  mock: false,
  api: '',
  helpCenter: {
    active: false,
    zendesk: {
      accessToken: '54731ed5c7050109d498f052621ad8f6539502f63d4e939370ac3385e7dfc098',
    },
  },
  showFroalaExpiration: true,
};
