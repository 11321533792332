import { FillExternalContributorInfoRequestDto } from '../../generated/models/fillExternalContributorInfoRequestDto';
import { SharedLinkContributor } from '../../generated/models/sharedLinkContributor';
import { UserLightDTOV15 } from '../../generated/models/userLightDTOV15';

import { UserNatureEnum } from '../../enums';

export interface UserShareData {
  firstName: string;
  lastName: string;
  nature: UserNatureEnum;
  uuidEntity: string;
  company?: string;
  function?: string;
  email?: string;
}

export namespace UserShareData {
  export function mapFromUserLightDTOV15(data: UserLightDTOV15): UserShareData {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      nature: UserNatureEnum.convertFromApiValue.getValue(data.natureUser),
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapFromSharedLinkContributor(data: SharedLinkContributor): UserShareData {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      nature: UserNatureEnum.convertFromApiValue.getValue(data.nature),
      company: data.companyName,
      function: data._function,
      email: data.login,
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToUpdateRequestValue(data: UserShareData): FillExternalContributorInfoRequestDto {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.company,
      _function: data.function,
    };
  }
}
