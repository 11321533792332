/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { FlowLightResponseDTO } from './flowLightResponseDTO';
import { UserLightDTO } from './userLightDTO';

export interface SchedulingResponseDTO {
  uuidEntity?: string;
  nature?: SchedulingResponseDTO.NatureEnum;
  status?: SchedulingResponseDTO.StatusEnum;
  name?: string;
  description?: string;
  priority?: SchedulingResponseDTO.PriorityEnum;
  toleranceBefore?: number;
  toleranceAfter?: number;
  alert?: number;
  slidingPlan?: boolean;
  lateSubmit?: boolean;
  blockNext?: boolean;
  recurrence?: string;
  startCondition?: SchedulingResponseDTO.StartConditionEnum;
  endCondition?: SchedulingResponseDTO.EndConditionEnum;
  maxSchedulingItems?: number;
  startDate?: Date;
  endDate?: Date;
  enableEndDate?: Date;
  reminder?: string;
  flowToCreateUuidEntity?: string;
  startCustomStatuses?: Array<CustomStatusLightDTO>;
  flow?: FlowLightResponseDTO;
  creationDate?: Date;
  modificationDate?: Date;
  createdBy?: UserLightDTO;
  lastModifiedBy?: UserLightDTO;
}
export namespace SchedulingResponseDTO {
  export type NatureEnum = 'REFERENTIAL' | 'REMINDER';
  export const NatureEnum = {
    REFERENTIAL: 'REFERENTIAL' as NatureEnum,
    REMINDER: 'REMINDER' as NatureEnum,
  };
  export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PAUSED' | 'FINISHED';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
    PAUSED: 'PAUSED' as StatusEnum,
    FINISHED: 'FINISHED' as StatusEnum,
  };
  export type PriorityEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'HIGHEST';
  export const PriorityEnum = {
    LOW: 'LOW' as PriorityEnum,
    MEDIUM: 'MEDIUM' as PriorityEnum,
    HIGH: 'HIGH' as PriorityEnum,
    HIGHEST: 'HIGHEST' as PriorityEnum,
  };
  export type StartConditionEnum = 'TIME' | 'USAGE' | 'STATUS';
  export const StartConditionEnum = {
    TIME: 'TIME' as StartConditionEnum,
    USAGE: 'USAGE' as StartConditionEnum,
    STATUS: 'STATUS' as StartConditionEnum,
  };
  export type EndConditionEnum = 'STATUS' | 'TIME' | 'AMOUNT' | 'NEVER';
  export const EndConditionEnum = {
    STATUS: 'STATUS' as EndConditionEnum,
    TIME: 'TIME' as EndConditionEnum,
    AMOUNT: 'AMOUNT' as EndConditionEnum,
    NEVER: 'NEVER' as EndConditionEnum,
  };
}
