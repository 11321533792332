import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ClientModalTabEnum {
  General = 'general',
  Options = 'options',
}

export namespace ClientModalTabEnum {
  const key = 'clients.modal.tabs.';

  export const toString: SfxMap<ClientModalTabEnum, string> = new SfxMap<ClientModalTabEnum, string>([
    [ClientModalTabEnum.General, key + 'general'],
    [ClientModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<ClientModalTabEnum, IconData> = new SfxMap<ClientModalTabEnum, IconData>([
    [ClientModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [ClientModalTabEnum.Options, { name: 'wrench', prefix: 'fas' }],
  ]);

  export const nextTab: SfxMap<ClientModalTabEnum, ClientModalTabEnum> = new SfxMap<ClientModalTabEnum, ClientModalTabEnum>([
    [ClientModalTabEnum.General, ClientModalTabEnum.Options],
  ]);
}
