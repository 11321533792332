import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './field-feedback-dialog.actions';
import { ApplicationEventLightData, LazyLoadResponse } from '../../../../../core/models';

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

export interface DashboardFieldFeedbackDialogState {
  historyDialogState: HistoryDialogState;
}

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

export const initialDashboardFieldFeedbackDialogState = () => ({
  historyDialogState: initialHistoryDialogState(),
});

const reducer = createReducer<DashboardFieldFeedbackDialogState>(
  initialDashboardFieldFeedbackDialogState(),
  on(
    fromActions.loadHistoryListSuccess,
    (state, { data, reset }): DashboardFieldFeedbackDialogState => ({
      ...state,
      historyDialogState: {
        ...state.historyDialogState,
        reset,
        historyList: reset ? data : { ...state.historyDialogState.historyList, payload: [...state.historyDialogState.historyList.payload, ...data.payload] },
      },
    }),
  ),
);

export function dashboardFieldFeedbackDialogReducer(state: DashboardFieldFeedbackDialogState | undefined, action: Action): DashboardFieldFeedbackDialogState {
  return reducer(state, action);
}
