import { QuestionTypeEnum } from '../../enums';
import { QuestionsRecapResponseDTO, RubricOptionRecap } from '../../generated/models/models';
import { FormQuestionRecapAnswer } from './form-question-recap-answer';

export interface FormQuestionRecap {
  uuidEntity: string;
  name: string;
  responseCount: number;
  order: number;
  isRequired: boolean;
  questionType: QuestionTypeEnum;
  responseRecap: FormQuestionRecapAnswer;
}

export namespace FormQuestionRecap {
  export function mapFromApiValue(data: QuestionsRecapResponseDTO): FormQuestionRecap {
    const questionType = QuestionTypeEnum.convertFromApiValue.getValue(data.questionType);

    return {
      uuidEntity: data.uuidEntity,
      name: data.questionName,
      order: data.questionOrder,
      isRequired: data.mandatory,
      questionType: questionType,
      responseCount: responseCount(data.rubricOptionRecaps, questionType),
      responseRecap: FormQuestionRecapAnswer.mapFromApiValue(data.rubricOptionRecaps, questionType),
    };
  }

  function responseCount(rubricOptionRecaps: RubricOptionRecap[], questionType: QuestionTypeEnum): number {
    if ([QuestionTypeEnum.MultipleChoices, QuestionTypeEnum.ListOfMultipleChoices].includes(questionType)) {
      return rubricOptionRecaps.length;
    }

    if (QuestionTypeEnum.fileQuestions.includes(questionType)) {
      return rubricOptionRecaps.reduce((count, answer) => count + answer.applicationFileUuides.length, 0);
    }

    if (QuestionTypeEnum.Duration === questionType) {
      return rubricOptionRecaps.filter(answer => answer.timeValue).length;
    }

    if (QuestionTypeEnum.ValueUnit === questionType) {
      return rubricOptionRecaps.filter(answer => answer.textValue && answer.textValueUnit).length;
    }

    if (QuestionTypeEnum.DateTime === questionType) {
      return rubricOptionRecaps.filter(answer => answer.dateTimeValue).length;
    }

    if (QuestionTypeEnum.Location === questionType) {
      return rubricOptionRecaps.filter(answer => answer.latitude && answer.longitude).length;
    }

    return rubricOptionRecaps.reduce((count, answer) => count + (answer.count ?? 1), 0);
  }
}
