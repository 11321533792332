import { ResetPasswordRequestDTO } from '../../generated/models/models';

export interface EmailForReset {
  email: string;
}

export namespace EmailForReset {
  export function mapToRequestApiValue(checkEmail: EmailForReset): ResetPasswordRequestDTO {
    return {
      email: checkEmail.email,
    };
  }
}
