import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe to filter items in an array
 * Example: <div *ngFor="let item of items | filter: filterPredicate">
 * And in .ts filterPredicate = (item: any) => item.foo === 'bar';
 * @param items Array to filter
 * @param filterPredicateFn The filter predicate function
 *
 */
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform<T>(items: T[], filterPredicateFn: (item: T) => boolean): T[] {
    if (!items || !filterPredicateFn) {
      return items;
    }

    return items.filter(filterPredicateFn);
  }
}
