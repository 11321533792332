import { createAction, props } from '@ngrx/store';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import {
  CheckExistedReferenceRequest,
  FlowDetailsData,
  TechnicalObjectDetailsData,
  CustomFieldData,
  FlowToImportSummaryData,
  TechnicalObjectForDuplicate,
  ObjectFlowSummaryData,
} from '../../../../../core/models';

// Object dialog actions
export const addObject = createAction(
  '[lObjectDialog] add new object',
  props<{ object: TechnicalObjectDetailsData; closeModal?: boolean; nextTab?: string; createAnother?: boolean }>(),
);
export const addObjectSuccess = createAction(
  '[lObjectDialog] add new object success',
  props<{ objectDetails: TechnicalObjectDetailsData; closeModal?: boolean; nextTab?: string }>(),
);
export const addObjectFail = createAction('[lObjectDialog] add new object fail', props<DialogToasterData>());

export const updateObject = createAction('[lObjectDialog] update object', props<{ object: TechnicalObjectDetailsData; closeModal?: boolean; createAnother?: boolean }>());
export const updateObjectSuccess = createAction('[lObjectDialog] update object success', props<{ objectDetails: TechnicalObjectDetailsData; closeModal?: boolean }>());
export const updateObjectFail = createAction('[lObjectDialog] update object fail', props<DialogToasterData>());

export const saveObjectCustomFields = createAction(
  '[lObjectDialog] save object custom fields',
  props<{ customFields: CustomFieldData[]; createAnother?: boolean; closeModal?: boolean }>(),
);
export const saveObjectCustomFieldsSuccess = createAction(
  '[lObjectDialog] save object custom fields success',
  props<{ customFields: CustomFieldData[]; createAnotherOne?: boolean; closeModal?: boolean }>(),
);
export const saveObjectCustomFieldsFail = createAction('[lObjectDialog] save object custom fields fail', props<DialogToasterData>());

export const loadObjectDetailsSuccess = createAction('[lObjectDialog] load object details success', props<{ objectDetails: TechnicalObjectDetailsData }>());
export const loadObjectDetailsFail = createAction('[lObjectDialog] load object details fail', props<DialogToasterData>());

export const loadObjectCustomFields = createAction('[lObjectDialog] load object custom fields', props<{ object: TechnicalObjectDetailsData }>());
export const loadObjectCustomFieldsSuccess = createAction('[lObjectDialog] load object custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadObjectCustomFieldsFail = createAction('[lObjectDialog] load object custom fields fail', props<DialogToasterData>());

export const initializeObjectDetails = createAction('[lObjectDialog] initialize object details', props<{ objectDetails: TechnicalObjectDetailsData; createAnother?: boolean }>());

export const saveChangesObjectDialog = createAction(
  '[lObjectDialog] save changes object dialog',
  props<{ object: TechnicalObjectDetailsData; customFields: CustomFieldData[]; nextTab: string; createAnotherOne: boolean }>(),
);

export const navigateThroughObjects = createAction(
  '[lObjectDialog] navigate through objects',
  props<{ uuidEntity: string; step: number; objectDetails: TechnicalObjectDetailsData; customFields: CustomFieldData[] }>(),
);

export const checkAllReferenceGenerated = createAction('[lObject/Dialog] check all references generated', props<CheckExistedReferenceRequest>());
export const checkAllReferenceGeneratedSuccess = createAction(
  '[lObject/Dialog] check all references generated success',
  props<{ hasRefIntGenerated: boolean; hasRefExtGenerated: boolean; reset: boolean }>(),
);
export const checkAllReferenceGeneratedFail = createAction('[lObject/Dialog] check all references generated fail', props<DialogToasterData>());

export const navigateThroughObjectsSuccess = createAction('[lObjectDialog] Navigate through objects success', props<{ uuidEntity: string; step: number }>());

export const loadContentFlows = createAction('[lObjectDialog] load content flows', props<{ object: TechnicalObjectDetailsData }>());
export const loadContentFlowsFail = createAction('[lObjectDialog] load content flows fail', props<DialogToasterData>());
export const loadContentFlowsSuccess = createAction('[lObjectDialog] load content flows success', props<{ flows: ObjectFlowSummaryData[]; totalCount: number; reset?: boolean }>());
export const loadMoreContentFlows = createAction('[lObjectDialog] load more content flows');

export const loadHistoryFlows = createAction('[lObjectDialog] load history flows', props<{ object: TechnicalObjectDetailsData }>());
export const loadHistoryFlowsFail = createAction('[lObjectDialog] load history flows fail', props<DialogToasterData>());
export const loadHistoryFlowsSuccess = createAction('[lObjectDialog] load history flows success', props<{ flows: ObjectFlowSummaryData[]; totalCount: number; reset?: boolean }>());
export const loadMoreHistoryFlows = createAction('[lObjectDialog] load more history flows');

export const deleteContentFlow = createAction('[lObjectDialog] delete content flow', props<{ relationUuidEntity: string }>());

// Assign dialog actions
export const loadAssignFlows = createAction('[lObjectDialog] load assign flows');
export const loadAssignFlowsFail = createAction('[lObjectDialog] load assign flows fail', props<DialogToasterData>());
export const loadAssignFlowsSuccess = createAction(
  '[lObjectDialog] load Assign flows success',
  props<{ flows: FlowToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadMoreAssignFlows = createAction('[lObjectDialog] load more assign flows');

export const assignFlows = createAction('[lObjectDialog] assign flows', props<{ selectedFlows: string[]; allSelected?: boolean }>());
export const assignFlowsFail = createAction('[lObjectDialog] assign flows fail', props<DialogToasterData>());
export const assignFlowsSuccess = createAction('[lObjectDialog] assign flows success');

export const initializeGeneratedFlow = createAction('[lObjectDialog] initialize generated flow', props<{ generatedFlow: FlowDetailsData }>());
export const generateFlow = createAction('[lObjectDialog] generate flow', props<{ flowDetails: FlowDetailsData; projectUuidEntity: string; content: string }>());
export const generateFlowFail = createAction('[lObjectDialog] generate flow fail', props<DialogToasterData>());
export const generateFlowSuccess = createAction('[lObjectDialog] generate flow success', props<{ flowDetails: FlowDetailsData }>());
export const checkFlowHasRefGenerated = createAction(
  '[lObjectDialog] check flow has generated reference',
  props<{ labelFamilyUuidEntity: string; parentLabelFamilyUuidEntity: string }>(),
);
export const checkFlowHasRefGeneratedFail = createAction('[lObjectDialog] check flow has generated reference fail', props<DialogToasterData>());
export const checkFlowHasRefGeneratedSuccess = createAction('[lObjectDialog] check flow has generated reference success', props<{ flowHasRefGenerated: boolean }>());

export const getDuplicatedObjectFail = createAction('[lObjectDialog] get duplicated object fail', props<DialogToasterData>());
export const getDuplicatedObjectSuccess = createAction('[lObjectDialog] get duplicated object success', props<{ duplicatedObject: TechnicalObjectForDuplicate }>());

export const duplicateObject = createAction('[lObjectDialog] duplicate object', props<{ duplicatedObject: TechnicalObjectForDuplicate }>());
export const duplicateObjectFail = createAction('[lObjectDialog] duplicate object fail', props<DialogToasterData>());
export const duplicateObjectSuccess = createAction('[lObjectDialog] duplicate object success', props<{ parentUuidEntity: string; sourceUuidEntity: string }>());

export const openObjectFilterDatePopover = createAction('[ObjectDialog] open object filter date modal', props<{ origin: HTMLElement }>());
