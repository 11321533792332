import { UserPropertiesResponseDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { FlowLightData } from '../flows/flow-light-data';

import { UserRightEnum, UserStatusEnum } from '../../enums';

export interface LibraryContributorPropertiesData {
  uuidEntity: string;
  firstName: string;
  lastName: string;
  trigram: string;
  authority: UserRightEnum;
  applicabilityName: string;
  status: UserStatusEnum;
  function: LabelFamilyLightData;
  group: LabelFamilyLightData;
  hierarchies: EntityLightData[];
  flows: FlowLightData[];
}

export namespace LibraryContributorPropertiesData {
  export function mapFromApiValue(data: UserPropertiesResponseDTO): LibraryContributorPropertiesData {
    return {
      uuidEntity: data.uuidEntity,
      firstName: data.firstName,
      lastName: data.lastName,
      trigram: data.trigram,
      function: data.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(data.labelFamilyFunction),
      status: data.status && UserStatusEnum.convertFromApiValue.getValue(data.status),
      group: data.labelFamilyTeam && LabelFamilyLightData.mapFromApiValue(data.labelFamilyTeam),
      authority: UserRightEnum.convertFromApiValue.getValue(data.userRight?.name),
      applicabilityName: data.applicability?.fullName || data.applicability?.name,
      hierarchies: (data.labelFamilyHierarchies || []).map(hierarchy => LabelFamilyLightData.mapToEntityLightData(hierarchy)),
      flows: (data.flows || []).map(flow => FlowLightData.mapFromApiResponse(flow)),
    };
  }
}
