/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicationFileVersionOptionResponseDTO } from './applicationFileVersionOptionResponseDTO';

export interface VersionRubricOptionResponseDTO {
  firstName: string;
  lastName: string;
  modificationDate: Date;
  provenance: VersionRubricOptionResponseDTO.ProvenanceEnum;
  newValue: string;
  oldValue: string;
  signatory: string;
  optionName: string;
  questionType: VersionRubricOptionResponseDTO.QuestionTypeEnum;
  signatureComment: string;
  applicationFiles: Array<ApplicationFileVersionOptionResponseDTO>;
}
export namespace VersionRubricOptionResponseDTO {
  export type ProvenanceEnum = 'MOBILE' | 'WEB';
  export const ProvenanceEnum = {
    MOBILE: 'MOBILE' as ProvenanceEnum,
    WEB: 'WEB' as ProvenanceEnum,
  };
  export type QuestionTypeEnum =
    | 'Text'
    | 'TextLong'
    | 'TextMultiple'
    | 'ValueUnit'
    | 'RadioSelect'
    | 'Contributor'
    | 'CheckBox'
    | 'File'
    | 'FileDocument'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'BooleanValue'
    | 'Localisation'
    | 'Formulas'
    | 'EnumSelection'
    | 'EnumMultipleSelections'
    | 'TextEditor';
  export const QuestionTypeEnum = {
    Text: 'Text' as QuestionTypeEnum,
    TextLong: 'TextLong' as QuestionTypeEnum,
    TextMultiple: 'TextMultiple' as QuestionTypeEnum,
    ValueUnit: 'ValueUnit' as QuestionTypeEnum,
    RadioSelect: 'RadioSelect' as QuestionTypeEnum,
    Contributor: 'Contributor' as QuestionTypeEnum,
    CheckBox: 'CheckBox' as QuestionTypeEnum,
    File: 'File' as QuestionTypeEnum,
    FileDocument: 'FileDocument' as QuestionTypeEnum,
    Date: 'Date' as QuestionTypeEnum,
    Time: 'Time' as QuestionTypeEnum,
    DateTime: 'DateTime' as QuestionTypeEnum,
    BooleanValue: 'BooleanValue' as QuestionTypeEnum,
    Localisation: 'Localisation' as QuestionTypeEnum,
    Formulas: 'Formulas' as QuestionTypeEnum,
    EnumSelection: 'EnumSelection' as QuestionTypeEnum,
    EnumMultipleSelections: 'EnumMultipleSelections' as QuestionTypeEnum,
    TextEditor: 'TextEditor' as QuestionTypeEnum,
  };
}
