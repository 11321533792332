import { SfxMap } from '../../utils/enum-utils';

export enum CustomFieldColumnEnum {
  Family = 'family',
  Actions = 'action',
}

export namespace CustomFieldColumnEnum {
  export const allColumns = [CustomFieldColumnEnum.Family, CustomFieldColumnEnum.Actions];

  const key = 'attributes.table.';
  export const toString: SfxMap<CustomFieldColumnEnum, string> = new SfxMap<CustomFieldColumnEnum, string>([
    [CustomFieldColumnEnum.Family, key + 'family'],
    [CustomFieldColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<CustomFieldColumnEnum, string> = new SfxMap<CustomFieldColumnEnum, string>([[CustomFieldColumnEnum.Family, 'family']]);
}
