import { createFeatureSelector, createSelector } from '@ngrx/store';

import { flatten, last } from 'lodash';

import { isProjectPGAC } from '../../state';
import { PhaseSummaryData } from '../../../../core/models';
import { getActiveRouteId, getRouteParam, getTextSearchFilter, getListQueryParam } from '../../../../modules/router';

import { FlowTypeEnum, FlowContentTemplateEnum, FlowMenuItemTypeEnum, RouteIdEnum, RouteParamEnum, StateName, RouteQueryParamEnum } from '../../../../core/enums';
import * as fromFlowContent from './flow-content.reducer';
import { FlowStatusEnum } from '../../../../core/enums/flow/flow-status.enum';

const flowContentState = createFeatureSelector<fromFlowContent.FlowContentState>(StateName.FlowContent);

export const getFocusedElement = createSelector(flowContentState, state => state.focusedElement);
export const getFlowSourceData = createSelector(flowContentState, state => state.flowSourceData);
export const getCurrentFlowRevision = createSelector(flowContentState, state => state.flowDetails && state.flowDetails.currentRevision);
export const revisionMode = createSelector(flowContentState, state => state.flowDetails && !!state.flowDetails.currentRevision);
export const getFlowDetails = createSelector(flowContentState, state => state.flowDetails);
export const getIsFlowDisabled = createSelector(getFlowDetails, flowDetails => FlowStatusEnum.Disabled === flowDetails?.modificationStatus);
export const getFlowClassName = createSelector(
  flowContentState,
  isProjectPGAC,
  (state, pgac) => state.flowDetails && FlowTypeEnum.defaultColorClass(pgac).getValue(state.flowDetails.flowType),
);
export const getFlowName = createSelector(flowContentState, state => state.flowDetails?.name);
export const getFlowType = createSelector(flowContentState, state => state.flowDetails?.flowType);
export const getSimplifiedSignatureMode = createSelector(flowContentState, state => state.flowDetails?.simplifiedSignatureMode);

const getPhaseTotalCount = createSelector(flowContentState, state => state.phasesTotalCount);
const getResetPhases = createSelector(flowContentState, state => state.reset);
const getPhaseFilteredTotalCount = createSelector(flowContentState, state => state.phasesFilteredTotalCount || 0);
export const getSteps = createSelector(flowContentState, state => state.steps);
export const getFirstStep = createSelector(getSteps, steps => steps && steps[Object.keys(steps)[0]]?.children[0]?.uuidEntity);
export const getFlattenSteps = createSelector(flowContentState, state => flatten(Object.values(state.steps).map(value => value.children || [])));
const getPhaseSteps = (phaseUuidEntity: string) => createSelector(getSteps, steps => steps && steps[phaseUuidEntity]);
export const getPhaseStepsLastChildren = (phaseUuidEntity: string) => createSelector(getPhaseSteps(phaseUuidEntity), steps => steps && last(steps.children || []));

export const getLoadedPhases = createSelector(flowContentState, fromFlowContent.selectAllPhases);
export const getPhasesList = createSelector(getLoadedPhases, getSteps, getPhaseFilteredTotalCount, getResetPhases, (phases, steps, filteredTotalCount, reset) => ({
  phases,
  filteredTotalCount,
  steps,
  reset,
}));
export const showStepNavigationBlock = (phaseUuidEntity: string) =>
  createSelector(
    flowContentState,
    state =>
      state.steps &&
      state.steps[phaseUuidEntity] &&
      state.steps[phaseUuidEntity].children &&
      state.steps[phaseUuidEntity].children.filter(step => step.type === FlowMenuItemTypeEnum.Step).length > 1,
  );
const emptyPhasesList = createSelector(getPhaseTotalCount, phasesTotalCount => phasesTotalCount !== undefined && phasesTotalCount === 0);
export const getFirstPhase = createSelector(
  getLoadedPhases,
  getSteps,
  (phases, steps) => phases && phases.length === 1 && !(steps[phases[0].uuidEntity] && steps[phases[0].uuidEntity].children.length) && phases[0].uuidEntity,
);
export const flowContentTemplate = createSelector(emptyPhasesList, getFirstPhase, getActiveRouteId, (emptyPhaseList, firstPhase, activeRouteId) => {
  if (emptyPhaseList) {
    return FlowContentTemplateEnum.EmptyPhaseList;
  }
  if (firstPhase) {
    return FlowContentTemplateEnum.FirstPhaseInList;
  }
  if (activeRouteId === RouteIdEnum.FlowContent) {
    return FlowContentTemplateEnum.NoStepSelected;
  }

  return FlowContentTemplateEnum.Default;
});

export const getActivePhaseDialog = createSelector(
  getActiveRouteId,
  getRouteParam(RouteParamEnum.PhaseUuidEntity),
  (activatedRouteId, phaseUuidEntity) => activatedRouteId === RouteIdEnum.EditPhaseModal && phaseUuidEntity,
);

export const getActiveStepDialog = createSelector(
  getActiveRouteId,
  getRouteParam(RouteParamEnum.StepElementUuidEntity),
  (activatedRouteId, stepUuidEntity) => activatedRouteId === RouteIdEnum.EditStepModal && stepUuidEntity,
);

export const getPhasesQueryParams = createSelector(
  getTextSearchFilter,
  getRouteParam(RouteParamEnum.ProjectUuidEntity),
  getListQueryParam(RouteQueryParamEnum.Status),
  getListQueryParam(RouteQueryParamEnum.Block),
  getListQueryParam(RouteQueryParamEnum.Family),
  getListQueryParam(RouteQueryParamEnum.Category),
  PhaseSummaryData.initializeQueryRequest,
);
