import { FormLibraryDuplicateRequestDTO } from '../../generated/models/models';
import { FormNatureEnum } from '../../enums';

export interface FormLibraryForDuplicate {
  sourceUuidEntity: string;
  sourceName: string;
  sourceNature: FormNatureEnum;
  name: string;
  nature: FormNatureEnum;
}

export namespace FormLibraryForDuplicate {
  export function mapToRequestApiValue(formLibraryForDuplicate: FormLibraryForDuplicate): FormLibraryDuplicateRequestDTO {
    return {
      sourceUuidEntity: formLibraryForDuplicate.sourceUuidEntity,
      name: formLibraryForDuplicate.name,
      nature: FormNatureEnum.convertToApiValue.getValue(formLibraryForDuplicate.nature),
    };
  }
}
