import { createAction, props } from '@ngrx/store';

import {
  LazyLoadResponse,
  SkillSummaryData,
  ContributorSummaryData,
  ContributorImportData,
  ContributorForImport,
  TeamRoleForReorganize,
  ApplicationFileLightData,
  ContributorDetailsData,
} from '../../../../../core/models';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';

import { PopoverPositionEnum } from '../../../../../core/enums';

export const loadContributorSuccess = createAction('[dContributor/dialog] load contributor data success', props<{ contributor: ContributorDetailsData }>());
export const loadContributorFail = createAction('[dContributor/dialog] load contributor data fail', props<DialogToasterData>());

export const updateContributor = createAction('[dContributor/dialog] update contributor', props<{ contributor: ContributorDetailsData; close?: boolean }>());
export const updateContributorSuccess = createAction('[dContributor/dialog] update contributor success', props<{ close?: boolean }>());
export const updateContributorFail = createAction('[dContributor/dialog] update contributor fail', props<DialogToasterData>());

export const navigateThroughContributors = createAction('[dContributor/dialog] Navigate through Contributors', props<{ contributorUuidEntity?: string; step: number }>());
export const checkBeforeNavigateToContributor = createAction(
  '[dContributor/dialog] Check before navigate Contributors',
  props<{ contributor: ContributorDetailsData; step: number }>(),
);

export const loadImportContributorList = createAction('[dContributor/dialog] Load import contributor list', props<{ reset?: boolean }>());
export const loadImportContributorListSuccess = createAction(
  '[dContributor/dialog] Load import contributor list success',
  props<{ contributors: ContributorImportData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadImportContributorNextUserListPage = createAction('[dContributor/dialog] Load next import contributor list page');
export const loadImportContributorListFails = createAction('[dContributor/dialog] Load import contributor list fails', props<DialogToasterData>());

export const importTeamRole = createAction('[dContributor/dialog] import team role', props<{ import: ContributorForImport }>());
export const importTeamRoleSuccess = createAction('[dContributor/dialog] import team role success');
export const importTeamRoleFail = createAction('[dContributor/dialog] import team role fail', props<DialogToasterData>());

export const loadSkillContributorList = createAction('[dContributor/dialog] Load Skill contributor list');
export const loadSkillContributorListSuccess = createAction(
  '[dContributor/dialog] Load Skill contributor list success',
  props<{ data: LazyLoadResponse<SkillSummaryData[]>; reset?: boolean }>(),
);
export const loadSkillContributorNextUserListPage = createAction('[dContributor/dialog] Load next Skill contributor list page');
export const loadSkillContributorListFails = createAction('[dContributor/dialog] Load Skill contributor list fails', props<DialogToasterData>());

export const setSkillDetailData = createAction('[dContributor/dialog] set contributor skill data', props<ContributorSummaryData>());
export const setCurrentPdfId = createAction('[dContributor/dialog] set current pdf ID', props<{ currentPdfId: string }>());

export const downloadSkillSummaryFile = createAction('[dContributor/dialog] download summary file', props<{ userUuidEntity: string }>());
export const downloadSkillSummaryFileFailed = createAction('[dContributor/dialog] download summary file failed', props<DialogToasterData>());

export const downloadSkillPdfFile = createAction('[dContributor/dialog] download skill pdf file', props<{ fileId: string }>());
export const downloadSkillPdfFileFailed = createAction('[dContributor/dialog] download skill pdf file failed', props<DialogToasterData>());

export const openVisualizePdfPopover = createAction(
  '[dContributor/dialog] open visualize pdf popover',
  props<{ origin: HTMLElement; position: PopoverPositionEnum; files: ApplicationFileLightData[] }>(),
);
export const viewPdfFile = createAction('[dContributor/dialog] view pdf file', props<{ fileId: string }>());
export const viewPdfFileSuccess = createAction('[dContributor/dialog] view pdf file Success');
export const viewPdfFileFail = createAction('[dContributor/dialog] view pdf file fail', props<DialogToasterData>());

export const loadReorganizeContributorsList = createAction('[dContributor/dialog] Load reorganize contributors list');
export const loadReorganizeContributorsListSuccess = createAction(
  '[dContributor/dialog] Load reorganize contributors list success',
  props<{ users: ContributorSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadReorganizeNextContributorsListPage = createAction('[dContributor/dialog] Load next reorganize contributors list page');
export const loadReorganizeContributorsListFails = createAction('[dContributor/dialog] Load reorganize contributors list fails', props<DialogToasterData>());

export const reorganizeContributors = createAction('[dContributor/dialog] reorganize contributors', props<{ data: TeamRoleForReorganize; count: number }>());
export const reorganizeContributorsFail = createAction('[dContributor/dialog] reorganize contributors fail', props<DialogToasterData>());
