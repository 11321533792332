import { compact } from 'lodash';

import { PageSectionDocumentToImportRequestDTO } from '../../generated/models/pageSectionDocumentToImportRequestDTO';

import { DocumentProvenanceEnum, InnerSortDirectionEnum } from '../../enums';
import { DocumentFilterStateEnum } from '../../enums/document/document-filter-state.enum';

import { DateFilterData } from '../utils/date-filter-data';
import { SortFilter } from '../utils/sort-filter';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { SectionDocumentSummaryData } from './section-document-summary-data';
import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';

export interface SectionDocumentToImportRequest extends BaseQuery<SectionDocumentSummaryData> {
  sectionUuidEntity: string;
  projectUuidEntity: string;
  families: string[];
  categoryPrincipal: string[];
  categorySecondary: string[];
  flows: string[];
  provenances: DocumentProvenanceEnum[];
  states: DocumentFilterStateEnum[];
  statuses: string[];
  dateFilter: DateFilterData;
  customWording?: string[];
}

export namespace SectionDocumentToImportRequest {
  const sortFields = ['family', 'name', 'refInt', 'indice,', 'etat'];

  export function mapToApiValue(queries: SectionDocumentToImportRequest): PageSectionDocumentToImportRequestDTO {
    return {
      categoryPrincipalUuidEntity: queries.categoryPrincipal,
      categorySecondaryUuidEntity: queries.categorySecondary,
      filterEtat: (queries.states && queries.states.map(state => DocumentFilterStateEnum.convertToApiValue.getValue(state))) || undefined,
      familyUuidEntity: queries.families,
      flowUuidEntity: queries.flows,
      page: queries.page,
      projectUuide: queries.projectUuidEntity,
      provenance: (queries.provenances && queries.provenances.map(provenance => DocumentProvenanceEnum.convertToApiValue.getValue(provenance))) || undefined,
      sectionUuide: queries.sectionUuidEntity,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      status: queries.statuses,
      customWordingUuidEntities: compact(queries.customWording) || undefined,
      textSearch: queries.textSearch || undefined,
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
    };
  }

  export function initialize(
    queryParam: { projectUuidEntity: string; sectionUuidEntity: string; searchText: string },
    listQueryParam: {
      categoryPrincipal: string[];
      categorySecondary: string[];
      families: string[];
      customWording: CustomWordingStateData[];
      flows: string[];
      provenances: string[];
      statuses: string[];
    },
    sort: SortFilter<SectionDocumentSummaryData>,
    dateFilter: DateFilterData,
  ): SectionDocumentToImportRequest {
    return {
      ...initializeQueries<SectionDocumentSummaryData>(sort),
      projectUuidEntity: queryParam.projectUuidEntity,
      sectionUuidEntity: queryParam.sectionUuidEntity,
      textSearch: queryParam.searchText || undefined,
      categoryPrincipal: (listQueryParam.categoryPrincipal || []).length ? listQueryParam.categoryPrincipal : undefined,
      categorySecondary: (listQueryParam.categorySecondary || []).length ? listQueryParam.categorySecondary : undefined,
      families: (listQueryParam.families || []).length ? listQueryParam.families : undefined,
      states: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(cw => cw.actionKey) : undefined,
      flows: (listQueryParam.flows || []).length ? listQueryParam.flows : undefined,
      customWording: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(cw => cw.customWordingUuidEntity) : undefined,
      provenances: (listQueryParam.provenances || []).length
        ? listQueryParam.provenances.map(provenance => DocumentProvenanceEnum.convertFromParamsValue.getValue(provenance))
        : undefined,
      statuses: (listQueryParam.statuses || []).length ? listQueryParam.statuses : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
