import { Action, createReducer, on } from '@ngrx/store';

import { FolderDetailsData, SectionDetailsData } from '../../../../../core/models';
import * as fromFolderDialogActions from './folder-dialog.actions';

export interface AddFolderDialogState {
  saveLoading: boolean;
  folderDetails: FolderDetailsData;
}

const initialAddFolderDialogState = (): AddFolderDialogState => ({
  saveLoading: false,
  folderDetails: {} as FolderDetailsData,
});

export interface AddSectionDialogState {
  saveLoading: boolean;
  sectionDetails: SectionDetailsData;
}

const initialAddSectionDialogState = (): AddSectionDialogState => ({
  saveLoading: false,
  sectionDetails: {} as SectionDetailsData,
});

export interface FolderDialogState {
  addFolder: AddFolderDialogState;
  addSection: AddSectionDialogState;
}

export const initialFolderDialogState = () => ({
  addFolder: initialAddFolderDialogState(),
  addSection: initialAddSectionDialogState(),
});

const reducer = createReducer<FolderDialogState>(
  initialFolderDialogState(),

  on(
    fromFolderDialogActions.createFolder,
    fromFolderDialogActions.updateFolder,
    (state): FolderDialogState => ({ ...state, addFolder: { ...state.addFolder, saveLoading: true } }),
  ),
  on(
    fromFolderDialogActions.createFolderFail,
    fromFolderDialogActions.updateFolderFail,
    (state): FolderDialogState => ({ ...state, addFolder: { ...state.addFolder, saveLoading: false } }),
  ),
  on(
    fromFolderDialogActions.createFolderSuccess,
    fromFolderDialogActions.updateFolderSuccess,
    (state): FolderDialogState => ({ ...state, addFolder: { ...state.addFolder, saveLoading: false } }),
  ),

  on(fromFolderDialogActions.loadFolderDetailsDataSuccess, (state, { folderDetails }): FolderDialogState => ({ ...state, addFolder: { ...state.addFolder, folderDetails } })),

  on(fromFolderDialogActions.createSection, (state): FolderDialogState => ({ ...state, addSection: { ...state.addSection, saveLoading: true } })),
  on(
    fromFolderDialogActions.createSectionFail,
    fromFolderDialogActions.updateSectionFail,
    (state): FolderDialogState => ({ ...state, addSection: { ...state.addSection, saveLoading: false } }),
  ),
  on(
    fromFolderDialogActions.createSectionSuccess,
    (state, { section, addNewOne }): FolderDialogState => ({
      ...state,
      addSection: {
        ...state.addSection,
        sectionDetails: addNewOne ? { ...section, name: null, description: null, category: null } : section,
        saveLoading: false,
      },
    }),
  ),
  on(
    fromFolderDialogActions.loadSectionDetailsDataSuccess,
    (state, { sectionDetails }): FolderDialogState => ({ ...state, addSection: { ...state.addSection, sectionDetails, saveLoading: false } }),
  ),
);

export function folderDialogReducer(state: FolderDialogState | undefined, action: Action): FolderDialogState {
  return reducer(state, action);
}
