/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicationFileLightDTO } from './applicationFileLightDTO';
import { EntityLightResponseDTO } from './entityLightResponseDTO';
import { LocalTime } from './localTime';

export interface RubricOptionDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name?: string;
  description?: string;
  optionOrder?: number;
  createDate: Date;
  modificationDate: Date;
  enums: string;
  textValue: string;
  textValueUnit: string;
  checkedValue: boolean;
  defaultResponse: boolean;
  dateValue: string;
  timeValue: LocalTime;
  dateTimeValue: Date;
  booleanValue: boolean;
  attachementWidth: number;
  attachementHeight: number;
  targetValue: string;
  checkedTargetValue: boolean;
  checkedPdfFieldName: boolean;
  pdfFieldName: string;
  checkedValidation: boolean;
  natureValidation: RubricOptionDetailsResponseDTO.NatureValidationEnum;
  valueOne: string;
  valueTwo: string;
  validationAlertText: string;
  checkedAlert: boolean;
  natureAlert: RubricOptionDetailsResponseDTO.NatureAlertEnum;
  alertText: string;
  checkedNextQuestion: boolean;
  nextRubric: EntityLightResponseDTO;
  nextQuestion: EntityLightResponseDTO;
  latitude: number;
  longitude: number;
  altitude: number;
  icon: string;
  color: string;
  multipleResponse: boolean;
  checkedBlockingControl: boolean;
  preEvaluationValue: string;
  preEvaluationEnums: string;
  checkedDisabledValue: boolean;
  exportXlsCheck: boolean;
  exportXlsFieldName: string;
  placeholder: string;
  optionName: string;
  checkedImageSize: boolean;
  canEvaluate: boolean;
  applicationFiles: Array<ApplicationFileLightDTO>;
  locked: boolean;
  evaluatedValue: string;
  content: string;
}
export namespace RubricOptionDetailsResponseDTO {
  export type NatureValidationEnum =
    | 'MORE_THAN'
    | 'MORE_THAN_EQUAL'
    | 'LESS_THAN'
    | 'LESS_THAN_EQUAL'
    | 'EQUAL'
    | 'NOT_EQUAL'
    | 'BETWEEN'
    | 'NOT_INCLUDED_BETWEEN'
    | 'BEFORE_DATE'
    | 'AFTER_DATE'
    | 'INCLUDED_BETWEEN_DATES'
    | 'NOT_INCLUDED_BETWEEN_DATES';
  export const NatureValidationEnum = {
    MORETHAN: 'MORE_THAN' as NatureValidationEnum,
    MORETHANEQUAL: 'MORE_THAN_EQUAL' as NatureValidationEnum,
    LESSTHAN: 'LESS_THAN' as NatureValidationEnum,
    LESSTHANEQUAL: 'LESS_THAN_EQUAL' as NatureValidationEnum,
    EQUAL: 'EQUAL' as NatureValidationEnum,
    NOTEQUAL: 'NOT_EQUAL' as NatureValidationEnum,
    BETWEEN: 'BETWEEN' as NatureValidationEnum,
    NOTINCLUDEDBETWEEN: 'NOT_INCLUDED_BETWEEN' as NatureValidationEnum,
    BEFOREDATE: 'BEFORE_DATE' as NatureValidationEnum,
    AFTERDATE: 'AFTER_DATE' as NatureValidationEnum,
    INCLUDEDBETWEENDATES: 'INCLUDED_BETWEEN_DATES' as NatureValidationEnum,
    NOTINCLUDEDBETWEENDATES: 'NOT_INCLUDED_BETWEEN_DATES' as NatureValidationEnum,
  };
  export type NatureAlertEnum = 'TRACE' | 'INFO' | 'WARN' | 'ERROR';
  export const NatureAlertEnum = {
    TRACE: 'TRACE' as NatureAlertEnum,
    INFO: 'INFO' as NatureAlertEnum,
    WARN: 'WARN' as NatureAlertEnum,
    ERROR: 'ERROR' as NatureAlertEnum,
  };
}
