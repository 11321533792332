import { compact } from 'lodash';
import { DocumentFlowSummaryToImportResponseDTO } from '../../generated/models/models';

import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';

import { DocumentProvenanceEnum, DocumentStatusEnum } from '../../enums';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface SectionDocumentSummaryData {
  uuidEntity: string;
  name: string;
  subtitle?: string;
  order?: number;
  family: LabelFamilyLightData;
  refIntern: string;
  docIndex: string;
  status: DocumentStatusEnum;
  provenance?: DocumentProvenanceEnum;
  alreadyImported?: boolean;
  principalCategory?: LabelFamilyLightData;
  secondaryCategory?: LabelFamilyLightData;
  lastVdf?: DocumentVersionLightData;
  complementRefInt?: string;
  largeFile?: boolean;
  rfi?: boolean;
  customWording: CustomWordingsData;
  diffusionDate: string;
}

export namespace SectionDocumentSummaryData {
  const emptyDocumentIndex = '-';

  export function mapFromApiValue(document: DocumentFlowSummaryToImportResponseDTO): SectionDocumentSummaryData {
    return {
      uuidEntity: document.uuidEntity,
      name: document.name,
      subtitle: compact([document.flowLight?.refInt, document.flowLight?.name]).join(' - '),
      refIntern: document.refInt,
      docIndex: document.indice || emptyDocumentIndex,
      family: (document.family && LabelFamilyLightData.mapFromApiValue(document.family)) || LabelFamilyLightData.defaultData,
      status: DocumentStatusEnum.convertFromApiValue.getValue(document.etat),
      provenance: DocumentProvenanceEnum.convertFromApiValue.getValue(document.provenance),
      alreadyImported: document.alreadyImported,
      principalCategory: document.categoryPrincipal && LabelFamilyLightData.mapFromApiValue(document.categoryPrincipal),
      secondaryCategory: document.categorySecondary && LabelFamilyLightData.mapFromApiValue(document.categorySecondary),
      lastVdf: document.lastVdf && DocumentVersionLightData.mapFromApiValue(document?.lastVdf),
      complementRefInt: document.complementRefInt,
      largeFile: document.largeFile,
      rfi: document['rfi'], // @Majdi MakeModels: remove it
      customWording: document.customWording && CustomWordingsData.mapFromApiValue(document.customWording),
      diffusionDate: DateUtils.toDateFormat(document.diffusionDate),
    };
  }
}
