import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContextMenuService } from '../../../core/services/context-menu.service';
import { PopoverService } from '../components/popover/popover.service';
import { VerticalMenuComponent } from '../components/vertical-menu/vertical-menu.component';
import { CustomMenuActionEnum } from '../../../core/enums/entity-actions/custom-menu-action.enum';
import { PopoverPositionEnum } from '../../../core/enums/utils/popover-position.enum';

@Directive({
  selector: '[sfxContextMenu]',
  standalone: true,
})
export class ContextMenuDirective {
  @Input() set sfxContextMenu(active: boolean) {
    this.isActive = active;
  }
  @Output() contextMenuPaste = new EventEmitter<string>();

  private element: HTMLInputElement;
  private isOpened: boolean;
  private isActive: boolean;
  private subscriptions: Subscription[] = [];

  constructor(private elementRef: ElementRef<HTMLInputElement>, private popoverService: PopoverService, private contextMenuService: ContextMenuService) {
    this.element = this.elementRef.nativeElement;
  }

  @HostListener('contextmenu', ['$event']) onContextMenu(event: MouseEvent): void {
    if (this.isActive) {
      event.preventDefault();

      if (!this.isOpened) {
        this.subscribeToContextMenu();
        this.openPopover();
      }
    }
  }

  private subscribeToContextMenu(): void {
    this.subscriptions.push(
      this.contextMenuService.getAction().subscribe(action => {
        switch (action) {
          case CustomMenuActionEnum.Copy:
            this.copyToClipboard();
            break;
          case CustomMenuActionEnum.Cut:
            this.copyToClipboard(true);
            break;
          case CustomMenuActionEnum.Paste:
            this.pasteFromClipboard();
            break;
          default:
            break;
        }

        this.closePopover();
      }),
    );
  }

  private copyToClipboard(clean?: boolean): void {
    const startIndex = this.element.selectionStart;
    const endIndex = this.element.selectionEnd;
    if (startIndex === endIndex) {
      return;
    }
    const copiedText = this.element.value?.slice(startIndex, endIndex - startIndex);
    navigator.clipboard.writeText(copiedText).catch(error => console.warn('Copy error', error));
    if (clean) {
      this.element.value = [this.element.value.slice(0, startIndex), this.element.value.slice(endIndex)].join('');
      this.element.setSelectionRange(0, 0);
    }
  }

  private pasteFromClipboard(): void {
    navigator.clipboard
      .readText()
      .then(copiedText => {
        const startIndex = this.element.selectionStart;
        const endIndex = this.element.selectionEnd;
        const text = [this.element.value.slice(0, startIndex), copiedText, this.element.value.slice(endIndex)].join('');
        this.element.value = text;
        this.contextMenuPaste.emit(text);
      })
      .catch(error => console.warn('Paste error', error));
  }

  private openPopover(): void {
    this.isOpened = true;
    this.subscriptions.push(
      this.popoverService
        .open({
          origin: this.element,
          content: VerticalMenuComponent,
          preferredPosition: PopoverPositionEnum.Vertical,
          showAnchor: false,
          panelClass: 'sfx-context-menu',
        })
        .afterClosed$.pipe()
        .subscribe(() => (this.isOpened = false)),
    );
  }

  private closePopover(): void {
    this.isOpened = false;
    this.subscriptions.forEach(subs => subs?.unsubscribe());
    this.popoverService.closePopover();
  }
}
