import { SfxMap } from '../../utils/enum-utils';

export enum GeoMapColumnEnum {
  Name = 'name',
  Color = 'color',
  CreateDate = 'createDate',
  Actions = 'action',
}

export namespace GeoMapColumnEnum {
  export const allColumns = [GeoMapColumnEnum.Name, GeoMapColumnEnum.Color, GeoMapColumnEnum.CreateDate, GeoMapColumnEnum.Actions];
  export const multiPrColumns = [GeoMapColumnEnum.Name, GeoMapColumnEnum.Actions];

  const key = 'flows.map.table.header.';
  export const toString: SfxMap<GeoMapColumnEnum, string> = new SfxMap<GeoMapColumnEnum, string>([
    [GeoMapColumnEnum.Name, key + 'name'],
    [GeoMapColumnEnum.Color, key + 'color'],
    [GeoMapColumnEnum.CreateDate, key + 'createDate'],
    [GeoMapColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<GeoMapColumnEnum, string> = new SfxMap<GeoMapColumnEnum, string>([[GeoMapColumnEnum.Name, 'name']]);
}
