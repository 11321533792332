import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';

export enum CustomStatusColumnEnum {
  Family = 'family',
  Importation = 'importation',
  Exportation = 'exportation',
  MobileApp = 'mobileApp',
  Actions = 'action',
  HiddenEntity = 'hiddenEntity',
}

export namespace CustomStatusColumnEnum {
  export const allColumns = [
    CustomStatusColumnEnum.Family,
    CustomStatusColumnEnum.Importation,
    CustomStatusColumnEnum.Exportation,
    CustomStatusColumnEnum.MobileApp,
    CustomStatusColumnEnum.Actions,
  ];
  export const columns: SfxMap<BusinessClassNameEnum, CustomStatusColumnEnum[]> = new SfxMap<BusinessClassNameEnum, CustomStatusColumnEnum[]>(
    [
      [BusinessClassNameEnum.Flow, [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Importation, CustomStatusColumnEnum.MobileApp, CustomStatusColumnEnum.Actions]],
      [BusinessClassNameEnum.Form, [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Importation, CustomStatusColumnEnum.MobileApp, CustomStatusColumnEnum.Actions]],
      [BusinessClassNameEnum.FormResponse, [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Exportation, CustomStatusColumnEnum.Actions]],
      [BusinessClassNameEnum.TechnicalObject, [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Actions]],
      [BusinessClassNameEnum.MultiPr, [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Actions]],
      [
        BusinessClassNameEnum.Project,
        [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.HiddenEntity /* CustomStatusColumnEnum.MobileApp   waiting for the mobile US */, CustomStatusColumnEnum.Actions],
      ],
    ],
    [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Actions],
  );
  export const multiPrColumns = [CustomStatusColumnEnum.Family, CustomStatusColumnEnum.Actions];

  const key = 'status.table.header.';
  export const toString: SfxMap<CustomStatusColumnEnum, string> = new SfxMap<CustomStatusColumnEnum, string>([
    [CustomStatusColumnEnum.Family, key + 'family'],
    [CustomStatusColumnEnum.Importation, key + 'importation'],
    [CustomStatusColumnEnum.Exportation, key + 'exportation'],
    [CustomStatusColumnEnum.MobileApp, key + 'mobileApp'],
    [CustomStatusColumnEnum.Actions, key + 'action'],
    [CustomStatusColumnEnum.HiddenEntity, key + 'hiddenEntity'],
  ]);

  export const sortField: SfxMap<CustomStatusColumnEnum, string> = new SfxMap<CustomStatusColumnEnum, string>([[CustomStatusColumnEnum.Family, 'family']]);
}
