import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { DocumentFlowFindLazyByProjectRequestDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum DocumentFilterStateEnum {
  prel = 1,
  clientReturnBpo = 2,
  diffusionBpo = 3,
  bpeSuiteVsosr = 4,
  bpe = 5,
  bpeValid = 6,
  clientReturnBpa = 7,
  diffusionBpa = 8,
  bpaValid = 9,
  Other = 10,
}

export namespace DocumentFilterStateEnum {
  const values: DocumentFilterStateEnum[] = [
    DocumentFilterStateEnum.Other,
    DocumentFilterStateEnum.prel,
    DocumentFilterStateEnum.clientReturnBpo,
    DocumentFilterStateEnum.diffusionBpo,
    DocumentFilterStateEnum.bpeSuiteVsosr,
    DocumentFilterStateEnum.bpe,
    DocumentFilterStateEnum.bpeValid,
    DocumentFilterStateEnum.clientReturnBpa,
    DocumentFilterStateEnum.diffusionBpa,
    DocumentFilterStateEnum.bpaValid,
  ];

  export const convertFromApiValue = new SfxMap<DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum, DocumentFilterStateEnum>([
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPAVALID, DocumentFilterStateEnum.bpaValid],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPE, DocumentFilterStateEnum.bpe],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPESUITEVSOSR, DocumentFilterStateEnum.bpeSuiteVsosr],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPEVALID, DocumentFilterStateEnum.bpeValid],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.CLIENTRETURNBPA, DocumentFilterStateEnum.clientReturnBpa],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.CLIENTRETURNBPO, DocumentFilterStateEnum.clientReturnBpo],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.DIFFUSIONBPA, DocumentFilterStateEnum.diffusionBpa],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.DIFFUSIONBPO, DocumentFilterStateEnum.diffusionBpo],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.PREL, DocumentFilterStateEnum.prel],
    [DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.WITHOUT, DocumentFilterStateEnum.Other],
  ]);

  export const convertToApiValue: SfxMap<DocumentFilterStateEnum, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum> = new SfxMap<
    DocumentFilterStateEnum,
    DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum
  >([
    [DocumentFilterStateEnum.bpaValid, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPAVALID],
    [DocumentFilterStateEnum.bpe, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPE],
    [DocumentFilterStateEnum.bpeSuiteVsosr, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPESUITEVSOSR],
    [DocumentFilterStateEnum.bpeValid, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.BPEVALID],
    [DocumentFilterStateEnum.clientReturnBpa, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.CLIENTRETURNBPA],
    [DocumentFilterStateEnum.clientReturnBpo, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.CLIENTRETURNBPO],
    [DocumentFilterStateEnum.diffusionBpa, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.DIFFUSIONBPA],
    [DocumentFilterStateEnum.diffusionBpo, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.DIFFUSIONBPO],
    [DocumentFilterStateEnum.prel, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.PREL],
    [DocumentFilterStateEnum.Other, DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum.WITHOUT],
  ]);

  export const convertToParamsValue: SfxMap<DocumentFilterStateEnum, string> = new SfxMap<DocumentFilterStateEnum, string>([
    [DocumentFilterStateEnum.bpaValid, 'bpaValid'],
    [DocumentFilterStateEnum.bpe, 'bpe'],
    [DocumentFilterStateEnum.bpeSuiteVsosr, 'bpeSuiteVsosr'],
    [DocumentFilterStateEnum.bpeValid, 'bpeValid'],
    [DocumentFilterStateEnum.clientReturnBpa, 'clientReturnBpa'],
    [DocumentFilterStateEnum.clientReturnBpo, 'clientReturnBpo'],
    [DocumentFilterStateEnum.diffusionBpa, 'diffusionBpa'],
    [DocumentFilterStateEnum.diffusionBpo, 'diffusionBpo'],
    [DocumentFilterStateEnum.prel, 'prel'],
    [DocumentFilterStateEnum.Other, 'without'],
  ]);

  export const convertFromParamsValue: SfxMap<string, DocumentFilterStateEnum> = new SfxMap<string, DocumentFilterStateEnum>([
    ['bpaValid', DocumentFilterStateEnum.bpaValid],
    ['bpe', DocumentFilterStateEnum.bpe],
    ['bpeSuiteVsosr', DocumentFilterStateEnum.bpeSuiteVsosr],
    ['bpeValid', DocumentFilterStateEnum.bpeValid],
    ['clientReturnBpa', DocumentFilterStateEnum.clientReturnBpa],
    ['clientReturnBpo', DocumentFilterStateEnum.clientReturnBpo],
    ['diffusionBpa', DocumentFilterStateEnum.diffusionBpa],
    ['diffusionBpo', DocumentFilterStateEnum.diffusionBpo],
    ['prel', DocumentFilterStateEnum.prel],
    ['without', DocumentFilterStateEnum.Other],
  ]);

  const filtreKey = 'document.filtreStatuses.';
  export const toString: SfxMap<DocumentFilterStateEnum, string> = new SfxMap<DocumentFilterStateEnum, string>([
    [DocumentFilterStateEnum.prel, filtreKey + 'prel'],
    [DocumentFilterStateEnum.clientReturnBpo, filtreKey + 'clientReturnBpo'],
    [DocumentFilterStateEnum.diffusionBpo, filtreKey + 'diffusionBpo'],
    [DocumentFilterStateEnum.bpeSuiteVsosr, filtreKey + 'bpeSuiteVsosr'],
    [DocumentFilterStateEnum.bpe, filtreKey + 'bpe'],
    [DocumentFilterStateEnum.bpeValid, filtreKey + 'bpeValid'],
    [DocumentFilterStateEnum.clientReturnBpa, filtreKey + 'clientReturnBpa'],
    [DocumentFilterStateEnum.diffusionBpa, filtreKey + 'diffusionBpa'],
    [DocumentFilterStateEnum.bpaValid, filtreKey + 'bpaValid'],
    [DocumentFilterStateEnum.Other, filtreKey + 'other'],
  ]);

  export const sfsTooltip: SfxMap<DocumentFilterStateEnum, string> = new SfxMap<DocumentFilterStateEnum, string>([
    [DocumentFilterStateEnum.bpaValid, filtreKey + 'tooltip.bpaValid'],
    [DocumentFilterStateEnum.bpe, filtreKey + 'tooltip.bpe'],
    [DocumentFilterStateEnum.bpeSuiteVsosr, filtreKey + 'tooltip.bpeSuiteVsosr'],
    [DocumentFilterStateEnum.bpeValid, filtreKey + 'tooltip.bpeValid'],
    [DocumentFilterStateEnum.clientReturnBpa, filtreKey + 'tooltip.clientReturnBpa'],
    [DocumentFilterStateEnum.clientReturnBpo, filtreKey + 'tooltip.clientReturnBpo'],
    [DocumentFilterStateEnum.diffusionBpa, filtreKey + 'tooltip.diffusionBpa'],
    [DocumentFilterStateEnum.diffusionBpo, filtreKey + 'tooltip.diffusionBpo'],
    [DocumentFilterStateEnum.prel, filtreKey + 'tooltip.prel'],
    [DocumentFilterStateEnum.Other, filtreKey + 'tooltip.other'],
  ]);

  export const translate: SfxMap<string, string> = new SfxMap<string, string>([
    ['bpaValid', filtreKey + 'bpaValid'],
    ['bpe', filtreKey + 'bpe'],
    ['bpeSuiteVsosr', filtreKey + 'bpeSuiteVsosr'],
    ['bpeValid', filtreKey + 'bpeValid'],
    ['clientReturnBpa', filtreKey + 'clientReturnBpa'],
    ['clientReturnBpo', filtreKey + 'clientReturnBpo'],
    ['diffusionBpa', filtreKey + 'diffusionBpa'],
    ['diffusionBpo', filtreKey + 'diffusionBpo'],
    ['prel', filtreKey + 'prel'],
    ['without', filtreKey + 'other'],
  ]);

  export const stateItemsResolver$ = (): Observable<MultiSelectItemData<DocumentFilterStateEnum>[]> =>
    of(values.map(status => ({ value: status, title: toString.getValue(status) })));
}
