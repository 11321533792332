import { compact } from 'lodash';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';

export enum FlowModalTabEnum {
  General = 'general',
  CustomField = 'customField',
  Document = 'referentialFlowDocument',
  Assignment = 'assignment',
  Planning = 'planning',
  Options = 'options',
}

export namespace FlowModalTabEnum {
  export function getValues(flowType?: FlowTypeEnum): string[] {
    return compact([
      FlowModalTabEnum.General,
      FlowModalTabEnum.CustomField,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowModalTabEnum.Document,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowModalTabEnum.Assignment,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowModalTabEnum.Planning,
      FlowTypeEnum.flowsWhereCanSetSupervisions.includes(flowType) && FlowModalTabEnum.Options,
    ]);
  }

  const key = 'flows.modals.addFlow.';
  export const toString: SfxMap<FlowModalTabEnum, string> = new SfxMap<FlowModalTabEnum, string>([
    [FlowModalTabEnum.General, key + 'general'],
    [FlowModalTabEnum.CustomField, key + 'customAttr'],
    [FlowModalTabEnum.Document, key + 'document'],
    [FlowModalTabEnum.Assignment, key + 'assignment'],
    [FlowModalTabEnum.Planning, key + 'planning'],
    [FlowModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<FlowModalTabEnum, IconData> = new SfxMap<FlowModalTabEnum, IconData>([
    [FlowModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [FlowModalTabEnum.CustomField, { name: 'paint-roller', prefix: 'fas' }],
    [FlowModalTabEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [FlowModalTabEnum.Assignment, { name: 'user-friends', prefix: 'fas' }],
    [FlowModalTabEnum.Planning, { name: 'calendar-alt', prefix: 'far' }],
    [FlowModalTabEnum.Options, { name: 'file-invoice', prefix: 'fas' }],
  ]);
}
