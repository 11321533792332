import { compact } from 'lodash';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { InnerSortDirectionEnum, ProjectStatusEnum, ProjectTypeEnum } from '../../enums';

import { PageProjectForTechnicalObjectRequestDTO, ProjectForDuplicateFlowPageRequest, ProjectLightResponseDTO, ProjectSummaryResponseDTO } from '../../generated/models/models';
import { BaseQuery, defaultPageSize, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';
import { ProjectSummaryData } from './project-summary';

export namespace DropdownProjectData {
  export interface QueryRequest extends BaseQuery<ProjectSummaryData> {
    types?: ProjectTypeEnum[];
    applicability?: string[];
    status?: ProjectStatusEnum;
    labelFamilies?: string[];
    managers?: string[];
    clients?: string[];
    flowToDuplicateUuidEntity?: string;
    folderUuidEntity?: string;
    published?: boolean;
  }
  export function mapToSingleSelectItemData(projectLight: ProjectLightResponseDTO): SingleSelectItemData {
    return {
      value: { uuidEntity: projectLight.uuidEntity, name: projectLight.name },
      title: compact([projectLight.name, projectLight.index]).join(' - '),
    };
  }

  export function mapToSingleSelectItemDataFilter(project: ProjectSummaryResponseDTO): SingleSelectItemData<string> {
    return {
      value: project.uuidEntity,
      title: project.designation,
    };
  }

  function mapToEntityLightData(project: ProjectSummaryResponseDTO): EntityLightData {
    return {
      uuidEntity: project.uuidEntity,
      name: project.designation,
      labelFamilyUuidEntity: project.labelFamilyApplication && project.labelFamilyApplication.uuidEntity,
      projectType: ProjectTypeEnum.convertFromApiValue.getValue(project.typeProject),
    };
  }

  export function mapToSelectItem(project: ProjectSummaryResponseDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: mapToEntityLightData(project),
      title: project.designation,
      subtitle: ProjectTypeEnum.toString.getValue(ProjectTypeEnum.convertFromApiValue.getValue(project.typeProject)),
    };
  }

  export function mapToMultiSelectItem(project: ProjectSummaryResponseDTO): MultiSelectItemData {
    return {
      value: project.uuidEntity,
      title: project.designation,
      subtitle: ProjectTypeEnum.toString.getValue(ProjectTypeEnum.convertFromApiValue.getValue(project.typeProject)),
    };
  }

  export function mapToSelectItemIndexFilter(project: ProjectSummaryResponseDTO): SingleSelectItemData<EntityLightData> {
    const subProjectDesignation = project.index || project.designation;

    return {
      value: { ...mapToEntityLightData(project), name: subProjectDesignation },
      title: subProjectDesignation,
    };
  }

  export function mapToForDuplicateFlowPageRequestApiValue(queries: QueryRequest): ProjectForDuplicateFlowPageRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      typesProject: (queries.types && queries.types.map(type => ProjectTypeEnum.convertToApiValue.getValue(type))) || undefined,
      textSearch: queries.textSearch || undefined,
      uuidEntityFlow: queries.flowToDuplicateUuidEntity || undefined,
    };
  }

  export function initializeFilterQueries(
    page: number,
    searchText?: string,
    types?: ProjectTypeEnum[],
    status?: ProjectStatusEnum,
    flowToDuplicateUuidEntity?: string,
    folderUuidEntity?: string,
    applicability?: string[],
    published?: boolean,
  ): QueryRequest {
    return {
      ...initializeQueries<ProjectSummaryData>(),
      applicability: (applicability || []).length ? applicability : undefined,
      page,
      textSearch: searchText || undefined,
      types: types && types.length ? types : undefined,
      status,
      flowToDuplicateUuidEntity,
      folderUuidEntity,
      published,
    };
  }

  export function initializeTechnicalObjectFilterQueries(page: number, searchText?: string, objectUuidEntity?: string): PageProjectForTechnicalObjectRequestDTO {
    return {
      size: defaultPageSize,
      page: page,
      textSearch: searchText || undefined,
      technicalObjectUuidEntity: objectUuidEntity || undefined,
    };
  }
}
