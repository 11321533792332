import { DocumentFlowFindLazyByProjectRequestDTO, DocumentFlowSummaryToImportResponseDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';
import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { DocumentNatureEnum, DocumentProvenanceEnum, DocumentSourceEnum, DocumentStatusEnum, InnerSortDirectionEnum } from '../../enums';
import { DocumentFilterStateEnum } from '../../enums/document/document-filter-state.enum';
import { compact } from 'lodash';

export interface BlockDocumentToImportSummaryData {
  uuidEntity: string;
  name: string;
  refInt: string;
  status: DocumentStatusEnum;
  documentIndex: string;
  family: LabelFamilyLightData;
  principalCategory: LabelFamilyLightData;
  secondaryCategory: LabelFamilyLightData;
  frozen: boolean;
  provenance: DocumentProvenanceEnum;
  nature: DocumentNatureEnum;
  lastVdf: DocumentVersionLightData;
  selectedDocVersions: string[];
  complementRefInt?: string;
  lastVersionDocumentFlowUuidEntity?: string;
  largeFile?: boolean;
  customWording: CustomWordingsData;
  atLeastOneFile?: boolean;
  source?: DocumentSourceEnum;
}

export namespace BlockDocumentToImportSummaryData {
  const emptyDocumentIndex = '-';
  const sortFields = ['labelFamilyApplication', 'name', 'refInt', 'indice', 'etat'];

  export interface QueryRequest extends BaseQuery<BlockDocumentToImportSummaryData> {
    projectUuidEntity: string;
    flowUuidEntity: string;
    blockUuidEntity: string;
    families?: string[];
    categories?: string[];
    categories2?: string[];
    statuses?: DocumentFilterStateEnum[];
    customWordingUuidEntities?: string[];
  }

  export function mapFromApiValue(document: DocumentFlowSummaryToImportResponseDTO): BlockDocumentToImportSummaryData {
    return {
      uuidEntity: document.uuidEntity,
      name: document.name,
      refInt: document.refInt,
      documentIndex: document.indice || emptyDocumentIndex,
      status: DocumentStatusEnum.convertFromApiValue.getValue(document.etat),
      family: document.family && LabelFamilyLightData.mapFromApiValue(document.family),
      principalCategory: document.categoryPrincipal && LabelFamilyLightData.mapFromApiValue(document.categoryPrincipal),
      secondaryCategory: document.categorySecondary && LabelFamilyLightData.mapFromApiValue(document.categorySecondary),
      frozen: document.alreadyImported || (!document?.atLeastOneFile && DocumentSourceEnum.convertFromApiValue.getValue(document?.documentSource) !== DocumentSourceEnum.Modules),
      provenance: document.provenance && DocumentProvenanceEnum.convertFromApiValue.getValue(document.provenance),
      nature: document.natureFile && DocumentNatureEnum.convertFromApiValue.getValue(document.natureFile),
      lastVdf: document?.lastVdf && DocumentVersionLightData.mapFromApiValue(document?.lastVdf),
      selectedDocVersions: document.selectedVdfs,
      complementRefInt: document.complementRefInt,
      lastVersionDocumentFlowUuidEntity: document.lastVersionDocumentFlowUuide,
      largeFile: document.largeFile,
      customWording: document.customWording && CustomWordingsData.mapFromApiValue(document.customWording),
      atLeastOneFile: document?.atLeastOneFile,
      source: DocumentSourceEnum.convertFromApiValue.getValue(document?.documentSource),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): DocumentFlowFindLazyByProjectRequestDTO {
    return {
      flowUuidEntity: [queries.flowUuidEntity],
      projectUuidEntity: queries.projectUuidEntity,
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      familyUuidEntity: queries.families || undefined,
      categoryPrincipalUuidEntity: queries.categories || undefined,
      categorySecondaryUuidEntity: queries.categories2 || undefined,
      filterEtat: queries?.statuses?.length ? queries.statuses.map(status => DocumentFilterStateEnum.convertToApiValue.getValue(status)) : undefined,
      blocUuidEntity: queries.blockUuidEntity,
      customWordingUuidEntities: compact(queries?.customWordingUuidEntities),
    };
  }

  export function initializeQueryRequest(
    filterUuides: {
      blockUuidEntity: string;
      projectUuidEntity: string;
      flowUuidEntity: string;
      customWording: CustomWordingStateData[];
    },
    families: string[],
    categories: string[],
    categories2: string[],
    searchText: string,
    sort: SortFilter<BlockDocumentToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<BlockDocumentToImportSummaryData>(sort),
      projectUuidEntity: filterUuides.projectUuidEntity,
      flowUuidEntity: filterUuides.flowUuidEntity,
      textSearch: searchText || undefined,
      families: families?.length ? families : undefined,
      categories: categories?.length ? categories : undefined,
      categories2: categories2?.length ? categories2 : undefined,
      statuses: filterUuides?.customWording?.length ? filterUuides.customWording.map(cw => cw.actionKey) : undefined,
      customWordingUuidEntities: filterUuides?.customWording?.length ? compact(filterUuides.customWording.map(cw => cw.customWordingUuidEntity)) : undefined,
      blockUuidEntity: filterUuides.blockUuidEntity,
    };
  }
}
