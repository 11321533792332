import {
  CreateMultipleDocumentsFromFlowRequest,
  DocumentFlowCreateFromFormRequestDTO,
  DocumentFlowDetailsResponseDTO,
  DocumentFlowFindRequestDTO,
} from '../../generated/models/models';

import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { AppConfigLightData } from '../app-config/app-config-light-data';
import { FormResponseSummaryData } from '../form-response/form-response-summary';
import { ProjectDetailsData } from '../projects/project-details';
import { BlockFormResponseSummaryData } from '../block-form/block-form-response-summary';

import { LanguageEnum, DocumentNatureIndexEnum, BusinessClassNameEnum, DocumentNatureEnum, DocumentSourceEnum, DocumentProvenanceEnum, ResponseStrategyEnum } from '../../enums';

// Review: @Bechir  : should remove it later => replace it by DocumentDetailsData
export interface DocumentFlowDetailsData {
  uuidEntity: string;
  name: string;
  refInt: string;
  refExt: string;
  nature: DocumentNatureEnum;
  labelFamilyApplication: EntityLightData;
  labelFamilyProject: EntityLightData;
  labelFamilyProjectSecondary: EntityLightData;
  formUuidEntity: string;
  responseUuidEntity: string;
  provenance: DocumentProvenanceEnum;
  source: DocumentSourceEnum;
  exportLanguageOne: LanguageEnum;
  exportLanguageTwo: LanguageEnum;
  project?: EntityLightData;
  includeAnnexeInterlayer?: boolean;
  includeEmmDoc?: boolean;
  includeEmptyOption?: boolean;
  includeFormDoc?: boolean;
  includeFormReferences?: boolean;
  includeRefForm?: boolean;
  includeRhContent?: boolean;
  includeSkeletonForm?: boolean;
  includeResponseDoc?: boolean;
  showAnnexes?: boolean;
  hideIndexColumn?: boolean;
  showSkillsSummary?: boolean;
  extern?: boolean;
  responsesStrategy: ResponseStrategyEnum;
  natureIndice: DocumentNatureIndexEnum;
  versionDocumentFlowUuidEntity?: string;
  flowUuidEntity: string;
}

export namespace DocumentFlowDetailsData {
  export const defaultReferenceIntern = '0000';

  export function mapToCreateRequestFromFormApiValue(documentFlow: DocumentFlowDetailsData): DocumentFlowCreateFromFormRequestDTO {
    return {
      categorySecondaryUuidEntity: documentFlow.labelFamilyProjectSecondary?.uuidEntity,
      categoryUuidEntity: documentFlow.labelFamilyProject?.uuidEntity,
      formUuidEntity: documentFlow.formUuidEntity,
      flowUuidEntity: documentFlow.flowUuidEntity,
      labelFamilyUuidEntity: documentFlow.labelFamilyApplication?.uuidEntity,
      name: documentFlow.name,
      natureFile: DocumentNatureEnum.convertToApiValue.getValue(documentFlow.nature),
      natureIndice: DocumentNatureIndexEnum.convertToApiValue.getValue(documentFlow.natureIndice),
      projectUuidEntity: documentFlow.project?.uuidEntity,
      refInt: documentFlow.refInt || undefined,
      refExt: documentFlow.refExt || undefined,
      responseUuidEntity: documentFlow.responseUuidEntity,
    };
  }

  export function mapToCreateMultipleRequestApiValue(documentFlows: DocumentFlowDetailsData[]): CreateMultipleDocumentsFromFlowRequest {
    return {
      documentList: documentFlows.map(documentFlow => ({
        flowUuidEntity: documentFlow?.flowUuidEntity,
        labelFamilyUuidEntity: documentFlow?.labelFamilyApplication?.uuidEntity,
        natureFile: DocumentNatureEnum.convertToApiValue.getValue(documentFlow.nature),
        natureIndice: DocumentNatureIndexEnum.convertToApiValue.getValue(documentFlow.natureIndice),
      })),
    };
  }

  export function mapToRequestApiValue(projectUuidEntity: string, nature: DocumentNatureEnum, responseUuidEntity?: string): DocumentFlowFindRequestDTO {
    return {
      projectUuidEntity,
      nature: DocumentNatureEnum.convertToApiValue.getValue(nature),
      responseUuidEntity,
    };
  }

  export function mapFromApiValue(documentFlow: DocumentFlowDetailsResponseDTO): DocumentFlowDetailsData {
    return {
      uuidEntity: documentFlow.uuidEntity,
      name: documentFlow.name,
      refInt: documentFlow.refInt,
      refExt: documentFlow.refExt,
      nature: DocumentNatureEnum.convertFromApiValue.getValue(documentFlow.natureFile),
      provenance: DocumentProvenanceEnum.convertFromApiValue.getValue(documentFlow.documentProvenance),
      labelFamilyApplication: documentFlow.labelFamilyApplication && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyApplication),
      labelFamilyProject: documentFlow.labelFamilyProject && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyProject),
      labelFamilyProjectSecondary: documentFlow.labelFamilyProjectSecondary && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyProjectSecondary),
      formUuidEntity: documentFlow.linkedForm?.uuidEntity,
      responseUuidEntity: documentFlow.linkedResponse?.uuidEntity,
      exportLanguageOne: LanguageEnum.convertFromApiValue.getValue(documentFlow.exportLanguageOne),
      exportLanguageTwo: LanguageEnum.convertFromApiValue.getValue(documentFlow.exportLanguageTwo),
      natureIndice: DocumentNatureIndexEnum.convertFromApiValue.getValue(documentFlow.natureIndice),
      responsesStrategy: ResponseStrategyEnum.convertFromApiValue.getValue(documentFlow.responsesStrategy),
      source: DocumentSourceEnum.convertFromApiValue.getValue(documentFlow.documentSource),
      versionDocumentFlowUuidEntity: documentFlow.lastVdf?.uuidEntity,
      flowUuidEntity: documentFlow.linkedFlow?.uuidEntity,
    };
  }

  export function mapFromLightApiValue(documentFlow: DocumentFlowDetailsResponseDTO): DocumentFlowDetailsData {
    return {
      uuidEntity: documentFlow.uuidEntity,
      name: documentFlow.name,
      refInt: documentFlow.refInt,
      refExt: documentFlow.refExt,
      nature: undefined,
      provenance: undefined,
      labelFamilyApplication: documentFlow.labelFamilyApplication && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyApplication),
      labelFamilyProject: documentFlow.labelFamilyProject && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyProject),
      labelFamilyProjectSecondary: documentFlow.labelFamilyProjectSecondary && LabelFamilyLightData.mapToEntityLightData(documentFlow.labelFamilyProjectSecondary),
      responseUuidEntity: undefined,
      formUuidEntity: undefined,
      flowUuidEntity: undefined,
      exportLanguageOne: undefined,
      exportLanguageTwo: undefined,
      natureIndice: undefined,
      responsesStrategy: undefined,
      source: undefined,
      // versionDocumentFlowUuidEntity: documentFlow?.lastVdf?.uuidEntity, // BackendIssue: @Sajir: the response still return the versionDocumentFlowUuidEntity
      versionDocumentFlowUuidEntity: documentFlow?.['versionDocumentFlowUuidEntity'], // MakeModels: @Sajir: the response still return the versionDocumentFlowUuidEntity
    };
  }

  export function mapToDocumentFlowDetailsData(
    responseSummary: Partial<FormResponseSummaryData & BlockFormResponseSummaryData>,
    appConf: AppConfigLightData,
    projectDetails: ProjectDetailsData,
    flowUuidEntity?: string,
  ): DocumentFlowDetailsData {
    return {
      name: responseSummary.reference,
      exportLanguageOne: appConf && appConf.firstLanguage,
      exportLanguageTwo: appConf && appConf.secondLanguage,
      responseUuidEntity: responseSummary.uuidEntity,
      formUuidEntity: responseSummary.form && responseSummary.form.uuidEntity,
      flowUuidEntity: flowUuidEntity || (responseSummary.flow && responseSummary.flow.uuidEntity),
      project: ProjectDetailsData.mapToEntityLightData(projectDetails),
      responsesStrategy: ResponseStrategyEnum.Selected,
      source: flowUuidEntity === undefined && responseSummary.businessClass === BusinessClassNameEnum.Project ? DocumentSourceEnum.Forms : DocumentSourceEnum.Flows,
      provenance: DocumentProvenanceEnum.Local,
      nature: flowUuidEntity === undefined && responseSummary.businessClass === BusinessClassNameEnum.Project ? DocumentNatureEnum.ProjectForm : DocumentNatureEnum.BlockForm,
      natureIndice: appConf && appConf.natureIndice,
      uuidEntity: undefined,
      refInt: undefined,
      refExt: undefined,
      labelFamilyApplication: null,
      labelFamilyProject: null,
      labelFamilyProjectSecondary: null,
    };
  }
}
