import { LevelAccessApiEnum, StatusAccessApiEnum } from '../../enums';
import {
  ExternalApiConsumerAccessResponseDTO,
  ExternalApiConsumerSummaryResponseDTO,
  GenerateExternalApiConsumerAccessRequestDTO,
  UpdateExternalApiConsumerAccessRequestDTO,
} from '../../generated/models/models';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { EntityLightData } from '../utils/entity-light-data';

export interface AccessApiV2DetailsData {
  uuidEntity: string;
  name: string;
  ssoIdentifier: string;
  applicability: EntityLightData;
  level: LevelAccessApiEnum;
  status?: StatusAccessApiEnum;
  clientId?: string;
  clientSecret?: string;
}

export namespace AccessApiV2DetailsData {
  export function mapFromApiValue(accessApi: ExternalApiConsumerSummaryResponseDTO): AccessApiV2DetailsData {
    return {
      uuidEntity: accessApi.uuidEntity,
      name: accessApi.name,
      ssoIdentifier: accessApi.ssoIdentifier,
      clientId: accessApi.login,
      status: StatusAccessApiEnum.mapFromApiValue.getValue(accessApi.status),
      level: accessApi.serviceLevelClass && LevelAccessApiEnum.mapFromApiValue.getValue(accessApi.serviceLevelClass),
      applicability: accessApi.applicability && LabelApplicabilityLightData.mapToEntityLightData(accessApi.applicability),
    };
  }

  export function mapToCreateRequestApiValue(accessApi: AccessApiV2DetailsData): GenerateExternalApiConsumerAccessRequestDTO {
    return {
      name: accessApi.name,
      ssoIdentifier: accessApi.ssoIdentifier,
      applicability: accessApi.applicability?.uuidEntity,
      serviceLevelClass: LevelAccessApiEnum.mapToApiValue.getValue(accessApi.level),
    };
  }

  export function mapToUpdateRequestApiValue(accessApi: AccessApiV2DetailsData): UpdateExternalApiConsumerAccessRequestDTO {
    return {
      uuidEntity: accessApi.uuidEntity,
      name: accessApi.name,
      ssoIdentifier: accessApi.ssoIdentifier,
      applicability: accessApi.applicability?.uuidEntity,
      status: StatusAccessApiEnum.mapToApiValue.getValue(accessApi.status),
      serviceLevelClass: LevelAccessApiEnum.mapToApiValue.getValue(accessApi.level),
    };
  }

  export function mapFromCreateRequestApiValue(accessApi: ExternalApiConsumerAccessResponseDTO): Partial<AccessApiV2DetailsData> {
    return {
      clientSecret: accessApi.clientSecret,
      clientId: accessApi.clientId,
    };
  }
}
