import { StringUtilsEnum } from '../../enums';
import { DeleteFormLink } from '../../generated/models/models';

export interface DeleteStepLink {
  flow: string;
  phase: string;
  phaseOrder: number;
  customPhaseOrder: string;
  step: string;
  stepOrder: number;
  customStepOrder: string;
}

export namespace DeleteStepLink {
  export function mapFromApiValue(deleteFormLink: DeleteFormLink): DeleteStepLink {
    return {
      flow: deleteFormLink.flowName,
      phase: deleteFormLink.phaseName,
      phaseOrder: deleteFormLink.phaseOrder,
      customPhaseOrder: deleteFormLink.customPhaseOrder,
      step: deleteFormLink.stepName,
      stepOrder: deleteFormLink.stepOrder,
      customStepOrder: deleteFormLink.customStepOrder,
    };
  }

  export function toString(deleteStepLink: DeleteStepLink): string {
    return deleteStepLink.flow
      .concat(StringUtilsEnum.DashSeparator)
      .concat(deleteStepLink.phase)
      .concat(StringUtilsEnum.SpaceSeparator)
      .concat(deleteStepLink.phaseOrder.toString())
      .concat(StringUtilsEnum.DashSeparator)
      .concat(deleteStepLink.step)
      .concat(StringUtilsEnum.SpaceSeparator)
      .concat(deleteStepLink.stepOrder.toString());
  }
}
