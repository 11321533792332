import { ResponseSummaryResponseDTO } from '../../generated/models/responseSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum ResponseStateEnum {
  InProgress = 1,
  Done = 2,
  All = 3,
}

export namespace ResponseStateEnum {
  const values: ResponseStateEnum[] = [ResponseStateEnum.InProgress, ResponseStateEnum.Done];
  const allValues: ResponseStateEnum[] = [ResponseStateEnum.All, ResponseStateEnum.InProgress, ResponseStateEnum.Done];

  export const convertFromApiValue: SfxMap<ResponseSummaryResponseDTO.StateEnum, ResponseStateEnum> = new SfxMap<ResponseSummaryResponseDTO.StateEnum, ResponseStateEnum>([
    [ResponseSummaryResponseDTO.StateEnum.RESPONSE, ResponseStateEnum.InProgress],
    [ResponseSummaryResponseDTO.StateEnum.RESPONSEDONE, ResponseStateEnum.Done],
  ]);

  export const convertToApiValue: SfxMap<ResponseStateEnum, ResponseSummaryResponseDTO.StateEnum> = new SfxMap<ResponseStateEnum, ResponseSummaryResponseDTO.StateEnum>([
    [ResponseStateEnum.InProgress, ResponseSummaryResponseDTO.StateEnum.RESPONSE],
    [ResponseStateEnum.Done, ResponseSummaryResponseDTO.StateEnum.RESPONSEDONE],
  ]);

  export const convertFromParamsValue: SfxMap<string, ResponseStateEnum> = new SfxMap<string, ResponseStateEnum>([
    ['in-progress', ResponseStateEnum.InProgress],
    ['done', ResponseStateEnum.Done],
  ]);

  export const convertToParamsValue: SfxMap<ResponseStateEnum, string> = new SfxMap<ResponseStateEnum, string>([
    [ResponseStateEnum.InProgress, 'in-progress'],
    [ResponseStateEnum.Done, 'done'],
  ]);

  const key = 'forms.response.state.';

  export const toString: SfxMap<ResponseStateEnum, string> = new SfxMap<ResponseStateEnum, string>(
    [
      [ResponseStateEnum.InProgress, key + 'inProgress'],
      [ResponseStateEnum.Done, key + 'done'],
      [ResponseStateEnum.All, key + 'all'],
    ],
    'global.filters.state',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<ResponseStateEnum>[]> =>
    of(
      values.map(state => ({
        value: state,
        title: toString.getValue(state),
      })),
    );

  export const allItemsResolver = (): Observable<SingleSelectItemData<ResponseStateEnum>[]> =>
    of(
      allValues.map(state => ({
        value: state,
        title: toString.getValue(state),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<ResponseStateEnum>, item: ResponseStateEnum) => node.value === item;
}
