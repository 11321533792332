import { PageParadeRequestDTO, ParadeSummaryResponseDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum, PictogramEnum } from '../../enums';
import { ApplicationFileLightData } from '../application-file/application-file';
import { SortFilter } from '../utils/sort-filter';

export interface BlockRiskParadeSummaryData {
  uuidEntity: string;
  name: string;
  applicationFile: ApplicationFileLightData;
  description: string;
  pictogram: PictogramEnum;
}

export namespace BlockRiskParadeSummaryData {
  const sortFields = ['name'];
  export interface QueryRequest extends BaseQuery<BlockRiskParadeSummaryData> {
    riskUuidEntity?: string;
    projectUuidEntity?: string;
    generic?: boolean;
  }

  export function mapFromApiValue(parade: ParadeSummaryResponseDTO): BlockRiskParadeSummaryData {
    return {
      uuidEntity: parade.uuidEntity,
      name: parade.name,
      applicationFile: parade.applicationFile && ApplicationFileLightData.mapFromApiValue(parade.applicationFile),
      description: parade.description,
      pictogram: PictogramEnum.convertFromApiValue.getValue(parade.pictogram),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageParadeRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: undefined,
      elementAdrUuidEntity: queries.riskUuidEntity,
      projectUuidEntity: queries.projectUuidEntity,
      generic: queries.generic,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, riskUuidEntity: string, sort: SortFilter<BlockRiskParadeSummaryData>): QueryRequest {
    return {
      ...initializeQueries<BlockRiskParadeSummaryData>(sort),
      riskUuidEntity,
      projectUuidEntity,
    };
  }
}
