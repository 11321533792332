import { CategorySummaryData } from '../../models/category/category-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FamiliesColumnEnum {
  Name = 'name',
  Actions = 'action',
}

export namespace FamiliesColumnEnum {
  export type familiesSortField = keyof CategorySummaryData;

  export const allColumns = [FamiliesColumnEnum.Name, FamiliesColumnEnum.Actions];

  const key = 'families.table.header.';
  export const toString: SfxMap<FamiliesColumnEnum, string> = new SfxMap<FamiliesColumnEnum, string>([
    [FamiliesColumnEnum.Name, key + 'name'],
    [FamiliesColumnEnum.Actions, key + 'action'],
  ]);

  export const sortField: SfxMap<FamiliesColumnEnum, familiesSortField> = new SfxMap<FamiliesColumnEnum, familiesSortField>([
    [FamiliesColumnEnum.Name, 'name' as familiesSortField],
  ]);
}
