import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './block-content-dialog.actions';

import { LazyLoadResponse, BlockPropertiesData, BlockStatusHistoryData, BlockDocumentToImportSummaryData } from '../../../../../../../core/models';

const adapter: EntityAdapter<BlockDocumentToImportSummaryData> = createEntityAdapter<BlockDocumentToImportSummaryData>({
  selectId: document => document.uuidEntity,
});

export interface BlockContentDialogState extends EntityState<BlockDocumentToImportSummaryData> {
  blockProperties: BlockPropertiesData;
  statusHistory: LazyLoadResponse<BlockStatusHistoryData[]>;
  loading: boolean;
  importLoading: boolean;
  reset: boolean;
  totalCount: number;
  filteredTotalCount: number;
  updateImageLoading: boolean;
}

export const initialBlockContentDialogState: BlockContentDialogState = adapter.getInitialState<BlockContentDialogState>({
  ids: [],
  entities: {},
  loading: false,
  importLoading: false,
  reset: false,
  totalCount: null,
  filteredTotalCount: null,
  blockProperties: null,
  statusHistory: {} as LazyLoadResponse<BlockStatusHistoryData[]>,
  updateImageLoading: false,
});

const reducer = createReducer<BlockContentDialogState>(
  initialBlockContentDialogState,
  on(fromActions.updateImage, (state): BlockContentDialogState => ({ ...state, updateImageLoading: true })),
  on(fromActions.updateImageFail, fromActions.updateImageSuccess, (state): BlockContentDialogState => ({ ...state, updateImageLoading: false })),
  on(fromActions.getBlockProperties, (state): BlockContentDialogState => ({ ...state, loading: true })),
  on(fromActions.getBlockPropertiesSuccess, (state, { blockProperties }): BlockContentDialogState => ({ ...state, loading: false, blockProperties })),
  on(fromActions.getBlockPropertiesFail, (state): BlockContentDialogState => ({ ...state, loading: false })),
  on(fromActions.loadDocuments, (state): BlockContentDialogState => ({ ...state, loading: true })),
  on(fromActions.linkDocuments, (state): BlockContentDialogState => ({ ...state, importLoading: true })),
  on(fromActions.linkDocumentsFail, fromActions.linkDocumentsSuccess, (state): BlockContentDialogState => ({ ...state, importLoading: false })),
  on(
    fromActions.loadDocumentsSuccess,
    (state, { documents, totalCount, filteredTotalCount, reset }): BlockContentDialogState => {
      const newState: BlockContentDialogState = { ...state, loading: false, reset, totalCount, filteredTotalCount };

      return reset ? adapter.setAll(documents, newState) : adapter.addMany(documents, newState);
    },
  ),
  on(
    fromActions.loadStatusHistorySuccess,
    (state, { statusEvents, reset }): BlockContentDialogState => ({
      ...state,
      statusHistory: reset ? statusEvents : { ...state.statusHistory, payload: [...state.statusHistory.payload, ...statusEvents.payload] },
      reset,
    }),
  ),
  on(
    fromActions.refreshDocumentToImportData,
    (state, { documentData, documentIndex }): BlockContentDialogState =>
      adapter.updateOne({ id: documentData.uuidEntity, changes: { lastVdf: { ...documentData.lastVdf, index: documentIndex } } }, state),
  ),
);

export function blockContentDialogReducer(state: BlockContentDialogState | undefined, action: Action): BlockContentDialogState {
  return reducer(state, action);
}

export const selectDocuments = adapter.getSelectors().selectAll;
