import { KpiRequestDTO } from '../../generated/models/models';
import { DateFilterData } from '../utils/date-filter-data';

export interface ProjectKpisQueryParams {
  families: string[];
  clients: string[];
  applicabilities: string[];
  dateFilter?: DateFilterData;
}

export namespace ProjectKpisQueryParams {
  export function mapToRequestApiValue(queries: ProjectKpisQueryParams): KpiRequestDTO {
    const filterDate = queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter);

    return {
      familiesUuidEntity: queries.families || undefined,
      clientsUuidEntity: queries.clients || undefined,
      applicabilityUuidEntities: queries.applicabilities,
      startAt: filterDate?.startDate,
      endAt: filterDate?.endDate,
    };
  }

  export function initialize(applicabilities: string[], families: string[], clients: string[], dateFilter: DateFilterData): ProjectKpisQueryParams {
    return {
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      clients: (clients || []).length ? clients : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
