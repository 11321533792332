import { EntityLightData } from '../utils/entity-light-data';
import { FormReorganizeTypeEnum } from '../../enums';
import { FormReorganizeRequestDTO } from '../../generated/models/models';
import { FormLibrarySummaryData } from './form-library-summary';

export interface FormReorganizeData {
  allSelected: boolean;
  formsUuidEntity: string[];
  family: EntityLightData;
  type: FormReorganizeTypeEnum;
  companies: string[];
  queries: FormLibrarySummaryData.QueryRequest;
}

export namespace FormReorganizeData {
  export function mapToRequestApiValue(formReorganize: FormReorganizeData): FormReorganizeRequestDTO {
    return {
      formsUuidEntity: !formReorganize.allSelected ? formReorganize.formsUuidEntity || [] : undefined,
      familyUuidEntity: formReorganize.type === FormReorganizeTypeEnum.Family ? formReorganize?.family?.uuidEntity : null,
      companiesUuidEntity: formReorganize.type === FormReorganizeTypeEnum.Company ? formReorganize?.companies : null,
      pageFormRequest: formReorganize.allSelected && formReorganize.queries ? FormLibrarySummaryData.mapToPageRequestApiValue(formReorganize.queries) : undefined,
    };
  }
}
