import { ApplicationFileTmpSummaryResponseDTO } from '../../generated/models/models';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { FileTypeEnum } from '../utils/file-type.enum';

export enum ApplicationFileTmpStatusEnum {
  InProgress = 1,
  Done = 2,
  Fail = 3,
}

export namespace ApplicationFileTmpStatusEnum {
  export const convertFromApiValue = new SfxMap<ApplicationFileTmpSummaryResponseDTO.StatusEnum, ApplicationFileTmpStatusEnum>([
    [ApplicationFileTmpSummaryResponseDTO.StatusEnum.DONE, ApplicationFileTmpStatusEnum.Done],
    [ApplicationFileTmpSummaryResponseDTO.StatusEnum.INPROGRESS, ApplicationFileTmpStatusEnum.InProgress],
    [ApplicationFileTmpSummaryResponseDTO.StatusEnum.FAIL, ApplicationFileTmpStatusEnum.Fail],
  ]);

  const key = 'documentEvent.tooltip.';
  export const tooltip: SfxMap<ApplicationFileTmpStatusEnum, string> = new SfxMap<ApplicationFileTmpStatusEnum, string>([
    [ApplicationFileTmpStatusEnum.Done, key + 'Done'],
    [ApplicationFileTmpStatusEnum.InProgress, key + 'InProgress'],
    [ApplicationFileTmpStatusEnum.Fail, key + 'Fail'],
  ]);

  export const iconData: SfxMap<ApplicationFileTmpStatusEnum, IconData> = new SfxMap<ApplicationFileTmpStatusEnum, IconData>([
    [ApplicationFileTmpStatusEnum.Done, { name: 'eye' }],
    [ApplicationFileTmpStatusEnum.InProgress, { name: 'spinner' }],
    [ApplicationFileTmpStatusEnum.Fail, { name: 'exclamation-triangle' }],
  ]);

  export function getIcons(status: ApplicationFileTmpStatusEnum, documentType: FileTypeEnum): IconData {
    if (status === ApplicationFileTmpStatusEnum.Done) {
      return { name: documentType === FileTypeEnum.Pdf ? 'eye' : 'archive' };
    }

    return iconData.getValue(status);
  }
}
