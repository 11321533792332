import { LatLng } from './map';
import { GeometryDTO } from '../../generated/models/models';

import { BusinessClassNameEnum } from '../../enums/common/business-class-name.enum';
import { GeometryIconEnum } from '../../enums/geometry/geometry-icon.enum';
import { GeometryTypeEnum } from '../../enums/geometry/geometry-type.enum';

export interface GeometryData {
  projectUuide: string;
  uuidEntity: string;
  geometryType: GeometryTypeEnum;
  geometryIcon: GeometryIconEnum;
  radius: number;
  label: string;
  businessClass: BusinessClassNameEnum;
  businessClassUuide: string;
  coordinates: LatLng[];
  color: string;
  mapColor?: string;
  editing?: boolean;
  createDate?: Date;
  enabled?: boolean;
}

export namespace GeometryData {
  export function mapFromApiValue(geometry: GeometryDTO): GeometryData {
    return {
      projectUuide: geometry.projectUuide,
      uuidEntity: geometry.uuidEntity,
      geometryType: geometry.geometryType && GeometryTypeEnum.convertFromApiValue.getValue(geometry.geometryType),
      geometryIcon: geometry.geometryIcon && GeometryIconEnum.convertFromApiValue.getValue(geometry.geometryIcon),
      radius: geometry.radius,
      label: geometry.label,
      businessClass: geometry.businessClass && BusinessClassNameEnum.convertFromApiValue.getValue(geometry.businessClass),
      businessClassUuide: geometry.businessClassUuide,
      coordinates: geometry.coordinates,
      color: geometry.customStatusFlowColor,
      mapColor: geometry.color,
      createDate: geometry.createDate,
      enabled: geometry.enabled,
    };
  }
}
