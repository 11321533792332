import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum PgacFormFilterEnum {
  NoUpdatePerformed = 'NoUpdatePerformed',
  UpdatePerformed = 'UpdatePerformed',
}

export namespace PgacFormFilterEnum {
  const values = [PgacFormFilterEnum.NoUpdatePerformed, PgacFormFilterEnum.UpdatePerformed];

  export const convertToParamsValue: SfxMap<PgacFormFilterEnum, string> = new SfxMap<PgacFormFilterEnum, string>([
    [PgacFormFilterEnum.NoUpdatePerformed, 'NoUpdatePerformed'],
    [PgacFormFilterEnum.UpdatePerformed, 'UpdatePerformed'],
  ]);

  export const convertFromParamsValue: SfxMap<string, PgacFormFilterEnum> = new SfxMap<string, PgacFormFilterEnum>([
    ['NoUpdatePerformed', PgacFormFilterEnum.NoUpdatePerformed],
    ['UpdatePerformed', PgacFormFilterEnum.UpdatePerformed],
  ]);

  const key = 'flows.pgacs.modals.epsilonOut.filter.';
  export const toString: SfxMap<PgacFormFilterEnum, string> = new SfxMap<PgacFormFilterEnum, string>([
    [PgacFormFilterEnum.NoUpdatePerformed, key + 'NoUpdatePerformed'],
    [PgacFormFilterEnum.UpdatePerformed, key + 'UpdatePerformed'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<PgacFormFilterEnum>[]> =>
    of(
      values.map(value => ({
        value: value,
        title: toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<PgacFormFilterEnum>, item: PgacFormFilterEnum) => node.value === item;
}
