import dayjs, { Dayjs } from 'dayjs';

import { HeaderCellDisplayData, PeriodHeaderCellDisplayData } from '../../../../../../core/models';

import { SfxMap } from '../../../../../../core/utils/enum-utils';
import { defaultRoadMapCellWidth } from './constant';
import { PlanDisplayFormatEnum } from '../../../../../../core/enums/plan/plan-display-format.enum';

export namespace PlaningHelper {
  export const unitOffsetWith: SfxMap<PlanDisplayFormatEnum, number> = new SfxMap<PlanDisplayFormatEnum, number>(
    [
      [PlanDisplayFormatEnum.Day, defaultRoadMapCellWidth / 60],
      [PlanDisplayFormatEnum.Week, defaultRoadMapCellWidth / 24],
      [PlanDisplayFormatEnum.Month, defaultRoadMapCellWidth / 7],
      [PlanDisplayFormatEnum.Quarter, defaultRoadMapCellWidth / 30.5],
      [PlanDisplayFormatEnum.Year, defaultRoadMapCellWidth / 30.5],
    ],
    0,
  );

  export const chartBarMinWidth: SfxMap<PlanDisplayFormatEnum, number> = new SfxMap<PlanDisplayFormatEnum, number>(
    [
      [PlanDisplayFormatEnum.Day, 15 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Day)],
      [PlanDisplayFormatEnum.Week, 4 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Week)],
      [PlanDisplayFormatEnum.Month, unitOffsetWith.getValue(PlanDisplayFormatEnum.Month)],
      [PlanDisplayFormatEnum.Quarter, unitOffsetWith.getValue(PlanDisplayFormatEnum.Quarter)],
      [PlanDisplayFormatEnum.Year, unitOffsetWith.getValue(PlanDisplayFormatEnum.Year)],
    ],
    0,
  );

  export const fakePlanningWidth: SfxMap<PlanDisplayFormatEnum, number> = new SfxMap<PlanDisplayFormatEnum, number>(
    [
      [PlanDisplayFormatEnum.Day, 60 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Day)],
      [PlanDisplayFormatEnum.Week, 24 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Week)],
      [PlanDisplayFormatEnum.Month, 5 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Month)],
      [PlanDisplayFormatEnum.Quarter, 30 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Quarter)],
      [PlanDisplayFormatEnum.Year, 15 * unitOffsetWith.getValue(PlanDisplayFormatEnum.Year)],
    ],
    0,
  );

  export function getDiffUnits(distance: number, displayFormat: PlanDisplayFormatEnum): number {
    return Math.round(distance / PlaningHelper.unitOffsetWith.getValue(displayFormat));
  }

  export function getEndDateDiffUnits(distance: number, displayFormat: PlanDisplayFormatEnum): number {
    return Math.floor(distance / PlaningHelper.unitOffsetWith.getValue(displayFormat));
  }

  export function getValidDate(selectedTime: string): dayjs.Dayjs {
    return dayjs.unix(+selectedTime).isValid() ? dayjs.unix(+selectedTime) : dayjs();
  }

  export function getMonthlyHeaderDisplay(startDate: Dayjs, endDate: Dayjs): HeaderCellDisplayData[] {
    const headerCellData: HeaderCellDisplayData[] = [];

    while (endDate.isSameOrAfter(startDate)) {
      headerCellData.push({ name: startDate.format("MMM [']YY") });
      startDate = startDate.add(1, 'month');
    }

    return headerCellData;
  }

  export function getWeeklyHeaderDisplay(startDate: Dayjs, endDate: Dayjs): HeaderCellDisplayData[] {
    const headerCellData: HeaderCellDisplayData[] = [];
    while (endDate.isSameOrAfter(startDate)) {
      const weekHeaderCellData: PeriodHeaderCellDisplayData[] = [];

      for (let i = 1; i <= 7; i++) {
        weekHeaderCellData.push({
          periodName: startDate.isoWeekday(i).format('D'),
          offsetLeft: unitOffsetWith.getValue(PlanDisplayFormatEnum.Month) * (i - 0.5),
          disabled: [6, 7].includes(i) || !endDate.isSame(startDate.isoWeekday(i), 'month'),
          isToday: dayjs().isSame(startDate.isoWeekday(i), 'day'),
        });
      }

      headerCellData.push({ name: startDate.format("MMM [']YY"), periodFragments: weekHeaderCellData });

      startDate = startDate.add(1, 'week');
    }

    return headerCellData;
  }

  export function getDailyHeaderDisplay(startDate: Dayjs, endDate: Dayjs): HeaderCellDisplayData[] {
    const headerCellData: HeaderCellDisplayData[] = [];
    while (endDate.isSameOrAfter(startDate)) {
      const hourHeaderCellData: PeriodHeaderCellDisplayData[] = [];

      for (let i = 1; i < 6; i++) {
        hourHeaderCellData.push({
          periodName: startDate
            .startOf('day')
            .add(i * 4, 'hours')
            .format('HH[h]'),
          offsetLeft: unitOffsetWith.getValue(PlanDisplayFormatEnum.Week) * (i * 4 - 0.5),
        });
      }

      headerCellData.push({ name: startDate.format('dddd DD MMMM YYYY'), periodFragments: hourHeaderCellData });

      startDate = startDate.add(1, 'day');
    }

    return headerCellData;
  }

  export function getHourlyHeaderDisplay(startDate: Dayjs, endDate: Dayjs): HeaderCellDisplayData[] {
    const headerCellData: HeaderCellDisplayData[] = [];
    while (endDate.isSameOrAfter(startDate)) {
      const minuteHeaderCellData: PeriodHeaderCellDisplayData[] = [];

      for (let i = 1; i < 4; i++) {
        minuteHeaderCellData.push({
          periodName: startDate.add(i * 15, 'minutes').format('HH:mm'),
          offsetLeft: unitOffsetWith.getValue(PlanDisplayFormatEnum.Day) * (i * 15 - 0.5),
        });
      }
      headerCellData.push({ name: startDate.format('HH:mm') + ' - ' + startDate.add(1, 'hour').format('HH:mm'), periodFragments: minuteHeaderCellData });

      startDate = startDate.add(1, 'hour');
    }

    return headerCellData;
  }

  export function getElementOffsetLeft(to: Dayjs, from: Dayjs, displayFormat: PlanDisplayFormatEnum): number {
    return (to.diff(from, PlanDisplayFormatEnum.diffUnit.getValue(displayFormat)) + 0.5) * unitOffsetWith.getValue(displayFormat);
  }

  export function getElementOffsetRight(to: Dayjs, from: Dayjs, displayFormat: PlanDisplayFormatEnum): number {
    return (to.diff(from, PlanDisplayFormatEnum.diffUnit.getValue(displayFormat)) + 1) * unitOffsetWith.getValue(displayFormat);
  }

  export function getElementWidth(startDate: Dayjs, endDate: Dayjs, dateIndex: dayjs.Dayjs, displayFormat: PlanDisplayFormatEnum): number {
    const offsetLeft = getElementOffsetLeft(startDate, dateIndex, displayFormat);
    const offsetRight = getElementOffsetRight(endDate, dateIndex, displayFormat);

    return offsetRight - offsetLeft;
  }

  export function formatPlanHeader(displayFormat: PlanDisplayFormatEnum, period: dayjs.Dayjs): HeaderCellDisplayData[] {
    switch (displayFormat) {
      case PlanDisplayFormatEnum.Day:
        return getHourlyHeaderDisplay(period.startOf('day'), period.endOf('day'));

      case PlanDisplayFormatEnum.Week:
        return getDailyHeaderDisplay(period.startOf('week'), period.endOf('week'));

      case PlanDisplayFormatEnum.Month:
        return getWeeklyHeaderDisplay(period.startOf('month'), period.endOf('month'));

      case PlanDisplayFormatEnum.Quarter:
        return getMonthlyHeaderDisplay(period.startOf('quarter'), period.endOf('quarter'));

      case PlanDisplayFormatEnum.Year:
        return getMonthlyHeaderDisplay(period.startOf('year'), period.endOf('year'));

      default:
        return [];
    }
  }
}
