import { CustomFieldCountResponseDTO } from '../../generated/models/models';

export interface CustomFieldsCount {
  totalCount: number;
}

export namespace CustomFieldsCount {
  export function mapFromApiValue(countData: CustomFieldCountResponseDTO): CustomFieldsCount {
    return {
      totalCount: countData.totalCount,
    };
  }
}
