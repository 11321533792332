import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ErrorResponseData, EquipmentDetailsData, LibraryEquipmentSummaryData } from '../../../../../core/models';
import * as fromActions from './equipment-dialog.actions';

const reorganizeAdapter: EntityAdapter<LibraryEquipmentSummaryData> = createEntityAdapter<LibraryEquipmentSummaryData>({
  selectId: equipment => equipment.uuidEntity,
});

export interface EquipmentReorganizeState extends EntityState<LibraryEquipmentSummaryData> {
  ids: string[];
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export const initialEquipmentReorganizeState = (): EquipmentReorganizeState =>
  reorganizeAdapter.getInitialState<EquipmentReorganizeState>({
    ids: [],
    entities: {},
    loading: false,
    error: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export interface EditLibraryEquipmentDialogState {
  saveLoading: boolean;
  equipmentDetails: EquipmentDetailsData;
  isFileUploading: boolean;
}

const initialEditLibraryEquipmentDialogState = (): EditLibraryEquipmentDialogState => ({
  saveLoading: false,
  equipmentDetails: {} as EquipmentDetailsData,
  isFileUploading: null,
});

export interface UploadEquipmentsDialogState {
  uploadLoading: boolean;
  downloadLoading: boolean;
}

const initialUploadEquipmentsDialogState = (): UploadEquipmentsDialogState => ({
  uploadLoading: false,
  downloadLoading: false,
});

export interface LibraryEquipmentDialogState {
  uploadEquipments: UploadEquipmentsDialogState;
  editEquipment: EditLibraryEquipmentDialogState;
  equipmentReorganizeState: EquipmentReorganizeState;
}

export const initialLibraryEquipmentsDialogState = () => ({
  uploadEquipments: initialUploadEquipmentsDialogState(),
  editEquipment: initialEditLibraryEquipmentDialogState(),
  equipmentReorganizeState: initialEquipmentReorganizeState(),
});

const reducer = createReducer<LibraryEquipmentDialogState>(
  initialLibraryEquipmentsDialogState(),
  on(fromActions.setFileToUploadEquipments, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, uploadLoading: true } })),
  on(fromActions.setFileToUploadEquipmentsSuccess, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, uploadLoading: false } })),
  on(fromActions.setFileToUploadEquipmentsFail, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, uploadLoading: false } })),
  on(fromActions.stopImportFileLoading, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, uploadLoading: false } })),
  on(fromActions.downloadInitialEmmTramFile, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, downloadLoading: true } })),
  on(
    fromActions.downloadInitialEmmTramFileSuccess,
    (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, downloadLoading: false } }),
  ),
  on(fromActions.downloadInitialEmmTramFileFail, (state): LibraryEquipmentDialogState => ({ ...state, uploadEquipments: { ...state.uploadEquipments, downloadLoading: false } })),
  on(
    fromActions.loadEquipmentDetailDataSuccess,
    (state, { equipmentDetails }): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, equipmentDetails } }),
  ),
  on(
    fromActions.createEquipment,
    fromActions.updateEquipment,
    (state): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, saveLoading: true } }),
  ),
  on(
    fromActions.createEquipmentFail,
    fromActions.updateEquipmentFail,
    (state): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, saveLoading: false } }),
  ),
  on(
    fromActions.createEquipmentSuccess,
    fromActions.updateEquipmentSuccess,
    (state, { equipment, addNewOne }): LibraryEquipmentDialogState => ({
      ...state,
      editEquipment: {
        ...state.editEquipment,
        equipmentDetails: addNewOne
          ? { ...equipment, name: null, provider: null, reference: null, referenceFile: null, comment: null, applicability: null, uuidEntity: null, displayComment: null }
          : { ...equipment },
        saveLoading: false,
      },
    }),
  ),
  on(fromActions.downloadFile, (state): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, isFileUploading: true } })),
  on(fromActions.downloadFileFail, (state): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, isFileUploading: false } })),
  on(fromActions.downloadFileSuccess, (state): LibraryEquipmentDialogState => ({ ...state, editEquipment: { ...state.editEquipment, isFileUploading: false } })),
  on(
    fromActions.loadEquipmentReorganizeList,
    (state): LibraryEquipmentDialogState => ({ ...state, equipmentReorganizeState: { ...state.equipmentReorganizeState, loading: true } }),
  ),
  on(
    fromActions.loadEquipmentReorganizeListSuccess,
    (state, { equipments, totalCount, filteredTotalCount, reset }): LibraryEquipmentDialogState => {
      const newState: EquipmentReorganizeState = { ...state.equipmentReorganizeState, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, equipmentReorganizeState: reset ? reorganizeAdapter.setAll(equipments, newState) : reorganizeAdapter.addMany(equipments, newState) };
    },
  ),
  on(
    fromActions.loadEquipmentReorganizeListFail,
    (state, { error }): LibraryEquipmentDialogState => ({ ...state, equipmentReorganizeState: { ...state.equipmentReorganizeState, error, loading: false } }),
  ),
);

export function EquipmentDialogReducer(state: LibraryEquipmentDialogState | undefined, action: Action): LibraryEquipmentDialogState {
  return reducer(state, action);
}

export const selectAllEquipments = reorganizeAdapter.getSelectors().selectAll;
