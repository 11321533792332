import { lastOneUuidEntity } from '../../../modules/shared/interfaces/select-item';
import { StepDuplicateRequestDTO } from '../../generated/models/models';
import { PhaseSummaryData } from '../phase/phase-summary-data';
import { StepSummaryData } from './step-summary-data';

export interface StepToDuplicateData {
  stepDestination: StepSummaryData;
  phaseDestination: PhaseSummaryData;
}

export namespace StepToDuplicateData {
  export function mapToRequestApiValue(stepUuidEntity: string, projectUuidEntity: string, step: StepToDuplicateData): StepDuplicateRequestDTO {
    return {
      uuidePhaseDestination: step.phaseDestination.uuidEntity,
      uuideStepDestination: step.stepDestination.uuidEntity,
      uuide: stepUuidEntity,
      uuideProject: projectUuidEntity,
      lastPosition: step.stepDestination.uuidEntity === lastOneUuidEntity,
    };
  }
}
