import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum EquipmentStatusEnum {
  SubjectToVerify = 1,
  Expired = 2,
}

export namespace EquipmentStatusEnum {
  const values: EquipmentStatusEnum[] = [EquipmentStatusEnum.Expired, EquipmentStatusEnum.SubjectToVerify];

  export const convertFromApiValue: SfxMap<string, EquipmentStatusEnum> = new SfxMap<string, EquipmentStatusEnum>([
    ['submittedVerification', EquipmentStatusEnum.SubjectToVerify],
    ['isExpired', EquipmentStatusEnum.Expired],
  ]);

  export const convertToApiValue: SfxMap<EquipmentStatusEnum, string> = new SfxMap<EquipmentStatusEnum, string>([
    [EquipmentStatusEnum.Expired, 'isExpired'],
    [EquipmentStatusEnum.SubjectToVerify, 'submittedVerification'],
  ]);

  export const convertFromParamsValue: SfxMap<string, EquipmentStatusEnum> = new SfxMap<string, EquipmentStatusEnum>([
    ['subjectToVerify', EquipmentStatusEnum.SubjectToVerify],
    ['expired', EquipmentStatusEnum.Expired],
  ]);

  export const convertToParamsValue: SfxMap<EquipmentStatusEnum, string> = new SfxMap<EquipmentStatusEnum, string>([
    [EquipmentStatusEnum.SubjectToVerify, 'subjectToVerify'],
    [EquipmentStatusEnum.Expired, 'expired'],
  ]);

  const key = 'equipment.filters.';
  export const toString: SfxMap<EquipmentStatusEnum, string> = new SfxMap<EquipmentStatusEnum, string>([
    [EquipmentStatusEnum.SubjectToVerify, key + 'subjectToVerify'],
    [EquipmentStatusEnum.Expired, key + 'expired'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<EquipmentStatusEnum>[]> => of(values.map(status => ({ value: status, title: toString.getValue(status) })));

  export const comparePredicate = (node: SingleSelectItemData<EquipmentStatusEnum>, item: EquipmentStatusEnum) => node.value === item;
}
