import { BlocPropsResponseDTO } from '../../generated/models/models';
import { BlockStatusEnum, BlockTypeEnum } from '../../enums';
import { BlockActorData } from './block-actor-data';

export interface BlockPropertiesData {
  type: BlockTypeEnum;
  status: BlockStatusEnum;
  createdBy: BlockActorData;
  lastModifiedBy: BlockActorData;
}

export namespace BlockPropertiesData {
  export function mapFromApiValue(blockProperties: BlocPropsResponseDTO): BlockPropertiesData {
    return {
      type: BlockTypeEnum.convertFromApiValue.getValue(blockProperties.nature),
      status: BlockStatusEnum.convertFromApiValue.getValue(blockProperties.status),
      createdBy: blockProperties.createdBy && BlockActorData.mapFromApiValue(blockProperties.creationDate, blockProperties.createdBy),
      lastModifiedBy: blockProperties.lastModifiedBy && BlockActorData.mapFromApiValue(blockProperties.modifcationDate, blockProperties.lastModifiedBy),
    };
  }
}
