import { SfxMap } from '../../utils/enum-utils';

export enum ResponseColumnEnum {
  Provenance = 'provenance',
  Reference = 'reference',
  Status = 'status',
  Index = 'index',
  ResponseDate = 'responseDate',
  Actions = 'actions',
}

export namespace ResponseColumnEnum {
  export const allColumns = [
    ResponseColumnEnum.Provenance,
    ResponseColumnEnum.Reference,
    ResponseColumnEnum.Status,
    ResponseColumnEnum.Index,
    ResponseColumnEnum.ResponseDate,
    ResponseColumnEnum.Actions,
  ];

  const key = 'responses.table.header.';
  export const toString: SfxMap<ResponseColumnEnum, string> = new SfxMap<ResponseColumnEnum, string>([
    [ResponseColumnEnum.Provenance, key + 'provenance'],
    [ResponseColumnEnum.Reference, key + 'reference'],
    [ResponseColumnEnum.Status, key + 'status'],
    [ResponseColumnEnum.Index, key + 'index'],
    [ResponseColumnEnum.ResponseDate, key + 'responseDate'],
    [ResponseColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ResponseColumnEnum, string> = new SfxMap<ResponseColumnEnum, string>([
    [ResponseColumnEnum.Provenance, 'provenance'],
    [ResponseColumnEnum.Reference, 'reference'],
    [ResponseColumnEnum.Status, 'customStatus'],
    [ResponseColumnEnum.Index, 'index'],
    [ResponseColumnEnum.ResponseDate, 'dateResponse'],
  ]);
}
