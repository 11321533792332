import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromClientActions from './client-dialog.actions';
import { dummyAction } from '../../../../main/state/main.actions';
import { ClientDetailsData } from '../../../../../core/models';

export interface SettingsClientDialogState {
  clientDetails: ClientDetailsData;
  initialClientDetails: ClientDetailsData;
  saveLoading: boolean;
}

export const initialSettingsClientDialogState = () => ({
  clientDetails: {} as ClientDetailsData,
  initialClientDetails: {} as ClientDetailsData,
  saveLoading: false,
});

const reducer = createReducer<SettingsClientDialogState>(
  initialSettingsClientDialogState(),
  on(fromClientActions.addClient, (state): SettingsClientDialogState => ({ ...state, saveLoading: true })),
  on(fromClientActions.addClientFail, (state): SettingsClientDialogState => ({ ...state, saveLoading: false })),
  on(fromClientActions.addClientSuccess, (state, { clientDetails }): SettingsClientDialogState => ({ ...state, clientDetails, saveLoading: false })),
  on(fromClientActions.updateClient, (state): SettingsClientDialogState => ({ ...state, saveLoading: true })),
  on(fromClientActions.updateClientFail, (state): SettingsClientDialogState => ({ ...state, saveLoading: false })),
  on(fromClientActions.updateClientSuccess, (state): SettingsClientDialogState => ({ ...state, saveLoading: false })),
  on(fromClientActions.saveChangesClientDialog, (state): SettingsClientDialogState => ({ ...state, saveLoading: true })),
  on(fromClientActions.uploadPdfFrameClientFail, (state): SettingsClientDialogState => ({ ...state, saveLoading: false })),
  on(fromClientActions.getClientDetailsSuccess, (state, { clientDetails }): SettingsClientDialogState => ({ ...state, clientDetails })),
  on(
    fromClientActions.initializeClientDetails,
    (state, { clientDetails }): SettingsClientDialogState => ({
      ...state,
      initialClientDetails: cloneDeep(clientDetails),
    }),
  ),
  on(fromClientActions.uploadLogoClient, (state, { logo }): SettingsClientDialogState => ({ ...state, clientDetails: { ...state.clientDetails, logo } })),
  on(dummyAction, (state): SettingsClientDialogState => ({ ...state, saveLoading: false })),
);

export function settingsClientDialogReducer(state: SettingsClientDialogState | undefined, action: Action): SettingsClientDialogState {
  return reducer(state, action);
}
