import { ChangePasswordRequestDTO } from '../../generated/models/models';

export interface PasswordForUpdate {
  oldPassword: string;
  password: string;
}

export namespace PasswordForUpdate {
  export function mapToUpdateRequestApiValue(userPassword: PasswordForUpdate): ChangePasswordRequestDTO {
    return {
      oldPassword: userPassword.oldPassword,
      password: userPassword.password,
    };
  }
}
