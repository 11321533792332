import { createReducer, Action, on } from '@ngrx/store';
import { CustomStatusDetailsData } from '../../../../../core/models';

import * as fromActions from './custom-status-dialog.actions';

export interface CustomStatusDialogState {
  saveLoading: boolean;
  customStatus: CustomStatusDetailsData;
}

export const initialCustomStatusDialogState = () => ({
  saveLoading: false,
  customStatus: {} as CustomStatusDetailsData,
});

const reducer = createReducer<CustomStatusDialogState>(
  initialCustomStatusDialogState(),
  on(fromActions.setCustomStatus, (state, { customStatus }): CustomStatusDialogState => ({ ...state, customStatus: customStatus })),
  on(fromActions.setStatusColor, (state, { color }): CustomStatusDialogState => ({ ...state, customStatus: { ...state.customStatus, color } })),
  on(fromActions.updateCustomStatus, (state): CustomStatusDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.updateCustomStatusFail, (state): CustomStatusDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.updateCustomStatusSuccess, (state): CustomStatusDialogState => ({ ...state, saveLoading: false })),
  on(fromActions.addCustomStatus, (state): CustomStatusDialogState => ({ ...state, saveLoading: true })),
  on(fromActions.addCustomStatusFail, (state): CustomStatusDialogState => ({ ...state, saveLoading: false })),
  on(
    fromActions.setStatusAsDefault,
    (state, { defaultStatus }): CustomStatusDialogState => ({
      ...state,
      customStatus: {
        ...state.customStatus,
        defaultStatus: defaultStatus,
        frozenDefault: defaultStatus,
      },
    }),
  ),
  on(
    fromActions.addCustomStatusSuccess,
    (state, { customStatus, addNewOne }): CustomStatusDialogState => {
      const newCustomStatus = {
        linkType: customStatus.linkType,
        labelFamily: customStatus.labelFamily,
        businessClass: customStatus.businessClass,
      } as CustomStatusDetailsData;

      return { ...state, saveLoading: false, customStatus: addNewOne ? newCustomStatus : state.customStatus };
    },
  ),
);

export function customStatusDialogReducer(state: CustomStatusDialogState | undefined, action: Action): CustomStatusDialogState {
  return reducer(state, action);
}
