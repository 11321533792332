import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { ElementEmmSummaryResponseDTO, PageElementEmmRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { SortFilter } from '../utils/sort-filter';

import { EquipmentStatusEnum, InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';

export interface LibraryEquipmentSummaryData {
  uuidEntity: string;
  applicability: EntityLightData;
  labelFamily: LabelFamilyLightData;
  name: string;
  provider: string;
  reference: string;
  verification: boolean;
  isExpired: boolean;
  quantity: number;
  unit: string;
  archived: boolean;
  color?: string;
}

export namespace LibraryEquipmentSummaryData {
  const sortFields = ['family', 'name', 'provider', 'reference'];
  export interface QueryRequest extends BaseQuery<LibraryEquipmentSummaryData> {
    applicabilities?: string[];
    families?: string[];
    status?: EquipmentStatusEnum;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(data: ElementEmmSummaryResponseDTO): LibraryEquipmentSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      applicability: data.applicability && LabelApplicabilityLightData.mapToEntityLightData(data.applicability),
      labelFamily: data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily),
      name: data.name,
      provider: data.provider,
      reference: data.reference,
      verification: data.verification,
      isExpired: data.expired,
      quantity: data.quantity,
      unit: data.unit,
      archived: data.archived,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageElementEmmRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      applicabilities: queries.applicabilities,
      families: queries.families,
      customFilter: EquipmentStatusEnum.convertToApiValue.getValue(queries.status),
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(
    searchText: string,
    applicabilities: string[],
    families: string[],
    status: string,
    sort: SortFilter<LibraryEquipmentSummaryData>,
    state?: string,
  ): QueryRequest {
    return {
      ...initializeQueries<LibraryEquipmentSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      status: EquipmentStatusEnum.convertFromParamsValue.getValue(status) || undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || FilterArchivedEnum.Activated,
    };
  }

  export function toString(equipment: LibraryEquipmentSummaryData): string {
    return (equipment.reference ? equipment.reference.concat(StringUtilsEnum.DashSeparator) : '').concat(equipment.name);
  }
}
