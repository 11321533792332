/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ElementPlanLinkLightResponseDTO {
  elementPlanSourceUuidEntity: string;
  elementPlanDestinationUuidEntity: string;
  dependencyType: ElementPlanLinkLightResponseDTO.DependencyTypeEnum;
}
export namespace ElementPlanLinkLightResponseDTO {
  export type DependencyTypeEnum = 'START_END' | 'END_START';
  export const DependencyTypeEnum = {
    STARTEND: 'START_END' as DependencyTypeEnum,
    ENDSTART: 'END_START' as DependencyTypeEnum,
  };
}
