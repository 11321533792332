import { Observable, of } from 'rxjs';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SchedulingItemResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum SchedulingItemStateEnum {
  Scheduled = 1,
  Expired,
  Executed,
}

export namespace SchedulingItemStateEnum {
  export const values = [SchedulingItemStateEnum.Scheduled, SchedulingItemStateEnum.Expired, SchedulingItemStateEnum.Executed];

  export const convertFromApiValue = new SfxMap<SchedulingItemResponseDTO.StateEnum, SchedulingItemStateEnum>([
    [SchedulingItemResponseDTO.StateEnum.SCHEDULED, SchedulingItemStateEnum.Scheduled],
    [SchedulingItemResponseDTO.StateEnum.EXPIRED, SchedulingItemStateEnum.Expired],
    [SchedulingItemResponseDTO.StateEnum.EXECUTED, SchedulingItemStateEnum.Executed],
  ]);

  export const convertToApiValue = new SfxMap<SchedulingItemStateEnum, SchedulingItemResponseDTO.StateEnum>([
    [SchedulingItemStateEnum.Scheduled, SchedulingItemResponseDTO.StateEnum.SCHEDULED],
    [SchedulingItemStateEnum.Expired, SchedulingItemResponseDTO.StateEnum.EXPIRED],
    [SchedulingItemStateEnum.Executed, SchedulingItemResponseDTO.StateEnum.EXECUTED],
  ]);

  export const convertToParamsValue = new SfxMap<SchedulingItemStateEnum, string>([
    [SchedulingItemStateEnum.Expired, 'expired'],
    [SchedulingItemStateEnum.Scheduled, 'scheduled'],
    [SchedulingItemStateEnum.Executed, 'executed'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, SchedulingItemStateEnum>([
    ['expired', SchedulingItemStateEnum.Expired],
    ['scheduled', SchedulingItemStateEnum.Scheduled],
    ['executed', SchedulingItemStateEnum.Executed],
  ]);

  const key = 'schedule.addModal.state.';
  export const toString = new SfxMap<SchedulingItemStateEnum, string>([
    [SchedulingItemStateEnum.Expired, key + 'expired'],
    [SchedulingItemStateEnum.Scheduled, key + 'scheduled'],
    [SchedulingItemStateEnum.Executed, key + 'executed'],
  ]);

  export const color = new SfxMap<SchedulingItemStateEnum, string>([
    [SchedulingItemStateEnum.Expired, '#dc3545'],
    [SchedulingItemStateEnum.Scheduled, '#ffad4a'],
    [SchedulingItemStateEnum.Executed, '#6cc183'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<SchedulingItemStateEnum>[]> => of(values.map(state => ({ value: state, title: toString.getValue(state) })));

  export const comparePredicate = (node: SingleSelectItemData<SchedulingItemStateEnum>, item: SchedulingItemStateEnum) => node.value === item;

  export const selectedItemValues: MultiSelectItemData<SchedulingItemStateEnum>[] = values.map(nature => ({ value: nature, title: toString.getValue(nature) }));
}
