import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { cloneDeep } from 'lodash';

import { dummyAction } from '../../../../main/state/main.actions';

import {
  FlowDetailsData,
  CustomFieldData,
  FlowToImportSummaryData,
  ErrorResponseData,
  ApplicationEventLightData,
  LazyLoadResponse,
  FlowSummaryData,
} from '../../../../../core/models';
import * as fromActions from './flow-referential-dialog.actions';

const importAdapter: EntityAdapter<FlowToImportSummaryData> = createEntityAdapter<FlowToImportSummaryData>({
  selectId: flowRef => flowRef.uuidEntity,
});
const executeAdapter: EntityAdapter<FlowSummaryData> = createEntityAdapter<FlowSummaryData>({
  selectId: flowRef => flowRef.uuidEntity,
});

export interface ImportReferentialFlowDialogState extends EntityState<FlowToImportSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
  selectedReferentialFlows: string[];
}

export interface ExecuteFlowDialogState extends EntityState<FlowSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
  selectedReferentialFlows: string[];
}

export const importReferentialFlowInitialState: ImportReferentialFlowDialogState = importAdapter.getInitialState<ImportReferentialFlowDialogState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
  selectedReferentialFlows: [],
});

export const executeFlowDialogInitialState: ExecuteFlowDialogState = importAdapter.getInitialState<ExecuteFlowDialogState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  totalCount: undefined,
  filteredTotalCount: undefined,
  reset: false,
  selectedReferentialFlows: [],
});

export interface ReferentialFlowDialogState {
  flowDetails: FlowDetailsData;
  customFields: CustomFieldData[];
  initialFlowDetails: FlowDetailsData;
  saveLoading: boolean;
}

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

export interface DashboardReferentialFlowDialogState {
  referentialFlowDialogState: ReferentialFlowDialogState;
  importReferential: ImportReferentialFlowDialogState;
  executeFlows: ExecuteFlowDialogState;
  historyState: HistoryDialogState;
}

const initialReferentialFlowDialogState: ReferentialFlowDialogState = {
  flowDetails: null,
  customFields: null,
  initialFlowDetails: null,
  saveLoading: false,
};

export const initialDashboardReferentialFlowDialogState = () => ({
  referentialFlowDialogState: initialReferentialFlowDialogState,
  importReferential: importReferentialFlowInitialState,
  executeFlows: executeFlowDialogInitialState,
  historyState: initialHistoryDialogState(),
});

const reducer = createReducer<DashboardReferentialFlowDialogState>(
  initialDashboardReferentialFlowDialogState(),
  on(
    fromActions.updateReferentialFlow,
    (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: true } }),
  ),
  on(
    fromActions.updateReferentialFlowFail,
    (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: false } }),
  ),
  on(
    fromActions.updateReferentialFlowSuccess,
    (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: false } }),
  ),
  on(fromActions.executeFlow, (state): DashboardReferentialFlowDialogState => ({ ...state, executeFlows: { ...state.executeFlows, loading: true } })),
  on(
    fromActions.executeFlowSuccess,
    fromActions.executeFlowFail,
    (state): DashboardReferentialFlowDialogState => ({ ...state, executeFlows: { ...state.executeFlows, loading: false } }),
  ),
  on(fromActions.executeFlowList, (state): DashboardReferentialFlowDialogState => ({ ...state, executeFlows: { ...state.executeFlows, loading: true } })),
  on(
    fromActions.getReferentialFlowDetailsSuccess,
    (state, { flowDetails }): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, flowDetails } }),
  ),
  on(
    fromActions.loadReferentialFlowCustomFields,
    (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: true } }),
  ),
  on(
    fromActions.loadReferentialFlowCustomFieldsFail,
    (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: false } }),
  ),
  on(
    fromActions.loadReferentialFlowCustomFieldsSuccess,
    (state, { customFields }): DashboardReferentialFlowDialogState => ({
      ...state,
      referentialFlowDialogState: { ...state.referentialFlowDialogState, customFields, saveLoading: false },
    }),
  ),
  on(
    fromActions.initializeReferentialFlowDetails,
    (state, { flowDetails }): DashboardReferentialFlowDialogState => ({
      ...state,
      referentialFlowDialogState: { ...state.referentialFlowDialogState, initialFlowDetails: cloneDeep(flowDetails) },
    }),
  ),
  on(dummyAction, (state): DashboardReferentialFlowDialogState => ({ ...state, referentialFlowDialogState: { ...state.referentialFlowDialogState, saveLoading: false } })),
  on(
    fromActions.loadImportReferentialListSuccess,
    (state, { flows, totalCount, filteredTotalCount, reset }): DashboardReferentialFlowDialogState => {
      const newImportState: ImportReferentialFlowDialogState = { ...state.importReferential, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? importAdapter.setAll(flows, newImportState) : importAdapter.addMany(flows, newImportState);

      return { ...state, importReferential: newState };
    },
  ),
  on(
    fromActions.loadExecuteListSuccess,
    (state, { executeFlowData, totalCount, filteredTotalCount, reset }): DashboardReferentialFlowDialogState => {
      const newImportState: ExecuteFlowDialogState = { ...state.executeFlows, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? executeAdapter.setAll(executeFlowData, newImportState) : executeAdapter.addMany(executeFlowData, newImportState);

      return { ...state, executeFlows: newState };
    },
  ),
  on(
    fromActions.setSelectedReferentialFlows,
    (state, { selectedReferentialFlowsUuidEntity }): DashboardReferentialFlowDialogState => ({
      ...state,
      importReferential: { ...state.importReferential, selectedReferentialFlows: selectedReferentialFlowsUuidEntity },
    }),
  ),
  on(
    fromActions.loadFlowRefHistorySuccess,
    (state, { data, reset }): DashboardReferentialFlowDialogState => ({
      ...state,
      historyState: {
        ...state.historyState,
        reset,
        historyList: reset ? data : { ...state.historyState.historyList, payload: [...state.historyState.historyList.payload, ...data.payload] },
      },
    }),
  ),
);

export function dashboardReferentialFlowDialogReducer(state: DashboardReferentialFlowDialogState | undefined, action: Action): DashboardReferentialFlowDialogState {
  return reducer(state, action);
}

export const selectAllFlowRef = importAdapter.getSelectors().selectAll;
export const selectAllExecute = executeAdapter.getSelectors().selectAll;
