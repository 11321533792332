import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum RiskReorganizeTypeEnum {
  Family = 'family',
}

export namespace RiskReorganizeTypeEnum {
  export const reorganizeTypes = [RiskReorganizeTypeEnum.Family];

  export const toString: SfxMap<RiskReorganizeTypeEnum, string> = new SfxMap<RiskReorganizeTypeEnum, string>([
    [RiskReorganizeTypeEnum.Family, 'riskLibrary.dialog.reorganize.types.family'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<RiskReorganizeTypeEnum>[]> =>
    of(
      reorganizeTypes.map(value => ({
        value,
        title: RiskReorganizeTypeEnum.toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<RiskReorganizeTypeEnum>, item: RiskReorganizeTypeEnum) => node.value === item;
}
