import { isNumber } from 'lodash';

import { StepCreateRequestDTO, StepDetailsResponseDTO, StepUpdateRequestDTO } from '../../generated/models/models';

import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { StepDetailsViewData } from './step-view-details-data';
import { TagLightData } from '../library-tag/tag-light-data';

import { FlowTypeEnum, BlockTypeEnum, FlowMenuItemTypeEnum } from '../../enums';
import { BlockLightData } from '../block/block-light-data';
import { PhaseSummaryData } from '../phase/phase-summary-data';

export interface StepDetailsData {
  name: string;
  customNodeOrder: string;
  nodeOrderToDisplay?: number;
  nodeOrder: number;
  description: string;
  uuidEntity: string;
  hideTitlePdf: boolean;
  adr: boolean;
  emm: boolean;
  rhp: boolean;
  dsi: boolean;
  form: boolean;
  txt: boolean;
  pr: boolean;
  blocObject: boolean;
  flowAlreadyLinked: boolean;
  multiPr: boolean;
  navigationFlowCount: number;
  tags?: TagLightData[];
  blocValidityDetails: Partial<BlockLightData>[];
  parentPhase: PhaseSummaryData;
  additionalComment?: string;
  excludeNumbering: boolean;
  mobileAppReadOnly: boolean;
  disabled?: boolean;
}

export namespace StepDetailsData {
  export function mapFromApiValue(stepDetails: StepDetailsResponseDTO): StepDetailsData {
    return {
      name: stepDetails.name,
      customNodeOrder: stepDetails.customNodeOrder,
      nodeOrder: stepDetails.nodeOrder,
      description: stepDetails.description,
      uuidEntity: stepDetails.uuidEntity,
      hideTitlePdf: stepDetails.hideTitlePdf,
      adr: stepDetails.adr,
      emm: stepDetails.emm,
      rhp: stepDetails.rhp,
      dsi: stepDetails.dsi,
      form: stepDetails.form,
      txt: stepDetails.txt,
      pr: stepDetails.pr,
      blocObject: stepDetails.blocObject,
      flowAlreadyLinked: !!stepDetails.flow,
      multiPr: stepDetails.multiPr,
      navigationFlowCount: stepDetails.navigationFlowCount,
      blocValidityDetails: (stepDetails.blocValidityDetails || []).map(bloc => BlockLightData.convertFromApiValue(bloc)),
      tags: (stepDetails.tags || []).map(tag => TagLightData.mapFromApiValue(tag)),
      parentPhase: PhaseSummaryData.mapFromApiValue(stepDetails.phase),
      additionalComment: stepDetails.additionalComment,
      excludeNumbering: stepDetails.excludeNumbering,
      mobileAppReadOnly: stepDetails.readOnlyOnMobileApp,
      disabled: stepDetails['disabled'],
      nodeOrderToDisplay: stepDetails.nodeOrderToDisplay,
    };
  }

  export function mapToFlowMenuItemData(phaseUuidEntity: string, flowType: FlowTypeEnum, step: StepDetailsData): FlowMenuItemData {
    return {
      title: step.name,
      nodeOrder: step.nodeOrder,
      uuidEntity: step.uuidEntity,
      type: FlowMenuItemTypeEnum.Step,
      customNodeOrder: step.customNodeOrder,
      additionalComment: step.additionalComment,
      blocks: BlockTypeEnum.filteredValues()
        .getValue(flowType)
        .map(type => ({ blockType: type, disabled: !StepDetailsViewData.isBlockEnabled(step, type) })),
      description: step.description,
      maskedInPdfFile: step.hideTitlePdf,
      parent: { uuidEntity: phaseUuidEntity } as FlowMenuItemData,
      mobileAppReadOnly: step.mobileAppReadOnly,
      disabled: step.disabled,
    };
  }

  export function mapToCreateRequestApiValue(phaseUuidEntity: string, projectUuidEntity: string, step: StepDetailsData, nodeOrder: string): StepCreateRequestDTO {
    return {
      name: step.name,
      description: step.description,
      customNodeOrder: step.customNodeOrder,
      hideTitlePdf: step.hideTitlePdf,
      pr: step.pr,
      emm: step.emm,
      adr: step.adr,
      dsi: step.dsi,
      form: step.form,
      txt: step.txt,
      rhp: step.rhp,
      blocObject: step.blocObject,
      uuidePhase: phaseUuidEntity,
      uuideProject: projectUuidEntity,
      nodeOrder: (isNumber(+nodeOrder) && +nodeOrder) || undefined,
      multiPr: step.multiPr,
      tagUuidEntities: (step.tags || []).map(tag => tag.uuidEntity),
      additionalComment: step.additionalComment,
      excludeNumbering: step.excludeNumbering,
    };
  }

  export function mapToUpdateRequestApiValue(projectUuidEntity: string, step: StepDetailsData): StepUpdateRequestDTO {
    return {
      name: step.name,
      description: step.description,
      customNodeOrder: step.customNodeOrder,
      hideTitlePdf: step.hideTitlePdf,
      uuide: step.uuidEntity,
      adr: step.adr,
      emm: step.emm,
      rhp: step.rhp,
      dsi: step.dsi,
      form: step.form,
      txt: step.txt,
      pr: step.pr,
      blocObject: step.blocObject,
      uuideProject: projectUuidEntity,
      multiPr: step.multiPr,
      tagUuidEntities: (step.tags || []).map(tag => tag.uuidEntity),
      additionalComment: step.additionalComment,
      excludeNumbering: step.excludeNumbering,
    };
  }
}
