import { compact } from 'lodash';
import { DateUtils } from '../../utils/date.utils';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { PageArchiveRequest, ArchiveSummaryResponseDTO } from '../../generated/models/models';
import { UserLightData } from '../user/user-light-data';

import { InnerSortDirectionEnum, TrashNatureEnum } from '../../enums';

export interface TrashSummaryData {
  type: TrashNatureEnum;
  name: string;
  archiveDate: string;
  uuidEntity: string;
  archivedBy: UserLightData;
}

export namespace TrashSummaryData {
  const sortFields = ['type', 'name', 'archiveDate'];

  export interface QueryRequest extends BaseQuery<TrashSummaryData> {
    nature: TrashNatureEnum;
    users?: string[];
  }

  export function mapFromApiValue(data: ArchiveSummaryResponseDTO): TrashSummaryData {
    return {
      type: TrashNatureEnum.convertFromApiValue.getValue(data.type),
      name: data.name,
      archiveDate: DateUtils.toDateTimeFormat(data.archiveDate),
      uuidEntity: data.uuidEntity,
      archivedBy: UserLightData.mapFromApiValue(data.modifiedBy),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageArchiveRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      types: compact([TrashNatureEnum.convertToApiValue.getValue(queries.nature)]),
      users: queries.users,
    };
  }

  export function initializeQueryRequest(nature: string, users: string[], searchText: string, sort: SortFilter<TrashSummaryData>): QueryRequest {
    return {
      ...initializeQueries<TrashSummaryData>(sort),
      textSearch: searchText || undefined,
      nature: TrashNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      users,
    };
  }
}
