import { ResetValidationFlowData } from '..';
import { RequestOrEntityUuidsRequestDTOPageFlowRequest } from '../../generated/models/requestOrEntityUuidsRequestDTOPageFlowRequest';

export type ResetValidationExecuteFlow = Partial<ResetValidationFlowData>;

export namespace ResetValidationExecuteFlow {
  export function mapExecuteListToRequestApiValue(object: ResetValidationExecuteFlow, entityUuids: string[]): RequestOrEntityUuidsRequestDTOPageFlowRequest {
    return {
      resetInternalValidation: object.resetInternalValidation,
      resetExternalValidation: object.resetExternalValidation,
      resetInternalParentValidation: object.resetInternalParentValidation,
      resetInternalChildValidation: object.resetInternalChildValidation,
      resetExternalParentValidation: object.resetExternalParentValidation,
      resetExternalChildValidation: object.resetExternalChildValidation,
      resetDocumentFlows: object.resetDocumentFlows,
      resetParentDocumentFlows: object.resetParentDocumentFlows,
      resetChildDocumentFlows: object.resetChildDocumentFlows,
      entityUuids: entityUuids,
    };
  }
}
