import { ConnectionPositionPair, OverlayRef } from '@angular/cdk/overlay';
import { SfxMap } from '../../utils/enum-utils';

export enum PopoverPositionEnum {
  Left = 1,
  Right = 2,
  Top = 3,
  Bottom = 4,
  Horizontal = 5,
  Vertical = 6,
  TopRight = 7,
  TopLeft = 8,
  BottomRight = 9,
  BottomLeft = 10,
  SelectPosition = 11,
}

export namespace PopoverPositionEnum {
  export const positions = new SfxMap<PopoverPositionEnum, ConnectionPositionPair[]>(
    [
      [PopoverPositionEnum.Left, [{ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }]],
      [PopoverPositionEnum.Right, [{ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' }]],
      [PopoverPositionEnum.BottomRight, [{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]],
      [PopoverPositionEnum.BottomLeft, [{ originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' }]],
      [PopoverPositionEnum.TopRight, [{ originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' }]],
      [PopoverPositionEnum.TopLeft, [{ originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' }]],
      [PopoverPositionEnum.Top, [{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]],
      [PopoverPositionEnum.Bottom, [{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]],
      [
        PopoverPositionEnum.Horizontal,
        [
          { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
          { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' },
        ],
      ],
      [
        PopoverPositionEnum.SelectPosition,
        [
          { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
          { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
        ],
      ],
      [
        PopoverPositionEnum.Vertical,
        [
          { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
          { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
        ],
      ],
    ],
    [
      { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' },
      { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
      { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
      { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
    ],
  );

  export const toAnchorClassName = (overlay: OverlayRef, origin: HTMLElement): SfxMap<PopoverPositionEnum, string> => {
    const overlayBottom = overlay.overlayElement.getBoundingClientRect().bottom;
    const originBottom = origin.getBoundingClientRect().bottom;
    const overlayLeft = overlay.overlayElement.getBoundingClientRect().left;
    const originLeft = origin.getBoundingClientRect().left;

    return new SfxMap<PopoverPositionEnum, string>(
      [
        [PopoverPositionEnum.Top, 'anchor anchor-bottom'],
        [PopoverPositionEnum.Bottom, 'anchor anchor-top'],
        [PopoverPositionEnum.Right, 'anchor anchor-left'],
        [PopoverPositionEnum.Left, 'anchor anchor-right'],
        [PopoverPositionEnum.TopRight, 'anchor anchor-top-left'],
        [PopoverPositionEnum.Vertical, overlayBottom < originBottom ? 'anchor anchor-bottom' : 'anchor anchor-top'],
        [PopoverPositionEnum.Horizontal, overlayLeft < originLeft ? 'anchor anchor-right' : 'anchor anchor-left'],
      ],
      '',
    );
  };
}
