import { createAction, props } from '@ngrx/store';

import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { DocumentDiffusionData } from '../../../../../../core/models/document/document-diffusion';
import { CustomFieldData } from '../../../../../../core/models/custom-fields/custom-field';
import { DialogToasterData } from '../../../../../sfx-dialog/state/dialog-toaster-data';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentTabsEnum } from '../../../../../../core/enums/entity-modal-tab/document-tabs.enum';

/* Add Folder actions */
export const loadFolderDetails = createAction('[AddDashFolder] Load folder details', props<{ folderUuidEntity: string; reset?: boolean }>());
export const loadFolderDetailsFail = createAction('[AddDashFolder] Load folder details fail', props<DialogToasterData>());
export const loadFolderDetailsSuccess = createAction('[AddDashFolder] Load folder details success', props<{ folderDetails: DocumentDetailsData }>());

export const saveFolderChanges = createAction(
  '[AddDashFolder] Save folder changes',
  props<{ folderDetails: DocumentDetailsData; customFields: CustomFieldData[]; nextTab: DocumentTabsEnum; nextFolderStep?: number }>(),
);
export const stopSaveLoading = createAction('[AddDashFolder] Stop save loading');
export const createFolderFail = createAction('[AddDashFolder] Create folder fail', props<DialogToasterData>());
export const updateFolderFail = createAction('[AddDashFolder] Update folder fail', props<DialogToasterData>());
export const createFolderSuccess = createAction('[AddDashFolder] Create folder success', props<{ folderDetails: DocumentDetailsData }>());
export const updateFolderSuccess = createAction(
  '[AddDashFolder] Update folder success',
  props<{ folderDetails: DocumentDetailsData; customFields: CustomFieldData[]; nextTab: DocumentTabsEnum }>(),
);

export const checkGeneratedRefs = createAction('[AddDashFolder] check generated references', props<{ familyUuidEntity: string; reset: boolean }>());
export const checkGeneratedRefsFail = createAction('[AddDashFolder] check generated references fail', props<DialogToasterData>());
export const checkGeneratedRefsSuccess = createAction(
  '[AddDashFolder] check generated references success',
  props<{ familyUuidEntity: string; genRefInt: boolean; genRefExt: boolean; genCompRefInt: boolean; genCompRefExt: boolean; reset: boolean }>(),
);

export const navigateThroughFolders = createAction(
  '[AddDashFolder] navigate through folders',
  props<{ folderDetails: DocumentDetailsData; customFields: CustomFieldData[]; step: number }>(),
);
export const navigateThroughFoldersSuccess = createAction('[AddDashFolder] navigate through folders Success', props<{ uuidEntity: string; step: number }>());

export const loadFolderCustomFields = createAction('[AddDashFolder] load folder custom fields', props<{ folderDetails: DocumentDetailsData }>());
export const loadFolderCustomFieldsFail = createAction('[AddDashFolder] load folder custom fields fail', props<DialogToasterData>());
export const loadFolderCustomFieldsSuccess = createAction('[AddDashFolder] load folder custom fields success', props<{ customFields: CustomFieldData[] }>());

export const checkDiffusedDocuments = createAction('[AddDashFolder] check diffused Documents', props<{ diffusionData: DocumentDiffusionData; uuidEntity: string }>());

// custom wording actions
export const loadCustomWordingDetails = createAction('[AddDashFolder] Load custom wording details', props<{ labelFamilyUuidEntity: string }>());
export const loadCustomWordingDetailsFail = createAction('[AddDashFolder] Load custom wording details fail', props<DialogToasterData>());
export const loadCustomWordingDetailsSuccess = createAction('[AddDashFolder] Load custom wording details success', props<{ folderCustomWording: CustomWordingsDocumentData }>());
