import { FamilySummaryResponseDTO, FamilyPageRequest } from '../../generated/models/models';
import { NatureLabelFamilyResponseDTO } from '../../generated/models/natureLabelFamilyResponseDTO';
import { InnerSortDirectionEnum, LabelFamilyProvenanceEnum, LabelFamilyNatureEnum, LabelFamilyRelativeNatureEnum } from '../../enums';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';
import { SfxMap } from '../../utils/enum-utils';

export interface FamilySummaryData {
  childrenCount: number;
  color: string;
  familyParent: LabelFamilyLightData;
  fullName: string;
  name: string;
  nature: LabelFamilyNatureEnum;
  priority: number;
  provenance: LabelFamilyProvenanceEnum;
  qualityReference: string;
  relativeNature: LabelFamilyRelativeNatureEnum;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  parent?: FamilySummaryData;
  archived?: boolean;
}

export namespace FamilySummaryData {
  export interface FamilyQueryRequest extends BaseQuery<FamilySummaryData> {
    nature?: LabelFamilyNatureEnum;
    relativeNature?: LabelFamilyRelativeNatureEnum;
    uuidEntityFamilyParent?: string;
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(family: FamilySummaryResponseDTO): FamilySummaryData {
    return {
      childrenCount: family.childTotalCount,
      color: family.color || '#0d4e8a',
      familyParent: family.familyParent && LabelFamilyLightData.mapFromApiValue(family.familyParent),
      fullName: family.fullName,
      name: (family.fullName && family.name + ' - ' + family.fullName) || family.name,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(family.nature),
      priority: family.priority,
      provenance: LabelFamilyProvenanceEnum.convertFromApiValue.getValue(family.provenance),
      qualityReference: family.qualityReference,
      relativeNature: LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(family.relativeNature),
      uuid: family.uuid,
      uuidEntity: family.uuidEntity,
      uuidOrganization: family.uuidOrganization,
      uuidType: family.uuidType,
      archived: family.archived,
    };
  }

  export function mapFromNatureFamilyApiValue(natureFamily: NatureLabelFamilyResponseDTO): FamilySummaryData {
    return {
      uuidEntity: LabelFamilyNatureEnum.relativeUuidEntities.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      relativeNature: LabelFamilyRelativeNatureEnum.Container,
      nature: LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature),
      name: LabelFamilyNatureEnum.toString.getValue(LabelFamilyNatureEnum.convertFromApiValue.getValue(natureFamily.nature)),
      color: '#000',
      childrenCount: natureFamily.childTotalCount,
    } as FamilySummaryData;
  }

  export function mapToPageRequestApiValue(queries: FamilyQueryRequest): FamilyPageRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      nature: LabelFamilyNatureEnum.convertToApiValue.getValue(queries.nature),
      uuideFamilyParent: queries.uuidEntityFamilyParent,
      choixRelativeNature: LabelFamilyRelativeNatureEnum.convertToApiValue.getValue(queries.relativeNature),
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(searchText: string, nature: string, state?: string): FamilyQueryRequest {
    return {
      ...initializeQueries<FamilySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      nature: LabelFamilyNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }
  export function initializeFilterQueries(page: number, searchText: string, nature: LabelFamilyNatureEnum): FamilyQueryRequest {
    return {
      ...initializeQueries<FamilySummaryData>({ key: 'priority', direction: InnerSortDirectionEnum.Asc }),
      textSearch: searchText || undefined,
      page,
      nature,
      relativeNature: LabelFamilyRelativeNatureEnum.Parent,
    };
  }

  export function toString(family: FamilySummaryData): string {
    return family.name;
  }

  export const initialRows: FamilySummaryData[] = LabelFamilyNatureEnum.initialValues().map(
    initialRow =>
      ({
        uuidEntity: initialRow.uuidEntity,
        relativeNature: LabelFamilyRelativeNatureEnum.Container,
        nature: initialRow.nature,
        name: LabelFamilyNatureEnum.toString.getValue(initialRow.nature),
        color: '#000',
        childrenCount: 1,
      } as FamilySummaryData),
  );

  export const familyErrorKeys = new SfxMap<string, string>([['usedName', 'usedName']]);

  export const getInitialRows = (nature: LabelFamilyNatureEnum, families: FamilySummaryData[]): FamilySummaryData[] =>
    nature ? families.filter(row => row.nature === nature) : families;
}
