export enum RouteParamEnum {
  ProjectUuidEntity = 'projectUuidEntity',
  FolderUuidEntity = 'folderUuidEntity',
  FlowUuidEntity = 'flowUuidEntity',
  FormUuidEntity = 'formUuidEntity',
  FormBusinessLinkUuidEntity = 'formBusinessLinkUuidEntity',
  RubricUuidEntity = 'rubricUuidEntity',
  QuestionUuidEntity = 'questionUuidEntity',
  PhaseUuidEntity = 'phaseUuidEntity',
  StepUuidEntity = 'stepUuidEntity',
  StepElementUuidEntity = 'stepElementUuidEntity',
  BlockUuidEntity = 'blockUuidEntity',
  ListUuidEntity = 'dListUuidEntity',
  ElementUuidEntity = 'elementUuidEntity',
  ParentUuidEntity = 'parentUuidEntity',
  ShareUuidEntity = 'shareUuidEntity',
  TagUuidEntity = 'tagUuidEntity',
  ApplicationFileUuidEntity = 'applicationFileUuidEntity',
  SignatureNature = 'signatureNature',
  NodeOrder = 'nodeOrder',
  BusinessClass = 'businessClass',
  StartDate = 'startDate',
  EndDate = 'endDate',
}
