import { ExternalApiUserAccessResponseDTO, ExternalApiUserSummaryResponseDTO, GenerateExternalApiUserAccessRequestDTO } from '../../generated/models/models';

export interface AccessApiDetailsData {
  clientSecret?: string;
  login?: string;
  name?: string;
  ssoIdentifier?: string;
  uuid?: string;
  uuidEntity?: string;
  uuidOrganization?: string;
  uuidType?: string;
}

export namespace AccessApiDetailsData {
  export function mapFromApiValue(accessApi: ExternalApiUserSummaryResponseDTO): AccessApiDetailsData {
    return {
      clientSecret: accessApi['clientSecret'], // make models issue
      login: accessApi.login,
      name: accessApi.name,
      ssoIdentifier: accessApi.ssoIdentifier,
      uuid: accessApi.uuid,
      uuidEntity: accessApi.uuidEntity,
      uuidOrganization: accessApi.uuidOrganization,
      uuidType: accessApi.uuidType,
    };
  }
  export function mapAccessApiDetailsDataFromApiValue(accessApi: ExternalApiUserAccessResponseDTO, request: GenerateExternalApiUserAccessRequestDTO): AccessApiDetailsData {
    return {
      clientSecret: accessApi.clientSecret,
      name: request.name,
      ssoIdentifier: request.ssoIdentifier,
    };
  }
}
