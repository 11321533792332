import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { TabletStatusEnum, UserNatureEnum } from '../../enums';
import { DeviceCreateRequestDTO, DeviceDetailsResponseDTO, DeviceUpdateRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { CompanySummaryData } from '../companies/companies-summary-data';

export interface CTabletDetailsData {
  uuidEntity: string;
  labelFamily: LabelFamilyLightData;
  refIntern: string;
  refExtern: string;
  mobileVersion: string;
  androidVersion: string;
  comment: string;
  batteryLevel: string;
  latitude: string;
  longitude: string;
  gpsPosition: string;
  email?: string;
  notificationComment?: string;
  uniqueDeviceId: string;
  qrCode: string;
  nature: UserNatureEnum;
  status: TabletStatusEnum;
  backgroundColor: string;
  applicabilityName: string;
  addressMac: string;
  lastSeen: string;
  deviceScreenSize: string;
  deviceModel: string;
  company: CompanySummaryData;
}

export namespace CTabletDetailsData {
  export function mapFromApiValue(tabletDetails: DeviceDetailsResponseDTO): CTabletDetailsData {
    return {
      uuidEntity: tabletDetails.uuidEntity,
      labelFamily: (tabletDetails.labelFamilyTeam && LabelFamilyLightData.mapFromApiValue(tabletDetails.labelFamilyTeam)) || LabelFamilyLightData.defaultData,
      refIntern: tabletDetails.refInt,
      refExtern: tabletDetails.refExt,
      mobileVersion: tabletDetails.versionApp,
      androidVersion: tabletDetails.androidVersion,
      comment: tabletDetails.description,
      batteryLevel: (tabletDetails.lastBatteryLevel || '').replace('%', ''),
      latitude: tabletDetails.lastLatitude,
      longitude: tabletDetails.lastLongitude,
      gpsPosition: tabletDetails.lastLatitude && tabletDetails.lastLongitude && `${tabletDetails.lastLatitude},${tabletDetails.lastLongitude}`,
      uniqueDeviceId: tabletDetails.uniqueDeviceId,
      qrCode: `data:image/png;base64,${tabletDetails.qrCode}`,
      nature: tabletDetails.nature && UserNatureEnum.convertFromApiValue.getValue(tabletDetails.nature),
      status: tabletDetails.status && TabletStatusEnum.convertFromApiValue.getValue(tabletDetails.status),
      backgroundColor: tabletDetails.backgroundColor,
      applicabilityName: tabletDetails.applicabilityName,
      addressMac: tabletDetails.addressMac,
      lastSeen: DateUtils.toDateTimeFormat(tabletDetails.lastSeen),
      deviceScreenSize: tabletDetails.deviceScreenSize,
      deviceModel: tabletDetails.deviceModel,
      company: tabletDetails.company && CompanySummaryData.mapFromCompanyLight(tabletDetails.company),
    };
  }

  export function mapToCreateRequestApiValue(data: CTabletDetailsData): DeviceCreateRequestDTO {
    return {
      backgroundColor: data.backgroundColor,
      description: data.comment,
      refExt: data.refExtern,
      nature: UserNatureEnum.convertToApiValue.getValue(data.nature),
      status: TabletStatusEnum.convertToApiValue.getValue(data.status),
      teamUuidEntity: data.labelFamily?.uuidEntity,
      companyUuidEntity: data.company?.uuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(data: CTabletDetailsData): DeviceUpdateRequestDTO {
    return {
      backgroundColor: data.backgroundColor,
      description: data.comment,
      nature: UserNatureEnum.convertToApiValue.getValue(data.nature),
      status: TabletStatusEnum.convertToApiValue.getValue(data.status),
      refExt: data.refExtern,
      teamUuidEntity: data.labelFamily?.uuidEntity,
      companyUuidEntity: data.company?.uuidEntity,
      uuidEntity: data.uuidEntity,
    };
  }
}
