import { createReducer, Action, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

import { LazyLoadResponse, SkillDetailsData, LibraryContributorSummaryData, SkillSummaryData, LibraryContributorDetailsData } from '../../../../../core/models';
import * as fromOperatorDialogActions from './operators-dialog.actions';

const adapter: EntityAdapter<LibraryContributorSummaryData> = createEntityAdapter<LibraryContributorSummaryData>({
  selectId: libraryOperator => libraryOperator.uuidEntity,
});

export interface ReorganizeOperatorDialogState extends EntityState<LibraryContributorSummaryData> {
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export interface EditOperatorDialogState {
  saveLoading: boolean;
  operatorsDetails: LibraryContributorDetailsData;
}

export interface UploadUsersDialogState {
  uploadLoading: boolean;
}

const initialUploadUsersDialogState = (): UploadUsersDialogState => ({
  uploadLoading: false,
});

export interface SkillsOperatorDialogState {
  skillsData: LazyLoadResponse<SkillSummaryData[]>;
  skillDetailData: SkillDetailsData;
  uploadLoading: boolean;
  filesToUpload: File[];
  reset: boolean;
}

export interface OperatorDialogState {
  operatorData: EditOperatorDialogState;
  skills: SkillsOperatorDialogState;
  uploadUsers: UploadUsersDialogState;
  reorganizeUsers: ReorganizeOperatorDialogState;
}

const initialSkillsOperatorDialogState = (): SkillsOperatorDialogState => ({
  skillsData: {} as LazyLoadResponse<SkillSummaryData[]>,
  skillDetailData: {} as SkillDetailsData,
  filesToUpload: [],
  uploadLoading: false,
  reset: false,
});

const initialOperatorDetailsDialogState = (): EditOperatorDialogState => ({
  saveLoading: false,
  operatorsDetails: { hierarchies: [] } as LibraryContributorDetailsData,
});

export const reorganizeOperatorsInitialState = (): ReorganizeOperatorDialogState =>
  adapter.getInitialState<ReorganizeOperatorDialogState>({
    ids: [],
    entities: {},
    loading: false,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export const initialLibraryOperatorDialogState = () => ({
  skills: initialSkillsOperatorDialogState(),
  uploadUsers: initialUploadUsersDialogState(),
  operatorData: initialOperatorDetailsDialogState(),
  reorganizeUsers: reorganizeOperatorsInitialState(),
});

const reducer = createReducer<OperatorDialogState>(
  initialLibraryOperatorDialogState(),
  on(
    fromOperatorDialogActions.loadUserDetailDataSuccess,
    (state, operatorsDetails): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, operatorsDetails } }),
  ),
  on(fromOperatorDialogActions.updateOperator, (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, saveLoading: true } })),
  on(fromOperatorDialogActions.updateOperatorFail, (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, saveLoading: false } })),
  on(fromOperatorDialogActions.stopImportFileLoading, (state): OperatorDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(fromOperatorDialogActions.updateOperatorSuccess, (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, saveLoading: false } })),

  on(fromOperatorDialogActions.addOperator, (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, saveLoading: true } })),
  on(fromOperatorDialogActions.addOperatorFail, (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, saveLoading: false } })),
  on(
    fromOperatorDialogActions.addOperatorSuccess,
    (state): OperatorDialogState => ({ ...state, operatorData: { ...state.operatorData, operatorsDetails: {} as LibraryContributorDetailsData, saveLoading: false } }),
  ),
  on(
    fromOperatorDialogActions.loadSkillsOperatorListSuccess,
    (state, { data, reset }): OperatorDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        skillsData: reset ? data : { ...state.skills.skillsData, payload: [...state.skills.skillsData.payload, ...data.payload] },
      },
    }),
  ),
  on(fromOperatorDialogActions.loadSkillDetailDataSuccess, (state, skill): OperatorDialogState => ({ ...state, skills: { ...state.skills, skillDetailData: skill } })),
  on(fromOperatorDialogActions.uploadFileSkill, (state): OperatorDialogState => ({ ...state, skills: { ...state.skills, uploadLoading: true } })),
  on(fromOperatorDialogActions.uploadFileSkillFail, (state): OperatorDialogState => ({ ...state, skills: { ...state.skills, uploadLoading: false } })),
  on(
    fromOperatorDialogActions.setFileToUpload,
    (state, { selectedFiles }): OperatorDialogState => ({ ...state, skills: { ...state.skills, filesToUpload: [...state.skills.filesToUpload, ...selectedFiles] } }),
  ),

  on(
    fromOperatorDialogActions.uploadFileSkillSuccess,
    (state, { skillUuidEntity, pdfFiles }): OperatorDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        uploadLoading: false,
        filesToUpload: [],
        skillsData: {
          ...state.skills.skillsData,
          payload:
            state.skills.skillsData &&
            state.skills.skillsData.payload.map(skill => (skill.uuidEntity === skillUuidEntity ? { ...skill, applicationFiles: [...skill.applicationFiles, ...pdfFiles] } : skill)),
        },
      },
    }),
  ),
  on(
    fromOperatorDialogActions.deletePdfFileSuccess,
    (state, { skillUuidEntity, fileUuidEntity }): OperatorDialogState => ({
      ...state,
      skills: {
        ...state.skills,
        skillsData: {
          ...state.skills.skillsData,
          payload:
            state.skills.skillsData.payload &&
            state.skills.skillsData.payload.map(skill =>
              skill.uuidEntity === skillUuidEntity ? { ...skill, applicationFiles: skill.applicationFiles.filter(file => file.uuidEntity !== fileUuidEntity) } : skill,
            ),
        },
      },
    }),
  ),
  on(fromOperatorDialogActions.setFileToUploadUsers, (state): OperatorDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: true } })),
  on(fromOperatorDialogActions.setFileToUploadUsersSuccess, (state): OperatorDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(fromOperatorDialogActions.setFileToUploadUsersFail, (state): OperatorDialogState => ({ ...state, uploadUsers: { ...state.uploadUsers, uploadLoading: false } })),
  on(
    fromOperatorDialogActions.loadReorganizeOperatorListSuccess,
    (state, { operators, totalCount, filteredTotalCount, reset }): OperatorDialogState => {
      const newReorganizeState: ReorganizeOperatorDialogState = { ...state.reorganizeUsers, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? adapter.setAll(operators, newReorganizeState) : adapter.addMany(operators, newReorganizeState);

      return { ...state, reorganizeUsers: newState };
    },
  ),
);

export function operatorsDialogReducer(state: OperatorDialogState | undefined, action: Action): OperatorDialogState {
  return reducer(state, action);
}

export const selectAllOperators = adapter.getSelectors().selectAll;
