import { QuestionTypeEnum } from '../../enums';
import { RubricOptionUpdateRequestDTO } from '../../generated/models/rubricOptionUpdateRequestDTO';

export interface OptionUpdateNameData {
  name: string;
  questionType: QuestionTypeEnum;
  uuidEntity: string;
}

export namespace OptionUpdateNameData {
  export function mapToRequestApiValue(updateRequest: OptionUpdateNameData): RubricOptionUpdateRequestDTO {
    return {
      uuidEntity: updateRequest.uuidEntity,
      name: updateRequest.name,
      type: QuestionTypeEnum.convertToRubricUpdateType.getValue(updateRequest.questionType),
    };
  }
}
