import { concat, find } from 'lodash';
import { CustomFieldSummaryDTO, LabelFamilyCustomFieldLightDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { IconData } from '../../../modules/shared/interfaces/icon-data';

import { CustomColumnsTypeEnum, FieldTypeEnum, FlowColumnEnum } from '../../enums';
import { TypeUuidEntityEnum } from '../../enums/utils/type-uuid-entity-enum';

export interface CustomColumnData<T = string> {
  uuidEntity: T;
  name: string;
  color: string;
  isParent: boolean;
  order?: number;
  parentUuidEntity?: string;
  parentName?: string;
  childrenCount?: number;
  columnType?: CustomColumnsTypeEnum;
  icon?: IconData;
  subItems?: CustomColumnData[];
  fieldType?: string;
  defaultFamily?: boolean;
  typeField?: FieldTypeEnum;
}

const defaultColor = '#0d4e8a';
export namespace CustomColumnData {
  export const columnsSubItems = (forPgac: boolean) => CustomColumnsTypeEnum.predefinedCategories.map(type => mapStaticColumn(type, forPgac));
  export const attributes = (forPgac: boolean) => concat([], ...columnsSubItems(forPgac).map(column => column.subItems));

  export const defaultAttributes = (forPgac: boolean) =>
    (forPgac ? FlowColumnEnum.pgacDndColumns : FlowColumnEnum.flowDndColumns).map(uuidEntity => ({ ...find(attributes(forPgac), { uuidEntity }), uuidEntity }));

  export interface QueryRequest extends BaseQuery<CustomColumnData> {
    projectUuidEntity: string;
  }

  export function mapCustomStatusValue(data: LabelFamilyCustomFieldLightDTO): CustomColumnData {
    const getName = (): string => {
      if (data.uuidEntity === TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT)) {
        return 'flows.customizeColumns.defaultAttribute';
      }

      // MakeModels: @Ahmed generate models after merge PR Backend.
      return (data['labelFamilyLightDTO']?.['fullName'] ? data['labelFamilyLightDTO']['fullName'].concat(' - ') : '').concat(data.name);
    };

    return {
      uuidEntity: data.uuidEntity,
      name: getName(),
      color: data?.color || defaultColor,
      columnType: CustomColumnsTypeEnum.Custom,
      isParent: true,
      defaultFamily: data.uuidEntity === TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT),
      childrenCount: (data.customFields || []).length ? 1 : 0,
      subItems: (data.customFields || []).map(cf => ({ ...mapCustomField(cf), parentUuidEntity: data.uuidEntity, color: data?.color || defaultColor })),
    };
  }

  export function mapStaticColumn(type: CustomColumnsTypeEnum, forPgac: boolean): CustomColumnData {
    return {
      uuidEntity: 'uuid-entity-' + type,
      columnType: type,
      name: CustomColumnsTypeEnum.toString.getValue(type),
      icon: CustomColumnsTypeEnum.icon.getValue(type),
      color: CustomColumnsTypeEnum.color.getValue(type),
      isParent: true,
      childrenCount: 1,
      subItems: CustomColumnsTypeEnum.toFlowColumn(forPgac)
        .getValue(type)
        .map(flow => ({
          uuidEntity: flow,
          name: FlowColumnEnum.toString.getValue(flow),
          color: CustomColumnsTypeEnum.color.getValue(type),
          icon: CustomColumnsTypeEnum.icon.getValue(type),
          columnType: type,
          isParent: false,
          parentUuidEntity: 'uuid-entity-' + type,
        })),
    };
  }

  export function mapCustomField(data: CustomFieldSummaryDTO): CustomColumnData {
    return {
      uuidEntity: data?.uuidEntity,
      name: data?.name,
      color: data?.labelFamilyLightDTO?.color,
      columnType: CustomColumnsTypeEnum.Custom,
      isParent: false,
      parentUuidEntity: data?.labelFamilyLightDTO?.uuidEntity || TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT),
      typeField: FieldTypeEnum.convertFromApiValue(data.longText)?.getValue(data?.typeField),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): QueryRequest {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function initialize(projectUuidEntity: string, searchText: string): QueryRequest {
    return {
      ...initializeQueries<CustomColumnData>(),
      textSearch: searchText || undefined,
      projectUuidEntity,
    };
  }
}
