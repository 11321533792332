import { FormDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum FormTypeEnum {
  Response = 1,
  Builder = 2,
  ResponseDone = 3,
  Other = 4,
}

export namespace FormTypeEnum {
  export const convertFromApiValue: SfxMap<FormDetailsResponseDTO.TypeFormEnum, FormTypeEnum> = new SfxMap<FormDetailsResponseDTO.TypeFormEnum, FormTypeEnum>([
    [FormDetailsResponseDTO.TypeFormEnum.RESPONSE, FormTypeEnum.Response],
    [FormDetailsResponseDTO.TypeFormEnum.FORM, FormTypeEnum.Builder],
    [FormDetailsResponseDTO.TypeFormEnum.RESPONSEDONE, FormTypeEnum.ResponseDone],
    [FormDetailsResponseDTO.TypeFormEnum.AUTRE, FormTypeEnum.Other],
  ]);

  export const shareInitSubmitText: SfxMap<FormTypeEnum, string> = new SfxMap<FormTypeEnum, string>([
    [FormTypeEnum.Response, 'share.formResponseSubmit'],
    [FormTypeEnum.Builder, 'share.formSubmit'],
  ]);
}
