import { ResponseErrorUploadEmmDTO, ResponseErrorUploadUserDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';

export enum ConflictUploadNatureEnum {
  Duplicated = 1,
  NotConform = 2,
  InvalidFamily = 3,
  InvalidDate = 4,
  InvalidName = 5,
  BreaksLine = 6,
  InvalidEMail = 7,
  InvalidRight = 8,
  InvalidCompany = 9,
  Mandatory = 10,
}
export namespace ConflictUploadNatureEnum {
  export const convertEmmAdrFromApiValue: SfxMap<ResponseErrorUploadEmmDTO.ErrorEnum, ConflictUploadNatureEnum> = new SfxMap<
    ResponseErrorUploadEmmDTO.ErrorEnum,
    ConflictUploadNatureEnum
  >([
    [ResponseErrorUploadEmmDTO.ErrorEnum.DUPLICATED, ConflictUploadNatureEnum.Duplicated],
    [ResponseErrorUploadEmmDTO.ErrorEnum.NOTCONFORM, ConflictUploadNatureEnum.NotConform],
    [ResponseErrorUploadEmmDTO.ErrorEnum.INVALIDFAMILY, ConflictUploadNatureEnum.InvalidFamily],
    [ResponseErrorUploadEmmDTO.ErrorEnum.INVALIDDATE, ConflictUploadNatureEnum.InvalidDate],
    [ResponseErrorUploadEmmDTO.ErrorEnum.INVALIDNAME, ConflictUploadNatureEnum.InvalidName],
    [ResponseErrorUploadEmmDTO.ErrorEnum.BREAKSLINE, ConflictUploadNatureEnum.BreaksLine],
  ]);

  export const convertUserFromApiValue: SfxMap<ResponseErrorUploadUserDTO.ErrorEnum, ConflictUploadNatureEnum> = new SfxMap<
    ResponseErrorUploadUserDTO.ErrorEnum,
    ConflictUploadNatureEnum
  >([
    [ResponseErrorUploadUserDTO.ErrorEnum.DUPLICATED, ConflictUploadNatureEnum.Duplicated],
    [ResponseErrorUploadUserDTO.ErrorEnum.INVALIDEMAIL, ConflictUploadNatureEnum.InvalidEMail],
    [ResponseErrorUploadUserDTO.ErrorEnum.INVALIDFAMILY, ConflictUploadNatureEnum.InvalidFamily],
    [ResponseErrorUploadUserDTO.ErrorEnum.INVALIDRIGHT, ConflictUploadNatureEnum.InvalidRight],
    [ResponseErrorUploadUserDTO.ErrorEnum.INVALIDCOMPANY, ConflictUploadNatureEnum.InvalidCompany],
    [ResponseErrorUploadUserDTO.ErrorEnum.MANDATORYPARAMETER, ConflictUploadNatureEnum.Mandatory],
  ]);

  export const toString = (nature: BusinessClassNameEnum): SfxMap<ConflictUploadNatureEnum, string> => {
    const name = BusinessClassNameEnum.convertToParamsValue.getValue(nature);
    const key = `global.elementConflict.${name}.titleErrorNature.`;

    return new SfxMap<ConflictUploadNatureEnum, string>([
      [ConflictUploadNatureEnum.Duplicated, key + 'duplicated'],
      [ConflictUploadNatureEnum.NotConform, key + 'notConform'],
      [ConflictUploadNatureEnum.InvalidFamily, key + 'invalidFamily'],
      [ConflictUploadNatureEnum.InvalidDate, key + 'invalidDate'],
      [ConflictUploadNatureEnum.InvalidName, key + 'invalidNameFormat'],
      [ConflictUploadNatureEnum.BreaksLine, key + 'breaksLine'],
      [ConflictUploadNatureEnum.InvalidEMail, key + 'invalidEMail'],
      [ConflictUploadNatureEnum.InvalidRight, key + 'invalidRight'],
      [ConflictUploadNatureEnum.InvalidCompany, key + 'invalidCompany'],
      [ConflictUploadNatureEnum.Mandatory, key + 'mandatory'],
    ]);
  };

  export const translateErrorKeys = (nature?: BusinessClassNameEnum): SfxMap<string, string> => {
    let prefix;
    const name = BusinessClassNameEnum.convertToParamsValue.getValue(nature);
    if (nature === BusinessClassNameEnum.Adr) {
      prefix = 'elementAdr';
    } else {
      prefix = nature === BusinessClassNameEnum.Emm ? 'elementEmm' : 'user';
    }

    return new SfxMap<string, string>(
      [
        [`${prefix}.invalidCellString`, `global.elementConflict.${name}.errorKey.uploadFail`],
        [`${prefix}.notFound`, `global.elementConflict.${name}.errorKey.notFound`],
        [`${prefix}.fileNotXml`, `global.elementConflict.${name}.errorKey.fileNotXml`],
        [`${prefix}.fileFormat`, `global.elementConflict.${name}.errorKey.fileFormat`],
        [`${prefix}.importFail`, `global.elementConflict.${name}.errorKey.importFail`],
      ],
      `global.elementConflict.${name}.errorKey.importFail`,
    );
  };
}
