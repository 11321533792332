import { ClosingFolderLevelsEnum, SectionNatureEnum } from '../../enums';
import { SectionSummaryResponseDTO } from '../../generated/models/models';

export interface FolderSectionSummaryData {
  uuidEntity: string;
  name: string;
  order: number;
  childrenCount: number;
  type: SectionNatureEnum;
  level: ClosingFolderLevelsEnum;
  disabled: boolean;
}

export namespace FolderSectionSummaryData {
  export function mapToApiValue(section: SectionSummaryResponseDTO): FolderSectionSummaryData {
    return {
      uuidEntity: section.uuidEntity,
      name: section.name,
      order: section.order,
      childrenCount: section.documentsCount,
      type: SectionNatureEnum.convertFromApiValue.getValue(section.type),
      level: ClosingFolderLevelsEnum.Section,
      disabled: section.disabled,
    };
  }

  export function mapConflictToString(section: FolderSectionSummaryData): string {
    return section.name;
  }
}
