/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalDateFilterRequest } from './localDateFilterRequest';
import { SortDto } from './sortDto';

/**
 * PageEpsilonOutRequest
 */
export interface PageEpsilonOutRequest {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  doublon?: boolean;
  locals?: Array<string>;
  reperes?: Array<string>;
  states?: Array<PageEpsilonOutRequest.StatesEnum>;
  dateFilterRequest?: LocalDateFilterRequest;
}
export namespace PageEpsilonOutRequest {
  export type StatesEnum = 'INITIAL' | 'UPDATED' | 'FINALIZED';
  export const StatesEnum = {
    INITIAL: 'INITIAL' as StatesEnum,
    UPDATED: 'UPDATED' as StatesEnum,
    FINALIZED: 'FINALIZED' as StatesEnum,
  };
}
