import { TemplateRef } from '@angular/core';
import { ToasterTypeEnum } from '../../../../core/enums/utils/toaster-type.enum';

export class ToasterData {
  type: ToasterTypeEnum;
  title?: string;
  subtitle?: string;
  dismissible?: boolean;
  delay?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template?: TemplateRef<any>;
  templateContext?: {};
  dataCy?: string;
}

export interface ToastConfig {
  delay?: number;
  top?: number;
  right?: number;
  animationFadeIn?: number;
  animationFadeOut?: number;
}

export const configData: ToastConfig = {
  delay: 3000,
  top: 16,
  right: 16,
  animationFadeIn: 300,
  animationFadeOut: 500,
};
