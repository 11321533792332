import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { BlockRiskParadeSummaryData, LazyLoadResponse, AdrElementToImportSummaryData, BlockRiskDetailsData } from '../../../../../../../../core/models';

import * as fromActions from './block-risk-dialog.actions';

export interface RiskDialogState {
  riskDetails: BlockRiskDetailsData;
  initialRiskDetails: BlockRiskDetailsData;
  riskParades: { parades: BlockRiskParadeSummaryData[]; totalCount: number; reset: boolean };
  loading: boolean;
}

export interface ImportDialogState {
  risks: LazyLoadResponse<AdrElementToImportSummaryData[]>;
  reset: boolean;
}

export interface BlockRiskDialogState {
  riskDialogState: RiskDialogState;
  importDialogState: ImportDialogState;
}

const initialRiskDialogState = (): RiskDialogState => ({
  riskDetails: {} as BlockRiskDetailsData,
  initialRiskDetails: {} as BlockRiskDetailsData,
  riskParades: null,
  loading: false,
});

const initialImportDialogState = (): ImportDialogState => ({
  risks: {} as LazyLoadResponse<AdrElementToImportSummaryData[]>,
  reset: false,
});

export const initialBlockRiskDialogState = () => ({
  riskDialogState: initialRiskDialogState(),
  importDialogState: initialImportDialogState(),
  filesToUpload: undefined,
});

const reducer = createReducer<BlockRiskDialogState>(
  initialBlockRiskDialogState(),
  on(
    fromActions.getBlockRiskDetailsSuccess,
    (state, { riskDetails }): BlockRiskDialogState => ({ ...state, riskDialogState: { ...state.riskDialogState, riskDetails, initialRiskDetails: cloneDeep(riskDetails) } }),
  ),
  on(fromActions.setEditBlockRiskLoading, (state, { loading }): BlockRiskDialogState => ({ ...state, riskDialogState: { ...state.riskDialogState, loading } })),
  on(
    fromActions.loadParadeListSuccess,
    (state, { parades, totalCount, reset }): BlockRiskDialogState => ({
      ...state,
      riskDialogState: {
        ...state.riskDialogState,
        riskParades: reset
          ? { parades, totalCount, reset: true }
          : { ...state.riskDialogState.riskParades, parades: [...state.riskDialogState.riskParades.parades, ...parades], reset: false },
      },
    }),
  ),
  on(
    fromActions.loadRisksSuccess,
    (state, { risks, totalCount, filteredTotalCount, reset }): BlockRiskDialogState => ({
      ...state,
      importDialogState: {
        reset,
        ...state.importDialogState,
        risks: reset ? { payload: risks, totalCount, filteredTotalCount } : { ...state.importDialogState.risks, payload: [...state.importDialogState.risks.payload, ...risks] },
      },
    }),
  ),
  on(
    fromActions.importRiskSuccess,
    (state, { riskUuidEntity }): BlockRiskDialogState => ({
      ...state,
      importDialogState: {
        ...state.importDialogState,
        risks: {
          ...state.importDialogState.risks,
          payload: state.importDialogState.risks.payload?.map(adr => (adr.uuidEntity === riskUuidEntity ? { ...adr, frozen: true } : adr)),
        },
      },
    }),
  ),
);

export function blockRiskDialogReducer(state: BlockRiskDialogState | undefined, action: Action): BlockRiskDialogState {
  return reducer(state, action);
}
