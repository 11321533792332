/**
 * @deprecated
 */
import { SfxMap } from '../../utils/enum-utils';

export enum RevisionStatusEnum {
  Done = 1,
  InProgress = 2,
}

export namespace RevisionStatusEnum {
  export const values = [RevisionStatusEnum.Done, RevisionStatusEnum.InProgress];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  export const convertFromApiValue: SfxMap<any, RevisionStatusEnum> = new SfxMap([
    ['DONE', RevisionStatusEnum.Done],
    ['INPROGRESS', RevisionStatusEnum.InProgress],
  ]);
}
