import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { EntityChronoCreateRequestDTO } from '../../generated/models/entityChronoCreateRequestDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum CounterTypeEnum {
  Local = 1,
  Global = 2,
}

export namespace CounterTypeEnum {
  const values: CounterTypeEnum[] = [CounterTypeEnum.Local, CounterTypeEnum.Global];

  export const convertFromApiValue: SfxMap<EntityChronoCreateRequestDTO.CounterTypeEnum, CounterTypeEnum> = new SfxMap<
    EntityChronoCreateRequestDTO.CounterTypeEnum,
    CounterTypeEnum
  >([
    [EntityChronoCreateRequestDTO.CounterTypeEnum.LOCAL, CounterTypeEnum.Local],
    [EntityChronoCreateRequestDTO.CounterTypeEnum.GLOBAL, CounterTypeEnum.Global],
  ]);

  export const convertToApiValue: SfxMap<CounterTypeEnum, EntityChronoCreateRequestDTO.CounterTypeEnum> = new SfxMap<CounterTypeEnum, EntityChronoCreateRequestDTO.CounterTypeEnum>(
    [
      [CounterTypeEnum.Local, EntityChronoCreateRequestDTO.CounterTypeEnum.LOCAL],
      [CounterTypeEnum.Global, EntityChronoCreateRequestDTO.CounterTypeEnum.GLOBAL],
    ],
  );

  const key = 'referenceTranslateKey.modals.new.typeCounter.';
  export const toString: SfxMap<CounterTypeEnum, string> = new SfxMap<CounterTypeEnum, string>([
    [CounterTypeEnum.Local, key + 'local'],
    [CounterTypeEnum.Global, key + 'global'],
  ]);

  function mapToSingleSelectItem(type: CounterTypeEnum): SingleSelectItemData<CounterTypeEnum> {
    return {
      value: type,
      title: CounterTypeEnum.toString.getValue(type),
    };
  }

  export const selectItems: SingleSelectItemData<CounterTypeEnum>[] = values.map(type => mapToSingleSelectItem(type));
}
