import { UserOnboardingRequestDTO, UserOnboardingResponseDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';

export interface UserOnboardingData {
  firstName: string;
  lastName: string;
  trigram: string;
  signature?: string;
  password?: string;
  modificationDate: string;
  passwordMinLength: number;
}

export namespace UserOnboardingData {
  export function mapFromApiValue(userData: UserOnboardingResponseDTO): UserOnboardingData {
    return {
      firstName: userData.firstName,
      lastName: userData.lastName,
      trigram: userData.trigram?.toLocaleUpperCase(),
      modificationDate: DateUtils.toDateFormat(userData.modificationDate),
      passwordMinLength: userData.passwordMinLength,
    };
  }

  export function mapToUpdateRequestApiValue(userData: UserOnboardingData): UserOnboardingRequestDTO {
    return {
      firstName: userData.firstName,
      lastName: userData.lastName,
      signature: userData.signature,
      password: userData.password,
      trigram: userData.trigram?.toLocaleUpperCase(),
    };
  }
}
