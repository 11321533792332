/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElementDsiLightDTO } from './elementDsiLightDTO';

export interface SignatoryDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  createDate: Date;
  modificationDate: Date;
  firstName: string;
  lastName: string;
  profession: string;
  email: string;
  companyName: string;
  optional: boolean;
  userRef: string;
  typeSignatory: SignatoryDetailsResponseDTO.TypeSignatoryEnum;
  signatureState: SignatoryDetailsResponseDTO.SignatureStateEnum;
  imageSignature: string;
  hashSignature: string;
  commentSignature: string;
  signatureDate: Date;
  signatureNature: SignatoryDetailsResponseDTO.SignatureNatureEnum;
  latitude: string;
  longitude: string;
  elementDsi: ElementDsiLightDTO;
  unavailable: boolean;
  localCreateDate: Date;
  disabled: boolean;
}
export namespace SignatoryDetailsResponseDTO {
  export type TypeSignatoryEnum = 'INTERN' | 'EXTERN' | 'NO_LISTED_INTERN';
  export const TypeSignatoryEnum = {
    INTERN: 'INTERN' as TypeSignatoryEnum,
    EXTERN: 'EXTERN' as TypeSignatoryEnum,
    NOLISTEDINTERN: 'NO_LISTED_INTERN' as TypeSignatoryEnum,
  };
  export type SignatureStateEnum = 'SIGNATURE_NOT_POSSIBLE' | 'WAITING' | 'FREE' | 'SIGNED';
  export const SignatureStateEnum = {
    SIGNATURENOTPOSSIBLE: 'SIGNATURE_NOT_POSSIBLE' as SignatureStateEnum,
    WAITING: 'WAITING' as SignatureStateEnum,
    FREE: 'FREE' as SignatureStateEnum,
    SIGNED: 'SIGNED' as SignatureStateEnum,
  };
  export type SignatureNatureEnum =
    | 'SIGNATURE_REALISATION'
    | 'SIGNATURE_SUPRV_INT_CT'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2'
    | 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3'
    | 'SIGNATURE_SUPRV_INT_PI1'
    | 'SIGNATURE_SUPRV_INT_PI2'
    | 'SIGNATURE_SUPRV_INT_PI3'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT1'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT2'
    | 'SIGNATURE_CONVOCATION_SUPRV_EXT3'
    | 'SIGNATURE_SUPRV_EXT1'
    | 'SIGNATURE_SUPRV_EXT2'
    | 'SIGNATURE_SUPRV_EXT3';
  export const SignatureNatureEnum = {
    REALISATION: 'SIGNATURE_REALISATION' as SignatureNatureEnum,
    SUPRVINTCT: 'SIGNATURE_SUPRV_INT_CT' as SignatureNatureEnum,
    CONVOCATIONSUPRVINTPI1: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI1' as SignatureNatureEnum,
    CONVOCATIONSUPRVINTPI2: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI2' as SignatureNatureEnum,
    CONVOCATIONSUPRVINTPI3: 'SIGNATURE_CONVOCATION_SUPRV_INT_PI3' as SignatureNatureEnum,
    SUPRVINTPI1: 'SIGNATURE_SUPRV_INT_PI1' as SignatureNatureEnum,
    SUPRVINTPI2: 'SIGNATURE_SUPRV_INT_PI2' as SignatureNatureEnum,
    SUPRVINTPI3: 'SIGNATURE_SUPRV_INT_PI3' as SignatureNatureEnum,
    CONVOCATIONSUPRVEXT1: 'SIGNATURE_CONVOCATION_SUPRV_EXT1' as SignatureNatureEnum,
    CONVOCATIONSUPRVEXT2: 'SIGNATURE_CONVOCATION_SUPRV_EXT2' as SignatureNatureEnum,
    CONVOCATIONSUPRVEXT3: 'SIGNATURE_CONVOCATION_SUPRV_EXT3' as SignatureNatureEnum,
    SUPRVEXT1: 'SIGNATURE_SUPRV_EXT1' as SignatureNatureEnum,
    SUPRVEXT2: 'SIGNATURE_SUPRV_EXT2' as SignatureNatureEnum,
    SUPRVEXT3: 'SIGNATURE_SUPRV_EXT3' as SignatureNatureEnum,
  };
}
