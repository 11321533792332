import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiagramDTO, FlowDiagramDTO, PatchDocumentDiagramRequestDTO } from '../generated/models/models';
import { DiagramData, FlowDiagramData } from '../models/diagram/diagram-data';
import { BaseHttpService } from './base-http.service';
import { ApiUrlsEnum } from './helpers/api-url';
import { apiUrlMatcher } from './helpers/api-url-matcher';

@Injectable()
export class DiagramService {
  constructor(private baseHttpService: BaseHttpService) {}

  public get(projectUuidEntity: string): Observable<DiagramData> {
    return this.baseHttpService.get<DiagramDTO>(apiUrlMatcher(ApiUrlsEnum.DiagramByProject, { projectUuidEntity })).pipe(map(diagram => DiagramData.mapFromApiValue(diagram)));
  }

  public update(diagram: DiagramData): Observable<DiagramData> {
    const formData = new FormData();
    formData.append('body', new Blob([JSON.stringify(DiagramData.mapToUpdateDiagramRequest(diagram))], { type: 'application/json' }));

    return this.baseHttpService
      .post<DiagramDTO>(apiUrlMatcher(ApiUrlsEnum.Diagram, { diagramUuidEntity: diagram.uuidEntity }), { body: formData })
      .pipe(map(updatedDiagram => DiagramData.mapFromApiValue(updatedDiagram)));
  }

  public create(projectUuidEntity: string): Observable<DiagramData> {
    return this.baseHttpService
      .post<DiagramDTO>(apiUrlMatcher(ApiUrlsEnum.CreateDiagram), { body: { projectUuidEntity } })
      .pipe(map(diagram => DiagramData.mapFromApiValue(diagram)));
  }

  public getDiagramFlows(diagramUuidEntity: string): Observable<FlowDiagramData[]> {
    return this.baseHttpService
      .get<FlowDiagramDTO[]>(apiUrlMatcher(ApiUrlsEnum.DiagramFlows, { diagramUuidEntity }))
      .pipe(map(flows => (flows || []).map(flow => DiagramData.mapFlowDiagramFromApiValue(flow))));
  }

  public patchDocumentDiagramImage(documentUuidEntity: string, diagramImageUuidEntity: string): Observable<void> {
    const body: PatchDocumentDiagramRequestDTO = {
      diagramImageUuidEntity,
    };

    return this.baseHttpService.patch<void>(apiUrlMatcher(ApiUrlsEnum.UpdateDocumentDiagramImage, { documentUuidEntity }), { body });
  }
}
