import { FormImportRequestDTO } from '../../generated/models/models';
import { FormToImportSummaryData } from './form-to-import-summary';

export interface ImportFormsRequest {
  uuidEntityDest: string;
  formsUuidEntities?: string[];
  queries?: FormToImportSummaryData.QueryRequest;
  allSelected?: boolean;
  confirmed?: boolean;
}

export namespace ImportFormsRequest {
  export function mapImportFormsRequest(request: ImportFormsRequest): FormImportRequestDTO {
    return {
      confirmed: request.confirmed,
      uuidEntityDest: request.uuidEntityDest,
      formsUuidEntity: !request.allSelected ? request.formsUuidEntities || [] : undefined,
      pageFormRequest: request.allSelected ? request.queries && FormToImportSummaryData.mapToPageRequestApiValue(request.queries) : undefined,
    };
  }
}
