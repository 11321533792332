import { QrCodeRequestDTO, UserDeviceDetailResponseDTO } from '../../generated/models/models';

export interface UserQrCodeData {
  uniqueDeviceId: string;
  qrCode: string;
  uuidEntity: string;
  deviceUuidEntity: string;
}

export namespace UserQrCodeData {
  export interface QrCodeRequest {
    userUuidEntity: string;
    password: string;
  }

  export function mapFromApiValue(data: UserDeviceDetailResponseDTO): UserQrCodeData {
    return {
      uniqueDeviceId: data.uniqueDeviceId,
      qrCode: `data:image/png;base64,${data.qrCode}`,
      uuidEntity: data['uuidEntity'],
      deviceUuidEntity: data.deviceUuidEntity,
    };
  }

  export function mapToRequestApiValue(request: QrCodeRequest): QrCodeRequestDTO {
    return {
      rawPassword: request.password,
      userUuidEntity: request.userUuidEntity,
    };
  }
}
