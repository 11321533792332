import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FlowPgacDetailsModalTabEnum {
  General = 'general',
  Planning = 'planning',
  ToInform = 'toInform',
  ExportableValues = 'exportableValues',
}

export namespace FlowPgacDetailsModalTabEnum {
  export function getValues(): string[] {
    return [FlowPgacDetailsModalTabEnum.General, FlowPgacDetailsModalTabEnum.Planning, FlowPgacDetailsModalTabEnum.ToInform, FlowPgacDetailsModalTabEnum.ExportableValues];
  }

  const key = 'flows.modals.PgacDetails.tabs.';
  export const toString: SfxMap<FlowPgacDetailsModalTabEnum, string> = new SfxMap<FlowPgacDetailsModalTabEnum, string>([
    [FlowPgacDetailsModalTabEnum.General, key + 'general'],
    [FlowPgacDetailsModalTabEnum.Planning, key + 'planning'],
    [FlowPgacDetailsModalTabEnum.ToInform, key + 'toInform'],
    [FlowPgacDetailsModalTabEnum.ExportableValues, key + 'exportableValues'],
  ]);

  export const iconData: SfxMap<FlowPgacDetailsModalTabEnum, IconData> = new SfxMap<FlowPgacDetailsModalTabEnum, IconData>([
    [FlowPgacDetailsModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [FlowPgacDetailsModalTabEnum.Planning, { name: 'calendar-alt', prefix: 'fas' }],
    [FlowPgacDetailsModalTabEnum.ToInform, { name: 'edit', prefix: 'fas' }],
    [FlowPgacDetailsModalTabEnum.ExportableValues, { name: 'calendar-alt', prefix: 'fas' }],
  ]);
}
