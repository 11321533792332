import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../sfx-dialog/state/dialog-toaster-data';
import { FormQuestionRecap, FormSummaryDetails, MapConfigData } from '../../../../core/models';

export const loadFormRubrics = createAction('[sfxFormSummary] load form Rubrics');
export const loadFormRubricsSuccess = createAction('[sfxFormSummary] load form Rubrics success', props<{ formData: FormSummaryDetails }>());
export const loadFormRubricsFail = createAction('[sfxFormSummary] load form Rubrics fail', props<DialogToasterData>());

export const openFormSummaryFilterDatePopover = createAction('[sfxFormSummary] open form summary filter date popover', props<{ origin: HTMLElement }>());

export const loadRubricQuestions = createAction('[sfxFormSummary] load rubric questions', props<{ rubricUuidEntity: string }>());
export const loadRubricQuestionsSuccess = createAction('[sfxFormSummary] load rubric questions success', props<{ rubricUuidEntity: string; questions: FormQuestionRecap[] }>());
export const loadRubricQuestionsFail = createAction('[sfxFormSummary] load rubric questions fail', props<DialogToasterData>());

export const loadMapConfig = createAction('[sfxFormSummary] load form summary map config');
export const loadMapConfigSuccess = createAction('[sfxFormSummary] load form summary map config success', props<{ mapConfigData: MapConfigData }>());

export const previewFile = createAction('[sfxFormSummary] preview file', props<{ fileUuidEntity: string }>());
