import { createAction, props } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { DialogToasterData } from '../../../../../../sfx-dialog/state/dialog-toaster-data';
import { LazyLoadResponse, BlockDocumentToImportSummaryData, BlockStatusHistoryData, BlockPropertiesData, BlockDocumentSummaryData } from '../../../../../../../core/models';

import { BlockStatusEnum } from '../../../../../../../core/enums';
import { ApplicationFileForUpdate } from '../../../../../../../core/models/application-file/application-file-for-update';

// Block properties
export const getBlockProperties = createAction('[stepBlocksDialog] get block properties');
export const getBlockPropertiesSuccess = createAction('[stepBlocksDialog] get block properties success', props<{ blockProperties: BlockPropertiesData }>());
export const getBlockPropertiesFail = createAction('[stepBlocksDialog] get block properties fail', props<DialogToasterData>());

// Block validation
export const validateBlock = createAction('[dFormDialog] validate block', props<{ currentBlockStatus: BlockStatusEnum; newBlockStatus: BlockStatusEnum; comment?: string }>());
export const validateBlockFail = createAction('[dFormDialog] validate block fail', props<DialogToasterData>());

export const loadDocuments = createAction('[BlocksContentDialog] load documents');
export const loadDocumentsNextPage = createAction('[BlocksContentDialog] load documents next page');
export const loadDocumentsSuccess = createAction(
  '[BlocksContentDialog] load documents success',
  props<{ documents: BlockDocumentToImportSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadDocumentsFail = createAction('[BlocksContentDialog] load documents fail', props<DialogToasterData>());

export const linkDocuments = createAction('[BlocksContentDialog] link documents', props<{ documents: string[]; versions: Dictionary<string[]> }>());
export const linkDocumentsSuccess = createAction('[BlocksContentDialog] link documents success');
export const linkDocumentsFail = createAction('[BlocksContentDialog] link documents fail', props<DialogToasterData>());

export const loadStatusHistory = createAction('[BlocksContentDialog] load status history');
export const loadMoreStatusHistory = createAction('[BlocksContentDialog] load more status history');
export const loadStatusHistoryFail = createAction('[BlocksContentDialog] load status history fail', props<DialogToasterData>());
export const loadStatusHistorySuccess = createAction(
  '[BlocksContentDialog] load status history success',
  props<{ statusEvents: LazyLoadResponse<BlockStatusHistoryData[]>; reset?: boolean }>(),
);

export const refreshDocumentToImportData = createAction(
  '[BlockContent] refresh document to import data',
  props<{ documentData: BlockDocumentSummaryData; documentIndex: string }>(),
);

export const updateImage = createAction('[BlocksContentDialog] update image', props<ApplicationFileForUpdate>());
export const updateImageSuccess = createAction('[BlocksContentDialog] update image success');
export const updateImageFail = createAction('[BlocksContentDialog] update image fail', props<DialogToasterData>());
