import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { DateUtils } from '../../utils/date.utils';
import { LevelAccessApiEnum, StatusAccessApiEnum } from '../../enums';
import { ExternalApiConsumerPropertiesResponseDTO } from '../../generated/models/models';

export interface AccessApiV2PropertiesData {
  name: string;
  ssoIdentifier: string;
  applicability: EntityLightData;
  level: LevelAccessApiEnum;
  status: StatusAccessApiEnum;
  creationDate: string;
  modificationDate: string;
  createdBy: string;
  lastModifiedBy: string;
}

export namespace AccessApiV2PropertiesData {
  export function mapFromApiValue(properties: ExternalApiConsumerPropertiesResponseDTO): AccessApiV2PropertiesData {
    return {
      name: properties.name,
      level: LevelAccessApiEnum.mapFromApiValue.getValue(properties.serviceLevelClass),
      applicability: properties.applicability && LabelApplicabilityLightData.mapToEntityLightData(properties.applicability),
      status: StatusAccessApiEnum.mapFromApiValue.getValue(properties.status),
      ssoIdentifier: properties.ssoIdentifier,
      creationDate: DateUtils.toDateTimeFormat(properties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(properties.modificationDate),
      createdBy: properties.createdBy,
      lastModifiedBy: properties.lastModifiedBy,
    };
  }
}
