import { SfxMap } from '../../utils/enum-utils';
import { SchedulingStartUpdateRequestDTO } from '../../generated/models/schedulingStartUpdateRequestDTO';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum ScheduleStartConditionEnum {
  Time = 1,
  Status = 2,
  Usage = 3,
}

export namespace ScheduleStartConditionEnum {
  export const values = [ScheduleStartConditionEnum.Time, ScheduleStartConditionEnum.Status];

  export const convertFromApiValue: SfxMap<SchedulingStartUpdateRequestDTO.ConditionEnum, ScheduleStartConditionEnum> = new SfxMap<
    SchedulingStartUpdateRequestDTO.ConditionEnum,
    ScheduleStartConditionEnum
  >([
    [SchedulingStartUpdateRequestDTO.ConditionEnum.TIME, ScheduleStartConditionEnum.Time],
    [SchedulingStartUpdateRequestDTO.ConditionEnum.STATUS, ScheduleStartConditionEnum.Status],
    [SchedulingStartUpdateRequestDTO.ConditionEnum.USAGE, ScheduleStartConditionEnum.Usage],
  ]);

  export const convertToApiValue: SfxMap<ScheduleStartConditionEnum, SchedulingStartUpdateRequestDTO.ConditionEnum> = new SfxMap<
    ScheduleStartConditionEnum,
    SchedulingStartUpdateRequestDTO.ConditionEnum
  >([
    [ScheduleStartConditionEnum.Time, SchedulingStartUpdateRequestDTO.ConditionEnum.TIME],
    [ScheduleStartConditionEnum.Status, SchedulingStartUpdateRequestDTO.ConditionEnum.STATUS],
    [ScheduleStartConditionEnum.Usage, SchedulingStartUpdateRequestDTO.ConditionEnum.USAGE],
  ]);

  const key = 'schedule.table.condition.';

  export const toString: SfxMap<ScheduleStartConditionEnum, string> = new SfxMap<ScheduleStartConditionEnum, string>([
    [ScheduleStartConditionEnum.Time, key + 'time'],
    [ScheduleStartConditionEnum.Status, key + 'status'],
    [ScheduleStartConditionEnum.Usage, key + 'usage'],
  ]);

  export const comparePredicate = (node: SingleSelectItemData<ScheduleStartConditionEnum>, item: ScheduleStartConditionEnum) => node.value === item;

  export const selectedItemValues = values.map(priority => ({ value: priority, title: toString.getValue(priority) }));
}
