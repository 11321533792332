import dayjs from 'dayjs';

import { BusinessClassNameEnum, DateFrequencyEnum, FilterDateEnum, FilterDateNatureEnum, FilterTypeDateEnum, ProjectTypeEnum, ResponseStateEnum } from '../../enums';
import { DateFilterRequest } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { DateFilterFlowRequest } from '../../generated/models/dateFilterFlowRequest';

export interface DateFilterData {
  dateFilterBy: FilterDateEnum;
  typeDate: FilterTypeDateEnum;
  endDate?: Date;
  startDate?: Date;
  status?: ResponseStateEnum;
  frequency?: DateFrequencyEnum;
  filterNature?: FilterDateNatureEnum;
  dialogFilter?: boolean;
  secondaryDate?: boolean;
  projectType?: ProjectTypeEnum;
  hideFilterNature?: boolean;
  businessClassName?: BusinessClassNameEnum;
  dateTime?: boolean;
  hidePrefilling?: boolean;
  maxDate?: Date;
}

export namespace DateFilterData {
  export function mapToDateFilterData(startDate: string, endDate: string, dateFilterBy: string, typeDate: string, state?: string): DateFilterData {
    return (
      dateFilterBy && {
        dateFilterBy: FilterDateEnum.convertFromParamsValue.getValue(dateFilterBy),
        typeDate: FilterTypeDateEnum.convertFromParamsValue.getValue(typeDate),
        status: ResponseStateEnum.convertFromParamsValue.getValue(state),
        ...(isValidUnixDateString(endDate) && { endDate: dayjs.unix(+endDate).toDate() }),
        ...(isValidUnixDateString(startDate) && { startDate: dayjs.unix(+startDate).toDate() }),
      }
    );
  }

  export function mapToFrequencyDateFilter(startDate: string, endDate: string, frequency: string): DateFilterData {
    return (frequency &&
      (startDate || endDate) && {
        ...(isValidUnixDateString(endDate) && { endDate: dayjs.unix(+endDate).toDate() }),
        ...(isValidUnixDateString(startDate) && { startDate: dayjs.unix(+startDate).toDate() }),
        frequency: DateFrequencyEnum.convertFromParamsValue.getValue(frequency),
      }) as DateFilterData;
  }

  export function mapToRequestApiValue(value: DateFilterData): DateFilterRequest {
    return {
      dateFilterBy: FilterDateEnum.convertToApiValue.getValue(value.dateFilterBy),
      ...((value.endDate || value.startDate) && {
        ...(isValidDate(value.startDate) && { startDate: DateUtils.toZonedDateTime(dayjs(value.startDate).startOf('day').toDate()) }),
        ...(isValidDate(value.endDate) && { endDate: DateUtils.toZonedDateTime(dayjs(value.endDate).endOf('day').toDate()) }),
      }),
    };
  }

  function isValidUnixDateString(dateString: string): boolean {
    return dateString && isValidDate(dayjs.unix(+dateString).toDate());
  }

  function isValidDate(date: Date): boolean {
    return date && !isNaN(date.getTime());
  }

  export function mapToFlowRequestApiValue(value: DateFilterData): DateFilterFlowRequest {
    return {
      dateFilterBy: FilterDateEnum.convertToDateFilterFlowApiValue.getValue(value.dateFilterBy),
      ...(isValidDate(value.startDate) && { from: DateUtils.toZonedDateTime(dayjs(value.startDate).startOf('day').toDate()) }),
      ...(isValidDate(value.endDate) && { to: DateUtils.toZonedDateTime(dayjs(value.endDate).endOf('day').toDate()) }),
    };
  }
}
