/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CheckQuestionResponseDTO {
  name: string;
  nature: CheckQuestionResponseDTO.NatureEnum;
  icon: string;
  errorType: CheckQuestionResponseDTO.ErrorTypeEnum;
}
export namespace CheckQuestionResponseDTO {
  export type NatureEnum =
    | 'Text'
    | 'TextLong'
    | 'TextMultiple'
    | 'ValueUnit'
    | 'RadioSelect'
    | 'Contributor'
    | 'CheckBox'
    | 'File'
    | 'FileDocument'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'BooleanValue'
    | 'Localisation'
    | 'Formulas'
    | 'EnumSelection'
    | 'EnumMultipleSelections'
    | 'TextEditor';
  export const NatureEnum = {
    Text: 'Text' as NatureEnum,
    TextLong: 'TextLong' as NatureEnum,
    TextMultiple: 'TextMultiple' as NatureEnum,
    ValueUnit: 'ValueUnit' as NatureEnum,
    RadioSelect: 'RadioSelect' as NatureEnum,
    Contributor: 'Contributor' as NatureEnum,
    CheckBox: 'CheckBox' as NatureEnum,
    File: 'File' as NatureEnum,
    FileDocument: 'FileDocument' as NatureEnum,
    Date: 'Date' as NatureEnum,
    Time: 'Time' as NatureEnum,
    DateTime: 'DateTime' as NatureEnum,
    BooleanValue: 'BooleanValue' as NatureEnum,
    Localisation: 'Localisation' as NatureEnum,
    Formulas: 'Formulas' as NatureEnum,
    EnumSelection: 'EnumSelection' as NatureEnum,
    EnumMultipleSelections: 'EnumMultipleSelections' as NatureEnum,
    TextEditor: 'TextEditor' as NatureEnum,
  };
  export type ErrorTypeEnum = 'INVALID' | 'REQUIRED';
  export const ErrorTypeEnum = {
    INVALID: 'INVALID' as ErrorTypeEnum,
    REQUIRED: 'REQUIRED' as ErrorTypeEnum,
  };
}
