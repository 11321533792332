export enum ModalIdEnum {
  FlowDialog = 'flowDialog',
  PlanDialog = 'planDialog',
  ProjectDialog = 'projectDialog',
  TechnicalObjectDialog = 'technicalObjectDialog',
  EntityConflictDialog = 'entityConflictDialog',
  BigQr = 'bigQr',
  CollaboratorDialog = 'collaboratorDialog',
  TabletDialog = 'tabletDialog',
  CategoriesDialog = 'categoriesDialog',
  DefaultCategoriesDialog = 'defaultCategoriesDialog',
  AccessApisDialog = 'accessApisDialog',
  SettingsFamilyDialog = 'SettingsFamilyDialog',
  ClientDialog = 'ClientDialog',
  DashboardFormDialog = 'dashboardFormDialog',
  DashboardFieldFeedbackDialog = 'dashboardFieldFeedbackDialog',
  DashboardListDialog = 'dashboardListDialog',
  LibraryFormDialog = 'libraryFormDialog',
  LibraryDocumentDialog = 'libraryDocumentDialog',
  EmmDialog = 'emmDialog',
  CustomStatusDialog = 'customStatusDialog',
  CustomFieldDialog = 'customFieldDialog',
  ReferenceDialog = 'referenceDialog',
  FlowContentDialog = 'flowContentDialog',
  DuplicateDialog = 'duplicateDialog',
  DisableCommentDialog = 'disableCommentDialog',
  NavigateDialog = 'navigateDialog',
  LinkFlowDialog = 'linkFlowDialog',
  MoveDialog = 'moveDialog',
  EditAttributesDialog = 'editAttributesDialog',
  BlockDialog = 'blockDialog',
  BlockAdrDialog = 'blockAdrDialog',
  BlockEmmDialog = 'blockEmmDialog',
  BlockDsiDialog = 'blockDsiDialog',
  BlockRhpDialog = 'blockRhpDialog',
  BlockFormDialog = 'blockFormDialog',
  BlockObjectDialog = 'blockObjectDialog',
  ClosingFolderDialog = 'closingFolderDialog',
  ExecuteValidationDialog = 'executeValidationDialog',
  TramDialog = 'tramDialog',
  CustomFileDialog = 'customFileDialog',
  CustomCellDialog = 'customCellDialog',
  FolderDialog = 'folderDialog',
  WordingConfigDialog = 'wordingConfigDialog',
  BlockMultiPrDialog = 'blockMultiPrDialog',
  CreateFlowDialog = 'createFlowDialog',
  DashboardDocumentsDialog = 'documents',
  FormDialog = 'formDialog',
  ShareDialog = 'shareDialog',
  MentionDialog = 'mentionDialog',
  SignatureDialog = 'signatureDialog',
  DynamicVariableDialog = 'dynamicVariableDialog',
  TextEditorDialog = 'textEditorDialog',
  ResponseValidationDialog = 'responseValidationDialog',
  GenericParamsDialog = 'genericParamsDialog',
  ExternalUsersDialog = 'externalUsersDialog',
  AddScheduleDialog = 'addScheduleDialog',
  SchedulePropertiesDialog = 'schedulePropertiesDialog',
  ChangesConfirmationDialog = 'changesConfirmationDialog',
  SettingsLogsPushDialog = 'settingsLogsPushDialog',
  CompaniesDialog = 'companiesDialog',
}
