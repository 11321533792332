import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { PageElementEmmRequestDTO } from '../../generated/models/pageElementEmmRequestDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum FilterArchivedEnum {
  All = 'all',
  Activated = 'activated',
  Deactivated = 'deactivated',
}

export namespace FilterArchivedEnum {
  const values = [FilterArchivedEnum.All, FilterArchivedEnum.Activated, FilterArchivedEnum.Deactivated];

  const key = 'global.filter.';
  export const toString = new SfxMap<FilterArchivedEnum, string>([
    [FilterArchivedEnum.Activated, key + 'activated'],
    [FilterArchivedEnum.Deactivated, key + 'deactivated'],
    [FilterArchivedEnum.All, key + 'all'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, FilterArchivedEnum>([
    ['activated', FilterArchivedEnum.Activated],
    ['deactivated', FilterArchivedEnum.Deactivated],
    ['all', FilterArchivedEnum.All],
  ]);

  export const convertToApiValue = new SfxMap<FilterArchivedEnum, PageElementEmmRequestDTO.ArchiveStatusEnum>([
    [FilterArchivedEnum.All, PageElementEmmRequestDTO.ArchiveStatusEnum.ALL],
    [FilterArchivedEnum.Activated, PageElementEmmRequestDTO.ArchiveStatusEnum.UNARCHIVED],
    [FilterArchivedEnum.Deactivated, PageElementEmmRequestDTO.ArchiveStatusEnum.ARCHIVED],
  ]);

  export const convertToParamsValue = new SfxMap<FilterArchivedEnum, string>([
    [FilterArchivedEnum.Activated, 'activated'],
    [FilterArchivedEnum.Deactivated, 'deactivated'],
    [FilterArchivedEnum.All, 'all'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<FilterArchivedEnum>[]> =>
    of(
      values.map((value: FilterArchivedEnum) => ({
        value,
        title: toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FilterArchivedEnum>, item: FilterArchivedEnum) => node.value === item;
}
