import { RubricQuestionUpdateRequestDTO } from '../../generated/models/rubricQuestionUpdateRequestDTO';

export interface QuestionUpdateNameData {
  name: string;
  uuidEntity: string;
}

export namespace QuestionUpdateNameData {
  export function mapToRequestApiValue(updateRequest: QuestionUpdateNameData): RubricQuestionUpdateRequestDTO {
    return {
      uuidEntity: updateRequest.uuidEntity,
      name: updateRequest.name,
    };
  }
}
