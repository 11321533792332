import { Injectable, Injector } from '@angular/core';
import { Overlay, GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { ToasterComponent } from './toaster.component';
import { ToasterData, configData } from './toaster-config';
import { ToastOverlayRef } from './toast-overlay-ref';

@Injectable()
export class ToasterService {
  private lastToasterOverlayRef: ToastOverlayRef;

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  public show(data: ToasterData): ToastOverlayRef {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy, minWidth: '25rem' });
    this.lastToasterOverlayRef = new ToastOverlayRef(overlayRef);

    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [
        { provide: ToastOverlayRef, useValue: this.lastToasterOverlayRef },
        { provide: OverlayRef, useValue: overlayRef },
        { provide: ToasterData, useValue: data },
      ],
      name: 'overlayToasterRef',
    });
    const toastPortal = new ComponentPortal(ToasterComponent, null, injector);

    overlayRef.attach(toastPortal);

    return this.lastToasterOverlayRef;
  }

  private getPositionStrategy(): GlobalPositionStrategy {
    return this.overlay.position().global().top(this.getPosition()).right(`${configData.right}px`);
  }

  private getPosition(): string {
    const lastToasterElement = this.lastToasterOverlayRef && this.lastToasterOverlayRef.getOverlayElement();
    const position = lastToasterElement ? this.lastToasterOverlayRef.getPosition().bottom : configData.top;

    return `${position}px`;
  }
}
