import { RfiSummaryResponseDTO } from '../../generated/models/models';

import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';

import { DateUtils } from '../../utils/date.utils';

import { ClosingFolderLevelsEnum, DocumentStatusEnum } from '../../enums';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
export interface ClosingFolderSummaryData {
  uuidEntity: string;
  name: string;
  refIntern: string;
  docIndex: string;
  diffusionDate: string;
  childrenCount: number;
  family: LabelFamilyLightData;
  status: DocumentStatusEnum;
  level: ClosingFolderLevelsEnum;
  versionUuidEntity: string;
  disabled: boolean;
  lastVdf: DocumentVersionLightData;
  complementRefInt?: string;
  largeFile?: boolean;
  rfi?: boolean;
  customWording: CustomWordingsData;
}

export namespace ClosingFolderSummaryData {
  export function mapFromApiValue(folder: RfiSummaryResponseDTO): ClosingFolderSummaryData {
    return {
      uuidEntity: folder.uuidEntity,
      name: folder.name,
      refIntern: folder.refInt,
      docIndex: folder.indice,
      diffusionDate: folder.diffusionDate && DateUtils.toDateFormat(folder.diffusionDate),
      childrenCount: folder.sectionsCount,
      family: (folder.labelFamily && LabelFamilyLightData.mapFromApiValue(folder.labelFamily)) || LabelFamilyLightData.defaultData,
      status: DocumentStatusEnum.convertFromApiValue.getValue(folder.etat),
      level: ClosingFolderLevelsEnum.Folder,
      versionUuidEntity: folder.versionUuidEntity,
      disabled: folder.disabled,
      lastVdf: folder.lastVdf && DocumentVersionLightData.mapFromApiValue(folder.lastVdf),
      complementRefInt: folder.complementRefInt,
      largeFile: folder.sectionLargeFile,
      rfi: folder.sectionRfi,
      customWording: folder.customWording && CustomWordingsData.mapFromApiValue(folder.customWording),
    };
  }
}
