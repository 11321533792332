import { ElementEmmSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockEmmColumnEnum {
  Family = 'family',
  Name = 'name',
  NameReference = 'nameReference',
  Quantity = 'quantity',
  QuantityUnit = 'quantityUnit',
  Unit = 'unit',
  Reference = 'reference',
  Action = 'action',
  EmmAction = 'emmAction',
}

export namespace BlockEmmColumnEnum {
  export type equipmentBlockSortField = keyof ElementEmmSummaryResponseDTO;

  export const allColumns = [
    BlockEmmColumnEnum.Family,
    BlockEmmColumnEnum.Name,
    BlockEmmColumnEnum.Quantity,
    BlockEmmColumnEnum.Unit,
    BlockEmmColumnEnum.Reference,
    BlockEmmColumnEnum.Action,
  ];

  export const blockMobileAppEmmColumns = [BlockEmmColumnEnum.NameReference, BlockEmmColumnEnum.QuantityUnit, BlockEmmColumnEnum.EmmAction];

  const key = 'blocks.emm.table.header.';
  export const toString: SfxMap<BlockEmmColumnEnum, string> = new SfxMap<BlockEmmColumnEnum, string>([
    [BlockEmmColumnEnum.Family, key + 'family'],
    [BlockEmmColumnEnum.Name, key + 'name'],
    [BlockEmmColumnEnum.NameReference, key + 'name'],
    [BlockEmmColumnEnum.Quantity, key + 'quantity'],
    [BlockEmmColumnEnum.QuantityUnit, key + 'quantityUnit'],
    [BlockEmmColumnEnum.Unit, key + 'unit'],
    [BlockEmmColumnEnum.Reference, key + 'reference'],
    [BlockEmmColumnEnum.Action, key + 'action'],
    [BlockEmmColumnEnum.EmmAction, key + 'action'],
  ]);

  export const sortField: SfxMap<BlockEmmColumnEnum, equipmentBlockSortField> = new SfxMap<BlockEmmColumnEnum, equipmentBlockSortField>([
    [BlockEmmColumnEnum.Family, 'labelFamily'],
    [BlockEmmColumnEnum.Name, 'name'],
    [BlockEmmColumnEnum.Quantity, 'quantity'],
    [BlockEmmColumnEnum.Unit, 'unit'],
    [BlockEmmColumnEnum.Reference, 'reference'],
  ]);
}
