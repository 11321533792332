import { maxBy, cloneDeep } from 'lodash';
import { Dictionary } from '@ngrx/entity';

import { OptionSignatoryDetailsResponseDTO, RubricOptionDetailsResponseDTO, RubricQuestionDetailsResponseDTO } from '../../generated/models/models';
import { optionValueType, questionOptionResponseType } from './question-option-data';
import { TableCellData } from './table-cell-data';
import { TableHeaderCellData } from './table-header-cell-data';
import { QuestionTypeEnum } from '../../enums';

export const addNewLineBtn = 'addNewLineBtn';
export const firstColumnUuidEntity = 'first__column';
export const fakeColumnPrefix = '__fake__';

export interface TableDataSource {
  [key: string]: TableCellData<optionValueType> | TableHeaderCellData | string;
}

export namespace TableDataSource {
  export function mapToColumnTable(questions: RubricQuestionDetailsResponseDTO[]): { columns: TableHeaderCellData[]; rows: TableDataSource[]; lockedOptions: Dictionary<boolean> } {
    const rows: TableDataSource[] = [];
    const columns: TableHeaderCellData[] = questions.map(question => TableHeaderCellData.mapFromQuestionValue(question));
    const lockedOptions: Dictionary<boolean> = {};

    if (questions?.length) {
      const firstQuestion = TableDataSource.fixFirstQuestionOptionsLengthGap(questions);
      const firstQuestionOptions = firstQuestion.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor ? firstQuestion.optionSignatories : firstQuestion.rubricOptions;
      const nbRows = Array.from({ length: firstQuestionOptions?.length });

      questions.forEach(question =>
        [...(question.rubricOptions || []), ...(question.optionSignatories || [])].forEach(option => (lockedOptions[option.uuidEntity] = option.locked)),
      );

      nbRows.forEach((_, rowIndex) => {
        const row: TableDataSource = {};
        row[firstColumnUuidEntity] = TableCellData.mapFromApiValue(firstQuestionOptions[rowIndex] as questionOptionResponseType, columns[0]);

        questions.forEach((question, columnIndex) => {
          const questionOptions = question.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor ? question.optionSignatories : question.rubricOptions;
          row[question.uuidEntity] = questionOptions[rowIndex]
            ? TableCellData.mapFromApiValue(questionOptions[rowIndex] as questionOptionResponseType, columns[columnIndex])
            : ({ uuidEntity: `${fakeColumnPrefix}${rowIndex}${columnIndex}`, fake: true, parent: columns[columnIndex] } as TableCellData<string>);
        });

        rows.push(row);
      });
    }

    columns.unshift({ headerName: null, nodeOrder: undefined, type: null, uuidEntity: firstColumnUuidEntity });

    return { columns, rows, lockedOptions };
  }

  export function mapToRowTable(questions: RubricQuestionDetailsResponseDTO[]): { columns: TableHeaderCellData[]; rows: TableDataSource[]; lockedOptions: Dictionary<boolean> } {
    const rows: TableDataSource[] = [];
    const lockedOptions: Dictionary<boolean> = {};
    let columns: TableHeaderCellData[] = [];

    if (questions?.length) {
      const firstQuestion = TableDataSource.fixFirstQuestionOptionsLengthGap(questions);
      const firstQuestionOptions = firstQuestion.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor ? firstQuestion.optionSignatories : firstQuestion.rubricOptions;
      columns = (firstQuestionOptions || []).map((option: questionOptionResponseType) =>
        TableHeaderCellData.mapFromOptionValue(option, QuestionTypeEnum.convertFromApiValue.getValue(firstQuestion.type)),
      );

      questions.forEach(question =>
        [...(question.rubricOptions || []), ...(question.optionSignatories || [])].forEach(option => (lockedOptions[option.uuidEntity] = option.locked)),
      );

      questions.forEach((question, rowIndex) => {
        const row: TableDataSource = {};
        const questionOptions = question.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor ? question.optionSignatories : question.rubricOptions;

        row[firstColumnUuidEntity] = TableHeaderCellData.mapFromQuestionValue(question);
        row.color = question.backgroundColor;

        if (questionOptions?.length) {
          columns.forEach((column, columnIndex) => {
            row[column.uuidEntity] = questionOptions[columnIndex]
              ? TableCellData.mapFromApiValue(questionOptions[columnIndex] as questionOptionResponseType, TableHeaderCellData.mapFromQuestionValue(question))
              : ({ uuidEntity: `${fakeColumnPrefix}${rowIndex}${columnIndex}`, fake: true } as TableCellData<string>);
          });
        }

        rows.push(row);
      });
    }

    columns.unshift({ headerName: null, nodeOrder: undefined, type: null, uuidEntity: firstColumnUuidEntity });

    return { columns, rows, lockedOptions };
  }

  export function fixFirstQuestionOptionsLengthGap(questions: RubricQuestionDetailsResponseDTO[]): RubricQuestionDetailsResponseDTO {
    const firstQuestion = cloneDeep(questions[0]);
    const isFirstContributor = firstQuestion.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor;
    const firstQuestionOptions = isFirstContributor ? firstQuestion.optionSignatories : firstQuestion.rubricOptions;
    const longestQuestion = maxBy(questions, question =>
      question.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor ? question.optionSignatories.length : question.rubricOptions.length,
    );
    const isLongestContributor = longestQuestion.type === RubricQuestionDetailsResponseDTO.TypeEnum.Contributor;
    const longestQuestionOptions = isLongestContributor ? longestQuestion.optionSignatories : longestQuestion.rubricOptions;
    const firstLength = firstQuestionOptions.length;
    const longestLength = longestQuestionOptions.length;

    if (longestLength > firstLength) {
      if (isFirstContributor) {
        longestQuestionOptions.slice(firstLength, longestLength).forEach(option =>
          firstQuestion.optionSignatories.push({
            uuidEntity: option.uuidEntity,
            name: (!isLongestContributor && option.optionName) || option.name,
            optionOrder: option.optionOrder,
            description: option.description,
          } as OptionSignatoryDetailsResponseDTO),
        );
      } else {
        longestQuestionOptions.slice(firstLength, longestLength).forEach(option =>
          firstQuestion.rubricOptions.push({
            uuidEntity: option.uuidEntity,
            name: option.name,
            optionName: option.optionName,
            optionOrder: option.optionOrder,
            description: option.description,
          } as RubricOptionDetailsResponseDTO),
        );
      }
    }

    return firstQuestion;
  }
}
