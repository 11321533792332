import { evaluate } from 'mathjs';
import { LocalStorageEnum } from '../../../core/enums/local-storage.enum';
import { NumberFormatEnum } from '../../../core/enums/number-format.enum';

export class NumberFormatUtils {
  private static getFormatNumberLanguage(): NumberFormatEnum {
    return NumberFormatEnum.convertFromLocalStorageValue.getValue(localStorage.getItem(LocalStorageEnum.NumberFormat));
  }

  private static parseEnNumber(value: string): string {
    return value && value.replace(/,/gi, '');
  }

  private static parseFrNumber(value: string): string {
    return value && value.replace(/\s/gi, '').replace(/,/gi, '.');
  }

  /**
   * Parse format to common format number for backend
   * @param value - the value in fr-FR (1 123,99) or en-US (1,123.99) format
   * @return value formatted as (1123.99)
   */
  public static parseNumber(value: string): string {
    return this.getFormatNumberLanguage() === NumberFormatEnum.Fr ? this.parseFrNumber(value) : this.parseEnNumber(value);
  }

  /**
   * Format a number to fr-FR (1 123,99) or en-US (1,123.99)
   * No value rounding is done
   * @param value
   */
  public static formatNumber(value: string): string {
    if (isNaN(Number(+value))) {
      return value;
    }

    return this.formatLocalNumber(value);
  }

  private static formatLocalNumber(value: string): string {
    const [a, b] = value.toString().split('.');
    const formattedValue = (+a).toLocaleString(this.getFormatNumberLanguage());
    const decimalSeparator = (1.1).toLocaleString(this.getFormatNumberLanguage()).substring(1, 2);

    return b ? formattedValue + decimalSeparator + b : formattedValue;
  }

  /**
   * Get decimal character from the current format
   * fr-FR -> ","
   * en-US -> "."
   */
  public static getNumberFormatDecimal(): string {
    return NumberFormatEnum.decimalCharacter.getValue(this.getFormatNumberLanguage());
  }

  public static getTextInfo(): string {
    return NumberFormatEnum.textInfo.getValue(this.getFormatNumberLanguage());
  }

  public static customEvaluate(expression: string) {
    const result = evaluate(expression);

    // Fixing by displaying the last 9 digits after the decimal.
    // Like in the mobileApp. This "fix" JS float precision issues
    return typeof result === 'number' && result % 1 !== 0 ? parseFloat(result.toFixed(9)) : result;
  }
}
