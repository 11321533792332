import { Component, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SfxControlValueAccessor } from '../../accessors/control.accessor';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuDirective } from '../../directives/context-menu.directive';
import { MinDateTimeDirective } from '../../directives/min-date-time-validator.directive';
import { NumberControlDirective } from '../../directives/number-control.directive';
import { SfxTooltipDirective } from '../../directives/tooltip/sfx-tooltip.directive';
import { IconComponent } from '../icon/icon.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { KeyboardKeyEnum } from '../../../../core/enums';

@Component({
  selector: 'sfx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IconComponent,
    ClipboardModule,
    SfxTooltipDirective,
    ContextMenuDirective,
    NumberControlDirective,
    MinDateTimeDirective,
    CopyToClipboardComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends SfxControlValueAccessor<string> implements AfterViewInit, OnDestroy {
  @Input() type = 'text';
  @Input() placeholder: string;
  @Input() cssClass: string;
  @Input() sfxClass: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() readonly: boolean;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() pattern: string;
  @Input() iconName: string;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() min: string;
  @Input() max: string;
  @Input() step: number;
  @Input() isNumber: boolean;
  @Input() isDecimal: boolean;
  @Input() infoBlock = true;
  @Input() manualControl = false;
  @Input() invalid = false;
  @Input() highlightOnFilterActive = false;
  @Input() preferredFocus: number;
  @Input() contextMenu = false;
  @Input() copyToClipboard = false;

  @Output() iconToggled = new EventEmitter<void>();
  @Output() blur = new EventEmitter<string>();
  @Output() keyUp = new EventEmitter<HTMLElement>();
  @Output() trustedChange = new EventEmitter<string>();
  @ViewChild('input') sfxInput: ElementRef;

  @HostListener('focus', ['$event'])
  onFocusEvent(event: FocusEvent): void {
    this.focus(event);
  }

  @HostListener('input', ['$event'])
  @HostListener('keydown', ['$event'])
  onTrustedChange = () => this.trustedChanges.next();

  public readonly KeyboardKeyEnum = KeyboardKeyEnum;
  private trustedChanges = new Subject<void>();
  private subscription = new Subscription();

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.subscription = this.trustedChanges.pipe(debounceTime(500)).subscribe(() => this.trustedChange.emit(this.value));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeValue(): void {}
  onGetValue = (): string => null;

  // Note: focus input from outside...
  public focus(_?: FocusEvent): void {
    setTimeout(() => this.sfxInput && this.sfxInput.nativeElement.focus());
  }

  public pasteEvent(pasteData: string): void {
    this.value = pasteData;
    this.trustedChanges.next();
  }
}
