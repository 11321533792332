import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { CreateDocumentFlowProjectRequestDTO, DocumentFlowDetailsResponseDTO } from '../../generated/models/models';
import { OrganizationConfigDetails } from '../../models/organization-config/organization-config-details';
import { SfxMap } from '../../utils/enum-utils';
import { DocumentNatureEnum } from './document-nature.enum';

export enum DocumentSourceEnum {
  Flows = 1,
  Forms = 2,
  Modules = 3,
  Externs = 4,
}

export namespace DocumentSourceEnum {
  const values: DocumentSourceEnum[] = [DocumentSourceEnum.Flows, DocumentSourceEnum.Forms, DocumentSourceEnum.Modules, DocumentSourceEnum.Externs];

  export const convertFromApiValue: SfxMap<DocumentFlowDetailsResponseDTO.DocumentSourceEnum, DocumentSourceEnum> = new SfxMap<
    DocumentFlowDetailsResponseDTO.DocumentSourceEnum,
    DocumentSourceEnum
  >([
    [DocumentFlowDetailsResponseDTO.DocumentSourceEnum.EXTRENS, DocumentSourceEnum.Externs],
    [DocumentFlowDetailsResponseDTO.DocumentSourceEnum.FLOWS, DocumentSourceEnum.Flows],
    [DocumentFlowDetailsResponseDTO.DocumentSourceEnum.FORMS, DocumentSourceEnum.Forms],
    [DocumentFlowDetailsResponseDTO.DocumentSourceEnum.MODULES, DocumentSourceEnum.Modules],
  ]);

  export const convertToApiValue: SfxMap<DocumentSourceEnum, DocumentFlowDetailsResponseDTO.DocumentSourceEnum> = new SfxMap<
    DocumentSourceEnum,
    DocumentFlowDetailsResponseDTO.DocumentSourceEnum & CreateDocumentFlowProjectRequestDTO.DocumentSourceEnum
  >([
    [DocumentSourceEnum.Externs, DocumentFlowDetailsResponseDTO.DocumentSourceEnum.EXTRENS],
    [DocumentSourceEnum.Flows, DocumentFlowDetailsResponseDTO.DocumentSourceEnum.FLOWS],
    [DocumentSourceEnum.Forms, DocumentFlowDetailsResponseDTO.DocumentSourceEnum.FORMS],
    [DocumentSourceEnum.Modules, DocumentFlowDetailsResponseDTO.DocumentSourceEnum.MODULES],
  ]);

  const key = 'flowApp.dashboardTab.document.documentSource.';
  export const toString: SfxMap<DocumentSourceEnum, string> = new SfxMap<DocumentSourceEnum, string>(
    [
      [DocumentSourceEnum.Externs, key + 'EXTRENS'],
      [DocumentSourceEnum.Flows, key + 'FLOWS'],
      [DocumentSourceEnum.Forms, key + 'FORMS'],
      [DocumentSourceEnum.Modules, key + 'MODULES'],
    ],
    'documentSource.source',
  );

  // Add document tools
  export const iconData: SfxMap<DocumentSourceEnum, IconData> = new SfxMap<DocumentSourceEnum, IconData>([
    [DocumentSourceEnum.Flows, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [DocumentSourceEnum.Forms, { name: 'list-alt', prefix: 'far' }],
    [DocumentSourceEnum.Modules, { name: 'th-large', prefix: 'fas' }],
    [DocumentSourceEnum.Externs, { name: 'file-pdf', prefix: 'far' }],
  ]);

  const labelKey = 'document.addModal.source.label.';
  export const sourceLabel: SfxMap<DocumentSourceEnum, string> = new SfxMap<DocumentSourceEnum, string>([
    [DocumentSourceEnum.Externs, labelKey + 'extern'],
    [DocumentSourceEnum.Flows, labelKey + 'flow'],
    [DocumentSourceEnum.Forms, labelKey + 'form'],
    [DocumentSourceEnum.Modules, labelKey + 'module'],
  ]);

  const sourceCommentKey = 'document.addModal.source.comment.';
  export const sourceComment: SfxMap<DocumentSourceEnum, string> = new SfxMap<DocumentSourceEnum, string>([
    [DocumentSourceEnum.Externs, sourceCommentKey + 'extern'],
    [DocumentSourceEnum.Flows, sourceCommentKey + 'flow'],
    [DocumentSourceEnum.Forms, sourceCommentKey + 'form'],
    [DocumentSourceEnum.Modules, sourceCommentKey + 'module'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<DocumentSourceEnum>[]> =>
    of(
      values.map(type => ({
        value: type,
        title: sourceLabel.getValue(type),
        subtitle: sourceComment.getValue(type),
        icons: [iconData.getValue(type)],
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DocumentSourceEnum>, item: DocumentSourceEnum) => node.value === item;

  export const documentNature = (organizationConfig: OrganizationConfigDetails) =>
    new SfxMap<DocumentSourceEnum, DocumentNatureEnum[]>(
      [
        [DocumentSourceEnum.Flows, DocumentNatureEnum.flowTypes],
        [DocumentSourceEnum.Forms, DocumentNatureEnum.formTypes],
        [DocumentSourceEnum.Modules, DocumentNatureEnum.moduleTypes(organizationConfig)],
        [DocumentSourceEnum.Externs, DocumentNatureEnum.externTypes],
      ],
      DocumentNatureEnum.externTypes,
    );

  export const singleSelectDocumentNatureItemResolver = (
    source: DocumentSourceEnum,
    organizationConfig: OrganizationConfigDetails,
  ): Observable<SingleSelectItemData<DocumentNatureEnum>[]> =>
    of(
      (documentNature(organizationConfig).getValue(source) || []).map(nature => ({
        value: nature,
        title: DocumentNatureEnum.natureLabel.getValue(nature),
        subtitle: DocumentNatureEnum.natureComment.getValue(nature),
        icons: [DocumentNatureEnum.iconData.getValue(nature)],
      })),
    );
}
