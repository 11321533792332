import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import { initialOrphanResponsesState, orphanResponsesOns, OrphanResponsesState } from '../orphan-responses/state/orphan-responses.reducer';
import * as fromFlowContentDialogActions from './flow-content-dialog.actions';

import {
  BlockDocumentData,
  CustomFieldData,
  FlowDetailsData,
  FlowEventData,
  FlowSummaryData,
  FlowSummaryForStepData,
  LazyLoadResponse,
  PhaseDetailsData,
  RevisionEventSummaryData,
  RevisionSummaryData,
  SequencePhaseSummaryData,
  SequenceSummaryData,
  StepDetailsData,
  StepNavigationData,
} from '../../../../../core/models';

import { StepSequenceTypeEnum } from './../../../../../core/enums';

const adapter: EntityAdapter<FlowSummaryForStepData> = createEntityAdapter<FlowSummaryForStepData>({
  selectId: linkFlow => linkFlow.uuidEntity,
});
export interface PhaseDialogState {
  loading: boolean;
  phaseDetails: PhaseDetailsData;
  phaseCustomFields: CustomFieldData[];
  initialPhaseCustomFields: CustomFieldData[];
  initialPhaseDetails: PhaseDetailsData;
}
export interface StepDialogState {
  loading: boolean;
  stepDetails: StepDetailsData;
  stepCustomFields: CustomFieldData[];
  initialStepCustomFields: CustomFieldData[];
  initialStepDetails: StepDetailsData;
  nodeOrder?: number;
}
export interface StepNavigationDialogState {
  navigation: LazyLoadResponse<StepNavigationData[]>;
  reset: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  currentStepUuidEntity: string;
}

export interface RevisionEventsDialogState {
  revisionEvents: LazyLoadResponse<RevisionEventSummaryData[]>;
  selectedRevision: RevisionSummaryData;
  reset: boolean;
}

export interface SequenceDialogState {
  sequences: LazyLoadResponse<SequenceSummaryData[]>;
  selectedSequence: SequenceSummaryData;
  reset: boolean;
}

export interface SequencePhaseDialogState {
  sequencePhases: LazyLoadResponse<SequencePhaseSummaryData[]>;
  reset: boolean;
}

export interface LinkFlowDialogState extends EntityState<FlowSummaryForStepData> {
  loading: boolean;
  loadLink: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export interface ImportFlowDialogState extends EntityState<FlowSummaryData> {
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export interface FlowDialogState {
  flowDetails: FlowDetailsData;
  customFields: CustomFieldData[];
  initialFlowDetails: FlowDetailsData;
  initialCustomFields: CustomFieldData[];
  hasReferenceGenerated: boolean;
  flowDocumentBlocks: BlockDocumentData[];
  saveLoading: boolean;
  viewLoading: boolean;
}

export interface FlowHistoryEventsDialog {
  flowDetails: FlowDetailsData;
  loadingFlowDetails: boolean;
  events: LazyLoadResponse<FlowEventData[]>;
  loadingFlowEvents: boolean;
  reset: boolean;
}

export interface FlowContentDialogState {
  phaseState: PhaseDialogState;
  stepState: StepDialogState;
  stepNavigationState: StepNavigationDialogState;
  revisionState: RevisionEventsDialogState;
  sequenceState: SequenceDialogState;
  sequencePhaseState: SequencePhaseDialogState;
  linkFlowState: LinkFlowDialogState;
  importFlowState: ImportFlowDialogState;
  flowDialogState: FlowDialogState;
  flowHistoryEventsState: FlowHistoryEventsDialog;
  orphanResponses: OrphanResponsesState;
  loading: boolean;
}

const initialFlowDialogState = (): FlowDialogState =>
  adapter.getInitialState<FlowDialogState>({
    flowDetails: null,
    customFields: null,
    initialFlowDetails: null,
    initialCustomFields: null,
    hasReferenceGenerated: false,
    flowDocumentBlocks: null,
    saveLoading: false,
    viewLoading: false,
  });

export const initialLinkFlowDialogState = (): LinkFlowDialogState =>
  adapter.getInitialState<LinkFlowDialogState>({
    ids: [],
    entities: {},
    totalCount: undefined,
    filteredTotalCount: undefined,
    loading: false,
    loadLink: false,
    reset: false,
  });

export const initialImportFlowDialogState = (): ImportFlowDialogState =>
  adapter.getInitialState<ImportFlowDialogState>({
    ids: [],
    entities: {},
    totalCount: undefined,
    filteredTotalCount: undefined,
    loading: false,
    reset: false,
  });

export const initialStepNavigationDialogState = (): StepNavigationDialogState => ({
  navigation: {} as LazyLoadResponse<StepNavigationData[]>,
  updateLoading: false,
  deleteLoading: false,
  currentStepUuidEntity: null,
  reset: false,
});

export const initialRevisionDialogState = (): RevisionEventsDialogState => ({
  revisionEvents: { filteredTotalCount: undefined } as LazyLoadResponse<RevisionEventSummaryData[]>,
  selectedRevision: null,
  reset: false,
});

export const initialSequenceDialogState = (): SequenceDialogState => ({
  sequences: { filteredTotalCount: undefined } as LazyLoadResponse<SequenceSummaryData[]>,
  selectedSequence: null,
  reset: false,
});

export const initialSequencePhaseDialogState = (): SequencePhaseDialogState => ({
  sequencePhases: { filteredTotalCount: undefined } as LazyLoadResponse<SequencePhaseSummaryData[]>,
  reset: false,
});

export const initialPhaseDialogState = (): PhaseDialogState => ({
  loading: false,
  phaseDetails: { stepSequenceType: StepSequenceTypeEnum.Linear } as PhaseDetailsData,
  initialPhaseDetails: null,
  phaseCustomFields: null,
  initialPhaseCustomFields: null,
});

export const initialStepDialogState = (): StepDialogState => ({
  stepDetails: {
    pr: true,
    dsi: true,
    form: true,
  } as StepDetailsData,
  initialStepDetails: null,
  loading: false,
  stepCustomFields: null,
  initialStepCustomFields: null,
});

export const initialFlowHistoryEventsDialogState = (): FlowHistoryEventsDialog => ({
  events: {} as LazyLoadResponse<FlowEventData[]>,
  flowDetails: undefined,
  loadingFlowDetails: false,
  loadingFlowEvents: false,
  reset: false,
});

export const initialFlowContentDialogState = (): FlowContentDialogState => ({
  phaseState: initialPhaseDialogState(),
  stepState: initialStepDialogState(),
  stepNavigationState: initialStepNavigationDialogState(),
  revisionState: initialRevisionDialogState(),
  sequenceState: initialSequenceDialogState(),
  sequencePhaseState: initialSequencePhaseDialogState(),
  linkFlowState: initialLinkFlowDialogState(),
  importFlowState: initialImportFlowDialogState(),
  flowDialogState: initialFlowDialogState(),
  flowHistoryEventsState: initialFlowHistoryEventsDialogState(),
  orphanResponses: initialOrphanResponsesState(),
  loading: false,
});

const reducer = createReducer<FlowContentDialogState>(
  initialFlowContentDialogState(),
  ...orphanResponsesOns,
  /* Flow content actions */
  on(fromFlowContentDialogActions.validateBlocks, (state): FlowContentDialogState => ({ ...state, loading: true })),
  on(fromFlowContentDialogActions.validateBlocksSuccess, fromFlowContentDialogActions.validateBlocksFail, (state): FlowContentDialogState => ({ ...state, loading: false })),
  /* Phase actions */
  on(
    fromFlowContentDialogActions.loadPhaseDetailsSuccess,
    (state, { phase }): FlowContentDialogState => ({
      ...state,
      phaseState: { ...state.phaseState, phaseDetails: phase, initialPhaseDetails: cloneDeep(phase), loading: false },
    }),
  ),
  on(fromFlowContentDialogActions.updatePhase, (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: true } })),
  on(fromFlowContentDialogActions.updatePhaseFail, (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: false } })),
  on(
    fromFlowContentDialogActions.updatePhaseSuccess,
    (state, { phase }): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, phaseDetails: phase, initialPhaseDetails: cloneDeep(phase), loading: false } }),
  ),
  on(fromFlowContentDialogActions.createPhase, (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: true } })),
  on(fromFlowContentDialogActions.createPhaseFail, (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: false } })),
  on(
    fromFlowContentDialogActions.createPhaseSuccess,
    (state): FlowContentDialogState => ({
      ...state,
      phaseState: { ...initialPhaseDialogState(), phaseDetails: { stepSequenceType: state.phaseState.phaseDetails.stepSequenceType } as PhaseDetailsData },
    }),
  ),
  on(
    fromFlowContentDialogActions.duplicatePhase,
    fromFlowContentDialogActions.movePhase,
    (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: true } }),
  ),
  on(
    fromFlowContentDialogActions.duplicatePhaseSuccess,
    fromFlowContentDialogActions.duplicatePhaseFail,
    fromFlowContentDialogActions.movePhaseSuccess,
    fromFlowContentDialogActions.movePhaseFail,
    (state): FlowContentDialogState => ({ ...state, phaseState: { ...state.phaseState, loading: false } }),
  ),
  on(
    fromFlowContentDialogActions.loadPhaseCustomFieldsSuccess,
    (state, { customFields }): FlowContentDialogState => ({
      ...state,
      phaseState: { ...state.phaseState, phaseCustomFields: customFields, initialPhaseCustomFields: cloneDeep(customFields) },
    }),
  ),
  /* Step actions */
  on(
    fromFlowContentDialogActions.loadStepDetailsSuccess,
    (state, { step }): FlowContentDialogState => ({
      ...state,
      stepState: { ...state.stepState, stepDetails: step, initialStepDetails: cloneDeep(step), loading: false },
    }),
  ),
  on(fromFlowContentDialogActions.updateStep, (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: true } })),
  on(fromFlowContentDialogActions.updateStepFail, (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: false } })),
  on(
    fromFlowContentDialogActions.updateStepSuccess,
    (state, { step }): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, stepDetails: step, initialStepDetails: cloneDeep(step), loading: false } }),
  ),
  on(fromFlowContentDialogActions.createStep, (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: true } })),
  on(fromFlowContentDialogActions.createStepFail, (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: false } })),
  on(
    fromFlowContentDialogActions.createStepSuccess,
    (state, { addNewOne }): FlowContentDialogState => ({
      ...state,
      stepState: addNewOne ? initialStepDialogState() : { ...state.stepState, initialStepDetails: cloneDeep(state.stepState.stepDetails), loading: false },
    }),
  ),
  on(
    fromFlowContentDialogActions.duplicateStep,
    fromFlowContentDialogActions.moveStep,
    (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: true } }),
  ),
  on(
    fromFlowContentDialogActions.duplicateStepSuccess,
    fromFlowContentDialogActions.duplicateStepFail,
    fromFlowContentDialogActions.moveStepSuccess,
    fromFlowContentDialogActions.moveStepFail,
    (state): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, loading: false } }),
  ),
  on(
    fromFlowContentDialogActions.toggleStepSuccess,
    (state, { disable, mobileAppReadOnly }): FlowContentDialogState => {
      const stepDetails = state.stepState.stepDetails;

      return {
        ...state,
        stepState: {
          ...state.stepState,
          stepDetails: {
            ...stepDetails,
            disabled: disable ?? stepDetails.disabled,
            mobileAppReadOnly: mobileAppReadOnly ?? stepDetails.mobileAppReadOnly,
          },
          initialStepDetails: cloneDeep({
            ...stepDetails,
            disabled: disable ?? stepDetails.disabled,
            mobileAppReadOnly: mobileAppReadOnly ?? stepDetails.mobileAppReadOnly,
          }),
        },
      };
    },
  ),
  on(
    fromFlowContentDialogActions.loadStepCustomFieldsSuccess,
    (state, { customFields }): FlowContentDialogState => ({
      ...state,
      stepState: { ...state.stepState, stepCustomFields: customFields, initialStepCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromFlowContentDialogActions.togglePhaseSuccess,
    (state, { disable, mobileAppReadOnly }): FlowContentDialogState => {
      const phaseDetails = state.phaseState.phaseDetails;

      return {
        ...state,
        phaseState: {
          ...state.phaseState,
          phaseDetails: {
            ...phaseDetails,
            disabled: disable ?? phaseDetails.disabled,
            mobileAppReadOnly: mobileAppReadOnly ?? phaseDetails.mobileAppReadOnly,
          },
          initialPhaseDetails: cloneDeep({
            ...phaseDetails,
            disabled: disable ?? phaseDetails.disabled,
            mobileAppReadOnly: mobileAppReadOnly ?? phaseDetails.mobileAppReadOnly,
          }),
        },
      };
    },
  ),
  /* Step navigation actions */
  on(
    fromFlowContentDialogActions.loadStepNavigationSuccess,
    (state, { navigation, reset }): FlowContentDialogState => ({
      ...state,
      stepNavigationState: {
        ...state.stepNavigationState,
        navigation: reset ? navigation : { ...state.stepNavigationState.navigation, payload: [...state.stepNavigationState.navigation.payload, ...navigation.payload] },
        reset,
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.addNewStepNavigation,
    (state, { navigation }): FlowContentDialogState => ({
      ...state,
      stepNavigationState: {
        ...state.stepNavigationState,
        navigation: {
          ...state.stepNavigationState.navigation,
          filteredTotalCount: state.stepNavigationState.navigation.filteredTotalCount + 1,
          totalCount: state.stepNavigationState.navigation.totalCount + 1,
          payload: [...state.stepNavigationState.navigation.payload, navigation],
        },
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.deleteStepNavigation,
    (state, { uuidEntity }): FlowContentDialogState => ({
      ...state,
      stepNavigationState: { ...state.stepNavigationState, deleteLoading: true, currentStepUuidEntity: uuidEntity },
    }),
  ),
  on(
    fromFlowContentDialogActions.deleteStepNavigationFail,
    fromFlowContentDialogActions.deleteStepNavigationSuccess,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, deleteLoading: false } }),
  ),
  on(
    fromFlowContentDialogActions.createStepNavigation,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, updateLoading: true } }),
  ),
  on(
    fromFlowContentDialogActions.createStepNavigationFail,
    fromFlowContentDialogActions.createStepNavigationSuccess,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, updateLoading: false } }),
  ),
  on(
    fromFlowContentDialogActions.updateStepNavigation,
    (state, { navigation }): FlowContentDialogState => ({
      ...state,
      stepNavigationState: { ...state.stepNavigationState, updateLoading: true, currentStepUuidEntity: navigation.uuidEntity },
    }),
  ),
  on(
    fromFlowContentDialogActions.updateStepNavigationFail,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, updateLoading: false } }),
  ),
  on(
    fromFlowContentDialogActions.updateStepNavigationSuccess,
    (state, { navigation }): FlowContentDialogState => ({
      ...state,
      stepNavigationState: {
        ...state.stepNavigationState,
        updateLoading: false,
        navigation: {
          ...state.stepNavigationState.navigation,
          payload: state.stepNavigationState.navigation.payload.map(_navigation => (_navigation.uuidEntity === navigation.uuidEntity ? { ...navigation } : _navigation)),
        },
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.moveStepNavigation,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, updateLoading: true } }),
  ),
  on(
    fromFlowContentDialogActions.moveStepNavigationFail,
    (state): FlowContentDialogState => ({ ...state, stepNavigationState: { ...state.stepNavigationState, updateLoading: false } }),
  ),
  on(fromFlowContentDialogActions.loadLinkFlowsList, (state): FlowContentDialogState => ({ ...state, linkFlowState: { ...state.linkFlowState, loading: true } })),
  on(fromFlowContentDialogActions.loadLinkFlowsListFail, (state): FlowContentDialogState => ({ ...state, linkFlowState: { ...state.linkFlowState, loading: false } })),
  on(
    fromFlowContentDialogActions.loadLinkFlowsListSuccess,
    (state, { linkFlows, totalCount, filteredTotalCount, reset }): FlowContentDialogState => {
      const newLinkFlowState: LinkFlowDialogState = { ...state.linkFlowState, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? adapter.setAll(linkFlows, newLinkFlowState) : adapter.addMany(linkFlows, newLinkFlowState);

      return { ...state, linkFlowState: newState };
    },
  ),

  on(fromFlowContentDialogActions.linkFlow, (state): FlowContentDialogState => ({ ...state, linkFlowState: { ...state.linkFlowState, loadLink: true } })),
  on(
    fromFlowContentDialogActions.linkFlowFail,
    fromFlowContentDialogActions.linkFlowSuccess,
    (state): FlowContentDialogState => ({ ...state, linkFlowState: { ...state.linkFlowState, loadLink: false } }),
  ),
  on(fromFlowContentDialogActions.resetImportFlowList, (state): FlowContentDialogState => ({ ...state, importFlowState: { ...initialImportFlowDialogState() } })),
  on(fromFlowContentDialogActions.loadImportFlowsList, (state): FlowContentDialogState => ({ ...state, importFlowState: { ...state.importFlowState, loading: true } })),
  on(fromFlowContentDialogActions.loadImportFlowsListFail, (state): FlowContentDialogState => ({ ...state, importFlowState: { ...state.importFlowState, loading: false } })),
  on(
    fromFlowContentDialogActions.loadImportFlowsListSuccess,
    (state, { flows, totalCount, filteredTotalCount, reset }): FlowContentDialogState => {
      const newImportFlowState: ImportFlowDialogState = { ...state.importFlowState, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? adapter.setAll(flows, newImportFlowState) : adapter.addMany(flows, newImportFlowState);

      return { ...state, importFlowState: newState };
    },
  ),
  on(fromFlowContentDialogActions.importFlow, (state): FlowContentDialogState => ({ ...state, importFlowState: { ...state.importFlowState, loading: true } })),
  on(
    fromFlowContentDialogActions.importFlowFail,
    fromFlowContentDialogActions.importFlowSuccess,
    (state): FlowContentDialogState => ({ ...state, importFlowState: { ...state.importFlowState, loading: false } }),
  ),
  on(fromFlowContentDialogActions.updateFlow, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowContentDialogActions.updateFlowFail, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowContentDialogActions.updateFlowSuccess, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowContentDialogActions.getFlowDetailsSuccess,
    (state, { flowDetails }): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDetails } }),
  ),
  on(fromFlowContentDialogActions.saveFlowCustomFields, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowContentDialogActions.saveFlowCustomFieldsFail, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowContentDialogActions.saveFlowCustomFieldsSuccess,
    (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } }),
  ),
  on(fromFlowContentDialogActions.loadFlowCustomFields, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowContentDialogActions.loadFlowCustomFieldsFail, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowContentDialogActions.loadFlowCustomFieldsSuccess,
    (state, { customFields }): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, customFields, saveLoading: false } }),
  ),
  on(
    fromFlowContentDialogActions.initializeFlowDetails,
    (state, { flowDetails }): FlowContentDialogState => ({
      ...state,
      flowDialogState: { ...state.flowDialogState, initialFlowDetails: cloneDeep(flowDetails) },
    }),
  ),
  on(
    fromFlowContentDialogActions.initializeFlowCustomFields,
    (state, { customFields }): FlowContentDialogState => ({
      ...state,
      flowDialogState: { ...state.flowDialogState, initialCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromFlowContentDialogActions.checkReferenceGeneratedSuccess,
    (state, { hasReferenceGenerated }): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, hasReferenceGenerated } }),
  ),
  on(
    fromFlowContentDialogActions.loadFlowDocumentsSuccess,
    (state, { flowDocumentBlocks }): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDocumentBlocks } }),
  ),
  on(fromFlowContentDialogActions.generateFlowDocuments, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(
    fromFlowContentDialogActions.generateFlowDocumentsSuccess,
    (state, { flowDocuments }): FlowContentDialogState => {
      const newFlowDocumentBlocks = state.flowDialogState.flowDocumentBlocks;
      newFlowDocumentBlocks.map(block => {
        const newDocument = flowDocuments.find(document => document.nature === block.documentNature);
        if (newDocument) {
          block.generated = true;
          block.uuidEntity = newDocument.uuidEntity;
          block.labelFamily = newDocument.labelFamilyApplication;
          block.versionUuidEntity = newDocument.versionUuidEntity;
        }

        return block;
      });

      return { ...state, flowDialogState: { ...state.flowDialogState, flowDocumentBlocks: newFlowDocumentBlocks, saveLoading: false } };
    },
  ),
  on(fromFlowContentDialogActions.generateFlowDocumentsFail, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(fromFlowContentDialogActions.viewFlowContentDocument, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: true } })),
  on(
    fromFlowContentDialogActions.viewFlowContentDocumentFail,
    (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: false } }),
  ),
  on(
    fromFlowContentDialogActions.viewFlowContentDocumentSuccess,
    (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, viewLoading: false } }),
  ),
  on(fromFlowContentDialogActions.addFlow, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: true } })),
  on(fromFlowContentDialogActions.addFlowFail, (state): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, saveLoading: false } })),
  on(
    fromFlowContentDialogActions.addFlowSuccess,
    (state, { flowDetails }): FlowContentDialogState => ({ ...state, flowDialogState: { ...state.flowDialogState, flowDetails, saveLoading: false } }),
  ),
  /* Revision */
  on(
    fromFlowContentDialogActions.loadRevisionEventsSuccess,
    (state, { revisionEvents, reset }): FlowContentDialogState => ({
      ...state,
      revisionState: {
        ...state.revisionState,
        revisionEvents: reset ? revisionEvents : { ...state.revisionState.revisionEvents, payload: [...state.revisionState.revisionEvents.payload, ...revisionEvents.payload] },
        reset,
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.findRevisionSuccess,
    (state, { revision }): FlowContentDialogState => ({ ...state, revisionState: { ...state.revisionState, selectedRevision: revision } }),
  ),
  on(
    fromFlowContentDialogActions.loadSequencePhasesSuccess,
    (state, { sequencePhases, reset }): FlowContentDialogState => ({
      ...state,
      sequencePhaseState: {
        ...state.sequenceState,
        sequencePhases: reset
          ? sequencePhases
          : { ...state.sequencePhaseState.sequencePhases, payload: [...state.sequencePhaseState.sequencePhases.payload, ...sequencePhases.payload] },
        reset,
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.deleteSequenceSuccess,
    (state): FlowContentDialogState => ({
      ...state,
      sequencePhaseState: initialSequencePhaseDialogState(),
      sequenceState: initialSequenceDialogState(),
    }),
  ),
  on(
    fromFlowContentDialogActions.changeSequence,
    (state, { sequence }): FlowContentDialogState => ({ ...state, sequenceState: { ...state.sequenceState, selectedSequence: sequence } }),
  ),
  on(fromFlowContentDialogActions.setNodeOrder, (state, { nodeOrder }): FlowContentDialogState => ({ ...state, stepState: { ...state.stepState, nodeOrder } })),
  on(
    fromFlowContentDialogActions.getFlowDetailsForHistory,
    (state): FlowContentDialogState => ({ ...state, flowHistoryEventsState: { ...state.flowHistoryEventsState, loadingFlowDetails: true } }),
  ),
  on(
    fromFlowContentDialogActions.getFlowDetailsForHistorySuccess,
    (state, { flowDetails }): FlowContentDialogState => ({ ...state, flowHistoryEventsState: { ...state.flowHistoryEventsState, flowDetails, loadingFlowDetails: false } }),
  ),
  on(
    fromFlowContentDialogActions.getFlowDetailsForHistoryFail,
    (state): FlowContentDialogState => ({ ...state, flowHistoryEventsState: { ...state.flowHistoryEventsState, loadingFlowDetails: false } }),
  ),
  on(
    fromFlowContentDialogActions.getViewHistory,
    (state): FlowContentDialogState => ({ ...state, flowHistoryEventsState: { ...state.flowHistoryEventsState, loadingFlowEvents: true } }),
  ),
  on(
    fromFlowContentDialogActions.getViewHistorySuccess,
    (state, { events, reset }): FlowContentDialogState => ({
      ...state,
      flowHistoryEventsState: {
        ...state.flowHistoryEventsState,
        loadingFlowEvents: false,
        reset,
        events: reset ? events : { ...state.flowHistoryEventsState.events, payload: [...state.flowHistoryEventsState.events.payload, ...events.payload] },
      },
    }),
  ),
  on(
    fromFlowContentDialogActions.getViewHistoryFail,
    (state): FlowContentDialogState => ({ ...state, flowHistoryEventsState: { ...state.flowHistoryEventsState, loadingFlowEvents: false } }),
  ),
);

export function flowContentDialogReducer(state: FlowContentDialogState | undefined, action: Action): FlowContentDialogState {
  return reducer(state, action);
}

export const selectAllLinkFlows = adapter.getSelectors().selectAll;
export const selectFlowsToImport = adapter.getSelectors().selectAll;
