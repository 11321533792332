import { OptionSignatoryDetailsResponseDTO } from '../../generated/models/optionSignatoryDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum ContributorTypeEnum {
  Extern = 1,
  Intern = 2,
  NotListedIntern = 3,
}

export namespace ContributorTypeEnum {
  export const convertFromApiValue: SfxMap<OptionSignatoryDetailsResponseDTO.TypeContributorEnum, ContributorTypeEnum> = new SfxMap<
    OptionSignatoryDetailsResponseDTO.TypeContributorEnum,
    ContributorTypeEnum
  >([
    [OptionSignatoryDetailsResponseDTO.TypeContributorEnum.INTERN, ContributorTypeEnum.Intern],
    [OptionSignatoryDetailsResponseDTO.TypeContributorEnum.EXTERN, ContributorTypeEnum.Extern],
    [OptionSignatoryDetailsResponseDTO.TypeContributorEnum.NOLISTEDINTERN, ContributorTypeEnum.NotListedIntern],
  ]);

  export const convertToApiValue: SfxMap<ContributorTypeEnum, OptionSignatoryDetailsResponseDTO.TypeContributorEnum> = new SfxMap<
    ContributorTypeEnum,
    OptionSignatoryDetailsResponseDTO.TypeContributorEnum
  >([
    [ContributorTypeEnum.Intern, OptionSignatoryDetailsResponseDTO.TypeContributorEnum.INTERN],
    [ContributorTypeEnum.Extern, OptionSignatoryDetailsResponseDTO.TypeContributorEnum.EXTERN],
    [ContributorTypeEnum.NotListedIntern, OptionSignatoryDetailsResponseDTO.TypeContributorEnum.NOLISTEDINTERN],
  ]);
}
