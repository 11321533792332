import { CanActivateFn, Router } from '@angular/router';
import { RouterPaths } from '../../../core/constant/route.constant';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { BaseHttpService } from '../../../core/services/base-http.service';
import { SessionService } from '../services/session.service';

export const SessionGuard: CanActivateFn = (route, _state): Observable<boolean> => {
  const sessionService = inject(SessionService);
  const router = inject(Router);
  const idToken = inject(BaseHttpService).getJwtAccessToken();
  if (!idToken) {
    router.navigate([RouterPaths.EntryPaths.loginPath], { replaceUrl: true });

    return of(false);
  }

  return sessionService.checkTokenValidity({ body: { idToken } }).pipe(
    tap(hasValidToken => {
      if (_state.url === '/') {
        router.navigate([hasValidToken ? RouterPaths.RootPaths.mainPath : RouterPaths.EntryPaths.loginPath], { replaceUrl: true });
      }
    }),
  );
};
