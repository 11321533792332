import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';
import { ErrorResponseData, ProjectSummaryData } from '../../../../core/models';
import * as fromProjectActions from './projects.actions';

const adapter: EntityAdapter<ProjectSummaryData> = createEntityAdapter<ProjectSummaryData>({
  selectId: project => project.uuidEntity,
});

export interface ProjectsState extends EntityState<ProjectSummaryData> {
  ids: string[];
  loading: boolean;
  totalCount: number;
  filteredTotalCount: number;
  error: ErrorResponseData;
  reset: boolean;
  folderExists: boolean;
  focusedProject: string;
  focusedFlow: string;
}

export const projectsInitialState: ProjectsState = adapter.getInitialState<ProjectsState>({
  ids: [],
  entities: {},
  loading: false,
  totalCount: undefined,
  filteredTotalCount: undefined,
  error: null,
  reset: false,
  folderExists: false,
  focusedProject: undefined,
  focusedFlow: undefined,
});

const _projectsReducer = createReducer<ProjectsState>(
  projectsInitialState,
  on(fromProjectActions.loadProjectList, (state): ProjectsState => ({ ...state, loading: true })),
  on(
    fromProjectActions.loadProjectListSuccess,
    (state, { projects, totalCount, filteredTotalCount, reset }): ProjectsState => {
      const newState = {
        ...state,
        totalCount,
        filteredTotalCount,
        reset,
        loading: false,
      };

      return reset ? adapter.setAll(projects, newState) : adapter.addMany(projects, newState);
    },
  ),
  on(fromProjectActions.loadProjectListFail, (state, { error }): ProjectsState => ({ ...state, ...error, loading: false })),
  on(fromProjectActions.deleteProjectFail, (state, { error }): ProjectsState => ({ ...state, ...error })),
  on(fromProjectActions.checkFolderExistence, (state, { exist }): ProjectsState => ({ ...state, folderExists: exist })),
  on(fromProjectActions.focusProject, (state, { projectUuidEntity }): ProjectsState => ({ ...state, focusedProject: projectUuidEntity })),
);

export function projectsReducer(state: ProjectsState | undefined, action: Action): ProjectsState {
  return _projectsReducer(state, action);
}

export const selectAllProjects = adapter.getSelectors().selectAll;
export const entityTotal = adapter.getSelectors().selectTotal;
