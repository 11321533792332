import { UpdateFlowReferentialRequestDTO } from '../../generated/models/models';

export interface FlowRefForUpdate {
  uuidEntity: string;
  confirmed?: boolean;
}

export namespace FlowRefForUpdate {
  export function mapToRequestApiValue(flow: FlowRefForUpdate): UpdateFlowReferentialRequestDTO {
    return {
      uuidEntity: flow.uuidEntity,
      confirmed: flow.confirmed,
    };
  }
}
