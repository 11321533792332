import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserLightData } from '../user/user-light-data';
import { FormPropsResponseDTO } from '../../generated/models/models';
import { FormNatureEnum } from '../../enums';
import { FlowSummaryData } from '../flows/flow-summary';
import { DateUtils } from '../../utils/date.utils';

export interface FormPropertiesData {
  name: string;
  nature: FormNatureEnum;
  family: EntityLightData;
  applicabilityName: string;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
  flows: FlowSummaryData[];
}

export namespace FormPropertiesData {
  export function mapFromApiValue(formProperties: FormPropsResponseDTO): FormPropertiesData {
    return {
      name: formProperties.name,
      nature: formProperties.nature && FormNatureEnum.convertFromApiValue.getValue(formProperties.nature),
      family: formProperties.family && LabelFamilyLightData.mapToEntityLightData(formProperties.family),
      applicabilityName: formProperties.applicabilityName,
      creationDate: DateUtils.toDateTimeFormat(formProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(formProperties.modifcationDate),
      createdBy: formProperties.createdBy && UserLightData.mapFromApiValue(formProperties.createdBy),
      lastModifiedBy: formProperties.lastModifiedBy && UserLightData.mapFromApiValue(formProperties.lastModifiedBy),
      flows: formProperties.flows && formProperties.flows.map(flow => FlowSummaryData.mapFromApiValue(flow)),
    };
  }
}
