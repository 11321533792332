import { SfxMap } from '../../utils/enum-utils';

export enum CustomColumnsColumnEnum {
  Attributes = 'attributes',
}

export namespace CustomColumnsColumnEnum {
  export const allColumns = [CustomColumnsColumnEnum.Attributes];

  export const toString: SfxMap<CustomColumnsColumnEnum, string> = new SfxMap<CustomColumnsColumnEnum, string>([
    [CustomColumnsColumnEnum.Attributes, 'customColumns.table.attributes'],
  ]);
}
