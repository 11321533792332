import { FlowProgressionResponseDTO } from '../../generated/models/models';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';

export interface FlowMetricsDetails {
  customStatus: CustomStatusLightData;
  webAppCount?: number;
  mobileAppCount?: number;
  count?: number;
}

export namespace FlowMetricsDetails {
  export function mapFromApiValue(flow: FlowProgressionResponseDTO): FlowMetricsDetails {
    return {
      customStatus: flow.customStatus && CustomStatusLightData.mapFromApiValue(flow.customStatus),
      webAppCount: flow.webAppProvenanceCount,
      mobileAppCount: flow.mobileAppProvenanceCount,
    };
  }
}
