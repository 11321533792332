import { createReducer, on, Action } from '@ngrx/store';

import * as fromRouteActions from './router.action';
import { SfxRouterState, routerInitialState } from './router.state';

const createRouteReducer = createReducer<SfxRouterState>(
  routerInitialState,
  on(fromRouteActions.routerNavigatedChange, (state, { routerState }): SfxRouterState => ({ ...state, ...routerState, breadCrumbs: state.breadCrumbs })),
  on(
    fromRouteActions.routerNavigationChange,
    (state, { routerState }): SfxRouterState => ({ ...state, routeSegments: routerState.segments, permissionParams: routerState.params }),
  ),
  on(fromRouteActions.buildBreadCrumb, (state, { breadCrumbs }): SfxRouterState => ({ ...state, breadCrumbs })),
  on(fromRouteActions.setBackToReferentialModule, (state, { value }): SfxRouterState => ({ ...state, backToReferentialModule: value })),
);

export function routeReducer(state: SfxRouterState | undefined, action: Action): SfxRouterState {
  return createRouteReducer(state, action);
}
