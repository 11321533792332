import { DateUtils } from '../../utils/date.utils';
import { BlocDsiSignatoriesHistoryDetailsResponseDTO, PageBlocRequestDTO, UserLightDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { ApplicationEventActionEnum, ApplicationEventSourceEnum, InnerSortDirectionEnum, SignatureNatureEnum } from '../../enums';

export interface BlockDsiHistoryData {
  uuidEntity: string;
  action: ApplicationEventActionEnum;
  userName: string;
  signatureNature: SignatureNatureEnum;
  actionSource: ApplicationEventSourceEnum;
  actionDate: string;
  actionTime: string;
  supervisorName?: string;
}

export namespace BlockDsiHistoryData {
  export interface QueryRequest extends BaseQuery<BlockDsiHistoryData> {
    blockUuidEntity: string;
    projectUuidEntity: string;
  }

  export function mapFromApiValue(blockDsiHistory: BlocDsiSignatoriesHistoryDetailsResponseDTO): BlockDsiHistoryData {
    let userName = blockDsiHistory.user && `${blockDsiHistory.user.firstName} ${blockDsiHistory.user.lastName}`;
    if (blockDsiHistory?.user?.natureUser !== UserLightDTO.NatureUserEnum.NORMAL) {
      userName = `${blockDsiHistory?.user?.refExtern}_${blockDsiHistory?.user?.refIntern}`;
    }

    return {
      uuidEntity: blockDsiHistory.uuidEntity,
      action: ApplicationEventActionEnum.convertFromApiValue.getValue(blockDsiHistory.action),
      userName,
      actionDate: blockDsiHistory.eventDate && DateUtils.toDateFormat(blockDsiHistory.eventDate),
      actionTime: blockDsiHistory.eventDate && DateUtils.toTimeFormat(blockDsiHistory.eventDate),
      signatureNature: SignatureNatureEnum.convertFromApiValue.getValue(blockDsiHistory.signatureNature),
      actionSource: ApplicationEventSourceEnum.convertFromApiValue.getValue(blockDsiHistory.actionSource),
      supervisorName: blockDsiHistory.supervisorName,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<BlockDsiHistoryData>(),
      projectUuidEntity,
      blockUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }
}
