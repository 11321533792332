import { EpsilonOutDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum PgacDuplicateColumnEnum {
  RefFolder = 'epsilonA',
  ActivityName = 'inEpsilonAf',
  Local = 'inEpsilonAg',
  Functional = 'inEpsilonAe',
  Discipline = 'inEpsilonAp',
  ControlledZone = 'inEpsilonAi',
  Production = 'inEpsilonD',
  Fold = 'inEpsilonH',
  State = 'statusEpsilon',
  Action = 'action',
}

export namespace PgacDuplicateColumnEnum {
  export type PgacDuplicatedSortField = keyof EpsilonOutDetailsResponseDTO;

  export const allColumns = [
    PgacDuplicateColumnEnum.RefFolder,
    PgacDuplicateColumnEnum.ActivityName,
    PgacDuplicateColumnEnum.Local,
    PgacDuplicateColumnEnum.Functional,
    PgacDuplicateColumnEnum.Discipline,
    PgacDuplicateColumnEnum.ControlledZone,
    PgacDuplicateColumnEnum.Production,
    PgacDuplicateColumnEnum.Fold,
    PgacDuplicateColumnEnum.State,
    PgacDuplicateColumnEnum.Action,
  ];

  const key = 'flows.modals.flowPgacDuplicate.table.header.';

  export const toString: SfxMap<PgacDuplicateColumnEnum, string> = new SfxMap<PgacDuplicateColumnEnum, string>([
    [PgacDuplicateColumnEnum.RefFolder, key + 'refFolder'],
    [PgacDuplicateColumnEnum.ActivityName, key + 'activityName'],
    [PgacDuplicateColumnEnum.Local, key + 'local'],
    [PgacDuplicateColumnEnum.Functional, key + 'functional'],
    [PgacDuplicateColumnEnum.Discipline, key + 'discipline'],
    [PgacDuplicateColumnEnum.ControlledZone, key + 'controlledZone'],
    [PgacDuplicateColumnEnum.Production, key + 'production'],
    [PgacDuplicateColumnEnum.Fold, key + 'fold'],
    [PgacDuplicateColumnEnum.State, key + 'state'],
    [PgacDuplicateColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<PgacDuplicateColumnEnum, PgacDuplicatedSortField> = new SfxMap<PgacDuplicateColumnEnum, PgacDuplicatedSortField>([
    [PgacDuplicateColumnEnum.RefFolder, 'epsilonA'],
    [PgacDuplicateColumnEnum.ActivityName, 'inEpsilonAf'],
    [PgacDuplicateColumnEnum.Local, 'inEpsilonAg'],
    [PgacDuplicateColumnEnum.Functional, 'inEpsilonAe'],
    [PgacDuplicateColumnEnum.Discipline, 'inEpsilonAp'],
    [PgacDuplicateColumnEnum.ControlledZone, 'inEpsilonAi'],
    [PgacDuplicateColumnEnum.Production, 'inEpsilonD'],
    [PgacDuplicateColumnEnum.Fold, 'inEpsilonH'],
    [PgacDuplicateColumnEnum.State, 'statusEpsilon'],
  ]);
}
