import { SignaturesProgressDto } from '../../generated/models/models';

export interface SignaturesProgressData {
  signaturesCount: number;
  filteredCount: number;
  signaturesSigned: number;
}

export namespace SignaturesProgressData {
  export function mapFromApiValue(countData: SignaturesProgressDto): SignaturesProgressData {
    return {
      signaturesCount: countData.totalNbSignatures,
      filteredCount: countData.filtredSignaturesCount,
      signaturesSigned: countData.nbsignaturesSigned,
    };
  }
}
