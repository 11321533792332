import { createReducer, Action, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromProjectActions from './project-dialog.actions';
import { dummyAction } from '../../../main/state/main.actions';

import {
  ProjectForDuplicate,
  ProjectForUpdate,
  CustomFieldData,
  PgacApplicationEventData,
  PgacConflictsData,
  CustomFileApplicationEventData,
  LazyLoadResponse,
  ApplicationEventLightData,
} from '../../../../core/models';

export interface ProjectDialogState {
  projectDetails: ProjectForUpdate;
  customFields: CustomFieldData[];
  initialProjectDetails: ProjectForUpdate;
  initialCustomFields: CustomFieldData[];
  hasRefIntGenerated: boolean;
  hasRefExtGenerated: boolean;
  saveLoading: boolean;
  dataLoading: boolean;
  isVisibleLanguageBlock: boolean;
}

export interface PgacProjectDialogState {
  pgacEvents: { events: PgacApplicationEventData[]; totalCount: number; reset: boolean };
  pgacConflicts: { conflicts: PgacConflictsData[]; totalCount: number; reset: boolean };
  importFileLoading: boolean;
  exportFileLoading: boolean;
}

export interface CustomFileDialogState {
  customFileEvents: { events: CustomFileApplicationEventData[]; totalCount: number; page: number };
  importCustomFileLoading: boolean;
  exportCustomFileLoading: boolean;
}

export interface DuplicateDialogState {
  duplicatedProject: ProjectForDuplicate;
  duplicateLoading: boolean;
}

export interface DashboardProjectDialogState {
  projectDialogState: ProjectDialogState;
  pgacProjectDialogState: PgacProjectDialogState;
  customFileDialogState: CustomFileDialogState;
  duplicateDialogState: DuplicateDialogState;
  historyState: HistoryDialogState;
}

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

const initialPgacProjectDialogState: PgacProjectDialogState = {
  pgacEvents: undefined,
  pgacConflicts: undefined,
  importFileLoading: false,
  exportFileLoading: false,
};

const initialCustomFileDialogState: CustomFileDialogState = {
  customFileEvents: undefined,
  importCustomFileLoading: false,
  exportCustomFileLoading: false,
};

const initialDuplicateDialogState: DuplicateDialogState = {
  duplicatedProject: {} as ProjectForDuplicate,
  duplicateLoading: false,
};

const initialHistoryDialogState: HistoryDialogState = {
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
};

const initialProjectDialogState: ProjectDialogState = {
  projectDetails: null,
  customFields: null,
  initialProjectDetails: null,
  initialCustomFields: null,
  hasRefIntGenerated: false,
  hasRefExtGenerated: false,
  saveLoading: false,
  dataLoading: true,
  isVisibleLanguageBlock: false,
};

export const initialDashboardProjectDialogState = () => ({
  projectDialogState: initialProjectDialogState,
  pgacProjectDialogState: initialPgacProjectDialogState,
  customFileDialogState: initialCustomFileDialogState,
  duplicateDialogState: initialDuplicateDialogState,
  historyState: initialHistoryDialogState,
});

const reducer = createReducer<DashboardProjectDialogState>(
  initialDashboardProjectDialogState(),
  on(
    fromProjectActions.addProject,
    fromProjectActions.updateProject,
    fromProjectActions.saveProjectCustomFields,
    fromProjectActions.prepareRefFlow,
    (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, saveLoading: true } }),
  ),
  on(
    fromProjectActions.addProjectFail,
    fromProjectActions.updateProjectFail,
    fromProjectActions.updateProjectSuccess,
    fromProjectActions.saveProjectCustomFieldsSuccess,
    fromProjectActions.saveProjectCustomFieldsFail,
    fromProjectActions.completePrepareRefFlow,
    (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, saveLoading: false } }),
  ),
  on(
    fromProjectActions.setVisibleLanguageBlock,
    (state, { isVisibleLanguageBlock }): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, isVisibleLanguageBlock } }),
  ),
  on(
    fromProjectActions.addProjectSuccess,
    (state, { projectDetails }): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, projectDetails, saveLoading: false } }),
  ),
  on(
    fromProjectActions.getProjectDetailsSuccess,
    (state, { projectDetails }): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, projectDetails, dataLoading: false } }),
  ),
  on(fromProjectActions.getProjectDetailsFail, (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, dataLoading: false } })),
  on(fromProjectActions.loadProjectCustomFields, (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, dataLoading: true } })),
  on(
    fromProjectActions.loadProjectCustomFieldsFail,
    (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, dataLoading: false } }),
  ),
  on(
    fromProjectActions.loadProjectCustomFieldsSuccess,
    (state, { customFields }): DashboardProjectDialogState => ({
      ...state,
      projectDialogState: { ...state.projectDialogState, customFields, dataLoading: false },
    }),
  ),
  on(
    fromProjectActions.initializeProjectDetails,
    (state, { projectDetails }): DashboardProjectDialogState => ({
      ...state,
      projectDialogState: { ...state.projectDialogState, initialProjectDetails: cloneDeep(projectDetails) },
    }),
  ),
  on(
    fromProjectActions.updateProjectLocally,
    (state, payload): DashboardProjectDialogState => ({
      ...state,
      projectDialogState: {
        ...state.projectDialogState,
        projectDetails: payload,
      },
    }),
  ),
  on(
    fromProjectActions.checkGeneratedRefsSuccess,
    (state, { hasRefExtGenerated, hasRefIntGenerated, reset }): DashboardProjectDialogState => {
      const newProject = {
        ...state.projectDialogState.projectDetails,
        businessRef: hasRefIntGenerated && reset ? null : state.projectDialogState.projectDetails?.businessRef,
        contractRef: hasRefExtGenerated && reset ? null : state.projectDialogState.projectDetails?.contractRef,
      };

      return {
        ...state,
        projectDialogState: {
          ...state.projectDialogState,
          hasRefIntGenerated,
          hasRefExtGenerated,
          projectDetails: newProject,
        },
      };
    },
  ),
  on(
    fromProjectActions.initializeProjectCustomFields,
    (state, { customFields }): DashboardProjectDialogState => ({
      ...state,
      projectDialogState: { ...state.projectDialogState, initialCustomFields: cloneDeep(customFields) },
    }),
  ),
  on(
    fromProjectActions.checkRefIntGeneratedSuccess,
    (state, { hasRefIntGenerated }): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, hasRefIntGenerated } }),
  ),
  on(
    fromProjectActions.checkRefExtGeneratedSuccess,
    (state, { hasRefExtGenerated }): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, hasRefExtGenerated } }),
  ),
  on(
    fromProjectActions.changeIsContractual,
    (state, { isContractual }): DashboardProjectDialogState => ({
      ...state,
      projectDialogState: { ...state.projectDialogState, projectDetails: { ...state.projectDialogState.projectDetails, isContractual } },
    }),
  ),
  on(
    fromProjectActions.loadPgacEventListSuccess,
    (state, { events, totalCount, reset }): DashboardProjectDialogState => ({
      ...state,
      pgacProjectDialogState: {
        ...state.pgacProjectDialogState,
        pgacEvents: reset
          ? { events, totalCount, reset }
          : { ...state.pgacProjectDialogState.pgacEvents, events: [...state.pgacProjectDialogState.pgacEvents.events, ...events], reset },
      },
    }),
  ),
  on(
    fromProjectActions.importEpsilonOutFiles,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, importFileLoading: true } }),
  ),
  on(
    fromProjectActions.importEpsilonOutFilesFail,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, importFileLoading: false } }),
  ),
  on(
    fromProjectActions.importEpsilonOutFilesSuccess,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, importFileLoading: false } }),
  ),
  on(
    fromProjectActions.exportEpsilonOutFile,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, exportFileLoading: true } }),
  ),
  on(
    fromProjectActions.exportEpsilonOutFileFail,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, exportFileLoading: false } }),
  ),
  on(
    fromProjectActions.exportEpsilonOutFileSuccess,
    (state): DashboardProjectDialogState => ({ ...state, pgacProjectDialogState: { ...state.pgacProjectDialogState, exportFileLoading: false } }),
  ),
  on(
    fromProjectActions.loadCustomFileEventListSuccess,
    (state, { events, totalCount, reset }): DashboardProjectDialogState => ({
      ...state,
      customFileDialogState: {
        ...state.customFileDialogState,
        customFileEvents: reset
          ? { events, totalCount, page: 0 }
          : { ...state.customFileDialogState.customFileEvents, events: [...state.customFileDialogState.customFileEvents.events, ...events] },
      },
    }),
  ),
  on(
    fromProjectActions.importCustomFiles,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, importCustomFileLoading: true } }),
  ),
  on(
    fromProjectActions.importCustomFilesFail,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, importCustomFileLoading: false } }),
  ),
  on(
    fromProjectActions.importCustomFilesSuccess,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, importCustomFileLoading: false } }),
  ),
  on(
    fromProjectActions.exportCustomFile,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, exportCustomFileLoading: true } }),
  ),
  on(
    fromProjectActions.exportCustomFileFail,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, exportCustomFileLoading: false } }),
  ),
  on(
    fromProjectActions.exportCustomFileSuccess,
    (state): DashboardProjectDialogState => ({ ...state, customFileDialogState: { ...state.customFileDialogState, exportCustomFileLoading: false } }),
  ),
  on(fromProjectActions.getDuplicatedProjectSuccess, (state, { duplicatedProject }) => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicatedProject } })),
  on(fromProjectActions.duplicateProject, (state): DashboardProjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: true } })),
  on(
    fromProjectActions.duplicateFolderProject,
    (state): DashboardProjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: true } }),
  ),
  on(
    fromProjectActions.duplicateProjectFail,
    (state): DashboardProjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } }),
  ),
  on(
    fromProjectActions.duplicateProjectSuccess,
    (state): DashboardProjectDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } }),
  ),
  on(
    fromProjectActions.loadConflictListSuccess,
    (state, { conflicts, totalCount, reset }): DashboardProjectDialogState => ({
      ...state,
      pgacProjectDialogState: {
        ...state.pgacProjectDialogState,
        pgacConflicts: reset
          ? { conflicts, totalCount, reset }
          : { ...state.pgacProjectDialogState.pgacConflicts, conflicts: [...state.pgacProjectDialogState.pgacConflicts.conflicts, ...conflicts], reset },
      },
    }),
  ),
  on(dummyAction, (state): DashboardProjectDialogState => ({ ...state, projectDialogState: { ...state.projectDialogState, saveLoading: false } })),
  on(
    fromProjectActions.loadHistoryListSuccess,
    (state, { data, reset }): DashboardProjectDialogState => ({
      ...state,
      historyState: {
        ...state.historyState,
        reset,
        historyList: reset ? data : { ...state.historyState.historyList, payload: [...state.historyState.historyList.payload, ...data.payload] },
      },
    }),
  ),
);

export function dashboardProjectDialogReducer(state: DashboardProjectDialogState | undefined, action: Action): DashboardProjectDialogState {
  return reducer(state, action);
}
