import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './closing-folder-dialogs.actions';

import { LazyLoadResponse, SectionDocumentSummaryData, VerificationSectionData, FolderForPropertiesData } from '../../../../../core/models';
import { SectionDetailsData } from '../../../../../core/models/section/section-details-data';

import { DocumentSummaryData } from '../../../../../core/models/closing-folder/document-data';
import { SectionEmmSummaryData } from '../../../../../core/models/closing-folder/section-emm-data';
import { SectionNatureEnum } from '../../../../../core/enums';

export interface SectionDocumentsState {
  documents: LazyLoadResponse<DocumentSummaryData[]>;
  loading: boolean;
  reset: boolean;
}

export interface SectionEmmsState {
  sectionEmms: LazyLoadResponse<SectionEmmSummaryData[]>;
  loading: boolean;
  reset: boolean;
}
export interface DocumentsToImportState {
  documents: LazyLoadResponse<SectionDocumentSummaryData[]>;
  loading: boolean;
  reset: boolean;
}
export interface DashboardClosingFolderDialogState {
  loading: boolean;
  sectionDetails: SectionDetailsData;
  folderDetails: FolderForPropertiesData;
  documentsToImport: DocumentsToImportState;
  sectionDocuments: SectionDocumentsState;
  sectionEmms: SectionEmmsState;
  categories: VerificationSectionData[];
  sectionType: SectionNatureEnum;
  reorganizeLoading: boolean;
}

export const initialDashboardClosingFolderDialogState = () => ({
  loading: false,
  sectionDetails: {} as SectionDetailsData,
  folderDetails: {} as FolderForPropertiesData,
  documentsToImport: {} as DocumentsToImportState,
  sectionDocuments: {} as SectionDocumentsState,
  sectionEmms: {} as SectionEmmsState,
  categories: [],
  sectionType: null,
  reorganizeLoading: false,
});

const reducer = createReducer<DashboardClosingFolderDialogState>(
  initialDashboardClosingFolderDialogState(),
  on(
    fromActions.loadSectionDetailsSuccess,
    (state, { section }): DashboardClosingFolderDialogState => ({ ...state, sectionDetails: { ...section, folderUuidEntity: state.sectionDetails.folderUuidEntity } }),
  ),
  on(fromActions.reorganizeSections, (state): DashboardClosingFolderDialogState => ({ ...state, reorganizeLoading: true })),
  on(fromActions.reorganizeSectionsSuccess, fromActions.reorganizeSectionsFail, (state): DashboardClosingFolderDialogState => ({ ...state, reorganizeLoading: false })),
  on(fromActions.loadFolderSuccess, (state, { folderDetails }): DashboardClosingFolderDialogState => ({ ...state, folderDetails })),
  on(fromActions.createSectionSuccess, (state, { section }): DashboardClosingFolderDialogState => ({ ...state, sectionDetails: section })),
  on(fromActions.loadDocumentsToImport, (state): DashboardClosingFolderDialogState => ({ ...state, documentsToImport: { loading: true } as DocumentsToImportState })),
  on(fromActions.loadDocumentsToImportFail, (state): DashboardClosingFolderDialogState => ({ ...state, documentsToImport: { ...state.documentsToImport, loading: false } })),
  on(
    fromActions.loadDocumentsToImportSuccess,
    (state, { data, reset }): DashboardClosingFolderDialogState => ({
      ...state,
      documentsToImport: {
        ...state.documentsToImport,
        loading: false,
        documents: reset
          ? data
          : {
              ...state.documentsToImport.documents,
              payload: [...((state.documentsToImport.documents && state.documentsToImport.documents.payload) || []), ...data.payload],
            },
        reset,
      },
    }),
  ),
  on(
    fromActions.importSectionDocumentsSuccess,
    (state, { selectedDocs, deselectedDocs }): DashboardClosingFolderDialogState => {
      const newSelectedDocs = [...(state.sectionDetails.preselectedDocs || []).filter(doc => !deselectedDocs.includes(doc)), ...(selectedDocs || [])];

      return { ...state, sectionDetails: { ...state.sectionDetails, preselectedDocs: newSelectedDocs } };
    },
  ),
  on(
    fromActions.loadSectionDocumentsSuccess,
    (state, { data, reset }): DashboardClosingFolderDialogState => ({
      ...state,
      sectionDocuments: {
        ...state.sectionDocuments,
        documents: reset ? data : { ...state.sectionDocuments.documents, payload: [...state.sectionDocuments.documents.payload, ...data.payload] },
        reset,
      },
    }),
  ),
  on(
    fromActions.loadSectionEmmsSuccess,
    (state, { data, reset }): DashboardClosingFolderDialogState => ({
      ...state,
      sectionEmms: {
        ...state.sectionEmms,
        sectionEmms: reset ? data : { ...state.sectionEmms.sectionEmms, payload: [...state.sectionEmms.sectionEmms.payload, ...data.payload] },
        reset,
      },
    }),
  ),
  on(fromActions.verifySectionSuccess, (state, { categories }): DashboardClosingFolderDialogState => ({ ...state, categories })),
  on(
    fromActions.initializeNewSection,
    (state, { section }): DashboardClosingFolderDialogState => ({
      ...state,
      sectionDetails: { nature: section.nature } as SectionDetailsData,
      documentsToImport: {} as DocumentsToImportState,
    }),
  ),
);

export function dashboardClosingFolderDialogReducer(state: DashboardClosingFolderDialogState | undefined, action: Action): DashboardClosingFolderDialogState {
  return reducer(state, action);
}
