import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { InnerSortDirectionEnum } from '../../enums';
import { SequenceResponseDTO, SequenceRequestDTO, PageSequenceRequest } from './../../generated/models/models';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface SequenceSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
}
export namespace SequenceSummaryData {
  export interface QueryRequest extends BaseQuery<SequenceSummaryData> {
    flowUuidEntity: string;
  }

  export function mapToUpdateRequestApiValue(sequence: SequenceSummaryData, projectUuidEntity: string, flowUuidEntity: string): SequenceRequestDTO {
    return {
      projectUuidEntity,
      flowUuidEntity,
      uuidEntity: sequence.uuidEntity,
      name: sequence.name,
      description: sequence.description,
    };
  }

  export function mapFromApiValue(sequence: SequenceResponseDTO): SequenceSummaryData {
    return {
      uuidEntity: sequence.uuidEntity,
      name: sequence.name,
      description: sequence.description,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageSequenceRequest {
    return {
      page: queries.page,
      size: queries.size,
      flowUuidEntity: queries.flowUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(page: number, searchText?: string, flowUuidEntity?: string): QueryRequest {
    return {
      ...initializeQueries<SequenceSummaryData>({ key: 'name' }),
      page,
      textSearch: searchText || undefined,
      flowUuidEntity,
    };
  }

  export function mapToSingleSelectItemData(sequence: SequenceResponseDTO): SingleSelectItemData<SequenceSummaryData> {
    return {
      value: mapFromApiValue(sequence),
      title: sequence.name,
      tooltip: sequence.description,
    };
  }
}
