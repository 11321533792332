import { DateUtils } from '../../utils/date.utils';

import { DocumentFlowPropertiesResponseDTO } from '../../generated/models/documentFlowPropertiesResponseDTO';

import { UserLightData } from '../user/user-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { DocumentNatureEnum, DocumentProvenanceEnum, DocumentStatusEnum, DocumentSourceEnum } from '../../enums';

export interface DashboardDocumentPropertiesData {
  name: string;
  source: DocumentSourceEnum;
  nature: DocumentNatureEnum;
  family: LabelFamilyLightData;
  applicabilityName: string;
  provenance: DocumentProvenanceEnum;
  states: DocumentStatusEnum;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
}

export namespace DashboardDocumentPropertiesData {
  export function mapFromApiValue(dashboardDocumentProperties: DocumentFlowPropertiesResponseDTO): DashboardDocumentPropertiesData {
    return {
      name: dashboardDocumentProperties.name,
      source: dashboardDocumentProperties.source && DocumentSourceEnum.convertFromApiValue.getValue(dashboardDocumentProperties.source),
      nature: dashboardDocumentProperties.natureFile && DocumentNatureEnum.convertFromApiValue.getValue(dashboardDocumentProperties.natureFile),
      family: dashboardDocumentProperties.family && LabelFamilyLightData.mapFromApiValue(dashboardDocumentProperties.family),
      applicabilityName: dashboardDocumentProperties.applicability,
      provenance: dashboardDocumentProperties.provenance && DocumentProvenanceEnum.convertFromApiValue.getValue(dashboardDocumentProperties.provenance),
      states: dashboardDocumentProperties.etat && DocumentStatusEnum.convertFromApiValue.getValue(dashboardDocumentProperties.etat),
      creationDate: dashboardDocumentProperties.createDate && DateUtils.toDateTimeFormat(dashboardDocumentProperties.createDate),
      modificationDate: dashboardDocumentProperties.modificationDate && DateUtils.toDateTimeFormat(dashboardDocumentProperties.modificationDate),
      createdBy: dashboardDocumentProperties.createdBy && UserLightData.mapFromApiValue(dashboardDocumentProperties.createdBy),
      lastModifiedBy: dashboardDocumentProperties.lastModifiedBy && UserLightData.mapFromApiValue(dashboardDocumentProperties.lastModifiedBy),
    };
  }
}
