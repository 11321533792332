import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SignatoryFilterDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum SignatureTypeEnum {
  Intern = 1,
  Extern = 2,
  NotListed = 3,
}

export namespace SignatureTypeEnum {
  const values = [SignatureTypeEnum.Intern, SignatureTypeEnum.Extern];

  export const convertFromApiValue = new SfxMap<SignatoryFilterDTO.TypeSignatoryEnum, SignatureTypeEnum>(
    [
      [SignatoryFilterDTO.TypeSignatoryEnum.INTERN, SignatureTypeEnum.Intern],
      [SignatoryFilterDTO.TypeSignatoryEnum.EXTERN, SignatureTypeEnum.Extern],
    ],
    SignatureTypeEnum.NotListed,
  );

  export const convertFromParamsValue = new SfxMap<string, SignatureTypeEnum>([
    ['intern', SignatureTypeEnum.Intern],
    ['extern', SignatureTypeEnum.Extern],
    ['noListed', SignatureTypeEnum.NotListed],
  ]);

  export const convertToParamsValue = new SfxMap<SignatureTypeEnum, string>([
    [SignatureTypeEnum.Intern, 'intern'],
    [SignatureTypeEnum.Extern, 'extern'],
    [SignatureTypeEnum.NotListed, 'noListed'],
  ]);

  export const convertToApiValue = new SfxMap<SignatureTypeEnum, SignatoryFilterDTO.TypeSignatoryEnum>([
    [SignatureTypeEnum.Intern, SignatoryFilterDTO.TypeSignatoryEnum.INTERN],
    [SignatureTypeEnum.Extern, SignatoryFilterDTO.TypeSignatoryEnum.EXTERN],
    [SignatureTypeEnum.NotListed, SignatoryFilterDTO.TypeSignatoryEnum.NOLISTEDINTERN],
  ]);

  const key = 'dashboardMetrics.signature.type.';
  export const toString = new SfxMap<SignatureTypeEnum, string>([
    [SignatureTypeEnum.Intern, key + 'intern'],
    [SignatureTypeEnum.Extern, key + 'extern'],
    [SignatureTypeEnum.NotListed, key + 'notListed'],
  ]);

  export const multiSelectItemResolver = (): Observable<MultiSelectItemData<SignatureTypeEnum>[]> =>
    of(values.map(signature => ({ value: signature, title: toString.getValue(signature) })));
}
