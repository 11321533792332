import { ArchiveRestoreRequestDTO } from '../../generated/models/models';
import { TrashNatureEnum } from '../../enums';

export interface ArchiveRestoreRequest {
  nature: TrashNatureEnum;
  uuidEntity: string;
}

export namespace ArchiveRestoreRequest {
  export function mapToRequestApiValue(queries: ArchiveRestoreRequest): ArchiveRestoreRequestDTO {
    return {
      type: TrashNatureEnum.convertToApiValue.getValue(queries.nature),
      uuidEntity: queries.uuidEntity,
    };
  }
}
