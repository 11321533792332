import { createAction, props } from '@ngrx/store';

import { CustomFieldDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { CustomFieldCreationPreVerificationDTO } from '../../../../../core/generated/models/models';

export const setCustomFieldSuccess = createAction('[customField] set customField details success', props<{ customField: CustomFieldDetailsData }>());
export const setCustomFieldFail = createAction('[customField] set customField details fail', props<DialogToasterData>());

export const addCustomField = createAction('[customField] add new customField', props<{ customField: CustomFieldDetailsData; addNewOne?: boolean }>());
export const addCustomFieldSuccess = createAction('[customField] add new customField success', props<{ customField: CustomFieldDetailsData; addNewOne?: boolean }>());
export const addCustomFieldFail = createAction('[customField] add new customField fail', props<DialogToasterData>());

export const updateCustomField = createAction('[customField] update customField', props<{ customField: CustomFieldDetailsData; closeModal?: boolean }>());
export const updateCustomFieldSuccess = createAction('[customField] update customField success', props<{ customField: CustomFieldDetailsData }>());
export const updateCustomFieldFail = createAction('[customField] update customField fail', props<DialogToasterData>());

export const preVerifyCustomFieldCreation = createAction('[customField] pre verify customField creation', props<{ customField: CustomFieldDetailsData; addNewOne: boolean }>());
export const preVerifyCustomFieldCreationSuccess = createAction(
  '[customField] pre verify customField creation success',
  props<{ customField: CustomFieldDetailsData; verification: CustomFieldCreationPreVerificationDTO; addNewOne: boolean }>(),
);
export const preVerifyCustomFieldCreationFail = createAction('[customField] pre verify customField creation fail', props<DialogToasterData>());

export const preVerifyCustomFieldUpdate = createAction('[customField] pre verify customField update', props<{ customField: CustomFieldDetailsData; closeModal?: boolean }>());
export const preVerifyCustomFieldUpdateSuccess = createAction(
  '[customField] pre verify customField update success',
  props<{ customField: CustomFieldDetailsData; verification: CustomFieldCreationPreVerificationDTO; closeModal?: boolean }>(),
);
export const preVerifyCustomFieldUpdateFail = createAction('[customField] pre verify customField update fail', props<DialogToasterData>());

export const setPreVerification = createAction('[customField] set pre verification', props<{ verification: CustomFieldCreationPreVerificationDTO }>());

export const navigateThroughCustomField = createAction('[customField] Navigate through customField', props<{ customField: CustomFieldDetailsData; step: number }>());
