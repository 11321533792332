import { IconPrefix } from '@fortawesome/free-solid-svg-icons';

import { WordingConfigurationFieldResponseDTO } from '../../generated/models/wordingConfigurationFieldResponseDTO';

import { IconData } from '../../../modules/shared/interfaces/icon-data';

import { WordingConfigActionEnum } from '../../enums/wording-configurations/wording-config-action.enum';
import { ColorPickerPaletteEnum } from '../../enums';

export interface CustomWordingsData {
  actionKey: WordingConfigActionEnum;
  color: string;
  icon: string;
  tooltip: string;
  value: string;
  iconPrefix?: string;
}

export namespace CustomWordingsData {
  const supportedPrefix: IconPrefix[] = ['fab', 'fas', 'far'];

  export function mapFromApiValue(data: WordingConfigurationFieldResponseDTO): CustomWordingsData {
    const iconData = extractIconData(data.icon);

    return {
      actionKey: WordingConfigActionEnum.convertFromApiValue.getValue(data.actionKey),
      color: data.color || ColorPickerPaletteEnum.defaultColor,
      icon: iconData?.name,
      tooltip: data.tooltip,
      value: data.value,
      iconPrefix: iconData?.prefix,
    };
  }

  export const extractIconData = (icon: string): IconData => {
    if (!icon) {
      return undefined;
    }
    const splitIconData = icon.split('-');

    return {
      name: !supportedPrefix.includes(splitIconData[0] as IconPrefix) ? icon : splitIconData.shift() && splitIconData.join('-'),
      prefix: supportedPrefix.includes(icon.split('-')[0] as IconPrefix) ? (icon.split('-')[0] as 'fas' | 'far' | 'fab') : 'fas',
    };
  };
}
