import { PageEpsilonOutRequest } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum EpsilonOutStateEnum {
  Initial = 1,
  Updated = 2,
  Finalized = 3,
}

export namespace EpsilonOutStateEnum {
  const values = [EpsilonOutStateEnum.Initial, EpsilonOutStateEnum.Updated, EpsilonOutStateEnum.Finalized];

  export const convertFromApiValue: SfxMap<PageEpsilonOutRequest.StatesEnum, EpsilonOutStateEnum> = new SfxMap<PageEpsilonOutRequest.StatesEnum, EpsilonOutStateEnum>([
    [PageEpsilonOutRequest.StatesEnum.INITIAL, EpsilonOutStateEnum.Initial],
    [PageEpsilonOutRequest.StatesEnum.UPDATED, EpsilonOutStateEnum.Updated],
    [PageEpsilonOutRequest.StatesEnum.FINALIZED, EpsilonOutStateEnum.Finalized],
  ]);

  export const convertToApiValue: SfxMap<EpsilonOutStateEnum, PageEpsilonOutRequest.StatesEnum> = new SfxMap<EpsilonOutStateEnum, PageEpsilonOutRequest.StatesEnum>([
    [EpsilonOutStateEnum.Initial, PageEpsilonOutRequest.StatesEnum.INITIAL],
    [EpsilonOutStateEnum.Updated, PageEpsilonOutRequest.StatesEnum.UPDATED],
    [EpsilonOutStateEnum.Finalized, PageEpsilonOutRequest.StatesEnum.FINALIZED],
  ]);

  export const convertFromParamsValue: SfxMap<string, EpsilonOutStateEnum> = new SfxMap<string, EpsilonOutStateEnum>([
    ['initial', EpsilonOutStateEnum.Initial],
    ['updated', EpsilonOutStateEnum.Updated],
    ['finalized', EpsilonOutStateEnum.Finalized],
  ]);

  export const convertToParamsValue: SfxMap<EpsilonOutStateEnum, string> = new SfxMap<EpsilonOutStateEnum, string>([
    [EpsilonOutStateEnum.Initial, 'initial'],
    [EpsilonOutStateEnum.Updated, 'updated'],
    [EpsilonOutStateEnum.Finalized, 'finalized'],
  ]);

  export const toString: SfxMap<EpsilonOutStateEnum, string> = new SfxMap<EpsilonOutStateEnum, string>([
    [EpsilonOutStateEnum.Initial, 'flows.modals.flowPgacDuplicate.states.initial'],
    [EpsilonOutStateEnum.Updated, 'flows.modals.flowPgacDuplicate.states.updated'],
    [EpsilonOutStateEnum.Finalized, 'flows.modals.flowPgacDuplicate.states.finalized'],
  ]);

  export const multiSelectItemsResolver = (): Observable<MultiSelectItemData<EpsilonOutStateEnum>[]> =>
    of(
      values.map(state => ({
        value: state,
        title: toString.getValue(state),
      })),
    );
}
