import { SkillDetailsResponseDTO, SkillCreateRequestDTO, SkillUpdateRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';

export interface SkillDetailsData {
  expertise: string;
  modificationDate: string;
  name: string;
  labelFamilySkill: EntityLightData;
  activityDomains: string[];
  skillOrder: number;
  startDate: string;
  calendarEndDate: string;
  calendarUpdateDate: string;
  calendarStartDate: string;
  endDate: string;
  updateDate: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  description: string;
  comment: string;
}

export namespace SkillDetailsData {
  export function mapFromApiValue(skill: SkillDetailsResponseDTO): SkillDetailsData {
    return {
      endDate: DateUtils.toDateFormat(skill.endDate),
      updateDate: DateUtils.toDateFormat(skill.updateDate),
      startDate: DateUtils.toDateFormat(skill.startDate),
      calendarEndDate: DateUtils.toCalendarFormat(skill.endDate, true),
      calendarUpdateDate: DateUtils.toCalendarFormat(skill.updateDate, true),
      calendarStartDate: DateUtils.toCalendarFormat(skill.startDate, true),
      expertise: skill.expertise,
      modificationDate: DateUtils.toDateFormat(skill.modificationDate),
      name: skill.name,
      labelFamilySkill: skill.labelFamilySkill && LabelFamilyLightData.mapToEntityLightData(skill.labelFamilySkill),
      activityDomains: (skill.activityDomains || []).map(activityDomain => activityDomain.uuidEntity),
      skillOrder: skill.skillOrder,
      uuid: skill.uuid,
      uuidEntity: skill.uuidEntity,
      uuidOrganization: skill.uuidOrganization,
      uuidType: skill.uuidType,
      description: skill.description,
      comment: skill.comment,
    };
  }

  export function mapToCreateRequestApiValue(skill: SkillDetailsData, userUuidEntity: string): SkillCreateRequestDTO {
    return {
      endDate: skill.calendarEndDate && DateUtils.toLocalDateTime(skill.calendarEndDate),
      updateDate: skill.calendarUpdateDate && DateUtils.toLocalDateTime(skill.calendarUpdateDate),
      startDate: skill.calendarStartDate && DateUtils.toLocalDateTime(skill.calendarStartDate),
      expertise: skill.expertise,
      familyUuide: skill.labelFamilySkill && skill.labelFamilySkill.uuidEntity,
      description: skill.description,
      comment: skill.comment,
      userUuide: userUuidEntity,
      activityDomainsUuides: skill.activityDomains,
    };
  }

  export function mapToUpdateRequestApiValue(skill: SkillDetailsData): SkillUpdateRequestDTO {
    return {
      uuidEntity: skill.uuidEntity,
      endDate: skill.calendarEndDate && DateUtils.toLocalDateTime(skill.calendarEndDate),
      updateDate: skill.calendarUpdateDate && DateUtils.toLocalDateTime(skill.calendarUpdateDate),
      startDate: skill.calendarStartDate && DateUtils.toLocalDateTime(skill.calendarStartDate),
      expertise: skill.expertise,
      familyUuide: skill.labelFamilySkill && skill.labelFamilySkill.uuidEntity,
      activityDomainsUuides: skill.activityDomains,
      description: skill.description,
      comment: skill.comment,
    };
  }
}
