import { BlocViewDetailsResponseDTO } from '../../generated/models/blocViewDetailsResponseDTO';
import { BlockActorData } from './block-actor-data';
import { BlockStatusHistoryData } from './block-status-history-data';
import { BlockStatusEnum, BlockTypeEnum } from '../../enums';

export interface BlockViewDetailsData {
  uuidEntity: string;
  disabled: boolean;
  type: BlockTypeEnum;
  status: BlockStatusEnum;
  revision: number;
  redactor: BlockActorData;
  approver: BlockActorData;
  auditor: BlockActorData;
  history: BlockStatusHistoryData[];
  multiPrTitleHidden?: boolean;
  requiredSignature: boolean;
  requiredStatus: boolean;
}
export namespace BlockViewDetailsData {
  export function mapFromApiValue(enabled: boolean, block: BlocViewDetailsResponseDTO): BlockViewDetailsData {
    return {
      uuidEntity: block.uuidEntity,
      disabled: !enabled,
      type: BlockTypeEnum.convertFromApiValue.getValue(block.natureBloc),
      status: BlockStatusEnum.convertFromApiValue.getValue(block.statusBloc),
      revision: block.revision,
      redactor: block.redacteur && BlockActorData.mapFromApiValue(block.dateRedaction, block.redacteur),
      approver: block.aprobateur && BlockActorData.mapFromApiValue(block.dateAprobation, block.aprobateur),
      auditor: block.verificateur && BlockActorData.mapFromApiValue(block.dateVerification, block.verificateur),
      history: block?.blocEvents?.map(event => BlockStatusHistoryData.mapFromApiValue(event)),
      multiPrTitleHidden: block.multiPrTitleHidden,
      requiredSignature: block?.requirementSignatory,
      requiredStatus: block?.requirementStatus,
    };
  }
}
