import { Params } from '@angular/router';

export enum RouteQueryParamEnum {
  ReturnUrl = 'returnUrl',

  UserKey = 'key',
  ViewMode = 'viewMode',
  ResponseViewMode = 'responseViewMode',

  // Date
  TypeDate = 'typeDate',
  StartDate = 'startDate',
  FrequencyDate = 'frequency',
  EndDate = 'endDate',
  FieldDate = 'fieldDate',
  DateState = 'date-state',
  TypeDateSecondary = 'secondTypeDate',
  StartDateSecondary = 'secondStartDate',
  EndDateSecondary = 'secondEndDate',
  FieldDateSecondary = 'secondFieldDate',
  DateStateSecondary = 'secondDateState',

  SortBy = 'sortBy',
  GroupBy = 'groupBy',
  SortDirection = 'direction',
  TextSearch = 'textSearch',

  Nature = 'nature',
  AccessLevel = 'accesslevel',
  Type = 'type',
  Type2 = 'type2',
  Link = 'link',
  Status = 'status',
  Company = 'company',
  Schedulings = 'schedulings',
  Family = 'family',
  SecondFamily = 'secondFamily',
  Category = 'category',
  SecondCategory = 'secondCategory',
  ThirdCategory = 'thirdCategory',
  Category2 = 'category2',
  Client = 'client',
  Assignee = 'assignee',
  LabelApplicability = 'labelApplicability',
  Applicability = 'applicability',
  ApplicabilitySecondary = 'secondApplicability',
  Function = 'function',
  Legal = 'legal',
  Group = 'group',
  Hierarchy = 'hierarchy',
  Skill = 'skill',
  Contract = 'contract',
  Authority = 'authority',
  Roles = 'roles',
  Role = 'role',
  Format = 'format',
  FlowReferential = 'flowRef',
  Provenance = 'provenance',
  Flow = 'flow',
  SecondFlow = 'secondFlow',
  Form = 'form',
  Block = 'block',
  Extension = 'extension',
  Template = 'template',
  Time = 'time',
  Month = 'month',
  Plan = 'plan',
  State = 'state',
  States = 'states',
  Priority = 'priority',
  Domain = 'domain',
  Question = 'question',
  Locals = 'locals',
  Landmarks = 'landmarks',
  Published = 'published',
  AdvancedSearch = 'advancedSearch',
  EventFlow = 'eventFlow',
  CustomWording = 'customWording',
  // Signature
  UuidEntities = 'uuidEntities',
  Collaborator = 'collaborator',
  Option = 'option',
  Method = 'method',

  UserType = 'userType',
  UserNatures = 'userNatures',
  TeamRole = 'teamRole',

  // Note: when opening image dialog from locked file option
  EditedLockedApplicationFileOption = 'editedLockedApplicationFileOption',
  // Note: opening locked file option annotation modal
  AnnotatedLockedOption = 'annotatedLockedOption',
}

export namespace RouteQueryParamEnum {
  export const params = Object.values(RouteQueryParamEnum);

  export function getInitialQueries(): Params {
    const initialQueries: Params = {};
    Object.keys(RouteQueryParamEnum).forEach(key => (initialQueries[RouteQueryParamEnum[key]] = undefined));

    return initialQueries;
  }
}
