import { BlockTypeEnum } from '../../enums';
import { BlocCopyPasteRequestDTO } from '../../generated/models/models';

export interface BlockClipboardData {
  blockUuidEntity: string;
  blockType: BlockTypeEnum;
}

export namespace BlockClipboardData {
  export function mapToCreateRequestApiValue(projectUuidEntity: string, uuideBlocDest: string, uuideBlocSource: string): BlocCopyPasteRequestDTO {
    return {
      uuideBlocDest,
      uuideBlocSource,
      uuideProject: projectUuidEntity,
    };
  }
}
