/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SortExternalDtoSortFormAttributeEnum {
  /**
   * The attribute on which we will sort the request
   */
  attribute: SortExternalDtoSortFormAttributeEnum.AttributeEnum;
  /**
   * The direction of the sorting
   */
  direction: SortExternalDtoSortFormAttributeEnum.DirectionEnum;
}
export namespace SortExternalDtoSortFormAttributeEnum {
  export type AttributeEnum = 'REFERENCE_RESPONSE' | 'STATUS' | 'INDICE' | 'MODIFICATION_DATE';
  export const AttributeEnum = {
    REFERENCERESPONSE: 'REFERENCE_RESPONSE' as AttributeEnum,
    STATUS: 'STATUS' as AttributeEnum,
    INDICE: 'INDICE' as AttributeEnum,
    MODIFICATIONDATE: 'MODIFICATION_DATE' as AttributeEnum,
  };
  export type DirectionEnum = 'ASC' | 'DESC';
  export const DirectionEnum = {
    ASC: 'ASC' as DirectionEnum,
    DESC: 'DESC' as DirectionEnum,
  };
}
