import { createAction, props } from '@ngrx/store';

import { LazyLoadResponse, TabletImportData, TabletQrCodeData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';

export const loadQrCodeDataSuccess = createAction('[dTabletQuickConnection] load qr code data success', props<TabletQrCodeData>());
export const loadQrCodeDataFail = createAction('[dTabletQuickConnection] load qr code data fail', props<DialogToasterData>());

export const loadImportTabletSuccess = createAction(
  '[dTabletImportTablet] load import tablet data success',
  props<{ data: LazyLoadResponse<TabletImportData[]>; reset?: boolean }>(),
);
export const loadImportTabletFail = createAction('[dTabletImportTablet] load import tablet data fail', props<DialogToasterData>());

export const loadMoreImportTablet = createAction('[dTabletImportTablet] load more import tablet data');

export const unlinkTablet = createAction('[dTabletQuickConnection] unlink tablet', props<{ uuidEntity: string }>());
export const unlinkTabletSuccess = createAction('[dTabletQuickConnection] unlink tablet success');
export const unlinkTabletFail = createAction('[dTabletQuickConnection] unlink tablet fail', props<DialogToasterData>());

export const importTablet = createAction('[dTabletImportTablet] import tablet', props<{ uuides: string[]; allSelected?: boolean }>());
export const importTabletSuccess = createAction('[dTabletImportTablet] import tablet success');
export const importTabletFail = createAction('[dTabletImportTablet] import tablet fail', props<DialogToasterData>());

export const navigateThroughTablets = createAction('[dTablets/API] Navigate through tablets', props<{ uuidEntity: string; step: number }>());
