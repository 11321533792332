import {
  BlocDsiCreateSignatoryRequestDTO,
  BlocDsiSignatoryDetailsResponsesDTO,
  BlocDsiUpdateSignatoryRequestDTO,
  ElementPrSignatoryDetailsResponsesDTO,
  ElementPrSignatoryRequest,
} from '../../generated/models/models';

import { UserLightData } from '../user/user-light-data';
import { EntityLightData } from '../utils/entity-light-data';

import { SignatureNatureEnum, SignatureStatusEnum, SignatureTypeEnum } from '../../enums';
import { DateUtils } from '../../utils/date.utils';

export interface SignatureDetailsData {
  uuidEntity: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  signatoryFunction: string;
  comment: string;
  signature: string;
  signatureDate: string;
  signatureProxiedDate?: string;
  signatureNature: SignatureNatureEnum;
  signatureState: SignatureStatusEnum;
  email?: string;
  notListedContributor?: boolean;
  disabled?: boolean;
  proxied?: boolean;
  signatoryType?: SignatureTypeEnum;
  signatoryRef?: EntityLightData;
  signWithQrCode?: boolean;
  supervisorName?: string;
  signatoryName?: string;
}

export namespace SignatureDetailsData {
  export function mapFromApiValue(signatureData: BlocDsiSignatoryDetailsResponsesDTO): SignatureDetailsData {
    let signatoryName;
    if (signatureData && SignatureStatusEnum.convertFromApiValue.getValue(signatureData.signatureState) !== SignatureStatusEnum.Waiting) {
      signatoryName = signatureData.proxied ? signatureData.firstName : signatureData.firstName + ' ' + signatureData.lastName;
    }
    const notListedContributor =
      signatureData.contributorType === BlocDsiSignatoryDetailsResponsesDTO.ContributorTypeEnum.NOLISTEDINTERN ||
      (signatureData.contributorType === BlocDsiSignatoryDetailsResponsesDTO.ContributorTypeEnum.EXTERN && !signatureData.user && !signatureData.proxied);

    return {
      uuidEntity: signatureData.uuidEntity,
      firstName: signatureData.firstName,
      lastName: signatureData.lastName,
      email: signatureData.email,
      companyName: signatureData.companyName,
      notListedContributor,
      signatoryFunction: signatureData.profession,
      comment: signatureData.comment,
      signature: signatureData.signatoryImage,
      signatureDate: DateUtils.toCalendarFormat(signatureData.date),
      signatureNature: SignatureNatureEnum.convertFromApiValue.getValue(signatureData.signatoryNature),
      signatureState: SignatureStatusEnum.convertFromApiValue.getValue(signatureData.signatureState),
      signatoryRef: signatureData.user && UserLightData.mapToEntityLightData(signatureData.user),
      signWithQrCode: signatureData.signWithQrCode,
      proxied: signatureData.proxied,
      signatureProxiedDate: signatureData.signatureProxiedDate && DateUtils.toCalendarFormat(signatureData.signatureProxiedDate),
      signatoryName,
    };
  }

  export function mapFromElementPrApiValue(signatureData: ElementPrSignatoryDetailsResponsesDTO): SignatureDetailsData {
    return {
      uuidEntity: signatureData.uuidEntity,
      firstName: null,
      lastName: null,
      email: null,
      signatoryFunction: null,
      comment: null,
      signature: signatureData.signatoryImage,
      signatureDate: DateUtils.toCalendarFormat(signatureData.date || DateUtils.today()),
      signatureNature: null,
      signatureState: null,
      signatoryRef: signatureData.user && UserLightData.mapToEntityLightData(signatureData.user),
    };
  }

  export function mapToCreateRequestApiValue(projectUuidEntity: string, elementDsiUuidEntity: string, signatureData: SignatureDetailsData): BlocDsiCreateSignatoryRequestDTO {
    return {
      elementDsiUuide: elementDsiUuidEntity,
      uuideProject: projectUuidEntity,
      contributorType: signatureData.signatoryRef ? BlocDsiCreateSignatoryRequestDTO.ContributorTypeEnum.INTERN : BlocDsiCreateSignatoryRequestDTO.ContributorTypeEnum.EXTERN,
      date: DateUtils.toZonedDateTime(signatureData.signatureDate),
      userUuide: signatureData.signatoryRef?.uuidEntity,
      firstName: signatureData.firstName,
      lastName: signatureData.lastName,
      email: signatureData.email,
      profession: signatureData.signatoryFunction,
      companyName: signatureData.companyName,
      signatoryImage: signatureData.signature,
      comment: signatureData.comment,
      signatoryNature: SignatureNatureEnum.convertToApiValue.getValue(signatureData.signatureNature),
      signatureState: SignatureStatusEnum.convertToApiValue.getValue(signatureData.signatureState),
      proxied: signatureData.proxied,
      signatureProxiedDate: DateUtils.toZonedDateTime(signatureData.signatureProxiedDate),
    };
  }

  export function mapToUpdateRequestApiValue(projectUuidEntity: string, signatureData: SignatureDetailsData): BlocDsiUpdateSignatoryRequestDTO {
    return {
      uuidEntity: signatureData.uuidEntity,
      uuideProject: projectUuidEntity,
      companyName: signatureData.companyName,
      comment: signatureData.comment,
      signatureState: SignatureStatusEnum.convertToApiValue.getValue(signatureData.signatureState),
      signatoryImage: signatureData.signature,
      signatureProxiedDate: DateUtils.toZonedDateTime(signatureData.signatureProxiedDate),
    };
  }

  export function mapToRequestApiValue(elementPrUuidEntity: string, signatureData: SignatureDetailsData): ElementPrSignatoryRequest {
    return {
      elementPrUuidEntity,
      signatureDate: signatureData.signatureDate && new Date(signatureData.signatureDate),
      userRef: signatureData.signatoryRef ? signatureData.signatoryRef.uuidEntity : null,
      imageSignature: signatureData.signature,
    };
  }
}
