import { BlocDocumentFlowSummaryResponseDTO, PageBlocRequestDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { DocumentVersionLightData } from '../document/document-version-light-data';
import { CustomWordingsData } from '../wording-config-setting/custom-wording-data';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { SfxMap } from '../../utils/enum-utils';

import { DocumentNatureEnum, DocumentStatusEnum, InnerSortDirectionEnum } from '../../enums';

export interface BlockDocumentSummaryData {
  uuidEntity: string;
  parentDocumentUuidEntity: string;
  noVdfSelected: boolean;
  name: string;
  refInt: string;
  status: DocumentStatusEnum;
  documentIndex: string;
  family: LabelFamilyLightData;
  nature: DocumentNatureEnum;
  lastVdf: DocumentVersionLightData;
  complementRefInt?: string;
  largeFile?: boolean;
  lastVersionDocumentFlowUuidEntity?: string;
  customWording: CustomWordingsData;
}

export namespace BlockDocumentSummaryData {
  const emptyDocumentIndex = '-';
  const sortFields = ['labelFamilyApplication', 'name', 'refInt', 'indice', 'etat'];
  export interface QueryRequest extends BaseQuery<BlockDocumentSummaryData> {
    blockUuidEntity: string;
    projectUuidEntity: string;
  }

  export function mapFromApiValue(document: BlocDocumentFlowSummaryResponseDTO): BlockDocumentSummaryData {
    return {
      uuidEntity: document.uuidEntity,
      parentDocumentUuidEntity: document.documentUuidEntity,
      noVdfSelected: document.noVdfSelected,
      name: document.name,
      refInt: document.refInt,
      documentIndex: document.indice || emptyDocumentIndex,
      status: DocumentStatusEnum.convertFromApiValue.getValue(document.etat),
      family: document.family && LabelFamilyLightData.mapFromApiValue(document.family),
      nature: document.natureFile && DocumentNatureEnum.convertFromApiValue.getValue(document.natureFile),
      lastVdf: document.selectedVersion && DocumentVersionLightData.mapFromApiValue(document.selectedVersion),
      complementRefInt: document.complementRefInt,
      largeFile: document.largeFile,
      lastVersionDocumentFlowUuidEntity: document.uuidEntity,
      customWording: document.customWording && CustomWordingsData.mapFromApiValue(document.customWording),
    };
  }
  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      blocUuidEntity: queries.blockUuidEntity,
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string, sort: SortFilter<BlockDocumentSummaryData>): QueryRequest {
    return {
      ...initializeQueries<BlockDocumentSummaryData>(sort),
      projectUuidEntity,
      blockUuidEntity,
    };
  }

  export const translateErrorKeys = new SfxMap<string, string>([['alreadyLinked', 'document.linkedDocVersionAlert.fail']], 'document.deleteAlert.fail');
}
