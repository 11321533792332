/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { SkillSummaryResponseDTO } from './skillSummaryResponseDTO';
import { UserRightDTO } from './userRightDTO';

export interface UserSummaryResponseDTO {
  labelFamilyFunction: LabelFamilyLightDTO;
  labelFamilyHierarchies: Array<LabelFamilyLightDTO>;
  labelFamilyTeam: LabelFamilyLightDTO;
  matricule: string;
  modificationDate: Date;
  name: string;
  skills: Array<SkillSummaryResponseDTO>;
  status: UserSummaryResponseDTO.StatusEnum;
  trigram: string;
  type: UserSummaryResponseDTO.TypeEnum;
  userRight: UserRightDTO;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}
export namespace UserSummaryResponseDTO {
  export type StatusEnum = 'ONBOARDING' | 'ACTIVATED' | 'BLOCKED' | 'DEACTIVATED' | 'DELETED';
  export const StatusEnum = {
    ONBOARDING: 'ONBOARDING' as StatusEnum,
    ACTIVATED: 'ACTIVATED' as StatusEnum,
    BLOCKED: 'BLOCKED' as StatusEnum,
    DEACTIVATED: 'DEACTIVATED' as StatusEnum,
    DELETED: 'DELETED' as StatusEnum,
  };
  export type TypeEnum = 'USER' | 'OPERATOR';
  export const TypeEnum = {
    USER: 'USER' as TypeEnum,
    OPERATOR: 'OPERATOR' as TypeEnum,
  };
}
