import { Observable, of } from 'rxjs';

import { ProjectDetailsResponseDTO } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { CustomStatusLightData } from '../../models';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum ProjectStatusEnum {
  Active = 1,
  Archive = 2,
  All = 3,
}

export namespace ProjectStatusEnum {
  const values = [ProjectStatusEnum.All, ProjectStatusEnum.Active, ProjectStatusEnum.Archive];

  export const convertFromApiValue: SfxMap<ProjectDetailsResponseDTO.StatusEnum, ProjectStatusEnum> = new SfxMap<ProjectDetailsResponseDTO.StatusEnum, ProjectStatusEnum>([
    [ProjectDetailsResponseDTO.StatusEnum.ACTIF, ProjectStatusEnum.Active],
    [ProjectDetailsResponseDTO.StatusEnum.ARCHIVE, ProjectStatusEnum.Archive],
  ]);

  export const convertToApiValue: SfxMap<ProjectStatusEnum, ProjectDetailsResponseDTO.StatusEnum> = new SfxMap<ProjectStatusEnum, ProjectDetailsResponseDTO.StatusEnum>([
    [ProjectStatusEnum.Active, ProjectDetailsResponseDTO.StatusEnum.ACTIF],
    [ProjectStatusEnum.Archive, ProjectDetailsResponseDTO.StatusEnum.ARCHIVE],
    [ProjectStatusEnum.All, undefined],
  ]);

  export const convertFromParamsValue: SfxMap<string, ProjectStatusEnum> = new SfxMap<string, ProjectStatusEnum>([
    ['active', ProjectStatusEnum.Active],
    ['archive', ProjectStatusEnum.Archive],
    ['all', ProjectStatusEnum.All],
  ]);

  export const convertToParamsValue: SfxMap<ProjectStatusEnum, string> = new SfxMap<ProjectStatusEnum, string>([
    [ProjectStatusEnum.Active, 'active'],
    [ProjectStatusEnum.Archive, 'archive'],
    [ProjectStatusEnum.All, 'all'],
  ]);

  const key = 'projects.filters.status.';
  export const toString: SfxMap<ProjectStatusEnum, string> = new SfxMap<ProjectStatusEnum, string>([
    [ProjectStatusEnum.Active, key + 'actif'],
    [ProjectStatusEnum.Archive, key + 'archive'],
    [ProjectStatusEnum.All, key + 'all'],
  ]);

  export const iconData: SfxMap<ProjectStatusEnum, IconData> = new SfxMap<ProjectStatusEnum, IconData>([
    [ProjectStatusEnum.Active, { name: 'eye', prefix: 'fas' }],
    [ProjectStatusEnum.Archive, { name: 'eye-slash', prefix: 'fas' }],
  ]);

  export const flowStatusContent: SfxMap<ProjectStatusEnum, CustomStatusLightData> = new SfxMap<ProjectStatusEnum, CustomStatusLightData>([
    [ProjectStatusEnum.Active, { name: 'projects.filters.status.actif', color: '#0d4e8a' } as CustomStatusLightData],
    [ProjectStatusEnum.Archive, { name: 'projects.filters.status.archive', color: '#0d4e8a' } as CustomStatusLightData],
  ]);

  function mapToSingleSelectItem(status: ProjectStatusEnum): SingleSelectItemData<ProjectStatusEnum> {
    return {
      value: status,
      title: toString.getValue(status),
    };
  }

  export const selectItems: SingleSelectItemData<ProjectStatusEnum>[] = values.map(status => mapToSingleSelectItem(status));

  export const itemsResolver = (): Observable<SingleSelectItemData<ProjectStatusEnum>[]> => of(values.map(status => ({ title: toString.getValue(status), value: status })));
  export const comparePredicate = (node: SingleSelectItemData<ProjectStatusEnum>, item: ProjectStatusEnum) => node.value === item;
}
