import { ElementEmmSummaryResponseDTO, PageBlocRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum } from '../../enums';

export interface EmmElementSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  name: string;
  quantity: number;
  unit: string;
  reference: string;
  comment: string;
  displayComment: boolean;
  consumable: boolean;
  provider: string;
  isExpired: boolean;
  verification: boolean;
  disabled?: boolean;
}

export namespace EmmElementSummaryData {
  const sortFields = ['labelFamily', 'name', 'quantity', 'unit', 'reference'];

  export interface QueryRequest extends BaseQuery<EmmElementSummaryData> {
    projectUuidEntity?: string;
    blockUuidEntity?: string;
  }

  export function mapFromApiValue(emmElement: ElementEmmSummaryResponseDTO): EmmElementSummaryData {
    return {
      uuidEntity: emmElement.uuidEntity,
      name: emmElement.name,
      family: emmElement.labelFamily && LabelFamilyLightData.mapFromApiValue(emmElement.labelFamily),
      quantity: emmElement.quantity,
      unit: emmElement.unit,
      reference: emmElement.reference,
      consumable: emmElement.consumable,
      provider: emmElement.provider,
      isExpired: emmElement.expired,
      verification: emmElement.verification,
      comment: emmElement.commentaire,
      displayComment: emmElement.displayComment,
      disabled: emmElement.disabled,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: undefined,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(projectUuidEntity: string, blockUuidEntity: string, sort: SortFilter<EmmElementSummaryData>): QueryRequest {
    return {
      ...initializeQueries<EmmElementSummaryData>(sort),
      projectUuidEntity,
      blockUuidEntity,
    };
  }
}
