import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { CustomFileSummaryResponseDTO, CustomFilePageRequestDTO } from '../../generated/models/models';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum, CustomFileExtensionEnum, CustomFileNatureEnum } from '../../enums';
import { CustomFileTypeEnum } from '../../enums/custom-file/custom-file-type.enum';
export interface CustomFileSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
  nature: CustomFileNatureEnum;
  extension: CustomFileExtensionEnum;
  type: CustomFileTypeEnum;
  order: number;
}

export namespace CustomFileSummaryData {
  const sortFields = ['extension', 'name'];
  export interface QueryRequest extends BaseQuery<CustomFileSummaryData> {
    nature: CustomFileNatureEnum;
    extension: CustomFileExtensionEnum;
    customFileType?: CustomFileTypeEnum;
  }

  export function mapFromApiValue(customFile: CustomFileSummaryResponseDTO): CustomFileSummaryData {
    return {
      name: customFile.name,
      description: customFile?.description,
      uuidEntity: customFile.uuidEntity,
      nature: CustomFileNatureEnum.convertFromApiValue.getValue(customFile.nature),
      extension: CustomFileExtensionEnum.convertFromApiValue.getValue(customFile.extension),
      order: customFile.order,
      type: CustomFileTypeEnum.convertFromApiValue.getValue(customFile.customFileType),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): CustomFilePageRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      nature: CustomFileNatureEnum.convertToApiValue.getValue(queries.nature) || undefined,
      extension: CustomFileExtensionEnum.convertToApiValue.getValue(queries.extension) || undefined,
      customFileType: CustomFileTypeEnum.convertToApiValue.getValue(queries.customFileType),
    };
  }

  export function initializeQueryRequest(nature: string, type: string, extension: string, searchText: string, sort: SortFilter<CustomFileSummaryData>): QueryRequest {
    return {
      ...initializeQueries<CustomFileSummaryData>({ ...sort, key: (!!nature && !!extension && 'order') || sort.key }),
      textSearch: searchText || undefined,
      nature: CustomFileNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      extension: CustomFileExtensionEnum.convertFromParamsValue.getValue(extension) || undefined,
      customFileType: CustomFileTypeEnum.convertFromParamsValue.getValue(type) || undefined,
    };
  }

  export function initializeFilterQueries(
    page?: number,
    searchText?: string,
    nature?: CustomFileNatureEnum,
    extension?: CustomFileExtensionEnum,
    customFileType?: CustomFileTypeEnum,
  ): QueryRequest {
    return {
      ...initializeQueries<CustomFileSummaryData>({ key: 'order' }),
      page: page || 0,
      textSearch: searchText || undefined,
      nature,
      extension,
      customFileType,
    };
  }
}
