import { KpiGlobalLicencesResponseDTO } from '../../generated/models/models';

export interface GlobalLicensesMetricsData {
  webUsersCount: number;
  activeWebUsersCount: number;
  mobileUsersCount: number;
  activeMobileUsersCount: number;
  externalMobileUsersCount: number;
  tabletUsersCount: number;
  activeTabletUsersCount: number;
  signatoryCount: number;
}

export namespace GlobalLicensesMetricsData {
  export function mapFromApiValue(metrics: KpiGlobalLicencesResponseDTO): GlobalLicensesMetricsData {
    return {
      webUsersCount: metrics.webUsersCount,
      activeWebUsersCount: metrics.activeWebUsersCount,
      mobileUsersCount: metrics.mobileUsersCount,
      activeMobileUsersCount: metrics.activeMobileUsersCount,
      externalMobileUsersCount: metrics.externalMobileUsersCount,
      tabletUsersCount: metrics.tabletUsersCount,
      activeTabletUsersCount: metrics.activeTabletUsersCount,
      signatoryCount: metrics.nbSignatories,
    };
  }
}
