import { FlowSummaryData, TemporalElementPlanSummaryData } from '../../models';
import { AffectationInfoData } from './affectation-info.data';

export interface FlowTeamRolesAffectationData {
  reporter: AffectationInfoData;
  assignee: AffectationInfoData;
  tablet1: AffectationInfoData;
  tablet2: AffectationInfoData;
  tablet3: AffectationInfoData;
  contributors: AffectationInfoData[];
}

export namespace FlowTeamRolesAffectationData {
  export interface Dialog {
    affectations: FlowTeamRolesAffectationData;
    flowUuidEntity: string;
  }

  export function mapFromFlowSummary(flow: FlowSummaryData): FlowTeamRolesAffectationData {
    return {
      reporter: flow.reporterUser && AffectationInfoData.mapFromEntityLightData(flow.reporterUser),
      assignee: flow.assignedUser && AffectationInfoData.mapFromEntityLightData(flow.assignedUser),
      tablet1: flow.userActor1 && AffectationInfoData.mapFromEntityLightData(flow.userActor1),
      tablet2: flow.userActor2 && AffectationInfoData.mapFromEntityLightData(flow.userActor2),
      tablet3: flow.userActor3 && AffectationInfoData.mapFromEntityLightData(flow.userActor3),
      contributors: (flow.contributors || []).map(contributor => AffectationInfoData.mapFromEntityLightData(contributor)),
    };
  }

  export function mapFromElementPlan(elementPlan: TemporalElementPlanSummaryData): FlowTeamRolesAffectationData {
    return {
      reporter: elementPlan.reporter && AffectationInfoData.mapFromUserLightData(elementPlan.reporter),
      assignee: elementPlan.assignee && AffectationInfoData.mapFromUserLightData(elementPlan.assignee),
      tablet1: elementPlan.tablet1 && AffectationInfoData.mapFromUserLightData(elementPlan.tablet1),
      tablet2: elementPlan.tablet2 && AffectationInfoData.mapFromUserLightData(elementPlan.tablet2),
      tablet3: elementPlan.tablet3 && AffectationInfoData.mapFromUserLightData(elementPlan.tablet3),
      contributors: (elementPlan.contributors || []).map(contributor => AffectationInfoData.mapFromUserLightData(contributor)),
    };
  }
}
