import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './wording-config-dialog.actions';

import { LanguageConfigDetailsData } from '../../../../../core/models/wording-config-setting/language-config-details-data';
import { WordingConfigDetailsData } from '../../../../../core/models/wording-config-setting/wording-config-details-data';
import { WordingConfigFieldDetailsData } from '../../../../../core/models/wording-config-setting/wording-config-field-details';

import { WordingConfigActionEnum } from '../../../../../core/enums/wording-configurations/wording-config-action.enum';
export interface AddWordingConfigDialogState {
  saveLoading: boolean;
  wordingConfigDetails: WordingConfigDetailsData;
}

const initialAddWordingConfigDialogState = (): AddWordingConfigDialogState => ({
  saveLoading: false,
  wordingConfigDetails: {} as WordingConfigDetailsData,
});

export interface AddWordingConfigLanguageDialogState {
  saveLoading: boolean;
  wordingConfigLanguageDetails: LanguageConfigDetailsData;
}

const initialFields = () => {
  const bechir = {};
  WordingConfigActionEnum.values.forEach(val => (bechir[val] = { actionKey: val } as WordingConfigFieldDetailsData));

  return bechir;
};

const initialAddWordingConfigLanguageDialogState = (): AddWordingConfigLanguageDialogState => ({
  saveLoading: false,
  wordingConfigLanguageDetails: {
    configurationFields: initialFields(),
    language: null,
    uuid: null,
    uuidEntity: null,
    uuidOrganization: null,
    uuidType: null,
    wordingConfigurationUuide: null,
  } as LanguageConfigDetailsData,
});

export interface WordingConfigDialogState {
  addWordingConfig: AddWordingConfigDialogState;
  addWordingConfigLanguage: AddWordingConfigLanguageDialogState;
}

export const initialWordingConfigDialogState = () => ({
  addWordingConfig: initialAddWordingConfigDialogState(),
  addWordingConfigLanguage: initialAddWordingConfigLanguageDialogState(),
});

const reducer = createReducer<WordingConfigDialogState>(
  initialWordingConfigDialogState(),

  on(
    fromActions.createWordingConfig,
    fromActions.updateWordingConfig,
    (state): WordingConfigDialogState => ({ ...state, addWordingConfig: { ...state.addWordingConfig, saveLoading: true } }),
  ),
  on(
    fromActions.createWordingConfigFail,
    fromActions.updateWordingConfigFail,
    (state): WordingConfigDialogState => ({ ...state, addWordingConfig: { ...state.addWordingConfig, saveLoading: false } }),
  ),
  on(
    fromActions.createWordingConfigSuccess,
    fromActions.updateWordingConfigSuccess,
    (state): WordingConfigDialogState => ({ ...state, addWordingConfig: { ...state.addWordingConfig, saveLoading: false } }),
  ),

  on(
    fromActions.loadWordingConfigDetailsDataSuccess,
    (state, { wordingConfigDetails }): WordingConfigDialogState => ({ ...state, addWordingConfig: { ...state.addWordingConfig, wordingConfigDetails } }),
  ),
  on(fromActions.createLanguageConfig, (state): WordingConfigDialogState => ({ ...state, addWordingConfigLanguage: { ...state.addWordingConfigLanguage, saveLoading: true } })),
  on(
    fromActions.createLanguageConfigFail,
    fromActions.updateLanguageConfigFail,
    (state): WordingConfigDialogState => ({ ...state, addWordingConfigLanguage: { ...state.addWordingConfigLanguage, saveLoading: false } }),
  ),
  on(
    fromActions.createLanguageConfigSuccess,
    (state, { languageConfig }): WordingConfigDialogState => ({
      ...state,
      addWordingConfigLanguage: {
        ...state.addWordingConfigLanguage,
        wordingConfigLanguageDetails: languageConfig,
        saveLoading: false,
      },
    }),
  ),
  on(
    fromActions.loadLanguageConfigDetailsDataSuccess,
    (state, { languageConfig }): WordingConfigDialogState => ({
      ...state,
      addWordingConfigLanguage: { ...state.addWordingConfigLanguage, wordingConfigLanguageDetails: languageConfig, saveLoading: false },
    }),
  ),
);
export function wordingConfigDialogReducer(state: WordingConfigDialogState | undefined, action: Action): WordingConfigDialogState {
  return reducer(state, action);
}
