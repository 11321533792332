import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { cloneDeep } from 'lodash';

import * as fromActions from './form-library-dialog.actions';
import { dummyAction } from '../../../../main/state/main.actions';

import {
  ErrorResponseData,
  FormLibrarySummaryData,
  FormLibraryDetailsData,
  FormLibraryForDuplicate,
  LazyLoadResponse,
  ApplicationEventLightData,
} from '../../../../../core/models';

export interface FormDialogState {
  formDetails: FormLibraryDetailsData;
  initialFormDetails: FormLibraryDetailsData;
  hasReferenceGenerated: boolean;
  saveLoading: boolean;
}

export interface DuplicateDialogState {
  duplicatedForm: FormLibraryForDuplicate;
  duplicateLoading: boolean;
}

export interface FormReorganizeState extends EntityState<FormLibrarySummaryData> {
  ids: string[];
  loading: boolean;
  error: ErrorResponseData;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

export interface HistoryDialogState {
  historyList: LazyLoadResponse<ApplicationEventLightData[]>;
  reset: boolean;
}

export interface LibraryFormDialogState {
  formDialogState: FormDialogState;
  duplicateDialogState: DuplicateDialogState;
  formReorganizeState: FormReorganizeState;
  historyDialogState: HistoryDialogState;
}

const initialFormDialogState: FormDialogState = {
  formDetails: null,
  initialFormDetails: null,
  hasReferenceGenerated: false,
  saveLoading: false,
};

const initialDuplicateDialogState: DuplicateDialogState = {
  duplicatedForm: null,
  duplicateLoading: false,
};

const initialHistoryDialogState = (): HistoryDialogState => ({
  historyList: {} as LazyLoadResponse<ApplicationEventLightData[]>,
  reset: false,
});

const reorganizeAdapter: EntityAdapter<FormLibrarySummaryData> = createEntityAdapter<FormLibrarySummaryData>({
  selectId: form => form.uuidEntity,
});

export const initialFormReorganizeState = (): FormReorganizeState =>
  reorganizeAdapter.getInitialState<FormReorganizeState>({
    ids: [],
    entities: {},
    loading: false,
    error: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

export const initialLibraryFormDialogState = () => ({
  formDialogState: { ...initialFormDialogState },
  duplicateDialogState: { ...initialDuplicateDialogState },
  formReorganizeState: initialFormReorganizeState(),
  historyDialogState: initialHistoryDialogState(),
});

const reducer = createReducer<LibraryFormDialogState>(
  initialLibraryFormDialogState(),
  on(fromActions.addForm, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: true } })),
  on(fromActions.addFormFail, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: false } })),
  on(
    fromActions.addFormSuccess,
    (state, { formDetails }): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, formDetails, saveLoading: false } }),
  ),
  on(fromActions.updateForm, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: true } })),
  on(fromActions.updateFormFail, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: false } })),
  on(fromActions.updateFormSuccess, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: false } })),
  on(fromActions.updateFormTemplateParams, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: true } })),
  on(
    fromActions.toggleFormTemplateParams,
    (state, { useTemplate }): LibraryFormDialogState => ({
      ...state,
      formDialogState: {
        ...state.formDialogState,
        formDetails: { ...state.formDialogState.formDetails, useTemplate, templateParams: { ...state.formDialogState.formDetails.templateParams, useTemplate } },
      },
    }),
  ),
  on(fromActions.getFormDetailsSuccess, (state, { formDetails }): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, formDetails } })),
  on(
    fromActions.initializeFormDetails,
    (state, { formDetails }): LibraryFormDialogState => ({
      ...state,
      formDialogState: { ...state.formDialogState, initialFormDetails: cloneDeep(formDetails) },
    }),
  ),
  on(
    fromActions.checkReferenceGeneratedSuccess,
    (state, { hasReferenceGenerated, reset }): LibraryFormDialogState => ({
      ...state,
      formDialogState: {
        ...state.formDialogState,
        hasReferenceGenerated,
        formDetails: {
          ...state.formDialogState.formDetails,
          reference: hasReferenceGenerated && reset ? null : state.formDialogState?.formDetails?.reference,
        },
      },
    }),
  ),
  on(fromActions.getDuplicatedFormSuccess, (state, { duplicatedForm }) => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicatedForm } })),

  on(fromActions.duplicateForm, (state): LibraryFormDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: true } })),
  on(fromActions.duplicateFormFail, (state): LibraryFormDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } })),
  on(fromActions.duplicateFormSuccess, (state): LibraryFormDialogState => ({ ...state, duplicateDialogState: { ...state.duplicateDialogState, duplicateLoading: false } })),
  on(fromActions.loadFormReorganizeList, (state): LibraryFormDialogState => ({ ...state, formReorganizeState: { ...state.formReorganizeState, loading: true } })),
  on(
    fromActions.loadFormReorganizeListSuccess,
    (state, { forms, totalCount, filteredTotalCount, reset }): LibraryFormDialogState => {
      const newState: FormReorganizeState = { ...state.formReorganizeState, loading: false, reset, totalCount, filteredTotalCount };

      return { ...state, formReorganizeState: reset ? reorganizeAdapter.setAll(forms, newState) : reorganizeAdapter.addMany(forms, newState) };
    },
  ),
  on(
    fromActions.loadFormReorganizeListFail,
    (state, { error }): LibraryFormDialogState => ({ ...state, formReorganizeState: { ...state.formReorganizeState, error, loading: false } }),
  ),
  on(
    fromActions.loadHistoryListSuccess,
    (state, { data, reset }): LibraryFormDialogState => ({
      ...state,
      historyDialogState: {
        ...state.historyDialogState,
        reset,
        historyList: reset ? data : { ...state.historyDialogState.historyList, payload: [...state.historyDialogState.historyList.payload, ...data.payload] },
      },
    }),
  ),

  on(dummyAction, (state): LibraryFormDialogState => ({ ...state, formDialogState: { ...state.formDialogState, saveLoading: false } })),
);

export function libraryFormDialogReducer(state: LibraryFormDialogState | undefined, action: Action): LibraryFormDialogState {
  return reducer(state, action);
}

export const selectAllForms = reorganizeAdapter.getSelectors().selectAll;
