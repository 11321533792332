import { compact } from 'lodash';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';

export enum FlowContentModalTabEnum {
  General = '_general',
  CustomField = '_customField',
  Document = '_referentialFlowDocument',
  Assignment = '_assignment',
  Planning = '_planning',
  Options = '_options',
}

export namespace FlowContentModalTabEnum {
  export function getValues(flowType?: FlowTypeEnum): string[] {
    return compact([
      FlowContentModalTabEnum.General,
      FlowContentModalTabEnum.CustomField,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowContentModalTabEnum.Document,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowContentModalTabEnum.Assignment,
      FlowTypeEnum.flowsWhereHaveAllTab.includes(flowType) && FlowContentModalTabEnum.Planning,
      flowType !== FlowTypeEnum.FlowForm && FlowContentModalTabEnum.Options,
    ]);
  }

  const key = 'flowContentNavigation.modals.addFlow.';
  export const toString: SfxMap<FlowContentModalTabEnum, string> = new SfxMap<FlowContentModalTabEnum, string>([
    [FlowContentModalTabEnum.General, key + 'general'],
    [FlowContentModalTabEnum.CustomField, key + 'customAttr'],
    [FlowContentModalTabEnum.Document, key + 'document'],
    [FlowContentModalTabEnum.Assignment, key + 'assignment'],
    [FlowContentModalTabEnum.Planning, key + 'planning'],
    [FlowContentModalTabEnum.Options, key + 'options'],
  ]);

  export const iconData: SfxMap<FlowContentModalTabEnum, IconData> = new SfxMap<FlowContentModalTabEnum, IconData>([
    [FlowContentModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [FlowContentModalTabEnum.CustomField, { name: 'paint-roller', prefix: 'fas' }],
    [FlowContentModalTabEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [FlowContentModalTabEnum.Assignment, { name: 'user-friends', prefix: 'fas' }],
    [FlowContentModalTabEnum.Planning, { name: 'calendar-alt', prefix: 'far' }],
    [FlowContentModalTabEnum.Options, { name: 'file-invoice', prefix: 'fas' }],
  ]);
}
