import { CustomAttributeResponseDTO, CustomFieldLightDTO } from '../../generated/models/models';

export interface CustomFieldLightData {
  uuidEntity: string;
  uuidType: string;
  name: string;
}

export namespace CustomFieldLightData {
  export function mapFromApiValue(customField: CustomFieldLightDTO | CustomAttributeResponseDTO): CustomFieldLightData {
    return {
      uuidEntity: customField.uuidEntity,
      uuidType: customField.uuidType,
      name: customField.name,
    };
  }
}
