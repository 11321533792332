import { SfxMap } from '../../utils/enum-utils';
import { FormProvenanceEnum } from './form-provenance.enum';
import { FormTypeEnum } from './form-type.enum';

export enum FormModeEnum {
  ResponseMode = 1,
  BuildMode = 2,
  ViewMode = 3,
  ResponseViewMode = 4,
}

export namespace FormModeEnum {
  export const libraryMode = [FormModeEnum.BuildMode, FormModeEnum.ViewMode];
  export const whereValueShouldBeVisible = [FormModeEnum.ResponseMode, FormModeEnum.ResponseViewMode];
  export const viewMode = [FormModeEnum.ViewMode, FormModeEnum.ResponseViewMode];

  export const convertFromFromType: SfxMap<FormTypeEnum, FormModeEnum> = new SfxMap<FormTypeEnum, FormModeEnum>([
    [FormTypeEnum.Response, FormModeEnum.ResponseMode],
    [FormTypeEnum.ResponseDone, FormModeEnum.ResponseMode],
    [FormTypeEnum.Other, FormModeEnum.ResponseMode],
    [FormTypeEnum.Builder, FormModeEnum.BuildMode],
  ]);

  export const toString: SfxMap<FormModeEnum, string> = new SfxMap<FormModeEnum, string>([
    [FormModeEnum.ResponseMode, 'forms.mode.responseMode'],
    [FormModeEnum.ResponseViewMode, 'forms.mode.responseViewMode'],
    [FormModeEnum.ViewMode, 'forms.mode.viewMode'],
    [FormModeEnum.BuildMode, 'forms.mode.buildMode'],
  ]);

  export function isSwitchModeAuthorized(formMode: FormModeEnum, provenance: string) {
    const switchableProvenances = [FormProvenanceEnum.Library, FormProvenanceEnum.LocalCopy].includes(FormProvenanceEnum.convertFormParamsValue.getValue(provenance));

    return libraryMode.includes(formMode) && switchableProvenances;
  }
}
