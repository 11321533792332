import { UserUpdateRequestDTO } from '../../generated/models/models';

import { SfxMap } from '../../utils/enum-utils';

export enum AdditionalAuthEnum {
  QHSE = 1,
  UserModelForm = 2,
  ManageDiffusion = 3,
}

export namespace AdditionalAuthEnum {
  export const convertToApiValue = new SfxMap<AdditionalAuthEnum, UserUpdateRequestDTO.AdditionalAuthoritiesEnum>([
    [AdditionalAuthEnum.QHSE, UserUpdateRequestDTO.AdditionalAuthoritiesEnum.QHSE],
    [AdditionalAuthEnum.UserModelForm, UserUpdateRequestDTO.AdditionalAuthoritiesEnum.USERMODELFORM],
    [AdditionalAuthEnum.ManageDiffusion, UserUpdateRequestDTO.AdditionalAuthoritiesEnum.MANAGEDIFFUSION],
  ]);

  export const convertFromApiValue = new SfxMap<UserUpdateRequestDTO.AdditionalAuthoritiesEnum, AdditionalAuthEnum>([
    [UserUpdateRequestDTO.AdditionalAuthoritiesEnum.QHSE, AdditionalAuthEnum.QHSE],
    [UserUpdateRequestDTO.AdditionalAuthoritiesEnum.USERMODELFORM, AdditionalAuthEnum.UserModelForm],
    [UserUpdateRequestDTO.AdditionalAuthoritiesEnum.MANAGEDIFFUSION, AdditionalAuthEnum.ManageDiffusion],
  ]);
}
