export namespace IntercomData {
  export const appId = 't9hy3kjm';
  export const guidedTourQueryParam = 'product_tour_id';
  export const helpCenterLink = 'https://helpcenter.siteflow.app/fr/';
  export const scriptFile = 'https://widget.intercom.io/widget/' + appId;

  export function shutdown(): void {
    if (window['Intercom']) {
      window['Intercom']('shutdown');
    }
  }
}
