import { SfxMap } from '../../utils/enum-utils';

export enum ImportEmmColumnEnum {
  Family = 'family',
  Name = 'name',
  Quantity = 'quantity',
  Reference = 'reference',
  Provider = 'provider',
  Action = 'action',
}

export namespace ImportEmmColumnEnum {
  export const allColumns = [
    ImportEmmColumnEnum.Family,
    ImportEmmColumnEnum.Name,
    ImportEmmColumnEnum.Quantity,
    ImportEmmColumnEnum.Reference,
    ImportEmmColumnEnum.Provider,
    ImportEmmColumnEnum.Action,
  ];

  const key = 'blocks.emm.table.header.';
  export const toString: SfxMap<ImportEmmColumnEnum, string> = new SfxMap<ImportEmmColumnEnum, string>([
    [ImportEmmColumnEnum.Family, key + 'family'],
    [ImportEmmColumnEnum.Name, key + 'name'],
    [ImportEmmColumnEnum.Quantity, key + 'quantity'],
    [ImportEmmColumnEnum.Reference, key + 'reference'],
    [ImportEmmColumnEnum.Provider, key + 'provider'],
    [ImportEmmColumnEnum.Action, key + 'action'],
  ]);

  export const sortField: SfxMap<ImportEmmColumnEnum, string> = new SfxMap<ImportEmmColumnEnum, string>([
    [ImportEmmColumnEnum.Family, 'labelFamily'],
    [ImportEmmColumnEnum.Name, 'name'],
    [ImportEmmColumnEnum.Quantity, 'quantity'],
    [ImportEmmColumnEnum.Reference, 'reference'],
    [ImportEmmColumnEnum.Provider, 'provider'],
  ]);
}
