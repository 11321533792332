import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NumberFormatUtils } from '../../../modules/shared/helpers/number-format-utils';

@Directive({
  selector: '[sfxNumberControl]',
  standalone: true,
})
export class NumberControlDirective {
  @Input() decimal = false;
  @Input() applyControl = false;

  @Output() inverseSign = new EventEmitter<void>();
  @Output() pasteEvent = new EventEmitter<string>();

  private decimalSeparator = NumberFormatUtils.getNumberFormatDecimal();
  private decimalCounter = 0;
  private navigationKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'];
  private allowedChars = ['a', 'c', 'v', 'x'];

  private inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.decimal && event.key === '-') {
      event.preventDefault();
      this.inverseSign.emit();
    }

    if (
      !this.applyControl ||
      !(event.key === ' ' || isNaN(Number(event.key))) ||
      this.navigationKeys.includes(event.key) || // allow navigationKeys
      ((event.ctrlKey || event.metaKey) && this.allowedChars.includes(event.key)) || // allow selection copy cut and paste
      (this.decimal && event.key === this.decimalSeparator && this.decimalCounter < 1)
    ) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(): void {
    if (this.decimal) {
      this.decimalCounter = this.el.nativeElement.value.split(this.decimalSeparator).length - 1;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedInput: string = event.clipboardData.getData('text/plain');
    this.pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    const textData = event.dataTransfer.getData('text');
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const { selectionStart: start, selectionEnd: end } = this.inputElement;

    this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
    this.pasteEvent.emit(this.inputElement.value);
  }

  private sanitizeInput(input: string): string {
    let result = '';
    if (this.decimal && this.isValidDecimal(input)) {
      const regex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
      result = input.replace(regex, '');
    } else {
      result = input.replace(/[^0-9]/g, '');
    }

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }

    return this.applyControl ? result : input;
  }

  private isValidDecimal(value: string): boolean {
    return value.split(this.decimalSeparator).length <= 2;
  }
}
