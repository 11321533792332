import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import {
  ErrorResponseData,
  ListDetailsData,
  LazyLoadResponse,
  ListEmmSummaryData,
  ListAdrSummaryData,
  ImportEmmOptions,
  ImportAdrOptions,
  EmmSummaryData,
  AdrSummaryData,
} from '../../../../../core/models';

import { PopoverPositionEnum } from '../../../../../core/enums';

export const loadListDetails = createAction('[dList/API] Load list details data', props<{ listUuidEntity: string }>());
export const loadListDetailsSuccess = createAction('[dList/API] load list details data success', props<ListDetailsData>());
export const loadListDetailsFail = createAction('[dList/API] load list details data fail', props<DialogToasterData>());

export const createList = createAction('[dList/API] create list', props<{ list: ListDetailsData }>());
export const createListSuccess = createAction('[dList/API] create list success', props<{ list: ListDetailsData }>());
export const createListFail = createAction('[dList/API] create list fail', props<DialogToasterData>());

export const updateList = createAction('[dList/API] update list', props<{ list: ListDetailsData }>());
export const updateListSuccess = createAction('[dList/API] update list success', props<{ list: ListDetailsData }>());
export const updateListFail = createAction('[dList/API] update list fail', props<DialogToasterData>());

export const openImportPopover = createAction('[dList/API] open emm options import popover', props<{ origin: HTMLElement; position: PopoverPositionEnum; uuidEntity: string }>());

export const loadEmmList = createAction('[dList/API] Load Emm list');
export const loadEmmListSuccess = createAction('[dList/API] Load Emm list success', props<{ lazyResponse: LazyLoadResponse<ListEmmSummaryData[]>; reset?: boolean }>());
export const loadEmmListNextPage = createAction('[dList/API] Load next Emm list page');
export const loadEmmListFail = createAction('[dList/API] Load Emm list fails', props<DialogToasterData>());

export const importEmmOptions = createAction('[dList/API] import Emm list options', props<{ emmToImport: ImportEmmOptions }>());
export const importEmmOptionsSuccess = createAction('[dList/API] import Emm list options success');
export const importEmmOptionsFail = createAction('[dList/API] import Emm list options fail', props<DialogToasterData>());

export const loadAdrList = createAction('[dList/API] Load Adr list');
export const loadAdrListSuccess = createAction('[dList/API] Load Adr list success', props<{ lazyResponse: LazyLoadResponse<ListAdrSummaryData[]>; reset?: boolean }>());
export const loadAdrListNextPage = createAction('[dList/API] Load next Adr list page');
export const loadAdrListFail = createAction('[dList/API] Load Adr list fails', props<DialogToasterData>());

export const importAdrOptions = createAction('[dList/API] import Ard list options', props<{ adrToImport: ImportAdrOptions }>());
export const importAdrOptionsSuccess = createAction('[dList/API] import Adr list options success');
export const importAdrOptionsFail = createAction('[dList/API] import Adr list options fail', props<DialogToasterData>());

export const loadImportedEmmList = createAction('[dList/API] Load Imported Emm list');
export const loadImportedEmmListSuccess = createAction(
  '[dList/API] Load Imported Emm list success',
  props<{ lazyResponse: LazyLoadResponse<ListEmmSummaryData[]>; reset?: boolean }>(),
);
export const loadImportedEmmListNextPage = createAction('[dList/API] Load Imported next Emm list page');
export const loadImportedEmmListFail = createAction('[dList/API] Load Imported Emm list fails', props<DialogToasterData>());

export const loadImportedAdrList = createAction('[dList/API] Load Imported Adr list');
export const loadImportedAdrListSuccess = createAction(
  '[dList/API] Load Imported Adr list success',
  props<{ lazyResponse: LazyLoadResponse<ListAdrSummaryData[]>; reset?: boolean }>(),
);
export const loadImportedAdrListNextPage = createAction('[dList/API] Load Imported next Adr list page');
export const loadImportedAdrListFail = createAction('[dList/API] Load Imported Adr list fails', props<DialogToasterData>());

export const deleteEmm = createAction('[dList/API] delete emm list', props<ListEmmSummaryData>());
export const deleteEmmFail = createAction('[cTablets/API] delete emm list fails', props<{ error: ErrorResponseData }>());

export const deleteAdr = createAction('[dList/API] delete adr list', props<ListAdrSummaryData>());
export const deleteAdrFail = createAction('[dList/API] delete adr list fails', props<{ error: ErrorResponseData }>());

export const downloadPdfFrame = createAction('[dList/API] download pdf frame visualization', props<{ uuidEntity: string }>());
export const downloadPdfFrameFail = createAction('[dList/API] download pdf frame visualization fail', props<DialogToasterData>());
export const downloadPdfFrameSuccess = createAction('[dList/API] download pdf frame visualization success');

export const loadEmmDetails = createAction('[dList/API] Load visualize Emm details data', props<{ uuidEntity: string; projectUuidEntity: string }>());
export const loadEmmDetailsSuccess = createAction('[dList/API] load visualize Emm details data success', props<{ details: EmmSummaryData }>());
export const loadEmmDetailsFail = createAction('[dList/API] load visualize Emm details data fail', props<{ error: ErrorResponseData }>());

export const loadAdrDetails = createAction('[dList/API] Load visualize Adr details data', props<{ uuidEntity: string; projectUuidEntity: string }>());
export const loadAdrDetailsSuccess = createAction('[dList/API] Load visualize Adr details data success', props<{ details: AdrSummaryData }>());
export const loadAdrDetailsFail = createAction('[dList/API] Load visualize Adr details data fail', props<{ error: ErrorResponseData }>());
