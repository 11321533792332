import { Frequency } from 'rrule';
import { Observable, of } from 'rxjs';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FrequencyNatureEnum {
  Yearly = 1,
  Monthly = 2,
  Weekly = 3,
  Daily = 4,
  Hourly = 5,
  Minute = 6,
}

export namespace FrequencyNatureEnum {
  const values = [FrequencyNatureEnum.Yearly, FrequencyNatureEnum.Monthly, FrequencyNatureEnum.Weekly, FrequencyNatureEnum.Daily, FrequencyNatureEnum.Hourly];
  const toleranceValues = [FrequencyNatureEnum.Yearly, FrequencyNatureEnum.Monthly, FrequencyNatureEnum.Daily, FrequencyNatureEnum.Hourly, FrequencyNatureEnum.Minute];

  const key = 'schedule.frequency.';
  export const toString = new SfxMap<FrequencyNatureEnum, string>([
    [FrequencyNatureEnum.Yearly, key + 'yearly'],
    [FrequencyNatureEnum.Monthly, key + 'monthly'],
    [FrequencyNatureEnum.Weekly, key + 'weekly'],
    [FrequencyNatureEnum.Daily, key + 'daily'],
    [FrequencyNatureEnum.Hourly, key + 'hourly'],
  ]);

  const toleranceKey = 'schedule.tolerance.';
  export const toToleranceString = new SfxMap<FrequencyNatureEnum, string>([
    [FrequencyNatureEnum.Minute, toleranceKey + 'minute'],
    [FrequencyNatureEnum.Hourly, toleranceKey + 'hour'],
    [FrequencyNatureEnum.Daily, toleranceKey + 'day'],
    [FrequencyNatureEnum.Monthly, toleranceKey + 'month'],
    [FrequencyNatureEnum.Yearly, toleranceKey + 'year'],
  ]);

  const descriptionKey = 'schedule.addModal.expression.';
  export const toDescription = new SfxMap<FrequencyNatureEnum, string>([
    [FrequencyNatureEnum.Yearly, descriptionKey + 'years'],
    [FrequencyNatureEnum.Monthly, descriptionKey + 'months'],
    [FrequencyNatureEnum.Weekly, descriptionKey + 'weeks'],
    [FrequencyNatureEnum.Daily, descriptionKey + 'days'],
    [FrequencyNatureEnum.Hourly, descriptionKey + 'hours'],
  ]);

  export const convertFromApiValue = new SfxMap<Frequency, FrequencyNatureEnum>([
    [Frequency.YEARLY, FrequencyNatureEnum.Yearly],
    [Frequency.MONTHLY, FrequencyNatureEnum.Monthly],
    [Frequency.WEEKLY, FrequencyNatureEnum.Weekly],
    [Frequency.DAILY, FrequencyNatureEnum.Daily],
    [Frequency.HOURLY, FrequencyNatureEnum.Hourly],
  ]);

  export const convertToApiValue = new SfxMap<FrequencyNatureEnum, Frequency>([
    [FrequencyNatureEnum.Yearly, Frequency.YEARLY],
    [FrequencyNatureEnum.Monthly, Frequency.MONTHLY],
    [FrequencyNatureEnum.Weekly, Frequency.WEEKLY],
    [FrequencyNatureEnum.Daily, Frequency.DAILY],
    [FrequencyNatureEnum.Hourly, Frequency.HOURLY],
  ]);

  export const comparePredicateFrequency = (node: SingleSelectItemData<FrequencyNatureEnum>, item: FrequencyNatureEnum) => node.value === item;
  export const selectedItems = (): Observable<SingleSelectItemData<FrequencyNatureEnum>[]> => of(values.map(freq => ({ value: freq, title: toString.getValue(freq) })));
  export const toleranceSelectedItems = (): Observable<SingleSelectItemData<FrequencyNatureEnum>[]> =>
    of(toleranceValues.map(freq => ({ value: freq, title: toToleranceString.getValue(freq) })));
}
