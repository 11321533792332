import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserLightData } from '../user/user-light-data';
import { ElementEmmPropsResponseDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface EquipmentPropertiesData {
  name: string;
  family: EntityLightData;
  applicabilityName: string;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
  checked: boolean;
  expired: boolean;
}

export namespace EquipmentPropertiesData {
  export function mapFromApiValue(emmProperties: ElementEmmPropsResponseDTO): EquipmentPropertiesData {
    return {
      name: emmProperties.name,
      family: emmProperties.family && LabelFamilyLightData.mapToEntityLightData(emmProperties.family),
      applicabilityName: emmProperties.applicabilityName,
      creationDate: DateUtils.toDateTimeFormat(emmProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(emmProperties.modifcationDate),
      createdBy: emmProperties.createdBy && UserLightData.mapFromApiValue(emmProperties.createdBy),
      lastModifiedBy: emmProperties.lastModifiedBy && UserLightData.mapFromApiValue(emmProperties.lastModifiedBy),
      checked: emmProperties.verification || emmProperties['expired'],
      expired: emmProperties['expired'],
    };
  }
}
