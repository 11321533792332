/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserLightDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  login: string;
  email: string;
  firstName: string;
  lastName: string;
  refIntern: string;
  refExtern: string;
  natureUser: UserLightDTO.NatureUserEnum;
  backgroundColor: string;
  trigram: string;
  signature: string;
}
export namespace UserLightDTO {
  export type NatureUserEnum = 'NORMAL' | 'EXTERN' | 'DEVICE' | 'DEVICE_EXT' | 'EXTERNAL_API_ACCESS' | 'USER_CYPRESS' | 'EXTERNAL_CONTRIBUTOR';
  export const NatureUserEnum = {
    NORMAL: 'NORMAL' as NatureUserEnum,
    EXTERN: 'EXTERN' as NatureUserEnum,
    DEVICE: 'DEVICE' as NatureUserEnum,
    DEVICEEXT: 'DEVICE_EXT' as NatureUserEnum,
    EXTERNALAPIACCESS: 'EXTERNAL_API_ACCESS' as NatureUserEnum,
    USERCYPRESS: 'USER_CYPRESS' as NatureUserEnum,
    EXTERNALCONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR' as NatureUserEnum,
  };
}
