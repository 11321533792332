import { ElementAdrSummaryResponseDTO } from '../../generated/models/elementAdrSummaryResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum DashboardListAdrColumnEnum {
  Family = 'family',
  Risk = 'risk',
  Source = 'source',
  ImportAction = 'importAction',
  ContentAction = 'contentAction',
}

export namespace DashboardListAdrColumnEnum {
  export type AdrListSortField = keyof ElementAdrSummaryResponseDTO;

  export const sharedColumns = [DashboardListAdrColumnEnum.Family, DashboardListAdrColumnEnum.Risk, DashboardListAdrColumnEnum.Source];

  const key = 'lists.dialog.table.import.adr.header.';
  export const toString: SfxMap<DashboardListAdrColumnEnum, string> = new SfxMap<DashboardListAdrColumnEnum, string>([
    [DashboardListAdrColumnEnum.Family, key + 'family'],
    [DashboardListAdrColumnEnum.Risk, key + 'risk'],
    [DashboardListAdrColumnEnum.Source, key + 'source'],
    [DashboardListAdrColumnEnum.ImportAction, key + 'importAction'],
    [DashboardListAdrColumnEnum.ContentAction, key + 'contentAction'],
  ]);

  export const sortField: SfxMap<DashboardListAdrColumnEnum, AdrListSortField> = new SfxMap<DashboardListAdrColumnEnum, AdrListSortField>([
    [DashboardListAdrColumnEnum.Risk, 'risk'],
    [DashboardListAdrColumnEnum.Family, 'labelFamily'],
    [DashboardListAdrColumnEnum.Source, 'riskSource'],
  ]);
}
