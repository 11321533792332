import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as fromTagActions from './tag-dialog.actions';
import { LibraryTagDetailsData } from '../../../../../core/models';
import { TagLinkData } from '../../../../../core/models/library-tag/tag-link-data';

export interface LibraryTagsDialogState {
  saveLoading: boolean;
  tagsDetailsData: LibraryTagDetailsData;
  initialTagDetail: LibraryTagDetailsData;
  linkedFlows: TagLinkData[];
}

export const initialLibraryTagsDialogState = () => ({
  saveLoading: false,
  tagsDetailsData: {} as LibraryTagDetailsData,
  initialTagDetail: {} as LibraryTagDetailsData,
  linkedFlows: undefined,
});

const reducer = createReducer<LibraryTagsDialogState>(
  initialLibraryTagsDialogState(),
  on(fromTagActions.getTagDetailsSuccess, (state, { tag }): LibraryTagsDialogState => ({ ...state, tagsDetailsData: tag, initialTagDetail: cloneDeep(tag) })),
  on(fromTagActions.updateTag, (state): LibraryTagsDialogState => ({ ...state, saveLoading: true })),
  on(fromTagActions.updateTagFail, (state): LibraryTagsDialogState => ({ ...state, saveLoading: false })),
  on(fromTagActions.addTag, (state): LibraryTagsDialogState => ({ ...state, saveLoading: true })),
  on(fromTagActions.addTagFail, (state): LibraryTagsDialogState => ({ ...state, saveLoading: false })),
  on(
    fromTagActions.addTagSuccess,
    (state, { tag }): LibraryTagsDialogState => ({ ...state, saveLoading: false, tagsDetailsData: { applicability: tag.applicability } as LibraryTagDetailsData }),
  ),
  on(fromTagActions.loadLinkedTagListSuccess, (state, { linkedFlows }): LibraryTagsDialogState => ({ ...state, linkedFlows })),
);

export function LibraryTagsDialogReducer(state: LibraryTagsDialogState | undefined, action: Action): LibraryTagsDialogState {
  return reducer(state, action);
}
