import { SfxMap } from '../../utils/enum-utils';

export enum ParadeColumnEnum {
  Parade = 'parade',
  Picto = 'picto',
  PDF = 'pdf',
  Actions = 'actions',
}
export namespace ParadeColumnEnum {
  export const allColumns = [ParadeColumnEnum.Parade, ParadeColumnEnum.Picto, ParadeColumnEnum.PDF, ParadeColumnEnum.Actions];
  export const columnsWithoutAction = [ParadeColumnEnum.Parade, ParadeColumnEnum.Picto, ParadeColumnEnum.PDF];

  const key = 'riskLibrary.dialog.';
  export const toString: SfxMap<ParadeColumnEnum, string> = new SfxMap<ParadeColumnEnum, string>([
    [ParadeColumnEnum.Parade, key + 'parade'],
    [ParadeColumnEnum.Picto, key + 'picto'],
    [ParadeColumnEnum.PDF, key + 'pdf'],
    [ParadeColumnEnum.Actions, key + 'actions'],
  ]);
}
