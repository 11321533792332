import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FilterTypeDateEnum {
  Day = 'day',
  NextDay = 'nextDay',
  Week = 'week',
  NextWeek = 'nextWeek',
  Month = 'month',
  NextMonth = 'nexMonth',
  PreviousYear = 'previousYear',
  PreviousMonth = 'previousMonth',
  PreviousWeek = 'previousWeek',
  Custom = 'custom',
}

export namespace FilterTypeDateEnum {
  const values: FilterTypeDateEnum[] = [
    FilterTypeDateEnum.Day,
    FilterTypeDateEnum.NextDay,
    FilterTypeDateEnum.Week,
    FilterTypeDateEnum.NextWeek,
    FilterTypeDateEnum.Month,
    FilterTypeDateEnum.NextMonth,
    FilterTypeDateEnum.PreviousMonth,
    FilterTypeDateEnum.Custom,
  ];

  const key = 'global.dateFilter.';
  export const toString: SfxMap<FilterTypeDateEnum, string> = new SfxMap<FilterTypeDateEnum, string>(
    [
      [FilterTypeDateEnum.PreviousMonth, key + 'previousMonth'],
      [FilterTypeDateEnum.Month, key + 'currentMonth'],
      [FilterTypeDateEnum.NextMonth, key + 'nextMonth'],
      [FilterTypeDateEnum.Week, key + 'currentWeek'],
      [FilterTypeDateEnum.NextWeek, key + 'nextWeek'],
      [FilterTypeDateEnum.Day, key + 'currentDay'],
      [FilterTypeDateEnum.NextDay, key + 'nextDay'],
    ],
    key + 'customFill',
  );

  export const convertFromParamsValue: SfxMap<string, FilterTypeDateEnum> = new SfxMap<string, FilterTypeDateEnum>([
    ['day', FilterTypeDateEnum.Day],
    ['nextDay', FilterTypeDateEnum.NextDay],
    ['month', FilterTypeDateEnum.Month],
    ['nextMonth', FilterTypeDateEnum.NextMonth],
    ['week', FilterTypeDateEnum.Week],
    ['nextWeek', FilterTypeDateEnum.NextWeek],
    ['previousYear', FilterTypeDateEnum.PreviousYear],
    ['previousMonth', FilterTypeDateEnum.PreviousMonth],
    ['previousWeek', FilterTypeDateEnum.PreviousWeek],
    ['custom', FilterTypeDateEnum.Custom],
  ]);

  export const selectItems: SingleSelectItemData<FilterTypeDateEnum>[] = values.map(dateType => ({
    value: dateType,
    title: toString.getValue(dateType),
    selected: dateType === FilterTypeDateEnum.Day,
  }));

  export const selectItemsWithoutPreviousMonth = (): Observable<SingleSelectItemData<FilterTypeDateEnum>[]> =>
    of(
      values
        .filter(dateType => dateType !== FilterTypeDateEnum.PreviousMonth)
        .map(dateType => ({
          value: dateType,
          title: toString.getValue(dateType),
          selected: dateType === FilterTypeDateEnum.Custom,
        })),
    );
  export const comparePredicate = (node: SingleSelectItemData<FilterTypeDateEnum>, item: FilterTypeDateEnum) => node.value === item;
}
