import { LibraryEquipmentSummaryData } from '../../models/library-equipment/library-equipment-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum LibraryEquipmentColumnEnum {
  Family = 'family',
  Name = 'name',
  Reference = 'reference',
  Provider = 'provider',
  Actions = 'actions',
}

export namespace LibraryEquipmentColumnEnum {
  export type libraryEquipmentSortField = keyof LibraryEquipmentSummaryData;

  export const allColumns = [
    LibraryEquipmentColumnEnum.Family,
    LibraryEquipmentColumnEnum.Name,
    LibraryEquipmentColumnEnum.Reference,
    LibraryEquipmentColumnEnum.Provider,
    LibraryEquipmentColumnEnum.Actions,
  ];

  export const reorganizeColumns = [LibraryEquipmentColumnEnum.Family, LibraryEquipmentColumnEnum.Name, LibraryEquipmentColumnEnum.Reference, LibraryEquipmentColumnEnum.Provider];

  const key = 'equipment.table.header.';
  export const toString: SfxMap<LibraryEquipmentColumnEnum, string> = new SfxMap<LibraryEquipmentColumnEnum, string>([
    [LibraryEquipmentColumnEnum.Family, key + 'family'],
    [LibraryEquipmentColumnEnum.Name, key + 'name'],
    [LibraryEquipmentColumnEnum.Reference, key + 'reference'],
    [LibraryEquipmentColumnEnum.Provider, key + 'provider'],
    [LibraryEquipmentColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<LibraryEquipmentColumnEnum, libraryEquipmentSortField> = new SfxMap<LibraryEquipmentColumnEnum, libraryEquipmentSortField>([
    [LibraryEquipmentColumnEnum.Family, 'family' as libraryEquipmentSortField],
    [LibraryEquipmentColumnEnum.Name, 'name'],
    [LibraryEquipmentColumnEnum.Provider, 'provider'],
    [LibraryEquipmentColumnEnum.Reference, 'reference'],
  ]);
}
