import { FlowSummaryData } from '../flows/flow-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { TeamRoleDeleteResponseDTO } from '../../generated/models/models';

export interface TeamRoleDeleteResponseData {
  success: boolean;
  links: FlowSummaryData[];
  affected: boolean;
}

export namespace TeamRoleDeleteResponseData {
  export function mapFromApiValue(deleteResponse: TeamRoleDeleteResponseDTO): TeamRoleDeleteResponseData {
    return {
      success: deleteResponse.success,
      links: (deleteResponse.links || []).map(flow => FlowSummaryData.mapFromApiValue(flow)),
      affected: deleteResponse.affected,
    };
  }

  export function mapToConflictData(deleteResponse: TeamRoleDeleteResponseData): ConflictData {
    return {
      title: !deleteResponse.affected ? 'global.entityConflict.hint.contributor.flowsLink' : 'global.entityConflict.hint.contributor.restrictedLink',
      links: (deleteResponse.links || []).map(flow => FlowSummaryData.toString(flow)),
    };
  }
}
