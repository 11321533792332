import { AssignElementEmmToListRequestDTO } from '../../generated/models/assignElementEmmToListRequestDTO';

export interface ImportEmmOptions {
  uuidEntity: string;
  projectUuidEntity: string;
  flowUuidEntity: string;
  quantity: number;
  unity: string;
  consumable: boolean;
}
export namespace ImportEmmOptions {
  export function mapToRequestApiValue(data: ImportEmmOptions): AssignElementEmmToListRequestDTO {
    return {
      elementEmmUuidEntity: data.uuidEntity,
      flowUuidEntity: data.flowUuidEntity,
      projectUuidEntity: data.projectUuidEntity,
      quantity: data.quantity,
      unit: data.unity,
      consommable: data.consumable,
    };
  }
}
