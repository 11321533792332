import { DocumentFlowSummaryData } from '../document-flow/document-flow-summary';
import { ResponseDeleteResponseDTO } from '../../generated/models/models';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';

export interface FormResponseDeleteResponseData {
  success: boolean;
  documentFlowLinks: DocumentFlowSummaryData[];
}

export namespace FormResponseDeleteResponseData {
  export function mapFromApiValue(data: ResponseDeleteResponseDTO): FormResponseDeleteResponseData {
    return {
      success: data.success,
      documentFlowLinks: data.documentLinks && data.documentLinks.map(documentFlow => DocumentFlowSummaryData.mapFromApiValue(documentFlow)),
    };
  }

  export function mapToConflictData(responseDeleteResponse: FormResponseDeleteResponseData): ConflictData {
    return {
      title: 'global.hint.response.documentFlow.title',
      links: responseDeleteResponse.documentFlowLinks && responseDeleteResponse.documentFlowLinks.map(documentFlow => DocumentFlowSummaryData.toString(documentFlow)),
    };
  }
}
