import { CustomAttributeRequestDTO, CustomAttributeResponseDTO, LabelFamilyDetailsResponseDTO, LabelFamilyForCustomAttributeDTO } from '../../generated/models/models';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BusinessClassNameEnum, FieldTypeEnum, LabelFamilyRelativeNatureEnum } from '../../enums';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';
import { TypeUuidEntityEnum } from '../../enums/utils/type-uuid-entity-enum';
import { initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { CustomFieldLightData } from './custom-field.light';

export interface CustomFieldSummaryData {
  uuidEntity: string;
  labelFamily?: CustomFieldSummaryData;
  name: string;
  orderField?: string;
  typeField?: FieldTypeEnum;
  modificationDate?: Date;
  color?: string;
  relativeNature?: LabelFamilyRelativeNatureEnum;
  childrenCount?: number;
  isParent?: boolean;
  fullName?: string;
  archived?: boolean;
}
export namespace CustomFieldSummaryData {
  const defaultColor = '#0d4e8a';
  const defaultUuidEntity = TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT);

  export interface QueryRequest {
    businessClass?: BusinessClassNameEnum;
    projectUuidEntity?: string;
    state?: FilterArchivedEnum;
    textSearch?: string;
    childFamilyUuidEntity?: string;
  }

  export function mapCustomAttributeFromApiValue(data: CustomAttributeResponseDTO): CustomFieldSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
      orderField: data.orderField,
      labelFamily: mapFromLabelFamily(data.labelFamily),
      typeField: data.typeField && FieldTypeEnum.convertFromApiValue(data.longText)?.getValue(data.typeField),
      archived: data.archived,
    };
  }

  export function mapFromLabelFamily(labelFamily: LabelFamilyForCustomAttributeDTO): CustomFieldSummaryData {
    return {
      uuidEntity: labelFamily?.uuidEntity || defaultUuidEntity,
      name: labelFamily?.name,
      fullName: labelFamily?.fullName,
      color: labelFamily?.color || defaultColor,
      // MakeModels: Backend issue...
      relativeNature:
        labelFamily?.relativeNature && LabelFamilyRelativeNatureEnum.convertFromApiValue.getValue(labelFamily.relativeNature as LabelFamilyDetailsResponseDTO.RelativeNatureEnum),
      isParent: true,
    };
  }

  export function mapToRequestApiValue(queries: QueryRequest): CustomAttributeRequestDTO {
    return {
      textSearch: queries.textSearch || undefined,
      businessClass: queries.businessClass
        ? BusinessClassNameEnum.convertToApiValue.getValue(queries.businessClass)
        : BusinessClassNameEnum.convertToApiValue.getValue(BusinessClassNameEnum.Flow),
      childFamilyUuidEntity: queries.childFamilyUuidEntity || undefined,
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(businessClass: string, textSearch: string, sort: SortFilter<CustomFieldSummaryData>, state?: string): QueryRequest {
    return {
      ...initializeQueries<CustomFieldSummaryData>(sort),
      businessClass: BusinessClassNameEnum.convertFromParamsValue.getValue(businessClass),
      textSearch: textSearch,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || undefined,
    };
  }

  export function mapToSingleSelectItemData(customField: CustomAttributeResponseDTO): SingleSelectItemData<CustomFieldLightData> {
    const type = FieldTypeEnum.convertFromApiValue(customField.longText)?.getValue(customField.typeField);

    return {
      value: customField && CustomFieldLightData.mapFromApiValue(customField),
      title: FieldTypeEnum.toString(true).getValue(type),
      disabled: !FieldTypeEnum.available(type),
      translateParams: { fieldName: customField.name },
    };
  }
}
