import { FlowDeleteResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum NatureLinkEnum {
  FlowLink = 1,
  DocumentFlowLink,
  StepLink,
  ObjectLink,
  FlowContentLink,
  FlowHistoryLink,
  BlockObjectLink,
  BlockObjectArchivedFlowLink,
  BlockObjectArchivedProjectLink,
}

export namespace NatureLinkEnum {
  export const convertFromApiValue: SfxMap<FlowDeleteResponseDTO.NatureLinkEnum, NatureLinkEnum> = new SfxMap<FlowDeleteResponseDTO.NatureLinkEnum, NatureLinkEnum>([
    [FlowDeleteResponseDTO.NatureLinkEnum.FLOWLINK, NatureLinkEnum.FlowLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.DOCUMENTFLOWLINK, NatureLinkEnum.DocumentFlowLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.STEPLINK, NatureLinkEnum.StepLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.TECHNICALOBJECTLINK, NatureLinkEnum.ObjectLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.FLOWCONTENTLINK, NatureLinkEnum.FlowContentLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.FLOWHISTORICLINK, NatureLinkEnum.FlowHistoryLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.BLOCOBJECTLINK, NatureLinkEnum.BlockObjectLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.ARCHIVEDFLOW, NatureLinkEnum.BlockObjectArchivedFlowLink],
    [FlowDeleteResponseDTO.NatureLinkEnum.ARCHIVEDPROJECT, NatureLinkEnum.BlockObjectArchivedProjectLink],
  ]);

  export const convertToApiValue: SfxMap<NatureLinkEnum, FlowDeleteResponseDTO.NatureLinkEnum> = new SfxMap<NatureLinkEnum, FlowDeleteResponseDTO.NatureLinkEnum>([
    [NatureLinkEnum.FlowLink, FlowDeleteResponseDTO.NatureLinkEnum.FLOWLINK],
    [NatureLinkEnum.DocumentFlowLink, FlowDeleteResponseDTO.NatureLinkEnum.DOCUMENTFLOWLINK],
    [NatureLinkEnum.StepLink, FlowDeleteResponseDTO.NatureLinkEnum.STEPLINK],
    [NatureLinkEnum.ObjectLink, FlowDeleteResponseDTO.NatureLinkEnum.TECHNICALOBJECTLINK],
    [NatureLinkEnum.FlowContentLink, FlowDeleteResponseDTO.NatureLinkEnum.FLOWCONTENTLINK],
    [NatureLinkEnum.FlowHistoryLink, FlowDeleteResponseDTO.NatureLinkEnum.FLOWHISTORICLINK],
    [NatureLinkEnum.BlockObjectLink, FlowDeleteResponseDTO.NatureLinkEnum.BLOCOBJECTLINK],
    [NatureLinkEnum.BlockObjectArchivedFlowLink, FlowDeleteResponseDTO.NatureLinkEnum.ARCHIVEDFLOW],
    [NatureLinkEnum.BlockObjectArchivedProjectLink, FlowDeleteResponseDTO.NatureLinkEnum.ARCHIVEDPROJECT],
  ]);

  const key = 'technicalObjects.alerts.delete.conflict.';
  export const toString = new SfxMap<NatureLinkEnum, string>([
    [NatureLinkEnum.BlockObjectLink, key + 'linkedToFlow'],
    [NatureLinkEnum.BlockObjectArchivedFlowLink, key + 'linkedToArchivedFlow'],
    [NatureLinkEnum.BlockObjectArchivedProjectLink, key + 'linkedToArchivedProject'],
  ]);
}
