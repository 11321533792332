import { UpdateReferentialFlowsRequestDTO } from '../../generated/models/models';

export interface RefreshReferentialFlowsRequest {
  referentialFlowsUuidEntity: string[];
  projectUuidEntity: string;
}

export namespace RefreshReferentialFlowsRequest {
  export function mapToRequestApiValue(request: RefreshReferentialFlowsRequest): UpdateReferentialFlowsRequestDTO {
    return {
      allSelected: undefined,
      filterFlowUpdateReferentialRequest: undefined,
      listUuideFlowSrc: request.referentialFlowsUuidEntity,
      uuideProject: request.projectUuidEntity,
    };
  }
}
