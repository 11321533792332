import { createAction, props } from '@ngrx/store';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { TramSummaryData } from '../../../../../core/models';

export const createTram = createAction('[tramDialog/API] create Reference', props<{ tram: TramSummaryData; fileRef: File; addNewOne?: boolean }>());
export const createTramSuccess = createAction('[tramDialog/API] create Reference success', props<{ tram: TramSummaryData; addNewOne?: boolean }>());
export const createTramFail = createAction('[tramDialog/API] create Reference fail', props<DialogToasterData>());

export const loadTramDetailDataSuccess = createAction('[tramDialog/API] load reference data data success', props<{ tramDetails: TramSummaryData }>());
export const loadTramDetailDataFail = createAction('[tramDialog/API] load reference data data fail', props<DialogToasterData>());

export const updateTram = createAction('[tramDialog/API] update reference', props<{ tram: TramSummaryData; fileRef: File; closeModel?: boolean }>());
export const updateTramSuccess = createAction('[tramDialog/API] update reference success', props<{ tram: TramSummaryData; closeModal?: boolean }>());
export const updateTramFail = createAction('[tramDialog/API] update reference fail', props<DialogToasterData>());

export const tramDetailNavigation = createAction('[tramDialog/API] Navigate through details references', props<{ tramUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[tramDialog/API] confirm navigate details references', props<{ tram: TramSummaryData; fileRef: File; step: number }>());

export const displayPdf = createAction('[tramDialog/API] display parade pdf', props<{ fileId: string }>());
