import { BlocFormDeleteResponseDTO } from '../../generated/models/models';
import { FormResponseSummaryData } from '../form-response/form-response-summary';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';

export interface BlockFormDeleteResponseData {
  success: boolean;
  responseLinks: FormResponseSummaryData[];
}

export namespace BlockFormDeleteResponseData {
  export function mapFromApiValue(data: BlocFormDeleteResponseDTO): BlockFormDeleteResponseData {
    return {
      success: data.success,
      responseLinks: data.responseLinks && data.responseLinks.map(response => FormResponseSummaryData.mapFromApiValue(response)),
    };
  }

  export function mapToConflictData(formDeleteResponse: BlockFormDeleteResponseData): ConflictData {
    let conflictData = {} as ConflictData;
    if (formDeleteResponse.responseLinks && formDeleteResponse.responseLinks.length) {
      conflictData = {
        title: 'global.entityConflict.hint.form.response',
        links: formDeleteResponse.responseLinks.map(response => FormResponseSummaryData.toString(response)),
      };
    }

    return conflictData;
  }
}
