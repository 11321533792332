import { BaseRouterStoreState } from '@ngrx/router-store';

import { BreadCrumbData, RouteSegment } from '../../core/models/utils/route-segment';
import { RouteIdEnum } from '../../core/enums/router-enums/route.enum';

export interface SfxRouterState extends BaseRouterStoreState {
  url: string;
  params: { [key: string]: string };
  queryParams: { [key: string]: string };
  segments: RouteSegment[];
  dialogSegments: RouteSegment[];
  currentRouteId: RouteIdEnum;
  permissionParams?: { [key: string]: string };
  breadCrumbs?: BreadCrumbData[];
  backToReferentialModule?: boolean;
  routeSegments?: RouteSegment[];
}

export const routerInitialState: SfxRouterState = {
  url: '',
  queryParams: {},
  params: {},
  permissionParams: {},
  segments: [],
  routeSegments: [],
  dialogSegments: [],
  breadCrumbs: [],
  currentRouteId: undefined,
  backToReferentialModule: false,
};
