import { EntityLightData } from '../utils/entity-light-data';
import { DuplicateProjectRequestDTO } from '../../generated/models/duplicateProjectRequestDTO';
import { ProjectPropsResponseDTO } from '../../generated/models/projectPropsResponseDTO';
import { ClientLightData } from '../client/client-light';
import { ProjectTypeEnum } from '../../enums';

export interface ProjectForDuplicate {
  projectToDuplicate: string;
  sourceName: string;
  sourceType: ProjectTypeEnum;
  name: string;
  type: ProjectTypeEnum;
  client: EntityLightData;
  application: EntityLightData;
  projectIndex: string;
}

export namespace ProjectForDuplicate {
  export function mapFromApiValue(data: ProjectPropsResponseDTO): ProjectForDuplicate {
    return {
      projectToDuplicate: data.uuidEntity,
      sourceName: data.name,
      sourceType: data.type && ProjectTypeEnum.convertFromApiValue.getValue(data.type),
      name: data.name,
      type: data.type && ProjectTypeEnum.convertFromApiValue.getValue(data.type),
      client: data.client && ClientLightData.mapToEntityLightData(data.client),
      projectIndex: data.index,
      application: {} as EntityLightData,
    };
  }

  export function mapToRequestApiValue(data: ProjectForDuplicate): DuplicateProjectRequestDTO {
    return {
      clientUuide: data.client.uuidEntity,
      designationProject: data.name,
      typeProject: ProjectTypeEnum.convertToApiValue.getValue(data.type),
      index: data.projectIndex,
    };
  }
}
