/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RubricQuestionAddLineRequestDTO {
  uuidEntity: string;
  name: string;
  type: RubricQuestionAddLineRequestDTO.TypeEnum;
  relativePosition?: RubricQuestionAddLineRequestDTO.RelativePositionEnum;
  siblingUuidEntity?: string;
}
export namespace RubricQuestionAddLineRequestDTO {
  export type TypeEnum =
    | 'Text'
    | 'TextLong'
    | 'TextMultiple'
    | 'ValueUnit'
    | 'RadioSelect'
    | 'Contributor'
    | 'CheckBox'
    | 'File'
    | 'FileDocument'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'BooleanValue'
    | 'Localisation'
    | 'Formulas'
    | 'EnumSelection'
    | 'EnumMultipleSelections'
    | 'TextEditor';
  export const TypeEnum = {
    Text: 'Text' as TypeEnum,
    TextLong: 'TextLong' as TypeEnum,
    TextMultiple: 'TextMultiple' as TypeEnum,
    ValueUnit: 'ValueUnit' as TypeEnum,
    RadioSelect: 'RadioSelect' as TypeEnum,
    Contributor: 'Contributor' as TypeEnum,
    CheckBox: 'CheckBox' as TypeEnum,
    File: 'File' as TypeEnum,
    FileDocument: 'FileDocument' as TypeEnum,
    Date: 'Date' as TypeEnum,
    Time: 'Time' as TypeEnum,
    DateTime: 'DateTime' as TypeEnum,
    BooleanValue: 'BooleanValue' as TypeEnum,
    Localisation: 'Localisation' as TypeEnum,
    Formulas: 'Formulas' as TypeEnum,
    EnumSelection: 'EnumSelection' as TypeEnum,
    EnumMultipleSelections: 'EnumMultipleSelections' as TypeEnum,
    TextEditor: 'TextEditor' as TypeEnum,
  };
  export type RelativePositionEnum = 'START' | 'BEFORE' | 'AFTER' | 'END';
  export const RelativePositionEnum = {
    START: 'START' as RelativePositionEnum,
    BEFORE: 'BEFORE' as RelativePositionEnum,
    AFTER: 'AFTER' as RelativePositionEnum,
    END: 'END' as RelativePositionEnum,
  };
}
