/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

export interface PageTeamRolePlanRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  onlyUsedInProject?: boolean;
  types?: Array<PageTeamRolePlanRequestDTO.TypesEnum>;
  userNatures?: Array<PageTeamRolePlanRequestDTO.UserNaturesEnum>;
  userType?: string;
  userLabelFamilyTeamUuides?: Array<string>;
  labelFamilyHierarchyUuides?: Array<string>;
  labelFamilySkillUuides?: Array<string>;
  labelFamilyFunctionUuides?: Array<string>;
  legalEntitiesUuides?: Array<string>;
  contratTypeUuides?: Array<string>;
  choixTeamRole?: PageTeamRolePlanRequestDTO.ChoixTeamRoleEnum;
  projectUuidEntity?: string;
  activityDomainsUuides?: Array<string>;
  applicabilityUuides?: Array<string>;
  flowUuides?: Array<string>;
  userUuidEntities?: Array<string>;
  startDate?: Date;
  finishDate?: Date;
  flowUuidEntity: string;
  role: PageTeamRolePlanRequestDTO.RoleEnum;
  statuses?: Array<PageTeamRolePlanRequestDTO.StatusesEnum>;
}
export namespace PageTeamRolePlanRequestDTO {
  export type TypesEnum =
    | 'GENERIC'
    | 'REFERENTIAL'
    | 'TEMPLATE'
    | 'SPECIFIC'
    | 'GROUP'
    | 'LIST_EMM'
    | 'LIST_ADR'
    | 'FLOW_FORM'
    | 'GROUP_REFERENTIAL'
    | 'LOCAL_REFERENTIAL'
    | 'REFERENTIAL_GENERIC'
    | 'REFERENTIAL_INTERVENTION';
  export const TypesEnum = {
    GENERIC: 'GENERIC' as TypesEnum,
    REFERENTIAL: 'REFERENTIAL' as TypesEnum,
    TEMPLATE: 'TEMPLATE' as TypesEnum,
    SPECIFIC: 'SPECIFIC' as TypesEnum,
    GROUP: 'GROUP' as TypesEnum,
    LISTEMM: 'LIST_EMM' as TypesEnum,
    LISTADR: 'LIST_ADR' as TypesEnum,
    FLOWFORM: 'FLOW_FORM' as TypesEnum,
    GROUPREFERENTIAL: 'GROUP_REFERENTIAL' as TypesEnum,
    LOCALREFERENTIAL: 'LOCAL_REFERENTIAL' as TypesEnum,
    REFERENTIALGENERIC: 'REFERENTIAL_GENERIC' as TypesEnum,
    REFERENTIALINTERVENTION: 'REFERENTIAL_INTERVENTION' as TypesEnum,
  };
  export type UserNaturesEnum = 'NORMAL' | 'EXTERN' | 'DEVICE' | 'DEVICE_EXT' | 'EXTERNAL_API_ACCESS' | 'USER_CYPRESS' | 'EXTERNAL_CONTRIBUTOR';
  export const UserNaturesEnum = {
    NORMAL: 'NORMAL' as UserNaturesEnum,
    EXTERN: 'EXTERN' as UserNaturesEnum,
    DEVICE: 'DEVICE' as UserNaturesEnum,
    DEVICEEXT: 'DEVICE_EXT' as UserNaturesEnum,
    EXTERNALAPIACCESS: 'EXTERNAL_API_ACCESS' as UserNaturesEnum,
    USERCYPRESS: 'USER_CYPRESS' as UserNaturesEnum,
    EXTERNALCONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR' as UserNaturesEnum,
  };
  export type ChoixTeamRoleEnum = 'ROLE_NORMAL' | 'ROLE_ADVANCED' | 'ROLE_READONLY' | 'ROLE_ADMIN_PROJECT' | 'ROLE_OPERATOR';
  export const ChoixTeamRoleEnum = {
    NORMAL: 'ROLE_NORMAL' as ChoixTeamRoleEnum,
    ADVANCED: 'ROLE_ADVANCED' as ChoixTeamRoleEnum,
    READONLY: 'ROLE_READONLY' as ChoixTeamRoleEnum,
    ADMINPROJECT: 'ROLE_ADMIN_PROJECT' as ChoixTeamRoleEnum,
    OPERATOR: 'ROLE_OPERATOR' as ChoixTeamRoleEnum,
  };
  export type RoleEnum = 'ASSIGNE' | 'REPORTER' | 'TABLET_1' | 'TABLET_2' | 'TABLET_3' | 'COLLABORATOR';
  export const RoleEnum = {
    ASSIGNE: 'ASSIGNE' as RoleEnum,
    REPORTER: 'REPORTER' as RoleEnum,
    TABLET1: 'TABLET_1' as RoleEnum,
    TABLET2: 'TABLET_2' as RoleEnum,
    TABLET3: 'TABLET_3' as RoleEnum,
    COLLABORATOR: 'COLLABORATOR' as RoleEnum,
  };
  export type StatusesEnum = 'ACTIVE' | 'DISABLE' | 'BROKEN' | 'DOWN' | 'MAINTENANCE';
  export const StatusesEnum = {
    ACTIVE: 'ACTIVE' as StatusesEnum,
    DISABLE: 'DISABLE' as StatusesEnum,
    BROKEN: 'BROKEN' as StatusesEnum,
    DOWN: 'DOWN' as StatusesEnum,
    MAINTENANCE: 'MAINTENANCE' as StatusesEnum,
  };
}
