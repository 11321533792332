import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { fas, IconName, IconPrefix } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { SfxTooltipDirective } from '../../directives/tooltip/sfx-tooltip.directive';

@Component({
  selector: 'sfx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, SfxTooltipDirective],
})
export class IconComponent {
  @Input() set name(name: IconName) {
    this.iconName = name;
    this.checkIconValidity();
  }
  @Input() set prefix(prefix: 'fas' | 'far' | 'fab') {
    this.iconPrefix = prefix;
    this.checkIconValidity();
  }
  @Input() sfxClass: string;
  @Input() tooltip: string;
  @Input() spin: boolean;

  public defaultPrefix: IconPrefix = 'fas';
  public iconName: IconName;
  public iconPrefix: IconPrefix;
  public exist: boolean;

  constructor(private library: FaIconLibrary) {
    this.library.addIconPacks(fas, far, fab);
  }

  private checkIconValidity() {
    this.exist = !!this.library.getIconDefinition(this.iconPrefix || this.defaultPrefix, this.iconName);
  }
}
