import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { CustomMenuActionEnum } from '../enums';

@Injectable({ providedIn: 'root' })
export class ContextMenuService {
  private copy = new Subject<CustomMenuActionEnum>();

  public getAction(): Observable<CustomMenuActionEnum> {
    return this.copy.asObservable();
  }

  public sendAction(action: CustomMenuActionEnum): void {
    this.copy.next(action);
  }
}
