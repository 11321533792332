import { CheckRubricResponseDTO } from '../../generated/models/checkRubricResponseDTO';
import { RubricNatureEnum } from '../../enums';
import { QuestionValidationData } from './question-validation-data';

export interface ResponseValidationData {
  name: string;
  nature: RubricNatureEnum;
  order: number;
  valid: boolean;
  questions: QuestionValidationData[];
}

export namespace ResponseValidationData {
  export function mapFromApiValue(validation: CheckRubricResponseDTO): ResponseValidationData {
    return {
      name: validation.name,
      nature: RubricNatureEnum.convertFromApiValue.getValue(validation.nature),
      order: validation.rubricOrder,
      valid: validation.valid,
      questions: (validation.questions || []).map(question => QuestionValidationData.mapFromApiValue(question)),
    };
  }
}
