import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { lastOneUuidEntity } from '../../../modules/shared/interfaces/select-item';
import { FormNavbarItemTypeEnum, QuestionIconEnum, QuestionTypeEnum } from '../../enums';
import { PageRubricQuestionRequestDTO } from '../../generated/models/pageRubricQuestionRequestDTO';
import { QuestionSummaryResponseDTO } from '../../generated/models/questionSummaryResponseDTO';
import { FormNavbarItemData } from '../form-content/form-navbar-item';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';

export interface QuestionSummaryData {
  uuidEntity: string;
  name: string;
  description: string;
  order: number;
  mandatory: boolean;
  lockable: boolean;
  locked: boolean;
  color: string;
  icon: QuestionIconEnum;
  nature: QuestionTypeEnum;
  collapsed?: boolean;
  additionalComment?: string;
  additionalObservation?: string;
}

export namespace QuestionSummaryData {
  export interface QueryRequest extends BaseQuery<QuestionSummaryData> {
    rubricUuidEntity?: string;
  }

  export function mapFromApiValue(question: QuestionSummaryResponseDTO): QuestionSummaryData {
    return {
      uuidEntity: question.uuidEntity,
      name: question.name,
      description: question.description,
      order: question.questionOrder,
      mandatory: question.mandatory,
      lockable: question.lockable,
      locked: question.locked,
      nature: QuestionTypeEnum.convertFromApiValue.getValue(question.nature),
      color: question.backgroundColor,
      icon: QuestionIconEnum.convertFromApiValue.getValue(question.iconStyle),
      additionalObservation: question.additionalObservation,
      additionalComment: question.additionalComment,
    };
  }

  export function mapToFormNavbarItemData(questionSummary: QuestionSummaryResponseDTO): FormNavbarItemData {
    return {
      uuidEntity: questionSummary.uuidEntity,
      name: questionSummary.name,
      description: questionSummary.description,
      type: FormNavbarItemTypeEnum.Question,
      order: questionSummary.questionOrder,
      mandatory: questionSummary.mandatory,
      lockable: questionSummary.lockable,
      locked: questionSummary.locked,
      nature: QuestionTypeEnum.convertFromApiValue.getValue(questionSummary.nature),
      color: questionSummary.backgroundColor,
      tooltip: questionSummary.additionalObservation,
      icon: QuestionIconEnum.convertFromApiValue.getValue(questionSummary.iconStyle),
      additionalComment: questionSummary.additionalComment,
      additionalObservation: questionSummary.additionalObservation,
    };
  }

  export function mapToSelectItem(question: QuestionSummaryResponseDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: { name: question.name, uuidEntity: question.uuidEntity, uuidType: question.uuidType },
      title: question.name,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageRubricQuestionRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      rubricUuidEntity: queries.rubricUuidEntity,
    };
  }

  export function initializeQueryRequest(page?: number, searchText?: string, rubricUuidEntity?: string): QueryRequest {
    return {
      ...initializeQueries<QuestionSummaryData>(),
      page,
      textSearch: searchText || undefined,
      rubricUuidEntity,
    };
  }

  export const lastQuestionInSelectItem: SingleSelectItemData<QuestionSummaryData> = {
    title: 'questions.lastPosition',
    value: { uuidEntity: lastOneUuidEntity, name: 'questions.lastPosition' } as QuestionSummaryData,
    icons: [{ name: 'hand-point-down', prefix: 'far' }],
  };
}
