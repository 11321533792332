import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockRiskModalTabEnum {
  General = 'general',
  Parade = 'parade',
  SpecificParade = 'specificParade',
}

export namespace BlockRiskModalTabEnum {
  const key = 'blocks.adr.dialogs.view.';

  export const toString: SfxMap<BlockRiskModalTabEnum, string> = new SfxMap<BlockRiskModalTabEnum, string>([
    [BlockRiskModalTabEnum.General, key + 'general'],
    [BlockRiskModalTabEnum.Parade, key + 'parade'],
    [BlockRiskModalTabEnum.SpecificParade, key + 'specificParade'],
  ]);

  export const iconData: SfxMap<BlockRiskModalTabEnum, IconData> = new SfxMap<BlockRiskModalTabEnum, IconData>([
    [BlockRiskModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [BlockRiskModalTabEnum.Parade, { name: 'shield-alt', prefix: 'fas' }],
    [BlockRiskModalTabEnum.SpecificParade, { name: 'shield-alt', prefix: 'fas' }],
  ]);
}
