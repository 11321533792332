import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import {
  LazyLoadResponse,
  ErrorResponseData,
  ApplicationFileLightData,
  LibraryContributorDetailsData,
  SkillDetailsData,
  SkillSummaryData,
  LibraryContributorSummaryData,
  LibraryReorganizeContributorUsersData,
} from '../../../../../core/models';

import { OperatorErrorEnum, PopoverPositionEnum } from '../../../../../core/enums';

export const loadSkillsUserList = createAction('[sUsers] Load skills users list');
export const loadSkillsUserListSuccess = createAction('[sUsers/API] Load skills users list success', props<{ data: LazyLoadResponse<SkillSummaryData[]>; reset?: boolean }>());
export const loadSkillUserListFails = createAction('[lUsers/API] Load skills users list fails', props<DialogToasterData>());

export const loadMoreUserSkills = createAction('[lUsers/API] Load more user skills');

export const loadUserDetailDataSuccess = createAction('[lUsers/API] load user data data success', props<{ userDetails: LibraryContributorDetailsData }>());
export const loadUserDetailDataFail = createAction('[lUsers/API] load user details data fail', props<DialogToasterData>());

export const addUser = createAction('[lUsers/API] add new user', props<{ user: LibraryContributorDetailsData; nextTab: string; addNewOne?: boolean }>());
export const addUserSuccess = createAction('[lUsers/API] add new user success', props<{ userDetails: LibraryContributorDetailsData; nextTab: string; addNewOne?: boolean }>());
export const addUserFail = createAction('[lUsers/API] add new user fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());

export const updateUser = createAction('[lUsers/API] update user', props<{ user: LibraryContributorDetailsData; closeModal?: boolean }>());
export const updateUserSuccess = createAction('[lUsers/API] update user success', props<{ closeModal?: boolean }>());
export const updateUserFail = createAction('[lUsers/API] update user fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());

export const addSkillUser = createAction('[lUsers/API] add user skill', props<{ skill: SkillDetailsData }>());
export const addSkillUserSuccess = createAction('[lUsers/API] add user skill success');
export const addSkillUserFail = createAction('[lUsers/API] add user skill fail', props<{ error: ErrorResponseData }>());

export const loadSkillDetailData = createAction('[lUsers/API] load Skill data ', props<{ uuidESkill: string }>());
export const loadSkillDetailDataSuccess = createAction('[lUsers/API] load user data success', props<SkillDetailsData>());
export const loadSkillDetailDataFail = createAction('[lUsers/API] load skill details data fail', props<DialogToasterData>());

export const updateSkillUser = createAction('[lUsers/API] update user skill', props<{ skill: SkillDetailsData }>());
export const updateSkillUserSuccess = createAction('[lUsers/API] update user skill success');
export const updateSkillUserFail = createAction('[lUsers/API] update user skill fail', props<{ error: ErrorResponseData }>());

export const deleteSkill = createAction('[lUsers/API] delete skill', props<{ uuidESkill: string }>());
export const deleteSkillFail = createAction('[lUsers/API] delete skill  fails', props<DialogToasterData>());

export const uploadFileSkill = createAction('[lUsers/API] upload file skill', props<{ skillUuidEntity: string }>());
export const setFileToUpload = createAction('[lUsers/API] set file to upload', props<{ selectedFiles: File[] }>());
export const uploadFileSkillSuccess = createAction('[lUsers/API] upload file skill success', props<{ skillUuidEntity: string; pdfFiles: ApplicationFileLightData[] }>());
export const uploadFileSkillFail = createAction('[lUsers/API] upload file skill fail', props<DialogToasterData>());

export const userSkillsNavigation = createAction('[lUsers/API] Navigate through skills users', props<{ userUuidEntity?: string; step: number }>());
export const userDetailNavigation = createAction('[sUsers/API] Navigate through details users', props<{ userUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[sUsers/API] confirm navigate details users', props<{ user: LibraryContributorDetailsData; step: number }>());

export const deletePdfFile = createAction('[lUsers/API] delete file skill', props<{ skillUuidEntity: string; fileUuidEntity: string }>());
export const deletePdfFileSuccess = createAction('[lUsers/API] delete file skill success', props<{ skillUuidEntity: string; fileUuidEntity: string }>());

export const openPdfPopover = createAction('[lUsers/API] open pdf popover', props<{ origin: HTMLElement; skillUuidEntity: string }>());
export const openSkillPopover = createAction('[lUsers/API] open skill popover', props<{ origin: HTMLElement; position: PopoverPositionEnum; skillUuidEntity?: string }>());

export const setFileToUploadUsers = createAction('[lUsers/API] set file to upload users', props<{ usersFile: File }>());
export const setFileToUploadUsersSuccess = createAction('[lUsers/API] set file to upload users success');
export const setFileToUploadUsersFail = createAction('[lUsers/API] set file to upload users fail', props<DialogToasterData>());
export const stopImportFileLoading = createAction('[lUsers/API] stop Import File Loading');

export const viewSummaryPdf = createAction('[lUsers/API] view summary pdf', props<{ userUuidEntity: string }>());
export const viewSummaryPdfSuccess = createAction('[lUsers/API] view summary pdf success');
export const viewSummaryPdfFail = createAction('[lUsers/API] view summary pdf fail', props<DialogToasterData>());

export const loadReorganizeUserList = createAction('[lUsers/API] Load reorganize users list');
export const loadReorganizeUserListSuccess = createAction(
  '[lUsers/API] Load reorganize users list success',
  props<{ users: LibraryContributorSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadReorganizeNextUserListPage = createAction('[lUsers/API] Load next reorganize users list page');
export const loadReorganizeUserListFails = createAction('[lUsers/API] Load reorganize users list fails', props<{ error: ErrorResponseData }>());

export const reorganizeUsers = createAction('[lUsers/API] reorganize users', props<{ data: LibraryReorganizeContributorUsersData; count: number }>());
export const reorganizeUsersFail = createAction('[lUsers/API] reorganize users fail', props<DialogToasterData>());

export const changeUserApplicability = createAction('[lUsers/API] change user applicability', props<{ applicabilityUuidEntity: string }>());
export const changeUserApplicabilitySuccess = createAction('[lUsers/API] change user applicability success');
export const changeUserApplicabilityFail = createAction('[lUsers/API] change user applicability fail', props<DialogToasterData>());
