import { FlowSummaryData } from '../flows/flow-summary';
import { TechnicalObjectDeleteResponseDTO } from '../../generated/models/models';
import { ConflictData } from '../../../modules/shared/interfaces/conflict-data';
import { NatureLinkEnum } from '../../enums';
import { TechnicalObjectSummaryData } from './technical-object-summary';

export interface ObjectDeleteResponse {
  success: boolean;
  natureLink: NatureLinkEnum;
  childLinks: TechnicalObjectSummaryData[];
  flowLinks: FlowSummaryData[];
}

export namespace ObjectDeleteResponse {
  export function mapFromApiValue(data: TechnicalObjectDeleteResponseDTO): ObjectDeleteResponse {
    return {
      success: data.success,
      natureLink: NatureLinkEnum.convertFromApiValue.getValue(data.natureLink),
      childLinks: data.childLinks && data.childLinks.map(object => TechnicalObjectSummaryData.mapFromApiValue(object)),
      flowLinks: data.flowLinks && data.flowLinks.map(flow => FlowSummaryData.mapFromApiValue(flow)),
    };
  }

  export function mapToConflictData(objectDeleteResponse: ObjectDeleteResponse): ConflictData {
    switch (objectDeleteResponse.natureLink) {
      case NatureLinkEnum.ObjectLink:
        return {
          title: 'global.entityConflict.hint.object.child',
          links: objectDeleteResponse.childLinks && objectDeleteResponse.childLinks.map(object => TechnicalObjectSummaryData.toString(object)),
        };

      case NatureLinkEnum.FlowContentLink:
        return {
          title: 'global.entityConflict.hint.object.flow',
          links: objectDeleteResponse.flowLinks && objectDeleteResponse.flowLinks.map(flow => FlowSummaryData.toString(flow)),
        };

      default:
        return null;
    }
  }
}
