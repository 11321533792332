import { CustomStatusLightDTO, EntityLight, EntityLightResponseDTO, FlowSummaryResponseDTO } from '../../generated/models/models';

import { ProjectTypeEnum } from '../../enums';

export interface EntityLightData {
  uuidEntity: string;
  name: string;
  shortName?: string;
  tooltip?: string;
  color?: string;
  labelFamilyUuidEntity?: string;
  parentName?: string;
  uuidType?: string;
  projectType?: ProjectTypeEnum;
  fullName?: string;
  signature?: string;
  originFlow?: FlowSummaryResponseDTO; // INFO: should create a FlowLightData instead of use EntityLightData
}

export namespace EntityLightData {
  export function mapToApiValue(entityLightData: EntityLightData): EntityLight {
    return {
      id: undefined, // BackendIssue: should be removed from backend
      uuidEntity: entityLightData.uuidEntity,
    };
  }

  export function mapFromApiValue(entityLight: EntityLight): EntityLightData {
    return (
      entityLight?.uuidEntity && {
        uuidEntity: entityLight.uuidEntity,
        name: undefined,
      }
    );
  }

  export function mapStatusFromApiValue(entityLight: CustomStatusLightDTO): EntityLightData {
    return {
      uuidEntity: entityLight.uuidEntity,
      name: entityLight.name,
      color: entityLight.color,
    };
  }

  export function mapFromEntityLightResponse(entityLight: EntityLightResponseDTO): EntityLightData {
    return {
      uuidEntity: entityLight.uuidEntity,
      name: entityLight.name,
    };
  }
}
