import { DocumentFlowDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ResponseStrategyEnum {
  All = 1,
  Selected = 2,
  None = 3,
}

export namespace ResponseStrategyEnum {
  export const convertFromApiValue: SfxMap<DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum, ResponseStrategyEnum> = new SfxMap<
    DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum,
    ResponseStrategyEnum
  >([
    [DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.ALL, ResponseStrategyEnum.All],
    [DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.SELECTED, ResponseStrategyEnum.Selected],
    [DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.NONE, ResponseStrategyEnum.None],
  ]);

  export const convertToApiValue: SfxMap<ResponseStrategyEnum, DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum> = new SfxMap<
    ResponseStrategyEnum,
    DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum
  >([
    [ResponseStrategyEnum.All, DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.ALL],
    [ResponseStrategyEnum.Selected, DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.SELECTED],
    [ResponseStrategyEnum.None, DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum.NONE],
  ]);
}
