import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum PictogramEnum {
  SFX_PICTO_0001 = 1,
  SFX_PICTO_0002 = 2,
  SFX_PICTO_0003 = 3,
  SFX_PICTO_0004 = 4,
  SFX_PICTO_0005 = 5,
  SFX_PICTO_0006 = 6,
  SFX_PICTO_0007 = 7,
  SFX_PICTO_0008 = 8,
  SFX_PICTO_0009 = 9,
  SFX_PICTO_0010 = 10,
  SFX_PICTO_0011 = 11,
  SFX_PICTO_0012 = 12,
  SFX_PICTO_0013 = 13,
  SFX_PICTO_0014 = 14,
  SFX_PICTO_0015 = 15,
  SFX_PICTO_0016 = 16,
  SFX_PICTO_0017 = 17,
  SFX_PICTO_0018 = 18,
  SFX_PICTO_0019 = 19,
  SFX_PICTO_0020 = 20,
  SFX_PICTO_0021 = 21,
  SFX_PICTO_0022 = 22,
  SFX_PICTO_0023 = 23,
  SFX_PICTO_0024 = 24,
  SFX_PICTO_0025 = 25,
  SFX_PICTO_0026 = 26,
  SFX_PICTO_0027 = 27,
  SFX_PICTO_0028 = 28,
  SFX_PICTO_0029 = 29,
  SFX_PICTO_0030 = 30,
  SFX_PICTO_0031 = 31,
  SFX_PICTO_0032 = 32,
  SFX_PICTO_0033 = 33,
  SFX_PICTO_0034 = 34,
  SFX_PICTO_0035 = 35,
  SFX_PICTO_0036 = 36,
  SFX_PICTO_0037 = 37,
  SFX_PICTO_0038 = 38,
  SFX_PICTO_0039 = 39,
  SFX_PICTO_0040 = 40,
  SFX_PICTO_0041 = 41,
  SFX_PICTO_0042 = 42,
  SFX_PICTO_0043 = 43,
  SFX_PICTO_0044 = 44,
  SFX_PICTO_0045 = 45,
  SFX_PICTO_0046 = 46,
  SFX_PICTO_0047 = 47,
  SFX_PICTO_0048 = 48,
  SFX_PICTO_0049 = 49,
  SFX_PICTO_0050 = 50,
  SFX_PICTO_0051 = 51,
  SFX_PICTO_0052 = 52,
  SFX_PICTO_0053 = 53,
  SFX_PICTO_0054 = 54,
  SFX_PICTO_0055 = 55,
  SFX_PICTO_0056 = 56,
  SFX_PICTO_0057 = 57,
  SFX_PICTO_0058 = 58,
  SFX_PICTO_0059 = 59,
  SFX_PICTO_0060 = 60,
  SFX_PICTO_0061 = 61,
  SFX_PICTO_0062 = 62,
  SFX_PICTO_0063 = 63,
  SFX_PICTO_0064 = 64,
  SFX_PICTO_0065 = 65,
  SFX_PICTO_0066 = 66,
  SFX_PICTO_0067 = 67,
  SFX_PICTO_0068 = 68,
  SFX_PICTO_0069 = 69,
  SFX_PICTO_0070 = 70,
  SFX_PICTO_0071 = 71,
  SFX_PICTO_0072 = 72,
  SFX_PICTO_0073 = 73,
  SFX_PICTO_0074 = 74,
  SFX_PICTO_0075 = 75,
  SFX_PICTO_0076 = 76,
  SFX_PICTO_0077 = 77,
  SFX_PICTO_0078 = 78,
  SFX_PICTO_0079 = 79,
  SFX_PICTO_0080 = 80,
  SFX_PICTO_0081 = 81,
  SFX_PICTO_0082 = 82,
  SFX_PICTO_0083 = 83,
  SFX_PICTO_0084 = 84,
  SFX_PICTO_0085 = 85,
  SFX_PICTO_0086 = 86,
  SFX_PICTO_0087 = 87,
  SFX_PICTO_0088 = 88,
  SFX_PICTO_0089 = 89,
  SFX_PICTO_0090 = 90,
  SFX_PICTO_0091 = 91,
  SFX_PICTO_0092 = 92,
  SFX_PICTO_0093 = 93,
  SFX_PICTO_0094 = 94,
  SFX_PICTO_0095 = 95,
  SFX_PICTO_0096 = 96,
  SFX_PICTO_0097 = 97,
  SFX_PICTO_0098 = 98,
  SFX_PICTO_0099 = 99,
  SFX_PICTO_0100 = 100,
  SFX_PICTO_0101 = 101,
  SFX_PICTO_0102 = 102,
  SFX_PICTO_0103 = 103,
  SFX_PICTO_0104 = 104,
  SFX_PICTO_0105 = 105,
  SFX_PICTO_0106 = 106,
  SFX_PICTO_0107 = 107,
  SFX_PICTO_0108 = 108,
  SFX_PICTO_0109 = 109,
  SFX_PICTO_0110 = 110,
  SFX_PICTO_0111 = 111,
  SFX_PICTO_0112 = 112,
  SFX_PICTO_0113 = 113,
  SFX_PICTO_0114 = 114,
  SFX_PICTO_0115 = 115,
  SFX_PICTO_0116 = 116,
  SFX_PICTO_0117 = 117,
  SFX_PICTO_0118 = 118,
  SFX_PICTO_0119 = 119,
  SFX_PICTO_0120 = 120,
  SFX_PICTO_0121 = 121,
  SFX_PICTO_0122 = 122,
  SFX_PICTO_0123 = 123,
  SFX_PICTO_0124 = 124,
  SFX_PICTO_0125 = 125,
  SFX_PICTO_0126 = 126,
  SFX_PICTO_0127 = 127,
  SFX_PICTO_0128 = 128,
  SFX_PICTO_0129 = 129,
  SFX_PICTO_0130 = 130,
  SFX_PICTO_0131 = 131,
  SFX_PICTO_0132 = 132,
  SFX_PICTO_0133 = 133,
  SFX_PICTO_0134 = 134,
  SFX_PICTO_0135 = 135,
  SFX_PICTO_0136 = 136,
  SFX_PICTO_0137 = 137,
  SFX_PICTO_0138 = 138,
  SFX_PICTO_0139 = 139,
  SFX_PICTO_0140 = 140,
  SFX_PICTO_0141 = 141,
  SFX_PICTO_0142 = 142,
  SFX_PICTO_0143 = 143,
  SFX_PICTO_0144 = 144,
  SFX_PICTO_0145 = 145,
  SFX_PICTO_0146 = 146,
  SFX_PICTO_0147 = 147,
  SFX_PICTO_0148 = 148,
  SFX_PICTO_0149 = 149,
  SFX_PICTO_0150 = 150,
  SFX_PICTO_0151 = 151,
  SFX_PICTO_0152 = 152,
  SFX_PICTO_0153 = 153,
  SFX_PICTO_0154 = 154,
  SFX_PICTO_0155 = 155,
  SFX_PICTO_0156 = 156,
  SFX_PICTO_0157 = 157,
  SFX_PICTO_0158 = 158,
  SFX_PICTO_0159 = 159,
  SFX_PICTO_0160 = 160,
  SFX_PICTO_0161 = 161,
  SFX_PICTO_0162 = 162,
  SFX_PICTO_0163 = 163,
  SFX_PICTO_0164 = 164,
  SFX_PICTO_0165 = 165,
  SFX_PICTO_0166 = 166,
  SFX_PICTO_0167 = 167,
  SFX_PICTO_0168 = 168,
  SFX_PICTO_0169 = 169,
  SFX_PICTO_0170 = 170,
  SFX_PICTO_0171 = 171,
  SFX_PICTO_0172 = 172,
  SFX_PICTO_0173 = 173,
  SFX_PICTO_0174 = 174,
  SFX_PICTO_0175 = 175,
  SFX_PICTO_0176 = 176,
  SFX_PICTO_0177 = 177,
  SFX_PICTO_0178 = 178,
  SFX_PICTO_0179 = 179,
  SFX_PICTO_0180 = 180,
  SFX_PICTO_0181 = 181,
  SFX_PICTO_0182 = 182,
  SFX_PICTO_0183 = 183,
  SFX_PICTO_0184 = 184,
  SFX_PICTO_0185 = 185,
  SFX_PICTO_0186 = 186,
  SFX_PICTO_0187 = 187,
  SFX_PICTO_0188 = 188,
  SFX_PICTO_0189 = 189,
  SFX_PICTO_0190 = 190,
  SFX_PICTO_0191 = 191,
  SFX_PICTO_0192 = 192,
  SFX_PICTO_0193 = 193,
  SFX_PICTO_0194 = 194,
  SFX_PICTO_0195 = 195,
  SFX_PICTO_0196 = 196,
  SFX_PICTO_0197 = 197,
  SFX_PICTO_0198 = 198,
  SFX_PICTO_0199 = 199,
  SFX_PICTO_0200 = 200,
  SFX_PICTO_0201 = 201,
  SFX_PICTO_0202 = 202,
  SFX_PICTO_0203 = 203,
  SFX_PICTO_0204 = 204,
  SFX_PICTO_0205 = 205,
  SFX_PICTO_0206 = 206,
  SFX_PICTO_0207 = 207,
  SFX_PICTO_0208 = 208,
  SFX_PICTO_0209 = 209,
  SFX_PICTO_0210 = 210,
  SFX_PICTO_0211 = 211,
  SFX_PICTO_0212 = 212,
  SFX_PICTO_0213 = 213,
  SFX_PICTO_0214 = 214,
  SFX_PICTO_0215 = 215,
  SFX_PICTO_0216 = 216,
  SFX_PICTO_0217 = 217,
  SFX_PICTO_0218 = 218,
  SFX_PICTO_0219 = 219,
  SFX_PICTO_0220 = 220,
  SFX_PICTO_0221 = 221,
  SFX_PICTO_0222 = 222,
  SFX_PICTO_0223 = 223,
  SFX_PICTO_0224 = 224,
  SFX_PICTO_0225 = 225,
  SFX_PICTO_0226 = 226,
  SFX_PICTO_0227 = 227,
  SFX_PICTO_0228 = 228,
  SFX_PICTO_0229 = 229,
  SFX_PICTO_0230 = 230,
  SFX_PICTO_0231 = 231,
  SFX_PICTO_0232 = 232,
  SFX_PICTO_0233 = 233,
  SFX_PICTO_0234 = 234,
  SFX_PICTO_0235 = 235,
  SFX_PICTO_0236 = 236,
  SFX_PICTO_0237 = 237,
  SFX_PICTO_0238 = 238,
  SFX_PICTO_0239 = 239,
  SFX_PICTO_0240 = 240,
}

export namespace PictogramEnum {
  const values = [
    PictogramEnum.SFX_PICTO_0001,
    PictogramEnum.SFX_PICTO_0002,
    PictogramEnum.SFX_PICTO_0003,
    PictogramEnum.SFX_PICTO_0004,
    PictogramEnum.SFX_PICTO_0005,
    PictogramEnum.SFX_PICTO_0006,
    PictogramEnum.SFX_PICTO_0007,
    PictogramEnum.SFX_PICTO_0008,
    PictogramEnum.SFX_PICTO_0009,
    PictogramEnum.SFX_PICTO_0010,
    PictogramEnum.SFX_PICTO_0011,
    PictogramEnum.SFX_PICTO_0012,
    PictogramEnum.SFX_PICTO_0013,
    PictogramEnum.SFX_PICTO_0014,
    PictogramEnum.SFX_PICTO_0015,
    PictogramEnum.SFX_PICTO_0016,
    PictogramEnum.SFX_PICTO_0017,
    PictogramEnum.SFX_PICTO_0018,
    PictogramEnum.SFX_PICTO_0019,
    PictogramEnum.SFX_PICTO_0020,
    PictogramEnum.SFX_PICTO_0021,
    PictogramEnum.SFX_PICTO_0022,
    PictogramEnum.SFX_PICTO_0023,
    PictogramEnum.SFX_PICTO_0024,
    PictogramEnum.SFX_PICTO_0025,
    PictogramEnum.SFX_PICTO_0026,
    PictogramEnum.SFX_PICTO_0027,
    PictogramEnum.SFX_PICTO_0028,
    PictogramEnum.SFX_PICTO_0029,
    PictogramEnum.SFX_PICTO_0030,
    PictogramEnum.SFX_PICTO_0031,
    PictogramEnum.SFX_PICTO_0032,
    PictogramEnum.SFX_PICTO_0033,
    PictogramEnum.SFX_PICTO_0034,
    PictogramEnum.SFX_PICTO_0035,
    PictogramEnum.SFX_PICTO_0036,
    PictogramEnum.SFX_PICTO_0037,
    PictogramEnum.SFX_PICTO_0038,
    PictogramEnum.SFX_PICTO_0039,
    PictogramEnum.SFX_PICTO_0040,
    PictogramEnum.SFX_PICTO_0041,
    PictogramEnum.SFX_PICTO_0042,
    PictogramEnum.SFX_PICTO_0043,
    PictogramEnum.SFX_PICTO_0044,
    PictogramEnum.SFX_PICTO_0045,
    PictogramEnum.SFX_PICTO_0046,
    PictogramEnum.SFX_PICTO_0047,
    PictogramEnum.SFX_PICTO_0048,
    PictogramEnum.SFX_PICTO_0049,
    PictogramEnum.SFX_PICTO_0050,
    PictogramEnum.SFX_PICTO_0051,
    PictogramEnum.SFX_PICTO_0052,
    PictogramEnum.SFX_PICTO_0053,
    PictogramEnum.SFX_PICTO_0054,
    PictogramEnum.SFX_PICTO_0055,
    PictogramEnum.SFX_PICTO_0056,
    PictogramEnum.SFX_PICTO_0057,
    PictogramEnum.SFX_PICTO_0058,
    PictogramEnum.SFX_PICTO_0059,
    PictogramEnum.SFX_PICTO_0060,
    PictogramEnum.SFX_PICTO_0061,
    PictogramEnum.SFX_PICTO_0062,
    PictogramEnum.SFX_PICTO_0063,
    PictogramEnum.SFX_PICTO_0064,
    PictogramEnum.SFX_PICTO_0065,
    PictogramEnum.SFX_PICTO_0066,
    PictogramEnum.SFX_PICTO_0067,
    PictogramEnum.SFX_PICTO_0068,
    PictogramEnum.SFX_PICTO_0069,
    PictogramEnum.SFX_PICTO_0070,
    PictogramEnum.SFX_PICTO_0071,
    PictogramEnum.SFX_PICTO_0072,
    PictogramEnum.SFX_PICTO_0073,
    PictogramEnum.SFX_PICTO_0074,
    PictogramEnum.SFX_PICTO_0075,
    PictogramEnum.SFX_PICTO_0076,
    PictogramEnum.SFX_PICTO_0077,
    PictogramEnum.SFX_PICTO_0078,
    PictogramEnum.SFX_PICTO_0079,
    PictogramEnum.SFX_PICTO_0080,
    PictogramEnum.SFX_PICTO_0081,
    PictogramEnum.SFX_PICTO_0082,
    PictogramEnum.SFX_PICTO_0083,
    PictogramEnum.SFX_PICTO_0084,
    PictogramEnum.SFX_PICTO_0085,
    PictogramEnum.SFX_PICTO_0086,
    PictogramEnum.SFX_PICTO_0087,
    PictogramEnum.SFX_PICTO_0088,
    PictogramEnum.SFX_PICTO_0089,
    PictogramEnum.SFX_PICTO_0090,
    PictogramEnum.SFX_PICTO_0091,
    PictogramEnum.SFX_PICTO_0092,
    PictogramEnum.SFX_PICTO_0093,
    PictogramEnum.SFX_PICTO_0094,
    PictogramEnum.SFX_PICTO_0095,
    PictogramEnum.SFX_PICTO_0096,
    PictogramEnum.SFX_PICTO_0097,
    PictogramEnum.SFX_PICTO_0098,
    PictogramEnum.SFX_PICTO_0099,
    PictogramEnum.SFX_PICTO_0100,
    PictogramEnum.SFX_PICTO_0101,
    PictogramEnum.SFX_PICTO_0102,
    PictogramEnum.SFX_PICTO_0103,
    PictogramEnum.SFX_PICTO_0104,
    PictogramEnum.SFX_PICTO_0105,
    PictogramEnum.SFX_PICTO_0106,
    PictogramEnum.SFX_PICTO_0107,
    PictogramEnum.SFX_PICTO_0108,
    PictogramEnum.SFX_PICTO_0109,
    PictogramEnum.SFX_PICTO_0110,
    PictogramEnum.SFX_PICTO_0111,
    PictogramEnum.SFX_PICTO_0112,
    PictogramEnum.SFX_PICTO_0113,
    PictogramEnum.SFX_PICTO_0114,
    PictogramEnum.SFX_PICTO_0115,
    PictogramEnum.SFX_PICTO_0116,
    PictogramEnum.SFX_PICTO_0117,
    PictogramEnum.SFX_PICTO_0118,
    PictogramEnum.SFX_PICTO_0119,
    PictogramEnum.SFX_PICTO_0120,
    PictogramEnum.SFX_PICTO_0121,
    PictogramEnum.SFX_PICTO_0122,
    PictogramEnum.SFX_PICTO_0123,
    PictogramEnum.SFX_PICTO_0124,
    PictogramEnum.SFX_PICTO_0125,
    PictogramEnum.SFX_PICTO_0126,
    PictogramEnum.SFX_PICTO_0127,
    PictogramEnum.SFX_PICTO_0128,
    PictogramEnum.SFX_PICTO_0129,
    PictogramEnum.SFX_PICTO_0130,
    PictogramEnum.SFX_PICTO_0131,
    PictogramEnum.SFX_PICTO_0132,
    PictogramEnum.SFX_PICTO_0133,
    PictogramEnum.SFX_PICTO_0134,
    PictogramEnum.SFX_PICTO_0135,
    PictogramEnum.SFX_PICTO_0136,
    PictogramEnum.SFX_PICTO_0137,
    PictogramEnum.SFX_PICTO_0138,
    PictogramEnum.SFX_PICTO_0139,
    PictogramEnum.SFX_PICTO_0140,
    PictogramEnum.SFX_PICTO_0141,
    PictogramEnum.SFX_PICTO_0142,
    PictogramEnum.SFX_PICTO_0143,
    PictogramEnum.SFX_PICTO_0144,
    PictogramEnum.SFX_PICTO_0145,
    PictogramEnum.SFX_PICTO_0146,
    PictogramEnum.SFX_PICTO_0147,
    PictogramEnum.SFX_PICTO_0148,
    PictogramEnum.SFX_PICTO_0149,
    PictogramEnum.SFX_PICTO_0150,
    PictogramEnum.SFX_PICTO_0151,
    PictogramEnum.SFX_PICTO_0152,
    PictogramEnum.SFX_PICTO_0153,
    PictogramEnum.SFX_PICTO_0154,
    PictogramEnum.SFX_PICTO_0155,
    PictogramEnum.SFX_PICTO_0156,
    PictogramEnum.SFX_PICTO_0157,
    PictogramEnum.SFX_PICTO_0158,
    PictogramEnum.SFX_PICTO_0159,
    PictogramEnum.SFX_PICTO_0160,
    PictogramEnum.SFX_PICTO_0161,
    PictogramEnum.SFX_PICTO_0162,
    PictogramEnum.SFX_PICTO_0163,
    PictogramEnum.SFX_PICTO_0164,
    PictogramEnum.SFX_PICTO_0165,
    PictogramEnum.SFX_PICTO_0166,
    PictogramEnum.SFX_PICTO_0167,
    PictogramEnum.SFX_PICTO_0168,
    PictogramEnum.SFX_PICTO_0169,
    PictogramEnum.SFX_PICTO_0170,
    PictogramEnum.SFX_PICTO_0171,
    PictogramEnum.SFX_PICTO_0172,
    PictogramEnum.SFX_PICTO_0173,
    PictogramEnum.SFX_PICTO_0174,
    PictogramEnum.SFX_PICTO_0175,
    PictogramEnum.SFX_PICTO_0176,
    PictogramEnum.SFX_PICTO_0177,
    PictogramEnum.SFX_PICTO_0178,
    PictogramEnum.SFX_PICTO_0179,
    PictogramEnum.SFX_PICTO_0180,
    PictogramEnum.SFX_PICTO_0181,
    PictogramEnum.SFX_PICTO_0182,
    PictogramEnum.SFX_PICTO_0183,
    PictogramEnum.SFX_PICTO_0184,
    PictogramEnum.SFX_PICTO_0185,
    PictogramEnum.SFX_PICTO_0186,
    PictogramEnum.SFX_PICTO_0187,
    PictogramEnum.SFX_PICTO_0188,
    PictogramEnum.SFX_PICTO_0189,
    PictogramEnum.SFX_PICTO_0190,
    PictogramEnum.SFX_PICTO_0191,
    PictogramEnum.SFX_PICTO_0192,
    PictogramEnum.SFX_PICTO_0193,
    PictogramEnum.SFX_PICTO_0194,
    PictogramEnum.SFX_PICTO_0195,
    PictogramEnum.SFX_PICTO_0196,
    PictogramEnum.SFX_PICTO_0197,
    PictogramEnum.SFX_PICTO_0198,
    PictogramEnum.SFX_PICTO_0199,
    PictogramEnum.SFX_PICTO_0200,
    PictogramEnum.SFX_PICTO_0201,
    PictogramEnum.SFX_PICTO_0202,
    PictogramEnum.SFX_PICTO_0203,
    PictogramEnum.SFX_PICTO_0204,
    PictogramEnum.SFX_PICTO_0205,
    PictogramEnum.SFX_PICTO_0206,
    PictogramEnum.SFX_PICTO_0207,
    PictogramEnum.SFX_PICTO_0208,
    PictogramEnum.SFX_PICTO_0209,
    PictogramEnum.SFX_PICTO_0210,
    PictogramEnum.SFX_PICTO_0211,
    PictogramEnum.SFX_PICTO_0212,
    PictogramEnum.SFX_PICTO_0213,
    PictogramEnum.SFX_PICTO_0214,
    PictogramEnum.SFX_PICTO_0215,
    PictogramEnum.SFX_PICTO_0216,
    PictogramEnum.SFX_PICTO_0217,
    PictogramEnum.SFX_PICTO_0218,
    PictogramEnum.SFX_PICTO_0219,
    PictogramEnum.SFX_PICTO_0220,
    PictogramEnum.SFX_PICTO_0221,
    PictogramEnum.SFX_PICTO_0222,
    PictogramEnum.SFX_PICTO_0223,
    PictogramEnum.SFX_PICTO_0224,
    PictogramEnum.SFX_PICTO_0225,
    PictogramEnum.SFX_PICTO_0226,
    PictogramEnum.SFX_PICTO_0227,
    PictogramEnum.SFX_PICTO_0228,
    PictogramEnum.SFX_PICTO_0229,
    PictogramEnum.SFX_PICTO_0230,
    PictogramEnum.SFX_PICTO_0231,
    PictogramEnum.SFX_PICTO_0232,
    PictogramEnum.SFX_PICTO_0233,
    PictogramEnum.SFX_PICTO_0234,
    PictogramEnum.SFX_PICTO_0235,
    PictogramEnum.SFX_PICTO_0236,
    PictogramEnum.SFX_PICTO_0237,
    PictogramEnum.SFX_PICTO_0238,
    PictogramEnum.SFX_PICTO_0239,
    PictogramEnum.SFX_PICTO_0240,
  ];

  export const convertFromApiValue: SfxMap<string, PictogramEnum> = new SfxMap<string, PictogramEnum>([
    ['SFX_PICTO_0001', PictogramEnum.SFX_PICTO_0001],
    ['SFX_PICTO_0002', PictogramEnum.SFX_PICTO_0002],
    ['SFX_PICTO_0003', PictogramEnum.SFX_PICTO_0003],
    ['SFX_PICTO_0004', PictogramEnum.SFX_PICTO_0004],
    ['SFX_PICTO_0005', PictogramEnum.SFX_PICTO_0005],
    ['SFX_PICTO_0006', PictogramEnum.SFX_PICTO_0006],
    ['SFX_PICTO_0007', PictogramEnum.SFX_PICTO_0007],
    ['SFX_PICTO_0008', PictogramEnum.SFX_PICTO_0008],
    ['SFX_PICTO_0009', PictogramEnum.SFX_PICTO_0009],
    ['SFX_PICTO_0010', PictogramEnum.SFX_PICTO_0010],
    ['SFX_PICTO_0011', PictogramEnum.SFX_PICTO_0011],
    ['SFX_PICTO_0012', PictogramEnum.SFX_PICTO_0012],
    ['SFX_PICTO_0013', PictogramEnum.SFX_PICTO_0013],
    ['SFX_PICTO_0014', PictogramEnum.SFX_PICTO_0014],
    ['SFX_PICTO_0015', PictogramEnum.SFX_PICTO_0015],
    ['SFX_PICTO_0016', PictogramEnum.SFX_PICTO_0016],
    ['SFX_PICTO_0017', PictogramEnum.SFX_PICTO_0017],
    ['SFX_PICTO_0018', PictogramEnum.SFX_PICTO_0018],
    ['SFX_PICTO_0019', PictogramEnum.SFX_PICTO_0019],
    ['SFX_PICTO_0020', PictogramEnum.SFX_PICTO_0020],
    ['SFX_PICTO_0021', PictogramEnum.SFX_PICTO_0021],
    ['SFX_PICTO_0022', PictogramEnum.SFX_PICTO_0022],
    ['SFX_PICTO_0023', PictogramEnum.SFX_PICTO_0023],
    ['SFX_PICTO_0024', PictogramEnum.SFX_PICTO_0024],
    ['SFX_PICTO_0025', PictogramEnum.SFX_PICTO_0025],
    ['SFX_PICTO_0026', PictogramEnum.SFX_PICTO_0026],
    ['SFX_PICTO_0027', PictogramEnum.SFX_PICTO_0027],
    ['SFX_PICTO_0028', PictogramEnum.SFX_PICTO_0028],
    ['SFX_PICTO_0029', PictogramEnum.SFX_PICTO_0029],
    ['SFX_PICTO_0030', PictogramEnum.SFX_PICTO_0030],
    ['SFX_PICTO_0031', PictogramEnum.SFX_PICTO_0031],
    ['SFX_PICTO_0032', PictogramEnum.SFX_PICTO_0032],
    ['SFX_PICTO_0033', PictogramEnum.SFX_PICTO_0033],
    ['SFX_PICTO_0034', PictogramEnum.SFX_PICTO_0034],
    ['SFX_PICTO_0035', PictogramEnum.SFX_PICTO_0035],
    ['SFX_PICTO_0036', PictogramEnum.SFX_PICTO_0036],
    ['SFX_PICTO_0037', PictogramEnum.SFX_PICTO_0037],
    ['SFX_PICTO_0038', PictogramEnum.SFX_PICTO_0038],
    ['SFX_PICTO_0039', PictogramEnum.SFX_PICTO_0039],
    ['SFX_PICTO_0040', PictogramEnum.SFX_PICTO_0040],
    ['SFX_PICTO_0041', PictogramEnum.SFX_PICTO_0041],
    ['SFX_PICTO_0042', PictogramEnum.SFX_PICTO_0042],
    ['SFX_PICTO_0043', PictogramEnum.SFX_PICTO_0043],
    ['SFX_PICTO_0044', PictogramEnum.SFX_PICTO_0044],
    ['SFX_PICTO_0045', PictogramEnum.SFX_PICTO_0045],
    ['SFX_PICTO_0046', PictogramEnum.SFX_PICTO_0046],
    ['SFX_PICTO_0047', PictogramEnum.SFX_PICTO_0047],
    ['SFX_PICTO_0048', PictogramEnum.SFX_PICTO_0048],
    ['SFX_PICTO_0049', PictogramEnum.SFX_PICTO_0049],
    ['SFX_PICTO_0050', PictogramEnum.SFX_PICTO_0050],
    ['SFX_PICTO_0051', PictogramEnum.SFX_PICTO_0051],
    ['SFX_PICTO_0052', PictogramEnum.SFX_PICTO_0052],
    ['SFX_PICTO_0053', PictogramEnum.SFX_PICTO_0053],
    ['SFX_PICTO_0054', PictogramEnum.SFX_PICTO_0054],
    ['SFX_PICTO_0055', PictogramEnum.SFX_PICTO_0055],
    ['SFX_PICTO_0056', PictogramEnum.SFX_PICTO_0056],
    ['SFX_PICTO_0057', PictogramEnum.SFX_PICTO_0057],
    ['SFX_PICTO_0058', PictogramEnum.SFX_PICTO_0058],
    ['SFX_PICTO_0059', PictogramEnum.SFX_PICTO_0059],
    ['SFX_PICTO_0060', PictogramEnum.SFX_PICTO_0060],
    ['SFX_PICTO_0061', PictogramEnum.SFX_PICTO_0061],
    ['SFX_PICTO_0062', PictogramEnum.SFX_PICTO_0062],
    ['SFX_PICTO_0063', PictogramEnum.SFX_PICTO_0063],
    ['SFX_PICTO_0064', PictogramEnum.SFX_PICTO_0064],
    ['SFX_PICTO_0065', PictogramEnum.SFX_PICTO_0065],
    ['SFX_PICTO_0066', PictogramEnum.SFX_PICTO_0066],
    ['SFX_PICTO_0067', PictogramEnum.SFX_PICTO_0067],
    ['SFX_PICTO_0068', PictogramEnum.SFX_PICTO_0068],
    ['SFX_PICTO_0069', PictogramEnum.SFX_PICTO_0069],
    ['SFX_PICTO_0070', PictogramEnum.SFX_PICTO_0070],
    ['SFX_PICTO_0071', PictogramEnum.SFX_PICTO_0071],
    ['SFX_PICTO_0072', PictogramEnum.SFX_PICTO_0072],
    ['SFX_PICTO_0073', PictogramEnum.SFX_PICTO_0073],
    ['SFX_PICTO_0074', PictogramEnum.SFX_PICTO_0074],
    ['SFX_PICTO_0075', PictogramEnum.SFX_PICTO_0075],
    ['SFX_PICTO_0076', PictogramEnum.SFX_PICTO_0076],
    ['SFX_PICTO_0077', PictogramEnum.SFX_PICTO_0077],
    ['SFX_PICTO_0078', PictogramEnum.SFX_PICTO_0078],
    ['SFX_PICTO_0079', PictogramEnum.SFX_PICTO_0079],
    ['SFX_PICTO_0080', PictogramEnum.SFX_PICTO_0080],
    ['SFX_PICTO_0081', PictogramEnum.SFX_PICTO_0081],
    ['SFX_PICTO_0082', PictogramEnum.SFX_PICTO_0082],
    ['SFX_PICTO_0083', PictogramEnum.SFX_PICTO_0083],
    ['SFX_PICTO_0084', PictogramEnum.SFX_PICTO_0084],
    ['SFX_PICTO_0085', PictogramEnum.SFX_PICTO_0085],
    ['SFX_PICTO_0086', PictogramEnum.SFX_PICTO_0086],
    ['SFX_PICTO_0087', PictogramEnum.SFX_PICTO_0087],
    ['SFX_PICTO_0088', PictogramEnum.SFX_PICTO_0088],
    ['SFX_PICTO_0089', PictogramEnum.SFX_PICTO_0089],
    ['SFX_PICTO_0090', PictogramEnum.SFX_PICTO_0090],
    ['SFX_PICTO_0091', PictogramEnum.SFX_PICTO_0091],
    ['SFX_PICTO_0092', PictogramEnum.SFX_PICTO_0092],
    ['SFX_PICTO_0093', PictogramEnum.SFX_PICTO_0093],
    ['SFX_PICTO_0094', PictogramEnum.SFX_PICTO_0094],
    ['SFX_PICTO_0095', PictogramEnum.SFX_PICTO_0095],
    ['SFX_PICTO_0096', PictogramEnum.SFX_PICTO_0096],
    ['SFX_PICTO_0097', PictogramEnum.SFX_PICTO_0097],
    ['SFX_PICTO_0098', PictogramEnum.SFX_PICTO_0098],
    ['SFX_PICTO_0099', PictogramEnum.SFX_PICTO_0099],
    ['SFX_PICTO_0100', PictogramEnum.SFX_PICTO_0100],
    ['SFX_PICTO_0101', PictogramEnum.SFX_PICTO_0101],
    ['SFX_PICTO_0102', PictogramEnum.SFX_PICTO_0102],
    ['SFX_PICTO_0103', PictogramEnum.SFX_PICTO_0103],
    ['SFX_PICTO_0104', PictogramEnum.SFX_PICTO_0104],
    ['SFX_PICTO_0105', PictogramEnum.SFX_PICTO_0105],
    ['SFX_PICTO_0106', PictogramEnum.SFX_PICTO_0106],
    ['SFX_PICTO_0107', PictogramEnum.SFX_PICTO_0107],
    ['SFX_PICTO_0108', PictogramEnum.SFX_PICTO_0108],
    ['SFX_PICTO_0109', PictogramEnum.SFX_PICTO_0109],
    ['SFX_PICTO_0110', PictogramEnum.SFX_PICTO_0110],
    ['SFX_PICTO_0111', PictogramEnum.SFX_PICTO_0111],
    ['SFX_PICTO_0112', PictogramEnum.SFX_PICTO_0112],
    ['SFX_PICTO_0113', PictogramEnum.SFX_PICTO_0113],
    ['SFX_PICTO_0114', PictogramEnum.SFX_PICTO_0114],
    ['SFX_PICTO_0115', PictogramEnum.SFX_PICTO_0115],
    ['SFX_PICTO_0116', PictogramEnum.SFX_PICTO_0116],
    ['SFX_PICTO_0117', PictogramEnum.SFX_PICTO_0117],
    ['SFX_PICTO_0118', PictogramEnum.SFX_PICTO_0118],
    ['SFX_PICTO_0119', PictogramEnum.SFX_PICTO_0119],
    ['SFX_PICTO_0120', PictogramEnum.SFX_PICTO_0120],
    ['SFX_PICTO_0121', PictogramEnum.SFX_PICTO_0121],
    ['SFX_PICTO_0122', PictogramEnum.SFX_PICTO_0122],
    ['SFX_PICTO_0123', PictogramEnum.SFX_PICTO_0123],
    ['SFX_PICTO_0124', PictogramEnum.SFX_PICTO_0124],
    ['SFX_PICTO_0125', PictogramEnum.SFX_PICTO_0125],
    ['SFX_PICTO_0126', PictogramEnum.SFX_PICTO_0126],
    ['SFX_PICTO_0127', PictogramEnum.SFX_PICTO_0127],
    ['SFX_PICTO_0128', PictogramEnum.SFX_PICTO_0128],
    ['SFX_PICTO_0129', PictogramEnum.SFX_PICTO_0129],
    ['SFX_PICTO_0130', PictogramEnum.SFX_PICTO_0130],
    ['SFX_PICTO_0131', PictogramEnum.SFX_PICTO_0131],
    ['SFX_PICTO_0132', PictogramEnum.SFX_PICTO_0132],
    ['SFX_PICTO_0133', PictogramEnum.SFX_PICTO_0133],
    ['SFX_PICTO_0134', PictogramEnum.SFX_PICTO_0134],
    ['SFX_PICTO_0135', PictogramEnum.SFX_PICTO_0135],
    ['SFX_PICTO_0136', PictogramEnum.SFX_PICTO_0136],
    ['SFX_PICTO_0137', PictogramEnum.SFX_PICTO_0137],
    ['SFX_PICTO_0138', PictogramEnum.SFX_PICTO_0138],
    ['SFX_PICTO_0139', PictogramEnum.SFX_PICTO_0139],
    ['SFX_PICTO_0140', PictogramEnum.SFX_PICTO_0140],
    ['SFX_PICTO_0141', PictogramEnum.SFX_PICTO_0141],
    ['SFX_PICTO_0142', PictogramEnum.SFX_PICTO_0142],
    ['SFX_PICTO_0143', PictogramEnum.SFX_PICTO_0143],
    ['SFX_PICTO_0144', PictogramEnum.SFX_PICTO_0144],
    ['SFX_PICTO_0145', PictogramEnum.SFX_PICTO_0145],
    ['SFX_PICTO_0146', PictogramEnum.SFX_PICTO_0146],
    ['SFX_PICTO_0147', PictogramEnum.SFX_PICTO_0147],
    ['SFX_PICTO_0148', PictogramEnum.SFX_PICTO_0148],
    ['SFX_PICTO_0149', PictogramEnum.SFX_PICTO_0149],
    ['SFX_PICTO_0150', PictogramEnum.SFX_PICTO_0150],
    ['SFX_PICTO_0151', PictogramEnum.SFX_PICTO_0151],
    ['SFX_PICTO_0152', PictogramEnum.SFX_PICTO_0152],
    ['SFX_PICTO_0153', PictogramEnum.SFX_PICTO_0153],
    ['SFX_PICTO_0154', PictogramEnum.SFX_PICTO_0154],
    ['SFX_PICTO_0155', PictogramEnum.SFX_PICTO_0155],
    ['SFX_PICTO_0156', PictogramEnum.SFX_PICTO_0156],
    ['SFX_PICTO_0157', PictogramEnum.SFX_PICTO_0157],
    ['SFX_PICTO_0158', PictogramEnum.SFX_PICTO_0158],
    ['SFX_PICTO_0159', PictogramEnum.SFX_PICTO_0159],
    ['SFX_PICTO_0160', PictogramEnum.SFX_PICTO_0160],
    ['SFX_PICTO_0161', PictogramEnum.SFX_PICTO_0161],
    ['SFX_PICTO_0162', PictogramEnum.SFX_PICTO_0162],
    ['SFX_PICTO_0163', PictogramEnum.SFX_PICTO_0163],
    ['SFX_PICTO_0164', PictogramEnum.SFX_PICTO_0164],
    ['SFX_PICTO_0165', PictogramEnum.SFX_PICTO_0165],
    ['SFX_PICTO_0166', PictogramEnum.SFX_PICTO_0166],
    ['SFX_PICTO_0167', PictogramEnum.SFX_PICTO_0167],
    ['SFX_PICTO_0168', PictogramEnum.SFX_PICTO_0168],
    ['SFX_PICTO_0169', PictogramEnum.SFX_PICTO_0169],
    ['SFX_PICTO_0170', PictogramEnum.SFX_PICTO_0170],
    ['SFX_PICTO_0171', PictogramEnum.SFX_PICTO_0171],
    ['SFX_PICTO_0172', PictogramEnum.SFX_PICTO_0172],
    ['SFX_PICTO_0173', PictogramEnum.SFX_PICTO_0173],
    ['SFX_PICTO_0174', PictogramEnum.SFX_PICTO_0174],
    ['SFX_PICTO_0175', PictogramEnum.SFX_PICTO_0175],
    ['SFX_PICTO_0176', PictogramEnum.SFX_PICTO_0176],
    ['SFX_PICTO_0177', PictogramEnum.SFX_PICTO_0177],
    ['SFX_PICTO_0178', PictogramEnum.SFX_PICTO_0178],
    ['SFX_PICTO_0179', PictogramEnum.SFX_PICTO_0179],
    ['SFX_PICTO_0180', PictogramEnum.SFX_PICTO_0180],
    ['SFX_PICTO_0181', PictogramEnum.SFX_PICTO_0181],
    ['SFX_PICTO_0182', PictogramEnum.SFX_PICTO_0182],
    ['SFX_PICTO_0183', PictogramEnum.SFX_PICTO_0183],
    ['SFX_PICTO_0184', PictogramEnum.SFX_PICTO_0184],
    ['SFX_PICTO_0185', PictogramEnum.SFX_PICTO_0185],
    ['SFX_PICTO_0186', PictogramEnum.SFX_PICTO_0186],
    ['SFX_PICTO_0187', PictogramEnum.SFX_PICTO_0187],
    ['SFX_PICTO_0188', PictogramEnum.SFX_PICTO_0188],
    ['SFX_PICTO_0189', PictogramEnum.SFX_PICTO_0189],
    ['SFX_PICTO_0190', PictogramEnum.SFX_PICTO_0190],
    ['SFX_PICTO_0191', PictogramEnum.SFX_PICTO_0191],
    ['SFX_PICTO_0192', PictogramEnum.SFX_PICTO_0192],
    ['SFX_PICTO_0193', PictogramEnum.SFX_PICTO_0193],
    ['SFX_PICTO_0194', PictogramEnum.SFX_PICTO_0194],
    ['SFX_PICTO_0195', PictogramEnum.SFX_PICTO_0195],
    ['SFX_PICTO_0196', PictogramEnum.SFX_PICTO_0196],
    ['SFX_PICTO_0197', PictogramEnum.SFX_PICTO_0197],
    ['SFX_PICTO_0198', PictogramEnum.SFX_PICTO_0198],
    ['SFX_PICTO_0199', PictogramEnum.SFX_PICTO_0199],
    ['SFX_PICTO_0200', PictogramEnum.SFX_PICTO_0200],
    ['SFX_PICTO_0201', PictogramEnum.SFX_PICTO_0201],
    ['SFX_PICTO_0202', PictogramEnum.SFX_PICTO_0202],
    ['SFX_PICTO_0203', PictogramEnum.SFX_PICTO_0203],
    ['SFX_PICTO_0204', PictogramEnum.SFX_PICTO_0204],
    ['SFX_PICTO_0205', PictogramEnum.SFX_PICTO_0205],
    ['SFX_PICTO_0206', PictogramEnum.SFX_PICTO_0206],
    ['SFX_PICTO_0207', PictogramEnum.SFX_PICTO_0207],
    ['SFX_PICTO_0208', PictogramEnum.SFX_PICTO_0208],
    ['SFX_PICTO_0209', PictogramEnum.SFX_PICTO_0209],
    ['SFX_PICTO_0210', PictogramEnum.SFX_PICTO_0210],
    ['SFX_PICTO_0211', PictogramEnum.SFX_PICTO_0211],
    ['SFX_PICTO_0212', PictogramEnum.SFX_PICTO_0212],
    ['SFX_PICTO_0213', PictogramEnum.SFX_PICTO_0213],
    ['SFX_PICTO_0214', PictogramEnum.SFX_PICTO_0214],
    ['SFX_PICTO_0215', PictogramEnum.SFX_PICTO_0215],
    ['SFX_PICTO_0216', PictogramEnum.SFX_PICTO_0216],
    ['SFX_PICTO_0217', PictogramEnum.SFX_PICTO_0217],
    ['SFX_PICTO_0218', PictogramEnum.SFX_PICTO_0218],
    ['SFX_PICTO_0219', PictogramEnum.SFX_PICTO_0219],
    ['SFX_PICTO_0220', PictogramEnum.SFX_PICTO_0220],
    ['SFX_PICTO_0221', PictogramEnum.SFX_PICTO_0221],
    ['SFX_PICTO_0222', PictogramEnum.SFX_PICTO_0222],
    ['SFX_PICTO_0223', PictogramEnum.SFX_PICTO_0223],
    ['SFX_PICTO_0224', PictogramEnum.SFX_PICTO_0224],
    ['SFX_PICTO_0225', PictogramEnum.SFX_PICTO_0225],
    ['SFX_PICTO_0226', PictogramEnum.SFX_PICTO_0226],
    ['SFX_PICTO_0227', PictogramEnum.SFX_PICTO_0227],
    ['SFX_PICTO_0228', PictogramEnum.SFX_PICTO_0228],
    ['SFX_PICTO_0229', PictogramEnum.SFX_PICTO_0229],
    ['SFX_PICTO_0230', PictogramEnum.SFX_PICTO_0230],
    ['SFX_PICTO_0231', PictogramEnum.SFX_PICTO_0231],
    ['SFX_PICTO_0232', PictogramEnum.SFX_PICTO_0232],
    ['SFX_PICTO_0233', PictogramEnum.SFX_PICTO_0233],
    ['SFX_PICTO_0234', PictogramEnum.SFX_PICTO_0234],
    ['SFX_PICTO_0235', PictogramEnum.SFX_PICTO_0235],
    ['SFX_PICTO_0236', PictogramEnum.SFX_PICTO_0236],
    ['SFX_PICTO_0237', PictogramEnum.SFX_PICTO_0237],
    ['SFX_PICTO_0238', PictogramEnum.SFX_PICTO_0238],
    ['SFX_PICTO_0239', PictogramEnum.SFX_PICTO_0239],
    ['SFX_PICTO_0240', PictogramEnum.SFX_PICTO_0240],
  ]);

  export const convertToApiValue: SfxMap<PictogramEnum, string> = new SfxMap<PictogramEnum, string>([
    [PictogramEnum.SFX_PICTO_0001, 'SFX_PICTO_0001'],
    [PictogramEnum.SFX_PICTO_0002, 'SFX_PICTO_0002'],
    [PictogramEnum.SFX_PICTO_0003, 'SFX_PICTO_0003'],
    [PictogramEnum.SFX_PICTO_0004, 'SFX_PICTO_0004'],
    [PictogramEnum.SFX_PICTO_0005, 'SFX_PICTO_0005'],
    [PictogramEnum.SFX_PICTO_0006, 'SFX_PICTO_0006'],
    [PictogramEnum.SFX_PICTO_0007, 'SFX_PICTO_0007'],
    [PictogramEnum.SFX_PICTO_0008, 'SFX_PICTO_0008'],
    [PictogramEnum.SFX_PICTO_0009, 'SFX_PICTO_0009'],
    [PictogramEnum.SFX_PICTO_0010, 'SFX_PICTO_0010'],
    [PictogramEnum.SFX_PICTO_0011, 'SFX_PICTO_0011'],
    [PictogramEnum.SFX_PICTO_0012, 'SFX_PICTO_0012'],
    [PictogramEnum.SFX_PICTO_0013, 'SFX_PICTO_0013'],
    [PictogramEnum.SFX_PICTO_0014, 'SFX_PICTO_0014'],
    [PictogramEnum.SFX_PICTO_0015, 'SFX_PICTO_0015'],
    [PictogramEnum.SFX_PICTO_0016, 'SFX_PICTO_0016'],
    [PictogramEnum.SFX_PICTO_0017, 'SFX_PICTO_0017'],
    [PictogramEnum.SFX_PICTO_0018, 'SFX_PICTO_0018'],
    [PictogramEnum.SFX_PICTO_0019, 'SFX_PICTO_0019'],
    [PictogramEnum.SFX_PICTO_0020, 'SFX_PICTO_0020'],
    [PictogramEnum.SFX_PICTO_0021, 'SFX_PICTO_0021'],
    [PictogramEnum.SFX_PICTO_0022, 'SFX_PICTO_0022'],
    [PictogramEnum.SFX_PICTO_0023, 'SFX_PICTO_0023'],
    [PictogramEnum.SFX_PICTO_0024, 'SFX_PICTO_0024'],
    [PictogramEnum.SFX_PICTO_0025, 'SFX_PICTO_0025'],
    [PictogramEnum.SFX_PICTO_0026, 'SFX_PICTO_0026'],
    [PictogramEnum.SFX_PICTO_0027, 'SFX_PICTO_0027'],
    [PictogramEnum.SFX_PICTO_0028, 'SFX_PICTO_0028'],
    [PictogramEnum.SFX_PICTO_0029, 'SFX_PICTO_0029'],
    [PictogramEnum.SFX_PICTO_0030, 'SFX_PICTO_0030'],
    [PictogramEnum.SFX_PICTO_0031, 'SFX_PICTO_0031'],
    [PictogramEnum.SFX_PICTO_0032, 'SFX_PICTO_0032'],
    [PictogramEnum.SFX_PICTO_0033, 'SFX_PICTO_0033'],
    [PictogramEnum.SFX_PICTO_0034, 'SFX_PICTO_0034'],
    [PictogramEnum.SFX_PICTO_0035, 'SFX_PICTO_0035'],
    [PictogramEnum.SFX_PICTO_0036, 'SFX_PICTO_0036'],
    [PictogramEnum.SFX_PICTO_0037, 'SFX_PICTO_0037'],
    [PictogramEnum.SFX_PICTO_0038, 'SFX_PICTO_0038'],
    [PictogramEnum.SFX_PICTO_0039, 'SFX_PICTO_0039'],
    [PictogramEnum.SFX_PICTO_0040, 'SFX_PICTO_0040'],
    [PictogramEnum.SFX_PICTO_0041, 'SFX_PICTO_0041'],
    [PictogramEnum.SFX_PICTO_0042, 'SFX_PICTO_0042'],
    [PictogramEnum.SFX_PICTO_0043, 'SFX_PICTO_0043'],
    [PictogramEnum.SFX_PICTO_0044, 'SFX_PICTO_0044'],
    [PictogramEnum.SFX_PICTO_0045, 'SFX_PICTO_0045'],
    [PictogramEnum.SFX_PICTO_0046, 'SFX_PICTO_0046'],
    [PictogramEnum.SFX_PICTO_0047, 'SFX_PICTO_0047'],
    [PictogramEnum.SFX_PICTO_0048, 'SFX_PICTO_0048'],
    [PictogramEnum.SFX_PICTO_0049, 'SFX_PICTO_0049'],
    [PictogramEnum.SFX_PICTO_0050, 'SFX_PICTO_0050'],
    [PictogramEnum.SFX_PICTO_0051, 'SFX_PICTO_0051'],
    [PictogramEnum.SFX_PICTO_0052, 'SFX_PICTO_0052'],
    [PictogramEnum.SFX_PICTO_0053, 'SFX_PICTO_0053'],
    [PictogramEnum.SFX_PICTO_0054, 'SFX_PICTO_0054'],
    [PictogramEnum.SFX_PICTO_0055, 'SFX_PICTO_0055'],
    [PictogramEnum.SFX_PICTO_0056, 'SFX_PICTO_0056'],
    [PictogramEnum.SFX_PICTO_0057, 'SFX_PICTO_0057'],
    [PictogramEnum.SFX_PICTO_0058, 'SFX_PICTO_0058'],
    [PictogramEnum.SFX_PICTO_0059, 'SFX_PICTO_0059'],
    [PictogramEnum.SFX_PICTO_0060, 'SFX_PICTO_0060'],
    [PictogramEnum.SFX_PICTO_0061, 'SFX_PICTO_0061'],
    [PictogramEnum.SFX_PICTO_0062, 'SFX_PICTO_0062'],
    [PictogramEnum.SFX_PICTO_0063, 'SFX_PICTO_0063'],
    [PictogramEnum.SFX_PICTO_0064, 'SFX_PICTO_0064'],
    [PictogramEnum.SFX_PICTO_0065, 'SFX_PICTO_0065'],
    [PictogramEnum.SFX_PICTO_0066, 'SFX_PICTO_0066'],
    [PictogramEnum.SFX_PICTO_0067, 'SFX_PICTO_0067'],
    [PictogramEnum.SFX_PICTO_0068, 'SFX_PICTO_0068'],
    [PictogramEnum.SFX_PICTO_0069, 'SFX_PICTO_0069'],
    [PictogramEnum.SFX_PICTO_0070, 'SFX_PICTO_0070'],
    [PictogramEnum.SFX_PICTO_0071, 'SFX_PICTO_0071'],
    [PictogramEnum.SFX_PICTO_0072, 'SFX_PICTO_0072'],
    [PictogramEnum.SFX_PICTO_0073, 'SFX_PICTO_0073'],
    [PictogramEnum.SFX_PICTO_0074, 'SFX_PICTO_0074'],
    [PictogramEnum.SFX_PICTO_0075, 'SFX_PICTO_0075'],
    [PictogramEnum.SFX_PICTO_0076, 'SFX_PICTO_0076'],
    [PictogramEnum.SFX_PICTO_0077, 'SFX_PICTO_0077'],
    [PictogramEnum.SFX_PICTO_0078, 'SFX_PICTO_0078'],
    [PictogramEnum.SFX_PICTO_0079, 'SFX_PICTO_0079'],
    [PictogramEnum.SFX_PICTO_0080, 'SFX_PICTO_0080'],
    [PictogramEnum.SFX_PICTO_0081, 'SFX_PICTO_0081'],
    [PictogramEnum.SFX_PICTO_0082, 'SFX_PICTO_0082'],
    [PictogramEnum.SFX_PICTO_0083, 'SFX_PICTO_0083'],
    [PictogramEnum.SFX_PICTO_0084, 'SFX_PICTO_0084'],
    [PictogramEnum.SFX_PICTO_0085, 'SFX_PICTO_0085'],
    [PictogramEnum.SFX_PICTO_0086, 'SFX_PICTO_0086'],
    [PictogramEnum.SFX_PICTO_0087, 'SFX_PICTO_0087'],
    [PictogramEnum.SFX_PICTO_0088, 'SFX_PICTO_0088'],
    [PictogramEnum.SFX_PICTO_0089, 'SFX_PICTO_0089'],
    [PictogramEnum.SFX_PICTO_0090, 'SFX_PICTO_0090'],
    [PictogramEnum.SFX_PICTO_0091, 'SFX_PICTO_0091'],
    [PictogramEnum.SFX_PICTO_0092, 'SFX_PICTO_0092'],
    [PictogramEnum.SFX_PICTO_0093, 'SFX_PICTO_0093'],
    [PictogramEnum.SFX_PICTO_0094, 'SFX_PICTO_0094'],
    [PictogramEnum.SFX_PICTO_0095, 'SFX_PICTO_0095'],
    [PictogramEnum.SFX_PICTO_0096, 'SFX_PICTO_0096'],
    [PictogramEnum.SFX_PICTO_0097, 'SFX_PICTO_0097'],
    [PictogramEnum.SFX_PICTO_0098, 'SFX_PICTO_0098'],
    [PictogramEnum.SFX_PICTO_0099, 'SFX_PICTO_0099'],
    [PictogramEnum.SFX_PICTO_0100, 'SFX_PICTO_0100'],
    [PictogramEnum.SFX_PICTO_0101, 'SFX_PICTO_0101'],
    [PictogramEnum.SFX_PICTO_0102, 'SFX_PICTO_0102'],
    [PictogramEnum.SFX_PICTO_0103, 'SFX_PICTO_0103'],
    [PictogramEnum.SFX_PICTO_0104, 'SFX_PICTO_0104'],
    [PictogramEnum.SFX_PICTO_0105, 'SFX_PICTO_0105'],
    [PictogramEnum.SFX_PICTO_0106, 'SFX_PICTO_0106'],
    [PictogramEnum.SFX_PICTO_0107, 'SFX_PICTO_0107'],
    [PictogramEnum.SFX_PICTO_0108, 'SFX_PICTO_0108'],
    [PictogramEnum.SFX_PICTO_0109, 'SFX_PICTO_0109'],
    [PictogramEnum.SFX_PICTO_0110, 'SFX_PICTO_0110'],
    [PictogramEnum.SFX_PICTO_0111, 'SFX_PICTO_0111'],
    [PictogramEnum.SFX_PICTO_0112, 'SFX_PICTO_0112'],
    [PictogramEnum.SFX_PICTO_0113, 'SFX_PICTO_0113'],
    [PictogramEnum.SFX_PICTO_0114, 'SFX_PICTO_0114'],
    [PictogramEnum.SFX_PICTO_0115, 'SFX_PICTO_0115'],
    [PictogramEnum.SFX_PICTO_0116, 'SFX_PICTO_0116'],
    [PictogramEnum.SFX_PICTO_0117, 'SFX_PICTO_0117'],
    [PictogramEnum.SFX_PICTO_0118, 'SFX_PICTO_0118'],
    [PictogramEnum.SFX_PICTO_0119, 'SFX_PICTO_0119'],
    [PictogramEnum.SFX_PICTO_0120, 'SFX_PICTO_0120'],
    [PictogramEnum.SFX_PICTO_0121, 'SFX_PICTO_0121'],
    [PictogramEnum.SFX_PICTO_0122, 'SFX_PICTO_0122'],
    [PictogramEnum.SFX_PICTO_0123, 'SFX_PICTO_0123'],
    [PictogramEnum.SFX_PICTO_0124, 'SFX_PICTO_0124'],
    [PictogramEnum.SFX_PICTO_0125, 'SFX_PICTO_0125'],
    [PictogramEnum.SFX_PICTO_0126, 'SFX_PICTO_0126'],
    [PictogramEnum.SFX_PICTO_0127, 'SFX_PICTO_0127'],
    [PictogramEnum.SFX_PICTO_0128, 'SFX_PICTO_0128'],
    [PictogramEnum.SFX_PICTO_0129, 'SFX_PICTO_0129'],
    [PictogramEnum.SFX_PICTO_0130, 'SFX_PICTO_0130'],
    [PictogramEnum.SFX_PICTO_0131, 'SFX_PICTO_0131'],
    [PictogramEnum.SFX_PICTO_0132, 'SFX_PICTO_0132'],
    [PictogramEnum.SFX_PICTO_0133, 'SFX_PICTO_0133'],
    [PictogramEnum.SFX_PICTO_0134, 'SFX_PICTO_0134'],
    [PictogramEnum.SFX_PICTO_0135, 'SFX_PICTO_0135'],
    [PictogramEnum.SFX_PICTO_0136, 'SFX_PICTO_0136'],
    [PictogramEnum.SFX_PICTO_0137, 'SFX_PICTO_0137'],
    [PictogramEnum.SFX_PICTO_0138, 'SFX_PICTO_0138'],
    [PictogramEnum.SFX_PICTO_0139, 'SFX_PICTO_0139'],
    [PictogramEnum.SFX_PICTO_0140, 'SFX_PICTO_0140'],
    [PictogramEnum.SFX_PICTO_0141, 'SFX_PICTO_0141'],
    [PictogramEnum.SFX_PICTO_0142, 'SFX_PICTO_0142'],
    [PictogramEnum.SFX_PICTO_0143, 'SFX_PICTO_0143'],
    [PictogramEnum.SFX_PICTO_0144, 'SFX_PICTO_0144'],
    [PictogramEnum.SFX_PICTO_0145, 'SFX_PICTO_0145'],
    [PictogramEnum.SFX_PICTO_0146, 'SFX_PICTO_0146'],
    [PictogramEnum.SFX_PICTO_0147, 'SFX_PICTO_0147'],
    [PictogramEnum.SFX_PICTO_0148, 'SFX_PICTO_0148'],
    [PictogramEnum.SFX_PICTO_0149, 'SFX_PICTO_0149'],
    [PictogramEnum.SFX_PICTO_0150, 'SFX_PICTO_0150'],
    [PictogramEnum.SFX_PICTO_0151, 'SFX_PICTO_0151'],
    [PictogramEnum.SFX_PICTO_0152, 'SFX_PICTO_0152'],
    [PictogramEnum.SFX_PICTO_0153, 'SFX_PICTO_0153'],
    [PictogramEnum.SFX_PICTO_0154, 'SFX_PICTO_0154'],
    [PictogramEnum.SFX_PICTO_0155, 'SFX_PICTO_0155'],
    [PictogramEnum.SFX_PICTO_0156, 'SFX_PICTO_0156'],
    [PictogramEnum.SFX_PICTO_0157, 'SFX_PICTO_0157'],
    [PictogramEnum.SFX_PICTO_0158, 'SFX_PICTO_0158'],
    [PictogramEnum.SFX_PICTO_0159, 'SFX_PICTO_0159'],
    [PictogramEnum.SFX_PICTO_0160, 'SFX_PICTO_0160'],
    [PictogramEnum.SFX_PICTO_0161, 'SFX_PICTO_0161'],
    [PictogramEnum.SFX_PICTO_0162, 'SFX_PICTO_0162'],
    [PictogramEnum.SFX_PICTO_0163, 'SFX_PICTO_0163'],
    [PictogramEnum.SFX_PICTO_0164, 'SFX_PICTO_0164'],
    [PictogramEnum.SFX_PICTO_0165, 'SFX_PICTO_0165'],
    [PictogramEnum.SFX_PICTO_0166, 'SFX_PICTO_0166'],
    [PictogramEnum.SFX_PICTO_0167, 'SFX_PICTO_0167'],
    [PictogramEnum.SFX_PICTO_0168, 'SFX_PICTO_0168'],
    [PictogramEnum.SFX_PICTO_0169, 'SFX_PICTO_0169'],
    [PictogramEnum.SFX_PICTO_0170, 'SFX_PICTO_0170'],
    [PictogramEnum.SFX_PICTO_0171, 'SFX_PICTO_0171'],
    [PictogramEnum.SFX_PICTO_0172, 'SFX_PICTO_0172'],
    [PictogramEnum.SFX_PICTO_0173, 'SFX_PICTO_0173'],
    [PictogramEnum.SFX_PICTO_0174, 'SFX_PICTO_0174'],
    [PictogramEnum.SFX_PICTO_0175, 'SFX_PICTO_0175'],
    [PictogramEnum.SFX_PICTO_0176, 'SFX_PICTO_0176'],
    [PictogramEnum.SFX_PICTO_0177, 'SFX_PICTO_0177'],
    [PictogramEnum.SFX_PICTO_0178, 'SFX_PICTO_0178'],
    [PictogramEnum.SFX_PICTO_0179, 'SFX_PICTO_0179'],
    [PictogramEnum.SFX_PICTO_0180, 'SFX_PICTO_0180'],
    [PictogramEnum.SFX_PICTO_0181, 'SFX_PICTO_0181'],
    [PictogramEnum.SFX_PICTO_0182, 'SFX_PICTO_0182'],
    [PictogramEnum.SFX_PICTO_0183, 'SFX_PICTO_0183'],
    [PictogramEnum.SFX_PICTO_0184, 'SFX_PICTO_0184'],
    [PictogramEnum.SFX_PICTO_0185, 'SFX_PICTO_0185'],
    [PictogramEnum.SFX_PICTO_0186, 'SFX_PICTO_0186'],
    [PictogramEnum.SFX_PICTO_0187, 'SFX_PICTO_0187'],
    [PictogramEnum.SFX_PICTO_0188, 'SFX_PICTO_0188'],
    [PictogramEnum.SFX_PICTO_0189, 'SFX_PICTO_0189'],
    [PictogramEnum.SFX_PICTO_0190, 'SFX_PICTO_0190'],
    [PictogramEnum.SFX_PICTO_0191, 'SFX_PICTO_0191'],
    [PictogramEnum.SFX_PICTO_0192, 'SFX_PICTO_0192'],
    [PictogramEnum.SFX_PICTO_0193, 'SFX_PICTO_0193'],
    [PictogramEnum.SFX_PICTO_0194, 'SFX_PICTO_0194'],
    [PictogramEnum.SFX_PICTO_0195, 'SFX_PICTO_0195'],
    [PictogramEnum.SFX_PICTO_0196, 'SFX_PICTO_0196'],
    [PictogramEnum.SFX_PICTO_0197, 'SFX_PICTO_0197'],
    [PictogramEnum.SFX_PICTO_0198, 'SFX_PICTO_0198'],
    [PictogramEnum.SFX_PICTO_0199, 'SFX_PICTO_0199'],
    [PictogramEnum.SFX_PICTO_0200, 'SFX_PICTO_0200'],
    [PictogramEnum.SFX_PICTO_0201, 'SFX_PICTO_0201'],
    [PictogramEnum.SFX_PICTO_0202, 'SFX_PICTO_0202'],
    [PictogramEnum.SFX_PICTO_0203, 'SFX_PICTO_0203'],
    [PictogramEnum.SFX_PICTO_0204, 'SFX_PICTO_0204'],
    [PictogramEnum.SFX_PICTO_0205, 'SFX_PICTO_0205'],
    [PictogramEnum.SFX_PICTO_0206, 'SFX_PICTO_0206'],
    [PictogramEnum.SFX_PICTO_0207, 'SFX_PICTO_0207'],
    [PictogramEnum.SFX_PICTO_0208, 'SFX_PICTO_0208'],
    [PictogramEnum.SFX_PICTO_0209, 'SFX_PICTO_0209'],
    [PictogramEnum.SFX_PICTO_0210, 'SFX_PICTO_0210'],
    [PictogramEnum.SFX_PICTO_0211, 'SFX_PICTO_0211'],
    [PictogramEnum.SFX_PICTO_0212, 'SFX_PICTO_0212'],
    [PictogramEnum.SFX_PICTO_0213, 'SFX_PICTO_0213'],
    [PictogramEnum.SFX_PICTO_0214, 'SFX_PICTO_0214'],
    [PictogramEnum.SFX_PICTO_0215, 'SFX_PICTO_0215'],
    [PictogramEnum.SFX_PICTO_0216, 'SFX_PICTO_0216'],
    [PictogramEnum.SFX_PICTO_0217, 'SFX_PICTO_0217'],
    [PictogramEnum.SFX_PICTO_0218, 'SFX_PICTO_0218'],
    [PictogramEnum.SFX_PICTO_0219, 'SFX_PICTO_0219'],
    [PictogramEnum.SFX_PICTO_0220, 'SFX_PICTO_0220'],
    [PictogramEnum.SFX_PICTO_0221, 'SFX_PICTO_0221'],
    [PictogramEnum.SFX_PICTO_0222, 'SFX_PICTO_0222'],
    [PictogramEnum.SFX_PICTO_0223, 'SFX_PICTO_0223'],
    [PictogramEnum.SFX_PICTO_0224, 'SFX_PICTO_0224'],
    [PictogramEnum.SFX_PICTO_0225, 'SFX_PICTO_0225'],
    [PictogramEnum.SFX_PICTO_0226, 'SFX_PICTO_0226'],
    [PictogramEnum.SFX_PICTO_0227, 'SFX_PICTO_0227'],
    [PictogramEnum.SFX_PICTO_0228, 'SFX_PICTO_0228'],
    [PictogramEnum.SFX_PICTO_0229, 'SFX_PICTO_0229'],
    [PictogramEnum.SFX_PICTO_0230, 'SFX_PICTO_0230'],
    [PictogramEnum.SFX_PICTO_0231, 'SFX_PICTO_0231'],
    [PictogramEnum.SFX_PICTO_0232, 'SFX_PICTO_0232'],
    [PictogramEnum.SFX_PICTO_0233, 'SFX_PICTO_0233'],
    [PictogramEnum.SFX_PICTO_0234, 'SFX_PICTO_0234'],
    [PictogramEnum.SFX_PICTO_0235, 'SFX_PICTO_0235'],
    [PictogramEnum.SFX_PICTO_0236, 'SFX_PICTO_0236'],
    [PictogramEnum.SFX_PICTO_0237, 'SFX_PICTO_0237'],
    [PictogramEnum.SFX_PICTO_0238, 'SFX_PICTO_0238'],
    [PictogramEnum.SFX_PICTO_0239, 'SFX_PICTO_0239'],
    [PictogramEnum.SFX_PICTO_0240, 'SFX_PICTO_0240'],
  ]);

  const key = 'riskLibrary.pictogram.';
  export const toString: SfxMap<PictogramEnum, string> = new SfxMap<PictogramEnum, string>([
    [PictogramEnum.SFX_PICTO_0001, key + 'picto1'],
    [PictogramEnum.SFX_PICTO_0002, key + 'picto2'],
    [PictogramEnum.SFX_PICTO_0003, key + 'picto3'],
    [PictogramEnum.SFX_PICTO_0004, key + 'picto4'],
    [PictogramEnum.SFX_PICTO_0005, key + 'picto5'],
    [PictogramEnum.SFX_PICTO_0006, key + 'picto6'],
    [PictogramEnum.SFX_PICTO_0007, key + 'picto7'],
    [PictogramEnum.SFX_PICTO_0008, key + 'picto8'],
    [PictogramEnum.SFX_PICTO_0009, key + 'picto9'],
    [PictogramEnum.SFX_PICTO_0010, key + 'picto10'],
    [PictogramEnum.SFX_PICTO_0011, key + 'picto11'],
    [PictogramEnum.SFX_PICTO_0012, key + 'picto12'],
    [PictogramEnum.SFX_PICTO_0013, key + 'picto13'],
    [PictogramEnum.SFX_PICTO_0014, key + 'picto14'],
    [PictogramEnum.SFX_PICTO_0015, key + 'picto15'],
    [PictogramEnum.SFX_PICTO_0016, key + 'picto16'],
    [PictogramEnum.SFX_PICTO_0017, key + 'picto17'],
    [PictogramEnum.SFX_PICTO_0018, key + 'picto18'],
    [PictogramEnum.SFX_PICTO_0019, key + 'picto19'],
    [PictogramEnum.SFX_PICTO_0020, key + 'picto20'],
    [PictogramEnum.SFX_PICTO_0021, key + 'picto21'],
    [PictogramEnum.SFX_PICTO_0022, key + 'picto22'],
    [PictogramEnum.SFX_PICTO_0023, key + 'picto23'],
    [PictogramEnum.SFX_PICTO_0024, key + 'picto24'],
    [PictogramEnum.SFX_PICTO_0025, key + 'picto25'],
    [PictogramEnum.SFX_PICTO_0026, key + 'picto26'],
    [PictogramEnum.SFX_PICTO_0027, key + 'picto27'],
    [PictogramEnum.SFX_PICTO_0028, key + 'picto28'],
    [PictogramEnum.SFX_PICTO_0029, key + 'picto29'],
    [PictogramEnum.SFX_PICTO_0030, key + 'picto30'],
    [PictogramEnum.SFX_PICTO_0031, key + 'picto31'],
    [PictogramEnum.SFX_PICTO_0032, key + 'picto32'],
    [PictogramEnum.SFX_PICTO_0033, key + 'picto33'],
    [PictogramEnum.SFX_PICTO_0034, key + 'picto34'],
    [PictogramEnum.SFX_PICTO_0035, key + 'picto35'],
    [PictogramEnum.SFX_PICTO_0036, key + 'picto36'],
    [PictogramEnum.SFX_PICTO_0037, key + 'picto37'],
    [PictogramEnum.SFX_PICTO_0038, key + 'picto38'],
    [PictogramEnum.SFX_PICTO_0039, key + 'picto39'],
    [PictogramEnum.SFX_PICTO_0040, key + 'picto40'],
    [PictogramEnum.SFX_PICTO_0041, key + 'picto41'],
    [PictogramEnum.SFX_PICTO_0042, key + 'picto42'],
    [PictogramEnum.SFX_PICTO_0043, key + 'picto43'],
    [PictogramEnum.SFX_PICTO_0044, key + 'picto44'],
    [PictogramEnum.SFX_PICTO_0045, key + 'picto45'],
    [PictogramEnum.SFX_PICTO_0046, key + 'picto46'],
    [PictogramEnum.SFX_PICTO_0047, key + 'picto47'],
    [PictogramEnum.SFX_PICTO_0048, key + 'picto48'],
    [PictogramEnum.SFX_PICTO_0049, key + 'picto49'],
    [PictogramEnum.SFX_PICTO_0050, key + 'picto50'],
    [PictogramEnum.SFX_PICTO_0051, key + 'picto51'],
    [PictogramEnum.SFX_PICTO_0052, key + 'picto52'],
    [PictogramEnum.SFX_PICTO_0053, key + 'picto53'],
    [PictogramEnum.SFX_PICTO_0054, key + 'picto54'],
    [PictogramEnum.SFX_PICTO_0055, key + 'picto55'],
    [PictogramEnum.SFX_PICTO_0056, key + 'picto56'],
    [PictogramEnum.SFX_PICTO_0057, key + 'picto57'],
    [PictogramEnum.SFX_PICTO_0058, key + 'picto58'],
    [PictogramEnum.SFX_PICTO_0059, key + 'picto59'],
    [PictogramEnum.SFX_PICTO_0060, key + 'picto60'],
    [PictogramEnum.SFX_PICTO_0061, key + 'picto61'],
    [PictogramEnum.SFX_PICTO_0062, key + 'picto62'],
    [PictogramEnum.SFX_PICTO_0063, key + 'picto63'],
    [PictogramEnum.SFX_PICTO_0064, key + 'picto64'],
    [PictogramEnum.SFX_PICTO_0065, key + 'picto65'],
    [PictogramEnum.SFX_PICTO_0066, key + 'picto66'],
    [PictogramEnum.SFX_PICTO_0067, key + 'picto67'],
    [PictogramEnum.SFX_PICTO_0068, key + 'picto68'],
    [PictogramEnum.SFX_PICTO_0069, key + 'picto69'],
    [PictogramEnum.SFX_PICTO_0070, key + 'picto70'],
    [PictogramEnum.SFX_PICTO_0071, key + 'picto71'],
    [PictogramEnum.SFX_PICTO_0072, key + 'picto72'],
    [PictogramEnum.SFX_PICTO_0073, key + 'picto73'],
    [PictogramEnum.SFX_PICTO_0074, key + 'picto74'],
    [PictogramEnum.SFX_PICTO_0075, key + 'picto75'],
    [PictogramEnum.SFX_PICTO_0076, key + 'picto76'],
    [PictogramEnum.SFX_PICTO_0077, key + 'picto77'],
    [PictogramEnum.SFX_PICTO_0078, key + 'picto78'],
    [PictogramEnum.SFX_PICTO_0079, key + 'picto79'],
    [PictogramEnum.SFX_PICTO_0080, key + 'picto80'],
    [PictogramEnum.SFX_PICTO_0081, key + 'picto81'],
    [PictogramEnum.SFX_PICTO_0082, key + 'picto82'],
    [PictogramEnum.SFX_PICTO_0083, key + 'picto83'],
    [PictogramEnum.SFX_PICTO_0084, key + 'picto84'],
    [PictogramEnum.SFX_PICTO_0085, key + 'picto85'],
    [PictogramEnum.SFX_PICTO_0086, key + 'picto86'],
    [PictogramEnum.SFX_PICTO_0087, key + 'picto87'],
    [PictogramEnum.SFX_PICTO_0088, key + 'picto88'],
    [PictogramEnum.SFX_PICTO_0089, key + 'picto89'],
    [PictogramEnum.SFX_PICTO_0090, key + 'picto90'],
    [PictogramEnum.SFX_PICTO_0091, key + 'picto91'],
    [PictogramEnum.SFX_PICTO_0092, key + 'picto92'],
    [PictogramEnum.SFX_PICTO_0093, key + 'picto93'],
    [PictogramEnum.SFX_PICTO_0094, key + 'picto94'],
    [PictogramEnum.SFX_PICTO_0095, key + 'picto95'],
    [PictogramEnum.SFX_PICTO_0096, key + 'picto96'],
    [PictogramEnum.SFX_PICTO_0097, key + 'picto97'],
    [PictogramEnum.SFX_PICTO_0098, key + 'picto98'],
    [PictogramEnum.SFX_PICTO_0099, key + 'picto99'],
    [PictogramEnum.SFX_PICTO_0100, key + 'picto100'],
    [PictogramEnum.SFX_PICTO_0101, key + 'picto101'],
    [PictogramEnum.SFX_PICTO_0102, key + 'picto102'],
    [PictogramEnum.SFX_PICTO_0103, key + 'picto103'],
    [PictogramEnum.SFX_PICTO_0104, key + 'picto104'],
    [PictogramEnum.SFX_PICTO_0105, key + 'picto105'],
    [PictogramEnum.SFX_PICTO_0106, key + 'picto106'],
    [PictogramEnum.SFX_PICTO_0107, key + 'picto107'],
    [PictogramEnum.SFX_PICTO_0108, key + 'picto108'],
    [PictogramEnum.SFX_PICTO_0109, key + 'picto109'],
    [PictogramEnum.SFX_PICTO_0110, key + 'picto110'],
    [PictogramEnum.SFX_PICTO_0111, key + 'picto111'],
    [PictogramEnum.SFX_PICTO_0112, key + 'picto112'],
    [PictogramEnum.SFX_PICTO_0113, key + 'picto113'],
    [PictogramEnum.SFX_PICTO_0114, key + 'picto114'],
    [PictogramEnum.SFX_PICTO_0115, key + 'picto115'],
    [PictogramEnum.SFX_PICTO_0116, key + 'picto116'],
    [PictogramEnum.SFX_PICTO_0117, key + 'picto117'],
    [PictogramEnum.SFX_PICTO_0118, key + 'picto118'],
    [PictogramEnum.SFX_PICTO_0119, key + 'picto119'],
    [PictogramEnum.SFX_PICTO_0120, key + 'picto120'],
    [PictogramEnum.SFX_PICTO_0121, key + 'picto121'],
    [PictogramEnum.SFX_PICTO_0122, key + 'picto122'],
    [PictogramEnum.SFX_PICTO_0123, key + 'picto123'],
    [PictogramEnum.SFX_PICTO_0124, key + 'picto124'],
    [PictogramEnum.SFX_PICTO_0125, key + 'picto125'],
    [PictogramEnum.SFX_PICTO_0126, key + 'picto126'],
    [PictogramEnum.SFX_PICTO_0127, key + 'picto127'],
    [PictogramEnum.SFX_PICTO_0128, key + 'picto128'],
    [PictogramEnum.SFX_PICTO_0129, key + 'picto129'],
    [PictogramEnum.SFX_PICTO_0130, key + 'picto130'],
    [PictogramEnum.SFX_PICTO_0131, key + 'picto131'],
    [PictogramEnum.SFX_PICTO_0132, key + 'picto132'],
    [PictogramEnum.SFX_PICTO_0133, key + 'picto133'],
    [PictogramEnum.SFX_PICTO_0134, key + 'picto134'],
    [PictogramEnum.SFX_PICTO_0135, key + 'picto135'],
    [PictogramEnum.SFX_PICTO_0136, key + 'picto136'],
    [PictogramEnum.SFX_PICTO_0137, key + 'picto137'],
    [PictogramEnum.SFX_PICTO_0138, key + 'picto138'],
    [PictogramEnum.SFX_PICTO_0139, key + 'picto139'],
    [PictogramEnum.SFX_PICTO_0140, key + 'picto140'],
    [PictogramEnum.SFX_PICTO_0141, key + 'picto141'],
    [PictogramEnum.SFX_PICTO_0142, key + 'picto142'],
    [PictogramEnum.SFX_PICTO_0143, key + 'picto143'],
    [PictogramEnum.SFX_PICTO_0144, key + 'picto144'],
    [PictogramEnum.SFX_PICTO_0145, key + 'picto145'],
    [PictogramEnum.SFX_PICTO_0146, key + 'picto146'],
    [PictogramEnum.SFX_PICTO_0147, key + 'picto147'],
    [PictogramEnum.SFX_PICTO_0148, key + 'picto148'],
    [PictogramEnum.SFX_PICTO_0149, key + 'picto149'],
    [PictogramEnum.SFX_PICTO_0150, key + 'picto150'],
    [PictogramEnum.SFX_PICTO_0151, key + 'picto151'],
    [PictogramEnum.SFX_PICTO_0152, key + 'picto152'],
    [PictogramEnum.SFX_PICTO_0153, key + 'picto153'],
    [PictogramEnum.SFX_PICTO_0154, key + 'picto154'],
    [PictogramEnum.SFX_PICTO_0155, key + 'picto155'],
    [PictogramEnum.SFX_PICTO_0156, key + 'picto156'],
    [PictogramEnum.SFX_PICTO_0157, key + 'picto157'],
    [PictogramEnum.SFX_PICTO_0158, key + 'picto158'],
    [PictogramEnum.SFX_PICTO_0159, key + 'picto159'],
    [PictogramEnum.SFX_PICTO_0160, key + 'picto160'],
    [PictogramEnum.SFX_PICTO_0161, key + 'picto161'],
    [PictogramEnum.SFX_PICTO_0162, key + 'picto162'],
    [PictogramEnum.SFX_PICTO_0163, key + 'picto163'],
    [PictogramEnum.SFX_PICTO_0164, key + 'picto164'],
    [PictogramEnum.SFX_PICTO_0165, key + 'picto165'],
    [PictogramEnum.SFX_PICTO_0166, key + 'picto166'],
    [PictogramEnum.SFX_PICTO_0167, key + 'picto167'],
    [PictogramEnum.SFX_PICTO_0168, key + 'picto168'],
    [PictogramEnum.SFX_PICTO_0169, key + 'picto169'],
    [PictogramEnum.SFX_PICTO_0170, key + 'picto170'],
    [PictogramEnum.SFX_PICTO_0171, key + 'picto171'],
    [PictogramEnum.SFX_PICTO_0172, key + 'picto172'],
    [PictogramEnum.SFX_PICTO_0173, key + 'picto173'],
    [PictogramEnum.SFX_PICTO_0174, key + 'picto174'],
    [PictogramEnum.SFX_PICTO_0175, key + 'picto175'],
    [PictogramEnum.SFX_PICTO_0176, key + 'picto176'],
    [PictogramEnum.SFX_PICTO_0177, key + 'picto177'],
    [PictogramEnum.SFX_PICTO_0178, key + 'picto178'],
    [PictogramEnum.SFX_PICTO_0179, key + 'picto179'],
    [PictogramEnum.SFX_PICTO_0180, key + 'picto180'],
    [PictogramEnum.SFX_PICTO_0181, key + 'picto181'],
    [PictogramEnum.SFX_PICTO_0182, key + 'picto182'],
    [PictogramEnum.SFX_PICTO_0183, key + 'picto183'],
    [PictogramEnum.SFX_PICTO_0184, key + 'picto184'],
    [PictogramEnum.SFX_PICTO_0185, key + 'picto185'],
    [PictogramEnum.SFX_PICTO_0186, key + 'picto186'],
    [PictogramEnum.SFX_PICTO_0187, key + 'picto187'],
    [PictogramEnum.SFX_PICTO_0188, key + 'picto188'],
    [PictogramEnum.SFX_PICTO_0189, key + 'picto189'],
    [PictogramEnum.SFX_PICTO_0190, key + 'picto190'],
    [PictogramEnum.SFX_PICTO_0191, key + 'picto191'],
    [PictogramEnum.SFX_PICTO_0192, key + 'picto192'],
    [PictogramEnum.SFX_PICTO_0193, key + 'picto193'],
    [PictogramEnum.SFX_PICTO_0194, key + 'picto194'],
    [PictogramEnum.SFX_PICTO_0195, key + 'picto195'],
    [PictogramEnum.SFX_PICTO_0196, key + 'picto196'],
    [PictogramEnum.SFX_PICTO_0197, key + 'picto197'],
    [PictogramEnum.SFX_PICTO_0198, key + 'picto198'],
    [PictogramEnum.SFX_PICTO_0199, key + 'picto199'],
    [PictogramEnum.SFX_PICTO_0200, key + 'picto200'],
    [PictogramEnum.SFX_PICTO_0201, key + 'picto201'],
    [PictogramEnum.SFX_PICTO_0202, key + 'picto202'],
    [PictogramEnum.SFX_PICTO_0203, key + 'picto203'],
    [PictogramEnum.SFX_PICTO_0204, key + 'picto204'],
    [PictogramEnum.SFX_PICTO_0205, key + 'picto205'],
    [PictogramEnum.SFX_PICTO_0206, key + 'picto206'],
    [PictogramEnum.SFX_PICTO_0207, key + 'picto207'],
    [PictogramEnum.SFX_PICTO_0208, key + 'picto208'],
    [PictogramEnum.SFX_PICTO_0209, key + 'picto209'],
    [PictogramEnum.SFX_PICTO_0210, key + 'picto210'],
    [PictogramEnum.SFX_PICTO_0211, key + 'picto211'],
    [PictogramEnum.SFX_PICTO_0212, key + 'picto212'],
    [PictogramEnum.SFX_PICTO_0213, key + 'picto213'],
    [PictogramEnum.SFX_PICTO_0214, key + 'picto214'],
    [PictogramEnum.SFX_PICTO_0215, key + 'picto215'],
    [PictogramEnum.SFX_PICTO_0216, key + 'picto216'],
    [PictogramEnum.SFX_PICTO_0217, key + 'picto217'],
    [PictogramEnum.SFX_PICTO_0218, key + 'picto218'],
    [PictogramEnum.SFX_PICTO_0219, key + 'picto219'],
    [PictogramEnum.SFX_PICTO_0220, key + 'picto220'],
    [PictogramEnum.SFX_PICTO_0221, key + 'picto221'],
    [PictogramEnum.SFX_PICTO_0222, key + 'picto222'],
    [PictogramEnum.SFX_PICTO_0223, key + 'picto223'],
    [PictogramEnum.SFX_PICTO_0224, key + 'picto224'],
    [PictogramEnum.SFX_PICTO_0225, key + 'picto225'],
    [PictogramEnum.SFX_PICTO_0226, key + 'picto226'],
    [PictogramEnum.SFX_PICTO_0227, key + 'picto227'],
    [PictogramEnum.SFX_PICTO_0228, key + 'picto228'],
    [PictogramEnum.SFX_PICTO_0229, key + 'picto229'],
    [PictogramEnum.SFX_PICTO_0230, key + 'picto230'],
    [PictogramEnum.SFX_PICTO_0231, key + 'picto231'],
    [PictogramEnum.SFX_PICTO_0232, key + 'picto232'],
    [PictogramEnum.SFX_PICTO_0233, key + 'picto233'],
    [PictogramEnum.SFX_PICTO_0234, key + 'picto234'],
    [PictogramEnum.SFX_PICTO_0235, key + 'picto235'],
    [PictogramEnum.SFX_PICTO_0236, key + 'picto236'],
    [PictogramEnum.SFX_PICTO_0237, key + 'picto237'],
    [PictogramEnum.SFX_PICTO_0238, key + 'picto238'],
    [PictogramEnum.SFX_PICTO_0239, key + 'picto239'],
    [PictogramEnum.SFX_PICTO_0240, key + 'picto240'],
  ]);

  export const imagePrefix: SfxMap<PictogramEnum, string> = new SfxMap<PictogramEnum, string>([
    [PictogramEnum.SFX_PICTO_0001, 'SFX_PICTO_0001'],
    [PictogramEnum.SFX_PICTO_0002, 'SFX_PICTO_0002'],
    [PictogramEnum.SFX_PICTO_0003, 'SFX_PICTO_0003'],
    [PictogramEnum.SFX_PICTO_0004, 'SFX_PICTO_0004'],
    [PictogramEnum.SFX_PICTO_0005, 'SFX_PICTO_0005'],
    [PictogramEnum.SFX_PICTO_0006, 'SFX_PICTO_0006'],
    [PictogramEnum.SFX_PICTO_0007, 'SFX_PICTO_0007'],
    [PictogramEnum.SFX_PICTO_0008, 'SFX_PICTO_0008'],
    [PictogramEnum.SFX_PICTO_0009, 'SFX_PICTO_0009'],
    [PictogramEnum.SFX_PICTO_0010, 'SFX_PICTO_0010'],
    [PictogramEnum.SFX_PICTO_0011, 'SFX_PICTO_0011'],
    [PictogramEnum.SFX_PICTO_0012, 'SFX_PICTO_0012'],
    [PictogramEnum.SFX_PICTO_0013, 'SFX_PICTO_0013'],
    [PictogramEnum.SFX_PICTO_0014, 'SFX_PICTO_0014'],
    [PictogramEnum.SFX_PICTO_0015, 'SFX_PICTO_0015'],
    [PictogramEnum.SFX_PICTO_0016, 'SFX_PICTO_0016'],
    [PictogramEnum.SFX_PICTO_0017, 'SFX_PICTO_0017'],
    [PictogramEnum.SFX_PICTO_0018, 'SFX_PICTO_0018'],
    [PictogramEnum.SFX_PICTO_0019, 'SFX_PICTO_0019'],
    [PictogramEnum.SFX_PICTO_0020, 'SFX_PICTO_0020'],
    [PictogramEnum.SFX_PICTO_0021, 'SFX_PICTO_0021'],
    [PictogramEnum.SFX_PICTO_0022, 'SFX_PICTO_0022'],
    [PictogramEnum.SFX_PICTO_0023, 'SFX_PICTO_0023'],
    [PictogramEnum.SFX_PICTO_0024, 'SFX_PICTO_0024'],
    [PictogramEnum.SFX_PICTO_0025, 'SFX_PICTO_0025'],
    [PictogramEnum.SFX_PICTO_0026, 'SFX_PICTO_0026'],
    [PictogramEnum.SFX_PICTO_0027, 'SFX_PICTO_0027'],
    [PictogramEnum.SFX_PICTO_0028, 'SFX_PICTO_0028'],
    [PictogramEnum.SFX_PICTO_0029, 'SFX_PICTO_0029'],
    [PictogramEnum.SFX_PICTO_0030, 'SFX_PICTO_0030'],
    [PictogramEnum.SFX_PICTO_0031, 'SFX_PICTO_0031'],
    [PictogramEnum.SFX_PICTO_0032, 'SFX_PICTO_0032'],
    [PictogramEnum.SFX_PICTO_0033, 'SFX_PICTO_0033'],
    [PictogramEnum.SFX_PICTO_0034, 'SFX_PICTO_0034'],
    [PictogramEnum.SFX_PICTO_0035, 'SFX_PICTO_0035'],
    [PictogramEnum.SFX_PICTO_0036, 'SFX_PICTO_0036'],
    [PictogramEnum.SFX_PICTO_0037, 'SFX_PICTO_0037'],
    [PictogramEnum.SFX_PICTO_0038, 'SFX_PICTO_0038'],
    [PictogramEnum.SFX_PICTO_0039, 'SFX_PICTO_0039'],
    [PictogramEnum.SFX_PICTO_0040, 'SFX_PICTO_0040'],
    [PictogramEnum.SFX_PICTO_0041, 'SFX_PICTO_0041'],
    [PictogramEnum.SFX_PICTO_0042, 'SFX_PICTO_0042'],
    [PictogramEnum.SFX_PICTO_0043, 'SFX_PICTO_0043'],
    [PictogramEnum.SFX_PICTO_0044, 'SFX_PICTO_0044'],
    [PictogramEnum.SFX_PICTO_0045, 'SFX_PICTO_0045'],
    [PictogramEnum.SFX_PICTO_0046, 'SFX_PICTO_0046'],
    [PictogramEnum.SFX_PICTO_0047, 'SFX_PICTO_0047'],
    [PictogramEnum.SFX_PICTO_0048, 'SFX_PICTO_0048'],
    [PictogramEnum.SFX_PICTO_0049, 'SFX_PICTO_0049'],
    [PictogramEnum.SFX_PICTO_0050, 'SFX_PICTO_0050'],
    [PictogramEnum.SFX_PICTO_0051, 'SFX_PICTO_0051'],
    [PictogramEnum.SFX_PICTO_0052, 'SFX_PICTO_0052'],
    [PictogramEnum.SFX_PICTO_0053, 'SFX_PICTO_0053'],
    [PictogramEnum.SFX_PICTO_0054, 'SFX_PICTO_0054'],
    [PictogramEnum.SFX_PICTO_0055, 'SFX_PICTO_0055'],
    [PictogramEnum.SFX_PICTO_0056, 'SFX_PICTO_0056'],
    [PictogramEnum.SFX_PICTO_0057, 'SFX_PICTO_0057'],
    [PictogramEnum.SFX_PICTO_0058, 'SFX_PICTO_0058'],
    [PictogramEnum.SFX_PICTO_0059, 'SFX_PICTO_0059'],
    [PictogramEnum.SFX_PICTO_0060, 'SFX_PICTO_0060'],
    [PictogramEnum.SFX_PICTO_0061, 'SFX_PICTO_0061'],
    [PictogramEnum.SFX_PICTO_0062, 'SFX_PICTO_0062'],
    [PictogramEnum.SFX_PICTO_0063, 'SFX_PICTO_0063'],
    [PictogramEnum.SFX_PICTO_0064, 'SFX_PICTO_0064'],
    [PictogramEnum.SFX_PICTO_0065, 'SFX_PICTO_0065'],
    [PictogramEnum.SFX_PICTO_0066, 'SFX_PICTO_0066'],
    [PictogramEnum.SFX_PICTO_0067, 'SFX_PICTO_0067'],
    [PictogramEnum.SFX_PICTO_0068, 'SFX_PICTO_0068'],
    [PictogramEnum.SFX_PICTO_0069, 'SFX_PICTO_0069'],
    [PictogramEnum.SFX_PICTO_0070, 'SFX_PICTO_0070'],
    [PictogramEnum.SFX_PICTO_0071, 'SFX_PICTO_0071'],
    [PictogramEnum.SFX_PICTO_0072, 'SFX_PICTO_0072'],
    [PictogramEnum.SFX_PICTO_0073, 'SFX_PICTO_0073'],
    [PictogramEnum.SFX_PICTO_0074, 'SFX_PICTO_0074'],
    [PictogramEnum.SFX_PICTO_0075, 'SFX_PICTO_0075'],
    [PictogramEnum.SFX_PICTO_0076, 'SFX_PICTO_0076'],
    [PictogramEnum.SFX_PICTO_0077, 'SFX_PICTO_0077'],
    [PictogramEnum.SFX_PICTO_0078, 'SFX_PICTO_0078'],
    [PictogramEnum.SFX_PICTO_0079, 'SFX_PICTO_0079'],
    [PictogramEnum.SFX_PICTO_0080, 'SFX_PICTO_0080'],
    [PictogramEnum.SFX_PICTO_0081, 'SFX_PICTO_0081'],
    [PictogramEnum.SFX_PICTO_0082, 'SFX_PICTO_0082'],
    [PictogramEnum.SFX_PICTO_0083, 'SFX_PICTO_0083'],
    [PictogramEnum.SFX_PICTO_0084, 'SFX_PICTO_0084'],
    [PictogramEnum.SFX_PICTO_0085, 'SFX_PICTO_0085'],
    [PictogramEnum.SFX_PICTO_0086, 'SFX_PICTO_0086'],
    [PictogramEnum.SFX_PICTO_0087, 'SFX_PICTO_0087'],
    [PictogramEnum.SFX_PICTO_0088, 'SFX_PICTO_0088'],
    [PictogramEnum.SFX_PICTO_0089, 'SFX_PICTO_0089'],
    [PictogramEnum.SFX_PICTO_0090, 'SFX_PICTO_0090'],
    [PictogramEnum.SFX_PICTO_0091, 'SFX_PICTO_0091'],
    [PictogramEnum.SFX_PICTO_0092, 'SFX_PICTO_0092'],
    [PictogramEnum.SFX_PICTO_0093, 'SFX_PICTO_0093'],
    [PictogramEnum.SFX_PICTO_0094, 'SFX_PICTO_0094'],
    [PictogramEnum.SFX_PICTO_0095, 'SFX_PICTO_0095'],
    [PictogramEnum.SFX_PICTO_0096, 'SFX_PICTO_0096'],
    [PictogramEnum.SFX_PICTO_0097, 'SFX_PICTO_0097'],
    [PictogramEnum.SFX_PICTO_0098, 'SFX_PICTO_0098'],
    [PictogramEnum.SFX_PICTO_0099, 'SFX_PICTO_0099'],
    [PictogramEnum.SFX_PICTO_0100, 'SFX_PICTO_0100'],
    [PictogramEnum.SFX_PICTO_0101, 'SFX_PICTO_0101'],
    [PictogramEnum.SFX_PICTO_0102, 'SFX_PICTO_0102'],
    [PictogramEnum.SFX_PICTO_0103, 'SFX_PICTO_0103'],
    [PictogramEnum.SFX_PICTO_0104, 'SFX_PICTO_0104'],
    [PictogramEnum.SFX_PICTO_0105, 'SFX_PICTO_0105'],
    [PictogramEnum.SFX_PICTO_0106, 'SFX_PICTO_0106'],
    [PictogramEnum.SFX_PICTO_0107, 'SFX_PICTO_0107'],
    [PictogramEnum.SFX_PICTO_0108, 'SFX_PICTO_0108'],
    [PictogramEnum.SFX_PICTO_0109, 'SFX_PICTO_0109'],
    [PictogramEnum.SFX_PICTO_0110, 'SFX_PICTO_0110'],
    [PictogramEnum.SFX_PICTO_0111, 'SFX_PICTO_0111'],
    [PictogramEnum.SFX_PICTO_0112, 'SFX_PICTO_0112'],
    [PictogramEnum.SFX_PICTO_0113, 'SFX_PICTO_0113'],
    [PictogramEnum.SFX_PICTO_0114, 'SFX_PICTO_0114'],
    [PictogramEnum.SFX_PICTO_0115, 'SFX_PICTO_0115'],
    [PictogramEnum.SFX_PICTO_0116, 'SFX_PICTO_0116'],
    [PictogramEnum.SFX_PICTO_0117, 'SFX_PICTO_0117'],
    [PictogramEnum.SFX_PICTO_0118, 'SFX_PICTO_0118'],
    [PictogramEnum.SFX_PICTO_0119, 'SFX_PICTO_0119'],
    [PictogramEnum.SFX_PICTO_0120, 'SFX_PICTO_0120'],
    [PictogramEnum.SFX_PICTO_0121, 'SFX_PICTO_0121'],
    [PictogramEnum.SFX_PICTO_0122, 'SFX_PICTO_0122'],
    [PictogramEnum.SFX_PICTO_0123, 'SFX_PICTO_0123'],
    [PictogramEnum.SFX_PICTO_0124, 'SFX_PICTO_0124'],
    [PictogramEnum.SFX_PICTO_0125, 'SFX_PICTO_0125'],
    [PictogramEnum.SFX_PICTO_0126, 'SFX_PICTO_0126'],
    [PictogramEnum.SFX_PICTO_0127, 'SFX_PICTO_0127'],
    [PictogramEnum.SFX_PICTO_0128, 'SFX_PICTO_0128'],
    [PictogramEnum.SFX_PICTO_0129, 'SFX_PICTO_0129'],
    [PictogramEnum.SFX_PICTO_0130, 'SFX_PICTO_0130'],
    [PictogramEnum.SFX_PICTO_0131, 'SFX_PICTO_0131'],
    [PictogramEnum.SFX_PICTO_0132, 'SFX_PICTO_0132'],
    [PictogramEnum.SFX_PICTO_0133, 'SFX_PICTO_0133'],
    [PictogramEnum.SFX_PICTO_0134, 'SFX_PICTO_0134'],
    [PictogramEnum.SFX_PICTO_0135, 'SFX_PICTO_0135'],
    [PictogramEnum.SFX_PICTO_0136, 'SFX_PICTO_0136'],
    [PictogramEnum.SFX_PICTO_0137, 'SFX_PICTO_0137'],
    [PictogramEnum.SFX_PICTO_0138, 'SFX_PICTO_0138'],
    [PictogramEnum.SFX_PICTO_0139, 'SFX_PICTO_0139'],
    [PictogramEnum.SFX_PICTO_0140, 'SFX_PICTO_0140'],
    [PictogramEnum.SFX_PICTO_0141, 'SFX_PICTO_0141'],
    [PictogramEnum.SFX_PICTO_0142, 'SFX_PICTO_0142'],
    [PictogramEnum.SFX_PICTO_0143, 'SFX_PICTO_0143'],
    [PictogramEnum.SFX_PICTO_0144, 'SFX_PICTO_0144'],
    [PictogramEnum.SFX_PICTO_0145, 'SFX_PICTO_0145'],
    [PictogramEnum.SFX_PICTO_0146, 'SFX_PICTO_0146'],
    [PictogramEnum.SFX_PICTO_0147, 'SFX_PICTO_0147'],
    [PictogramEnum.SFX_PICTO_0148, 'SFX_PICTO_0148'],
    [PictogramEnum.SFX_PICTO_0149, 'SFX_PICTO_0149'],
    [PictogramEnum.SFX_PICTO_0150, 'SFX_PICTO_0150'],
    [PictogramEnum.SFX_PICTO_0151, 'SFX_PICTO_0151'],
    [PictogramEnum.SFX_PICTO_0152, 'SFX_PICTO_0152'],
    [PictogramEnum.SFX_PICTO_0153, 'SFX_PICTO_0153'],
    [PictogramEnum.SFX_PICTO_0154, 'SFX_PICTO_0154'],
    [PictogramEnum.SFX_PICTO_0155, 'SFX_PICTO_0155'],
    [PictogramEnum.SFX_PICTO_0156, 'SFX_PICTO_0156'],
    [PictogramEnum.SFX_PICTO_0157, 'SFX_PICTO_0157'],
    [PictogramEnum.SFX_PICTO_0158, 'SFX_PICTO_0158'],
    [PictogramEnum.SFX_PICTO_0159, 'SFX_PICTO_0159'],
    [PictogramEnum.SFX_PICTO_0160, 'SFX_PICTO_0160'],
    [PictogramEnum.SFX_PICTO_0161, 'SFX_PICTO_0161'],
    [PictogramEnum.SFX_PICTO_0162, 'SFX_PICTO_0162'],
    [PictogramEnum.SFX_PICTO_0163, 'SFX_PICTO_0163'],
    [PictogramEnum.SFX_PICTO_0164, 'SFX_PICTO_0164'],
    [PictogramEnum.SFX_PICTO_0165, 'SFX_PICTO_0165'],
    [PictogramEnum.SFX_PICTO_0166, 'SFX_PICTO_0166'],
    [PictogramEnum.SFX_PICTO_0167, 'SFX_PICTO_0167'],
    [PictogramEnum.SFX_PICTO_0168, 'SFX_PICTO_0168'],
    [PictogramEnum.SFX_PICTO_0169, 'SFX_PICTO_0169'],
    [PictogramEnum.SFX_PICTO_0170, 'SFX_PICTO_0170'],
    [PictogramEnum.SFX_PICTO_0171, 'SFX_PICTO_0171'],
    [PictogramEnum.SFX_PICTO_0172, 'SFX_PICTO_0172'],
    [PictogramEnum.SFX_PICTO_0173, 'SFX_PICTO_0173'],
    [PictogramEnum.SFX_PICTO_0174, 'SFX_PICTO_0174'],
    [PictogramEnum.SFX_PICTO_0175, 'SFX_PICTO_0175'],
    [PictogramEnum.SFX_PICTO_0176, 'SFX_PICTO_0176'],
    [PictogramEnum.SFX_PICTO_0177, 'SFX_PICTO_0177'],
    [PictogramEnum.SFX_PICTO_0178, 'SFX_PICTO_0178'],
    [PictogramEnum.SFX_PICTO_0179, 'SFX_PICTO_0179'],
    [PictogramEnum.SFX_PICTO_0180, 'SFX_PICTO_0180'],
    [PictogramEnum.SFX_PICTO_0181, 'SFX_PICTO_0181'],
    [PictogramEnum.SFX_PICTO_0182, 'SFX_PICTO_0182'],
    [PictogramEnum.SFX_PICTO_0183, 'SFX_PICTO_0183'],
    [PictogramEnum.SFX_PICTO_0184, 'SFX_PICTO_0184'],
    [PictogramEnum.SFX_PICTO_0185, 'SFX_PICTO_0185'],
    [PictogramEnum.SFX_PICTO_0186, 'SFX_PICTO_0186'],
    [PictogramEnum.SFX_PICTO_0187, 'SFX_PICTO_0187'],
    [PictogramEnum.SFX_PICTO_0188, 'SFX_PICTO_0188'],
    [PictogramEnum.SFX_PICTO_0189, 'SFX_PICTO_0189'],
    [PictogramEnum.SFX_PICTO_0190, 'SFX_PICTO_0190'],
    [PictogramEnum.SFX_PICTO_0191, 'SFX_PICTO_0191'],
    [PictogramEnum.SFX_PICTO_0192, 'SFX_PICTO_0192'],
    [PictogramEnum.SFX_PICTO_0193, 'SFX_PICTO_0193'],
    [PictogramEnum.SFX_PICTO_0194, 'SFX_PICTO_0194'],
    [PictogramEnum.SFX_PICTO_0195, 'SFX_PICTO_0195'],
    [PictogramEnum.SFX_PICTO_0196, 'SFX_PICTO_0196'],
    [PictogramEnum.SFX_PICTO_0197, 'SFX_PICTO_0197'],
    [PictogramEnum.SFX_PICTO_0198, 'SFX_PICTO_0198'],
    [PictogramEnum.SFX_PICTO_0199, 'SFX_PICTO_0199'],
    [PictogramEnum.SFX_PICTO_0200, 'SFX_PICTO_0200'],
    [PictogramEnum.SFX_PICTO_0201, 'SFX_PICTO_0201'],
    [PictogramEnum.SFX_PICTO_0202, 'SFX_PICTO_0202'],
    [PictogramEnum.SFX_PICTO_0203, 'SFX_PICTO_0203'],
    [PictogramEnum.SFX_PICTO_0204, 'SFX_PICTO_0204'],
    [PictogramEnum.SFX_PICTO_0205, 'SFX_PICTO_0205'],
    [PictogramEnum.SFX_PICTO_0206, 'SFX_PICTO_0206'],
    [PictogramEnum.SFX_PICTO_0207, 'SFX_PICTO_0207'],
    [PictogramEnum.SFX_PICTO_0208, 'SFX_PICTO_0208'],
    [PictogramEnum.SFX_PICTO_0209, 'SFX_PICTO_0209'],
    [PictogramEnum.SFX_PICTO_0210, 'SFX_PICTO_0210'],
    [PictogramEnum.SFX_PICTO_0211, 'SFX_PICTO_0211'],
    [PictogramEnum.SFX_PICTO_0212, 'SFX_PICTO_0212'],
    [PictogramEnum.SFX_PICTO_0213, 'SFX_PICTO_0213'],
    [PictogramEnum.SFX_PICTO_0214, 'SFX_PICTO_0214'],
    [PictogramEnum.SFX_PICTO_0215, 'SFX_PICTO_0215'],
    [PictogramEnum.SFX_PICTO_0216, 'SFX_PICTO_0216'],
    [PictogramEnum.SFX_PICTO_0217, 'SFX_PICTO_0217'],
    [PictogramEnum.SFX_PICTO_0218, 'SFX_PICTO_0218'],
    [PictogramEnum.SFX_PICTO_0219, 'SFX_PICTO_0219'],
    [PictogramEnum.SFX_PICTO_0220, 'SFX_PICTO_0220'],
    [PictogramEnum.SFX_PICTO_0221, 'SFX_PICTO_0221'],
    [PictogramEnum.SFX_PICTO_0222, 'SFX_PICTO_0222'],
    [PictogramEnum.SFX_PICTO_0223, 'SFX_PICTO_0223'],
    [PictogramEnum.SFX_PICTO_0224, 'SFX_PICTO_0224'],
    [PictogramEnum.SFX_PICTO_0225, 'SFX_PICTO_0225'],
    [PictogramEnum.SFX_PICTO_0226, 'SFX_PICTO_0226'],
    [PictogramEnum.SFX_PICTO_0227, 'SFX_PICTO_0227'],
    [PictogramEnum.SFX_PICTO_0228, 'SFX_PICTO_0228'],
    [PictogramEnum.SFX_PICTO_0229, 'SFX_PICTO_0229'],
    [PictogramEnum.SFX_PICTO_0230, 'SFX_PICTO_0230'],
    [PictogramEnum.SFX_PICTO_0231, 'SFX_PICTO_0231'],
    [PictogramEnum.SFX_PICTO_0232, 'SFX_PICTO_0232'],
    [PictogramEnum.SFX_PICTO_0233, 'SFX_PICTO_0233'],
    [PictogramEnum.SFX_PICTO_0234, 'SFX_PICTO_0234'],
    [PictogramEnum.SFX_PICTO_0235, 'SFX_PICTO_0235'],
    [PictogramEnum.SFX_PICTO_0236, 'SFX_PICTO_0236'],
    [PictogramEnum.SFX_PICTO_0237, 'SFX_PICTO_0237'],
    [PictogramEnum.SFX_PICTO_0238, 'SFX_PICTO_0238'],
    [PictogramEnum.SFX_PICTO_0239, 'SFX_PICTO_0239'],
    [PictogramEnum.SFX_PICTO_0240, 'SFX_PICTO_0240'],
  ]);

  export function mapToSelectItemData(pictogram: PictogramEnum): SingleSelectItemData<PictogramEnum> {
    return {
      value: pictogram,
      title: toString.getValue(pictogram),
      image: `${imagePrefix.getValue(pictogram)}`,
    };
  }

  export const itemResolver = (): Observable<SingleSelectItemData<PictogramEnum>[]> => {
    const mappedValues = values.map(value => mapToSelectItemData(value));

    return of(mappedValues);
  };
  export const comparePredicate = (node: SingleSelectItemData<PictogramEnum>, item: PictogramEnum) => node.value === item;
}
