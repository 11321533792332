import { compact } from 'lodash';

import {
  CategoryDocumentRfiRequestDTO,
  CategoryParentsLibraryAffectationRequestDTO,
  CategoryParentsProjectAffectationRequestDTO,
  LabelFamilyCategoryProjectFilterRequestDto,
  LabelFamilyLibraryCategoryFilterRequestDTO,
  LabelFamilyLibraryUserFilterRequestDTO,
  LabelFamilyLightResponseDTO,
  LabelFamilyLinkFlowFilterRequestDTO,
  LabelFamilyPlanTreeFilterRequestDTO,
  LabelFamilyTreeFilterRequestDTO,
} from '../../generated/models/models';

import { SearchStrategyEnum } from '../../../modules/shared/components/select-v2/utils/search-strategy.enum';

import { DefaultValueData, MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyNatureEnum, UserTypeEnum } from '../../enums';

export namespace DropdownCategoryData {
  export function mapToSelectFilterItemData(labelFamily: LabelFamilyLightResponseDTO, parentUuidEntity?: string): MultiSelectItemData {
    return {
      title: compact([labelFamily.name, labelFamily.fullName]).join(' - '),
      value: labelFamily.uuidEntity,
      children: (labelFamily.children || []).map(child => mapToSelectFilterItemData(child, labelFamily.uuidEntity)),
      parent: parentUuidEntity ? ({ value: parentUuidEntity } as MultiSelectItemData) : undefined,
    };
  }

  export function mapToSingleSelectAffectationItemData(labelFamily: LabelFamilyLightResponseDTO, parent?: LabelFamilyLightResponseDTO): SingleSelectItemData {
    return {
      title: compact([labelFamily.name, labelFamily.fullName]).join(' - '),
      value: { name: compact([labelFamily.name, labelFamily.fullName]).join(' - '), uuidEntity: labelFamily.uuidEntity },
      children: (labelFamily.children || []).map(child => mapToSingleSelectAffectationItemData(child, parent)),
      parent: parent ? { uuidEntity: parent.uuidEntity } : undefined,
    };
  }

  export function mapToMultiSelectAffectationItemData(labelFamily: LabelFamilyLightResponseDTO, parent?: LabelFamilyLightResponseDTO): MultiSelectItemData<DefaultValueData> {
    return {
      title: compact([labelFamily.name, labelFamily.fullName]).join(' - '),
      value: { name: compact([labelFamily.name, labelFamily.fullName]).join(' - '), uuidEntity: labelFamily.uuidEntity },
      tooltip: labelFamily.fullName,
      children: (labelFamily.children || []).map(child => mapToMultiSelectAffectationItemData(child, labelFamily)),
      parent: parent ? ({ value: { uuidEntity: parent.uuidEntity } } as MultiSelectItemData<DefaultValueData>) : undefined,
      disabled: !parent && !labelFamily.children?.length,
    };
  }

  export function mapToProjectApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, projectUuidEntity: string): LabelFamilyTreeFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, projectUuidEntity };
  }

  export function mapToFlowLinkedApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, flowUuidEntity: string): LabelFamilyLinkFlowFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, flowUuidEntity };
  }

  export function mapToPlanApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, planUuidEntity: string): LabelFamilyPlanTreeFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, planUuidEntity };
  }

  export function mapToLibraryApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum): LabelFamilyLibraryCategoryFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child };
  }

  export function mapToLibraryParentApiRequestValue(textSearch: string, nature: LabelFamilyNatureEnum): CategoryParentsLibraryAffectationRequestDTO {
    return { textSearch, choixNatureLabel: LabelFamilyNatureEnum.convertToApiValue.getValue(nature) };
  }

  export function mapToProjectParentApiRequestValue(textSearch: string, projectUuidEntity: string, nature: LabelFamilyNatureEnum): CategoryParentsProjectAffectationRequestDTO {
    return { textSearch, projectUuidEntity, choixNatureLabel: LabelFamilyNatureEnum.convertToApiValue.getValue(nature) };
  }

  export function mapToProjectDocumentApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    projectUuidEntity: string,
    secondary?: boolean,
    extern?: boolean,
  ): LabelFamilyCategoryProjectFilterRequestDto {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, projectUuidEntity, secondary, extern };
  }

  export function mapToProjectDocumentRfiApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    sectionUuidEntity: string,
    secondary?: boolean,
  ): CategoryDocumentRfiRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, sectionUuidEntity, secondary };
  }

  export function mapToLibraryDocumentApiFilterRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    secondary?: boolean,
  ): LabelFamilyLibraryCategoryFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, secondary };
  }

  export function mapToHierarchyLibraryFilterApiRequestValue(
    textSearch: string,
    searchStrategy: SearchStrategyEnum,
    userType: UserTypeEnum,
    filterOnActivated: boolean,
  ): LabelFamilyLibraryUserFilterRequestDTO {
    return { textSearch, searchOnChildren: searchStrategy === SearchStrategyEnum.Child, choixTypeUser: UserTypeEnum.convertToApiValue.getValue(userType), filterOnActivated };
  }

  export function mapToLinkFlowFilterApiRequestValue(textSearch: string, searchStrategy: SearchStrategyEnum, flowUuidEntity: string): LabelFamilyLinkFlowFilterRequestDTO {
    return {
      textSearch,
      searchOnChildren: searchStrategy === SearchStrategyEnum.Child,
      flowUuidEntity,
    };
  }
}
