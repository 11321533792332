import { RubricRecapResponseDTO } from '../../generated/models/models';

export interface FormRubricLightData {
  name: string;
  uuidEntity: string;
}

export namespace FormRubricLightData {
  export function mapFromApiValue(data: RubricRecapResponseDTO): FormRubricLightData {
    return {
      name: data.rubricName,
      uuidEntity: data.uuideRubric,
    };
  }
}
