import { Observable, of } from 'rxjs';
import dayjs from 'dayjs';

import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LocalStorageEnum } from '../local-storage.enum';

export enum PlanDisplayFormatEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export namespace PlanDisplayFormatEnum {
  const values: PlanDisplayFormatEnum[] = [
    PlanDisplayFormatEnum.Day,
    PlanDisplayFormatEnum.Week,
    PlanDisplayFormatEnum.Month,
    PlanDisplayFormatEnum.Quarter,
    PlanDisplayFormatEnum.Year,
  ];

  const key = 'planTranslate.periods.';
  export const toString = new SfxMap<PlanDisplayFormatEnum, string>([
    [PlanDisplayFormatEnum.Day, key + 'day'],
    [PlanDisplayFormatEnum.Week, key + 'week'],
    [PlanDisplayFormatEnum.Month, key + 'month'],
    [PlanDisplayFormatEnum.Quarter, key + 'quarter'],
    [PlanDisplayFormatEnum.Year, key + 'year'],
  ]);

  export const convertToParamsValue = new SfxMap<PlanDisplayFormatEnum, string>([
    [PlanDisplayFormatEnum.Day, 'Day'],
    [PlanDisplayFormatEnum.Week, 'Week'],
    [PlanDisplayFormatEnum.Month, 'Month'],
    [PlanDisplayFormatEnum.Quarter, 'Quarter'],
    [PlanDisplayFormatEnum.Year, 'Year'],
  ]);

  export const diffUnit = new SfxMap<PlanDisplayFormatEnum, dayjs.OpUnitType>([
    [PlanDisplayFormatEnum.Day, 'minutes'],
    [PlanDisplayFormatEnum.Week, 'hours'],
    [PlanDisplayFormatEnum.Month, 'days'],
    [PlanDisplayFormatEnum.Quarter, 'days'],
    [PlanDisplayFormatEnum.Year, 'days'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, PlanDisplayFormatEnum>(
    [
      ['Day', PlanDisplayFormatEnum.Day],
      ['Week', PlanDisplayFormatEnum.Week],
      ['Month', PlanDisplayFormatEnum.Month],
      ['Quarter', PlanDisplayFormatEnum.Quarter],
      ['Year', PlanDisplayFormatEnum.Year],
    ],
    PlanDisplayFormatEnum.Month,
  );

  export const toDisplayHeaderFormat = new SfxMap<PlanDisplayFormatEnum, string>(
    [
      [PlanDisplayFormatEnum.Day, 'dddd, DD MMMM YYYY'],
      [PlanDisplayFormatEnum.Week, '[Semaine] WW, MMMM YYYY'],
      [PlanDisplayFormatEnum.Month, 'MMMM YYYY'],
      [PlanDisplayFormatEnum.Quarter, '[Trimestre] Q, YYYY'],
      [PlanDisplayFormatEnum.Year, 'YYYY'],
    ],
    'MMMM YYYY',
  );

  export const toDisplayChartBarFormat = new SfxMap<PlanDisplayFormatEnum, string>(
    [
      [PlanDisplayFormatEnum.Day, localStorage.getItem(LocalStorageEnum.TimeFormat) || 'HH:mm'],
      [PlanDisplayFormatEnum.Week, localStorage.getItem(LocalStorageEnum.DateTimeFormat) || 'DD/MM/YYYY HH:mm'],
      [PlanDisplayFormatEnum.Month, localStorage.getItem(LocalStorageEnum.DateFormat) || 'DD/MM/YYYY'],
      [PlanDisplayFormatEnum.Quarter, localStorage.getItem(LocalStorageEnum.DateFormat) || 'DD/MM/YYYY'],
      [PlanDisplayFormatEnum.Year, localStorage.getItem(LocalStorageEnum.DateFormat) || 'DD/MM/YYYY'],
    ],
    'DD/MM/YYYY',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<PlanDisplayFormatEnum>[]> =>
    of(
      values.map(displayFormat => ({
        value: displayFormat,
        title: toString.getValue(displayFormat),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<PlanDisplayFormatEnum>, item: PlanDisplayFormatEnum) => node.value === item;
}
