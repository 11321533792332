import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

import { intersection } from 'lodash';
import dayjs from 'dayjs';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom, concatMap, tap, filter, finalize, first } from 'rxjs/operators';

import * as fromMainActions from './main.actions';
import { setDialogErrorData, setDialogLoadingState, setDialogSuccessData } from '../../sfx-dialog/state/sfx-dialog.actions';
import { routerNavigationChange } from '../../router/router.action';

import { AppState } from '../../app.state';
import { getQueryParam, getRouteParam } from '../../router';
import { getAppConfigLight, getUserData, getAccessHistoryData, getZendeskEnabled } from '.';

import { AppConfigService } from '../../../core/services/app-config.service';
import { LoginService } from '../../../core/services/login.service';
import { PermissionService } from '../../shared/services/permission.service';
import { UserService } from '../../../core/services/user.service';
import { ApplicationFileTmpService } from '../../../core/services/application-file-tmp.service';
import { ToasterService } from '../../shared/components/toaster/toaster.service';
import { ActivityWsService } from '../../../core/services/activity-ws.service';
import { SessionService } from '../../shared/services/session.service';
import { OrganizationConfigService } from '../../../core/services/organization-config.service';
import { ZendeskService } from '../../../core/services/zendesk.service';

import { UserForUpdate, ExchangeCodeData, ApplicationFileTmpData, UserQrCodeData } from '../../../core/models';
import { IntercomData } from '../../../core/models/help-center/intercom-data';

import { environment } from '../../../../../environments/environment';
import { RouterPaths } from '../../../core/constant/route.constant';
import { NumberFormatEnum } from '../../../core/enums/number-format.enum';
import { HelpCenterEnum } from '../../../core/enums/help-center/help-center.enum';
import { LocalStorageEnum, RouteIdEnum, RouteQueryParamEnum, LanguageEnum, RouteParamEnum, ToasterTypeEnum, UserRightEnum } from '../../../core/enums';
import { ZendeskData } from '../../../core/models/help-center/zendesk-data';

@Injectable()
export class MainEffects {
  private queries: ApplicationFileTmpData.QueryRequest;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private translate: TranslateService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService,
    private permissionService: PermissionService,
    private toasterService: ToasterService,
    private router: Router,
    private appConfigService: AppConfigService,
    private activityWsService: ActivityWsService,
    private sessionService: SessionService,
    private applicationFileTmpService: ApplicationFileTmpService,
    private organizationConfigService: OrganizationConfigService,
    private zendeskService: ZendeskService,
  ) {}

  mainNavigationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationChange),
      filter(action => !!action.routerState.segments?.find(segment => segment.id === RouteIdEnum.Main)),
      withLatestFrom(this.store.pipe(select(getAppConfigLight)), this.store.pipe(select(getUserData))),
      filter(([_, appConfig, userInfo]) => !appConfig || !userInfo),
      tap(_ => this.permissionService.loadPermissions()),
      concatMap(() => [fromMainActions.getUserAccount(), fromMainActions.getAppConfig()]),
    ),
  );

  setCurrentLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.setCurrentLanguage),
      tap(({ language, temporary }) => {
        if (!temporary) {
          this.localStorageService.store(LocalStorageEnum.currentLanguage, language);
        }
        this.translate.use(language);
        dayjs.locale(language);
      }),
      map(_ => fromMainActions.dummyAction()),
    ),
  );

  loadUserAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.getUserAccount),
      switchMap(() => this.userService.getUserInfo().pipe(map(userData => fromMainActions.getUserAccountSuccess({ userInfo: userData })))),
      catchError(error => of(fromMainActions.getUserAccountFail({ error }))),
    ),
  );

  loadUserAccountSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.getUserAccountSuccess),
      concatMap(({ userInfo }) => {
        this.permissionService.addUserRights([userInfo.authority, ...(userInfo?.additionalAuthorities || [])]);

        return [
          fromMainActions.loadOrganizationConfig({ organizationUuid: userInfo.uuidOrganization }),
          fromMainActions.setCurrentLanguage({ language: LanguageEnum.convertFromApiSupportedLanguage.getValue(userInfo.language) }),
        ];
      }),
    ),
  );

  organizationConfigLoadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.loadOrganizationConfig),
      switchMap(action =>
        this.organizationConfigService.loadOrganizationConfig(action.organizationUuid).pipe(
          map(config => fromMainActions.loadOrganizationConfigSuccess({ config })),
          catchError(error => of(fromMainActions.loadOrganizationConfigFail(error))),
        ),
      ),
    ),
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.login),
      switchMap(action =>
        this.loginService.login({ body: action.credentials }).pipe(
          map(({ token }) => fromMainActions.loginSuccess({ data: { token } })),
          catchError(error => {
            if (error.details === 'Blocked account') {
              this.toasterService.show({
                type: ToasterTypeEnum.Warning,
                title: this.translate.instant('login.blockedAccountToaster'),
                subtitle: '',
              });
            }

            return of(fromMainActions.loginFail({ error: { ...error, message: error && error.details === 'Blocked account' ? 'login.blockedAccount' : 'login.wrongData' } }));
          }),
        ),
      ),
    ),
  );

  loginSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.loginSuccess),
      withLatestFrom(this.store.pipe(select(getQueryParam(RouteQueryParamEnum.ReturnUrl)))),
      map(([{ data }, returnUrl]) => {
        returnUrl
          ? this.router.navigateByUrl(returnUrl, { replaceUrl: true })
          : this.router.navigate([RouterPaths.RootPaths.mainPath, RouterPaths.ProjectPaths.projectsPath], { replaceUrl: true });
        this.localStorageService.store(LocalStorageEnum.AuthToken, data.token);
        if (data.oauth2) {
          this.localStorageService.store(LocalStorageEnum.oauth2, data.oauth2);
          this.localStorageService.store(LocalStorageEnum.refreshToken, data.refreshToken);
        }

        if (environment.production) {
          this.activityWsService.initAndConnect();
        }

        return fromMainActions.dummyAction();
      }),
    ),
  );

  logoutEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.logOut),
        tap(action => {
          this.localStorageService.clear(LocalStorageEnum.AuthToken);
          this.localStorageService.clear(LocalStorageEnum.SelectedFlowFilter);
          if (action.redirect) {
            this.router.navigate([RouterPaths.EntryPaths.loginPath], { replaceUrl: true });
          }
          this.permissionService.flushRoles();
          if (environment.production) {
            this.activityWsService.disconnect();
          }
          const oauth2 = this.localStorageService.retrieve(LocalStorageEnum.oauth2);
          if (oauth2) {
            this.store.dispatch(fromMainActions.singleOauth2Logout());
            this.localStorageService.clear(LocalStorageEnum.oauth2);
            this.localStorageService.clear(LocalStorageEnum.refreshToken);
          }
          if (environment.helpCenter.active) {
            HelpCenterEnum.shutdown();
          }
        }),
      ),
    { dispatch: false },
  );

  singleOauth2LogoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.singleOauth2Logout),
      switchMap(() => this.loginService.getAuthorizationServerConfig()),
      tap(config => {
        window.location.href = `${config.endSessionEndpoint}`;
      }),
      map(_ => fromMainActions.dummyAction()),
    ),
  );

  updateUserAccountEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.updateUserAccount),
      concatMap(action =>
        this.userService.updateUserAccount({ body: UserForUpdate.mapToUpdateRequestApiValue(action.userAccount) }).pipe(
          map(userAccount => fromMainActions.updateUserAccountSuccess({ userAccount: userAccount })),
          catchError(error => of(fromMainActions.updateUserAccountFail({ error }))),
        ),
      ),
    ),
  );

  updateUserAccountSuccessEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.updateUserAccountSuccess),
      tap(() => {
        this.toasterService.show({
          type: ToasterTypeEnum.Success,
          title: this.translate.instant('global.toaster.success'),
          subtitle: '',
        });
      }),
      map(action =>
        action.userAccount.language
          ? fromMainActions.setCurrentLanguage({
              language: LanguageEnum.convertToApiValue.getValue(action.userAccount.language).toLowerCase(),
            })
          : fromMainActions.dummyAction(),
      ),
    ),
  );

  loadAppConfigEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.getAppConfig),
      concatMap(_ => this.appConfigService.getAppConfigInfo().pipe(map(data => fromMainActions.getAppConfigSuccess({ appConfig: data })))),
      catchError(error => of(fromMainActions.getAppConfigFail({ error }))),
    ),
  );

  getAppConfigSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.getAppConfigSuccess),
        tap(data => {
          if (data.appConfig.webDateFormat) {
            localStorage.setItem(LocalStorageEnum.DateFormat, data.appConfig.webDateFormat.dateFormat);
            localStorage.setItem(LocalStorageEnum.DateTimeFormat, data.appConfig.webDateFormat.dateTimeFormat);
            localStorage.setItem(LocalStorageEnum.TimeFormat, data.appConfig.webDateFormat.timeFormat);
            localStorage.setItem(LocalStorageEnum.SensitiveDateTimeFormat, data.appConfig.webDateFormat.sensitiveDateTimeFormat);
            localStorage.setItem(LocalStorageEnum.NumberFormat, NumberFormatEnum.convertToLocalStorageValue.getValue(data.appConfig.numberFormatEnum));
          }
        }),
      ),
    { dispatch: false },
  );

  ssoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.sso),
      concatMap(() => this.loginService.getAuthorizationServerConfig()),
      tap(config => {
        const host = `${window.location.protocol}//${window.location.host}`;
        const state = this.sessionService.getExistingOrCreateAuthStateControl();
        window.location.href =
          `${config.authorizationTokenEndpoint}?client_id=${encodeURIComponent(config.clientId)}` +
          `&redirect_uri=${encodeURIComponent(host)}/callback&scope=${encodeURIComponent(config.scope)}&response_type=code&state=${encodeURIComponent(state)}`;
      }),
      map(_ => fromMainActions.dummyAction()),
    ),
  );

  exchangeCodeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.exchangeCode),
      concatMap(action => this.sessionService.exchangeCode({ body: action.request })),
      switchMap((response: ExchangeCodeData) => this.sessionService.checkTokenValidity({ body: { idToken: response.idToken } }).pipe(map(_ => response))),
      map((response: ExchangeCodeData) => fromMainActions.loginSuccess({ data: { token: response.idToken, oauth2: true, refreshToken: response.refreshToken } })),
      catchError(error => {
        if (error && error.details) {
          this.router.navigate([RouterPaths.EntryPaths.onboardingPath], { queryParams: { key: error.details } });
        }

        return of(fromMainActions.loginFail({ error: { ...error, message: error && error.details === 'Blocked account' ? 'login.blockedAccount' : 'login.wrongData' } }));
      }),
    ),
  );

  tokensRefreshedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.tokensRefreshed),
        tap(_ => environment.production && this.activityWsService.resetConnection()),
      ),
    { dispatch: false },
  );

  getAuthMethodEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.getAuthMethod),
      concatMap(_ => this.loginService.getAuthMethod().pipe(map(data => fromMainActions.setAuthMethod({ authMethod: data })))),
    ),
  );

  getQrCodeDataEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.loadQrCodeData),
      withLatestFrom(
        this.store.pipe(
          select(getRouteParam(RouteParamEnum.ElementUuidEntity)),
          filter(routeParam => !!routeParam),
        ),
      ),
      tap(() => this.store.dispatch(setDialogLoadingState({ loadingState: true }))),
      switchMap(([action, tabletUuidEntity]) =>
        this.userService.getTabletQrCode({ password: action.password, userUuidEntity: tabletUuidEntity } as UserQrCodeData.QrCodeRequest).pipe(
          map(data => fromMainActions.loadQrCodeDataSuccess(data)),
          catchError(() => of(fromMainActions.loadQrCodeDataFail({ title: this.translate.instant('login.quickConnection.loadQrCodeDataFail') }))),
          finalize(() => this.store.dispatch(setDialogLoadingState({ loadingState: false }))),
        ),
      ),
    ),
  );

  unlinkTabletEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.unlinkUser),
      switchMap(({ password }) =>
        this.userService.unlinkMyTablet().pipe(
          map(() => fromMainActions.unlinkUserSuccess({ password })),
          catchError(() => of(fromMainActions.unlinkUserFail({ title: this.translate.instant('login.quickConnection.unlinkTabletFail') }))),
        ),
      ),
    ),
  );

  unlinkTabletSuccessEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.unlinkUserSuccess),
      switchMap(({ password }) => [
        setDialogSuccessData({ success: { title: this.translate.instant('login.quickConnection.unlinkTabletSuccess') } }),
        fromMainActions.loadQrCodeData({ password }),
      ]),
    ),
  );

  failErrorEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.unlinkUserFail, fromMainActions.loadQrCodeDataFail),
      map(action => setDialogErrorData({ error: { title: this.translate.instant(action.title), subtitle: action.subtitle && this.translate.instant(action.subtitle) } })),
    ),
  );

  loadAccessHistoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.getUserAccountSuccess),
      map(action =>
        fromMainActions.loadAccessHistoryData({ items: JSON.parse(this.localStorageService.retrieve(`${LocalStorageEnum.historyAccess}${action.userInfo.uuidEntity}`)) || [] }),
      ),
    ),
  );

  setHistoryItemEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.setAccessHistoryItem),
        withLatestFrom(this.store.pipe(select(getAccessHistoryData)), this.store.pipe(select(getUserData))),
        tap(([action, historyData, { uuidEntity }]) => {
          if (historyData.length === 6) {
            historyData.pop();
          }
          const historyItemAlreadyExist = !!historyData.find(_item => _item.id === action.item.id);
          const newHistoryData = historyItemAlreadyExist ? historyData.map(_item => (_item.id === action.item.id ? { ...action.item } : _item)) : [action.item, ...historyData];

          this.localStorageService.store(`${LocalStorageEnum.historyAccess}${uuidEntity}`, JSON.stringify(newHistoryData));
        }),
      ),
    { dispatch: false },
  );

  removeHistoryItemEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.removeAccessHistoryItem),
        withLatestFrom(this.store.pipe(select(getAccessHistoryData)), this.store.pipe(select(getUserData))),
        tap(([action, historyData, { uuidEntity }]) => {
          const newHistoryData = historyData.filter(_item => _item.id !== action.item.id);

          this.localStorageService.store(`${LocalStorageEnum.historyAccess}${uuidEntity}`, JSON.stringify(newHistoryData));
        }),
      ),
    { dispatch: false },
  );

  removeHistoryItemByUuidEntityEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.removeAccessHistoryItemByUuidEntity),
        withLatestFrom(this.store.pipe(select(getAccessHistoryData)), this.store.pipe(select(getUserData))),
        tap(([action, historyData, { uuidEntity }]) => {
          const newHistoryData = historyData.filter(_item => _item.uuidEntity !== action.uuidEntity);
          this.localStorageService.store(`${LocalStorageEnum.historyAccess}${uuidEntity}`, JSON.stringify(newHistoryData));
        }),
      ),
    { dispatch: false },
  );

  loadApplicationFileTmpEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.loadApplicationFileTmp),
      tap(() => (this.queries = ApplicationFileTmpData.initializeQueryRequest())),
      switchMap(() =>
        this.applicationFileTmpService.findLazyApplicationFileTmp({ body: ApplicationFileTmpData.mapToPageRequestApiValue(this.queries) }).pipe(
          map(response => fromMainActions.loadApplicationFileTmpSuccess({ response, reset: true })),
          catchError(error => of(fromMainActions.loadApplicationFileTmpFail({ error }))),
        ),
      ),
    ),
  );

  loadMoreApplicationFileTmpEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.loadMoreApplicationFileTmp),
      concatMap(() => {
        this.queries.page = this.queries.page + 1;

        return this.applicationFileTmpService.findLazyApplicationFileTmp({ body: ApplicationFileTmpData.mapToPageRequestApiValue(this.queries) }).pipe(
          map(response => fromMainActions.loadApplicationFileTmpSuccess({ response })),
          catchError(error => of(fromMainActions.loadApplicationFileTmpFail({ error }))),
        );
      }),
    ),
  );

  // HelpCenter
  initializeHelpCenterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.initializeHelpCenter),
      switchMap(() =>
        combineLatest([this.store.pipe(select(getUserData)), this.store.pipe(select(getAppConfigLight)), this.store.pipe(select(getZendeskEnabled))]).pipe(
          filter(
            ([userData, appConfig, zendeskEnabled]) =>
              !!userData && !!appConfig && zendeskEnabled !== undefined && !intersection([userData.authority], appConfig.authoritiesBlackList).length,
          ),
          first(),
          map(([userData, _, zendeskEnabled]) => ({ userData, selectedHelpCenter: zendeskEnabled ? HelpCenterEnum.Zendesk : HelpCenterEnum.Intercom })),
          switchMap(({ userData, selectedHelpCenter }) =>
            // The ID ze-snippet is mandatory for zendesk. To avoid useless code I'm keeping the same id for intercom.
            HelpCenterEnum.loadScript('ze-snippet', HelpCenterEnum.scriptSource.getValue(selectedHelpCenter)).pipe(
              map(() =>
                selectedHelpCenter === HelpCenterEnum.Zendesk ? fromMainActions.zendeskCreateOrUpdateOrganizationAndUser() : fromMainActions.configureIntercom({ userData }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  configurationIntercomEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.configureIntercom),
        map(({ userData }) => {
          window['Intercom']('boot', {
            app_id: IntercomData.appId,
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
            profession: userData.profession,
            subdomain: userData.baseUrl,
            user_hash: userData.hmacIntercom,
            user_id: userData.uuidEntity,
            user_role: UserRightEnum.convertToApiValue.getValue(userData.authority),
            company: {
              company_id: userData.companyName,
              name: userData.companyName,
            },
          });
        }),
        catchError(() => of(this.toasterService.show({ type: ToasterTypeEnum.Error, title: this.translate.instant('global.errorToaster.error.Title') }))),
      ),
    { dispatch: false },
  );

  openZendeskHelpCenterEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.openZendeskHelpCenter),
        withLatestFrom(this.store.pipe(select(getUserData))),
        switchMap(([{ helpCenterLink }, userData]) =>
          this.zendeskService.getSingleSignOnToken(userData).pipe(
            map(({ token }) => this.zendeskService.authenticateToHelpCenter(token, helpCenterLink)),
            catchError(() => of(this.toasterService.show({ type: ToasterTypeEnum.Error, title: this.translate.instant('global.errorToaster.error.Title') }))),
          ),
        ),
      ),
    { dispatch: false },
  );

  createOrUpdateOrganizationAndUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMainActions.zendeskCreateOrUpdateOrganizationAndUser),
      withLatestFrom(this.store.pipe(select(getUserData))),
      switchMap(([_, userData]) =>
        this.zendeskService
          .createOrUpdateOrganization(userData)
          .pipe(switchMap(({ organization: { id } }) => this.zendeskService.createOrUpdateUser(userData, id).pipe(map(() => fromMainActions.initializeZendesk())))),
      ),
      catchError(() => {
        this.toasterService.show({
          type: ToasterTypeEnum.Error,
          title: this.translate.instant('global.errorToaster.error.Title'),
        });

        return of(fromMainActions.dummyAction());
      }),
    ),
  );

  initializeZendeskEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMainActions.initializeZendesk),
        withLatestFrom(this.store.pipe(select(getUserData))),
        switchMap(([_, userData]) => this.zendeskService.getEndUserAuthToken(userData)),
        switchMap(({ token }) => HelpCenterEnum.loadScript('ze-script', ZendeskData.configFile, 'module').pipe(tap(() => this.zendeskService.initZendeskWidget(token)))),
        catchError(() =>
          of(
            this.toasterService.show({
              type: ToasterTypeEnum.Error,
              title: this.translate.instant('global.errorToaster.error.Title'),
            }),
          ),
        ),
      ),
    { dispatch: false },
  );
}
