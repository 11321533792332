import dayjs from 'dayjs';

import { ElementPlanSchedulingCreateRequestDTO } from '../../generated/models/elementPlanSchedulingCreateRequestDTO';
import { DateUtils } from '../../utils/date.utils';

export interface ElementPlanCreateData {
  teamRoleUuidEntity: string;
  flowUuidEntity: string;
  planUuidEntity: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export namespace ElementPlanCreateData {
  export function mapToCreateRequest(elementPlan: ElementPlanCreateData): ElementPlanSchedulingCreateRequestDTO {
    return {
      teamRoleUuidEntity: elementPlan.teamRoleUuidEntity,
      flowUuidEntity: elementPlan.flowUuidEntity,
      planUuidEntity: elementPlan.planUuidEntity,
      finishDate: (elementPlan.endDate && DateUtils.toZonedDateTime(elementPlan.endDate.toDate())) || undefined,
      startDate: (elementPlan.startDate && DateUtils.toZonedDateTime(elementPlan.startDate.toDate())) || undefined,
    };
  }
}
