import { ApplicationDTO } from '../../generated/models/models';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelApplicabilityEnum, UserTypeEnum } from '../../enums';
import { LabelApplicabilityAffectationRequestDTO } from '../../generated/models/labelApplicabilityAffectationRequestDTO';
import { LabelApplicabilityFilterRequestDTO } from '../../generated/models/labelApplicabilityFilterRequestDTO';
import { LabelApplicabilityLightDTO } from '../../generated/models/labelApplicabilityLightDTO';
import { LabelApplicabilityProjectFilterRequestDTO } from '../../generated/models/labelApplicabilityProjectFilterRequestDTO';
import { LabelApplicabilityUserFilterRequestDTO } from '../../generated/models/labelApplicabilityUserFilterRequestDTO';

export namespace DropdownApplicabilityData {
  export function mapToSelectFilterItemData(applicability: LabelApplicabilityLightDTO | ApplicationDTO): MultiSelectItemData {
    return {
      title: applicability.name,
      value: applicability.uuidEntity,
    };
  }

  export function mapToSelectAffectationItemData(applicability: LabelApplicabilityLightDTO): SingleSelectItemData {
    return {
      title: applicability.name,
      value: { name: applicability.name, uuidEntity: applicability.uuidEntity },
    };
  }

  export function mapToLibraryFilterRequestValue(textSearch: string, usedOnBlockRex?: boolean): LabelApplicabilityFilterRequestDTO {
    return { textSearch, usedOnBlockRex };
  }

  export function mapToLibraryUserFilterRequestValue(textSearch: string, userType: UserTypeEnum): LabelApplicabilityUserFilterRequestDTO {
    return { textSearch, choixTypeUser: UserTypeEnum.convertToApiValue.getValue(userType) };
  }

  export function mapToLibraryAffectationRequestValue(textSearch: string, type: LabelApplicabilityEnum): LabelApplicabilityAffectationRequestDTO {
    return { textSearch, type: LabelApplicabilityEnum.convertToApiValue.getValue(type) };
  }

  export function mapToProjectFilterRequestValue(textSearch: string, projectUuidEntity: string): LabelApplicabilityProjectFilterRequestDTO {
    return { textSearch, projectUuidEntity };
  }
}
