import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { RouterPaths } from '../../constant/route.constant';
import { BlocViewDetailsResponseDTO } from '../../generated/models/blocViewDetailsResponseDTO';

import { Observable, of } from 'rxjs';
import { MultiSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';
import { RouteIdEnum } from '../router-enums/route.enum';

export enum BlockTypeEnum {
  Pr = 1,
  Emm,
  Adr,
  Dsi,
  Form,
  Txt,
  Rhp,
  Document,
  MultiPr,
  Object,
}

export namespace BlockTypeEnum {
  export const getValues = [
    BlockTypeEnum.Pr,
    BlockTypeEnum.Emm,
    BlockTypeEnum.Adr,
    BlockTypeEnum.Dsi,
    BlockTypeEnum.Form,
    BlockTypeEnum.Txt,
    BlockTypeEnum.Rhp,
    BlockTypeEnum.MultiPr,
    BlockTypeEnum.Object,
  ];

  export const blocksCanBeCopied = [
    BlockTypeEnum.Pr,
    BlockTypeEnum.Emm,
    BlockTypeEnum.Adr,
    BlockTypeEnum.Form,
    BlockTypeEnum.Txt,
    BlockTypeEnum.Rhp,
    BlockTypeEnum.MultiPr,
    BlockTypeEnum.Object,
  ];

  export const blocksWithNoDocuments = [BlockTypeEnum.Object];

  export const metricBlocks = [
    BlockTypeEnum.Pr,
    BlockTypeEnum.Emm,
    BlockTypeEnum.Adr,
    BlockTypeEnum.Dsi,
    BlockTypeEnum.Form,
    BlockTypeEnum.Txt,
    BlockTypeEnum.Rhp,
    BlockTypeEnum.MultiPr,
  ];
  export const blocksHaveHistory = [BlockTypeEnum.Dsi];

  export const filteredValues = () =>
    new SfxMap(
      [
        [FlowTypeEnum.Group, [BlockTypeEnum.Pr, BlockTypeEnum.Dsi, BlockTypeEnum.Form]],
        [FlowTypeEnum.GroupReferential, [BlockTypeEnum.Pr, BlockTypeEnum.Dsi, BlockTypeEnum.Form]],
        [FlowTypeEnum.Specific, getValues],
        [
          FlowTypeEnum.Template,
          [BlockTypeEnum.Pr, BlockTypeEnum.Emm, BlockTypeEnum.Adr, BlockTypeEnum.Dsi, BlockTypeEnum.Form, BlockTypeEnum.Txt, BlockTypeEnum.Rhp, BlockTypeEnum.MultiPr],
        ],
        [FlowTypeEnum.Referential, getValues],
        [FlowTypeEnum.FlowForm, [BlockTypeEnum.Pr, BlockTypeEnum.Form]],
        [FlowTypeEnum.ReferentialForm, [BlockTypeEnum.Pr, BlockTypeEnum.Form]],
        [FlowTypeEnum.Generic, [BlockTypeEnum.Pr, BlockTypeEnum.Emm, BlockTypeEnum.Adr, BlockTypeEnum.Txt, BlockTypeEnum.Rhp]],
        [FlowTypeEnum.ReferentialGeneric, [BlockTypeEnum.Pr, BlockTypeEnum.Emm, BlockTypeEnum.Adr, BlockTypeEnum.Txt, BlockTypeEnum.Rhp]],
      ],
      [],
    );

  export const attributeName: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'pr'],
    [BlockTypeEnum.Adr, 'adr'],
    [BlockTypeEnum.Emm, 'emm'],
    [BlockTypeEnum.Form, 'form'],
    [BlockTypeEnum.Rhp, 'rhp'],
    [BlockTypeEnum.Dsi, 'dsi'],
    [BlockTypeEnum.Txt, 'txt'],
    [BlockTypeEnum.MultiPr, 'multiPr'],
    [BlockTypeEnum.Object, 'blocObject'],
  ]);

  export const convertFromApiValue: SfxMap<BlocViewDetailsResponseDTO.NatureBlocEnum, BlockTypeEnum> = new SfxMap([
    [BlocViewDetailsResponseDTO.NatureBlocEnum.PR, BlockTypeEnum.Pr],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.ADR, BlockTypeEnum.Adr],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.EMM, BlockTypeEnum.Emm],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.FORM, BlockTypeEnum.Form],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.RHP, BlockTypeEnum.Rhp],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.DSI, BlockTypeEnum.Dsi],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.TXT, BlockTypeEnum.Txt],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.MULTIPR, BlockTypeEnum.MultiPr],
    [BlocViewDetailsResponseDTO.NatureBlocEnum.OBJECT, BlockTypeEnum.Object],
  ]);

  export const convertToApiValue: SfxMap<BlockTypeEnum, BlocViewDetailsResponseDTO.NatureBlocEnum> = new SfxMap([
    [BlockTypeEnum.Pr, BlocViewDetailsResponseDTO.NatureBlocEnum.PR],
    [BlockTypeEnum.Adr, BlocViewDetailsResponseDTO.NatureBlocEnum.ADR],
    [BlockTypeEnum.Emm, BlocViewDetailsResponseDTO.NatureBlocEnum.EMM],
    [BlockTypeEnum.Form, BlocViewDetailsResponseDTO.NatureBlocEnum.FORM],
    [BlockTypeEnum.Rhp, BlocViewDetailsResponseDTO.NatureBlocEnum.RHP],
    [BlockTypeEnum.Dsi, BlocViewDetailsResponseDTO.NatureBlocEnum.DSI],
    [BlockTypeEnum.Txt, BlocViewDetailsResponseDTO.NatureBlocEnum.TXT],
    [BlockTypeEnum.MultiPr, BlocViewDetailsResponseDTO.NatureBlocEnum.MULTIPR],
    [BlockTypeEnum.Object, BlocViewDetailsResponseDTO.NatureBlocEnum.OBJECT],
  ]);

  export const convertFromParamsValue: SfxMap<string, BlockTypeEnum> = new SfxMap([
    ['pr', BlockTypeEnum.Pr],
    ['adr', BlockTypeEnum.Adr],
    ['emm', BlockTypeEnum.Emm],
    ['form', BlockTypeEnum.Form],
    ['rhp', BlockTypeEnum.Rhp],
    ['dsi', BlockTypeEnum.Dsi],
    ['txt', BlockTypeEnum.Txt],
    ['multiPr', BlockTypeEnum.MultiPr],
    ['object', BlockTypeEnum.Object],
  ]);

  export const convertToParamValue: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'pr'],
    [BlockTypeEnum.Adr, 'adr'],
    [BlockTypeEnum.Emm, 'emm'],
    [BlockTypeEnum.Form, 'form'],
    [BlockTypeEnum.Rhp, 'rhp'],
    [BlockTypeEnum.Dsi, 'dsi'],
    [BlockTypeEnum.Txt, 'txt'],
    [BlockTypeEnum.MultiPr, 'multiPr'],
    [BlockTypeEnum.Object, 'object'],
  ]);

  export const toString: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'blocks.label.instruction'],
    [BlockTypeEnum.MultiPr, 'blocks.label.multiInstruction'],
    [BlockTypeEnum.Adr, 'blocks.label.adr'],
    [BlockTypeEnum.Emm, 'blocks.label.emm'],
    [BlockTypeEnum.Form, 'blocks.label.form'],
    [BlockTypeEnum.Rhp, 'blocks.label.rhp'],
    [BlockTypeEnum.Dsi, 'blocks.label.dsi'],
    [BlockTypeEnum.Txt, 'blocks.label.text'],
    [BlockTypeEnum.Document, 'blocks.label.document'],
    [BlockTypeEnum.Object, 'blocks.label.object'],
  ]);

  export const toMetricsString: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'dashboardMetrics.blocks.pr'],
    [BlockTypeEnum.Emm, 'dashboardMetrics.blocks.emm'],
    [BlockTypeEnum.Adr, 'dashboardMetrics.blocks.risk'],
    [BlockTypeEnum.Dsi, 'dashboardMetrics.blocks.dsi'],
    [BlockTypeEnum.Form, 'dashboardMetrics.blocks.form'],
    [BlockTypeEnum.Txt, 'dashboardMetrics.blocks.text'],
    [BlockTypeEnum.Rhp, 'dashboardMetrics.blocks.team'],
    [BlockTypeEnum.MultiPr, 'dashboardMetrics.blocks.multiPr'],
  ]);

  export const shortName: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'blocks.title.instruction'],
    [BlockTypeEnum.MultiPr, 'blocks.title.multiInstruction'],
    [BlockTypeEnum.Adr, 'blocks.title.adr'],
    [BlockTypeEnum.Emm, 'blocks.title.emm'],
    [BlockTypeEnum.Form, 'blocks.title.form'],
    [BlockTypeEnum.Rhp, 'blocks.title.rhp'],
    [BlockTypeEnum.Dsi, 'blocks.title.dsi'],
    [BlockTypeEnum.Txt, 'blocks.title.text'],
    [BlockTypeEnum.Document, 'blocks.title.document'],
    [BlockTypeEnum.Object, 'blocks.title.object'],
  ]);

  export const tooltip: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'flowContentNavigation.stepNavbar.tooltip.displayInstruction'],
    [BlockTypeEnum.MultiPr, 'flowContentNavigation.stepNavbar.tooltip.displayMultiInstruction'],
    [BlockTypeEnum.Adr, 'flowContentNavigation.stepNavbar.tooltip.displayAdr'],
    [BlockTypeEnum.Emm, 'flowContentNavigation.stepNavbar.tooltip.displayEmm'],
    [BlockTypeEnum.Form, 'flowContentNavigation.stepNavbar.tooltip.displayForm'],
    [BlockTypeEnum.Rhp, 'flowContentNavigation.stepNavbar.tooltip.displayRhp'],
    [BlockTypeEnum.Dsi, 'flowContentNavigation.stepNavbar.tooltip.displayDsi'],
    [BlockTypeEnum.Txt, 'flowContentNavigation.stepNavbar.tooltip.displayText'],
    [BlockTypeEnum.Document, 'flowContentNavigation.stepNavbar.tooltip.displayDocument'],
    [BlockTypeEnum.Object, 'flowContentNavigation.stepNavbar.tooltip.displayObject'],
  ]);

  export const subtitle: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'blocks.label.subtitle.instruction'],
    [BlockTypeEnum.MultiPr, 'blocks.label.subtitle.multiInstruction'],
    [BlockTypeEnum.Adr, 'blocks.label.subtitle.adr'],
    [BlockTypeEnum.Emm, 'blocks.label.subtitle.emm'],
    [BlockTypeEnum.Form, 'blocks.label.subtitle.form'],
    [BlockTypeEnum.Rhp, 'blocks.label.subtitle.rhp'],
    [BlockTypeEnum.Dsi, 'blocks.label.subtitle.dsi'],
    [BlockTypeEnum.Txt, 'blocks.label.subtitle.text'],
    [BlockTypeEnum.Object, 'blocks.label.subtitle.object'],
  ]);

  export const selectValidationTitle: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'blocks.label.selectValidation.instruction'],
    [BlockTypeEnum.MultiPr, 'blocks.label.selectValidation.multiInstruction'],
    [BlockTypeEnum.Adr, 'blocks.label.selectValidation.adr'],
    [BlockTypeEnum.Emm, 'blocks.label.selectValidation.emm'],
    [BlockTypeEnum.Form, 'blocks.label.selectValidation.form'],
    [BlockTypeEnum.Rhp, 'blocks.label.selectValidation.rhp'],
    [BlockTypeEnum.Dsi, 'blocks.label.selectValidation.dsi'],
    [BlockTypeEnum.Txt, 'blocks.label.selectValidation.text'],
    [BlockTypeEnum.Object, 'blocks.label.selectValidation.object'],
  ]);

  export const blockDocumentTitle: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, 'blocks.label.blockDocumentTitle.pr'],
    [BlockTypeEnum.MultiPr, 'blocks.label.blockDocumentTitle.multiPr'],
    [BlockTypeEnum.Dsi, 'blocks.label.blockDocumentTitle.dsi'],
    [BlockTypeEnum.Txt, 'blocks.label.blockDocumentTitle.text'],
  ]);

  export const iconData: SfxMap<BlockTypeEnum, IconData> = new SfxMap([
    [BlockTypeEnum.Pr, { name: 'book-open', prefix: 'fas' }],
    [BlockTypeEnum.MultiPr, { name: 'check-square', prefix: 'fas' }],
    [BlockTypeEnum.Adr, { name: 'exclamation-triangle', prefix: 'fas' }],
    [BlockTypeEnum.Emm, { name: 'tools', prefix: 'fas' }],
    [BlockTypeEnum.Form, { name: 'list-alt', prefix: 'far' }],
    [BlockTypeEnum.Rhp, { name: 'users', prefix: 'fas' }],
    [BlockTypeEnum.Dsi, { name: 'file-signature', prefix: 'fas' }],
    [BlockTypeEnum.Txt, { name: 'file-alt', prefix: 'fas' }],
    [BlockTypeEnum.Document, { name: 'file-invoice', prefix: 'fas' }],
    [BlockTypeEnum.Object, { name: 'object-group', prefix: 'fas' }],
  ]);

  export const blockRouteId: SfxMap<BlockTypeEnum, RouteIdEnum> = new SfxMap([
    [BlockTypeEnum.Pr, RouteIdEnum.BlockPrContent],
    [BlockTypeEnum.MultiPr, RouteIdEnum.BlockMultiPrContent],
    [BlockTypeEnum.Adr, RouteIdEnum.BlockAdrContent],
    [BlockTypeEnum.Emm, RouteIdEnum.BlockEmmContent],
    [BlockTypeEnum.Form, RouteIdEnum.BlockFormContent],
    [BlockTypeEnum.Rhp, RouteIdEnum.BlockRhpContent],
    [BlockTypeEnum.Dsi, RouteIdEnum.BlockDsiContent],
    [BlockTypeEnum.Txt, RouteIdEnum.BlockTxtContent],
    [BlockTypeEnum.Object, RouteIdEnum.BlockObjectContent],
  ]);

  export const blockTypeFromBlockRouteId: SfxMap<RouteIdEnum, BlockTypeEnum> = new SfxMap([
    [RouteIdEnum.BlockPrContent, BlockTypeEnum.Pr],
    [RouteIdEnum.BlockAdrContent, BlockTypeEnum.Adr],
    [RouteIdEnum.BlockEmmContent, BlockTypeEnum.Emm],
    [RouteIdEnum.BlockFormContent, BlockTypeEnum.Form],
    [RouteIdEnum.BlockRhpContent, BlockTypeEnum.Rhp],
    [RouteIdEnum.BlockDsiContent, BlockTypeEnum.Dsi],
    [RouteIdEnum.BlockTxtContent, BlockTypeEnum.Txt],
    [RouteIdEnum.BlockMultiPrContent, BlockTypeEnum.MultiPr],
    [RouteIdEnum.BlockDsiHistoryModal, BlockTypeEnum.Dsi],
    [RouteIdEnum.MultiPrHistoryModal, BlockTypeEnum.MultiPr],
    [RouteIdEnum.BlockObjectContent, BlockTypeEnum.Object],
  ]);

  export const blockRoutePath: SfxMap<BlockTypeEnum, string> = new SfxMap([
    [BlockTypeEnum.Pr, RouterPaths.FlowContentPaths.blockPrContentPath],
    [BlockTypeEnum.MultiPr, RouterPaths.FlowContentPaths.blockMultiPrContentPath],
    [BlockTypeEnum.Adr, RouterPaths.FlowContentPaths.blockAdrContentPath],
    [BlockTypeEnum.Emm, RouterPaths.FlowContentPaths.blockEmmContentPath],
    [BlockTypeEnum.Form, RouterPaths.FlowContentPaths.blockFormContentPath],
    [BlockTypeEnum.Rhp, RouterPaths.FlowContentPaths.blockRhpContentPath],
    [BlockTypeEnum.Dsi, RouterPaths.FlowContentPaths.blockDsiContentPath],
    [BlockTypeEnum.Txt, RouterPaths.FlowContentPaths.blockTxtContentPath],
    [BlockTypeEnum.Object, RouterPaths.FlowContentPaths.blockObjectContentPath],
  ]);

  export const selectValidationMenuItems = (flowType: FlowTypeEnum): MultiSelectItemData<BlockTypeEnum>[] =>
    filteredValues()
      .getValue(flowType)
      .map(value => ({
        title: BlockTypeEnum.selectValidationTitle.getValue(value),
        value: value,
      }));

  export const multiSelectValidationMenuItems = (flowType: FlowTypeEnum): Observable<MultiSelectItemData<BlockTypeEnum>[]> => of(selectValidationMenuItems(flowType));
}
