import { RefreshTokenResponse } from '../../generated/models/models';

export interface RefreshTokenResponseData {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  idToken: string;
}

export namespace RefreshTokenResponseData {
  export function mapFromApiValue(data: RefreshTokenResponse): RefreshTokenResponseData {
    return {
      accessToken: data.access_token,
      tokenType: data.token_type,
      expiresIn: data.expires_in,
      idToken: data.id_token,
    };
  }
}
