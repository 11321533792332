import { DefaultValueData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { FormUpdateCustomStatusRequestDTO } from '../../generated/models/formUpdateCustomStatusRequestDTO';

export interface FormLibraryForUpdate {
  uuidEntity: string;
  familyUuidEntity: string;
  customStatus: DefaultValueData;
  comment?: string;
}

export namespace FormLibraryForUpdate {
  export function mapToUpdateRequestApiValue(form: FormLibraryForUpdate): FormUpdateCustomStatusRequestDTO {
    return {
      formUuidEntity: form.uuidEntity,
      customStatusUuidEntity: form.customStatus && form.customStatus.uuidEntity,
      comment: form.comment,
    };
  }
}
