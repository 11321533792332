import { MoveEnum } from '../../enums';
import { MoveElementOnParentRequestDTO } from '../../generated/models/moveElementOnParentRequestDTO';

export interface MoveTableHeaderData {
  siblingUuidEntity: string;
  relativePosition: MoveEnum;
  uuidEntity: string;
}

export namespace MoveTableHeaderData {
  export function mapToRequestApiValue(moveRequest: MoveTableHeaderData): MoveElementOnParentRequestDTO {
    return {
      uuidEntity: moveRequest.uuidEntity,
      siblingUuidEntity: moveRequest.siblingUuidEntity,
      relativePosition: MoveEnum.convertToApiValue.getValue(moveRequest.relativePosition),
    };
  }
}
