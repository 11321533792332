import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FlowAffectationModalTabEnum {
  Collaborators = '_collaborators',
  Tablets = '_tablets',
}

export namespace FlowAffectationModalTabEnum {
  const key = 'planTranslate.affectationPopover.tab.';
  export const toString: SfxMap<FlowAffectationModalTabEnum, string> = new SfxMap<FlowAffectationModalTabEnum, string>([
    [FlowAffectationModalTabEnum.Collaborators, key + 'collaborators'],
    [FlowAffectationModalTabEnum.Tablets, key + 'tablets'],
  ]);

  export const iconData: SfxMap<FlowAffectationModalTabEnum, IconData> = new SfxMap<FlowAffectationModalTabEnum, IconData>([
    [FlowAffectationModalTabEnum.Collaborators, { name: 'user' }],
    [FlowAffectationModalTabEnum.Tablets, { name: 'tablet-alt' }],
  ]);
}
