/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApplicationFileTmpSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  createDate: Date;
  name: string;
  entityName: string;
  description: string;
  status: ApplicationFileTmpSummaryResponseDTO.StatusEnum;
  fileType: ApplicationFileTmpSummaryResponseDTO.FileTypeEnum;
}
export namespace ApplicationFileTmpSummaryResponseDTO {
  export type StatusEnum = 'IN_PROGRESS' | 'DONE' | 'FAIL';
  export const StatusEnum = {
    INPROGRESS: 'IN_PROGRESS' as StatusEnum,
    DONE: 'DONE' as StatusEnum,
    FAIL: 'FAIL' as StatusEnum,
  };
  export type FileTypeEnum = 'ZIP' | 'PDF';
  export const FileTypeEnum = {
    ZIP: 'ZIP' as FileTypeEnum,
    PDF: 'PDF' as FileTypeEnum,
  };
}
