import { SfxMap } from '../../utils/enum-utils';
import { SchedulingResponseDTO } from '../../generated/models/schedulingResponseDTO';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { IconData } from '../../../modules/shared/interfaces/icon-data';

export enum ScheduleNatureEnum {
  Referential = 1,
  Reminder = 2,
}

export namespace ScheduleNatureEnum {
  export const values = [ScheduleNatureEnum.Referential, ScheduleNatureEnum.Reminder];

  export const convertFromApiValue: SfxMap<SchedulingResponseDTO.NatureEnum, ScheduleNatureEnum> = new SfxMap<SchedulingResponseDTO.NatureEnum, ScheduleNatureEnum>([
    [SchedulingResponseDTO.NatureEnum.REFERENTIAL, ScheduleNatureEnum.Referential],
    [SchedulingResponseDTO.NatureEnum.REMINDER, ScheduleNatureEnum.Reminder],
  ]);

  export const convertToApiValue: SfxMap<ScheduleNatureEnum, SchedulingResponseDTO.NatureEnum> = new SfxMap<ScheduleNatureEnum, SchedulingResponseDTO.NatureEnum>([
    [ScheduleNatureEnum.Referential, SchedulingResponseDTO.NatureEnum.REFERENTIAL],
    [ScheduleNatureEnum.Reminder, SchedulingResponseDTO.NatureEnum.REMINDER],
  ]);

  export const convertToParamsValue = new SfxMap<ScheduleNatureEnum, string>([
    [ScheduleNatureEnum.Reminder, 'reminder'],
    [ScheduleNatureEnum.Referential, 'referential'],
  ]);

  export const convertFromParamsValue = new SfxMap<string, ScheduleNatureEnum>([
    ['reminder', ScheduleNatureEnum.Reminder],
    ['referential', ScheduleNatureEnum.Referential],
  ]);

  const key = 'schedule.addModal.nature.';
  export const toString: SfxMap<ScheduleNatureEnum, string> = new SfxMap<ScheduleNatureEnum, string>([
    [ScheduleNatureEnum.Referential, key + 'referential'],
    [ScheduleNatureEnum.Reminder, key + 'reminder'],
  ]);

  export const toTooltip: SfxMap<ScheduleNatureEnum, string> = new SfxMap<ScheduleNatureEnum, string>([
    [ScheduleNatureEnum.Referential, key + 'referentialType'],
    [ScheduleNatureEnum.Reminder, key + 'reminderType'],
  ]);

  export const scheduleColor = new SfxMap<ScheduleNatureEnum, string>([
    [ScheduleNatureEnum.Reminder, '#E46C0B'],
    [ScheduleNatureEnum.Referential, '#A672AC'],
  ]);

  export const scheduleIcon = new SfxMap<ScheduleNatureEnum, IconData>([
    [ScheduleNatureEnum.Reminder, { name: 'sitemap', iconClassName: 'fa-rotate-270' }],
    [ScheduleNatureEnum.Referential, { name: 'pencil-ruler' }],
  ]);

  export const quickSearchValues: MultiSelectItemData<ScheduleNatureEnum>[] = [
    {
      value: ScheduleNatureEnum.Reminder,
      title: null,
      color: scheduleColor.getValue(ScheduleNatureEnum.Reminder),
      icons: [scheduleIcon.getValue(ScheduleNatureEnum.Reminder)],
      tooltip: 'scheduleTasks.filters.nature.reminder',
    },
    {
      value: ScheduleNatureEnum.Referential,
      title: null,
      color: scheduleColor.getValue(ScheduleNatureEnum.Referential),
      icons: [scheduleIcon.getValue(ScheduleNatureEnum.Referential)],
      tooltip: 'scheduleTasks.filters.nature.referential',
    },
  ];

  export const comparePredicate = (node: SingleSelectItemData<ScheduleNatureEnum>, item: ScheduleNatureEnum) => node.value === item;

  export const selectedItemValues: MultiSelectItemData<ScheduleNatureEnum>[] = values.map(nature => ({ value: nature, title: toString.getValue(nature) }));
}
