import { Observable, of } from 'rxjs';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BlocDsiDetailsResponsesDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum BlockSignaturesStatusEnum {
  Disabled = 1,
  Enabled = 2,
  Optional = 3,
  Required = 4,
}

export namespace BlockSignaturesStatusEnum {
  const values: BlockSignaturesStatusEnum[] = [BlockSignaturesStatusEnum.Enabled, BlockSignaturesStatusEnum.Required, BlockSignaturesStatusEnum.Disabled];
  const chartValues: BlockSignaturesStatusEnum[] = [BlockSignaturesStatusEnum.Required, BlockSignaturesStatusEnum.Enabled];

  export const convertFromApiValue: SfxMap<BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum, BlockSignaturesStatusEnum> = new SfxMap<
    BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum,
    BlockSignaturesStatusEnum
  >(
    [
      [BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.DISABLED, BlockSignaturesStatusEnum.Disabled],
      [BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.OPTIONAL, BlockSignaturesStatusEnum.Optional],
      [BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.REQUIRED, BlockSignaturesStatusEnum.Required],
      [BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.ENABLED, BlockSignaturesStatusEnum.Enabled],
    ],
    BlockSignaturesStatusEnum.Enabled,
  );

  export const convertToApiValue: SfxMap<BlockSignaturesStatusEnum, BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum> = new SfxMap<
    BlockSignaturesStatusEnum,
    BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum
  >(
    [
      [BlockSignaturesStatusEnum.Disabled, BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.DISABLED],
      [BlockSignaturesStatusEnum.Optional, BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.OPTIONAL],
      [BlockSignaturesStatusEnum.Required, BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.REQUIRED],
      [BlockSignaturesStatusEnum.Enabled, BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.ENABLED],
    ],
    BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum.ENABLED,
  );

  export const convertFromParamsValue: SfxMap<string, BlockSignaturesStatusEnum> = new SfxMap<string, BlockSignaturesStatusEnum>([
    ['disabled', BlockSignaturesStatusEnum.Disabled],
    ['optional', BlockSignaturesStatusEnum.Optional],
    ['required', BlockSignaturesStatusEnum.Required],
    ['enabled', BlockSignaturesStatusEnum.Enabled],
  ]);

  export const convertToParamsValue: SfxMap<BlockSignaturesStatusEnum, string> = new SfxMap<BlockSignaturesStatusEnum, string>([
    [BlockSignaturesStatusEnum.Disabled, 'disabled'],
    [BlockSignaturesStatusEnum.Optional, 'optional'],
    [BlockSignaturesStatusEnum.Required, 'required'],
    [BlockSignaturesStatusEnum.Enabled, 'enabled'],
  ]);

  const key = 'blocks.dsi.label.';
  export const toString: SfxMap<BlockSignaturesStatusEnum, string> = new SfxMap<BlockSignaturesStatusEnum, string>(
    [
      [BlockSignaturesStatusEnum.Disabled, key + 'disabledSignature'],
      [BlockSignaturesStatusEnum.Required, key + 'requiredSignature'],
      [BlockSignaturesStatusEnum.Enabled, key + 'enabledSignature'],
    ],
    '',
  );

  const chartKey = 'dashboardMetrics.signature.';
  export const toChartString: SfxMap<BlockSignaturesStatusEnum, string> = new SfxMap<BlockSignaturesStatusEnum, string>([
    [BlockSignaturesStatusEnum.Required, chartKey + 'required'],
    [BlockSignaturesStatusEnum.Enabled, chartKey + 'enabled'],
  ]);

  export const color: SfxMap<BlockSignaturesStatusEnum, string> = new SfxMap<BlockSignaturesStatusEnum, string>([
    [BlockSignaturesStatusEnum.Enabled, '#626466'],
    [BlockSignaturesStatusEnum.Required, 'repeating-linear-gradient(135deg, white, white 8px, #626466 8px, #626466 16px)'],
  ]);

  export const selectItems: SingleSelectItemData<BlockSignaturesStatusEnum>[] = values.map(status => ({
    value: status,
    title: BlockSignaturesStatusEnum.toString.getValue(status),
  }));

  export const selectChartItems: SingleSelectItemData<BlockSignaturesStatusEnum>[] = chartValues.map(status => ({
    value: status,
    color: color.getValue(status),
    title: toChartString.getValue(status),
  }));

  export const multiSelectItemResolver = (): Observable<MultiSelectItemData<BlockSignaturesStatusEnum>[]> =>
    of(
      values.map(status => ({
        value: status,
        title: toString.getValue(status),
      })),
    );
}
