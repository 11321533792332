import { MobileDumpErrorDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum LogsPushColumnEnum {
  Date = 'date',
  Type = 'type',
  CreatedBy = 'createdBy',
  Actions = 'actions',
}

export namespace LogsPushColumnEnum {
  export type tableSortField = keyof MobileDumpErrorDTO;

  export const allColumns = [LogsPushColumnEnum.Date, LogsPushColumnEnum.Type, LogsPushColumnEnum.CreatedBy, LogsPushColumnEnum.Actions];

  export const toString: SfxMap<LogsPushColumnEnum, string> = new SfxMap<LogsPushColumnEnum, string>(
    [
      [LogsPushColumnEnum.Date, 'logs-push.table.date'],
      [LogsPushColumnEnum.Type, 'logs-push.table.type'],
      [LogsPushColumnEnum.CreatedBy, 'logs-push.table.createdBy'],
      [LogsPushColumnEnum.Actions, 'logs-push.table.action'],
    ],
    'Unknown',
  );

  export const sortField: SfxMap<LogsPushColumnEnum, tableSortField> = new SfxMap<LogsPushColumnEnum, tableSortField>([
    [LogsPushColumnEnum.Date, 'date'],
    [LogsPushColumnEnum.Type, 'type'],
    [LogsPushColumnEnum.CreatedBy, 'createdBy'],
  ]);
}
