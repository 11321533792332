import { UpdateHideMultiPrTitleRequestDTO } from '../../generated/models/models';

export interface BlockUpdateHiddenTitle {
  blockUuidEntity: string;
  hiddenMultiPrTitle: boolean;
}
export namespace BlockUpdateHiddenTitle {
  export function mapToUpdateRequestApiValue(blockUuidEntity: string, hiddenMultiPrTitle: boolean): UpdateHideMultiPrTitleRequestDTO {
    return {
      blocUuidEntity: blockUuidEntity,
      hiddenMultiPrTitle: hiddenMultiPrTitle,
    };
  }
}
