import { CustomCellDetailsResponseDTO } from '../../generated/models/customCellDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum CustomCellFormatEnum {
  StringValue = 1,
  Integer = 2,
  Double = 3,
  Image = 4,
  Date = 5,
  DateTime = 6,
}

export namespace CustomCellFormatEnum {
  const values: CustomCellFormatEnum[] = [
    CustomCellFormatEnum.StringValue,
    CustomCellFormatEnum.Integer,
    CustomCellFormatEnum.Double,
    CustomCellFormatEnum.Image,
    CustomCellFormatEnum.Date,
    CustomCellFormatEnum.DateTime,
  ];

  export const convertFromApiValue: SfxMap<CustomCellDetailsResponseDTO.FormatEnum, CustomCellFormatEnum> = new SfxMap<
    CustomCellDetailsResponseDTO.FormatEnum,
    CustomCellFormatEnum
  >([
    [CustomCellDetailsResponseDTO.FormatEnum.STRING, CustomCellFormatEnum.StringValue],
    [CustomCellDetailsResponseDTO.FormatEnum.INTEGER, CustomCellFormatEnum.Integer],
    [CustomCellDetailsResponseDTO.FormatEnum.DOUBLE, CustomCellFormatEnum.Double],
    [CustomCellDetailsResponseDTO.FormatEnum.IMAGE, CustomCellFormatEnum.Image],
    [CustomCellDetailsResponseDTO.FormatEnum.DATE, CustomCellFormatEnum.Date],
    [CustomCellDetailsResponseDTO.FormatEnum.DATETIME, CustomCellFormatEnum.DateTime],
  ]);

  export const convertToApiValue: SfxMap<CustomCellFormatEnum, CustomCellDetailsResponseDTO.FormatEnum> = new SfxMap<CustomCellFormatEnum, CustomCellDetailsResponseDTO.FormatEnum>(
    [
      [CustomCellFormatEnum.StringValue, CustomCellDetailsResponseDTO.FormatEnum.STRING],
      [CustomCellFormatEnum.Integer, CustomCellDetailsResponseDTO.FormatEnum.INTEGER],
      [CustomCellFormatEnum.Double, CustomCellDetailsResponseDTO.FormatEnum.DOUBLE],
      [CustomCellFormatEnum.Image, CustomCellDetailsResponseDTO.FormatEnum.IMAGE],
      [CustomCellFormatEnum.Date, CustomCellDetailsResponseDTO.FormatEnum.DATE],
      [CustomCellFormatEnum.DateTime, CustomCellDetailsResponseDTO.FormatEnum.DATETIME],
    ],
  );

  export const convertToParamsValue: SfxMap<CustomCellFormatEnum, string> = new SfxMap<CustomCellFormatEnum, string>([
    [CustomCellFormatEnum.StringValue, 'string'],
    [CustomCellFormatEnum.Integer, 'integer'],
    [CustomCellFormatEnum.Double, 'double'],
    [CustomCellFormatEnum.Image, 'image'],
    [CustomCellFormatEnum.Date, 'date'],
    [CustomCellFormatEnum.DateTime, 'dateTime'],
  ]);

  export const convertFromParamsValue: SfxMap<string, CustomCellFormatEnum> = new SfxMap<string, CustomCellFormatEnum>([
    ['string', CustomCellFormatEnum.StringValue],
    ['integer', CustomCellFormatEnum.Integer],
    ['double', CustomCellFormatEnum.Double],
    ['image', CustomCellFormatEnum.Image],
    ['date', CustomCellFormatEnum.Date],
    ['dateTime', CustomCellFormatEnum.DateTime],
  ]);

  export const toString = (forthcoming?: boolean): SfxMap<CustomCellFormatEnum, string> => {
    const key = 'customCells.formats.' + (forthcoming ? 'forthcoming.' : '');

    return new SfxMap<CustomCellFormatEnum, string>([
      [CustomCellFormatEnum.StringValue, key + 'string'],
      [CustomCellFormatEnum.Integer, key + 'integer'],
      [CustomCellFormatEnum.Double, key + 'double'],
      [CustomCellFormatEnum.Image, key + 'image'],
      [CustomCellFormatEnum.Date, key + 'date'],
      [CustomCellFormatEnum.DateTime, key + 'dateTime'],
    ]);
  };

  export const isDisabled: SfxMap<CustomCellFormatEnum, boolean> = new SfxMap<CustomCellFormatEnum, boolean>([
    [CustomCellFormatEnum.Integer, true],
    [CustomCellFormatEnum.Double, true],
    [CustomCellFormatEnum.Image, true],
    [CustomCellFormatEnum.Date, true],
    [CustomCellFormatEnum.DateTime, true],
  ]);

  export const itemsResolver = (forthcoming?: boolean): Observable<SingleSelectItemData<CustomCellFormatEnum>[]> =>
    of(
      values.map(format => ({
        value: format,
        title: toString(forthcoming).getValue(format),
        disabled: isDisabled.getValue(format),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<CustomCellFormatEnum>, item: CustomCellFormatEnum) => item === node.value;
}
