/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FlowLightDTO } from './flowLightDTO';
import { StepLightDTO } from './stepLightDTO';
import { TagLightDTO } from './tagLightDTO';

export interface PhaseDetailsResponseDTO {
  additionalComment?: string;
  createDate: Date;
  customNodeOrder: string;
  description: string;
  disabled: boolean;
  excludeNumbering: boolean;
  flow: FlowLightDTO;
  hideTitlePdf: boolean;
  modificationDate: Date;
  name: string;
  nodeOrder: number;
  nodeOrderToDisplay: number;
  readOnlyOnMobileApp: boolean;
  stepSequenceType: PhaseDetailsResponseDTO.StepSequenceTypeEnum;
  steps: Array<StepLightDTO>;
  tags: Array<TagLightDTO>;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  version: string;
}
export namespace PhaseDetailsResponseDTO {
  export type StepSequenceTypeEnum = 'LINEAR' | 'PARALLEL';
  export const StepSequenceTypeEnum = {
    LINEAR: 'LINEAR' as StepSequenceTypeEnum,
    PARALLEL: 'PARALLEL' as StepSequenceTypeEnum,
  };
}
