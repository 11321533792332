import { CustomStatusDetailsResponseDTO, CustomStatusCreateRequestDTO, CustomStatusUpdateRequestDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { BusinessClassNameEnum, ColorPickerPaletteEnum, CustomStatusCategoryEnum, LinkTypeEnum } from '../../enums';
import { TypeUuidEntityEnum } from '../../enums/utils/type-uuid-entity-enum';

export interface CustomStatusDetailsData {
  uuidEntity: string;
  businessClass: BusinessClassNameEnum;
  name: string;
  description: string;
  linkType: LinkTypeEnum;
  labelFamily: LabelFamilyLightData;
  color: string;
  downloadable: boolean;
  importable: boolean;
  exportable: boolean;
  hideEntity?: boolean;
  defaultStatus: boolean;
  frozenDefault?: boolean;
  entityStatus?: CustomStatusCategoryEnum;
}

export namespace CustomStatusDetailsData {
  export const defaultUuidEntity = TypeUuidEntityEnum.convertToApiValue.getValue(TypeUuidEntityEnum.WITHOUT);

  export function mapFromApiValue(value: CustomStatusDetailsResponseDTO): CustomStatusDetailsData {
    return {
      uuidEntity: value.uuidEntity,
      businessClass: BusinessClassNameEnum.convertFromApiValue.getValue(value.businessClass),
      name: value.name,
      linkType: LinkTypeEnum.convertFromApiValue.getValue(value.choixLinkType),
      description: value.description,
      labelFamily: value.labelFamily && LabelFamilyLightData.mapFromApiValue(value.labelFamily),
      color: value.color,
      downloadable: value.downloadable,
      importable: value.importable,
      exportable: value.exportable,
      defaultStatus: value.defaultCustomStatus,
      frozenDefault: value.defaultCustomStatus,
      entityStatus: CustomStatusCategoryEnum.convertFromApiValue.getValue(value.customStatusCategory),
      hideEntity: value.hideEntity,
    };
  }

  export function mapToCreateRequestApiValue(value: CustomStatusDetailsData): CustomStatusCreateRequestDTO {
    return {
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(value.businessClass) as CustomStatusCreateRequestDTO.BusinessClassEnum,
      name: value.name,
      choixLinkType: LinkTypeEnum.convertToApiValue.getValue(value.linkType),
      description: value.description,
      labelFamily: value.labelFamily?.uuidEntity && { uuidEntity: value.labelFamily?.uuidEntity, id: undefined },
      color: value.color || ColorPickerPaletteEnum.defaultColor,
      downloadable: value.downloadable,
      importable: value.importable,
      exportable: value.exportable,
      defaultCustomStatus: value.defaultStatus,
      customStatusCategory: CustomStatusCategoryEnum.convertToApiValue.getValue(value.entityStatus) as CustomStatusCreateRequestDTO.CustomStatusCategoryEnum,
      hideEntity: value.hideEntity,
    };
  }

  export function mapToUpdateRequestApiValue(value: CustomStatusDetailsData): CustomStatusUpdateRequestDTO {
    return {
      uuidEntity: value.uuidEntity,
      name: value.name,
      choixLinkType: LinkTypeEnum.convertToApiValue.getValue(value.linkType),
      description: value.description,
      labelFamily: value.labelFamily?.uuidEntity && { uuidEntity: value.labelFamily?.uuidEntity, id: null },
      color: value.color,
      downloadable: value.downloadable,
      importable: value.importable,
      exportable: value.exportable,
      defaultCustomStatus: value.defaultStatus,
      customStatusCategory: CustomStatusCategoryEnum.convertToApiValue.getValue(value.entityStatus) as CustomStatusUpdateRequestDTO.CustomStatusCategoryEnum,
      hideEntity: value.hideEntity,
    };
  }
}
