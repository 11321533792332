import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum CustomMenuActionEnum {
  Cut = 1,
  Copy = 2,
  Paste = 3,
  SpecialCharacters = 4,
}

export namespace CustomMenuActionEnum {
  const values: CustomMenuActionEnum[] = [CustomMenuActionEnum.Cut, CustomMenuActionEnum.Copy, CustomMenuActionEnum.Paste];

  const key = 'customMenu.actions.';

  export const toString = new SfxMap<CustomMenuActionEnum, string>([
    [CustomMenuActionEnum.Cut, key + 'cut'],
    [CustomMenuActionEnum.Copy, key + 'copy'],
    [CustomMenuActionEnum.Paste, key + 'paste'],
    [CustomMenuActionEnum.SpecialCharacters, key + 'specialCharacters'],
  ]);

  export const iconData = new SfxMap<CustomMenuActionEnum, IconData>([
    [CustomMenuActionEnum.Cut, { name: 'cut', prefix: 'fas' }],
    [CustomMenuActionEnum.Copy, { name: 'copy', prefix: 'fas' }],
    [CustomMenuActionEnum.Paste, { name: 'paste', prefix: 'fas' }],
  ]);

  export const selectItems: SingleSelectItemData<CustomMenuActionEnum>[] = values.map(action => ({
    value: action,
    title: toString.getValue(action),
    icons: [iconData.getValue(action)],
  }));
}
