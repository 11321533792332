import { FactoryProvider, Injectable, InjectionToken, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalStorageService } from 'ngx-webstorage';

import { merge } from 'lodash';
import { forkJoin, Observable, of, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { frontAppConfig } from '../../core/models/utils/front-app-config';
import { LocalStorageEnum } from '../../core/enums/local-storage.enum';
import { LanguageEnum } from '../../core/enums/language.enum';

@Injectable()
export class SfxMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return params.key;
  }
}

export function TRANSLATION_FILE_PATH_TOKEN() {
  return new InjectionToken<string | string[]>('TRANSLATION_FILE_PATH_TOKEN');
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, private resources: { prefix: string; suffix: string }[]) {}

  public getTranslation(lang: string): Observable<Object> {
    const requests = this.resources.map(resource => {
      const path = resource.prefix + lang + resource.suffix;

      return this.http.get(path).pipe(
        catchError(res => {
          console.warn('Something went wrong for the following translation file:', path);
          console.warn(res.message);

          return of({});
        }),
      );
    });

    return forkJoin(requests).pipe(map(responses => responses.reduce((a, b) => merge(a, b), {})));
  }
}

function sharedTranslateFactory(http: HttpClient, path: string | string[]): TranslateHttpLoader | MultiTranslateHttpLoader {
  const suffix = '.json?=' + frontAppConfig.version;
  if (Array.isArray(path)) {
    const paths = path.map(prefix => ({ prefix, suffix }));

    return new MultiTranslateHttpLoader(http, paths);
  }

  return new TranslateHttpLoader(http, path, suffix);
}

export const sfxTranslateLoader: FactoryProvider = {
  provide: TranslateLoader,
  useFactory: sharedTranslateFactory,
  deps: [HttpClient, TRANSLATION_FILE_PATH_TOKEN],
};

export const sfxTranslateConfig: TranslateModuleConfig = {
  loader: sfxTranslateLoader,
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: SfxMissingTranslationHandler,
  },
  defaultLanguage: localStorage.getItem(LocalStorageEnum.currentLanguage) || LanguageEnum.defaultLanguage,
  isolate: true,
};

export function appLangInitializer(translate: TranslateService, injector: Injector, localStorage: LocalStorageService) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const currentLanguage =
      localStorage.retrieve(LocalStorageEnum.currentLanguage) ||
      (LanguageEnum.supportedLanguages.includes(translate.getBrowserLang()) ? translate.getBrowserLang() : LanguageEnum.defaultLanguage);

    translate.addLangs(LanguageEnum.supportedLanguages);
    translate.setDefaultLang(currentLanguage);
    localStorage.store(LocalStorageEnum.currentLanguage, currentLanguage);
    try {
      await lastValueFrom(translate.use(currentLanguage));
    } catch (error) {
      console.info(error);
    }
    console.info(`%cSuccessfully initialized ${currentLanguage} language.`, 'color: blue');
  };
}
