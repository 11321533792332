import { AccountCreateRequestDTO } from '../../generated/models/accountCreateRequestDTO';

export interface UserForCreate {
  companyName: string;
  email: string;
  login: string;
  password: string;
  langKey: string;
  subdomain?: string;
  ssoIdentifier?: string;
}

export namespace UserForCreate {
  export function mapUserForCreateToAccountCreateRequestDTO(userForCreate: UserForCreate): AccountCreateRequestDTO {
    return {
      companyName: userForCreate.companyName,
      email: userForCreate.email,
      login: userForCreate.login,
      password: userForCreate.password,
      langKey: userForCreate.langKey,
      subdomain: userForCreate.subdomain,
      ssoIdentifier: userForCreate.ssoIdentifier || undefined,
    };
  }
}
