import { KpiGlobalResponseDTO } from '../../generated/models/models';

export interface GlobalMetricsData {
  userCount: number;
  userTotalCount: number;
  operatorCount: number;
  operatorTotalCount: number;
  collaboratorCount: number;
  collaboratorTotalCount: number;
  entityCount: number;
  flowCount: number;
  formCount: number;
  signatureCount: number;
  formResponseCount: number;
  blockCount: number;
  phaseCount: number;
  stepCount: number;
}

export namespace GlobalMetricsData {
  export function mapFromApiValue(metrics: KpiGlobalResponseDTO): GlobalMetricsData {
    return {
      userCount: metrics.userCount,
      userTotalCount: metrics.totalUserCount,
      operatorCount: metrics.operatorCount,
      operatorTotalCount: metrics.totalOperatorCount,
      collaboratorCount: metrics.collaboratorCount,
      collaboratorTotalCount: metrics.totalCollaboratorCount,
      entityCount: metrics.entityCount,
      flowCount: metrics.flowCount,
      formCount: metrics.formCount,
      signatureCount: metrics.signatureCount,
      formResponseCount: metrics.formResponseCount,
      blockCount: metrics.blocCount,
      phaseCount: metrics.phaseCount,
      stepCount: metrics.stepCount,
    };
  }
}
