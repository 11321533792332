import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ImageEditorToolEnum {
  Pencil = 'pencil',
  Text = 'text',
}

export namespace ImageEditorToolEnum {
  export const values = [ImageEditorToolEnum.Pencil, ImageEditorToolEnum.Text];

  export const toString: SfxMap<ImageEditorToolEnum, string> = new SfxMap<ImageEditorToolEnum, string>([
    [ImageEditorToolEnum.Pencil, 'pencil'],
    [ImageEditorToolEnum.Text, 'text'],
  ]);

  export const iconData: SfxMap<ImageEditorToolEnum, IconData> = new SfxMap<ImageEditorToolEnum, IconData>([
    [ImageEditorToolEnum.Pencil, { name: 'pencil-alt', prefix: 'fas' }],
    [ImageEditorToolEnum.Text, { name: 'font', prefix: 'fas' }],
  ]);
}
