import { BlocRhpSummaryResponseDTO, PageBlocRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';

export interface BlockTeamSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  count: number;
  observation: string;
  skills: string;
  disabled?: boolean;
}

export namespace BlockTeamSummaryData {
  const sortFields = ['function', 'number', 'skills'];

  export interface QueryRequest extends BaseQuery<BlockTeamSummaryData> {
    projectUuidEntity: string;
    blockUuidEntity: string;
  }

  export function mapFromApiValue(teamBlockSummary: BlocRhpSummaryResponseDTO): BlockTeamSummaryData {
    return {
      uuidEntity: teamBlockSummary.uuidEntity,
      family: teamBlockSummary.familyFunction && LabelFamilyLightData.mapFromApiValue(teamBlockSummary.familyFunction),
      count: teamBlockSummary.number,
      skills: teamBlockSummary.skills && teamBlockSummary.skills.map(skill => LabelFamilyLightData.mapFromApiValue(skill).name).join(StringUtilsEnum.SlashSeparator),
      observation: teamBlockSummary.observation,
      disabled: teamBlockSummary.disabled,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: undefined,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
    };
  }

  export function initializeQueryRequest(blockUuidEntity: string, projectUuidEntity: string, sort: SortFilter<BlockTeamSummaryData>): QueryRequest {
    return {
      ...initializeQueries<BlockTeamSummaryData>(sort),
      blockUuidEntity,
      projectUuidEntity,
    };
  }
}
