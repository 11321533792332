import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { BaseHttpService } from '../../../core/services/base-http.service';
import { apiUrlMatcher } from '../../../core/services/helpers/api-url-matcher';
import { ApiUrlsEnum } from '../../../core/services/helpers/api-url';
import { HttpParamsType, ExchangeCodeRequest, ExchangeCodeData, RefreshTokenResponseData } from '../../../core/models';
import { createRandom } from '../helpers/random';
import { ExchangeCodeResponse, RefreshTokenResponse } from '../../../core/generated/models/models';
import { LocalStorageEnum } from '../../../core/enums/local-storage.enum';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(private baseHttpService: BaseHttpService, private localStorageService: LocalStorageService) {}

  public checkTokenValidity(httpParams: HttpParamsType<{ idToken: string }>): Observable<boolean> {
    return this.baseHttpService.post<boolean>(apiUrlMatcher(ApiUrlsEnum.CheckTokenValidity), httpParams).pipe(map(valid => valid));
  }

  public exchangeCode(httpParams: HttpParamsType<ExchangeCodeRequest>): Observable<ExchangeCodeData> {
    return this.baseHttpService.post<ExchangeCodeResponse>(apiUrlMatcher(ApiUrlsEnum.ExchangeCode), httpParams).pipe(map(data => ExchangeCodeData.mapFromApiValue(data)));
  }

  public refreshToken(httpParams: HttpParamsType<String>): Observable<RefreshTokenResponseData> {
    return this.baseHttpService.post<RefreshTokenResponse>(apiUrlMatcher(ApiUrlsEnum.RefreshToken), httpParams).pipe(map(data => RefreshTokenResponseData.mapFromApiValue(data)));
  }

  public getExistingOrCreateAuthStateControl(): string {
    this.localStorageService.retrieve(LocalStorageEnum.oauth2);
    let state = this.localStorageService.retrieve(LocalStorageEnum.authStateControl);
    if (!state) {
      state = createRandom(40);
      this.localStorageService.store('authStateControl', LocalStorageEnum.authStateControl);
    }

    return state;
  }

  public isAuth2 = (): boolean => !!this.localStorageService.retrieve(LocalStorageEnum.oauth2);
  public getRefreshToken = (): string => this.localStorageService.retrieve(LocalStorageEnum.refreshToken);
}
