import { PictogramEnum } from '../../enums';
import { ParadeCreateRequestDTO, ParadeDetailsResponseDTO, ParadeUpdateRequestDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';
import { RiskDetailsData } from './risk-details-data';

export interface ParadeDetailsData {
  applicationFile: ApplicationFileLightData;
  description: string;
  elementAdr: RiskDetailsData;
  name: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
  pictogram: PictogramEnum;
}

export namespace ParadeDetailsData {
  export function mapFromApiValue(parade: ParadeDetailsResponseDTO): ParadeDetailsData {
    return {
      applicationFile: parade.applicationFile && ApplicationFileLightData.mapFromApiValue(parade.applicationFile),
      description: parade.description,
      elementAdr: RiskDetailsData.mapFromApiValue(parade.elementAdr),
      name: parade.name,
      uuid: parade.uuid,
      uuidEntity: parade.uuidEntity,
      uuidOrganization: parade.uuidOrganization,
      uuidType: parade.uuidType,
      pictogram: PictogramEnum.convertFromApiValue.getValue(parade.pictogram),
    };
  }

  export function mapToCreateRequestApiValue(parade: ParadeDetailsData): ParadeCreateRequestDTO {
    return {
      applicationFileUuidEntity: parade.applicationFile && parade.applicationFile.uuidEntity,
      description: parade.description,
      elementAdrUuidEntity: parade.elementAdr && parade.elementAdr.uuidEntity,
      name: parade.name,
      pictogram: PictogramEnum.convertToApiValue.getValue(parade.pictogram),
    };
  }

  export function mapToUpdateRequestApiValue(parade: ParadeDetailsData): ParadeUpdateRequestDTO {
    return {
      applicationFileUuidEntity: parade.applicationFile && parade.applicationFile.uuidEntity,
      description: parade.description,
      elementAdrUuidEntity: parade.elementAdr && parade.elementAdr.uuidEntity,
      name: parade.name,
      uuidEntity: parade.uuidEntity,
      pictogram: PictogramEnum.convertToApiValue.getValue(parade.pictogram),
    };
  }
}
