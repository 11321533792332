import { CategoryCountResponseDTO } from '../../generated/models/models';

export interface CategoriesCount {
  filteredCategoriesCount: number;
  totalCategoriesCount: number;
}

export namespace CategoriesCount {
  export function mapFromApiValue(countData: CategoryCountResponseDTO): CategoriesCount {
    return {
      filteredCategoriesCount: countData.filteredCount,
      totalCategoriesCount: countData.totalCount,
    };
  }
}
