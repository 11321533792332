import { compact } from 'lodash';
import { DocumentProvenanceEnum } from '../../enums';
import { DocumentsProgressionRequestDTO } from '../../generated/models/models';
import { DateFilterData } from '../utils/date-filter-data';
import { CustomWordingStateData } from '../wording-config-setting/custom-wording-state-data';

export interface MetricsDocumentsQueryParams {
  projectUuidEntity: string;
  provenances?: DocumentProvenanceEnum[];
  families?: string[];
  categories?: string[];
  categories2?: string[];
  customWording?: string[];
  flows?: string[];
  dateFilter?: DateFilterData;
}

export namespace MetricsDocumentsQueryParams {
  export function mapToRequestApiValue(queries: MetricsDocumentsQueryParams): DocumentsProgressionRequestDTO {
    return {
      familyUuidEntities: queries.families || undefined,
      category1UuidEntities: queries.categories || undefined,
      category2UuidEntities: queries.categories2 || undefined,
      flowUuidEntities: queries.flows || undefined,
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
      provenance: (queries.provenances && queries.provenances.map(provenance => DocumentProvenanceEnum.convertToApiValue.getValue(provenance))) || undefined,
      types: compact(queries.customWording) as DocumentsProgressionRequestDTO.TypesEnum[],
    };
  }

  export function initializeQueryRequest(
    projectUuidEntity: string,
    provenances: string[],
    listQueryParam: {
      families: string[];
      categories: string[];
      categories2: string[];
      customWording: CustomWordingStateData[];
      flows: string[];
    },
    dateFilter: DateFilterData,
  ): MetricsDocumentsQueryParams {
    return {
      projectUuidEntity,
      provenances: (provenances || []).length ? provenances.map(provenance => DocumentProvenanceEnum.convertFromParamsValue.getValue(provenance)) : undefined,
      flows: (listQueryParam.flows || []).length ? listQueryParam.flows : undefined,
      families: (listQueryParam.families || []).length ? listQueryParam.families : undefined,
      categories: (listQueryParam.categories || []).length ? listQueryParam.categories : undefined,
      categories2: (listQueryParam.categories2 || []).length ? listQueryParam.categories2 : undefined,
      customWording: (listQueryParam.customWording || []).length ? listQueryParam.customWording.map(cw => cw.customWordingUuidEntity) : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
