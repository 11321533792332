import { BusinessClassNameEnum } from '../../enums';
import { CheckUserSharedLinkRequestDto } from '../../generated/models/checkUserSharedLinkRequestDto';
import { CheckUserSharedLinkResponseDto } from '../../generated/models/checkUserSharedLinkResponseDto';

export interface CheckUserShareData {
  name: string;
  email: string;
}

export namespace CheckUserShareData {
  export function mapFromApiValue(data: CheckUserSharedLinkResponseDto): CheckUserShareData {
    return {
      name: data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : '',
      email: data.login,
    };
  }

  export function mapToRequestApiValue(email: string, businessClass: BusinessClassNameEnum, uuidEntity: string): CheckUserSharedLinkRequestDto {
    return {
      login: email,
      businessClass: BusinessClassNameEnum.convertToApiValue.getValue(businessClass),
      refUuide: uuidEntity,
    };
  }
}
