import { BlocValidationUpdateRequestDTO, FlowInternalValidationRequestDTO, FlowInternalRedactionRequestDTO } from '../../generated/models/models';

import { BlockStatusEnum, BlockTypeEnum } from '../../enums';

export interface ValidateAllBlocksData {
  blocks: BlockTypeEnum[];
  statusBloc: BlockStatusEnum;
  retry?: boolean;
}

export namespace ValidateAllBlocksData {
  export function mapToRequestApiValue(
    flowUuidEntity: string,
    projectUuidEntity: string,
    blockValidation: ValidateAllBlocksData,
    comment?: string,
  ): FlowInternalValidationRequestDTO {
    return {
      natureBlocList: blockValidation.blocks.map(block => BlockTypeEnum.convertToApiValue.getValue(block)),
      statusBloc: BlockStatusEnum.convertToApiValue.getValue(blockValidation.statusBloc),
      flowUuidEntity,
      projectUuidEntity,
      retry: blockValidation.retry,
      comment: comment,
    };
  }

  export function mapToUpdateRequestApiValue(blockUuidEntity: string, projectUuidEntity: string, statusBlock: BlockStatusEnum, comment?: string): BlocValidationUpdateRequestDTO {
    return {
      uuidEntity: blockUuidEntity,
      uuideProject: projectUuidEntity,
      comment: comment || undefined,
      newStatus: BlockStatusEnum.convertToApiValue.getValue(statusBlock),
    };
  }

  export function mapToRedactionRequestApiValue(flowUuidEntity: string, blockValidation: ValidateAllBlocksData, comment?: string): FlowInternalRedactionRequestDTO {
    return {
      natureBlocList: blockValidation.blocks.map(block => BlockTypeEnum.convertToApiValue.getValue(block)),
      flowUuidEntity,
      comment: comment,
    };
  }
}
