import { createAction, props } from '@ngrx/store';

import { LazyLoadResponse, VerificationSectionData, SectionDocumentSummaryData, DocumentSummaryData, FolderForPropertiesData } from '../../../../../core/models';
import { SectionEmmSummaryData } from '../../../../../core/models/closing-folder/section-emm-data';

import { SectionDetailsData } from '../../../../../core/models/section/section-details-data';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';
import { ReorganizeSectionEnum } from '../../../../../core/enums/reorganize-section.enum';

export const loadSectionDetailsSuccess = createAction('[D-Sections-dialog] load section details data success', props<{ section: SectionDetailsData }>());
export const loadSectionDetailsFail = createAction('[D-Sections-dialog] load section details data fail', props<DialogToasterData>());

export const createSection = createAction('[D-Sections-dialog] create section', props<{ section: SectionDetailsData; editMode: boolean }>());
export const createSectionSuccess = createAction('[D-Sections-dialog] create section success', props<{ section: SectionDetailsData; folderUuidEntity: string }>());
export const createSectionFail = createAction('[D-Sections-dialog] create section fail', props<DialogToasterData>());

export const updateSection = createAction('[D-Sections-dialog] update section', props<{ section: SectionDetailsData; close?: boolean; nextTab?: string }>());
export const updateSectionContent = createAction('[D-Sections-dialog] update section content', props<{ section: SectionDetailsData; close?: boolean; nextTab?: string }>());
export const updateSectionSuccess = createAction('[D-Sections-dialog] update section success', props<{ section: SectionDetailsData; close?: boolean; nextTab?: string }>());
export const updateSectionFail = createAction('[D-Sections-dialog] update section fail', props<DialogToasterData>());

export const initializeNewSection = createAction('[D-Sections-dialog] initialize new section', props<{ section: SectionDetailsData }>());

export const importSectionDocuments = createAction('[D-Sections-dialog] import section documents', props<{ documents: string[]; close?: boolean; nextTab?: string }>());
export const importSectionDocumentsSuccess = createAction(
  '[D-Sections-dialog] import section documents success',
  props<{ selectedDocs: string[]; deselectedDocs: string[]; close?: boolean; nextTab?: string }>(),
);
export const moveSectionDocument = createAction('[D-Sections-dialog] Move section document', props<{ document: DocumentSummaryData; previousDocument: string }>());
export const moveSectionEmm = createAction('[D-Sections-dialog] Move section emm', props<{ sectionEmm: SectionEmmSummaryData; previousSectionEmm: string }>());

export const sectionDetailNavigation = createAction('[D-Sections-dialog] Navigate through details section', props<{ sectionUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[D-Sections-dialog] confirm navigate details section', props<{ section: SectionDetailsData; step: number }>());

export const loadSectionDocuments = createAction('[D-Sections-dialog] load section documents');
export const loadSectionDocumentsSuccess = createAction(
  '[D-Sections-dialog] load section documents success',
  props<{ data: LazyLoadResponse<DocumentSummaryData[]>; reset?: boolean }>(),
);
export const loadSectionDocumentsFail = createAction('[D-Sections-dialog] load section documents fail', props<DialogToasterData>());
export const loadMoreSectionDocuments = createAction('[D-Sections-dialog] load more section documents');

export const loadSectionEmms = createAction('[D-Sections-dialog] load section emm');
export const loadSectionEmmsSuccess = createAction('[D-Sections-dialog] load section emm success', props<{ data: LazyLoadResponse<SectionEmmSummaryData[]>; reset?: boolean }>());
export const loadSectionEmmsFail = createAction('[D-Sections-dialog] load section emm fail', props<DialogToasterData>());
export const loadMoreSectionEmms = createAction('[D-Sections-dialog] load section emm documents');

export const loadDocumentsToImport = createAction('[D-Sections-dialog] load documents to import');
export const loadDocumentsToImportSuccess = createAction(
  '[D-Sections-dialog] load documents to import success',
  props<{ data: LazyLoadResponse<SectionDocumentSummaryData[]>; reset?: boolean; createAnother?: boolean }>(),
);
export const loadDocumentsToImportFail = createAction('[D-Sections-dialog] load documents to import fail', props<DialogToasterData>());
export const loadMoreDocumentsToImport = createAction('[D-Sections-dialog] load more documents to import');

export const openFilterDatePopover = createAction('[dClosingFolder] open field closing folder filter date popover', props<{ origin: HTMLElement }>());

export const verifySection = createAction('[D-Sections-dialog] verify section', props<{ uuidEntity: string }>());
export const verifySectionSuccess = createAction('[D-Sections-dialog] verify section success', props<{ categories: VerificationSectionData[] }>());
export const verifySectionFail = createAction('[D-Sections-dialog] verify section fail', props<DialogToasterData>());

export const loadFolderSuccess = createAction('[D-Sections-dialog] loadFolder success', props<{ folderDetails: FolderForPropertiesData }>());
export const loadFolderFail = createAction('[D-Sections-dialog] loadFolder fail', props<DialogToasterData>());

export const refreshDocuments = createAction('[D-Sections-dialog] refresh Documents');
export const refreshEmms = createAction('[D-Sections-dialog] refresh Emms');

export const reorganizeSections = createAction('[D-Sections-dialog] reorganize section', props<{ reorganizeBy: ReorganizeSectionEnum }>());
export const reorganizeSectionsSuccess = createAction('[D-Sections-dialog] reorganize section success');
export const reorganizeSectionsFail = createAction('[D-Sections-dialog] reorganize section fail');
