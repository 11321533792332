import { SignatoryRequestDTO } from '../../generated/models/signatoryRequestDTO';

export interface SignatoryRequestData {
  firstName: string;
  lastName: string;
  email: string;
  userRef: string;
  imageSignature: string;
  commentSignature: string;
  signatureDate: string;
  profession: string;
  companyName: string;
  hashSignature: string;
  signWithQrCode: boolean;
}
export namespace SignatoryRequestData {
  export function mapToApiValue(signatoryData: SignatoryRequestData): SignatoryRequestDTO {
    return {
      firstName: signatoryData.firstName,
      lastName: signatoryData.lastName,
      email: signatoryData.email,
      userRef: signatoryData.userRef || null,
      imageSignature: signatoryData.imageSignature,
      commentSignature: signatoryData.commentSignature || null,
      signatureDate: signatoryData.signatureDate && new Date(signatoryData.signatureDate),
      profession: signatoryData.profession || null,
      companyName: signatoryData.companyName || null,
      hashSignature: signatoryData.hashSignature || null,
      signWithQrCode: signatoryData.signWithQrCode || null,
    };
  }
}
