import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { UserRightEnum } from '../user-right.enum';
import { compact } from 'lodash';

export enum FormReorganizeTypeEnum {
  Family = 'family',
  Company = 'company',
}

export namespace FormReorganizeTypeEnum {
  export const reorganizeTypes = (userRight: UserRightEnum) =>
    compact([FormReorganizeTypeEnum.Family, userRight !== UserRightEnum.UserContractor && FormReorganizeTypeEnum.Company]);

  export const toString = new SfxMap<FormReorganizeTypeEnum, string>([
    [FormReorganizeTypeEnum.Family, 'forms.dialogs.reorganize.types.family'],
    [FormReorganizeTypeEnum.Company, 'forms.dialogs.reorganize.types.company'],
  ]);

  export const itemsResolver = (userRight: UserRightEnum): Observable<SingleSelectItemData<FormReorganizeTypeEnum>[]> =>
    of(
      reorganizeTypes(userRight).map(value => ({
        value,
        title: FormReorganizeTypeEnum.toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FormReorganizeTypeEnum>, item: FormReorganizeTypeEnum) => node.value === item;
}
