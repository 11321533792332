/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

export interface PageSkillRequest {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  labelFamilyParentUuides?: Array<string>;
  labelFamilyChildUuides?: Array<string>;
  status?: PageSkillRequest.StatusEnum;
  activityDomainsUuides?: Array<string>;
  uuidEntityUser: string;
  labelFamilyChildsUuides?: Array<string>;
}
export namespace PageSkillRequest {
  export type StatusEnum = 'ACTIF' | 'EXPIRED';
  export const StatusEnum = {
    ACTIF: 'ACTIF' as StatusEnum,
    EXPIRED: 'EXPIRED' as StatusEnum,
  };
}
