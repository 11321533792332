import { compact } from 'lodash';
import { Observable, of } from 'rxjs';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomFileDetailsResponseDTO } from '../../generated/models/customFileDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum CustomFileTypeEnum {
  PROJECT_FLOW_CONTENT_MINING = 1,
  PROJECT_FLOW_CONTENT,
  PROJECT_FLOW_FORM,
  TECHNICAL_OBJECT,
  LIBRARY_ADR,
  LIBRARY_EMM,
  LIBRARY_USER,
}

export namespace CustomFileTypeEnum {
  const notImplemented = [CustomFileTypeEnum.LIBRARY_ADR, CustomFileTypeEnum.LIBRARY_EMM, CustomFileTypeEnum.LIBRARY_USER];

  export const values = (isExport: boolean): CustomFileTypeEnum[] =>
    compact([
      !isExport && CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING,
      CustomFileTypeEnum.PROJECT_FLOW_CONTENT,
      isExport && CustomFileTypeEnum.PROJECT_FLOW_FORM,
      CustomFileTypeEnum.TECHNICAL_OBJECT,
      CustomFileTypeEnum.LIBRARY_ADR,
      CustomFileTypeEnum.LIBRARY_EMM,
      CustomFileTypeEnum.LIBRARY_USER,
    ]);

  export const convertFromApiValue: SfxMap<CustomFileDetailsResponseDTO.CustomFileTypeEnum, CustomFileTypeEnum> = new SfxMap<
    CustomFileDetailsResponseDTO.CustomFileTypeEnum,
    CustomFileTypeEnum
  >([
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWCONTENTMINING, CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWCONTENT, CustomFileTypeEnum.PROJECT_FLOW_CONTENT],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWFORM, CustomFileTypeEnum.PROJECT_FLOW_FORM],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.TECHNICALOBJECT, CustomFileTypeEnum.TECHNICAL_OBJECT],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYADR, CustomFileTypeEnum.LIBRARY_ADR],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYEMM, CustomFileTypeEnum.LIBRARY_EMM],
    [CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYUSER, CustomFileTypeEnum.LIBRARY_USER],
  ]);

  export const convertToApiValue: SfxMap<CustomFileTypeEnum, CustomFileDetailsResponseDTO.CustomFileTypeEnum> = new SfxMap<
    CustomFileTypeEnum,
    CustomFileDetailsResponseDTO.CustomFileTypeEnum
  >([
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING, CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWCONTENTMINING],
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT, CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWCONTENT],
    [CustomFileTypeEnum.PROJECT_FLOW_FORM, CustomFileDetailsResponseDTO.CustomFileTypeEnum.PROJECTFLOWFORM],
    [CustomFileTypeEnum.TECHNICAL_OBJECT, CustomFileDetailsResponseDTO.CustomFileTypeEnum.TECHNICALOBJECT],
    [CustomFileTypeEnum.LIBRARY_ADR, CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYADR],
    [CustomFileTypeEnum.LIBRARY_EMM, CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYEMM],
    [CustomFileTypeEnum.LIBRARY_USER, CustomFileDetailsResponseDTO.CustomFileTypeEnum.LIBRARYUSER],
  ]);

  export const convertFromParamsValue: SfxMap<string, CustomFileTypeEnum> = new SfxMap<string, CustomFileTypeEnum>([
    ['fl_content_mining', CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING],
    ['fl_content', CustomFileTypeEnum.PROJECT_FLOW_CONTENT],
    ['fl_form', CustomFileTypeEnum.PROJECT_FLOW_FORM],
    ['tech_obj', CustomFileTypeEnum.TECHNICAL_OBJECT],
    ['lib_adr', CustomFileTypeEnum.LIBRARY_ADR],
    ['lib_emm', CustomFileTypeEnum.LIBRARY_EMM],
    ['lib_user', CustomFileTypeEnum.LIBRARY_USER],
  ]);

  export const convertToParamsValue: SfxMap<CustomFileTypeEnum, string> = new SfxMap<CustomFileTypeEnum, string>([
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING, 'fl_content_mining'],
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT, 'fl_content'],
    [CustomFileTypeEnum.PROJECT_FLOW_FORM, 'fl_form'],
    [CustomFileTypeEnum.TECHNICAL_OBJECT, 'tech_obj'],
    [CustomFileTypeEnum.LIBRARY_ADR, 'lib_adr'],
    [CustomFileTypeEnum.LIBRARY_EMM, 'lib_emm'],
    [CustomFileTypeEnum.LIBRARY_USER, 'lib_user'],
  ]);

  const key = 'customFiles.types.';
  export const toString: SfxMap<CustomFileTypeEnum, string> = new SfxMap<CustomFileTypeEnum, string>([
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT_MINING, key + 'flowContentMining'],
    [CustomFileTypeEnum.PROJECT_FLOW_CONTENT, key + 'flowContent'],
    [CustomFileTypeEnum.PROJECT_FLOW_FORM, key + 'flowForm'],
    [CustomFileTypeEnum.TECHNICAL_OBJECT, key + 'technicalObject'],
    [CustomFileTypeEnum.LIBRARY_ADR, key + 'adr'],
    [CustomFileTypeEnum.LIBRARY_EMM, key + 'emm'],
    [CustomFileTypeEnum.LIBRARY_USER, key + 'user'],
  ]);

  export const resolver = (isExport?: boolean): Observable<SingleSelectItemData<CustomFileTypeEnum>[]> =>
    of(
      values(isExport)
        .filter(item => !notImplemented.includes(item))
        .map(type => ({
          value: type,
          title: toString.getValue(type),
        })),
    );

  export const comparePredicate = (node: SingleSelectItemData<CustomFileTypeEnum>, item: CustomFileTypeEnum) => node.value === item;
}
