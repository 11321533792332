import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { PageEpsilonFilterRequest } from '../../generated/models/pageEpsilonFilterRequest';
import { InnerSortDirectionEnum } from '../../enums';
import { EpsilonOutSummaryData } from './epsilon-out-summary-data';

export namespace EpsilonOutLightData {
  export interface QueryRequest extends BaseQuery<EpsilonOutSummaryData> {
    projectUuidEntity: string;
    doublon: boolean;
  }

  export function initializeFilterQueries(page: number, searchText?: string, doublon?: boolean, projectUuidEntity?: string): QueryRequest {
    return {
      ...initializeQueries<EpsilonOutSummaryData>({ key: 'id' }),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
      doublon,
    };
  }
  export function mapToPageRequestApiValue(queries: QueryRequest): PageEpsilonFilterRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) },
      textSearch: queries.textSearch,
      projectUuidEntity: queries.projectUuidEntity,
      doublon: queries.doublon,
    };
  }
}
