import { find } from 'lodash';

import { CustomColumnData } from './custom-columns-data';
import { CustomColumnsConfigurationEnum } from '../../enums';
import { CustomColumnsLocalStorageData } from './custom-columns-local-storage-data';

export interface CustomColumnsConfigurationData {
  configuration: CustomColumnsConfigurationEnum;
  columns: CustomColumnData[];
  sizes?: { [key: string]: number };
  confirmation?: boolean;
}

export namespace CustomColumnsConfigurationData {
  export function mapToLocalStorage(config: CustomColumnsConfigurationData): CustomColumnsLocalStorageData {
    return {
      configuration: config.configuration,
      uuidEntities: config.columns.map(column => column.uuidEntity),
      sizes: {}, // if columns set in local storage we reinitialize columns width
    };
  }

  export function mapFromLocalStorage(config: CustomColumnsLocalStorageData, forPgac: boolean): CustomColumnsConfigurationData {
    return {
      sizes: config.sizes,
      configuration: config.configuration,
      columns: (config.uuidEntities || []).map(uuidEntity => find(CustomColumnData.attributes(forPgac), ['uuidEntity', uuidEntity]) || { uuidEntity }),
    } as CustomColumnsConfigurationData;
  }
}
