import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { FormNavbarItemTypeEnum, RubricNatureEnum } from '../../enums';
import { PageRubricRequest } from '../../generated/models/pageRubricRequest';
import { RubricSummaryResponseDTO } from '../../generated/models/rubricSummaryResponseDTO';
import { FormNavbarItemData } from '../form-content/form-navbar-item';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';

export interface RubricSummaryData {
  uuidEntity: string;
  uuidType: string;
  name: string;
  description: string;
  nature: RubricNatureEnum;
  order: number;
  questionsCount: number;
  additionalComment?: string;
  additionalObservation?: string;
}

export namespace RubricSummaryData {
  export interface QueryRequest extends BaseQuery<RubricSummaryData> {
    nature: RubricNatureEnum;
  }

  export function mapFromApiValue(rubric: RubricSummaryResponseDTO): RubricSummaryData {
    return {
      uuidEntity: rubric.uuidEntity,
      uuidType: rubric.uuidType,
      name: rubric.name,
      description: rubric.description,
      order: rubric.rubricOrder,
      nature: RubricNatureEnum.convertFromApiValue.getValue(rubric.rubricType),
      questionsCount: rubric.questionsCount,
      additionalComment: rubric.additionalComment,
      additionalObservation: rubric.additionalObservation,
    };
  }

  export function mapToEntityLightData(rubric: Partial<RubricSummaryData & RubricSummaryResponseDTO>): EntityLightData {
    return {
      name: rubric.name,
      uuidEntity: rubric.uuidEntity,
      uuidType: rubric.uuidType,
    };
  }

  export function mapToSelectItem(rubric: Partial<RubricSummaryData & RubricSummaryResponseDTO>): SingleSelectItemData<EntityLightData> {
    return {
      value: mapToEntityLightData(rubric),
      title: rubric.name,
    };
  }

  export function mapToFormNavbarItemData(rubricSummary: RubricSummaryResponseDTO): FormNavbarItemData {
    const rubric = mapFromApiValue(rubricSummary);

    return {
      uuidEntity: rubric.uuidEntity,
      name: rubric.name,
      description: rubric.description,
      type: FormNavbarItemTypeEnum.Rubric,
      order: rubric.order,
      nature: rubric.nature,
      childrenCount: rubric.questionsCount,
      tooltip: rubric.additionalObservation,
      uuidType: rubric.uuidType,
      additionalComment: rubricSummary.additionalComment,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageRubricRequest {
    return {
      page: queries.page,
      size: queries.size,
      nature: RubricNatureEnum.convertToApiValue.getValue(queries.nature),
    };
  }

  export function initializeQueryRequest(page?: number, searchText?: string, nature?: RubricNatureEnum): QueryRequest {
    return {
      ...initializeQueries<RubricSummaryData>(),
      page,
      textSearch: searchText || undefined,
      nature,
    };
  }
}
