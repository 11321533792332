import { FlowDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ObjectFlowColumnEnum {
  Family = 'family',
  Name = 'name',
  Status = 'status',
  LastUpdate = 'lastUpdate',
  Actions = 'actions',
}

export namespace ObjectFlowColumnEnum {
  type flowSortField = keyof FlowDetailsResponseDTO;

  export const allColumns = [ObjectFlowColumnEnum.Family, ObjectFlowColumnEnum.Name, ObjectFlowColumnEnum.Status, ObjectFlowColumnEnum.LastUpdate, ObjectFlowColumnEnum.Actions];
  export const assignFlowTableColumns = [ObjectFlowColumnEnum.Family, ObjectFlowColumnEnum.Name, ObjectFlowColumnEnum.Status, ObjectFlowColumnEnum.LastUpdate];
  export const flowTableColumns = [ObjectFlowColumnEnum.Family, ObjectFlowColumnEnum.Name, ObjectFlowColumnEnum.Status, ObjectFlowColumnEnum.Actions];

  export function getFlowTableColumns(assignFlowTable: boolean): ObjectFlowColumnEnum[] {
    return assignFlowTable ? assignFlowTableColumns : flowTableColumns;
  }

  const key = 'technicalObjects.table.header.';
  export const toString: SfxMap<ObjectFlowColumnEnum, string> = new SfxMap<ObjectFlowColumnEnum, string>([
    [ObjectFlowColumnEnum.Family, key + 'family'],
    [ObjectFlowColumnEnum.Name, key + 'name'],
    [ObjectFlowColumnEnum.Status, key + 'status'],
    [ObjectFlowColumnEnum.LastUpdate, key + 'lastUpdate'],
    [ObjectFlowColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ObjectFlowColumnEnum, flowSortField> = new SfxMap<ObjectFlowColumnEnum, flowSortField>([
    [ObjectFlowColumnEnum.Name, 'name'],
    [ObjectFlowColumnEnum.Family, 'labelFamilyApplication'],
    [ObjectFlowColumnEnum.LastUpdate, 'modificationDate'],
    [ObjectFlowColumnEnum.Status, 'customStatusFlow'],
  ]);
}
