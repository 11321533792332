import { Action, createReducer, on } from '@ngrx/store';

import { CustomFieldDetailsData } from '../../../../../core/models';
import * as fromActions from './custom-field-dialog.actions';

export interface CustomFieldDialogState {
  saveLoading: boolean;
  customField: CustomFieldDetailsData;
  displayInCardError: boolean;
  displayInBannerError: boolean;
  displayOnFlowOpeningError: boolean;
}

export const initialCustomFieldDialogState = () => ({
  saveLoading: false,
  customField: {} as CustomFieldDetailsData,
  displayInCardError: false,
  displayInBannerError: false,
  displayOnFlowOpeningError: false,
});

const reducer = createReducer<CustomFieldDialogState>(
  initialCustomFieldDialogState(),
  on(fromActions.setCustomFieldSuccess, (state, { customField }): CustomFieldDialogState => ({ ...state, customField })),
  on(fromActions.addCustomField, fromActions.updateCustomField, (state): CustomFieldDialogState => ({ ...state, saveLoading: true })),
  on(
    fromActions.addCustomFieldSuccess,
    (state, { customField, addNewOne }): CustomFieldDialogState => {
      const newCustomField = {
        typeField: customField.typeField,
        linkType: customField.linkType,
        businessClass: customField.businessClass,
        labelFamily: customField.labelFamily,
      } as CustomFieldDetailsData;

      return { ...state, saveLoading: false, customField: addNewOne ? newCustomField : state.customField };
    },
  ),
  on(
    fromActions.addCustomFieldFail,
    fromActions.updateCustomFieldFail,
    fromActions.updateCustomFieldSuccess,
    fromActions.preVerifyCustomFieldCreationFail,
    fromActions.preVerifyCustomFieldUpdateFail,
    (state): CustomFieldDialogState => ({ ...state, saveLoading: false }),
  ),
  on(
    fromActions.setPreVerification,
    (state, { verification }): CustomFieldDialogState => ({
      ...state,
      displayInCardError: verification.displayInFlowCardError,
    }),
  ),
);

export function customFieldDialogReducer(state: CustomFieldDialogState | undefined, action: Action): CustomFieldDialogState {
  return reducer(state, action);
}
