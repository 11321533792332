import { ApplicationEventLightDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ApplicationEventSourceEnum {
  Mobile = 1,
  WebApp,
  APIEXT,
}

export namespace ApplicationEventSourceEnum {
  export const convertFromApiValue: SfxMap<ApplicationEventLightDTO.EventSourceEnum, ApplicationEventSourceEnum> = new SfxMap<
    ApplicationEventLightDTO.EventSourceEnum,
    ApplicationEventSourceEnum
  >(
    [
      [ApplicationEventLightDTO.EventSourceEnum.WebApp, ApplicationEventSourceEnum.WebApp],
      [ApplicationEventLightDTO.EventSourceEnum.MobileApp, ApplicationEventSourceEnum.Mobile],
      [ApplicationEventLightDTO.EventSourceEnum.APIEXT, ApplicationEventSourceEnum.APIEXT],
    ],
    ApplicationEventSourceEnum.WebApp,
  );

  export const convertToApiValue: SfxMap<ApplicationEventSourceEnum, ApplicationEventLightDTO.EventSourceEnum> = new SfxMap<
    ApplicationEventSourceEnum,
    ApplicationEventLightDTO.EventSourceEnum
  >([
    [ApplicationEventSourceEnum.WebApp, ApplicationEventLightDTO.EventSourceEnum.WebApp],
    [ApplicationEventSourceEnum.Mobile, ApplicationEventLightDTO.EventSourceEnum.MobileApp],
    [ApplicationEventSourceEnum.APIEXT, ApplicationEventLightDTO.EventSourceEnum.APIEXT],
  ]);

  export const toString: SfxMap<ApplicationEventSourceEnum, string> = new SfxMap<ApplicationEventSourceEnum, string>([
    [ApplicationEventSourceEnum.WebApp, 'WebApp'],
    [ApplicationEventSourceEnum.Mobile, 'MobileApp'],
    [ApplicationEventSourceEnum.APIEXT, 'API'],
  ]);
}
