import { compact } from 'lodash';
import { Observable, of } from 'rxjs';
import { SfxMap } from '../../utils/enum-utils';

import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { ProjectTypeEnum } from '../project/project-type.enum';
import { RouteQueryParamEnum } from '../router-enums/query-params.enum';

export enum FlowReorganizeTypeEnum {
  Category = 'category',
  Reporter = 'reporter',
  Responsible = 'responsible',
  Collaborators = 'collaborators',
  TabletOne = 'tabletOne',
  TabletTwo = 'tabletTwo',
  TabletThree = 'tabletThree',
  Status = 'status',
  StartDate = 'startDate',
  EndDate = 'endDate',
  EstimatedStartDate = 'estimatedStartDate',
  EstimatedEndDate = 'estimatedEndDate',
  AchievedStartDate = 'achievedStartDate',
  AchievedEndDate = 'achievedEndDate',
}

export namespace FlowReorganizeTypeEnum {
  export const reorganizeTypes = (projectType: ProjectTypeEnum) =>
    compact([
      FlowReorganizeTypeEnum.Category,
      FlowReorganizeTypeEnum.Reporter,
      FlowReorganizeTypeEnum.Responsible,
      FlowReorganizeTypeEnum.Collaborators,
      !ProjectTypeEnum.projectsWhereTabletNotUsed.includes(projectType) && FlowReorganizeTypeEnum.TabletOne,
      !ProjectTypeEnum.projectsWhereTabletNotUsed.includes(projectType) && FlowReorganizeTypeEnum.TabletTwo,
      !ProjectTypeEnum.projectsWhereTabletNotUsed.includes(projectType) && FlowReorganizeTypeEnum.TabletThree,
      FlowReorganizeTypeEnum.Status,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.StartDate,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.EndDate,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.EstimatedStartDate,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.EstimatedEndDate,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.AchievedStartDate,
      projectType !== ProjectTypeEnum.Template && FlowReorganizeTypeEnum.AchievedEndDate,
    ]);

  export const usersTypes = [FlowReorganizeTypeEnum.Responsible, FlowReorganizeTypeEnum.Reporter];
  export const tabletTypes = [FlowReorganizeTypeEnum.TabletOne, FlowReorganizeTypeEnum.TabletTwo, FlowReorganizeTypeEnum.TabletThree];
  export const dateTypes = [
    FlowReorganizeTypeEnum.StartDate,
    FlowReorganizeTypeEnum.EndDate,
    FlowReorganizeTypeEnum.EstimatedStartDate,
    FlowReorganizeTypeEnum.EstimatedEndDate,
    FlowReorganizeTypeEnum.AchievedStartDate,
    FlowReorganizeTypeEnum.AchievedEndDate,
  ];

  const key = 'flows.modals.reorganize.types.';
  export const toString: SfxMap<FlowReorganizeTypeEnum, string> = new SfxMap<FlowReorganizeTypeEnum, string>([
    [FlowReorganizeTypeEnum.Category, key + 'category'],
    [FlowReorganizeTypeEnum.Reporter, key + 'reporter'],
    [FlowReorganizeTypeEnum.Responsible, key + 'responsible'],
    [FlowReorganizeTypeEnum.Collaborators, key + 'collaborators'],
    [FlowReorganizeTypeEnum.TabletOne, key + 'tabletOne'],
    [FlowReorganizeTypeEnum.TabletTwo, key + 'tabletTwo'],
    [FlowReorganizeTypeEnum.TabletThree, key + 'tabletThree'],
    [FlowReorganizeTypeEnum.Status, key + 'status'],
    [FlowReorganizeTypeEnum.StartDate, key + 'startDate'],
    [FlowReorganizeTypeEnum.EndDate, key + 'endDate'],
    [FlowReorganizeTypeEnum.EstimatedStartDate, key + 'estimatedStartDate'],
    [FlowReorganizeTypeEnum.EstimatedEndDate, key + 'estimatedEndDate'],
    [FlowReorganizeTypeEnum.AchievedStartDate, key + 'achievedStartDate'],
    [FlowReorganizeTypeEnum.AchievedEndDate, key + 'achievedEndDate'],
  ]);

  export const itemsResolver = (projectType: ProjectTypeEnum): Observable<SingleSelectItemData<FlowReorganizeTypeEnum>[]> =>
    of(
      reorganizeTypes(projectType).map(value => ({
        value,
        title: FlowReorganizeTypeEnum.toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FlowReorganizeTypeEnum>, item: FlowReorganizeTypeEnum) => node.value === item;

  export const matchingFlowFilter = (reorganizeType: FlowReorganizeTypeEnum) => {
    const dateFilters = [RouteQueryParamEnum.StartDate, RouteQueryParamEnum.EndDate, RouteQueryParamEnum.TypeDate, RouteQueryParamEnum.FieldDate];

    return new SfxMap<FlowReorganizeTypeEnum, RouteQueryParamEnum[]>(
      [
        [FlowReorganizeTypeEnum.Category, [RouteQueryParamEnum.Category]],
        [FlowReorganizeTypeEnum.Reporter, [RouteQueryParamEnum.Assignee]],
        [FlowReorganizeTypeEnum.Responsible, [RouteQueryParamEnum.Assignee]],
        [FlowReorganizeTypeEnum.Status, [RouteQueryParamEnum.Status]],
        [FlowReorganizeTypeEnum.StartDate, dateFilters],
        [FlowReorganizeTypeEnum.EndDate, dateFilters],
        [FlowReorganizeTypeEnum.EstimatedStartDate, dateFilters],
        [FlowReorganizeTypeEnum.EstimatedEndDate, dateFilters],
        [FlowReorganizeTypeEnum.AchievedStartDate, dateFilters],
        [FlowReorganizeTypeEnum.AchievedEndDate, dateFilters],
      ],
      [],
    ).getValue(reorganizeType);
  };
}
