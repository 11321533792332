import dayjs from 'dayjs';

import { PlaningHelper } from '../../../modules/dashboard/flow-management/list/plan/utils/helpers';
import { PlanDisplayFormatEnum, PlanLinkTypeEnum } from '../../enums';
import { ElementPlanLinkLightResponseDTO, ElementPlanSummaryResponseDTO } from '../../generated/models/models';

export interface ChartBarDateLimitData {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export interface ChartBarLinksData {
  destinationUuidEntity: string;
  linkType: PlanLinkTypeEnum;
}

export namespace ChartBarLinksData {
  export function mapFromApiValue(link: ElementPlanLinkLightResponseDTO): ChartBarLinksData {
    return {
      destinationUuidEntity: link.elementPlanDestinationUuidEntity,
      linkType: PlanLinkTypeEnum.convertFromApiValue.getValue(link.dependencyType),
    };
  }
}
export interface PlanningChartData {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  offsetWidth: number;
  offsetLeft: number;
  elementPlanUuidEntity: string;
  links: ChartBarLinksData[];
  draggable?: boolean;
  color?: string;
  parentName?: string;
  parentUuidEntity?: string;
  progress?: number;
  frozen?: boolean;
}

export namespace PlanningChartData {
  export function mapFromApiValue(
    elementPlan: ElementPlanSummaryResponseDTO & { frozen?: boolean },
    selectedTime: dayjs.Dayjs,
    displayFormat: PlanDisplayFormatEnum,
  ): PlanningChartData {
    const startDate = elementPlan.startDate && dayjs(elementPlan.startDate);
    const endDate = elementPlan.finishDate && dayjs(elementPlan.finishDate);
    const isoWeekDay = displayFormat === PlanDisplayFormatEnum.Month ? selectedTime.startOf(displayFormat as dayjs.OpUnitType).isoWeekday() - 1 : 0;

    return {
      startDate,
      endDate,
      offsetWidth: startDate && endDate && PlaningHelper.getElementWidth(startDate, endDate, selectedTime.startOf(displayFormat as dayjs.OpUnitType), displayFormat),
      offsetLeft: startDate && PlaningHelper.getElementOffsetLeft(startDate, selectedTime.startOf(displayFormat as dayjs.OpUnitType).subtract(isoWeekDay, 'days'), displayFormat),
      elementPlanUuidEntity: elementPlan.uuidEntity,
      links: (elementPlan.links || []).map(link => ChartBarLinksData.mapFromApiValue(link)),
      parentUuidEntity: elementPlan.parentUuidEntity,
      parentName: elementPlan.name,
      frozen: elementPlan.frozen,
      draggable: elementPlan.frozen
        ? false
        : startDate &&
          endDate &&
          startDate.isBetween(
            selectedTime.startOf(displayFormat as dayjs.OpUnitType),
            selectedTime.endOf(displayFormat as dayjs.OpUnitType),
            displayFormat as dayjs.OpUnitType,
            '[]',
          ) &&
          endDate.isBetween(
            selectedTime.startOf(displayFormat as dayjs.OpUnitType),
            selectedTime.endOf(displayFormat as dayjs.OpUnitType),
            displayFormat as dayjs.OpUnitType,
            '[]',
          ),
      color: elementPlan?.customStatusLightResponseDTO?.color || '#0d4e8a',
    };
  }
}
