import { compact } from 'lodash';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { ContributorSummaryData, SelectAllData, TabletSummaryData } from '..';
import { FlowTypeEnum, InnerSortDirectionEnum, PlanScaleEnum, UserNatureEnum } from '../../enums';
import {
  ElementPlanCreateRequestDTOPageFlowRequest,
  ElementPlanCreateRequestDTOPageTeamRoleRequest,
  ElementPlanCreateRequestDTOPageTeamRoleTabletRequestDTO,
  FlowSummaryForPlanResponseDTO,
  PagePlanRequestDTO,
  PlanCreateRequestDTO,
  PlanSummaryResponseDTO,
  PlanUpdateRequestDTO,
} from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FlowSummaryData } from '../flows/flow-summary';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

export interface PlanSummaryData {
  name: string;
  description: string;
  nodeOrder: number;
  scale: PlanScaleEnum;
  uuidEntity: string;
}

export namespace PlanSummaryData {
  export interface PlanQueryRequest extends BaseQuery<PlanSummaryData> {
    projectUuidEntity: string;
  }

  export function mapFromApiValue(plan: PlanSummaryResponseDTO): PlanSummaryData {
    const scale = PlanScaleEnum.convertFromApiValue.getValue(plan.scale);

    return {
      name: scale === PlanScaleEnum.Default ? 'global.DefaultPlan' : plan.name,
      description: plan.description,
      nodeOrder: plan.planOrder,
      scale,
      uuidEntity: plan.uuidEntity,
    };
  }

  export function mapToCreateRequestDTO(projectUuidEntity: string, plan: PlanSummaryData): PlanCreateRequestDTO {
    return {
      name: plan.name,
      description: plan.description,
      scale: PlanScaleEnum.convertToApiValue.getValue(plan.scale),
      projectUuidEntity,
    };
  }

  export function mapToUpdateRequestDTO(projectUuidEntity: string, plan: PlanSummaryData): PlanUpdateRequestDTO {
    return {
      name: plan.name,
      description: plan.description,
      scale: PlanScaleEnum.convertToApiValue.getValue(plan.scale),
      projectUuidEntity,
      planUuidEntity: plan.uuidEntity,
    };
  }

  export function mapToSelectItemData(plan: PlanSummaryResponseDTO): SingleSelectItemData<PlanSummaryData> {
    const value = mapFromApiValue(plan);

    return {
      value,
      title: value?.name,
      tooltip: compact([value?.name, value?.description]).join('<br>'),
      icons: [PlanScaleEnum.iconData.getValue(value?.scale)],
    };
  }

  export function initializePlanQueries(page: number, searchText?: string, projectUuidEntity?: string): PlanQueryRequest {
    return {
      ...initializeQueries<PlanSummaryData>(),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
    };
  }

  export function mapToPagePlanRequestDTO(queries: PlanQueryRequest): PagePlanRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function mapFromImportElementRequestDTO(flowSummary: FlowSummaryForPlanResponseDTO): Partial<FlowSummaryData> {
    return {
      name: flowSummary.name,
      labelFamilyApplication: (flowSummary.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      labelFamilyProject: flowSummary.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyProject),
      modificationDate: DateUtils.toDateFormat(flowSummary.modificationDate),
      flowCustomStatus: flowSummary.customStatusFlow && CustomStatusLightData.mapFromApiValue(flowSummary.customStatusFlow),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flowSummary.typeFlow),
      uuidEntity: flowSummary.uuidEntity,
      frozen: flowSummary.alreadyImported,
    };
  }

  export function mapToImportSchedulerElementRequestDTO(
    planUuidEntity: string,
    projectUuidEntity: string,
    queries: ContributorSummaryData.QueryRequest,
    selectAllData: SelectAllData,
  ): ElementPlanCreateRequestDTOPageTeamRoleRequest {
    return {
      planUuidEntity,
      projectUuidEntity,
      multiSelectRequest:
        (selectAllData && {
          selectedUuids: !selectAllData.allSelected ? selectAllData.selectedUuids || [] : undefined,
          excludedUuids: selectAllData.allSelected ? selectAllData.excludedUuids || [] : undefined,
          pageRequest: selectAllData.allSelected
            ? ContributorSummaryData.mapToPageRequestApiValue({
                queries: queries,
                userNatures: [UserNatureEnum.Normal, UserNatureEnum.Extern],
                projectUuidEntity,
              })
            : undefined,
        }) ||
        null,
    };
  }

  export function mapToImportSchedulerElementFromTabletRequestDTO(
    planUuidEntity: string,
    projectUuidEntity: string,
    queries: TabletSummaryData.QueryRequest,
    selectAllData: SelectAllData,
  ): ElementPlanCreateRequestDTOPageTeamRoleTabletRequestDTO {
    return {
      planUuidEntity,
      projectUuidEntity,
      multiSelectRequest:
        (selectAllData && {
          selectedUuids: !selectAllData.allSelected ? selectAllData.selectedUuids || [] : undefined,
          excludedUuids: selectAllData.allSelected ? selectAllData.excludedUuids || [] : undefined,
          pageRequest: selectAllData.allSelected ? TabletSummaryData.mapToPageRequestApiValue(queries, projectUuidEntity) : undefined,
        }) ||
        null,
    };
  }

  export function mapToImportTemporalElementRequestDTO(
    planUuidEntity: string,
    projectUuidEntity: string,
    queries: FlowSummaryData.QueryRequest,
    selectAllData: SelectAllData,
  ): ElementPlanCreateRequestDTOPageFlowRequest {
    return {
      planUuidEntity,
      projectUuidEntity,
      multiSelectRequest:
        (selectAllData && {
          selectedUuids: !selectAllData.allSelected ? selectAllData.selectedUuids || [] : undefined,
          excludedUuids: selectAllData.allSelected ? selectAllData.excludedUuids || [] : undefined,
          pageRequest: selectAllData.allSelected ? FlowSummaryData.mapToPageRequestApiValue(queries) : undefined,
        }) ||
        null,
    };
  }
}
