/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchedulingItemResponseDTO {
  uuidEntity?: string;
  name?: string;
  nature?: SchedulingItemResponseDTO.NatureEnum;
  dueDate?: Date;
  dueDateMin?: Date;
  dueDateMax?: Date;
  priority?: SchedulingItemResponseDTO.PriorityEnum;
  flowToCreateUuidEntity?: string;
  flowCreatedUuidEntity?: string;
  reminder?: string;
  runnable?: boolean;
  state?: SchedulingItemResponseDTO.StateEnum;
}
export namespace SchedulingItemResponseDTO {
  export type NatureEnum = 'REFERENTIAL' | 'REMINDER';
  export const NatureEnum = {
    REFERENTIAL: 'REFERENTIAL' as NatureEnum,
    REMINDER: 'REMINDER' as NatureEnum,
  };
  export type PriorityEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'HIGHEST';
  export const PriorityEnum = {
    LOW: 'LOW' as PriorityEnum,
    MEDIUM: 'MEDIUM' as PriorityEnum,
    HIGH: 'HIGH' as PriorityEnum,
    HIGHEST: 'HIGHEST' as PriorityEnum,
  };
  export type StateEnum = 'SCHEDULED' | 'EXPIRED' | 'EXECUTED';
  export const StateEnum = {
    SCHEDULED: 'SCHEDULED' as StateEnum,
    EXPIRED: 'EXPIRED' as StateEnum,
    EXECUTED: 'EXECUTED' as StateEnum,
  };
}
