import { DeviceMailingRequestDTO } from '../../generated/models/models';

export interface TabletSendEmailData {
  email: string;
  uuidEntity: string;
  comment?: string;
}

export namespace TabletSendEmailData {
  export function mapToRequestApiValue(data: TabletSendEmailData): DeviceMailingRequestDTO {
    return {
      email: data.email,
      comment: data.comment,
      uuidEntity: data.uuidEntity,
    };
  }
}
