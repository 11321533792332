import { createReducer, Action, on } from '@ngrx/store';
import { ShareHistoryData } from '../../../../../core/models';
import { MultiSelectItemData } from '../../../../shared/components/select-v2/utils/select-item-data';

import * as fromActions from './share-dialog.actions';

export interface ShareDialogState {
  loading: boolean;
  submitLoading: boolean;
  checkUserLoading: boolean;
  deleteLoading: boolean;
  checkedUser: MultiSelectItemData;
  history: ShareHistoryData[];
  resendLoading: boolean;
}

export const initialShareDialogState = (): ShareDialogState => ({
  loading: false,
  submitLoading: false,
  checkUserLoading: false,
  deleteLoading: false,
  checkedUser: undefined,
  history: [],
  resendLoading: false,
});

const reducer = createReducer<ShareDialogState>(
  initialShareDialogState(),
  on(fromActions.checkUser, state => ({ ...state, checkUserLoading: true })),
  on(fromActions.checkUserSuccess, (state, { email }) => ({
    ...state,
    checkUserLoading: false,
    checkedUser: { value: email, selected: true } as MultiSelectItemData<string>,
  })),
  on(fromActions.checkUserFail, state => ({ ...state, checkUserLoading: false, checkedUser: undefined })),
  on(fromActions.removeCheckedUser, state => ({ ...state, checkedUsers: undefined })),
  on(fromActions.loadShareHistory, state => ({ ...state, loading: true })),
  on(fromActions.loadShareHistoryFail, state => ({ ...state, loading: false })),
  on(fromActions.loadShareHistorySuccess, (state, { history }) => ({ ...state, loading: false, history })),
  on(fromActions.deleteSharedLink, state => ({ ...state, deleteLoading: true })),
  on(fromActions.deleteSharedLinkSuccess, (state, { uuidEntity }) => ({ ...state, deleteLoading: false, history: state.history.filter(item => item.uuidEntity !== uuidEntity) })),
  on(fromActions.deleteSharedLinkFail, state => ({ ...state, deleteLoading: false })),
  on(fromActions.stopLoadingButton, state => ({ ...state, deleteLoading: false })),
  on(fromActions.resendSharedLink, state => ({ ...state, resendLoading: true })),
  on(fromActions.resendSharedLinkSuccess, fromActions.resendSharedLinkFail, state => ({ ...state, resendLoading: false })),
  on(fromActions.shareLink, state => ({ ...state, submitLoading: true })),
  on(fromActions.shareLinkSuccess, fromActions.shareLinkFail, state => ({ ...state, submitLoading: false })),
);

export function shareDialogReducer(state: ShareDialogState | undefined, action: Action): ShareDialogState {
  return reducer(state, action);
}
