import { on, ReducerTypes, ActionCreator } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { BlockFormResponseSummaryData, LazyLoadResponse } from '../../../../../../core/models';
import { FlowContentDialogState } from '../../state/flow-content-dialog.reducer';

import { loadOrphanResponses, loadOrphanResponsesFail, loadOrphanResponsesSuccess } from './orphan-responses.actions';

const adapter: EntityAdapter<BlockFormResponseSummaryData> = createEntityAdapter<BlockFormResponseSummaryData>({
  selectId: response => response.uuidEntity,
});

export interface OrphanResponsesState {
  responses: LazyLoadResponse<BlockFormResponseSummaryData[]>;
  loading: boolean;
  error: string;
  reset: boolean;
}

export const initialOrphanResponsesState = (): OrphanResponsesState => ({
  responses: {} as LazyLoadResponse<BlockFormResponseSummaryData[]>,
  loading: false,
  error: null,
  reset: true,
});

export const orphanResponsesOns: ReducerTypes<FlowContentDialogState, ActionCreator[]>[] = [
  on(loadOrphanResponses, (state): FlowContentDialogState => ({ ...state, orphanResponses: { ...state.orphanResponses, loading: true } })),
  on(loadOrphanResponsesSuccess, loadOrphanResponsesFail, (state): FlowContentDialogState => ({ ...state, orphanResponses: { ...state.orphanResponses, loading: false } })),
  on(
    loadOrphanResponsesSuccess,
    (state, { responses, reset }): FlowContentDialogState => ({
      ...state,
      orphanResponses: {
        ...state.orphanResponses,
        responses: reset ? responses : { ...state.orphanResponses.responses, payload: [...state.orphanResponses.responses.payload, ...responses.payload] },
        reset,
      },
    }),
  ),
];
export const allOrphanResponses = adapter.getSelectors().selectAll;
