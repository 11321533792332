import { LabelFamilyHierarchyResponseDTO } from '../../generated/models/labelFamilyHierarchyResponseDTO';

export interface LabelFamilyHierarchyData {
  uuidEntity: string;
  name: string;
}

export namespace LabelFamilyHierarchyData {
  export function mapFromApiValue(labelFamily: LabelFamilyHierarchyResponseDTO): LabelFamilyHierarchyData {
    return { uuidEntity: labelFamily.uuidEntity, name: labelFamily.name };
  }
}
