import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import { FormEntityEnum } from '../../../../core/enums';

import { RubricDetailsData, QuestionDetailsData, ApplicationFileLightData, OptionDetailsData, VersionRubricOptionResponseData } from '../../../../core/models';

import * as fromActions from './form-dialog.actions';

export interface RubricDialogState {
  rubricDetails: RubricDetailsData;
  initialRubricDetails: RubricDetailsData;
  loading: boolean;
}

export interface OptionDialogState {
  optionDetails: OptionDetailsData;
  loading: boolean;
}

export interface QuestionDialogState {
  questionDetails: QuestionDetailsData;
  initialQuestionDetails: QuestionDetailsData;
  uploadImageLoading: boolean;
  loading: boolean;
  questionChangesHistory: VersionRubricOptionResponseData[];
}

export interface ImagesDialogState {
  images: ApplicationFileLightData[];
  entity: FormEntityEnum;
}

export interface AnnotateDialogState {
  localMode: boolean;
  entity: FormEntityEnum;
  loading: boolean;
}

export interface ViewDialogState {
  localMode: boolean;
}

export interface FormDialogState {
  rubricDialogState: RubricDialogState;
  imagesDialogState: ImagesDialogState;
  questionDialogState: QuestionDialogState;
  optionDialogState: OptionDialogState;
  annotateDialogState: AnnotateDialogState;
  viewDialogState: ViewDialogState;
}

export const initialRubricDialogState = (): RubricDialogState => ({
  rubricDetails: {} as RubricDetailsData,
  initialRubricDetails: {} as RubricDetailsData,
  loading: false,
});

export const initialOptionDialogState = (): OptionDialogState => ({
  optionDetails: { validationData: {}, alertData: {}, redirectionData: {} } as OptionDetailsData,
  loading: false,
});

export const initialQuestionDialogState = (): QuestionDialogState => ({
  questionDetails: {} as QuestionDetailsData,
  initialQuestionDetails: {} as QuestionDetailsData,
  uploadImageLoading: false,
  loading: false,
  questionChangesHistory: [],
});

export const initialImagesDialogState = (): ImagesDialogState => ({
  images: [],
  entity: undefined,
});

export const initialAnnotateDialogState = (): AnnotateDialogState => ({
  localMode: undefined,
  entity: undefined,
  loading: false,
});

export const initialViewDialogState = (): ViewDialogState => ({
  localMode: undefined,
});

export const initialFormDialogState = (): FormDialogState => ({
  rubricDialogState: initialRubricDialogState(),
  imagesDialogState: initialImagesDialogState(),
  questionDialogState: initialQuestionDialogState(),
  optionDialogState: initialOptionDialogState(),
  annotateDialogState: initialAnnotateDialogState(),
  viewDialogState: initialViewDialogState(),
});

const reducer = createReducer<FormDialogState>(
  initialFormDialogState(),
  /* Question state */
  on(
    fromActions.loadQuestionDetailsSuccess,
    (state, { questionDetails }): FormDialogState => ({
      ...state,
      questionDialogState: { ...state.questionDialogState, questionDetails, initialQuestionDetails: cloneDeep(questionDetails) },
    }),
  ),
  on(
    fromActions.updateOptionFail,
    (state): FormDialogState => ({
      ...state,
      optionDialogState: {
        ...state.optionDialogState,
        optionDetails: { ...state.optionDialogState.optionDetails, exportableValue: null },
      },
    }),
  ),
  on(
    fromActions.updateQuestionSuccess,
    (state, { questionDetails }): FormDialogState => ({
      ...state,
      questionDialogState: { ...state.questionDialogState, initialQuestionDetails: cloneDeep(questionDetails) },
    }),
  ),
  on(fromActions.setQuestionLoading, (state, { loading }): FormDialogState => ({ ...state, questionDialogState: { ...state.questionDialogState, loading } })),
  /* Rubric state */
  on(fromActions.loadRubricDetails, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: true } })),
  on(fromActions.loadRubricDetailsFail, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: false } })),
  on(
    fromActions.loadRubricDetailsSuccess,
    fromActions.updateRubricSuccess,
    (state, { rubricDetails }): FormDialogState => ({
      ...state,
      rubricDialogState: { ...state.rubricDialogState, loading: false, rubricDetails, initialRubricDetails: cloneDeep(rubricDetails) },
    }),
  ),
  on(fromActions.createRubric, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: true } })),
  on(fromActions.createRubricSuccess, fromActions.createRubricFail, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: false } })),
  on(fromActions.moveRubric, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: true } })),
  on(fromActions.moveRubricFail, fromActions.moveRubricSuccess, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: false } })),
  on(fromActions.duplicateRubric, (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: true } })),
  on(
    fromActions.duplicateRubricSuccess,
    fromActions.duplicateRubricFail,
    (state): FormDialogState => ({ ...state, rubricDialogState: { ...state.rubricDialogState, loading: false } }),
  ),
  /* Images state */
  on(fromActions.loadImagesSuccess, (state, { images }): FormDialogState => ({ ...state, imagesDialogState: { ...state.imagesDialogState, images } })),
  on(fromActions.setImagesEntity, (state, { entity }): FormDialogState => ({ ...state, imagesDialogState: { ...state.imagesDialogState, entity } })),
  /* Option state */
  on(fromActions.loadOptionDetails, (state): FormDialogState => ({ ...state, optionDialogState: { ...state.optionDialogState, loading: true } })),
  on(fromActions.resetOptionState, (state): FormDialogState => ({ ...state, optionDialogState: initialOptionDialogState() })),
  on(fromActions.loadOptionDetailsFail, (state): FormDialogState => ({ ...state, optionDialogState: { ...state.optionDialogState, loading: false } })),
  on(
    fromActions.loadOptionDetailsSuccess,
    (state, { optionDetails }): FormDialogState => ({
      ...state,
      optionDialogState: {
        ...state.optionDialogState,
        loading: false,
        optionDetails: cloneDeep(optionDetails),
      },
    }),
  ),
  on(fromActions.setAnnotateImageData, (state, { localMode, entity }): FormDialogState => ({ ...state, annotateDialogState: { ...state.annotateDialogState, localMode, entity } })),
  on(fromActions.setViewImageData, (state, { localMode }): FormDialogState => ({ ...state, viewDialogState: { ...state.viewDialogState, localMode } })),
  on(fromActions.updateImage, (state): FormDialogState => ({ ...state, annotateDialogState: { ...state.annotateDialogState, loading: true } })),
  on(fromActions.updateImageSuccess, fromActions.updateImageFail, (state): FormDialogState => ({ ...state, annotateDialogState: { ...state.annotateDialogState, loading: true } })),
  on(
    fromActions.loadQuestionChangesHistorySuccess,
    (state, { questionChangesHistory }): FormDialogState => ({ ...state, questionDialogState: { ...state.questionDialogState, questionChangesHistory } }),
  ),
  on(
    fromActions.removeImage,
    (state, { imageUuidEntity }): FormDialogState => ({
      ...state,
      imagesDialogState: {
        ...state.imagesDialogState,
        images: state.imagesDialogState?.images?.filter(image => image?.uuidEntity !== imageUuidEntity),
      },
    }),
  ),
);

export function formDialogReducer(state: FormDialogState | undefined, action: Action): FormDialogState {
  return reducer(state, action);
}
