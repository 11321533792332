import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FormUpdateCustomStatusRequestDTO } from '../../generated/models/formUpdateCustomStatusRequestDTO';

export interface BlockFormResponseStatusForUpdate {
  uuidEntity: string;
  customStatus: CustomStatusLightData;
  formFamilyUuidEntity: string;
  projectUuidEntity: string;
  formUuidEntity: string;
  comment?: string;
}

export namespace BlockFormResponseStatusForUpdate {
  export function mapToUpdateRequestApiValue(response: BlockFormResponseStatusForUpdate): FormUpdateCustomStatusRequestDTO {
    return {
      formUuidEntity: response.uuidEntity,
      customStatusUuidEntity: response.customStatus?.uuidEntity,
      // formBusinessLinkUuidEntity: response.formUuidEntity, @wissem @amal
      comment: response.comment,
    };
  }
}
