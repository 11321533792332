import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum OnboardingStepEnum {
  InfoStep = 1,
  SignatureStep = 2,
  PasswordStep = 3,
  FinishStep = 4,
}

export namespace OnboardingStepEnum {
  const values = [OnboardingStepEnum.InfoStep, OnboardingStepEnum.SignatureStep, OnboardingStepEnum.PasswordStep, OnboardingStepEnum.FinishStep];

  const valuesWithSso = [OnboardingStepEnum.InfoStep, OnboardingStepEnum.SignatureStep, OnboardingStepEnum.FinishStep];

  export const color: SfxMap<OnboardingStepEnum, string> = new SfxMap<OnboardingStepEnum, string>(
    [
      [OnboardingStepEnum.InfoStep, 'sfx-icon-secondary-warning'],
      [OnboardingStepEnum.SignatureStep, 'step-icon-light-pink'],
      [OnboardingStepEnum.PasswordStep, 'sfx-chip-purple'],
      [OnboardingStepEnum.FinishStep, 'sfx-chip-blue-clair'],
    ],
    'sfx-chip-blue-clair',
  );

  export const toString: SfxMap<OnboardingStepEnum, string> = new SfxMap<OnboardingStepEnum, string>([
    [OnboardingStepEnum.InfoStep, 'onboarding.info'],
    [OnboardingStepEnum.SignatureStep, 'onboarding.signature'],
    [OnboardingStepEnum.PasswordStep, 'onboarding.password'],
    [OnboardingStepEnum.FinishStep, 'onboarding.finish'],
  ]);

  export const nextStep: SfxMap<OnboardingStepEnum, OnboardingStepEnum> = new SfxMap<OnboardingStepEnum, OnboardingStepEnum>([
    [OnboardingStepEnum.InfoStep, OnboardingStepEnum.SignatureStep],
    [OnboardingStepEnum.SignatureStep, OnboardingStepEnum.PasswordStep],
    [OnboardingStepEnum.PasswordStep, OnboardingStepEnum.FinishStep],
  ]);

  export const nextStepWithSso: SfxMap<OnboardingStepEnum, OnboardingStepEnum> = new SfxMap<OnboardingStepEnum, OnboardingStepEnum>([
    [OnboardingStepEnum.InfoStep, OnboardingStepEnum.SignatureStep],
    [OnboardingStepEnum.SignatureStep, OnboardingStepEnum.FinishStep],
  ]);

  export const previousStep: SfxMap<OnboardingStepEnum, OnboardingStepEnum> = new SfxMap<OnboardingStepEnum, OnboardingStepEnum>([
    [OnboardingStepEnum.SignatureStep, OnboardingStepEnum.InfoStep],
    [OnboardingStepEnum.PasswordStep, OnboardingStepEnum.SignatureStep],
    [OnboardingStepEnum.FinishStep, OnboardingStepEnum.PasswordStep],
  ]);

  export const previousStepWithSso: SfxMap<OnboardingStepEnum, OnboardingStepEnum> = new SfxMap<OnboardingStepEnum, OnboardingStepEnum>([
    [OnboardingStepEnum.SignatureStep, OnboardingStepEnum.InfoStep],
    [OnboardingStepEnum.FinishStep, OnboardingStepEnum.SignatureStep],
  ]);

  export const selectItems: SingleSelectItemData<OnboardingStepEnum>[] = values.map(step => ({ value: step, title: toString.getValue(step), color: color.getValue(step) }));

  export const selectItemsWithSso: SingleSelectItemData<OnboardingStepEnum>[] = valuesWithSso.map(step => ({
    value: step,
    title: toString.getValue(step),
    color: color.getValue(step),
  }));
}
