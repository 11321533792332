/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DocumentFlowReorganizeCategoryRequestDTO {
  documentFlowUuidEntityList: Array<string>;
  categoryType: DocumentFlowReorganizeCategoryRequestDTO.CategoryTypeEnum;
  labelFamilyUuidEntity: string;
}
export namespace DocumentFlowReorganizeCategoryRequestDTO {
  export type CategoryTypeEnum = 'PRINCIPAL' | 'SECONDARY';
  export const CategoryTypeEnum = {
    PRINCIPAL: 'PRINCIPAL' as CategoryTypeEnum,
    SECONDARY: 'SECONDARY' as CategoryTypeEnum,
  };
}
