import { createAction, props } from '@ngrx/store';

import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { ErrorResponseData, LibraryTagDetailsData } from '../../../../../core/models';
import { OperatorErrorEnum } from '../../../../../core/enums';
import { TagLinkData } from '../../../../../core/models/library-tag/tag-link-data';

export const addTag = createAction('[LTagDialog] add new Tag', props<{ tag: LibraryTagDetailsData; addNewOne?: boolean }>());
export const addTagSuccess = createAction('[LTagDialog] add new Tag success', props<{ tag: LibraryTagDetailsData; addNewOne?: boolean }>());
export const addTagFail = createAction('[LTagDialog] add new Tag fail', props<{ error: ErrorResponseData<OperatorErrorEnum> }>());

export const updateTag = createAction('[LTagDialog] update Tag', props<{ tag: LibraryTagDetailsData; closeModal?: boolean }>());
export const updateTagSuccess = createAction('[LTagDialog] update Tag success', props<{ tag: LibraryTagDetailsData; closeModal?: boolean; parentTagUuidEntity?: string }>());
export const updateTagFail = createAction('[LTagDialog] update Tag fail');

export const getTagDetailsSuccess = createAction('[LTagDialog] get Tag details success', props<{ tag: LibraryTagDetailsData }>());
export const getTagDetailsFail = createAction('[LTagDialog] get Tag details fail', props<DialogToasterData>());

export const navigateThroughTags = createAction('[LTagDialog] navigate through Tags', props<{ tag: LibraryTagDetailsData; step: number }>());
export const confirmNavigateThroughTags = createAction('[LTagDialog] confirm navigate through Tags', props<{ tag: LibraryTagDetailsData; step: number }>());

export const loadLinkedTagListSuccess = createAction('[LTagDialog] Load tag linked list success', props<{ linkedFlows: TagLinkData[] }>());
export const loadLinkedTagListFail = createAction('[LTagDialog] Load tag linked list fail', props<DialogToasterData>());

export const gotoLinkData = createAction('[LTagDialog] go to linked data', props<{ tagLink: TagLinkData }>());
