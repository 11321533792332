import { SfxMap } from '../../utils/enum-utils';

export enum PhotoSizeEnum {
  ExtraSmall = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  ExtraLarge = 5,
}

export namespace PhotoSizeEnum {
  export const values = [PhotoSizeEnum.ExtraSmall, PhotoSizeEnum.Small, PhotoSizeEnum.Medium, PhotoSizeEnum.Large, PhotoSizeEnum.ExtraLarge];

  export const convertToApiValue: SfxMap<PhotoSizeEnum, number> = new SfxMap<PhotoSizeEnum, number>([
    [PhotoSizeEnum.ExtraSmall, 60],
    [PhotoSizeEnum.Small, 90],
    [PhotoSizeEnum.Medium, 180],
    [PhotoSizeEnum.Large, 360],
    [PhotoSizeEnum.ExtraLarge, 490],
  ]);

  export const convertFromApiValue: SfxMap<number, PhotoSizeEnum> = new SfxMap<number, PhotoSizeEnum>([
    [60, PhotoSizeEnum.ExtraSmall],
    [90, PhotoSizeEnum.Small],
    [180, PhotoSizeEnum.Medium],
    [360, PhotoSizeEnum.Large],
    [490, PhotoSizeEnum.ExtraLarge],
  ]);

  export const toString: SfxMap<PhotoSizeEnum, string> = new SfxMap<PhotoSizeEnum, string>([
    [PhotoSizeEnum.ExtraSmall, 'XS'],
    [PhotoSizeEnum.Small, 'S'],
    [PhotoSizeEnum.Medium, 'M'],
    [PhotoSizeEnum.Large, 'L'],
    [PhotoSizeEnum.ExtraLarge, 'XL'],
  ]);

  export const toRem: SfxMap<PhotoSizeEnum, string> = new SfxMap<PhotoSizeEnum, string>([
    [PhotoSizeEnum.ExtraSmall, '0.375rem'],
    [PhotoSizeEnum.Small, '0.625rem'],
    [PhotoSizeEnum.Medium, '1rem'],
    [PhotoSizeEnum.Large, '1.25rem'],
    [PhotoSizeEnum.ExtraLarge, '1.5rem'],
  ]);
}
