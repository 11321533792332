import { Observable, of } from 'rxjs';
import { SfxMap } from '../../utils/enum-utils';

import { PageTeamRoleRequest } from '../../generated/models/models';
import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum UserNatureEnum {
  Normal = 1,
  Extern = 2,
  Device = 3,
  DeviceExtern = 4,
  ExternalApiAccess = 5,
  Operator = 6,
  ExternalContributor = 7,
}

export namespace UserNatureEnum {
  export const teamRoleTypes = [UserNatureEnum.Normal, UserNatureEnum.Extern];
  export const tabletTypes = [UserNatureEnum.Device, UserNatureEnum.DeviceExtern];
  export const mixedTypes = [UserNatureEnum.Normal, UserNatureEnum.Extern, UserNatureEnum.Device, UserNatureEnum.DeviceExtern];

  export const convertFromApiValue: SfxMap<string, UserNatureEnum> = new SfxMap<string, UserNatureEnum>([
    [PageTeamRoleRequest.UserNaturesEnum.NORMAL, UserNatureEnum.Normal],
    [PageTeamRoleRequest.UserNaturesEnum.EXTERN, UserNatureEnum.Extern],
    [PageTeamRoleRequest.UserNaturesEnum.DEVICE, UserNatureEnum.Device],
    [PageTeamRoleRequest.UserNaturesEnum.DEVICEEXT, UserNatureEnum.DeviceExtern],
    [PageTeamRoleRequest.UserNaturesEnum.EXTERNALCONTRIBUTOR, UserNatureEnum.ExternalContributor],
  ]);

  export const convertToApiValue: SfxMap<UserNatureEnum, PageTeamRoleRequest.UserNaturesEnum> = new SfxMap<UserNatureEnum, PageTeamRoleRequest.UserNaturesEnum>([
    [UserNatureEnum.Normal, PageTeamRoleRequest.UserNaturesEnum.NORMAL],
    [UserNatureEnum.Extern, PageTeamRoleRequest.UserNaturesEnum.EXTERN],
    [UserNatureEnum.Device, PageTeamRoleRequest.UserNaturesEnum.DEVICE],
    [UserNatureEnum.DeviceExtern, PageTeamRoleRequest.UserNaturesEnum.DEVICEEXT],
    [UserNatureEnum.ExternalApiAccess, PageTeamRoleRequest.UserNaturesEnum.EXTERNALAPIACCESS],
  ]);

  export const convertFromParamsValue: SfxMap<string, UserNatureEnum> = new SfxMap<string, UserNatureEnum>([
    ['normal', UserNatureEnum.Normal],
    ['extern', UserNatureEnum.Extern],
    ['device', UserNatureEnum.Device],
    ['deviceExtern', UserNatureEnum.DeviceExtern],
    ['externalApiAccess', UserNatureEnum.ExternalApiAccess],
  ]);

  export const convertToParamsValue: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>([
    [UserNatureEnum.Normal, 'normal'],
    [UserNatureEnum.Extern, 'extern'],
    [UserNatureEnum.Device, 'device'],
    [UserNatureEnum.DeviceExtern, 'deviceExtern'],
    [UserNatureEnum.ExternalApiAccess, 'externalApiAccess'],
  ]);

  export const sharePasswordLabel: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>([
    [UserNatureEnum.Normal, 'share.password'],
    [UserNatureEnum.ExternalContributor, 'share.code'],
  ]);

  const key = 'tablets.filters.';
  export const toString: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>([
    [UserNatureEnum.Device, key + 'internal'],
    [UserNatureEnum.DeviceExtern, key + 'external'],
  ]);

  export const iconDataForTablet: SfxMap<UserNatureEnum, IconData> = new SfxMap<UserNatureEnum, IconData>([
    [UserNatureEnum.Device, { name: 'home', prefix: 'fas' }],
    [UserNatureEnum.DeviceExtern, { name: 'caravan', prefix: 'fas' }],
  ]);

  export const iconData: SfxMap<UserNatureEnum, IconData> = new SfxMap<UserNatureEnum, IconData>([
    [UserNatureEnum.Normal, { name: 'user' }],
    [UserNatureEnum.Extern, { name: 'user' }],
    [UserNatureEnum.Operator, { name: 'user-astronaut' }],
    [UserNatureEnum.Device, { name: 'tablet-alt' }],
    [UserNatureEnum.DeviceExtern, { name: 'tablet-alt' }],
  ]);

  export const iconClass: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>([
    [UserNatureEnum.Device, 'sfx-chip-primary'],
    [UserNatureEnum.Normal, 'sfx-chip-primary'],
    [UserNatureEnum.Operator, 'sfx-chip-operator'],
    [UserNatureEnum.DeviceExtern, 'sfx-chip-operator'],
    [UserNatureEnum.Extern, 'sfx-chip-operator'],
  ]);

  const tooltipKey = 'global.users.type.';

  export const tooltip: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>([
    [UserNatureEnum.Normal, tooltipKey + 'normal'],
    [UserNatureEnum.Extern, tooltipKey + 'external'],
    [UserNatureEnum.Device, tooltipKey + 'device'],
    [UserNatureEnum.DeviceExtern, tooltipKey + 'deviceExt'],
    [UserNatureEnum.Operator, tooltipKey + 'operator'],
  ]);

  export const entityClass: SfxMap<UserNatureEnum, string> = new SfxMap<UserNatureEnum, string>(
    [
      [UserNatureEnum.Operator, 'orange'],
      [UserNatureEnum.DeviceExtern, 'orange'],
      [UserNatureEnum.Extern, 'orange'],
    ],
    'default',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<UserNatureEnum>[]> => of(tabletTypes.map(nature => ({ value: nature, title: toString.getValue(nature) })));

  export const comparePredicate = (node: SingleSelectItemData<UserNatureEnum>, item: UserNatureEnum) => node.value === item;
}
