/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomFieldExtraLightDTO } from './customFieldExtraLightDTO';
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { TagLightDTO } from './tagLightDTO';
import { UserLightDTO } from './userLightDTO';

export interface FlowSummaryResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  description: string;
  typeFlow: FlowSummaryResponseDTO.TypeFlowEnum;
  labelFamilyApplication: LabelFamilyLightDTO;
  labelFamilyProject: LabelFamilyLightDTO;
  customStatusFlow: CustomStatusLightDTO;
  unlocked: boolean;
  flowReferentialLocal: boolean;
  modificationDate: Date;
  reporterUser: UserLightDTO;
  assigneeUser: UserLightDTO;
  actor1: UserLightDTO;
  actor2: UserLightDTO;
  actor3: UserLightDTO;
  functionMarker: string;
  locationLocal: string;
  refExt: string;
  refInt: string;
  startDate: Date;
  finishDate: Date;
  lastUpdateFromSource: Date;
  flowBarColor: string;
  flowBarOrder: number;
  availableUpdate: boolean;
  supervisionIntern1: boolean;
  supervisionIntern2: boolean;
  supervisionIntern3: boolean;
  internalSupervisorName1: string;
  internalSupervisorName2: string;
  internalSupervisorName3: string;
  supervision1: boolean;
  supervision2: boolean;
  supervision3: boolean;
  externalSupervisorName1: string;
  externalSupervisorName2: string;
  externalSupervisorName3: string;
  tags: Array<TagLightDTO>;
  pgacDateChanged: boolean;
  hasFlowSource: boolean;
  collaborators: Array<UserLightDTO>;
  modificationStatus: FlowSummaryResponseDTO.ModificationStatusEnum;
  achievedEndDate?: Date;
  achievedStartDate?: Date;
  estimatedEndDate?: Date;
  estimatedStartDate?: Date;
  customFields?: Array<CustomFieldExtraLightDTO>;
  simplifiedSignatureMode?: boolean;
}
export namespace FlowSummaryResponseDTO {
  export type TypeFlowEnum =
    | 'GENERIC'
    | 'REFERENTIAL'
    | 'TEMPLATE'
    | 'SPECIFIC'
    | 'GROUP'
    | 'LIST_EMM'
    | 'LIST_ADR'
    | 'FLOW_FORM'
    | 'GROUP_REFERENTIAL'
    | 'LOCAL_REFERENTIAL'
    | 'REFERENTIAL_GENERIC'
    | 'REFERENTIAL_INTERVENTION';
  export const TypeFlowEnum = {
    GENERIC: 'GENERIC' as TypeFlowEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeFlowEnum,
    TEMPLATE: 'TEMPLATE' as TypeFlowEnum,
    SPECIFIC: 'SPECIFIC' as TypeFlowEnum,
    GROUP: 'GROUP' as TypeFlowEnum,
    LISTEMM: 'LIST_EMM' as TypeFlowEnum,
    LISTADR: 'LIST_ADR' as TypeFlowEnum,
    FLOWFORM: 'FLOW_FORM' as TypeFlowEnum,
    GROUPREFERENTIAL: 'GROUP_REFERENTIAL' as TypeFlowEnum,
    LOCALREFERENTIAL: 'LOCAL_REFERENTIAL' as TypeFlowEnum,
    REFERENTIALGENERIC: 'REFERENTIAL_GENERIC' as TypeFlowEnum,
    REFERENTIALINTERVENTION: 'REFERENTIAL_INTERVENTION' as TypeFlowEnum,
  };
  export type ModificationStatusEnum = 'DISABLED' | 'ENABLED' | 'WARNING';
  export const ModificationStatusEnum = {
    DISABLED: 'DISABLED' as ModificationStatusEnum,
    ENABLED: 'ENABLED' as ModificationStatusEnum,
    WARNING: 'WARNING' as ModificationStatusEnum,
  };
}
