import { ApiUrlsEnum, apiUrl } from './api-url';

export function apiUrlMatcher(urlName: ApiUrlsEnum, args?: { [param: string]: string }): string {
  let url = urlName || '';
  Object.keys(args || {}).forEach(param => (url = url.replace(/\s/g, '').replace(new RegExp(`{${param}}`, 'gi'), _ => args[param])));

  return (url && `${apiUrl}${url}`) || '';
}

export function externApiUrlMatcher(urlName: ApiUrlsEnum, externApiUrl: ApiUrlsEnum, args?: { [param: string]: string }): string {
  let url = urlName || '';
  Object.keys(args || {}).forEach(param => (url = url.replace(new RegExp(`{${param}}`, 'gi'), _ => args[param])));

  return (url && `${externApiUrl}${url}`) || '';
}
