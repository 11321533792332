import { createAction, props } from '@ngrx/store';
import { SectionDetailsData, FolderDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../../modules/sfx-dialog/state/dialog-toaster-data';

export const createFolder = createAction('[sFolder/API] create folder', props<{ folder: FolderDetailsData }>());
export const createFolderSuccess = createAction('[sFolder/API] create folder success');
export const createFolderFail = createAction('[sFolder/API] create folder fail', props<DialogToasterData>());

export const loadFolderDetailsDataSuccess = createAction('[sFolder/API] load folder data data success', props<{ folderDetails: FolderDetailsData }>());
export const loadFolderDetailsDataFail = createAction('[sFolder/API] load folder data data fail', props<DialogToasterData>());

export const updateFolder = createAction('[sFolder/API] update folder', props<{ folder: FolderDetailsData }>());
export const updateFolderSuccess = createAction('[sFolder/API] update folder success');
export const updateFolderFail = createAction('[sFolder/API] update folder fail', props<DialogToasterData>());

export const createSection = createAction('[sFolder/API] create section', props<{ section: SectionDetailsData; addNewOne?: boolean }>());
export const createSectionSuccess = createAction('[sFolder/API] create section success', props<{ section: SectionDetailsData; folderUuidEntity: string; addNewOne?: boolean }>());
export const createSectionFail = createAction('[sFolder/API] create section fail', props<DialogToasterData>());

export const loadSectionDetailsDataSuccess = createAction('[sFolder/API] load section data data success', props<{ sectionDetails: SectionDetailsData }>());
export const loadSectionDetailsDataFail = createAction('[sFolder/API] load section data data fail', props<DialogToasterData>());

export const updateSection = createAction('[sFolder/API] update section', props<{ section: SectionDetailsData; closeModal?: boolean }>());
export const updateSectionSuccess = createAction('[sFolder/API] update section success', props<{ section: SectionDetailsData; closeModal: boolean }>());
export const updateSectionFail = createAction('[sFolder/API] update section fail', props<DialogToasterData>());

export const sectionDetailNavigation = createAction('[sFolder/API] Navigate through details sections', props<{ sectionUuidEntity?: string; step: number }>());
export const confirmDetailNavigation = createAction('[sFolder/API] confirm navigate details references', props<{ section: SectionDetailsData; step: number }>());
