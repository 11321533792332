import { isNumber } from 'lodash';

import { PhaseCreateRequestDTO, PhaseDetailsResponseDTO, PhaseUpdateRequestDTO } from '../../generated/models/models';
import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { FlowMenuItemTypeEnum, StepSequenceTypeEnum } from '../../enums';
import { TagLightData } from '../library-tag/tag-light-data';

export interface PhaseDetailsData {
  name: string;
  customNodeOrder: string;
  nodeOrder: number;
  nodeOrderToDisplay: number;
  description: string;
  excludeNumbering?: boolean;
  hideTitlePdf: boolean;
  uuidEntity: string;
  stepCount: number;
  stepSequenceType: StepSequenceTypeEnum;
  tags?: TagLightData[];
  additionalComment?: string;
  mobileAppReadOnly: boolean;
  disabled: boolean;
}

export namespace PhaseDetailsData {
  export function mapToFlowMenuItemData(phase: PhaseDetailsData): FlowMenuItemData {
    return {
      title: phase.name,
      description: phase.description,
      customNodeOrder: phase.customNodeOrder,
      maskedInPdfFile: phase.hideTitlePdf,
      nodeOrder: phase.nodeOrder,
      type: FlowMenuItemTypeEnum.Phase,
      uuidEntity: phase.uuidEntity,
      sequence: { sequenceType: phase.stepSequenceType },
      additionalComment: phase.additionalComment,
      mobileAppReadOnly: phase.mobileAppReadOnly,
      disabled: phase.disabled,
    };
  }
  export function mapFromApiValue(phaseDetails: PhaseDetailsResponseDTO): PhaseDetailsData {
    return {
      name: phaseDetails.name,
      customNodeOrder: phaseDetails.customNodeOrder,
      nodeOrder: phaseDetails.nodeOrder,
      hideTitlePdf: phaseDetails.hideTitlePdf,
      description: phaseDetails.description,
      uuidEntity: phaseDetails.uuidEntity,
      stepCount: (phaseDetails.steps || []).length,
      stepSequenceType: StepSequenceTypeEnum.convertFromApiValue.getValue(phaseDetails.stepSequenceType),
      tags: (phaseDetails.tags || []).map(tag => TagLightData.mapFromApiValue(tag)),
      additionalComment: phaseDetails.additionalComment,
      mobileAppReadOnly: phaseDetails.readOnlyOnMobileApp,
      disabled: phaseDetails['disabled'],
      excludeNumbering: phaseDetails.excludeNumbering,
      nodeOrderToDisplay: phaseDetails.nodeOrderToDisplay,
    };
  }

  export function mapToCreateRequestApiValue(flowUuidEntity: string, projectUuidEntity: string, nodeOrder: string, phase: PhaseDetailsData): PhaseCreateRequestDTO {
    return {
      name: phase.name,
      description: phase.description,
      customNodeOrder: phase.customNodeOrder,
      hideTitlePdf: phase.hideTitlePdf,
      uuideFlow: flowUuidEntity,
      nodeOrder: (isNumber(+nodeOrder) && +nodeOrder) || undefined,
      uuideProject: projectUuidEntity,
      stepSequenceType: StepSequenceTypeEnum.convertToApiValue.getValue(phase.stepSequenceType),
      tagUuidEntities: (phase.tags || []).map(tag => tag.uuidEntity),
      additionalComment: phase.additionalComment,
      excludeNumbering: phase.excludeNumbering,
    };
  }

  export function mapToUpdateRequestApiValue(projectUuidEntity: string, phase: PhaseDetailsData): PhaseUpdateRequestDTO {
    return {
      name: phase.name,
      description: phase.description,
      customNodeOrder: phase.customNodeOrder,
      hideTitlePdf: phase.hideTitlePdf,
      uuide: phase.uuidEntity,
      uuideProject: projectUuidEntity,
      stepSequenceType: StepSequenceTypeEnum.convertToApiValue.getValue(phase.stepSequenceType),
      tagUuidEntities: (phase.tags || []).map(tag => tag['uuidEntity']),
      additionalComment: phase.additionalComment,
      excludeNumbering: phase.excludeNumbering,
    };
  }
}
