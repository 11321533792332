import { PlaningHelper } from '../../../modules/dashboard/flow-management/list/plan/utils/helpers';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { InnerSortDirectionEnum, PlanBusinessClassNameEnum, PlanColumnEnum, PlanDisplayFormatEnum } from '../../enums';
import { ElementPlanSummaryResponseDTO } from '../../generated/models/elementPlanSummaryResponseDTO';
import { ElementPlanLightSummaryResponseDTO } from '../../generated/models/models';
import { PageElementPlanRequestDTO } from '../../generated/models/pageElementPlanRequestDTO';
import { DateUtils } from '../../utils/date.utils';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { UserLightData } from '../user/user-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';
import { BaseElementPlanSummaryData } from './base-element-plan-summary-data';
import { GroupedByElementSummaryData } from './grouped-by-element-summary-data';
import { PlanningChartData } from './plan-chart-bar-data';

export interface TemporalElementPlanSummaryData extends BaseElementPlanSummaryData {
  businessClass: PlanBusinessClassNameEnum;
  parentUuidEntity: string;
  flowUuidEntity: string;
  startDate: string;
  endDate: string;
  tooltip?: string;
  childrenCount?: number;
  parent?: GroupedByElementSummaryData;
  reporter: UserLightData;
  assignee: UserLightData;
  tablet1: UserLightData;
  tablet2: UserLightData;
  tablet3: UserLightData;
  contributors: UserLightData[];
  customStatus: CustomStatusLightData;
}

export namespace TemporalElementPlanSummaryData {
  const sortFields = ['name', 'startDate', 'endDate'];
  export interface ElementPlanQueryRequest extends BaseQuery<TemporalElementPlanSummaryData> {
    planUuidEntity: string;
    currentElementPlanUuidEntity: string;
    categories?: string[];
    families?: string[];
    statuses?: string[];
    assignments?: string[];
    locals?: string[];
    landmarks?: string[];
  }
  export function mapFromApiValue(
    elementPlan: ElementPlanSummaryResponseDTO,
    selectedTime: string,
    displayFormat: PlanDisplayFormatEnum,
    businessClass: PlanBusinessClassNameEnum,
  ): TemporalElementPlanSummaryData {
    return {
      flowUuidEntity: elementPlan.flowUuidEntity,
      name: elementPlan?.name,
      refExt: elementPlan?.refExt,
      businessClass,
      nodeOrder: elementPlan.elementOrder,
      uuidEntity: elementPlan.uuidEntity,
      startDate: DateUtils.toCalendarFormat(elementPlan.startDate),
      endDate: DateUtils.toCalendarFormat(elementPlan.finishDate),
      parentUuidEntity: elementPlan.parentUuidEntity,
      planningData: [
        PlanningChartData.mapFromApiValue(
          {
            ...elementPlan,
            startDate: elementPlan.estimatedStartDate,
            finishDate: elementPlan.estimatedEndDate,
            frozen: true,
            uuidEntity: 'fake-estimated',
          },
          selectedTime && PlaningHelper.getValidDate(selectedTime),
          displayFormat,
        ),
        PlanningChartData.mapFromApiValue(
          {
            ...elementPlan,
            startDate: elementPlan.achievedStartDate,
            finishDate: elementPlan.achievedEndDate,
            frozen: true,
            uuidEntity: 'fake-achieved',
          },
          selectedTime && PlaningHelper.getValidDate(selectedTime),
          displayFormat,
        ),
        PlanningChartData.mapFromApiValue(elementPlan, selectedTime && PlaningHelper.getValidDate(selectedTime), displayFormat),
      ],
      reporter: elementPlan.reporter && UserLightData.mapFromApiValue(elementPlan.reporter),
      assignee: elementPlan.assignee && UserLightData.mapFromApiValue(elementPlan.assignee),
      tablet1: elementPlan.tablet1 && UserLightData.mapFromApiValue(elementPlan.tablet1),
      tablet2: elementPlan.tablet2 && UserLightData.mapFromApiValue(elementPlan.tablet2),
      tablet3: elementPlan.tablet3 && UserLightData.mapFromApiValue(elementPlan.tablet3),
      contributors: (elementPlan.collaborators ?? []).map(collaborator => UserLightData.mapFromApiValue(collaborator)),
      customStatus: elementPlan.customStatusLightResponseDTO && CustomStatusLightData.mapFromApiValue(elementPlan.customStatusLightResponseDTO),
    };
  }

  export function initializeElementPlanQueries(
    planUuidEntity: string,
    textSearch: string,
    time: string,
    statuses: string[],
    assignments: string[],
    otherFilters: { categories: string[]; families: string[]; locals?: string[]; landmarks?: string[] },
    displayFormat: PlanDisplayFormatEnum,
    sort: SortFilter<TemporalElementPlanSummaryData>,
    currentElementPlanUuidEntity?: string,
  ): ElementPlanQueryRequest {
    return {
      ...initializeQueries<TemporalElementPlanSummaryData>(sort),
      textSearch: textSearch || undefined,
      planUuidEntity,
      currentElementPlanUuidEntity,
      categories: otherFilters.categories,
      families: otherFilters.families,
      statuses,
      assignments,
      locals: (otherFilters?.locals || []).length ? otherFilters?.locals : undefined,
      landmarks: (otherFilters?.landmarks || []).length ? otherFilters?.landmarks : undefined,
    };
  }

  export function initializeElementPlanLightQueries(
    page: number,
    textSearch: string,
    planUuidEntity: string,
    currentElementPlanUuidEntity: string,
  ): Partial<ElementPlanQueryRequest> {
    return {
      ...initializeQueries<TemporalElementPlanSummaryData>(null),
      page,
      textSearch: textSearch || undefined,
      planUuidEntity,
      currentElementPlanUuidEntity,
    };
  }

  export function mapToPageRequestDto(request: Partial<ElementPlanQueryRequest>, parentRow?: GroupedByElementSummaryData, isDefault?: boolean): PageElementPlanRequestDTO {
    const sortKey = PlanColumnEnum.convertSortToApi(request?.sort?.key, isDefault);

    return {
      page: request.page,
      size: request.size,
      planUuidEntity: request.planUuidEntity,
      currentElementPlanUuidEntity: request.currentElementPlanUuidEntity,
      textSearch: request.textSearch || undefined,
      sort: sortFields.includes(request?.sort?.key)
        ? { attribute: sortKey, direction: InnerSortDirectionEnum.convertToApiValue.getValue(request.sort.direction || InnerSortDirectionEnum.Asc) }
        : undefined,
      categories: parentRow && parentRow.businessClass === PlanBusinessClassNameEnum.Category ? [parentRow.uuidEntity] : request.categories || undefined,
      families: parentRow && parentRow.businessClass === PlanBusinessClassNameEnum.Family ? [parentRow.uuidEntity] : request.families || undefined,
      customStatuses: parentRow && parentRow.businessClass === PlanBusinessClassNameEnum.Status ? [parentRow.uuidEntity] : request.statuses || undefined,
      assignments:
        parentRow && [PlanBusinessClassNameEnum.Responsible, PlanBusinessClassNameEnum.Reporter, PlanBusinessClassNameEnum.Tablet].includes(parentRow.businessClass)
          ? [parentRow.uuidEntity]
          : request.assignments || undefined,
      groupedByBusinessClassEnum: (parentRow && PlanBusinessClassNameEnum.convertToApiValue.getValue(parentRow.businessClass)) || undefined,
      locals: request.locals || undefined,
      reperes: request.landmarks || undefined,
    };
  }

  export function mapToSelectItemData(elementPlan: ElementPlanLightSummaryResponseDTO): SingleSelectItemData {
    return {
      title: elementPlan.name,
      value: { uuidEntity: elementPlan.uuidEntity, name: elementPlan.name },
    };
  }
}
