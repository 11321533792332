import { compact, difference } from 'lodash';

import {
  PageStepRequestDTO,
  PagePhaseRequestDTO,
  PhaseSummaryResponseDTO,
  StepSignatoryResponseDTO,
  SignatorySummaryResponseDTO,
  SignatoryFilterDTO,
} from '../../generated/models/models';
import { initializeQueries, BaseQuery } from '../utils/base-query.model';

import { ElementPrSignatoryLightDTO } from '../../generated/models/elementPrSignatoryLightDTO';
import { SignatureStatusEnum, SignatureNatureEnum, SignatureTypeEnum, InnerSortDirectionEnum, BlockSignaturesStatusEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';
import { SignatureMethodEnum } from '../../enums/signature/signature-method.enum';
import { DateFilterData } from '../utils/date-filter-data';
import { SignatureSourceEnum } from '../../enums/signature/signature-source.enum';

export interface SignatureSummaryData {
  uuidEntity: string;
  signatoryName: string;
  signatoryTrigram: string;
  comment: string;
  signatureDate: string;
  disabled: boolean;
  proxied?: boolean;
  signatoryType: SignatureTypeEnum;
  signatureNature: SignatureNatureEnum;
  signatureState: SignatureStatusEnum;
  signatureSource?: SignatureSourceEnum;
  signatorySupervisor?: string;
  signWithQrCode?: boolean;
  proxyName?: string;
}

export namespace SignatureSummaryData {
  const sortFields = ['nodeOrder', 'name'];
  export interface QueryRequest extends BaseQuery<PhaseSummaryResponseDTO | StepSignatoryResponseDTO> {
    projectUuidEntity: string;
    signatureNature?: SignatureNatureEnum[];
    signatureStatus?: SignatureStatusEnum[];
    signatureSource?: SignatureSourceEnum[];
    signatureType?: BlockSignaturesStatusEnum[];
    signatureMethod?: SignatureMethodEnum[];
    signatureContributors?: SignatureTypeEnum[];
    dateFilter?: DateFilterData;
  }

  export function mapFromApiValue(signature: SignatorySummaryResponseDTO): SignatureSummaryData {
    const lastName = signature?.lastName ? '.' + signature.lastName.toLocaleUpperCase() : '';

    return {
      uuidEntity: signature.uuidEntity,
      signatoryName: signature.proxied ? signature.firstName : signature?.firstName?.length && signature.firstName[0].toLocaleUpperCase() + lastName,
      signatoryTrigram: signature.proxied
        ? ''
        : ((signature.firstName && signature.firstName[0] + (signature.lastName && signature.lastName.substring(0, 2))) || '').toLocaleUpperCase(),
      disabled: signature.disabled,
      comment: signature.commentSignature,
      signatureDate: DateUtils.toSensitiveTimeFormat(signature.signatureDate),
      signatoryType: SignatureTypeEnum.convertFromApiValue.getValue(signature.typeSignatory),
      signatureNature: SignatureNatureEnum.convertFromApiValue.getValue(signature.signatureNature),
      signatureState: SignatureStatusEnum.convertFromApiValue.getValue(signature.signatureState),
      signatureSource: SignatureSourceEnum.convertFromAPiValue.getValue(signature.source),
      signWithQrCode: signature.signWithQrCode,
      proxied: signature.proxied,
      proxyName: signature.proxied ? signature.firstName : undefined,
    };
  }

  export function mapFromElementPrApiValue(signature: ElementPrSignatoryLightDTO): SignatureSummaryData {
    return {
      uuidEntity: signature.uuidEntity,
      signatoryName: compact([signature.user?.firstName, signature?.user?.lastName]).join(' '),
      signatorySupervisor: null,
      signatoryTrigram: signature.user?.trigram?.toLocaleUpperCase(),
      disabled: null,
      comment: null,
      signatureDate: DateUtils.toSensitiveTimeFormat(signature.date),
      signatoryType: null,
      signatureNature: null,
      signatureState: null,
    };
  }

  function mapFiltersFromQueries(queries: QueryRequest): SignatoryFilterDTO {
    const queriesSignMethods = queries.signatureContributors ?? [];
    const signWithQrCode = difference(
      SignatureMethodEnum.authMethod,
      queries.signatureMethod?.map(value => value),
    );

    if (queries.signatureMethod?.includes(SignatureMethodEnum.NotListed)) {
      queriesSignMethods.push(SignatureTypeEnum.NotListed);
    }

    return {
      signatureNatures: queries.signatureNature?.map(nature => SignatureNatureEnum.convertToApiValue.getValue(nature)) || undefined,
      signatureState: queries.signatureStatus?.map(status => SignatureStatusEnum.convertToApiValue.getValue(status)) || undefined,
      signatoriesStatus: queries.signatureType?.map(option => BlockSignaturesStatusEnum.convertToApiValue.getValue(option)) || undefined,
      typeSignatory: (queriesSignMethods.length && queriesSignMethods.map(contributor => SignatureTypeEnum.convertToApiValue.getValue(contributor))) || undefined,
      signWithQrCode: !signWithQrCode.length
        ? null
        : signWithQrCode.length === SignatureMethodEnum.authMethod.length
        ? undefined
        : queries.signatureMethod?.some(value => value === SignatureMethodEnum.Strong),
      proxied: queries.signatureMethod?.includes(SignatureMethodEnum.Proxied) || undefined,
      dateFilterRequest: (queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter)) || undefined,
    };
  }

  export function mapToPagePhaseRequestApiValue(queries: QueryRequest): PagePhaseRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
      projectUuidEntity: queries.projectUuidEntity,
      families: [],
      categories: [],
      signatoryRequestDTO: mapFiltersFromQueries(queries),
    };
  }

  export function mapToPageStepRequestApiValue(queries: QueryRequest): PageStepRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch,
      projectUuidEntity: queries.projectUuidEntity,
      signatoryRequest: mapFiltersFromQueries(queries),
    };
  }

  export function initializeQueryRequest(
    projectUuidEntity: string,
    searchText: string,
    filters: {
      types: string[];
      status: string[];
      options: string[];
      methods: string[];
      contributors: string[];
    },
    dateFilter: DateFilterData,
    sort: SortFilter<PhaseSummaryResponseDTO | StepSignatoryResponseDTO>,
  ): QueryRequest {
    return {
      ...initializeQueries<PhaseSummaryResponseDTO | StepSignatoryResponseDTO>(sort),
      textSearch: searchText || undefined,
      signatureNature: filters.types?.map(type => SignatureNatureEnum.convertFromParamsValue.getValue(type)),
      signatureStatus: filters.status?.map(status => SignatureStatusEnum.convertFromParamsValue.getValue(status)),
      signatureType: filters.options?.map(type => BlockSignaturesStatusEnum.convertFromParamsValue.getValue(type)),
      signatureMethod: filters.methods?.map(method => SignatureMethodEnum.convertFromParamsValue.getValue(method)),
      signatureContributors: filters.contributors?.map(contributor => SignatureTypeEnum.convertFromParamsValue.getValue(contributor)),
      dateFilter: dateFilter || undefined,
      projectUuidEntity,
    };
  }
}
