import { DateUtils } from '../../utils/date.utils';

import { ApplicationEventDTO, PageApplicationEventPgacRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { ApplicationEventActionEnum, InnerSortDirectionEnum } from '../../enums';

export interface CustomFileApplicationEventData {
  uuidEntity: string;
  userLabel: string;
  actionDate: string;
  message: string;
  actionType: ApplicationEventActionEnum;
  fileUuidEntity: string;
}
export namespace CustomFileApplicationEventData {
  const sortFields = ['natureAction', 'userLibelle', 'actionDate'];

  export type QueryRequest = BaseQuery<CustomFileApplicationEventData>;

  export function mapFromApiValue(applicationEvent: ApplicationEventDTO): CustomFileApplicationEventData {
    return {
      uuidEntity: applicationEvent.id + '', // BackendIssue: @Sajir replace it by uuidEntity.
      userLabel: applicationEvent.userLibelle,
      actionDate: DateUtils.toDateTimeFormat(applicationEvent.actionDate),
      message: applicationEvent.message,
      actionType: ApplicationEventActionEnum.convertFromApiValue.getValue(applicationEvent.natureAction),
      fileUuidEntity: applicationEvent.uuideFile,
    };
  }

  export function mapToQueriesRequestmapToPageRequestApiValue(queries: QueryRequest): PageApplicationEventPgacRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(sort: SortFilter<CustomFileApplicationEventData>): QueryRequest {
    return {
      ...initializeQueries<CustomFileApplicationEventData>(sort),
    };
  }
}
