import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { MobileDumpErrorDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { ISearchParams } from '../utils/http-params.type';

export interface MobileDumpErrorSummaryData {
  uuidEntity: string;
  createdBy: string;
  errorDate: string;
  dump: string;
  overridden?: string;
  errorType: string;
}

export namespace MobileDumpErrorSummaryData {
  export interface QueryRequest extends BaseQuery<MobileDumpErrorSummaryData> {
    upperLimit: Date;
    lowerLimit: Date;
  }

  export function mapFromApiValue(dumpError: MobileDumpErrorDTO): MobileDumpErrorSummaryData {
    return {
      uuidEntity: uuid(),
      createdBy: dumpError.createdBy,
      errorDate: dumpError.date && DateUtils.toDateTimeFormat(dumpError.date),
      dump: dumpError.dump,
      overridden: dumpError.overridden,
      errorType: dumpError.type,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): ISearchParams {
    let query: ISearchParams = {
      page: queries.page,
      size: queries.size,
    };
    if (queries.upperLimit) {
      query = { ...query, upperLimit: DateUtils.toZonedDateTime(dayjs(queries.upperLimit).toDate()) };
    }
    if (queries.lowerLimit) {
      query = { ...query, lowerLimit: DateUtils.toZonedDateTime(dayjs(queries.lowerLimit).toDate()) };
    }

    return query;
  }

  export function initializeQueryRequest(lowerLimit: Date, upperLimit: Date): QueryRequest {
    return {
      ...initializeQueries<MobileDumpErrorSummaryData>(),
      lowerLimit,
      upperLimit,
    };
  }
}
