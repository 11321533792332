import { RubricOptionDetailsResponseDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';

export interface OptionRedirectionData {
  nextRubric: EntityLightData;
  nextQuestion: EntityLightData;
}

export namespace OptionRedirectionData {
  export function mapFromApiValue(option: RubricOptionDetailsResponseDTO): OptionRedirectionData {
    return {
      nextRubric: {
        uuidType: option.nextRubric.uuidType,
        uuidEntity: option.nextRubric.uuidEntity,
        name: option.nextRubric.name,
      },
      nextQuestion: {
        uuidType: option.nextQuestion.uuidType,
        uuidEntity: option.nextQuestion.uuidEntity,
        name: option.nextQuestion.name,
      },
    };
  }
}
