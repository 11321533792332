import { FormForImportResponseDTO, PageFormRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { SortFilter } from '../utils/sort-filter';

import { DateUtils } from '../../utils/date.utils';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { FormNatureEnum, InnerSortDirectionEnum } from '../../enums';
import { CompanySummaryData } from '../companies/companies-summary-data';

export interface FormToImportSummaryData {
  name: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  modificationDate: string;
  nature: FormNatureEnum;
  indice: string;
  reference: string;
  labelFamily: LabelFamilyLightData;
  useTemplate: boolean;
  customStatus: CustomStatusLightData;
  frozen: boolean;
  updated: boolean;
  additionalComment?: string;
  companies: CompanySummaryData[];
  companiesTooltip?: string;
}

export namespace FormToImportSummaryData {
  const sortFields = ['name', 'family', 'reference', 'index', 'modificationDate', 'customStatus'];
  export interface QueryRequest extends BaseQuery<FormToImportSummaryData> {
    applicabilities: string[];
    type: FormNatureEnum;
    families: string[];
    statuses: string[];
  }

  export function mapFromApiValue(formForImport: FormForImportResponseDTO): FormToImportSummaryData {
    const formCompanies = (formForImport.companies || []).map(company => CompanySummaryData.mapFromApi(company));

    return {
      uuidEntity: formForImport.uuidEntity,
      uuidType: formForImport.uuidType,
      uuid: formForImport.uuid,
      name: formForImport.name,
      modificationDate: DateUtils.toDateFormat(formForImport.modificationDate),
      nature: FormNatureEnum.convertFromApiValue.getValue(formForImport.nature),
      indice: formForImport.indice,
      reference: formForImport.reference,
      useTemplate: formForImport['useTemplate'], // @wajdi @wissem why removed from dto ?
      labelFamily: formForImport.labelFamily && LabelFamilyLightData.mapFromApiValue(formForImport.labelFamily),
      customStatus: formForImport.customStatus && CustomStatusLightData.mapFromApiValue(formForImport.customStatus),
      frozen: formForImport.alreadyImported,
      updated: formForImport.updated,
      additionalComment: formForImport.additionalComment,
      companies: (formForImport.companies || []).map(company => CompanySummaryData.mapFromApi(company)),
      companiesTooltip: formCompanies?.length > 1 ? '- ' + formCompanies.map(company => company.name).join('<br/>- ') : null,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageFormRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      applicabilitiesUuidEntity: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
      type: FormNatureEnum.convertToApiValue.getValue(queries.type) || undefined,
      familiesUuidEntity: (queries.families || []).length ? queries.families : undefined,
      customStatusUuidEntity: (queries.statuses || []).length ? queries.statuses : undefined,
      textSearch: queries.textSearch || undefined,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    type: string,
    families: string[],
    statuses: string[],
    searchText: string,
    sort: SortFilter<FormToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<FormToImportSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      type: FormNatureEnum.convertFromParamsValue.getValue(type) || undefined,
      families: (families || []).length ? families : undefined,
      statuses: (statuses || []).length ? statuses : undefined,
    };
  }
}
