import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';
import { BlockTypeEnum } from '../block/block-type.enum';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { BlockStatusEnum } from '../block/block-status.enum';

export enum BlockActionEnum {
  Historic = 1,
  Properties,
  Copy,
  Paste,
  HideMultiPrTitle,
  ShowMultiPrTitle,
}

export namespace BlockActionEnum {
  export const values = (flowTye: FlowTypeEnum, blockType: BlockTypeEnum, multiPrTitleHidden?: boolean): BlockActionEnum[] =>
    compact([
      BlockTypeEnum.MultiPr === blockType && multiPrTitleHidden && BlockActionEnum.ShowMultiPrTitle,
      BlockTypeEnum.MultiPr === blockType && !multiPrTitleHidden && BlockActionEnum.HideMultiPrTitle,
      FlowTypeEnum.flowsWhereBlocksCanBeValidated.includes(flowTye) && BlockActionEnum.Historic,
      BlockActionEnum.Properties,
      BlockTypeEnum.blocksCanBeCopied.includes(blockType) && FlowTypeEnum.flowsWhereBlocksCanBeCopied.includes(flowTye) && BlockActionEnum.Copy,
      BlockTypeEnum.blocksCanBeCopied.includes(blockType) && FlowTypeEnum.flowsWhereBlocksCanBeCopied.includes(flowTye) && BlockActionEnum.Paste,
    ]);

  const displayMultiPrTitleOnMode = [BlockStatusEnum.Redaction, BlockStatusEnum.Validation];
  const blockMultiPrActionToHide = [BlockActionEnum.HideMultiPrTitle, BlockActionEnum.ShowMultiPrTitle];
  export const toString = new SfxMap<BlockActionEnum, string>([
    [BlockActionEnum.Historic, 'blocks.actions.historic'],
    [BlockActionEnum.Properties, 'blocks.actions.properties'],
    [BlockActionEnum.Copy, 'blocks.actions.copy'],
    [BlockActionEnum.Paste, 'blocks.actions.paste'],
    [BlockActionEnum.HideMultiPrTitle, 'blocks.actions.hideMultiPrTitle'],
    [BlockActionEnum.ShowMultiPrTitle, 'blocks.actions.showMultiPrTitle'],
  ]);

  export const iconData = new SfxMap<BlockActionEnum, IconData>([
    [BlockActionEnum.Historic, { name: 'flag' }],
    [BlockActionEnum.Properties, { name: 'info' }],
    [BlockActionEnum.Copy, { name: 'copy' }],
    [BlockActionEnum.Paste, { name: 'paste' }],
    [BlockActionEnum.HideMultiPrTitle, { name: 'hashtag' }],
    [BlockActionEnum.ShowMultiPrTitle, { name: 'hashtag' }],
  ]);

  export const selectItems = (
    flowTye: FlowTypeEnum,
    blockType: BlockTypeEnum,
    canPaste?: boolean,
    multiPrTitleHidden?: boolean,
    blockStatus?: BlockStatusEnum,
  ): ActionSelectItemData<BlockActionEnum>[] =>
    values(flowTye, blockType, multiPrTitleHidden).map(action => ({
      name: toString.getValue(action),
      value: action,
      icon: iconData.getValue(action),
      disabled: (BlockActionEnum.Paste === action && !canPaste) || (blockMultiPrActionToHide.includes(action) && !displayMultiPrTitleOnMode.includes(blockStatus)),
    }));
}
