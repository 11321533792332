import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { LabelFamilyDetailsResponseDTO } from '../../generated/models/labelFamilyDetailsResponseDTO';
import { SfxMap } from '../../utils/enum-utils';

export enum LabelFamilyRelativeNatureEnum {
  Parent = 1,
  Child = 2,
  Container = 3,
}

export namespace LabelFamilyRelativeNatureEnum {
  const values: LabelFamilyRelativeNatureEnum[] = [LabelFamilyRelativeNatureEnum.Parent, LabelFamilyRelativeNatureEnum.Child];

  export const convertFromApiValue: SfxMap<LabelFamilyDetailsResponseDTO.RelativeNatureEnum, LabelFamilyRelativeNatureEnum> = new SfxMap<
    LabelFamilyDetailsResponseDTO.RelativeNatureEnum,
    LabelFamilyRelativeNatureEnum
  >([
    [LabelFamilyDetailsResponseDTO.RelativeNatureEnum.CHILD, LabelFamilyRelativeNatureEnum.Child],
    [LabelFamilyDetailsResponseDTO.RelativeNatureEnum.PARENT, LabelFamilyRelativeNatureEnum.Parent],
  ]);

  export const convertToApiValue: SfxMap<LabelFamilyRelativeNatureEnum, LabelFamilyDetailsResponseDTO.RelativeNatureEnum> = new SfxMap<
    LabelFamilyRelativeNatureEnum,
    LabelFamilyDetailsResponseDTO.RelativeNatureEnum
  >([
    [LabelFamilyRelativeNatureEnum.Child, LabelFamilyDetailsResponseDTO.RelativeNatureEnum.CHILD],
    [LabelFamilyRelativeNatureEnum.Parent, LabelFamilyDetailsResponseDTO.RelativeNatureEnum.PARENT],
  ]);

  const key = 'attributes.dialog.family.';

  export const toString: SfxMap<LabelFamilyRelativeNatureEnum, string> = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, key + 'parent'],
    [LabelFamilyRelativeNatureEnum.Child, key + 'child'],
  ]);

  const keyForFamilies = 'families.dialog.family.';
  const toStringForFamiliesCustomField = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, keyForFamilies + 'parent'],
    [LabelFamilyRelativeNatureEnum.Child, keyForFamilies + 'child'],
  ]);

  export const selectItemForFamiliesCustomField: SingleSelectItemData<LabelFamilyRelativeNatureEnum>[] = values.map(relativeNature => ({
    value: relativeNature,
    title: toStringForFamiliesCustomField.getValue(relativeNature),
  }));

  const keyForCategory = 'attributes.dialog.category.';

  export const toStringForCategory: SfxMap<LabelFamilyRelativeNatureEnum, string> = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, keyForCategory + 'parent'],
    [LabelFamilyRelativeNatureEnum.Child, keyForCategory + 'child'],
  ]);

  const letterKey = 'status.table.relativeNature.';

  export const toLetter: SfxMap<LabelFamilyRelativeNatureEnum, string> = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, letterKey + 'parent'],
    [LabelFamilyRelativeNatureEnum.Child, letterKey + 'child'],
  ]);

  const tooltipKey = 'status.table.relativeNature.tooltip.';

  export const tooltip: SfxMap<LabelFamilyRelativeNatureEnum, string> = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, tooltipKey + 'parent'],
    [LabelFamilyRelativeNatureEnum.Child, tooltipKey + 'child'],
  ]);

  export const className: SfxMap<LabelFamilyRelativeNatureEnum, string> = new SfxMap<LabelFamilyRelativeNatureEnum, string>([
    [LabelFamilyRelativeNatureEnum.Parent, 'sfx-chip-light-blue'],
    [LabelFamilyRelativeNatureEnum.Child, 'sfx-chip-dark-green'],
  ]);

  export const selectItems: SingleSelectItemData<LabelFamilyRelativeNatureEnum>[] = values.map(relativeNature => ({
    value: relativeNature,
    title: toString.getValue(relativeNature),
  }));

  export const selectItemsForCategory: SingleSelectItemData<LabelFamilyRelativeNatureEnum>[] = values.map(relativeNature => ({
    value: relativeNature,
    title: toStringForCategory.getValue(relativeNature),
  }));
}
