import { SfxMap } from '../../utils/enum-utils';
import { FlowTeamRolesEnum } from '../flow-team-roles/flow-team-roles.enum';
import { PlanDisplayFormatEnum } from '../plan/plan-display-format.enum';

export enum PlanColumnEnum {
  Name = 'name',
  Status = 'status',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PlanningDay = 'planningDay',
  PlanningWeek = 'planningWeek',
  PlanningMonth = 'planningMonth',
  PlanningQuarter = 'planningQuarter',
  PlanningYear = 'planningYear',
  SeparatorColumn = 'separatorColumn',
  Assignment = 'assignment',
  Family = 'family',
  Hierarchy = 'hierarchy',
  Function = 'function',
  Skills = 'skills',
  AssignedTo = 'assignedTo',
  Team = 'team',
  TeamRoleName = 'teamRoleName',
  DeleteAffectation = 'deleteAffectation',
}

export namespace PlanColumnEnum {
  export const allColumns = [
    PlanColumnEnum.Name,
    PlanColumnEnum.StartDate,
    PlanColumnEnum.EndDate,
    PlanColumnEnum.PlanningDay,
    PlanColumnEnum.PlanningWeek,
    PlanColumnEnum.PlanningMonth,
    PlanColumnEnum.PlanningQuarter,
    PlanColumnEnum.PlanningYear,
  ];
  export const temporalPlanCommonColumns = [PlanColumnEnum.Name, PlanColumnEnum.StartDate, PlanColumnEnum.EndDate];
  export const TemporalTeamDialogColumns = [
    PlanColumnEnum.Function,
    PlanColumnEnum.TeamRoleName,
    PlanColumnEnum.Team,
    PlanColumnEnum.Hierarchy,
    PlanColumnEnum.Skills,
    PlanColumnEnum.AssignedTo,
  ];
  export const TemporalTeamWithoutAssigneeDialogColumns = [
    PlanColumnEnum.Function,
    PlanColumnEnum.TeamRoleName,
    PlanColumnEnum.Team,
    PlanColumnEnum.Hierarchy,
    PlanColumnEnum.Skills,
  ];
  export const TemporalTeamTabletDialogColumns = [PlanColumnEnum.Family, PlanColumnEnum.TeamRoleName, PlanColumnEnum.Status, PlanColumnEnum.AssignedTo];
  export const TemporalTeamTabletWithoutAssigneeDialogColumns = [PlanColumnEnum.Family, PlanColumnEnum.TeamRoleName, PlanColumnEnum.Status];
  export const schedulerPlanCommonColumns = [PlanColumnEnum.Name];

  export const toString = new SfxMap<PlanColumnEnum, string>([
    [PlanColumnEnum.Name, 'planTranslate.table.columns.name'],
    [PlanColumnEnum.StartDate, 'planTranslate.modals.addFlow.debutDate'],
    [PlanColumnEnum.EndDate, 'planTranslate.modals.addFlow.finDate'],
    [PlanColumnEnum.Assignment, 'planTranslate.table.columns.assignment'],
    [PlanColumnEnum.Family, 'planTranslate.table.columns.family'],
    [PlanColumnEnum.Hierarchy, 'planTranslate.table.columns.hierarchy'],
    [PlanColumnEnum.Team, 'planTranslate.table.columns.team'],
    [PlanColumnEnum.Function, 'planTranslate.table.columns.function'],
    [PlanColumnEnum.AssignedTo, 'planTranslate.table.columns.assignedTo'],
    [PlanColumnEnum.TeamRoleName, 'planTranslate.table.columns.teamRoleName'],
    [PlanColumnEnum.Status, 'planTranslate.table.columns.status'],
    [PlanColumnEnum.Skills, 'planTranslate.table.columns.skills'],
  ]);

  export const convertFromDisplayFormat = new SfxMap<PlanDisplayFormatEnum, PlanColumnEnum>([
    [PlanDisplayFormatEnum.Day, PlanColumnEnum.PlanningDay],
    [PlanDisplayFormatEnum.Week, PlanColumnEnum.PlanningWeek],
    [PlanDisplayFormatEnum.Month, PlanColumnEnum.PlanningMonth],
    [PlanDisplayFormatEnum.Quarter, PlanColumnEnum.PlanningQuarter],
    [PlanDisplayFormatEnum.Year, PlanColumnEnum.PlanningYear],
  ]);

  export const tableColumn = (disabled: boolean) =>
    new SfxMap<FlowTeamRolesEnum, PlanColumnEnum[]>(
      [
        [FlowTeamRolesEnum.Tablet_1, disabled ? TemporalTeamTabletWithoutAssigneeDialogColumns : TemporalTeamTabletDialogColumns],
        [FlowTeamRolesEnum.Tablet_2, disabled ? TemporalTeamTabletWithoutAssigneeDialogColumns : TemporalTeamTabletDialogColumns],
        [FlowTeamRolesEnum.Tablet_3, disabled ? TemporalTeamTabletWithoutAssigneeDialogColumns : TemporalTeamTabletDialogColumns],
      ],
      disabled ? TemporalTeamWithoutAssigneeDialogColumns : TemporalTeamDialogColumns,
    );

  export const sortField = new SfxMap<PlanColumnEnum, string>([
    [PlanColumnEnum.Name, 'name'],
    [PlanColumnEnum.StartDate, 'startDate'],
    [PlanColumnEnum.EndDate, 'endDate'],
    [PlanColumnEnum.Family, 'family'],
    [PlanColumnEnum.Team, 'group'],
    [PlanColumnEnum.Hierarchy, 'hierarchy'],
    [PlanColumnEnum.Function, 'function'],
    [PlanColumnEnum.TeamRoleName, 'name'],
  ]);

  export function convertSortToApi(key: string, isDefault: boolean) {
    if (key === PlanColumnEnum.sortField.getValue(PlanColumnEnum.StartDate)) {
      return isDefault ? 'planning.plannedDates.startDatetime' : 'flow.planning.plannedDates.startDatetime';
    }

    if (key === PlanColumnEnum.sortField.getValue(PlanColumnEnum.EndDate)) {
      return isDefault ? 'planning.plannedDates.endDatetime' : 'flow.planning.plannedDates.endDatetime';
    }

    return key;
  }
}
