import { createFeatureSelector, createSelector } from '@ngrx/store';

import { initialSfxDialogState, SfxDialogState } from './sfx-dialog.type';
import { StateName } from '../../../core/enums';

const sfxMainDialogState = createSelector(createFeatureSelector<SfxDialogState>(StateName.SfxDialog), state => state?.common || initialSfxDialogState().common);

export const getCurrentModalId = createSelector(sfxMainDialogState, state => state.modalId);
export const getDialogSuccessData = createSelector(sfxMainDialogState, state => state.successData);
export const getDialogErrorData = createSelector(sfxMainDialogState, state => state.errorData);
export const getDialogWarningData = createSelector(sfxMainDialogState, state => state.warningData);
export const getDialogEditMode = createSelector(sfxMainDialogState, state => state.editMode);
export const getDialogConvertMode = createSelector(sfxMainDialogState, state => state.convertMode);
export const getDialogKeepCreating = createSelector(sfxMainDialogState, state => state.keepCreate);
export const getDialogCreateAnother = createSelector(sfxMainDialogState, state => state.createAnother);
export const getDialogViewMode = createSelector(sfxMainDialogState, state => state.viewMode);
export const getDialogLoading = createSelector(sfxMainDialogState, state => state.loading);
export const getCurrentTab = createSelector(sfxMainDialogState, state => state.currentTab);
export const getSubmitted = createSelector(sfxMainDialogState, state => state.submitted);
export const getIsFormValid = createSelector(sfxMainDialogState, state => state.formValid);
export const getFocusIndex = createSelector(sfxMainDialogState, state => state.focusIndex);
