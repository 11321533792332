import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';
import { GroupedByElementResponseDTO } from '../../generated/models/models';
import { FlowDetailsData } from '../../models';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum PlanBusinessClassNameEnum {
  None = 1,
  Family = 2,
  Category = 3,
  Status = 4,
  Reporter = 5,
  Responsible = 6,
  Tablet = 7,
  Flow = 8,
  TeamRole = 9,
}

export namespace PlanBusinessClassNameEnum {
  export const valuesWhereElementsCanBePlanned = [PlanBusinessClassNameEnum.Flow];
  export const values: PlanBusinessClassNameEnum[] = [
    PlanBusinessClassNameEnum.None,
    PlanBusinessClassNameEnum.Family,
    PlanBusinessClassNameEnum.Category,
    PlanBusinessClassNameEnum.Status,
    PlanBusinessClassNameEnum.Reporter,
    PlanBusinessClassNameEnum.Responsible,
    PlanBusinessClassNameEnum.Tablet,
  ];
  export const convertToApiValue: SfxMap<PlanBusinessClassNameEnum, GroupedByElementResponseDTO.BusinessClassEnum> = new SfxMap<
    PlanBusinessClassNameEnum,
    GroupedByElementResponseDTO.BusinessClassEnum
  >(
    [
      [PlanBusinessClassNameEnum.Family, GroupedByElementResponseDTO.BusinessClassEnum.LABELFAMILY],
      [PlanBusinessClassNameEnum.Category, GroupedByElementResponseDTO.BusinessClassEnum.CATEGORY],
      [PlanBusinessClassNameEnum.Status, GroupedByElementResponseDTO.BusinessClassEnum.CUSTOMSTATUS],
      [PlanBusinessClassNameEnum.Reporter, GroupedByElementResponseDTO.BusinessClassEnum.REPORTER],
      [PlanBusinessClassNameEnum.Responsible, GroupedByElementResponseDTO.BusinessClassEnum.ASSIGNEE],
      [PlanBusinessClassNameEnum.Tablet, GroupedByElementResponseDTO.BusinessClassEnum.DEVICE],
    ],
    null,
  );

  export const convertFromApiValue: SfxMap<GroupedByElementResponseDTO.BusinessClassEnum, PlanBusinessClassNameEnum> = new SfxMap<
    GroupedByElementResponseDTO.BusinessClassEnum,
    PlanBusinessClassNameEnum
  >(
    [
      [GroupedByElementResponseDTO.BusinessClassEnum.LABELFAMILY, PlanBusinessClassNameEnum.Family],
      [GroupedByElementResponseDTO.BusinessClassEnum.CATEGORY, PlanBusinessClassNameEnum.Category],
      [GroupedByElementResponseDTO.BusinessClassEnum.CUSTOMSTATUS, PlanBusinessClassNameEnum.Status],
      [GroupedByElementResponseDTO.BusinessClassEnum.REPORTER, PlanBusinessClassNameEnum.Reporter],
      [GroupedByElementResponseDTO.BusinessClassEnum.ASSIGNEE, PlanBusinessClassNameEnum.Responsible],
      [GroupedByElementResponseDTO.BusinessClassEnum.DEVICE, PlanBusinessClassNameEnum.Tablet],
    ],
    PlanBusinessClassNameEnum.None,
  );

  export const iconData: SfxMap<PlanBusinessClassNameEnum, IconData> = new SfxMap<PlanBusinessClassNameEnum, IconData>(
    [
      [PlanBusinessClassNameEnum.Family, { name: 'tag' }],
      [PlanBusinessClassNameEnum.Category, { name: 'tags' }],
      [PlanBusinessClassNameEnum.Status, { name: 'flag' }],
      [PlanBusinessClassNameEnum.Reporter, { name: 'user' }],
      [PlanBusinessClassNameEnum.Responsible, { name: 'user' }],
      [PlanBusinessClassNameEnum.Tablet, { name: 'tablet-alt' }],
      [PlanBusinessClassNameEnum.Flow, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
      [PlanBusinessClassNameEnum.TeamRole, { name: 'user' }],
    ],
    null,
  );

  export const toString: SfxMap<PlanBusinessClassNameEnum, string> = new SfxMap<PlanBusinessClassNameEnum, string>(
    [
      [PlanBusinessClassNameEnum.None, 'planTranslate.filter.groupBy.none'],
      [PlanBusinessClassNameEnum.Family, 'planTranslate.filter.groupBy.family'],
      [PlanBusinessClassNameEnum.Category, 'planTranslate.filter.groupBy.category'],
      [PlanBusinessClassNameEnum.Status, 'planTranslate.filter.groupBy.status'],
      [PlanBusinessClassNameEnum.Reporter, 'planTranslate.filter.groupBy.reporter'],
      [PlanBusinessClassNameEnum.Responsible, 'planTranslate.filter.groupBy.responsible'],
      [PlanBusinessClassNameEnum.Tablet, 'planTranslate.filter.groupBy.tablet'],
    ],
    'planTranslate.filter.groupBy.none',
  );

  export const convertToParamsValue: SfxMap<PlanBusinessClassNameEnum, string> = new SfxMap<PlanBusinessClassNameEnum, string>(
    [
      [PlanBusinessClassNameEnum.None, 'none'],
      [PlanBusinessClassNameEnum.Family, 'family'],
      [PlanBusinessClassNameEnum.Category, 'category'],
      [PlanBusinessClassNameEnum.Status, 'status'],
      [PlanBusinessClassNameEnum.Reporter, 'reporter'],
      [PlanBusinessClassNameEnum.Responsible, 'responsible'],
      [PlanBusinessClassNameEnum.Tablet, 'tablet'],
    ],
    'none',
  );

  export const convertFromParamsValue: SfxMap<string, PlanBusinessClassNameEnum> = new SfxMap<string, PlanBusinessClassNameEnum>(
    [
      ['none', PlanBusinessClassNameEnum.None],
      ['family', PlanBusinessClassNameEnum.Family],
      ['category', PlanBusinessClassNameEnum.Category],
      ['status', PlanBusinessClassNameEnum.Status],
      ['reporter', PlanBusinessClassNameEnum.Reporter],
      ['responsible', PlanBusinessClassNameEnum.Responsible],
      ['tablet', PlanBusinessClassNameEnum.Tablet],
    ],
    PlanBusinessClassNameEnum.None,
  );

  export const groupUuidEntity = (flow: FlowDetailsData): SfxMap<PlanBusinessClassNameEnum, string> =>
    new SfxMap<PlanBusinessClassNameEnum, string>([
      [PlanBusinessClassNameEnum.Family, flow.labelFamilyApplication?.uuidEntity],
      [PlanBusinessClassNameEnum.Category, flow.labelFamilyProject?.uuidEntity],
      [PlanBusinessClassNameEnum.Status, flow.flowCustomStatus?.uuidEntity],
      [PlanBusinessClassNameEnum.Reporter, flow.reporterUser?.uuidEntity],
      [PlanBusinessClassNameEnum.Responsible, flow.assignedUser?.uuidEntity],
      [PlanBusinessClassNameEnum.Tablet, flow.userActor1?.uuidEntity],
    ]);

  export const hasChangedGroup = (flow: FlowDetailsData, initialFlow: FlowDetailsData): SfxMap<PlanBusinessClassNameEnum, boolean> =>
    new SfxMap<PlanBusinessClassNameEnum, boolean>([
      [PlanBusinessClassNameEnum.Family, flow.labelFamilyApplication?.uuidEntity !== initialFlow.labelFamilyApplication?.uuidEntity],
      [PlanBusinessClassNameEnum.Category, flow.labelFamilyProject?.uuidEntity !== initialFlow.labelFamilyProject?.uuidEntity],
      [PlanBusinessClassNameEnum.Status, flow.flowCustomStatus?.uuidEntity !== initialFlow.flowCustomStatus?.uuidEntity],
      [PlanBusinessClassNameEnum.Reporter, flow.reporterUser?.uuidEntity !== initialFlow.reporterUser?.uuidEntity],
      [PlanBusinessClassNameEnum.Responsible, flow.assignedUser?.uuidEntity !== initialFlow.assignedUser?.uuidEntity],
      [PlanBusinessClassNameEnum.Tablet, flow.userActor1?.uuidEntity !== initialFlow.userActor1?.uuidEntity],
    ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<PlanBusinessClassNameEnum>[]> =>
    of(
      values.map(value => ({
        value,
        title: toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<PlanBusinessClassNameEnum>, item: PlanBusinessClassNameEnum) => node.value === item;
}
