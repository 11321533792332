import dayjs from 'dayjs';
import { KpiGlobalRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';

export interface LicensesKpisQueryParams {
  applicabilities: string[];
  startAt: string;
  endAt: string;
}

export namespace LicensesKpisQueryParams {
  export function mapToRequestApiValue(queries: LicensesKpisQueryParams): KpiGlobalRequestDTO {
    return {
      applicabilityUuidEntities: queries.applicabilities || undefined,
      startAt: DateUtils.toZonedDateTime(dayjs(queries.startAt).startOf('month').toDate()),
      endAt: DateUtils.toZonedDateTime(dayjs(queries.endAt).endOf('month').toDate()),
    };
  }

  export function initialize(applicabilities: string[], month: string): LicensesKpisQueryParams {
    const unixMonth = dayjs.unix(+month);

    return {
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      startAt: DateUtils.toCalendarFormat(dayjs(unixMonth).startOf('month').toDate(), true),
      endAt: DateUtils.toCalendarFormat(dayjs(unixMonth).endOf('month').toDate(), true),
    };
  }
}
