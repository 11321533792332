import { CheckQuestionResponseDTO } from '../../generated/models/models';
import { QuestionErrorTypeEnum, QuestionIconEnum, QuestionTypeEnum } from '../../enums';

export interface QuestionValidationData {
  name: string;
  nature: QuestionTypeEnum;
  icon: QuestionIconEnum;
  errorType: QuestionErrorTypeEnum;
}

export namespace QuestionValidationData {
  export function mapFromApiValue(question: CheckQuestionResponseDTO): QuestionValidationData {
    return {
      name: question.name,
      nature: QuestionTypeEnum.convertFromApiValue.getValue(question.nature),
      icon: QuestionIconEnum.convertFromApiValue.getValue(question.icon),
      errorType: QuestionErrorTypeEnum.convertFromApiValue.getValue(question.errorType),
    };
  }
}
