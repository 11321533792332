import { map, groupBy, mapValues, sortBy, countBy, uniq, Dictionary } from 'lodash';

import { QuestionTypeEnum } from '../../enums';
import { RubricOptionRecap } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { ApplicationFileLightData } from '../application-file/application-file';
import { QuestionLocationData } from '../form-builder/question-location-data';
import { LineChartDatasets } from '../kpis/line-chart-datasets';

export interface ChartDataSets<T = number[]> {
  labels: string[];
  datasets: T;
  labelValues?: Array<string[]>;
  legends?: LineChartDatasets[];
  comment?: string;
  chartAlignment?: 'horizontal' | 'vertical';
}

export interface FormQuestionRecapAnswer {
  blockText?: {
    title?: string;
    labels: { label: string; translateKeys?: Dictionary<string> }[];
    emptyResponseCount?: number;
  }[];

  imageUuidEntities?: string[];
  applicationFiles?: ApplicationFileLightData[];
  locations?: QuestionLocationData[];
  pieData?: ChartDataSets;
  barData?: ChartDataSets;
  timeData?: ChartDataSets<number[][]>;
  unitValueData?: ChartDataSets[];
  description?: string;
}

const hourSeparator = ':';

export namespace FormQuestionRecapAnswer {
  export const defaultColor = ['#1F5086'];
  export const colors = ['#D6722E', '#A876C4', '#5DB082', '#F3AD3D', '#1F5086', '#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba'];
  export function mapFromApiValue(answers: RubricOptionRecap[], questionType: QuestionTypeEnum): FormQuestionRecapAnswer {
    switch (questionType) {
      case QuestionTypeEnum.Location:
        return { locations: answers.map(QuestionLocationData.mapFromApiValue) };

      case QuestionTypeEnum.ShortAnswer:
      case QuestionTypeEnum.LongAnswer:
      case QuestionTypeEnum.Formula:
        return {
          blockText: [
            {
              labels: answers.filter(answer => answer.textValue).map(answer => ({ label: answer.textValue })),
              emptyResponseCount: answers.filter(answer => !answer.textValue).length,
            },
          ],
        };

      case QuestionTypeEnum.MultipleAnswers:
        return {
          blockText: map(groupBy(answers, 'option'), (items, option) => ({ title: option, labels: map(items, 'textValue').map(label => ({ label })) })),
        };

      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.ListOfUniqueChoices:
        const pieDatasets = answers.map(answer => answer.count ?? 0);

        return {
          pieData: {
            datasets: pieDatasets,
            labels: answers.map(answer => answer.textValue),
            legends: answers.map((answer, index) => ({
              label: `${answer.textValue} ${percent(pieDatasets, index)}`,
              backgroundColor: colors[index % colors.length],
              color: colors[index % colors.length],
              metric: undefined,
              data: [],
            })),
          },
        };

      case QuestionTypeEnum.MultipleChoices:
        return {
          barData: {
            chartAlignment: 'horizontal',
            datasets: answers.map(answer => answer.count ?? 0),
            labels: answers.map(answer => answer.textValue),
          },
        };

      case QuestionTypeEnum.ListOfMultipleChoices:
        const labelValues = getListOfMultipleChoices(answers);

        return {
          barData: {
            chartAlignment: 'horizontal',
            labels: labelValues.map(answer => answer.label),
            datasets: labelValues.map(answer => answer.count),
          },
        };

      case QuestionTypeEnum.Date:
        return {
          barData: {
            chartAlignment: 'vertical',
            labels: sortDates(answers, 'dateValue').map(answer => DateUtils.toCustomDateFormat(answer.dateValue, 'DD/MM/YYYY')),
            datasets: sortBy(answers, 'dateValue').map(answer => answer.count),
          },
        };

      case QuestionTypeEnum.Duration:
        const formatDuration = answers.filter(answer => answer.timeValue).map(answer => DateUtils.toChartTimeFormat(answer.timeValue as string, 'HH:mm'));
        const groupedByHours = groupByHour(sortBy(formatDuration));

        return {
          timeData: {
            labels: groupedByHours.map(group => (group.hour === '00' ? '30min' : `${group.hour}h`)),
            datasets: getTimeDatasets(groupedByHours),
            labelValues: groupedByHours.map(group => group.times),
          },
        };

      case QuestionTypeEnum.Collaborator:
        const filteredCollaborators = answers.filter(answer => answer.signatoryRecap?.name || answer.signatoryRecap?.date);
        const allCollaborators = filteredCollaborators.map(answer => ({
          label: 'forms.summary.messages.collaborator',
          translateKeys: {
            name: answer.signatoryRecap?.name || 'N/A',
            date: DateUtils.toCustomDateFormat(answer.signatoryRecap?.date, 'DD/MM/YYYY [à] HH:mm') || '--/--/----',
          },
        }));

        return {
          blockText: [
            {
              labels: allCollaborators,
              emptyResponseCount: answers.length - allCollaborators.length,
            },
          ],
        };

      case QuestionTypeEnum.Photo:
        return { imageUuidEntities: answers.reduce((acc, answer) => acc.concat(...answer.applicationFileUuides.map(appFile => appFile.uuidEntity)), []) };

      case QuestionTypeEnum.File:
        return {
          applicationFiles: answers.reduce((acc, answer) => acc.concat(...answer.applicationFileUuides.map(appFile => ApplicationFileLightData.mapFromApiValue(appFile))), []),
        };

      case QuestionTypeEnum.ValueUnit:
        const filteredUnitValue = answers.filter(it => it.textValue && it.textValueUnit);
        const unitList = groupedUnitValue(filteredUnitValue.sort((a, b) => (+a.textValue > +b.textValue ? 1 : -1)));

        return {
          unitValueData: Object.keys(unitList).map(key => ({
            comment: key,
            labels: uniq(unitList[key]),
            datasets: uniq(unitList[key]).map(value => unitList[key].filter(it => it === value).length),
          })),
        };

      case QuestionTypeEnum.DateTime:
        const filteredDateTime = sortDates(
          answers.filter(answer => answer.dateTimeValue),
          'dateTimeValue',
        );
        const valuesDateTime = filteredDateTime.map(answer => DateUtils.toCustomDateFormat(answer.dateTimeValue, 'DD/MM/YYYY'));
        const sortedUniqDateTime = uniq(valuesDateTime);

        const hoursDateTime = filteredDateTime.map(answer => DateUtils.toCustomDateFormat(answer.dateTimeValue, `HH${hourSeparator}mm`));
        const groupedByDateTime = groupByHour(hoursDateTime);

        return {
          barData: {
            chartAlignment: 'vertical',
            labels: sortedUniqDateTime,
            datasets: sortedUniqDateTime.map(answer => valuesDateTime.filter(it => it === answer).length),
          },
          timeData: {
            labels: groupedByDateTime.map(group => `${group.hour}h`),
            datasets: getTimeDatasets(groupedByDateTime),
            labelValues: groupedByDateTime.map(group => group.times),
          },
        };

      default:
        return {};
    }
  }

  /* Helpers */
  export const getTimeDatasets = (groupedByHours: { hour: string; times: string[] }[]): number[][] => {
    const countHours = groupedByHours.map(_g => Object.values(countBy(_g.times)));
    const timeLineBars = countHours.reduce((acc, curr) => (acc < curr.length ? curr.length : acc), 0);
    const result = new Array(timeLineBars).fill(0).map(() => new Array(countHours.length).fill(0));

    countHours.forEach((count, x) => {
      count.forEach((value, y) => {
        result[y][x] = value;
      });
    });

    return result;
  };

  export const groupByHour = (times: string[]): { hour: string; times: string[] }[] => {
    const result: { hour: string; times: string[] }[] = [];
    (times || []).forEach(time => {
      const hour = time.split(hourSeparator)[0];
      if (result.find(group => group.hour === hour)) {
        result[result.findIndex(item => item.hour === hour)].times.push(time);
      } else {
        result.push({ hour, times: [time] });
      }
    });

    return result;
  };

  export const getListOfMultipleChoices = (answers: RubricOptionRecap[]): { label: string; count: number }[] => {
    let result = [];
    const mapAnswers = (answers || []).map(answer => ({ ...answer, textValue: (answer.textValue || '').split('__') }));

    mapAnswers.forEach(block => {
      block.textValue.forEach(_label => {
        if (result.find(_value => _label === _value.label)) {
          result = result.map(res => (_label === res.label ? { label: _label, count: res.count + block.count } : res));
        } else {
          result.push({ label: _label, count: block.count || 1 });
        }
      });
    });

    return result;
  };

  export const percent = (data: number[], index: number) => {
    if (index < 0 || index >= data.length) {
      return '(0 %)';
    }
    const total = data.reduce((sum, current) => sum + current, 0);

    return total ? `(${Math.round((data[index] / total) * 100)} %)` : '(0 %)';
  };

  export const groupedUnitValue = (answers: RubricOptionRecap[]) => mapValues(groupBy(answers, 'textValueUnit'), unitValues => unitValues.map(unitValue => unitValue.textValue));

  export const sortDates = (list: RubricOptionRecap[], field: keyof RubricOptionRecap) => (list || []).sort((a, b) => DateUtils.sorDate(a[field] as Date, b[field] as Date));
}
