import { ContributorSummaryData } from '../../models/contributors/contributor-summary-data';
import { SfxMap } from '../../utils/enum-utils';

export enum ContributorColumnEnum {
  Type = 'type',
  LastName = 'lastName',
  Name = 'name',
  Function = 'function',
  Group = 'group',
  Role = 'role',
  Hierarchy = 'hierarchy',
  Company = 'company',
  Actions = 'actions',
}

export namespace ContributorColumnEnum {
  export type contributorSortField = keyof ContributorSummaryData;

  export enum HierarchyType {
    ProjectHierarchy = 1,
    FlowViewHierarchy,
    FlowEditHierarchy,
  }

  export const allColumns = [
    ContributorColumnEnum.Function,
    ContributorColumnEnum.Name,
    ContributorColumnEnum.Hierarchy,
    ContributorColumnEnum.Role,
    ContributorColumnEnum.Company,
    ContributorColumnEnum.Actions,
  ];

  export const reorganizeColumns = [ContributorColumnEnum.Function, ContributorColumnEnum.Name, ContributorColumnEnum.Hierarchy, ContributorColumnEnum.Company];
  export const columnImport = [ContributorColumnEnum.Function, ContributorColumnEnum.Name, ContributorColumnEnum.Hierarchy, ContributorColumnEnum.Company];

  const key = 'contributors.table.header.';
  export const toString = new SfxMap<ContributorColumnEnum, string>([
    [ContributorColumnEnum.Type, key + 'type'],
    [ContributorColumnEnum.LastName, key + 'lastName'],
    [ContributorColumnEnum.Name, key + 'name'],
    [ContributorColumnEnum.Function, key + 'function'],
    [ContributorColumnEnum.Group, key + 'group'],
    [ContributorColumnEnum.Role, key + 'role'],
    [ContributorColumnEnum.Hierarchy, key + 'hierarchy'],
    [ContributorColumnEnum.Company, key + 'company'],
    [ContributorColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField = new SfxMap<ContributorColumnEnum, contributorSortField>([
    [ContributorColumnEnum.Name, 'name'],
    [ContributorColumnEnum.Group, 'group'],
    [ContributorColumnEnum.Function, 'function'],
  ]);
}
