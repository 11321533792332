import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { PermissionActionEnum } from '../permission-action.enum';
import { TechnicalObjectNatureEnum } from '../technical-object/technical-object-nature.enum';

export enum TechnicalObjectActionEnum {
  AddTO = 1,
  Duplicate,
  Delete,
  AddContent,
  GenerateFlow,
  Property,
}

export namespace TechnicalObjectActionEnum {
  const key = 'technicalObjects.table.actions.';

  export const toString: SfxMap<TechnicalObjectActionEnum, string> = new SfxMap<TechnicalObjectActionEnum, string>(
    [
      [TechnicalObjectActionEnum.Duplicate, key + 'duplicate'],
      [TechnicalObjectActionEnum.AddTO, key + 'addObject'],
      [TechnicalObjectActionEnum.Delete, key + 'delete'],
      [TechnicalObjectActionEnum.AddContent, key + 'addContent'],
      [TechnicalObjectActionEnum.GenerateFlow, key + 'generateFlow'],
      [TechnicalObjectActionEnum.Property, key + 'properties'],
    ],
    'Unknown',
  );

  export const iconData: SfxMap<TechnicalObjectActionEnum, IconData> = new SfxMap<TechnicalObjectActionEnum, IconData>([
    [TechnicalObjectActionEnum.AddTO, { name: 'plus', prefix: 'fas' }],
    [TechnicalObjectActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [TechnicalObjectActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [TechnicalObjectActionEnum.AddContent, { name: 'box', prefix: 'fas' }],
    [TechnicalObjectActionEnum.GenerateFlow, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [TechnicalObjectActionEnum.Property, { name: 'info', prefix: 'fas' }],
  ]);

  export function mapToActionMenuItem(action: TechnicalObjectActionEnum, permissionAction?: PermissionActionEnum): ActionSelectItemData<TechnicalObjectActionEnum> {
    return {
      name: toString.getValue(action),
      permissionAction,
      value: action,
      icon: iconData.getValue(action),
    };
  }

  export const getActionMenu = (nature: TechnicalObjectNatureEnum) =>
    compact([
      mapToActionMenuItem(TechnicalObjectActionEnum.AddTO),
      nature !== TechnicalObjectNatureEnum.GeographyObject && mapToActionMenuItem(TechnicalObjectActionEnum.AddContent),
      nature !== TechnicalObjectNatureEnum.GeographyObject && mapToActionMenuItem(TechnicalObjectActionEnum.GenerateFlow),
      mapToActionMenuItem(TechnicalObjectActionEnum.Duplicate),
      mapToActionMenuItem(TechnicalObjectActionEnum.Property),
      mapToActionMenuItem(TechnicalObjectActionEnum.Delete, PermissionActionEnum.Delete),
    ]);
}
