import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { CustomStatusDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { BusinessClassNameEnum } from '../common/business-class-name.enum';

export enum LinkTypeEnum {
  Default = 1,
  EntityLabelFamily = 2,
  EntityLabelFamilyParent = 3,
  EntityParentLabelFamily = 4,
  EntityParentLabelFamilyParent = 5,
}

export namespace LinkTypeEnum {
  const values: SfxMap<BusinessClassNameEnum, LinkTypeEnum[]> = new SfxMap<BusinessClassNameEnum, LinkTypeEnum[]>(
    [
      [BusinessClassNameEnum.Project, [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily]],
      [BusinessClassNameEnum.Form, [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily]],
      [BusinessClassNameEnum.TechnicalObject, [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily]],
      [
        BusinessClassNameEnum.Flow,
        [
          LinkTypeEnum.Default,
          LinkTypeEnum.EntityLabelFamilyParent,
          LinkTypeEnum.EntityLabelFamily,
          LinkTypeEnum.EntityParentLabelFamilyParent,
          LinkTypeEnum.EntityParentLabelFamily,
        ],
      ],
      [
        BusinessClassNameEnum.Document,
        [
          LinkTypeEnum.Default,
          LinkTypeEnum.EntityLabelFamilyParent,
          LinkTypeEnum.EntityLabelFamily,
          LinkTypeEnum.EntityParentLabelFamilyParent,
          LinkTypeEnum.EntityParentLabelFamily,
        ],
      ],
    ],
    [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily],
  );

  export const linkTypesWhereCanSelectFamilyChild = [LinkTypeEnum.EntityParentLabelFamily, LinkTypeEnum.EntityLabelFamily];
  export const linkTypesWhereCanSelectFamilyParent = [LinkTypeEnum.EntityParentLabelFamilyParent, LinkTypeEnum.EntityLabelFamilyParent];

  export const convertFromApiValue: SfxMap<CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum, LinkTypeEnum> = new SfxMap<
    CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum,
    LinkTypeEnum
  >([
    [CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.DEFAULT, LinkTypeEnum.Default],
    [CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILY, LinkTypeEnum.EntityLabelFamily],
    [CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILYPARENT, LinkTypeEnum.EntityLabelFamilyParent],
    [CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILY, LinkTypeEnum.EntityParentLabelFamily],
    [CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILYPARENT, LinkTypeEnum.EntityParentLabelFamilyParent],
  ]);

  export const convertToApiValue: SfxMap<LinkTypeEnum, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum> = new SfxMap<
    LinkTypeEnum,
    CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum
  >([
    [LinkTypeEnum.Default, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.DEFAULT],
    [LinkTypeEnum.EntityLabelFamily, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILY],
    [LinkTypeEnum.EntityLabelFamilyParent, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILYPARENT],
    [LinkTypeEnum.EntityParentLabelFamily, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILY],
    [LinkTypeEnum.EntityParentLabelFamilyParent, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILYPARENT],
  ]);

  export const convertToApiValueRegardingNature = (nature: BusinessClassNameEnum) =>
    new SfxMap<LinkTypeEnum, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum>([
      [LinkTypeEnum.Default, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.DEFAULT],
      [
        LinkTypeEnum.EntityLabelFamily,
        [BusinessClassNameEnum.Flow, BusinessClassNameEnum.Document].includes(nature)
          ? CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILY
          : CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILY,
      ],
      [
        LinkTypeEnum.EntityLabelFamilyParent,
        [BusinessClassNameEnum.Flow, BusinessClassNameEnum.Document].includes(nature)
          ? CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYPARENTLABELFAMILYPARENT
          : CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILYPARENT,
      ],
      [LinkTypeEnum.EntityParentLabelFamily, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILY],
      [LinkTypeEnum.EntityParentLabelFamilyParent, CustomStatusDetailsResponseDTO.ChoixLinkTypeEnum.ENTITYLABELFAMILYPARENT],
    ]);

  export const toString = (nature?: BusinessClassNameEnum, placeHolder?: boolean): SfxMap<LinkTypeEnum, string> => {
    const key = 'status.dialog.linkType.';
    const endKey = `${placeHolder ? 'placeholder' : 'label'}`;

    return new SfxMap<LinkTypeEnum, string>([
      [LinkTypeEnum.Default, key + 'default'],
      [LinkTypeEnum.EntityLabelFamilyParent, key + linkTypeLabelFamilyParent(nature, endKey)],
      [LinkTypeEnum.EntityLabelFamily, key + linkTypeEntityLabelFamily(nature, endKey)],
      [LinkTypeEnum.EntityParentLabelFamilyParent, key + linkTYpeEntityParentLabelFamily(nature, endKey, LinkTypeEnum.EntityParentLabelFamilyParent)],
      [LinkTypeEnum.EntityParentLabelFamily, key + linkTYpeEntityParentLabelFamily(nature, endKey, LinkTypeEnum.EntityParentLabelFamily)],
    ]);
  };

  export function selectItems(selectedLinkType: LinkTypeEnum, nature?: BusinessClassNameEnum, onEdit?: boolean): SingleSelectItemData<LinkTypeEnum>[] {
    const linkTypeValue = selectedLinkType || LinkTypeEnum.Default;

    return values
      .getValue(nature)
      .map(linkType => ({ value: linkType, title: toString(nature).getValue(linkType), selected: linkType === linkTypeValue }))
      .filter(item => !onEdit || item.selected);
  }

  export function customFieldsSelectItems(selectedLinkType: LinkTypeEnum, onEdit?: boolean): SingleSelectItemData<LinkTypeEnum>[] {
    const linkTypeValue = selectedLinkType || LinkTypeEnum.Default;

    return [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily]
      .map(linkType => ({ value: linkType, title: toString().getValue(linkType), selected: linkType === linkTypeValue }))
      .filter(item => !onEdit || item.selected);
  }

  const customStatusValues = new SfxMap<BusinessClassNameEnum, LinkTypeEnum[]>(
    [
      [BusinessClassNameEnum.TechnicalObject, [LinkTypeEnum.Default, LinkTypeEnum.EntityLabelFamilyParent, LinkTypeEnum.EntityLabelFamily]],
      [BusinessClassNameEnum.Flow, [LinkTypeEnum.Default, LinkTypeEnum.EntityParentLabelFamilyParent, LinkTypeEnum.EntityParentLabelFamily]],
    ],
    [LinkTypeEnum.Default, LinkTypeEnum.EntityParentLabelFamilyParent, LinkTypeEnum.EntityParentLabelFamily],
  );

  export function customStatusSelectItems(selectedLinkType: LinkTypeEnum, nature?: BusinessClassNameEnum, onEdit?: boolean): SingleSelectItemData<LinkTypeEnum>[] {
    const linkTypeValue = selectedLinkType || LinkTypeEnum.Default;

    return customStatusValues
      .getValue(nature)
      .map(linkType => ({ value: linkType, title: toString().getValue(linkType), selected: linkType === linkTypeValue }))
      .filter(item => !onEdit || item.selected);
  }

  export function linkTypeLabelFamilyParent(nature: BusinessClassNameEnum, endKey: string): string {
    if (BusinessClassNameEnum.TechnicalObject === nature) {
      return `objectParent.${endKey}`;
    }

    if (BusinessClassNameEnum.Form === nature) {
      return `formParent.${endKey}`;
    }

    return [BusinessClassNameEnum.Project, BusinessClassNameEnum.Flow, BusinessClassNameEnum.Document].includes(nature) ? `entityParent.${endKey}` : `labelFamilyParent.${endKey}`;
  }

  export function linkTypeEntityLabelFamily(nature: BusinessClassNameEnum, endKey: string): string {
    if (BusinessClassNameEnum.TechnicalObject === nature) {
      return `objectChild.${endKey}`;
    }

    if (BusinessClassNameEnum.Form === nature) {
      return `formChild.${endKey}`;
    }

    return [BusinessClassNameEnum.Project, BusinessClassNameEnum.Flow, BusinessClassNameEnum.Document].includes(nature) ? `entityChild.${endKey}` : `labelFamilyChild.${endKey}`;
  }

  export function linkTYpeEntityParentLabelFamily(nature: BusinessClassNameEnum, endKey: string, linkTypeEnum: LinkTypeEnum): string {
    if (linkTypeEnum === LinkTypeEnum.EntityParentLabelFamilyParent) {
      if (nature === BusinessClassNameEnum.Flow) {
        return `flowParent.${endKey}`;
      }

      return nature === BusinessClassNameEnum.Document ? `documentParent.${endKey}` : `labelFamilyParent.${endKey}`;
    }

    if (linkTypeEnum === LinkTypeEnum.EntityParentLabelFamily) {
      if (nature === BusinessClassNameEnum.Flow) {
        return `flowChild.${endKey}`;
      }

      return nature === BusinessClassNameEnum.Document ? `documentChild.${endKey}` : `labelFamilyChild.${endKey}`;
    }
  }
}
