import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../../utils/enum-utils';

export enum EquipmentModalTabEnum {
  General = 'general',
  Verification = 'verification',
}

export namespace EquipmentModalTabEnum {
  const key = 'equipment.modals.add.tabs.';
  export const toString: SfxMap<EquipmentModalTabEnum, string> = new SfxMap<EquipmentModalTabEnum, string>([
    [EquipmentModalTabEnum.General, key + 'general'],
    [EquipmentModalTabEnum.Verification, key + 'verification'],
  ]);

  export const iconData: SfxMap<EquipmentModalTabEnum, IconData> = new SfxMap<EquipmentModalTabEnum, IconData>([
    [EquipmentModalTabEnum.General, { name: 'sliders-h', prefix: 'fas' }],
    [EquipmentModalTabEnum.Verification, { name: 'gavel', prefix: 'fas' }],
  ]);
}
