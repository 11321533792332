import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { ClientLightData } from '../client/client-light';
import { ProjectStatusEnum, ProjectTypeEnum } from '../../enums';
import { ProjectPropsResponseDTO } from '../../generated/models/models';
import { UserLightData } from '../user/user-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface ProjectForPropertiesData {
  name: string;
  type: ProjectTypeEnum;
  client: EntityLightData;
  family: EntityLightData;
  applicabilityName: string;
  statusProject: ProjectStatusEnum;
  shared: boolean;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
  isSubFolder: boolean;
  uuidOrganization: string;
}

export namespace ProjectForPropertiesData {
  export function mapFromApiValue(projectProperties: ProjectPropsResponseDTO): ProjectForPropertiesData {
    return {
      name: projectProperties.name,
      type: projectProperties.type && ProjectTypeEnum.convertFromApiValue.getValue(projectProperties.type),
      client: projectProperties.client && ClientLightData.mapToEntityLightData(projectProperties.client),
      family: projectProperties.family && LabelFamilyLightData.mapToEntityLightData(projectProperties.family),
      applicabilityName: projectProperties.applicabilityName,
      statusProject: projectProperties.status && ProjectStatusEnum.convertFromApiValue.getValue(projectProperties.status),
      shared: projectProperties.shared,
      creationDate: DateUtils.toDateTimeFormat(projectProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(projectProperties.modifcationDate),
      createdBy: projectProperties.createdBy && UserLightData.mapFromApiValue(projectProperties.createdBy),
      lastModifiedBy: projectProperties.lastModifiedBy && UserLightData.mapFromApiValue(projectProperties.lastModifiedBy),
      isSubFolder: projectProperties.subFolder,
      uuidOrganization: projectProperties.uuidOrganization,
    };
  }
}
