import { createReducer, Action, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as fromActions from './tablets-dialog.actions';

import { CTabletDetailsData } from '../../../../../core/models';

export interface TabletDialogState {
  loading: boolean;
  isSaving: boolean;
  tabletDetails: CTabletDetailsData;
  initialTabletDetails: CTabletDetailsData;
}

export const tabletDialogInitialState = () => ({
  loading: false,
  isSaving: false,
  tabletDetails: {} as CTabletDetailsData,
  initialTabletDetails: {} as CTabletDetailsData,
});

const _tabletsDialogReducer = createReducer<TabletDialogState>(
  tabletDialogInitialState(),
  on(fromActions.loadTabletDetailsSuccess, (state, tablet): TabletDialogState => ({ ...state, tabletDetails: tablet, initialTabletDetails: cloneDeep(tablet) })),
  on(fromActions.createTablet, fromActions.updateTablet, (state): TabletDialogState => ({ ...state, isSaving: true })),
  on(
    fromActions.createTabletSuccess,
    (state, { tablet, addNewOne }): TabletDialogState => {
      const newTabletData = addNewOne ? { ...tablet, comment: null, refExtern: null, uuidEntity: null } : tablet;

      return { ...state, isSaving: false, tabletDetails: newTabletData, initialTabletDetails: cloneDeep(newTabletData) };
    },
  ),
  on(fromActions.updateTabletFail, fromActions.createTabletFail, (state): TabletDialogState => ({ ...state, initialTabletDetails: { ...state.initialTabletDetails } })),
  on(fromActions.updateTabletSuccess, fromActions.updateTabletFail, fromActions.createTabletFail, (state): TabletDialogState => ({ ...state, isSaving: false })),
  on(fromActions.resetTabletDialogState, (): TabletDialogState => tabletDialogInitialState()),
);

export function tabletDialogReducer(state: TabletDialogState | undefined, action: Action): TabletDialogState {
  return _tabletsDialogReducer(state, action);
}
