import { ElementAdrSummaryResponseDTO, PageElementAdrListRequestDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { InnerSortDirectionEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';

export interface ListAdrSummaryData {
  uuidEntity: string;
  family: LabelFamilyLightData;
  risk: string;
  source: string;
  major?: boolean;
  alreadyImported?: boolean;
}

export namespace ListAdrSummaryData {
  const sortFields = ['risk', 'labelFamily', 'riskSource'];
  export interface QueryRequest extends BaseQuery<ListAdrSummaryData> {
    flowUuidEntity: string;
    families?: string[];
    applicabilities?: string[];
  }

  export function mapFromApiValue(data: ElementAdrSummaryResponseDTO): ListAdrSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      family: (data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily)) || LabelFamilyLightData.defaultData,
      risk: data.risk,
      source: data.riskSource,
      alreadyImported: data.alreadyImported,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageElementAdrListRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      families: queries.families,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      flowUuidEntity: queries.flowUuidEntity,
      applicabilities: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
    };
  }

  export function mapToImportedAdrsPageRequest(queries: QueryRequest): PageElementAdrListRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      families: queries.families,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      flowUuidEntity: queries.flowUuidEntity,
    };
  }

  export function initializeQueryRequest(
    flowUuidEntity: string,
    applicabilities: string[],
    families: string[],
    searchText: string,
    sort: SortFilter<ListAdrSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<ListAdrSummaryData>(sort),
      textSearch: searchText || undefined,
      families: (families || []).length ? families : undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      flowUuidEntity,
    };
  }
}
