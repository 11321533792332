import { SelectAllData } from '..';
import { FlowReorganizeTypeEnum } from '../../enums';
import { FlowsReorganizeRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { EntityLightData } from '../utils/entity-light-data';
import { FlowSummaryData } from './flow-summary';

export interface FlowReorganizeData {
  flowsUuidEntity: string[];
  responsibleUuidEntity: string;
  categoryUuide: string;
  tablet1Uuide: string;
  tablet2Uuide: string;
  tablet3Uuide: string;
  reporterUuide: string;
  statusUuide: string;
  finishDate: Date;
  startDate: Date;
  estimatedStartDate: Date;
  estimatedEndDate: Date;
  achievedStartDate: Date;
  achievedEndDate: Date;
  queries?: FlowSummaryData.QueryRequest;
  allSelected?: boolean;
  collaborators: EntityLightData[];
  excludedUuids?: string[];
}

export namespace FlowReorganizeData {
  export function mapToRequestApiValue(data: FlowReorganizeData): FlowsReorganizeRequestDTO {
    return {
      multiSelectRequest: {
        selectedUuids: !data.allSelected ? data.flowsUuidEntity || [] : undefined,
        excludedUuids: data.allSelected ? data.excludedUuids || [] : undefined,
        pageRequest: data.allSelected ? FlowSummaryData.mapToPageRequestApiValue(data.queries) : undefined,
      },
      assigneeUuidEntity: data.responsibleUuidEntity,
      categoryUuidEntity: data.categoryUuide,
      refUuideActor1: data.tablet1Uuide,
      refUuideActor2: data.tablet2Uuide,
      refUuideActor3: data.tablet3Uuide,
      statusUuidEntity: data.statusUuide,
      reporterUuidEntity: data.reporterUuide,
      finishDate: DateUtils.toZonedDateTime(data.finishDate) || undefined,
      startDate: DateUtils.toZonedDateTime(data.startDate) || undefined,
      estimatedStartDate: DateUtils.toZonedDateTime(data.estimatedStartDate) || undefined,
      estimatedEndDate: DateUtils.toZonedDateTime(data.estimatedEndDate) || undefined,
      achievedStartDate: DateUtils.toZonedDateTime(data.achievedStartDate) || undefined,
      achievedEndDate: DateUtils.toZonedDateTime(data.achievedEndDate) || undefined,
      collaboratorsUuidEntities: data.collaborators?.length ? data.collaborators.map(col => col.uuidEntity) : undefined,
    };
  }

  export function mapToFlowReorganizeData(
    selectAllData: SelectAllData,
    type: FlowReorganizeTypeEnum,
    selectedReorganize: EntityLightData,
    date: Date,
    collaborators: EntityLightData[],
  ): FlowReorganizeData {
    return {
      flowsUuidEntity: selectAllData.selectedUuids,
      excludedUuids: selectAllData.excludedUuids,
      allSelected: selectAllData.allSelected,
      responsibleUuidEntity: type === FlowReorganizeTypeEnum.Responsible ? selectedReorganize.uuidEntity : null,
      categoryUuide: type === FlowReorganizeTypeEnum.Category ? selectedReorganize.uuidEntity : null,
      tablet1Uuide: type === FlowReorganizeTypeEnum.TabletOne ? selectedReorganize.uuidEntity : null,
      tablet2Uuide: type === FlowReorganizeTypeEnum.TabletTwo ? selectedReorganize.uuidEntity : null,
      tablet3Uuide: type === FlowReorganizeTypeEnum.TabletThree ? selectedReorganize.uuidEntity : null,
      reporterUuide: type === FlowReorganizeTypeEnum.Reporter ? selectedReorganize.uuidEntity : null,
      statusUuide: type === FlowReorganizeTypeEnum.Status ? selectedReorganize.uuidEntity : null,
      finishDate: type === FlowReorganizeTypeEnum.EndDate ? date : null,
      startDate: type === FlowReorganizeTypeEnum.StartDate ? date : null,
      estimatedStartDate: type === FlowReorganizeTypeEnum.EstimatedStartDate ? date : null,
      estimatedEndDate: type === FlowReorganizeTypeEnum.EstimatedEndDate ? date : null,
      achievedStartDate: type === FlowReorganizeTypeEnum.AchievedStartDate ? date : null,
      achievedEndDate: type === FlowReorganizeTypeEnum.AchievedEndDate ? date : null,
      collaborators: collaborators?.length ? collaborators : null,
    };
  }
}
