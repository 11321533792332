import { createFeatureSelector, createSelector } from '@ngrx/store';

import { compact, uniq } from 'lodash';

import dayjs from 'dayjs';
import { DateUtils } from '../../core/utils/date.utils';
import { DateFilterData } from '../../core/models/utils/date-filter-data';

import { SfxRouterState } from './router.state';
import { RouterPaths } from '../../core/constant/route.constant';
import { DialogRouteQueryParam, InnerSortDirectionEnum, RouteIdEnum, RouteParamEnum, RouteQueryParamEnum, StateName } from '../../core/enums';

export const selectRouterState = createFeatureSelector<SfxRouterState>(StateName.SfxRouter);

export const getRouteParams = createSelector(selectRouterState, state => state.params);
const getPermissionRouteParams = createSelector(selectRouterState, state => state.permissionParams);

export const getRouteParam = (key: RouteParamEnum) => createSelector(getRouteParams, params => params[key]);
export const getPermissionRouteParam = (key: RouteParamEnum) => createSelector(getPermissionRouteParams, params => params[key]);

export const getQueryParams = createSelector(selectRouterState, state => state.queryParams);
export const getListQueryParam = (key: RouteQueryParamEnum | DialogRouteQueryParam) =>
  createSelector(getQueryParams, query => (query[key] && compact(uniq(query[key].split(',')))) || []);
export const getQueryParam = (key: RouteQueryParamEnum | DialogRouteQueryParam) => createSelector(getQueryParams, query => query[key] || '');

export const getRouteUrl = createSelector(selectRouterState, state => state.url);
export const getRouteNavigatedSegments = createSelector(selectRouterState, state => state.segments);
export const getRouteNavigationSegments = createSelector(selectRouterState, state => state.routeSegments);
export const getDialogNavigationSegments = createSelector(selectRouterState, state => state.dialogSegments);
export const getRouteSegmentsIds = createSelector(getRouteNavigatedSegments, segments => (segments || []).map(segment => segment.id));
export const getNavigationRouteSegmentsIds = createSelector(getRouteNavigationSegments, segments => (segments || []).map(segment => segment.id));
export const getActiveRouteId = createSelector(selectRouterState, state => state.currentRouteId);
export const getBackToReferentialModule = createSelector(selectRouterState, state => state.backToReferentialModule);
export const getBreadcrumbs = createSelector(selectRouterState, state => state.breadCrumbs);
export const getNavigateBackRouteData = createSelector(getBreadcrumbs, getRouteNavigatedSegments, getBackToReferentialModule, (breadcrumb, segments, backToRefModule) => {
  const breadcrumbData = (breadcrumb || []).find(item => item.routeId === RouteIdEnum.ProjectDashboard);

  return {
    segments: breadcrumbData && [
      ...breadcrumbData.segments,
      ...(backToRefModule ? [RouterPaths.DashboardPaths.dashboardPath, RouterPaths.DashboardPaths.flowReferentialPath] : []),
    ],
    hideAddButton: segments && !!segments.find(segment => segment.data && segment.data.hideAddButton),
  };
});

export const dialogOutletIsActive = createSelector(getDialogNavigationSegments, segments => segments && segments.length);

export const getDateFilters = createSelector(
  getQueryParam(RouteQueryParamEnum.StartDate),
  getQueryParam(RouteQueryParamEnum.EndDate),
  getQueryParam(RouteQueryParamEnum.FieldDate),
  getQueryParam(RouteQueryParamEnum.TypeDate),
  getQueryParam(RouteQueryParamEnum.DateState),
  (startDate, endDate, dateFilterBy, typeDate, state): DateFilterData => DateFilterData.mapToDateFilterData(startDate, endDate, dateFilterBy, typeDate, state),
);

export const getSecondaryDateFilters = createSelector(
  getQueryParam(RouteQueryParamEnum.StartDateSecondary),
  getQueryParam(RouteQueryParamEnum.EndDateSecondary),
  getQueryParam(RouteQueryParamEnum.FieldDateSecondary),
  getQueryParam(RouteQueryParamEnum.TypeDateSecondary),
  getQueryParam(RouteQueryParamEnum.DateStateSecondary),
  (startDate, endDate, dateFilterBy, typeDate, state): DateFilterData => DateFilterData.mapToDateFilterData(startDate, endDate, dateFilterBy, typeDate, state),
);

export const getDialogDateFilters = createSelector(
  getQueryParam(DialogRouteQueryParam.StartDate),
  getQueryParam(DialogRouteQueryParam.EndDate),
  getQueryParam(DialogRouteQueryParam.FieldDate),
  getQueryParam(DialogRouteQueryParam.TypeDate),
  (startDate, endDate, dateFilterBy, typeDate): DateFilterData => DateFilterData.mapToDateFilterData(startDate, endDate, dateFilterBy, typeDate),
);

export const getMonthFilters = createSelector(getQueryParam(RouteQueryParamEnum.Month), month => DateUtils.toMonthFormat(dayjs.unix(+month).toDate()));

export const getTextSearchFilter = createSelector(getQueryParam(RouteQueryParamEnum.TextSearch), textSearch => textSearch);
export const getAdvancedSearchFilter = createSelector(getQueryParam(RouteQueryParamEnum.AdvancedSearch), advancedSearch => advancedSearch);
export const getSortFilters = createSelector(getQueryParam(RouteQueryParamEnum.SortBy), getQueryParam(RouteQueryParamEnum.SortDirection), (key, direction) => ({
  key,
  direction: InnerSortDirectionEnum.convertFromParamsValue.getValue(direction),
}));

export const getDialogTextSearchFilter = createSelector(getQueryParam(DialogRouteQueryParam.TextSearch), textSearch => textSearch);
export const getDialogSortFilters = createSelector(getQueryParam(DialogRouteQueryParam.SortBy), getQueryParam(DialogRouteQueryParam.SortDirection), (key, direction) => ({
  key,
  direction: InnerSortDirectionEnum.convertFromParamsValue.getValue(direction),
}));
