import { KpiGlobalRequestDTO } from '../../generated/models/models';
import { DateFilterData } from '../utils/date-filter-data';

export interface UsedKpisQueryParams {
  applicabilities: string[];
  dateFilter: DateFilterData;
}

export namespace UsedKpisQueryParams {
  export function mapToRequestApiValue(queries: UsedKpisQueryParams): KpiGlobalRequestDTO {
    const filterDate = queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter);

    return {
      applicabilityUuidEntities: queries.applicabilities || undefined,
      startAt: filterDate?.startDate,
      endAt: filterDate?.endDate,
    };
  }

  export function initialize(applicabilities: string[], dateFilter: DateFilterData): UsedKpisQueryParams {
    return {
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
