import { FormTemplateParamsLightDTO, FormTemplateParamsUpdateRequestDTO } from '../../generated/models/models';
import { ApplicationFileLightData } from '../application-file/application-file';

export interface FormTemplateParamsData {
  useTemplate: boolean;
  template: ApplicationFileLightData;
  xPagePosition: number;
  yPagePosition: number;
  uuidEntity: string;
  hideInExportDsi?: boolean;
  hideInExportForm?: boolean;
  hideInExportMultiPr?: boolean;
  hideInExportPr?: boolean;
}

export namespace FormTemplateParamsData {
  export function mapFromApiValue(
    formTemplateParams: FormTemplateParamsLightDTO,
    useTemplate: boolean,
    hideInExportDsi,
    hideInExportForm: boolean,
    hideInExportMultiPr: boolean,
    hideInExportPr: boolean,
  ): FormTemplateParamsData {
    return {
      useTemplate,
      hideInExportDsi,
      hideInExportForm,
      hideInExportMultiPr,
      hideInExportPr,
      template: formTemplateParams.template && ApplicationFileLightData.mapFromApiValue(formTemplateParams.template),
      xPagePosition: formTemplateParams.xNumPage,
      yPagePosition: formTemplateParams.yNumPage,
      uuidEntity: formTemplateParams.uuidEntity,
    };
  }

  export function mapToUpdateRequestApiValue(formTemplateParams: FormTemplateParamsData): FormTemplateParamsUpdateRequestDTO {
    return {
      useTemplate: formTemplateParams.useTemplate,
      templateUuidEntity: (formTemplateParams.useTemplate && formTemplateParams.template && formTemplateParams.template.uuidEntity) || undefined,
      xNumPage: (formTemplateParams.useTemplate && formTemplateParams.xPagePosition) || undefined,
      yNumPage: (formTemplateParams.useTemplate && formTemplateParams.yPagePosition) || undefined,
      uuidEntity: formTemplateParams.uuidEntity,
      hideInExportDsi: formTemplateParams.hideInExportDsi,
      hideInExportForm: formTemplateParams.hideInExportForm,
      hideInExportMultiPr: formTemplateParams.hideInExportMultiPr,
      hideInExportPr: formTemplateParams.hideInExportPr,
    };
  }
}
