/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CustomFileDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  description: string;
  nature: CustomFileDetailsResponseDTO.NatureEnum;
  extension: CustomFileDetailsResponseDTO.ExtensionEnum;
  customFileType: CustomFileDetailsResponseDTO.CustomFileTypeEnum;
}
export namespace CustomFileDetailsResponseDTO {
  export type NatureEnum = 'IN' | 'OUT';
  export const NatureEnum = {
    IN: 'IN' as NatureEnum,
    OUT: 'OUT' as NatureEnum,
  };
  export type ExtensionEnum = 'CSV' | 'XLS' | 'XLSX' | 'WORD' | 'PDF' | 'TXT';
  export const ExtensionEnum = {
    CSV: 'CSV' as ExtensionEnum,
    XLS: 'XLS' as ExtensionEnum,
    XLSX: 'XLSX' as ExtensionEnum,
    WORD: 'WORD' as ExtensionEnum,
    PDF: 'PDF' as ExtensionEnum,
    TXT: 'TXT' as ExtensionEnum,
  };
  export type CustomFileTypeEnum =
    | 'PROJECT_FLOW_CONTENT_MINING'
    | 'PROJECT_FLOW_CONTENT'
    | 'PROJECT_FLOW_FORM'
    | 'TECHNICAL_OBJECT'
    | 'LIBRARY_ADR'
    | 'LIBRARY_EMM'
    | 'LIBRARY_USER';
  export const CustomFileTypeEnum = {
    PROJECTFLOWCONTENTMINING: 'PROJECT_FLOW_CONTENT_MINING' as CustomFileTypeEnum,
    PROJECTFLOWCONTENT: 'PROJECT_FLOW_CONTENT' as CustomFileTypeEnum,
    PROJECTFLOWFORM: 'PROJECT_FLOW_FORM' as CustomFileTypeEnum,
    TECHNICALOBJECT: 'TECHNICAL_OBJECT' as CustomFileTypeEnum,
    LIBRARYADR: 'LIBRARY_ADR' as CustomFileTypeEnum,
    LIBRARYEMM: 'LIBRARY_EMM' as CustomFileTypeEnum,
    LIBRARYUSER: 'LIBRARY_USER' as CustomFileTypeEnum,
  };
}
