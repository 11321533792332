/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExternalApiApplicabilityDTO } from './externalApiApplicabilityDTO';

export interface ExternalApiConsumerSummaryResponseDTO {
  name: string;
  serviceLevelClass: ExternalApiConsumerSummaryResponseDTO.ServiceLevelClassEnum;
  applicability: ExternalApiApplicabilityDTO;
  status: ExternalApiConsumerSummaryResponseDTO.StatusEnum;
  ssoIdentifier?: string;
  uuidEntity: string;
  uuid: string;
  login: string;
  uuidOrganization: string;
}
export namespace ExternalApiConsumerSummaryResponseDTO {
  export type ServiceLevelClassEnum = 'ROLE_EXTERNAL_API_BASE_ACCESS' | 'ROLE_EXTERNAL_API_ADVANCED_ACCESS' | 'ROLE_EXTERNAL_API_PREMIUM_ACCESS';
  export const ServiceLevelClassEnum = {
    BASEACCESS: 'ROLE_EXTERNAL_API_BASE_ACCESS' as ServiceLevelClassEnum,
    ADVANCEDACCESS: 'ROLE_EXTERNAL_API_ADVANCED_ACCESS' as ServiceLevelClassEnum,
    PREMIUMACCESS: 'ROLE_EXTERNAL_API_PREMIUM_ACCESS' as ServiceLevelClassEnum,
  };
  export type StatusEnum = 'ENABLED' | 'DISABLED';
  export const StatusEnum = {
    ENABLED: 'ENABLED' as StatusEnum,
    DISABLED: 'DISABLED' as StatusEnum,
  };
}
