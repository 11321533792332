import { ElementAdrPropsResponseDTO } from '../../generated/models/models';

import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserLightData } from '../user/user-light-data';
import { DateUtils } from '../../utils/date.utils';

export interface RiskPropertiesData {
  name: string;
  riskSource: string;
  family: EntityLightData;
  applicabilityName: string;
  creationDate: string;
  modificationDate: string;
  createdBy: UserLightData;
  lastModifiedBy: UserLightData;
}

export namespace RiskPropertiesData {
  export function mapFromApiValue(riskProperties: ElementAdrPropsResponseDTO): RiskPropertiesData {
    return {
      name: riskProperties.risk,
      riskSource: riskProperties.riskSource,
      family: riskProperties.family && LabelFamilyLightData.mapToEntityLightData(riskProperties.family),
      applicabilityName: riskProperties.applicabilityName,
      creationDate: DateUtils.toDateTimeFormat(riskProperties.creationDate),
      modificationDate: DateUtils.toDateTimeFormat(riskProperties.modifcationDate),
      createdBy: riskProperties.createdBy && UserLightData.mapFromApiValue(riskProperties.createdBy),
      lastModifiedBy: riskProperties.lastModifiedBy && UserLightData.mapFromApiValue(riskProperties.lastModifiedBy),
    };
  }
}
