import { InnerSortDirectionEnum, LabelApplicabilityEnum, LevelAccessApiEnum, StatusAccessApiEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { ExternalApiConsumerSummaryResponseDTO, PageExternalApiConsumerAccessRequestDTO } from '../../generated/models/models';

export interface AccessApiV2SummaryData {
  uuidEntity: string;
  name: string;
  applicability: EntityLightData;
  ssoIdentifier: string;
  level: LevelAccessApiEnum;
  status: StatusAccessApiEnum;
}

export namespace AccessApiV2SummaryData {
  const sortFields = ['destination'];
  export interface QueryRequest extends BaseQuery<AccessApiV2SummaryData> {
    applicabilityTypes: LabelApplicabilityEnum[];
    accessLevels: LevelAccessApiEnum[];
    statuses: StatusAccessApiEnum[];
  }

  export function mapFromApiValue(data: ExternalApiConsumerSummaryResponseDTO): AccessApiV2SummaryData {
    return {
      uuidEntity: data.uuidEntity,
      name: data.name,
      applicability: data.applicability && LabelApplicabilityLightData.mapToEntityLightData(data.applicability),
      ssoIdentifier: data.ssoIdentifier || null,
      level: LevelAccessApiEnum.mapFromApiValue.getValue(data.serviceLevelClass),
      status: StatusAccessApiEnum.mapFromApiValue.getValue(data.status),
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageExternalApiConsumerAccessRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      textSearch: queries.textSearch || undefined,
      applicabilityTypes: (queries.applicabilityTypes || []).length ? queries.applicabilityTypes.map(type => LabelApplicabilityEnum.convertToApiValue.getValue(type)) : undefined,
      statuses: (queries.statuses || []).length ? queries.statuses.map(status => StatusAccessApiEnum.mapToApiValue.getValue(status)) : undefined,
      serviceLevelClasses: (queries.accessLevels || []).length ? queries.accessLevels.map(level => LevelAccessApiEnum.mapToApiValue.getValue(level)) : undefined,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    accessLevels: string[],
    statuses: string[],
    searchText: string,
    sort: SortFilter<AccessApiV2SummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<AccessApiV2SummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilityTypes: (applicabilities || []).map(type => LabelApplicabilityEnum.convertFromParamsValue.getValue(type)),
      accessLevels: (accessLevels || []).map(level => LevelAccessApiEnum.convertFromParamsValue.getValue(level)),
      statuses: (statuses || []).map(status => StatusAccessApiEnum.convertFromParamsValue.getValue(status)),
    };
  }
}
