import { GeometryDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum GeometryTypeEnum {
  Point = 1,
  Polygon = 2,
  Polyline = 3,
  Circle = 4,
  MultiLineString = 5,
}

export namespace GeometryTypeEnum {
  export const convertFromApiValue: SfxMap<GeometryDTO.GeometryTypeEnum, GeometryTypeEnum> = new SfxMap<GeometryDTO.GeometryTypeEnum, GeometryTypeEnum>([
    [GeometryDTO.GeometryTypeEnum.POINT, GeometryTypeEnum.Point],
    [GeometryDTO.GeometryTypeEnum.POLYGON, GeometryTypeEnum.Polygon],
    [GeometryDTO.GeometryTypeEnum.POLYLINE, GeometryTypeEnum.Polyline],
    [GeometryDTO.GeometryTypeEnum.CIRCLE, GeometryTypeEnum.Circle],
    [GeometryDTO.GeometryTypeEnum.MULTILINESTRING, GeometryTypeEnum.MultiLineString],
  ]);
  export const convertToApiValue: SfxMap<GeometryTypeEnum, GeometryDTO.GeometryTypeEnum> = new SfxMap<GeometryTypeEnum, GeometryDTO.GeometryTypeEnum>([
    [GeometryTypeEnum.Point, GeometryDTO.GeometryTypeEnum.POINT],
    [GeometryTypeEnum.Polygon, GeometryDTO.GeometryTypeEnum.POLYGON],
    [GeometryTypeEnum.Polyline, GeometryDTO.GeometryTypeEnum.POLYLINE],
    [GeometryTypeEnum.Circle, GeometryDTO.GeometryTypeEnum.CIRCLE],
    [GeometryTypeEnum.MultiLineString, GeometryDTO.GeometryTypeEnum.MULTILINESTRING],
  ]);
}
