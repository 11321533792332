import { ElementPlanSummaryResponseDTO, PageElementSchedulingRequestDTO, SchedulingElementPlanSummaryResponseDTO } from '../../generated/models/models';
import { BaseElementPlanSummaryData } from './base-element-plan-summary-data';
import { PlanningChartData } from './plan-chart-bar-data';

import { PlaningHelper } from '../../../modules/dashboard/flow-management/list/plan/utils/helpers';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum, PlanColumnEnum, PlanDisplayFormatEnum, TabletStatusEnum, UserNatureEnum, UserTypeEnum } from '../../enums';
import { UserRoleEnum } from '../../enums/user-role.enum';

export interface SchedulerElementPlanSummaryData extends BaseElementPlanSummaryData {
  nature: UserNatureEnum;
}

export namespace SchedulerElementPlanSummaryData {
  const sortFields = ['name', 'startDate', 'endDate'];
  export interface ElementPlanQueryRequest extends BaseQuery<SchedulerElementPlanSummaryData> {
    planUuidEntity: string;
    functions?: string[];
    groups?: string[];
    hierarchies?: string[];
    skills?: string[];
    role?: UserRoleEnum;
    userType?: UserTypeEnum;
    natures?: UserNatureEnum[];
    families?: string[];
    statuses?: TabletStatusEnum[];
  }

  export function mapFromApiValue(row: SchedulingElementPlanSummaryResponseDTO, selectedTime: string, displayFormat: PlanDisplayFormatEnum): SchedulerElementPlanSummaryData {
    return {
      name: row.name,
      uuidEntity: row.uuidEntity,
      nature: row.operator ? UserNatureEnum.Operator : UserNatureEnum.convertFromApiValue.getValue(row.natureUser),
      planningData: (row.elementPlans || [])
        .filter(elementPlan => elementPlan.startDate && elementPlan.finishDate)
        .map(elementPlan => PlanningChartData.mapFromApiValue(elementPlan, selectedTime && PlaningHelper.getValidDate(selectedTime), displayFormat)),
    };
  }

  export function mapToPlanningChartData(elementPlan: ElementPlanSummaryResponseDTO, selectedTime: string, displayFormat: PlanDisplayFormatEnum): PlanningChartData {
    return PlanningChartData.mapFromApiValue(elementPlan, selectedTime && PlaningHelper.getValidDate(selectedTime), displayFormat);
  }

  export function initializeElementPlanQueries(
    planUuidEntity: string,
    textSearch: string,
    time: string,
    tabletFilters: { families: string[]; natures: string[]; statuses: string[] },
    userFilters: { functions: string[]; groups: string[]; hierarchies: string[]; skills: string[]; role: string; userType: string },
    displayFormat: PlanDisplayFormatEnum,
    sort: SortFilter<SchedulerElementPlanSummaryData>,
  ): ElementPlanQueryRequest {
    return {
      ...initializeQueries<SchedulerElementPlanSummaryData>(sort),
      textSearch: textSearch || undefined,
      planUuidEntity,
      families: tabletFilters.families,
      natures: (tabletFilters.natures || []).map(nature => UserNatureEnum.convertFromParamsValue.getValue(nature)),
      statuses: (tabletFilters.statuses || []).map(status => TabletStatusEnum.convertFromParamsValue.getValue(status)),
      functions: userFilters.functions,
      groups: userFilters.groups,
      hierarchies: userFilters.hierarchies,
      skills: userFilters.skills,
      userType: userFilters.userType && UserTypeEnum.convertFromParamsValue.getValue(userFilters.userType),
      role: userFilters.role && UserRoleEnum.convertFromParamsValue.getValue(userFilters.role),
    };
  }

  export function mapToPageRequestDto(request: ElementPlanQueryRequest, projectUuidEntity: string): PageElementSchedulingRequestDTO {
    const sortKey = PlanColumnEnum.convertSortToApi(request?.sort?.key, false);

    return {
      page: request.page,
      size: request.size,
      planUuidEntity: request.planUuidEntity,
      textSearch: request.textSearch || undefined,
      sort: sortFields.includes(request?.sort?.key) ? { attribute: sortKey, direction: InnerSortDirectionEnum.convertToApiValue.getValue(request.sort.direction) } : undefined,
      labelFamiliesUuidEntity: request.families,
      choixTeamRole: (request.role && UserRoleEnum.convertToApiValue.getValue(request.role)) || undefined,
      labelFamilyFunctionUuides: request.functions,
      labelFamilyHierarchyUuides: request.hierarchies,
      labelFamilySkillUuides: request.skills,
      userLabelFamilyTeamUuides: request.groups,
      natures: (request.natures || []).map(nature => UserNatureEnum.convertToApiValue.getValue(nature)),
      statuses: (request.statuses || []).map(status => TabletStatusEnum.convertToApiValue.getValue(status)),
      userType: request.userType && UserTypeEnum.convertToApiValue.getValue(request.userType),
      projectUuidEntity,
    };
  }
}
