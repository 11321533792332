import { ParadeLightDTO } from '../../generated/models/models';

export interface ParadeLightData {
  parade: string;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  uuidType: string;
}

export namespace ParadeLightData {
  export function mapFromApiValue(parade: ParadeLightDTO): ParadeLightData {
    return {
      parade: parade.parade,
      uuid: parade.uuid,
      uuidEntity: parade.uuidEntity,
      uuidOrganization: parade.uuidOrganization,
      uuidType: parade.uuidType,
    };
  }
}
