import { ErrorResponseData } from '../../models';
import { DialogToasterData } from '../../../modules/sfx-dialog/state/dialog-toaster-data';
import { SfxMap } from '../../utils/enum-utils';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';

export enum DuplicateStateEnum {
  Active = 1,
  Rejected = 2,
}

export namespace DuplicateStateEnum {
  const values: DuplicateStateEnum[] = [DuplicateStateEnum.Active, DuplicateStateEnum.Rejected];

  export const convertFromParamsValue: SfxMap<string, DuplicateStateEnum> = new SfxMap<string, DuplicateStateEnum>([
    ['actif', DuplicateStateEnum.Active],
    ['rejected', DuplicateStateEnum.Rejected],
  ]);

  export const convertToParamsValue: SfxMap<DuplicateStateEnum, string> = new SfxMap<DuplicateStateEnum, string>([
    [DuplicateStateEnum.Active, 'actif'],
    [DuplicateStateEnum.Rejected, 'rejected'],
  ]);

  const key = 'flows.modals.flowPgacDuplicate.duplicates.';
  export const toString: SfxMap<DuplicateStateEnum, string> = new SfxMap<DuplicateStateEnum, string>(
    [
      [DuplicateStateEnum.Active, key + 'actif'],
      [DuplicateStateEnum.Rejected, key + 'rejected'],
    ],
    'flows.modals.flowPgacDuplicate.filter.reject',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<DuplicateStateEnum>[]> =>
    of(
      values.map(state => ({
        value: state,
        title: toString.getValue(state),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DuplicateStateEnum>, item: DuplicateStateEnum) => node.value === item;

  export function getErrorKey(error: ErrorResponseData): DialogToasterData {
    return error.errorKey === 'flow.alreadyInUseOnDoc' ? { title: 'flows.modals.flowPgacDuplicate.duplicates.error.alreadyInUseOnDoc' } : { title: error.message };
  }
}
